import bchaddr from 'bchaddrjs';
import WAValidator from "@getdelta/wallet-address-validator/dist/wallet-address-validator.min";

const overETH = [
    'USDT',
    'MANA',
    'USDC',
    'BNT',
    'TUSD',
    'PAX',
    'BAT',
    'LINK',
    'MTL',
    'ZRX',
    'REP',
    'MATIC',
    'COMP',
    'ELF',
    'SNT',
    'USDT',
    'HOT',
    'KNC',
    'STORJ',
    'LRC',
    'BUSD',
];

export function checkAddress(address, currency) {
    if(currency === 'AGT')
        currency = 'TRX';

    if ((currency === '') || (address === '')) return false;
    if(!(/^([a-zA-Z0-9:])+$/i.test(address))) return false;
    if (currency === "BCH") {
        if (bchaddr.isValidAddress(address)) {
            return true;
        } else {
            return false;
        }
    }

    let ticker = (overETH.includes(currency)) ? 'ETH' : currency;
    let res;
    try {
        res = WAValidator.validate(address, ticker);
        return res;
    } catch(e) {
        console.log(e);
        return false;
    }
}
