<template>
  <div class="base-input">
    <div class="label">
      <slot name="label">
        <div
          v-if="$slots['label-left']"
          class="label-left"
        >
          <slot name="label-left" />
        </div>
        <div
          v-if="$slots['label-right']"
          class="label-right"
        >
          <slot name="label-right" />
        </div>
      </slot>
    </div>
    <div class="input-wrapper">
      <template v-if="!isTextArea">
        <input
            v-model="innerModel"
            v-bind="$attrs"
            :placeholder="placeholder"
            :type="type"
            @blur="checkLimits"
        />
        <div
            v-if='hasActionBtn'
            class="action"
            @click="$emit('action-btn-click')"
        >
          MAX
        </div>
        <div
            class="bottom-label"
            v-if="$slots['bottom-label']"
        >
          <slot name="bottom-label" />
        </div>
      </template>
      <textarea v-else v-model="innerModel" :placeholder="placeholder" rows="4" />
    </div>
  </div>
</template>

<script>
export default {
  name: "InputElement",
  props: {
    placeholder: {
      type: String,
      default: 'Начните ввод'
    },
    hasActionBtn: {
      type: Boolean,
      default: false
    },
    min: {
      type: [String, Number],
      default: undefined
    },
    max: {
      type: [String, Number],
      default: undefined
    },
    value: {
      type: [String, Number],
      default: undefined
    },
    type: {
      type: String,
      default: 'number'
    },
  },
  computed: {
    innerModel: {
      get({ value }) {
        return value
      },
      set(v) {
        const { max } = this
        if (max && Number(v) > Number(max)) {
          this.$emit('input', undefined)
          this.$nextTick(() => {
            this.$emit('input', Number(max))
          })
        } else {
          if (this.type === 'number') {
            this.$emit('input', Number(v))
          } else {
            this.$emit('input', v)
          }
        }
      }
    },
    isTextArea({ type }) {
      return type === 'textarea'
    }
  },
  methods: {
    checkLimits() {
      // отключено временно - после тестирования если все ок - удаляем все что с этим связано
      // this.checkLowestThenMin()
      // this.checkHighestThenMax()
    },
    checkLowestThenMin() {
      const { min, value } = this

      if (this.min &&  Number(value) < Number(min)) {
        this.$emit('input', undefined)
        this.$nextTick(() => {
          this.$emit('input', Number(min))
        })
      }
    },
    checkHighestThenMax() {
      const { max, value } = this

      if (this.max &&  Number(value) > Number(max)) {
        this.$emit('input', undefined)
        this.$nextTick(() => {
          this.$emit('input', Number(max))
        })
      }
    }
  },
}
</script>

<style scoped lang="scss">
  .base-input {
    .label {
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;

      color: var(--primary-font-color);

      .label-right, .label-left {
        padding-bottom: 5px;
      }
    }
    .input-wrapper {
      width: 100%;
      position: relative;

      input {
        width: 100%;

        min-height: 49px;
        line-height: 49px;
        padding: 0 20px 0 30px;

        background: var(--main-bg);
        border: 1px solid var(--main-bg);
        box-shadow: inset 0 4px 20px var(--main-bg);

        border-radius: 12px;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 16px;
        color: #8F949A;

        &:focus {
          outline: none !important;
          border: 1px solid #445DE1;
        }
      }
      textarea {
        width: 100%;

        line-height: 49px;
        padding: 20px 30px;

        background: var(--main-bg);
        border: 1px solid var(--main-bg);
        box-shadow: inset 0 4px 20px var(--main-bg);

        border-radius: 12px;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 16px;
        color: #8F949A;

        &:focus {
          outline: none !important;
          border: 1px solid #445DE1;
        }
      }

      .action {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 80px;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        background: var(--secondary-color);
        border: 1px solid #445DE1;
        border-radius: 12px;

        color: var(--new-front-input-max-btn-color);

        cursor: pointer;
      }

      ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 16px;

        color: #C9C9C9;
        opacity: 1; /* Firefox */

        @include below_phone() {
          font-size: 14px;
          line-height: 14px;
        }
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 16px;

        color: #C9C9C9;

        @include below_phone() {
          font-size: 14px;
          line-height: 14px;
        }
      }

      ::-ms-input-placeholder { /* Microsoft Edge */
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 16px;

        color: #C9C9C9;

        @include below_phone() {
          font-size: 14px;
          line-height: 14px;
        }
      }

      .bottom-label {
        position: absolute;
        top: 12px;
        right: 0;

        display: flex;

        width: fit-content;

        transform: translateY(100%);

        text-align: right;
        //.label-right {
        //  display: none;
        //}
      }
    }

    @include below_phone() {
      .label {
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;

        .label-right {
          display: none;
        }
      }
      .input-wrapper {
        input {
          font-size: 14px;
          line-height: 14px;
        }
      }
      //.sub-label {
      //  display: flex;
      //  align-items: center;
      //  justify-content: end;
      //
      //  padding-top: 5px;
      //
      //  .label-right {
      //    display: flex;
      //  }
      //}
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }
</style>