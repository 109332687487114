export const updateInvoice = ({state, commit}, {orderId, status, amount, received, expiresAt}) => {
    const orders = state.orders;

    const newOrders = orders.map(o => {
        o = Object.assign({}, o);

        if(o.id === orderId) {
            Object.assign(o, {status, amount, received, expiresAt});
        }

        return o;
    });

    commit('orders', newOrders);
};
