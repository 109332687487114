<template>
  <div class="balances-list-header list-header row">
    <div class="list-header__item col">
      Тип действия
    </div>
    <div class="list-header__item col">
      Сумма изменения
    </div>
    <div class="list-header__item col">
      Баланс
    </div>
    <div class="list-header__item col">
      Дата операции
    </div><div class="list-header__item col">
    Идентификатор ордера
  </div>
  </div>
</template>

<script>

export default {
    name: 'AccountsDataListHeader',
};

</script>
