<script>
  import {mapActions, mapGetters, mapState} from "vuex";
  import BaseButton from "@/public/components/common/uiKit/button/base/bg-secondary/index.vue";

  export default {
    name: "ModalTestnet",
    components: {
      BaseButton,
      StoreModal: () => import(/* webpackChunkName: "/public/components/modals/StoreModal" */ '/public/components/modals/StoreModal'),
    },
    data () {
      return {
        isApollo: import.meta.env.VITE_PRODUCT_NAME === 'Apollopayment'
      }
    },
    computed: {
      ...mapGetters('app', [
        'sortedCurrencies',
        'isOpenModal',
      ]),
      ...mapState('app', {
        modalPayload: state => state.modal.payload,
      }),
      isOpen() {

        const {
          $options: {
            name,
          },
          isOpenModal,
        } = this;

        return isOpenModal(name);
      },
    },
    methods: {
      ...mapActions('app', [
        'closeModal',
      ]),
      close() {
        this.closeModal()
      },
      goToTestnet () {
        this.closeModal()
        this.$emit('goToTestnet')
      }
    },
  }
</script>

<template>
<StoreModal
    class="new-order-modal"
    :value="isOpen"
    :show-close="true"
    name="ModalTestnet"
    @closeModal="close"
>
  <template #body>
    <div class="content">
      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_21100_50277)">
          <rect width="60" height="60" rx="10" fill="var(--bg-background-table)"/>
          <path d="M34.4531 18.4121V22.6879H38.7286L34.4531 18.4121Z" fill="#744DB2"/>
          <path d="M33.75 24.0938C33.3617 24.0938 33.0469 23.7789 33.0469 23.3906V18H22.9688C21.8056 18 20.8594 18.9463 20.8594 20.1094V39.8906C20.8594 41.0537 21.8056 42 22.9688 42H37.0312C38.1944 42 39.1406 41.0537 39.1406 39.8906V24.0938H33.75ZM27.2589 34.2374C27.5075 34.5357 27.4672 34.9791 27.1688 35.2276C27.0427 35.3331 26.8835 35.3908 26.7191 35.3906C26.6159 35.3908 26.514 35.3682 26.4206 35.3245C26.3272 35.2807 26.2445 35.2169 26.1786 35.1376L23.8348 32.3251C23.7295 32.1988 23.6718 32.0395 23.6718 31.875C23.6718 31.7105 23.7295 31.5512 23.8348 31.4249L26.1786 28.6124C26.4272 28.314 26.8705 28.2738 27.1688 28.5224C27.4672 28.771 27.5075 29.2143 27.2589 29.5126L25.2902 31.875L27.2589 34.2374ZM31.6045 29.2849L29.7295 34.9099C29.6313 35.2045 29.357 35.3908 29.0625 35.3908C28.9889 35.3908 28.9139 35.3791 28.8401 35.3546C28.4717 35.2318 28.2727 34.8336 28.3954 34.4652L30.2704 28.8402C30.3932 28.4718 30.7914 28.2727 31.1598 28.3955C31.5283 28.5183 31.7273 28.9164 31.6045 29.2849ZM36.1651 32.3251L33.8214 35.1376C33.7554 35.2169 33.6728 35.2807 33.5794 35.3245C33.4859 35.3682 33.384 35.3908 33.2809 35.3906C33.1165 35.3908 32.9573 35.3331 32.8311 35.2276C32.5328 34.979 32.4924 34.5357 32.7411 34.2374L34.7098 31.875L32.7411 29.5126C32.4925 29.2143 32.5328 28.7709 32.8312 28.5224C33.1293 28.2738 33.5728 28.314 33.8214 28.6124L36.1652 31.4249C36.2705 31.5512 36.3282 31.7105 36.3281 31.875C36.3281 32.0395 36.2705 32.1988 36.1651 32.3251Z" fill="#744DB2"/>
        </g>
        <defs>
          <clipPath id="clip0_21100_50277">
            <rect width="60" height="60" fill="white"/>
          </clipPath>
        </defs>
      </svg>

      <div class="title">{{ modalPayload.success ? $t('testnet.modal-testnet-create-title') : $t('testnet.modal-testnet-error-title') }}</div>
      <div class="subtitle">{{ modalPayload.success ? $t('testnet.modal-testnet-create-desc') : $t('testnet.modal-testnet-error-desc') }}</div>
      <div :class="['actions', {'one-column': modalPayload.success}]">
        <template v-if="!modalPayload.success">
          <base-button type="secondary" :label="$t('testnet.close')" @click="close"></base-button>
          <a :href="isApollo ? 'https://t.me/apollopayment' : 'https://t.me/onchainpay'" target="_blank">
            <base-button type="primary" :label="$t('testnet.support')"></base-button>
          </a>
        </template>
        <template v-else>
          <base-button type="primary" :label="$t('testnet.go-to-testnet')" @click="goToTestnet"></base-button>
        </template>
      </div>
    </div>
  </template>
</StoreModal>
</template>

<style lang="scss" scoped>
.subtitle {
  color: var(--text-color-text-secondary, #8B81A0);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 10px;
}
.content {
  font-family: $Gilroy;
  padding: 0 $p30;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 450px) {
    padding: 0 $p10;
  }
}
.title {
  color: var(--text-text-primary, #000);
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.38px;
  margin-top: 20px;
}
.actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 30px;
  width: 100%;
}
.one-column {
  grid-template-columns: 1fr !important;
}
</style>
