import BigNumber from "bignumber.js";

export const mutations = {
    accountBalances: (state, balances) => {
        state.balances = balances;
    },
    accountBalancesGrouped: (state, balances) => {
        state.balancesGrouped = balances;
    },
    accountBalancesGroupedPayin: (state, balances) => {
        state.balancesGroupedPayin = balances;
    },
    accountBalancesBusiness: (state, balances) => {
        state.balancesBusiness = balances;
    },
    accountBalancesGroupedBusiness: (state, balances) => {
        state.balancesGroupedBusiness = balances;
    },
    rollingReserve: (state, rr) => {
        state.rollingReserve = rr;
    },
    setLastUpdateAddress(state, address) {
        state.lastUpdateAddress = address
    },
    setNewAddressBalance (state, { balance, addressId, networkId, currencyId, type }) {
        if (!state.balancesGrouped.currencyGroups.length && type === "PAY_IN") return
        // if (!state.balancesGroupedBusiness.currencyGroups.length && type === "BUSINESS") return

        let address = null

        if (type !== "BUSINESS") {
            const currencyGroup = state.balancesGrouped.currencyGroups.find(e => e.currencyId === currencyId)
            const networkGroup = currencyGroup.networkGroups.find(e => e.networkId === networkId)

            address = networkGroup.addresses.find(e => e.id === addressId)

            const diff = new BigNumber(balance).minus(address.balance)

            networkGroup.balance = new BigNumber.sum(networkGroup.balance, diff).toString()
            currencyGroup.balance = new BigNumber.sum(currencyGroup.balance, diff).toString()
        } else {
            address = state.balancesBusiness.find(({ id }) => id === addressId)
        }

        if (!address) return

        address.balance = balance
    },
    clearAllStates: (state) => {
        state.balances = [],
        state.rollingReserve = [],
        state.accountBalances = [],
        state.price = {},
        state.balancesGrouped = {
            currencyGroups: [{
                currencyId: '',
                networkGroups: [{
                    networkId: '',
                    addresses: [{
                        accountId: '',
                        address: '',
                        balance: '',
                        currencyId: '',
                        id: '',
                        networkId: '',
                        tag: '',
                    }],
                }],
            }],
        },
        state.balancesGroupedPayin = {
            currencyGroups: [{
                currencyId: '',
                networkGroups: [{
                    networkId: '',
                    addresses: [{
                        accountId: '',
                        address: '',
                        balance: '',
                        currencyId: '',
                        id: '',
                        networkId: '',
                        tag: '',
                    }],
                }],
            }],
        },
        state.balancesGroupedBusiness = {
            currencyGroups: [{
                currencyId: '',
                networkGroups: [{
                    networkId: '',
                    addresses: [{
                        accountId: '',
                        address: '',
                        balance: '',
                        currencyId: '',
                        id: '',
                        networkId: '',
                        tag: '',
                    }],
                }],
            }],
        };
    },

    setUpdatesSub(state, sub) {
        if(sub) {
            state.updatesSub = sub;
        } else {
            if(state.updatesSub && state.updatesSub.unsubscribe) {
                state.updatesSub.unsubscribe();

                state.updatesSub = undefined;
            }
        }
    },
};
