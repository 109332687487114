import {v4 as UUID} from 'uuid';
import getFeeForTransfer from "../../../../graphQl/mutations/getFeeForTransfer";

export const fetchFeeForTransfer = async ({state, commit, dispatch, rootGetters}, payload) => {
    if (state.fee.pending) {
        return;
    }

    const id = UUID();

    commit('setFeeRequestId', id);
    commit('setFeePending', true);

    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    try {
        const withdrawalRequestData = await dispatch('app/request', {
            type: 'mutation',
            gql: getFeeForTransfer,
            variables: {
                organizationId,
                accountId: payload.accountId,
                addressId: payload.addressId,
                amount: payload.amount,
            },
        }, {root: true});

        if (id !== state.feeRequestId) {
            return;
        }

        commit('setFeePending', false);

        if (withdrawalRequestData.errors) {
            commit('setFeeError', withdrawalRequestData.errors[0]);
        } else {
            const {data: {requestFeeForTransfer: {code, result}}} = withdrawalRequestData;

            commit('setFee', {
                code,
                currency: result.currencyId,
                amount: result.amount,
                usdAmount: result.usdAmount,
                until: result.until,
                source: result.source,
                token: result.token,
                tariffFee: result.tariffFee,
                tariffFeeUSD: result.tariffFeeUSD,
                networkFee: result.networkFee,
                networkFeeUSD: result.networkFeeUSD,
            });
        }

        commit('setFeeComplete', true);
    } catch (error) {
        if (id !== state.feeRequestId) {
            return;
        }

        commit('setFeePending', false);
        commit('setFeeComplete', true);

        return error;
    }
};
