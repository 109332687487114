import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!, $widgetId: String!) {
         IFrameWidget(organizationId: $organizationId, widgetId: $widgetId) {
            id
            organizationId
            alias
            theme
            accentColor
            apiKeyId
            link
            siteURL
            showStartPage
            payinCrypto
            payoutCrypto
            payinCryptoType
            payinCryptoInvoiceFree
            payinCryptoInvoiceInsurancePercent
            payinCryptoInvoiceSlippagePercent
            payinCryptoInvoiceTolerancePercent
            payinCryptoInvoiceCommissionOnPayer
            payinCryptoInvoicePayerEmailAllow
            payinCryptoInvoicePayerEmailRequired
            payinCryptoInvoiceCurrencies {
                currency
                currencyId
                currencyAlias
                isFiat
                available
            }
            payinCryptoCurrenciesTo {
                    currency
                    currencyId
                    currencyAlias
                    network
                    networkId
                    networkName
                    contract
                    available
            }
            payoutCryptoCurrenciesTo {
                    currency
                    currencyId
                    currencyAlias
                    network
                    networkId
                    networkName
                    contract
                    available
            }
            payoutCryptoCurrenciesFrom {
                currency
                currencyId
                currencyAlias
                isFiat
            }       
            payinCryptoWebhookURL
            payoutCryptoWebhookURL
            payoutCryptoApprovalWebhookURL
            createdAt
            updatedAt
        }
    }
`;
