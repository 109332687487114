import gql from 'graphql-tag';

export default gql`
query {
  getBankAccountValidationData {
    regexp
    countries {
      code
      country
      len
      sample
    }
  }
}
`;
