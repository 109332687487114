import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!, $id: String!) {
        getAddressTransaction(organizationId: $organizationId, id: $id) {
            id
            userId
            status
            currencyId
            networkId
            addressType
            addressFrom
            addressFromId
            addressTo
            addressToId
            type
            amount
            txId
            alias
            comment
            createdAt
        }
    }
`;
