import getOrphan from "/public/graphQl/queries/getOrphanDeposit";

export const getOrphanDeposit = async ({dispatch, commit, rootGetters}, id) => {
    let orphanData;
    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    const variables = {
        id,
        organizationId
    }
    commit('setInfoState', true);
    try {
        commit('setFetching', true);
        orphanData = await dispatch('app/request', {
            type: 'query',
            gql: getOrphan,
            variables
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('setFetching', false);
    }

    if (orphanData.message) {
        throw orphanData.message;
    }

    if (orphanData.errors) {
        throw orphanData.errors;
    }

    const {
        data: {orphanDeposit},
    } = orphanData;
    commit('setOrphan', orphanDeposit)
    return orphanDeposit;
};
