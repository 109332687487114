<template>
<BaseModal
    class="two-fa-restriction"
    width="100%"
    :value="isOpen"
    :show-close="true"
    :show-head="true"
    :show-footer="true"
    @on-confirm="handleEnableTwoFactor"
    @on-cancel="handleCancel"
>
  <template #header>
    <!--  полностью переписываем слот заголовка модалки-->
    <div class="two-fa-restriction__header">
      {{ title }}
    </div>
  </template>
  <template>
    <p class="two-fa-restriction__description">{{ $t('two-fa-restrictions.description') }}</p>
  </template>
  <template #footer>
    <base-button
      type="secondary"
      class="button"
      style="width: 100% !important;"
      :label="$t('two-fa-restrictions.cancel')"
      @click="handleCancel"
    />
    <base-button
      type="primary"
      class="button"
      style="width: 100% !important;"
      :label="$t('two-fa-restrictions.enableTwoFA')"
      @click="handleEnableTwoFactor"
    />
  </template>
</BaseModal>
</template>

<script>
  import {mapActions, mapGetters, mapState} from "vuex";
  import BaseModal from "../BaseModal";

  export default {
    name: "TwoFaRestriction",
    components: {
      BaseModal,
      BaseButton: () => import(/* webpackChunkName: "@/public/components/common/uiKit/button/base/index.vue" */ '@/public/components/common/uiKit/button/base/index.vue')
    },
    props: {
      pid: {
        type: Number,
        default: undefined,
      }
    },
    methods: {
      ...mapActions('app', [
        'closeModal',
        'openModal'
      ]),
      handleCancel () {
        this.closeModal()
      },
      handleEnableTwoFactor () {
        this.closeModal()
          if (!this.userTwoFactorAuth) {
            const { pid } = this
            this.openModal({
              name: pid ? `QRCodeModal_${pid}` : `QRCodeModal`,
            });
          }
      }
    },
    computed: {
      ...mapGetters('app', [
        'isOpenModal',
      ]),
      ...mapState('user', {
        userOtpEnabled: state => state.user.otpToken,
      }),
      userTwoFactorAuth() {
        return !!this.userOtpEnabled;
      },
      title () {
        return this.$t('two-fa-restrictions.title')
      },
      isOpen() {
        const {
          $options: {name},
          isOpenModal,
        } = this;

        return isOpenModal(name);
      },
    }
  }
</script>

<style lang="scss">
.two-fa-restriction {
  font-family: var(--new-front-font-family);

  .at-modal {
    background: var(--new-front-modal-bg)!important;
  }
  .at-modal__header {
    background: transparent!important;
    border: 0!important;
    min-height: 100px!important;

    .at-modal__title {
      width: 100%;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    font-family: $Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: $h1;
    color: var(--new-front-primary-font-color);
  }

  .at-modal__body {
    padding-top: 0!important;
  }
  &__description {
    text-align: center;
    font-size: $h4!important;
    color: var(--new-front-text-secondary-bg-modal-primary);
  }
}
</style>
