import getAddressTransactionsQuery from "/public/graphQl/queries/getAddressTransactions.js";
import {getRouteQuery} from "../../../../utils/getRouteQuery";
import {getNetworksIdsByNames} from "../../../../utils/getNetworksIdsByNames";
import {getFormatForUrlByArray} from "../../../../utils/getFormatForUrlByArray";
import {getCurrenciesIdsByNames} from "../../../../utils/getCurrenciesIdsByNames";

export const addressTransactions = async ({state, rootState, commit, dispatch, rootGetters}, payload) => {
    const {pagesCount} = state;

    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const {
        route,
        router,
        showUrlFilters = true,
        excludeParams = [],
        page,
        sortDate,
        type,
        currencyId,
        date,
        networks,
        addressId,
        addressType,
        search,
        status,
        riskLevel,
    } = payload;

    const currentPage = page
    const currentSortDate = sortDate
    const typeAddress = addressType
    const currentDate = date
    const currentNetworks = networks
    const currentSearch = search
    const allNetworks = rootState.app.networks;

    const variables = {
        limit: pagesCount,
        offset: currentPage,
        sortDate: currentSortDate,
        addressType: typeAddress,
        currencyId,
        networkId: getNetworksIdsByNames(currentNetworks, allNetworks),
        date: currentDate,
        addressId,
        type: type,
        organizationId,
        search: currentSearch,
        status,
        riskLevel
    };

    let transactionsData

    try {
        commit('app/toggleLoading', null, {root: true});
        transactionsData = await dispatch('app/request', {
            type: 'query',
            gql: getAddressTransactionsQuery,
            variables,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (transactionsData.message) {
        throw transactionsData.message;
    }

    if (transactionsData.errors) {
        throw transactionsData.errors;
    }

    const {
        data: {
            getAddressTransactions: {
                totalCount,
                transactions,
                address,
            },
        },
    } = transactionsData;

    commit('count', totalCount);
    commit('setListAddress', transactions);
    commit('setType', payload.type);
    commit('currentAddress', address);

    return true;
};
