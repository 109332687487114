export const mutations = {
    testPublicKey: (state, key) => {
        state.testPublicKey = key;
    },
    testSecretKey: (state, key) => {
        state.testSecretKey = key;
    },
    apiKeys: (state, keys) => {
        state.apiKeys = keys;
    },
    apiKeysPlaceholder: (state, data) =>  {
      state.apiKeysPlaceholder = data
    },
    changeLangMutations: (state, lang) => {
        state.lang = lang;
    },
    changeUser: (state, getUser) => {
        state.user = getUser;
    },
    changeToken: (state, getToken) => {
        state.token = getToken;
    },
    changeReserveCodes: (state, createReserveCodes) => {
        state.reserveCodes = createReserveCodes;
    },
    setCreatedApiKeys: (state, data) => {
        state.createdApiKeys = data
    },
    clearAllStates (state) {
        state.testPublicKey = '';
        state.testSecretKey = '';
        state.apiKeys = [];
        state.user = {
            id: undefined,
            email: undefined,
            createdAt: undefined,
            otpToken: undefined,
        };
        state.token = {
            secret: undefined,
            token: undefined,
            otpauth: undefined,
        };
        state.reserveCodes = {
            code: undefined,
            result: undefined,
        };
    },
};
