<template>
  <div class="invoices-sort-by-status">
    <p class="invoices-sort-by-status__title">
      {{ $t('invoice.status') }}
    </p>
    <div class="invoices-sort-by-status__select-wrapper">
      <AtSelect
          class="invoices-sort-by-status__select"
          :placeholder="select_the_status"
          clearable
          :value="isActiveStatus"
          @on-change="selectStatus"
      >
        <AtOption
            v-for="(status, key) in $options.statuses"
            :key="key"
            :value="status.value"
            :label="$t(status.title)"
        />
      </AtSelect>
      <p class="invoices-sort-by-status__status">{{ selectedStatus }}</p>
    </div>

  </div>
</template>

<script>

import {mapState, mapMutations, mapActions} from 'vuex';

import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';
import {INVOICE_STATUS_ENUM} from "../../../../common/constants/invoices-status-enum";

export default {
    name: 'BillsSortByStatus',
    statuses: [
        {
            value: INVOICE_STATUS_ENUM.CREATED,
            title: 'invoice.active',
        },
        {
            value: INVOICE_STATUS_ENUM.PENDING,
            title: 'invoice.in_processing',
        },
        {
            value: INVOICE_STATUS_ENUM.PARTIAL,
            title: 'invoice.partial_payment',
        },
        {
            value: INVOICE_STATUS_ENUM.PROCESSED,
            title: 'invoice.completed',
        },
        {
            value: INVOICE_STATUS_ENUM.EXPIRED,
            title: 'invoice.expired',
        },
        {
            value: INVOICE_STATUS_ENUM.ERROR,
            title: 'invoice.with_errors',
        },
    ],
    components: {
        AtSelect,
        AtOption,
    },
    computed: {
        ...mapState('invoices', {
            isActiveStatus: state => state.filters.status[0],
        }),
        select_the_status() {
            return this.$t('invoice.select_the_status');
        },
        selectedStatus() {
            const status = this.$options.statuses.find((status) => {
                return status.value === this.isActiveStatus;
            });
            return this.$t(status?.title);
        },
    },
    beforeMount() {
        this.clearFilters();
        this.setStatusFromUrl();
    },
    methods: {
        ...mapMutations('invoices', [
            'filtersStatus',
            'clearFilters',
        ]),
        ...mapActions('invoices', [
            'fetchInvoices',
        ]),
        setStatusFromUrl() {
            const {
                $route: {query},
                filtersStatus,
            } = this;
            const statusFromUrl = query.status;

            if (!statusFromUrl) {
                return false;
            }

            filtersStatus(statusFromUrl.split(','));
        },
        selectStatus(values) {
            const {
                $route,
                $router,
                filtersStatus,
                fetchInvoices,
            } = this;
            const val = values ? [values] : [];
            filtersStatus(val);
            fetchInvoices({
                route: $route,
                router: $router,
                excludeParams: ['page'],
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.invoices-sort-by-status {
  width: 100%;
  &__title {
    margin-bottom: 8px;
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__select ::v-deep .at-select__selection {
    min-height: 28px;
    border: 1px solid var(--border-color);
    background: var(--secondary-color);
    color: var(--primary-font-color);
  }

  &::v-deep .at-select__dropdown .at-select__option {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);

    &:hover {
      background: var(--hover-color-select);
    }
  }

  &::v-deep .at-select__dropdown {
    border: 1px solid var(--border-color);
    background: var(--bg-select);
  }

  ::v-deep .at-select__option--selected {
    background: var(--hover-color-select);
  }

    ::v-deep .at-select__placeholder {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
    }

  ::v-deep .at-select__selected {
    display: none;
  }

  &__select-wrapper {
    position: relative;
  }

  &__status {
    position: absolute;
    top: 12px;
    left: 30px;
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }


}


</style>
