<script>

  export default {
    name: "DashboardTabs",
    data () {
      return {
        activeTab: 'currency',
      }
    },
    methods: {
      input (value) {
        this.activeTab = value
        this.$emit('input', value)
      }
    }
  }
</script>

<template>
<div class="tabs">
  <div :class="['tabs__tab', { active: activeTab === 'currency' }]" @click="input('currency')">{{ $t('payments.currency') }}</div>
  <div :class="['tabs__tab', { active: activeTab === 'network' }]" @click="input('network')">{{ $t('payments.network') }}</div>
</div>
</template>

<style scoped lang="scss">
.tabs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  &__tab {
    width: 100%;
    max-width: 242px;
    padding: 12px 10px;
    color: var(--text-color-text-secondary);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    &.active {
      color: var(--text-text-primary, #000);
      background: var(--bg-background-table, #F4F0FE);
    }
  }
}
</style>
