<template>
  <div class="order-list-header">
    <div class=" order-list-header__item ">
      id
    </div>
    <div
        class=" order-list-header__item order-list-header__item--sorting list-header__item--sorting"
        @click="sortByDate"
    >
      {{ $t('orders.date_of_creation') }}
    </div>
    <div class=" order-list-header__item ">
      {{ $t('invoice.account_number') }}
    </div>
    <div class=" order-list-header__item ">
      {{ $t('invoice.invoice_currency') }}
    </div>
    <div class=" order-list-header__item ">
      {{ $t('invoice.account_amount') }}
    </div>
    <div class=" order-list-header__item ">
      {{ $t('invoice.payment_currency') }}
    </div>
    <div class=" order-list-header__item ">
      {{ $t('invoice.order') }}
    </div>
    <div class=" order-list-header__item ">
      {{ $t('invoice.payment_amount') }}
    </div>
    <div class=" order-list-header__item ">
      {{ $t('invoice.status') }}
    </div>
  </div>
</template>

<script>

import {mapActions} from 'vuex';

import Icon from "/public/components/elements/Icon";

export default {
    name: 'InvoicesListHeader',
    components: {
        Icon,
    },
    data() {
        return {
            sortByDateStatus: 'DESC',
        };
    },
    beforeMount() {
        this.setSortByDateFromUrl();
    },
    methods: {
        ...mapActions('orders', [
            'fetchOrders',
        ]),
        setSortByDateFromUrl() {
            const sortDateFromUrl = this.$route.query.sortDate;

            if (!sortDateFromUrl) {
                return false;
            }

            this.sortByDateStatus = sortDateFromUrl;
        },
        sortByDate() {
            const {
                $route,
                $router,
                sortByDateStatus,
                fetchOrders,
            } = this;

            if (sortByDateStatus === 'DESC') {
                this.sortByDateStatus = 'ASC';
            } else {
                this.sortByDateStatus = 'DESC';
            }

            fetchOrders({
                route: $route,
                router: $router,
                sortDate: this.sortByDateStatus,
            });
        },
    },
};

</script>
<style lang="scss" scoped>
.order-list-header {
  background: var(--primary-color);
  border-bottom: var(--border-table);
  border-top: var(--border-table);
  display: grid;
  grid-template-columns: repeat(14, 20%);
  //gap: 10px;
  min-height: 46px;
  align-items: center;
  padding-left: 40px;
  //padding-right: 40px;

  &__item {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: var(--font-size-text-secondary);
    line-height: var(--line-height);
    min-height: 46px;
    background-color: var(--primary-color);
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    text-align: start;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--primary-font-color);
  }
}

@media screen and (max-width: 1300px) {
  .order-list-header {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 950px) {
  .order-list-header {
    display: none;
  }

}
</style>
