import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!, $limit: Float!, $offset: Float!, $id: String, $sortDate: String, $status: [String!], $currencies: [String!], $networks: [String!], $date: [String!]) {
        getOrders(organizationId: $organizationId, limit: $limit, offset: $offset, sortDate: $sortDate, status: $status, currencies: $currencies, networks: $networks, date: $date, id: $id) {
            orders {
                id
                currency
                amount
                createdAt
                status
                order
                transactions {
                    id
                    orderId
                }
                networkId
                received
                accountId

            }
            totalCount
        }
    }
`;
