import gql from 'graphql-tag';

export default gql`
query ($organizationId: String!, $currencyId: String, $networkId: String) {
  getCollectingSettings (
    organizationId: $organizationId,
    currencyId: $currencyId,
    networkId: $networkId
  ) {
    id
    alias
    currencyId
    networkId
    type
    amount
    amountCollected
    addressTo
  }
}
`;
