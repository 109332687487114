<template>
  <div class="transactions-sort-by-type">
    <p class="transactions-sort-by-type__title">
      {{ $t('transactions.transaction_type') }}
    </p>
    <div class="transactions-sort-by-type__select-wrapper">
      <AtSelect
          class="transactions-sort-by-type__select"
          :placeholder="select_the_transaction_type"
          clearable
          v-model="isActiveType"
          @on-change="selectType"
      >
        <AtOption
            v-for="(status, key) in $options.transactionHistoryType"
            :key="key"
            :value="status.value"
        >
          {{ $t(status.title) }}
        </AtOption>
      </AtSelect>
      <p class="transactions-sort-by-type__status">{{ selectedStatus }}</p></div>
  </div>
</template>

<script>

import {mapActions} from 'vuex';
import {TRANSACTION_HISTORY_TYPE_ENUM} from '../../../../common/constants/transaction-history-type-enum';

import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';


export default {
    name: 'TransactionsSortByType',

    transactionHistoryType: [
    // {
    //     value: TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION,
    //     title: 'transactions.commission',
    // },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.ORDER_TRANSACTION,
            title: 'transactions.order_transaction',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.DEPOSIT,
            title: 'transactions.deposit',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.ADVANCED_BALANCE_REFUND,
            title: 'transactions.advanced_balance_refund',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.DEPOSIT_WALLET,
            title: 'transactions.deposit_wallet',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.DEPOSIT_RECURRENT,
            title: 'transactions.deposit_recurrent',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.DEPOSIT_PERSONAL,
            title: 'transactions.deposit_personal',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.DEPOSIT_PAYOUT_BALANCE,
            title: 'transactions.deposit_payout_balance',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.TRANSFER,
            title: 'transactions.transfer',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.WITHDRAWAL,
            title: 'transactions.withdrawal',
        },

        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_EXECUTE_ORDER,
            title: 'transactions.commission_execute_order',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_WALLET_DEPOSIT,
            title: 'transactions.commission_wallet_deposit',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_RECURRENT_DEPOSIT,
            title: 'transactions.commission_recurrent_deposit',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_PERSONAL_DEPOSIT,
            title: 'transactions.commission_personal_deposit',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_PAYOUT_DEPOSIT,
            title: 'transactions.commission_payout_deposit',
        },
        // {
        //     value: TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_CREATE_ORDER,
        //     title: 'transactions.commission_create_order',
        // },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_TRANSFER,
            title: 'transactions.commission-transfer',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_WALLET_WITHDRAWAL,
            title: 'transactions.commission_wallet_withdrawal',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_RECURRENT_WITHDRAWAL,
            title: 'transactions.commission_recurrent_withdrawal',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_PERSONAL_WITHDRAWAL,
            title: 'transactions.commission_personal_withdrawal',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.COMMISSION_PAYOUT_WITHDRAWAL,
            title: 'transactions.commission_payout_withdrawal',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.BRIDGE_INTERNAL,
            title: 'transactions.bridge_internal',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.BRIDGE_EXTERNAL,
            title: 'transactions.bridge_external',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.BRIDGE_INTERNAL_FEE,
            title: 'transactions.bridge_internal_fee',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.BRIDGE_EXTERNAL_FEE,
            title: 'transactions.bridge_external_fee',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.KYT_TRANSACTION_FEE,
            title: 'advance_balances.kyt-transaction-fee',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.KYT_WITHDRAWAL_ADDRESS_FEE,
            title: 'advance_balances.kyt-withdrawal-address-fee',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.EXCHANGE_AUTO,
            title: 'advance_balances.exchange-auto',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.EXCHANGE_AUTO_FEE,
            title: 'advance_balances.exchange-auto-fee',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.ORPHAN_DEPOSIT_WITHDRAWAL_FEE,
            title: 'advance_balances.orphan-deposit-withdrawal-fee',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.ORPHAN_DEPOSIT_WITHDRAWAL,
            title: 'advance_balances.orphan-deposit-withdrawal',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.PAYOUT_AUTO_SWAP,
            title: 'advance_balances.payout_auto_swap',
        },
        {
            value: TRANSACTION_HISTORY_TYPE_ENUM.PAYOUT_AUTO_SWAP_FEE,
            title: 'advance_balances.payout_auto_swap_fee',
        }
    ],

    components: {
        AtSelect,
        AtOption,
    },
    data() {
        return {
            isActiveType: '',
        };
    },
    computed: {
        select_the_transaction_type() {
            return this.$t('transactions.select_the_transaction_type');
        },
        selectedStatus() {
            const status = this.$options.transactionHistoryType.find(item => item.value === this.isActiveType);
            return this.$t(status?.title);
        },
    },
    beforeMount() {
        this.setTypeFromUrl();
    },
    methods: {
        ...mapActions('transactions', [
            'fetchTransactions',
        ]),
        setTypeFromUrl() {
            const typeFromUrl = this.$route.query.type;

            if (!typeFromUrl) {
                return false;
            }

            return this.isActiveType = typeFromUrl.split(',')[0];
        },
        selectType(value) {
            const {
                $route,
                $router,
                fetchTransactions,
            } = this;
            const val = value ? [value] : [];
            fetchTransactions({
                route: $route,
                router: $router,
                type: val,
                excludeParams: ['page'],
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.transactions-sort-by-type {
  &__title {
    margin-bottom: 8px;
    font-size: var(--font-size-text-main);
    font-family: var(--font-family);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__select ::v-deep .at-select {
    background: #FFFFFF;
    box-sizing: border-box;


  }

  ::v-deep .at-select__arrow {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  ::v-deep .at-select__clear {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  &__select::v-deep .at-select__selection {
    border: 1px solid var(--border-color);
    border-radius: 12px;
    min-height: 46px;
    display: flex;
    padding-left: 30px;
    align-items: center;
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    flex-wrap: wrap;
  }

  ::v-deep .at-select__dropdown .at-select__option {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);

    &:hover {
      background: var(--hover-color-select);
    }
  }

  ::v-deep .at-select__placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }

  ::v-deep .at-select__selection {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    border: 1px solid var(--border-color);
    background: var(--secondary-color);
  }

  &::v-deep .at-select__dropdown {
    border: 1px solid var(--border-color);
    background: var(--bg-select);
  }

  ::v-deep .at-select__option--selected {
    background: var(--hover-color-select);
  }

  ::v-deep .at-select__selected {
    display: none;
  }

  &__select-wrapper {
    position: relative;
  }

  &__status {
    position: absolute;
    top: 12px;
    left: 30px;
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

}


</style>
