<template>
  <component
      :is="canCopy ? 'div' : 'div'"
      :placement="placement"
      :content="canCopy ? string : false"
  >
    <div class="copy-string" :class="{ bold }">
      <a
          v-if="isLink"
          class="copy-string__string"
          :class="size"
          :href="string"
          target="_blank"
      >
        {{ preparedString }}
      </a>
      <p
          v-else
          class="copy-string__string"
          :class="size"
      >
        {{ preparedString }}
      </p>
      <div
          v-if="canCopy"
          class="icon-wrapper"
      >
        <svg
            v-if="showCopyIcon"
            @click.stop.self="copy"
            class="clipboard-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
          <path
              d="M0 2C0 0.89543 0.895431 0 2 0H10C11.1046 0 12 0.895431 12 2V4H14C15.1046 4 16 4.89543 16 6V14C16 15.1046 15.1046 16 14 16H6C4.89543 16 4 15.1046 4 14V12H2C0.895431 12 0 11.1046 0 10V2ZM2 1C1.44772 1 1 1.44772 1 2V10C1 10.5523 1.44772 11 2 11H10C10.5523 11 11 10.5523 11 10V2C11 1.44772 10.5523 1 10 1H2Z"
              :fill="isDarkTheme ? '#7E7E7E' : '#8B81A0'"
          />
        </svg>
        <svg
            v-else
            class="clipboard-icon"
            width="16"
            height="16"
            viewBox="8 8 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M23.5361 13.2812L15.74 20.7188L11.8486 17" stroke="#97C582" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </component>
</template>

<script>

import copyToClipboard from "/public/mixins/copyToClipboard";

import AtTooltip from 'at-ui/src/components/tooltip';
import Icon from "./Icon";
import { mapState } from "vuex";

export default {
    name: 'CopyString',
    components: {
        AtTooltip,
        Icon,
    },
    mixins: [ copyToClipboard ],
    props: {
        canCopy: Boolean,
        placement: {
            type: String,
            default: 'top',
        },
        string: {
            type: String,
            default: '',
            required: true,
        },
        isLink: {
            type: Boolean,
            default: false,
        },
        successMessage: {
            type: String,
            default: 'id скопировано',
        },
        failMessage: {
            type: String,
            default: 'id не скопировано',
        },
        hash: {
            type: Boolean,
            default: true,
        },
        all: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: 'normal'
        },
        bold: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapState('app', ['theme']),
        isDarkTheme () {
          const { theme } = this
          if (!theme) {
            let theme = localStorage.getItem('data-theme-private');

            return theme === 'dark';
          }
          return theme === 'dark'
        },
        preparedString() {
            // TODO для сокращения использовать getTruncatedString
            const { string = '', hash, all } = this;
            const stringLength = string.length;

            if (!stringLength) return ''

            if (hash && !all) {
                if (hash) {
                    return stringLength >= 16 ? `${string.substr(0, 3)}****${string.substr(-4)}` : string;
                } else {
                    return stringLength >= 16 ? `${string.substr(0, 13)}...` : string;
                }
            } else {
                return string;
            }
        },
        notification () {
            return this.$t('copy.notification');
        },
    },
    methods: {
        copy() {
            const {
                string,
                successMessage,
                failMessage,
                copyToClipboard,
                title,
            } = this;

            const defaultTitle = title ? title : this.notification;

            copyToClipboard(string, true, {
                title: defaultTitle,
                successMessage,
                failMessage,
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.copy-string {
  display: flex;
  align-items: center;
  gap: 4px;

  font-family: var(--new-front-font-family);

  font-weight: 400;

  &.bold {
    font-weight: 500;
  }

  &__string {
    padding-top: 1px;
    color: var(--new-front-text-primary-bg-secondary);

    &.normal {
      font-size: $h4;
      line-height: $h4;
    }
    &.small {
      font-size: $h6;
      line-height: $h6;
    }
  }

  &__copy {
    cursor: pointer;
    color: var(--new-front-text-primary-bg-secondary);

    &.normal {
      font-size: $h4;
      line-height: $h4;
    }
    &.small {
      font-size: $h6;
      line-height: $h6;
    }
  }

  .icon-wrapper {
    display: flex;
    align-items: center;

    width: 16px;
    height: 16px;

    cursor: pointer;
    margin-left: 10px;

    position: relative;

    .clipboard-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
