import getApiRulesMapQuery from "/public/graphQl/queries/getApiRulesMap";

export const fetchApiRulesMap = async ({commit, dispatch, rootGetters}) => {
    let apiKeysData;

    const isTestnet = rootGetters['app/getSelectedOrganization'].isTest
    const excludeTestnet = ['SWAPS', 'BRIDGE']

    try {
        commit('app/toggleLoading', null, {root: true});
        apiKeysData = await dispatch('app/request', {
            type: 'query',
            gql: getApiRulesMapQuery,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (apiKeysData.message) {
        throw apiKeysData.message;
    }

    if (apiKeysData.errors) {
        throw apiKeysData.errors;
    }

    const {
        data: {getApiKeysPlaceholder},
    } = apiKeysData;

    let filteredApiKeys = getApiKeysPlaceholder;

    if (isTestnet) {
        filteredApiKeys = getApiKeysPlaceholder.filter(({ section }) => !excludeTestnet.includes(section));
    }


    commit('apiKeysPlaceholder', filteredApiKeys);

    return true;
};
