<template>
  <store-modal
      class="create-new-api-keys-modal"
      name="InfoBusinessAddress"
  >
    <template v-if="!isLoading" #header>
      <div class="create-business-modal__header">
        <div class="title">
          {{ title }}
        </div>
        <!--            <div v-if="notCreated" class="subtitle">-->
        <!--                {{ subtitle }}-->
        <!--            </div>-->
      </div>
    </template>
    <template #body>
      <template v-if="!isLoading">
        <div class="qr-code-wrapper">
          <QrCode :string="addressInfo.address" size="medium" />
        </div>

        <div class="business-modal__assets create-business-modal__padding-wrapper">
          <div class="assets-row">
            <div class="assets-row__label currency_and_network">
              {{ $t('business-wallet.currency-and-select') }}
            </div>
            <div class="assets-row__value">
              <currency-network-icon
                :currency="getCurrencyNameById(addressInfo.currencyId)"
                :network="network?.name.toUpperCase()"
              />
              <div class="asset-currency">
                {{ getCurrencyNameById(addressInfo.currencyId) }} |
              </div>
              <div class="asset-network">
                {{ addressInfo.networkId | getNetworkName }}
              </div>
            </div>
          </div>
          <div class="assets-row">
            <div class="assets-row__label">
              {{ $t('business-wallet.address') }}
            </div>
            <div class="assets-row__value address">
              {{ addressInfo.address }} <copy :value="addressInfo.address" />
            </div>
          </div>
          <div v-if="hasAmount" class="assets-row">
            <div class="assets-row__label">
              {{ $t('business-wallet.amount') }}
            </div>
            <div class="assets-row__value amount">
              {{ amount }}
            </div>
          </div>
        </div>
        <div class="business-modal__inputs create-business-modal__padding-wrapper">
          <div class="business-modal__row">
            <div>
              <base-input
                  v-model="alias"
                  class="amount-input"
                  placeholder="0"
                  :max-length="16"
                  :label="$t('business-wallet.pseudo')"
              >
                <template #label-left>
                  {{ $t('business-wallet.add-modal.alias-placeholder') }}
                </template>
              </base-input>
            </div>
          </div>
          <div class="business-modal__row">
            <!--                        <div class="business-modal__label">-->
            <!--                            {{ $t('business-wallet.comment') }}-->
            <!--                        </div>-->
            <div>
              <base-textarea
                  v-model="comment"
                  :placeholder="$t('business-wallet.add-modal.comment-placeholder')"
                  :label="$t('business-wallet.comment')"
                  :max-length="255"
                  resize="none"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <LoadingSpinner :title="creating_an_api_keys" />
      </template>
    </template>

    <template v-if="!isLoading" #footer>
      <div class="create-business-modal__footer">
        <base-button
          :label="$t('business-wallet.add-modal.cancel')"
          type="secondary"
          @click="handleCancelCreate"
        />
        <base-button
          :label="createButtonTitle"
          type="primary"
          @click="handleUpdateRequest"
        />
      </div>
    </template>

  </store-modal>
</template>

<script>

import { mapGetters, mapActions, mapState } from 'vuex';

import copyToClipboard from "/public/mixins/copyToClipboard";

import AtModal from 'at-ui/src/components/modal';
import AtInput from 'at-ui/src/components/input';
import LoadingSpinner from "/public/components/elements/LoadingSpinner";
import AtButton from 'at-ui/src/components/button';
import AtAlert from 'at-ui/src/components/alert';
import BaseModal from "../BaseModal";
import updateBusinessAddress from "../../../graphQl/mutations/updateBusinessAddress";
import CopyString from "../../elements/CopyString";
import CurrencyImg from "../../elements/CurrencyImg";
import CurrencyNetworkIcon from "@/public/components/elements/business-walet/Select/CurrencyNetworkIcon.vue";
import BaseInput from "@/public/components/common/uiKit/input/base/index.vue";
import BaseTextarea from "@/public/components/common/uiKit/textarea/index.vue";
import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";

import Copy from "@/public/components/common/copy.vue";
import { prettyAmountWithHideZeros } from "/public/filters";

export default {
  name: 'InfoBusinessAddress',
  components: {
    BaseButton,
    BaseTextarea,
    BaseInput,
    CurrencyNetworkIcon,
    CurrencyImg,
    CopyString,
    BaseModal,
    AtModal,
    AtInput,
    LoadingSpinner,
    AtButton,
    AtAlert,
    StoreModal: () => import(/* webpackChunkName: "/public/components/modals/StoreModal" */ '/public/components/modals/StoreModal'),
    QrCode: () => import(/* webpackChunkName: "/public/components/common/uiKit/qr-code" */ '/public/components/common/uiKit/qr-code'),
    Copy
  },
  mixins: [copyToClipboard],
  data() {
    return {
      isLoading: false,
      alias: '',
      comment: '',
      amount: undefined,
      addressInfo: {
        address: '',
        networkId: '',
        currencyId: '',
        alias: '',
        comment: '',
      },
    };
  },
  watch: {
    isOpen(status) {
      if (this.modal.opened !== this.$options?.name || !status) {
        this.clearAllData()
        return
      }
      this.$nextTick(() => {
        if (this.modal.payload.businessAddress) {
          const { businessAddress } = this.modal.payload
          this.alias = businessAddress.alias
          this.comment = businessAddress.comment
          this.addressInfo = businessAddress

          if (!!businessAddress.balance) {
            this.amount = this.prettyAmountWithHideZeros(businessAddress.balance, 6)
          }
        }
      })
    }
  },
  computed: {
    ...mapGetters('app', [
      'isOpenModal',
    ]),
    ...mapState('app', [
      'networks',
      'currencies',
      'modal',
      'selectedOrganizationId',
    ]),
    ...mapState('accounts', ['accounts']),
    ...mapGetters('app', [
      'getCurrencyNameById',
    ]),
    hasAmount({ amount }) {
      return amount === 0 || !!amount
    },
    isOpen() {
      const {
        $options: {
          name,
        },
        isOpenModal,
      } = this;
      return isOpenModal(name);
    },
    title() {
      return this.$t('business-wallet.info-modal-title');
    },
    currenciesForDropdown () {
      return this.currencies.filter(({ allowPayout }) => allowPayout);
    },
    createButtonTitle () {
      return  this.$t('business-wallet.add-modal.confirm');
    },
    be_sure_to_keep() {
      return this.$t('business-wallet.add-modal.creating');
    },
    creating_an_api_keys() {
      return this.$t('business-wallet.add-modal.creating');
    },
    network () {
      return this.networks.find( ({id}) => this.addressInfo.networkId === id)
    }
  },
  methods: {
    ...mapActions('app', [
      'closeModal',
      'request',
    ]),
    ...mapActions('user', [
      'createApiKeys',
      'fetchApiKeys',
    ]),
    ...mapActions('balances', ['getAccountBalancesBusiness']),
    prettyAmountWithHideZeros,
    handleSelectNetwork(value) {
      this.networksValue = value;
      this.currencyValue = '';
    },
    handleSelectCurrency(value) {
      this.currencyValue = value;
    },
    clearAllData() {
      this.isLoading = false;
      this.alias = '';
      this. comment = '';
    },
    handleUpdateRequest: async function () {
      this.isLoading = true;
      const {
        networksValue,
        currencyValue,
        alias,
        comment,
        addressInfo,
      } = this;
      const variables = {
        organizationId: this.selectedOrganizationId,
        addressId: addressInfo.id,
        accountId: this.accounts[0].accountId,
        currencyId: currencyValue,
        networkId: networksValue,
        comment,
        alias,
      };
      const {data: {updateBusinessAddress: res}} = await this.request({
        type: 'mutation',
        gql: updateBusinessAddress,
        variables,
      });
      if (res.code === 0) {
        await this.getAccountBalancesBusiness({  organizationId: this.selectedOrganizationId, accountId: this.accounts[0].accountId, includeArchived: true });
        this.closeModal();
        this.$Message.success({
          message: this.$t('business-wallet.success_update'),
          duration: 10000,
        });
        this.$nextTick(() => {
          this.clearAllData();
        })
      }
      this.isLoading = false;
    },
    handleCancelCreate() {
      const {
        clearAllData,
        closeModal,
        fetchApiKeys,
        hasApiKeys,
      } = this;
      closeModal();
      if (hasApiKeys) {
        fetchApiKeys();
      }
      setTimeout(() => {
        clearAllData();
      }, 500);
    },
  },
};

</script>

<style lang="scss" scoped>

.button {
  ::v-deep .at-btn__text {

    font-family: var(--new-front-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

  }
}
.create-business-modal {
  font-family: $Gilroy;
  &__padding-wrapper {
    padding: 0 $p30;
  }
  &__header {
    text-align: center;

    .title {
      font-size: $h1;
      font-weight: 500;
      color: var(--new-front-primary-font-color);
    }
    .subtitle {
      font-size: $h4;
      color: var(--secondary-font-color);
    }
  }
  &__body {
    padding: 0 0 $p30 0;
  }
  &__footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding: 0 10px;
  }
  &::v-deep {
    .list {
      max-height: 290px !important;
    }
  }
}

.create-new-api-keys-modal {
  font-family: $Gilroy;

  .qr-code-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    padding-bottom: 20px;
  }

  ::v-deep .at-select {
    background: var(--secondary-color);
    box-sizing: border-box;
  }

  ::v-deep .at-select__arrow {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  ::v-deep .at-select__clear {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  ::v-deep .at-select__selection {
    border-radius: 12px;
    min-height: 46px;
    display: flex;
    padding-left: 30px;
    align-items: center;
    box-shadow: inset 0 4px 20px rgba(0, 0, 0, 0.03);
    flex-wrap: wrap;
    border: 1px solid var(--border-color);
    background: var(--secondary-color);
    color: var(--primary-font-color);
  }

  &::v-deep .at-select__dropdown {
    border: 1px solid var(--border-color);
    background: var(--bg-select);
  }

  ::v-deep .at-select__option--selected {
    background: var(--hover-color-select);
  }

  ::v-deep .at-select__dropdown .at-select__option {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);

    &:hover {
      background: var(--hover-color-select);
    }
  }

  ::v-deep .at-select__placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }

  ::v-deep .at-select__selection {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    padding-left: 30px;
  }

  ::v-deep .at-modal {
    max-width: 764px;
    border-radius: 20px;
  }

  ::v-deep .at-input__original {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);

    &::placeholder {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
    }
  }

  ::v-deep .at-modal__body {
    padding: 30px 50px;
    @media (max-width: 950px) {
      padding: 20px 10px;
    }
  }

  ::v-deep .at-modal__header {
    display: flex;
    align-items: center;
    min-height: 107px;
    border-radius: 20px 20px 0 0;
    background: var(--modal-header-bg);
    border-bottom: 1px solid var(--border-color);
  }

  ::v-deep .at-modal__header p, .at-modal__header .at-modal__title {
    font-family: var(--new-front-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-h2);
    line-height: var(--line-height);
    max-width: 100%;
  }

  ::v-deep .col {
    width: 100%;

    font-family: var(--new-front-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--primary-font-color);
  }

  ::v-deep .at-input__original {
    background: var(--secondary-color);
    border: 1px solid var(--border-color);
    box-sizing: border-box;
    box-shadow: inset 0 4px 20px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
  }

  ::v-deep .at-modal__footer {
    border: none;
    min-height: 106px;
    display: flex;
    //justify-content: space-between;
    gap: 15px;
    @media (max-width: 950px) {
      flex-direction: column-reverse;
    }
  }

  ::v-deep .at-btn {
    width: 100%;
    height: 46px;
    max-width: 315px;
    border-radius: 12px;
    @media (max-width: 950px) {
      max-width: 100%;
    }
  }

  ::v-deep .at-btn.create-new-api-keys-modal__api-keys-copy-value {
    width: 24px;
    height: 24px;
    border: none;
  }

  ::v-deep .at-btn__icon.icon.icon-copy {
    font-size: 20px;
    color: var(--primary-font-color);
    line-height: 0;
  }

  ::v-deep .at-btn--primary {
    background: #0059F7;
  }

  ::v-deep .at-btn--default {
    border: 1px solid var(--border-color-modal);
  }

  ::v-deep .at-modal__close {
    font-size: 24px;
    color: #8F949A;
    top: 35px;
    right: 35px;
  }


  &__row {
    display: flex;
    align-items: center;

    flex-direction: column;
    gap: 11px;
  }

  &__title {
    font-weight: 500;
  }

  &__sub-title {
    margin-bottom: 6px;
    @include secondaryText;
    font-weight: 700;
    color: var(--primary-font-color);
  }

  &__api-keys-item {
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
  }

  &__api-keys-value {
    display: flex;
    align-items: center;
    width: 100%;
    word-break: break-word;
  }

  &__api-keys-value-input {
    width: 100%;
  }

  &__api-keys-copy-value {
    margin-left: 8px;
    width: 24px;
    height: 24px;
  }

  &__full-width-btn {
    width: 100%;
  }
}

.business-modal {
  &__select {
    &-container {
      margin-bottom: 20px;
    }
  }
  &__label {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }
  &__currency {
    &-ticker {
      color: var(--secondary-font-color);
    }
  }
  &__assets {
    display: flex;
    flex-direction: column;
    gap: 20px;

    margin-top: 10px;

    border-bottom: 1px solid var(--new-front-border-color);
    padding-bottom: 20px;

    .assets-row {
      &__label {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 13px;
        padding-bottom: 3px;
        color: var(--new-front-input-label-font-color)!important;

        &.currency_and_network {
          padding-bottom: 7px;
        }
      }
      &__value {
        display: flex;
        align-items: center;
        gap: 8px;

        font-weight: 500;
        font-size: $h3;

        color: var(--new-front-primary-font-color);

        .asset-network {
          color: var(--new-front-secondary-font-color);
        }
      }

      @media (max-width: 576px) {
        &__value {
          &.address, &.amount {
            font-size: $h6;
          }
        }
      }
    }
  }
  &__info {
    padding: 30px;
    border: 1px solid var(--border-color);
    border-radius: 12px;
    @media (max-width: 950px) {
      padding: 10px;
    }
    &-item {
      margin-bottom: 30px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-label {
      font-size: 16px;
      color: var(--primary-font-color);
    }
    &-value {
      color: var(--primary-font-color);
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
    }
  }
  &__currency {
    height: 34px;
    width: 34px;
    margin-right: 10px;
    @media (max-width: 576px) {
      width: 24px;
      height: 24px;
    }
  }
  &__inputs {
    margin-top: 20px;
  }
  &__row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
::v-deep .at-modal__body {
  padding: 0 50px;
}
::v-deep .at-textarea__original {
  background: var(--secondary-color);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  box-shadow: inset 0 4px 20px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  min-height: 88px;
  font-family: var(--font-family);
  font-size: var(--font-size-text-main);
  line-height: var(--line-height);
  color: var(--primary-font-color);
}
</style>
