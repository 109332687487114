import getWithdrawalRequestQuery from "/public/graphQl/queries/getWithdrawalRequest";

export const fetchWithdrawalRequest = async ({dispatch, commit, rootGetters}, payload) => {
    commit('request', null);

    let requestData;

    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    const variables = {
        organizationId,
        ...payload,
    };

    try {
        commit('app/toggleLoading', null, {root: true});
        requestData = await dispatch('app/request', {
            type: 'query',
            gql: getWithdrawalRequestQuery,
            variables,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (requestData.message) {
        throw requestData.message;
    }

    if (requestData.errors) {
        throw requestData.errors;
    }

    const {
        data: {getWithdrawalRequest},
    } = requestData;

    commit('request', getWithdrawalRequest);

    return getWithdrawalRequest;
};
