import createOrganizationUserMutation from "/public/graphQl/mutations/createOrganizationUser.js";

export const createOrganizationUser = async ({commit, dispatch,rootState, rootGetters, state}, payload) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    let currenciesData;

    let variables = {
        organizationId,
        organizationRoleId: payload.id,
        name: payload.name,
        email: payload.email,
    };

    try {
        if (!rootState.app.isLoading) {
            commit('app/setLoading', true, {root: true});
        }
        currenciesData = await dispatch('app/request', {
            type: 'query',
            gql: createOrganizationUserMutation,
            variables,
        }, {root: true});

        if (currenciesData.data.inviteUser.code !== 0) {
            return currenciesData.data.inviteUser.code; throw new Error(currenciesData.data.inviteUser.code);
        }
    } catch (error) {
        throw new Error(error.message);
    } finally {
        commit('app/setLoading', false, {root: true});
    }

    if (currenciesData.code === 0) {
        throw currenciesData.message;
    }

    const {
        data: {
            inviteUser: {
                result,
            },
        },
    } = currenciesData;

    commit('setInviteUrl', result);
    return currenciesData;
};
