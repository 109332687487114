import requestFeeForRegistry from "../../../../graphQl/mutations/requestFeeForRegistry";

export const fetchFeeForRegistry = async ({dispatch}, payload) => {
    let feeForTransfer;

    try {
        feeForTransfer = await dispatch('app/request', {
            type: 'mutation',
            gql: requestFeeForRegistry,
            variables: payload,
        }, {root: true});
    } catch (error) {
        return error;
    }

    if (feeForTransfer.message) {
        throw feeForTransfer.message;
    }

    if (feeForTransfer.errors) {
        throw feeForTransfer.errors;
    }


    return feeForTransfer;
};