import gql from "graphql-tag";

export default gql`
    mutation createPreCheckout(
        $organizationId: String!,
        $accountId: String!, 
        $url: String!, 
        $alias: String, 
        $title: String!, 
        $description: String, 
        $avatarLink: String, 
        $theme: PreCheckoutPageInfoTheme!, 
        $currencies: [[String!]]!, 
    ) {
        createPreCheckout(
            organizationId: $organizationId,
            accountId: $accountId,
            url: $url,
            alias: $alias, 
            title: $title, 
            description: $description, 
            avatarLink: $avatarLink, 
            theme: $theme,
            currencies:$currencies,
        ) {
            code
            result
        }
    }
`;
