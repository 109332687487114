import getBankAccounts from "/public/graphQl/queries/getBankAccounts";

export const fetchBankAccounts = async ({state, rootState, rootGetters, commit, dispatch}, payload) => {
    const {transactionsCountOnPage} = state;
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const variables = {
        organizationId,
        limit: 9999,
        offset: 0,
    };
    let accountsData;

    try {
        commit('app/toggleLoading', null, {root: true});
        accountsData = await dispatch('app/request', {
            type: 'query',
            gql: getBankAccounts,
            variables,
        }, {root: true});
    } catch (error) {
        console.error(error);
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (!accountsData.message && !accountsData.errors) {
        const {
            data: {
                getBankAccounts: {
                    size,
                    rows,
                },
            },
        } = accountsData;

        commit('setBankAccounts', rows);
    }

    return true;
};
