export const changeAccountBalance = ({commit, state}, {accountId, balance}) => {
    const accounts = state.accounts;

    const newAccounts = accounts
        .map(a => {
            a = Object.assign({}, a);

            if(a.accountId === accountId) {
                a.balance = balance;
            }

            return a;
        });

    commit('setAccounts', newAccounts);
};
