<script>

    import Copy from "@/public/components/common/copy.vue";
    import vClickOutside from "v-click-outside";
    import moment from "moment/moment";
    import BigNumber from "bignumber.js";
    import CurrencyNetworkIcon from "@/public/components/elements/business-walet/Select/CurrencyNetworkIcon.vue";
    import {mapState} from "vuex";
    import {getPrettyAddress} from "@/public/filters";
    import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";

    export default {
        name: "WebhookMobileItem",
        components: {BaseButton, CurrencyNetworkIcon, Copy},
        directives: {
            clickOutside: vClickOutside.directive
        },
        props: {
            item: {
                type: Object,
                default: () => ({})
            }
        },
        data () {
            return {
                isOpened: false,
            }
        },
        computed: {
            ...mapState('app', ['networks', 'currencies']),
            moment() {
                return moment
            },
            webhookStatus () {
                if (!this.item.responseStatus) {
                    return ''
                }
                return  this.item.responseStatus.toLowerCase()
            },
            eventLabel () {
                return this.$t(`webhooks.${this.item.event.toLowerCase()}`)
            },
            id () {
                if (!this.item?.id) return ''
                return getPrettyAddress(this.item?.id)
            },
            eventId () {
                if (!this.item.eventId) return
                return getPrettyAddress(this.item.eventId)
            },
            requestUrl () {
                if (!this.item?.requestUrl) return
                return this.item?.requestUrl
            }
        },
        methods: {
            toggleOpen() {
                this.isOpened = !this.isOpened
            },
            close () {
                this.isOpened = false
            },
            goToDetails () {
                this.$router.push(`/webhooks/${ this.item.id }`)
            }
        }
    }
</script>

<template>
<div class="orphan__item" :class="['orphan__item', isOpened ? 'orphan__item--opened' : '']" v-click-outside="close">
    <div class="orphan__item-header" @click="toggleOpen">
        <div class="orphan__item-left">
            <div :class="['orphan__item-status', `orphan__item-status--${webhookStatus}`]"></div>
            <div class="orphan__item-desc">
                <div class="orphan__item-amount">{{ id }} <copy :value="item.id"/></div>
                <div class="orphan__item-date">{{ moment(item.createdAt).format('DD/MM/YY, HH:mm') }}</div>
            </div>
        </div>
        <div class="orphan__item-right">
            <div class="orphan__item-info">
                <div class="orphan__item__asset  create-business-modal__padding-wrapper">
                    <span class="orphan__item-title">{{ eventLabel }},&ensp;</span> <span class="orphan__item-value">ID: {{ eventId }}</span>
                </div>
            </div>
            <svg :class="['orphan__item-chevron', isOpened ? 'orphan__item-chevron--active' : '']" width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.712375 4.38453C0.569375 4.55753 0.484375 4.77853 0.484375 5.01953C0.484375 5.57153 0.932375 6.01953 1.48438 6.01953H7.48438C8.03638 6.01953 8.48438 5.57153 8.48438 5.01953C8.48438 4.77853 8.39937 4.55753 8.25637 4.38453L5.29238 0.430531C5.11037 0.181531 4.81637 0.0195312 4.48438 0.0195312C4.15237 0.0195312 3.85837 0.181531 3.67637 0.430531L0.712375 4.38453Z" fill="var(--new-front-text-primary-bg-primary)"/>
            </svg>
        </div>
    </div>
    <div class="orphan__item-content">
        <div class="orphan__item-element">
            <div class="orphan__item-title">
                {{ $t('webhooks.status') }}:
            </div>
                <div :class="['orphan__item-value-status', `orphan__item-value-status--${webhookStatus}`]">{{ item.responseCode }}</div>
        </div>
        <div class="orphan__item-element">
            <div class="orphan__item-title">
                URL:
            </div>
            <div class="orphan__item-value">
                <!--                <div class="orphan__item-value-status"></div>-->
                <copy :value="requestUrl" />
                <a :href="requestUrl" target="_blank">
                    {{ requestUrl | getPrettyUrl }}
                </a>
            </div>
        </div>
        <base-button @click="goToDetails" class="orphan__item-linkBtn" :label="$t('orphan.info-btn')" type="primary" />
    </div>
</div>
</template>
<style scoped lang="scss">
.orphan__item {
  max-height: 85px;
  transition: max-height .3s ease;
  overflow: hidden;
  &-linkBtn {
    margin: 10px 0;
  }
  &-amount {
    color: var(--new-front-primary-font-color);
    font-size: $h6;
    font-weight: 500;
      display: flex;
      align-items: center;
      gap: 5px;
  }
  &-date {
    color: var(--new-front-secondary-font-color);
    font-size: $h6;
    font-weight: 500;
  }
  &--opened {
    max-height: 310px;
  }
  &-chevron {
    transform: rotate(180deg);
    transition: transform .3s ease;
    &--active {
      transform: rotate(0deg);
    }
  }
  &__asset {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
      justify-content: end;
    &-currency {
      font-weight: 500;
      font-size: $h4;
      color: var(--new-front-primary-font-color);
      margin: 0 10px;
    }
    &-network {
      font-weight: 500;
      font-size: $h4;
      color: var(--new-front-secondary-font-color);
    }
  }
  &-title {
    font-size: $h6;
    color: var(--new-front-primary-font-color);
      text-decoration: unset !important;
  }
  &-content {
    padding: 0 $p20;
    border-bottom: 1px solid var(--new-front-divider-color);
  }
  &-element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    border-bottom: 1px solid var(--new-front-divider-color);
    &:last-child {
      border-bottom: none;
    }
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 $p20 0 $p5;
    min-height: 85px;
    border-bottom: 1px solid var(--new-front-divider-color);
  }
  &-right {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  &-status {
    width: 3px;
    border-radius: 300px;
    background: #44B070;
    height: 55px;
    &--error {
      background: #CF5252;
    }
    &--pending {
      background: #BE9B40;
    }
    &--processed {
      background: #44B070;
    }
  }
  &-left {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &-value-status {
    padding: 4px $p15;
    background: var(--new-front-status-processed);;
    color: var(--new-front-status-text-processed);
    border-radius: 10px;
    font-size: $h4;
    font-weight: 500;
    &--error {
        background: var(--new-front-status-error);
        color: var(--new-front-status-text-error);
    }
    &--pending {
        background: var(--new-front-status-pending);
        color: var(--new-front-status-text-pending);
    }
    &--processed {
        background: var(--new-front-status-processed);
        color: var(--new-front-status-text-processed);
    }
  }
  &-value {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--new-front-purple);
    font-size: $h6;
      font-weight: 500;
      text-decoration: underline;
    &--error {
      background: #CF5252;
    }
    &--pending {
      background: #BE9B40;
    }
    &--processed {
      background: #44B070;
    }
  }
}
a {
    color: var(--new-front-purple) !important;
}
</style>
