import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!, $accountId: String!, $includeArchived: Boolean) {
        getBusinessAddresses(organizationId: $organizationId, accountId: $accountId, includeArchived: $includeArchived) {
            id
            accountId
            currencyId
            networkId
            address
            tag
            balance
            alias
            comment
            isBanned
            isActive
            isArchived
            riskLevel
        }
    }
`;
