<template>
  <div>
    <preloader v-if="isLoading || !isInit"/>
    <div v-else class="transaction">
      <AtButton
          class="transaction__all"
          type="primary"
          icon="icon-arrow-left"
          @click="goBack"
      >
        {{ $t('transaction.return') }}
      </AtButton>
      <template v-if="hasTransaction">
        <OrderTransactionInfo
            class="transaction__info"
            :data="transaction"
        />
        <!--            <DepositTransactionInfo-->
        <!--                v-if="isDeposit"-->
        <!--                :data="transaction"-->
        <!--            />-->
        <!--            <ExchangeTransactionInfo-->
        <!--                v-if="isExchange"-->
        <!--                :data="transaction"-->
        <!--            />-->
        <!--            <WithdrawalTransactionInfo-->
        <!--                v-if="isWithdraw"-->
        <!--                :data="transaction"-->
        <!--            />-->
      </template>
    </div>

  </div>
</template>

<script>

import {mapActions, mapState} from 'vuex';

import AtButton from 'at-ui/src/components/button';
import DepositTransactionInfo from "/public/components/elements/transaction/DepositTransactionInfo";
import ExchangeTransactionInfo from "/public/components/elements/transaction/ExchangeTransactionInfo";
import WithdrawalTransactionInfo from "/public/components/elements/transaction/WithdrawalTransactionInfo";
import TransactionInfo from "../components/elements/transaction/TransactionInfo";
import Preloader from "/public/components/common/uiKit/spinner/index.vue";
import OrderTransactionInfo from "../components/elements/orders/OrderTransactionInfo";

export default {
    name: 'TransactionPage',
    title: 'transaction.title',
    components: {
        OrderTransactionInfo,
        Preloader,
        TransactionInfo,
        AtButton,
        DepositTransactionInfo,
        ExchangeTransactionInfo,
        WithdrawalTransactionInfo,
    },
    data() {
        return {
            transaction: null,
        };
    },
    computed: {
        ...mapState('app', ['isInit', 'isLoading']),
        transactionId() {
            return this.$route.params.id;
        },
        hasTransaction() {
            return Boolean(this.transaction);
        },
        type() {
            return this.transaction.type;
        },
        isDeposit() {
            return this.type === 'deposit';
        },
        isExchange() {
            return this.type === 'exchange';
        },
        isWithdraw() {
            return this.type === 'withdrawal';
        },
    },
    beforeMount() {
        this.setTransaction();
    },
    methods: {
        ...mapActions('orders', [
            'fetchBlockChainTransaction',
        ]),
        setTransaction() {
            const {
                transactionId,
                fetchBlockChainTransaction,
            } = this;
            fetchBlockChainTransaction({
                id: transactionId,
            })
                .then(transaction => {
                    this.transaction = transaction;
                });
        },
        goBack() {
            if (window.history.length > 2)
                this.$router.go(-1);
            else this.$router.push({name: 'orders'});
        },
    },
};

</script>

<style lang="scss" scoped>

.transaction {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: var(--padding-left-container);
  padding-right: var(--padding-right-container);
  padding-top: var(--padding-top-container);

  &__info {
    margin-bottom: var(--margin-container-bottom);
    margin-top: var(--margin-container-top);
  }

  &__all {
    background: #0059F7;
    border-radius: 6px;
    border: none;
    width: 100%;
    max-width: 125px;
    min-height: 40px;
    align-self: flex-start;
    transition: all 0.3s ease-in;

    &:hover {
      background: var(--blue-400);
    }

    ::v-deep .at-btn__text {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: var(--white-100);
    }

    ::v-deep .at-btn__icon {
      font-size: var(--font-size-text-main);
    }
  }

  &__title {
    margin-bottom: 24px;
    min-height: 107px;
  }
}


@media screen and (max-width: 1180px) {
  .transaction {
    &__all {

    }
  }

}

@media screen and (max-width: 950px) {
  .transaction {
    padding-left: 20px;
    padding-right: 20px;
  }

}

@media screen and (max-width: 375px) {
  .transaction {
    padding-right: 0;
    padding-left: 0;

    &__all {
      margin-left: 10px;
    }
  }

}
</style>
