<script>

  import WidgetsListItem from "@/public/components/elements/widgets/WidgetsListItem.vue";
  import {mapActions, mapMutations, mapState} from "vuex";
  import {STEP_START_PAGE, STEP_WIDGET} from "@/public/pages/widgets/constants";

  export default {
    name: "WidgetsList",
    components: {WidgetsListItem},
    props: {
      list: {
        type: Array,
        default: () => ([])
      },
    },
    computed: {
      ...mapState('widgetsManage', ['widgets'])
    },

    methods: {
      ...mapActions('widgetsManage', ['fetchWidget']),
      ...mapMutations('widgetsManage', ['setLoading', 'setCurrentStep', 'setManageType']),
      async goToManage (type, id) {
        const step = type === 'demo' ? STEP_WIDGET : STEP_START_PAGE
        this.setManageType(type)
        this.setLoading(true)
        await this.fetchWidget(id)
        this.setCurrentStep(step)
        this.setLoading(false)
        await this.$router.push({ name: 'widgetsManage' })
      }
    }
  }
</script>

<template>
<div class="list">
 <WidgetsListItem v-for="(item, idx) in list" :item="item" :index="idx" :key="item.id" @goToManage="goToManage" />
</div>
</template>

<style scoped lang="scss">
.list {
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  @media (max-width: 1800px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1480px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 20px;
  }
}
</style>
