<template>
  <BaseModal
      class="create-exchange-modal"
      width="640"
      :value="isOpen"
      :title="title"
      :show-head="!isLoading"
      :show-footer="!isLoading"
      @on-confirm="handleCreateRequest"
      @on-cancel="handleCancelCreate"
  >
    <template v-if="!isLoading">
      <template v-if="isSuccess">
        <p> Обмен успешно создан </p>
      </template>
      <template v-else-if="isError">
        <p v-html="errorReason"/>
      </template>
      <template v-else>
        <div
            v-if="hasErrors"
            class="row"
        >
          <AtAlert
              v-for="(error, key) in errors"
              :key="key"
              type="error"
              :message="error"
          />
        </div>
        <div
            v-if="hasCurrencies"
            class="row"
        >
          <div class="title col col-8">
            Валюта, которую хотите обменять
          </div>
          <div class="col col-16">
            <AtSelect
                size="small"
                placeholder="Выберите валюту"
                v-model="currencyFrom"
            >
              <AtOption
                  v-for="(currency, key) in currenciesToCurrencyFrom"
                  :key="key"
                  :value="currency.currency"
              />
            </AtSelect>
          </div>
        </div>
        <div
            v-if="hasCurrencies"
            class="row"
        >
          <div class="title col col-8">
            Валюта, которую хотите получить
          </div>
          <div class="col col-16">
            <AtSelect
                size="small"
                placeholder="Выберите валюту"
                v-model="currencyTo"
            >
              <AtOption
                  v-for="(currency, key) in currenciesToCurrencyTo"
                  :key="key"
                  :value="currency.currency"
              />
            </AtSelect>
          </div>
        </div>
        <div class="row">
          <div class="title col col-8">
            Баланс
          </div>
          <div class="currency col col-16">
            {{ balance }}
            <CurrencyImg
                v-if="currencyFrom"
                class="img"
                :currency="currencyFrom"
            />
            {{ currencyFrom }}
          </div>
        </div>
        <div class="row">
          <div class="title col col-8">
            Сумма к обмену
          </div>
          <div class="col col-16">
            <AtInput
                size="small"
                type="number"
                placeholder="0"
                v-bind:status="amountFromStatus"
                v-model="amountFrom"
            />
            <template v-if="!!amountFromMessage.length">
              <p
                  v-for="err in amountFromMessage"
                  v-bind:key="err"
                  style="font-size: 9px;"
              >{{ err }}</p>
            </template>
          </div>
        </div>
        <div class="row">
          <div class="title col col-8">
            Сумма к получению
          </div>
          <div class="col col-16">
            <AtInput
                size="small"
                readonly
                v-bind:disabled="amountToDisabled"
                v-bind:status="amountToStatus"
                v-bind:value="amountTo"
            />
            <template v-if="!!amountToMessage.length">
              <p
                  v-for="err in amountToMessage"
                  v-bind:key="err"
                  style="font-size: 9px;"
              >{{ err }}</p>
            </template>
          </div>
        </div>
        <div class="row">
          <div class="title col col-8">
            Error webhook
          </div>
          <div class="col col-16">
            <AtInput
                v-model="exchange.errorWebhook"
            />
          </div>
        </div>
        <div class="row">
          <div class="title col col-8">
            Success webhook
          </div>
          <div class="col col-16">
            <AtInput
                v-model="exchange.successWebhook"
            />
          </div>
        </div>
        <div class="row" style="justify-content: center;">
          <div class="col col-18 offset-2">
            <p style="font-size: 10px;text-align: center;">
              <i class="icon icon-alert-circle"/>
              При высокой волатильности итоговая сумма может отличаться
            </p>
          </div>
        </div>
      </template>
    </template>
    <LoadingSpinner
        v-else
        title="Создание обмена"
    />
    <template #footer>
      <template v-if="(!isSuccess && !isError)">
        <AtButton @click="handleCancelCreate">
          Отмена
        </AtButton>
        <AtButton
            type="primary"
            @click="handleCreateRequest"
        >
          Создать обмен
        </AtButton>
      </template>
      <AtButton
          v-else
          class="full-width-btn"
          type="primary"
          @click="handleCancelCreate"
      >
        Закрыть
      </AtButton>
    </template>
  </BaseModal>
</template>

<script>

import {mapState, mapGetters, mapActions} from 'vuex';
import {checkAddress} from "../../../../common/validators/address";

import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';
import AtModal from 'at-ui/src/components/modal';
import CurrencyImg from "/public/components/elements/CurrencyImg";
import AtInput from 'at-ui/src/components/input';
import AtRadioGroup from 'at-ui/src/components/radio-group';
import AtRadio from 'at-ui/src/components/radio';
import LoadingSpinner from "/public/components/elements/LoadingSpinner";
import AtButton from 'at-ui/src/components/button';
import WithdrawalAddress from "./components/WithdrawalAddress";
import AtAlert from 'at-ui/src/components/alert';
import {IO_EVENTS} from "../../../../common/constants/io-events";
import AtNotification from "at-ui/src/components/notification";
import BaseModal from "../BaseModal";

const truncater = (amount) => (+(amount || 0) + '').match(/^([0-9]*).([0-9]{0,8})/ig)[0];

export default {
    name: 'CreateExchangeModal',
    components: {
        BaseModal,
        AtSelect,
        AtOption,
        AtModal,
        CurrencyImg,
        AtInput,
        AtRadioGroup,
        AtRadio,
        LoadingSpinner,
        AtButton,
        WithdrawalAddress,
        AtAlert,
    },
    data() {
        return {
            isLoading: false,
            isSuccess: false,
            isError: false,
            error: undefined,
            exchange: {
                currencyFrom: null,
                currencyTo: null,
                amountFrom: 0,
                errorWebhook: '',
                successWebhook: '',
                rate: -2,
                rules: {
                    lowFrom: 0,
                    lowTo: 0,
                    highFrom: 0,
                    highTo: 0,
                },
            },
        };
    },
    computed: {
        ...mapState('app', {
            modalPayload: state => state.modal.payload,
            currencies: state => state.currencies,
        }),
        ...mapGetters('balances', [
            'balances',
        ]),
        ...mapGetters('app', [
            'isOpenModal',
            'isFiat',
            'hasCurrencies',
        ]),
        errorReason() {
            if (!this.isError)
                return '';

            if (!this.error)
                return '';

            let message = `Произошла ошибка. Обратитесь в службу поддержки на почту <a href="${import.meta.env.VITE_SUPPORT_EMAIL}">${import.meta.env.VITE_SUPPORT_EMAIL}</a> для уточнения подробностей`;

            this.error.graphQLErrors?.forEach(e => {
                if (e?.extensions?.exception?.code && typeof e?.extensions?.exception?.code === "number") {
                    switch (true) {
                    case (e.extensions.exception.code <= 1000):
                        message = `Ошибка сервера. Обратитесь в службу поддержки на почту <a href="${import.meta.env.VITE_SUPPORT_EMAIL}">${import.meta.env.VITE_SUPPORT_EMAIL}</a> для уточнения подробностей. Код ошибки ${e.extensions.exception.code}`;
                        break;
                    case (e?.extensions?.exception?.code <= 3000):
                        message = `Ошибка при проверке входных параметров, код ошибки ${e.extensions.exception.code}, описание: ${e.message}`;
                        break;
                    case (e?.extensions?.exception?.code <= 4000):
                        message = `Операция запрещена, код ${e.extensions.exception.code}, описание: ${e.message}. Обратитесь в службу поддержки на почту <a href="${import.meta.env.VITE_SUPPORT_EMAIL}">${import.meta.env.VITE_SUPPORT_EMAIL}</a> для уточнения подробностей`;
                        break;
                    }
                }
            });

            return message;
        },
        amountFromErrors() {
            const {amountFrom, balance} = this;

            const errors = [];

            if (amountFrom < 0)
                errors.push(1);

            if (balance < amountFrom)
                errors.push(0);

            if (this.exchange.rules.highFrom && amountFrom > this.exchange.rules.highFrom)
                errors.push(2);

            if (this.exchange.rules.lowFrom && amountFrom < this.exchange.rules.lowFrom)
                errors.push(3);

            return errors;
        },
        amountFromStatus() {
            return this.amountFromErrors.length ? 'error' : '';
        },
        amountFromMessage() {
            const messages = [];

            if (this.amountFromErrors.includes(0))
                messages.push('Сумма превышает доступный баланс');

            if (this.amountFromErrors.includes(1))
                messages.push('Сумма не может быть меньше нуля');

            if (this.amountFromErrors.includes(2))
                messages.push(`Сумма не должна быть больше ${this.exchange.rules.highFrom} ${this.currencyFrom}`);

            if (this.amountFromErrors.includes(3))
                messages.push(`Сумма не должна быть меньше ${this.exchange.rules.lowFrom} ${this.currencyFrom}`);

            if (!messages.length) {
                if (this.exchange.rules.lowFrom)
                    messages.push(`Минимальная сумма обмена ${this.exchange.rules.lowFrom}`);

                if (this.exchange.rules.highFrom)
                    messages.push(`Максимальная сумма обмена ${this.exchange.rules.highFrom}`);
            }

            return messages;
        },
        amountToErrors() {
            const {amountTo} = this;

            const errors = [];

            if (amountTo < 0)
                errors.push(1);

            if (this.exchange.rules.highTo && amountTo > this.exchange.rules.highTo)
                errors.push(2);

            if (this.exchange.rules.lowTo && amountTo < this.exchange.rules.lowTo)
                errors.push(3);

            return errors;
        },
        amountToStatus() {
            return this.amountToErrors.length ? 'error' : '';
        },
        amountToDisabled() {
            if (this.exchange.rate < 0)
                return true;
        },
        amountToMessage() {
            const messages = [];

            if (this.amountToErrors.includes(1))
                messages.push('Сумма не может быть меньше нуля');

            if (this.amountToErrors.includes(2))
                messages.push(`Сумма не должна быть больше ${this.exchange.rules.highTo} ${this.currencyTo}`);

            if (this.amountToErrors.includes(3))
                messages.push(`Сумма не должна быть меньше ${this.exchange.rules.lowTo} ${this.currencyTo}`);

            if (!messages.length) {
                if (this.exchange.rate < 0) {
                    if (this.exchange.rate === -2) {
                        messages.push('Выберите валюты');
                    }
                    if (this.exchange.rate === -1) {
                        messages.push('Получение курса');
                    }
                } else
                    messages.push(`Курс обмена ~ ${truncater(this.exchangeRate)}`);
            }

            return messages;
        },
        amountFrom: {
            get() {
                if (this.exchange.rules.lowFrom && this.exchange.rules.lowFrom > this.exchange.amountFrom)
                    return this.exchange.rules.lowFrom;

                if (this.exchange.rules.highFrom && this.exchange.rules.highFrom < this.exchange.amountFrom)
                    return this.exchange.rules.highFrom;

                return this.exchange.amountFrom;
            },
            set(v) {
                this.exchange.amountFrom = Number(truncater(v));
            },
        },
        exchangeRate() {
            return 1 / (this.exchange.rate >= 0 ? this.exchange.rate : 0);
        },
        amountTo() {
            const amountTo = this.amountFrom / this.exchangeRate;

            if (this.exchange.rules.lowTo && this.exchange.rules.lowTo > amountTo)
                return this.exchange.rules.lowTo;

            if (this.exchange.rules.highTo && this.exchange.rules.highTo < amountTo)
                return this.exchange.rules.highTo;

            return amountTo < 0 ? '0' : truncater(amountTo);
        },
        balance() {
            if (!this.currencyFrom || !this.balances.length) {
                return 0;
            }

            return this.balances.find(b => b.currency === this.currencyFrom)?.amount || 0;
        },
        currencyFrom: {
            get() {
                return this.currenciesToCurrencyFrom.find(c => c.currency === this.exchange.currencyFrom)?.currency;
            },
            set(v) {
                return this.exchange.currencyFrom = v;
            },
        },
        currencyTo: {
            get() {
                return this.currenciesToCurrencyTo.find(c => c.currency === this.exchange.currencyTo)?.currency;
            },
            set(v) {
                return this.exchange.currencyTo = v;
            },
        },
        pair() {
            const {
                currencyFrom,
                currencyTo,
            } = this;

            return `${currencyFrom || ''}/${currencyTo || ''}`;
        },
        currenciesToCurrencyFrom() {
            return this.currencies;
        },
        currenciesToCurrencyTo() {
            return this.currencies?.filter(c => c.currency !== this.exchange.currencyFrom) || [];
        },
        isOpen() {
            const {
                $options: {name},
                isOpenModal,
            } = this;

            return isOpenModal(name);
        },
        withoutCurrency() {
            const {modalPayload} = this;
            return modalPayload ? modalPayload.withoutCurrency : false;
        },
        title() {
            return this.isSuccess ? 'Обмен создан' : 'Создание обмена';
        },
        errorWebhookStatus() {
            return /*!this.errorWebhook ? 'error' : */'';
        },
        successWebhookStatus() {
            return /*!this.successWebhook ? 'error' : */'';
        },
        errors() {
            const errors = [];

            if (this.exchange.rate === 0)
                errors.push(`По валютной паре ${this.pair} нельзя провести обмен`);

            return errors;
        },
        hasErrors() {
            return Boolean(this.errors.length);
        },
    },
    methods: {
        ...mapActions('app', [
            'closeModal',
        ]),
        // ...mapActions('balances', [
        //     'fetchBalances',
        // ]),
        ...mapActions('exchanges', [
            'createExchange',
        ]),
        onReceiveRate(data) {
            const {
                pair,
                rate = 0,
                lowFrom,
                lowTo,
                highFrom,
                highTo,
            } = data;

            if (pair !== this.pair)
                return;

            this.exchange.rate = rate;
            this.exchange.rules.lowFrom = lowFrom;
            this.exchange.rules.lowTo = lowTo;
            this.exchange.rules.highFrom = highFrom;
            this.exchange.rules.highTo = highTo;
        },
        clearAllData() {
            this.isLoading = false;
            this.isSuccess = false;
            this.exchange.currencyTo = undefined;
            this.exchange.currencyFrom = undefined;
            this.exchange.amountFrom = 0;
            this.exchange.amountTo = 0;
            this.exchange.exchangeRate = 0;
        },
        handleCreateRequest() {
            const {
                currencyFrom,
                currencyTo,
                amountFrom,
                amountFromErrors,
                amountToErrors,
                errors,
                createExchange,
                // fetchBalances,
                exchange: {
                    successWebhook,
                    errorWebhook,
                },
            } = this;

            if (!amountFrom || !currencyFrom || !currencyTo || amountFromErrors.length || amountToErrors.length || errors.length) {
                return false;
            }

            this.isLoading = true;

            createExchange({
                currencyFrom,
                currencyTo,
                amountFrom,
                successWebhook,
                errorWebhook,
            })
                .then(() => {
                    this.isSuccess = true;
                    // fetchBalances();
                })
                .catch((err) => {
                    this.isError = true;
                    this.error = err;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        handleCancelCreate() {
            const {
                clearAllData,
                closeModal,
            } = this;

            closeModal();

            setTimeout(() => {
                clearAllData();
            }, 500);
        },
    },
    watch: {
        isOpen(v) {
            if (!v)
                this.$io.off(IO_EVENTS.EXCHANGE_RATE, this.onReceiveRate);
            else {
                this.$io.on(IO_EVENTS.EXCHANGE_RATE, this.onReceiveRate);

                Object.assign(this, {
                    isLoading: false,
                    isSuccess: false,
                    isError: false,
                    error: undefined,
                    exchange: {
                        currencyFrom: null,
                        currencyTo: null,
                        amountFrom: 0,
                        rate: -2,
                        rules: {
                            lowFrom: 0,
                            lowTo: 0,
                            highFrom: 0,
                            highTo: 0,
                        },
                    },
                });
            }
        },
        pair(p) {
            if (!this.currencyFrom || !this.currencyTo) {
                this.exchange.rate = -2;
                return;
            }

            this.exchange.rate = -1;
            this.$io.emit(IO_EVENTS.EXCHANGE_RATE, p);
        },
    },
};

</script>

<style lang="scss" scoped>

.create-exchange-modal {

  .row {
    margin-bottom: 16px;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .title {
    font-weight: 500;
  }

  .currency {
    display: flex;
    align-items: center;

    .img {
      margin-left: 8px;
      margin-right: 4px;
      width: 24px;
      height: 24px;
    }
  }

  .withdrawal-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .full-width-btn {
    width: 100%;
  }

  .table-header {
    margin-left: 0;
    margin-right: 0;
    padding: 4px 12px;
    background-color: $gray;
    border: 1px solid $gray2;
  }

  .add-address {
    margin-top: 12px;
  }
}

</style>
