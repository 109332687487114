import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!, $id: String!) {
        getWithdrawalRequest(organizationId: $organizationId, id: $id) {
            id
            currencyId
            accountId
            networkId
            amount
            feeAmount
            status
            feeSource
            tx
            address
            tag
            createdAt
            addressId
        }
    }
`;
