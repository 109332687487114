import gql from 'graphql-tag';

// передается либо amountFrom либо amountTo - один из двух

export default gql`
    mutation($organizationId: String!, $accountId: String!, $currencyFromId: String!, $currencyToId: String!, $networkFromId: String!, $networkToId: String!, $amountFrom: String, $amountTo: String) {
        crosschainSwapToken(organizationId: $organizationId, accountId: $accountId, currencyFromId: $currencyFromId, currencyToId: $currencyToId, networkFromId: $networkFromId, networkToId: $networkToId, amountFrom: $amountFrom, amountTo: $amountTo){
                success
                code
                result {                  
                    accountId
                    currencyFromId
                    currencyToId
                    networkFromId
                    networkToId
                    blockchainFeeSource
                    blockchainFeeFrom
                    blockchainFeeFromUSD
                    blockchainFeeTo
                    blockchainFeeToUSD
                    serviceFee
                    serviceFeeUSD
                    amountFrom
                    amountFromUSD
                    amountTo
                    amountToUSD
                    price
                    token
                    expiresAt
                }
        }
    }
`;
