import getWebhookQuery from "/public/graphQl/queries/getWebhook";

export const getWebhook = async ({dispatch, commit, rootGetters}, id) => {
    let webhookData;
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const variables = {
        organizationId,
        webhookId: id
    }

    try {
        commit('app/toggleLoading', null, {root: true});
        webhookData = await dispatch('app/request', {
            type: 'query',
            gql: getWebhookQuery,
            variables
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (webhookData.message) {
        throw webhookData.message;
    }

    if (webhookData.errors) {
        throw webhookData.errors;
    }

    const {
        data: {getWebhook},
    } = webhookData;
    commit('setWebhook', getWebhook)
    return getWebhook;
};
