import gql from 'graphql-tag';

export default gql`
    mutation updateBusinessAddress(
        $organizationId: String!,
        $accountId: String!, 
        $addressId: String!,
        $comment: String,
        $alias: String,
    ) {
        updateBusinessAddress(
            organizationId: $organizationId,
            accountId: $accountId, 
            addressId: $addressId,
            comment: $comment, 
            alias: $alias,
        ) {
            code
            result {
              id
              currencyId
              networkId
              address
              tag
              balance
              alias
              comment
            }
        }
    }
`;
