import getUserQuery from "../../../../graphQl/queries/getUser";

export const fetchUser = async ({ commit, dispatch, rootGetters }) => {
    let userData;

    try {
        userData = await dispatch('app/request', {
            type: 'query',
            gql: getUserQuery,
        }, { root: true });
    } catch (error) {
        return error;
    }

    if (userData.message) {
        throw userData.message;
    }

    const {
        data: {
            getUser,
        },
    } = userData;

    commit('changeUser', getUser);

    return true;
};
