import getTokenQuery from "../../../../graphQl/queries/getToken";

export const fetchToken = async ({ commit, dispatch }) => {
    let userToken;

    try {
        userToken = await dispatch('app/request', {
            type: 'query',
            gql: getTokenQuery,
        }, { root: true });
    } catch (error) {
        return error;
    }

    if (userToken.message) {
        throw userToken.message;
    }

    const {
        data: {
            getToken,
        },
    } = userToken;

    commit('changeToken', getToken);

    return true;
};