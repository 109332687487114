<script>
    import Copy from "@/public/components/common/copy.vue";
    import Preloader from "/public/components/common/uiKit/spinner/index.vue";
    import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
    import CurrencyIcon from "@/public/components/common/uiKit/CurrencyIcon.vue";
    import BigNumber from "bignumber.js";
    import { getPrettyAddress } from "@/public/filters";
    import NetworkIcon from "@/public/components/common/uiKit/NetworkIcon.vue";
    import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
    import {BlockchainLinks} from "@/public/mixins/blockchain-links";
    import CurrencyNetworkIcon from "@/public/components/elements/business-walet/Select/CurrencyNetworkIcon.vue";
    import moment from "moment/moment";
    import NotEnoughAdvanceBalance from "@/public/components/modals/business-wallet/NotEnoughAdvanceBalance.vue";
    import TwoFaRestriction from "@/public/components/modals/twoFactorAuthorization/TwoFaRestriction.vue";
    import TwoFaConfirmation from "@/public/components/modals/twoFactorAuthorization/TwoFaConfirmation.vue";
    import OrphanCreateWithdrawalRequestModalPayout
        from "@/public/components/modals/orphan/OrphanCreateWithdrawalRequestModalPayout.vue";
    import QRCodeModal from "@/public/components/modals/twoFactorAuthorization/QRCodeModal.vue";
    import NewAddressModal from "@/public/components/modals/addressBook/NewAddressModal.vue";
    export default {
        name: "OrphanInfo",
        props: {
            statusEnum: {
                type: Object,
                required: false,
            },
        },
        mixins: [
            BlockchainLinks,
        ],
        components: {
            NewAddressModal,
            QRCodeModal,
            OrphanCreateWithdrawalRequestModalPayout,
            TwoFaConfirmation,
            TwoFaRestriction,
            NotEnoughAdvanceBalance,
            CurrencyNetworkIcon, BaseButton, NetworkIcon, CurrencyIcon, Copy, Preloader},
        computed: {
            ...mapGetters('app', ['getCurrencyNameById']),
            ...mapState('app', ['networks', 'currencies']),
            ...mapState('orphan', ['orphan', 'isLoading']),
            ...mapState('accounts', ['accounts']),
            ...mapState('user', {
                userOtpEnabled: state => state.user.otpToken,
            }),
            ...mapGetters('organizations', ['userRoleRules']),
            isWithdrawAllowed () {
                return this.userRoleRules['orphan'].entities.make_withdrawal
            },
            orphanOutHash () {
                if (!this.orphan.outTransaction || !this.orphan.outTransaction.txId)  {
                    return ''
                }
                return getPrettyAddress(this.orphan.outTransaction.txId)
            },
            orphanInHash () {
                if (!this.orphan.inTransaction || !this.orphan.inTransaction.txId)  {
                    return ''
                }
                return getPrettyAddress(this.orphan.inTransaction.txId)
            },
            orphanOutAddress () {
                if (!this.orphan.outTransaction)  {
                    return ''
                }
                return getPrettyAddress(this.orphan.outTransaction.address)
            },
            network () {
                return this.networks.find(({ id }) => id === this.orphan.networkId)
            },
            networkName () {
                return this.network?.name
            },
            moment() {
                return moment
            },
            currency () {
                return this.currencies.find(({ id }) => id === this.orphan.currencyId)
            },
            ticker ( ) {
                return this.currency?.currency
            },
            alias () {
                return this.currency?.alias
            },
            addressId() {
                return this.$route.params.id;
            },
            amount () {
                return new BigNumber(this.orphan.amount)
                    .toFixed()
            },
            status () {
                if (!this.orphan?.status) {
                    return {
                        status: '',
                        label: ''
                    }
                }
                return {
                    status: this.orphan.status.toLowerCase(),
                    label: this.$t(`orphan.status-${this.orphan.status.toLowerCase()}`)
                }
            },
            statusOut () {
                if (!this.orphan?.outTransaction) {
                    return {
                        status: '',
                        label: ''
                    }
                }
                return {
                    status: this.orphan.outTransaction.status.toLowerCase(),
                    label: this.$t(`orphan.status-${this.orphan.outTransaction.status.toLowerCase()}`)
                }
            },
            statusIn () {
                if (!this.orphan?.inTransaction) {
                    return {
                        status: '',
                        label: ''
                    }
                }
                return {
                    status: this.orphan.inTransaction.status.toLowerCase(),
                    label: this.$t(`orphan.status-${this.orphan.inTransaction.status.toLowerCase()}`)
                }
            }
        },
        methods: {
            ...mapMutations('orphan', ['setInfoState', 'setOrphan']),
            ...mapActions('orphan', ['getOrphanDeposit', 'getOrphanDeposits']),
            ...mapActions('app', ['openModal']),
            loadOrphanDeposit () {
                this.getOrphanDeposit(this.orphan.id)
                this.getOrphanDeposits()
            },
            openCreateWithdrawalRequestModal(addressId) {
                const {
                    // currency,
                    // amount,
                    openModal,
                    accounts,
                    orphan
                } = this;

                if (!this.userOtpEnabled) {
                    openModal({
                        name: 'TwoFaRestriction',
                    });
                    return;
                }
                if (Number(accounts[0].balance) < 0) {
                    openModal({
                        name: 'NotEnoughAdvanceBalance',
                        payload: {
                            advanceBalanceAmount: accounts[0].balance,
                        },
                    });
                    return;
                }

                openModal({
                    name: 'OrphanCreateWithdrawalRequestModalPayout',
                    payload: {
                        orphan,
                    },
                });
            },
            closeInfo () {
                this.setInfoState(false)
                this.setOrphan(null)
            },
            txLink(txId, networkId) {
                return this.$txLink(txId, networkId);
            },
        }
    }
</script>

<template>
<div class="info">
    <div class="info__bg" @click="closeInfo"></div>
    <div  class="info__content">
        <div class="info__close" @click="closeInfo">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.712087 0.712087C1.0782 0.345971 1.6718 0.345971 2.03791 0.712087L7 5.67418L11.9621 0.712087C12.3282 0.345971 12.9218 0.345971 13.2879 0.712087C13.654 1.0782 13.654 1.6718 13.2879 2.03791L8.32582 7L13.2879 11.9621C13.654 12.3282 13.654 12.9218 13.2879 13.2879C12.9218 13.654 12.3282 13.654 11.9621 13.2879L7 8.32582L2.03791 13.2879C1.6718 13.654 1.0782 13.654 0.712087 13.2879C0.345971 12.9218 0.345971 12.3282 0.712087 11.9621L5.67418 7L0.712087 2.03791C0.345971 1.6718 0.345971 1.0782 0.712087 0.712087Z" fill="var(--new-front-primary-font-color)"/>
            </svg>
        </div>
        <transition name="slide-fade" mode="out-in">
            <div v-if="!isLoading">
                <div class="orphan__header">
                    <div class="orphan__header-left">
                        <h1 class="orphan__title">{{ $t('orphan.info') }}</h1>
                        <h2 class="orphan__subtitle">{{ moment(orphan.createdAt).format('DD/MM/YY HH:mm') }}</h2>
                    </div>
                    <div :class="['orphan__statusLabel', `orphan__statusLabel--${ status.status }`]">
                        {{ status.label }}
                    </div>
                </div>
                <div class="orphan__items">
                    <div class="orphan__item">
                        <div class="orphan__item-title">
                            tx hash in:
                        </div>
                        <div class="orphan__item-value">
                            {{ orphanInHash }}
                            <copy :value="this.orphan.inTransaction?.txId"/>
                        </div>
                    </div>
                    <div class="orphan__item">
                        <div class="orphan__item-title">
                            tx in status:
                        </div>
                        <div :class="['orphan__statusLabel', `orphan__statusLabel--${ statusIn.status }`]">
                            {{ statusIn.label }}
                        </div>
                    </div>
                    <div class="orphan__item">
                        <div class="orphan__item-title">
                            {{ $t('orphan.amount') }}:
                        </div>
                        <div class="orphan__item-value--black">
                            {{ amount | separatePeriods }}
                        </div>
                    </div>
                    <div class="orphan__item">
                        <div class="orphan__item-title">
                            {{ $t('orphan.currency-network') }}:
                        </div>
                        <div class="orphan__asset create-business-modal__padding-wrapper">
                            <currency-network-icon :currency="ticker" :network="networkName?.toUpperCase()" />
                            <div class="orphan__asset-currency">
                                {{ alias }} |
                            </div>
                            <div class="orphan__asset-network">
                                {{ (networkName || '') | getNetworkShortNameByNetworkName }}
                            </div>
                        </div>
                    </div>
                    <div class="orphan__item">
                        <div class="orphan__item-title">
                            {{ $t('orphan.where-found') }}:
                        </div>
                        <div
                            class="orphan__item-value"
                            v-if="orphan.inTransaction?.address"
                        >
                            {{ (orphan.inTransaction?.address || '') | getPrettyAddress(4) }}
                            <copy :value="orphan.inTransaction?.address" />
                        </div>
                        <div class="orphan__comment-value--black" v-else>-</div>
                    </div>
                    <div class="orphan__item">
                        <div class="orphan__item-title">
                            tx hash out:
                        </div>
                        <div class="orphan__item-value" v-if="orphanOutHash">
                            {{ orphanOutHash }}
                            <copy v-if="orphanOutHash" :value="this.orphan.outTransaction?.txId"/>
                        </div>
                        <div class="orphan__comment-value--black" v-else>-</div>
                    </div>
                    <div class="orphan__item">
                        <div class="orphan__item-title">
                            tx out status:
                        </div>
                        <div v-if="orphan.outTransaction" :class="['orphan__statusLabel', `orphan__statusLabel--${ statusOut?.status }`]">
                            {{  statusOut.label }}
                        </div>
                        <div class="orphan__comment-value--black" v-else>-</div>
                    </div>
                    <div class="orphan__item">
                        <div class="orphan__item-title">
                            {{ $t('orphan.transfer-address') }}:
                        </div>
                        <div class="orphan__item-value" v-if="orphanOutAddress">
                            {{ orphanOutAddress }}
                            <copy v-if="orphanOutAddress" :value="orphan.outTransaction.address"/>
                        </div>
                        <div class="orphan__comment-value--black" v-else>-</div>
                    </div>
                    <div class="orphan__comment">
                        <div class="orphan__comment-title">{{ $t('orphan.withdrawal-comment') }}:</div>
                        <div class="orphan__comment-value">
                            {{ orphan.message }}
                        </div>
                    </div>
                    <div v-if="orphan.canWithdrawal" class="orphan__btn">
                        <base-button
                          type="primary"
                          :label="$t('create-withdrawal-request-modal.send')"
                          :disabled="!isWithdrawAllowed"
                          @click="openCreateWithdrawalRequestModal"
                        />
                    </div>
                </div>
            </div>
            <preloader v-else />
        </transition>
    </div>
    <QRCodeModal :pid="_uid" />
    <NotEnoughAdvanceBalance />
    <TwoFaRestriction :pid="_uid" />
    <TwoFaConfirmation modal-name="OrphanCreateWithdrawalRequestModalPayout"/>
    <NewAddressModal />
    <OrphanCreateWithdrawalRequestModalPayout @update="loadOrphanDeposit"/>
</div>
</template>

<style scoped lang="scss">
.info {
    font-family: $Gilroy;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  // Огромный z-index для перекрытия виджета интеркома
  z-index: 2147483001;
  &__rate-icon {
    margin: 0 10px;
  }
  &__btn {
    margin-top: 50px;
  }

  &__close {
    position: absolute;
    top: 30px;
    cursor: pointer;
  }
  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .50);
    height: 100vh;
    width: 100vw;
    z-index: 1;
    cursor: pointer;
  }
  &__content {
    width: 530px;
    height: 100vh;
    box-sizing: border-box;
    padding: 100px 50px 30px 50px;
    background: var(--new-front-main-bg);
    position: absolute;
    right: 0;
    z-index: 2;
    overflow: auto;
  }
  &__item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 58px;
    border-bottom: 1px solid var(--new-front-divider-color);
    &:last-of-type {
      border-bottom: 0;
    }
    &-title {
      font-size: $h4;
      font-weight: 500;
      color: var(--new-front-secondary-font-color);
    }
    &-value {
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 500;
      font-size: $h4;
      color: var(--new-front-primary-font-color);
      &--secondary {
        color: var(--new-front-secondary-font-color);
      }
        &--link {
            color: var(--new-front-purple);
            text-decoration: underline;
            a {
                color: var(--new-front-purple);
            }
            &::v-deep {
                //.copy-wrapper {
                //    margin-right: 5px !important;
                //}
            }
        }
    }
  }
  &__header {
    margin-bottom: 20px;
    &-topRow {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-title {
      font-size: $h1;
      color: var(--new-front-primary-font-color)
    }
    &-subtitle {
      margin-top: 5px;
      font-size: $h4;
      color: var(--new-front-secondary-font-color)
    }
  }
}
.orphan {
    padding: 30px;
    font-family: $Gilroy;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    &::v-deep {
        .container-new {
            width: 665px;
        }
    }
    &__btn {
        margin-top: $p30;
    }
    &__comment {
        margin-top: $p15;
        font-weight: 500;
        font-size: 16px;
        &-title {
            color: var(--new-front-secondary-font-color);
        }
        &-value {
            color: var(--new-front-primary-font-color);
        }
    }
    &__asset {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        text-decoration: none;
        &-currency {
            font-weight: 500;
            font-size: $h4;
            color: var(--new-front-primary-font-color);
            margin: 0 10px;
        }
        &-network {
            font-weight: 500;
            font-size: $h4;
            color: var(--new-front-secondary-font-color);
        }
    }
    &__return {
        margin-top: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--new-front-bg-btn-primary-bg-modal-primary);
        font-size: $h4;
        font-weight: 500;
    }
    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        //padding: 0 $p15;
        height: 60px;
        border-bottom: 1px var(--new-front-divider-color) solid;
        &-title {
            font-weight: 500;
            font-size: 16px;
            color: var(--new-front-secondary-font-color);
        }
        &-value {
            display: flex;
            align-items: center;
            color: var(--new-front-purple);
            text-decoration: underline;
            font-size: $h4;
            font-weight: 500;
            &--black {
                color: var(--new-front-primary-font-color);
                font-weight: 500;
                font-size: $h4;
            }
        }
    }
    &__statusLabel {
        padding: 4px $p15;
        background: var(--new-front-status-processed);;
        color: var(--new-front-status-text-processed);
        border-radius: 10px;
        font-size: $h4;
        font-weight: 500;
        min-width: 130px;
        text-align: center;
        &--error {
            background: var(--new-front-status-error);
            color: var(--new-front-status-text-error);
        }
        &--pending {
            background: var(--new-front-status-pending);
            color: var(--new-front-status-text-pending);
        }
        &--processed {
            background: var(--new-front-status-processed);
            color: var(--new-front-status-text-processed);
        }
        &--rejected {
            background: var(--new-front-status-error);
            color: var(--new-front-status-text-error);
        }
    }
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $p30;
    }
    &__gridRow {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }
    &__searchRow {
        display: flex;
        align-items: center;
        gap: 10px;
        .search {
            width: 93%;
        }
    }
    &__title {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: $h2;
        color: var(--new-front-text-primary-bg-modal-primary);
        margin-bottom: $p5;
        line-height: 31px;
        > svg {
            margin-left: 5px;
        }
    }
    &__subtitle {
        font-size: $h4;
        font-weight: 400;
        color: var(--new-front-secondary-font-color);
    }
}
.item__status {
    width: 120px;
    height: 32px;
    border-radius: 10px;
    text-align: center;
    padding: 5px 15px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    &--init {
        color: var(--new-front-status-text-active);
        background: var(--new-front-status-active);
    }
    &--rejected {
        color: var(--new-front-status-text-error);
        background: var(--new-front-status-error);
    }
    &--expired {
        color: var(--new-front-status-text-expired);
        background: var(--new-front-status-expired);
    }
    &--processed {
        color: var(--new-front-status-text-processed);
        background: var(--new-front-status-processed);
    }
    &--overpaid {
        color: var(--new-front-status-text-processed);
        background: var(--new-front-status-processed);
    }
    &--pending {
        color: var(--new-front-status-text-active);
        background: var(--new-front-status-active);
    }
    &--partial {
        color: var(--new-front-status-text-pending);
        background: var(--new-front-status-pending);
    }
    &--error {
        color: var(--new-front-status-text-error);
        background: var(--new-front-status-error);
    }
    @media (max-width: 950px) {
        font-size: 13px;
    }
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
::v-deep .copy-wrapper {
    margin-left: 5px !important;
}
@media screen and (max-width: 576px) {
    .orphan__title {
        font-size: $h2;
    }
.orphan__item-title {
    font-size: $h5;
}
    .orphan__item-value {
        font-size: $h5;
    }
    .orphan__subtitle {
        font-size: $h5;
    }
  .info__header-title {
    font-size: $h2;
  }
  .info__item-title {
    font-size: $h5;
  }
  .info__item-value {
    font-size: $h5;
  }
  .info__header-subtitle {
    font-size: $h5;
  }
  .info__content {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 70px 20px 30px;
    overflow: auto;
  }
}
</style>
