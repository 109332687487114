<template>
  <BaseModal
      class="new-donation-modal"
      width="100%"
      :value="isOpen"
      :title="creating_order"
      :show-head="false"
      :show-footer="false"
      :show-close="false"
      @on-cancel="handleClose"
      @on-confirm="createPreCheckoutPageHandler"
  >

    <svg class="svg">
      <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
        <path
            d="M0,0.018 C0,0.008,0.008,0,0.018,0 H0.982 C0.992,0,1,0.008,1,0.018 V0.67 C0.987,0.67,0.977,0.68,0.977,0.693 C0.977,0.705,0.987,0.716,1,0.716 V0.982 C1,0.992,0.992,1,0.982,1 H0.018 C0.008,1,0,0.992,0,0.982 V0.714 C0.013,0.714,0.023,0.704,0.023,0.691 C0.023,0.678,0.013,0.668,0,0.668 V0.018"></path>
      </clipPath>
    </svg>
    <div class="new-donation-modal__top-container" :style="`--bg-color:var(--${theme})`">
      <div class="new-donation-modal__user-info">
        <div class="new-donation-modal__avatar">
          <svg width="106" height="106" viewBox="0 0 106 106" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="53" cy="53" r="50.5" fill="#E7F0FF" stroke="white" stroke-width="5"/>
            <path
                d="M52.9998 51.1667C48.9497 51.1667 45.6665 47.8834 45.6665 43.8333C45.6665 39.7832 48.9497 36.5 52.9998 36.5C57.0499 36.5 60.3332 39.7832 60.3332 43.8333C60.3332 47.8834 57.0499 51.1667 52.9998 51.1667Z"
                stroke="#0059F7" stroke-width="2" stroke-linecap="round"/>
            <path
                d="M38.3333 69.5V67.6667C38.3333 61.5915 43.2581 56.6667 49.3333 56.6667H56.6666C62.7417 56.6667 67.6666 61.5915 67.6666 67.6667V69.5"
                stroke="#0059F7" stroke-width="2" stroke-linecap="round"/>
          </svg>
        </div>
        <div class="new-donation-modal__text-container">
          <AtInput
              v-model="alias"
              :maxlength="255"
              :placeholder="'alias'"
              size="normal"
          />
          <AtInput
              v-model="url"
              :maxlength="255"
              :placeholder="'URL'"
              size="normal"
          />


        </div>


      </div>

      <div>
        <AtInput
            v-model="title"
            :maxlength="255"
            :placeholder="'title'"
            size="normal"
        />
      </div>
      <div>
        <AtInput
            v-model="description"
            :maxlength="255"
            :placeholder="'description'"
            size="normal"
        />
      </div>
      <AtSelect class="new-donation-modal__color" v-model="theme">
        <AtOption :style="`--bg-color-option: var(--${themeOptionsEnum[themeOption]})`"
                  v-for="themeOption in themeOptions"
                  :key="themeOptionsEnum[themeOption]"
                  :value="themeOptionsEnum[themeOption]">
        </AtOption>
      </AtSelect>
      <div>
        <AtSelect multiple v-model="currenciesModel">
          <AtOption :label="`${flatCurrency.currency},${flatCurrency.network.alias}` "
                    v-for="flatCurrency in flatCurrencies"
                    :value="`${flatCurrency.id},${flatCurrency.network.id}`"
                    :key="flatCurrency.id + flatCurrency.network.id">
            <div>
              <p>{{ flatCurrency.currency }}</p>
              <p>{{ flatCurrency.network.alias }}</p>
            </div>
          </AtOption>
        </AtSelect>
      </div>
    </div>

    <div>
      <AtInput
          v-model="url"
          :maxlength="255"
          :placeholder="'URL'"
          size="normal"
      />
    </div>
    <div>
      <AtInput
          v-model="alias"
          :maxlength="255"
          :placeholder="'alias'"
          size="normal"
      />
    </div>
    <div>
      <AtInput
          v-model="title"
          :maxlength="255"
          :placeholder="'alias'"
          size="normal"
      />
    </div>
    <div>
      <AtInput
          v-model="description"
          :maxlength="255"
          :placeholder="'alias'"
          size="normal"
      />
    </div>
    <div>
      <AtInput
          v-model="avatarLink"
          :maxlength="255"
          :placeholder="'alias'"
          size="normal"
      />
    </div>

    <div>

    </div>

    <template #footer>
      <template>
        <AtButton @click="handleClose">
          {{ $t('create-order-modal.close') }}

        </AtButton>
        <AtButton
            @click="createPreCheckoutPageHandler"
            type="primary"
        >
          {{ $t('create-order-modal.create') }}
        </AtButton>
      </template>
      <!--      <AtButton-->

      <!--          class="new-order-modal__full-width-btn"-->
      <!--          type="primary"-->
      <!--      >-->
      <!--        {{ $t('create-order-modal.close') }}-->
      <!--      </AtButton>-->
    </template>


  </BaseModal>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {PRE_CHECKOUT_THEME_ENUM} from "../../../../common/constants/pre-checkout-info-theme-enum";
import BaseModal from "../BaseModal";

export default {

    name: "DonationModal",
    data() {
        return {
            url: null,
            alias: null,
            title: null,
            description: null,
            avatarLink: null,
            theme: PRE_CHECKOUT_THEME_ENUM.BLUE_GRADIENT,
            currenciesModel: [],
        };
    },
    components: {
        BaseModal,
    },
    methods: {
        ...mapActions('app', [
            'closeModal',
        ]),
        ...mapActions('donations', [
            'createPreCheckoutDonation',
            'fetchPreCheckoutPages',
        ]),
        handleClose() {
            this.closeModal();
        },
        createPreCheckoutPageHandler() {
            const {
                accountId,
                url,
                alias,
                title,
                description,
                avatarLink,
                theme,
            } = this;
            const tupleCurrenciesArray = this.currenciesModel.map((i) => {
                i = i.split(',');
                return i;
            });
            const args = {
                accountId,
                url,
                alias,
                title,
                description,
                avatarLink,
                theme,
                currencies: tupleCurrenciesArray,
            };
            this.createPreCheckoutDonation(args);
        },
    },
    computed: {
        ...mapGetters('app', [
            'sortedCurrencies',
            'isOpenModal',
        ]),
        ...mapState('accounts', {
            accounts: state => state.accounts,
        }),
        ...mapState('app', ['currencies']),
        themeOptionsEnum() {
            return PRE_CHECKOUT_THEME_ENUM;
        },

        accountId() {
            return this.accounts?.[0]?.accountId;
        },
        themeOptions() {
            return Object.keys(PRE_CHECKOUT_THEME_ENUM);
        },
        creating_order() {
            return this.$t('create-order-modal.creating_order');
        },
        flatCurrencies() {
            let currenciesFlat = [];
            for (const currency of this.currencies) {
                for (const currencyNetwork of currency.networks) {
                    currenciesFlat.push({...currency, network: currencyNetwork});
                }
            }
            return currenciesFlat;
        },
        isOpen() {

            const {
                $options: {
                    name,
                },
                isOpenModal,
            } = this;

            return isOpenModal(name);
        },
    },

};
</script>

<style lang="scss" scoped>
.svg {
  position: absolute;
  width: 0;
  height: 0;
}

.new-donation-modal {
  ::v-deep .at-modal {
    height: 820px;
    position: relative;
    -webkit-clip-path: url(#my-clip-path);
    clip-path: url(#my-clip-path);
  }

  &__top-container {
    height: 62%;
    background: var(--bg-color);
    border-radius: 12px;
  }

  &__color {
    max-width: 300px;

    ::v-deep .at-select__dropdown .at-select__list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 10px;
      padding: 10px;
    }

    ::v-deep .at-select__option {
      width: 48px;
      height: 48px;
      background: var(--bg-color-option);
      color: transparent;
      border-radius: 12px;
    }

    ::v-deep .at-select__selected {
      color: transparent;
    }
  }

  &__user-info {
    display: flex;
  }
}
</style>

