<template>
    <i class="icon" :class="iconClass" />
</template>

<script>

export default {
    name: 'Icon',
    props: {
        icon: {
            type: String,
            required: true,
        },
    },
    computed: {
        iconClass () {
            return `icon-${this.icon}`;
        },
    },
};

</script>