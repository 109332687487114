<template>
    <div class="item" @click.stop="goToDetails">
        <div class="item__element">
            <div v-if="item.status" :class="['item__status', `item__status--${status.status}`]"></div>
            <div class="item__element-value--black">
                {{ moment(item.createdAt).format('DD/MM/YY, HH:mm') }}
            </div>
        </div>
        <div class="item__element">
            <div v-if="item.inTransaction.txId" :class="['item__status', `item__status--${statusIn.status}`]"></div>
            <div class="item__element-value">
                {{ item.inTransaction.txId | getPrettyAddress(4) }}
            </div>
            <copy v-if="item.inTransaction.txId"  :value="item.inTransaction.txId" />
        </div>
        <div class="item__element">
            <div class="item__element-value--black">
                {{ amount | separatePeriods }}
            </div>
        </div>
        <div class="item__element">
            <div class="item__asset create-business-modal__padding-wrapper">
                <currency-network-icon :currency="currency.currency" :network="network.name.toUpperCase()" />
                <div class="item__asset-currency">
                    {{ currency.currency }}
                </div>
                <div class="item__asset-network">
                    {{ network.name | getNetworkShortNameByNetworkName }}
                </div>
            </div>
        </div>
        <div class="item__element">
            <div class="item__element-value">
                {{ item.inTransaction.address | getPrettyAddress(4) }}
            </div>
            <copy v-if="item.inTransaction.address" :value="item.inTransaction.address" />
        </div>
        <div class="item__element">
            <div v-if="statusOut.status" :class="['item__status', `item__status--${statusOut.status}`]"></div>
            <div class="item__element-value">
                {{ orphanOutHash }}
            </div>
            <copy v-if="orphanOutHash" :value="item.outTransaction?.txId" />
        </div>
<!--        <div  class="item__arrow">-->
<!--            <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.635 0.228C1.462 0.0850001 1.241 5.42458e-08 1 4.37114e-08C0.448 1.95827e-08 -1.95827e-08 0.448 -4.37114e-08 1L-3.0598e-07 7C-3.30108e-07 7.552 0.448 8 1 8C1.241 8 1.462 7.915 1.635 7.772L5.589 4.808C5.838 4.626 6 4.332 6 4C6 3.668 5.838 3.374 5.589 3.192L1.635 0.228Z" fill="var(&#45;&#45;new-front-text-primary-bg-primary)"/>-->
<!--            </svg>-->
<!--        </div>-->
    </div>
</template>
<script >

    import Copy from "@/public/components/common/copy.vue";
    import moment from "moment";
    import BigNumber from "bignumber.js";
    import CurrencyNetworkIcon from "@/public/components/elements/business-walet/Select/CurrencyNetworkIcon.vue";
    import {mapState} from "vuex";
    import {getPrettyAddress} from "@/public/filters";

    export default {
        name: "TableItem",
        components: {CurrencyNetworkIcon, Copy},
        props: {
            item: {
                type: Object,
                default: () => ({})
            }
        },
        computed: {
            ...mapState('app', ['networks', 'currencies']),
            network () {
                return this.networks.find(({ id }) => id === this.item.networkId)
            },
            currency () {
                return this.currencies.find(({ id }) => id === this.item.currencyId)
            },
            moment() {
                return moment
            },
            amount () {
                return new BigNumber(this.item.amount)
                    .toFixed()
            },
            statusOut () {
                if (!this.item?.outTransaction) {
                    return {
                        status: '',
                        label: ''
                    }
                }
                return {
                    status: this.item.outTransaction.status.toLowerCase(),
                    label: this.$t(`orphan.status-${this.item.outTransaction.status.toLowerCase()}`)
                }
            },
            orphanOutHash () {
                if (!this.item.outTransaction || !this.item.outTransaction.txId)  {
                    return ''
                }
                return getPrettyAddress(this.item.outTransaction.txId)
            },
            statusIn () {
                if (!this.item?.inTransaction) {
                    return {
                        status: '',
                        label: ''
                    }
                }
                return {
                    status: this.item.inTransaction.status.toLowerCase(),
                    label: this.$t(`orphan.status-${this.item.inTransaction.status.toLowerCase()}`)
                }
            },
            status () {
                if (!this.item?.status) {
                    return {
                        status: '',
                        label: ''
                    }
                }
                return {
                    status: this.item.status.toLowerCase(),
                    label: this.$t(`orphan.status-${this.item.status.toLowerCase()}`)
                }
            }
        },
        methods: {
            goToDetails () {
                this.$emit('openDetails', this.item.id)
                // this.$router.push(`/orphan-info/${  }`)
            }
        }
    }
</script>
<style scoped lang="scss">
.item {
  padding: 0 $p30;
  min-height: 105px;
  border-bottom: 1px var(--new-front-divider-color) solid;
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(105px,1fr));
  grid-auto-flow: column;
  align-items: center;
    position: relative;
    cursor: pointer;
  //&:hover {
  //  background: var(--new-front-bg-btn-primary-bg-primary-hover);
  //}
  &__element {
    display: flex;
    align-items: center;
    //justify-content: space-between;
    gap: 10px;
      &-value {
          color: var(--new-front-purple);
          text-decoration: underline;
          font-weight: 500;
          font-size: $h4;
          &--black {
              color: var(--new-front-primary-font-color);
              font-weight: 500;
              font-size: $h4;
          }
      }

  }
  &__asset {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &-currency {
      font-weight: 500;
      font-size: $h4;
      color: var(--new-front-primary-font-color);
      margin: 0 10px;
    }
    &-network {
      font-weight: 500;
      font-size: $h4;
      color: var(--new-front-secondary-font-color);
    }
  }
}
.item__status {
  width: 10px;
  height: 10px;
  border-radius:2px;
    background: #44B070;
    &--error {
        background: #CF5252;
    }
    &--rejected {
        background: #CF5252;
    }
    &--init {
        background: #4D63B2;
    }
    &--pending {
        background: #BE9B40;
    }
    &--processed {
        background: #44B070;
    }
}
.item__arrow {
    position: absolute;
    right: 0;
    width: 20px;
    height: 100%;
    cursor: pointer;
    > svg {
        position: relative;
        top: 44px;
    }
}
//
//PENDING
//PROCESSED
//ERROR
//REJECTED
</style>
