<template>
    <orphan-search
        :value="search"
        class="api-keys-search"
        :placeholder="$t('api_keys.search')"
        @input="(str) => $emit('search', str)"
    />
</template>

<script>

import AtInput from 'at-ui/src/components/input';
import OrphanSearch from "@/public/components/elements/orphan-transactions/OrphanSearch.vue";

export default {
    name: 'ApiKeysSearch',
    components: {
        OrphanSearch,
        AtInput,
    },
    props: {
      search: {
        type: String,
        default: ''
      }
    }
};

</script>

<style lang="css" scoped>
.api-keys-search ::v-deep .at-input__original {

  background: #FFFFFF;
  box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  border: none;
  min-height: 46px;
  padding-left: 30px;
}
.api-keys-search ::v-deep .at-input__icon {
  right: 30px;
}

</style>
