<script>
  import {mapActions, mapGetters, mapState} from "vuex";
  import BaseSelect from "@/public/components/common/uiKit/selects/base/index.vue";
  import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
  import BaseInput from "@/public/components/common/uiKit/input/base/index.vue";
  import BusinessWalletNetworkSelect
    from "@/public/components/elements/business-walet/Select/businessWalletNetworkSelect.vue";
  import CurrencyNetworkIcon from "@/public/components/elements/business-walet/Select/CurrencyNetworkIcon.vue";
  export default {
    name: "InfoBankInvoice",
    components: {
      CurrencyNetworkIcon,
      BusinessWalletNetworkSelect,
      BaseInput,
      BaseButton,
      BaseSelect,
      StoreModal: () => import(/* webpackChunkName: "/public/components/modals/StoreModal" */ '/public/components/modals/StoreModal'),
      NewSpinner: () => import (/* webpackChunkName: "Spinner" */ "/public/components/common/uiKit/spinner/index.vue"),
    },

    methods: {
      ...mapActions('app', ['closeModal']),
      handleCancelCreate() {
        const {
          closeModal,
        } = this;

        closeModal();
      },
    },
    computed: {
      ...mapGetters('app', [
        'sortedCurrencies',
        'isOpenModal',
      ]),
      ...mapState('app', ['currencies']),
      ...mapState('sepaSwift', ['isLoading', 'account']),
      ...mapGetters('app', ['getCurrencyNameById']),
      currency () {
        return this.currencies.find(({ id }) => id === this.account.currencyId)
      },
      isOpen() {

        const {
          $options: {
            name,
          },
          isOpenModal,
        } = this;

        return isOpenModal(name);
      },
      title() {
        return this.$t('bank.info');
      },
    }
  }
</script>

<template>
<StoreModal
    class="invoiceModal"
    :value="isOpen"
    :title="title"
    :show-close="true"
    name="InfoBankInvoice"
    @closeModal="handleCancelCreate"
>
  <template #header>
      <div class="title">
        {{ title }}
      </div>
  </template>
  <template #body>
    <div class="content">
      <div class="topBar">
        <div class="topBar__left">
          <div class="topBar__type">{{ account.type }}</div>
          <div class="topBar__alias">{{ account.companyName }}</div>
        </div>
        <div class="topBar__right">
          <currency-network-icon :currency="currency.currency" />
          <div class="topBar__right-currency">
            {{ currency.currency }}
          </div>
        </div>
      </div>
      <div class="info__item">
        <div class="info__item-title">
          IBAN
        </div>
        <div class="info__item-value">
          {{ account.iban }}
        </div>
      </div>
      <div class="info__item">
        <div class="info__item-title">
          {{ $t('bank.bank-address') }}
        </div>
        <div class="info__item-value">
          {{ account.bankAddress }}
        </div>
      </div>
      <div class="info__item">
        <div class="info__item-title">
          {{ $t('bank.bank') }}
        </div>
        <div class="info__item-value">
          {{ account.bankName }}
        </div>
      </div>
      <div class="info__item">
        <div class="info__item-title">
          {{ $t('bank.bank-code') }}
        </div>
        <div class="info__item-value">
          {{ account.bankCountryCode }}
        </div>
      </div>
      <div class="info__item">
        <div class="info__item-title">
          SWIFT/BIC
        </div>
        <div class="info__item-value">
          {{ account.bic }}
        </div>
      </div>
      <div class="info__item">
        <div class="info__item-title">
          {{ $t('bank.postal-code') }}
        </div>
        <div class="info__item-value">
          {{ account.postalCode }}
        </div>
      </div>
    </div>
  </template>
  <template #footer>
      <base-button  type="secondary" :label="$t('create-invoice-modal.close')"  @click="handleCancelCreate" />
  </template>
</StoreModal>
</template>

<style scoped lang="scss">
.title {
  font-size: $h1;
  font-weight: 500;
  color: var(--new-front-primary-font-color);
  text-align: center;
  @media (max-width: 450px) {
    font-size: $h2;
  }
}
.topBar {
  padding-bottom: 30px;
  border-bottom: 1px solid var(--new-front-border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__type {
    color: var(--new-front-secondary-font-color);
    font-weight: 500;
    font-size: $h4;
  }
  &__alias {
    font-size: $h2;
    font-style: normal;
    font-weight: 500;
    color: var(--new-front-primary-font-color);
  }
  &__right {
    display: flex;
    align-items: center;
    gap: 5px;
    &-currency {
      font-weight: 500;
      font-size: $h2;
      color: var(--new-front-primary-font-color);
    }
  }
  &::v-deep {
    .currency-network-icon-wrapper .currency-network-icon__currency {
      width: 36px;
      height: 36px;
    }
  }
}
.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.content {
  padding: 15px 30px 50px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.info__item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:last-of-type {
    border-bottom: 0;
  }
  &-title {
    font-size: $h4;
    font-weight: 400;
    color: var(--new-front-secondary-font-color);
  }
  &-value {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
    font-size: $h4;
    color: var(--new-front-primary-font-color);
    &--secondary {
      color: var(--new-front-secondary-font-color);
    }
    &--link {
      color: var(--new-front-purple);
      text-decoration: underline;
      a {
        color: var(--new-front-purple);
      }
      &::v-deep {
        .copy-wrapper {
          margin-right: 5px !important;
        }
      }
    }
  }
}
.select-item {
  display: flex;
  align-items: center;
  gap: 7px;

  min-height: 32px;
}
::v-deep {
  .options-list-item {
    margin-bottom: 10px;
  }
}
.error {
  color: var(--new-front-status-text-error);
}
</style>
