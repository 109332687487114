<template>
  <div>
    <div class="preview-order">
      <div class="main">
        <div class="preview-order__text-secondary">
          {{ type }}
        </div>
        <div class="preview-order__text-main">
          {{ date | getFormatDate('DD.MM.YYYY, HH:mm') }}
        </div>
      </div>
      <!--создание ордера-->
      <template v-if="checkingOrder">
        <div class="main main_id">
          <div v-if="orderId" class="preview-order__text-secondary">
            id:
          </div>
          <div v-if="orderId" class="preview-order__text-main">
            <CopyString
                :string="orderId"
                :successMessage="id_copied"
                :failMessage="id_not_copied"
                :title="notification"
                canCopy
            />
          </div>
        </div>

        <div class="main ">
          <div v-if="order" class="preview-order__text-secondary">
            {{ $t('advance_balances.order') }}:
          </div>
          <div v-if="order" class="preview-order__text-main">
            {{ order }}
          </div>
        </div>

        <div class="main ">
          <div v-if="received" class="preview-order__text-secondary">
            {{ $t('advance_balances.amount_received') }}:
          </div>
          <div v-if="received" class="preview-order__text-main">
            {{ received }}
          </div>
        </div>

        <div class="main main_amount">
          <div v-if="amountOrder" class="preview-order__text-secondary">
            {{ $t('advance_balances.amount') }}:
          </div>
          <div v-if="amountOrder" class="preview-order__text-main">
            {{ amountOrder }}
          </div>
        </div>
        <div class="main ">
          <p class="preview-order__text-secondary"> {{ $t('advance_balances.link_to_order') }}</p>
          <RouterLink
              class="preview-order__text-main preview-order__text-main_link"
              v-if="id"
              :to="{ name: 'order', params: {id} }"
          >
            {{ $t('advance_balances.link') }}
          </RouterLink>
        </div>
      </template>
      <!--        пополнение авансового баланса-->
      <template v-else-if="data.type === 'DEPOSIT' ||data.type === 'TRANSFER'">
        <div class="main main_id">
          <div v-if="fromAddress" class="preview-order__text-secondary">
            {{ $t('advance_balances.from_address') }}
          </div>
          <div v-if="fromAddress" class="preview-order__text-main">
            <CopyString
                :string="fromAddress"
                :successMessage="$t('copy.addresses_copied')"
                :fail-message="$t('copy.addresses_not_copied')"
                :title="notification"
                canCopy
            />
          </div>
        </div>
        <div class="main">
          <div v-if="depositCurrency" class="preview-order__text-secondary">
            {{ $t('advance_balances.currency') }}:
          </div>
          <div v-if="depositCurrency" class="preview-order__text-main preview-order__currency-container-desktop">
            <CurrencyImg
                class="preview-order__currency-img"
                :currency="depositCurrency"
            />
            {{ depositCurrency | getCurrencyTicker }}
          </div>
        </div>
        <div class="main ">
          <div v-if="networkId" class="preview-order__text-secondary">
            {{ $t('advance_balances.network') }}:
          </div>
          <div v-if="networkId" class="preview-order__text-main">
            {{ networkId | getNetworkName }}
          </div>
        </div>

        <div class="main ">
          <div v-if="orderTx && txLink" class="preview-order__text-secondary">
            {{ $t('checkout.contract_address') }}
          </div>
          <a v-if="orderTx && txLink" class="preview-order__text-main preview-order__text-main_link" :href="txLink"
             target="_blank">
            {{ contract }}
          </a>
        </div>
        <div class="main"/>
      </template>
      <!--создание вывода -->
      <template v-else-if="checkingWithdrawal">

        <div class="main main_id ">
          <div v-if="withdrawalId" class="preview-order__text-secondary">
            id:
          </div>
          <div v-if="withdrawalId" class="preview-order__text-main">
            <CopyString
                :string="withdrawalId"
                :successMessage="id_copied"
                :failMessage="id_not_copied"
                :title="notification"
                canCopy
            />
          </div>
        </div>
        <div class="main ">
          <div v-if="withdrawalCurrency" class="preview-order__text-secondary">
            {{ $t('advance_balances.currency') }}:
          </div>
          <div v-if="withdrawalCurrency" class="preview-order__text-main preview-order__currency-container-desktop">
            <CurrencyImg
                class="preview-order__currency-img"
                :currency="withdrawalCurrency"
            />
            {{ withdrawalCurrency | getCurrencyTicker }}
          </div>
        </div>
        <div class="main ">
          <div v-if="withdrawalNetwork" class="preview-order__text-secondary">
            {{ $t('advance_balances.network') }}:
          </div>
          <div v-if="withdrawalNetwork" class="preview-order__text-main">
            {{ withdrawalNetwork | getNetworkName }}
          </div>
        </div>
        <div class="main ">
          <div v-if="withdrawalAmount" class="preview-order__text-secondary">
            {{ $t('advance_balances.amount') }}:
          </div>
          <div v-if="withdrawalAmount" class="preview-order__text-main">
            {{ withdrawalAmount }}
          </div>
        </div>
        <div class="main ">
          <p class="preview-order__text-secondary">
            {{ $t('advance_balances.link_to_withdrawal') }}</p>
          <RouterLink
              class="preview-order__text-main preview-order__text-main_link"
              v-if="withdrawalId"
              :to="{ name: 'request', params: {id: withdrawalId} }"
          >
            {{ $t('advance_balances.link') }}
          </RouterLink>
        </div>

      </template>
      <!--        возврат и списание комиссии и пр -->
      <template v-else>
        <div class="main ">
        </div>
        <div class="main ">
        </div>
        <div class="main ">
        </div>
        <div class="main ">
        </div>
        <div class="main ">
        </div>
      </template>

      <div class="main">
        <p class="preview-order__text-secondary">{{ $t('advance_balances.balance') }}:</p>
        <p class="preview-order__text-main">{{ balance }}</p>
      </div>
      <div class="amount" :style="`--statusColor: ${isIncr ? '#27AE60' : '#EB5757'}`">
        <p class="amount__color">{{ amountColor }} {{ accountCurrency | getCurrencyTicker }}</p>

      </div>
    </div>

    <div class=" preview-order_mobile">
      <div class="preview-order__mobile">
        <AtCollapse accordion :value="id">
          <AtCollapseItem data="">
            <div class="preview-order__preview" slot="title">
              <div class=" preview-order__currency">

                <div class="preview-order__currency-container">
                  <p class="preview-order__text-main ">{{ type }}</p>
                  <p class="preview-order__currency-text">
                    {{ date | getFormatDate('DD.MM.YYYY, HH:mm') }}</p>
                </div>
                <div class="preview-order__amount-container">
                  <p class="preview-order__text-main">{{ balance }}</p>
                  <div class="amount" :style="`--statusColor: ${isIncr ? '#27AE60' : '#EB5757'}`">
                    <p class="amount__color">{{ amountColor }}</p>
                  </div>
                </div>
              </div>
              <!--              <p class="preview-order__currency-amount">-->
              <!--                {{ amount }}-->
              <!--              </p>-->
            </div>
            <div class="preview-order__detailed-info">
              <template v-if="checkingOrder">
                <div class="preview-order__row">
                  <p v-if="orderId" class="preview-order__text-main preview-order__text-main_title">
                    id:
                  </p>
                  <div v-if="orderId" class="preview-order__text-main">
                    <CopyString
                        :string="orderId"
                        :successMessage="id_copied"
                        :failMessage="id_not_copied"
                        :title="notification"
                        canCopy
                    />
                  </div>
                </div>
                <div class="preview-order__row">
                  <p v-if="order" class="preview-order__text-main preview-order__text-main_title">
                    {{ $t('advance_balances.order') }}:
                  </p>
                  <p v-if="order" class="preview-order__text-main">
                    {{ order }}
                  </p>
                </div>
                <div class="preview-order__row">
                  <p v-if="received" class="preview-order__text-main preview-order__text-main_title">
                    {{ $t('advance_balances.amount_received') }}:
                  </p>
                  <p v-if="received" class="preview-order__text-main">
                    {{ received }}
                  </p>
                </div>
                <div class="preview-order__row">
                  <p v-if="amountOrder" class="preview-order__text-main preview-order__text-main_title">
                    {{ $t('advance_balances.amount') }}:
                  </p>
                  <p v-if="amountOrder" class="preview-order__text-main">
                    {{ amountOrder }}
                  </p>
                </div>
                <div class="preview-order__row">
                  <p class="preview-order__text-main preview-order__text-main_title">Ссылка</p>
                  <RouterLink
                      class="preview-order__text-main preview-order__text-main_link"
                      v-if="id"
                      :to="{ name: 'order', params: {id} }"
                  >
                    {{ $t('advance_balances.link_to_order') }}
                  </RouterLink>
                </div>
              </template>
              <template v-else-if="data.type === 'DEPOSIT' || data.type === 'TRANSFER'">
                <div class="preview-order__row" v-if="fromAddress">
                  <div v-if="fromAddress" class="preview-order__text-main preview-order__text-main_title">
                    {{ $t('advance_balances.from_address') }}
                  </div>
                  <div v-if="fromAddress" class="preview-order__text-main">
                    <CopyString
                        :string="fromAddress"
                        :successMessage="$t('copy.addresses_copied')"
                        :fail-message="$t('copy.addresses_not_copied')"
                        :title="notification"
                        canCopy
                    />
                  </div>
                </div>
                <div class="preview-order__row" v-if="depositCurrency">
                  <p v-if="depositCurrency" class="preview-order__text-main preview-order__text-main_title">
                    {{ $t('advance_balances.currency') }}
                  </p>
                  <div v-if="depositCurrency"
                       class="preview-order__text-main preview-order__currency-container-desktop">
                    <CurrencyImg
                        class="preview-order__currency-img"
                        :currency="depositCurrency"
                    />
                    {{ depositCurrency | getCurrencyTicker }}
                  </div>
                </div>
                <div class="preview-order__row" v-if="networkId">
                  <p v-if="networkId" class="preview-order__text-main preview-order__text-main_title">
                    {{ $t('advance_balances.network') }}:
                  </p>

                  <p v-if="networkId" class="preview-order__text-main preview-order__text-main_title">
                    {{ networkId | getNetworkName }}
                  </p>
                </div>
                <div class="preview-order__row" v-if="orderTx && txLink">
                  <p v-if="orderTx && txLink" class="preview-order__text-main preview-order__text-main_title">
                    {{ $t('checkout.contract_address') }}
                  </p>
                  <a v-if="orderTx && txLink" class="preview-order__text-main preview-order__text-main_link"
                     :href="this.txLink" target="_blank">
                    {{ contract }}

                  </a>

                </div>
              </template>
              <template v-else-if="checkingWithdrawal">

                <div class="preview-order__row">
                  <div v-if="withdrawalId" class="preview-order__text-main preview-order__text-main_title">
                    id:
                  </div>
                  <div v-if="withdrawalId" class="preview-order__text-main">
                    <CopyString
                        :string="withdrawalId"
                        :successMessage="id_copied"
                        :failMessage="id_not_copied"
                        :title="notification"
                        canCopy
                    />
                  </div>
                </div>
                <div class="preview-order__row">
                  <div v-if="withdrawalCurrency" class="preview-order__text-main preview-order__text-main_title">
                    {{ $t('advance_balances.currency') }}:
                  </div>
                  <div v-if="withdrawalCurrency"
                       class="preview-order__text-main preview-order__currency-container-desktop">
                    <CurrencyImg
                        class="preview-order__currency-img"
                        :currency="withdrawalCurrency"
                    />
                    {{ withdrawalCurrency | getCurrencyTicker }}
                  </div>
                </div>
                <div class="preview-order__row">
                  <div v-if="withdrawalNetwork" class="preview-order__text-main preview-order__text-main_title">
                    {{ $t('advance_balances.network') }}:
                  </div>
                  <div v-if="withdrawalNetwork" class="preview-order__text-main">
                    {{ withdrawalNetwork | getNetworkName }}
                  </div>
                </div>
                <div class="preview-order__row">
                  <div v-if="withdrawalAmount" class="preview-order__text-main preview-order__text-main_title">
                    {{ $t('advance_balances.amount') }}:
                  </div>
                  <div v-if="withdrawalAmount" class="preview-order__text-main">
                    {{ withdrawalAmount }}
                  </div>
                </div>
                <div class="preview-order__row">
                  <p class="preview-order__text-main preview-order__text-main_title">
                    {{ $t('advance_balances.link_to_withdrawal') }}</p>
                  <RouterLink
                      class="preview-order__text-main preview-order__text-main_link"
                      v-if="withdrawalId"
                      :to="{ name: 'request', params: {id: withdrawalId} }"
                  >
                    {{ $t('advance_balances.link') }}
                  </RouterLink>
                </div>

              </template>
            </div>

          </AtCollapseItem>
        </AtCollapse>
      </div>

    </div>

  </div>
</template>

<script>
import {mapState} from "vuex";
import CopyString from "../CopyString";
import CurrencyImg from "../CurrencyImg";
import {BlockchainLinks} from "../../../mixins/blockchain-links";
import BigNumber from "bignumber.js";

const types = {
    DEPOSIT: "advance_balances.Replenishment",
    PARTNER_DEPOSIT: "advance_balances.Replenishment",
    WITHDRAWAL: "advance_balances.withdrawal",
    WITHDRAWAL_INIT: "advance_balances.withdrawal_fee",
    TRANSACTION_INIT: "advance_balances.creating_an_order",
    TRANSACTION_PROCESSED: "advance_balances.deposit_by_order",

    ORDER_INIT_FEE: "advance_balances.creating_an_order",
    ORDER_DEPOSIT_FEE: "advance_balances.deposit_by_order",
    WALLET_DEPOSIT_FEE: 'advance_balances.wallet_deposit_fee',
    RECURRENT_DEPOSIT_FEE: 'advance_balances.recurrent_deposit_fee',
    PERSONAL_DEPOSIT_FEE: 'advance_balances.personal_deposit_fee',
    PAYOUT_DEPOSIT_FEE: 'advance_balances.payout_deposit_fee',
    WALLET_WITHDRAWAL_FEE: 'advance_balances.wallet_withdrawal_fee',
    RECURRENT_WITHDRAWAL_FEE: 'advance_balances.recurrent_withdrawal_fee',
    PERSONAL_WITHDRAWAL_FEE: 'advance_balances.personal_withdrawal_fee',
    PAYOUT_WITHDRAWAL_FEE: 'advance_balances.payout_withdrawal_fee',

    TRANSFER: "advance_balances.transfer",
    TRANSFER_FEE: "advance_balances.transfer_fee",
    SYSTEM_INCR: "advance_balances.Replenishment",
    SYSTEM_DECR: "advance_balances.write-downs",
    REFUND: 'advance_balances.refund',
    BONUS_ACCRUAL: 'advance_balances.bonus_accrual',
    BRIDGE_EXTERNAL_FEE: 'advance_balances.bridge_external_fee',
    BRIDGE_INTERNAL_FEE: 'advance_balances.bridge_internal_fee',
    KYT_TRANSACTION_FEE: 'advance_balances.kyt-transaction-fee',
    KYT_WITHDRAWAL_ADDRESS_FEE: 'advance_balances.kyt-withdrawal-address-fee',
    EXCHANGE_AUTO: "advance_balances.exchange-auto",
    EXCHANGE_AUTO_FEE: "advance_balances.exchange-auto",
    ORPHAN_DEPOSIT_WITHDRAWAL_FEE: "advance_balances.orphan-deposit-withdrawal",
    ORPHAN_DEPOSIT_WITHDRAWAL: "advance_balances.orphan-deposit-withdrawal",
};

export default  {
    name: 'PreviewAccountHistory',
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    mixins: [
        BlockchainLinks,
    ],
    components: {
        CopyString,
        CurrencyImg,
    },
    computed: {
        ...mapState('accounts', ['accounts']),
        accountId() {
            return this.$route.params.accountId;
        },
        accountCurrency() {
            return this.accounts.find(a => a.accountId === this.accountId)?.currencyId;
        },
        type() {
            return this.data ? this.$t(types[this.data?.type]) : '-';
        },
        amount() {
            return this.data?.amount;
        },
        date() {
            return this.data?.date;
        },
        balance() {
            let number = new BigNumber(this.data?.balance).decimalPlaces(2);
            return number.toFixed();
        },
        isIncr() {
            return this.amount?.indexOf('-') === -1;
        },
        deposit() {
            return this.data?.deposit;
        },
        networkId() {
            return this.data?.deposit?.networkId;
        },
        fromAddress() {
            return this.data?.deposit?.from;
        },
        depositCurrency() {
            return this.data?.deposit?.currencyId;
        },
        id_copied() {
            return this.$t('copy.id_copied');
        },
        id_not_copied() {
            return this.$t('copy.id_not_copied');link
        },
        notification() {
            return this.$t('copy.notification');
        },
        checkingOrder() {
            return !!this.data.orderData;
        },
        id() {
            return this.data?.orderData?.id;
        },
        orderTx() {
            return this.data?.deposit?.tx;
        },
        contract() {
            return this.$linkName(this.orderTx, this.networkId);
        },
        orderId() {
            return this.data?.orderData?.id;
        },
        received() {
            let number = new BigNumber(this.data?.orderData?.received).decimalPlaces(2);
            return number.toFixed();
        },
        amountOrder() {
            let number = new BigNumber(+this.data?.orderData?.amount).decimalPlaces(2);
            return number.toFixed();
        },
        order() {
            return this.data?.orderData.order;
        },
        checkingWithdrawal() {
            return !!this.data?.withdrawalData;
        },
        withdrawalId() {
            return this.data?.withdrawalData?.id;
        },
        withdrawalCurrency() {
            return this.data?.withdrawalData?.currencyId;
        },
        withdrawalNetwork() {
            return this.data?.withdrawalData?.networkId;
        },
        withdrawalAmount() {
            return this.data?.withdrawalData?.amount;
        },

        txLink() {
            return this.$txLink(this.orderTx, this.networkId);
        },

        amountColor() {
            let arrayAmount = this.amount.split(' ');
            let number = new BigNumber(+arrayAmount[arrayAmount.length - 1]).decimalPlaces(2);
            return `${arrayAmount[0]} ${number.toFixed()}`;
        },
    },
};

</script>

<style lang="scss" scoped>

.preview-order {
  position: relative;
  align-items: flex-start;
  color: var(--new-front-primary-font-color);
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 5px;
  border-left: none;
  border-right: none;
  min-height: 78px;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);


  &__currency-container-desktop {
    display: flex;
    align-items: center;
  }

  &__text-main {
    font-family: var(--new-front-font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--new-front-primary-font-color);

    &_link {
      color: var(--blue-300);
    }
  }

  &__text-secondary {
    font-family: var(--new-front-font-family);
    font-size: var(--font-size-text-secondary);
    line-height: var(--line-height);
    color: var(--new-front-primary-font-color);
  }

  &_mobile {
    display: none;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  &__id {
    position: relative;
    z-index: 2;
  }

  &__currency {
    display: flex;
    align-items: center;
  }

  &__currency-img {
    margin-right: 12px;
    width: 24px;
    height: 24px;
  }

  .main {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 10px;

    &_amount {
      padding-top: 10px;
      justify-self: center;
      align-items: flex-start;
      width: 100%;
    }

    &_id {
      padding-top: 10px;
      justify-self: center;
    }

    &:first-child {
      padding-left: 40px;
    }


    .title {
      font-family: var(--new-front-font-family);
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: var(--new-front-primary-font-color);
    }

    .timestamp {
      display: flex;
      align-items: center;
      font-family: var(--new-front-font-family);
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: var(--new-front-primary-font-color);

      ::v-deep .copy-string__string {
        font-family: var(--new-front-font-family);
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .amount {
    display: flex;
    justify-self: flex-start;
    padding-top: 10px;
    align-self: flex-end;
    padding-bottom: 10px;

    &__color {
      font-family: var(--new-front-font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: var(--statusColor);
    }
  }

  .address {
    position: relative;

    &.link {
      cursor: pointer;

      color: #5ECAE5;
    }
  }
}


@media screen and (max-width: 1600px) {
  .preview-order {
    display: none;

    &__text-main {
      &_title {
        color: var(--new-front-primary-font-color);
      }
    }

    &__currency {
      width: 100%;
      justify-content: space-between;
    }

    &__detailed-info {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    &__read-more {
      width: 100%;
      max-width: 300px;
      border-radius: 12px;
      background: #0059F7;
      font-family: var(--new-front-font-family);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: var(--new-front-primary-font-color);
      min-height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 25px;
      margin-bottom: 20px;
    }

    &__currency-text {
      font-family: var(--new-front-font-family);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: var(--new-front-primary-font-color);
    }

    &__currency-status {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: var(--colorStatus);
    }

    &__currency-amount {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: right;
      color: var(--new-front-primary-font-color);
    }

    &__currency-container {
      display: flex;
      flex-direction: column;
    }

    &__row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 40px;
      border-top: 1px solid var(--border-color);

      &:last-of-type {
        border-bottom: none;
      }
    }

    &__title-text {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: var(--new-front-primary-font-color);
    }

    &__preview {
      display: flex;
      justify-content: space-between;
      flex: 1;
      padding-right: 40px;
      align-items: center;
    }


    &_mobile {
      display: block;

      ::v-deep .at-collapse {
        border: 1px solid var(--border-color);
        border-right: none;
        border-left: none;
      }

      ::v-deep .at-collapse__content {
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 0;
        background: var(--secondary-color);
      }

      ::v-deep .at-collapse__header {
        min-height: 50px;
        display: flex;
        align-items: center;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 0;
        padding-bottom: 0;
        background: var(--secondary-color);
      }

      ::v-deep .at-collapse__icon {
        right: 40px;
        top: 17px;
        font-size: 20px;
        left: unset;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }

      ::v-deep .at-collapse__item--active > .at-collapse__header .at-collapse__icon {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
      }

    }
  }
  .amount {
    display: flex;
    justify-content: flex-end;

    &__color {
      color: var(--statusColor);
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
    }
  }
}


@media screen and (max-width: 1180px) {
  .preview-order {
    display: none;

    &__text-main {
      &_title {
        color: var(--new-front-primary-font-color);
      }
    }

    &__currency {
      width: 100%;
      justify-content: space-between;
    }

    &__detailed-info {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    &__read-more {
      width: 100%;
      max-width: 300px;
      border-radius: 12px;
      background: #0059F7;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: var(--new-front-primary-font-color);
      min-height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 25px;
      margin-bottom: 20px;
    }

    &__currency-text {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: var(--new-front-primary-font-color);
    }

    &__currency-status {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: var(--new-front-primary-font-color);
    }

    &__currency-amount {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: right;
      color: var(--new-front-primary-font-color);
    }

    &__currency-container {
      display: flex;
      flex-direction: column;
    }

    &__row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 40px;
      border-top: 1px solid var(--border-color);

      &:last-of-type {
        border-bottom: none;
      }
    }

    &__title-text {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: var(--new-front-primary-font-color);
    }

    &__preview {
      display: flex;
      justify-content: space-between;
      flex: 1;
      padding-right: 40px;
      align-items: center;
    }


    &_mobile {
      display: block;

      ::v-deep .at-collapse {
        border: 1px solid var(--border-color);
        border-right: none;
        border-left: none;
      }

      ::v-deep .at-collapse__content {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 0;
      }

      ::v-deep .at-collapse__header {
        min-height: 50px;
        display: flex;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 0;
        padding-bottom: 0;
      }

      ::v-deep .at-collapse__icon {
        right: 10px;
        top: 17px;
        font-size: 20px;
        left: unset;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }

      ::v-deep .at-collapse__item--active > .at-collapse__header .at-collapse__icon {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
      }

    }
  }
  .amount {
    display: flex;

    &__color {
      color: var(--statusColor);
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
    }
  }
}


</style>
