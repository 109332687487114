<template>
    <div :class="[listAddress && listAddress.length ? 'history-address__list' : 'history-address__list-empty']">
      <div v-if="listAddress && listAddress.length">
        <TransactionsListHeader
          class="transactions__list-header"
          :filters="filters"
        />
        <PreviewTransaction
          v-for="(transaction, key) in mappedList"
          :key="key"
          routeName="historyAddress"
          :data="transaction"
        />
      </div>
      <template v-else>
        {{ $t('common.empty') }}
      </template>
    </div>
</template>

<script>
import TransactionsListHeader from "./TransactionsListHeader";
import PreviewTransaction from "./PreviewTransaction";
import {mapActions, mapState} from "vuex";

export default {
    name: "HistoryAddress",
    components: {
      TransactionsListHeader,
      PreviewTransaction
    },
    props: {
      filters: {
        type: Object,
        default: () => ({})
      },
    },
    computed: {
      ...mapState('historyAddresses', ['listAddress']),
      ...mapState('addressBook', {
        addressBook: state => state.listCurrenciesAddress,
      }),
      mappedList () {
        return this.listAddress.map((item) => ({
          ...item,
          addressBookAlias: this.addressBook.find(el => item.addressFrom === el.address)?.alias || '',
          addressToBookAlias: this.addressBook.find(el => item.addressTo === el.address)?.alias || ''
        }))
      }
    },
    methods: {
      ...mapActions('addressBook', [
        'getAddressBook',
      ]),
    },
    created() {
      this.getAddressBook({
        route: this.$route,
        router: this.$router,
        showUrlFilters: false,
        page: 1,
        limit: 9999
      });
    }
};
</script>

<style scoped lang="scss">
.history-address {

  &__list {
    overflow-x: scroll;
    margin: 0 30px;
  }

  @media screen and (max-width: 576px) {
    &__list {
      overflow-x: unset;
      margin: 0 15px;
    }
  }

  &__list-empty {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--new-front-secondary-font-color);
    font-size: $h5;

    margin-bottom: 10px;
  }
}
</style>
