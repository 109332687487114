<template>
<div class="currency-network-icon-wrapper">
    <img
            v-if="currency"
            :src="currencyImg"
            :alt="currency"
            class="circle-icon"
    />
</div>
</template>

<script>
    export default {
        name: "DashboardCurrencyIcon",
        props: {
            /**
             * Примеры пропса.
             * @param currency 'BCH' 'BUSD' 'LTC'
             */
            currency: {
                type: String,
                default: undefined
            },
        },
        computed: {
            currencyImg({ currency }) {
                return new URL(`../../../assets/image/circleIcons/currencies/${currency.toUpperCase()}.svg`, import.meta.url).href;
            },
        },
    }
</script>

<style lang="scss">
.currency-network-icon-wrapper {
  position: relative;

  display: flex;

}
.circle-icon {
  width: 46px;
  height: 46px;
}

</style>
