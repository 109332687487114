import getOrganizationsQuery from "/public/graphQl/queries/getOrganizations";

export const fetchOrganizations = async ({ commit, dispatch, rootGetters }) => {
    const selectedOrganizationId = rootGetters['app/getSelectedOrganizationId'];
    let organizationsData;

    try {
        organizationsData = await dispatch('request', {
            type: 'query',
            gql: getOrganizationsQuery,
        });
    } catch (error) {
        return error;
    }

    if (organizationsData.message) {
        throw organizationsData.message;
    }

    if (organizationsData.errors) {
        throw organizationsData.errors;
    }

    const {
        data: { getOrganizations: organizations },
    } = organizationsData;

    // Если не было выбрано ни одной организации, то назначаем первую
    if (!selectedOrganizationId && organizations.length) {
        commit('selectOrganization', organizations[0].id);
    }

    commit('organizations', organizations);

    return true;
};
