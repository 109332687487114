import gql from 'graphql-tag';

export default gql`
    mutation createBankAccount(
        $organizationId: String!,
        $alias: String,
        $type: BankAccountType!,
        $iban: String!,
        $bic: String,
        $account: String!,
        $companyName: String!,
        $bankAddress: String!,
        $bankName: String!,
        $bankCountryCode: String!,
        $postalCode: String!,
        $currencyId: String!
    ) {
        createBankAccount(
            organizationId: $organizationId,
            alias: $alias,
            type: $type,
            iban: $iban,
            bic: $bic,
            account: $account,
            companyName: $companyName,
            bankAddress: $bankAddress,
            bankName: $bankName,
            bankCountryCode: $bankCountryCode,
            postalCode: $postalCode,
            currencyId: $currencyId,
        ) {
            id
            organizationId
            alias
            type
            status
            moderationMessage
            currencyId
            iban
            bic
            account
            companyName
            bankAddress
            bankName
            bankCountryCode
            postalCode
            createdAt
        }
    }
`;
