import gql from 'graphql-tag';

export default gql`
     mutation($organizationId: String!, $aml: Boolean) {
         enableCollecting(organizationId: $organizationId, aml: $aml) {
            id
            address
            tag
            currencyId
            networkId
            amount
            amountLastInterval
            amountUSD
            amountUSDLastInterval
            amountEUR
            amountEURLastInterval
            riskLevel
            alias
            comment
         }
    }
`;
