import getTransactionQuery from "/public/graphQl/queries/getTransaction";

export const fetchTransaction = async ({dispatch, commit, rootGetters}, payload) => {
    let transactionData;

    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    const variables = {
        organizationId,
        ...payload,
    };
    commit('setTransactionInfoState', true);
    try {
        // commit('app/toggleLoading', null, {root: true});
        commit('setFetching', true);
        transactionData = await dispatch('app/request', {
            type: 'query',
            gql: getTransactionQuery,
            variables,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        // commit('app/toggleLoading', null, {root: true});
        commit('setFetching', false);
    }

    if (transactionData.message) {
        throw transactionData.message;
    }

    if (transactionData.errors) {
        throw transactionData.errors;
    }

    const {
        data: {getTransaction},
    } = transactionData;
    commit('setTransaction', getTransaction);
    return getTransaction;
};
