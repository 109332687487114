<template>
  <div
    class="currency-icon"
    :class="{ active }"
  >
    <img
      v-if="!!name"
      :src="langImg"
    />
  </div>

</template>

<script>
export default {
  name: "LanguagesIcons",
  props: {
    name: {
      type: String,
      default: undefined
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    langImg({ name }) {
      return `/assets/image/countries/${name}.png`;
    },
  },
}
</script>

<style lang="scss">
  .currency-icon {
    width: 34px;
    height: 34px;

    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    border-color: #A0A0A0;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;

    img {
      width: 34px;
      height: 35px;
    }

    &.active {
      border-color: #3C72EB;
    }

    @media screen and (max-width: 480px) {
      width: 28px;
      height: 28px;

      img {
        width: 28px;
        height: 29px;
      }
    }
  }
</style>
