export const FEE_TOKEN_SOURCE = {
    ADVANCED_BALANCE: 'advanced_balance',
    WALLET: 'wallet',

    ADDRESS: 'address',

    ACCOUNT: 'account',
    BALANCE: 'balance',

    NATIVE: 'native'
};
