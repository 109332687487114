<template>
  <div v-if="dialogVisible">
    <div
      class="main-modal-overlay"
      @click.stop="outsideClickHandle"
    />
    <div
      ref="modal"
      class="main-modal"
      :class="{ 'dark': darkMode }"
      :style="mainModalStyles"
    >
      <div class="main-modal-wrapper">
        <div class="main-modal-header">
          <slot name="header" />
        </div>
        <div class="main-modal-body">
          <slot name="body" />
        </div>
        <div class="main-modal-footer">
          <slot name="footer" v-bind="{ close }" />
        </div>
        <div v-if="closeBtn" class="close-button">
          <svg
            @click.stop="close"
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
                d="M0.96967 0.96967C1.26256 0.676777 1.73744 0.676777 2.03033 0.96967L6 4.93934L9.96967 0.96967C10.2626 0.676777 10.7374 0.676777 11.0303 0.96967C11.3232 1.26256 11.3232 1.73744 11.0303 2.03033L7.06066 6L11.0303 9.96967C11.3232 10.2626 11.3232 10.7374 11.0303 11.0303C10.7374 11.3232 10.2626 11.3232 9.96967 11.0303L6 7.06066L2.03033 11.0303C1.73744 11.3232 1.26256 11.3232 0.96967 11.0303C0.676777 10.7374 0.676777 10.2626 0.96967 9.96967L4.93934 6L0.96967 2.03033C0.676777 1.73744 0.676777 1.26256 0.96967 0.96967Z"
                fill="var(--new-front-primary-font-color)"
            />
          </svg>
        </div>
        <div v-if="loading" class="loader">
          <spinner size="small" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
  name: "BaseStoreDialog",
  components: {
    Spinner: () => import (/* webpackChunkName: "Spinner" */ "/public/components/common/uiKit/spinner/index.vue"),
  },
  props: {
    name: {
      type: String,
      default: undefined
    },
    closeBtn: {
      type: Boolean,
      default: true
    },
    darkMode: {
      type: Boolean,
      default: true
    },
    backgroundColor: {
      type: String,
      default: '#FFFFFF',
    },
    height: {
      type: [String, Number],
      default: undefined,
    },
    width: {
      type: String,
      default: '640px',
    },
    top: {
      type: String,
      default: '50%',
    },
    loading: {
      type: Boolean,
      default: false
    },
    outsideClose: {
      type: Boolean,
      default: true,
    },
    disableDestroyClose: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    openedModal: {
      handler(v) {
        this.dialogVisible = !!v && v === this.name
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      dialogVisible: false,
    }
  },
  computed: {
    ...mapState('app', {
      openedModal: state => state.modal.opened,
      themeStatus: state => state.theme,
    }),
    mainModalStyles({ height, width, top, backgroundColor }) {
      return ({
        maxHeight: `${height}`,
        maxWidth: `${width}`,
        top
      });
    }
  },
  // beforeDestroy() {
  //     // TODO: Костыль для пэйинов, когда переписаны будут на новые модалки костыль нужно убрать
  //     if (this.openedModal === 'CreateWithdrawalRequestModal') return
  //     if(this.$props.disableDestroyClose) return;
  //     console.log('destroy closedModal', this.$props.disableDestroyClose,this.$props );
  //       this.closeModal()
  // },
  methods: {
    ...mapActions('app',[
      'closeModal',
    ]),
    outsideClickHandle () {
      if(!this.outsideClose) return
      this.close()
      console.log('outside');
    },
    close() {
      this.$emit('update:loading', false)
      this.$emit('closeModal')
      this.closeModal()
      console.log('event');
    },
  }
}
</script>

<style scoped lang="scss">
.main-modal {
  position: fixed;
  z-index: 10002;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 640px;
  box-shadow: 0 0.747159px 1.93708px rgba(20, 25, 80, 0.0196802), 0 1.79553px 4.65507px rgba(20, 25, 80, 0.0282725), 0 3.38082px 8.76509px rgba(20, 25, 80, 0.03), 0 6.0308px 15.6354px rgba(20, 25, 80, 0.0417275), 0 11.28px 29.2443px rgba(20, 25, 80, 0.0503198), 0 27px 70px rgba(20, 25, 80, 0.07);
  border-radius: 30px;

  background: var(--new-front-bg-modal-primary);

  &::-webkit-scrollbar {
    width: 0; /* width of the entire scrollbar */
  }
  &::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }
  &::-webkit-scrollbar-thumb {
    background: transparent; /* color of the tracking area */
    width: 0;
  }
  @media (max-width: 576px) {
    max-height: 100%;
    overflow-y: auto;
  }
  &-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 100vh;

    .main-modal-header {
      position: relative;
      max-height: 160px;
      width: 100%;
      display: flex;
      padding: 30px;
      flex-direction: column;

      -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
      -moz-box-sizing: border-box;    /* Firefox, other Gecko */
      box-sizing: border-box;         /* Opera/IE 8+ */
    }

    .main-modal-body {
      width: 100%;
      -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
      -moz-box-sizing: border-box;    /* Firefox, other Gecko */
      box-sizing: border-box;         /* Opera/IE 8+ */
    }

    .main-modal-footer {
      display: flex;
      flex-direction: column;
      justify-content: end;

      -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
      -moz-box-sizing: border-box;    /* Firefox, other Gecko */
      box-sizing: border-box;         /* Opera/IE 8+ */

      //height: 160px;
      width: 100%;
      padding: 20px;
    }

    .close-button {
      cursor: pointer;
      position: absolute;
      z-index: 2;
      top: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-overlay {
    position: fixed;
    z-index: 10001;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.5; //если вдруг overlay поменял свою прозрачность не спешшите ее менять здесь - проблема связана с множественным открытием диалоговых окон с одним названием
    //backdrop-filter: blur(19.5px);
  }

  .loader {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: var(--new-front-loader-bg-modal-bg-primary);
    border-radius: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  //@include below_tablet {
  //  max-width: 95% !important;
  //  border-radius: 10px;
  //}
  //@include below_360 {
  //  height: 98% !important;
  //}
}
</style>
