import gql from 'graphql-tag';

export default gql`
    query ($organizationId: String!, $merchantId: String!, $status: ESubscriberBillingLinkStatus, $currencyId: String, $networkId: String, $query: String, $offset: Int!, $limit: Int!) {
         getBillingLinks(
            organizationId: $organizationId,
            merchantId: $merchantId,
            status: $status,
            currencyId: $currencyId,
            networkId: $networkId,
            query: $query,
            offset: $offset,
            limit: $limit,
          ) {
          totalCount
          billingLinks {
            id
            merchantId
            currencyId
            networkId
            status
            createdAt
            subscriberInfo {
              email
              clientId
              address
            }
       }
          }
    }
`;
