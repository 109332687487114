<template>
  <BaseModal
    class="two-fa-auth-qrcode-modal"
    width="100%"
    :value="isOpen"
    :show-head="true"
    :show-footer="true"
    show-close
    @on-cancel="checkCloseModal"
  >
    <template #header>
<!--  полностью переписываем слот заголовка модалки-->
      <div
        v-if="!showSuccessModal"
        class="two-fa-auth-qrcode-modal__header"
      >
          {{ title }}
      </div>
    </template>

<!--  body модального окна -->
    <template v-if="showSuccessModal">
      <div class="two-fa-auth-qrcode-modal__text-main__success">
        <div class="icon">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="60"
              height="60"
              viewBox="0 0 60 60"
              fill="none"
          >
            <rect
                width="60"
                height="60"
                rx="10"
                fill="#5CA450"
            />
            <path
                d="M36.3102 23.5605C36.9326 22.9382 37.9417 22.9382 38.5641 23.5605C39.1789 24.1753 39.1864 25.1674 38.5867 25.7914L30.1033 36.3957C30.0911 36.411 30.078 36.4256 30.0641 36.4395C29.4417 37.0619 28.4326 37.0619 27.8102 36.4395L22.1865 30.8158C21.5641 30.1934 21.5641 29.1842 22.1865 28.5618C22.8089 27.9395 23.818 27.9395 24.4404 28.5618L28.8892 33.0106L36.268 23.6082C36.2811 23.5915 36.2952 23.5756 36.3102 23.5605Z"
                fill="white"
            />
          </svg>
        </div>
        <div class="title">
          {{ $t('2fa-auth-qrcode-modal.success') }}
        </div>
        <div class="info">
          {{ $t('2fa-auth-qrcode-modal.success_info') }}
        </div>
      </div>

    </template>
    <template v-else-if="!wants_to_connect_2fa">
      <p class="two-fa-auth-qrcode-modal__text-main">
        {{ $t('2fa-auth-qrcode-modal.do_you_want_to_enable_two-factor_authorization') }}
      </p>
    </template>
    <div v-else-if="!auth2faSuccess" class="two-fa-auth-qrcode-modal__main-container">
      <p class="two-fa-auth-qrcode-modal__text-main">
        {{ $t('2fa-auth-qrcode-modal.connect_two-factor_authentication') }}
      </p>

      <div class="qr-code__container">
        <QrCode
          v-if="stringQR && isOpen"
          class="two-fa-auth-qrcode-modal__qr"
          :string="stringQR"
        />
      </div>

      <fake-input class="two-fa-auth-qrcode-modal__secret-code">
        <template #label>
          {{ $t('2fa-auth-qrcode-modal.remember_the_secret_code') }}
        </template>
        <template #value>
          {{ secret }}
        </template>
        <template #suffix>
          <copy :value="secret" />
        </template>
      </fake-input>
      <base-input
          class="two-fa-auth-qrcode-modal__secret-code-input"
          v-model="code"
          :label="$t('2fa-auth-qrcode-modal.enter_code_input-label')"
          :placeholder="$t('2fa-auth-qrcode-modal.enter_code_input-placeholder')"
          type="number"
          :maxLength="6"
          :error="error"
      />
    </div>
    <template v-else-if="reserveCodesIsNull">
      <p class="two-fa-auth-qrcode-modal__text-main">
        {{ $t('2fa-auth-qrcode-modal.save_codes') }}
      </p>

      <ul class="two-fa-auth-qrcode-modal__reserve-codes-list">
        <li
          v-for="(codeReserve) in reserveCodesResult"
          class="two-fa-auth-qrcode-modal__reserve-codes"
        >
          {{ codeReserve.code }}
        </li>
      </ul>
    </template>
    <template v-else>
      <div class="two-fa-auth-qrcode-modal__sorry_error">
        {{ $t('2fa-auth-qrcode-modal.sorry_error') }}
      </div>
    </template>

    <template #footer>
      <base-button
        :label="!showSuccessModal ? $t('2fa-auth-qrcode-modal.close') : $t('2fa-auth-qrcode-modal.ok')"
        :type="showSuccessModal ? 'primary' : 'secondary'"
        @click="checkCloseModal"
      />
      <template v-if="!auth2faSuccess && !showSuccessModal">
        <base-button
          :label="wants_to_connect_2fa ? $t('2fa-auth-qrcode-modal.next') : $t('2fa-auth-qrcode-modal.enable')"
          @click="sendCode"
        />

      </template>
      <template v-else-if="reserveCodesIsNull && !showSuccessModal">
        <base-button
          :label="$t('2fa-auth-qrcode-modal.download')"
          @click="downloadItem"
        />
      </template>
    </template>
  </BaseModal>

</template>

<script>

import {mapState, mapGetters, mapActions} from 'vuex';

import AtModal from 'at-ui/src/components/modal';
import AtButton from 'at-ui/src/components/button';
import BaseModal from "../BaseModal";
import QrCode from "../../elements/QrCode";
import BaseButton from "/public/components/common/uiKit/button/base";
import copy from "../../common/copy";
import {verify} from '2fa-util';

export default {
    name: 'QRCodeModal',
    components: {
        BaseModal,
        AtModal,
        AtButton,
        QrCode,
        BaseButton,
        copy,
        BaseInput: () => import(/* webpackChunkName: "/public/components/common/uiKit/input/base" */ '/public/components/common/uiKit/input/base'),
        FakeInput: () => import(/* webpackChunkName: "/public/components/common/uiKit/input/base" */ '/public/components/common/uiKit/input/FakeInput'),
    },
    data() {
        return {
            wants_to_connect_2fa: false, //свойство подтверждающее желание пользователя подключить 2fa и показывающее второе диалоговое окно (qr-код окно)
            auth2faSuccess: false, //свойство подтверждающее то что пользователь подключил 2fa авторизацию и показывающее третье окно с группой кодов
            code: null,
            checkClose: false, //свойство позволяет закрыть окно (существуют ситуации - показ предупреждения что коды не скачаны или показ окна Success, когда при нажатии на Cancel окно закрывать не надо)
            error: undefined,
            showSuccessModal: false, //триггер для отображения последнего окна с поздравленями
            showedDontDownloadError: false //свойство отвечает за то что была показана отшибка при попытке выхода без скачивания кодов
        };
    },
    props: {
      pid: {
        type: Number,
        default: undefined,
      }
    },
    watch: {
      isOpen() {
        this.fetchToken();
      },
      code() {
        this.error = undefined
      }
    },
    computed: {
        ...mapState('user', [
            'token',
            'reserveCodes',
        ]),
        ...mapGetters('app', [
            'isOpenModal',
        ]),

        stringQR() {
            return this.token?.otpauth;
        },
        secret() {
            return this.token?.secret;
        },
        isOpen() {
          const {
            $options: {
              name
            },
            isOpenModal,
            pid,
          } = this;


          return pid ? isOpenModal(`${name}_${pid}`) : isOpenModal(name);
        },
        codeStatus() {
            const {code} = this;
            return (!code || code.length < 6) ? 'error' : '';
        },
        title() {
            if (!this.wants_to_connect_2fa) {
                return this.$t('2fa-auth-qrcode-modal.2FA_Authorization');
            }

            if (!this.auth2faSuccess) {
                return this.$t('2fa-auth-qrcode-modal.enable_two-factor_authorization');
            }

            return this.$t('2fa-auth-qrcode-modal.access_recovery_keys');
        },

        reserveCodesIsNull() {
            return this.reserveCodes?.code === 0;
        },
        reserveCodesResult() {
            // return [
            //   {
            //     "code": "IYHYQLL3B",
            //     "__typename": "Codes"
            //   },
            //   {
            //     "code": "D703SKXC",
            //     "__typename": "Codes"
            //   },
            //   {
            //     "code": "BPC44D3G9H",
            //     "__typename": "Codes"
            //   },
            //   {
            //     "code": "97A9LNY0",
            //     "__typename": "Codes"
            //   },
            //   {
            //     "code": "XN9BWKNG",
            //     "__typename": "Codes"
            //   },
            //   {
            //     "code": "V9OW6GYAF",
            //     "__typename": "Codes"
            //   },
            //   {
            //     "code": "36TRP2VX",
            //     "__typename": "Codes"
            //   },
            //   {
            //     "code": "7XM49YPWU",
            //     "__typename": "Codes"
            //   },
            //   {
            //     "code": "LUIB7U27",
            //     "__typename": "Codes"
            //   },
            //   {
            //     "code": "5JGA4ERX",
            //     "__typename": "Codes"
            //   },
            //   {
            //     "code": "WI0RHCL6BD",
            //     "__typename": "Codes"
            //   },
            //   {
            //     "code": "014M9QTHO",
            //     "__typename": "Codes"
            //   },
            //   {
            //     "code": "B8Y30QNID",
            //     "__typename": "Codes"
            //   },
            //   {
            //     "code": "XWPQ1YFD0A",
            //     "__typename": "Codes"
            //   },
            //   {
            //     "code": "H5B5KSDOCX",
            //     "__typename": "Codes"
            //   },
            //   {
            //     "code": "0BAAQ0TVE",
            //     "__typename": "Codes"
            //   },
            //   {
            //     "code": "CMGUTRO14",
            //     "__typename": "Codes"
            //   },
            //   {
            //     "code": "Y592U3KUYO",
            //     "__typename": "Codes"
            //   },
            //   {
            //     "code": "WO548BGHWY",
            //     "__typename": "Codes"
            //   },
            //   {
            //     "code": "YFE14CD4C",
            //     "__typename": "Codes"
            //   }
            // ]
            return this.reserveCodes?.result;
        },

    },
    methods: {
        ...mapActions('user', [
            'fetchToken',
            'createTokenAuth',
            'fetchUser',
        ]),
        ...mapActions('app', [
            'closeModal',
        ]),
        sendCode() {
          //TODO: Добавить красивые уведомления об ошибке когда будут статусы с бэка
            if (!this.wants_to_connect_2fa) {
                return this.wants_to_connect_2fa = true;
            }
            // const checkCode = verify(this.code, this.secret);
                this.error = undefined

                this.createTokenAuth({token: this.token.token, enteredCodeTwoFactorAuth: this.code})

                    .then(status => {
                        if (!status) {
                            this.$Notify({
                                title: this.$t('2fa-auth-qrcode-modal.Two-factor_authorization'),
                                message: this.$t('2fa-auth-qrcode-modal.setup_successfully', {productName: import.meta.env.VITE_PRODUCT_NAME}),
                                type: 'success',
                            });
                            this.auth2faSuccess = true;
                            this.fetchUser();
                        } else {
                            this.error = this.$t('2fa-auth-qrcode-modal.invalid_code')

                            this.$Notify({
                                title: this.$t('2fa-auth-qrcode-modal.Two-factor_authorization'),
                                message: this.$t('2fa-auth-qrcode-modal.error_when_setting_up_two-factor_authorization'),
                                type: 'error',
                            });
                        }
                    })
                    .catch(_ => {
                      this.error = this.$t('2fa-auth-qrcode-modal.invalid_code')
                    })
        },
        checkCloseModal() {
            if (this.checkClose || !this.auth2faSuccess) {
              this.closeModal()

              setTimeout(() => {
                this.code = null
                this.error = undefined
                this.showSuccessModal = false
                this.checkClose = false;
              }, 500)
            } else {
                if ( !this.showedDontDownloadError ) {
                  //здесь будет проверка что мы не сохранили коды в файл
                  this.$Message.error({
                    message: this.$t('2fa-auth-qrcode-modal.save_the_backup_codes_for_authorization'),
                    duration: 10000,
                  });

                  this.showedDontDownloadError = true
                  return
                }

                //здесь будет проверка что мы пытаемся закрыть предпоследнее окно - мы должны перед его закрытием показать SUCCESS окно
                if (this.auth2faSuccess) {
                  this.showSuccessModal = true
                  this.checkClose = true;
                }
            }
        },
        downloadItem() {
            const file = new File(this.reserveCodesResult.map((el) => {
                return el.code + '\n';
            }), `${import.meta.env.VITE_PRODUCT_NAME}BackupCodes.txt`, {
                type: 'text/plain',
            });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(file);
            link.download = `${import.meta.env.VITE_PRODUCT_NAME}BackupCodes.txt`;
            link.click();
            URL.revokeObjectURL(link.href);

            this.showedDontDownloadError = true
        },

    },
};

</script>

<style lang="scss">
.two-fa-auth-qrcode-modal {
  font-family: var(--new-front-font-family);
  .at-modal {
    background: var(--new-front-modal-bg)!important;
  }
  .at-modal__header {
    background: transparent!important;
    border: 0!important;
    min-height: 100px!important;

    .at-modal__title {
      width: 100%;

      white-space: normal!important;
      padding: 0 10px;
      text-align: center;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    font-family: var(--new-front-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: $h1;
    color: var(--new-front-primary-font-color);
  }

  .at-modal__body {
    padding-top: 0!important;
  }

  &__text-main {
    font-family: var(--new-front-font-family);
    font-size: $h4!important;
    color: var(--new-front-text-secondary-bg-modal-primary)!important;

    text-align: center;

    &__success {
      .icon {
        text-align: center;
        padding: 20px 0;
      }
      .title {
        font-weight: 500;
        font-size: $h1;
        text-align: center;
        color: var(--primary-font-color);
        margin-bottom: 20px;
      }
      .info {
        font-size: $h4;
        text-align: center;
        color: var(--new-front-secondary-font-color);
      }
    }
  }

  &__sorry_error {
    font-family: $Gilroy;
    font-size: $h4 !important;
    color: var(--new-front-primary-font-color);

    text-align: center;
  }

  &__qr {
    width: 200px;
    height: 200px;
  }

  &__main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__secret-code {
    width: 100%;
    margin-bottom: 20px;
    &::v-deep .fake-input {
      font-size: $h4;
    }
    > .fake-input {
      font-size: $h4;
    }
    &-input {
      &::v-deep .search-input {
        font-size: 16px !important;
        height: 54px !important;
      }
      &::v-deep .base-input__wrapper input {
        font-size: 16px !important;
        height: 54px !important;
      }
    }
  }

  &__input {
    width: 100%;
  }

  &__reserve-codes-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    margin-top: 30px;
  }

  &__reserve-codes {
    font-family: $Gilroy;
    font-size: $h5;
    line-height: var(--line-height);
    color: var(--new-front-primary-font-color);
    border: 1px solid var(--new-front-input-border);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 112px;
    border-radius: 12px;
    word-break: break-all;
    padding: 15px 0;
  }

  @include below_phone() {
    &__header {
      font-size: $h3;
    }
    &__text-main {
      font-size: $h5;
    }
    &__qr {
      width: 182px;
      height: 182px;
    }
  }
}

.qr-code__container {
  position: relative;
  max-width: 255px;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

@media screen and (max-width: 600px) {
  .two-fa-auth-qrcode-modal {
    &__reserve-codes-list {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@media screen and (max-width: 450px) {
  .two-fa-auth-qrcode-modal {
    &__reserve-codes-list {
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
    }
  }
}
::v-deep {
  .base-modal .at-modal__close {
    font-size: 24px;
    color: #343A40;
    top: 15px;
    right: 15px;
  }
}
.two-fa-auth-qrcode-modal__secret-code-input {
  > .search-input {
    font-size: 16px !important;
    height: 54px !important;
  }
}
</style>
