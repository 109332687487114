import { getRouteQuery } from "/public/utils/getRouteQuery";
import { getCurrenciesIdsByNames } from "/public/utils/getCurrenciesIdsByNames";
import { getFormatForUrlByArray } from "/public/utils/getFormatForUrlByArray";
import {getRollingReserveQuery} from "../../../../graphQl/queries/getRollingReserves";

export const fetchRollingReserves = async ({ state, rootState, commit, dispatch }, payload) => {
    const {
        route,
        router,
        showUrlFilters = true,
        excludeParams = [],
        page,
        sortDate,
        statuses,
        currencies,
        date,
    } = payload;
    const { countOnPage } = state;
    const currentPage = page ? page : getRouteQuery({
        route,
        queryName: 'page',
        defaultValue: 1,
        excludeQueries: excludeParams,
        preparedReturnValueFunc: (value) => Number(value),
    });
    const currentSortDate = sortDate ? sortDate : getRouteQuery({
        route,
        queryName: 'sortDate',
        defaultValue: undefined,
    });
    const currentStatuses = statuses ? statuses : getRouteQuery({
        route,
        queryName: 'statuses',
        defaultValue: [],
        preparedReturnValueFunc: (value) => value.split(','),
    });
    const currentCurrencies = (currencies && Array.isArray(currencies)) ? currencies : getRouteQuery({
        route,
        queryName: 'currencies',
        defaultValue: [],
        preparedReturnValueFunc: (value) => value.split(','),
    });
    const currentDate = date ? date : getRouteQuery({
        route,
        queryName: 'date',
        defaultValue: [],
        preparedReturnValueFunc: (value) => value.slit('to'),
    });
    const allCurrencies = rootState.app.currencies;
    const variables = {
        limit: countOnPage,
        offset: currentPage,
        sortDate: currentSortDate,
        statuses: currentStatuses,
        currencies: getCurrenciesIdsByNames(currentCurrencies, allCurrencies),
        date: currentDate,
    };
    let result;

    if (showUrlFilters) {
        router.push({
            query: {
                page: currentPage,
                sortDate: currentSortDate,
                statuses: getFormatForUrlByArray(currentStatuses, (array) => array.join(',')),
                currencies: getFormatForUrlByArray(currentCurrencies, (array) => array.join(',')),
                date: getFormatForUrlByArray(currentDate, (array) => array.join('to')),
            },
        });
    }

    try {
        result = await dispatch('app/request', {
            type: 'query',
            gql: getRollingReserveQuery,
            variables,
        }, { root: true });
    } catch (error) {
        return error;
    }

    if (result.message) {
        throw result.message;
    }

    if (result.errors) {
        throw result.errors;
    }

    const {
        data: {
            getRollingReserves: {
                result: items,
                totalCount,
            },
        },
    } = result;

    commit('result', items);
    commit('totalCount', totalCount);

    return true;
};
