<script>

  import Login from "@/public/pages/login.vue";
  import ApolloLogin from "@/public/pages/apollo/apolloLogin.vue";
  import closedSession from "@/public/pages/closedSession.vue";
  import apolloClosedSession from "@/public/pages/apollo/apolloClosedSession.vue";

  const APOLLO_PRODUCT_NAME = 'Apollopayment';
  const envProductName = import.meta.env.VITE_PRODUCT_NAME
  export default {
    name: "LoginContainer",
    computed: {
      component () {
        return envProductName !== APOLLO_PRODUCT_NAME ? closedSession : apolloClosedSession;
      }
    }
  }
</script>

<template>
<component :is="component" />
</template>
