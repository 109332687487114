<script>
    import BaseInput from "@/public/components/common/uiKit/input/base/index.vue";
    import {mapActions, mapMutations, mapState} from "vuex";
    import BusinessWalletNetworkSelect
        from "@/public/components/elements/business-walet/Select/businessWalletNetworkSelect.vue";
    import AddressBookSelect from "@/public/components/elements/business-walet/addressBookSelect/addressBookSelect.vue";
    import bchaddr from "bchaddrjs";
    import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
    import WebhooksFilterSelect from "@/public/components/elements/webhooks/Select/WebhooksFilterSelect.vue";

    export default {
        name: "DeleteSettingModal",
        components: {
            WebhooksFilterSelect,
            BaseButton,
            AddressBookSelect,
            BusinessWalletNetworkSelect,
            BaseInput,
            StoreModal: () => import(/* webpackChunkName: "/public/components/modals/StoreModal" */ '/public/components/modals/StoreModal'),
        },
        computed: {
          ...mapState('app', ['modal']),
          ...mapState('collectingProfits', ['isRequestProcessed']),
            payload () {
              return this.modal.payload
            }
        },
        methods: {
            ...mapActions('app', [
                'openModal',
                'closeModal',
                'request',
            ]),
            ...mapMutations('collectingProfits', ['setRequestState']),
            handleCancelCreate() {
                const {
                    // clearAllData,
                    closeModal,
                    // fetchApiKeys,
                    // hasApiKeys,
                } = this;
                this.setRequestState(false)
                closeModal();
                // if (hasApiKeys) {
                //     fetchApiKeys();
                // }
                // setTimeout(() => {
                //     clearAllData();
                // }, 500);
            },
            handleCreateSetting () {
                const payload = {
                    id: this.modal.payload.id,
                    actionName: 'deleteCollectSetting'
                }
                this.openModal({
                    name: 'TwoFaConfirmation',
                    payload
                });
            },
        }
    }
</script>

<template>
<StoreModal @closeModal="handleCancelCreate" name="DeleteSettingModal" class="create-settings">
    <template   #body>
        <template v-if="!isRequestProcessed">
            <div class="create-settings__complete">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_11355_117466)">
                        <rect width="60" height="60" rx="10" fill="#DAC066"/>
                        <path d="M24.8098 18.2197C24.9504 18.079 25.1412 18 25.3401 18H34.6599C34.8588 18 35.0496 18.079 35.1902 18.2197L41.7803 24.8098C41.921 24.9504 42 25.1412 42 25.3401V34.6599C42 34.8588 41.921 35.0496 41.7803 35.1902L35.1902 41.7803C35.0496 41.921 34.8588 42 34.6599 42H25.3401C25.1412 42 24.9504 41.921 24.8098 41.7803L18.2197 35.1902C18.079 35.0496 18 34.8588 18 34.6599V25.3401C18 25.1412 18.079 24.9504 18.2197 24.8098L24.8098 18.2197ZM25.6508 19.5L19.5 25.6508V34.3492L25.6508 40.5H34.3492L40.5 34.3492V25.6508L34.3492 19.5H25.6508Z" fill="white"/>
                        <path d="M28.5023 34.5C28.5023 33.6716 29.1739 33 30.0023 33C30.8307 33 31.5023 33.6716 31.5023 34.5C31.5023 35.3284 30.8307 36 30.0023 36C29.1739 36 28.5023 35.3284 28.5023 34.5Z" fill="white"/>
                        <path d="M28.6493 25.4926C28.5693 24.6934 29.1969 24 30 24C30.8031 24 31.4307 24.6934 31.3507 25.4926L30.8246 30.7537C30.7823 31.1774 30.4258 31.5 30 31.5C29.5742 31.5 29.2177 31.1774 29.1754 30.7537L28.6493 25.4926Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_11355_117466">
                            <rect width="60" height="60" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
                <div class="create-settings__complete-title">{{ $t('profits.delete-title') }}</div>
                <div class="create-settings__complete-desc">{{ $t('profits.delete-subtitle') }}</div>
                <div  v-if="payload.alias" class="delete-setting__alias">
                    {{ payload.alias }}
                </div>
            </div>
        </template>
        <template v-else>
            <div class="create-settings__complete">
                <svg class="create-settings__complete-icon" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_11355_117487)">
                        <rect width="60" height="60" rx="10" fill="#5BB24D"/>
                        <path d="M36.3102 23.5605C36.9326 22.9382 37.9417 22.9382 38.5641 23.5605C39.1789 24.1753 39.1864 25.1674 38.5867 25.7914L30.1033 36.3957C30.0911 36.411 30.078 36.4256 30.0641 36.4395C29.4417 37.0619 28.4326 37.0619 27.8102 36.4395L22.1865 30.8158C21.5641 30.1934 21.5641 29.1842 22.1865 28.5618C22.8089 27.9395 23.818 27.9395 24.4404 28.5618L28.8892 33.0106L36.268 23.6082C36.2811 23.5915 36.2952 23.5756 36.3102 23.5605Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_11355_117487">
                            <rect width="60" height="60" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
                <div class="create-settings__complete-title">{{ $t('profits.success') }}</div>
                <div class="create-settings__complete-desc">{{ $t('profits.success-desc-delete') }}</div>
            </div>
        </template>
    </template>
    <template #footer>
        <div v-if="!isRequestProcessed" class="create-settings__footer">
            <base-button type="secondary" style="width: 100% !important;" :label="$t('profits.cancel')" @click="handleCancelCreate" />
            <base-button  type="primary" style="width: 100% !important;" :label="$t('profits.delete')" @click="handleCreateSetting" />
        </div>
        <template v-else>
            <base-button type="primary" :label="$t('profits.close')" @click="handleCancelCreate" />
        </template>
    </template>
</StoreModal>
</template>

<style scoped lang="scss">
.delete-setting__alias {
    color: var(--new-front-primary-font-color);
    font-size: $h4;
    font-weight: 500;
    margin-bottom: $p30;
}
.create-settings {
    &__footer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        @media (max-width: 450px) {
            display: flex;
            flex-direction: column-reverse;
            grid-template-columns: unset;
        }
    }

    &__select-address {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    &__address-book {
        position: relative;
        top: 8px;
    }
    &__content {
        padding: 0 $p30;
        @media (max-width: 450px) {
            padding: 0 $p10;
        }
    }
    &__header {
        text-align: center;
        .title {
            font-size: $h1;
            font-weight: 500;
            color: var(--new-front-primary-font-color);
            @media (max-width: 450px) {
                font-size: $h2;
            }
        }
    }
    &__complete {
        display: flex;
        align-items: center;
        flex-direction: column;
        &-icon {
            margin-bottom: $p30;
        }
        &-title {
            font-size: $h1;
            font-weight: 500;
            color: var(--new-front-primary-font-color);
            margin-bottom: $p20;
        }
        &-desc {
            font-size: $h4;
            color: var(--new-front-secondary-font-color);
            margin-bottom: $p10;
        }
    }
}
@media (max-width: 450px) {
    ::v-deep .main-modal-header {
        padding: 0 $p10;
    }
}

::v-deep .base-btn {
    width: 100% !important;
}
::v-deep .advanced-balances-select-wrapper .input-wrapper .options-list .list {
    max-height: 300px !important;
}
</style>
