import gql from 'graphql-tag';

export default gql`
    mutation($organizationId: String!, $networkId: String!, $currencyId: String!) {
        payoutAddress(organizationId: $organizationId, networkId: $networkId, currencyId: $currencyId) {
            code
            result {
                id
                currencyId
                networkId
                address
                tag
                balance
            }
        }
    }
`;
