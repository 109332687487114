<template>
  <div class="balances-list-header">

    <p class="balances-list-header__item  ">
      {{ $t('advance_balances.currency') }}
    </p>
    <p class="balances-list-header__item  ">
      ID
    </p>
    <p class="balances-list-header__item  ">
      {{ $t('advance_balances.balance') }}
    </p>
    <!--    <div class="list-header__item  header-list">-->
    <!--      <at-tooltip :content="information">-->
    <!--        {{ $t('advance_balances.deposit_by_order') }}-->
    <!--        <i class="icon icon-help-circle"></i>-->
    <!--      </at-tooltip>-->
    <!--    </div>-->
  </div>
</template>

<script>

export default {
    name: 'AccountsListHeader',
    computed: {
        information() {
            return this.$t('advance_balances.tariff_description');
        },
    },
};

</script>
<style lang="scss" scoped>
.header-list {
  text-align: center;
}

.balances-list-header {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: start;
  align-items: center;
  min-height: 75px;
  background: #FCFCFC;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  border-radius: 20px 20px 0px 0px;
  padding-left: 40px;
  padding-right: 40px;

  &__item {
    font-family: var(--new-front-font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

}
</style>