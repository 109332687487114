import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!, $limit: Float!, $offset: Float!, $sortDate: String, $status: [String], $currencies: [String], $networks: [String], $date: [String]) {
        getWithdrawalRequests(organizationId: $organizationId, limit: $limit, offset: $offset, sortDate: $sortDate, status: $status, currencies: $currencies, networks: $networks, date: $date) {
            requests {
                id
                currencyId
                networkId
                accountId
                amount
                tx
                feeAmount
                createdAt
                status
            }
            totalCount
        }
    }
`;
