<template>
<div>
    <div class="preview-order" @click="openOrder">
        <div class="preview-order__col preview-order__id ">
            <span class="preview-order__text">{{ id | preparedString }}</span>
            <copy :value="id" />
        </div>
        <p class=" preview-order__col preview-order__text ">
            {{ createdAt | getFormatDate('DD/MM/YYYY, HH:mm') }}
        </p>
        <p class=" preview-order__col preview-order__text preview-order__network-name ">
            {{ order.order }}
        </p>
        <div class="preview-order__col preview-order__currency ">
            <CurrencyIcon :currency="currency" />
            <p class="preview-order__text">{{ currencyAlias }}&ensp;<span class="preview-order__text-secondary"> {{ currencyName }}</span></p>
        </div>

        <div class="preview-order__col preview-order__currency " v-if="networkId">
            <NetworkIcon :network="receivedNetwork.toUpperCase()" />
            <p class="preview-order__text">{{ receivedNetwork | getNetworkFullNameByNetworkName }}&ensp; <span class="preview-order__text-secondary">{{ receivedNetwork | getNetworkShortNameByNetworkName }}</span></p>
        </div>
        <p class="preview-order__col preview-order__text" v-else>
            -
        </p>
        <p v-if="amount" class="preview-order__col preview-order__text">
            {{ amount }}
        </p>
        <p class="preview-order__col preview-order__text" v-else>
            -
        </p>
        <p class="preview-order__col preview-order__text">
            {{ receivedAmount }}
        </p>
        <div class="preview-order__col preview-order__tag" :style="`--colorText: ${status.colorTag === 'default' ? 'var(--primary-font-color)' : '#FFFFFF'}`">
            <div :class="['item__status', `item__status--${status.colorTag}`]">
                {{ $t(status.title) }}
            </div>
        </div>
    </div>

    <div :class="['preview-order__mobile', isPreviewOpened ? 'preview-order__mobile--opened' : '']" :style="`--colorStatus:${mobileStatusTextColor};`">
        <div class="preview-order__preview" slot="title" @click="togglePreview">
            <div class="preview-order__info">
                <div :class="['item__status', 'preview-order__info-status', `preview-order__info-status--${status.colorTag}`]"></div>
                <p class="preview-order__text">
                    {{ order.order }}
<!--                    {{ id | preparedString }}-->
<!--                    <copy :value="id"/>-->
                </p>
                <p class="preview-order__text">
                    {{ amount }}
                </p>
                <p class=" preview-order__text preview-order__text-secondary">
                    {{ createdAt | getFormatDate('DD/MM/YYYY, HH:mm') }}
                </p>
                <p class=" preview-order__text preview-order__text-secondary">
                    {{ currencyAlias }}&ensp;{{ receivedNetwork | getNetworkShortNameByNetworkName }}
                </p>
            </div>

            <svg :class="['preview-order__chevron', isPreviewOpened ?  'preview-order__chevron--active' : '']" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.228 1.635C0.085 1.462 0 1.241 0 1C0 0.448 0.448 0 1 0H7C7.552 0 8 0.448 8 1C8 1.241 7.915 1.462 7.772 1.635L4.808 5.589C4.626 5.838 4.332 6 4 6C3.668 6 3.374 5.838 3.192 5.589L0.228 1.635Z" fill="var(--new-front-primary-font-color)"/>
            </svg>
        </div>

        <div class="preview-order__detailed-info">
            <div class="preview-order__row">
                <p class="preview-order__title-text">ID:</p>
                <p class="preview-order__text">
                    {{ id | preparedString }}
                    <copy :value="id"/>
                </p>
            </div>
            <div class="preview-order__row">
                <p class="preview-order__title-text"> {{ $t('orders.date_of_creation') }}:</p>
                <p class="preview-order__text">
                    {{ createdAt | getFormatDate('DD/MM/YYYY') }}
                </p>
            </div>
            <div class="preview-order__row">
                <p class="preview-order__title-text"> {{ $t('orders.order-number') }}:</p>
                <p class="preview-order__text">
                    {{ orderNumber }}
                </p>
            </div>
            <div class="preview-order__row">
                <p class="preview-order__title-text"> {{ $t('orders.currency') }}:</p>
                <div class=" preview-order__currency" v-if="currency">
                    <CurrencyIcon
                            class="preview-order__currency-img"
                            :currency="currency"
                    />
                    <div class="preview-order__currency-container">

                        <p class="preview-order__text">{{ currencyAlias }}&ensp;<span class="preview-order__text-secondary"> {{ currencyName }}</span></p>
                    </div>
                </div>
                <p class="preview-order__text" v-else>-</p>
            </div>
            <div class="preview-order__row">
                <p class="preview-order__title-text"> {{ $t('orders.network') }}:</p>
                <div class=" preview-order__currency" v-if="receivedNetwork">
                    <NetworkIcon
                        class="preview-order__currency-img"
                        :network="receivedNetwork.toUpperCase()"
                    />
                    <div class="preview-order__currency-container">

                        <p class="preview-order__text">{{ receivedNetwork | getNetworkFullNameByNetworkName }}&ensp; <span class="preview-order__text-secondary">{{ receivedNetwork | getNetworkShortNameByNetworkName }}</span></p>
                    </div>
                </div>
                <p class="preview-order__text" v-else>-</p>
            </div>
            <div class="preview-order__row">
                <p class="preview-order__title-text"> {{ $t('orders.amount') }}:</p>
                <p v-if="amount" class=" preview-order__text">
                    {{ amount }}
                </p>
                <p class="preview-order__text" v-else>-</p>
            </div>
            <div class="preview-order__row">
                <p class="preview-order__title-text"> {{ $t('orders.status') }}:</p>
                <div class="preview-order__tag" :style="`--colorText: ${status.colorTag === 'default' ? 'var(--primary-font-color)' : '#FFFFFF'}`">
                    <div :class="['item__status', `item__status--${status.colorTag}`]">
                        {{ $t(status.title) }}
                    </div>
                </div>
            </div>
            <base-button :type="'primary'" :label="$t('orders.more')" class="preview-order__btn" @click="openOrder"/>
        </div>
    </div>

</div>
</template>

<script>

    import {mapGetters} from 'vuex';
    import BigNumber from "bignumber.js";
    import CopyString from "/public/components/elements/CopyString";
    import CurrencyImg from "/public/components/elements/CurrencyImg";
    import AtTag from 'at-ui/src/components/tag';
    import AtCollapse from 'at-ui/src/components/collapse/';
    import AtCollapseItem from 'at-ui/src/components/collapse-item/';
    import Copy from "@/public/components/common/copy.vue";
    import CurrencyIcon from "@/public/components/common/uiKit/CurrencyIcon.vue";
    import {getCurrencyInfoById, getNetworkSystemName} from "@/public/filters";
    import NetworkIcon from "@/public/components/common/uiKit/NetworkIcon.vue";
    import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";

    export default {
        name: 'PreviewInvoice',
        components: {
            BaseButton,
            NetworkIcon,
            CurrencyIcon,
            Copy,
            CopyString,
            CurrencyImg,
            AtTag,
            AtCollapse,
            AtCollapseItem,
        },
        props: {
            item: {
                type: Object,
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            isLink: {
                type: Boolean,
                default: true,

            },

            statusEnum: {
                type: Object,
                required: true,
            },
            orderEnum: {
                type: Object,
                required: true,
            },
            mobileStatusTextColor: {
                type: String,
                required: true,
            },
        },
        data () {
            return {
                isPreviewOpened: false
            }
        },
        computed: {
            ...mapGetters('app', [
                'getCurrencyNameById',
            ]),
            rate () {
                return BigNumber(this.item.rate).toFixed(3)
            },
            currencyAlias () {
                return getCurrencyInfoById(this.item.currency).alias
            },
            id() {
                return this.item.id;
            },
            order() {
                return this.item;
            },
            orderId() {
                return this.item.orderId;
            },
            account() {
                return this.item.account ? this.item.account : 'пусто';
            },
            networkId() {
                return this.item.networkId;
            },

            currency() {
                const {
                    item: {currency, currencyId},
                    getCurrencyNameById,
                } = this;

                return getCurrencyNameById(currency || currencyId);
            },
            receivedCurrency() {
                const {
                    item: {receivedCurrency},
                    getCurrencyNameById,
                } = this;

                return getCurrencyNameById(receivedCurrency);
            },
            receivedNetwork () {
                return getNetworkSystemName(this.item.networkId)
            },
            received() {
                return Number(this.item.received)
            },
            amount() {
                return Number(this.item.amount)
            },
            orderAmount () {
                return Number(this.item.orderAmount)
            },
            receivedAmount() {
                return Number(this.item.received)
            },
            currencyName() {
                return `(${getCurrencyInfoById(this.item.currency).currency})`;
            },
            receivedCurrencyId() {
                return this.item.receivedCurrency;
            },
            receivedNetworkId() {
                return this.item.networkId;
            },
            orderNumber() {
                return this.item.order;
            },
            status() {
                const {
                    statusEnum,
                    item,
                } = this;
                if (!statusEnum[item.status]) {
                    return
                }
                return statusEnum[item.status];
            },
            createdAt() {
                return this.item.createdAt;
            },
            id_copied() {
                return this.$t('copy.id_copied');
            },
            id_not_copied() {
                return this.$t('copy.id_not_copied');
            },
            notification() {
                return this.$t('copy.notification');
            },
            addresses_copied() {
                return this.$t('copy.addresses_copied');
            },
            addresses_not_copied() {
                return this.$t('copy.addresses_not_copied');
            },

        },
        methods: {
            openOrder () {
                this.$emit('openOrder', this.id)
            },
            togglePreview () {
                this.isPreviewOpened = !this.isPreviewOpened
            }
        },
    };

</script>

<style lang="scss" scoped>
p {
  word-break: break-word;
}
.item__status {
  width: 120px;
  height: 32px;
  border-radius: 10px;
  text-align: center;
  padding: 5px 15px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
    &--init {
        color: var(--new-front-status-text-active);
        background: var(--new-front-status-active);
    }
    &--expired {
        color: var(--new-front-status-text-expired);
        background: var(--new-front-status-expired);
    }
    &--processed {
        color: var(--new-front-status-text-processed);
        background: var(--new-front-status-processed);
    }
    &--pending {
        color: var(--new-front-status-text-pending);
        background: var(--new-front-status-pending);
    }
    &--partial {
        color: var(--new-front-status-text-pending);
        background: var(--new-front-status-pending);
    }
    &--overpaid {
        color: var(--new-front-status-text-processed);
        background: var(--new-front-status-processed);
    }
    &--error {
        color: var(--new-front-status-text-error);
        background: var(--new-front-status-error);
    }
        @media (max-width: 950px) {
        font-size: 13px;
    }
}
.preview-order {
  position: relative;
  align-items: center;
  display: grid;
  grid-template-columns: 20% 20% 15% 25% 25% 15% 15% 15%;
    @media screen and (max-width: 1600px) {
        grid-template-columns: 20% 20% 20% 26% 35% 15% 15% 15%;
    }
  min-height: 88px;
  background: var(--new-front-main-bg);
  color: var(--new-front-primary-font-color);
  cursor: pointer;
  &__chevron {
    transition: transform .3s ease;
    &--active {
      transform: rotate(180deg);
    }
  }
  &__col {
    min-height: 58px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--new-front-divider-color);
    height: 85px;
    &:first-child {
      padding-left: 40px;
    }
  }
  &__text {
    display: flex;
    align-items: center;
    font-family: $Gilroy;
    font-size: $h4;
    font-weight: 500;
    line-height: var(--line-height);
    color: var(--new-front-primary-font-color);
    &-secondary {
      color: var(--new-front-secondary-font-color);
    }
  }

  &__mobile {
    display: none;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 193%;
    height: 100%;
    z-index: 0;
    border-bottom: 1px solid var(--new-front-divider-color);
  }

  &__tag ::v-deep .at-tag {
    min-height: 30px;
    display: flex;
    align-items: center;
    width: 87px;
    border-radius: 8px;
    justify-content: center;
  }
  &__tag ::v-deep .at-tag--default {
    background: var(--main-page-window);
    color: var(--primary-font-color);
    border: 1px solid var(--border-color);
  }
  &__tag ::v-deep .at-tag__text {
    font-style: normal;
    font-family: var(--font-family);
    font-size: var(--font-size-text-secondary);
    line-height: var(--line-height);
    color: var(--colorText);
    white-space: normal;
  }

  &__id {
    position: relative;
    z-index: 2;

    &::v-deep p {
      word-break: break-word;
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
    }
  }

  &__currency {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__currency-img {
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 950px) {
  .preview-order {
    display: none;

    &__detailed-info {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    &__read-more {
      width: 100%;
      max-width: 300px;
      border-radius: 12px;
      background: #0059F7;
      font-family: $Gilroy;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #FFFFFF;
      min-height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 25px;
      margin-bottom: 20px;
    }

    &__currency-text {
      font-family: $Gilroy;
      font-style: normal;
      font-weight: 500;
      font-size: $h4;
      line-height: var(--line-height);
      color: var(--new-front-primary-font-color)
    }

    &__currency-status {
      font-family: $Gilroy;
      font-style: normal;
      font-weight: 400;
      font-size: var(--font-size-text-caption);
      line-height: var(--line-height);
      color: var(--colorStatus);
    }

    &__currency-amount {
      font-family: $Gilroy;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: right;
      color: var(--primary-font-color)
    }

    &__currency-container {
      display: flex;
      flex-direction: column;
    }
    &__btn {
      margin: 20px 0;
    }
    &__row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 53px;
      border-bottom: 1px solid var(--new-front-divider-color);
      color: var(--new-front-primary-font-color);
      &:last-of-type {
        border-bottom: none;
      }
    }

    &__title-text {
      font-family: $Gilroy;
      font-style: normal;
      font-weight: 500;
      font-size: $h6;
      line-height: 16px;
      color: var(--new-front-secondary-font-color);
    }
    &__info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        width: 100%;
        padding-right: 10px;
        position: relative;
        p:nth-child(3) {
            justify-content: flex-end;
            text-align: end;
        }
        p:nth-child(5) {
            justify-content: flex-end;
            text-align: end;
        }
        &-status {
            width: 3px;
            height: 55px;
            border-radius: 13px;
            position: absolute;
            left: -10px;
            padding: 0;
            &--init {
                background: var(--new-front-status-text-active);
            }
            &--expired {
                background: var(--new-front-status-text-expired);
            }
            &--processed {
                background: var(--new-front-status-text-processed);
            }
            &--overpaid {
                background: var(--new-front-status-text-processed);
            }
            &--pending {
                background: var(--new-front-status-text-active);
            }
            &--partial {
                background: var(--new-front-status-text-pending);
            }
            &--error {
                background: var(--new-front-status-text-error);
            }
        }
    }
    &__preview {
      display: flex;
      justify-content: space-between;
      flex: 1;
      align-items: center;
      height: 68px;
    }


    &__mobile {
      display: block;
      padding: 0 20px;
      border-bottom: 1px solid var(--new-front-divider-color);
      max-height: 68px;
      overflow: hidden;
      transition: max-height .3s ease;
      &--opened {
        max-height: 570px;
      }
    }

  }
  .preview-order__text {
    font-size: $h5;
  }
}
::v-deep .at-collapse__content {
  background: var(--secondary-color);
}
.info__rate-icon {
  margin: 0 10px;
}
</style>
