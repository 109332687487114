<template>
<div class="currency-network-icon-wrapper">
    <img
            v-if="network"
            :src="networkImg"
            class="circle-icon"
    />
</div>
</template>

<script>
    export default {
        name: "DashboardNetworkIcon",
        props: {
            /**
             * Примеры пропса.
             * @param network BITCOIN BSC SOLANA
             */
            network: {
                type: String,
                default: undefined
            },
        },
        computed: {
            networkImg({ network }) {
                return new URL(`../../../assets/image/circleIcons/networks/${network.toUpperCase()}.svg`, import.meta.url).href;
            },
        },
    }
</script>

<style lang="scss">
.currency-network-icon-wrapper {
  position: relative;

  display: flex;
}
.circle-icon {
  width: 46px;
  height: 46px;
}
</style>
