<script >

  import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
  import Copy from "@/public/components/common/copy.vue";
  import {mapState} from "vuex";

  export default {
    name: "WidgetsDemoLinkBlock",
    components: {Copy, BaseButton},
    data() {
      return {
        copied: null,
      }
    },
    methods: {
      objectToUrlParams(obj) {
        const params = new URLSearchParams();

        // Формирует строку валют с сетью или без
        const formatCurrencyArray = arr => arr
            ?.map(({ currency, network, networkId }) => network && networkId ? `${currency}_${network}` : currency)
            .join(',') || '';

        // Добавляем параметр, если значение не пустое
        const appendParam = (key, value) => value && params.append(key, value);

        // Обрабатываем массивы валют
        appendParam('payinCryptoCurrenciesTo', formatCurrencyArray(obj.payinCryptoCurrenciesTo || []));
        appendParam('payinCryptoInvoiceCurrencies', formatCurrencyArray(obj.payinCryptoInvoiceCurrencies || []));
        appendParam('payoutCryptoCurrenciesFrom', formatCurrencyArray(obj.payoutCryptoCurrenciesFrom || []));

        // Добавляем остальные параметры
        Object.entries(obj).forEach(([key, value]) => {
          if (!['payinCryptoCurrenciesTo', 'payinCryptoInvoiceCurrencies', 'payoutCryptoCurrenciesFrom'].includes(key)) {
            appendParam(key, value);
          }
        });

        return params.toString();
      },
      async copyClipboard(value, type) {
        await navigator.clipboard.writeText(value)
        this.copied = type
        setTimeout(() => this.copied = null, 2000)
      },
      goToLink (value) {
        window.open(value, '_blank')
      }
    },
    computed: {
      ...mapState('widgetsManage', ['widget', 'demo', 'host']),
      invoiceLinkData () {
        const {
          payinCryptoInvoicePayerEmailRequired,
          payinCryptoInvoicePayerEmailAllow,
          payinCryptoWebhook,
          payinCrypto,
          payoutCrypto,
          showStartPage,
        } = this.widget

        const {
          payinCryptoInvoiceCurrencies,
          payinCryptoInvoiceId,
          payinCryptoInvoiceDesc,
          payinCryptoInvoiceAmount,
          payinCryptoInvoicePayerEmail,
          payoutCryptoReceiverEmail,
          payoutCryptoAmountFrom,
          payoutCryptoCurrenciesFrom,
          payinCryptoCurrenciesTo,
          payoutCryptoReciverEmailAllow,
          accentColor
        } = this.demo

        const payinParams = {
          payinCryptoInvoiceAmount: payinCryptoInvoiceCurrencies.length === 1 ? payinCryptoInvoiceAmount : '',
          payinCryptoInvoiceCurrencies,
          payinCryptoCurrenciesTo,
          payinCryptoInvoiceId,
          payinCryptoInvoiceDesc,
          payinCryptoInvoicePayerEmail,
          payinCryptoWebhook,
          accentColor
        }

        const payoutParams = {
          payoutCryptoReceiverEmail,
          payoutCryptoAmountFrom,
          payoutCryptoCurrenciesFrom,
          accentColor
        }

        if (payoutCrypto && !payinCrypto) {
          return this.objectToUrlParams(payoutParams)
        }

        if (payoutCrypto && payinCrypto && showStartPage) {
          return this.objectToUrlParams({ ...payinParams, ...payoutParams })
        }

        if (payinCrypto && payoutCrypto && !showStartPage) {
          return {
            payin: this.objectToUrlParams(payinParams),
            payout: this.objectToUrlParams(payoutParams)
          }
        }

        return this.objectToUrlParams(payinParams)
      },

      singleLink () {
        return `https://${this.host}/${this.widget.id}${this.widget.showStartPage ? '' : this.widget.payoutCrypto && !this.widget.payinCrypto ? '/payout-crypto' : '/payin-crypto'}?${this.invoiceLinkData}`;
      },
      doubleLink () {
        return {
          payin: `https://${this.host}/${this.widget.id}/payin-crypto?${this.invoiceLinkData.payin}`,
          payout: `https://${this.host}/${this.widget.id}/payout-crypto?${this.invoiceLinkData.payout}`,
      };
      }
    }
  }
</script>

<template>
<div class="demo-link__wrapper">
  <div class="demo-links" v-if="!widget.showStartPage && widget.payinCrypto && widget.payoutCrypto">
    <div class="demo-link small">
      <div class="demo-link__title">{{ $t('widgets.depositWidget') }}</div>
      <div class="demo-link__inner">
        <div  class="demo-link__link big">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_26541_183582)">
              <path d="M8.07373 10.3669L5.77866 12.662L5.7786 12.6621L5.77855 12.6621C4.82947 13.6113 3.2851 13.6113 2.33591 12.6621C1.87609 12.2023 1.62291 11.591 1.62291 10.9408C1.62291 10.2906 1.87609 9.67939 2.33575 9.21957L2.33591 9.2194L4.63099 6.92428C4.94783 6.60738 4.94783 6.09356 4.63093 5.77672C4.31408 5.45987 3.80027 5.45987 3.48337 5.77672L1.1883 8.07184L1.18781 8.07238C0.421849 8.83861 0 9.85732 0 10.9408C0 12.0245 0.422012 13.0434 1.18835 13.8097C1.97936 14.6007 3.0183 14.9962 4.05729 14.9962C5.09627 14.9962 6.13526 14.6007 6.92616 13.8097L6.92622 13.8096L9.22129 11.5145C9.53814 11.1976 9.53814 10.6838 9.22124 10.3669C8.90444 10.0501 8.39068 10.0501 8.07373 10.3669ZM15 4.05534C15 2.97162 14.5779 1.95275 13.8116 1.18641C12.2296 -0.395497 9.65564 -0.395443 8.07378 1.18641L8.07357 1.18668L5.77855 3.48164C5.46165 3.79849 5.46165 4.31236 5.77855 4.62921C5.85381 4.70468 5.94325 4.76454 6.04173 4.80533C6.1402 4.84612 6.24577 4.86705 6.35236 4.86691C6.55998 4.86691 6.76771 4.78766 6.92611 4.62921L9.22113 2.33424L9.2214 2.33397C10.1705 1.38489 11.7148 1.38484 12.664 2.33397C13.1238 2.7938 13.3771 3.40515 13.3771 4.05534C13.3771 4.70548 13.1239 5.31673 12.6642 5.77655L12.664 5.77672L10.369 8.07184C10.0521 8.38869 10.0521 8.9025 10.369 9.2194C10.4443 9.29487 10.5337 9.35471 10.6322 9.39551C10.7306 9.4363 10.8362 9.45723 10.9428 9.45711C11.1504 9.45711 11.3581 9.37785 11.5166 9.2194L13.8116 6.92428L13.8121 6.92374C14.5781 6.15751 15 5.1388 15 4.05534Z" fill="var(--text-text-primary)"/>
              <path d="M4.63221 10.3661C4.70747 10.4415 4.7969 10.5014 4.89537 10.5422C4.99383 10.583 5.09938 10.6039 5.20596 10.6038C5.41364 10.6038 5.62132 10.5245 5.77977 10.3661L10.37 5.77582C10.6869 5.45898 10.6869 4.94516 10.37 4.62826C10.0532 4.31141 9.53936 4.31141 9.22246 4.62826L4.63221 9.21846C4.31531 9.53541 4.31531 10.0492 4.63221 10.3661Z" fill="var(--text-text-primary)"/>
            </g>
            <defs>
              <clipPath id="clip0_26541_183582">
                <rect width="15" height="15" fill="var(--text-text-primary)"/>
              </clipPath>
            </defs>
          </svg>
          <div class="demo-link__link-text big">
            {{ doubleLink.payin }}
          </div>
        </div>
        <div class="demo-link__btns small">
          <base-button @click="copyClipboard(doubleLink.payin, 'payin')">
            <template #append>
              <svg
                  v-if="copied === 'payin'"
                  class="clipboard-icon"
                  width="16"
                  height="16"
                  viewBox="8 8 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M23.5361 13.2812L15.74 20.7188L11.8486 17" stroke="#ffffff" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2C0 0.89543 0.895431 0 2 0H10C11.1046 0 12 0.895431 12 2V4H14C15.1046 4 16 4.89543 16 6V14C16 15.1046 15.1046 16 14 16H6C4.89543 16 4 15.1046 4 14V12H2C0.895431 12 0 11.1046 0 10V2ZM2 1C1.44772 1 1 1.44772 1 2V10C1 10.5523 1.44772 11 2 11H10C10.5523 11 11 10.5523 11 10V2C11 1.44772 10.5523 1 10 1H2Z" fill="#ffffff"/>
              </svg>
            </template>
          </base-button>
          <base-button @click="goToLink(doubleLink.payin)">
            <template #append>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 8H14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.0002 3L14 8L9.0001 13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </template>
          </base-button>
        </div>
        <div class="demo-link__btns mobileOnly">
          <!--      :label="copied ? $t('widgets.copied') : $t('widgets.copyLink')"-->
          <base-button @click="copyClipboard(doubleLink.payin, 'payin')">
            <template #append>
              <svg
                  v-if="copied === 'payin'"
                  class="clipboard-icon"
                  width="16"
                  height="16"
                  viewBox="8 8 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M23.5361 13.2812L15.74 20.7188L11.8486 17" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 2C0 0.89543 0.895431 0 2 0H10C11.1046 0 12 0.895431 12 2V4H14C15.1046 4 16 4.89543 16 6V14C16 15.1046 15.1046 16 14 16H6C4.89543 16 4 15.1046 4 14V12H2C0.895431 12 0 11.1046 0 10V2ZM2 1C1.44772 1 1 1.44772 1 2V10C1 10.5523 1.44772 11 2 11H10C10.5523 11 11 10.5523 11 10V2C11 1.44772 10.5523 1 10 1H2Z" fill="white"/>
            </svg>
            </template>
          </base-button>
          <!--      :label="$t('widgets.goto')"-->
          <base-button @click="goToLink(doubleLink.payin)">
            <template #append>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 8H14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.0002 3L14 8L9.0001 13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </template>
          </base-button>
        </div>
      </div>
    </div>
    <div class="demo-link small">
      <div class="demo-link__title">{{ $t('widgets.payoutWidget') }}</div>
      <div class="demo-link__inner">
        <div  class="demo-link__link big">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_26541_183582)">
              <path d="M8.07373 10.3669L5.77866 12.662L5.7786 12.6621L5.77855 12.6621C4.82947 13.6113 3.2851 13.6113 2.33591 12.6621C1.87609 12.2023 1.62291 11.591 1.62291 10.9408C1.62291 10.2906 1.87609 9.67939 2.33575 9.21957L2.33591 9.2194L4.63099 6.92428C4.94783 6.60738 4.94783 6.09356 4.63093 5.77672C4.31408 5.45987 3.80027 5.45987 3.48337 5.77672L1.1883 8.07184L1.18781 8.07238C0.421849 8.83861 0 9.85732 0 10.9408C0 12.0245 0.422012 13.0434 1.18835 13.8097C1.97936 14.6007 3.0183 14.9962 4.05729 14.9962C5.09627 14.9962 6.13526 14.6007 6.92616 13.8097L6.92622 13.8096L9.22129 11.5145C9.53814 11.1976 9.53814 10.6838 9.22124 10.3669C8.90444 10.0501 8.39068 10.0501 8.07373 10.3669ZM15 4.05534C15 2.97162 14.5779 1.95275 13.8116 1.18641C12.2296 -0.395497 9.65564 -0.395443 8.07378 1.18641L8.07357 1.18668L5.77855 3.48164C5.46165 3.79849 5.46165 4.31236 5.77855 4.62921C5.85381 4.70468 5.94325 4.76454 6.04173 4.80533C6.1402 4.84612 6.24577 4.86705 6.35236 4.86691C6.55998 4.86691 6.76771 4.78766 6.92611 4.62921L9.22113 2.33424L9.2214 2.33397C10.1705 1.38489 11.7148 1.38484 12.664 2.33397C13.1238 2.7938 13.3771 3.40515 13.3771 4.05534C13.3771 4.70548 13.1239 5.31673 12.6642 5.77655L12.664 5.77672L10.369 8.07184C10.0521 8.38869 10.0521 8.9025 10.369 9.2194C10.4443 9.29487 10.5337 9.35471 10.6322 9.39551C10.7306 9.4363 10.8362 9.45723 10.9428 9.45711C11.1504 9.45711 11.3581 9.37785 11.5166 9.2194L13.8116 6.92428L13.8121 6.92374C14.5781 6.15751 15 5.1388 15 4.05534Z" fill="var(--text-text-primary)"/>
              <path d="M4.63221 10.3661C4.70747 10.4415 4.7969 10.5014 4.89537 10.5422C4.99383 10.583 5.09938 10.6039 5.20596 10.6038C5.41364 10.6038 5.62132 10.5245 5.77977 10.3661L10.37 5.77582C10.6869 5.45898 10.6869 4.94516 10.37 4.62826C10.0532 4.31141 9.53936 4.31141 9.22246 4.62826L4.63221 9.21846C4.31531 9.53541 4.31531 10.0492 4.63221 10.3661Z" fill="var(--text-text-primary)"/>
            </g>
            <defs>
              <clipPath id="clip0_26541_183582">
                <rect width="15" height="15" fill="var(--text-text-primary)"/>
              </clipPath>
            </defs>
          </svg>
          <div class="demo-link__link-text big">
            {{ doubleLink.payout }}
          </div>
        </div>
        <div class="demo-link__btns small">
          <base-button @click="copyClipboard(doubleLink.payout, 'payout')">
            <template #append>

              <svg
                  v-if="copied === 'payout'"
                  class="clipboard-icon"
                  width="16"
                  height="16"
                  viewBox="8 8 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M23.5361 13.2812L15.74 20.7188L11.8486 17" stroke="#ffffff" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 2C0 0.89543 0.895431 0 2 0H10C11.1046 0 12 0.895431 12 2V4H14C15.1046 4 16 4.89543 16 6V14C16 15.1046 15.1046 16 14 16H6C4.89543 16 4 15.1046 4 14V12H2C0.895431 12 0 11.1046 0 10V2ZM2 1C1.44772 1 1 1.44772 1 2V10C1 10.5523 1.44772 11 2 11H10C10.5523 11 11 10.5523 11 10V2C11 1.44772 10.5523 1 10 1H2Z" fill="#ffffff"/>
            </svg>
            </template>
          </base-button>
          <base-button @click="goToLink(doubleLink.payout)">
            <template #append>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 8H14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.0002 3L14 8L9.0001 13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </template>
          </base-button>
        </div>
        <div class="demo-link__btns mobileOnly">
          <!--      :label="copied ? $t('widgets.copied') : $t('widgets.copyLink')"-->
          <base-button @click="copyClipboard(doubleLink.payout, 'payout')">
            <template #append>
              <svg
                  v-if="copied === 'payout'"
                  class="clipboard-icon"
                  width="16"
                  height="16"
                  viewBox="8 8 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M23.5361 13.2812L15.74 20.7188L11.8486 17" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2C0 0.89543 0.895431 0 2 0H10C11.1046 0 12 0.895431 12 2V4H14C15.1046 4 16 4.89543 16 6V14C16 15.1046 15.1046 16 14 16H6C4.89543 16 4 15.1046 4 14V12H2C0.895431 12 0 11.1046 0 10V2ZM2 1C1.44772 1 1 1.44772 1 2V10C1 10.5523 1.44772 11 2 11H10C10.5523 11 11 10.5523 11 10V2C11 1.44772 10.5523 1 10 1H2Z" fill="white"/>
              </svg>
            </template>
          </base-button>
          <!--      :label="$t('widgets.goto')"-->
          <base-button @click="goToLink(doubleLink.payout)">
            <template #append>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 8H14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.0002 3L14 8L9.0001 13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </template>
          </base-button>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="demo-link">
    <div  class="demo-link__link">
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_26541_183582)">
          <path d="M8.07373 10.3669L5.77866 12.662L5.7786 12.6621L5.77855 12.6621C4.82947 13.6113 3.2851 13.6113 2.33591 12.6621C1.87609 12.2023 1.62291 11.591 1.62291 10.9408C1.62291 10.2906 1.87609 9.67939 2.33575 9.21957L2.33591 9.2194L4.63099 6.92428C4.94783 6.60738 4.94783 6.09356 4.63093 5.77672C4.31408 5.45987 3.80027 5.45987 3.48337 5.77672L1.1883 8.07184L1.18781 8.07238C0.421849 8.83861 0 9.85732 0 10.9408C0 12.0245 0.422012 13.0434 1.18835 13.8097C1.97936 14.6007 3.0183 14.9962 4.05729 14.9962C5.09627 14.9962 6.13526 14.6007 6.92616 13.8097L6.92622 13.8096L9.22129 11.5145C9.53814 11.1976 9.53814 10.6838 9.22124 10.3669C8.90444 10.0501 8.39068 10.0501 8.07373 10.3669ZM15 4.05534C15 2.97162 14.5779 1.95275 13.8116 1.18641C12.2296 -0.395497 9.65564 -0.395443 8.07378 1.18641L8.07357 1.18668L5.77855 3.48164C5.46165 3.79849 5.46165 4.31236 5.77855 4.62921C5.85381 4.70468 5.94325 4.76454 6.04173 4.80533C6.1402 4.84612 6.24577 4.86705 6.35236 4.86691C6.55998 4.86691 6.76771 4.78766 6.92611 4.62921L9.22113 2.33424L9.2214 2.33397C10.1705 1.38489 11.7148 1.38484 12.664 2.33397C13.1238 2.7938 13.3771 3.40515 13.3771 4.05534C13.3771 4.70548 13.1239 5.31673 12.6642 5.77655L12.664 5.77672L10.369 8.07184C10.0521 8.38869 10.0521 8.9025 10.369 9.2194C10.4443 9.29487 10.5337 9.35471 10.6322 9.39551C10.7306 9.4363 10.8362 9.45723 10.9428 9.45711C11.1504 9.45711 11.3581 9.37785 11.5166 9.2194L13.8116 6.92428L13.8121 6.92374C14.5781 6.15751 15 5.1388 15 4.05534Z" fill="var(--text-text-primary)"/>
          <path d="M4.63221 10.3661C4.70747 10.4415 4.7969 10.5014 4.89537 10.5422C4.99383 10.583 5.09938 10.6039 5.20596 10.6038C5.41364 10.6038 5.62132 10.5245 5.77977 10.3661L10.37 5.77582C10.6869 5.45898 10.6869 4.94516 10.37 4.62826C10.0532 4.31141 9.53936 4.31141 9.22246 4.62826L4.63221 9.21846C4.31531 9.53541 4.31531 10.0492 4.63221 10.3661Z" fill="var(--text-text-primary)"/>
        </g>
        <defs>
          <clipPath id="clip0_26541_183582">
            <rect width="15" height="15" fill="var(--text-text-primary)"/>
          </clipPath>
        </defs>
      </svg>
      <div class="demo-link__link-text">
        {{ singleLink }}
      </div>
    </div>
    <div class="demo-link__btns small">
      <base-button @click="copyClipboard(singleLink, 'payin')">
        <template #append>
          <svg v-if="!copied" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2C0 0.89543 0.895431 0 2 0H10C11.1046 0 12 0.895431 12 2V4H14C15.1046 4 16 4.89543 16 6V14C16 15.1046 15.1046 16 14 16H6C4.89543 16 4 15.1046 4 14V12H2C0.895431 12 0 11.1046 0 10V2ZM2 1C1.44772 1 1 1.44772 1 2V10C1 10.5523 1.44772 11 2 11H10C10.5523 11 11 10.5523 11 10V2C11 1.44772 10.5523 1 10 1H2Z" fill="#ffffff"/>
          </svg>
          <svg
              v-else
              class="clipboard-icon"
              width="16"
              height="16"
              viewBox="8 8 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M23.5361 13.2812L15.74 20.7188L11.8486 17" stroke="#ffffff" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </template>
      </base-button>
      <base-button @click="goToLink(singleLink)">
        <template #append>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 8H14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.0002 3L14 8L9.0001 13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </template>
      </base-button>
    </div>
    <div class="demo-link__btns mobileOnly">
<!--      :label="copied ? $t('widgets.copied') : $t('widgets.copyLink')"-->
      <base-button @click="copyClipboard(singleLink, 'payin')">
        <template #append>
          <svg v-if="!copied" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2C0 0.89543 0.895431 0 2 0H10C11.1046 0 12 0.895431 12 2V4H14C15.1046 4 16 4.89543 16 6V14C16 15.1046 15.1046 16 14 16H6C4.89543 16 4 15.1046 4 14V12H2C0.895431 12 0 11.1046 0 10V2ZM2 1C1.44772 1 1 1.44772 1 2V10C1 10.5523 1.44772 11 2 11H10C10.5523 11 11 10.5523 11 10V2C11 1.44772 10.5523 1 10 1H2Z" fill="white"/>
          </svg>
          <svg
              v-else
              class="clipboard-icon"
              width="16"
              height="16"
              viewBox="8 8 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M23.5361 13.2812L15.74 20.7188L11.8486 17" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </template>
      </base-button>
<!--      :label="$t('widgets.goto')"-->
      <base-button @click="goToLink(singleLink)">
        <template #append>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 8H14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.0002 3L14 8L9.0001 13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </template>
      </base-button>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
::v-deep {
  .copy-wrapper {
    margin: 0;
    .clipboard-icon {
      path {
        fill: var(--bg-background-primary-purple);
      }
    }
  }
}
.demo-links {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
  @media (max-width: 850px) {
    grid-template-columns: 1fr;
    width: 100%;
    gap: 15px;
  }
}
.base-btn {
  max-width: 210px;
  @media (max-width: 850px) {
    max-width: 100%;
    height: 48px !important;
  }
}
.copy-wrapper {
  margin: 0;
  .clipboard-icon {
    path {
      fill: var(--bg-background-primary-purple);
    }
  }
}
.demo-link {
  //padding: 10px;
  //border-radius: 10px;
  //background: var(--bg-background-input-secondary, #333);
  font-family: $Gilroy;
  display: flex;
  align-items: center;

  gap: 10px;
  width: 100%;
    @media (max-width: 850px) {
      flex-direction: column;
      width: 100%;
    }
  &.small {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
  }
  &__title {
    color: var(--text-text-primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
  }
  &__inner {
    display: grid;
    grid-template-columns: 1fr 106px;
    gap: 10px;
    @media (max-width: 850px) {
      grid-template-columns: 1fr;
    }
  }
  &__wrapper {

  }
  &__btns {
    display: grid;
    grid-template-columns: 210px 210px;
    gap: 10px;
    @media (max-width: 850px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      width: 100%;
      //flex-direction: column;
    }
    &.small {
      display: flex;
      align-items: center;
      //flex-direction: column;
      //justify-content: space-between;
      gap: 5px;
      width: 106px;
      .base-btn {
        width: 48px;
      }
      @media (max-width: 850px) {
        display: none;
      }
    }
    &.mobileOnly {
      @media (min-width: 850px) {
        display: none;
      }
    }
  }
  &__link {
    display: grid;
    grid-template-columns: 15px 1fr;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: var(--bg-background-input-secondary, #333);
    padding: 13px 20px;
    //border-radius: 10px;
    //border: 1px solid rgba(255, 255, 255, 0.20);
    //background: rgba(255, 255, 255, 0.10);
    color: var(--text-text-primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    word-break: break-all;
    height: 48px;
    width: 100%;
    @media (max-width: 850px) {
      //height: 164px;
      height: 48px;
    }
    &.big {
      height: 48px;
      @media (max-width: 850px) {
        //height: 164px;
        height: 48px;
      }
    }
    &-text {
      height: 21px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      //@media (max-width: 850px) {
      //  -webkit-line-clamp: 7;
      //  height: 144px;
      //}
      &.big {
        height: 21px;
        -webkit-line-clamp: 1;
        //@media (max-width: 850px) {
        //  -webkit-line-clamp: 7;
        //  height: 144px;
        //}
      }
    }
  }
}
</style>
