export const state = {
    testPublicKey: '',
    testSecretKey: '',
    createdApiKeys: {},
    apiKeys: [],
    apiKeysPlaceholder: null,
    user: {
        id: undefined,
        email: undefined,
        createdAt: undefined,
        otpToken: undefined,
        lang: null,
    },
    token: {
        secret: undefined,
        token: undefined,
        otpauth: undefined,
    },
    reserveCodes: {
        code: undefined,
        result: undefined,
    },
    lang: null
};
