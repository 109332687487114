<template>
<div class="input-password">
  <input @focus="focus" @blur="blur" @input="emitInput"  class="login-input" name="password" :value="value" :placeholder="$t('authorization.password')" :type="inputType" />
  <svg v-if="inputType === 'text'" @click="toggleInputType" class="eye" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 6C10.5 7.38071 9.38071 8.5 8 8.5C6.61929 8.5 5.5 7.38071 5.5 6C5.5 4.61929 6.61929 3.5 8 3.5C9.38071 3.5 10.5 4.61929 10.5 6Z" fill="var(--new-front-primary-font-color)"/>
    <path d="M0 6C0 6 3 0.5 8 0.5C13 0.5 16 6 16 6C16 6 13 11.5 8 11.5C3 11.5 0 6 0 6ZM8 9.5C9.933 9.5 11.5 7.933 11.5 6C11.5 4.067 9.933 2.5 8 2.5C6.067 2.5 4.5 4.067 4.5 6C4.5 7.933 6.067 9.5 8 9.5Z" fill="var(--new-front-primary-font-color)"/>
  </svg>
  <svg v-else @click="toggleInputType" class="eye" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.7904 11.9117L9.17617 10.2975C8.80858 10.4286 8.41263 10.5 8 10.5C6.067 10.5 4.5 8.93303 4.5 7.00004C4.5 6.58741 4.5714 6.19146 4.70253 5.82387L2.64112 3.76246C0.938717 5.27906 0 7.00004 0 7.00004C0 7.00004 3 12.5 8 12.5C9.01539 12.5 9.9483 12.2732 10.7904 11.9117Z" fill="var(--new-front-primary-font-color)"/>
    <path d="M5.20967 2.08837C6.05172 1.72686 6.98462 1.50004 8 1.50004C13 1.50004 16 7.00004 16 7.00004C16 7.00004 15.0613 8.72101 13.3589 10.2376L11.2975 8.17618C11.4286 7.8086 11.5 7.41266 11.5 7.00004C11.5 5.06704 9.933 3.50004 8 3.50004C7.58738 3.50004 7.19144 3.57144 6.82386 3.70256L5.20967 2.08837Z" fill="var(--new-front-primary-font-color)"/>
    <path d="M5.52485 6.64619C5.50847 6.76178 5.5 6.87992 5.5 7.00004C5.5 8.38075 6.61929 9.50004 8 9.50004C8.12012 9.50004 8.23825 9.49157 8.35385 9.47519L5.52485 6.64619Z" fill="var(--new-front-primary-font-color)"/>
    <path d="M10.4752 7.35386L7.64618 4.52488C7.76176 4.50851 7.87989 4.50004 8 4.50004C9.38071 4.50004 10.5 5.61933 10.5 7.00004C10.5 7.12014 10.4915 7.23827 10.4752 7.35386Z" fill="var(--new-front-primary-font-color)"/>
    <path d="M13.6464 13.3536L1.64645 1.35359L2.35355 0.646484L14.3536 12.6465L13.6464 13.3536Z" fill="var(--new-front-primary-font-color)"/>
  </svg>
</div>
</template>

<script>
  export default {
    name: "NInputPassword",
    props: {
      placeholder: {
        type: String,
        default: 'password'
      },
      value: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        inputType: 'password',
        isTimeoutEnabled: false,
      }
    },
    methods: {
      emitInput (e) {

        this.$emit('input', e.target.value)
      },
      focus () {
        this.$emit('focus')
      },
      blur () {
        this.$emit('blur')
      },
      toggleInputType () {
        this.inputType = this.inputType === 'text' ? 'password' : 'text'

        if (this.isTimeoutEnabled) return
        this.isTimeoutEnabled = true

        setTimeout(() => {
          this.inputType = 'password'
          this.isTimeoutEnabled = false
        }, 5000)
      }
    }
  }
</script>

<style lang="scss" scoped>
.input-password {
  display: flex;
  align-items: center;
  position: relative;
}
.login-input {
  width: 100%;
  border-radius: 9px;
  outline: none;
  padding: 15px;
  border: 1px var(--new-front-input-border) solid;
  font-size: 18px;
  margin-top: 10px;
  background: var(--new-front-input-bg);
  color: var(--new-front-primary-font-color);
}
.eye {
  position: absolute;
  right: 15px;
  width: 16px;
  transform: translateY(35%);
}
@media (max-width: 556px) {
  .login-input {
    font-size: 13px;
    margin-top: 2px;
  }
}
</style>
