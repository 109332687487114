import createPayinAddressGQL from "../../../../graphQl/mutations/createPayinAddress";

export const createPayinAddress = async ({ dispatch, commit, rootGetters }, payload) => {
  const organizationId = rootGetters['app/getSelectedOrganization'].id;

  let response;

  try {
    response = await dispatch('app/request', {
      type: 'mutation',
      gql: createPayinAddressGQL,
      variables: {
        organizationId,
        ...payload,
      },
    }, {root: true});
  } catch (error) {
    return error;
  }

  return ( response.data && response.data.createPayinAddress ) ? response.data.createPayinAddress : {}
};