import BigNumber from "bignumber.js";
import getSummaryQuery from "/public/graphQl/queries/getSummary";

export const getSummary = async ({ commit, dispatch, rootGetters }) => {
  let response;

  try {
    response = await dispatch('app/request', {
      type: 'query',
      gql: getSummaryQuery,
      variables: {
        organizationId: rootGetters['app/getSelectedOrganization'].id
      },
    }, { root: true });
  } catch (error) {
    return error;
  }

  const { data } = response || {}
  const { getAddressSummary } = data || {}


  if (getAddressSummary) {
    getAddressSummary.PAYOUT = new BigNumber(getAddressSummary.PAYOUT || 0).plus(getAddressSummary.COLLECT || 0).toFixed();

    commit('setSummary', getAddressSummary);
  }
  return getAddressSummary;
};