import getCrosschainSwapsAmountLimitsQuery from "../../../../graphQl/queries/getCrosschainSwapsAmountLimits";

export const getCrosschainSwapsAmountLimits = async ({ dispatch, rootGetters }) => {
  let response;
  const organizationId = rootGetters['app/getSelectedOrganization'].id;

  try {
    response = await dispatch('app/request', {
      type: 'query',
      gql: getCrosschainSwapsAmountLimitsQuery,
      variables: {
        organizationId: organizationId,
      },
    }, {root: true});
  } catch (error) {
    return error;
  }

  return ( response.data && response.data.crosschainSwapsAmountLimits ) ? response.data.crosschainSwapsAmountLimits : {}
};
