<template>
<div class="top-bar">
    <div class="top-bar__container mobileHidden">
        <div class="top-bar__input">
            <orphan-search
                class="search"
                v-model="query"
                :placeholder="$t('historyAddresses.search-address-hash')"
            />
            <refresh-button @click="loadHistory(false)"/>
            <columns-filter />
        </div>
        <div class="top-bar__row">
            <div class="top-bar__filters">
              <business-wallet-network-select
                  v-model="selectedCurrency"
                  :list="filteredCurrencies"
                  :placeholder="`${$t('orders.currency')}...`"
                  clearable
                  uniq-key="id"
                  @input="selectCurrency"
              />
                <TransactionsSortByType @selectType="selectType" />
                <orders-status-select v-model="selectedStatus" :placeholder="`${$t('invoice.status')}...`" :list="statuses" clearable  uniq-key="value" @input="setStatus" />
                <SortByDate @selectDate="selectDate" v-model="dates"/>
              <address-risks-select :value="riskLevel" @input="selectRisk" />
            </div>
            <div class="top-bar__wrapper">
                <downloader
                    v-model="selectedDownloadType"
                    class="file-downloader"
                    type="secondary"
                >
                    <template #icon>
                        <svg
                            id="btn-select-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                        >
                            <path
                                d="M8.72933 14.9529C8.33425 15.3743 7.66533 15.3743 7.27025 14.9529L0.924369 8.18392C0.32562 7.54525 0.778465 6.49998 1.65391 6.49998L4.49979 6.49998L4.49979 5.49998C4.49979 4.94769 4.94751 4.49998 5.49979 4.49998L10.4998 4.49998C11.0521 4.49998 11.4998 4.94769 11.4998 5.49998L11.4998 6.49998L14.3457 6.49998C15.2211 6.49998 15.674 7.54525 15.0752 8.18392L8.72933 14.9529Z"
                                fill="#744DB2"
                            />
                            <path
                                d="M11.4998 2.49998C11.4998 3.05226 11.0521 3.49998 10.4998 3.49998L5.49979 3.49998C4.94751 3.49998 4.49979 3.05226 4.49979 2.49998L4.49979 1.49998C4.49979 0.947692 4.94751 0.499977 5.49979 0.499977L10.4998 0.499978C11.0521 0.499978 11.4998 0.947693 11.4998 1.49998L11.4998 2.49998Z"
                                fill="#744DB2"
                            />
                        </svg>
                    </template>
                </downloader>
            </div>
        </div>
    </div>
    <div class="top-bar__container desktopHidden">
        <downloader
            v-model="selectedDownloadType"
            class="file-downloader"
            type="secondary"
        >
            <template #icon>
                <svg
                    id="btn-select-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                >
                    <path
                        d="M8.72933 14.9529C8.33425 15.3743 7.66533 15.3743 7.27025 14.9529L0.924369 8.18392C0.32562 7.54525 0.778465 6.49998 1.65391 6.49998L4.49979 6.49998L4.49979 5.49998C4.49979 4.94769 4.94751 4.49998 5.49979 4.49998L10.4998 4.49998C11.0521 4.49998 11.4998 4.94769 11.4998 5.49998L11.4998 6.49998L14.3457 6.49998C15.2211 6.49998 15.674 7.54525 15.0752 8.18392L8.72933 14.9529Z"
                        fill="#744DB2"
                    />
                    <path
                        d="M11.4998 2.49998C11.4998 3.05226 11.0521 3.49998 10.4998 3.49998L5.49979 3.49998C4.94751 3.49998 4.49979 3.05226 4.49979 2.49998L4.49979 1.49998C4.49979 0.947692 4.94751 0.499977 5.49979 0.499977L10.4998 0.499978C11.0521 0.499978 11.4998 0.947693 11.4998 1.49998L11.4998 2.49998Z"
                        fill="#744DB2"
                    />
                </svg>
            </template>
        </downloader>
        <refresh-button @click="loadHistory(false)"/>
        <filter-mobile :is-filtered="isFiltered" class="filter-mobile">
          <business-wallet-network-select
              v-model="selectedCurrency"
              :list="filteredCurrencies"
              :placeholder="`${$t('orders.currency')}...`"
              clearable
              uniq-key="id"
              @input="selectCurrency"
          />
            <orphan-search
                class="search"
                v-model="query"
                :placeholder="$t('transactions.search-address-id')"
            />
            <TransactionsSortByType @selectType="selectType" />
            <SortByDate @selectDate="selectDate" />
          <address-risks-select :value="riskLevel" @input="selectRisk" />
        </filter-mobile>
    </div>
</div>
</template>

<script>
import TransactionsSortByType from "./TransactionsSortByType";
import SortByDate from "./SortByDate";
import {mapActions, mapState} from "vuex";
import BtnRefresh from "../../common/btn-refresh";
import _ from "lodash";
import BaseInput from "@/public/components/common/uiKit/input/base/index.vue";
import OrphanSearch from "@/public/components/elements/orphan-transactions/OrphanSearch.vue";
import RefreshButton from "@/public/components/common/uiKit/button/refresh/index.vue";
import Downloader from "@/public/components/elements/personal-addresses/Select/Downloader/index.vue";
import FilterMobile from "@/public/components/elements/accounts/History/FilterToggler/index.vue";
import ColumnsFilter from "@/public/components/elements/history-addresses/column-filter/index.vue";
import OrdersStatusSelect from "@/public/components/elements/orders/Select/OrdersStatusSelect.vue";
import BusinessWalletNetworkSelect
  from "@/public/components/elements/business-walet/Select/businessWalletNetworkSelect.vue";
import AddressRisksSelect from "@/public/components/elements/address-risks/Select/AddressRisksSelect.vue";

export default {
    name: "TopBarHistory",

    components: {
      AddressRisksSelect,

      BusinessWalletNetworkSelect,
        OrdersStatusSelect,
        ColumnsFilter,
        FilterMobile,
        Downloader,
        RefreshButton,
        OrphanSearch,
        BaseInput,
        TransactionsSortByType,
        SortByDate,
        BtnRefresh,
    },
    props: {
      page: {
          type: [Number, String],
          default: 1,
      },
        sortValue: {
            type: String,
            default: 'DESC'
        },
      networkId: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
          query: '',
          dates: [],
          type: [],
          selectedDownloadType: null,
          selectedStatus: null,
        selectedCurrency: null,
        riskLevel: undefined,
      }
    },
    computed: {
        ...mapState('app', ['isLoading', 'isInit']),
        ...mapState('app', ['networks', 'currencies']),
        ...mapState('historyAddresses', ['pages', 'count', 'pagesCount']),
        isFiltered ({ query, dates, type}) {
            return !!query || !!dates.length || !!type.length
        },
      filteredCurrencies () {
        const {
          currencies,
        } = this;
        let sortedCurrencies = currencies.filter(({isFiat, networks}) => !isFiat && networks.some(({id}) => id === this.networkId));


        return sortedCurrencies;
      },
        addressType() {
            return this.$route.query.type ? [this.$route.query.type] : [];
        },
        addressId() {
            return this.$route.params.id;
        },
        statuses () {
            return [
                {
                    value: 'processed'
                },
                {
                    value: 'pending'
                },
                {
                    value: 'error'
                },
                {
                    value: 'rejected'
                },
            ]
        },
    },
beforeMount() {
        this.setQueryFromUrl()
},
    watch: {
        selectedDownloadType(v) {
            const {
                $route,
                dates,
            } = this;
            if (!v) {return}
            if (v.id === 'csv') {
                this.download();
            } else {
                this.downloadXLSXQuery();
            }
            this.selectedDownloadType = undefined
        },
        query () {
            this.loadHistory(true)
        },
        page() {
            this.loadHistory(false)
        },
        sortValue () {
            this.loadHistory(false)
        }
    },
    methods: {
        ...mapActions('historyAddresses', [
            'addressTransactions',
            'downloadXLSX',
            'downloadCSV',
        ]),
        selectType (val) {
          this.type = val
          this.loadHistory(true)
        },
        selectRisk (val) {
          this.riskLevel = val
          this.loadHistory(true)
        },
        selectCurrency (val) {
          this.selectedCurrency = val
          this.loadHistory(true)
        },
        selectDate (val) {
          this.dates = val
          this.loadHistory(true)
        },
        setStatus (value) {
            this.selectedStatus = value
            this.loadHistory(true)
        },
        setQueryFromUrl() {
            const queryFromUrl = this.$route.query.search;

            if (!queryFromUrl) {
                return '';
            }

            return this.query = queryFromUrl
        },
        loadHistory(changePage) {
            if (changePage) {
                this.$emit('changePage', 1)
            }
            this.addressTransactions({
                route: this.$route,
                router: this.$router,
                addressType: this.addressType,
                addressId: this.addressId,
                type: this.type,
                search: this.query,
                date: this.dates,
                page: this.page,
                sortDate: this.sortValue,
                status: this.selectedStatus ? this.selectedStatus.value : null,
                currencyId: this.selectedCurrency ? [this.selectedCurrency?.id] : [],
                riskLevel: this.riskLevel ? this.riskLevel.value : undefined
            });
        },
        downloadXLSXQuery() {
            const {
                $route,
            } = this;

            this.downloadXLSX({
                route: this.$route,
                router: this.$router,
                addressType: this.addressType,
                addressId: this.addressId,
                type: this.type,
                search: this.query,
                date: this.dates,
                page: this.page,
                sortDate: this.sortValue,
                status: this.selectedStatus ? this.selectedStatus.value : null,
                riskLevel: this.riskLevel ? this.riskLevel.value : undefined
            });
        },
        download() {
            const {
                $route,
            } = this;

            this.downloadCSV({
                route: this.$route,
                router: this.$router,
                addressType: this.addressType,
                addressId: this.addressId,
                type: this.type,
                search: this.query,
                date: this.dates,
                page: this.page,
                sortDate: this.sortValue,
                status: this.selectedStatus ? this.selectedStatus.value : this.selectedStatus,
                riskLevel: this.riskLevel ? this.riskLevel.value : undefined
            });
        },
    },
};
</script>

<style scoped lang="scss">
.search {
    width: 100%;
}
.file-downloader {
    min-width: 48px;
}
.top-bar {
  &__container {
    width: 100%;
    margin-bottom: 30px;
      @media screen and (max-width: 576px) {
          flex-direction: row;
          height: auto;
          justify-content: end;
          margin-right: 20px;
          padding: 20px 20px 10px 20px;
          gap: 10px;
      }
  }
    &__row {
        display: grid;
        grid-template-columns: 1fr 50px;
        gap: 10px;
        width: 100%;
    }
    &__input {
        margin-bottom: 10px;
        display: flex;
        //align-items: center;
        gap: 10px;
    }
  &__wrapper {
    display: flex;
  }

  &__filters {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      gap: 10px;
  }

  &__download-button {
    height: 46px;
    width: 46px;
    border-radius: 12px;
    padding: 0;
    border: none;
    margin-right: 10px;

    &:first-child {
      background: var(--icon-exel)
    }

    &:nth-child(2) {
      background: var(--icon-csv)
    }
  }

  &__btn-refresh {

    &::v-deep .at-btn__icon {
      color: #0059F7;
    }

    @media (min-width: 992px) {
      position: static;
      right: auto;
    }
  }
}

@media screen and (max-width: 950px) {
  ::v-deep .transactions-sort-by-type {
    width: 100%;
  }

  ::v-deep .transactions-filter-by-date {
    width: 100%;
  }

  .top-bar {

    &__filters {
      flex-direction: column;
      width: 100%;
      margin: 0;
    }

    &__wrapper {
      width: 100%;
      justify-content: flex-end;
    }
  }
}
.topBar__inputLabel {
    margin-bottom: 8px;
    font-size: var(--font-size-text-main);
    font-family: var(--font-family);
    line-height: var(--line-height);
    color: var(--primary-font-color);
}
::v-deep {
    .advanced-balances-select-wrapper .sublabel {
        display: none;
    }
}
.filter-mobile {
    width: 48px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
::v-deep {
    .currency-search-wrapper {
        width: 100%;
        margin-bottom: 10px;
        .search-input {
            &::placeholder {
                opacity: 1 !important;
            }
        }
        @media screen and (max-width: 576px) {
            .search-input {
                font-size: 13px;
                padding: 0 40px 0 10px;
                &::placeholder {
                    font-size: 13px !important;
                    opacity: 1 !important;
                }
            }
        }
    }
    .file-downloader {
            .btn-wrapper {
                width: 48px;
            }
    }
    .filter-toggler__content {
        min-width: 280px;
        gap: 10px;
        .currency-search-wrapper {
            margin-bottom: 0;
        }
    }

}
.desktopHidden {
    display: none;
}
.mobileHidden {
    display: block;
}



@media screen and (max-width: 576px) {
    .desktopHidden {
        display: flex;
    }
    .mobileHidden {
        display: none;
    }
    ::v-deep {
        .mx-input {
            padding: 0 10px !important;
            &::placeholder {
                font-size: 13px;
            }
        }
      .advanced-balances-select-wrapper {
        width: 100%;
      }
    }
}
</style>
