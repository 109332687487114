<script>
  import WidgetsManagePreviewBg from "@/public/components/elements/widgets-manage/preview/WidgetsManagePreviewBg.vue";
  import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
  import PoweredByIcon from "@/public/components/elements/widgets-manage/preview/PoweredByIcon.vue";
  import {mapState} from "vuex";
  import {
    PAYIN_CRYPTO_TYPE_INVOICE,
    PAYIN_CRYPTO_TYPE_PERSONAL,
    STEP_PAY_SELECT,
    STEP_START_PAGE, STEP_WIDGET
  } from "@/public/pages/widgets/constants";
  import WidgetsPreviewOrganizationLogo
    from "@/public/components/elements/widgets-manage/settings/WidgetsPreviewOrganizationLogo.vue";
  import WidgetsDemoLinkBlock from "@/public/components/elements/widgets-manage/settings/WidgetsDemoLinkBlock.vue";
  import WidgetsDemoScriptBlock from "@/public/components/elements/widgets-manage/settings/WidgetsDemoScriptBlock.vue";

  export default {
    name: "widgetsManagePreview",
    components: {
      WidgetsDemoScriptBlock,
      WidgetsDemoLinkBlock, WidgetsPreviewOrganizationLogo, PoweredByIcon, BaseButton, WidgetsManagePreviewBg},
    PAYIN_CRYPTO_TYPE_PERSONAL,
    PAYIN_CRYPTO_TYPE_INVOICE,
    data () {
      return {
        isApollo: import.meta.env.VITE_PRODUCT_NAME === 'Apollopayment',
        copied: false,
      }
    },
    methods: {
      async copyClipboard(value) {
        await navigator.clipboard.writeText(value)
        this.copied = true
        setTimeout(() => this.copied = false, 2000)
      },
    },
    computed: {
      ...mapState('widgetsManage', ['widget', 'currentStep', "demo"]),
      isStartPageShow () {
        return this.currentStep === STEP_START_PAGE || this.currentStep === STEP_PAY_SELECT
      },
      showDepositBtn () {
        return this.widget.payinCryptoType === PAYIN_CRYPTO_TYPE_PERSONAL || this.widget.payinCryptoType === PAYIN_CRYPTO_TYPE_INVOICE
      },
      isDemoStep () {
        return this.currentStep === STEP_WIDGET
      },
      generateApolloPaymentButton(data) {
        const {
          payinCryptoInvoiceCurrencies,
          payinCryptoInvoiceId,
          payinCryptoInvoiceDesc,
          payinCryptoInvoiceAmount,
          payinCryptoInvoicePayerEmail,
          payoutCryptoReceiverEmail,
          payoutCryptoAmountFrom,
          payoutCryptoCurrenciesFrom,
          payinCryptoCurrenciesTo
        } = this.demo;

        // Функция для преобразования объектов в строку URL параметров
        const objectToUrlParams = (obj) => {
          const params = new URLSearchParams();

          const appendCurrencyParams = (key, array) => {
            if (array) {
              const formatted = array.map(({ currency, network, networkId }) =>
                  network && networkId ? `${currency}_${network}` : currency
              ).join(',');
              if (formatted) params.append(key, formatted);
            }
          };

          // Добавляем валюты с помощью вспомогательной функции
          appendCurrencyParams('payinCryptoCurrenciesTo', obj.payinCryptoCurrenciesTo);
          appendCurrencyParams('payinCryptoInvoiceCurrencies', obj.payinCryptoInvoiceCurrencies);

          // Добавляем остальные параметры
          Object.entries(obj).forEach(([key, value]) => {
            if (value && !['payinCryptoCurrenciesTo', 'payinCryptoInvoiceCurrencies'].includes(key)) {
              params.append(key, value);
            }
          });

          return params.toString();
        };

        // Подготовка атрибутов
        const attributes = Object.fromEntries(
            objectToUrlParams({
              widgetid: this.widget.id,
              payinCryptoInvoiceId,
              payinCryptoInvoiceAmount,
              payinCryptoInvoiceCurrencies,
              payinCryptoCurrenciesTo,
              payinCryptoInvoiceDesc,
              payinCryptoInvoicePayerEmail,
              payoutCryptoReceiverEmail,
              payoutCryptoAmountFrom,
              payoutCryptoCurrenciesFrom
            }).split('&').map(param => {
              const [key, value] = param.split('=');
              return [`data-${key}`, decodeURIComponent(value)];
            })
        );

        // Фильтрация и создание строки атрибутов
        const filteredAttributes = Object.entries(attributes)
            .filter(([_, value]) => value)
            .map(([key, value]) => `  ${key}="${value}"`)  // добавляем отступ для новой строки
            .join('\n');  // каждый атрибут на новой строке

        // Возвращаем строку с тегом <pre> и отформатированными атрибутами
        return `&lt;apollo-payment-button\n${filteredAttributes}\n&gt;&lt;/apollo-payment-button&gt;`;
      },
      paymentButtonWithArrows() {
        const encodedHtml = this.generateApolloPaymentButton
        return encodedHtml
            .replace(/&lt;/g, '<')  // Замена &lt; на стрелочку влево
            .replace(/&gt;/g, '>'); // Замена &gt; на стрелочку вправо
      }
    }
  }
</script>

<template>
<div class="preview">
  <div class="startPage" v-if="isStartPageShow">
    <WidgetsManagePreviewBg class="bg" />
    <div class="organization">
      <WidgetsPreviewOrganizationLogo />
    </div>
    <div class="buttons">
      <base-button v-if="showDepositBtn" :label="$t('widgets.deposit')" />
      <base-button v-if="widget.payoutCrypto" :label="$t('widgets.withdraw')" />
    </div>
    <div class="footer">
      <powered-by-icon />
    </div>
    <div v-if="!widget.showStartPage" class="locked">
      <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.5515 73.4531C19.8528 73.4531 18.3988 72.8484 17.1893 71.6389C15.9798 70.4294 15.375 68.9753 15.375 67.2766V35.2129C15.375 33.5143 15.9798 32.0602 17.1893 30.8507C18.3988 29.6412 19.8528 29.0365 21.5515 29.0365H25.625V22.2031C25.625 17.9368 27.1209 14.3078 30.1128 11.3159C33.1047 8.32405 36.7337 6.82812 41 6.82812C45.2663 6.82812 48.8953 8.32405 51.8872 11.3159C54.8791 14.3078 56.375 17.9368 56.375 22.2031V29.0365H60.4485C62.1472 29.0365 63.6012 29.6412 64.8108 30.8507C66.0203 32.0602 66.625 33.5143 66.625 35.2129V67.2766C66.625 68.9753 66.0203 70.4294 64.8108 71.6389C63.6012 72.8484 62.1472 73.4531 60.4485 73.4531H21.5515ZM41 57.224C42.6599 57.224 44.0716 56.6426 45.235 55.4797C46.3978 54.3164 46.9792 52.9047 46.9792 51.2448C46.9792 49.5849 46.3978 48.1732 45.235 47.0098C44.0716 45.847 42.6599 45.2656 41 45.2656C39.3401 45.2656 37.9284 45.847 36.765 47.0098C35.6022 48.1732 35.0208 49.5849 35.0208 51.2448C35.0208 52.9047 35.6022 54.3164 36.765 55.4797C37.9284 56.6426 39.3401 57.224 41 57.224ZM30.75 29.0365H51.25V22.2031C51.25 19.3559 50.2535 16.9358 48.2604 14.9427C46.2674 12.9497 43.8472 11.9531 41 11.9531C38.1528 11.9531 35.7326 12.9497 33.7396 14.9427C31.7465 16.9358 30.75 19.3559 30.75 22.2031V29.0365Z" fill="#7E7E7E"/>
      </svg>
      <div v-if="!widget.showStartPage" class="locked__text">
        {{ $t('widgets.startPageLocked') }}
      </div>
    </div>
  </div>
  <div v-if="!isStartPageShow && !isDemoStep" :class="['preview__block', { bottomAlign: isDemoStep }]">
    <template v-if="!isApollo">
      <template v-if="widget.theme === 'dark'">
        <img v-if="widget.payinCryptoType === $options.PAYIN_CRYPTO_TYPE_INVOICE" src="../assets/invoice_ocp.svg" alt="">
        <img v-else src="../assets/personal_ocp.svg" alt="">
      </template>
      <template v-else>
        <img v-if="widget.payinCryptoType === $options.PAYIN_CRYPTO_TYPE_INVOICE" src="../assets/invoice_ocp_light.svg" alt="">
        <img  v-else src="../assets/personal_ocp_light.svg" alt="">
      </template>
    </template>
    <template v-else>
      <template v-if="widget.theme === 'dark'">
        <img v-if="widget.payinCryptoType === $options.PAYIN_CRYPTO_TYPE_INVOICE" src="../assets/invoice_apollo.svg" alt="">
        <img v-else src="../assets/personal_apollo.svg" alt="">
      </template>
      <template v-else>
        <img v-if="widget.payinCryptoType === $options.PAYIN_CRYPTO_TYPE_INVOICE" src="../assets/invoice_apollo_light.svg" alt="">
        <img  v-else src="../assets/personal_apollo_light.svg" alt="">
      </template>
    </template>
  </div>
  <div  v-if="isDemoStep" class="script-wrapper">
    <WidgetsDemoScriptBlock />
  </div>
  <div class="blur"></div>
</div>
</template>

<style scoped lang="scss">
.script-wrapper {
  max-height: 100%;
  overflow: auto;
  width: 100%;
}
//.demo-wrapper {
//  position: absolute;
//  top: 15px;
//  left: 15px;
//  z-index: 20;
//  width: calc(100% - 30px);
//  @media (max-width: 850px) {
//    display: none;
//  }
//}
.preview {
  position: relative;
  width: 100%;
  //height: 888px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg-background-fourthly);
  padding: 20px 20px;
  overflow: hidden;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  @media (max-width: 850px) {
    display: none;
    border-top-right-radius: 0;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    height: 400px;
  }
  &__block {
    position: relative;
    z-index: 5;
    height: 95%;
    display: flex;
    align-items: center;
    max-width: 580px;
    &.bottomAlign {
      display: flex;
      align-items: flex-end;
      max-width: 580px;
    }
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .startPage {
    position: relative;
    z-index: 5;
    padding: 0 30px;
    border-radius: 20px;
    background: var(--bg-background-iframe-secondary);
    width: 583px;
    height: 700px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    pointer-events: none;
    .organization {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    @media (max-width: 1440px) {
      width: 560px;
      height: 670px;
    }
    @media (max-width: 850px) {
      width: 282px;
      height: 339px;
      padding: 0 10px;
    }
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
    .buttons {
      position: relative;
      z-index: 3;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding-bottom: 70px;
      @media (max-width: 850px) {
        padding-bottom: 40px;
        gap: 7px;
        &::v-deep {
          .label {
            font-size: 9px;
          }
        }
        .base-btn {
          height: 28px;
          border-radius: 5px;
        }
      }
    }
    .footer {
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 15px;
      left: 0;
    }
    .locked {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #000;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15px;
      background: rgba(0, 0, 0, 0.55);
      backdrop-filter: blur(7.5px);
      border-radius: 15px;
      z-index: 5;
      &__text {
        color: var(--text-color-text-secondary, #7E7E7E);
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
}
.blur {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(50%);
  width: 400px;
  height: 400px;
  border-radius: 400px;
  opacity: 0.7;
  background: linear-gradient(153deg, #744DB2 -33.83%, #8D4CF5 33.16%, #744DB2 96.25%);
  filter: blur(250px);
  z-index: 1;
}
</style>
