<template>
  <p
      class="empty"
  >
    {{ $t('addresses.no_data_available') }}
  </p>
</template>

<script>

export default {
    name: 'EmptyTable',
};

</script>

<style lang="scss" scoped>
.empty {
  line-height: var(--line-height);
  font-family: var(--new-front-font-family);
  font-size: var(--font-size-h3);
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-bottom: 30px;
  color: var(--new-front-primary-font-color);
}
</style>
