<template>
<div class="orphan">
    <zero-state
      v-if="showZeroState"
      :meta="zeroStateMeta"
      :background-transparent="false"
    />

    <Container v-else>
        <div class="orphan__topBar">
            <div class="orphan__searchRow">
                <div class="search">
                    <orphan-search :placeholder="$t('new_invoice.search')" v-model="searchString" />
                </div>
                    <refresh-button  @click="refresh"/>
            </div>
            <div class="orphan__gridRow">
                <business-wallet-network-select
                  :value="currencyValue"
                  :list="filteredCurrencies"
                  :label="$t('business-wallet.currency')"
                  :placeholder="currencySelectPlaceholder"
                  clearable
                  uniq-key="id"
                  @input="handleSelectCurrency"
                />
                <business-wallet-network-select
                  :value="networksValue"
                  :list="filteredNetworks"
                  :label="$t('orders.network')"
                  :placeholder="networkSelectPlaceholder"
                  clearable
                  uniq-key="id"
                  @input="handleSelectNetwork"
                />
                <orphan-status-select
                  :value="statusValue"
                  :list="statuses"
                  :label="$t('orphan.select-status')"
                  :placeholder="$t('orphan.select-status')"
                  clearable
                  uniq-key="value"
                  @input="handleSelectStatus"
                />
            </div>
        </div>
        <div class="orphan__topBar--mobile">
            <refresh-button @click.native="refresh"/>
            <filter-mobile
              :is-filtered="!isEmptyFilters"
              @confirm="loadOrphans"
              @reset="resetFilters"
            >
                <div class="orphan__topBar-filterMobile">
                    <orphan-search :placeholder="$t('new_invoice.search')" v-model="searchString" />
                    <business-wallet-network-select
                        :value="currencyValue"
                        :list="filteredCurrencies"
                        :label="''"
                        :placeholder="currencySelectPlaceholder"
                        uniq-key="id"
                        clearable
                        @input="handleSelectCurrency"
                    />
                    <business-wallet-network-select
                        :value="networksValue"
                        :list="filteredNetworks"
                        :label="''"
                        :placeholder="networkSelectPlaceholder"
                        uniq-key="id"
                        clearable
                        @input="handleSelectNetwork"
                    />
                    <orphan-status-select
                        :value="statusValue"
                        :list="statuses"
                        :label="$t('orphan.select-status')"
                        :placeholder="$t('orphan.select-status')"
                        uniq-key="value"
                        clearable
                        @input="handleSelectStatus"
                    />
                </div>
            </filter-mobile>
        </div>
        <template v-if="!isLoading">
            <Table class="orphan__table" @sort="sortTable" :header-list="tableHeader">
                <table-item v-for="item in paginateSortedOrphans" :item="item" @openDetails="openDetails"/>
            </Table>
            <div class="orphan__mobileList">
                <orphan-mobile-item v-for="item in paginateSortedOrphans" :item="item" @openDetails="openDetails" />
            </div>
            <div v-if="!paginateSortedOrphans.length" class="orphan__table-empty">
                {{ $t('orphan.empty') }}
            </div>
            <Pagination :total="orphansCount" :page-size="pageSize" @page-change="catchPagination" v-if="orphansCount > this.pageSize"  />
        </template>
        <template v-else>
            <new-spinner />
        </template>
    </Container>
    <transition name="slide-fade">
        <orphan-info v-if="isInfoOpened" />
    </transition>
</div>
</template>
<script>
    import Container from "@/public/components/common/uiKit/Container.vue";
    import Pagination from "@/public/components/common/uiKit/Pagination.vue";
    import OrphanSearch from "@/public/components/elements/orphan-transactions/OrphanSearch.vue";
    import BusinessWalletNetworkSelect
        from "@/public/components/elements/business-walet/Select/businessWalletNetworkSelect.vue";
    import Table from "@/public/components/elements/orphan-transactions/table/Table.vue";
    import RefreshButton from "@/public/components/common/uiKit/button/refresh/index.vue";
    import TableItem from "@/public/components/elements/orphan-transactions/table/TableItem.vue";
    import FilterMobile from "@/public/components/elements/orphan-transactions/filter/index.vue";
    import Copy from "@/public/components/common/copy.vue";
    import OrphanMobileItem from "@/public/components/elements/orphan-transactions/OrphanMobileItem.vue";
    import {mapActions, mapGetters, mapState} from "vuex";
    import OrphanStatusSelect from "@/public/components/elements/orphan/Select/orphanStatusSelect.vue";
    import CommonInfoModal from "@/public/components/modals/commonInfo/index.vue";
    import OrphanInfo from "@/public/components/elements/orphan/OrphanInfo.vue";

    import _ from "lodash";

    export default  {
    name: 'OrphanTransactions',
    components: {
        OrphanInfo,
        CommonInfoModal,
        OrphanStatusSelect,
        OrphanMobileItem,
        Copy,
        FilterMobile,
        NewSpinner: () => import (/* webpackChunkName: "Spinner" */ "/public/components/common/uiKit/spinner/index.vue"),
        TableItem,
        RefreshButton,
        Table,
        BusinessWalletNetworkSelect,
        OrphanSearch,
        Container,
        Pagination,
        ZeroState: () => import(/* webpackChunkName: "public/pages/zeroState" */ '/public/pages/zeroState')
    },
    data () {
        return {
            searchString: '',
            networksValue: null,
            currencyValue: null,
            statusValue: null,
            sortValue: null,
            page: 1,
            pageSize: 10,
        }
    },
    computed: {
        ...mapState('orphan', ['orphanList', 'isLoading', 'isInfoOpened']),
        ...mapState('app', ['networks', 'currencies', 'isLoading']),
        ...mapGetters('organizations', ['userRoleRules']),
        tableHeader () {
          return [
              {
                  title: this.$t('orphan.date'),
                  slug: 'date',
                  isSort: true
              },
              {
                  title: 'tx hash in',
                  slug: 'hashIn',
                  isSort: false
              },
              {
                  title: this.$t('orphan.amount'),
                  slug: 'amount',
                  isSort: false
              },
              {
                  title: this.$t('orphan.currency-network'),
                  slug: 'currencyNetwork',
                  isSort: false
              },
              {
                  title: this.$t('orphan.where-found'),
                  slug: 'found',
                  isSort: false
              },
              {
                  title: 'tx hash out',
                  slug: 'hashOut',
                  isSort: false
              }
          ]
        },
        orphanBalanceFiltered() {
            const {
                orphanList,
                hideEmptyBalances,
                currencyValue,
                networksValue,
                searchString
            } = this;

            let preparedOrphan = _.cloneDeep(orphanList);
            if (currencyValue && networksValue) {
                preparedOrphan = preparedOrphan.filter(({currencyId, networkId}) => currencyId === currencyValue.id && networkId === networksValue.id)
            }
            if (currencyValue) {
                preparedOrphan = preparedOrphan.filter(({currencyId}) => currencyId === currencyValue.id)
            }
            if (networksValue) {
                preparedOrphan = preparedOrphan.filter(({networkId}) => networkId === networksValue.id)
            }

            if (!searchString) {
                return preparedOrphan;
            }

            return  preparedOrphan.filter(item => item.inTransaction.txId.toLowerCase().includes(searchString.toLowerCase()));
        },
        statuses () {
            return [
                {
                    title: this.$t(`orphan.status-processed`),
                    value: 'processed'
                },
                {
                    title: this.$t(`orphan.status-pending`),
                    value: 'pending'
                },

                {
                    title: this.$t(`orphan.status-error`),
                    value: 'error'
                },
            ]
        },
        filteredNetworks() {
            const {
                currencies,
            } = this;

          const networks = {};

          for(const currency of currencies) {
            if(!currency.allowBusiness) {
              continue;
            }

            for(const network of currency.networks) {
              if(!network.allowDeposit) {
                continue;
              }

              networks[network.id] = network;
            }
          }

          return Object.values(networks);
        },
        currencySelectPlaceholder () {
            return this.$t('orders.select_currencies')
        },
        networkSelectPlaceholder () {
            return this.$t('orders.select_network')
        },
        filteredCurrencies () {
            const {
                currencies,
            } = this;
            let sortedCurrencies = currencies.filter(({isFiat}) => !isFiat);

            if (this.networksValue) {
                sortedCurrencies = sortedCurrencies.filter((i) => {
                    return i.networks.find(({id}) => id === this.networksValue.id);
                });
                return sortedCurrencies;
            }

            return sortedCurrencies;
        },
        paginateSortedOrphans() {
            let arr = this.orphanList
            if (this.sortValue) {
                arr = this.sortValue.direction ? arr.toSorted((a, b) =>   Number(new Date(a.createdAt)) - Number(new Date(b.createdAt))) : arr.toSorted((a, b) =>  Number(new Date(b.createdAt)) -  Number(new Date(a.createdAt)))
            }
            return arr.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
        },
        orphansCount() {
            return this.orphanList?.length ?? 0;
        },
        isEmptyFilters({ searchString, networksValue, currencyValue, statusValue }) {
          return !searchString && _.isEmpty(networksValue) && _.isEmpty(currencyValue) && _.isEmpty(statusValue)
        },
        showZeroState({ isEmptyFilters, paginateSortedOrphans }) {
          return isEmptyFilters && !paginateSortedOrphans.length
        },
        zeroStateMeta() {
          return {
            title: this.$t('zero-states.orphan-transactions.title'),
            subtitle: this.$t('zero-states.orphan-transactions.subtitle'),
            notifications: [
              this.$t('zero-states.orphan-transactions.notifications.first-line')
            ],
            type: 'default'
          }
        }
    },
    created() {
        const isAllowed = this.userRoleRules['orphan'].isAllowed;
        const isShowAllowed = this.userRoleRules['orphan'].entities.view_transactions
        if (!isAllowed || !isShowAllowed) {
            this.$Message.error(this.$t('rules.not_allowed'));
            this.$router.push('/merchant/dashboard');
        }
        this.getOrphanDeposits()
    },
        watch: {
          searchString () {
              this.loadOrphans()
          }
        },
    methods: {
        ...mapActions('orphan', ['getOrphanDeposits', 'getOrphanDeposit']),
        openDetails (id) {
            this.getOrphanDeposit(id)
        },
        async loadOrphans () {
            const variables = {
                currencyIds: this.currencyValue ? [ this.currencyValue.id ] : null,
                networkIds: this.networksValue ? [ this.networksValue.id ] : null,
                status: this.statusValue ? this.statusValue.value.toUpperCase() : null,
                txId: this.searchString
            }
            await this.getOrphanDeposits(variables)
        },
        sortTable (slug, direction) {
          this.sortValue = {
              slug,
              direction
          }
        },
        handleSelectNetwork(value) {
            this.networksValue = value
            this.currencyValue = null;
            this.loadOrphans()
        },
        handleSelectCurrency(value) {
            this.currencyValue = value;
            this.loadOrphans()
        },
        handleSelectStatus(value) {
            this.statusValue = value;
            this.loadOrphans()
        },
        catchPagination(page) {
            this.page = page;
        },
        refresh () {
            const filters = ['networksValue', 'currencyValue', 'statusValue', 'searchString']

            filters.forEach(filter => {
              this.$set(this, filter, '')
            })

            this.loadOrphans()
        },
        resetFilters() {
          this.refresh()
        }
    }
}
</script>

<style scoped lang="scss">
.orphan {
    padding: 0 30px 30px 30px;
    font-family: $Gilroy;
    &__mobileList {
        display: none;
    }
    &__topBar {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-bottom: 30px;
    }
  &__gridRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }
    &__searchRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        .search {
            width: 93%;
        }
    }
    &__title {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: $h1;
        color: var(--new-front-text-primary-bg-modal-primary);
        margin-left: $p5;
        margin-bottom: $p5;
        line-height: 31px;
        > svg {
            margin-left: 5px;
        }
    }
    &__subtitle {
        font-size: $h3;
        font-weight: 400;
        color: var(--new-front-secondary-font-color);
        margin-bottom: $p30;
        margin-left: $p5;
    }
    &__table {
        &-empty {
            font-weight: 500;
            padding: 30px;
            font-size: 24px;
            text-align: center;
            margin-top: 20px;
            color: var(--new-front-text-primary-bg-modal-primary);
        }
    }
}
.orphan__topBar--mobile {
    display: none;
}
::v-deep {
    .copy-wrapper {
        margin: 0;
    }
}
@media (max-width: 1024px) {
    .orphan__table {
        display: none;
        &-empty {
            font-size: 16px;
            border-top: 1px solid var(--new-front-divider-color)
        }
    }
    .orphan__mobileList {
        display: unset;
    }
}
@media (max-width: 576px) {
    .orphan {
        padding: $p10;
    }
    .orphan__topBar {
        display: none;
        &-filterMobile {
            min-width: 280px;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }
    .orphan__topBar--mobile {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        padding: $p20 $p20 0 $p20 ;
    }
    .orphan__title {
        font-size: $h3;
        margin-top: $p10;
        font-weight: 500;
    }
    .orphan__subtitle {
        font-size: $h6;
        margin-bottom: $p10;
    }
    ::v-deep .container-new {
        padding-bottom: 30px;
    }
}
::v-deep {
    .orphan__gridRow, .orphan__topBar--mobile {
        .advanced-balances-select-wrapper .sublabel {
            display: none;
        }
        .advanced-balances-select-wrapper .label {
            display: none;
        }
    }
    .base-btn .label {
        display: block !important;
    }
}
.orphan__gridRow, .orphan__topBar--mobile {
    ::v-deep .icon  {
        .currency-network-icon__network {
            width: 22px !important;
            height: 22px !important;

            border: none !important;
            position: unset !important;

            top: unset !important;
            left: unset !important;

            transform: unset !important;
        }
    }
}
::v-deep .advanced-balances-select-wrapper .input-wrapper .input {
    background: var(--new-front-main-bg);
}
::v-deep .advanced_balances__network-select {
    margin: 0 !important;
}
</style>
