<template>
    <div v-if="pending">
        грузим
    </div>
    <div v-else>
        <div v-if="success">
            успех, жди письмо на почту
        </div>
        <form @submit.prevent="onSubmit" v-else>
            <input name="email" v-model="email" placeholder="email" />
            <br/>
            <input name="password" v-model="password" placeholder="password" />
            <br/>
            <input name="rePassword" v-model="rePassword" placeholder="rePassword" />
            <br/>
            <input name="refCode" v-model="refCode" placeholder="refCode" />
            <br/>
            <br/>

            <button type="submit">submit</button>
        </form>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "sign-up",
    data() {
        return {
            email: '',
            password: '',
            rePassword: '',
            refCode: '',

            pending: false,
            success: false,
        };
    },

    beforeMount() {
        const utm = document.location.search;
        const formattedUtm = !!utm ? utm.split('?').join('') : ''
        this.$router.push(`/registration?${formattedUtm}`)
    },
    methods: {
        async onSubmit() {
            this.pending = true;

            try {
              const loc = window.location.href || null;
              const ref = document.referrer || null;
              let ga = null;

              const _ga = (document.cookie || '').matchAll(/_ga=([A-Z0-9.]+);/g);

              if(_ga) {
                let __ga = _ga.next();

                if(__ga && __ga.value && __ga.value[1]) {
                  __ga = __ga.value[1].split('.').reverse();

                  if(__ga.length >= 2) {
                    ga = __ga[1] + '.' + __ga[0];
                  }
                }
              }

                const {data} = await axios.post('/public-api/sign-up', {
                    email: this.email,
                    password: this.password,
                    rePassword: this.rePassword,
                    utm: '', // todo: сами выцепите как оно берется со станици реги в осп
                    refCode: this.refCode, // todo: сами посмотрите от куда он берется там же
                  ga: ga,
                  location: loc,
                  referrer: ref,
                });

                if(!data.success) {
                    /*
                    INCORRECT_EMAIL: 3000,
                    INCORRECT_PASSWORD: 3001,
                    INCORRECT_RE_PASSWORD: 3002,
                    ALREADY_EXISTS: 4000,
                    * */

                    switch (data.code) {
                        case 3000:{
                            alert('некорректная почта');
                        }break;
                        case 3001:{
                            alert('некорректный пароль');
                        }break;
                        case 3002:{
                            alert('пароли не совпадают');
                        }break;
                        case 4000:{
                            alert('пользователь с такой почтой уже зареган');
                        }break;
                        default:
                            alert('Internal error, code: ' + data.code);
                            break;
                    }
                } else {
                    this.success = true;
                }

                this.pending = false;
            } catch (e) {
                console.log('reg error', e);

                await new Promise(r => setTimeout(r, 1000));

                return this.onSubmit();
            }
        }
    },
}
</script>

<style scoped>

</style>
