
import gql from 'graphql-tag';

export default gql`

mutation (    $id: String!,
    $organizationId: String!,
    $otpToken: String!
    ) {
  deleteCollectingSetting(
    id:  $id,
    organizationId: $organizationId,
    otpToken: $otpToken
  ) {
    code
    traceId
  }
}
`
