import gql from 'graphql-tag';
// search: $search,
// $search: String,
export default gql`
    query($organizationId: String!, $search: String,  $addressType: [AddressType!], $type: [AddressTransactionHistoryType!], $status: AddressTransactionHistoryStatus, $offset: Int, $limit: Int, $sortDate: String, $currencyId: [String], $networkId: [String], $date: [String!], $addressId: String, $riskLevel: String) {
        getAddressTransactions(organizationId: $organizationId, search: $search,  addressType: $addressType, type:$type, offset: $offset, status: $status, limit: $limit, sortDate: $sortDate, currencyId: $currencyId, networkId: $networkId, date: $date, addressId: $addressId, riskLevel: $riskLevel) {
          transactions {
            id
            userId
            status
            currencyId
            networkId
            addressType
            addressFrom
            addressFromId
            addressTo
            addressToId
            type
            amount
            txId
            alias
            comment
            createdAt
            risks
        }
        totalCount
        address {
            id
            currencyId
            networkId
            alias
            address
           }
        }
    }
`;
