<script>

  import Copy from "@/public/components/common/copy.vue";
  import HistoryButton from "@/public/components/common/uiKit/history/index.vue";
  import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
  import CurrencyImg from "@/public/components/common/uiKit/currencyImg/index.vue";
  import NetworkImg from "@/public/components/common/uiKit/networkImg/index.vue";
  import {mapGetters, mapState} from "vuex";

  export default {
    name: "DashboardInfoItem",
    components: {NetworkImg, CurrencyImg, BaseButton, HistoryButton, Copy},
    props: {
      balance: {
        type: Object,
        default: () => ({})
      },
      isWithdrawalDisabled: {
        type: Boolean,
        default: false,
      },
      isSwapDisabled: {
        type: Boolean,
        default: false,
      },
      mainCurrency: {
        type: String,
        default: ''
      },
      isCurrencyTab: {
        type: Boolean,
        default: true,
      }
    },
    computed: {
      ...mapGetters('dashboard', ['currentSymbol', 'amountCurrencyString']),
      ...mapState('app', ['currencies']),
      isSwapEnabled () {
        const currency = this.currencies.find(item => item.id === this.balance.currencyId)
        const network = currency.networks.find(item => item.id === this.balance.networkId)
        return network.allowCrosschainSwapFrom && network.allowCrosschainSwaps && this.balance.riskLevel !== 'severe' && Number(this.balance.amount) && !this.isSwapDisabled
      },
      fiatAmount () {
        return this.balance[this.amountCurrencyString]
      }
    },
    methods: {
      goToHistory () {
        this.$router.push(`/history-address/${ this.balance.id }`)
        this.$emit('closeInfo')
      }
    }
  }
</script>

<template>
<div class="item">
  <div class="item__top">
    <div class="item__top-left">
      <NetworkImg :network="balance.name" :size="'big'" v-if="isCurrencyTab" />
      <CurrencyImg :currency="balance.currency" :size="'big'" v-else />
      <div v-if="isCurrencyTab">
        <div class="item__top-title">
          {{ balance.name | getNetworkFullNameByNetworkName }} <span class="secondary-text">{{ balance.name | getNetworkShortNameByNetworkName }}</span>
        </div>
        <div class="item__top-textBottom id">
          {{ balance.address | preparedString }} <copy :value="balance.address" />
        </div>
      </div>
      <div v-else>
        <div class="item__top-title">
          {{ balance.currencyAlias }} <span class="secondary-text">({{ balance.currency }})</span>
        </div>
        <div class="item__top-textBottom id">
          {{ balance.address | preparedString }} <copy :value="balance.address" />
        </div>
      </div>
    </div>
    <div class="item__top-right">
      <div class="item__top-title text-right">
        {{ balance.amount }} {{ isCurrencyTab ? mainCurrency : balance.currency }}
      </div>
      <div v-if="!isCurrencyTab" class="item__top-textBottom text-right">
        {{ currentSymbol }}{{ fiatAmount }}
      </div>
    </div>
  </div>
  <div class="item__actions">
    <base-button class="btn-swap" :label="$t('payments.swap')" :disabled="!isSwapEnabled" type="primary" @click="$emit('openActionModal', balance, 'DashboardSwap')"  />
    <base-button class="btn-withdraw" :disabled="!Number(balance.amount) || isWithdrawalDisabled" :label="$t('payments.withdraw')" type="primary" @click="$emit('openActionModal', balance, 'DashboardCreateWithdrawalRequestModalPayout')" />
    <history-button class="btn-history" @click="goToHistory" />
  </div>
</div>
</template>

<style scoped lang="scss">
.item {
  padding: 40px 50px;
  border-bottom: 1px solid var(--border-devider-primary);
  &__top{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 30px;
    &-left {
      display: flex;
      align-items: flex-start;
      gap: 5px;
    }
    &-title {
      color: var(--text-text-primary, #000);
      font-size: 20px;
      font-weight: 500;
      line-height: 100%;
      margin-bottom: 3px;
    }
    &-textBottom {
      color: var(--text-color-text-secondary, #8B81A0);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      &.id {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
  &__actions {
    display: grid;
    grid-template-columns: 1fr 1fr 48px;
    grid-template-areas: 'swap withdraw history';
    gap: 10px;
  }
}
.secondary-text {
  color: var(--text-color-text-secondary, #8B81A0);
}
.text-right {
  text-align: right;
}
.btn-swap {
  grid-area: swap;
}
.btn-withdraw {
  grid-area: withdraw;
}
.btn-history {
  grid-area: history;
}
@media screen and (max-width: 576px) {

  .item {
    padding: 20px 30px;
  }
  .item__actions {
    grid-template-areas: 'swap history'
                          'withdraw withdraw';
    grid-template-columns: 1fr 48px;
  }
}
</style>
