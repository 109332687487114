export const state = {
    baseUrl: '',
    count: 0,
    pagesCount: 10,
    pages: [],
    page: {},
    pageCurrencies: [],
    pageOrders: [],
    pageOrdersCount: 0,
    pageOrdersSize: 10,
    pageOrder: {},
    donationOrder: {
        orderId: '',
        id: '',
        currencyId: '',
        amount: '',
        status: '',
        createdAt: '',
        networkId: '',
        priceUSD: '',
        receivedUSD: '',
        received: '',
        nickname: '',
        message: '',
        pageId: '',
    },
};