<template>
<div class="navItem__wrapper">
  <a id="intercom_custom_activator" :href="emailLink">
    <div :class="['navItem', item.active ? 'navItem--active' : '']">
      <div class="navItem__icon"><Icon :icon="item.icon"/> </div>
      <div class="navItem__title">{{ $t(item.title) }}</div>
    </div>
  </a>
</div>

</template>

<script>
  import Icon from "../../common/uiKit/Icon";
  export default {
    name: "NavSupportButton",
    components: {Icon},
      data () {
        return {
            emailLink: import.meta.env.VITE_SUPPORT_EMAIL
        }
      },
    props: {
      item: {
        type: Object,
        required: true,
      }
    }
  }
</script>

<style lang="scss" scoped>
.navItem {
  display: flex;
  align-items: center;
  padding: $p15 $p20;
  border-radius: 10px;
  transition: all .3s ease;
  font-family: $Gilroy;
  &:hover {
    background: var(--new-font-nav-hover-bg);
    .navItem__title {
      color: var(--new-front-primary-font-color);
    }
    .navItem__icon {
      &::v-deep svg {
        path {
          fill: #744DB2 !important;
        }
      }
    }
  }
  &--active {
    .navItem__title {
      color: var(--new-front-primary-font-color);
    }
    .navItem__icon {
      &::v-deep svg {
        path {
          fill: #744DB2 !important;
        }
      }
    }
  }
  &__icon {
    height: 16px;
    &::v-deep svg {
      path {
        fill: var(--new-front-secondary-font-color) !important;
      }
    }
  }
  &__title {
    color: var(--new-front-secondary-font-color);
    margin-left: $p10;
    font-size: $h4;
  }
  &__wrapper {
    padding: $p5 $p30;
  }
}
@media (max-width: 1180px) {
  .navItem__wrapper {
    padding: $p5 0;
  }
}
</style>
