<template>
  <div>
    <div class="balance ">
      <div class="balance__currency ">
        <CurrencyImg
            class="balance__currency-img"
            :currency="currencyId"
        />
        <span class="balance__value">{{ currencyId | getCurrencyTicker }}</span>
      </div>

      <div class="balance__id ">
        <CopyString
            :string="accountId"
            :successMessage="id_copied"
            :failMessage="id_not_copied"
            :title="notification"
            canCopy
        />
      </div>


      <p class="balance__value ">
        {{ balance }}
      </p>
      <div class="balance__actions">

        <AtButton
            :disabled=false
            @click="openReplenishRequestModal"
        >
          {{ $t('advance_balances.deposit') }}
        </AtButton>
        <RouterLink
            class="balance__history"
            :to="{ name: 'advanced-balance-history', params: { accountId } }"
        >
          <svg width="50" height="40" viewBox="0 0 50 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M25 12.5C23.0773 12.5055 21.2301 13.2492 19.84 14.5775V13.25C19.84 13.0511 19.761 12.8603 19.6203 12.7197C19.4797 12.579 19.2889 12.5 19.09 12.5C18.8911 12.5 18.7003 12.579 18.5597 12.7197C18.419 12.8603 18.34 13.0511 18.34 13.25V16.625C18.34 16.8239 18.419 17.0147 18.5597 17.1553C18.7003 17.296 18.8911 17.375 19.09 17.375H22.465C22.6639 17.375 22.8547 17.296 22.9953 17.1553C23.136 17.0147 23.215 16.8239 23.215 16.625C23.215 16.4261 23.136 16.2353 22.9953 16.0947C22.8547 15.954 22.6639 15.875 22.465 15.875H20.665C21.6293 14.8673 22.9146 14.227 24.2999 14.0643C25.6852 13.9015 27.0839 14.2265 28.2555 14.9833C29.4271 15.7401 30.2985 16.8814 30.7197 18.2111C31.141 19.5408 31.0859 20.9756 30.5638 22.269C30.0417 23.5625 29.0854 24.6336 27.8591 25.2982C26.6329 25.9629 25.2134 26.1796 23.8447 25.911C22.476 25.6424 21.2436 24.9054 20.3594 23.8266C19.4753 22.7479 18.9946 21.3948 19 20C19 19.8011 18.921 19.6103 18.7803 19.4697C18.6397 19.329 18.4489 19.25 18.25 19.25C18.0511 19.25 17.8603 19.329 17.7197 19.4697C17.579 19.6103 17.5 19.8011 17.5 20C17.5 21.4834 17.9399 22.9334 18.764 24.1668C19.5881 25.4001 20.7594 26.3614 22.1299 26.9291C23.5003 27.4968 25.0083 27.6453 26.4632 27.3559C27.918 27.0665 29.2544 26.3522 30.3033 25.3033C31.3522 24.2544 32.0665 22.918 32.3559 21.4632C32.6453 20.0083 32.4968 18.5003 31.9291 17.1299C31.3614 15.7594 30.4001 14.5881 29.1668 13.764C27.9334 12.9399 26.4834 12.5 25 12.5ZM25 17C24.8011 17 24.6103 17.079 24.4697 17.2197C24.329 17.3603 24.25 17.5511 24.25 17.75V20C24.25 20.1989 24.329 20.3897 24.4697 20.5303C24.6103 20.671 24.8011 20.75 25 20.75H26.5C26.6989 20.75 26.8897 20.671 27.0303 20.5303C27.171 20.3897 27.25 20.1989 27.25 20C27.25 19.8011 27.171 19.6103 27.0303 19.4697C26.8897 19.329 26.6989 19.25 26.5 19.25H25.75V17.75C25.75 17.5511 25.671 17.3603 25.5303 17.2197C25.3897 17.079 25.1989 17 25 17Z"
                fill="#0059F7"/>
            <rect x="0.5" y="0.5" width="49" height="39" rx="11.5" stroke="#F2F2F2"/>
          </svg>

        </RouterLink>
      </div>
    </div>
    <div class="balance balance_mobile">
      <div class="balance__row">
        <p class="balance__item-title ">
          {{ $t('advance_balances.currency') }}
        </p>
        <div class="balance__currency ">
          <CurrencyImg
              class="balance__currency-img"
              :currency="currencyId"
          />
          <span class="balance__value">{{ currencyId | getCurrencyTicker }}</span>
        </div>
      </div>
      <div class="balance__row">
        <p class="balance__item-title ">
          ID
        </p>
        <div class="balance__id ">
          <CopyString
              :string="accountId"
              :successMessage="id_copied"
              :failMessage="id_not_copied"
              :title="notification"
              canCopy
          />
        </div>
      </div>
      <div class="balance__row">
        <p class="balance__item-title">
          {{ $t('advance_balances.balance') }}
        </p>
        <div class="balance__value">
          {{ balance }}
        </div>
      </div>
      <div class="balance__row">
        <div class="balance__actions">

          <AtButton
              :disabled=false
              @click="openReplenishRequestModal"
          >
            {{ $t('advance_balances.deposit') }}
          </AtButton>
          <RouterLink
              :to="{ name: 'advanced-balance-history', params: { accountId } }"
          >
            <svg width="50" height="40" viewBox="0 0 50 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M25 12.5C23.0773 12.5055 21.2301 13.2492 19.84 14.5775V13.25C19.84 13.0511 19.761 12.8603 19.6203 12.7197C19.4797 12.579 19.2889 12.5 19.09 12.5C18.8911 12.5 18.7003 12.579 18.5597 12.7197C18.419 12.8603 18.34 13.0511 18.34 13.25V16.625C18.34 16.8239 18.419 17.0147 18.5597 17.1553C18.7003 17.296 18.8911 17.375 19.09 17.375H22.465C22.6639 17.375 22.8547 17.296 22.9953 17.1553C23.136 17.0147 23.215 16.8239 23.215 16.625C23.215 16.4261 23.136 16.2353 22.9953 16.0947C22.8547 15.954 22.6639 15.875 22.465 15.875H20.665C21.6293 14.8673 22.9146 14.227 24.2999 14.0643C25.6852 13.9015 27.0839 14.2265 28.2555 14.9833C29.4271 15.7401 30.2985 16.8814 30.7197 18.2111C31.141 19.5408 31.0859 20.9756 30.5638 22.269C30.0417 23.5625 29.0854 24.6336 27.8591 25.2982C26.6329 25.9629 25.2134 26.1796 23.8447 25.911C22.476 25.6424 21.2436 24.9054 20.3594 23.8266C19.4753 22.7479 18.9946 21.3948 19 20C19 19.8011 18.921 19.6103 18.7803 19.4697C18.6397 19.329 18.4489 19.25 18.25 19.25C18.0511 19.25 17.8603 19.329 17.7197 19.4697C17.579 19.6103 17.5 19.8011 17.5 20C17.5 21.4834 17.9399 22.9334 18.764 24.1668C19.5881 25.4001 20.7594 26.3614 22.1299 26.9291C23.5003 27.4968 25.0083 27.6453 26.4632 27.3559C27.918 27.0665 29.2544 26.3522 30.3033 25.3033C31.3522 24.2544 32.0665 22.918 32.3559 21.4632C32.6453 20.0083 32.4968 18.5003 31.9291 17.1299C31.3614 15.7594 30.4001 14.5881 29.1668 13.764C27.9334 12.9399 26.4834 12.5 25 12.5ZM25 17C24.8011 17 24.6103 17.079 24.4697 17.2197C24.329 17.3603 24.25 17.5511 24.25 17.75V20C24.25 20.1989 24.329 20.3897 24.4697 20.5303C24.6103 20.671 24.8011 20.75 25 20.75H26.5C26.6989 20.75 26.8897 20.671 27.0303 20.5303C27.171 20.3897 27.25 20.1989 27.25 20C27.25 19.8011 27.171 19.6103 27.0303 19.4697C26.8897 19.329 26.6989 19.25 26.5 19.25H25.75V17.75C25.75 17.5511 25.671 17.3603 25.5303 17.2197C25.3897 17.079 25.1989 17 25 17Z"
                  fill="#0059F7"/>
              <rect x="0.5" y="0.5" width="49" height="39" rx="11.5" stroke="#F2F2F2"/>
            </svg>

          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions} from 'vuex';

import CurrencyImg from "/public/components/elements/CurrencyImg";
import AtButton from 'at-ui/src/components/button';
import CopyString from "/public/components/elements/CopyString";

export default {
    name: 'Account',
    components: {
        CurrencyImg,
        AtButton,
        CopyString,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        currencyId() {
            return this.data.currencyId;
        },
        accountId() {
            return this.data.accountId;
        },
        balance() {
            return this.data.balance;
        },
        id_copied() {
            return this.$t('copy.id_copied');
        },
        id_not_copied() {
            return this.$t('copy.id_not_copied');
        },
        notification() {
            return this.$t('copy.notification');
        },
        tariffAmountInit() {
            return this.data.tariffs
                .find(t => t.action === 'TRANSACTION_INIT')?.amount || 0;
        },
        tariffAmountProcessed() {
            return this.data.tariffs
                .find(t => t.action === 'TRANSACTION_PROCESSED') || 0;
        },
    },
    methods: {
        ...mapActions('app', [
            'openModal',
        ]),
        openHistory() {
            window.location.href;
        },
        openReplenishRequestModal() {
            const {
                openModal,
            } = this;
            openModal({
                name: 'DepositRequestModal',
                payload: {
                    accountId: this.accountId,
                    currencyBalanceId: this.currencyId,
                },
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.balance {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: start;
  align-items: center;
  background: #FFFFFF;
  min-height: 75px;
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;

  &__history {
    border-radius: 12px;
    transition: all 0.3s ease-in;

    &:hover {
      background: var(--grey-100);
    }
  }

  &__item-title {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

  &__value {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

  &_mobile {
    display: none;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  &__currency {
    display: flex;
    align-items: center;
    justify-content: center;
    //text-align: center;
  }

  &__currency-img {
    margin-right: 12px;
    width: 24px;
    height: 24px;
  }

  &__id {
    & ::v-deep .copy-string__string {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
    }

    & ::v-deep .copy-string__copy {
      font-size: var(--font-size-text-main);
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    z-index: 2;
    gap: 30px;
    justify-content: flex-end;
    width: 100%;

    ::v-deep .at-btn {
      background: #0059F7;
      border-radius: 8px;
      color: #FFFFFF;
      transition: all 0.3s ease-in;

      &:hover {
        background: var(--blue-400);
      }
    }


    ::v-deep .at-btn__text {
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      font-family: var(--font-family);
    }
  }

  &__amount {
    display: flex;
    align-items: center;
    justify-content: center;
    //text-align: center;
  }

  &__currency-name {
    margin-left: 6px;
    color: #778899;
  }

  .main {
    display: flex;
    flex-direction: column;

    .title {
    }

    .timestamp {
      color: gray;
    }
  }
}

@media screen and (max-width: 950px) {
  .balance {
    display: none;
    justify-items: unset;
    align-items: unset;

    &__row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 55px;
      border-bottom: var(--border-table);
    }

    &_mobile {
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      padding-right: 10px;
    }

    &__actions {
      justify-content: flex-start;
    }
  }

}
</style>
