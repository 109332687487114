import gql from 'graphql-tag';

export default gql`
     mutation ($showCommissionWindow: Boolean) {
         updateUserContent (showCommissionWindow: $showCommissionWindow) {
            code
            result
        }
    }
`;
