import { getRouteQuery } from "/public/utils/getRouteQuery";
import { getCurrenciesIdsByNames } from "/public/utils/getCurrenciesIdsByNames";
import { getFormatForUrlByArray } from "/public/utils/getFormatForUrlByArray";
import getExchanges from "../../../../graphQl/queries/getExchanges";

export const fetchExchanges = async ({ state, rootState, commit, dispatch }, payload) => {
    const {
        route,
        router,
        showUrlFilters = true,
        excludeParams = [],
        page,
        sortDate,
        currencies,
        date,
    } = payload;
    const { exchangesCountOnPage, filters: { status } } = state;
    const currentPage = page ? page : getRouteQuery({
        route,
        queryName: 'page',
        defaultValue: 1,
        excludeQueries: excludeParams,
        preparedReturnValueFunc: (value) => Number(value),
    });
    const currentSortDate = sortDate ? sortDate : getRouteQuery({
        route,
        queryName: 'sortDate',
        defaultValue: undefined,
    });
    const currentStatus = status ? status : getRouteQuery({
        route,
        queryName: 'status',
        defaultValue: [],
        preparedReturnValueFunc: (value) => value.split(','),
    });
    const currentCurrencies = currencies ? currencies : getRouteQuery({
        route,
        queryName: 'currencies',
        defaultValue: [],
        preparedReturnValueFunc: (value) => value.split(','),
    });
    const currentDate = date ? date : getRouteQuery({
        route,
        queryName: 'date',
        defaultValue: [],
        preparedReturnValueFunc: (value) => value.slit('to'),
    });
    const allCurrencies = rootState.app.currencies;
    const variables = {
        limit: exchangesCountOnPage,
        offset: currentPage,
        sortDate: currentSortDate,
        status: currentStatus,
        currencies: getCurrenciesIdsByNames(currentCurrencies, allCurrencies),
        date: currentDate,
    };
    let exchangesData;

    if (showUrlFilters) {
        router.push({
            query: {
                page: currentPage,
                sortDate: currentSortDate,
                status: getFormatForUrlByArray(currentStatus, (array) => array.join(',')),
                currencies: getFormatForUrlByArray(currentCurrencies, (array) => array.join(',')),
                date: getFormatForUrlByArray(currentDate, (array) => array.join('to')),
            },
        });
    }

    try {
        exchangesData = await dispatch('app/request', {
            type: 'query',
            gql: getExchanges,
            variables,
        }, { root: true });
    } catch (error) {
        return error;
    }

    if (exchangesData.message) {
        throw exchangesData.message;
    }

    if (exchangesData.errors) {
        throw exchangesData.errors;
    }

    const {
        data: {
            getExchanges: {
                exchanges,
                totalCount,
            },
        },
    } = exchangesData;

    commit('exchanges', exchanges);
    commit('totalExchangesCount', totalCount);

    return true;
};
