import createOrganizationQuery from "@/public/graphQl/mutations/createOrganization";

export const createOrganization = async ({ dispatch }) => {
    let response;

    const variables = {
        name: "Testnet",
        testnet: true
    }

    try {
        response = await dispatch('app/request', {
            type: 'mutation',
            gql: createOrganizationQuery,
            variables
        }, { root: true });
    } catch (error) {
        return error;
    }

    return response.data.createOrganization
}
