<template>
<div class="pagination">
    <AtPagination
      :size="size"
      :page-size="pageSize"
      :current="current"
      :total="total"
      @page-change="changePage"
    />
</div>
</template>

<script>
    import AtPagination from "at-ui/src/components/pagination";

    export default {
        name: "pagination",
        components: {
          AtPagination,
        },
        props: {
            total: {
                type: Number,
                default: 1,
            },
            size: {
                type: String,
                default: 'small',
            },
            pageSize: {
                type: Number,
                default: 1,
            },
            current: {
                type: Number,
                default: 1,
            },

        },
        methods: {
            changePage(pageNumber) {
                this.$emit('page-change', pageNumber);
            },
        },
    };
</script>

<style scoped lang="scss">

.pagination {
  min-height: 84px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: var(--new-front-main-bg);
  border-radius: 0 0 20px 20px;

  &::v-deep .at-pagination {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: flex-start;

  }

  &::v-deep .at-pagination__prev {
      display: flex;
      align-items: center;
      justify-content: center;
    background: transparent;
    font-size: 24px;
    margin: 0;
      border: 1px solid var(--new-front-border-progress-bar-bg-modal-primary);
      border-radius: 10px;
      width: 32px;
      height: 32px;

    &:hover {
        background: var(--new-front-adv-hover-bg);
    }
  }

  &::v-deep .at-pagination__next {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      font-size: 24px;
      margin: 0;
      border: 1px solid var(--new-front-border-progress-bar-bg-modal-primary);
      border-radius: 10px;
      width: 32px;
      height: 32px;

      &:hover {
          background: var(--new-front-adv-hover-bg);
      }

  }
    &::v-deep .icon-chevron-right {
        position: relative;
    }
    &::v-deep .icon-chevron-right:before {
        position: absolute;
        top: -16px;
        right: -5px;
        content: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.921881 9.41687C1.18094 9.54543 1.49277 9.52359 1.72985 9.3603L7.1743 5.6103C7.37869 5.46952 7.5 5.24221 7.5 5C7.5 4.75779 7.37869 4.53048 7.1743 4.3897L1.72985 0.639704C1.49277 0.47641 1.18094 0.454575 0.92188 0.583128C0.662825 0.711681 0.499999 0.969063 0.499999 1.25L0.5 8.75C0.5 9.03094 0.662826 9.28832 0.921881 9.41687Z' fill='%239992A3'/%3E%3C/svg%3E%0A")
    }
    &::v-deep .icon-chevron-left {
        position: relative;
    }
    &::v-deep .icon-chevron-left:before {
        position: absolute;
        top: -16px;
        right: -3px;
        content: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.07812 9.41687C6.81906 9.54543 6.50723 9.52359 6.27015 9.3603L0.825704 5.6103C0.621307 5.46952 0.5 5.24221 0.5 5C0.5 4.75779 0.621307 4.53048 0.825704 4.3897L6.27015 0.639704C6.50723 0.47641 6.81906 0.454575 7.07812 0.583128C7.33718 0.711681 7.5 0.969063 7.5 1.25L7.5 8.75C7.5 9.03094 7.33717 9.28832 7.07812 9.41687Z' fill='%239992A3'/%3E%3C/svg%3E%0A");
    }
  &::v-deep .at-pagination__item--jump-next {
      &:after {
          color: var(--new-front-secondary-font-color);
          font-size: 14px;
          position: relative;
          top: 3px;
      }
    &:hover i {
        background: var(--new-front-adv-hover-bg);
    }
  }
    &::v-deep .at-pagination__item--jump-prev {
        &:after {
            color: var(--new-front-secondary-font-color);
            font-size: 14px;
            position: relative;
            top: 3px;
        }
        &:hover i {
            background: var(--new-front-adv-hover-bg);
        }
    }
    &::v-deep .at-pagination--disabled {
        opacity: .5;
    }

  &::v-deep .at-pagination__item--jump-prev {
    &:hover i {
        background: var(--new-front-adv-hover-bg);
    }
  }

  &::v-deep .at-pagination__item {
      background: transparent;
    float: unset;
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    color: var(--new-front-secondary-font-color);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
      //background: var(--new-front-bg-btn-primary-bg-primary);
      font-size: 14px;
      border: 1px solid var(--new-front-border-progress-bar-bg-modal-primary);
      border-radius: 10px;
      width: 32px;
      height: 32px;
      &:hover {
          background: var(--new-front-adv-hover-bg);
      }
  }

  &::v-deep .at-pagination__item--active {
    background-color: var(--new-front-text-btn-primary-bg-primary);
    border: none;
      color: #FFFFFF;
      &:hover {
          background-color: var(--new-front-text-btn-primary-bg-primary);;
      }
  }
    @media screen and (max-width: 400px) {
    &::v-deep .at-pagination {
      gap: 8px;
    }
  }


}


</style>
