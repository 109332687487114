export const getRouteQuery = payload => {
    const {
        route,
        queryName,
        defaultValue,
        excludeQueries = [],
        preparedReturnValueFunc,
    } = payload;

    if (excludeQueries.includes(queryName)) {
        return defaultValue;
    }

    const queryFromUrl = route.query[queryName];

    if (!queryFromUrl) {
        return defaultValue;
    }

    if (preparedReturnValueFunc && typeof preparedReturnValueFunc === 'function') {
        return preparedReturnValueFunc(queryFromUrl);
    } else {
        return queryFromUrl;
    }
};
