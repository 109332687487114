import collectDashboardQuery from "../../../../graphQl/queries/collectDashboard";

export const getDashboard = async ({dispatch, commit, rootGetters}, interval = 14) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    let collectDashboard;

    commit('app/toggleLoading', null, {root: true});
    try {
        collectDashboard = await dispatch('app/request', {
            type: 'query',
            gql: collectDashboardQuery,
            variables: {
                organizationId,
                intervalInDays: interval
            },
        }, {root: true});
    } catch (error) {
        commit('app/toggleLoading', null, {root: true});
        return error;

    }

    if (collectDashboard.message) {
        throw collectDashboard.message;
    }

    if (collectDashboard.errors) {
        throw collectDashboard.errors;
    }
    commit('app/toggleLoading', null, {root: true});
    const {
        data: {
            collectDashboard: collectDashboardResponse,
        },
    } = collectDashboard;

    commit('setDashboardRawData', collectDashboardResponse);

    return collectDashboardResponse;

};
