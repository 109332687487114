<script>

    import Copy from "@/public/components/common/copy.vue";
    import vClickOutside from "v-click-outside";
    import moment from "moment/moment";
    import BigNumber from "bignumber.js";
    import CurrencyNetworkIcon from "@/public/components/elements/business-walet/Select/CurrencyNetworkIcon.vue";
    import {mapState} from "vuex";
    import {getPrettyAddress} from "@/public/filters";
    import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";

    export default {
        name: "OrphanMobileItem",
        components: {BaseButton, CurrencyNetworkIcon, Copy},
        directives: {
            clickOutside: vClickOutside.directive
        },
        props: {
            item: {
                type: Object,
                default: () => ({})
            }
        },
        data () {
            return {
                isOpened: false,
            }
        },
        computed: {
            ...mapState('app', ['networks', 'currencies']),
            network () {
                return this.networks.find(({ id }) => id === this.item.networkId)
            },
            currency () {
                return this.currencies.find(({ id }) => id === this.item.currencyId)
            },
            moment() {
                return moment
            },
            amount () {
                return new BigNumber(this.item.amount)
                    .toFixed()
            },
            statusOut () {
                if (!this.item?.outTransaction) {
                    return {
                        status: '',
                        label: ''
                    }
                }
                return {
                    status: this.item.outTransaction.status.toLowerCase(),
                    label: this.$t(`orphan.status-${this.item.outTransaction.status.toLowerCase()}`)
                }
            },
            status () {
                if (!this.item?.status) {
                    return {
                        status: '',
                        label: ''
                    }
                }
                return {
                    status: this.item.status.toLowerCase(),
                    label: this.$t(`orphan.status-${this.item.status.toLowerCase()}`)
                }
            },
            orphanOutHash () {
                if (!this.item.outTransaction || !this.item.outTransaction.txId)  {
                    return ''
                }
                return getPrettyAddress(this.item.outTransaction.txId)
            },
            statusIn () {
                if (!this.item?.inTransaction) {
                    return {
                        status: '',
                        label: ''
                    }
                }
                return {
                    status: this.item.inTransaction.status.toLowerCase(),
                    label: this.$t(`orphan.status-${this.item.inTransaction.status.toLowerCase()}`)
                }
            }
        },
        methods: {
            toggleOpen() {
                this.isOpened = !this.isOpened
            },
            close () {
                this.isOpened = false
            },
            goToDetails () {
                this.$emit('openDetails', this.item.id)
                // this.$router.push(`/orphan-info/${ this.item.id }`)
            }
        }
    }
</script>

<template>
<div class="orphan__item" :class="['orphan__item', isOpened ? 'orphan__item--opened' : '']" v-click-outside="close">
    <div class="orphan__item-header" @click="toggleOpen">
        <div class="orphan__item-left">
            <div :class="['orphan__item-status', `orphan__item-status--${status.status}`]"></div>
            <div class="orphan__item-desc">
                <div class="orphan__item-amount">{{ amount | separatePeriods }}</div>
                <div class="orphan__item-date">{{ moment(item.createdAt).format('DD/MM/YY, HH:mm') }}</div>
            </div>
        </div>
        <div class="orphan__item-right">
            <div class="orphan__item-info">
                <div class="orphan__item__asset create-business-modal__padding-wrapper">
                    <currency-network-icon :currency="currency.currency" :network="network.name.toUpperCase()" />
                    <div class="orphan__item__asset-currency">
                        {{ currency.alias }} |
                    </div>
                    <div class="orphan__item__asset-network">
                        {{ network.name | getNetworkShortNameByNetworkName }}
                    </div>
                </div>
            </div>
            <svg :class="['orphan__item-chevron', isOpened ? 'orphan__item-chevron--active' : '']" width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.712375 4.38453C0.569375 4.55753 0.484375 4.77853 0.484375 5.01953C0.484375 5.57153 0.932375 6.01953 1.48438 6.01953H7.48438C8.03638 6.01953 8.48438 5.57153 8.48438 5.01953C8.48438 4.77853 8.39937 4.55753 8.25637 4.38453L5.29238 0.430531C5.11037 0.181531 4.81637 0.0195312 4.48438 0.0195312C4.15237 0.0195312 3.85837 0.181531 3.67637 0.430531L0.712375 4.38453Z" fill="var(--new-front-text-primary-bg-primary)"/>
            </svg>
        </div>
    </div>
    <div class="orphan__item-content">
        <div class="orphan__item-element">
            <div class="orphan__item-title">
                tx hash in:
            </div>
            <div class="orphan__item-value" v-if="item.inTransaction.txId">
                <div :class="['orphan__item-value-status', `orphan__item-value-status--${statusIn.status}`]"></div>
                <span>{{ item.inTransaction.txId | getPrettyAddress(4) }}</span>
                <copy :value="item.inTransaction.txId"/>
            </div>
            <div class="orphan__item-value" v-else>-</div>
        </div>
        <div class="orphan__item-element">
            <div class="orphan__item-title">
                {{ $t('orphan.where-found') }}
            </div>
            <div class="orphan__item-value" v-if="item.inTransaction.address">
<!--                <div class="orphan__item-value-status"></div>-->
                <span>{{ item.inTransaction.address | getPrettyAddress(4) }}</span>
                <copy :value="item.inTransaction.address"/>
            </div>
            <div class="orphan__item-value" v-else>-</div>
        </div>
        <div class="orphan__item-element">
            <div class="orphan__item-title">
                tx hash out:
            </div>
            <div class="orphan__item-value" v-if="orphanOutHash">
                <div :class="['orphan__item-value-status', `orphan__item-value-status--${statusOut.status}`]"></div>
                <span>{{ orphanOutHash }}</span>
                <copy v-if="orphanOutHash !== ''" :value="orphanOutHash"/>
            </div>
            <div class="orphan__item-value" v-else>-</div>
        </div>
        <base-button @click="goToDetails" class="orphan__item-linkBtn" :label="$t('orphan.info-btn')" type="primary" />
    </div>
</div>
</template>
<style scoped lang="scss">
.orphan__item {
    max-height: 85px;
    transition: max-height .3s ease;
    overflow: hidden;
    &-linkBtn {
        margin: 10px 0;
    }
    &-amount {
        color: var(--new-front-primary-font-color);
        font-size: $h6;
        font-weight: 500;
    }
    &-date {
        color: var(--new-front-secondary-font-color);
        font-size: $h6;
        font-weight: 500;
    }
    &--opened {
        max-height: 310px;
    }
    &-chevron {
        transform: rotate(180deg);
        transition: transform .3s ease;
        &--active {
            transform: rotate(0deg);
        }
    }
    &__asset {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        &-currency {
            font-weight: 500;
            font-size: $h4;
            color: var(--new-front-primary-font-color);
            margin: 0 10px;
        }
        &-network {
            font-weight: 500;
            font-size: $h4;
            color: var(--new-front-secondary-font-color);
        }
    }
  &-title {
    font-size: $h6;
    color: var(--new-front-primary-font-color);
  }
  &-content {
    padding: 0 $p20;
    border-bottom: 1px solid var(--new-front-divider-color);
  }
  &-element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    border-bottom: 1px solid var(--new-front-divider-color);
      &:last-child {
          border-bottom: none;
      }
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 $p20 0 $p5;
    min-height: 85px;
    border-bottom: 1px solid var(--new-front-divider-color);
  }
  &-right {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  &-status {
    width: 3px;
    border-radius: 300px;
    background: #44B070;
    height: 55px;
      &--error {
          background: #CF5252;
      }
      &--init {
          background: #4D63B2;
      }
      &--rejected {
          background: #CF5252;
      }
      &--pending {
          background: #BE9B40;
      }
      &--processed {
          background: #44B070;
      }
  }
  &-left {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &-value-status {
    width: 10px;
    height: 10px;
    border-radius:2px;
    background: #44B070;
      &--error {
          background: #CF5252;
      }
      &--init {
          background: #4D63B2;
      }
      &--pending {
          background: #BE9B40;
      }
      &--rejected {
          background: #CF5252;
      }
      &--processed {
          background: #44B070;
      }
  }
  &-value {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--new-front-purple);
    font-size: $h6;
      &--error {
          background: #CF5252;
      }
      &--rejected {
          background: #CF5252;
      }
      &--pending {
          background: #BE9B40;
      }
      &--processed {
          background: #44B070;
      }
  }
}
</style>
