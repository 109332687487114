import getBalanceFeeQuery from "/public/graphQl/queries/getBalanceFee";
import BigNumber from "bignumber.js";

export const getBalanceFee = async ({ commit, dispatch, rootGetters }, payload) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    let balance;

    try {
        balance = await dispatch('app/request', {
            type: 'query',
            gql: getBalanceFeeQuery,
            variables: {
                organizationId,
                ...payload,
            },
        }, { root: true });
    } catch (error) {
        return error;
    }

    let sumBalance = new BigNumber(balance.data.getBalanceFee.reduce((a, b) => {
        return a + b.amount;
    }, 0)).decimalPlaces(2).toFixed();

    commit('setBalance', sumBalance);

    return true;
};