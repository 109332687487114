import gql from 'graphql-tag';

export default gql`
    mutation($organizationId: String!, $webhookId: String!) {
        sendWebhook(organizationId: $organizationId, webhookId: $webhookId) {
            code
            traceId
            result {
                id
                event
                eventId
                requestUrl
                requestHeaders
                requestBody
                responseCode
                responseStatus
                responseBody
                sentAt
                createdAt
                updatedAt
                signature
                apiKeyAlias
            }
        }
    }
`;
