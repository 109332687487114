import CreateWithdrawalRequestMutation from '/public/graphQl/mutations/createWithdrawalRequest';
import {v4 as UUID} from "uuid";

export const createWithdrawalRequest = async ({dispatch, rootGetters}, payload) => {
    let withdrawalRequestData;
    const key = UUID();

    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    const variables = {
        organizationId,
        key,
        ...payload,
    };
    try {

        withdrawalRequestData = await dispatch('app/request', {
            type: 'mutation',
            gql: CreateWithdrawalRequestMutation,
            variables,
        }, {root: true});
    } catch (error) {
        return error;
    }

    if (withdrawalRequestData.message) {
        throw withdrawalRequestData.message;
    }

    if (withdrawalRequestData.errors) {
        throw withdrawalRequestData.errors;
    }

    const {
        data: {createWithdrawalRequest},
    } = withdrawalRequestData;

    return createWithdrawalRequest;
};
