import gql from 'graphql-tag';

export default gql`
     mutation($organizationId: String!, $pageId: String!, $currencies: [[String!]]!) {
         changePreCheckoutCurrencies(organizationId: $organizationId, pageId: $pageId, currencies: $currencies){
            code
            result
         }
    }
`;
