import gql from 'graphql-tag';

export default gql`
    query($limit: Int, $offset: Int) {
        getUserReferrals (limit: $limit, offset: $offset) {
        code
        result {
            referralCode
            bufferBalance
            rewardBalance
            freezingBufferBalance
            dailyIncome
            lastReward
            nextReward
            rewardDay
            activeReferrals
            inactiveReferrals
            totalCount
            addressInfo {
                currencyId
                networkId
            }
            userReferral {
                email
                referencePercent
                totalBalance
                monthIncome
                dailyIncome
                advReasonType
            }
        }
      }
    }
`;
