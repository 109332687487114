import {getRouteQuery} from "/public/utils/getRouteQuery";
import {getCurrenciesIdsByNames} from "/public/utils/getCurrenciesIdsByNames";
import {getNetworksIdsByNames} from "/public/utils/getNetworksIdsByNames";

export const downloadXLSX = async ({state, rootState, commit, dispatch, rootGetters}, {
    route,
    sortDate,
    currencies,
    id,
    date,
    networks,
    status,
    sorDate,
}) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;


    let dates = date

    if (dates.length) {
        dates = dates.map(item => encodeURIComponent(item))
    }

    const payload = JSON.stringify({
        organizationId,
        sortDate,
        status,
        currencies,
        networks,
        date: dates,
        id,
    });
    window.open('/downloads/xlsx-orders?_=' + payload);
};
