import gql from 'graphql-tag';

export default gql`
    query getAddressBookRecords($organizationId: String!, $page: Int, $limit: Int, $networkId: String) {
        getAddressBookRecords(organizationId: $organizationId, page: $page, limit: $limit, networkId: $networkId) {
            addresses {
                id
                address
                networks
                comment
                alias
            }
            count
            countPages
            limit
            page
        }
}
`;
