<template>
  <base-select
    v-model="innerModel"
    :label="label"
    :placeholder="$t('common-components.currency-network-select.placeholder')"
    class="currency-network-select"
    :list="filteredList"
    gap
    >
      <template #selected="{ currency, network }">
        <div class="currency-network-selected">
          <div class="currency-and-network-info">
            <CurrencyImg :currency="currency.currency"/>
            <span class="currency">
              {{ currency.alias }}
            </span>
            <span class="network">
              ({{ getNetworkShortNameByNetworksAlias(network.alias) }})
            </span>
          </div>
        </div>
      </template>
    <template #options-prepend>
      <search
        id="search-input"
        class="search"
        v-model="search"
      />
    </template>
      <template #info="item">
        <div class="currency-network-option-item">
          <div class="currency-and-network-info column">
            <CurrencyImg :currency="item.currency.currency"/>
            <span class="currency">
                {{ item.currency.alias }}
              </span>
            <span class="network">
                ({{ getNetworkShortNameByNetworksAlias(item.network.alias) }})
              </span>
          </div>
          <div class="amount column">
            <slot name="minimum">
              <div class="amount-label">
                {{ $t('common-components.currency-network-select.option.minimum') }}:
              </div>
              <div class="amount-value">
                {{ prettyNumber(item.pairSummary.min_amount) }} {{ currencyTicker(item) }}
              </div>
            </slot>
          </div>
          <div class="amount column">
            <slot name="maximum">
              <div class="amount-label">
                {{ $t('common-components.currency-network-select.option.maximum') }}:
              </div>
              <div class="amount-value">
                {{ prettyNumber(item.pairSummary.max_amount) }} {{ currencyTicker(item) }}
              </div>
            </slot>
          </div>
          <div class="amount column">
            <div class="amount-label">
              {{ $t('common-components.currency-network-select.option.available-balance') }}:
            </div>
            <div class="amount-value">
              {{ balanceString(item) }}
            </div>
          </div>
        </div>
      </template>
    <template #action>
      <span />
    </template>
    </base-select>
</template>

<script>
import { getNetworkShortNameByNetworksAlias } from "@/public/filters";

export default {
  name: "CurrencyAndNetwork",
  components: {
    BaseSelect: () => import(/* webpackChunkName: "public/components/common/uiKit/selects/base" */ '/public/components/common/uiKit/selects/base'),
    CurrencyImg: () => import(/* webpackChunkName: "public/components/common/uiKit/currencyImg" */ '/public/components/common/uiKit/currencyImg'),
    Search: () => import(/* webpackChunkName: "public/components/common/uiKit/search" */ '/public/components/common/uiKit/search'),
  },
  data() {
    return {
      search: undefined
    }
  },
  props: {
    balancesSummary: {
      type: Object,
      default: undefined
    },
    value:  {
      type: Object,
      default: undefined
    },
    list: {
      type: Array,
      default: () => ([])
    },
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    innerModel: {
      get({ value }) {
        return value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
    currenciesListWithBalances({ list }) {
      return list.map((pair) => {
        const pairSummary = {
          min_amount: undefined,
          max_amount: 0,
          summary: 0
        }


        if (this.balancesSummary) {
          Object.keys(this.balancesSummary).forEach((balanceName) => {
            const pairBalanceInfo = this.balancesSummary[balanceName].find(({ currencyId, networkId }) => {
              return pair.currency.id === currencyId && pair.network.id === networkId
            })

            if (pairBalanceInfo) {
              if (pairBalanceInfo.min_amount && (!pairSummary.min_amount || pairBalanceInfo.min_amount < pairSummary.min_amount)) {
                pairSummary.min_amount = pairBalanceInfo.min_amount
              }
              if (pairBalanceInfo.max_amount > pairSummary.max_amount) {
                pairSummary.max_amount = pairBalanceInfo.max_amount
              }

              pairSummary.summary += pairBalanceInfo.summary
            }
          })
        }

        return {
          ...pair,
          pairSummary
        }
      })
    },
    filteredList({ currenciesListWithBalances, search }) {
      //метод фильтрации общего списка валют
      if (search) {
        return currenciesListWithBalances.filter((item) => {
          const currencyAlias = item['currency'] ? item['currency']['alias'].toLowerCase() : ''
          const currency = item['currency'] ? item['currency']['currency'].toLowerCase() : ''
          const networkName = item['network'] ? item['network']['name'].toLowerCase() : ''
          const networkAlias = item['network'] ? item['network']['alias'].toLowerCase() : ''

          return currencyAlias.includes(search.toLowerCase()) || currency.includes(search.toLowerCase()) || networkName.includes(search.toLowerCase()) || networkAlias.includes(search.toLowerCase())
        })
      } else {
        return currenciesListWithBalances
      }
    }
  },
  methods: {
    getNetworkShortNameByNetworksAlias,
    prettyNumber(num) {
      if (num) {
        return ( num % 1 !== 0 ) ? parseFloat(num.toFixed(3)) : num
      } else {
        return 0
      }
    },
    balanceString( item ) {
      const { currencyTicker, prettyNumber } = this
      const { pairSummary: { summary } } = item

      return `${ prettyNumber(summary) } ${ currencyTicker(item) }`
    },
    currencyTicker({ currency }) {
      return  currency.currency
    }
  },
}
</script>

<style scoped lang="scss">
.currency-network-select {
  font-family: var(--new-front-font-family);

  .search {
    margin-bottom: 20px;
  }

  .currency-network-selected {
    .currency-and-network-info {
      display: flex;
      align-items: center;

      font-style: normal;
      font-weight: 500;

      gap: 5px;

      .currency {
        margin-left: 7px;
        color: var(--new-front-text-primary-bg-secondary);
      }
      .network {
        color: var(--new-front-text-secondary-bg-secondary);
      }
    }
  }

  .currency-network-option-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-weight: 500;
    font-style: normal;

    min-height: 32px;

    .column {
      width: 25%;
    }

    .currency-and-network-info {
      display: flex;
      align-items: center;

      color: #8F949A;

      gap: 7px;

      .currency {
        color: var(--new-front-text-primary-bg-secondary);
      }
      .network {
        color: var(--new-front-text-secondary-bg-secondary);
      }
    }
    .amount {
      display: flex;
      align-items: end;
      gap: 8px;

      .amount-label {
        font-weight: 400;
        color: var(--new-front-text-secondary-bg-secondary);
      }
      .amount-value {
        color: var(--new-front-text-primary-bg-secondary);
      }
    }

    @include below_880() {
      .column {
        width: 100%;
        &.amount {
          display: none;
        }
      }
    }

    @include below_phone(){
      .currency-left-block {
        .currency-info {
          width: 100%;
          padding: 0 15px;

          .name {}
        }

        .network-info {
          display: none;
          width: 60%;
        }
      }
    }
  }
  .options-list-item {
    &:hover {
      .currency-network-option-item {
        .currency-info-left-block {
          .currency-info {
            .alias {
              color: var(--primary-font-color);
            }
          }
          .network-info {
            color: var(--primary-font-color);
          }
        }
      }
    }
  }
}
</style>