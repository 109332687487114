import gql from 'graphql-tag';

export default gql`
    query($limit: Int, $offset: Int, $email: String, $advReasonType: String, $date: [String], $type: UserReferralsBufferBalanceType ) {
        getUserReferralsBufferBalance (limit: $limit, offset: $offset, email: $email, advReasonType: $advReasonType, date: $date, type: $type){
        code
        result {
        totalCount
        referralEmails
        history {
            email
            type
            percent 
            amount 
            date
            advReasonType
           }
        }
      }
    }
`;
