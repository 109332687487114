import {getRouteQuery} from "/public/utils/getRouteQuery";
import {getCurrenciesIdsByNames} from "/public/utils/getCurrenciesIdsByNames";
import {getNetworksIdsByNames} from "/public/utils/getNetworksIdsByNames";

export const downloadCSV = async ({rootState, rootGetters}, payload) => {

    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const {
        sortDate,
        type,
        date,
        addressId,
        addressType,
        search,
        status,
        currencyId,
        networks,
        riskLevel,
    } = payload;

    const currentSortDate = sortDate
    const typeAddress = addressType
    let currentDate = date

    const allCurrencies = rootState.app.currencies
    const allNetworks = rootState.app.networks

    const currentCurrencyId = currencyId
    const currentNetworks = networks

    if (date && date.length) {
        currentDate = date.map(item => encodeURIComponent(item))
    }
    const variables = JSON.stringify( {
        sortDate: currentSortDate,
        addressType: typeAddress,
        date: currentDate,
        addressId,
        type: type,
        organizationId,
        search,
        status,
        currencyId: getCurrenciesIdsByNames(currentCurrencyId, allCurrencies),
        networkId: getNetworksIdsByNames(currentNetworks, allNetworks),
        riskLevel
    });

    window.open('/downloads/csv-addresses-history?_=' + variables);
};
