<!---
    Приставка Bh — сокращение от прошлого названия broex

    prop      | type                                        | default   | description
    -----------------------------------------------------------------------------------------------
    size      | 'normal', 'small'                           | 'normal'  | размер кнопки
    color     | 'primary', 'secondary', 'warning', 'danger',| 'primary' | цветовая схема
              | 'tool'                                      |           |
    text      | String                                      | ''        | текст кнопки
    iconLeft  | строка из списка ниже                       | ''        | иконка слева от текста
    iconRight | строка из списка ниже                       | ''        | иконка справа от текста
    disabled  | Boolean                                     | false     | делает кнопку неактивной
    link      | Boolean                                     | false     | сделать ссылкой
    href      | String                                      | ''        | атрибут href для ссылки

    Типы иконок: 'export',

--->

<template>
    <div v-if="!isLink" class="bh-button"
         :class="bhButtonClassObject"

        @click="handleClick"
        v-bind:id="id"
    >
        <icon :name="iconLeft" :color="iconColor"></icon>
        <template v-if="text !== ''">
            &nbsp;{{ text }}&nbsp;
        </template>
        <icon :name="iconRight" :color="iconColor"></icon>
    </div>
    <a v-else :href="href" target="_blank" class="bh-button"
         :class="bhButtonClassObject"
         @click="handleClick">
        <icon :name="iconLeft" :color="iconColor"></icon>
        <template v-if="text !== ''">
            &nbsp;{{ text }}&nbsp;
        </template>
        <icon :name="iconRight" :color="iconColor"></icon>
    </a>
</template>

<script>

    import Icon from "./icon";

    export default {
        name: "bh-button",
        components: {
            Icon
        },
        props: {
            id: String,
            size: {
                type: String,
                default: 'normal'
            },
            color: {
                type: String,
                default: 'primary'
            },
            text: {
                type: String,
                default: ''
            },
            iconLeft: {
                type: String,
                default: ''
            },
            iconRight: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
            link: {
                type: Boolean,
                default: false
            },
            href: String
        },
        data() {
            return {
            }
        },
        computed: {
            isDisabled() {
                return this.disabled;
            },
            sizeIsNormal() {
                return this.size === 'normal';
            },
            sizeIsSmall() {
                return this.size === 'small';
            },
            colorIsPrimary() {
                return this.color === 'primary';
            },
            colorIsSecondary() {
                return this.color === 'secondary';
            },
            colorIsWarning() {
                return this.color === 'warning';
            },
            colorIsDanger() {
                return this.color === 'danger';
            },
            colorIsTool() {
                return this.color === 'tool';
            },
            bhButtonClassObject() {
                return {
                    'bh-button_disabled': this.isDisabled,
                    'bh-button_normal': this.sizeIsNormal,
                    'bh-button_small': this.sizeIsSmall && !this.colorIsTool,
                    'bh-button_primary': this.colorIsPrimary,
                    'bh-button_tool': this.colorIsTool,
                    'bh-button_normal_secondary': this.colorIsSecondary && this.sizeIsNormal,
                    'bh-button_normal_warning': this.colorIsWarning && this.sizeIsNormal,
                    'bh-button_normal_danger': this.colorIsDanger && this.sizeIsNormal,
                    'bh-button_small_secondary': this.colorIsSecondary && this.sizeIsSmall,
                    'bh-button_small_warning': this.colorIsWarning && this.sizeIsSmall,
                    'bh-button_small_danger': this.colorIsDanger && this.sizeIsSmall
                }
            },
            iconColor() {
                if (this.isDisabled) return '#B6BCC3';
                if (this.colorIsPrimary) return 'white';
                if (this.colorIsTool) return '#2939EC';
                if (this.colorIsSecondary && this.sizeIsNormal) return '#2939EC';
                if (this.colorIsWarning && this.sizeIsNormal) return 'white';
                if (this.colorIsDanger && this.sizeIsNormal) return 'white';
                if (this.colorIsSecondary && this.sizeIsSmall) return '#2939EC';
                if (this.colorIsWarning && this.sizeIsSmall) return '#F9AB00';
                if (this.colorIsDanger && this.sizeIsSmall) return '#E0294A';
                return 'black';
            },
            isLink() {
                return this.link;
            }
        },
        methods: {
            handleClick(e) {
                if (!this.disabled) {
                    this.$emit('click', e);
                }
            }
        }
    }
</script>

<style scoped>
    .bh-button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: .2s ease-in-out;
        line-height: 16px;
        border-radius: 12px;
        box-sizing: border-box;
        white-space: nowrap;
    }
    .bh-button:active {
        transform: translate(0, 1px);
    }
    .bh-button_normal {
        height: 46px;
        font-size: 14px;
        padding-left: 24px;
        padding-right: 24px;
    }
    .bh-button_small {
        height: 36px;
        font-size: 12px;
        padding-left: 16px;
        padding-right: 16px;
    }
    .bh-button_primary {
        border: 1px solid #2939EC;
        background: #2939EC;
        color: white;
    }
    .bh-button_primary:hover {
        border: 1px solid #2533D4;
        background: #2533D4;
    }
    .bh-button_tool {
        border: 1px solid var(--hover-color-select);
        background: var(--secondary-color);
        color: var(--primary-font-color);
    }
    .bh-button_tool:hover {
        border: 1px solid var(--hover-color-select);
        background: var(--secondary-color);
        color: var(--primary-font-color);
    }
    .bh-button_normal_secondary {
        border: 1px solid #EAEBEB;
        background: white;
        color: #2939EC;
    }
    .bh-button_normal_secondary:hover {
        border: 1px solid #2939EC;
        background: #F4F5FE;
    }
    .bh-button_small_secondary {
        border: 1px solid #F4F5FE;
        background: #F4F5FE;
        color: #2939EC;
    }
    .bh-button_small_secondary:hover {
        border: 1px solid #2939EC;
        background: #ECF0FC;;
    }
    .bh-button_normal_warning {
        border: 1px solid #F9AB00;
        background: #F9AB00;
        color: white;
    }
    .bh-button_normal_warning:hover {
        border: 1px solid #f99600;
        background: #f99600;;
    }
    .bh-button_small_warning {
        border: 1px solid #FEF6E5;
        background: #FEF6E5;
        color: #F9AB00;
    }
    .bh-button_small_warning:hover {
        border: 1px solid #F9AB00;
        background: #fdefd2;
    }
    .bh-button_small_warning svg {
        fill: #F9AB00!important;
    }
    .bh-button_normal_danger {
        border: 1px solid #E0294A;
        background: #E0294A;
        color: white;
    }
    .bh-button_normal_danger:hover {
        border: 1px solid #d11e3f;
        background: #d11e3f;
    }
    .bh-button_small_danger {
        border: 1px solid #FDEEF1;
        background: #FDEEF1;
        color: #E0294A;
    }
    .bh-button_small_danger:hover {
        border: 1px solid #E0294A;
        background: #fbdce3;
    }


    .bh-button_disabled {
        border: 1px solid var(--main-bg)!important;
        background: var(--main-bg)!important;
        color: #B6BCC3!important;
        cursor: not-allowed;
    }
    .bh-button_disabled:active {
        transform: translate(0, 0) !important;
    }
</style>