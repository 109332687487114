import gql from 'graphql-tag';

export default gql`
    query($registryId: String!,$accountId: String!) {
        getRegistry(accountId: $accountId,registryId: $registryId) {
            registry {
                id
                title
                status
                createdAt
                updatedAt
            }
            items {
                id
                currencyId
                networkId
                address
                tag
                amount
                status
                transactionHash
            }
        }
    }
`;
