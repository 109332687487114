export const mutations = {
    setFetching: (state, payload) => {
        state.isLoading = payload
    },
    setInvoice: (state, payload) => {
        state.invoice = payload
    },
    setBankAccounts: (state, payload) => {
      state.accounts = payload
    },
    setBankAccountsValidation: (state, payload) => {
      state.validation = payload
    },
    setBankAccount: (state, payload) => {
        state.account = payload
    },
    setBankAccountInfoState : (state, payload) => {
        state.isInfoOpened = payload
    },
    clearAllStates (state) {
        state.isLoading = false
        state.invoice = null
        state.isInfoOpened = false
    }
};
