import {fetchPayoutBalances} from "./actions/fetchPayoutBalances";
import {makeTransferRequestMutation} from "./actions/makeTransfer";
import {fetchFeeForTransfer} from "./actions/fetchFeeForTransfer";
import {getPayoutAddress} from "./actions/getPayoutAddress";
import {createPayoutAddress} from "./actions/createPayoutAddress";
import {clearDeposit} from "./actions/clearDeposit";
import {uploadRegistryMutation} from "./actions/uploadRegisrty";
import {fetchRegistries} from "./actions/fetchRegistries";
import {fetchRegistry} from "./actions/fetchRegistry";
import {fetchFeeForRegistry} from "./actions/fetchFeeForRegistry";
import {saveRegistryMutation} from "./actions/saveRegistry";
import {executeRegistryMutation} from "./actions/executeRegistry";
import {fetchRegistryInfoAction} from "./actions/fetchRegistryInfoData";
import {updatePayoutAddressInfo} from "./actions/updatePayoutAddressInfo";

export const actions = {
    fetchPayoutBalances,
    fetchRegistries,
    fetchRegistryInfoAction,
    makeTransferRequestMutation,
    fetchFeeForTransfer,
    getPayoutAddress,
    createPayoutAddress,
    clearDeposit,
    uploadRegistryMutation,
    fetchRegistry,
    fetchFeeForRegistry,
    saveRegistryMutation,
    executeRegistryMutation,
    updatePayoutAddressInfo
};