export const getters = {
    hasNetworks: state => {
        return Boolean(state.networks.length);
    },
    hasCurrencies: state => {
        return Boolean(state.currencies.length);
    },
    getCurrencyNameById: state => currencyId => {
        return state.currencies.find(currency => {
            return currency.id === currencyId;
        })?.currency || '(удалена)';
    },
    isOpenModal: state => modalName => {
        return state.modal.opened === modalName;
    },
    isFiat: state => currencyName => {
        return state.currencies.find(currency => {
            return currency.currency === currencyName && currency.isFiat;
        });
    },
    sortedCurrencies: state => {
        return state.currencies.sort((currencyA, currencyB) => {
            if (currencyA.currency < currencyB.currency) {
                return -1;
            } else if (currencyA.currency > currencyB.currency) {
                return 1;
            } else {
                return 0;
            }
        });
    },
    getSelectedOrganizationId: state => {
        return state.selectedOrganizationId;
    },
    getSelectedOrganization: state => {
        if (!state.selectedOrganizationId)
            return null;

        return state.organizations.find(organization => organization.id === state.selectedOrganizationId);
    },
    isOrganizationOwner: state => state.organizations.find(({id}) => state.selectedOrganizationId === id)?.isOwner || false,
    nativeCurrencies: state => {
        let nativeObj = {}
        state.currencies.forEach(currency => {
            if (!currency.networks.length) return

           const network =  currency.networks.find(({isDefault}) => { return isDefault })

            if (!network) return;
            nativeObj = {
               ...nativeObj,
                [network.id]: currency
            }
        })
        return nativeObj
    }
};
