<template>
  <div class="balance">
    <div class="balance__currency">
      <div class="balance__currency-container">
        <CurrencyImg
            class="balance__currency-img"
            :currency="currencyId"
        />
        <p class="balance__text-main">{{ currencyId | getCurrencyTicker }}</p>
      </div>
      <p class="balance__text-main balance__network-name balance__network-name_mobile">{{
          networkId | getNetworkName
        }}</p>
    </div>
    <div class="balance__address">
      <CopyString
          :string="address"
          :successMessage="addresses_copied"
          :failMessage="addresses_not_copied"
          :title="notification"
          canCopy
      />
    </div>
    <div class="balance__network">
      <p class="balance__text-main balance__network-name">{{ networkId | getNetworkName }}</p>
    </div>
    <div class="balance__amount ">
      <p class="balance__text-main">{{ balance }}</p>
      <!--            <span class="balance__currency-name">-->
      <!--                {{ currency }}-->
      <!--            </span>-->
    </div>
    <!--        <div class="balance__amount col">-->
    <!--            {{ rollingReserve }}-->
    <!--            <span class="balance__currency-name">-->
    <!--                {{ currency }}-->
    <!--            </span>-->
    <!--        </div>-->

    <div class="balance__withdraw">

      <button
          class="balance__button-withdraw"
          :disabled="isWithdrawalDisabled"
          @click="openCreateWithdrawalRequestModal"
      >
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.2861 8.79999L12.2861 4M12.2861 4L7.28613 8.79998M12.2861 4L12.2861 16" stroke="#0059F7"
                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path
              d="M2.94154 15.7867C3.47593 17.8466 4.68989 19.7275 6.48279 21.0754C9.94394 23.6773 14.7125 23.6533 18.1402 21.0166C19.8915 19.6695 21.073 17.8095 21.5925 15.7774"
              stroke="#0059F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p class="balance__text-main balance__button-withdraw-text">
          {{ $t('addresses.withdraw') }}
        </p>
      </button>
    </div>

  </div>
</template>

<script>

import {mapActions} from 'vuex';

import CopyString from "/public/components/elements/CopyString";
import CurrencyImg from "/public/components/elements/CurrencyImg";
import AtButton from 'at-ui/src/components/button';

export default {
    name: 'Balance',
    components: {
        CurrencyImg,
        AtButton,
        CopyString,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
    // ...mapState('balances', {
    //     rollingReserveBalances: state => state.rollingReserve,
    // }),
    // rollingReserve() {
    //     return this.rollingReserveBalances.find(({currency}) => currency === this.currency)?.amount || 0;
    // },
        currencyId() {
            return this.data?.currencyId;
        },
        address() {
            return this.data?.address;
        },
        addressId() {
            return this.data?.id;
        },
        networkId() {
            return this.data?.networkId;
        },
        balance() {
            return this.data?.balance;
        },
        isWithdrawalDisabled() {
            const {balance} = this;
            return !balance || balance <= 0;
        },
        addresses_copied() {
            return this.$t('copy.addresses_copied');
        },
        addresses_not_copied() {
            return this.$t('copy.addresses_not_copied');
        },
        notification() {
            return this.$t('copy.notification');
        },

    },
    methods: {
        ...mapActions('app', [
            'openModal',
        ]),

        openCreateWithdrawalRequestModal() {
            const {
                // currency,
                // amount,
                addressId,
                openModal,
            } = this;

            openModal({
                name: 'CreateWithdrawalRequestModal',
                payload: {
                    addressId,
                    // currency,
                    // balance: amount,
                },
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.balance {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  padding-left: 40px;
  padding-right: 40px;
  min-height: 53px;
  border-bottom: 1px solid #F2F2F2;
  align-items: center;

  &__address {
    &::v-deep .copy-string__string {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: var(--black-200);
    }

    &::v-deep .copy-string__copy {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);

    }
  }

  &__text-main {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

  &__network-name_mobile {
    display: none;
  }

  &__currency {
    display: flex;
    align-items: center;

    &-container {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  &__currency-img {
    width: 24px;
    height: 24px;
  }

  &__amount {
    word-break: break-all;
    display: flex;
    align-items: center;
  }

  &__currency-name {
    margin-left: 6px;
    color: #778899;
  }

  &__withdraw {
    justify-self: end;
  }

  &__button-withdraw {
    min-height: 40px;
    width: 115px;
    border: 1px solid #F2F2F2;
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: all 0.3s ease-in;
    cursor: pointer;

    &:hover {
      border: 1px solid var(--blue-400);
    }
  }


}

@media screen and (max-width: 970px) {
  .balance {
    grid-template-columns: repeat(3, 1fr) min-content;

    &__currency {
      flex-direction: column;
      align-items: flex-start;
      gap: 3px;
    }

    &__network {
      display: none;
    }

    &__network-name {
      display: none;
    }

    &__network-name_mobile {
      display: block;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      text-align: right;
      color: #8F949A;
    }

    &__button-withdraw {
      width: 100%;
      border: none;

      &-text {
        display: none;
      }
    }
  }

}

@media screen and (max-width: 567px) {
  .balance {
    padding-left: 15px;
    padding-right: 15px;
    grid-template-columns: repeat(2, 1fr) min-content;

    &__address {
      display: none;
    }
  }
}


</style>
