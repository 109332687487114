import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!) {
        dashboard: getDashboard_24(organizationId: $organizationId) {
            orders {
                currency
                status
                count
                amount
                expires
            }
            payments {
                created {
                    count
                    hour
                }
                processed {
                    count
                    hour
                }
            }
            transactions {
                type
                count
                currency
                amount
                network
            }
        }
    }
`;
