export const Router = {
    methods: {
        setRouterParams(params) {
            //метод меняет параметры текущего роута без перезагрузки страницы
            Object.keys(params).forEach(key => {
                //читстим пустые поля в объекте params
                if (params[key] === null || params[key] === undefined) {
                    delete params[key];
                }
            });

            history.pushState(
                {},
                null,
                this.$route.path + '?' + new URLSearchParams(params).toString()
            )
        }
    }
}
