import getWebhookQuery from "/public/graphQl/queries/getWebhook";
import getMinimumAmountQuery from "@/public/graphQl/queries/getMinimumAmount";

export const getMinimumAmount = async ({dispatch, commit, rootGetters}, id) => {
    let settingsData;
    // const organizationId = rootGetters['app/getSelectedOrganization'].id;
    //
    // const variables = {
    //     organizationId,
    //     webhookId: id
    // }

    try {
        commit('app/toggleLoading', null, {root: true});
        settingsData = await dispatch('app/request', {
            type: 'query',
            gql: getMinimumAmountQuery,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (settingsData.message) {
        throw settingsData.message;
    }

    if (settingsData.errors) {
        throw settingsData.errors;
    }

    const {
        data: {getSettings},
    } = settingsData;
    commit('setMinimumAmount', getSettings.collectingMinimumAmount)
    return getSettings;
};
