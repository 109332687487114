export const mutations = {
    setBaseUrl: (state, baseUrl) => {
        state.baseUrl = baseUrl;
    },
    count: (state, count) => {
        state.count = count;
    },
    pages: (state, pages) => {
        state.pages = pages;
    },
    createdAt: (state, createdAt) => {
        state.pages = createdAt;
    },
    page: (state, page) => {
        state.page = page;
    },
    pageCurrencies: (state, pageCurrencies) => {
        state.pageCurrencies = pageCurrencies;
    },
    pageOrders: (state, pageOrders) => {
        state.pageOrders = pageOrders;
    },
    pageOrdersCount: (state, pageOrdersCount) => {
        state.pageOrdersCount = pageOrdersCount;
    },
    addDonationOrder: (state, donationOrder) => {
        state.donationOrder = donationOrder;
    },
    clearAllStates: (state) => {
        state.count = 0;
        state.pagesCount = 10;
        state.pages = [];
        state.page = {};
        state.pageCurrencies = [];
        state.pageOrders = [];
        state.pageOrdersCount = 0;
        state.pageOrdersSize = 10;
        state.pageOrder= {};
        state.donationOrder = {
            orderId: '',
            id: '',
            currencyId: '',
            amount: '',
            status: '',
            createdAt: '',
            networkId: '',
            priceUSD: '',
            receivedUSD: '',
            received: '',
            nickname: '',
            message: '',
            pageId: '',
        };
    },
};
