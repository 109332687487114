<template>
  <div>
    <p class="error__text"><span class="error__text error__text_title">{{
        $t('upload_registry.line')
      }}:&nbsp;</span>{{ +error.line + 1 }}</p>
    <p class="error__text"><span class="error__text error__text_title">{{
        $t('upload_registry.error')
      }}:&nbsp;</span>{{ errorText }}</p>
  </div>
</template>

<script>
import {uploadRegistryErrors} from "../../../filters";

export default {
    name: "PayoutModalUploadErrors",
    props: {
        error: {
            type: Object,
            required: true,
        },
    },
    computed: {
        errorText() {
            return this.$t(uploadRegistryErrors(this.error.code));
        },
    },
};
</script>

<style lang="scss" scoped>
.error {
  &__text {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    font-weight: 300;
    color: #EB5757;

    &_title {
      color: var(--black-200);
    }
  }

}
</style>