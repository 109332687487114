<template>
  <div
      class="base-select-wrapper"
      :class="{ disabled }"
  >
    <div v-if="label" class="label">
      <slot name="label">
        {{ label }}
      </slot>
    </div>
    <div
        class="input-wrapper"
        :class="{ expanded }"
    >
      <div
          :id="`select-${_uid}`"
          class="input"
          :class="{ 'filled': valueModel && clearable }"
      >
        <div
            v-if="!valueModel"
            class="placeholder"
        >
          <slot name="placeholder">
            {{ placeholder }}
          </slot>
        </div>
        <div
            v-else
            class="selected"
        >
          <slot
              name="selected"
              v-bind="valueModel"
          >
            <div v-if="!multiple">
              {{ valueModel.label || valueModel.id }}
            </div>
            <div v-else>
              <span>
                {{ valueModel.label || valueModel.id }}
              </span>
              <span
                  v-if="value.length > 1"
                  style="padding-left: 5px"
              >
                +{{value.length - 1}}
              </span>
            </div>
          </slot>
        </div>
        <svg
            class="chevron"
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="6"
            viewBox="0 0 8 6"
            fill="none"
        >
          <path
              fill-rule="evenodd"
              clip-rule="evenodd" d="M0.228 1.635C0.085 1.462 0 1.241 0 1C0 0.448 0.448 0 1 0H7C7.552 0 8 0.448 8 1C8 1.241 7.915 1.462 7.772 1.635L4.808 5.589C4.626 5.838 4.332 6 4 6C3.668 6 3.374 5.838 3.192 5.589L0.228 1.635Z"
              :fill="themeStatus === 'dark' ? 'white' : 'black'"
          />
        </svg>
        <svg
            v-if="clearable"
            class="clear"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            @click="clear"
        >
          <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.96967 13.0303C0.676777 12.7374 0.676777 12.2626 0.96967 11.9697L5.93934 7L0.96967 2.03033C0.676777 1.73744 0.676777 1.26256 0.96967 0.96967C1.26256 0.676777 1.73744 0.676777 2.03033 0.96967L7 5.93934L11.9697 0.969671C12.2626 0.676777 12.7374 0.676777 13.0303 0.969671C13.3232 1.26256 13.3232 1.73744 13.0303 2.03033L8.06066 7L13.0303 11.9697C13.3232 12.2626 13.3232 12.7374 13.0303 13.0303C12.7374 13.3232 12.2626 13.3232 11.9697 13.0303L7 8.06066L2.03033 13.0303C1.73744 13.3232 1.26256 13.3232 0.96967 13.0303Z"
              fill="#8F949A"
          />
        </svg>
      </div>
      <slot name="options-list">
        <div
          v-if="expanded"
          class="options-list"
          :class="{ flat, 'with-search': hasSearchSlot }"
        >
          <slot name="options-prepend" />
          <slot name="search" />
          <template v-if="preparedList.length">
            <slot
                v-if="sorting && preparedList.length"
                name="sorting"
            >
              <div
                  class="sorting"
              >
              <span class="sorting__label">
                {{ sorting.label }}:
              </span>
                <div
                    :id="`sorting-${_uid}`"
                    class="sorting__field"
                    @click.stop="sortDesc = !sortDesc"
                >
                  <svg
                      v-if="sortDesc"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M5.25 5.25C5.25 4.83579 4.91421 4.5 4.5 4.5C4.08579 4.5 3.75 4.83579 3.75 5.25L3.75 18.4393L2.03033 16.7197C1.73744 16.4268 1.26256 16.4268 0.96967 16.7197C0.676777 17.0126 0.676777 17.4874 0.96967 17.7803L3.9684 20.7791L3.97955 20.79C4.04913 20.8571 4.12848 20.9082 4.21291 20.9431C4.30134 20.9798 4.39831 21 4.5 21C4.60169 21 4.69866 20.9798 4.78709 20.9431C4.87555 20.9065 4.95842 20.8522 5.03033 20.7803L8.03033 17.7803C8.32322 17.4874 8.32322 17.0126 8.03033 16.7197C7.73744 16.4268 7.26256 16.4268 6.96967 16.7197L5.25 18.4393L5.25 5.25Z" fill="#8B81A0"/>
                    <path d="M11.25 6C10.8358 6 10.5 5.66421 10.5 5.25C10.5 4.83579 10.8358 4.5 11.25 4.5H12.75C13.1642 4.5 13.5 4.83579 13.5 5.25C13.5 5.66421 13.1642 6 12.75 6H11.25Z" fill="#8B81A0"/>
                    <path d="M11.25 10.5C10.8358 10.5 10.5 10.1642 10.5 9.75C10.5 9.33579 10.8358 9 11.25 9H15.75C16.1642 9 16.5 9.33579 16.5 9.75C16.5 10.1642 16.1642 10.5 15.75 10.5H11.25Z" fill="#8B81A0"/>
                    <path d="M11.25 15C10.8358 15 10.5 14.6642 10.5 14.25C10.5 13.8358 10.8358 13.5 11.25 13.5H18.75C19.1642 13.5 19.5 13.8358 19.5 14.25C19.5 14.6642 19.1642 15 18.75 15H11.25Z" fill="#8B81A0"/>
                    <path d="M10.5 18.75C10.5 19.1642 10.8358 19.5 11.25 19.5H21.75C22.1642 19.5 22.5 19.1642 22.5 18.75C22.5 18.3358 22.1642 18 21.75 18H11.25C10.8358 18 10.5 18.3358 10.5 18.75Z" fill="#8B81A0"/>
                  </svg>
                  <svg
                      v-else
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                  >
                    <path d="M5.25 18.75C5.25 19.1642 4.91421 19.5 4.5 19.5C4.08579 19.5 3.75 19.1642 3.75 18.75L3.75 5.56066L2.03033 7.28033C1.73744 7.57322 1.26256 7.57322 0.96967 7.28033C0.676777 6.98744 0.676777 6.51256 0.96967 6.21967L3.96843 3.22091L3.97955 3.20997C4.04913 3.14287 4.12848 3.09184 4.21291 3.05691C4.30134 3.02024 4.39831 3 4.5 3C4.60169 3 4.69866 3.02024 4.78709 3.05691C4.87555 3.09351 4.95842 3.14776 5.03033 3.21967L8.03033 6.21967C8.32322 6.51256 8.32322 6.98744 8.03033 7.28033C7.73744 7.57322 7.26256 7.57322 6.96967 7.28033L5.25 5.56066L5.25 18.75Z" fill="#8B81A0"/>
                    <path d="M10.5 5.25C10.5 4.83579 10.8358 4.5 11.25 4.5H21.75C22.1642 4.5 22.5 4.83579 22.5 5.25C22.5 5.66421 22.1642 6 21.75 6H11.25C10.8358 6 10.5 5.66421 10.5 5.25Z" fill="#8B81A0"/>
                    <path d="M11.25 9C10.8358 9 10.5 9.33579 10.5 9.75C10.5 10.1642 10.8358 10.5 11.25 10.5H18.75C19.1642 10.5 19.5 10.1642 19.5 9.75C19.5 9.33579 19.1642 9 18.75 9H11.25Z" fill="#8B81A0"/>
                    <path d="M11.25 13.5C10.8358 13.5 10.5 13.8358 10.5 14.25C10.5 14.6642 10.8358 15 11.25 15H15.75C16.1642 15 16.5 14.6642 16.5 14.25C16.5 13.8358 16.1642 13.5 15.75 13.5H11.25Z" fill="#8B81A0"/>
                    <path d="M11.25 18C10.8358 18 10.5 18.3358 10.5 18.75C10.5 19.1642 10.8358 19.5 11.25 19.5H12.75C13.1642 19.5 13.5 19.1642 13.5 18.75C13.5 18.3358 13.1642 18 12.75 18H11.25Z" fill="#8B81A0"/>
                  </svg>
                  <span>
                  {{ sorting.field }}
                </span>
                </div>
              </div>
            </slot>
            <div
                v-if="multiple"
                class="options-list-header"
            >
              <div class="check-all">
                <div
                    :id="`check-all-${_uid}`"
                    class="checkbox"
                    :class="{ 'active': checkedAll }"
                    @click="checkAll"
                >
                  <svg
                      v-if="checkedAll"
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="14"
                      viewBox="0 0 21 14"
                      fill="none"
                      style="pointer-events: none"
                  >
                    <path
                        d="M15.3102 0.560548C15.9326 -0.0618493 16.9417 -0.0618493 17.5641 0.560548C18.1789 1.17531 18.1864 2.16736 17.5867 2.79138L9.10331 13.3957C9.09107 13.411 9.07799 13.4256 9.06413 13.4395C8.44173 14.0619 7.43262 14.0619 6.81023 13.4395L1.18653 7.81575C0.564127 7.19335 0.564127 6.18425 1.18653 5.56185C1.80892 4.93945 2.81803 4.93945 3.44043 5.56185L7.88916 10.0106L15.268 0.608222C15.2811 0.5915 15.2952 0.575579 15.3102 0.560548Z"
                        fill="white"
                    />
                  </svg>
                </div>
                <span class="check-all-label"> {{ $t('analytics.filters.wallets.select-all') }} </span>
              </div>
              <div class="counter">
                {{ counterText }}
              </div>
            </div>
            <div
                :id="`options-item-${_uid}`"
                v-for="(item, key) in list"
                class="options-list-item"
                :class="{ 'active': valueModel && checkInvite(item) && item[uniqKey] === valueModel[uniqKey], 'gap': gap && key !== preparedList.length - 1, 'bordered-options': borderedOptions }"
                :key="key"
                @click="valueModel = item"
            >
              <slot name="option">
                <div
                    v-if="multiple"
                    class="prepend-info"
                    style="pointer-events: none"
                >
                  <div
                      class="checkbox"
                      :class="{ 'active': valueModel && checkInvite(item)}"
                      @click="valueModel = item"
                  >
                    <svg
                        v-if="valueModel && checkInvite(item)"
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="14"
                        viewBox="0 0 21 14"
                        fill="none"
                    >
                      <path
                          d="M15.3102 0.560548C15.9326 -0.0618493 16.9417 -0.0618493 17.5641 0.560548C18.1789 1.17531 18.1864 2.16736 17.5867 2.79138L9.10331 13.3957C9.09107 13.411 9.07799 13.4256 9.06413 13.4395C8.44173 14.0619 7.43262 14.0619 6.81023 13.4395L1.18653 7.81575C0.564127 7.19335 0.564127 6.18425 1.18653 5.56185C1.80892 4.93945 2.81803 4.93945 3.44043 5.56185L7.88916 10.0106L15.268 0.608222C15.2811 0.5915 15.2952 0.575579 15.3102 0.560548Z"
                          fill="white"
                      />
                    </svg>
                  </div>
                </div>
                <div
                    class="info"
                    style="pointer-events: none"
                >
                  <slot
                      name="info"
                      v-bind="item"
                  >
                    {{ item.label || item.id }}
                  </slot>
                </div>
                <div
                    v-if="!multiple"
                    class="action"
                >
                  <slot
                      name="action"
                      v-bind="item"
                  >
                    <svg
                        v-if="valueModel && checkInvite(item) && item[uniqKey] === valueModel[uniqKey]"
                        class="check"
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                    >
                      <path d="M1 4L3.82843 6.82842L9.48528 1.17157" stroke="#744DB2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </slot>
                </div>
              </slot>
            </div>
          </template>
          <div
            v-else
            class="empty"
          >
            <slot name="empty">
              {{ $t('common.empty') }}
            </slot>
          </div>
          <slot name="options-footer" />
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "BaseSelect",
  data() {
    return {
      expanded: false,
      sortDesc: false
    }
  },
  props: {
    label: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    },
    list: {
      type: Array,
      default: () => ([])
    },
    value: {
      type: [Array, Object],
      default: undefined
    },
    clearable: {
      type: Boolean,
      default: false
    },
    //пропс отвечает за представление листа без паддингов - обычное представление списка
    flat: {
      type: Boolean,
      default: false
    },
    //пропс отвечает за паддинги между опшионсами
    gap: {
      type: Boolean,
      default: false
    },
    //пока заготовка под мультиселект - множетсвенный выбор не реализован до конца
    multiple: {
      type: Boolean,
      default: false
    },
    //пропс отвечает за то что сброс всех чекбоксов = их высавляению, то есть состояния с пустыми чекбоксами нет
    checkAllLoop: {
      type: Boolean,
      default: false
    },
    sorting: {
      type: Object,
      default: undefined
    },
    borderedOptions: {
      type: Boolean,
      default: true
    },
    canBeClosed: { //можно закрывать окно при выборе
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    //пропс отвечает за уникальный ключ для списка
    uniqKey: {
        type: String,
        default: 'id'
    },
    counterTextTranslate: {
      type: String,
      default: 'analytics.filters.wallets.selected-label'
    }
  },
  watch: {
    uncheckedAll: { //only for multiple
      handler(v) {
        if (!!v && this.checkAllLoop) {
          this.checkAll()
        }
      },
      immediate: true
    }
  },
  created() {
    this.addOuterClickListener()
  },
  computed: {
    ...mapState('app', {
      themeStatus: state => state.theme
    }),
    hasSearchSlot({$slots}) {
      //проверяем что существует слот search
      return $slots['search']
    },
    uncheckedAll({ multiple }) { //only for multiple
      //проверка того что все чекбоксы сняты
      return !!multiple && !this.value.length
    },
    checkedAll() {
      return this.value.length === this.list.length
    },
    valueModel: {
      get({ value, multiple }) {
        if ( multiple ) {
          return !!value.length ? value[0] : undefined
        } else {
          return value
        }
      },
      set(v) {
        if ( this.multiple ) {
          if (!v) {
            this.$emit('input', [])
          } else if ( this.checkInvite(v) ) {
            this.$emit('input', this.value.filter(({ id }) => v.id !== id))
          } else {
            this.$emit('input', [...this.value, v])
          }
        } else {
          this.$emit('input', v)
        }

        const { canBeClosed } = this
        if (!canBeClosed) {
          setTimeout(() => {
            this.expanded = true
          }, 100)
        }
      }
    },
    counterText({ counterTextTranslate }) {
      const { value, list } = this
      return this.$t(counterTextTranslate, { selected: value.length, length: list.length })
    },
    preparedList({ list, sorting, sortDesc }) {
      if ( !sorting ) {
        return list
      } else {
        const { value } = this.sorting
        return list.sort((a, b) => {
          return sortDesc ? (a[value] - b[value]) : (b[value] - a[value])
        })
      }
    },
  },
  destroyed() {
    document.removeEventListener("click", this.expandToggler, false);
  },
  methods: {
    addOuterClickListener() {
      document.addEventListener('click', this.expandToggler, false)
    },
    clear () {
      this.$emit('input', undefined)
      this.valueModel = undefined
    },
    checkInvite(item) {
      return this.multiple ? this.value.some(({ id }) => item.id === id) : item.id === this.value?.id
    },
    expandToggler(el) {
      const { _uid } = this

      if (el.target.id === `select-${_uid}`) {
        this.$set(this, 'expanded', !this.expanded)
      } else if ( this.multiple && (el.target.id === `options-item-${_uid}` || el.target.id === `check-all-${_uid}`) ) {
        this.expanded = true
        return
      } else if (el.target.id === `sorting-${_uid}`) {
        return
      } else if (el.target.id !== 'search-input') { //чтобы эта строка работала в слот в options-prepend должен быть передан таг с id = search-input
        this.expanded = false
      }

      setTimeout(() => {
        if (!this.expanded) this.$emit('closed')
      }, 200)
    },
    checkAll() {
      if (!this.checkedAll) {
        this.$emit('input', this.preparedList)
      } else {
        this.$emit('input', [])
      }
    }
  }
}
</script>

<style lang="scss">
.base-select-wrapper {
  font-family: var(--new-front-font-family);

  font-size: $h4;
  line-height: $h4;

  &.disabled {
    cursor: not-allowed;

    .input-wrapper {
      .input {
        pointer-events: none;
      }
    }
  }

  .label {
    font-style: normal;
    font-weight: 400;
    font-size: $h6;
    line-height: $h6;

    padding-bottom: 5px;

    color: var(--new-front-input-label-font-color)!important;
  }

  .input-wrapper {
    position: relative;

    .input {
      display: flex;
      align-items: center;

      width: 100%;

      min-height: 47px;
      border: 1px solid var(--new-front-input-border);
      border-radius: 12px;
      padding: 0 30px 0 20px;

      cursor: pointer;

      font-family: var(--new-front-font-family);

      font-style: normal;
      font-weight: 500;
      line-height: 47px;

      .selected {
        width: 100%;
        pointer-events: none;
        color: var(--new-front-input-font-color);
      }

      .placeholder {
        pointer-events: none;
        color: var(--new-front-input-placeholder-color);
      }

      &.filled {
        &:hover {
          svg.chevron {
            display: none;
          }
          svg.clear {
            display: flex;
          }
        }
      }


      svg.chevron {
        position: absolute;
        top: 50%;
        right: 20px;

        display: block;

        transition: all .3s;
        transform: translateY(-50%) rotate(0);

        width: 10px;

        pointer-events: none;
      }

      svg.clear {
        display: none;

        position: absolute;
        top: 50%;
        right: 20px;

        transform: translateY(-50%);

        width: 10px;
      }
    }


    .options-list {
      transition: all .3s;

      position: absolute;
      top: 57px;
      left: 0;

      opacity: 0;
      pointer-events: none;

      width: 100%;
      max-height: 500px;

      background: var(--new-front-select-option-list-bg);

      border: 1px solid var(--new-front-input-border);
      border-radius: 12px;

      padding: 20px;

      z-index: 5;

      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0; /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: transparent; /* color of the tracking area */
      }

      &::-webkit-scrollbar-thumb {
        background: transparent; /* color of the tracking area */
        width: 0;
      }

      .options-prepend {
        font-size: $h6;
        line-height: $h6;
      }

      .options-list-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 20px;

        padding: 0 15px;

        color: var(--new-front-input-placeholder-color);

        .check-all {
          display: flex;
          align-items: center;

          .check-all-label {
            font-size: $h6;
            line-height: $h6;

            margin-left: 12px;
          }
        }
        .counter {
          font-size: $h6;
          line-height: $h6;
        }
      }

      .checkbox {
        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        height: 20px;
        width: 20px;

        border: 1px solid var(--new-front-input-border);
        border-radius: 5px;

        &.active {
          padding-left: 1px;
          background-color: var(--new-front-bg-purple);
        }

        svg {
          width: 15px;
          height: 15px;
        }
      }

      .sorting {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 20px;

        font-family: var(--new-front-font-family);
        font-size: $h6;
        line-height: $h6;
        font-weight: 500;

        color: var(--new-front-primary-label-color);

        &__field {
          display: flex;
          align-items: center;
          gap: 6px;

          cursor: pointer;
        }
        &__label {
          font-size: $h6;
          line-height: $h6;
        }
      }

      .options-list-item {
        width: 100%;

        font-style: normal;
        font-weight: 500;

        padding: 8px 20px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        cursor: pointer;

        border-radius: 12px;

        color: var(--new-front-select-option-list-item-font-color);

        &:hover {
          transition: all .3s;
          background: var(--new-front-select-option-hover);
          color: var(--new-front-select-option-list-item-font-color);
        }

        &.active {
          background: var(--new-front-select-option-active);
        }

        &.bordered-options {
          border: 1px solid var(--new-front-input-border);
        }

        &.gap {
          margin-bottom: 20px;
        }

        .prepend-info {
          margin-right: 10px;
        }

        .info {
          width: 100%;
        }

        .action {
          width: fit-content;
          white-space: nowrap;

          svg.check {
            width: 13px;
          }
        }
      }

      &.flat {
        padding: 0;
        .options-list-item {
          box-shadow: none;
          border-radius: 0;
          margin: 0;
        }
      }

      .empty {
        padding: 20px;
        color: var(--new-front-text-secondary-bg-secondary);
      }
    }

    &.expanded {
      svg.chevron {
        transition: all .3s;

        transform: translateY(-50%) rotate(180deg);
      }

      .options-list {
        opacity: 1;
        pointer-events: visible;
      }
    }
  }

  @include below_phone() {
    .label {
      font-size: $h8;
      line-height: $h8;
    }
    .input-wrapper {
      font-size: $h6;
      line-height: $h6;

      .options-list {
        padding: 15px;

        .options-list-header {
          .check-all {
            .check-all-label {
              font-size: $h8;
              line-height: $h8;
            }
          }
        }
      }
    }
  }
}
</style>
