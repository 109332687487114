import createApiKeysMutation from "../../../../graphQl/mutations/createApiKeys";
import {getErrorMessage} from "@/public/filters";
import {i18n} from "@/public/i18n";

export const createApiKeys = async ({ dispatch, rootGetters, commit }, payload) => {
    let apiKeysData;

    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    const variables = {
        organizationId,
        ...payload,
    };

    try {
        apiKeysData = await dispatch('app/request', {
            type: 'mutation',
            gql: createApiKeysMutation,
            variables,
        }, { root: true });
    } catch (error) {
        return error;
    }

    if (apiKeysData.graphQLErrors && apiKeysData.graphQLErrors[0].extensions.code === "INCORRECT_2FA") {
        throw i18n.t('error.error_two-fa');
    }

    if (apiKeysData.message) {
        throw apiKeysData.message;
    }

    if (apiKeysData.errors) {
        throw apiKeysData.errors;
    }

    const {
        data: { createApiKeys },
    } = apiKeysData;

    return createApiKeys;
};
