import networkAddressBookMutation from "../../../../graphQl/queries/getNetworkAddress";

export const networkAddressBook = async ({dispatch, commit, rootGetters}) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    let networkAddress;

    try {
        networkAddress = await dispatch('app/request', {
            type: 'mutation',
            gql: networkAddressBookMutation,
            variables: {
                organizationId,
            },
        }, {root: true});
    } catch (error) {
        return error;
    }

    if (networkAddress.message) {
        throw networkAddress.message;
    }

    if (networkAddress.errors) {
        throw networkAddress.errors;
    }

    const {
        data: {
            getNetworks,
        },
    } = networkAddress;

    commit('setNetworks', getNetworks);

    return networkAddress;
};