<template>
<div class="toggle">
  <label class="switch">
    <input ref="checkbox" type="checkbox" @click="toggleCheckbox">
    <div class="slider-bg round"></div>
  </label>
  <span class="checkbox-label">{{ label }}</span>
</div>
</template>

<script>
  export default {
    name: "WidgetsToggle",
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: ''
      },
    },
    data () {
      return {
        checkbox: this.value
      }
    },
    mounted() {
      this.$refs.checkbox.checked = this.checkbox
    },
    watch: {
      value (val) {
        this.$refs.checkbox.checked = val
        this.checkbox = val
      }
    },
    methods: {
      toggleCheckbox() {
        this.checkbox = !this.checkbox
        this.$emit('change', this.checkbox)
        this.$emit('input', this.checkbox)
      }
    }
  }
</script>

<style lang="scss" scoped>
.toggle {
  display: flex;
  align-items: center;
  gap: 5px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 32px;
}

.switch input {
  display: none;
}

.slider-bg {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--new-front-input-border);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider-bg:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 3px;
  bottom: 3px;
  background-color: #fff;
  -webkit-transition: .2s;
  transition: .2s;
  border-radius: 50%;
}

input:checked + .slider-bg {
  background-color: #6750A4;
}

input:focus + .slider {
  //box-shadow: 0 0 1px #6750A4;
}

input:checked + .slider-bg:before {
  transform: translateX(21px);
}

.slider-bg.round {

}

.slider-bg.round:before {
}
.checkbox-label {
  font-family: $Gilroy;
  font-size: $h4;
  color: var(--new-front-primary-font-color);
  @media (max-width: 850px) {
    font-size: 10px;
  }
}
</style>
