<template>
    <div class="example-setting">
        <p class="example-setting__title">
            {{ title }}
        </p>
        <div class="example-setting__content">
            <slot />
        </div>
    </div>
</template>

<script>

export default {
    name: 'ExampleSetting',
    props: {
        title: {
            type: String,
            required: true,
        },
    },
};

</script>

<style lang="scss" scoped>

    .example-setting {
        &__title {
            margin-bottom: 8px;
            font-weight: 500;
        }
    }

</style>