export const tr = {
    'invoice': {
        'invoice': 'Hesap No.:',
        'sum-invoice': 'Fatura tutarı:',
        'select-invoice': 'Ödeme para birimini seçin',
        'coin-network': 'Para birimi ve ağ:',
        'rate': 'Kurs:',
        'sum-payment': 'Ödeme tutarı:',
        'button': 'Ödeme',
        'search': 'Arama',
    },
    'checkout': {
        'shop': 'Dükkân',
        'payment': 'Ödeme',
        'back_to_the_store': 'Mağazaya geri dön',
        'to_make_a_payment': 'Ödeme yapmak için «{orderAlias}» ağına «{orderCurrency} gönderin belirtilen adrese',
        'payment_completed': 'Ödeme gerçekleştirildi',
        'the_payment_is_invalid': 'Ödeme geçerli değil',
        'payment_rejected': 'Ödeme reddedildi',
        'payment_pending': 'Peşinen ödeme',
        'payment_init': 'Ödeme bekleniyor',
        'lifetime_has_expired': 'Ödemenin ömrü doldu. Belirtilen adrese bozuk para göndermeyin.',
        'contact_the_store_owner': 'Ödemenin ömrü doldu. Belirtilen adrese bozuk para göndermeyin. Jeton iadesi veya ödemenin devamı için mağaza sahibiyle iletişime geçin',
        'order': 'Garanti etmek',
        'to_be_paid': 'Ödeme yapmak için',
        'received': 'Alınan:',
        'description': 'Tarif:',
        'before_the_expiration_of_the_order': 'Ödeme süresi:',
        'network': 'Net:',
        'payment_address': 'Ödeme adresi:',
        'payment_address_not_found': 'Ödeme adresi bulunamadı',
        'tag': 'Etiket:',
        'contract_address': 'Sözleşme adresi:',
        'send_to_this_address_only': 'Yalnızca bu adrese gönder',
        'make_sure_the_network_is_correct': 'Ağın doğru olduğundan emin olun:',
        'network_is_correct': 'Ağın doğru olduğundan emin olun:',
        'contract_address_matches': 'Sözleşme adresinin eşleştiğinden emin olun',
        'payment_page': 'Ödeme sayfası',
        'the_address_was_copied': 'Adres başarıyla kopyalandı',
        'the_tag_was_copied': 'Etiket kopyalandı',
        'invoice_was_successfully_paid': 'Sipariş başarıyla ödendi',
        'the_invoice_was_paid': 'Sipariş , tutar için ödendi',
        'important_info': 'Önemli bilgiler',
        'the_contract_address_does_not_exist_on_this_network': 'Sözleşme adresi bu ağda yok',
        'error': 'Hata',
        'time_hours': 'Saat',
        'time_minute': 'Tutanak',
        'time_second': 'Saniye',
        'sellers_address': 'Satıcının adresi doğrulandı',
        'stored_table': 'İşte gelen işlemlerin geçmişi olacak',
        'history_table': 'Gelen işlemlerin geçmişi',
        'full_info': 'Tüm ayrıntıları göster',
        'table_date': 'Tarih',
        'table_sum': 'Toplam',
        'table_value': 'Para birimi',
        'table_hash': 'tx_hash',
        'table_date_sum': 'Tutar ve tarih',
        'payment_partially_paid': 'Kısmen ödenmiş',
        'paid_timer': 'Ücretli',
        'expired_timer': 'Ödemenin süresi doldu',
    },
    'new_checkout': {
        'details': {
            'title': 'Detaylar',
            'order': 'Emir',
            'date': 'Tarih',
            'description': 'Tanım',
        },
        'notification': 'Lütfen yalnızca <b>{orderNetwork}</b> ağındaki <b>{orderCurrency}</b> jetonlarını bu adrese gönderin.',
        'payment_address': 'Fatura adresi',
        'verified_notification': "Satıcının adresi doğrulandı",
        'paid_amount': "Ödenen miktar",
        'timer_notifications': {
            'remaining_time': 'Ödeme için kalan süre:',
            'completed': 'Ödeme başarıyla tamamlandı',
            'expired_with_invoice': 'Siparişin kullanım süresi doldu ancak geri dönüp yeni bir sipariş oluşturabilirsiniz.',
            'expired_without_invoice': 'Ödeme ömrünün süresi doldu',
            'dont_make_transactions': "Bu siparişte işlem yapmayın!"
        },
        'statuses': {
            'not_paid': 'Ödenmeyen',
            'overpaid': 'Fazla ödenmiş',
            'partially_paid': 'Kısmen ödenmiş',
            'fully_paid': 'Tamamen ödenmiş',
        },
        'history': {
            'title': 'Ödeme Geçmişi',
            'columns': {
                'date': 'Tarih',
                'amount': 'Miktar',
                'tx_hash': 'Tx_hash'
            },
            'empty_notification': 'Belirtilen adrese gönderilen tüm işlemleriniz bu pencerede mevcut olacaktır.',
            'ok': 'TAMAM'
        },
        'orphan': {
            'title': 'Yetim işlem',
            'description': 'Bu siparişteki işlemlerden biri yanlış ağ veya para birimi içeriyor.',
            'contact': 'Kimlikle birlikte teknik desteğe başvurun',
        },
        'time-is-over': 'Zaman bitti',
        'payment-successful': 'Ödeme Başarılı',
        'partial-desc': 'Tutarın bir kısmı ödendi. Başarılı ödeme için kalan tutarın ödenmesi gerekmektedir.',
        'cancel': 'İptal etmek',
        'back_to_shop': "Dükkan'a geri dön",
        'back_to_invoice': 'Faturaya geri dön',
        'choose_another_currency': 'Başka bir para birimi seçin',
        'you_can_pay': 'Faturayı birden fazla para biriminde ödeyebileceksiniz!'
    },
    'new_invoice': {
        'details': {
            title: 'Detaylar',
            invoice: 'Fatura',
            date: 'Tarih',
            description: 'Tanım'
        },
        'amount': 'Miktar',
        'btn_label': 'Devam etmek',
        'btn_label_empty': 'Para birimini seçin',
        'cancel': 'İptal etmek',
        'search': 'Aramak...',
        'back_to_shop': "Dükkan'a geri dön",
        'choose-network': 'Ağ seçin',
        'status': 'Durum',
        'you-payed': 'Sen ödedin',
        'statuses': {
            "ERROR": 'HATA',
            "PENDING": 'ASKIDA OLMASI',
            "PROCESSED": 'İŞLENDİ',
            "REJECTED": 'REDDEDİLMİŞ',
            'OVERPAID': 'FAZLA ÖDENMİŞ',
            "CREATED": 'OLUŞTURULDU',
            'PARTIAL': 'KISMİ ÖDENMİŞ',
            'INIT': 'BAŞLAT',
            'EXPIRED': 'GÜNÜ GEÇMİŞ'
        },
        'active_order': 'Aktif sipariş',
        'payment-successful': 'Ödeme Başarılı'
    },
    'languages': {
        'select': 'Dil Seçin'
    },
    'preCheckout': {
        'errors': {
            'page_not_found': 'Sayfa bulunamadı',
            'page_not_found_text': 'Aradığınız sayfayı bulamıyoruz',
        }
    }
};
