import gql from 'graphql-tag';

export default gql`
    mutation updateOrganizationSetting(
        $organizationId: String!,
        $method: String!,
        $requestType: String!,
        $field: String!,
        $value: any!,
        $isTemplate: Boolean,
        $templateType: String
    ) {
        updateOrganizationSetting(
            organizationId: $organizationId, 
            method: $method,
            requestType: $requestType,
            field: $field, 
            value: $value,
            isTemplate: $isTemplate,
            templateType: $templateType
        ) {
            code
            result
        }
    }
`;
