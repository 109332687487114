<template>
    <div class="api-keys">
        <div class="api-keys__key api-keys__key--public">
            <span class="api-keys__key-title">
                Публичный ключ
            </span>
            <AtInput
                size="small"
                :value="testPublicKey"
                :status="publicKeyStatus"
                @input="setTestPublicKey"
            />
        </div>
        <div class="api-keys__key api-keys__key--secret">
            <span class="api-keys__key-title">
                Приватный ключ
            </span>
            <AtInput
                size="small"
                :value="testSecretKey"
                :status="secretKeyStatus"
                @input="setTestSecretKey"
            />
        </div>
    </div>
</template>

<script>

import { mapState, mapMutations } from 'vuex';

import AtInput from 'at-ui/src/components/input';

export default {
    name: 'ApiKeys',
    components: {
        AtInput,
    },
    computed: {
        ...mapState('user', [
            'testPublicKey',
            'testSecretKey',
        ]),
        publicKeyStatus () {
            return !this.testPublicKey ? 'error' : '';
        },
        secretKeyStatus () {
            return !this.testSecretKey ? 'error' : '';
        },
    },
    methods: {
        ...mapMutations('user', {
            setTestPublicKey: 'testPublicKey',
            setTestSecretKey: 'testSecretKey',
        }),
    },
};

</script>

<style lang="scss" scoped>

    .api-keys {
        display: flex;

        &__key {
            width: calc(50% - 16px);

            &--public {
                margin-right: 16px;
            }

            &--secret {
                margin-left: 16px;
            }
        }

        &__key-title {
            margin-bottom: 6px;
            display: block;
            font-weight: 500;
        }
    }

</style>