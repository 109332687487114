import {getRouteQuery} from "/public/utils/getRouteQuery";
import {getCurrenciesIdsByNames} from "/public/utils/getCurrenciesIdsByNames";
import {getNetworksIdsByNames} from "/public/utils/getNetworksIdsByNames";

export const downloadCSV = async ({state, rootState, commit, dispatch, rootGetters}, {
    route,
    sortDate,
    currencyId,
    date,
    status,
    to,
    from,
    receivedCurrencyId,
    receivedNetworkId,
    id
}) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    const dateTo = to ? encodeURIComponent(to) : ''
    const dateFrom = from ? encodeURIComponent(from) : ''
    const payload = JSON.stringify({
        organizationId,
        sortDate,
        status,
        currencyId,
        from: dateFrom,
        to: dateTo,
        receivedCurrencyId,
        receivedNetworkId,
        id
    });
    window.open('/downloads/csv-invoices?_=' + payload);
};
