import AccountDataQuery from "/public/graphQl/queries/getAccountData";

export const getAccountData = async ({commit, dispatch, rootGetters}, payload) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const variables = {
        organizationId,
        ...payload,
    }

    try {
        const {data: {getAccountData: {count, logs}}} = await dispatch('app/request', {
            type: 'query',
            gql: AccountDataQuery,
            variables,
        }, {root: true})

        commit("setAccountData", {count, logs})
    } catch (error) {
        console.error('Fail get account data', error)
    }
};

export const getAccountHistory = async ({state, dispatch, commit, rootGetters}, payload) => {
    commit('app/toggleLoading', null, {root: true});

    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const {
        accountId,
        page,
        date,
        limit,
        types,
        sortDate
    } = payload;

    const variables = {
        organizationId,
        accountId: accountId,
        limit,
        page,
        type: types || [],
        date: date || [],
        sortDate: sortDate || ''
    };

    try {
        const { data } = await dispatch('app/request', {
            type: 'query',
            gql: AccountDataQuery,
            variables: variables,
        }, {root: true});

        const { getAccountData } = data

        return getAccountData || {}
    } catch (error) {
        console.error('Fail get account data', error);
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }
};
