<template>
  <div>
    <div
      v-if="!isBridgeHistoryRoute"
      class="cross-chain"
    >
      <zero-state
        v-if="showZeroState"
        :meta="zeroStateMeta"
        :backgroundTransparent="false"
      />
      <template v-else>
        <div class="cross-chain__title-wrapper">
          <history-button
              class="history-btn"
              :disabled="!isHistoryAllowed"
              @click="showHistoryRoute"
          />
        </div>
        <div class="cross-chain__container">
          <div class="cross-chain-dashboard">
            <div class="line mobile-history-line">
              <history-button
                  :disabled="!isHistoryAllowed"
                  class="history-btn"
                  @click="showHistoryRoute"
              />
            </div>
            <div class="line currency-and-network-line">
              <currency-and-network-select
                  v-model="currencyAndNetworkFrom"
                  :list="currenciesWithChain"
                  :balances-summary="balancesSummary"
                  :label="$t('common-components.currency-network-select.label')"
                  class="currency-and-network-select"
              />
              <history-button
                  class="history-btn"
                  :disabled="!isHistoryAllowed"
                  @click="showHistoryRoute"
              />
            </div>
            <div class="line">
              <address-select-from
                  v-model="addressFrom"
                  :disabled="!currencyAndNetworkFrom"
                  :wallets-list="walletsListFrom"
                  :balances-summary="balancesSummary"
                  :currency-and-network="currencyAndNetworkFrom"
                  :label="$t('bridge.address_select.label_from')"
                  hide-nullish-balances
                  hide-nullish-addresses
                  class="item"
              />
              <max-amount-input
                  v-model="amountFromModel"
                  class="amount-input item"
                  :label="$t('bridge.amount_input.label')"
                  placeholder="0"
                  has-action-btn
                  :min="amountLimits.min"
                  :max="availableBalance > amountLimits.max ? amountLimits.max : availableBalance"
                  @action-btn-click="setAmountFromMax"
              >
                <template #label-left>
                  {{ $t('bridge.amount_input.label') }}
                </template>
                <template #label-right>
                  <div class="available-balance">
                  <span class="info">
                    {{ $t('bridge.amount_input.available') }}:
                  </span>
                    <span class="value">
                    {{ availableBalanceString }}
                  </span>
                  </div>
                </template>
                <template #bottom-label>
                  <div class="limits-wrapper">
                    <div
                        class="minimum-amount"
                        :class="{ 'error': !isValid.form.minAmount }"
                    >
                    <span class="info">
                      {{ $t('bridge.amount_input.minimum') }}:
                    </span>
                      <span class="value">
                      {{ amountLimits.min || '~' }} USD
                    </span>
                    </div>
                    <div
                        class="maximum-amount"
                        :class="{ 'error': !isValid.form.maxAmount }"
                    >
                    <span class="info">
                      {{ $t('bridge.amount_input.maximum') }}:
                    </span>
                      <span class="value">
                      {{ amountLimits.max || '~' }} USD
                    </span>
                    </div>
                  </div>
                  <div class="available-balance">
                  <span class="info">
                    {{ $t('bridge.amount_input.available') }}:
                  </span>
                    <span class="value">
                    {{ availableBalanceString }}
                  </span>
                  </div>
                </template>
              </max-amount-input>
            </div>
          </div>

          <div class="cross-chain-divider">
            <div class="central-swap-icon">
              <icon
                  class="icon"
                  name="arrow_down"
                  color="#8B81A0"
              />
            </div>
          </div>

          <div
              class="cross-chain-dashboard"
              style="padding-bottom: 50px"
          >
            <div class="line">
              <base-select
                  v-model="networkTo"
                  :label="$t('bridge.network_select.label')"
                  :placeholder="$t('bridge.network_select.placeholder')"
                  class="network-select item"
                  :list="networksToList"
                  gap
              >
                <template #selected="{ name, alias }">
                  <div class="network-select-selected">
                    <network-img :network="name"/>
                    {{ alias }}
                  </div>
                </template>
                <template #info="{ name, alias }">
                  <div class="network-select-option-item">
                    <network-img :network="name"/>
                    {{ alias }}
                  </div>
                </template>
              </base-select>
              <base-input
                  :value="currencyTo"
                  class="amount-input item"
                  :label="$t('bridge.currency_select.label')"
                  :placeholder="$t('bridge.currency_select.placeholder')"
                  type="text"
                  disabled
              />
            </div>
            <div class="line">
              <address-select-to
                  v-model="addressTo"
                  :disabled="!currencyAndNetworkFrom || !networkTo"
                  :wallets-list="walletsListTo"
                  :balances-summary="balancesSummary"
                  :currency-and-network="currencyAndNetworkTo"
                  :account-id="accountId"
                  :label="$t('bridge.address_select.label_to')"
                  :show-nullish-balances-for="['business', 'payout']"
                  :hidden-address="addressFrom"
                  hide-nullish-balances
                  class="item"
                  :retry="getBalancesSummary"
              />

              <base-input
                  v-model="amountToModel"
                  class="amount-input item"
                  placeholder="0"
                  :label="$t('bridge.amount_to_input.label')"
                  :min="amountLimits.min"
                  :max="availableBalance > amountLimits.max ? amountLimits.max : availableBalance"
              />
            </div>
          </div>

          <div class="footer">
            <div class="info">
              <div
                  class="item source"
                  :class="{ 'balance-error': advanceBalanceError }"
              >
                <div class="label">
                  {{ $t('bridge.footer.source') }}:
                </div>
                <div class="value">
                <span v-if="!advanceBalanceError" class="sublabel">
                  {{ $t('bridge.footer.advance_balance') }}
                </span>
                  <span v-else class="sublabel">
                  {{ $t('bridge.errors.advance_balance_enough') }}
                </span>
                  <span style="margin-left: 7px; white-space: nowrap">
                  {{ advanceBalance }} USD
                </span>
                </div>
              </div>
              <div class="item">
              <span class="label">
                {{ $t('bridge.footer.service_commission') }}:
              </span>
                <span class="value">
                {{ serviceCommission ? parseFloat(serviceCommission.toFixed(3)) : '~' }} USD
              </span>
              </div>
              <div class="item">
              <span class="label">
                {{ $t('bridge.footer.network_commission') }}:
              </span>
                <span class="value">
                {{ networksCommission ? parseFloat(networksCommission.toFixed(3)) : '~' }} USD
              </span>
              </div>
            </div>
            <div :class="['action',!isValid.value || !isTransferAllowed ? 'notAllowed' : '' ]">
              <base-button
                  v-if="!hideActionBtn"
                  type="primary"
                  :label="$t('bridge.footer.execute_btn')"
                  class="execute-btn"
                  @click="openBridgeModal"
                  :disabled="!isValid.value || !isTransferAllowed"
              />
            </div>
          </div>
        </div>
      </template>

<!--      modals-->
      <CrossChainModal
        @transfer-data="openResultModal"
      />
      <ErrorModal :pid="_uid" />
      <SuccessModal :pid="_uid">
        <template #body>
          {{ $t('bridge.modals.success.body-text') }}
        </template>
      </SuccessModal>
    </div>
    <RouterView name="history"/>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import CrossChainModal from '/public/components/modals/crossChain';
import CurrencyAndNetworkSelect from "/public/components/elements/cross-chain/selects/CurrencyAndNetwork";

import Icon from "../components/common/icon";

import cloneDeep from "lodash/cloneDeep";

import CommonInfoModal from "/public/components/modals/commonInfo";

import {
    getPrettyAddress,
} from "/public/filters";

export default {
    name: "crossChain",
    title: 'navigation.bridge',
    components: {
        CrossChainModal,
        CurrencyAndNetworkSelect,
        Icon,
        CommonInfoModal,

        AddressSelectFrom:  () => import(/* webpackChunkName: "/public/components/elements/cross-chain/selects/AddressSelect" */ '/public/components/elements/cross-chain/selects/AddressSelect'),
        AddressSelectTo:  () => import(/* webpackChunkName: "/public/components/elements/cross-chain/selects/AddressSelect" */ '/public/components/elements/cross-chain/selects/AddressSelect'),
        BaseSelect: () => import(/* webpackChunkName: "public/components/common/uiKit/selects/base" */ '/public/components/common/uiKit/selects/base'),
        BaseButton: () => import(/* webpackChunkName: "/public/components/common/uiKit/button/base/bg-secondary" */ '/public/components/common/uiKit/button/base/bg-secondary'),
        HistoryButton: () => import(/* webpackChunkName: "public/components/common/uiKit/button/history" */ '/public/components/common/uiKit/button/history'),
        MaxAmountInput: () => import(/* webpackChunkName: "public/components/common/uiKit/input/MaxInput" */ '/public/components/common/uiKit/input/MaxInput'),
        BaseInput: () => import(/* webpackChunkName: "public/components/common/uiKit/input/base" */ '/public/components/common/uiKit/input/base'),
        NetworkImg: () => import(/* webpackChunkName: "public/components/common/uiKit/networkImg" */ '/public/components/common/uiKit/networkImg'),
        SuccessModal: () => import(/* webpackChunkName: "public/components/modals/common/results/Success" */ '/public/components/modals/common/results/Success'),
        ErrorModal: () => import(/* webpackChunkName: "public/components/modals/common/results/Error" */ '/public/components/modals/common/results/Error'),
        ZeroState: () => import(/* webpackChunkName: "public/pages/zeroState" */ '/public/pages/zeroState')
    },
    rulesAlias: 'wallets_crosschain_transfer',
    data() {
        return {
            amountFrom: undefined,
            amountTo: undefined,
            addressFrom: undefined,
            currencyAndNetworkFrom: undefined,
            networkTo: undefined,
            addressTo: undefined,
            amountLimits: {
                min: undefined,
                max: undefined,
            },
            hideActionBtn: false,
            balancesSummary: {
              business: [],
              payin: [],
              payout: [],
              personal: [],
              recurrent: []
            },
            crossChainsList: {}
        };
    },
    watch: {
        isPayinWallet() {
          this.clearData();
        },
        currencyAndNetworkFrom() {
          this.networkTo = undefined;
          this.addressFrom = undefined;
          this.addressTo = undefined;
        },
        addressFrom() {
          this.addressTo =  undefined;
          this.amountFrom = undefined;
          this.amountTo = undefined;
        },
        advanceBalanceError: {
            handler(v) {
              this.hideActionBtn = v;
            },
            immediate: true,
        }
    },
    computed: {
        ...mapState({
            themeStatus: state => state.themeStatus,
        }),
        ...mapState('app', [
            'selectedOrganizationId'
        ]),
        ...mapGetters('organizations', ['userRoleRules']),
        ...mapState('accounts', ['accounts']),
        ...mapState('balances', ['balancesGrouped', 'balancesGroupedPayin', 'balancesGroupedBusiness']),
        ...mapState('app', ['currencies']),
        isHistoryAllowed () {
            return this.userRoleRules[this.$options.rulesAlias].entities.view_crosschain_transfers;
        },
        isTransferAllowed () {
            return this.userRoleRules[this.$options.rulesAlias].entities.make_crosschain_transfer;
        },
        activeExchangeSource({ list }) {
            return list.find(({ active }) => !!active);
        },
        allowedRoles({ userRoleRules }) {
          //собираем в массив имена допустимых кошельков из 5-ти возможных в мостах
          const roles = [
            {
              role_name: 'wallets_business',
              id: 'business'
            },
            {
              role_name: 'wallets_payin',
              id: 'payin'
            },
            {
              role_name: 'payout_balances',
              id: 'payout'
            },
            {
              role_name: 'personal',
              id: 'personal'
            },
            {
              role_name: 'recurrent',
              id: 'recurrent'
            }
          ]

          return roles.reduce((acc, role) => {
            if (userRoleRules[role.role_name]?.isAllowed) {
              acc.push(role.id)
            }
            return acc
          }, [])
        },
        walletsListFull() {
          return [
            {
              id: 'business',
              label: this.$t('common-labels.wallets.business_wallets')
            },
            {
              id: 'payin',
              label: this.$t('common-labels.wallets.pay_in')
            },
            {
              id: 'payout',
              label: this.$t('common-labels.wallets.pay_out')
            },
            {
              id: 'personal',
              label: this.$t('common-labels.wallets.personal_address')
            },
            {
              id: 'recurrent',
              label: this.$t('common-labels.wallets.payment_addresses')
            }
          ]
        },
        walletsListTo({ walletsListFrom }) {
          // адреса вывода не могут быть персональными
          return walletsListFrom.filter(({ id }) => id !== 'personal')
        },
        walletsListFrom({ allowedRoles, walletsListFull }) {
          return walletsListFull.filter(wallet => {
            return allowedRoles.includes(wallet.id)
          })
        },
        accountId({ accounts }) {
            return accounts.find(({ accountId }) => !!accountId).accountId;
        },
        addressesList({ currencyAndNetworkFrom }) {
            return currencyAndNetworkFrom ? currencyAndNetworkFrom.addressesWithBalances : [];
        },
        advanceBalance({ accounts }) {
            return (!!accounts && accounts[0]) ? Number(accounts[0].balance) : 0;
        },
        advanceBalanceError({ advanceBalance, commonCommission }) {
            return advanceBalance < commonCommission;
        },
        amountFromModel: {
            get({ amountFrom }) {
                return amountFrom;
            },
            set(v) {
                this.amountFrom = v;
                this.amountTo = v;
            },
        },
        amountToModel: {
            get({ amountTo }) {
                return amountTo;
            },
            set(v) {
                this.amountTo = v;
                this.amountFrom = v;
            },
        },
        availableBalance({ addressFrom }) {
            return addressFrom && addressFrom.balance;
        },
        availableBalanceString({ currencyAndNetworkFrom, availableBalance }) {
            if ( currencyAndNetworkFrom ) {
                const { currency } = currencyAndNetworkFrom.currency;
                if (availableBalance) {
                    return `${availableBalance} ${currency}`;
                } else {
                    return `~ ${currency}`;
                }
            } else {
                return "~";
            }
        },
        currencyAndNetworkTo({ currencyAndNetworkFrom = {}, networkTo }) {
          const { currency, id } = currencyAndNetworkFrom
          return {
            id,
            currency,
            network: networkTo
          }
        },
        currenciesWithChain({ currencies }) {
           return currencies.reduce(( acc, curr ) => {
              if (curr.networks) {
                const { allowWithdraw } = curr

                if (allowWithdraw) {
                  curr.networks.forEach(network => {
                    const { allowCrosschainTransfer } = network

                    if ( allowCrosschainTransfer )  {
                      const currencyWithNetwork = {
                        id: `${curr.id}_${network.id}`,
                        currency: curr,
                        network: network,
                      }

                      acc.push(currencyWithNetwork)
                    }
                  })
                }
              }

              return acc
            }, [])
        },
        currencyTo({ currencyAndNetworkFrom }) {
            return currencyAndNetworkFrom ? currencyAndNetworkFrom.currency.currency : undefined;
        },
        darkTheme({ themeStatus }) {
            return themeStatus;
        },
        networksToList({ currencyAndNetworkFrom = {} }) {
        //получение списка сетей для вывода на основании выбранной валюты
            const { currency: currentCurrency } = currencyAndNetworkFrom;
            const { network: currentNetwork } = currencyAndNetworkFrom;

            if (currentCurrency && currentNetwork) {
                return currentCurrency.networks.filter(network => !!network.allowCrosschainTransfer && network.id !== currentNetwork.id);
            } else {
                return [];
            }
        },
        isBridgeHistoryRoute() {
            return this.$route.name === 'bridgeHistory';
        },
        bridgeInternalTariff({ accounts, accountId }) {
            const currentAccount = accounts.find( ({ accountId: id }) => id === accountId );
            return currentAccount.tariffs.find(({action}) => action === "BRIDGE_INTERNAL");
        },
        serviceCommission({ amountFrom, bridgeInternalTariff, currencyAndNetworkFrom }) {
            const { amount: tariffAmount, minAmount: tariffMinAmount, maxAmount: tariffMaxAmount, type: tariffType } = bridgeInternalTariff;

            if (!currencyAndNetworkFrom || !amountFrom) return undefined;

            let currencyUsdRate = currencyAndNetworkFrom.currency.usdRate;

            if (tariffType === 'PERCENT') {
                let estimatedTariffAmount = amountFrom * tariffAmount * currencyUsdRate;

                if (estimatedTariffAmount < tariffMinAmount) {
                    estimatedTariffAmount = tariffMinAmount;
                } else if (estimatedTariffAmount > tariffMaxAmount) {
                    estimatedTariffAmount = tariffMaxAmount;
                }

                return Number(estimatedTariffAmount);
            } else {
                return Number(tariffAmount);
            }
        },
        networksCommission({ currencyAndNetworkFrom, networkTo }) {
            if (!currencyAndNetworkFrom || !networkTo) return undefined;

            let networkFromFee = currencyAndNetworkFrom.network.networkFeeUSD;
            let networkToFee = networkTo.networkFeeUSD;

            return Number(networkFromFee) + Number(networkToFee);
        },
        commonCommission({ serviceCommission, networksCommission }) {
            if ( !serviceCommission || !networksCommission ) {
                if (!serviceCommission) {
                  serviceCommission = 0
                }
                if (!networksCommission) {
                  networksCommission = 0
                }
            }

            return ( Number(serviceCommission) + Number(networksCommission) ).toFixed(3)
        },
        getPrettyAddress() {
            return getPrettyAddress;
        },
        isValid() {
            const { min, max } = this.amountLimits;
            const { amountFrom, amountTo, addressFrom, addressTo, availableBalance, currencyAndNetworkFrom, networkTo } = this;

            const form = {
                amountFrom: !!this.amountFrom && availableBalance >= amountFrom,
                amountTo,
                addressFrom,
                currencyAndNetworkFrom,
                networkTo,
                addressTo,
                minAmount: !this.amountFrom || amountFrom >= min,
                maxAmount: !this.amountFrom || amountFrom <= max,
            };

            return {
                value: Object.keys(form).every(key => {
                    return !!form[key];
                }),
                form,
            };
        },
        showZeroState({ hasBalance, crossChainsList = {} }) {
          return !hasBalance && !crossChainsList.count
        },
        hasBalance({ balancesSummary }) {
          return Object.keys(balancesSummary).reduce((acc, key) => {
            if (balancesSummary[key].length) {
              balancesSummary[key].forEach(item => {
                acc += item.summary
              })
            }

            return acc
          }, 0)
        },
        zeroStateMeta() {
          return {
            title: this.$t('zero-states.bridge.title'),
            subtitle: this.$t('zero-states.bridge.subtitle'),
            notifications: [
              this.$t('zero-states.bridge.notifications.first-line')
            ],
            actions: [
              {
                title: this.$t('zero-states.bridge.actions.api-integration.title'),
                info: this.$t('zero-states.bridge.actions.api-integration.info'),
                btnName: this.$t('zero-states.bridge.actions.api-integration.btn-name'),
                btnSecondary: true,
                withoutIcon: true,
                apiMethod: '/api-reference/08crosschain_bridge/post_create'
              }
            ],
            type: 'default'
          }
        }
    },
    created() {
        this.getAmountLimits();
        const isAllowed = this.userRoleRules[this.$options.rulesAlias].isAllowed;
        if (!isAllowed) {
            this.$Message.error(this.$t('rules.not_allowed'));
            this.$router.push('/merchant/dashboard');
        }
    },
    async mounted() {
      await this.getBalancesSummary()
      // this.openBridgeModal()
      // this.openResultModal()

      //получение информации о списке операций в этом компоненте, а не в истории, необходимо только для отображения нулевого состояния
      this.crossChainsList = await this.getCrosschainTransfersList(
        {
          "currencyId": [],
          "createdAt": [],
          "networkToId": [],
          "networkFromId": [],
          "status": [],
          "id": [],
          "clientId": [],
          "amount": [],
          "addressFromId": [],
          "addressToId": [],
          "offset": 0,
          "limit": 10,
          "sort": "DESC"
        }
      )
    },
    methods: {
        ...mapActions('balances', [
          'getAccountBalancesPayin',
          'getAccountBalancesBusiness',
          'getAccountBalancesRecurrent'
        ]),
        ...mapActions('payoutBalances', [
          'fetchPayoutBalances'
        ]),
        ...mapActions('personalAddresses', [
          'getAddressesForPerson'
        ]),
        ...mapActions('crossChain', [
            'getCrosschainTransfersAmountLimits',
        ]),
        ...mapActions('app', [
            'openModal',
        ]),
        ...mapActions('crossChain', [
          'getCrosschainTransfersList'
        ]),
        clearData() {
            this.amountFrom = undefined;
            this.amountTo = undefined;
            this.addressFrom = undefined;
            this.networkTo = undefined;
            this.currencyAndNetworkFrom = undefined;
            this.addressTo = undefined;
        },
        async getAmountLimits() {
            const result = await this.getCrosschainTransfersAmountLimits();

            if (result) {
                this.amountLimits.min = Number(result.min);
                this.amountLimits.max = Number(result.max);
            }
        },
        async getBalancesSummary() {
          const { accountId, selectedOrganizationId: organizationId, currenciesWithChain } = this

          const walletsNames =  [ 'business', 'payin', 'payout', 'personal', 'recurrent' ]

          const balancesRequests = await Promise.all([
            this.getAccountBalancesBusiness({ accountId, organizationId }).catch(() => {}),
            this.getAccountBalancesPayin({ accountId, organizationId }).catch(() => {}),
            this.fetchPayoutBalances({ organizationId }).catch(() => {}),
            this.getAddressesForPerson({ organizationId }).catch(() => {}),
            this.getAccountBalancesRecurrent({ organizationId }).catch(() => {})
          ])

          walletsNames.forEach((walletName, index) => {
            this.balancesSummary[walletName] = currenciesWithChain.map(({ currency, network }) => {
              let addresses = []

              if (!!balancesRequests[index]) {
                addresses = balancesRequests[index].filter(addr => {
                  return currency.id === addr.currencyId && network.id === addr.networkId && addr.riskLevel !== "red" && addr.riskLevel !== "black";
                })
              }

              return {
                currencyId: currency.id,
                networkId: network.id,
                min_amount: addresses.length ? Math.min.apply(Math, addresses.map(({ balance }) => Number(balance))) : 0,
                max_amount: addresses.length ? Math.max.apply(Math, addresses.map(({ balance }) => Number(balance))) : 0,
                summary: addresses.length ? addresses.reduce(( acc, addr ) => {
                  acc += Number(addr.balance)
                  return acc
                }, 0) : 0,
                addresses
              }
            })
          })
        },
        openBridgeModal() {
            if (!this.isTransferAllowed) { return }
            const { openModal, amountFrom, amountTo,addressFrom, currencyAndNetworkFrom, networkTo, addressTo, serviceCommission } = this;

            openModal({
                name: 'CrossChainModal',
                payload: {
                    amountFrom,
                    amountTo,
                    addressFrom,
                    currencyAndNetworkFrom,
                    networkTo,
                    addressTo,
                    serviceCommission,
                },
            })
        },
        setAmountFromMax() {
            const { availableBalance, amountLimits } = this;

            if (availableBalance) {
                this.amountFromModel = cloneDeep(availableBalance > amountLimits.max ? amountLimits.max : availableBalance);
            }
        },
        showHistoryRoute() {
            if (!this.isHistoryAllowed) { return }
            this.$router.push({
                name: 'bridgeHistory',
            });
        },
        openResultModal(result) {
            const { openModal, _uid } = this;

            const modalName = !!result?.success ? `BaseSuccessModal_${_uid}` : `BaseErrorModal_${_uid}`

            this.$nextTick(() => {
                openModal({
                    name: modalName,
                });

                if (!!result?.success)  {
                    this.clearData();
                }
            });
        },
    },
};
</script>

<style lang="scss">
.cross-chain {
  padding: 0 30px 30px 30px;

  font-family: var(--new-front-font-family);
  @media screen and (max-width: 576px) {
    padding: 0 10px 10px 10px;
  }
  &__title-wrapper {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--margin-h1-bottom);

    .history-btn {
      display: none;
    }

    @include below_phone() {
      .cross-chain__title {
        font-size: $h3;
      }
    }
    @include below_375() {
      padding: 0 20px;
    }
  }

  &__title {
    display: flex;
    align-items: center;

    font-style: normal;
    font-weight: 400;
    font-size: $h1;
    color: var(--new-front-text-primary-bg-secondary);
  }

  &__paragraph-block {
    display: flex;
    align-items: start;
  }

  &__paragraph {
    font-style: normal;
    font-weight: 400;
    font-size: $h3;
    line-height: $h3;
    color: var(--new-front-text-secondary-bg-secondary);

    @include below_375() {
      padding: 0 20px;
    }
  }

  &__container {
    //max-width: 1415px;
    background: var(--new-front-bg-secondary);
    border-radius: 20px;
    box-shadow: 0 8px 50px rgba(10, 17, 105, 0.08);
    margin-bottom: var(--margin-container-bottom);

    padding: 30px 0 10px;

    .header {
      display: flex;
      align-items: center;
      justify-content: end;

      padding: 0 30px;

      .label {
        font-family: var(--new-front-font-family);
        font-style: normal;
        font-weight: 400;
        font-size: $h4;
        line-height: $h4;

        padding-right: 10px;

        color: var(--primary-font-color);
      }

      @include below_880() {
        flex-direction: column;
        padding: 0;

        .label {
          margin-bottom: 10px;
        }
      }
    }

    .cross-chain-dashboard {
      padding: 0 30px;

      @include below_360() {
        padding: 0 10px;
      }
    }

    .line {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: space-between;

      flex-wrap: wrap;

      gap: 10px;

      .item {
        margin-top: 30px;
        flex-grow: 1;
        flex-basis: 0;

        min-width: 320px;

        &.amount-input {
          .label-right {
            .available-balance {
              font-style: normal;
              font-weight: 400;

              text-align: center;

              color: var(--new-front-secondary-font-color);

              .value {
                color: var(--new-front-primary-font-color);
              }
            }
          }
          .bottom-label {
            display: flex;
            justify-content: right;

            font-family: var(--new-front-font-family);
            font-style: normal;
            font-weight: 500;

            .limits-wrapper {
              width: 100%;
              display: flex;
              justify-content: end;

              gap: $h6;

              .minimum-amount,.maximum-amount {
                &.error {
                  .info {
                    color: red;
                  }
                  .value {
                    color: red;
                  }
                }
              }

              @include below_phone() {
                display: none;
              }
            }



            .available-balance {
              display: none;

              @include below_phone() {
                width: 100%;
                display: flex;
                justify-content: end;
              }

              .info {
                color: var(--new-front-secondary-font-color);
                margin-right: $h6;
              }
            }

            .info {
              color: var(--new-front-secondary-font-color);
            }
            .value {
              color: var(--new-front-primary-font-color);
            }
          }
        }

        @include below_880() {
          width: 100%;
          margin-top: 20px;
        }
      }

      .no-address-warning {
        position: absolute;
        top: 105px;
        left: 0;
      }
    }
    .line.mobile-history-line {
      justify-content: end;

      .history-btn {
        display: none;

        @include below_880() {
          display: block;
        }
      }
    }
    .line.currency-and-network-line {
      flex-wrap: nowrap;
      gap: 10px;
      align-items: end;

      .currency-and-network-select {
        flex-grow: 1;
      }
      .history-btn {
        flex-shrink: 1;
      }
      @include below_880() {
        .history-btn {
          display: none;
        }
      }
    }

    .network-select {
      .network-select-selected, .network-select-option-item {
        display: flex;
        align-items: center;
        gap: 7px;

        min-height: 32px;
      }
    }

    .cross-chain-divider {
      position: relative;

      height: 1px;
      width: 100%;
      margin: 70px 0 40px;

      background: var(--new-front-divider-color);

      .central-swap-icon {
        position: absolute;
        width: 60px;
        height: 60px;
        left: 50%;
        top: 50%;

        background: var(--new-front-divider-color);
        border-radius: $h6;
        transform: translate(-50%, -50%) rotate(-45deg);

        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }

      @include below_phone () {
        margin: 70px 0 40px;
      }
    }

    .swapper {
      font-family: var(--new-front-font-family);
      font-style: normal;
      font-weight: 400;
      font-size: $h4;
      line-height: $h4;

      color: #2C405A;

      margin-top: 30px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      max-width: 380px;

      .swap-btn {
        width: 36px;
        height: 50px;

        background: linear-gradient(135deg, #582FFA 5.3%, #B503FE 94.87%);
        border-radius: $h6;
        transform: rotate(-90deg);

        margin: 0 $h4;

        display: flex;
        align-items: center;
        justify-content: center;

        .swap-btn-icon {
          svg {
            height: 40%;
          }
        }
      }

      @include below_phone() {
        font-size: 13px;
        line-height: 13px;

        margin-top: 10px;
        max-width: 320px;

        .swap-btn {
          width: 35px;
          height: 40px;

          margin: 0 10px;
        }
      }
    }

    .footer {
      padding: 18px 30px;
      margin: 30px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      flex-wrap: wrap;
      border-radius: 12px;

      background-color: var(--new-front-bg-primary);

      gap: 30px;

      font-size: $h4;
      line-height: $h3;

      .action {
        .execute-btn {
          width: 260px;
        }
      }
      .info {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        font-family: var(--new-front-font-family);
        font-style: normal;

        flex-grow: 1;
        gap: 30px;

        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;

          gap: 10px;

          .label {
            color: var(--new-front-text-secondary-bg-secondary);
            font-weight: 400;

            white-space: nowrap;
          }
          .value {
            color: var(--new-front-text-primary-bg-secondary);
            font-weight: 500;
          }

          &.source {
            &.balance-error {
              background: var( --new-front-bg-rejected-bg-secondary);
              padding: 8px 16px;
              border-radius: 12px;

              .value {
                .sublabel {
                  color: var(--new-front-status-text-error);
                  max-width: 70%;
                }
              }
            }
          }
        }
      }

      @include below_880() {
        align-items: start;
        flex-direction: column;
      }
      @include below_680() {
        padding: 18px;

        .action {
          width: 100%;

          .execute-btn {
            width: 100%;
          }
        }

        .info {
          flex-direction: column;
          align-items: start;
          gap: 10px;

          width: 100%;

          .item {
            width: 100%;

            &.source {
              display: flex;
              flex-wrap: wrap;

              .value {
                width: 100%;
                display: flex;
                justify-content: space-between;
              }
            }
          }
        }
      }
      @include below_phone() {
        font-size: $h6;
        font-weight: $h5;
      }
      @include below_360() {
        margin: 10px;
        .info {
          gap: 0;

          .item {
            margin: 5px 5px 5px 0;
          }
        }
      }
    }
  }
}

.comingSoon {
  height: 100%;
  margin-top: -170px;
}
.notAllowed {
  cursor: not-allowed;
}
</style>
