import gql from 'graphql-tag';

export default gql`
    query($limit: Number!, $offset: Number!, $sortDate: String, $status: [String], $currencies: [String], $date: [String]) {
        getExchanges(limit: $limit, offset: $offset, sortDate: $sortDate, status: $status, currencies: $currencies, date: $date) {
            exchanges {
                id
                currencyFrom
                currencyTo
                amountFrom
                amountTo
                state
                userId
                createdAt
            }
            totalCount
        }
    }
`;
