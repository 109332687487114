<template>
<BaseModal
    class="downCommissionModal"
    width="100%"
    :value="isOpen"
    :show-close="true"
    :show-head="false"
    :show-footer="false"
    @on-cancel="close"
>
  <div class="downCommissionModal__content">
    <div class="downCommissionModal__icon">
      <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_5747_27714)">
          <path d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z" fill="#4CAF50"/>
          <path d="M31.6001 11.6L18.0001 25.2L12.4001 19.6L9.6001 22.4L18.0001 30.8L34.4001 14.4L31.6001 11.6Z" fill="#CCFF90"/>
        </g>
        <defs>
          <clipPath id="clip0_5747_27714">
            <rect width="42" height="42" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="downCommissionModal__desc">
      {{ $t('downCommission.congratulations') }}
    </div>
    <btn class="downCommissionModal__btn" @click="close">
      {{ $t('downCommission.ready') }}
    </btn>
  </div>
</BaseModal>
</template>

<script>
import BaseModal from "../BaseModal";
import {mapActions, mapGetters} from "vuex";
import Btn from "../../common/btn";
export default {
    name: "DownCommissionModal",
    components: {
        Btn,
        BaseModal,
    },
    computed: {
        ...mapGetters('app', [
            'isOpenModal',
        ]),
        isOpen() {
            const {
                $options: {
                    name,
                },
                isOpenModal,
            } = this;

            return isOpenModal(name);
        },
    },
    methods: {
        ...mapActions('app', [
            'closeModal',
        ]),
        close () {
            this.$emit('close');
            this.closeModal();
        },
    },
};
</script>

<style lang="scss" scoped>
.downCommissionModal {
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__desc {
    font-size: 24px;
    color: var(--primary-font-color);
    margin-bottom: 50px;
    width: 100%;
    text-align: center;
  }
  &__icon {
    margin-top: 20px;
    margin-bottom: 50px;
  }
  &__btn {
    min-width: 100% !important;
    max-width: 100% !important;
    margin: 0 20px;
  }
}
</style>
