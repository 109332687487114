import { getPersons } from "./actions/getPersons";
import { getAddressesForPerson } from "./actions/getPersonAddresses";
import { createUser } from "./actions/createNewUser";
import { createUsersAddress } from "./actions/createUsersAddress";

export const actions = {
  createUser,
  createUsersAddress,
  getPersons,
  getAddressesForPerson
};