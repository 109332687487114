import apiClient from "@/public/graphQl/apiClient";
import loginSubscription from "@/public/graphQl/subscriptions/loginSubscription";

export const loginSubscriptions = async ({ state, commit, dispatch, rootGetters }, handler) => {
    const client = apiClient

    const sub$ = await client.subscribe({
        query: loginSubscription,
        variables: {},
    })

    sub$.subscribe({
        next: ({ data: {loginUpdates: type} }) => {
            if(window.$$beforeLoginEventTrigger === true) {
                //
            } else if(window.$$beforeLoginEventTrigger) {
                window.$$beforeLoginEventTrigger();

                delete window.$$beforeLoginEventTrigger;
            } else {
                window.$$beforeLoginEventTrigger = true;
            }

            if(type === 'login') {
                commit('setAuthLogin', true);
                commit('setAuthTwoFa', true);
            } else if(type === 'waitTotp') {
                commit('setAuthLogin', true);
                commit('setAuthTwoFa', false);
            } else {
                commit('setAuthLogin', false);
                commit('setAuthTwoFa', false);

                commit('accounts/setUpdatesSub', undefined, {root: true});
                commit('balances/setUpdatesSub', undefined, {root: true});
            }

            handler(type);
        },
    });
}
