<template>
    <div class="table-line__container">
        <div
             class="table-line"
        >
            <div class="table-line__wrapper alias">
                <div class="table-line__sub-title">
                    {{ item.alias }}
                </div>
            </div>
            <div class="table-line__wrapper currency">
                <div class="table-line__sub-title">
                    <currency-network-icon :network="getNetworkSystemName(item.networks[0]).toUpperCase()" />
                    <span class="table-line__network">{{ item.networks[0] | getNetworkName }}</span>
                </div>
            </div>
            <div class="table-line__wrapper address">
                <div class="table-line__sub-title">
                    {{ item.address | getPrettyAddress }}
                    <copy :value="item.address" />
                </div>
            </div>
            <actions-popup class="actions">
                <div :class="['actions__modal-item', !isUpdateAddressAllowed ? 'disabled' : '']" @click="changeAddress(item)">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.811 0.695271L11.9406 0.565638C12.3531 0.153159 13.0219 0.153159 13.4344 0.565638C13.8468 0.978117 13.8468 1.64688 13.4344 2.05936L13.3047 2.18899C13.7651 2.70436 13.7479 3.49583 13.2531 3.99058L4.24687 12.9969C4.1908 13.0529 4.12055 13.0927 4.04362 13.1119L0.543619 13.9869C0.39453 14.0242 0.236816 13.9805 0.12815 13.8719C0.0194837 13.7632 -0.0242003 13.6055 0.0130719 13.4564L0.888072 9.95639C0.907304 9.87946 0.947081 9.80921 1.00315 9.75314L9.43925 1.31703C9.26852 1.19664 9.03091 1.21282 8.87815 1.36558L5.99687 4.24686C5.82601 4.41771 5.549 4.41771 5.37815 4.24686C5.2073 4.076 5.2073 3.79899 5.37815 3.62814L8.25943 0.746857C8.75466 0.25163 9.54717 0.23488 10.0625 0.696606C10.5593 0.251489 11.3137 0.251044 11.811 0.695271ZM11.2469 1.36558C11.076 1.19472 10.799 1.19472 10.6281 1.36558L1.70756 10.2862L1.0388 12.9612L3.71384 12.2925L12.6344 3.37186C12.8053 3.201 12.8053 2.92399 12.6344 2.75314L11.2469 1.36558Z" fill="var(--new-front-secondary-font-color)"/>
                    </svg>
                    <span>{{ $t('profits.edit') }}</span>
                </div>
                <div :class="['actions__modal-item', !isAddAddressAllowed ? 'disabled' : '']" @click="deleteAddress(item)">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.1875 0.875C1.70425 0.875 1.3125 1.26675 1.3125 1.75V2.625C1.3125 3.10825 1.70425 3.5 2.1875 3.5H2.625V11.375C2.625 12.3415 3.4085 13.125 4.375 13.125H9.625C10.5915 13.125 11.375 12.3415 11.375 11.375V3.5H11.8125C12.2957 3.5 12.6875 3.10825 12.6875 2.625V1.75C12.6875 1.26675 12.2957 0.875 11.8125 0.875H8.75C8.75 0.391751 8.35825 0 7.875 0H6.125C5.64175 0 5.25 0.391751 5.25 0.875H2.1875ZM4.8125 4.375C5.05412 4.375 5.25 4.57088 5.25 4.8125V10.9375C5.25 11.1791 5.05412 11.375 4.8125 11.375C4.57088 11.375 4.375 11.1791 4.375 10.9375L4.375 4.8125C4.375 4.57088 4.57088 4.375 4.8125 4.375ZM7 4.375C7.24162 4.375 7.4375 4.57088 7.4375 4.8125V10.9375C7.4375 11.1791 7.24162 11.375 7 11.375C6.75838 11.375 6.5625 11.1791 6.5625 10.9375V4.8125C6.5625 4.57088 6.75838 4.375 7 4.375ZM9.625 4.8125V10.9375C9.625 11.1791 9.42912 11.375 9.1875 11.375C8.94588 11.375 8.75 11.1791 8.75 10.9375V4.8125C8.75 4.57088 8.94588 4.375 9.1875 4.375C9.42912 4.375 9.625 4.57088 9.625 4.8125Z" fill="var(--new-front-secondary-font-color)"/>
                    </svg>
                    <span>{{ $t('profits.delete') }}</span>
                </div>
            </actions-popup>
            <div class="table-line__actions mobileHidden">
                <edit-button :disabled="!isUpdateAddressAllowed" @click="changeAddress(item)" />
                <delete-button :disabled="!isAddAddressAllowed" @click="deleteAddress(item)" />
            </div>
        </div>
    </div>

</template>

<script>
    import {mapActions, mapGetters, mapState} from "vuex";
import CopyString from "/public/components/elements/CopyString";
import Copy from "@/public/components/common/copy.vue";
import EditButton from "@/public/components/common/uiKit/edit-button/index.vue";
import DeleteButton from "@/public/components/common/uiKit/delete-button/index.vue";
import CurrencyNetworkIcon from "@/public/components/elements/orphan/Select/CurrencyNetworkIcon.vue";
import {getNetworkSystemName} from "@/public/filters";
import ActionsPopup from "@/public/components/common/uiKit/actionsPopup/ActionsPopup.vue";

export default {
    name: 'TableLine',

    components: {
        ActionsPopup,
        CurrencyNetworkIcon,
        DeleteButton,
        EditButton,
        Copy,
        CopyString,
    },
    props: {
      item: {
          type: Object,
          default: ''
      }
    },
    computed: {
        ...mapGetters('organizations', ['userRoleRules']),
        notification() {
            return this.$t('copy.notification');
        },
        isAddAddressAllowed () {
            return this.userRoleRules['address_book'].entities.manage_address_book
        },
        isUpdateAddressAllowed () {
            return this.userRoleRules['address_book'].entities.update_address_book
        },
    },

    methods: {
        getNetworkSystemName,
        ...mapActions('app', [
            'openModal',
        ]),

        deleteAddress(item) {
            const {openModal} = this;
            if (!this.isAddAddressAllowed) {return }
            openModal({
                name: 'RemoveAddressBook',
                payload: {
                    alias: item.alias,
                    currentId: item.id,
                    address: item.address,
                },
            });
        },

        changeAddress(item) {
            const {openModal} = this;
            if (!this.isUpdateAddressAllowed) {return }
            openModal({
                name: 'ChangeAddressBook',
                payload: {
                    currentId: item.id,
                    currentIdNetwork: item.networks[0],
                    address: item.address,
                    comment: item.comment,
                    noteText: item.alias,
                },
            });
        },
    },
};
</script>

<style scoped lang="scss">
.table-line {
  width: 100%;
  padding: 20px 30px;
  border-bottom: 1px solid var(--new-front-divider-color);
  height: 88px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr .5fr;
  align-items: center;

    &__network {
        margin-left: 25px;
    }
    ::v-deep .currency-network-icon-wrapper .currency-network-icon__network {
        left: 10px;
    }
  &:last-child {
    margin-bottom: 0;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;

  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  &__title {
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--secondary-font-color);
    margin-bottom: 10px;
  }

  &__sub-title {
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: $h4;
    line-height: 19px;
    color: var(--new-front-primary-font-color);
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 60px;
    height: 40px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    margin-right: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease;

    &:hover {
      border: 1px solid var(--blue-400);
    }
  }
}
.actions {
    display: none;
}
@media (max-width: 860px) {
    .mobileHidden {
        display: none;
    }
    .table-line{
        grid-template-columns: 1fr 1fr 30px;
        grid-template-areas:
            'alias currency actions'
            'address currency actions'
    ;
        grid-gap: 5px;
    }
    .alias {
        grid-area: alias;
    }
    .currency {
        grid-area: currency;
    }
    .address {
        grid-area: address;
    }
    .actions {
        display: inherit;
        grid-area: actions;
    }
}
.actions__modal-item {
    display: none;
}
@media (max-width: 860px) {
    .actions__modal-item {
        display: flex;
        align-items: center;
        gap: 10px;
        min-width: 100px;
        width: 100%;
        border-bottom: 1px solid var(--new-front-input-border);
        color: var(--new-front-secondary-font-color);
        font-weight: 500;
        padding: $p10;
        transition: all .2s ease;
        cursor: pointer;
        &:hover {
            color:var(--new-front-primary-font-color) ;
            background: var(--new-font-nav-hover-bg);
        }
        &:first-of-type {
            &:hover {
                &::v-deep svg {
                    path {
                        stroke: #744DB2 !important;
                    }
                }
            }
        }
        &:nth-child(2){
            border-bottom: unset;
            &:hover {
                &::v-deep svg {
                    path {
                        fill: #744DB2 !important;
                    }
                }
            }
        }
    }
}
.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}
</style>
