<script>
    import Copy from "@/public/components/common/copy.vue";
    import {
        getCurrencyInfoById,
        getCurrencySymbol,
        getCurrencyTicker,
        getNetworkName,
        getNetworkSystemName
    } from "@/public/filters";
    import CurrencyNetworkIcon from "@/public/components/elements/orphan/Select/CurrencyNetworkIcon.vue";
    import CollectingSettingsActions from "@/public/components/modals/collecting-profits/CollectingSettingsActions.vue";
    import {mapActions, mapGetters, mapState} from "vuex";

    export default{
        name: "ListItem",
        rulesAlias: "collecting",
        components: {CollectingSettingsActions, CurrencyNetworkIcon, Copy},
        props: {
            item: {
                type: Object,
                default: () => ({
                    addressTo:"",
                    alias:"",
                    amount:"",
                    amountCollected:"",
                    currencyId:"",
                    id:"",
                    networkId:"",
                    type:"",
                })
            }
        },
        computed: {
            ...mapGetters('organizations', ['userRoleRules']),
            ...mapState('user', {
                userOtpEnabled: state => state.user.otpToken,
            }),
            isActionsAllowedShow () {
                return this.userRoleRules[this.$options.rulesAlias].entities.manage_settings
            },
            isHistoryAllowedShow () {
                return this.userRoleRules[this.$options.rulesAlias].entities.view_history
            },
        },
        methods: {
            getNetworkSystemName, getCurrencyInfoById, getCurrencyTicker, getCurrencySymbol, getNetworkName,
            ...mapActions('collectingProfits', ['deleteCollectingSetting']),
            ...mapActions('app', ['openModal']),
            handleActionClick (type) {
                if (type === 'history' && this.isHistoryAllowedShow) {
                    this.$router.push(`/collecting-history/${this.item.id}`)
                    return;
                }
                if (!this.userOtpEnabled) {
                    this.openModal({
                        name: 'TwoFaRestriction',
                    });
                    return;
                }
                if (!this.isActionsAllowedShow) {
                    return
                }
                if  (type === 'delete') {
                    const payload = {
                        ...this.item
                    }
                    this.openModal({
                        name: 'DeleteSettingModal',
                        payload
                    })
                } else if (type === 'edit') {
                    this.openModal({
                        name: 'UpdateSettingsModal',
                        payload: { ...this.item }
                    })
                }
            },
            walletType (type) {
                if (!type) return ''
                return type === `PAY_IN` ? 'pay-in' : this.$t('profits.personal-wallet-lowercase')
            },
        }
    }
</script>

<template>
<div class="item-wrapper">
    <div class="item">
        <div class="item__value">
            <template v-if="item.alias">{{ item.alias }}</template>
        </div>
        <div  class="item__value">
            <template v-if="item.type"> <span class="item__value-textPurple">{{ $t('profits.wallet') }}: &thinsp;</span> {{ walletType(item.type) }}</template>
        </div>
        <div class="item__value">
            <div class="item__asset create-business-modal__padding-wrapper">
                <currency-network-icon :currency="getCurrencyInfoById(item.currencyId)?.currency " :network="getNetworkSystemName(item.networkId).toUpperCase()" />
                <div class="item__asset-currency">
                    {{ getCurrencyTicker(item.currencyId) }}
                </div>
                <div class="item__asset-network">
                    {{ getNetworkSystemName(item.networkId) | getNetworkShortNameByNetworkName }}
                </div>
            </div>
        </div>
        <div class="item__value">
            <template v-if="item.amount"><span>{{ $t('profits.amount-to-collect') }}:&nbsp;</span> {{ item.amount }}</template>
        </div>
        <div  class="item__value">
            <template v-if="item.amountCollected"><span class="item__value-textPurple">{{ $t('profits.collected') }}:&nbsp;</span> {{ item.amountCollected }}</template>
        </div>
        <div class="item__value">
            <template v-if="item.addressTo">
                <span class="item__value-textPurple">{{ $t('profits.address') }}:&nbsp;</span>
                <span class="address-underline">{{ item.addressTo | getPrettyAddress }}</span>
                <copy v-if="item.addressTo" :value="item.addressTo" />
            </template>
        </div>
        <div class="item__value">
            <collecting-settings-actions :history-allowed="isHistoryAllowedShow" :actions-allowed="isActionsAllowedShow" :id="item.id" @click="handleActionClick" class="actions" />
        </div>

    </div>
</div>
</template>

<style scoped lang="scss">
.item {

  display: grid;
  grid-template-columns: minmax(150px, 1fr) minmax(170px, 1fr) minmax(150px, 1fr) minmax(250px, 1fr) minmax(250px, 1fr) minmax(175px, 1fr) 20px;
  align-items: center;
  width: 100%;
  border-radius: 10px;
    //border: 1px solid var(--new-front-divider-color);
    //padding: $p15 $p20;
    @media (max-width: 1800px) {
        grid-template-columns: 15% 25% 15% 20% 20% 18% 20px;
    }
    @media (max-width: 1250px) {
        grid-template-columns: 20% 20% 30% 30% 20% 25% 20px;
    }
  &-wrapper {
      width: 100%;
  }
  &__asset {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &-currency {
      font-weight: 500;
      font-size: $h4;
      color: var(--new-front-primary-font-color);
      margin: 0 10px;
    }
    &-network {
      font-weight: 500;
      font-size: $h4;
      color: var(--new-front-secondary-font-color);
    }
  }
  &__value {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: $h4;
    color: var(--new-front-text-primary-bg-modal-primary);
      padding: $p15 0;
      border-top: 1px solid var(--new-front-divider-color);
      border-bottom: 1px solid var(--new-front-divider-color);
      min-height: 80px;
      &:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          padding: $p15 0 $p15 $p20;
          border: 1px solid var(--new-front-divider-color);
          border-right: none;
      }
      &:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          padding: $p15 $p20 $p15 0;
          border: 1px solid var(--new-front-divider-color);
          border-left: none;
      }
    &-textPurple {
      color: var(--new-front-secondary-font-color)
    }
  }
}
.actions {
    width: 40px;
}
.address-underline {
    color: var(--new-front-purple);
    text-decoration: underline;
}
::v-deep {
    .actions__trigger {
        position: absolute;
        z-index: 1;
        right: -15px;
        top: -23px;
        width: 16px;
    }
}
</style>
