import {fetchPreCheckoutPages} from "./actions/fetchPreCheckoutPages";
import {createPreCheckoutDonation} from "./actions/createPreCheckout";
import {fetchPreCheckoutOrders} from "./actions/fetchPreCheckoutOrders";
import {removePreCheckoutPage} from "./actions/removePreCheckoutPage";
import {checkPreCheckoutURLQuery} from "./actions/checkPrecheckoutURL";
import {fetchPreCheckoutPage} from "./actions/fetchPreCheckoutPage";
import {fetchPreCheckoutCurrencies} from "./actions/fetchPreCheckoutCurrencies";
import {changePreCheckoutURLQuery} from "../dashboard/actions/changePreCheckoutURL";
import {changePreCheckoutAliasQuery} from "./actions/changePreCheckoutAlias";
import {changePreCheckoutInfoQuery} from "./actions/changePreCheckoutInfoQuery";
import {uploadPreCheckoutLinkQuery} from "./actions/uploadPrecheckoutLinkQuery";
import {changePreCheckoutCurrenciesQuery} from "./actions/changePreCheckoutCurrenciesQuery";
import {fetchDonationOrderInfo} from "./actions/getDonationPageOrderInfoQuery";

export const actions = {
    fetchPreCheckoutPages,
    createPreCheckoutDonation,
    fetchPreCheckoutOrders,
    removePreCheckoutPage,
    checkPreCheckoutURLQuery,
    fetchPreCheckoutPage,
    fetchPreCheckoutCurrencies,
    changePreCheckoutURLQuery,
    changePreCheckoutAliasQuery,
    changePreCheckoutInfoQuery,
    uploadPreCheckoutLinkQuery,
    changePreCheckoutCurrenciesQuery,
    fetchDonationOrderInfo,
};