<template>
    <div class="balance empty-table row">
        <div class="balance__amount col">
            {{ action }}
            <at-tooltip v-if="information" :content="information">
                <i class="icon icon-help-circle"></i>
            </at-tooltip>
        </div>
        <div class="balance__amount col">
            {{ amount }}
        </div>
        <div class="balance__amount col">
            <CurrencyImg
                v-if="currencyId"
                class="balance__currency-img"
                :currency="currencyId"
            />
            {{ currencyId | getCurrencyTicker }}
        </div>
    </div>
</template>

<script>

import CurrencyImg from "../CurrencyImg";

const types = {
    // DEPOSIT: "advance_balances.Replenishment",
    // WITHDRAWAL: "advance_balances.withdrawal",
    // WITHDRAWAL_INIT: "advance_balances.withdrawal_fee",
    TRANSACTION_INIT: "advance_balances.creating_an_order",
    TRANSACTION_PROCESSED: "advance_balances.deposit_by_order",
    // SYSTEM_INCR: "advance_balances.Replenishment",
    // SYSTEM_DECR: "advance_balances.write-downs",
    // REFUND: 'advance_balances.cancellation_of_the_operation',
};

export default {
    name: 'Tariffs',

    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        CurrencyImg,
    },
    computed: {
        amount() {
            return this.data.amount;
        },
        action() {
            return this.data ? this.$t(types[this.data?.action]) : '-';
        },
        currencyId() {
            return this.data.currencyId;
        },
        information() {
            if (this.data.action === "TRANSACTION_PROCESSED"){
                return this.$t('advance_balances.tariff_description');
            }else{
                return false;
            }
        },
    },

};

</script>

<style lang="scss" scoped>

.balance {
    position: relative;

    &__amount {
        display: flex;
        align-items: center;
        justify-content: center;
        //text-align: center;
    }

    &__currency-img {
        margin-right: 12px;
        width: 24px;
        height: 24px;
    }

}
.icon{
    margin-left: 5px;
}

</style>
