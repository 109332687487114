<template>
  <div class="donations-container">
    <div class="donations-container__container">

      <svg class="svg">
        <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
          <path
              d="M0,0.02 C0,0.009,0.008,0,0.018,0 H0.982 C0.992,0,1,0.009,1,0.02 V0.764 C0.984,0.764,0.971,0.779,0.971,0.798 C0.971,0.817,0.984,0.832,1,0.832 V0.98 C1,0.991,0.992,1,0.982,1 H0.018 C0.008,1,0,0.991,0,0.98 V0.83 C0.016,0.83,0.029,0.815,0.029,0.796 C0.029,0.777,0.016,0.762,0,0.762 V0.02"></path>
        </clipPath>
      </svg>
      <slot name="content"/>
      <slot name="button"/>
    </div>
  </div>
</template>

<script>
export default {
    name: "donations-container",
};
</script>

<style scoped lang="scss">
.svg {
  position: absolute;
  width: 0;
  height: 0;
}

.donations-container {
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(var(--shadow-container));

  &__container {
    position: relative;
    height: 1150px;
    background: #FFFFFF;
    width: 100%;
    max-width: 1125px;
    -webkit-clip-path: url(#my-clip-path);
    clip-path: url(#my-clip-path);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  }
}

@media screen and (max-width: 950px) {
  .donations-container {
    &__container {
      clip-path: unset;
      border-radius: 14px;
    }

  }
}
</style>