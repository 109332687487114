<template>
    <div>
        <div class="history-addresses" >
            <div class="history-addresses__head-wrapper">
<!--                <AtButton-->
<!--                        class="history-addresses__all"-->
<!--                        type="primary"-->
<!--                        icon="icon-arrow-left"-->
<!--                        @click="goBack"-->
<!--                >-->

<!--                    <span class="history-addresses__head-title">{{ $t('transaction.return') }}</span>-->
<!--                </AtButton>-->
                <div class="history-addresses__title-wrapper">
                    <h1 class="history-addresses__sub">
<!--                        {{ $t('historyAddresses.title_main_current') }}:-->
                      {{ stringAddress }}
                    </h1>
                    <span class="history-addresses__sub">{{ $t('historyAddresses.network') }}: {{
                            address.networkId | getNetworkSystemName | getNetworkFullNameByNetworkName
                        }}
                        <span class="secondary-text">{{ address.networkId | getNetworkSystemName | getNetworkShortNameByNetworkName }}</span>
                    </span>
                </div>
                <a class="history-addresses__return" @click="goBack">
                    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.07812 0.583127C6.81906 0.454574 6.50723 0.476409 6.27015 0.639703L0.825704 4.3897C0.621307 4.53048 0.5 4.75779 0.5 5C0.5 5.24221 0.621307 5.46952 0.825704 5.6103L6.27015 9.3603C6.50723 9.52359 6.81906 9.54543 7.07812 9.41687C7.33717 9.28832 7.5 9.03094 7.5 8.75V1.25C7.5 0.969062 7.33717 0.71168 7.07812 0.583127Z" fill="#6750A4" stroke="#6750A4" stroke-linejoin="round"/>
                    </svg>
                    {{ $t('orphan.return') }}
                </a>
            </div>
            <Container>
                <TopBarHistory :networkId="address.networkId" :sort-value="sortDate"  :page="currentPage" @changePage="changePage" />
                <HistoryAddress @setSort="setSort" :sortValue="sortValue"  v-if="!isLoading"/>
            </Container>
            <preloader v-if="isLoading"/>
            <pagination size="small"
                        :total="count"
                        :page-size="pagesCount"
                        :current="currentPage"
                        @page-change="changePage" v-if="count"
            />
            <transition name="slide-fade">
                <history-transaction-info v-if="infoState" />
            </transition>
        </div>
    </div>
</template>

<script>
import pagination from "@/public/components/common/uiKit/Pagination.vue";
import Preloader from "/public/components/common/uiKit/spinner/index.vue";
import HistoryAddress from "../components/elements/currentHistoryAddress/HistoryAddress";
import TopBarHistory from "../components/elements/currentHistoryAddress/TopBarHistory";
import {mapActions, mapState} from "vuex";
import {getNetworkFullNameByNetworkName, getNetworkShortNameByNetworkName} from "@/public/filters";
import Container from "@/public/components/common/uiKit/Container.vue";
import HistoryTransactionInfo from "@/public/components/elements/history-addresses/HistoryTransactionInfo.vue";

export default {
    name: "historyAddresses",
    title: 'historyAddresses.title_main',

    components: {
        HistoryTransactionInfo,
        Container,
        Preloader,
        HistoryAddress,
        TopBarHistory,
        pagination,
    },

    data() {
        return {
            currentAddress: null,
            currentPage: 1,
            sortValue: false,
        };
    },
    watch: {
      lastUpdateAddress: {
        handler(v) {
          const {
            addressId
          } = this

          if (addressId === v.addressId) {
            this.getList()
          }
        },
        deep: true
      }
    },
    computed: {
        ...mapState('balances', ['lastUpdateAddress']),
        ...mapState('app', ['isLoading', 'isInit']),
        ...mapState('historyAddresses', ['pages', 'count', 'pagesCount', 'address', 'infoState', 'columns', 'defaultColumns']),
        addressType() {
            return this.$route.query.type ? [this.$route.query.type] : [];
        },

        addressId() {
            return this.$route.params.id;
        },

        stringAddress() {
            if (this.address?.address) return `${this.address.address.substr(0, 3)}****${this.address.address.substr(-4)}`;
        },
        sortDate (direction) {
             return  this.sortValue ? "ASC" : "DESC"
        },
    },

   async created() {
        const {
            $route,
            $router,
            isInit,
            getColumns,
            defaultColumns,
            getAddressBook,
        } = this;

        if (isInit) {
            this.getList()
            getAddressBook({
              route: $route,
              router: $router,
              showUrlFilters: false,
              page: this.currentPage,
              limit: 9999
            });
            await getColumns()
            if (!this.columns.length) {
                await this.sendColumns(defaultColumns)
            }
        }
    },

    methods: {
        getNetworkShortNameByNetworkName,
        getNetworkFullNameByNetworkName,
        ...mapActions('historyAddresses', [
            'addressTransactions',
            'getColumns',
            'sendColumns'
        ]),
        ...mapActions('addressBook', [
          'getAddressBook',
        ]),
        setSort (val) {
          this.sortValue = val
        },
        changePage(pageNumber) {
            this.currentPage = pageNumber
        },

        goBack() {
            if (window.history.length > 2) this.$router.go(-1);
            else this.$router.push({name: 'historyAddresses'});
        },
        getList() {
          const {
            addressTransactions,
            addressId,
            addressType,
          } = this

          addressTransactions({
            showUrlFilters: false,
            addressId,
            addressType,
            page: 1,
          });
        }
    },
};
</script>

<style scoped lang="scss">
.history-addresses {
  padding: 0 30px 30px 30px;
  margin-top: -20px;
    &__return {
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--new-front-bg-btn-primary-bg-modal-primary);
        font-size: $h4;
        font-weight: 500;
        margin-bottom: 20px;
    }
  &__title {
    font-family: $Gilroy;
    font-size: $h1;
    color: var(--new-front-primary-font-color);
    font-weight: 400;
    margin-bottom: 5px;
    width: 100%;
  }

  &__sub {
      font-family: $Gilroy;
    font-style: normal;
    font-weight: 400;
      font-size: $h2;
    line-height: 23px;
    color: var(--new-front-primary-font-color);
    margin-bottom: 20px;
  }

  &__head-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__title-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__all {
    background: #0059F7;
    border-radius: 6px;
    border: none;
    width: 100%;
    max-width: 125px;
    min-height: 40px;
    align-self: flex-start;
    transition: all 0.3s ease-in;

    &:hover {
      background: var(--blue-400);
    }

    ::v-deep .at-btn__text {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: var(--white-100);
    }

    ::v-deep .at-btn__icon {
      font-size: var(--font-size-text-main);
    }
  }
}
@media screen and (max-width: 576px) {
  .history-addresses {
    margin-top: -10px;
    padding: 0 10px 10px 10px;
    &__head-title {
      display: none;
    }

    &__all {
      background: transparent;
      padding: 0;
      width: 20px;

      ::v-deep .at-btn__icon {
        font-size: 20px;
        color: #0059F7;
      }
    }

    &__head-wrapper {
      flex-direction: column;
      /* padding-left: 15px; */
      justify-content: flex-start;
    }

    &__title-wrapper {
      align-items: start;
    }

    &__title {
      font-size: 20px;
      font-weight: 500;
      line-height: 23px;
      align-items: center;
      display: flex;
      justify-content: flex-start;
      width: 151px;
      text-align: left;
      margin-bottom: 5px;
    }

    &__sub {
      font-size: 16px;
      line-height: 19px;
    }
  }
}
.secondary-text {
  color: var(--new-front-secondary-font-color)
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.flip-list-move {
  transition: transform 1s;
}
</style>
