<template>
  <div class="balances-list-header list-header row">
    <div class="list-header__item col">
      Тип операции
    </div>
    <div class="list-header__item col">
      Сумма
    </div>
    <div class="list-header__item col">
      Дата
    </div>
    <div class="list-header__item col">
      Баланс
    </div>
    <div class="list-header__item col">
      Ордер
    </div>
  </div>
</template>

<script>

export default {
    name: 'TransactionsHistoryListHeader',
};

</script>
