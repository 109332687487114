import gql from 'graphql-tag';

export default gql`
mutation($organizationId: String!, $name: String!, $entities: Json)  {
  setUserInterfaceSettings (organizationId: $organizationId,name: $name,entities: $entities,) {
    traceId
    code
    result {
      name
      entities
    }
  }
}
`;
