import gql from 'graphql-tag';

export const getExchangeMutation = gql`
    query($id: String!) {
        getExchange(id: $id) {
            id
            currencyFrom
            currencyTo
            amountFrom
            amountTo
            state
            exchangeRate
            userId
            createdAt
            meta
        }
    }
`;
