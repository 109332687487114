import gql from 'graphql-tag';

export default gql`
    query {
        getUser {
            id
            email
            createdAt
            otpToken
            lang
        }
    }
`;