import gql from 'graphql-tag';

export default gql`
    mutation($registryId: String!, $accountId: String!, $feeTokens:[RegistryItemFeeToken!]!, $key: String!) {
        executeRegistry(registryId: $registryId, accountId: $accountId, feeTokens: $feeTokens, key: $key) {
            code
            result {
                itemId
                status
            }
        }
    }
`;
