import getPreCheckoutCurrencies from '/public/graphQl/queries/getPreCheckoutCurrencies';

export const fetchPreCheckoutCurrencies = async ({dispatch, commit, rootGetters}, payload) => {
    let pageCurrenciesData;

    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    const variables = {
        organizationId,
        ...payload,
    };

    try {
        commit('app/toggleLoading', null, {root: true});
        pageCurrenciesData = await dispatch('app/request', {
            type: 'query',
            gql: getPreCheckoutCurrencies,
            variables,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (pageCurrenciesData.message) {
        throw pageCurrenciesData.message;
    }

    if (pageCurrenciesData.errors) {
        throw pageCurrenciesData.errors;
    }

    const {
        data: {getPreCheckoutPageCurrencies: pageCurrencies},
    } = pageCurrenciesData;

    commit('pageCurrencies', pageCurrencies);
    
    return pageCurrencies;
};