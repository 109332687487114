<script>
  import registration from "@/public/pages/registration.vue";
  import apolloRegistration from "@/public/pages/apollo/apolloRegistration.vue";

  const APOLLO_PRODUCT_NAME = 'Apollopayment';
  const envProductName = import.meta.env.VITE_PRODUCT_NAME
  export default {
    name: "RegistrationContainer",
    computed: {
      component () {
        return envProductName !== APOLLO_PRODUCT_NAME ? registration : apolloRegistration;
      }
    }
  }
</script>

<template>
<component :is="component" />
</template>
