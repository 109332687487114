import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!) {
        getCurrencies(organizationId: $organizationId) {
            id
            currency
            alias
            orderIndex
            allowDeposit
            allowWithdraw
            allowPayout
            allowInvoice
            allowBusiness
            allowAccounts
            isFiat
            stablecoin
            usdRate
            alias
            networks {
                id
                name
                alias
                confirmations
                addressRegex
                tagRegex
                underMaintenance
                allowDeposit
                allowWithdrawal
                allowCrosschainSwaps
                allowCrosschainSwapFrom
                allowCrosschainSwapTo
                allowCrosschainTransfer
                allowRecurring
                isDefault
                contract
                networkFee
                networkFeeUSD
                minWithdrawal
                minFreezeTime
                maxFreezeTime
            }
        }
    }
`;
