<template>
  <div
      class="base-btn"
      :class="[ size, { disabled } ]"
      @click="$emit('click')"
  >
    <slot name="prepend" />
    <slot name="label">
      <span class="label">
        {{ label }}
      </span>
    </slot>
  </div>
</template>

<script>
export default {
  name: "base-button",
  props: {
    label: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'normal'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.base-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  &.normal {
    height: 58px;
  }

  &.small {
    height: 34px;
  }

  background: var(--button-border-button-primary-default);
  cursor: pointer;

  &.disabled {
    background: var(--button-background-button-disabled);
    cursor: not-allowed;

    .label {
      color: var(--ocp-white);
    }
  }

  border-radius: 10px;

  .label {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size-normal);

    color: var(--white);

    padding: 0 10px;

    white-space: nowrap;
  }
}
</style>
