<template>
    <div class="exchanges">
        <h1 class="title">
            Обмены
        </h1>
        <ExchangesTopBar class="top-bar" />
        <div class="list">
            <ExchangesListHeader class="list-header" />
            <template v-if="hasExchanges">
                <PreviewExchange
                    v-for="(exchange, key) in exchanges"
                    :key="key"
                    class="exchange"
                    :data="exchange"
                />
                <div class="pagination">
                    <AtPagination
                        :total="totalExchangesCount"
                        :page-size="exchangesCountOnPage"
                        :current="currentPage"
                        @page-change="changePage"
                    />
                </div>
            </template>
            <EmptyTable
                v-else
                class="empty"
            >
                Нет данных
            </EmptyTable>
        </div>
        <CreateExchangeModal />
    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';

import RequestsListHeader from "/public/components/elements/requests/RequestsListHeader";
import AtPagination from "at-ui/src/components/pagination";
import EmptyTable from "/public/components/elements/EmptyTable";
import ExchangesTopBar from "../components/elements/exchanges/ExchangesTopBar";
import ExchangesListHeader from "../components/elements/exchanges/ExchangesListHeader";
import PreviewExchange from "../components/elements/exchanges/PreviewExchange";
import CreateExchangeModal from "../components/modals/exchanges/CreateExchangeModal";

export default {
    name: 'ExchangesPage',
    components: {
        CreateExchangeModal,
        PreviewExchange,
        ExchangesListHeader,
        ExchangesTopBar,
        RequestsListHeader,
        AtPagination,
        EmptyTable,
    },
    computed: {
        ...mapState('app', [
            'isInit',
        ]),
        ...mapState('exchanges', [
            'exchangesCountOnPage',
            'exchanges',
            'totalExchangesCount',
        ]),
        currentPage () {
            const pageFromUrl = this.$route.query.page;

            if (!pageFromUrl) {
                return 1;
            }

            return Number(pageFromUrl);
        },
        hasExchanges () {
            return Boolean(this.exchanges.length);
        },
    },
    mounted () {
        const {
            $route,
            isInit,
            fetchExchanges,
        } = this;

        if (isInit) {
            fetchExchanges({
                route: $route,
                showUrlFilters: false,
            });
        }
    },
    methods: {
        ...mapActions('exchanges', [
            'fetchExchanges',
        ]),
        changePage (pageNumber) {
            const {
                $route,
                $router,
                fetchExchanges,
            } = this;

            fetchExchanges({
                route: $route,
                router: $router,
                page: pageNumber,
            });
        },
    },
    watch: {
        isInit () {
            const {
                $route,
                fetchExchanges,
            } = this;

            fetchExchanges({
                route: $route,
                showUrlFilters: false,
            });
        },
    },
};

</script>

<style lang="scss" scoped>

    .exchanges {

        .title {
            margin-bottom: 24px;
            @include h1;
        }

        .top-bar {
            margin-bottom: 42px;
        }

        & .list {
            min-width: 720px;
        }

        .pagination {
            margin-top: 24px;
            display: flex;
            justify-content: center;
        }
    }

</style>
