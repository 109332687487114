<template>
  <BaseModal
    :value="isOpen"
    :title="title"
    :show-head="false"
    :show-close="true"
    :show-footer="false"
    @on-cancel="closeKYCModal"
  >
    <div class="kyc-wrapper">
      <div class="svg-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
          <g clip-path="url(#clip0_17483_9202)">
            <rect width="60" height="60" rx="10" fill="#DAC066"/>
            <path d="M24 18H31.9393C32.3372 18 32.7187 18.18 33 18.4393L38.5607 24C38.842 24.2813 39 24.6628 39 25.0607V39C39 40.6569 37.6569 42 36 42H24C22.3431 42 21 40.6569 21 39V21C21 19.3431 22.3431 18 24 18ZM32.25 20.25V23.25C32.25 24.0784 32.9216 24.75 33.75 24.75H36.75L32.25 20.25Z" fill="white"/>
          </g>
          <defs>
            <clipPath id="clip0_17483_9202">
              <rect width="60" height="60" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <p class="kyc__title">{{ $t('kyc-modal.title') }}</p>
      <p class="kyc__description">{{ $t('kyc-modal.description') }}</p>
      <div class="kyc__buttons">
        <a :href="tgLink" target="_blank">
          <base-button  label="Telegram" type="primary">
            <template #prepend>
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_8648_58990)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8.5C16 12.9183 12.4183 16.5 8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5C12.4183 0.5 16 4.08172 16 8.5ZM8.28668 6.40595C7.50857 6.7296 5.95342 7.39946 3.62126 8.41555C3.24256 8.56615 3.04417 8.71348 3.02611 8.85754C2.99559 9.101 3.30047 9.19687 3.71565 9.32741C3.77212 9.34517 3.83064 9.36357 3.89062 9.38307C4.29909 9.51585 4.84855 9.67118 5.13419 9.67735C5.3933 9.68295 5.68249 9.57613 6.00176 9.35689C8.18077 7.886 9.30559 7.14254 9.37621 7.12651C9.42603 7.1152 9.49507 7.10099 9.54184 7.14256C9.58862 7.18414 9.58402 7.26288 9.57907 7.284C9.54886 7.41276 8.35208 8.5254 7.73275 9.10119C7.53967 9.28069 7.40272 9.40801 7.37472 9.43709C7.312 9.50223 7.24809 9.56385 7.18665 9.62307C6.80718 9.98889 6.52261 10.2632 7.20241 10.7112C7.52909 10.9265 7.79051 11.1045 8.05131 11.2821C8.33612 11.4761 8.62019 11.6695 8.98774 11.9104C9.08139 11.9718 9.17082 12.0356 9.25793 12.0977C9.58938 12.334 9.88717 12.5463 10.2551 12.5124C10.4688 12.4928 10.6896 12.2917 10.8018 11.6922C11.0668 10.2754 11.5878 7.20564 11.7081 5.94065C11.7187 5.82982 11.7054 5.68798 11.6948 5.62572C11.6841 5.56345 11.6618 5.47474 11.5809 5.40907C11.4851 5.33129 11.3371 5.31489 11.2709 5.31606C10.97 5.32136 10.5084 5.48188 8.28668 6.40595Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_8648_58990">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
                  </clipPath>
                </defs>
              </svg>
            </template>
          </base-button>
        </a>
        <a :href="emailLink">
          <base-button  label="E-mail" type="primary">
            <template #prepend>
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.44118 0.934513C8.85294 0.620787 8.14706 0.620787 7.55882 0.934513L1.55882 4.13451C0.907097 4.4821 0.5 5.16059 0.5 5.89922V6.21272L7.20853 10.1447L8.5 9.42723L9.79147 10.1447L16.5 6.21272V5.89922C16.5 5.16059 16.0929 4.4821 15.4412 4.13451L9.44118 0.934513Z" fill="white"/>
                <path d="M16.5 7.37183L10.8067 10.7088L16.5 13.8717V7.37183Z" fill="white"/>
                <path d="M16.4411 14.9829L8.5 10.5712L0.558888 14.9829C0.77519 15.8538 1.56222 16.4992 2.5 16.4992H14.5C15.4378 16.4992 16.2248 15.8538 16.4411 14.9829Z" fill="white"/>
                <path d="M0.5 13.8717L6.19326 10.7088L0.5 7.37183V13.8717Z" fill="white"/>
              </svg>
            </template>
          </base-button>
        </a>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseModal from "../modals/BaseModal";
import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";

export default {
    name: "KYCModal",
    components: {
      BaseModal,
      BaseButton
    },
    data () {
        return {
            emailLink: import.meta.env.VITE_SUPPORT_EMAIL,
            tgLink: import.meta.env.VITE_TELEGRAM_LINK
        }
    },
    computed: {
        ...mapGetters('app', [
            'isOpenModal',
        ]),
        isOpen() {
            const {
                $options: {name},
                isOpenModal,
            } = this;

            return isOpenModal(name);
        },
        title () {

        },
    },
    methods: {
        ...mapActions('app', [
            'closeModal',
        ]),
      closeKYCModal () {
            this.closeModal();
        },
    },
};
</script>

<style lang="scss" scoped>
.kyc {
  font-family: var(--new-front-font-family);

  &-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 15px;
  }
  &__title {
    font-size: $h1;
    font-weight: 500;
    color: var(--new-front-text-primary-bg-modal-primary);
    margin-bottom: 18px;
    text-align: center;
  }
  &__description {
    font-size: $h4;
    color: var(--new-front-text-secondary-bg-modal-primary);
    margin-bottom: 30px;
    text-align: center;
  }
  &__buttons {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
    &-content {
      display: flex;
      align-items: center;
      > svg {
        margin-right: 10px;
      }
    }
  }
}
.svg-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 18px;
}

::v-deep .at-modal {
  max-width: 640px!important;
  width: auto!important;
}

::v-deep .at-modal__body {
  padding: 26px 48px!important;
}

::v-deep .at-btn {
  max-width: 100% !important;
}

@include below_phone() {
  .kyc {
    &__title {
      font-size: $h3;
      margin-bottom: 5px;
    }
    &__description {
      font-size: $h6;
      margin-bottom: 50px;
    }
    &__buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .base-btn {
        width: 100%;
      }
    }
  }
}
</style>
