export const mutations = {
    orders: (state, orders) => {
        state.orders = orders;
    },
    setOrder: (state, order) => {
        state.order = order;
    },
    setLoading: (state, value) => {
      state.isLoading = value
    },
    setOrderInfoState: (state, value) => {
      state.infoState = value
    },
    totalOrdersCount: (state, count) => {
        state.totalOrdersCount = count;
    },
    filtersStatus: (state, status) => {
        state.filters.status = status;
    },
    clearFilters: (state) => {
        state.filters = {
            status: [],
        };
    },
    clearAllStates: (state) => {
        state.ordersCountOnPage = 10;
        state.orders = [];
        state.totalOrdersCount = null;
        state.filters = {
            status: [],
        };
        state.orderTransaction = null;
    },
};
