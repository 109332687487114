import updateOrganizationUserMutation from "/public/graphQl/mutations/updateOrganizationUser.js";

export const updateOrganizationUser = async ({commit, dispatch,rootState, rootGetters}, payload) => {
    const selectedOrganizationId = rootGetters['app/getSelectedOrganizationId'];
    let currenciesData;

    let variables = {
        organizationId: selectedOrganizationId,
        userId: payload.userId,
        name: payload.name,
        organizationRoleId: payload.roleId,
    };

    try {
        if (!rootState.app.isLoading) {
            commit('app/setLoading', true, {root: true});
        }
        currenciesData = await dispatch('app/request', {
            type: 'query',
            gql: updateOrganizationUserMutation,
            variables,
        }, {root: true});

    } catch (error) {
        throw new Error(error.message);
    } finally {
        commit('app/setLoading', false, {root: true});
    }

    if (currenciesData.code === 0) {
        throw currenciesData.message;
    }

    return true;
};
