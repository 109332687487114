<script lang="ts">
  import {defineComponent} from 'vue'
  import BaseInput from "@/public/pages/integration-settings/components/Input/index.vue";

  export default defineComponent({
    name: "WidgetsTopHeaderInput",
    components: {BaseInput}
  })
</script>

<template>
  <base-input class="input_wrapper" v-bind="$attrs" />
</template>

<style scoped lang="scss">
.input_wrapper input {
  border-radius: 10px;
  border: 1px solid var(--border-border-input-primary-default, #464646);
  background: var(--bg-background-input-primary, #2A2A2A);
  padding: 0 15px 0 15px;
  color: var(--text-text-primary, #FFF);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
</style>
