import gql from 'graphql-tag';

export default gql`
   query ($event: WebhookEvent,$organizationId: String!, $query: String, $date: [String!], $webhookStatus: Int, $sortDate: String, $limit: Int!, $offset: Int!) {
  getWebhooks (event: $event, organizationId: $organizationId, query: $query, date: $date,sortDate: $sortDate, webhookStatus: $webhookStatus, limit: $limit, offset: $offset) {
    webhooks  {
      id
      event
      eventId
      requestUrl
      requestHeaders
      requestBody
      responseCode
      responseStatus
      responseBody
      sentAt
      createdAt
      updatedAt
    }
    total
} 
}
`;
