<template>
    <div class="preview-transaction-wrapper">
        <div class="preview-transaction-web" @click="handlePreviewClick">
            <div class="preview-transaction__inner-row">
                <p class="preview-transaction__col ">
                    {{ createdAt | getFormatDate('DD/MM/YY HH:mm') }}
                </p>
                <p class="preview-transaction__col">
                    {{ data.alias ? data.alias : '-' }}
                </p>
              <div class="preview-transaction__col">
                <address-risks :status="riskLevel" />
              </div>
                <div class="preview-transaction__col addressBookAlias preview-transaction__id"
                     v-if="data.addressTo && !isAddressToTransferType">
                    <copy-string
                      v-if="data.type === 'deposit' ? data.addressTo : data.addressFrom"
                      :string="data.type === 'deposit' ? data.addressTo : data.addressFrom"
                      :successMessage="$t('copy.addresses_copied')"
                      :failMessage="$t('copy.addresses_not_copied')"
                      :title="notification"
                      canCopy
                    />
                    <div class="preview-transaction__preview-text" v-else>-</div>
                    <div v-if="data.addressToBookAlias || data.addressBookAlias" class="secondary-text">{{ data.type === 'deposit' ? data.addressToBookAlias : data.addressBookAlias }}</div>
                </div>
                <div class="preview-transaction__col">
                    <svg v-if="data.type === 'deposit'" class="preview-transaction__col-typeIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="24" height="24" rx="8" fill="var(--new-front-status-processed)"/>
                      <path d="M13.2 8L10 12M10 12L13.2 16M10 12L18 12" stroke="var(--new-front-status-text-processed)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M11.179 5.64164C9.86021 6.00582 8.65599 6.83312 7.7931 8.05495C6.12731 10.4137 6.14269 13.6634 7.8307 15.9993C8.69313 17.1928 9.88396 17.9979 11.185 18.352" stroke="var(--new-front-status-text-processed)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg v-else width="24" class="preview-transaction__col-typeIcon" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="24" height="24" rx="8" fill="var(--new-front-status-error)"/>
                      <path d="M10.8 16L14 12M14 12L10.8 8M14 12L6 12" stroke="var(--new-front-status-text-error)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12.821 18.3584C14.1398 17.9942 15.344 17.1669 16.2069 15.9451C17.8727 13.5863 17.8573 10.3366 16.1693 8.0007C15.3069 6.80723 14.116 6.00206 12.815 5.64804" stroke="var(--new-front-status-text-error)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span style="margin-left: 5px">
                      {{ $t(`historyAddresses.${data.type}`) }}
                    </span>
                </div>
                <div class="preview-transaction__col addressBookAlias preview-transaction__id"
                     v-if="data.addressTo && !isAddressToTransferType">
                    <copy-string
                      v-if="data.type === 'deposit' ? data.addressFrom : data.addressTo"
                      :string="data.type === 'deposit' ? data.addressFrom : data.addressTo"
                      :successMessage="$t('copy.addresses_copied')"
                      :failMessage="$t('copy.addresses_not_copied')"
                      :title="notification"
                      canCopy
                    />
                  <div class="preview-transaction__preview-text" v-else>-</div>
                  <div v-if="data.addressToBookAlias || data.addressBookAlias" class="secondary-text">{{ data.type === 'deposit' ? data.addressBookAlias : data.addressToBookAlias }}</div>
                </div>
                <div class="preview-transaction__col preview-transaction__id txid">
                    <template v-if="data.txId">
                      <a :href="viewTxHash(networkId, data.txId)" target="_blank">{{data.txId | preparedString }}</a>
                      <copy v-if="data.txId" :value="viewTxHash(networkId, data.txId)" />
                    </template>
                    <template v-else>
                      -
                    </template>
                </div>
                <div class="preview-transaction__col preview-transaction__network">
                  <network-img :network="networkName"/>
                  <span class="network-alias">
                    {{ networkName | getNetworkFullNameByNetworkName }}
                  </span>
                  <span class="network-ticker">
                    {{ networkName | getNetworkShortNameByNetworkName }}
                  </span>
                </div>
                <div class="preview-transaction__col preview-transaction__currency">
                    <template v-if="currencyToInfo">
                      <currency-img :currency-id="currencyToInfo.id"/>
                      <span class="currency-alias">
                        {{ currencyToInfo.alias }}
                      </span>
                      <span class="currency-ticker">
                        ({{ currencyToInfo.currency }})
                      </span>
                    </template>
                </div>
                <p class="preview-transaction__col"
                >
                    {{amount}}
                </p>
                <p class="preview-transaction__col preview-transaction__col_deposit"
                   v-if="deposit && deposit !== 0 && !credit">
                    + {{ deposit }}
                </p>

                <p class="preview-transaction__col preview-transaction__col_deposit" v-else-if="deposit === 0">
                    {{ deposit }}
                </p>
                <p class="preview-transaction__col preview-transaction__col_credit"
                   v-if="credit && credit !== 0 && !deposit">
                    - {{ credit }}
                </p>
                <p class="preview-transaction__col preview-transaction__col_credit" v-else-if="credit === 0">
                    {{ credit }}
                </p>
                <div class="preview-transaction__col" v-else-if="credit && deposit">
                    <span class="preview-transaction__col_credit">{{ credit }}</span>
                    <span>&nbsp;&#x2192;&nbsp;</span>
                    <span class="preview-transaction__col_deposit">{{ deposit }}</span>
                </div>
                <div class="preview-transaction__col">
                    <base-badge :status="data.status">
                      <template #label>
                        {{ $t(`bridge.history.statuses.${data.status.toUpperCase()}`) }}
                      </template>
                    </base-badge>
                </div>
            </div>
        </div>
        <div :class="['preview-transaction-mobile', isDetailsOpen ? 'preview-transaction_mobile--active' : '']">
          <div class="preview-transaction-mobile__preview" @click="openDetails">
            <div :class="['preview-transaction-mobile__info-status', `preview-transaction-mobile__info-status--${data.status}`]"></div>
            <div class="preview-transaction-mobile__currency-container">
              <p class="preview-transaction-mobile__preview-text">
                <svg v-if="data.type === 'deposit'" class="preview-transaction-mobile__col-typeIcon" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="24" height="24" rx="8" fill="var(--new-front-status-processed)"/>
                  <path d="M13.2 8L10 12M10 12L13.2 16M10 12L18 12" stroke="var(--new-front-status-text-processed)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11.179 5.64164C9.86021 6.00582 8.65599 6.83312 7.7931 8.05495C6.12731 10.4137 6.14269 13.6634 7.8307 15.9993C8.69313 17.1928 9.88396 17.9979 11.185 18.352" stroke="var(--new-front-status-text-processed)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg v-else  class="preview-transaction-mobile__col-typeIcon" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="24" height="24" rx="8" fill="var(--new-front-status-error)"/>
                  <path d="M10.8 16L14 12M14 12L10.8 8M14 12L6 12" stroke="var(--new-front-status-text-error)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12.821 18.3584C14.1398 17.9942 15.344 17.1669 16.2069 15.9451C17.8727 13.5863 17.8573 10.3366 16.1693 8.0007C15.3069 6.80723 14.116 6.00206 12.815 5.64804" stroke="var(--new-front-status-text-error)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span style="margin-left: 5px">
                  {{ $t(type) }}
                </span>
              </p>
              <p class="preview-transaction-mobile__preview-text secondary-text"> {{ createdAt | getFormatDate('DD/MM/YY HH:mm') }}</p>
            </div>
            <div class="preview-transaction-mobile__amount-container">
              <p :class="['preview-transaction-mobile__preview-text', data.type === 'deposit' ? 'col_deposit' : 'col_credit']" style="justify-content: end">
                {{ data.type === 'deposit' ? '+' : '-' }}{{ amount }}
              </p>
              <span class="preview-transaction-mobile__preview-text">{{ currencyTo | getCurrencyTicker }}<template v-if="networkId">&nbsp;<span class="secondary-text">{{ getNetworkInfoById(networkId).name | getNetworkShortNameByNetworkName }}</span></template></span>
            </div>
            <svg :class="['chevron', isDetailsOpen ? 'chevron--active' : '']" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.712375 1.635C0.569375 1.462 0.484375 1.241 0.484375 1C0.484375 0.448 0.932375 0 1.48438 0H7.48438C8.03638 0 8.48438 0.448 8.48438 1C8.48438 1.241 8.39937 1.462 8.25637 1.635L5.29238 5.589C5.11037 5.838 4.81637 6 4.48438 6C4.15237 6 3.85837 5.838 3.67637 5.589L0.712375 1.635Z" fill="var(--new-front-primary-font-color)"/>
            </svg>
          </div>
          <div
            v-if="isDetailsOpen"
            class="preview-transaction-mobile__details"
          >
            <div class="preview-transaction__details-item">
              <p class="preview-transaction__preview-text">
                {{ $t('addressRisks.placeholder') }}:
              </p>
              <div class="preview-transaction__preview-text column">
                <address-risks :status="riskLevel" />
              </div>
            </div>
            <div class="preview-transaction-mobile__details-item">
              <p class="preview-transaction-mobile__preview-text">
                {{ $t('historyAddresses.write-off_address') }}:
              </p>
              <p class="preview-transaction-mobile__preview-text ">

                <CopyString
                    :string="data.addressFrom"
                    :successMessage="$t('copy.addresses_copied')"
                    :failMessage="$t('copy.addresses_not_copied')"
                    :title="notification"
                    size="small"
                    canCopy
                />
              </p>
            </div>
            <div class="preview-transaction-mobile__details-item">
              <p class="preview-transaction-mobile__preview-text">
                {{ $t('historyAddresses.transfer_address') }}:
              </p>
              <p class="preview-transaction-mobile__preview-text">
                <CopyString
                    :string="data.addressTo"
                    :successMessage="$t('copy.addresses_copied')"
                    :failMessage="$t('copy.addresses_not_copied')"
                    :title="notification"
                    size="small"
                    canCopy
                />
              </p>
            </div>
            <div class="preview-transaction-mobile__details-item">
              <p class="preview-transaction-mobile__preview-text">
                TX_hash:
              </p>
              <p v-if="data.txId" class="preview-transaction-mobile__preview-text preview-transaction-mobile__id txid">
                <template v-if="data.txId">
                  <a :href="viewTxHash(networkId, data.txId)" target="_blank">{{data.txId | preparedString }}</a>
                  <copy v-if="data.txId" :value="viewTxHash(networkId, data.txId)" />
                </template>
                <template v-else>
                  -
                </template>
              </p>
              <p v-else class="preview-transaction-mobile__preview-text">-</p>
            </div>
            <div class="preview-transaction-mobile__details-item last">
              <p class="preview-transaction-mobile__preview-text">
                {{ $t('historyAddresses.status') }}
              </p>
              <div class="preview-transaction-mobile__preview-text">
                <base-badge :status="data.status">
                  <template #label>
                    {{ $t(`bridge.history.statuses.${data.status.toUpperCase()}`) }}
                  </template>
                </base-badge>
              </div>
            </div>
            <base-button @click="handlePreviewClick" class="preview-transaction-mobile__linkBtn" :label="$t('orphan.info-btn')" type="primary" />
          </div>
        </div>
    </div>
</template>

<script>

import {mapActions, mapState} from 'vuex';
import AtCollapse from 'at-ui/src/components/collapse/';
import AtCollapseItem from 'at-ui/src/components/collapse-item/';
import CopyString from "/public/components/elements/CopyString";
import {
  getNetworkById, getNetworkInfoById,
  transactionHistoryBasis,
  transactionHistorySource,
  transactionHistoryType,
} from "../../../filters";
import {TRANSACTION_HISTORY_STATUS_ENUM} from "../../../../common/constants/transaction-history-status-enum";
import BigNumber from "bignumber.js";
import Copy from "@/public/components/common/copy.vue";
import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
import AddressRisks from "@/public/components/elements/address-risks/AddressRisks.vue";

export default {
    name: 'PreviewTransaction',
    components: {
      AddressRisks,
      BaseButton,
        Copy,
        CopyString,
        AtCollapse,
        AtCollapseItem,

        CurrencyImg: () => import(/* webpackChunkName: "public/components/common/uiKit/currencyImg" */ '/public/components/common/uiKit/currencyImg'),
        NetworkImg: () => import(/* webpackChunkName: "public/components/common/uiKit/networkImg" */ '/public/components/common/uiKit/networkImg'),
        BaseBadge: () => import(/* webpackChunkName: "public/components/common/uiKit/badge" */ '/public/components/common/uiKit/badge')
    },
    data() {
        return {
            isDetailsOpen: false
        }
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        routeName: {
            type: String,
            default: 'transaction',
        },
    },

    methods: {
        ...mapActions('historyAddresses', ['getCurrentAddress']),
        getNetworkInfoById,
        handlePreviewClick() {
          this.getCurrentAddress({ id: this.id })
        },
        openDetails () {
          this.isDetailsOpen = !this.isDetailsOpen
        },
        stringAddress(string) {
          return !!string ? `${string.substring(0, 8)}****${string.substring(-6)}` : '';
        },
        transactionHistoryStatus(status) {
            const statuses = {
                [TRANSACTION_HISTORY_STATUS_ENUM.INIT]: 'transactions.init',
                [TRANSACTION_HISTORY_STATUS_ENUM.ERROR]: 'transactions.error',
                [TRANSACTION_HISTORY_STATUS_ENUM.PENDING]: 'historyAddresses.pending',
                [TRANSACTION_HISTORY_STATUS_ENUM.PROCESSED]: 'historyAddresses.executed',
                [TRANSACTION_HISTORY_STATUS_ENUM.REJECTED]: 'transactions.rejected',
            };

            return statuses[status] || 'transactions.empty';
        },
        viewTxHash(networkId, hash) {
            const networkName = getNetworkInfoById(networkId).name;

            if (networkName === 'bsc') {
              return this.linkScan = `https://bscscan.com/tx/${hash}`;
            } else if (networkName === 'bsc-testnet') {
              return this.linkScan = `https://testnet.bscscan.com/tx/${hash}`;
            } else if (networkName === 'fantom') {
              return this.linkScan = `https://ftmscan.com/tx/${hash}`;
            } else if (networkName === 'ethereum') {
              return this.linkScan = `https://etherscan.io/tx/${hash}`;
            } else if (networkName === 'sepolia') {
              return this.linkScan = `https:/sepolia.etherscan.io/tx/${hash}`;
            } else if (networkName === 'tron') {
              return this.linkScan = `https://tronscan.org/#/transaction/${hash}`;
            } else if (networkName === 'solana') {
              return this.linkScan = `https://solscan.io/tx/${hash}`;
            } else if (networkName === 'bitcoin') {
              return this.linkScan = `https://blockchair.com/bitcoin/transaction/${hash}`;
            } else if (networkName === 'litecoin') {
              return this.linkScan = `https://blockchair.com/litecoin/transaction/${hash}`;
            } else if (networkName === 'bitcoincash') {
              return this.linkScan = `https://blockchair.com/bitcoin-cash/transaction/${hash}`;
            } else if (networkName === 'polygon') {
              return this.linkScan = `https://polygonscan.com/tx/${hash}`;
            }
        }
    },

    computed: {
        ...mapState('app', [
            'currencies',
        ]),
      riskLevel () {
        if (!this.data.risks) { return null }
        return this.data.risks.level
      },
        id() {
            return this.data.id;
        },
        type() {
            return transactionHistoryType(this.data.type);
        },
        status() {
            return this.transactionHistoryStatus(this.data.status);
        },
        basis() {
            return transactionHistoryBasis(this.data.basis);
        },
        source() {
            return transactionHistorySource(this.data.side);
        },
        createdAt() {
            return this.data.createdAt;
        },
        networkId() {
            return this.data.networkId;
        },
        networkName({ networkId }) {
          const network = getNetworkById(networkId)
          return network ? network.name : ''
        },
        isAddressToTransferType() {
            const ADDRESS_TYPE = {
                PAY_IN: 'transactions.transfer_type.pay_in',
                PAY_OUT: 'transactions.transfer_type.pay_out',
                ADVANCE: 'transactions.transfer_type.advance',
            };
            return ADDRESS_TYPE[this.data.addressTo] || false;
        },
        isAddressFromTransferType() {
            const ADDRESS_TYPE = {
                PAY_IN: 'transactions.transfer_type.pay_in',
                PAY_OUT: 'transactions.transfer_type.pay_out',
                ADVANCE: 'transactions.transfer_type.advance',
            };
            return ADDRESS_TYPE[this.data.addressFrom] || false;
        },
        currencyTo() {
            const {
                data: {
                    currencyId,
                },
            } = this;

            return currencyId;
        },
        currencyToInfo({ currencyTo, currencies }) {
          return currencies.find(({ id }) => id === currencyTo)
        },
        mobileStatusTextColor() {
            switch (this.data.status) {
            case TRANSACTION_HISTORY_STATUS_ENUM.INIT:
                return '#6190e8';
            case TRANSACTION_HISTORY_STATUS_ENUM.PROCESSED:
                return '#13ce66';
            case TRANSACTION_HISTORY_STATUS_ENUM.PENDING:
                return '#F2F2F2';
            case TRANSACTION_HISTORY_STATUS_ENUM.ERROR:
                return '#EB5757';
            case TRANSACTION_HISTORY_STATUS_ENUM.REJECTED:
                return '#EB5757';
            default:
                return '#8F949A';
            }
        },
        orderId() {
            return this.data.orderId;
        },
        hasOrderId() {
            return Boolean(this.orderId);
        },
        orderCreatedAt() {
            return this.data.orderCreatedAt;
        },
        credit() {
            if (this.data.credit) {
                const credit = new BigNumber(this.data.credit);
                return credit.decimalPlaces(8).toNumber();
            }
            return null;
        },
        amount() {
            return new BigNumber(this.data.amount).toNumber();
        },
        deposit() {
            if (this.data.deposit) {
                const credit = new BigNumber(this.data.deposit);
                return credit.decimalPlaces(8).toNumber();
            }
            return null;
        },
        id_copied() {
            return this.$t('copy.id_copied');
        },
        id_not_copied() {
            return this.$t('copy.id_not_copied');
        },
        notification() {
            return this.$t('copy.notification');
        },
        addressToTransfer() {
            return this.$t(this.$options.filters.transferAddressType(this.data.addressTo));
        },
        addressFromTransfer() {
            return this.$t(this.$options.filters.transferAddressType(this.data.addressFrom));
        },
    },
};

</script>

<style lang="scss" scoped>
.preview-transaction-wrapper {
  font-family: var(--new-front-font-family);

  &:last-child {
    .preview-transaction {
      &__col {
        border-bottom: none;
      }
    }
  }
}

.preview-transaction {
  padding: 0;
  position: relative;
  color: var(--new-front-primary-font-color);

  &-web {
    display: block;
    cursor: pointer;

    .preview-transaction__coin {
      font-style: normal;
      font-weight: 400;;
      color: var(--new-front-primary-font-color);
      margin-bottom: 2px;
    }

    .preview-transaction__date {
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #8F949A;
    }

    .preview-transaction__inner-row {
      display: grid;
      grid-template-columns: repeat(9, 27%) repeat(2, 20%);
      width: 100%;
      min-height: 65px;
      align-items: center;
    }

    .preview-transaction__write_off_currency {
      text-align: start;

    }

    .preview-transaction__col {
      font-family: var(--new-front-font-family);
      font-size: $h4;
      font-weight: 500;
      color: var(--new-front-text-primary-bg-primary);

      text-align: start;
      min-height: 65px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--new-front-border-bg-secondary);
      word-break: break-word;

      .currency-alias, .network-alias {
        white-space: nowrap;
        margin: 0 5px;
      }
      .currency-ticker, .network-ticker {
        color: var(--new-front-text-secondary-bg-secondary);
      }

      &_deposit {
        color: var(--green-200);
      }

      &_credit {
        color: var(--red-200);
      }

      &.txid {
        a {
          color: var(--new-front-text-link-bg-modal-secondary);
          text-decoration: underline;
        }
      }

      &_status {
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7px 10px;
        background: var(--colorStatus);
        border-radius: 8px;
        font-style: normal;
        line-height: 16px;
        text-align: center;
        width: 93px;
        margin-right: -30px;

        &.pending {
          color: #2C405A;
        }
      }

      &:first-child {
        padding-left: 45px;
      }

      &:last-child {
        padding-right: 45px;
        justify-content: flex-end;
      }
    }

    .preview-transaction__link {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(8 * 18% + 240px);
      height: 100%;
      z-index: 0;
    }

    .preview-transaction__id {
      position: relative;
      z-index: 2;
      display: flex;
    }

    .preview-transaction__currency, .preview-transaction__network {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: start;
      flex-wrap: wrap;
    }

    .preview-transaction__currency_write-off {
      align-items: center;
    }

    .preview-transaction__currency-img {
      margin-right: 12px;
      width: 24px;
      height: 24px;
    }
  }
  &-mobile {
    display: none;
  }
}

@media screen and (max-width: 1330px) {
  .preview-transaction-web {
    .preview-transaction__inner-row {
      grid-template-columns: repeat(9, 40%) repeat(2, 30%);
    }

    .preview-transaction {
      &__col {
        &:first-child {
          padding-left: 15px;
        }
      }
    }
  }

}

@media screen and (max-width: 576px) {
  .preview-transaction-web {
    display: none;
  }
  .preview-transaction-mobile {
    display: block;

    font-size: $h6;
    font-weight: 500;

    padding-bottom: 15px;
    border-bottom: 1px solid var(--new-front-divider-color);

    &__title-text {
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: var(--new-front-primary-font-color);
      text-align: right;

      &_secondary {
        color: var(--new-front-primary-font-color);
      }
    }

    &__details {
      padding: 0 15px;
      &-item {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--new-front-divider-color);
        padding: 15px 0;

        &.last {
          border-bottom: none;
        }

        .txid {
          a {
            color: var(--new-front-text-link-bg-modal-secondary);
            text-decoration: underline;
          }
          ::v-deep .copy-wrapper {
            margin-right: 0;
          }
        }
      }
    }

    &__item-value {
      margin-right: 10px;
    }

    &__currency-container, &__amount-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &__currency-container {
      flex-grow: 1;
    }
    &__amount-container {
      justify-content: end;
      .col_deposit {
        text-align: end;
        color: var(--green-200);
      }
      .col_credit {
        text-align: end;
        color: var(--red-200);
      }
    }

    &__preview {
      display: flex;
      justify-content: space-between;
      flex: 1;
      padding-top: 15px;
      align-items: center;

      gap: 10px;

      &-text {
        color: var(--new-front-primary-font-color);
        display: flex;
        align-items: center;
        font-size: $h6;
      }

      cursor: pointer;
    }

    &__info-status {
      width: 3px;
      min-width: 3px;
      height: 55px;
      border-radius: 13px;

      padding: 0;
      &--init {
        background: var(--new-front-status-text-active);
      }
      &--expired {
        background: var(--new-front-status-text-expired);
      }
      &--processed {
        background: var(--new-front-status-text-processed);
      }
      &--pending {
        background: var(--new-front-status-text-pending);
      }
      &--partial {
        background: var(--new-front-status-text-pending);
      }
      &--error {
        background: var(--new-front-status-text-error);
      }
      &--rejected {
        background: var(--new-front-status-text-error);
      }
    }

    .chevron {
      transition: transform .2s ease;
      min-width: 20px;

      &--active {
        transform: rotate(180deg);
      }
    }
  }
}
.addressBookAlias {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start !important;
  gap: 5px;
}
.secondary-text {
  color: var(--new-front-secondary-font-color);
}
</style>
