<template>
    <div>
        <div class="dashboard-transaction" v-if="hasTransactionsDeposit">
            <div class="dashboard-transaction__title">
                {{ $t('dashboard.incoming_transactions') }}
            </div>
            <dashboard-transaction-header :deposit="true" />
            <dashboard-transaction-row
                v-for="(transaction, key) in transactionsDeposit"
                :key="'d'+key"
                :data="transaction"
                :deposit="true"
            />
        </div>
        <div class="dashboard-transaction" v-if="hasTransactionsWithdrawal">
            <div class="dashboard-transaction__title">
                {{ $t('dashboard.outgoing_transactions') }}
            </div>
            <dashboard-transaction-header />
            <dashboard-transaction-row
                v-for="(transaction, key) in transactionsWithdrawal"
                :key="'w'+key"
                :data="transaction"
                :deposit="false"
            />
        </div>
    </div>
</template>

<script>

import {mapState} from "vuex";
import DashboardTransactionHeader from "./DashboardTransactionHeader";
import DashboardTransactionRow from "./DashboardTransactionRow";
import CurrencyImg from "/public/components/elements/CurrencyImg";

export default {
    name: 'DashboardTransaction',
    components: {
        DashboardTransactionHeader,
        DashboardTransactionRow,
        CurrencyImg,
    },
    computed: {
        ...mapState('dashboard', [
            'transactions',
        ]),
        transactionsDeposit() {
            return this.transactions.filter(tr => (tr.type === 'deposit'));
        },
        transactionsWithdrawal() {
            return this.transactions.filter(tr => (tr.type === 'withdrawal'));
        },
        hasTransactionsDeposit () {
            return Boolean(this.transactionsDeposit.length);
        },
        hasTransactionsWithdrawal () {
            return Boolean(this.transactionsWithdrawal.length);
        },
    },
    methods: {
    },
};

</script>

<style lang="scss" scoped>
.dashboard-transaction {
    margin-top: 45px;
    border: 1px solid $gray2;
    box-shadow: 2px 2px 4px 0 $gray2 ;
    border-radius: 1em;
    padding: 1em;

    &__title {
        @include h3;
        margin-bottom: .5em;
    }
}


</style>