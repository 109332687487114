export const cn = {
    'invoice': {
        'invoice': '帐户号码',
        'sum-invoice': '账单的金额。',
        'select-invoice': '选择支付货币',
        'coin-network': '货币和网络。',
        'rate': '课程。',
        'sum-payment': '支付金额。',
        'button': '支付',
        'search': '搜索',
    },

    'checkout': {
        'shop': '淘宝店',
        'payment': '付款方式',
        'back_to_the_store': '回到商店',
        'to_make_a_payment': '要进行支付，请发送 "{orderCurrency}"到"{orderAlias}"网络到给定的地址',
        'payment_completed': '已执行的付款',
        'the_payment_is_invalid': '付款无效',
        'payment_rejected': '拒绝付款',
        'payment_pending': '处理中的付款',
        'payment_init': '待付款',
        'lifetime_has_expired': '该款项的有效期已过。请不要将硬币寄到所给的地址。',
        'contact_the_store_owner': '该款项的有效期已过。请不要将硬币寄到所给的地址。联系店主要求退款或继续付款',
        'order': '秩序',
        'to_be_paid': '有待支付',
        'received': '收到了。',
        'description': '描述。',
        'before_the_expiration_of_the_order': '付款截止日期。',
        'network': '网络。',
        'payment_address': '付款地址。',
        'payment_address_not_found': '没有找到付款地址',
        'tag': '标签。',
        'contract_address': '合同地址。',
        'send_to_this_address_only': '只发送至此地址',
        'make_sure_the_network_is_correct': '确保网络是正确的。',
        'network_is_correct': '确保网络是正确的。',
        'contract_address_matches': '确保合同地址相符',
        'payment_page': '支付页面',
        'the_address_was_copied': '成功复制的地址',
        'the_tag_was_copied': '该标签已被复制',
        'invoice_was_successfully_paid': '授权书已成功支付',
        'the_invoice_was_paid': '该认股权证的支付金额为 ',
        'important_info': '重要信息',
        'the_contract_address_does_not_exist_on_this_network': '该合同地址在该网络中不存在',
        'error': '误差',
        'time_hours': '小时',
        'time_minute': '分钟',
        'time_second': '秒',
        'sellers_address': '卖家的地址已被核实',
        'stored_table': '这里将是传入交易的历史',
        'history_table': '入境交易记录',
        'full_info': '查看全部细节',
        'table_date': '日期',
        'table_sum': '数量',
        'table_value': '货币',
        'table_hash': 'tx_hash',
        'table_date_sum': '金额和日期',
        'payment_partially_paid': '部分支付',
        'paid_timer': '有薪酬的',
        'expired_timer': '付款已过期',
    },
    'new_checkout': {
        'details': {
            'title': '细节',
            'order': '命令',
            'date': '日期',
            'description': '描述',
        },
        'notification': '请仅将<b>{orderCurrency}</b>代币在以太坊 <b>{orderNetwork}</b>网络上发送到此地址。',
        'payment_address': '付款地址',
        'verified_notification': "卖方的地址已验证",
        'paid_amount': "已付金额",
        'timer_notifications': {
            'remaining_time': '剩余的付款时间',
            'completed': '付款已成功完成',
            'expired_with_invoice': '订单的有效期已过期，但您可以返回并创建新订单',
            'expired_without_invoice': '付款寿命过期',
            'dont_make_transactions': "请勿在此订单上进行交易!"
        },
        'statuses': {
            'not_paid': '未支付',
            'overpaid': '多付',
            'partially_paid': '部分付款',
            'fully_paid': '全额付款',
        },
        'history': {
            'title': '付款历史记录',
            'columns': {
                'date': '日期',
                'amount': '数量',
                'tx_hash': 'Tx_hash'
            },
            'empty_notification': '您发送到指定地址的所有交易都将在此窗口中可用。',
            'ok': '好的'
        },
        'orphan': {
            'title': '孤儿交易',
            'description': '此订单上的其中一项交易包含错误的网络或货币。.',
            'contact': '联系技术支持 ID',
        },
        'time-is-over': '时间到',
        'payment-successful': '付款成功',
        'partial-desc': '金额已部分支付。必须支付剩余金额才能成功付款。',
        'cancel': '取消',
        'back_to_shop': '回到商店',
        'back_to_invoice': '返回发票',
        'choose_another_currency': '选择其他货币',
        'you_can_pay': '您将能够以多种货币支付发票！'
    },
    'new_invoice': {
        'details': {
            title: '细节',
            invoice: '发票',
            date: '日期',
            description: '描述'
        },
        'amount': '数量',
        'btn_label': '继续',
        'btn_label_empty': '选择货币',
        'cancel': '取消',
        'search': '搜索...',
        'back_to_shop': '回到商店',
        'choose-network': '选择网络',
        'status': '地位',
        'you-payed': '你付了钱',
        'statuses': {
            "ERROR": '错误',
            "PENDING": '待办的',
            "PROCESSED": '已加工',
            "REJECTED": '被拒绝',
            'OVERPAID': '多付的钱',
            "CREATED": '已创建',
            'PARTIAL': '部分付费',
            'INIT': '初始化',
            'EXPIRED': '已到期'
        },
        'active_order': '活跃订单',
        'payment-successful': '付款成功'
    },
    'languages': {
        'select': '选择语言'
    },
    'preCheckout': {
        'errors': {
            'page_not_found': '找不到网页',
            'page_not_found_text': '我们似乎找不到您要寻找的页面',
        }
    }
};
