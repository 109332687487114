import gql from 'graphql-tag';

export default gql`
    query {
        getApiKeysPlaceholder {
            section
            entities
        }
    }
`;
