import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!, $accountId: String!) {
        getAccountBalances(organizationId: $organizationId, accountId: $accountId) {
            id
            accountId
            currencyId
            networkId
            address
            tag
            balance
            riskLevel
        }
    }
`;
