<script>
    import BaseInput from "@/public/components/common/uiKit/input/base/index.vue";
    import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
    import BusinessWalletNetworkSelect
        from "@/public/components/elements/business-walet/Select/businessWalletNetworkSelect.vue";
    import AddressBookSelect from "@/public/components/elements/business-walet/addressBookSelect/addressBookSelect.vue";
    import bchaddr from "bchaddrjs";
    import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
    import WebhooksFilterSelect from "@/public/components/elements/webhooks/Select/WebhooksFilterSelect.vue";
    import {getCurrencyInfoById, getNetworkInfoById, getNetworkName} from "@/public/filters";

    export default {
        name: "UpdateSettingsModal",
        components: {
            WebhooksFilterSelect,
            BaseButton,
            AddressBookSelect,
            BusinessWalletNetworkSelect,
            BaseInput,
            StoreModal: () => import(/* webpackChunkName: "/public/components/modals/StoreModal" */ '/public/components/modals/StoreModal'),
        },
        data () {
          return {
              networksValue: null,
              currencyValue: null,
              alias: '',
              address: '',
              type: '',
              amount: ''
          }
        },
        computed: {
            ...mapState('app', ['networks', 'currencies', 'isLoading']),
            ...mapState('addressBook', {
                listAddress: state => state.listCurrenciesAddress,
            }),
            ...mapState('app', {
                modal: state => state.modal
            }),
            ...mapState('collectingProfits', {
                setting: state => state.setting,
                minimumAmount: state => state.minimumAmount
            }),
            ...mapGetters('app', [
                'isOpenModal',
            ]),
            isOpen() {
                const {
                    $options: {
                        name,
                    },
                    isOpenModal,
                } = this;

                return isOpenModal(name);
            },
            title() {
                return this.$t('profits.update-title');
            },
            payload () {
                if (!this.modal.payload) return {
                    networksValue: null,
                    currencyValue: null,
                    alias: '',
                    address: '',
                    type: '',
                    amount: ''
                }

                return this.modal.payload
            },
            disabledCreate () {
              return this.networksValue && this.address && !this.addressValid && Number(this.amount) >= Number(this.minimumAmount)
            },
            filteredCurrencies () {
                const {
                    currencies,
                } = this;
                let sortedCurrencies = currencies.filter(({isFiat}) => !isFiat);

                if (this.networksValue) {
                    sortedCurrencies = sortedCurrencies.filter((i) => {
                        return i.networks.find(({id}) => id === this.networksValue.id);
                    });
                    return sortedCurrencies;
                }

                return sortedCurrencies;
            },
            filteredNetworks() {
                const {
                    currencies,
                } = this;

              const networks = {};

              for(const currency of currencies) {
                if(!currency.allowBusiness) {
                  continue;
                }

                for(const network of currency.networks) {
                  if(!network.allowDeposit) {
                    continue;
                  }

                  networks[network.id] = network;
                }
              }

              return Object.values(networks);
            },
            currencySelectPlaceholder () {
                return this.$t('orders.select_currencies')
            },
            networkSelectPlaceholder () {
                return this.$t('orders.select_network')
            },
            enter_address() {
                return this.$t('create-withdrawal-request-modal.enter_address');
            },
            wallets () {
              return [
                  {
                      label: `Pay-in`,
                      value: 'PAY_IN',
                  },
                  {
                      label: this.$t('profits.personal-wallet'),
                      value: 'PERSONAL',
                  },
              ]
            },
            isRequestProcessed () {
                return this.setting.id
            },
            amountError () {
                if (this.amount < Number(this.minimumAmount) && this.amount !== '') {
                    return `${ this.$t('profits.error-amount') }: ${this.minimumAmount}`
                }

                return ''
            },
            addressValid() {
                if (!this.address || !this.networksValue) {
                    return ''
                }
                if (this.address) {
                    if (this.currencyValue?.currency === "BCH") {
                        return bchaddr.isValidAddress(this.address);
                    }
                }
                const isValid = this.networksValue && this.networksValue.addressRegex && new RegExp(this.networksValue.addressRegex).test(this.address);
                return !isValid ? this.$t('profits.error-invalid-address') : ''
            },
            // addressValid() {
            //     if (this.address) {
            //         if (this.balanceCurrency?.currency === "BCH") {
            //             return bchaddr.isValidAddress(this.address);
            //         }
            //     }
            //
            //     return this.balanceNetwork && this.balanceNetwork.addressRegex && new RegExp(this.balanceNetwork.addressRegex).test(this.address);
            // },
        },
        mounted () {
            this.getAddressBook({
                route: this.$route,
                router: this.$router,
                page: 1,
                showUrlFilters: false,
                networks: [],
            });
        },
        watch: {
            payload(status) {
                let {
                    networksValue,
                    currencyValue,
                    alias,
                    address,
                    type,
                    amount,
                    payload,
                } = this
                if (this.modal.opened !== this.$options.name || !status) {
                    return;
                }
                this.$nextTick(() => {
                    this.networksValue = getNetworkInfoById(payload.networkId)
                    this.currencyValue = getCurrencyInfoById(payload.currencyId)
                    this.alias = payload.alias
                    this.address = payload.addressTo.toString()
                    this.type = this.wallets.find(({ value }) => value === payload.type)
                    this.amount = payload.amount
                });
            },
            networksValue(n, o) {
                let networkTicker;
                // const network = this.addressesList.find(item => item.id === this.addressId);
                if (!this.networksValue) return

                networkTicker = getNetworkName(this.networksValue?.id);

                this.getAddressBook({
                    route: this.$route,
                    router: this.$router,
                    page: 1,
                    showUrlFilters: false,
                    networks: [`${networkTicker}`],
                });
            },
        },
        methods: {
            ...mapActions('app', [
                'openModal',
                'closeModal',
                'request',
            ]),
            ...mapActions('addressBook', [
                'getAddressBook',
                'networkAddressBook',
                'ChangeAddressBook',
            ]),
            ...mapActions('collectingProfits', ['createSetting']),
            ...mapMutations('collectingProfits', ['setSetting']),

            handleCancelCreate() {
                const {
                    closeModal,
                } = this;
                closeModal();
            },
            handleSelectAddress(value) {
                if (value) this.address = this.listAddress.find(item => item.id === value.id).address;
            },
            handleSelectNetwork(value) {
                this.networksValue = value
                this.currencyValue = null;
            },
            handleSelectCurrency(value) {
                this.currencyValue = value;
            },
            handleUpdateSetting () {
                if (!this.disabledCreate) {
                    return
                }
                const payload = {
                    addressTo: this.address,
                    networkId: this.networksValue.id,
                    currencyId: this.currencyValue?.id ? this.currencyValue?.id : null,
                    amount: this.amount,
                    alias: this.alias,
                    type: this.type?.value ? this.type?.value : null,
                    id: this.payload.id,
                    actionName: 'updateCollectionSetting'
                };
                this.openModal({
                    name: 'TwoFaConfirmation',
                    payload
                });
            },
            handleSelectType (value) {
              this.type = value
            },
            clear () {
                this.address = ''
                this.alias = ''
                this.amount = ''
                this.type = null
                this.currencyValue = null
                this.networksValue = null
                this.setSetting({})
            },
            handleCloseModal () {
                this.clear()
                this.setSetting({})
                this.closeModal()
            },
        }
    }
</script>

<template>
<StoreModal name="UpdateSettingsModal" class="create-settings" @closeModal="handleCloseModal">
    <template v-if="!isRequestProcessed" #header>
        <div class="create-settings__header">
            <div class="title">
                {{ title }}
            </div>
            <!--            <div v-if="notCreated" class="subtitle">-->
            <!--                {{ subtitle }}-->
            <!--            </div>-->
        </div>
    </template>
    <template   #body>
        <template v-if="!isRequestProcessed">
            <div class="create-settings__content">
              <div class="business-modal__select-container">
                <base-input max-length="16" :label="$t('profits.alias')" v-model="alias" :placeholder="$t('profits.enter-alias')" />
              </div>
              <div class="business-modal__select-container">
                    <webhooks-filter-select
                        :value="type"
                        :list="wallets"
                        :label="$t('profits.wallet-from')"
                        :placeholder="$t('profits.select-wallet')"
                        uniq-key="value"
                        clearable
                        @input="handleSelectType"
                    />
                </div>
                <div class="business-modal__select-container">
                    <business-wallet-network-select
                        :value="networksValue"
                        :list="filteredNetworks"
                        :label="$t('orders.network')"
                        :placeholder="networkSelectPlaceholder"
                        uniq-key="id"
                        @input="handleSelectNetwork"
                    />
                </div>
                <div class="business-modal__select-container">
                    <business-wallet-network-select
                        v-model="currencyValue"
                        :list="filteredCurrencies"
                        :label="$t('business-wallet.currency')"
                        :placeholder="currencySelectPlaceholder"
                        uniq-key="id"
                        clearable
                    />
                </div>
                <base-input max-length="8" max="99999999" min="0" v-model.trim="amount" type="number" :label="$t('profits.amount-modal')" :error="amountError" :placeholder="'0'" />
                <span class="maxAmount"><span class="maxAmount__min">min</span>{{ minimumAmount }} USD</span>
                <div class="create-settings__select-address">
                    <base-input
                        v-model.trim="address"
                        :placeholder="enter_address"
                        :error="addressValid"
                        :label="$t('profits.address-to')" />

                    <div class="create-settings__address-book">
                        <address-book-select :list="listAddress"  with-search @input="handleSelectAddress" />
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div v-if="setting?.code === 0" class="create-settings__complete">
                <svg class="create-settings__complete-icon" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_11355_117487)">
                        <rect width="60" height="60" rx="10" fill="#5BB24D"/>
                        <path d="M36.3102 23.5605C36.9326 22.9382 37.9417 22.9382 38.5641 23.5605C39.1789 24.1753 39.1864 25.1674 38.5867 25.7914L30.1033 36.3957C30.0911 36.411 30.078 36.4256 30.0641 36.4395C29.4417 37.0619 28.4326 37.0619 27.8102 36.4395L22.1865 30.8158C21.5641 30.1934 21.5641 29.1842 22.1865 28.5618C22.8089 27.9395 23.818 27.9395 24.4404 28.5618L28.8892 33.0106L36.268 23.6082C36.2811 23.5915 36.2952 23.5756 36.3102 23.5605Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_11355_117487">
                            <rect width="60" height="60" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
                <div class="create-settings__complete-title">{{ $t('profits.success') }}</div>
                <div class="create-settings__complete-desc">{{ setting.alias }} {{ $t('profits.success-desc-updated') }}</div>
            </div>
            <div v-else class="create-settings__complete">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_11349_117300)">
                        <rect width="60" height="60" rx="10" fill="#B24D4D"/>
                        <path d="M24.8098 18.2197C24.9504 18.079 25.1412 18 25.3401 18H34.6599C34.8588 18 35.0496 18.079 35.1902 18.2197L41.7803 24.8098C41.921 24.9504 42 25.1412 42 25.3401V34.6599C42 34.8588 41.921 35.0496 41.7803 35.1902L35.1902 41.7803C35.0496 41.921 34.8588 42 34.6599 42H25.3401C25.1412 42 24.9504 41.921 24.8098 41.7803L18.2197 35.1902C18.079 35.0496 18 34.8588 18 34.6599V25.3401C18 25.1412 18.079 24.9504 18.2197 24.8098L24.8098 18.2197ZM25.6508 19.5L19.5 25.6508V34.3492L25.6508 40.5H34.3492L40.5 34.3492V25.6508L34.3492 19.5H25.6508Z" fill="white"/>
                        <path d="M24.9697 24.9697C25.2626 24.6768 25.7374 24.6768 26.0303 24.9697L30 28.9393L33.9697 24.9697C34.2626 24.6768 34.7374 24.6768 35.0303 24.9697C35.3232 25.2626 35.3232 25.7374 35.0303 26.0303L31.0607 30L35.0303 33.9697C35.3232 34.2626 35.3232 34.7374 35.0303 35.0303C34.7374 35.3232 34.2626 35.3232 33.9697 35.0303L30 31.0607L26.0303 35.0303C25.7374 35.3232 25.2626 35.3232 24.9697 35.0303C24.6768 34.7374 24.6768 34.2626 24.9697 33.9697L28.9393 30L24.9697 26.0303C24.6768 25.7374 24.6768 25.2626 24.9697 24.9697Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_11349_117300">
                            <rect width="60" height="60" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
                <div class="create-settings__complete-title">{{ $t('profits.error') }}</div>
                <div class="create-settings__complete-desc">{{ setting.code === 1187 ? `${$t('profits.error-conflict')}: ${ setting.alias ? setting.alias : setting.id }` : `${$t('profits.error-internal')} ${ setting.id }` }}</div>
            </div>
        </template>
    </template>
    <template #footer>
        <div v-if="!isRequestProcessed" class="create-settings__footer">
            <base-button type="secondary" :label="$t('profits.cancel')" @click="handleCloseModal" />
            <base-button :disabled="!disabledCreate" type="primary" :label="$t('profits.save-btn')" @click="handleUpdateSetting" />
        </div>
        <base-button v-else :label="$t('profits.close')" type="primary" @click="handleCloseModal" />
    </template>
</StoreModal>
</template>

<style scoped lang="scss">
.maxAmount {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    color: var(--new-front-primary-font-color);
    font-size: $h6;
    margin-top: 5px;
    &__min {
        margin-right: 5px;
        color: var(--new-front-secondary-font-color);
    }
}
.create-settings {
    &__footer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        @media (max-width: 450px) {
            display: flex;
            flex-direction: column-reverse;
            grid-template-columns: unset;
        }
    }

    &__select-address {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    &__address-book {
        position: relative;
        top: 8px;
    }
    &__content {
        padding: 0 $p30;
        @media (max-width: 450px) {
            padding: 0 $p10;
        }
    }
    &__header {
        text-align: center;
        .title {
            font-size: $h1;
            font-weight: 500;
            color: var(--new-front-primary-font-color);
            @media (max-width: 450px) {
                font-size: $h2;
            }
        }
    }
    &__complete {
        display: flex;
        align-items: center;
        flex-direction: column;
        &-icon {
            margin-bottom: $p30;
        }
        &-title {
            font-size: $h1;
            font-weight: 500;
            color: var(--new-front-primary-font-color);
            margin-bottom: $p20;
        }
        &-desc {
            font-size: $h4;
            color: var(--new-front-secondary-font-color);
            margin-bottom: $p10;
        }
    }
}
@media (max-width: 450px) {
    ::v-deep .main-modal-header {
        padding: 0 $p10;
    }
}
::v-deep .base-btn {
    width: 100% !important;
}
::v-deep .advanced-balances-select-wrapper .input-wrapper .options-list .list {
    max-height: 300px !important;
}
.create-settings__address-book {
    ::v-deep .advanced-balances-select-wrapper .input-wrapper .options-list {
        transform: translateY(-115%);
    }
}
::v-deep  .advanced-balances-select-wrapper .label {
    display: unset !important;
}
.business-modal__select-container {
  margin-bottom: 20px;
}
</style>
