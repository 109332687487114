<template>
  <div class="base-period-select">
    <p class="base-period-select__title">
      {{ label }}
    </p>
    <DatePicker
      v-model="model"
      class="base-period-select__date"
      type="date"
      :show-second="false"
      range
      confirm
      :confirm-text="$t('bridge.history.period_select_btn')"
      :lang="selectLang"
      :disabled-date="disabledRange"
      value-type="date"
      :placeholder="placeholder"
      @confirm="selectDates"
      @clear="clearDates"
    />
  </div>
</template>

<script>
import Moment from "moment";
import {mapActions, mapState} from 'vuex';

import DatePicker from "vue2-datepicker";

export default {
  name: 'AccountsFilterByDate',
  components: {
    DatePicker,
  },
  data() {
    return {
      dates: [],
    };
  },
  props: {
    label: {
      type: String,
      default: 'Period'
    },
    placeholder: {
      type: String,
      default: 'Period placeholder'
    },
    value: {
      type: Array,
      default: () => ([])
    },
  },
  computed: {
    ...mapState('user', [
      'user',
    ]),
    selectLang() {
      return this.user.lang;
    },
    model: {
      get({ value }) {
        return value
      },
      set(v) {
        if (!v[0]) {
          this.$emit('input', [])
        } else {
          this.$emit('input', v)
        }
      }
    }
  },
  methods: {
    disabledRange(date) {
      return date < new Date(2022, 0, 1) || date > new Date();
    },
    selectDates() {
      // const {
      //   $route,
      //   $router,
      //   dates,
      // } = this;
      //
      // const dateFrom = Moment(dates[0]).format();
      // const dateTo = Moment(dates[1])
      //     .add(23, 'hours')
      //     .add(59, 'minutes')
      //     .add(59, 'seconds')
      //     .format()
      // ;
    },
    clearDates() {
      // const {
      //   $route,
      //   $router,
      // } = this;
    },
  },
};
</script>

<style lang="scss" scoped>
.base-period-select {
  &__title {
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 16px;
    font-family: var(--font-family);
    color: var(--primary-font-color);
  }

  &__date::v-deep.mx-datepicker-range {
    width: 100%;
  }

  &__date ::v-deep .mx-input {
    height: 46px;

    background: var(--main-bg);
    box-shadow: inset 0 4px 20px var(--main-bg);
    border: 1px solid var(--main-bg);

    padding-left: 30px;

    border-radius: 12px;
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__date ::v-deep .mx-icon-clear {
    color: var(--secondary-font-color);

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__date ::v-deep .mx-icon-calendar {
    color: #8F949A;
  }
}

</style>
