import gql from 'graphql-tag';

export default gql`
    query ($organizationId: String!, $stage: [OrphanDepositStage!], $status: [OrphanDepositStatus!], $currencyIds: [String!], $networkIds: [String!], $txId: String, $limit: Int, $offset: Int) {
        orphanDeposits ( organizationId: $organizationId, stage: $stage, status: $status, currencyIds: $currencyIds, networkIds: $networkIds, txId: $txId, limit: $limit, offset: $offset) {
            id
            organizationId
            orderId
            stage
            status
            message
            currencyId
            networkId
            amount
            canWithdrawal
            inTransaction {
                addressType
                addressId
                address
                txId
                amount
                status
                createdAt
            }
            outTransaction  {
                address
                txId
                amount
                status
                withdrawalId
                createdAt
            }
            createdAt
        }
    }
`;
