export const updateAccountBalances = ({state, commit}, {accountId, addressId, balance}) => {
    const balances = state.balances;

    const newBalances = balances.map(b => {
        b = Object.assign({}, b);

        if(b.accountId === accountId && b.id === addressId) {
            b.balance = balance;
        }

        return b;
    });

    commit('accountBalances', newBalances);
};
