<template>
  <div class="api-keys-top-bar">
      <ApiKeysSearch
        class="api-keys-top-bar__search"
        @search="searchHandler"
      />
    <base-button
        class="api-keys-top-bar__button"
        size="small"
        type="primary"
        icon="icon-plus"
        :disabled="!isAddKeysAllowed"
        :label="$t('api_keys.add_api_key')"
        @click="openCreateNewApiKeysModal"
    >

        <template #prepend>
            <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
            >
                <path
                        d="M6 0C6.41421 0 6.75 0.335786 6.75 0.75V5.25H11.25C11.6642 5.25 12 5.58579 12 6C12 6.41421 11.6642 6.75 11.25 6.75H6.75V11.25C6.75 11.6642 6.41421 12 6 12C5.58579 12 5.25 11.6642 5.25 11.25V6.75H0.75C0.335786 6.75 0 6.41421 0 6C0 5.58579 0.335786 5.25 0.75 5.25H5.25V0.75C5.25 0.335786 5.58579 0 6 0Z"
                        fill="white"
                />
            </svg>
        </template>
    </base-button>
      <filter-mobile :is-filtered="!!search" class="filter" @reset="searchHandler('')">
          <ApiKeysSearch
            :search="search"
            @search="searchHandler"
            style="width: 100%"
          />
      </filter-mobile>
  </div>
</template>

<script>

    import {mapActions, mapGetters, mapState} from 'vuex';

import ApiKeysSearch from "./ApiKeysSearch";
import AtButton from 'at-ui/src/components/button';
    import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
    import FilterMobile from "@/public/components/elements/accounts/History/FilterToggler/index.vue";
    import _ from "lodash";

export default {
    name: 'ApiKeysTopBar',
    components: {
        FilterMobile,
        BaseButton,
        ApiKeysSearch,
        AtButton,
    },
    data() {
      return {
          search: ''
      }
    },
  computed: {
    ...mapState('user', {
      userOtpEnabled: state => state.user.otpToken,
    }),
      ...mapGetters('organizations', ['userRoleRules']),
      isEmptyFilters({ search }) {
        return !search
      },
      isAddKeysAllowed () {
          return this.userRoleRules['api_keys'].entities.manage_api_keys
      },
  },
  watch: {
    isEmptyFilters: {
      handler(v) {
        this.$nextTick(() => {
          this.$emit('is-empty-filters', v)
        })
      },
      immediate: true
    },
  },
  methods: {
      ...mapActions('app', [
          'openModal',
      ]),
      openCreateNewApiKeysModal() {
          if (!this.isAddKeysAllowed) {
              return
          }
        this.$emit('openCreateModal')
      },
      searchHandler(searchText) {
          this.search = searchText
          this.$emit('searchApiKeys', searchText);
      },
  },
};

</script>
<style lang="css" scoped>
.api-keys-top-bar__button ::v-deep .at-btn__icon, .at-btn__loading {

  font-size: 24px;

}

.api-keys-top-bar__button ::v-deep .at-btn__text {


  font-family: $Gilroy;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  margin: 0;
  text-align: center;
}
</style>
<style lang="scss" scoped>

.api-keys-top-bar {
  display: grid;
  grid-template-columns: 1fr 221px;
  grid-gap: 10px;

  &__button {
    min-height: 46px;
    width: 100%;
    max-width: 221px;
    background: #0059F7;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 18px;
    transition: all 0.3s ease-in;
    border: none;

    &:hover {
      background: var(--blue-400);
    }
  }

  &__search {
    width: 100%;
  }
}
.filter {
  display: none;
}
@media screen and (max-width: 950px) {
  .filter {
    display: unset;
    &::v-deep {
      .filter-toggler__content {
        width: 280px;
        gap: 10px;
      }
    }
  }
  .api-keys-top-bar__search {
    display: none;
  }
  .api-keys-top-bar {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  //.api-keys-top-bar {
  //  &__button {
  //    display: none;
  //  }
  //}
}
</style>
