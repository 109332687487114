<script>
  import BaseInput from "@/public/pages/integration-settings/components/Input/index.vue";
  import WidgetsTopHeaderInput from "@/public/components/elements/widgets-manage/WidgetsTopHeaderInput.vue";
  import {mapMutations, mapState} from "vuex";
  import {STEP_START_PAGE} from "@/public/pages/widgets/constants";

  export default {
    name: "widgetsManageTopHeader",
    STEP_START_PAGE,
    components: {WidgetsTopHeaderInput, BaseInput},
    computed: {
      ...mapState('widgetsManage', ['widget', 'currentStep']),
    },
    methods: {
      ...mapMutations('widgetsManage', ['setPrevStep', 'setAlias']),

    }
  }
</script>

<template>
<div class="topHeader">
  <div @click="setPrevStep()" v-if="currentStep !== $options.STEP_START_PAGE" class="topHeader__back">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.0781 3.58313C10.8191 3.45457 10.5072 3.47641 10.2701 3.6397L4.8257 7.3897C4.62131 7.53048 4.5 7.75779 4.5 8C4.5 8.24221 4.62131 8.46952 4.8257 8.6103L10.2701 12.3603C10.5072 12.5236 10.8191 12.5454 11.0781 12.4169C11.3372 12.2883 11.5 12.0309 11.5 11.75V4.25C11.5 3.96906 11.3372 3.71168 11.0781 3.58313Z" fill="var(--text-color-text-secondary)" stroke="var(--text-color-text-secondary)" stroke-linejoin="round"/>
    </svg>
    {{ $t('widgets.back') }}
  </div>
  <base-input @input="(value) => setAlias(value)" :value="widget.alias" :placeholder="$t('widgets.widgetAlias')" />
</div>
</template>

<style scoped lang="scss">
.topHeader{
  width: 100%;
  &__back {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    max-width: 72px;
    height: 28px;
    border-radius: 6px;
    background: var(--bg-background-table);
    color: var(--text-color-text-secondary);
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
    margin-bottom: 15px;
    cursor: pointer;
  }
}
::v-deep {
  .base-input__wrapper input {
    border-radius: 10px;
    border: 1px solid var(--border-border-input-primary-default, #464646);
    background: var(--bg-background-input-primary, #2A2A2A);
    padding: 0 15px 0 15px;
    color: var(--text-text-primary, #FFF);
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media (max-width: 850px) {
      font-size: 22px;
    }
  }
}
</style>
