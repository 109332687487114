import gql from 'graphql-tag';

export default gql`
    query ($organizationId: String!) {
         getNetworks (organizationId: $organizationId) {
            id
            name
            alias
            addressRegex
            txLink
            addressLink
            contractLink
        }
    }
`;
