<template>
  <div class="balances-list-header">
    <!--    <div-->
    <!--        class="balances-list-header__item balances-list-header__item_currency"-->
    <!--    >-->

    <!--      &lt;!&ndash;      <p class="balances-list-header__text-main">{{ $t('addresses.currency') }}</p>&ndash;&gt;-->
    <!--      <p class="balances-list-header__text-main balances-list-header__network-title">/{{ $t('addresses.network') }}</p>-->

    <!--    </div>-->
    <!--    <div class="balances-list-header__item balances-list-header__item_address">-->

    <!--      <p class="balances-list-header__text-main">{{ $t('addresses.address') }}</p>-->

    <!--    </div>-->
    <div
        class="balances-list-header__item balances-list-header__item_network"
    >
      <p class="balances-list-header__text-main">{{ $t('addresses.network') }}</p>

    </div>
    <div
        class="balances-list-header__item"
    >

      <p class="balances-list-header__text-main">{{ $t('addresses.balance') }}</p>

    </div>
    <div
        class="balances-list-header__item balances-list-header__item_withdraw"
    >
    </div>
  </div>
</template>

<script>


import Icon from "../Icon";

export default {
    name: 'BalancesListHeader',
    components: {
        Icon,
    },
};

</script>

<style lang="scss" scoped>

.balances-list-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding-right: 60px;
  padding-left: 60px;
  min-height: 46px;
  align-items: center;
  background: var(--main-page-line);
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);

  &__text-main {
    font-family: var(--font-family);
    font-size: var(--font-size-text-secondary);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__network-title {
    display: none;
  }
}

@media screen and (max-width: 850px) {
  .balances-list-header {
    grid-template-columns: repeat(3, 1fr);
    padding: 10px;

    &__item_currency {
      display: flex;
    }


    &__network-title {
      display: block;
    }
  }

}

//@media screen and (max-width: 567px) {
//  .balances-list-header {
//    padding-left: 15px;
//    padding-right: 15px;
//    grid-template-columns: repeat(2, 1fr) 24px;
//
//
//    &__item {
//      &_address {
//        display: none;
//      }
//    }
//
//  }
//}

</style>