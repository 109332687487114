import GetPersonalAddressesUsersQuery from "../../../../graphQl/queries/personalAddressGetPersons";

export const getPersons = async ({dispatch, commit, rootGetters}, payload) => {
  let resp;

  payload.organizationId = rootGetters['app/getSelectedOrganization'].id;

  try {
    resp = await dispatch('app/request', {
      type: 'query',
      gql: GetPersonalAddressesUsersQuery ,
      variables: payload,
    }, {root: true});
  } catch (error) {
    return error;
  }

  const { data } = resp || {}
  const { getPersonalUsers } = data || {}

  return getPersonalUsers || {}
};