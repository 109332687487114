import removeAddressBookMutation from "../../../../graphQl/mutations/removeAddressBook";

export const removeAddressBook = async ({dispatch, rootGetters}, payload) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    let removeAddress;

    try {
        removeAddress = await dispatch('app/request', {
            type: 'mutation',
            gql: removeAddressBookMutation,
            variables: {
                organizationId,
                ...payload,
            },
        }, {root: true});
    } catch (error) {
        return error;
    }

    if (removeAddress.message) {
        throw removeAddress.message;
    }

    if (removeAddress.errors) {
        throw removeAddress.errors;
    }

    return removeAddress;
};