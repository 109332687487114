export const ua = {
    'invoice': {
        'invoice': 'Рахунок №:',
        'sum-invoice': 'Сума рахунку:',
        'select-invoice': 'Виберіть валюту оплати',
        'coin-network': 'Валюта та мережа:',
        'rate': 'Курс:',
        'sum-payment': 'Сума оплати:',
        'button': 'Оплата',
        'search': 'Обшук',
    },
    'checkout': {
        'shop': 'Магазин',
        'payment': 'Оплата',
        'back_to_the_store': 'Повернутись до магазину',
        'to_make_a_payment': 'Для здійснення оплати надішліть «{orderCurrency}» в мережі «{orderAlias}» на вказану адресу',
        'payment_completed': 'Платіж виконано',
        'the_payment_is_invalid': 'Платіж недійсний',
        'payment_rejected': 'Платіж відхилений',
        'payment_pending': 'Платіж в обробці',
        'payment_init': 'Платіж в очікуванні',
        'lifetime_has_expired': 'Минув час життя платежу. Не надсилайте монети за вказаною адресою.',
        'contact_the_store_owner': 'Минув час життя платежу. Не надсилайте монети за вказаною адресою. Зв`яжіться з власником магазину для повернення монет або продовження оплати',
        'order': 'Ордер',
        'to_be_paid': 'До оплати',
        'received': 'Отримано:',
        'description': 'Опис:',
        'before_the_expiration_of_the_order': 'Термін дії платежу:',
        'network': 'Мережа:',
        'payment_address': 'Адреса оплати:',
        'payment_address_not_found': 'Адреса оплати не знайдено',
        'tag': 'Тег:',
        'contract_address': 'Адреса контракту:',
        'send_to_this_address_only': 'Надсилайте на цю адресу тільки',
        'make_sure_the_network_is_correct': 'Переконайтеся, що мережа вірна:',
        'network_is_correct': 'Переконайтеся, що мережа вірна:',
        'contract_address_matches': 'Переконайтеся, що адреса контракту збігається',
        'payment_page': 'Сторінка оплати',
        'the_address_was_copied': 'Адреса успішно скопійована',
        'the_tag_was_copied': 'Тег було скопійовано',
        'invoice_was_successfully_paid': 'Ордер був успішно сплачений',
        'the_invoice_was_paid': 'Ордер був сплачений на суму',
        'important_info': 'Важлива інформація',
        'the_contract_address_does_not_exist_on_this_network': 'Адреса контракту не існує у цій мережі',
        'error': 'Помилка',
        'time_hours': 'годин',
        'time_minute': 'хвилин',
        'time_second': 'секунд',
        'sellers_address': 'Адреса продавця верифікована',
        'stored_table': 'Тут буде історія вхідних транзакцій',
        'history_table': 'Історія вхідних транзакцій',
        'full_info': 'Показати повну інформацію',
        'table_date': 'Дата',
        'table_sum': 'Сума',
        'table_value': 'Валюта',
        'table_hash': 'tx_hash',
        'table_date_sum': 'Сума та дата',
        'payment_partially_paid': 'Частково сплачено',
        'paid_timer': 'Оплачено',
        'expired_timer': 'Термін дії платежу минув',
    },
    'new_checkout': {
        'details': {
            'title': 'Деталі',
            'order': 'Ордер',
            'date': 'Дата',
            'description': 'Опис',
        },
        'notification': 'Будь ласка, надішліть лише токени <b>{orderCurrency}</b> в мережу <b>{orderNetwork}</b> на цю адресу.',
        'payment_address': 'Адреса оплати',
        'verified_notification': "Адреса продавця перевірена",
        'paid_amount': "Оплачена сума",
        'timer_notifications': {
            'remaining_time': 'Часу до оплати',
            'completed': 'Оплата успішно завершена',
            'expired_with_invoice': 'Термін дії замовлення минув, але ви можете повернутися та створити нове замовлення.',
            'expired_without_invoice': 'Термін дії терміну експлуатації платежу закінчився',
            'dont_make_transactions': "Не робіть операції за цим замовленням!"
        },
        'statuses': {
            'not_paid': 'Не оплачений',
            'overpaid': 'Переплачений',
            'partially_paid': 'Частково оплачений',
            'fully_paid': 'Повністю оплачений',
        },
        'history': {
            'title': 'Історія платежів',
            'columns': {
                'date': 'Дата',
                'amount': 'Обсяг',
                'tx_hash': 'Tx_hash'
            },
            'empty_notification': 'Усі ваші транзакції, надіслані на вказану адресу, будуть доступні у цьому вікні.',
            'ok': 'Добре'
        },
        'orphan': {
            'title': 'Орфанна операція',
            'description': 'Одна з транзакцій цього замовлення містить неправильну мережу або валюту.',
            'contact': 'Зверніться до технічної підтримки з ID',
        },
        'time-is-over': 'Час вийшов',
        'payment-successful': 'Оплата успішна',
        'partial-desc': 'Сума сплачена частково. решту суми необхідно сплатити для успішної оплати.',
        'cancel': 'Скасувати',
        'back_to_shop': "Назад до магазину",
        'back_to_invoice': 'Повернутися до рахунку-фактури',
        'choose_another_currency': 'Виберіть іншу валюту',
        'you_can_pay': 'Ви зможете оплатити рахунок у кількох валютах!'
    },
    'new_invoice': {
        'details': {
            title: 'Деталі',
            invoice: 'Рахунок-фактура',
            date: 'Дата',
            description: 'Опис'
        },
        'amount': 'Обсяг',
        'btn_label': 'Продовжити',
        'btn_label_empty': 'Виберіть валюту',
        'cancel': 'Скасувати',
        'search': 'Пошук...',
        'back_to_shop': "Назад до магазину",
        'choose-network': 'Виберіть мережу',
        'status': 'Статус',
        'you-payed': 'Ви заплатили',
        'statuses': {
            "ERROR": 'ПОМИЛКА',
            "PENDING": 'В ОЧІКУВАННІ',
            "PROCESSED": 'ОБРОБЛЕНО',
            "REJECTED": 'ВІДХИЛЕНО',
            'OVERPAID': 'ПЕРЕПЛАЧЕНИЙ',
            "CREATED": 'СТВОРЕНО',
            'PARTIAL': 'ЧАСТКОВО ОПЛАЧЕНО',
            'INIT': 'INIT',
            'EXPIRED': 'ЧАС МИНУВ'
        },
        'active_order': 'Активне замовлення',
        'payment-successful': 'Оплата успішна'
    },
    'languages': {
        'select': 'Виберіть мову'
    },
    'preCheckout': {
        'errors': {
            'page_not_found': 'Сторінку не знайдено',
            'page_not_found_text': 'Ми не можемо знайти сторінку, яку ви шукаєте',
        }
    }
};
