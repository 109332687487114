import gql from 'graphql-tag';

export default gql`
query ($organizationId: String!, $currencyId: String, $networkId: String, $query: String, $settingId: String,) {
  getCollectingHistory (
    organizationId: $organizationId,
    currencyId: $currencyId,
    networkId: $networkId,
    settingId: $settingId,
    query: $query
  ) {
id
currencyId
networkId
addressFrom
addressTo
amount
savedFeeAmount
transactionsAmount
status
settingId
settingAlias
settingAmount
txId
createdAt
updatedAt
addressFromType
  }
}
`;
