<template>
<div>
  <preloader v-if="localLoading || isLoading"/>
<div v-else class="balanceHistory">
  <AtButton
      class="balanceHistory__back"
      type="primary"
      icon="icon-arrow-left"
      @click="goBack"
  >

    <span class="transaction__head-title" >{{ $t('transaction.return') }}</span>
  </AtButton>
  <h1 class="balanceHistory__title">{{ $t('buffer_balance_history.title') }}</h1>
  <Container :overflow="true" class="balanceHistory__topBar">
    <div class="balanceHistory__topBar-buttons">
      <btn-refresh
          class="balanceHistory__refresh-button"
          size="small"
          :icon="'icon-rotate-cw'"
          :is-loading="localLoading"
          @update="refresh"
      />
    </div>
    <div class="balanceHistory__topBar-selects">
      <div class="balanceHistory__topBar__select-container">
        <p class="balanceHistory__topBar__label">{{ $t('buffer_balance_history.merchant') }}</p>
        <AtSelect v-model="merch" clearable
                  :placeholder="$t('buffer_balance_history.select_merchant')"
                  @on-change="refresh"
        >
          <template v-if="referralEmails.length">
            <AtOption v-for="merchant in referralEmails" :key="merchant" :value="merchant">{{
                merchant
              }}
            </AtOption>
          </template>
          <AtOption :value="''" v-else>
            {{ $t('buffer_balance_history.empty') }}
          </AtOption>
        </AtSelect>
      </div>
      <div class="balanceHistory__topBar__select-container">
        <p class="balanceHistory__topBar__label">{{ $t('buffer_balance_history.type') }}</p>
        <AtSelect v-model="type" clearable
                  @on-change="refresh"
                  :placeholder="$t('buffer_balance_history.select_type')">
          <AtOption v-for="type in types" :key="type" :value="type">
            {{ type === 'in' ? $t('buffer_balance_history.accrual') : $t('buffer_balance_history.write-downs') }}
          </AtOption>
        </AtSelect>
      </div>
      <div class="balanceHistory__topBar__select-container">
        <p class="balanceHistory__topBar__label">{{ $t('buffer_balance_history.commission_type') }}</p>
        <AtSelect v-model="commissionType" clearable
                  @on-change="refresh"
                  :placeholder="$t('buffer_balance_history.select_commission_type')">
          <AtOption v-for="fee in feeTypes" :key="fee" :value="fee">
              {{ feeTariffType(fee) }}
          </AtOption>
        </AtSelect>
      </div>
      <div class="balanceHistory__topBar__select-container">
        <p class="balanceHistory__topBar__label">{{ $t('buffer_balance_history.period') }}</p>
        <DatePicker
            v-model="date"
            class="orders-filter-by-date__date"
            type="date"
            :show-second="false"
            range
            :lang="selectLang"
            confirm
            :confirm-text="select_dates"
            value-type="date"
            :disabled-date="disabledRange"
            @confirm="refresh"
            @clear="clearDates"
        />
      </div>
    </div>
  </Container>
  <Container :overflow="true" class="balanceHistory__table">
    <div class="balanceHistory__tableHeader">
      <div class="balanceHistory__tableHeader-label">{{ $t('buffer_balance_history.date') }}</div>
      <div class="balanceHistory__tableHeader-label">{{ $t('buffer_balance_history.merchant') }}</div>
      <div class="balanceHistory__tableHeader-label">{{ $t('buffer_balance_history.type') }}</div>
      <div class="balanceHistory__tableHeader-label">{{ $t('buffer_balance_history.commission_type') }}</div>
      <div class="balanceHistory__tableHeader-label">{{ $t('buffer_balance_history.percent') }}</div>
      <div class="balanceHistory__tableHeader-label">{{ $t('buffer_balance_history.sum') }}</div>
    </div>
    <div class="balanceHistory__tableList">
      <template v-if="filteredHistory.length">
        <div v-for="(item, index) in paginateSortedHistory" :key="index" class="balanceHistory__tableList-item">
          <div class="balanceHistory__tableList-item-container">
            <span class="balanceHistory__tableList-label">{{ item.date | getFormatDate }}</span>
            <span class="balanceHistory__tableList-label">{{ item.email }}</span>
            <span :class="['balanceHistory__tableList-label', item.type === 'in' ? 'greenColor' : 'redColor']">{{ item.type === 'in' ? $t('buffer_balance_history.accrual') : $t('buffer_balance_history.write-downs') }}</span>
            <span class="balanceHistory__tableList-label">{{ feeTariffType(item.advReasonType) }}</span>
            <span class="balanceHistory__tableList-label">{{ item.percent }}%</span>
            <span class="balanceHistory__tableList-label">{{ item.amount }}</span>
          </div>
          <div class="balanceHistory__tableList-item--mobile">
            <AtCollapse accordion>
              <AtCollapseItem>
                <div class="balanceHistory__tableList-item--mobile__header" slot="title">
                  <div>
                    <div class="balanceHistory__tableList-item--mobile-label">{{ item.email }}</div>
                    <div :class="['balanceHistory__tableList-item--mobile-sublabel', item.type === 'in' ? 'greenColor' : 'redColor']">{{ item.type === 'in' ? $t('buffer_balance_history.accrual') : $t('buffer_balance_history.write-downs') }}</div>
                  </div>
                  <div>
                    <div class="balanceHistory__tableList-item--mobile-label">{{ item.amount }}</div>
                    <div class="balanceHistory__tableList-item--mobile-sublabel text-right">{{ item.percent }}%</div>
                  </div>
                </div>
                <div class="balanceHistory__tableList-item--mobile__info">
                  <div class="balanceHistory__tableList-item--mobile__info-item">
                    <div class="balanceHistory__tableList-item--mobile-label secondary-font-color">{{ $t('buffer_balance_history.commission_type') }}</div>
                    <div class="balanceHistory__tableList-item--mobile-label">{{ feeTariffType(item.advReasonType) }}</div>
                  </div>
                </div>
                <div class="balanceHistory__tableList-item--mobile__info">
                  <div class="balanceHistory__tableList-item--mobile__info-item">
                    <div class="balanceHistory__tableList-item--mobile-label secondary-font-color">{{ $t('buffer_balance_history.date') }}</div>
                    <div class="balanceHistory__tableList-item--mobile-label">{{ item.date | getFormatDate }}</div>
                  </div>
                </div>
              </AtCollapseItem>
            </AtCollapse>
          </div>
        </div>
      </template>
      <div v-else class="balanceHistory__empty">
        {{ $t('buffer_balance_history.empty') }}
      </div>
    </div>
    <pagination :size="'small'"
                :total="totalCount"
                :page-size="pageSize"
                :current="page"
                @page-change="changePage"
    />
  </Container>
</div>
</div>
</template>

<script>
import Container from "../components/common/Container";
import Pagination from "../components/common/pagination";
import BtnRefresh from "../components/common/btn-refresh";
import getUserReferralsBufferBalanceResponse from "../graphQl/queries/getUserReferralsBufferBalanceResponse";
import {mapActions, mapState} from "vuex";
import Preloader from "/public/components/common/uiKit/spinner/index.vue";
import moment from "moment";
import Moment from "moment/moment";
import DatePicker from "vue2-datepicker";
export default {
    name: "BufferBalanceHistory",
    components: {
        Preloader,
        BtnRefresh,
        Container,
        Pagination,
        DatePicker
    },
    data() {
        return {
            history: [],
            totalCount: 0,
            localLoading: false,
            referralEmails: '',
            types: ['in', 'out'],
            merch: '',
            type: '',
            commissionType: '',
            date: [],
            page: 1,
            pageSize: 8,
        };
    },
    async created() {
        this.localLoading = true;
        await this.getHistory().then(() => {
            this.localLoading = false;
        });

    },
    computed: {
        ...mapState('app', [
            'isInit',
            'isLoading',
        ]),
        ...mapState('user', [
            'user',
        ]),
        feeTypes () {
            const feeTariffType = [
                'ORDER_DEPOSIT_FEE',
                'WALLET_WITHDRAWAL_FEE',
                'PAYOUT_WITHDRAWAL_FEE',
                'WALLET_DEPOSIT_FEE',
                'PAYOUT_DEPOSIT_FEE',
                'EXCHANGE_INTERNAL_FEE',
                'EXCHANGE_AUTO_FEE',
                'BRIDGE_INTERNAL_FEE',
                'BRIDGE_EXTERNAL_FEE',
                'REFUND',
                'REWARD_TRANSFER',
                'RECURRENT_DEPOSIT_FEE',
                'RECURRENT_WITHDRAWAL_FEE',
                'KYT_TRANSACTION_FEE',
                'KYT_WITHDRAWAL_ADDRESS_FEE',
                'EXCHANGE_AUTO',
            ];
            return feeTariffType;
        },
        selectLang() {
            return this.user.lang;
        },
        select_dates() {
            return this.$t('orders.select_dates');
        },
        merchants () {
            const merchantsArr = this.history.map(({ email }) => email);
            return [ ...new Set(merchantsArr) ];
        },
        paginateSortedHistory() {
            return this.filteredHistory;
        },
        filteredHistory () {
            // this.history.filter(({email, type, date, advReasonType}) => {
            //   let result = true;
            //   if (this.merch) {
            //     result = email === this.merch;
            //   }
            //   if (this.type) {
            //     result = type === this.type;
            //   }
            //   if (this.commissionType) {
            //     result = advReasonType === this.commissionType;
            //   }
            //   if (this.date.length === 2) {
            //     const dateFrom = Moment(this.date[0]).format();
            //     const dateTo = Moment(this.date[1])
            //         .add(23, 'hours')
            //         .add(59, 'minutes')
            //         .add(59, 'seconds')
            //         .format();
            //     result = moment(date).isBetween(dateFrom, dateTo);
            //   }
            //   return result;
            // });
            return this.history;
        },
    },
    methods: {
        ...mapActions('app', [
            'openModal',
            'request',
        ]),
        async changePage (page) {
            this.localLoading = true;
            await this.getHistory(page).then(() => {
                this.localLoading = false;
            });
        },
        feeTariffType(tariff) {
            const feeTariffType = {
                'ORDER_DEPOSIT_FEE': 'referral-commission-type.deposit_by_order_fee',
                'WALLET_WITHDRAWAL_FEE': "referral-commission-type.wallet_withdrawal_fee",
                'PAYOUT_WITHDRAWAL_FEE': "referral-commission-type.payout_withdrawal_fee",
                'WALLET_DEPOSIT_FEE': "referral-commission-type.deposit_by_wallet_fee",
                'PAYOUT_DEPOSIT_FEE': "referral-commission-type.payout_deposit_fee",
                'BRIDGE_EXTERNAL_FEE': 'referral-commission-type.bridge_external_fee',
                'BRIDGE_INTERNAL_FEE': 'referral-commission-type.bridge_internal_fee',
                'EXCHANGE_INTERNAL_FEE': "referral-commission-type.exchange_internal_fee",
                'EXCHANGE_AUTO_FEE': "referral-commission-type.exchange_auto_fee",
                'REFUND': 'referral-commission-type.refund',
                'REWARD_TRANSFER': 'referral-commission-type.reward_transfer',
                'RECURRENT_DEPOSIT_FEE': 'referral-commission-type.recurrent_deposit_fee',
                'RECURRENT_WITHDRAWAL_FEE': 'referral-commission-type.recurrent_withdrawal_fee',
                'KYT_TRANSACTION_FEE': 'advance_balances.kyt-transaction-fee',
                'KYT_WITHDRAWAL_ADDRESS_FEE': 'advance_balances.kyt-withdrawal-address-fee',
                'EXCHANGE_AUTO': "advance_balances.exchange-auto",
            };
            return this.$t(feeTariffType[tariff]) || '-';
        },
        clearDates () {
            this.date = [];
            this.refresh();
        },
        async refresh () {
            this.localLoading = true;
            await this.getHistory().then(() => {
                this.localLoading = false;
            });
        },
        disabledRange(date) {
            return date < new Date(2022, 0, 1) || date > new Date();
        },
        goBack() {
            if (window.history.length > 2) this.$router.go(-1);
            else this.$router.push({name: 'historyAddresses'});
        },
        async getHistory (page = 1) {
            const {
                merch,
                type,
                commissionType,
                date,
            } = this;

            this.page = page;
            const req = await this.request({
                type: 'query',
                gql: getUserReferralsBufferBalanceResponse,
                variables: {
                    limit: this.pageSize,
                    offset: page,
                    ...(merch) && {email: merch},
                    ...(commissionType) && {advReasonType: commissionType},
                    ...(type) && {type},
                    ...(date.length) && {date},
                },
            });
            this.history = req.data.getUserReferralsBufferBalance.result.history;
            this.totalCount = req.data.getUserReferralsBufferBalance.result.totalCount;
            this.referralEmails = req.data.getUserReferralsBufferBalance.result.referralEmails;
        },
    },
};
</script>

<style lang="scss" scoped>
.balanceHistory {
  padding: 20px 58px 0 58px;
  width: 100%;
  @media (max-width: 950px) {
    padding: 10px;
  }
  &__empty {
    line-height: var(--line-height);
    font-family: var(--font-family);
    font-size: var(--font-size-h3);
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    color: var(--primary-font-color);
  }
  &__topBar {
    padding: 30px;
    grid-gap: 30px;
    @media (max-width: 950px) {
      padding: 10px;
    }
    &-selects {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-gap: 20px;
      @media (max-width: 950px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
      }
    }
    &__select-container {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    &__label {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: var(--primary-font-color);
    }
    &-buttons {
      //padding-top: 24px;
      //margin-bottom: 30px;
      display: flex;
      justify-content: flex-end;
    }
  }
  &__back {
    background: #0059F7;
    border-radius: 6px;
    border: none;
    width: 100%;
    max-width: 125px;
    min-height: 40px;
    align-self: flex-start;
    transition: all 0.3s ease-in;

    &:hover {
      background: var(--blue-400);
    }

    ::v-deep .at-btn__text {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: var(--white-100);
    }

    ::v-deep .at-btn__icon {
      font-size: var(--font-size-text-main);
    }
  }
  &__title {
    margin-top: 30px;
    margin-bottom: var(--margin-h1-bottom);
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-h1);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }
  &__table {
    padding: 0;
    @media (max-width: 950px) {
      overflow: auto;
    }
  }
  &__tableHeader {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 2fr 1fr 1fr;
    background: var(--secondary-head-color);
    padding: 15px 30px;
    border-radius: 20px 20px 0 0;
    border-bottom: 1px solid var(--border-color);
    @media (max-width: 950px) {
      display: none;
    }
    &-label {
      font-size: 14px;
      color: var(--primary-font-color);
      &:first-child {
        text-align: left;
      }
    }
  }
  &__tableList {
    &-item {
      &-container {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr 2fr 1fr 1fr;
        border-bottom: 1px solid var(--border-color);
        padding: 15px 30px;
        @media (max-width: 950px) {
          display: none;
        }
      }
    }
    &-item--mobile {
      &-label {
        color: var(--primary-font-color);
        font-size: 14px;
      }
      &-sublabel {
        color: var(--secondary-font-color);
        font-size: 10px;
      }
      &__header {
        display: flex;
        justify-content: space-between;
      }
      &__info {
        padding: 0 15px;
        &-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid var(--border-color);
          padding: 10px 0;
        }
      }
      @media (min-width: 950px) {
        display: none;
      }
    }
    &-label {
      font-size: 16px;
      color: var(--primary-font-color);
      &:first-child {
        text-align: left;
      }
    }
  }
  &__download {
    &-csv {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 46px;
      height: 46px;
      background: var(--icon-csv);
      border-radius: 12px;
      cursor: pointer;
      margin-right: 32px;
      @media (max-width: 950px) {
        margin-right: 15px;
      }
    }
    &-xls {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 46px;
      height: 46px;
      background: var(--icon-exel);
      border-radius: 12px;
      cursor: pointer;
      @media (max-width: 950px) {
        margin-right: 0;
      }
    }
  }
  //&__refresh-button {
  //  margin-top: 42px;
  //}
  ::v-deep .at-select {
    background: var(--secondary-color);
    box-sizing: border-box;


  }

  ::v-deep .at-select__arrow {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  ::v-deep .at-select__clear {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  ::v-deep .at-select__selection {
    border-radius: 12px;
    min-height: 46px;
    display: flex;
    padding-left: 30px;
    align-items: center;
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    flex-wrap: wrap;
    border: 1px solid var(--border-color);
    background: var(--secondary-color);
    color: var(--primary-font-color);
  }

  &::v-deep .at-select__dropdown {
    border: 1px solid var(--border-color);
    background: var(--bg-select);
  }

  ::v-deep .at-select__option--selected {
    background: var(--hover-color-select);
  }

  ::v-deep .at-select__dropdown .at-select__option {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);

    padding: 10px;
    display: flex;
    align-items: center;

    &:hover {
      background: var(--hover-color-select);
    }
  }

  ::v-deep .at-select__placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }

  ::v-deep .at-select__selection {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    padding-left: 30px;
  }
  ::v-deep .at-collapse {
    background: var(--secondary-color);
    /* Gray 6 */

    border: 1px solid var(--border-color);
    //box-shadow: 0 8px 50px rgba(10, 17, 105, 0.08);
    //border-radius: 20px;
  }
  ::v-deep .at-collapse__icon {
    right: 18px;
    top: 18px;
    font-size: 20px;
    left: unset;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  ::v-deep .at-collapse__item--active > .at-collapse__header .at-collapse__icon {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  ::v-deep .at-collapse__header {
    background: var(--secondary-color);
    padding: 10px 65px 10px 10px;
  }
  ::v-deep .at-collapse__content  {
    padding: 0;
    border-top: 1px solid var(--border-color);
    background: var(--secondary-color);
  }
  ::v-deep.mx-datepicker-range {
    width: 100%;
  }

   ::v-deep .mx-input {
    height: 46px;
    background: var(--secondary-color);
    border: 1px solid var(--border-color);
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

   ::v-deep .mx-icon-clear svg {
    width: 24px;
    height: 24px;
  }

   ::v-deep .mx-icon-calendar {
    color: #8F949A;
  }
}
.df {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.secondary-font-color {
  color: var(--secondary-font-color);
}
.redColor {
  color: #ED6A5E;
}
.greenColor {
  color:#27AE60;
}
.text-right {
  text-align: right;
}
::v-deep .mx-datepicker-range {
  width: 100% !important;
}
</style>
