export const exampleMixin = {
    data () {
        return {
            requestInfo: {
                status: null,
                statusText: null,
                requestData: null,
                publicKey: null,
                signature: null,
            },
        };
    },
    methods: {
        setRequestInfo (response) {
            const {
                status,
                statusText,
                config: {
                    data,
                    headers,
                },
            } = response;

            this.requestInfo.requestData = JSON.parse(data);
            this.requestInfo.publicKey = headers['x-api-public-key'];
            this.requestInfo.signature = headers['x-api-signature'];
            this.requestInfo.status = status;
            this.requestInfo.statusText = statusText;
        },
    },
};