import gql from 'graphql-tag';

export default gql`
    mutation($organizationId: String!, $network: String!, $accountId: String!, $currency: String!) {
        accountDepositAddress(organizationId: $organizationId, accountId: $accountId, network: $network, currency: $currency) {
            code
            result {
                currencyId
                networkId
                address
                tag
                from
                until  
            }
        }
    }
`;
