<template>
  <div class="requests-sort-by-status">
    <p class="requests-sort-by-status__title">
      {{ $t('withdrawal.status') }}
    </p>
    <div class="requests-sort-by-status__select-wrapper">
      <AtSelect
          class="requests-sort-by-status__select"
          :placeholder="select_the_status"

          clearable
          :value="isActiveStatus"
          @on-change="selectStatus"
      >
        <AtOption
            v-for="(status, key) in $options.statuses"
            :key="key"
            :value="status.value"
            :label="$t(status.title)"
        />
      </AtSelect>
      <p class="requests-sort-by-status__status">{{ selectedStatus }}</p>
    </div>
  </div>
</template>

<script>

import {mapState, mapMutations, mapActions} from 'vuex';

import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';

export default {
    name: 'RequestsSortByStatus',
    statuses: [
        {
            value: 'init',
            title: 'withdrawal.in_processing',
        },
        {
            value: 'processed',
            title: 'withdrawal.completed',
        },
        {
            value: 'error',
            title: 'withdrawal.error',
        },
        {
            value: 'rejected',
            title: 'withdrawal.rejected',
        },
        {
            value: 'pending',
            title: 'withdrawal.pending',
        },
    ],
    components: {
        AtSelect,
        AtOption,
    },
    computed: {
        ...mapState('withdrawalRequests', {
            isActiveStatus: state => state.filters.status[0],
        }),
        selectedStatus() {
            const status = this.$options.statuses.find((status) => {
                return status.value === this.isActiveStatus;
            });
            return this.$t(status?.title);
        },
        select_the_status() {
            return this.$t('withdrawal.select_the_status');
        },
    },
    beforeMount() {
        this.clearFilters();
        this.setStatusFromUrl();
    },
    methods: {
        ...mapMutations('withdrawalRequests', [
            'filtersStatus',
            'clearFilters',
        ]),
        ...mapActions('withdrawalRequests', [
            'fetchWithdrawalRequests',
        ]),
        setStatusFromUrl() {
            const {
                $route: {query},
                filtersStatus,
            } = this;
            const statusFromUrl = query.status;

            if (!statusFromUrl) {
                return false;
            }

            filtersStatus(statusFromUrl.split(','));
        },
        selectStatus(values) {
            const {
                $route,
                $router,
                filtersStatus,
                fetchWithdrawalRequests,
            } = this;
            const val = values ? [values] : [];
            filtersStatus(val);
            fetchWithdrawalRequests({
                route: $route,
                router: $router,
                excludeParams: ['page'],
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.requests-sort-by-status {
  &__title {
    margin-bottom: 8px;
    font-size: var(--font-size-text-main);
    font-family: var(--font-family);
    line-height: var(--line-height);
    color: var(--black-200);
  }


  &::v-deep .at-select {
    background: #FFFFFF;
    box-sizing: border-box;
  }

  ::v-deep .at-select__arrow {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  ::v-deep .at-select__clear {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  &::v-deep .at-select__selection {
    border: 1px solid #F2F2F2;
    border-radius: 12px;
    min-height: 46px;
    display: flex;
    padding-left: 30px;
    align-items: center;
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    flex-wrap: wrap;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  ::v-deep .at-select__dropdown .at-select__option {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

  ::v-deep .at-select__placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }

  ::v-deep .at-select__selection {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

  ::v-deep .at-select__selected {
    display: none;
  }

  &__select-wrapper {
    position: relative;
  }

  &__status {
    position: absolute;
    top: 12px;
    left: 30px;
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

}

</style>