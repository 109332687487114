<script>
  import WidgetsRadioBtn from "@/public/components/elements/widgets-manage/WidgetsRadioBtn.vue";
  import {mapMutations, mapState} from "vuex";
  import {
    PAYIN_CRYPTO_TYPE_INVOICE,
    PAYIN_CRYPTO_TYPE_PERSONAL,
    STEP_PAY_SELECT,
    STEP_START_PAGE
  } from "@/public/pages/widgets/constants";
  import WidgetsConstructorItem from "@/public/components/elements/widgets-manage/settings/WidgetsConstructorItem.vue";
  import WidgetsMultiCurrencySelect
    from "@/public/components/elements/widgets-manage/settings/WidgetMultiWalletSelect.vue";
  import BaseInput from "@/public/pages/integration-settings/components/Input/index.vue";
  import validUrl from "valid-url";

  export default {
    name: "WidgetsManageChooseForm",
    components: {BaseInput, WidgetsMultiCurrencySelect, WidgetsConstructorItem, WidgetsRadioBtn},
    STEP_START_PAGE,
    PAYIN_CRYPTO_TYPE_PERSONAL,
    PAYIN_CRYPTO_TYPE_INVOICE,
    STEP_PAY_SELECT,
    computed: {
      ...mapState('widgetsManage', ['widget', 'currentStep']),
      isStartPage () {
        return this.widget.showStartPage
      },
      isPersonalCryptoType () {
        return this.widget.payinCryptoType === PAYIN_CRYPTO_TYPE_PERSONAL
      },
      isPayoutEnabled () {
        return this.widget.payoutCrypto
      },
      isSiteUrlValid() {
        if (this.widget.siteURL === '') { return false; }
        return !validUrl.isHttpUri(this.widget.siteURL, true);
      },
    },
    methods: {
      ...mapMutations('widgetsManage', ['setStartPage', 'setPayinCryptoType', 'setPayoutState', 'setSiteURL']),
      handleChooseStartPage (payload) {
        this.setStartPage(payload)
      },
      handleCryptoType (type) {
        if (type === this.widget.payinCryptoType) {
          this.setPayinCryptoType('')
          return
        }
        this.setPayinCryptoType(type)
      },
      handleSiteUrl (url) {
        this.setSiteURL(url)
      }
    }
  }
</script>

<template>
<div class="choose-wrapper">
  <template v-if="currentStep === $options.STEP_START_PAGE">
    <div class="choose-page">
      <div>
        <div class="title">
          {{ $t('widgets.yourSiteUrl') }}
        </div>
        <div class="theme-wrapper">
          <div class="choose-items" >
            <WidgetsConstructorItem
                :is-required="true"
                :label="$t('widgets.siteUrl')"
                :tooltip-title="$t('widgets.siteUrl')"
                :tooltip-text="$t('widgets.tooltip.siteUrl')"
            >
              <base-input @input="handleSiteUrl" :error="isSiteUrlValid" :value="widget.siteURL" :placeholder="$t('widgets.enterSiteUrl')" />
            </WidgetsConstructorItem>
          </div>
        </div>
      </div>
      <div>
        <div class="title">
          {{ $t('widgets.startPage') }} <span class="secondary-text">{{ $t('widgets.oneOption') }}</span>
        </div>
        <div class="choose-items" >
          <div :class="['choose-item', { active: isStartPage }]" @click="handleChooseStartPage(true)">
            <WidgetsRadioBtn :active="isStartPage" />
            <div>
              <div class="choose-item__title">{{ $t('widgets.startPage') }}</div>
              <div class="choose-item__desc">{{ $t('widgets.startPageDesc') }}</div>
            </div>
          </div>
          <div :class="['choose-item', { active: !isStartPage }]" @click="handleChooseStartPage(false)">
            <WidgetsRadioBtn :active="!isStartPage" />
            <div>
              <div class="choose-item__title">{{ $t('widgets.directLink') }}</div>
              <div class="choose-item__desc">{{ $t('widgets.directLinkDesc') }}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </template>
  <template v-if="currentStep === $options.STEP_PAY_SELECT">
    <div class="choose-page">
      <div>
        <div class="title">
          {{ $t('widgets.payin') }} <span class="secondary-text">{{ $t('widgets.oneOption') }}</span>
        </div>
        <div class="choose-items" >
          <div :class="['choose-item', { active: widget.payinCryptoType === $options.PAYIN_CRYPTO_TYPE_PERSONAL && widget.payinCrypto }]" @click="handleCryptoType($options.PAYIN_CRYPTO_TYPE_PERSONAL)">
            <WidgetsRadioBtn :active="widget.payinCryptoType === $options.PAYIN_CRYPTO_TYPE_PERSONAL && widget.payinCrypto" />
            <div>
              <div class="choose-item__title">{{ $t('widgets.personal') }}</div>
              <div class="choose-item__desc">{{ $t('widgets.personalDesc') }}</div>
            </div>
          </div>
          <div :class="['choose-item', { active: widget.payinCryptoType === $options.PAYIN_CRYPTO_TYPE_INVOICE && widget.payinCrypto}]" @click="handleCryptoType($options.PAYIN_CRYPTO_TYPE_INVOICE)">
            <WidgetsRadioBtn :active="widget.payinCryptoType === $options.PAYIN_CRYPTO_TYPE_INVOICE && widget.payinCrypto" />
            <div>
              <div class="choose-item__title">{{ $t('widgets.invoices') }}</div>
              <div class="choose-item__desc">{{ $t('widgets.invoicesDesc') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="title">
          {{ $t('widgets.payout') }}
        </div>
        <div class="choose-items" >
          <div :class="['choose-item', { active: isPayoutEnabled }]" @click="setPayoutState()">
            <WidgetsRadioBtn :active="isPayoutEnabled" />
            <div>
              <div class="choose-item__title">{{ $t('widgets.payout') }}</div>
              <div class="choose-item__desc">{{ $t('widgets.payoutDesc') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</div>
</template>

<style scoped lang="scss">
.choose-wrapper {
  padding: 30px;
  margin-bottom: 15px;
  @media (max-width: 850px) {
    padding: 20px;
  }
}
.choose-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.choose-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.choose-item {
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 8px;
  border-radius: 10px;
  border: 1px solid var(--border-border-input-primary-default, #E0E2E7);
  //background: var(--bg-background-input-primary, #FBFBFB);
  padding: 13px 15px;
  cursor: pointer;
  &__title {
    color: var(--text-text-primary);
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
    line-height: 1.2;
    @media (max-width: 850px) {
      font-size: 16px;
    }
  }
  &__desc {
    color: var(--text-color-text-secondary, #8B81A0);
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    @media (max-width: 850px) {
      font-size: 13px;
    }
  }
  &.active {
    border: 1.5px solid var(--border-border-input-primary-active, #744DB2);
  }
}
.title {
  color: var(--text-text-primary, #FFF);
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 15px;
  @media (max-width: 850px) {
    font-size: 18px;
  }
}
.secondary-text {
  color: var(--text-color-text-secondary, #7E7E7E);
  font-size: 16px;
  font-weight: 500;
  @media (max-width: 850px) {
    font-size: 13px;
  }
}

.theme {
  &-wrapper {
    border-radius: 10px;
    border: 1px solid var(--border-border-input-primary-default, #464646);
    background: var(--bg-background-input-primary, #2A2A2A);
    padding: 15px;
  }
}
</style>
