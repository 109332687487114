import getRegistryInfo from "../../../../graphQl/mutations/getRegistryInfo";

export const fetchRegistryInfoAction = async ({dispatch, commit, rootState}, payload) => {

    let orderData;

    try {
        // commit('app/toggleLoading', null, {root: true});
        orderData = await dispatch('app/request', {
            type: 'query',
            gql: getRegistryInfo,
            variables: payload,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        // commit('app/toggleLoading', null, {root: true});
    }

    if (orderData.message) {
        throw orderData.message;
    }

    if (orderData.errors) {
        throw orderData.errors;
    }

    const {
        data: {fetchRegistryInfo: {code, result}},
    } = orderData;


    commit('setRegistriesItems', {registryId: payload.registryId, result});
    return orderData;
};