<template>
  <div>
    <base-select
      v-model="innerModel"
      v-bind="$attrs"
      :placeholder="$t('bridge.address_select.placeholder')"
      class="address-select"
      :list="currentList"
      :can-be-closed="walletIsSelected"
      @closed="selectedWallet = undefined"
      :sorting="walletIsSelected ? sorting : undefined"
      gap
    >
      <template #selected="item">
        <div class="selected-item">
          <div class="address-select-selected">
            <currency-network-icon
                :currency="getCurrencyTicker(item.currencyId)"
                :network="getNetworkSystemName(item.networkId).toUpperCase()"
                class="address-select-option-item__currency-icon"
            />
            {{ getPrettyAddress(item.address) }} {{ item.alias }} | {{ getCurrencyTicker(item.currencyId) }} <span class="secondary-text"> {{ getNetworkShortNameByNetworkName(getNetworkSystemName(item.networkId)) }} </span>
          </div>
          <div>
            {{ prettyNumber(Number(item.balance)) }} {{ getCurrencyTicker(item.currencyId) }}
          </div>
        </div>
      </template>

      <template #options-prepend>
        <div class="options-prepend">
          <span v-if="!walletIsSelected" class="choose-wallet">
            {{ $t('bridge.address_select.options_list.title.choose_wallet') }}
          </span>
          <div
              v-else
              class="back-btn"
              @click.stop="selectedWallet = undefined"
          >
            <svg
                width="8"
                height="10"
                viewBox="0 0 8 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M7.07812 0.583127C6.81906 0.454574 6.50723 0.476409 6.27015 0.639703L0.825704 4.3897C0.621307 4.53048 0.5 4.75779 0.5 5C0.5 5.24221 0.621307 5.46952 0.825704 5.6103L6.27015 9.3603C6.50723 9.52359 6.81906 9.54543 7.07812 9.41687C7.33717 9.28832 7.5 9.03094 7.5 8.75V1.25C7.5 0.969062 7.33717 0.71168 7.07812 0.583127Z"
                  fill="#6750A4"
                  stroke="#6750A4"
                  stroke-linejoin="round"
              />
            </svg>
            <span>
            {{ $t('bridge.address_select.options_list.back_btn.label') }}
          </span>
          </div>
        </div>
      </template>

      <template #info="item">
        <div class="address-select-option-item">
          <template v-if="!walletIsSelected">
            {{ item.label }}
          </template>
          <template v-else>
            <currency-network-icon
                :currency="getCurrencyTicker(item.currencyId)"
                :network="getNetworkSystemName(item.networkId).toUpperCase()"
                class="address-select-option-item__currency-icon"
            />
            {{ getPrettyAddress(item.address) }} {{ item.alias }} | {{ getCurrencyTicker(item.currencyId) }} <span class="secondary-text"> {{ getNetworkShortNameByNetworkName(getNetworkSystemName(item.networkId)) }} </span>
          </template>
        </div>
      </template>
      <template #action="item">
        <div
            v-if="item"
            class="address-select-option-action"
        >
<!--          <template v-if="!walletIsSelected">-->
<!--            {{ item.balance || 0 }}-->
<!--          </template>-->
          <template v-if="walletIsSelected">
            {{ prettyNumber(Number(item.balance)) }} {{ getCurrencyTicker(item.currencyId) }}
          </template>
        </div>
      </template>
      <template #empty>
        <div v-if="!walletIsSelected" class="no-wallets">
          <div class="label">
            {{ $t('bridge.address_select.options_list.empty.wallets') }}
          </div>
        </div>
        <div v-else class="no-addresses">
          <div class="label">
            {{ $t('bridge.address_select.options_list.empty.addresses') }}
          </div>
        </div>
      </template>
    </base-select>
  </div>
</template>

<script>
  import {
    getCurrencyTicker, getNetworkName, getNetworkShortNameByNetworkName, getNetworkSystemName,
    getPrettyAddress
  } from "/public/filters";
import {mapActions} from "vuex";
  import CurrencyNetworkIcon from "@/public/components/elements/business-walet/Select/CurrencyNetworkIcon.vue";

export default {
  name: "DashboardSwapAddressSelect",
  components: {
    CurrencyNetworkIcon,
    BaseButton: () => import(/* webpackChunkName: "/public/components/common/uiKit/button/base/bg-secondary" */ '/public/components/common/uiKit/button/base/bg-secondary'),
    BaseSelect: () => import(/* webpackChunkName: "public/components/common/uiKit/selects/base" */ '/public/components/common/uiKit/selects/base'),
    AddAddressModal: () => import(/* webpackChunkName: "public/components/modals/crossChain/addAddressModal" */ '/public/components/modals/crossChain/addAddressModal'),
  },
  data() {
    return {
      selectedWallet: undefined
    }
  },
  props: {
    accountId: {
      type: String,
      default: undefined
    },
    currencyAndNetworkFrom: {
      type: Object,
      default: () => ({})
    },
    walletsList: {
      type: Array,
      default: () => ([])
    },
    balancesSummary: {
      type: Object,
      default: () => ({})
    },
    value:  {
      type: Object,
      default: undefined
    },
    hideNullishBalances: {
      type: Boolean,
      default: false
    },
    hideNullishAddresses: {
      type: Boolean,
      default: false
    },
    showNullishBalancesFor: {
      type: Array,
      default: () => ([])
    },
    retry: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    walletIsSelected({ selectedWallet }) {
      return !!selectedWallet
    },
    sorting() {
      return {
        label: this.$t('bridge.address_select.options_list.sorting.label'),
        field: this.$t('bridge.address_select.options_list.sorting.fields.amount'),
        value: 'balance'
      }
    },
    walletsWithBalances({ walletsList, currencyAndNetworkFrom, balancesSummary, hideNullishAddresses }) {

      return walletsList.reduce((acc, wallet) => {

        const addressesArr = balancesSummary[wallet.id] ? balancesSummary[wallet.id].map(item => item.addresses).flat().filter(item => {
          return !(item.address === currencyAndNetworkFrom.address && item.currencyId === currencyAndNetworkFrom.currencyId && item.networkId === currencyAndNetworkFrom.networkId);
        }) : []

        let addresses = addressesArr.length ? addressesArr : []

        if (hideNullishAddresses) {
          addresses = addresses.filter(({ balance }) => !!balance && Number(balance))
        }

        const balance = addressesArr.length ? this.prettyNumber( addressesArr.reduce((acc, addr) => {
              acc += Number(addr.balance)
              return acc
            }, 0) ) : 0

        acc.push({
          ...wallet,
          addresses,
          balance
        })

        return acc
      }, [])
    },
    currentList({ walletsWithBalances, selectedWallet, hideNullishBalances }) {
      //выбираем что показывать в options - список кошельков или список адресов
      if (!selectedWallet) {
        const { showNullishBalancesFor } = this
        return !hideNullishBalances ? walletsWithBalances : walletsWithBalances.filter(({ id, balance }) => !!balance || showNullishBalancesFor.includes(id))
      } else {
        return selectedWallet.addresses
      }
    },
    innerModel: {
      get({ value }) {
        return value
      },
      set(v) {
        const { selectedWallet } = this

        if (!selectedWallet) {
          this.selectedWallet = v
        } else {
          this.$emit('input', v)

          this.selectedWallet = undefined
        }
      }
    },
  },
  methods: {
    getNetworkShortNameByNetworkName,
    getNetworkSystemName,
    getNetworkName,
    getCurrencyTicker,
    ...mapActions('app', [
      'openModal',
    ]),
    getPrettyAddress,
    prettyNumber(num) {
      if (num) {
        return ( num % 1 !== 0 ) ? parseFloat(num.toFixed(6)) : num
      } else {
        return 0
      }
    },
    openCreateAddressModal() {
      const { accountId, currencyAndNetwork, openModal } = this;
      //
      // const { currency, network } = currencyAndNetwork
      //
      // const { id: currencyId } = currency
      // const { currency: currencyName } = currency
      //
      // const { id: networkId } = network
      // const { alias: networkName } = network
      //
      // const payload = {
      //   accountId,
      //   currencyId,
      //   currencyName,
      //   networkId,
      //   networkName
      // }

      // const payload = {
      //   accountId: "316a59ea-be39-4eaa-9392-6fda708f24d8",
      //   currencyId: "03dbd2a5-3148-413d-a26c-8de1103d34a5",
      //   currencyName: "USDT",
      //   networkId: "34013069-1766-4146-af38-1300a1ba797b",
      //   networkName: "Ethereum (ERC20)",
      //   organizationId: "1f07eb01-5fd8-4e05-89b5-bebcd1d1fc39",
      //   userId: "30e19d9c-b4a8-4c9c-8c4c-f43524d22aaf"
      // }

      openModal({
        name: `AddAddressModal_${this._uid}`
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.address-select {
  .address-select-selected {
    display: flex;
    align-items: center;

    .currency-icon {
      width: 25px;
    }

    font-family: var(--new-front-font-family);
    font-style: normal;
    font-weight: 500;

    color: var(--new-front-primary-font-color);
  }

  .options-prepend {
    font-family: var(--new-front-font-family);

    font-weight: 500;
    margin-bottom: 20px;

    .choose-wallet {
      color: var(--new-front-primary-label-color);
    }
    .back-btn {
      display: flex;
      align-items: center;
      gap: 7px;

      width: fit-content;
      cursor: pointer;
      color: var(--new-front-text-link-bg-modal-secondary);
    }
  }

  .address-select-option-item {
    display: flex;
    align-items: center;

    font-weight: 500;

    min-height: 32px;

    color: var(--new-front-primary-font-color);
  }
  .address-select-option-action {
    font-family: var(--new-front-font-family);
    font-style: normal;
    font-weight: 500;
    text-align: right;

    color: var(--new-front-primary-font-color);
  }

  .options-list-item {
    &:hover {
      .address-select-option-item {
        .address-info {
          color: var(--primary-font-color);
        }
      }
      .address-select-option-action {
        color: var(--primary-font-color);
      }
    }
  }

  .no-addresses, .no-wallets {
    .label {
      padding-bottom: 10px;
      color: var(--new-front-text-secondary-bg-secondary);
    }
  }
}
.secondary-text {
  color: var(--text-color-text-secondary);
}
.address-select-option-item {
  &__currency-icon {
    margin-right: 5px;
  }
}
.selected-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
::v-deep {
  .base-select-wrapper .input-wrapper .options-list {
    max-height: 500px;
    height: 29vh;
  }
  @media screen and (max-height: 800px) {
    .base-select-wrapper .input-wrapper .options-list {
      height: 25vh;
    }
  }
}
</style>
