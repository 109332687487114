<template>

  <div class="input-container">
    <input
      class="balances-search"
      :placeholder="currency_search"
      @input="$emit('search', $event)"
  />
    <svg class="search-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="#8F949A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M21 21L16.65 16.65" stroke="#8F949A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  </div>

</template>

<script>

import AtInput from 'at-ui/src/components/input';

export default {
    name: 'BalancesSearch',
    components: {
        AtInput,
    },
    computed: {
        currency_search() {
            return this.$t('addresses.currency_search');
        },
    },
};

</script>
<style lang="scss">
.balances-search {
  background: #FFFFFF;
  box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  border: none;
  padding-left: 15px;
  min-height: 45px;
  width: 100%;
  &:focus + .search-icon {
    display: none;
  }
}
.input-container {
  position: relative;
}
.search-icon {
  position: absolute;
  top: 10px;
  right: 15px;
}

</style>