<script>
  import MiniLoader from "@/public/components/common/uiKit/MiniLoader/index.vue";
  import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
  import selectOrganization from "@/public/graphQl/mutations/selectOrganization";
  import {getPrettyAddress, getTruncatedString} from "../../../filters";
  import CopyString from "@/public/components/elements/CopyString.vue";
  import Copy from "@/public/components/common/copy.vue";

  export default {
    name: "IntegrationsTestnet",
    components: {Copy, CopyString, MiniLoader},
    props: {
      isTestnetOrganization: {
        type: Boolean,
        default: false,
      },
      testnetOrganization: {
        type: [Object, undefined],
        default: undefined,
      }
    },
    data () {
      return {
        isLoading: false
      }
    },
    created() {
      if (this.isTestnetOrganization) {
        this.fetchApiKeys()
      }
    },
    methods: {
      getTruncatedString,
      getPrettyAddress,
      ...mapActions('app', [
        'openModal',
        'fetchOrganizations',
        'getOrganization',
          'clearAllStores',
          'init',
          'request',
      ]),
      ...mapMutations('app', ['selectOrganization', 'isInit']),
      ...mapActions('organizations', [
        'createOrganization',
          'getUserOrganizationRole',
      ]),
      ...mapActions('user', [
        'fetchApiKeys',
        'fetchApiRulesMap'
      ]),
      async handleCreate () {
        if(this.testnetOrganization) {
            this.$emit('handleSelectTestnet')
          return
        };

        this.isLoading = true

        const data = await this.createOrganization()
        this.fetchOrganizations()

        this.isLoading = false

        this.openModal({
          name: 'ModalTestnet',
          payload: {
            success: !data.code,
          }
        });
      },
      openTestnet () {
        this.openModal({
          name: 'ModalTestnet',
        });
      },
    },
    computed: {
      ...mapState('app', ['organizations']),
      ...mapGetters('app', ['getSelectedOrganization']),
      ...mapState('user', [
        'apiKeys',
      ]),
      testnetApiKeys () {
        return this.apiKeys[this.apiKeys.length - 1]
      },
    }
  }
</script>

<template>
<div class="testnet">
    <div>
      <div class="header">
        <div class="testnet__title">{{ isTestnetOrganization ? 'Api keys' : 'Testnet' }}</div>
        <svg v-if="!isTestnetOrganization" width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_20621_121463)">
            <circle opacity="0.2" cx="27" cy="27" r="27" fill="white"/>
            <path d="M32.5664 12.5137V17.8584H37.9108L32.5664 12.5137Z" fill="white"/>
            <path d="M31.6875 19.6172C31.2021 19.6172 30.8086 19.2237 30.8086 18.7383V12H18.2109C16.7571 12 15.5742 13.1828 15.5742 14.6367V39.3633C15.5742 40.8172 16.7571 42 18.2109 42H35.7891C37.2429 42 38.4258 40.8172 38.4258 39.3633V19.6172H31.6875ZM23.5736 32.2967C23.8844 32.6696 23.8339 33.2238 23.4611 33.5346C23.3034 33.6663 23.1044 33.7385 22.8988 33.7383C22.7699 33.7385 22.6425 33.7102 22.5257 33.6556C22.409 33.6009 22.3057 33.5212 22.2232 33.4221L19.2935 29.9064C19.1619 29.7485 19.0898 29.5494 19.0898 29.3438C19.0898 29.1381 19.1619 28.939 19.2935 28.7811L22.2232 25.2654C22.534 24.8926 23.0882 24.8422 23.4611 25.1529C23.8339 25.4637 23.8844 26.0179 23.5736 26.3908L21.1128 29.3438L23.5736 32.2967ZM29.0057 26.1061L26.6619 33.1373C26.5391 33.5056 26.1962 33.7385 25.8282 33.7385C25.7361 33.7385 25.6423 33.7239 25.5502 33.6932C25.0897 33.5397 24.8408 33.042 24.9943 32.5815L27.338 25.5502C27.4915 25.0897 27.9893 24.8409 28.4498 24.9943C28.9103 25.1479 29.1592 25.6455 29.0057 26.1061ZM34.7064 29.9064L31.7767 33.4221C31.6943 33.5212 31.591 33.6009 31.4742 33.6556C31.3574 33.7102 31.23 33.7385 31.1011 33.7383C30.8956 33.7385 30.6966 33.6663 30.5389 33.5346C30.166 33.2238 30.1155 32.6696 30.4263 32.2967L32.8872 29.3438L30.4264 26.3908C30.1156 26.0179 30.1661 25.4637 30.5389 25.1529C30.9117 24.8422 31.466 24.8926 31.7768 25.2654L34.7065 28.7811C34.8381 28.939 34.9102 29.1381 34.9102 29.3438C34.9102 29.5494 34.8381 29.7485 34.7064 29.9064Z" fill="white"/>
          </g>
          <defs>
            <clipPath id="clip0_20621_121463">
              <rect width="54" height="54" rx="27" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <svg v-else width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_22614_169207)">
            <circle opacity="0.2" cx="27" cy="27" r="27" fill="#1B1B1B"/>
            <g clip-path="url(#clip1_22614_169207)">
              <path d="M27.8864 24.2729C26.7614 21.0956 23.7409 18.8184 20.1818 18.8184C15.6614 18.8184 12 22.4797 12 27.0002C12 31.5206 15.6614 35.182 20.1818 35.182C23.7409 35.182 26.7614 32.9047 27.8864 29.7275H33.8182V35.182H39.2727V29.7275H42V24.2729H27.8864ZM20.1818 29.7274C18.675 29.7274 17.4545 28.507 17.4545 27.0001C17.4545 25.4933 18.675 24.2729 20.1818 24.2729C21.6886 24.2729 22.9091 25.4933 22.9091 27.0002C22.9091 28.507 21.6886 29.7274 20.1818 29.7274Z" fill="white"/>
            </g>
          </g>
          <defs>
            <clipPath id="clip0_22614_169207">
              <rect width="54" height="54" rx="27" fill="white"/>
            </clipPath>
            <clipPath id="clip1_22614_169207">
              <rect width="30" height="30" fill="white" transform="translate(12 12)"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <div v-if="!isTestnetOrganization" class="testnet__desc" v-html="$t('testnet.testnet-desc')" />
    </div>
    <div class="testnet__api" v-if="isTestnetOrganization && testnetApiKeys">
      <div>
        <div class="testnet__api-label">{{ $t('api_keys.public_key') }}</div>
        <div class="testnet__api-block">
          <div class="testnet__api-value">{{ testnetApiKeys.public ? testnetApiKeys.public : '' | getTruncatedString(8, 6) }}</div>
          <div class="testnet__api-copy"><copy :color="'var(--bg-background-primary-purple)'" :value="testnetApiKeys.public" /></div>
        </div>
      </div>
      <div>
        <div class="testnet__api-label">{{ $t('api_keys.private_key') }}</div>
        <div class="testnet__api-block">
          <div class="testnet__api-value">{{ testnetApiKeys.secret ? testnetApiKeys.secret : '' | getTruncatedString(8, 6) }}</div>
          <div class="testnet__api-copy"><copy :color="'var(--bg-background-primary-purple)'" :value="testnetApiKeys.secret" /></div>
        </div>
      </div>
    </div>
    <button v-if="!isTestnetOrganization" class="testnet__btn" @click="handleCreate">{{ testnetOrganization ? $t('testnet.redirect') : $t('testnet.create') }}</button>
  <div v-if="isLoading" class="testnet__loader">
    <mini-loader />
  </div>
</div>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.testnet {
  position: relative;
  padding: 30px;
  border-radius: 30px;
  border: 1px solid var(--border-border-input-primary-default);
  background: var(--bg-background-primary-purple);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__api {
    display: flex;
    flex-direction: column;
    gap: 10px;
    &-label {
      color: var(--text-text-white, #FFF);
      font-size: 13px;
      font-weight: 500;
      line-height: 100%;
      margin-bottom: 5px;
    }
    &-block {
      display: flex;
      align-items: center;
      width: 100%;
      &:first-child {
        margin-bottom: 10px;
      }
    }
    &-value {
      border-radius: 10px 0 0 10px;
      background: var(--bg-background-disabled);
      color: var(--text-text-primary);
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
      padding: 14px;
      width: 100%;
    }
    &-copy {
      padding: 15px;
      border-radius: 0 10px 10px 0;
      border-left: 1px solid var(--border-border-input-primary-default, #E0E2E7);
      background: var(--bg-background-disabled, #F9F5FF);
    }
  }
  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.65);
    border-radius: 30px;
  }
  &__title {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    color: var(--text-text-white);
  }
  &__desc {
    color: var(--text-text-white);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
  }
  &__btn {
    border-radius: 10px;
    background: var(--button-background-button-primary-white);
    color: var(--text-text-primary-accent, #744DB2);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    height: 48px;
    width: 100%;
    border: 0;
    cursor: pointer;
  }
}
//::v-deep {
//  .copy-wrapper {
//    margin: 0;
//    svg {
//      path {
//        fill:
//      }
//    }
//  }
//}
</style>
