import io from 'socket.io-client';

import {SOCKET_IO_PATH, SOCKET_IO_URL} from "@/config";

let ioParams = {};

if(SOCKET_IO_PATH !== '') {
    ioParams.path = SOCKET_IO_PATH;
}

const ioClient = SOCKET_IO_URL === '' || SOCKET_IO_URL === '/' ?
    io(ioParams) :
    io(SOCKET_IO_URL, ioParams);

export const ioVue = {
    install(Vue){
        Vue.prototype.$io = ioClient;
    },
};

export default ioClient;
