<template>
  <div class="transactions-sort-by-type">
      <transactions-types-select
        v-model="isActiveType"
        :placeholder="`${$t('transactions.type')}...`"
        :list="$options.transactionHistoryType"
        clearable
        uniq-key="value"
        @input="selectType"
      />
  </div>
</template>

<script>

import {mapActions} from 'vuex';

import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';
import TransactionsTypesSelect from "@/public/components/elements/transactions/Select/TransctionsTypesSelect.vue";


export default {
    name: 'TransactionsSortByType',

    transactionHistoryType: [
        {
            value: 'withdrawal',
            title: 'historyAddresses.withdrawal',
        },
        {
            value: 'deposit',
            title: 'historyAddresses.deposit',
        },
    ],

    components: {
        TransactionsTypesSelect,
        AtSelect,
        AtOption,
    },
    data() {
        return {
            isActiveType: undefined,
        };
    },
    watch: {
      selected: {
        handler(v) {
          this.isActiveType = (v && v[0]) ? this.$options.transactionHistoryType.find(({ value }) => value === this.selected[0]) : undefined
        },
        immediate: true,
        deep: true
      }
    },
    props: {
      selected: {
        type: Array,
        default: () => ([])
      },
    },
    computed: {
        select_the_transaction_type() {
            return this.$t('historyAddresses.take_type');
        },
        selectedStatus() {
            const status = this.$options.transactionHistoryType.find(item => item.value === this.isActiveType.value);
            return this.$t(status?.title);
        },

        addressId() {
            return this.$route.params.id;
        },
    },
    methods: {
        ...mapActions('historyAddresses', [
            'addressTransactions',
        ]),
        selectType(item) {
            const val = item.value ? [item.value] : [];
            this.$emit('selectType',  val)
        },
    },
};

</script>

<style lang="scss" scoped>

.transactions-sort-by-type {
  &__title {
    margin-bottom: 8px;
    font-size: var(--font-size-text-main);
    font-family: var(--font-family);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__select ::v-deep .at-select {
    background: #FFFFFF;
    box-sizing: border-box;


  }

  ::v-deep .at-select__arrow {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  ::v-deep .at-select__clear {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  &__select::v-deep .at-select__selection {
    border: 1px solid var(--border-color);
    border-radius: 12px;
    min-height: 46px;
    display: flex;
    padding-left: 30px;
    align-items: center;
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    flex-wrap: wrap;
  }

  ::v-deep .at-select__dropdown .at-select__option {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);

    &:hover {
      background: var(--hover-color-select);
    }
  }

  ::v-deep .at-select__placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }

  ::v-deep .at-select__selection {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    border: 1px solid var(--border-color);
    background: var(--secondary-color);
  }

  &::v-deep .at-select__dropdown {
    border: 1px solid var(--border-color);
    background: var(--bg-select);
  }

  ::v-deep .at-select__option--selected {
    background: var(--hover-color-select);
  }

  ::v-deep .at-select__selected {
    display: none;
  }

  &__select-wrapper {
    position: relative;
  }

  &__status {
    position: absolute;
    top: 12px;
    left: 30px;
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

}


</style>
