<template>
  <div class="common-info-modal-wrapper">
    <svg
        class="common-info-modal-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        @click="openInfoModal"
    >
      <path
        d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill="#FFFFFF"
      />
      <path
        d="M8.9307 6.58789L6.63969 6.875L6.55766 7.25586L7.00883 7.33789C7.3018 7.4082 7.36039 7.51367 7.29594 7.80664L6.55766 11.2754C6.3643 12.1719 6.66313 12.5938 7.36625 12.5938C7.91117 12.5938 8.54398 12.3418 8.83109 11.9961L8.91898 11.5801C8.71977 11.7559 8.4268 11.8262 8.23344 11.8262C7.95805 11.8262 7.85844 11.6328 7.92875 11.293L8.9307 6.58789Z"
        fill="#FFFFFF"
      />
      <path
        d="M9 4.5C9 5.05228 8.55229 5.5 8 5.5C7.44772 5.5 7 5.05228 7 4.5C7 3.94772 7.44772 3.5 8 3.5C8.55229 3.5 9 3.94772 9 4.5Z"
        fill="#FFFFFF"
      />
    </svg>
    <StoreModal
      name="CommonInfoModalContent"
      @on-cancel="handleClose"
      @on-confirm="handleClose"
    >
      <template #header>
        <div class="common-info-header">
          {{ $t('info_modals.title') }}
        </div>
      </template>
        <template #body>
            <div
              v-html="description"
              class="common-info-body"
            />
        </template>

      <template #footer>
        <div class="footer-block">
          <div class="action">
            <base-button
              style="width: 100% !important;"
              :label="$t('info_modals.btn_label')"
              @click="handleClose"
            />
          </div>
        </div>
      </template>
    </StoreModal>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import BaseModal from "../BaseModal";
import BhButton from "/public/components/common/bh-button"
import Icon from "/public/components/common/icon";
import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";

export default {
  name: "CommonInfoModal",
  components: {
    BaseButton,
    BaseModal,
    BhButton,
    Icon,
    StoreModal: () => import(/* webpackChunkName: "/public/components/modals/StoreModal" */ '/public/components/modals/StoreModal')
  },
  props: {
    description: {
      type: String,
      default: undefined
    },
  },
  computed: {
    ...mapGetters('app', [
      'isOpenModal',
    ]),
    componentId({ _uid }) {
      return _uid
    },
    isOpen() {
      const {
        isOpenModal,
      } = this;

      return isOpenModal(`CommonInfoModalContent`);
    },
  },
  methods: {
    ...mapActions('app', [
      'openModal',
      'closeModal',
    ]),
    handleClose() {
      this.closeModal();
    },
    openInfoModal() {
      this.openModal({ name: `CommonInfoModalContent` })
    }
  },
}
</script>

<style lang="scss">
.common-info-modal-wrapper {
  width: 22px;
  min-width: 22px;
  height: 22px;
  border-radius: 6px;
  border: 1px solid var(--new-front-bg-info);
  background-color: var(--new-front-bg-info);

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  .common-info-modal-icon {
  }
  .footer-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .action {
      width: 100%;
    }
  }
  .common-info-modal {
    .at-modal__body {
      padding-top: 10px;
      padding-bottom: 10px;
      max-height: 50vh;
      overflow-y: scroll;
    }
  }
  .common-info-header {
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 500;
    text-align: center;

    font-size: $h1;
    color: var(--new-front-primary-font-color);
  }
  .common-info-body {
    font-family: $Gilroy;
    //max-height: 58%;
    //overflow-y: scroll;
    font-size: 16px;
    color: var(--new-front-primary-font-color);
    padding: 0 30px;
    max-height: 550px;
    overflow-y: auto;
    //&::-webkit-scrollbar {
    //  width: 0; /* width of the entire scrollbar */
    //}
    //&::-webkit-scrollbar-track {
    //  background: transparent; /* color of the tracking area */
    //}
    //&::-webkit-scrollbar-thumb {
    //  background: transparent; /* color of the tracking area */
    //  width: 0;
    //}

    ul {
      font-family: $Gilroy;
      color: var(--primary-font-color);
      font-size: var(--font-size-text-main);
      li {
        margin: 10px 0 10px 20px;

        &::marker {
          content: '-  ';
        }
      }
    }

    p {
      margin: 20px 0;
      color: var(--new-front-primary-font-color);
      font-size: var(--font-size-text-main);
    }

    div {
      color: var(--new-front-primary-font-color);
      font-size: var(--font-size-text-main);
    }
    p, li, div {
      font-weight: 500;
      font-family: $Gilroy;
      color: var(--new-front-primary-font-color);
      line-height: 1.5;
    }
  }



  @media screen and (max-width: 950px) {
    .common-info-modal {
      .common-info-header {
        font-size: var(--font-size-h3);
      }
    }
    .common-info-body {
      p {
        margin: 15px 0;
        font-size: var(--font-size-text-secondary);
      }
      div {
        font-size: var(--font-size-text-secondary);
      }
    }
  }
}
::v-deep .base-btn {
  width: 100% !important;
}
</style>
