import getSelectedOrganizationQuery from "/public/graphQl/queries/getSelectedOrganization";

export const fetchSelectedOrganization = async ({ commit, dispatch }) => {
    let organizationsData;

    try {
        organizationsData = await dispatch('request', {
            type: 'query',
            gql: getSelectedOrganizationQuery,
        });
    } catch (error) {
        return error;
    }

    if (organizationsData.message) {
        throw organizationsData.message;
    }

    if (organizationsData.errors) {
        throw organizationsData.errors;
    }

    const {
        data: {
            getSelectedOrganization: organizationId,
        },
    } = organizationsData;

    commit('selectOrganization', organizationId);

    return true;
};