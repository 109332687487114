import gql from "graphql-tag";

export default gql`
    mutation uploadPreCheckoutLink(
        $organizationId: String!,
        $pageId: String!, 
    ) {
        uploadPreCheckoutLink(
            organizationId: $organizationId,
            pageId: $pageId,
        ) {
            code
            result
        }
    }
`;
