<template>
  <base-button-select
    :options="options"
    v-model="valueModel"
  />
</template>

<script>
import BaseButtonSelect from '/public/components/common/uiKit/buttonSelect'

export default {
  name: "AnalyticsButtonSelect",
  components: {
    BaseButtonSelect
  },
  data() {
    return {
      options: [
        {
          id: 'date',
          label: this.$t('analytics.filters.button-select.date'),
        },
        {
          id: 'period',
          label: this.$t('analytics.filters.button-select.period'),
        },
      ]
    }
  },
  props: {
    value: {
      type: String,
      default: undefined
    },
  },
  computed: {
    valueModel: {
      get({ value }) {
        return value
      },
      set(v) {
        this.$emit('input', v)
      },
    }
  },
}
</script>

<style scoped>

</style>