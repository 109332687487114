import gql from 'graphql-tag';

export default gql`
    mutation($organizationId: String!, $alias: String!, $otpToken: String!, $ips: [String!]!, $rules: String!, $readOnly: Boolean!) {
        createApiKeys(organizationId: $organizationId, alias: $alias, otpToken: $otpToken, ips: $ips, rules: $rules, readOnly: $readOnly) {
            public
            secret
        }
    }
`;
