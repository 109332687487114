import gql from 'graphql-tag';

export default gql`
subscription ($organizationId: String!) {
  advancedBalanceUpdate (organizationId: $organizationId,) {
    organizationId
    accountId
    balance
  }
}
`
