import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!) {
         getAccounts(organizationId: $organizationId) {
             accountId
             currencyId
             blocked
             blockReason
             balance
             tariffs {
                 action
                 amount
                 maxAmount
                 minAmount
                 type
             }
             availableCurrenciesForDeposit
        }
    }
`;
