import gql from 'graphql-tag';

export default gql`
     mutation($organizationId: String!, $pageId: String!, $title: String, $description: String, $avatarLink: String, $theme: PreCheckoutPageInfoTheme) {
         changePreCheckoutInfo(organizationId: $organizationId, pageId: $pageId, title: $title, description: $description, avatarLink: $avatarLink, theme: $theme){
            code
            result {
                title,
                description,
                avatarLink,
                theme
            }
         }
    }
`;
