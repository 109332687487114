<script>

  import WidgetsPercentLabel from "@/public/components/elements/widgets/WidgetsPercentLabel.vue";
  import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
  import {mapActions, mapGetters} from "vuex";
  import {PAYIN_CRYPTO_TYPE_INVOICE} from "../../../pages/widgets/constants";

  export default {
    name: "WidgetsListItem",
    components: {BaseButton, WidgetsPercentLabel},
    props: {
      item: {
        type: Object,
        default: () => ({})
      },
      index: {
        type: Number,
        default: 0
      }
    },
    PAYIN_CRYPTO_TYPE_INVOICE,
    data () {
      return {
        hoverState: false
      }
    },
    computed: {
      ...mapGetters('dashboard', ["getDifference",]),
      ...mapGetters('organizations', ['userRoleRules']),
      stats () {
        return this.item.stats.payin
      },
      isEditDisabled () {
        return !this.userRoleRules['iframe_widgets'].entities.update
      },
      isDemoDisabled () {
        return !this.userRoleRules['iframe_widgets'].entities.view
      },
      isDeleteDisabled () {
        return !this.userRoleRules['iframe_widgets'].entities.delete
      }
    },
    methods: {
      ...mapActions('app', ['openModal']),
      deleteWidget () {
        if (this.isDeleteDisabled) return

        this.openModal({
          name: 'WidgetDeleteConfirmation',
          payload: {
            id: this.item.id
          }
        });
      },
      goToManage(type) {
        this.$emit('goToManage', type, this.item.id)
      }
    }
  }
</script>

<template>
<div class="item" @mouseenter="hoverState = true" @mouseleave="hoverState = false">
  <div class="item__stats">
    <div class="item__stats-item">
      <div class="label">{{ $t('widgets.sumOfDeposits') }}</div>
      <div class="value">${{ stats.all.amountUSD }}</div>
      <div class="percent">
        <WidgetsPercentLabel v-bind="getDifference(stats.all.amountUSD, stats.period.previous.amountUSD)" />
      </div>
    </div>
    <div class="item__stats-item">
      <div class="label">{{ $t('widgets.countOfDeposits') }}</div>
      <div class="value">{{ stats.all.count }}</div>
      <div class="percent">
        <WidgetsPercentLabel v-bind="getDifference(stats.all.count, stats.period.previous.count)" :is-currency="false" />
      </div>
    </div>
  </div>
  <div class="item__settings">
    <div class="item__settings-item">
        <div class="label">{{ $t('widgets.payin') }}: </div>
        <div v-if="item.payinCrypto" class="value">{{
            item.payinCryptoType === $options.PAYIN_CRYPTO_TYPE_INVOICE ? $t('widgets.invoices') : $t('widgets.personal')
          }}</div>
      <div v-else class="value disabled">
        <div class="round disabled"></div>
        {{ $t('widgets.disabled') }}
      </div>
    </div>
    <div class="item__settings-item">
      <div class="label">{{ $t('widgets.payout') }}: </div>
      <div :class="['value', item.payoutCrypto ? 'active' : 'disabled']">
        <div :class="['round', item.payoutCrypto ? 'active' : 'disabled']"></div>
        {{ item.payoutCrypto ? $t('widgets.active') : $t('widgets.disabled') }}
      </div>
    </div>
  </div>
  <div class="item__footer">
    {{ item.alias }}
  </div>
  <transition name="slide-fade">
    <div class="item__hoverBlock" v-if="hoverState">
      <div :class="['delete', {disabled: isDeleteDisabled}]" @click="deleteWidget">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.75 1.5C2.92157 1.5 2.25 2.17157 2.25 3V4.5C2.25 5.32843 2.92157 6 3.75 6H4.5V19.5C4.5 21.1569 5.84315 22.5 7.5 22.5H16.5C18.1569 22.5 19.5 21.1569 19.5 19.5V6H20.25C21.0784 6 21.75 5.32843 21.75 4.5V3C21.75 2.17157 21.0784 1.5 20.25 1.5H15C15 0.671573 14.3284 0 13.5 0H10.5C9.67157 0 9 0.671573 9 1.5H3.75ZM8.25 7.5C8.66421 7.5 9 7.83579 9 8.25V18.75C9 19.1642 8.66421 19.5 8.25 19.5C7.83579 19.5 7.5 19.1642 7.5 18.75L7.5 8.25C7.5 7.83579 7.83579 7.5 8.25 7.5ZM12 7.5C12.4142 7.5 12.75 7.83579 12.75 8.25V18.75C12.75 19.1642 12.4142 19.5 12 19.5C11.5858 19.5 11.25 19.1642 11.25 18.75V8.25C11.25 7.83579 11.5858 7.5 12 7.5ZM16.5 8.25V18.75C16.5 19.1642 16.1642 19.5 15.75 19.5C15.3358 19.5 15 19.1642 15 18.75V8.25C15 7.83579 15.3358 7.5 15.75 7.5C16.1642 7.5 16.5 7.83579 16.5 8.25Z" fill="var(--ocp-0-100-error-error40)"/>
        </svg>
      </div>
      <div class="actions">
        <base-button @click="goToManage('demo')" :disabled="isDemoDisabled" :label="$t('widgets.demo')" />
        <base-button @click="goToManage('edit')" :disabled="isEditDisabled" :label="$t('widgets.edit')" />
      </div>
    </div>
  </transition>
</div>
</template>

<style scoped lang="scss">
.item {
  border-radius: 15px;
  background: var(--bg-background-card-iframe-1);
  overflow: hidden;
  position: relative;
  &__stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    padding: 20px 20px 0 20px;
    @media (max-width: 768px) {
      margin-bottom: 40px;
      padding: 15px 15px 0 15px;
    }
    &-item {
      .label {
        color: var(--text-color-text-secondary);
        font-size: 14px;
        font-weight: 500;
        line-height: 100%;
        margin-bottom: 5px;
        @media (max-width: 768px) {
          font-size: 11px;
        }
      }
      .value {
        color: var(--text-text-primary);
        font-size: 32px;
        font-weight: 500;
        margin-bottom: 8px;
        line-height: 100%;
        @media (max-width: 768px) {
          font-size: 26px;
        }
      }
    }
  }
  &__settings {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 19px 0 19px;
    margin-bottom: 35px;
    &-item {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .label {
        color: var(--text-color-text-secondary, #8B81A0);
        font-size: 14px;
        font-weight: 500;
        line-height: 100%;
        @media (max-width: 768px) {
          font-size: 11px;
        }
      }
      .value {
        color: var(--text-text-primary, #000);
        font-size: 16px;
        font-weight: 500;
        line-height: 100%;
        display: flex;
        align-items: center;
        gap: 5px;
        @media (max-width: 768px) {
          font-size: 13px;
        }
        &.active {
          color: var(--ocp-status-success-success-primary);
        }
        &.disabled {
          color: var(--ocp-0-100-error-error40);
        }
        .round {
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: var(--ocp-status-success-success-primary);
          &.disabled {
            background: var(--ocp-0-100-error-error40);
          }
        }
      }
    }
  }
  &__footer {
    padding: 20px 19px;
    color: var(--text-text-primary);
    font-size: 22px;
    font-weight: 600;
    background: var(--bg-background-card-iframe-2);
    line-height: 100%;
    @media (max-width: 768px) {
      font-size: 18px;
      padding: 20px 15px;
    }
  }

  &__hoverBlock {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px;
    .actions {
      display: flex;
      flex-direction: column;
      gap: 15px;
      z-index: 2;
    }
    .delete {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid rgba(237, 5, 5, 0.05);
      background: var(--ocp-0-100-error-error90);
      cursor: pointer;
      &.disabled {
        opacity: .5;
      }
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      opacity: 0.95;
      background: var(--bg-background-card-iframe-2);
      backdrop-filter: blur(5px);
      z-index: 0;
    }
  }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
