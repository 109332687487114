import {v4 as UUID} from 'uuid';

import RequestFeeMutation from '/public/graphQl/mutations/requestFee';

export const fetchFee = async ({ state, commit, dispatch, rootGetters }, payload) => {
    if(state.fee.pending) {
        return;
    }

    const id = UUID();

    commit('setFeeRequestId', id);
    commit('setFeePending', true);

    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    try {
        const withdrawalRequestData = await dispatch('app/request', {
            type: 'mutation',
            gql: RequestFeeMutation,
            variables: {
                organizationId,
                accountId: payload.accountId,
                addressId: payload.addressId,
                native: payload.native,
                amount: payload.amount.toString(),
            },
        }, { root: true });

        if(id !== state.feeRequestId) {
            return;
        }

        commit('setFeePending', false);

        if (withdrawalRequestData.errors) {
            commit('setFeeError', withdrawalRequestData.errors[0]);
        } else {
            const {data: {requestFee: {code, result}}} = withdrawalRequestData;

            commit('setFee', {
                code: code,
                currency: result.currencyId,
                amount: result.amount,
                usdAmount: result.usdAmount,
                until: result.until,
                source: result.source,
                token: result.token,
                tariffFee: result.tariffFee,
                tariffFeeUSD: result.tariffFeeUSD,
                networkFee: result.networkFee,
                networkFeeUSD: result.networkFeeUSD,
            });

        }

        commit('setFeeComplete', true);
    } catch (error) {
        if(id !== state.feeRequestId) {
            return;
        }

        commit('setFeePending', false);
        commit('setFeeComplete', true);

        return error;
    }
};
