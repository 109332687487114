<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 4.5L6.75 12.75L3 9" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
    name: "checkmark",
    props: {
        color: {
            type: String,
            default: '#8F949A',
        },
    },
};
</script>

<style scoped>

</style>