import createOrderMutation from "/public/graphQl/mutations/createOrder";

export const createOrder = async ({dispatch, rootGetters}, {
    accountId,
    network,
    currency,
    amount,
    availableTill,
    errorWebhook,
    successWebhook,
    returnUrl,
    order,
    description,
    lifetime,
}) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    let orderData;
    const stringAmount = amount.toString()
    try {
        orderData = await dispatch('app/request', {
            type: 'mutation',
            gql: createOrderMutation,
            variables: {
                organizationId,
                accountId,
                network,
                currency,
                amount: stringAmount,
                availableTill,
                errorWebhook,
                successWebhook,
                returnUrl,
                order,
                description,
                lifetime,
            },
        }, {root: true});
    } catch (error) {
        return error;
    }

    if (orderData.message) {
        throw orderData.message;
    }

    if (orderData.errors) {
        throw orderData.errors;
    }

    const {
        data: {
            createOrder,
        },
    } = orderData;

    return createOrder;
};
