import { removeAddressBook } from "./actions/removeAddressBook";
import { createAddressBook } from "./actions/createdAddressBook";
import { getAddressBook } from "./actions/getAddressBook";
import {changeAddressBook} from "./actions/changeAddressBook";
import {networkAddressBook} from "./actions/networkAddressBook";

export const actions = {
    changeAddressBook,
    createAddressBook,
    removeAddressBook,
    networkAddressBook,
    getAddressBook: getAddressBook,
};