import {fetchOrders} from './actions/fetchOrders';
import {createOrder} from './actions/createOrder';
import {fetchOrder} from './actions/fetchOrder';
import {updateOrder} from "./actions/updateOrder";
import {downloadCSV} from "./actions/downloadCSV";
import {fetchBlockChainTransaction} from "./actions/fetchBlockchainTransaction";
import {downloadXLSX} from "./actions/downloadXLSX";


export const actions = {
    fetchOrders,
    downloadCSV,
    downloadXLSX,
    createOrder,
    fetchOrder,
    updateOrder,
    fetchBlockChainTransaction,
};
