<script>

  import Container from "@/public/components/common/uiKit/Container.vue";
  import Copy from "@/public/components/common/copy.vue";
  import BaseInput from "@/public/components/common/uiKit/input/base/index.vue";
  import BaseCheckbox from "@/public/components/common/uiKit/Checkbox/index.vue";
  import BaseTextarea from "@/public/components/common/uiKit/textarea/index.vue";
  import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
  import copyToClipboard from "/public/mixins/copyToClipboard";
  import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
  import TwoFaRestriction from "@/public/components/modals/twoFactorAuthorization/TwoFaRestriction.vue";
  import TwoFaConfirmation from "@/public/components/modals/twoFactorAuthorization/TwoFaConfirmation.vue";
  import ipRangeCheck from "ip-range-check"

  const  FULL_RIGHTS = 1
  const PARTIAL_RIGHTS = 2
  const READ_ONLY = 3
  export default {
    name: "ApiKeysCreate",
    components: {TwoFaConfirmation, TwoFaRestriction, BaseButton, BaseTextarea, BaseCheckbox, BaseInput, Copy, Container},
    data() {
      return {
        // apiKeys: null,
        alias: null,
        canSendForm: false,
        ipsString: '',
        ipv6String: '',
        ipsError: '',
        ipv6Error: '',
        aliasError: '',
        rightsType: READ_ONLY,
        rules: {}
      };
    },
    FULL_RIGHTS,
    PARTIAL_RIGHTS,
    READ_ONLY,
    mixins: [copyToClipboard],
    computed: {
      ...mapGetters('app', [
        'isOpenModal',
      ]),
      ...mapState('app', {
        modalPayload: state => state.modal.payload,
      }),
      ...mapState('user', ['apiKeysPlaceholder', 'createdApiKeys']),
      ...mapGetters('organizations', ['userRoleRules']),
      isAddKeysAllowed () {
        return this.userRoleRules['api_keys'].entities.manage_api_keys
      },
      apiKeys () {
        if (this.createdApiKeys && this.createdApiKeys.public) {
          return {
            public: this.createdApiKeys.public,
            secret: this.createdApiKeys.secret
          }
          // {"public": "12312312", "secret": "43312321321"}
        }
        return null
      },
      ipArray () {
        return this.ipsString.split(' ')
      },
      ipv6Array () {
        return this.ipv6String.split(' ')
      },
      hasApiKeys() {
        return Boolean(this.apiKeys);
      },
      publicKey() {
        if(!this.apiKeys) return ''
        return this.apiKeys?.public;
      },
      secretKey() {
        if(!this.apiKeys) return ''
        return this.apiKeys?.secret;
      },
      isOpen() {
        const {
          $options: {
            name,
          },
          isOpenModal,
        } = this;

        return isOpenModal(name);
      },
      title() {
        return this.$t(this.hasApiKeys ? 'api_keys.api_key_created' : 'api_keys.creating_an_api_key');
      },
      aliasStatus() {
        const {
          alias,
          canSendForm,
        } = this;

        return !alias && canSendForm ? 'error' : '';
      },
      be_sure_to_keep() {
        return this.$t('api_keys.be_sure_to_keep');
      },
      creating_an_api_keys() {
        return this.$t('api_keys.creating_an_api_keys');
      },
    },
    watch: {
      ipsString(newVal) {
        if (!newVal) {
          return
        }
        let re = /[^\d\s\.]/g;
        this.$set(this, 'ipsString', newVal.replace(re, ''));
      },
      ipv6String(newVal) {
        if (!newVal) {
          return
        }
        let re = /[^\d\s\.]/g;
        this.$set(this, 'ipv6String', newVal);
      },
      rightsType (value) {
        if (value === PARTIAL_RIGHTS) return

        this.setRightsType(value)
      }
    },
    async beforeMount() {
      await this.fetchApiRulesMap()
      await this.setRules()
      if (!this.isAddKeysAllowed) {
        this.$Message.error(this.$t('rules.not_allowed'));
        this.$router.push('/keys-api');
      }
    },
    methods: {
      ...mapActions('app', [
        'closeModal',
        'openModal'
      ]),
      ...mapActions('user', [
        'createApiKeys',
        'fetchApiKeys',
        'fetchApiRulesMap',
      ]),
      ...mapMutations('user', ['setCreatedApiKeys']),
      checkRights () {
        let viewAccessCount = 0
        let allTrueCount = 0
        let allRightsCount = 0
        let allViewCount = 0
        for (let key in this.rules) {
          for (const entity in this.rules[key]) {
            allRightsCount += 1
            if (entity === 'VIEW') {
              allViewCount+=1
              if (this.rules[key][entity]) {
                viewAccessCount += 1
              }
            }
            if (this.rules[key][entity]) {
              allTrueCount += 1
            }
          }
        }

        this.rightsType = (viewAccessCount === allTrueCount) && (viewAccessCount === allViewCount)
            ? READ_ONLY
            : allRightsCount === allTrueCount
                ? FULL_RIGHTS
                : PARTIAL_RIGHTS
        // rights[]
      },
      setRightsType(type) {
        for (let key in this.rules) {
          for (const entity in this.rules[key]) {
            this.rules[key][entity] = type === READ_ONLY ? entity === 'VIEW' : true
          }
        }
      },
      setRules () {
        this.apiKeysPlaceholder.forEach(item => {
          this.rules[item.section] = {}
          item.entities.forEach(entity => {
            this.rules[item.section][entity] = entity === 'VIEW'
          })
        })
        this.$forceUpdate()
      },
      clearAllData() {
        this.isLoading = false;
        // this.apiKeys = null;
        this.alias = null;
        this.canSendForm = false;
        this.ipsError = ''
        this.ipsString = ''
        this.aliasError = ''
      },
      handleCreateRequest() {
        const {
          alias,
          createApiKeys,
            rules,
        } = this;

        this.canSendForm = true;

        if (!alias) {
          this.aliasError = this.$t('api_keys.alias_error')
          return false;
        }

        this.aliasError = ''

        const ips = this.ipArray.filter(item => item.length)
        const ipv6 = this.ipv6Array.filter(item => item.length)

        if (!ips.length && !ipv6.length && this.rightsType !== READ_ONLY) {
          this.ipsError = this.$t('api_keys.ip_exist')
          return
        }
        const v4Seg = '(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])';
        const v4Str = `(${v4Seg}[.]){3}${v4Seg}`;
        const v6Seg = '(?:[0-9a-fA-F]{1,4})';
        const IPv6Reg = new RegExp('^(' +
            `(?:${v6Seg}:){7}(?:${v6Seg}|:)|` +
            `(?:${v6Seg}:){6}(?:${v4Str}|:${v6Seg}|:)|` +
            `(?:${v6Seg}:){5}(?::${v4Str}|(:${v6Seg}){1,2}|:)|` +
            `(?:${v6Seg}:){4}(?:(:${v6Seg}){0,1}:${v4Str}|(:${v6Seg}){1,3}|:)|` +
            `(?:${v6Seg}:){3}(?:(:${v6Seg}){0,2}:${v4Str}|(:${v6Seg}){1,4}|:)|` +
            `(?:${v6Seg}:){2}(?:(:${v6Seg}){0,3}:${v4Str}|(:${v6Seg}){1,5}|:)|` +
            `(?:${v6Seg}:){1}(?:(:${v6Seg}){0,4}:${v4Str}|(:${v6Seg}){1,6}|:)|` +
            `(?::((?::${v6Seg}){0,5}:${v4Str}|(?::${v6Seg}){1,7}|:))` +
            ')(%[0-9a-zA-Z-.:]{1,})?$');

        const ipExcludeRange = [
          '0.0.0.0/8',
          '10.0.0.0/8',
          '127.0.0.0/8',
          '169.254.0.0/16',
          '172.16.0.0/12',
          '192.0.0.0/24',
          '192.0.2.0/24',
          '192.88.99.0/24',
          '192.168.0.0/16',
          '198.18.0.0/15',
          '198.51.100.0/24',
          '203.0.113.0/24',
          '224.0.0.0/4',
          '240.0.0.0/4',
          '255.255.255.255/32',
        ]

        ips.forEach(ip => {
              if (
                  !/^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/.test(ip) ||
                  ipRangeCheck(ip, ipExcludeRange)
              ) {
                this.ipsError = `${this.$t('api_keys.ip_invalid')}: ${ip}`

                return
              }
              this.ipsError = ''
            }
        )
        ipv6.forEach(ip => {
              if (
                  !IPv6Reg.test(ip)
              ) {
                this.ipv6Error = `${this.$t('api_keys.ip_invalid')}: ${ip}`

                return
              }
              this.ipsError = ''
            }
        )
        if (this.ipsError || this.ipv6Error) {
          return;
        }
        const readOnly = this.rightsType === 3

        const allIps = [...ips, ...ipv6]
        this.openModal({
          name: 'TwoFaConfirmation',
          payload: {
            alias,
            ips: allIps,
            rules: JSON.stringify(rules),
            readOnly
          }
        });
      },
      copyPublicKey() {
        const {
          publicKey,
          copyToClipboard,
        } = this;

        copyToClipboard(publicKey, true, {
          title: this.$t('copy.notification'),
          successMessage: this.$t('copy.the_public_key_copied'),
          failMessage: this.$t('copy.the_public_key_not_copied'),
        });
      },
      copySecretKey() {
        const {
          secretKey,
          copyToClipboard,
        } = this;

        copyToClipboard(secretKey, true, {
          title: this.$t('copy.notification'),
          successMessage: this.$t('copy.the_private_key_copied'),
          failMessage: this.$t('copy.the_private_key_not_copied'),
        });
      },
      handleCancelCreate() {
        const {
          clearAllData,
          fetchApiKeys,
          hasApiKeys,
        } = this;
        if (hasApiKeys) {
          fetchApiKeys();
        }
        setTimeout(() => {
          clearAllData();
        }, 500);
      },
      back () {
        this.$emit('return')
        this.setCreatedApiKeys({})
        this.handleCancelCreate()
      }
    },
  }
</script>

<template>
<div class="apiKeysCreate">
  <Container class="apiKeysCreate__container">
    <a class="apiKeysCreate__return" @click.prevent="back">
      <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.07812 0.583127C6.81906 0.454574 6.50723 0.476409 6.27015 0.639703L0.825704 4.3897C0.621307 4.53048 0.5 4.75779 0.5 5C0.5 5.24221 0.621307 5.46952 0.825704 5.6103L6.27015 9.3603C6.50723 9.52359 6.81906 9.54543 7.07812 9.41687C7.33717 9.28832 7.5 9.03094 7.5 8.75V1.25C7.5 0.969062 7.33717 0.71168 7.07812 0.583127Z" fill="#6750A4" stroke="#6750A4" stroke-linejoin="round"/>
      </svg>
      {{ $t('orphan.return') }}
    </a>
    <p class="apiKeysCreate__title">
        {{ $t(hasApiKeys ? 'api_keys.api_key_created' : 'api_keys.creating_an_api_key') }}
    </p>
    <div class="create-new-api-keys-modal__container" v-show="!hasApiKeys">
      <div class="create-new-api-keys-modal__row">
        <base-input
            class="create-new-api-keys-modal__api-keys-value-input"
            :status="aliasStatus"
            :label="$t('api_keys.name_of_the_api_key')"
            :placeholder="$t('api_keys.enter_a_new_api_key_name')"
            v-model="alias"
            :max-length="255"
        />
        <p class="error" v-if="aliasError">{{ aliasError }}</p>
      </div>
      <div class="rules">
        <div class="rules__radio">
          <at-radio-group v-model="rightsType">
            <at-radio :label="$options.FULL_RIGHTS">{{ $t('api_keys-create.full-rights') }}</at-radio>
            <at-radio :label="$options.PARTIAL_RIGHTS">{{ $t('api_keys-create.partial-rights') }}</at-radio>
            <at-radio :label="$options.READ_ONLY">{{ $t('api_keys-create.read-only') }}</at-radio>
          </at-radio-group>
        </div>
        <div class="rules__list-wrapper">
          <div class="rules__item" v-for="(item, key) in rules" :key="key">
            <div class="rules__title">{{ $t(`api_keys-create.${key.toLowerCase()}`) }}</div>
            <div class="rules__list">
              <!--               entity type: VIEW UPDATE CREATE REMOVE PAYMENT  -->
              <base-checkbox
                  v-model="rules[key][entityKey]"
                  :value="rules[key][entityKey]"
                  v-for="(entity, entityKey) in item"
                  :key="`${entityKey}-${rules[key][entityKey]}`"
                  :label="$t(`api_keys-create.${entityKey.toLowerCase()}`)"
                  @input="checkRights"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="apiKeysCreate__ips">
        <div class=" create-new-api-keys-modal__row create-new-api-keys-modal__api-keys-item">
          <base-textarea
              сlas="textarea"
              v-model="ipsString"
              :placeholder="$t('api_keys.ip_addresses_placeholder')"
              :label="$t('api_keys.ip_addresses')"
              size="large"
              resize="none"
          />
          <p class="error" v-if="ipsError">{{ ipsError }}</p>
        </div>
        <div class=" create-new-api-keys-modal__row create-new-api-keys-modal__api-keys-item">
          <base-textarea
              сlas="textarea"
              v-model="ipv6String"
              :placeholder="$t('api_keys.ipv6_addresses_placeholder')"
              :label="$t('api_keys.ipv6_addresses')"
              size="large"
              resize="none"
          />
          <p class="error" v-if="ipv6Error">{{ ipv6Error }}</p>
        </div>
      </div>
    </div>
    <div class="create-new-api-keys-modal__container" v-show="hasApiKeys">
      <div class="create-new-api-keys-modal__api-keys-item">
        <div class="create-new-api-keys-modal__api-keys-value">
          <base-input
              class="create-new-api-keys-modal__api-keys-value-input inputSecondary"
              :value="publicKey"
              :label="$t('api_keys.public_key')"
              :placeholder="$t('api_keys.enter_a_new_api_key_name')"
              readonly
          />
          <copy class="copy-key" :value="publicKey"/>
        </div>
      </div>
      <div class="create-new-api-keys-modal__api-keys-item">
        <div class="create-new-api-keys-modal__api-keys-value">
          <base-input
              class="create-new-api-keys-modal__api-keys-value-input inputSecondary"
              :value="secretKey"
              :label="$t('api_keys.private_key')"
              readonly
          />
          <copy class="copy-key" :value="secretKey"/>
        </div>
      </div>
      <div class="alert">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.80977 0.21967C6.95042 0.0790176 7.14118 0 7.3401 0H16.6599C16.8588 0 17.0496 0.0790176 17.1902 0.21967L23.7803 6.80977C23.921 6.95042 24 7.14118 24 7.3401V16.6599C24 16.8588 23.921 17.0496 23.7803 17.1902L17.1902 23.7803C17.0496 23.921 16.8588 24 16.6599 24H7.3401C7.14118 24 6.95042 23.921 6.80977 23.7803L0.21967 17.1902C0.0790176 17.0496 0 16.8588 0 16.6599V7.3401C0 7.14118 0.0790176 6.95042 0.21967 6.80977L6.80977 0.21967ZM7.65076 1.5L1.5 7.65076V16.3492L7.65076 22.5H16.3492L22.5 16.3492V7.65076L16.3492 1.5H7.65076Z" fill="#BE9B40"/>
          <path d="M10.5023 16.5C10.5023 15.6716 11.1739 15 12.0023 15C12.8307 15 13.5023 15.6716 13.5023 16.5C13.5023 17.3284 12.8307 18 12.0023 18C11.1739 18 10.5023 17.3284 10.5023 16.5Z" fill="#BE9B40"/>
          <path d="M10.6493 7.49256C10.5693 6.69343 11.1969 6 12 6C12.8031 6 13.4307 6.69343 13.3507 7.49256L12.8246 12.7537C12.7823 13.1774 12.4258 13.5 12 13.5C11.5742 13.5 11.2177 13.1774 11.1754 12.7537L10.6493 7.49256Z" fill="#BE9B40"/>
        </svg>
        <p class="alert__message">{{ be_sure_to_keep }}</p>
      </div>
    </div>
    <div class="apiKeysCreate__button" v-show="!hasApiKeys">
      <base-button
          class="button"
          type="primary"
          :label="$t('api_keys.create')"
          @click="handleCreateRequest"
      />
    </div>
  </Container>
</div>
</template>

<style scoped lang="scss">
.rules {
  font-family: $Gilroy;
  &__title {
    font-weight: 500;
    font-size: $h4;
    margin-bottom: 20px;
    color: var(--new-front-primary-font-color);
  }
  &__list {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    &-wrapper {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 10px;
      row-gap: 0;
      @media screen and (max-width: 900px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media screen and (max-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    &::v-deep {
      .checkbox {
        margin-bottom: 20px;
      }
      .checkbox-label {
        font-weight: 500;
      }
    }
  }
  &__radio {
    margin: 30px 0;
  }
}
.apiKeysCreate {
  font-family: $Gilroy;
  &__return {
    display: flex;
    align-items: center;
    color: var(--new-front-bg-btn-primary-bg-modal-primary);
    font-size: $h4;
    font-weight: 500;
    margin-bottom: $p30;
    gap: 10px;
  }
  &__title {
    font-family: $Gilroy;
    font-size: $h2;
    color: var(--new-front-primary-font-color);
    font-weight: 500;
    margin-bottom: 30px;
    width: 100%;
    @media screen and (max-width: 950px) {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  &__ips {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-bottom: 50px;
  }
  &__button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .base-btn {
      max-width: 280px;
    }
  }
}
// Radio styles
::v-deep {
  .at-radio {
    .at-radio__label {
      font-family: $Gilroy;
      font-size: $h4;
      font-weight: 500;
      color: var(--new-front-primary-font-color);
    }
    .at-radio__inner {
      border: 2px solid var(--new-front-input-border);
      background: var(--new-front-main-bg);
      width: 20px;
      height: 20px;
    }
    .at-radio__inner.at-radio--checked {
      border-color: var(--new-front-bg-btn-primary-bg-modal-primary);
    }
    .at-radio__inner::after {
      width: 11px;
      height: 11px;
      background: var(--new-front-bg-btn-primary-bg-modal-primary);
    }
  }
}
@media screen and (max-width: 576px){
  .apiKeysCreate {
    &__ips {
      grid-template-columns: 1fr;
    }
  }
  .apiKeysCreate__container {
    padding: 20px;
  }
  ::v-deep {
    .at-radio-group {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
      .at-radio+.at-radio {
        margin-left: 0;
      }
    }
    .apiKeysCreate__button .base-btn {
      max-width: 100%;
    }
  }
}
.error {
  color: var(--new-front-input-font-color-error);
  width: 100%;
}
.alert {
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 15px;
  &__message {
    font-weight: $Gilroy;
    color:  #BE9B40;
    font-size: 16px;
  }
}
.create-new-api-keys-modal__api-keys-value {
  display: flex;
  align-items: center;
  width: 100%;
  word-break: break-word;
  position: relative;
  margin-bottom: 20px;
}
.copy-key {
  position: absolute;
  right: 0;
  top: 33px;
}
</style>
