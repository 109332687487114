<script>
  import AddressRisks from "@/public/components/elements/address-risks/AddressRisks.vue";
  import SvgDust from "@/public/components/elements/dashboard/assets/SvgDust.vue";
  import SvgDustClear from "@/public/components/elements/dashboard/assets/SvgDustClear.vue";
  import {getNetworkShortNameByNetworkName, getNetworkFullNameByNetworkName} from "@/public/filters";
  import NetworkImg from "@/public/components/common/uiKit/networkImg";
  import CurrencyImg from "@/public/components/common/uiKit/currencyImg";
  import {mapGetters} from "vuex";
  import Copy from "@/public/components/common/copy.vue";
  import DashboardCurrencyIcon from "@/public/components/elements/dashboard/DashboardCurrencyIcon.vue";
  import DashboardNetworkIcon from "@/public/components/elements/dashboard/DashboardNetworkIcon.vue";
  import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
  import Tooltip from "@/public/components/common/uiKit/tooltip/Tooltip.vue";

  export default {
    name: "DashboardBalance",
    methods: {getNetworkShortNameByNetworkName, getNetworkFullNameByNetworkName},
    props: {
      item: {
        type: Object,
        default: () => ({})
      },
      currentTab: {
        type: String,
        default: "",
      }
    },
    components: {
      Tooltip,
      BaseButton,
      DashboardNetworkIcon,
      DashboardCurrencyIcon, Copy, CurrencyImg, NetworkImg, SvgDustClear, SvgDust, AddressRisks},
    computed: {
      ...mapGetters('dashboard', ['currentSymbol', 'amountCurrencyString']),
      isCurrencyTab() {
        return this.currentTab === 'currency'
      },
      title () {
        return this.isCurrencyTab ? this.item.alias : getNetworkFullNameByNetworkName(this.item.name)
      },
      ticker () {
        return this.isCurrencyTab ? this.item.currency : `network ${getNetworkShortNameByNetworkName(this.item.name)}`
      },
      // amount () {
      //   return this.isCurrencyTab ? this.item.amount : this.item.amount
      // },

      currencyAmount () {
        return this.item[this.amountCurrencyString]
      },
      limitItems () {
        return this.item.items.slice(0, 3)
      },
      overItems () {
        return this.item.items.slice(3)
      },
      dustAmount () {
        if (!this.item.dust) return false
        return this.item.dust[this.amountCurrencyString]
      }
    }
  }
</script>

<template>
<div class="balance">
  <div>
    <div class="balance__header">
      <div class="balance__header-left">
        <DashboardCurrencyIcon v-if="isCurrencyTab" :currency="item.currency" />
        <DashboardNetworkIcon v-else :network="item.name" />
        <div class="balance__header-currency">
          <div class="balance__header-alias">{{ title }}</div>
          <div class="balance__header-ticker">{{ ticker }}</div>
          <div v-if="!isCurrencyTab" class="balance__header-ticker">
            {{ item.items[0].address | preparedString }} <copy :value="item.items[0].address" />
          </div>
        </div>
      </div>
      <div class="balance__header-right">
        <div class="balance__header-alias align-right">
          <template v-if="!isCurrencyTab">
            {{ currentSymbol }}{{ currencyAmount }}
          </template>
          <template v-if="isCurrencyTab">
            {{ item.amount }} {{ ticker }}
          </template>
        </div>
        <div v-if="isCurrencyTab" class="balance__header-ticker align-right">
          {{ currentSymbol }}{{ currencyAmount }}
        </div>
      </div>
    </div>
    <div class="balance__risk">
      <div class="balance__risk-block">
        <address-risks :status="item.riskLevel" />
        <Tooltip trigger="hover" class="">
          <div class="tooltip">
            <div class="tooltip__title">{{ $t('payments.risk-tooltip-title') }}</div>
            <div class="tooltip__desc">{{ $t('payments.risk-tooltip-desc') }}</div>
          </div>
        </Tooltip>
      </div>

      <div class="balance__risk-dust" v-if="dustAmount">
        <SvgDustClear />
        <div class="balance__risk-dust-title">{{ $t('payments.dust') }}:</div>
        <div class="balance__risk-dust-value">
          {{ currentSymbol }}{{ dustAmount }}
          <!--     Нет данных для процента     -->
          <!--     <span class="orange-text">({{ }}%)</span>-->
        </div>
        <Tooltip trigger="hover" class="">
          <div class="tooltip">
            <div class="tooltip__title">{{ $t('payments.dust-tooltip-title') }}</div>
            <div class="tooltip__desc">{{ $t('payments.dirty-tooltip-desc') }}</div>
          </div>
        </Tooltip>
      </div>
    </div>
    <div class="balance__list">
      <div class="balance__item" v-for="(balance, index) in limitItems" :key="`${balance.id}-${index}-${item.id}`">
        <div class="balance__item-left">
          <NetworkImg :network="balance.name" :size="'big'" v-if="isCurrencyTab" />
          <CurrencyImg :currency="balance.currency" :size="'big'" v-else />
          <template v-if="isCurrencyTab">
            <div class="balance__item-ticker">{{ balance.name | getNetworkFullNameByNetworkName }}</div>
            <div class="balance__item-network">{{ balance.name | getNetworkShortNameByNetworkName }}</div>
          </template>
          <template v-else>
            <div class="balance__item-ticker">{{ balance.currencyAlias }}</div>
            <div class="balance__item-network">({{ balance.currency }})</div>
          </template>
        </div>
        <div class="balance__item-right">
          {{ balance.amount }} {{ isCurrencyTab ? item.currency : balance.currency }}
        </div>
      </div>
    </div>
  </div>

  <base-button type="primary" class="balance__open" :label="$t('payments.more')" @click="$emit('openMore', item)" />
</div>
</template>

<style scoped lang="scss">
.balance__risk-block {
  display: flex;
  align-items: center;
  gap: 5px;
}
.tooltip {
  text-align: left;
  &__title {
    color: var(--text-text-primary, #000);
    font-family: $Gilroy;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__desc {
    color: var(--text-text-primary, #000);
    font-family: $Gilroy;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 14.3px */
    letter-spacing: -0.15px;
  }
}
.balance {
  padding: 20px;
  border-radius: 15px;
  border: 1px solid var(--border-border-input-primary-default);
  background: var(--bg-background-secondary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
    &-alias {
      color: var(--text-text-primary);
      font-size: 20px;
      font-weight: 500;
      line-height: 100%;
      margin-bottom: 3px;
    }
    &-ticker {
      color: var(--text-color-text-secondary, #8B81A0);
      font-size: 16px;
      font-weight: 500;
      line-height: 100%;
      display: flex;
      align-items: center;
    }
    &-left {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    &-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  &__risk {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    padding-bottom: 15px;
    margin-bottom: 5px;
    &-dust {
      display: flex;
      align-items: center;
      gap: 5px;
      color: var(--text-color-text-tertiary, #928F98);
      &-value {
        display: flex;
        align-items: center;
        color: var(--text-text-primary);
      }
    }
    &:after {
      content: "";
      position: absolute;
      height: 1px;
      width: calc(100% + 40px);
      left: -20px;
      bottom: 0;
      border-bottom: 1px dashed var(--border-border-input-primary-default);
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid var(--border-border-input-primary-default);
    &:last-child {
      border-bottom: 0;
    }
    &-left {
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 16px;
      font-weight: 500;
      line-height: 100%;
    }
    &-right {
      color: var(--text-text-primary);
      font-size: 16px;
      font-weight: 500;
      line-height: 100%;
    }
    &-ticker {
      font-weight: 500;
      color: var(--text-text-primary);
    }
    &-network {
      font-weight: 500;
      color: var(--text-color-text-secondary);
    }
  }
  &__open {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    color: var(--text-text-primary-accent, #744DB2);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-top: 15px;
    cursor: pointer;
  }
}
.align-right {
  justify-content: flex-end;
}
@media screen and (max-width: 900px) {
  .balance__header-alias {
    font-size: 16px;
  }
  .balance__header-ticker {
    font-size: 13px;
  }
  .balance__item-right {
    font-size: 13px;
  }
  .balance__item-left {
    font-size: 13px;
  }
}
.orange-text {
  color: var(--ocp-status-medium-medium-orange-primary);
}
</style>
