import changeAddressBookMutation from "../../../../graphQl/mutations/changeAddressBook";

export const changeAddressBook = async ({dispatch, rootGetters}, payload) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    let changeAddress;

    try {
        changeAddress = await dispatch('app/request', {
            type: 'mutation',
            gql: changeAddressBookMutation,
            variables: {
                organizationId,
                ...payload,
            },
        }, {root: true});
    } catch (error) {
        return error;
    }

    if (changeAddress.message) {
        throw changeAddress.message;
    }

    if (changeAddress.errors) {
        throw changeAddress.errors;
    }

    return changeAddress;
};