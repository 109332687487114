import removeOrganizationRoleMutation from "/public/graphQl/mutations/removeOrganizationRole.js";

export const deleteOrganizationRole = async ({commit, dispatch, rootGetters,rootState, state}) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    let currenciesData;

    let variables = {
        organizationId,
        organizationRoleId: state.detailRoleId,
    };

    try {
        if (!rootState.app.isLoading) {
            commit('app/setLoading', true, {root: true});
        }
        currenciesData = await dispatch('app/request', {
            type: 'query',
            gql: removeOrganizationRoleMutation,
            variables,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/setLoading', false, {root: true});
    }

    if (currenciesData.message) {
        throw currenciesData.message;
    }

    if (currenciesData.errors) {
        throw currenciesData.errors;
    }

    return true;
};
