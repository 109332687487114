<template>
  <btn-toggler v-model="langModel">
    <template #left-btn-icon="{ active }">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g clip-path="url(#clip0_20537_59548)">
          <path d="M0 0H20V20H0V0Z" fill="#004DE0"/>
          <path d="M2.34375 0L9.96875 7.54167L17.5625 0H20V2.58333L12.5 10.0417L20 17.4583V20H17.5L10 12.5417L2.53125 20H0V17.5L7.46875 10.0833L0 2.66667V0H2.34375Z" fill="white"/>
          <path d="M13.25 11.7083L20 18.3333V20L11.5312 11.7083H13.25ZM7.5 12.5417L7.6875 14L1.6875 20H0L7.5 12.5417ZM20 0V0.125L12.2188 7.95833L12.2812 6.125L18.4375 0H20ZM0 0L7.46875 7.33333H5.59375L0 1.75V0Z" fill="#FE1200"/>
          <path d="M7.53125 0V20H12.5312V0H7.53125ZM0 6.66667V13.3333H20V6.66667H0Z" fill="white"/>
          <path d="M0 8.04167V12.0417H20V8.04167H0ZM8.53125 0V20H11.5312V0H8.53125Z" fill="#FE1200"/>
        </g>
        <defs>
          <clipPath id="clip0_20537_59548">
            <rect width="20" height="20" rx="10" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </template>
    <template #right-btn-icon="{ active }">
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <g clip-path="url(#clip0_20537_59551)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1 1H21V21H1V1Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1 7.6665H21V20.9998H1V7.6665Z" fill="#004DE0"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1 14.3335H21V21.0002H1V14.3335Z" fill="#FE1200"/>
        </g>
        <rect x="0.5" y="0.5" width="21" height="21" rx="10.5" stroke="#E0E2E7"/>
        <defs>
          <clipPath id="clip0_20537_59551">
            <rect x="1" y="1" width="20" height="20" rx="10" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </template>
  </btn-toggler>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { i18n } from "../../../../i18n";
import router from "../../../../router/router";
import BtnToggler from "@/public/components/common/uiKit/togglers/BtnToggler";

export default {
    name: "HeaderLanguage",
    components: {
        BtnToggler
    },
    computed: {
        ...mapState('user', [
            'user',
        ]),
        langModel: {
          get() {
            return this.user.lang === 'ru'
          },
          set(v) {
            let selected = v ? 'ru' : 'en'
            this.selectLang(selected)
          }
        }
    },
    created() {
      this.setLanguageFromCookie()
    },
    methods: {
        ...mapActions('user', [
            'changeLangQuery',
            'fetchUser',
        ]),
        setLanguageFromCookie() {
          const cookiesObj = Object.fromEntries(document.cookie.split('; ').map(v=>v.split(/=(.*)/s).map(decodeURIComponent)))
          if (cookiesObj.lang) {
            this.selectLang(cookiesObj.lang)
          }
        },
        selectLang(value) {
            this.changeLangQuery({lang: value}).then(() => {
                this.fetchUser().then(() => {
                    i18n.locale = this.user.lang;
                    router.updateTitle();
                    try {
                        document.cookie = "updateLang=true";
                        document.cookie = `lang=${this.user.lang}`;
                    } catch (e) {
                        console.log('changeLang.js', e);
                    }
                })
            })
        }
    }
}
</script>