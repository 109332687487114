<template>
    <store-modal
      name="ChoiceStepModal"
      class="choice-step-modal"
    >
      <template #header>
        <span class="choice-step-modal__header">
          {{ title }}
        </span>
      </template>
      <template #body>
        <div class="choice-step-modal__body">
          <advanced-balances-method-select
            v-model="value"
            :label="$t('advance_balances.choose-deposit-method')"
            :placeholder="$t('bridge.network_select.placeholder')"
            class="method-select item"
            :list="filteredMethodsList"
          >
            <template #selected="{ alias, amount, isAllowed }">
              <div :class="['method-select-selected', !isAllowed ? 'disabledItem' : '']">
                <span>{{ alias }}</span>
                <span>{{ amount }}</span>
              </div>
            </template>
            <template #info="{ alias, amount, isAllowed }">
              <div :class="['method-select-option-item', !isAllowed ? 'disabledItem' : '']">
                <span>{{ alias }}</span>
                <span>{{ amount }}</span>
              </div>
            </template>
          </advanced-balances-method-select>
        </div>
      </template>
      <template #footer>
        <div class="choice-step-modal__footer">
          <base-button
            :label="$t('advance_balances.btn-cancel')"
            type="secondary"
            @click="handleCancelCreate"
          />
          <base-button
            :label="$t('advance_balances.btn-continue')"
            type="primary"
            @click="handleCreateRequest"
          />
        </div>
      </template>
    </store-modal>
</template>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex';

import BaseButton from "/public/components/common/uiKit/button/modals/bg-primary";
import BaseModal from "/public/components/modals/BaseModal";
import AdvancedBalancesMethodSelect from "/public/components/elements/advance-balances/bh-select";
import StoreModal from "/public/components/modals/StoreModal";
    import BigNumber from "bignumber.js";

export default {
    name: 'ChoiceStepModal',
    components: {
        AdvancedBalancesMethodSelect,
        BaseButton,
        BaseModal,
        StoreModal,
    },
    data() {
        return {
            value: {
              id: 'externalWallet',
              alias: this.$t('advance_balances.external_wallet')
            },
          balancesSummary: {
            business: [],
            payin: [],
            payout: [],
            personal: [],
            recurrent: []
          },
        };
    },
    watch: {
      openedModal(v) {
        if (!v) {
          this.value = this.methodList[0] //сбрасываем выбранный метод на дефолтный при закрытии окна
        }
      }
    },
  mounted() {
    this.getSummary()
  },
    computed: {
        ...mapState('user', [
            'user',
        ]),
      ...mapState('summary', [
        'summary',
      ]),
        ...mapState('app', {
            payload: state => state.modal.payload,
            openedModal: state => state.modal.opened
        }),
      ...mapState('app', ['currencies']),
        ...mapState('accounts', {
            accounts: state => state.accounts,
        }),
      ...mapState('app', [
        'selectedOrganizationId'
      ]),
        ...mapGetters('organizations', ['userRoleRules']),
      stableCoins ({currencies}) {
          return currencies.reduce(( acc, curr ) => {
          if (curr.networks) {
            const { stablecoin } = curr

            if (stablecoin) {
              curr.networks.forEach(network => {
                  const currencyWithNetwork = {
                    id: `${curr.id}_${network.id}`,
                    currency: curr,
                    network: network,
                  }

                  acc.push(currencyWithNetwork)
              })
            }
          }

          return acc
        }, [])
      },
        title() {
            return this.$t('advance_balances.replenishment_method');
        },
        isAllowedPayIn () {
            return this.userRoleRules['wallets_payin'].entities.make_transfer
        },
        isAllowedBusiness () {
            return this.userRoleRules['wallets_business'].entities.make_transfer
        },
        isAllowedRecurrent () {
            return this.userRoleRules['recurrent'].entities.make_transfer
        },
        filteredMethodsList () {
            return this.methodList.filter(({isAllowed}) => isAllowed)
        },
      accountId({ accounts }) {
        return accounts.find(({ accountId }) => !!accountId).accountId;
      },
        methodList() {
            return [
                {
                    id: 'externalWallet',
                    alias: `${this.$t('advance_balances.external_wallet')}`,
                    isAllowed: true
                },
                {
                    id: 'internalPayInBalance',
                    alias: `${this.$t('advance_balances.internal_pay-in-balance')}`,
                    amount: `~${this.balancesSummary['payin']} $`,
                    method: 'getAccountBalancesPayin',
                    isAllowed: this.isAllowedPayIn
                },
                {
                    id: 'internalBusinessWallet',
                    alias: `${this.$t('advance_balances.internal_business-wallet')}`,
                    amount: `~${this.balancesSummary['business']} $`,
                    method: 'getAccountBalancesBusiness',
                    isAllowed: this.isAllowedBusiness
                },
                {
                    id: 'internalRecurrentAddress',
                    alias: `${this.$t('advance_balances.internal_recurrent-address')}`,
                    amount: `~${this.balancesSummary['recurrent']} $`,
                    method: 'getAccountBalancesRecurrent',
                    isAllowed: this.isAllowedRecurrent
                },
            ];
        },
    },
    methods: {
        ...mapActions('accounts', [
            'accountDepositAddress',
            "clearDeposit",
        ]),

        ...mapActions('app', [
            'closeModal',
            'openModal',
        ]),
      ...mapActions('balances', [
        'getAccountBalancesPayin',
        'getAccountBalancesBusiness',
        'getAccountBalancesRecurrent'
      ]),
      ...mapActions('payoutBalances', [
        'fetchPayoutBalances'
      ]),
      ...mapActions('personalAddresses', [
        'getAddressesForPerson'
      ]),
        async getSummary () {
          const { accountId, selectedOrganizationId: organizationId, stableCoins } = this

          const walletsNames =  [ 'business', 'payin', 'recurrent' ]

          const balancesRequests = await Promise.all([
            this.getAccountBalancesBusiness({ accountId, organizationId }).catch(() => {}),
            this.getAccountBalancesPayin({ accountId, organizationId }).catch(() => {}),
            this.getAccountBalancesRecurrent({ organizationId }).catch(() => {})
          ])

          walletsNames.forEach((walletName, index) => {
            const summary = balancesRequests[index].length ? balancesRequests[index].reduce(( acc, addr ) => {
              if (!this.stableCoins.find(({currency}) => currency.id === addr.currencyId)) return acc
              acc += Number(addr.balance)
              return acc
            }, 0) : 0
            this.balancesSummary[walletName] = new BigNumber(summary).decimalPlaces(8).toFixed()
          })
        },
        handleCreateRequest() {
            const { openModal, value } = this;
            let accountId = this.payload.accountId;

            let currencyBalanceId = this.payload.currencyBalanceId;
            if ( value.id.includes('internal') ) {
                this.closeModal();

                openModal({
                    name: 'PayoutModalTransfer',
                    payload: {
                        accountId: accountId,
                        currencyBalanceId: currencyBalanceId,
                        depositMethodName: value.id,
                        depositMethodLink: value.method,
                        depositMethodAlias: value.alias
                    },
                });
            } else {
                this.closeModal();

                openModal({
                    name: 'DepositRequestModal',
                    payload: {
                        accountId: accountId,
                        currencyBalanceId: currencyBalanceId,
                    },
                });
            }
        },

        handleCancelCreate() {
            this.closeModal();
        },
    },
};
</script>

<style lang="scss" scoped>
  .choice-step-modal {
    font-family: var(--new-front-font-family);

    &__header {
      font-size: $h1; //32px
      font-weight: 500;
      text-align: center;
      color: var(--new-front-text-primary-bg-modal-primary);
    }
    &__body {
      padding: 0 20px;
      margin-bottom: 30px;

      .method-select {
        &-selected {
          font-size: $h4; //16px
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: 10px;
        }
        &-option-item {
          font-size: $h4; //16px
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: 10px;
        }
      }
    }
    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .base-btn {
        margin-top: 10px;
        max-width: 280px;
        min-width: 260px;
      }
    }

    @include below_phone(){
      &__header {
        font-size: $h3; //20px
      }

      &__body {
        .method-select {
          &-selected {
            font-size: $h6; //13px
          }
          &-option-item {
            font-size: $h4; //16px
          }
        }
      }

      &__footer {
        justify-content: center;
        flex-wrap: wrap;

        .base-btn {
          margin-top: 10px;
          min-width: 100%;
          max-width: 100%;
        }
      }
    }
  }
</style>
