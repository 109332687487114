<template>
<base-select
        v-bind="$attrs"
        v-model="valueModel"
        :label="label"
        :placeholder="placeholder"
        :list="list"
        :with-search="false"
        class="advanced_balances__network-select"
        @closed="search = undefined"
>
    <template #selected="data">
        <item
            v-bind="data"
        />
    </template>
    <template #info="data">
        <item
            v-bind="data"
            height="22px"
        />
    </template>
</base-select>
</template>

<script>
    import { mapState } from "vuex";

    export default {
        name: "organisationRoleSelect",
        components: {
            BaseSelect: () => import(/* webpackChunkName: "/public/components/elements/advance-balances/bh-select" */ '/public/components/elements/advance-balances/bh-select'),
            Item: () => import(/* webpackChunkName: "/public/components/elements/advanced-balances/NetworkAndCurrenciesList/Item" */ './UserSelectItem.vue'),
        },
        data() {
            return {
                search: undefined
            }
        },
        props: {
            value: {
                type: [ String, Object ],
                default: () => {}
            },
            invoiceAmount: {
                type: String,
                default: ''
            },
            invoiceCurrency: {
                type: String,
                default: ''
            },
            list: {
                type: Array,
                default: () => ([])
            },
            withSearch: {
                type: Boolean,
                default: false
            },
            label: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: ''
            }
        },
        computed: {
            ...mapState({
                currentCurrencyInfo: state => state.currency,
            }),
            valueModel: {
                get({ value }) {
                    return value
                },
                set(v) {
                    this.$emit('input', v)
                }
            },
            // filteredList({ list, search }) {
            //     //метод фильтрации общего списка валют
            //     if (search) {
            //         return list.filter((netCurr = {}) => {
            //             const currencyName = netCurr['currency'] ? netCurr['currency'].toLowerCase() : ''
            //             const currencyAlias = netCurr['alias'] ? netCurr['alias'].toLowerCase() : ''
            //             const networkName = ( netCurr['networks'] && netCurr['networks']['name'] ) ? netCurr['networks']['name'].toLowerCase() : ''
            //             const networkAlias = ( netCurr['networks'] && netCurr['networks']['alias'] ) ? netCurr['networks']['alias'].toLowerCase() : ''
            //
            //             return networkName.includes(search.toLowerCase()) || networkAlias.includes(search.toLowerCase()) || currencyName.includes(search.toLowerCase()) || currencyAlias.includes(search.toLowerCase())
            //         })
            //     } else {
            //         return list
            //     }
            // }
        },
    }
</script>

<style lang="scss">
.advanced_balances {
  &__network-select {
    margin: 20px 0;

    .search {
      margin-bottom: 15px;
    }
  }
}
.advanced_balances__network-select {
    ::v-deep  .label {
        color: var(--new-front-input-label-font-color)!important;
    }
}
</style>
