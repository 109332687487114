import gql from 'graphql-tag';

export default gql`
    mutation ($organizationId: String!, $accountId: String!, $addressId: String!, $amount: String!) {
        requestFeeForTransfer(organizationId: $organizationId, accountId: $accountId,addressId:$addressId, amount:$amount) {
            code,
            result {
                source
                currencyId
                currencyTicker
                amount
                usdAmount
                until
                token
                tariffFee,
                tariffFeeUSD,
                networkFee,
                networkFeeUSD
            }
        }
    }
`;
