import {v4 as UUID} from 'uuid';

import requestOrphanFee from "@/public/graphQl/mutations/requestOrphanFee";

export const fetchOrphanFee = async ({ state, commit, dispatch, rootGetters }, payload) => {
    if(state.fee.pending) {
        return;
    }

    const id = UUID();

    commit('setFeeRequestId', id);
    commit('setFeePending', true);

    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    try {
        const withdrawalRequestData = await dispatch('app/request', {
            type: 'mutation',
            gql: requestOrphanFee,
            variables: {
                organizationId,
                advancedBalanceId: payload.advancedBalanceId,
                orphanDepositId: payload.orphanDepositId,
            },
        }, { root: true });

        commit('setFeePending', false);

        if (withdrawalRequestData.errors || withdrawalRequestData.data.orphanWithdrawalToken.result === null) {
            commit('setFeeError', withdrawalRequestData.errors[0]);
        } else {
            const {data: {orphanWithdrawalToken: {code, result}}}  = withdrawalRequestData;
            commit('setFee', {
                code: code,
                currency: result.currencyId,
                amount: result.amount,
                amountToReceive: result.amountTo,
                // usdAmount: result.amount,
                until: result.expiresAt,
                source: result.feeSource,
                token: result.token,
                tariffFee: result.serviceFee,
                tariffFeeUSD: result.serviceFeeUSD,
                networkFee: result.blockchainFee,
                networkFeeUSD: result.blockchainFeeUSD,
            });
        }

        commit('setFeeComplete', true);
    } catch (error) {
        if(id !== state.feeRequestId) {
            return;
        }

        commit('setFeePending', false);
        commit('setFeeComplete', true);

        return error;
    }
};
