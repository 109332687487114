import gql from 'graphql-tag';

export default gql`
    mutation(
        $organizationId: String!,
        $advancedBalanceId: String!,
        $address: String!,
        $comment: String,
        $webhookUrl: String,
        $token: String!,
        $key: String!,
        $otpToken: String!
    ) {
        orphanWithdrawal(
            organizationId: $organizationId,
            advancedBalanceId: $advancedBalanceId,
            address: $address,
            comment: $comment,
            webhookUrl: $webhookUrl,
            token: $token,
            key: $key,
            otpToken: $otpToken
        ) {
            code
            result {
                id
                organizationId
                orderId
                stage
                status
                message
                currencyId
                networkId
                amount
                canWithdrawal
                inTransaction {
                    addressType
                    addressId
                    address
                    txId
                    amount
                    status
                    createdAt
                }
                outTransaction  {
                    address
                    txId
                    amount
                    status
                    withdrawalId
                    createdAt
                }
                createdAt
            }
        }
    }
`;
