import getCrosschainTransfersGQL from "../../../../graphQl/queries/getCrosschainTransfers";

export const getCrosschainTransfersList = async ({commit, dispatch, rootGetters}, payload) => {
  const selectedOrganizationId = rootGetters['app/getSelectedOrganizationId'];
  let response;

  const variables = {
    ...payload,
    organizationId: selectedOrganizationId
  }

  try {
    response = await dispatch('app/request', {
      type: 'query',
      gql: getCrosschainTransfersGQL,
      variables,
    }, {root: true});
  } catch (error) {
    return error;
  }

  return ( response.data && response.data.crosschainTransfers ) ? response.data.crosschainTransfers : {}
};
