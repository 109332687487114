<template>
    <div class="organization-list">
        <OrganisationListHeader/>
        <OrganisationListBody/>
    </div>
</template>

<script>
import OrganisationListHeader from "./OrganisationListHeader";
import OrganisationListBody from "./OrganisationListBody";

export default {
    name: 'OrganizationList',

    components: {
        OrganisationListHeader,
        OrganisationListBody,
    },

};
</script>

<style scoped lang="scss">
.organization-list {
  overflow: auto;
  width: 100%;
}
</style>