<template>
  <div class="donation">
    <AtButton
        class="donation__all"
        type="primary"
        icon="icon-arrow-left"
        @click="goBack"
    >
      {{ $t('order.return') }}
    </AtButton>
    <h1 class="donation__title">
      {{ page.alias }}
    </h1>
    <p class="donation__paragraph">
      {{ $t('donation.details') }}
      <br>
    </p>

    <div class="donation__container">

      <donations-top-bar class="donation__top-bar"/>
      <p class="donation__header-title">{{ $t('donation.orders') }}</p>
      <DonationsListHeader class="orders__list-header"/>
      <preview-order v-if="pageOrders.length" v-for="(order, key) in pageOrders"
                     :key="order.id"
                     :name="'donation-order'"
                     class="donation__order"
                     :order-enum="$options.orderEnum"
                     :status-enum="statusesEnum"
                     :mobile-status-text-color="mobileStatusTextColor(order.status, $options.orderEnum)"
                     :data="order"/>
      <pagination v-if="pageOrders.length" :size="'small'"
                  :total="pageOrdersCount"
                  :page-size="pageOrdersSize"
                  :current="currentPage"
                  @page-change="changePage"/>
      <EmptyTable
          v-else
          class="orders__empty"
      >
        {{ $t('orders.no_data_available') }}
      </EmptyTable>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import PreviewOrder from "../components/elements/orders/PreviewOrder";
import {PRE_CHECKOUT_ORDER_STATUS_ENUM} from "../../common/constants/pre-checkout-order-status-enum";
import DonationsTopBar from "../components/elements/donations/donation-top-bar";
import OrderListHeader from "../components/elements/orders/OrdersListHeader";
import pagination from "../components/common/pagination";
import DonationsListHeader from "../components/elements/donations/donations-list-header";
import EmptyTable from "/public/components/elements/EmptyTable";


export default {
    name: "DonationPage",
    orderEnum: PRE_CHECKOUT_ORDER_STATUS_ENUM,
    components: {DonationsListHeader, DonationsTopBar, PreviewOrder, pagination, OrderListHeader, EmptyTable},
    title: 'donation.title',
    data() {
        return {
            offset: 0,
            limit: 10,
        };
    },
    computed: {
        ...mapState('donations', ['pageOrders', 'pageOrdersCount', 'pageOrdersSize', 'pageOrder', 'page']),
        donationId() {
            return this.$route.params.id;
        },
        currentPage() {
            const pageFromUrl = this.$route.query.page;
            if (!pageFromUrl) {
                return 1;
            }

            return Number(pageFromUrl);
        },
        statusesEnum() {

            const statuses = {
                [PRE_CHECKOUT_ORDER_STATUS_ENUM.CREATED]: {
                    title: 'orders.active',
                    colorTag: 'primary',
                },
                [PRE_CHECKOUT_ORDER_STATUS_ENUM.PENDING]: {
                    title: 'orders.in_processing',
                    colorTag: 'primary',
                },
                [PRE_CHECKOUT_ORDER_STATUS_ENUM.PROCESSED]: {
                    title: 'orders.completed',
                    colorTag: 'success',
                },
                [PRE_CHECKOUT_ORDER_STATUS_ENUM.EXPIRED]: {
                    title: 'orders.expired',
                    colorTag: 'default',
                },
                [PRE_CHECKOUT_ORDER_STATUS_ENUM.ERROR]: {
                    title: 'orders.error',

                },
                [PRE_CHECKOUT_ORDER_STATUS_ENUM.REFUND]: {
                    title: 'orders.refund',
                    colorTag: 'warning',
                },
                [PRE_CHECKOUT_ORDER_STATUS_ENUM.REJECT]: {
                    title: 'orders.partial_payment',
                    colorTag: 'error',
                },
            };
            return statuses;
        },

    },
    methods: {
        ...mapActions('donations', [
            'fetchPreCheckoutOrders',
            'fetchPreCheckoutPage',
        ]),
        goBack() {
            if (window.history.length > 2)
                this.$router.go(-1);
            else this.$router.push({name: 'donations'});
        },
        changePage(pageNumber) {
            const {
                $route,
                $router,
                donationId,
                fetchPreCheckoutOrders,
            } = this;

            fetchPreCheckoutOrders({
                pageId: donationId,
                route: $route,
                router: $router,
                page: pageNumber,
            });
        },
        mobileStatusTextColor(status, orderEnum) {
            switch (status) {
            case orderEnum.CREATED:
                return '#6190e8';
            case orderEnum.PENDING:
                return '#6190e8';
            case orderEnum.PROCESSED:
                return '#13ce66';
            case orderEnum.ERROR:
                return '#EB5757';
            case orderEnum.REFUND:
                return '#ffc82c';
            case orderEnum.EXPIRED:
                return '#8F949A';
            case orderEnum.PARTIAL:
                return '#ffc82c';
            case orderEnum.REJECT:
                return '#EB5757';
            default:
                return '#8F949A';
            }
        },
    },
    beforeMount() {
        const {$route, donationId, fetchPreCheckoutPage} = this;
        fetchPreCheckoutPage({pageId: donationId});
        this.fetchPreCheckoutOrders({
            route: $route,
            pageId: donationId,
            showUrlFilters: false,
        });
    },
};
</script>

<style lang="scss" scoped>
.donation {
  max-width: 1420px;
  padding-top: var(--padding-top-container);
  padding-left: var(--padding-left-container);
  padding-right: var(--padding-right-container);


  &__all {
    background: #0059F7;
    margin-bottom: 16px;
    border-radius: 6px;
    border: none;
    width: 100%;
    max-width: 125px;
    min-height: 40px;
    transition: all 0.3s ease-in;

    &:hover {
      background: var(--blue-400);
    }

    ::v-deep .at-btn__icon {
      font-size: var(--font-size-text-main);
    }

    ::v-deep .at-btn__text {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: #fff;
    }

    ::v-deep .at-btn__icon {
      font-size: var(--font-size-text-main);
    }

  }


  &__header-title {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__container {
    box-shadow: var(--shadow-container);
    border-radius: 20px;
    background: var(--secondary-color);
    overflow: hidden;
    margin-top: var(--margin-container-top);
    margin-bottom: var(--margin-container-bottom);
    border: 1px solid var(--border-color);
  }

  &__title {
    margin-bottom: 13px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-h1);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__paragraph {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-h3);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__top-bar {
    padding-bottom: 42px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    border-radius: 20px 20px 0 0;
  }
}

@media screen and (max-width: 950px) {
  .donation {
    &__header-title {
      display: none;
    }

    &__top-bar {
      padding: 16px;
    }
  }

}

@media screen and (max-width: 375px) {
  .donation {
    &__all {
      margin-left: 10px;
    }

    &__title {
      padding-left: 10px;
    }

    &__paragraph {
      padding-left: 10px;
    }
  }


}
</style>