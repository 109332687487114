import sendWebhookQuery from "@/public/graphQl/mutations/sendWebhook";

export const sendWebhook = async ({dispatch, commit, rootState, rootGetters}, webhookId) => {
    let webhookResponse;

    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    try {
        commit('app/toggleLoading', null, {root: true});
        webhookResponse = await dispatch('app/request', {
            type: 'mutation',
            gql: sendWebhookQuery,
            variables: {organizationId, webhookId},
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }
    const { data: { sendWebhook: {code, result, traceId } } } = webhookResponse

    commit('setWebhook', result)
    return webhookResponse;
};
