import gql from 'graphql-tag';

export default gql`
    mutation($token: String!, $enteredCodeTwoFactorAuth: String!) {
        createTokenAuth(token: $token, enteredCodeTwoFactorAuth: $enteredCodeTwoFactorAuth){
                code
                result {
                    code
                }
        }
    }
`;