<template>
  <div class="exchanges-sort-by-currency">
    <p class="title">
      Валюты
    </p>
    <AtSelect
        placeholder="Выберите валюту"

        clearable
        v-model="selectedCurrencies"
        @on-change="changeSelect"
    >
      <AtOption
          v-for="(currency, key) in currencies"
          :key="key"
          :value="currency.currency"
      />
    </AtSelect>
  </div>
</template>

<script>

import {mapState, mapActions} from 'vuex';

import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';

export default {
    name: 'ExchangesSortByCurrency',
    components: {
        AtSelect,
        AtOption,
    },
    data() {
        return {
            selectedCurrencies: [],
        };
    },
    computed: {
        ...mapState('app', [
            'currencies',
        ]),
    },
    beforeMount() {
        this.setCurrenciesFromUrl();
    },
    methods: {
        ...mapActions('exchanges', [
            'fetchExchanges',
        ]),
        setCurrenciesFromUrl() {
            const currenciesFromUrl = this.$route.query.currencies;

            if (!currenciesFromUrl) {
                return false;
            }

            this.selectedCurrencies = currenciesFromUrl.split(',');
        },
        changeSelect() {
            const {
                $route,
                $router,
                fetchExchanges,
            } = this;

            fetchExchanges({
                route: $route,
                router: $router,
                currencies: this.selectedCurrencies,
                excludeParams: ['page'],
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.requests-sort-by-currency {
  & .title {
    margin-bottom: 8px;
    font-weight: 700;
  }
}

</style>
