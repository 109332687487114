export const state = {

    withdrawalRequestId: null,
    withdrawal: {
        pending: false,
        complete: false,

        error: null,

        id: null,
        status: null,
        address: null,
        tag: null,
        traceId: null
    },

    feeRequestId: null,
    fee: {
        pending: false,
        complete: false,

        error: null,

        code: null,
        currency: null,
        amount: null,
        amountToReceive: null,
        usdAmount: null,
        source: null,

        until: null,

        token: null,

        tariffFee: null,
        tariffFeeUSD: null,
        networkFee: null,
        networkFeeUSD: null,
    },
};
