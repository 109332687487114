import createInvoiceMutation from "/public/graphQl/mutations/createInvoice";

export const createInvoice = async ({dispatch, rootGetters}, {
    accountId,
    itemId,
    itemDescription,
    currencyId,
    amount,
    includeFee,
    insurancePercent,
    currencies,
    slippagePercent,
    additionalFees,
}) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    let invoiceData;
    const stringAmount = amount.toString()
    const StringSlippagePercent = slippagePercent.toString()
    const StringInsurancePercent = insurancePercent.toString()
    try {
        invoiceData = await dispatch('app/request', {
            type: 'mutation',
            gql: createInvoiceMutation,
            variables: {
                organizationId,
                accountId,
                itemId,
                itemDescription,
                currencyId,
                amount: stringAmount,
                includeFee,
                insurancePercent: StringInsurancePercent,
                currencies,
                slippagePercent: StringSlippagePercent,
                additionalFees,
            },
        }, {root: true});
    } catch (error) {
        return error;
    }

    if (invoiceData.message) {
        throw invoiceData.message;
    }

    if (invoiceData.errors) {
        throw invoiceData.errors;
    }

    const {
        data: {
            createInvoice,
        },
    } = invoiceData;

    return createInvoice;
};
