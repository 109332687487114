
import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!) {
        getOrganizationSettings(organizationId: $organizationId) {
            method
            field
            value
            requestType
            isTemplate
            templateType
            updatedAt
        }
    }
`;
