import gql from 'graphql-tag';
// , $native: Boolean
// , native: $native
export default gql`
    mutation($organizationId: String!, $accountId: String!, $native: Boolean, $addressId: String!, $amount: String!) {
        requestFee(organizationId: $organizationId, accountId: $accountId,native: $native, addressId: $addressId, amount: $amount ) {
            code
            result {
                currencyId
                amount
                usdAmount
                tariffFee
                tariffFeeUSD
                networkFee
                networkFeeUSD
                until
                token
                source
            }
        }
    }
`;
