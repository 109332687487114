<template>
<div class="bills-top-bar topBar">
    <div class="topBar__topRow">
        <orphan-search :placeholder="statusSelectPlaceholder" v-model="search" />
        <orders-status-select v-model="selectedStatus" :placeholder="`${$t('invoice.status')}...`" :list="statuses" clearable  uniq-key="value" @input="setStatus" />
        <base-button
            type="primary"
            class="add-new-user-btn"
            :disabled="!isCreateOrderAllowed"
            :label="$t('orders.new_order')"
            @click="openNewOrderModal"
        >
            <template #prepend>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                >
                    <path
                        d="M6 0C6.41421 0 6.75 0.335786 6.75 0.75V5.25H11.25C11.6642 5.25 12 5.58579 12 6C12 6.41421 11.6642 6.75 11.25 6.75H6.75V11.25C6.75 11.6642 6.41421 12 6 12C5.58579 12 5.25 11.6642 5.25 11.25V6.75H0.75C0.335786 6.75 0 6.41421 0 6C0 5.58579 0.335786 5.25 0.75 5.25H5.25V0.75C5.25 0.335786 5.58579 0 6 0Z"
                        fill="white"
                    />
                </svg>
            </template>
        </base-button>
    </div>
    <div class="topBar__downRow">
        <business-wallet-network-select
            v-model="orderNetwork"
            :list="filteredNetworks"
            :placeholder="`${$t('orders.network')}...`"
            clearable
            uniq-key="id"
            @input="updateOrders(true)"
        />
        <business-wallet-network-select
                v-model="orderCurrency"
                :list="filteredCurrencies"
                :placeholder="`${$t('orders.currency')}...`"
                clearable
                uniq-key="id"
                @input="updateOrders(true)"
        />
        <date-picker v-model="dates" :placeholder="$t('webhooks.period')" clearable @input="handleSelectDates"/>
        <refresh-button  @click="updateOrders(false)"/>
        <downloader
                v-model="selectedDownloadType"
                class="file-downloader"
                type="secondary"
        >
            <template #icon>
                <svg
                        id="btn-select-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                >
                    <path
                            d="M8.72933 14.9529C8.33425 15.3743 7.66533 15.3743 7.27025 14.9529L0.924369 8.18392C0.32562 7.54525 0.778465 6.49998 1.65391 6.49998L4.49979 6.49998L4.49979 5.49998C4.49979 4.94769 4.94751 4.49998 5.49979 4.49998L10.4998 4.49998C11.0521 4.49998 11.4998 4.94769 11.4998 5.49998L11.4998 6.49998L14.3457 6.49998C15.2211 6.49998 15.674 7.54525 15.0752 8.18392L8.72933 14.9529Z"
                            fill="#744DB2"
                    />
                    <path
                            d="M11.4998 2.49998C11.4998 3.05226 11.0521 3.49998 10.4998 3.49998L5.49979 3.49998C4.94751 3.49998 4.49979 3.05226 4.49979 2.49998L4.49979 1.49998C4.49979 0.947692 4.94751 0.499977 5.49979 0.499977L10.4998 0.499978C11.0521 0.499978 11.4998 0.947693 11.4998 1.49998L11.4998 2.49998Z"
                            fill="#744DB2"
                    />
                </svg>
            </template>
        </downloader>
    </div>
    <div class="topBar__mobile">
        <base-button
                type="primary"
                class="add-new-user-btn"
                :disabled="!isCreateOrderAllowed"
                :label="$t('orders.new_order')"
                @click="openNewOrderModal(true)"
        >
            <template #prepend>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                    <path
                      d="M6 0C6.41421 0 6.75 0.335786 6.75 0.75V5.25H11.25C11.6642 5.25 12 5.58579 12 6C12 6.41421 11.6642 6.75 11.25 6.75H6.75V11.25C6.75 11.6642 6.41421 12 6 12C5.58579 12 5.25 11.6642 5.25 11.25V6.75H0.75C0.335786 6.75 0 6.41421 0 6C0 5.58579 0.335786 5.25 0.75 5.25H5.25V0.75C5.25 0.335786 5.58579 0 6 0Z"
                      fill="white"
                    />
                </svg>
            </template>
        </base-button>
        <refresh-button  @click="updateOrders(true)"/>
        <downloader
                v-model="selectedDownloadType"
                class="file-downloader"
                type="secondary"
        >
            <template #icon>
                <svg
                        id="btn-select-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                >
                    <path
                            d="M8.72933 14.9529C8.33425 15.3743 7.66533 15.3743 7.27025 14.9529L0.924369 8.18392C0.32562 7.54525 0.778465 6.49998 1.65391 6.49998L4.49979 6.49998L4.49979 5.49998C4.49979 4.94769 4.94751 4.49998 5.49979 4.49998L10.4998 4.49998C11.0521 4.49998 11.4998 4.94769 11.4998 5.49998L11.4998 6.49998L14.3457 6.49998C15.2211 6.49998 15.674 7.54525 15.0752 8.18392L8.72933 14.9529Z"
                            fill="#744DB2"
                    />
                    <path
                            d="M11.4998 2.49998C11.4998 3.05226 11.0521 3.49998 10.4998 3.49998L5.49979 3.49998C4.94751 3.49998 4.49979 3.05226 4.49979 2.49998L4.49979 1.49998C4.49979 0.947692 4.94751 0.499977 5.49979 0.499977L10.4998 0.499978C11.0521 0.499978 11.4998 0.947693 11.4998 1.49998L11.4998 2.49998Z"
                            fill="#744DB2"
                    />
                </svg>
            </template>
        </downloader>
        <filter-mobile
          :is-filtered="!isEmptyFilters"
          class="topBar__filterMobile"
          @reset="resetFilters"
        >
            <orphan-search :placeholder="statusSelectPlaceholder" v-model="search" />
            <orders-status-select :placeholder="`${$t('invoice.status')}...`" :list="statuses" clearable v-model="selectedStatus" uniq-key="value" @input="updateOrders(true)" />
            <business-wallet-network-select
                v-model="orderCurrency"
                :list="currencies"
                :placeholder="$t('invoice.select_invoice_currency')"
                clearable
                uniq-key="id"
                @input="updateOrders(true)"
            />
            <business-wallet-network-select
                v-model="orderNetwork"
                :list="filteredNetworks"
                :placeholder="$t('invoice.select_payment_network')"
                clearable
                uniq-key="id"
                @input="updateOrders(true)"
            />
        </filter-mobile>
    </div>
    <!--    <div class="bills-top-bar__left-side">-->
    <!--      <div class="left">-->
    <!--        <BillsSortByStatus class="bills-top-bar__sort-by-status"/>-->
    <!--&lt;!&ndash;        <BillsSortByCurrency class="bills-top-bar__sort-by-currency"/>&ndash;&gt;-->
    <!--      </div>-->
    <!--      <div class="right">-->
    <!--&lt;!&ndash;        <BillsSortByNetwork class="bills-top-bar__sort-by-network"/>&ndash;&gt;-->
    <!--        <BillsFilterByDate class="bills-top-bar__filter-by-date"/>-->
    <!--      </div>-->

    <!--    </div>-->
</div>
</template>

<script>

    import {mapActions, mapGetters, mapState} from 'vuex';

import OrdersSortByStatus from "./OrdersSortByStatus";
import OrdersSortByCurrency from "./OrdersSortByCurrency";
import OrdersFilterByDate from "./OrdersFilterByDate";
import OrdersSortByNetwork from "./OrdersSortByNetwork";
import AtButton from 'at-ui/src/components/button';
import BtnRefresh from "../../common/btn-refresh";
import btn from "../../common/btn";
import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
import FilterMobile from "@/public/components/elements/orphan-transactions/filter/index.vue";
// import NewDatepicker from "@/public/components/common/uiKit/Datepicker/Datepicker.vue";
import Downloader from "@/public/components/elements/personal-addresses/Select/Downloader/index.vue";
import OrphanSearch from "@/public/components/elements/orphan-transactions/OrphanSearch.vue";
import RefreshButton from "@/public/components/common/uiKit/button/refresh/index.vue";
import BusinessWalletNetworkSelect
    from "@/public/components/elements/business-walet/Select/businessWalletNetworkSelect.vue";
import InvoiceStatusSelect from "@/public/components/elements/invoice/Select/invoiceStatusSelect.vue";
    import OrdersStatusSelect from "@/public/components/elements/orders/Select/OrdersStatusSelect.vue";
    import _ from "lodash";

export default {
    name: 'OrdersTopBar',
    components: {
        OrdersStatusSelect,
        InvoiceStatusSelect,
        BusinessWalletNetworkSelect,
        RefreshButton,
        OrphanSearch,
        Downloader,
        DatePicker: () => import(/* webpackChunkName: "public/components/elements/analytics/selects/DatePicker" */ '/public/components/elements/analytics/selects/DatePicker'),
        FilterMobile,
        BaseButton,
        BtnRefresh,
        OrdersSortByStatus,
        OrdersSortByCurrency,
        OrdersFilterByDate,
        OrdersSortByNetwork,
        AtButton,
        btn,
    },
    data() {
        return {
            isLoading: false,
            search: '',
            selectedStatus: null,
            orderCurrency: null,
            orderNetwork: null,
            dates: [],
            selectedDownloadType: null,
        };
    },
    props: {
        page: {
            type: Number,
            default: 1
        },
        sortValue: {
            type: String,
            default: 'DESC'
        }
    },
    computed: {
        ...mapState('app', ['networks', 'currencies']),
        ...mapState('accounts', [
            'accounts',
        ]),
        ...mapGetters('organizations', ['userRoleRules']),
        isEmptyFilters({ search, selectedStatus, orderNetwork, orderCurrency }) {
          return !search && _.isEmpty(selectedStatus) && _.isEmpty(orderNetwork) && _.isEmpty(orderCurrency) && _.isEmpty(this.dates)
        },
        status () {
            if (!this.selectedStatus) return null
            return [this.selectedStatus.value]
            // switch (this.selectedStatus.value) {
            //     case 'processed':
            //         return ['processed']
            //     case 'expired':
            //         return ['expired']
            //     case 'init':
            //         return ['init']
            //     case 'pending':
            //         return ['pending']
            //     case 'partial':
            //         return ['partial']
            //     case 'error':
            //         return ['error']
            //     default:
            //         return ''
            // }
        },
        isCreateOrderAllowed () {
            return this.userRoleRules['orders'].entities.create_order
        },
        filteredCurrencies () {
            const {
                currencies,
            } = this;
            let sortedCurrencies = currencies.filter(({isFiat}) => !isFiat);

            if (this.networksValue) {
                sortedCurrencies = sortedCurrencies.filter((i) => {
                    return i.networks.find(({id}) => id === this.networksValue.id);
                });
                return sortedCurrencies;
            }

            return sortedCurrencies;
        },
        filteredNetworks() {
            const {
                currencies,
            } = this;

            if (this.orderCurrency) {
                return this.orderCurrency.networks
            }

          const networks = {};

          for(const currency of currencies) {
            if(!currency.allowBusiness) {
              continue;
            }

            for(const network of currency.networks) {
              if(!network.allowDeposit) {
                continue;
              }

              networks[network.id] = network;
            }
          }

          return Object.values(networks);
        },
        statusSelectPlaceholder () {
            return `${this.$t('invoice.search')}`
        },
        statuses () {
            return [
                {
                    value: 'processed'
                },
                {
                    value: 'expired'
                },
                {
                    value: 'init'
                },
                {
                    value: 'pending'
                },
                {
                    value: 'partial'
                },
                {
                    value: 'overpaid'
                },
                {
                    value: 'error'
                }
            ]
        },
        accountId () {
            if (this.accounts.length) {
                return this.accounts[0].accountId
            }
            return null
        }
    },
    watch: {
        isEmptyFilters: {
          handler(v) {
            this.$nextTick(() => {
              this.$emit('is-empty-filters', v)
            })
          },
          immediate: true
        },
        selectedDownloadType(v) {
            const {
                $route,
                page,
                orderCurrency,
                orderNetwork,
                dates,
                status,
                sortValue,
            } = this;
            if (!v) {return}
            if (v.id === 'csv') {
                this.downloadCSV({
                    route: $route,
                    page,
                    currencies: orderCurrency ? [orderCurrency?.id] : [],
                    networks: orderNetwork ? [orderNetwork?.id] : [],
                    date: dates ? dates : [],
                    status,
                    sort: sortValue,
                });
            } else {
                this.downloadXLSX({
                    route: $route,
                    page,
                    currencies: orderCurrency ? [orderCurrency?.id] : [],
                    networks: orderNetwork ? [orderNetwork?.id] : [],
                    date: dates ? dates : [],
                    status,
                    sort: sortValue,
                });
            }
            this.selectedDownloadType = undefined
        },
        search () {
            this.updateOrders(true)
        },
        page() {
            this.updateOrders(false)
        },
        sortValue () {
            this.updateOrders(true)
        }

    },
    methods: {
        ...mapActions('app', [
            'openModal',
        ]),
        ...mapActions('orders', [
            'fetchOrders', 'downloadCSV', 'downloadXLSX',
        ]),
        handleSelectDates(value) {
            this.dates = value;
            this.updateOrders(true)
        },
        openNewOrderModal() {
            if (!this.isCreateOrderAllowed) {
                return
            }
            this.openModal({
                name: 'NewOrderModal',
            });
        },
        setStatus (value) {
          this.selectedStatus = value
          this.updateOrders(true)
        },
        updateOrders(changePage = true) {
            const {
                $route,
                $router,
                fetchOrders,
                page,
                orderCurrency,
                orderNetwork,
                dates,
                status,
                sortValue,
                search
            } = this;
            if (changePage) {
                this.$emit('changePage', 1)
            }
            this.isLoading = true;
            fetchOrders({
                route: $route,
                router: $router,
                page,
                currencies: orderCurrency ? [orderCurrency?.id] : [],
                networks: orderNetwork ? [orderNetwork?.id] : [],
                date: dates ? dates : [],
                status,
                sortDate: sortValue,
                id: search

            })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        download() {
            const {
                $route,
            } = this;

            this.downloadCSV({
                route: $route,
            });
        },
        downloadXLSXQuery() {
            const {
                $route,
            } = this;

            this.downloadXLSX({
                route: $route,
            });
        },
        resetFilters() {
          ['orderNetwork', 'orderCurrency', 'selectedStatus', 'search'].forEach(filter => {
            this.$set(this, filter, '')
          })
        }
    },
};

</script>

<style lang="scss" scoped>
.topBar {
    margin-bottom: 30px;
    &__topRow {
        display: grid;
        grid-template-columns: 1fr .5fr 165px;
        gap: 10px;
        margin-bottom: 30px;
    }
    &__filterMobile {
        ::v-deep .filter__content {
            min-width: 330px;
        }
        ::v-deep {
            .currency-search-wrapper {
                width: 100%;
                .search-input {
                    &::placeholder {
                        opacity: 1 !important;
                    }
                }
                .search-icon {
                    top: 43% !important;
                }
                @media screen and (max-width: 950px) {
                    .search-icon {
                        top: 50%;
                    }
                    .search-input {
                        //&::placeholder {
                        //    opacity: 1 !important;
                        //}
                    }
                }
            }

        }
        ::v-deep .base-btn {
            width: 100% !important;
        }

    }
    &__downRow {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 50px 50px;
        gap: 10px;

    }
    &__mobile {
        display: none;
    }
}
.bills-top-bar {
    justify-content: space-between;
    align-items: flex-end;

    &__left-side {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        gap: 22px 40px;
    }

    &__sort-by-network,
    &__sort-by-status,
    &__sort-by-currency {

    }
}

.left {
    width: inherit;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.right {
    width: inherit;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.button {
    &__container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 25px;
        margin-bottom: 5px;
        padding-top: 26px;
    }
    &__label {
        @media screen and (max-width: 950px) {
            display: none;
        }
    }
}

.button_update {
}

@media screen and (max-width: 950px) {
    .topBar{
        &__topRow {
            display: none;
        }
        &__downRow {
            display: none;
        }
        &__mobile {
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            ::v-deep .base-btn {
                width: 160px;
            }
        }
    }
    .bills-top-bar {
        &__new-button {
            max-width: 157px;
        }

        &__left-side {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }
    .button__container {
        gap: 8px;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
        ::v-deep .button {
            justify-content: center;
            max-width: 190px;
            min-width: 46px;
        }
    }
}

@media screen and (max-width: 576px) {
    .bills-top-bar {
        padding-top: 20px;
    }
}

@media screen and (max-width: 375px) {
    .bills-top-bar {
        &__button-container {
            gap: 25px;
        }
    }
    .button {
        gap: 0;
    }
    .button__container {
        gap: 8px;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
        ::v-deep .button {
            justify-content: center;
            max-width: 190px;
            min-width: 46px;
        }
    }

}
::v-deep     .file-downloader {
    max-width: 48px !important;
    width: 48px !important;
    .base-btn {
        max-width: 48px !important;
        width: 48px !important;
    }
}
::v-deep .icon  {
    .currency-network-icon__network {
        width: 22px !important;
        height: 22px !important;

        border: none !important;
        position: unset !important;

        top: unset !important;
        left: unset !important;

        transform: unset !important;
    }
}
::v-deep .advanced_balances__network-select {
    width: 100%;
}
::v-deep {
    .mx-input::placeholder {
        opacity: 1 !important;
    }
}
@media screen and (min-width: 950px) {
    ::v-deep .currency-search-wrapper .search-icon {
        top: 42%;
    }
}
</style>
