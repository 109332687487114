export const mutations = {
    setAnalyticsInit(state, status) {
        state.isAnalyticsInit = status;
    },

    setAnalyticsLoading(state, status) {
        state.isAnalyticsLoading = status;
    },

    setAnalytics(state, payload) {
        state.analytics = payload;
    },
};