import getOrderQuery from "/public/graphQl/queries/getOrder";

export const fetchOrder = async ({dispatch, commit, rootGetters}, payload) => {
    let orderData;
    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    const variables = {
        organizationId,
        ...payload,
    };
    commit('setOrderInfoState', true);
    try {
        commit('setLoading', true);
        orderData = await dispatch('app/request', {
            type: 'query',
            gql: getOrderQuery,
            variables,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('setLoading', false);
    }

    if (orderData.message) {
        throw orderData.message;
    }

    if (orderData.errors) {
        throw orderData.errors;
    }

    const {
        data: {getOrder},
    } = orderData;

    commit('setOrder', getOrder);

    return getOrder;
};
