<template>
  <Container class="donations__item-container">
    <div class="donations__color" :style="`--bg-color: var(--${page.info.theme});`"/>
    <div class="donations__item donations__item_title">

      <p class="donations__text-main">{{ page.alias }}</p>
    </div>
    <div class="donations__item donations__item_link">
      <CopyString
          class="donations__link"
          v-if="page.url"
          :is-link="true"
          :string="`${baseURL}${page.url}`"
          :hash="true"
          :success-message="$t('copy.addresses_copied')"
          :title="$t('copy.notification')"
          canCopy
      />
    </div>
    <p class="donations__text-main donations__item_turnover">{{ pageTurnover }}</p>
    <div class="donations__item donations__item_button">
      <div class="donations__item-button-container">
        <RouterLink class="donations__text-main donations__detailed-link "
                    :to="{ name: 'donation', params: { id: page.id } }">
          {{ $t('donations.details') }}
        </RouterLink>
        <AtButton
            class="donations__button"
            size="small"
            icon="icon-edit"
            @click="pushToEditPage"
        />
        <AtButton
            class="donations__button"
            size="small"
            icon="icon-trash"
            @click="openRemoveDonationModalModal"
        />
      </div>
    </div>
  </Container>
</template>

<script>
import Container from "../../common/Container";
import CopyString from "../CopyString";
import {mapActions, mapState} from "vuex";
import BigNumber from "bignumber.js";

export default {
    name: "donation-preview",
    components: {
        Container,
        CopyString,
    },
    props: {
        page: null,
        pageNumber: null,
    },
    methods: {
        ...mapActions('app', [
            'openModal',
        ]),
        openRemoveDonationModalModal() {
            this.openModal({
                name: 'RemoveDonationModal',
                payload: {
                    alias: this.page.alias,
                    id: this.page.id,
                    pageNumber: this.pageNumber,
                },
            });
        },
        pushToEditPage() {
            this.$router.push(`/edit-donation/${this.page.id}`);
        },
    },
    computed: {
        baseURL() {
            return this.$store.state.donations.baseUrl;
        },
        pageTurnover() {
            if (this.page.dailyTurnover === null) return null;
            const pageTurnover = new BigNumber(this.page.dailyTurnover);
            return pageTurnover.decimalPlaces(8).toNumber();
        },
    },
};
</script>


<style scoped lang="scss">
.donations__text-main {
  color: var(--primary-font-color);
}

.donations {
  max-width: 1420px;
  padding-top: var(--padding-top-container);
  padding-left: var(--padding-left-container);
  padding-right: var(--padding-right-container);

  &__wrapper {
    padding-bottom: 10px;
  }

  &__color {
    width: 12px;
    background: var(--bg-color);
    border-radius: 8px;
    height: 100%;
    max-height: 61px;
    margin-left: 6px;
    margin-right: 22px;
  }

  &__container {
    margin-bottom: 10px;
    border-radius: 20px 20px 0 0;
  }

  &__link {
    ::v-deep .copy-string__string {
      color: var(--blue-300);
      font-weight: 400;
    }
  }

  &__item-button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }

  &__item-container {
    display: grid;
    grid-template-columns: min-content 1fr 1fr 1fr 1fr;
    padding-right: 40px;
    min-height: 75px;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__header {
    display: grid;
    grid-template-columns: 40px 1fr 1fr 1fr 1fr;
    padding-right: 40px;
    align-items: center;
    min-height: 46px;
    background: #FCFCFC;
    border: 1px solid #F2F2F2;
    border-radius: 0px;

    &-item {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      font-weight: 300;

      &_color {
      }

      &_title {
      }
    }
  }


  &__item {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    font-weight: 300;

    &_button {
      justify-self: flex-end;
    }


    &_title {
      display: flex;
      gap: 22px;
      height: 100%;
      align-items: center;
      padding-left: 6px;
    }

    &_link {

    }

    &_turnover {

    }

    &_button {

    }

  }


  &__button-container {
    padding-left: 40px;
    padding-right: 40px;
    min-height: 77px;
    display: flex;
    align-items: center;
  }

  &__button {
    width: 60px;
    height: 40px;
    background: var(--main-page-window);
    border: 1px solid var(--border-color);
    color: var(--primary-font-color);
    border-radius: 8px;
    transition: all 0.3s ease-in;

    ::v-deep .at-btn__icon {
      font-size: 20px;
      line-height: 1;
      color: var(--primary-font-color);
    }

    &:hover {
      border: 1px solid var(--blue-400);
    }
  }

  &__detailed-link {
    height: 40px;
    width: 100%;
    max-width: 115px;
    border: var(--border-table);
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-family: var(--font-family);
    line-height: var(--line-height);
    font-size: var(--font-size-text-main);
    justify-content: center;
    color: var(--blue-300);
    transition: all 0.3s ease-in;

    &:hover {
      border: 1px solid var(--blue-400);
    }
  }

  &__new-button {
    height: 46px;
    max-width: 211px;
    width: 100%;
    background: #0059F7;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    transition: all 0.3s ease-in;

    &:hover {
      background: var(--blue-400);
    }
  }
}

@media screen and (max-width: 768px) {
  .donations {
    &__header {
      display: none;
    }

    &__wrapper {
      background: #FFFFFF;
      border-radius: 14px;
      padding: 10px;

    }

    &__container {
      border-radius: 14px;
      margin-top: 0;
      background: #E7F0FF;
      margin-bottom: 50px;
      position: relative;
      overflow: visible;

      &:after {
        content: '';
        position: absolute;
        bottom: -25px;
        left: 0;
        outline: 1px solid #F2F2F2;

        width: 100%;
      }
    }

    &__button-container {
      padding-left: 10px;
      padding-right: 10px;
    }

    &__new-button {
      max-width: 100%;
      justify-content: center;
    }

    &__detailed-link {
      max-width: unset;
    }

    &__color {
      grid-area: color;
      max-height: unset;
      margin-left: 0;
    }

    &__item {
      &-container {
        border-radius: 14px;
        grid-template-columns: min-content 1fr 1fr;
        grid-template-areas: 'color title turnover'
                              'color link link'
                              'color button button';
        grid-row-gap: 20px;
        padding: 10px;
      }

      &_button {
        justify-self: flex-start;
        grid-area: button;
        width: 100%;
      }

      &_title {
        display: flex;
        gap: 22px;
        height: 100%;
        align-items: center;
        padding-left: 0;
        grid-area: title;
      }

      &_link {
        grid-area: link;
      }

      &_turnover {
        grid-area: turnover;
        justify-self: flex-end;
      }

      &-button-container {
        justify-content: space-between;
        width: 100%;
        gap: 10px;
      }
    }
  }


}
</style>