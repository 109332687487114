
import gql from 'graphql-tag';

export default gql`

mutation (
    $organizationId: String!,
    $widgetId: String!
    ) {
  deleteIFrameWidget(
    organizationId: $organizationId,
    widgetId: $widgetId
  ) {
    success
    code
  }
}
`
