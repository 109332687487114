<template>
  <div>
    <preloader v-if="isLoading || !isInit"/>
    <div v-else class="request">
      <AtButton
          class="request__all"
          type="primary"
          icon="icon-arrow-left"
          @click="goBack"
      >
        {{ $t('request.return') }}
      </AtButton>
      <div class="request__container">
        <h1 class="request__title">
          {{ $t('request.title') }}
        </h1>
        <div
            v-if="hasRequest"
            class="request__info"
        >
          <div class="request__info-item">
                <span class="request__info-item-title">
                    id:
                </span>
            <CopyString
                class="request__info-item-value"
                :string="requestId"
                :successMessage="$t('copy.id_copied')"
                :failMessage="$t('copy.id_not_copied')"
                :title="$t('copy.notification')"
                canCopy
            />
            <!--          <span class="request__info-item-value">-->
            <!--                    {{ requestId }}-->
            <!--                </span>-->
          </div>
          <div class="request__info-item">
                <span class="request__info-item-title">
            {{ $t('request.currency') }}
                </span>
            <span class="request__info-item-value request__info-item-value-currency">
                    <CurrencyImg
                        class="request__currency-img"
                        :currency="currency"
                    />
                    {{ currency | getCurrencyTicker }}
                </span>
          </div>
          <div class="request__info-item">
                <span class="request__info-item-title">
            {{ $t('request.network') }}
                </span>
            <span class="request__info-item-value">
                    {{ network | getNetworkName }}
                </span>
          </div>
          <div class="request__info-item">
                <span class="request__info-item-title">
            {{ $t('request.status') }}
                </span>
            <span class="request__info-item-value">
                    {{ $t(requestStatusValue) }}
                </span>
          </div>
          <div class="request__info-item">
                <span class="request__info-item-title">
            {{ $t('request.amount') }}
                </span>
            <span class="request__info-item-value">
                    {{ amount }}
                </span>
          </div>
          <div class="request__info-item">
                <span class="request__info-item-title">
            {{ $t('request.commission') }}
                </span>
            <span class="request__info-item-value">
                    {{ feeAmount }} {{ feeCurrency | getCurrencyTicker }}
                </span>
          </div>
          <div class="request__info-item">
                <span class="request__info-item-title">
            {{ $t('request.commission_source') }}
                </span>
            <span class="request__info-item-value">
                    {{ $t(feeSourceName) }}
                </span>
          </div>
          <div v-if="shortTx" class="request__info-item">
                <span class="request__info-item-title">
                    {{ $t('request.transaction_hash') }}:
                </span>
            <span class="request__info-item-value request__info-item-value_link" @click="viewTx">
                    {{ shortTx }}
                </span>
          </div>
          <div class="request__info-item">
                <span class="request__info-item-title">
                    {{ $t('request.withdrawal_address') }}:
                </span>
            <CopyString
                class="request__info-item-value"
                :string="fromAddress"
                :successMessage="$t('copy.addresses_copied')"
                :failMessage="$t('copy.addresses_not_copied')"
                :title="$t('copy.notification')"
                canCopy
            />
          </div>
          <div class="request__info-item">
                <span class="request__info-item-title">
            {{ $t('request.sending_address') }}
                </span>
            <CopyString
                class="request__info-item-value"
                :string="address"
                :successMessage="$t('copy.addresses_copied')"
                :failMessage="$t('copy.addresses_not_copied')"
                :title="$t('copy.notification')"
                canCopy
            />

          </div>

          <div class="request__info-item">
                <span class="request__info-item-title">
            {{ $t('request.tag_of_the_sending_address') }}
                </span>
            <span class="request__info-item-value">
                    {{ tag }}
                </span>
          </div>
          <div class="request__info-item">
                <span class="request__info-item-title">
            {{ $t('request.date_of_application_creation') }}
                </span>
            <span class="request__info-item-value">
                    {{ createdAt | getFormatDate('DD.MM.YYYY, HH:mm') }}
                </span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {mapActions, mapGetters, mapState} from 'vuex';

import AtButton from 'at-ui/src/components/button';
import CurrencyImg from "/public/components/elements/CurrencyImg";
import CopyString from "../components/elements/CopyString";
import {FEE_TOKEN_SOURCE} from "../../common/constants/fee-token-source";
import {BlockchainLinks} from "../mixins/blockchain-links";
import BigNumber from "bignumber.js";
import Preloader from "/public/components/common/uiKit/spinner/index.vue";

export default {
    name: 'RequestPage',
    title: 'request.title',
    components: {
        Preloader,
        AtButton,
        CopyString,
        CurrencyImg,
    },
    mixins: [
        BlockchainLinks,
    ],
    computed: {
        ...mapState('withdrawalRequests', {
            _request: state => state.request,
        }),
        ...mapState('accounts', [
            'accounts',
        ]),
        ...mapGetters('balances', [
            'balances',
        ]),
        ...mapState('payoutBalances', ['payoutBalances']),
        ...mapState('app', ['isLoading', 'isInit']),
        request() {
            return this._request;
        },
        requestId() {
            return this.$route.params.id;
        },
        hasRequest() {
            return !!this.request;
        },
        currency() {
            return this.request?.currencyId;
        },
        network() {
            return this.request?.networkId;
        },
        amount() {
            return this.request?.amount;
        },
        feeAmount() {
            let number = BigNumber(this.request?.feeAmount).decimalPlaces(8);
            return number.toFixed();
        },
        feeSource() {
            return this.request?.feeSource;
        },
        feeCurrency() {
            if (this.feeSource === FEE_TOKEN_SOURCE.ADVANCED_BALANCE) {
                return this.accounts.find(a => a.accountId === this.request?.accountId)?.currencyId;
            } else if (this.feeSource === FEE_TOKEN_SOURCE.WALLET) {
                return this.currency;
            }

            return '';
        },
        status() {
            return this.request?.status;
        },
        shortTx() {
            return this.$linkName(this.request?.tx, this.network);
        },
        tx() {
            return this.request?.tx;
        },
        txLink() {
            return this.$txLink(this.tx, this.network);
        },
        // tx() {
        //     const startPart = this.request?.tx.substr(0, 6);
        //     const endPart = this.request?.tx.substr(-6);
        //
        //     let source;
        //
        //     if (getNetworkName(this.network) === 'Fantom') {
        //         source = 'FTMScan';
        //     } else if (getNetworkName(this.network) === 'Ethereum') {
        //         source = 'EtherScan';
        //     } else if (getNetworkName(this.network) === 'Tron') {
        //         source = 'TronScan';
        //     } else if (getNetworkName(this.network) === 'Solana') {
        //         source = 'SolScan';
        //     } else if (getNetworkName(this.network) === 'Binance Smart Chain'){
        //         source = 'BSCScan';
        //     }
        //
        //     return `${startPart}***${endPart} (${source || getCurrencyTicker(this.currency)})` || '-';
        // },
        address() {
            return this.request?.address;
        },
        tag() {
            return this.request?.tag || '-';
        },
        errorWebhook() {
            return this.request?.errorWebhook || '-';
        },
        successWebhook() {
            return this.request?.successWebhook || '-';
        },
        createdAt() {
            return this.request?.createdAt;
        },

        feeSourceName() {

            switch (this.feeSource) {
            case FEE_TOKEN_SOURCE.WALLET:
                return 'request.address_balance';
            case FEE_TOKEN_SOURCE.ADVANCED_BALANCE:
                return 'request.advance_balance';
            }

            return '-';
        },
        requestStatusValue() {
            if (!this.status) {
                return '-';
            }
            switch (this.status) {
            case 'init':
                return 'request.init';
            case 'error':
                return 'request.error';
            case 'processed':
                return 'request.processed';
            case 'rejected':
                return 'request.rejected';
            case 'pending':
                return 'request.pending';
            }
        },
        fromAddress() {
            return this.balances.find(e => this.request.addressId === e.id)?.address || this.payoutBalances.find((e) => this.request.addressId === e.id)?.address;
        },
    },
    beforeMount() {
        this.setRequest();
    },
    filters: {
        requestStatus(status) {
            if (!status) {
                return '-';
            }

            switch (status) {
            case 'init':
                return 'request.init';
            case 'error':
                return 'request.error';
            case 'processed':
                return 'request.processed';
            case 'rejected':
                return 'request.rejected';
            case 'pending':
                return 'request.pending';
            }
        },
    },
    methods: {
        ...mapActions('withdrawalRequests', [
            'fetchWithdrawalRequest',
        ]),
        ...mapActions('payoutBalances', [
            'fetchPayoutBalances',
        ]),
        setRequest() {
            this.fetchWithdrawalRequest({id: this.requestId}).then(() => this.fetchPayoutBalances());
        },
        goBack() {
            if (window.history.length > 2)
                this.$router.go(-1);
            else this.$router.push({name: 'requests'});
        },
        viewTx() {
            // let link, source;
            //
            // if (getNetworkName(this.network) === 'Fantom') {
            //     link = `https://ftmscan.com/tx/${this.tx}`;
            //     source = 'FTMScan';
            // } else if (getNetworkName(this.network) === 'Ethereum') {
            //     link = `https://etherscan.io/tx/${this.tx}`;
            //     source = 'EtherScan';
            // } else if (getNetworkName(this.network) === 'Tron') {
            //     link = `https://tronscan.org/#/transaction/${this.tx}`;
            //     source = 'TronScan';
            // } else if (getNetworkName(this.network) === 'Solana') {
            //     link = `https://solanascan.io/txn/${this.tx}`;
            //     source = 'SolScan';
            // } else if (getNetworkName(this.network) === 'Binance Smart Chain') {
            //     link = `https://bscscan.com/tx${this.tx}`;
            //     source = 'BSCScan';
            // }
            //
            // if (!link) {
            //     return;
            // }

            window.open(this.txLink);
        },
    },
};

</script>

<style lang="scss" scoped>

.request {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: var(--padding-left-container);
  padding-right: var(--padding-right-container);
  padding-top: var(--padding-top-container);

  &__container {
    width: 100%;
    max-width: 764px;
    border-radius: 20px;
    box-shadow: 0px 8px 50px rgba(10, 17, 105, 0.08);
    margin-bottom: var(--margin-container-bottom);
    margin-top: var(--margin-container-top);
  }

  &__title {
    min-height: 107px;
    background: #F9F9F9;
    border: 1px solid #F2F2F2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 60px;
    border-radius: 20px 20px 0 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
  }

  &__info {
    background: #FFFFFF;
    padding-left: 60px;
    padding-right: 60px;
    border-radius: 0 0 20px 20px;
  }

  &__info-item {
    min-height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F2F2F2;
    padding-bottom: 16px;
    padding-top: 16px;

    &:last-of-type {
      border: none;
      padding-bottom: 40px;
    }

    &_transaction {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &__info-item-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  &__info-item-value {
    white-space: normal;
    word-break: break-all;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #2C405A;

    &_link {
      color: var(--blue-300);
      cursor: pointer;
    }

    &-currency {
      display: flex;
      align-items: center;
    }
  }

  &__all {
    background: #0059F7;
    border-radius: 6px;
    border: none;
    width: 100%;
    max-width: 125px;
    min-height: 40px;
    align-self: flex-start;
    transition: all 0.3s ease-in;

    ::v-deep .at-btn__text {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: var(--white-100);
    }

    ::v-deep .at-btn__icon {
      font-size: var(--font-size-text-main);
    }

    &:hover {
      background: var(--blue-400);
    }
  }


  &__info {

  }


  &__currency-img {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 950px) {
  .request {
    padding-left: 20px;
    padding-right: 20px;

    &__all {
    }

    &__title {
      padding-left: 10px;
      min-height: 47px;
      font-size: 16px;
      line-height: 19px;
    }

    &__info {
      padding-left: 10px;
      padding-right: 10px;
    }

    &__info-item {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
    }

    &__info-item-value {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;

      &_link {
        color: var(--blue-300);
        cursor: pointer;
      }
    }

    &__info-item-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }

}


@media screen and (max-width: 375px) {
  .request {
    padding-right: 0;
    padding-left: 0;

    &__all {
      margin-left: 10px;
    }
  }

}
</style>


