<template>
  <div class="button-select-wrapper">
    <base-button
      v-for="(option, index) in options"
      :type="option.id === activeOption.id ? 'select active' : 'select'"
      class="button"
      :label="option.label"
      @click="handleSelect(option)"
      :key="index"
    />
  </div>
</template>

<script>
export default {
  name: "BaseButtonSelect",
  components: {
    BaseButton: () => import(/* webpackChunkName: "/public/components/common/uiKit/button/base/index.vue" */ '/public/components/common/uiKit/button/base/index.vue')
  },
  data() {
    return {
      activeOption: {}
    }
  },
  watch: {
    activeOption(option) {
      this.$emit('input', option.id)
    }
  },
  props: {
    options: {
      type: Array,
      default: () => ([])
    },
    value: {
      type: String,
      default: undefined
    }
  },
  created() {
    this.setDefault()
  },
  methods: {
    handleSelect(option) {
      this.$set(this, 'activeOption', option)
    },
    setDefault() {
      const { value } =  this
      this.options.forEach(option => {
        if (!!value && option.id === value) {
          this.$set(this, 'activeOption', option)
        }
      })
    }
  },
}
</script>

<style scoped lang="scss">
  .button-select-wrapper {
    display: flex;
    align-items: center;
    
    .button {
      width: 160px;
      margin-right: 10px;
    }
  }
</style>