import {v4 as UUID} from 'uuid';

import CreateWithdrawalRequest from '/public/graphQl/mutations/createWithdrawalRequest';
import {getErrorMessage} from "../../../../filters";
import CreateOrphanWithdrawalRequest from "@/public/graphQl/mutations/createOrphanWithdrawalRequest";

export const createOrphanWithdrawal = async ({ state, commit, dispatch, rootGetters }, payload) => {

    dispatch('clearFee');
    dispatch('clearWithdrawal');

    if(state.withdrawal.pending) {
        return;
    }

    const id = UUID();

    commit('setWithdrawalRequestId', id);
    commit('setWithdrawalPending', true);

    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    try {
        const withdrawalRequestData = await dispatch('app/request', {
            type: 'mutation',
            gql: CreateOrphanWithdrawalRequest,
            variables: {
                ...payload,
                organizationId,
                key: id
            },
        }, { root: true });

        if(id !== state.withdrawalRequestId) {
            return;
        }

        commit('setWithdrawalPending', false);

        if (withdrawalRequestData.graphQLErrors) {
            if (withdrawalRequestData.graphQLErrors[0].extensions.code === "INCORRECT_2FA") {
                commit('setWithdrawalError', getErrorMessage(withdrawalRequestData.graphQLErrors[0].extensions.code));
                return
            }
            commit('setWithdrawal', {
                id: '',
                status: code !== 0 ? 'error' : '',
                address: '',
                tx: '',
                tag: '',
            });
            commit('setWithdrawalError', withdrawalRequestData.graphQLErrors[0].message);
        } else {
            const {data: {orphanWithdrawal: {code, result}}} = withdrawalRequestData;

            if(code === 0) {
                commit('setWithdrawal', {
                    id: result.id,
                    status: result.status,
                    address: result.address,
                    amount: result.amount,
                    tx: result.outTransaction.txId,
                });
            } else {
                commit('setWithdrawal', {
                    id: '',
                    status: code !== 0 ? 'error' : '',
                    address: '',
                    tx: '',
                    tag: '',
                });
                commit('setWithdrawalError', getErrorMessage(code));
            }

        }
        commit('setWithdrawalComplete', true);
    } catch (error) {
        if(id !== state.withdrawalRequestId) {
            return;
        }
        commit('setWithdrawalPending', false);
        commit('setWithdrawalComplete', true);

        return error;
    }
};
