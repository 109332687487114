<template>
    <StoreModal
            class="invite-user-modal"
            name="InviteUserModal"
            :value="isOpen"
            :title="title"
            @closeModal="handleCancelCreate"
    >
        <template v-if="!urlStep" #header>
            <div class="invite-user-modal__header">
                <div class="title">
                    {{ title }}
                </div>
            </div>
        </template>
        <template #body>
            <div class="modal-wrapper" v-if="!urlStep">
                <div class="invite-user-modal__input-wrapper">
                    <base-input
                        class="invite-user-modal__input"
                        size="small"
                        :placeholder="$t('organization.organization_enter_email')"
                        v-model="email"
                        :status="emailInputStatus"
                        :label="$t('organization.modal_email')"
                        :error="invalidEmailText"
                    />
                </div>
                <div class="invite-user-modal__input-wrapper">
                    <base-input
                            class="invite-user-modal__input"
                            size="small"
                            :placeholder="$t('organization.organization_enter_name')"
                            v-model="userName"
                            :maxlength="20"
                            :label="$t('organization.modal_name')"
                    />
                </div>
                <div class="invite-user-modal__input-wrapper">
                    <organisation-role-select
                        v-model="role"
                        :placeholder="$t('organization.modal_take_role')"
                        :label="$t('organization.organization_role')"
                        :list="listRoles"
                        uniq-key="id"
                        clearable
                        @input="handleRole"
                    />
                </div>
            </div>
            <div class="success-block" v-else>
                <svg class="invite-user-modal__icon" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_11753_147632)">
                        <rect width="60" height="60" rx="10" fill="#5BB24D"/>
                        <path d="M36.3112 23.5605C36.9336 22.9382 37.9427 22.9382 38.5651 23.5605C39.1799 24.1753 39.1874 25.1674 38.5877 25.7914L30.1043 36.3957C30.092 36.411 30.079 36.4256 30.0651 36.4395C29.4427 37.0619 28.4336 37.0619 27.8112 36.4395L22.1875 30.8158C21.5651 30.1934 21.5651 29.1842 22.1875 28.5618C22.8099 27.9395 23.819 27.9395 24.4414 28.5618L28.8901 33.0106L36.2689 23.6082C36.2821 23.5915 36.2962 23.5756 36.3112 23.5605Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_11753_147632">
                            <rect width="60" height="60" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
                <div class="invite-user-modal__success-title">
                    {{ $t('organization.success') }}
                </div>
                <div class="invite-user-modal__success-subtitle">
                    {{ $t('organization.success-invite-desc') }}
                </div>
                <div class="invite-user-modal__link-wrapper modal-wrapper">
                    <div class="invite-user-modal__link-block">
                        <a :href="inviteUrl"
                           target="_blank" class="invite-user-modal__link">
                            {{ inviteUrl }}
                        </a>
                        <copy :value="inviteUrl" />
                    </div>
                </div>
            </div>
        </template>

        <template #footer>
            <div class="invite-user-modal__footer" v-if="!urlStep">
                <base-button :label="$t('api_keys.close')" type="secondary" @click="handleCancelCreate" />
                <base-button
                  type="primary"
                  @click="handleInviteRequest"
                  :disabled="disabledBtn"
                  :label="$t('organization.modal_btn_invite')"
                />
            </div>
            <template v-else>
                <div class="invite-user-modal__close-btn">
                    <base-button :label="$t('organization.modal_btn_close')"  class="invite-user-modal__btn" @click="handleCancelCreate" />
                </div>
            </template>
        </template>

    </StoreModal>
</template>

<script>

import {mapGetters, mapActions, mapState} from 'vuex';

import AtModal from 'at-ui/src/components/modal';
import LoadingSpinner from "/public/components/elements/LoadingSpinner";
import AtButton from 'at-ui/src/components/button';
import BaseModal from "../BaseModal";
import CopyString from "/public/components/elements/CopyString";
import OrganisationRoleSelect from "@/public/components/elements/organizations/Select/organisationRoleSelect.vue";
import BaseInput from "@/public/components/common/uiKit/input/base/index.vue";
import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
import Copy from "@/public/components/common/copy.vue";

export default {
    name: 'InviteUserModal',
    components: {
        Copy,
        BaseButton,
        BaseInput,
        OrganisationRoleSelect,
        BaseModal,
        AtModal,
        LoadingSpinner,
        AtButton,
        CopyString,
        StoreModal: () => import(/* webpackChunkName: "/public/components/modals/StoreModal" */ '/public/components/modals/StoreModal'),
    },

    data() {
        return {
            isLoading: false,
            isSuccess: false,
            userName: '',
            email: '',
            role: null,
            urlStep: false,
            emailNotValid: false,
        };
    },

    created() {
        // this.getOrganizationRoles();
    },

    computed: {
        ...mapGetters('app', [
            'isOpenModal',
        ]),

        ...mapState('app', {
            modalPayload: state => state.modal.payload,
        }),

        ...mapState('organizations', [
            'listRoles',
            'inviteUrl',
        ]),

        isOpen() {
            const {
                $options: {
                    name,
                },
                isOpenModal,
            } = this;

            return isOpenModal(name);
        },

        title() {
            return this.urlStep ? this.$t('organization.modal_create_user') : this.$t('organization.modal_user');
        },

        disabledBtn() {
            return !(this.email.length && !!this.userName.length && !!this.role && this.isEmailValid !== 'error');
        },
        isEmailValid () {
            if (!this.email) {
                return 'empty';
            }
            const regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
            return regExp.test(this.email) ? '' : 'error'
        },
        emailInputStatus () {
            if (this.emailNotValid === 1014 || this.emailNotValid === 2003) {
                return 'error';
            }
            return this.isEmailValid;
        },
        invalidEmailText () {
            if (this.emailNotValid === 1014) {
                return this.$t('organization.organization_no_user_found')
            } else if (this.emailNotValid === 2003) {
                return this.$t('organization.organization_user_already_in')
            }
            return ''
        }
    },

    methods: {
        ...mapActions('app', [
            'closeModal',
        ]),

        ...mapActions('organizations', [
            'createOrganizationUser',
            'getOrganizationRoles',
            'getOrganizationUsers',
        ]),

        clearAllData() {
            this.isLoading = false;
            this.isSuccess = false;
            this.userName = '';
            this.email = '';
            this.role = null;
            this.urlStep = false;
            this.emailNotValid = false;
        },

        handleInviteRequest() {
            if (this.disabledBtn) {
                return
            }
            this.createOrganizationUser({
                name: this.userName,
                email: this.email.toLowerCase(),
                id: this.role.id,
            }).then((data) => {
                if (data === 1014 || data === 2003) {
                    this.emailNotValid = data;
                    return;
                }
                this.urlStep = true;
            });
        },

        handleRole(value) {
            this.role = value;
        },

        handleCancelCreate() {
            const {
                closeModal,
                clearAllData,
            } = this;

            closeModal();
            this.$store.commit('organizations/setSelectedRole', null);
            this.$store.commit('organizations/setDetailRoleId', null);

            setTimeout(() => {
                clearAllData();
                if (this.urlStep) this.getOrganizationUsers();
            }, 500);
        },
    },
};

</script>

<style lang="scss" scoped>
.modal-wrapper {
    padding: 0 30px;
}
.success-block {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.invite-user-modal {
    &::v-deep {
        .list {
            max-height: 290px !important;
        }
    }
    font-family: $Gilroy;
    &__success {
        &-title {
            margin-top: 30px;
            font-weight: 500;
            color: var(--new-front-primary-font-color);
            font-size: $h1;
        }
        &-subtitle {
            margin-top: 20px;
            font-size: $h4;
            color: var(--new-front-secondary-font-color);
            margin-bottom: 10px;
        }
    }
    &__header {
        text-align: center;
        .title {
            font-size: $h1;
            font-weight: 500;
            color: var(--new-front-primary-font-color);
            @media (max-width: 450px) {
                font-size: $h2;
            }
        }
    }
    color: var(--new-front-primary-font-color);
    &__footer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
  &__full-width-btn {
    width: 100%;
  }

  &__message {
    color: var(--primary-font-color);
      font-family: $Gilroy;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;

    span {
      color: #0059F7;
    }
  }

  &__alias {
      font-family: $Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: var(--primary-font-color);
    margin-top: 25px;
  }

  &__input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__input-title {
      font-family: $Gilroy;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--primary-font-color);
    margin-bottom: 10px;
  }

  &__input {
    &::v-deep .at-input__original {
      background: var(--secondary-color);
      border: 1px solid var(--border-color);
      box-sizing: border-box;
      border-radius: 12px;
      min-height: 47px;
      color: var(--primary-font-color);
        font-family: $Gilroy;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 148.8%;
      padding-left: 30px;

      &::placeholder {
          font-family: $Gilroy;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 148.8%;
        color: #8F949A;
      }
    }
  }

  &__link-text {
      font-family: $Gilroy;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--primary-font-color);
    margin-bottom: 25px;
  }

  &__link-wrapper {
    display: flex;
    align-items: center;
  }

  &__link-block {
    display: flex;
  }

  &__link {
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-decoration-line: underline;
    color: var(--new-front-purple);
    margin-right: 10px;
  }

  &__close-btn {
    width: 100%;
  }

  &__btn {
    min-width: 100%;
  }
}
.at-input--error ::v-deep .at-input__original {
  border-color: red;
}
.inputError-title {
  color: #EB5757;
  font-family: var(--font-family);
  font-size: 14px;
  line-height: var(--line-height);
}
</style>
