<template>
    <AtMenu mode="horizontal">
        <AtMenuItem
            v-for="(link, key) in $options.navs"
            :key="key"
            :to="link.link"
        >
            {{ link.title }}
        </AtMenuItem>
    </AtMenu>
</template>

<script>

import AtMenu from 'at-ui/src/components/menu';
import AtMenuItem from 'at-ui/src/components/menu-item';

export default {
    name: 'ApiNav',
    navs: [
        {
            title: 'Get balance',
            link: { name: 'apiExample', params: { type: 'get-balance' } },
        },
        {
            title: 'Active currencies',
            link: { name: 'apiExample', params: { type: 'active-currencies' } },
        },
        {
            title: 'Create New Order',
            link: { name: 'apiExample', params: { type: 'create-new-order' } },
        },
        {
            title: 'Get order info',
            link: { name: 'apiExample', params: { type: 'get-order-info' } },
        },
        {
            title: 'Estimated fee',
            link: { name: 'apiExample', params: { type: 'estimated-fee' } },
        },
        {
            title: 'Withdraw',
            link: { name: 'apiExample', params: { type: 'withdraw' } },
        },
        // {
        //     title: 'Exchange rate',
        //     link: { name: 'apiExample', params: { type: 'exchange-rate' } },
        // },
        // {
        //     title: 'Exchange balance order',
        //     link: { name: 'apiExample', params: { type: 'exchange-balance-order' } },
        // },
        // {
        //     title: 'Get orders',
        //     link: { name: 'apiExample', params: { type: 'get-orders' } },
        // },
        // {
        //     title: 'Get transactions',
        //     link: { name: 'apiExample', params: { type: 'get-transactions' } },
        // },
        // {
        //     title: 'Rolling reserve info',
        //     link: { name: 'apiExample', params: { type: 'rolling-reserve-info' } },
        // },
    ],
    components: {
        AtMenu,
        AtMenuItem,
    },
};

</script>