<template>
  <div class="dashboard-top-bar">
    <h1 class="dashboard-top-bar__title">
      {{ $t('dashboard.main') }}
    </h1>
  </div>
</template>

<script>

import DashboardFilterByDate from "./DashboardFilterByDate";


export default {
    name: 'DashboardTopBar',
    components: {
        DashboardFilterByDate,
    },
    data() {
        return {
            isLoading: true,
        };
    },
    computed: {
        refreshIcon() {
            return this.isLoading ? '' : 'icon-refresh-cw';
        },
    },
};

</script>

<style lang="scss" scoped>

.dashboard-top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 32px;

  &__title {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-h1);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }


}

@media screen and (max-width: 375px) {
  .dashboard-top-bar {
    padding-left: 10px;
  }
}

</style>