import gql from 'graphql-tag';

export default gql`
    mutation createBusinessAddress(
        $organizationId: String!,
        $accountId: String!, 
        $currencyId: String!,
        $networkId: String!,  
        $comment: String,
        $alias: String,
    ) {
        createBusinessAddress(
            organizationId: $organizationId,
            accountId: $accountId, 
            currencyId: $currencyId,
            networkId: $networkId, 
            comment: $comment, 
            alias: $alias,
        ) {
            code
            result {
              id
              currencyId
              networkId
              address
              tag
              balance
              alias
              comment
            }
        }
    }
`;
