export const mutations = {
    setListAddress(state, payload) {
        state.listAddress = payload;
    },

    setTotalPage(state, payload) {
        state.count = payload;
    },

    count: (state, payload) => {
        state.count = payload;
    },
    pages: (state, payload) => {
        state.pages = payload;
    },

    setNetworks(state, payload) {
        state.getNetworks = payload;
    },

    setType(state, payload) {
        state.type = payload;
    },

    currentAddress(state, payload) {
        state.address = payload;
    },

    currentNetwork(state, payload) {
        state.currentNetwork = payload;
    },
    setFetching: (state, payload) => {
        state.isInfoLoading = payload
    },
    setTransaction: (state, payload) => {
        state.transaction = payload
    },
    setTransactionInfoState : (state, payload) => {
        state.infoState = payload
    },
    setColumns: (state, payload) => {
        state.columns = payload
    },
    clearAllStates: (state) => {
        state.type = '';
        state.listAddress = [];
        state.count = 0;
        state.pagesCount = 10;
        state.pages = [];
        state.page = {};
        state.address = [];
        state.currentNetwork = [];
    },

};
