import getWidgetsQuery from "/public/graphQl/queries/getIframeWidgets";
// import {getAllBalances} from "../../balances/actions/getAllBalances";
export const fetchWidgets = async ({state, commit, rootGetters, dispatch}) => {
    if (state.loading) {
        return;
    }

    commit('setLoading', true);

    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    let data;
    try {
        data = await dispatch('app/request', {
            type: 'query',
            gql: getWidgetsQuery,
            variables: { organizationId, limit: 999, offset: 0 },
        }, {root: true});
    } catch (error) {
        console.error('Fail fetch accounts', error);
    } finally {
        commit('setLoading', false);
    }

    const {
        data: {
            IFrameWidgets: widgets,
        },
    } = data;


    commit('setWidgets', widgets.widgets);
    commit('setWidgetsCount', widgets.count);
    commit('setAlias', `Widget - ${widgets.widgets.length + 1}`)

    return widgets;
};
