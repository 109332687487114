import getUserInterfaceSettingsQuery from "/public/graphQl/queries/getUserInterfaceSettings";

export const getPinnedMenu = async ({dispatch, commit, rootGetters}, id) => {
    let setingsData;
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const variables = {
        organizationId,
        name: 'menu'
    }

    try {
        // commit('app/toggleLoading', null, {root: true});
        setingsData = await dispatch('app/request', {
            type: 'query',
            gql: getUserInterfaceSettingsQuery,
            variables
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        // commit('app/toggleLoading', null, {root: true});
    }

    if (setingsData.message) {
        throw setingsData.message;
    }

    if (setingsData.errors) {
        throw setingsData.errors;
    }

    const {
        data: {getUserInterfaceSettings},
    } = setingsData;
    const menu = getUserInterfaceSettings.entities ? getUserInterfaceSettings.entities : []
    if (!menu.length) {
        commit('setPinnedMenu', menu)
    } else {
        commit('setPinnedMenu', JSON.parse(menu))
    }

    return getUserInterfaceSettings;
};
