<template></template>

<script>

export default {
    name: 'ExchangeTransactionInfo',
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
};

</script>