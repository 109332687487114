import {getRouteQuery} from "/public/utils/getRouteQuery";
import {getCurrenciesIdsByNames} from "/public/utils/getCurrenciesIdsByNames";
import {getNetworksIdsByNames} from "/public/utils/getNetworksIdsByNames";
import moment from "moment";

export const downloadCSV = async ({state, rootState, commit, dispatch, rootGetters}, {
    route,
    sortDate,
    currencies,
    date,
    networks,
}) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const currentSortDate = sortDate ? sortDate : getRouteQuery({
        route,
        queryName: 'sort',
        defaultValue: undefined,
    });
    const currentStatus = status ? status : getRouteQuery({
        route,
        queryName: 'status',
        defaultValue: [],
        preparedReturnValueFunc: (value) => value.split(','),
    });
    const currentCurrencies = currencies ? currencies : getRouteQuery({
        route,
        queryName: 'currencies',
        defaultValue: [],
        preparedReturnValueFunc: (value) => value.split(','),
    });
    const currentNetworks = networks ? networks : getRouteQuery({
        route,
        queryName: 'networks',
        defaultValue: [],
        preparedReturnValueFunc: (value) => value.split(','),
    });
    let currentDate = date ? date : getRouteQuery({
        route,
        queryName: 'date',
        defaultValue: [],
        preparedReturnValueFunc: (value) => value.split('to'),
    });

    //crutch for plus sign in date
    currentDate = currentDate.map((i) => {
        i = new Date(i);
        return i.toISOString();
    },
    );

    const allCurrencies = rootState.app.currencies;
    const allNetworks = rootState.app.networks;

    const payload = JSON.stringify({
        organizationId,
        sortDate: currentSortDate,
        status: currentStatus,
        currencies: getCurrenciesIdsByNames(currentCurrencies, allCurrencies),
        networks: getNetworksIdsByNames(currentNetworks, allNetworks),
        date: currentDate,
    });


    window.open('/downloads/csv-withdrawals?_=' + payload);
};
