import uploadPayoutAddressInfoMutation from "../../../../graphQl/mutations/uploadPayoutAddressInfo";

export const updatePayoutAddressInfo = async ({ dispatch, commit, rootState, rootGetters }, payload ) => {
    // const accountId = rootState.accounts.accounts[0].accountId;
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    let response;

    try {
        response = await dispatch('app/request', {
            type: 'mutation',
            gql: uploadPayoutAddressInfoMutation,
            variables: { ...payload, organizationId },
        }, {root: true});
    } catch (error) {
        return error;
    }

    const { data } = response || {}
    const { updatePayoutAddressInfo } = data || {}

    return updatePayoutAddressInfo || {};
};