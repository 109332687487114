import gql from 'graphql-tag';

export default gql`
    query ($organizationId: String!, $offset: Int!, $limit: Int!, $query: String) {
        getPersonalUsers(organizationId:$organizationId, offset:$offset, limit:$limit, query:$query) {
            users {
                id
                balance
                clientId
                clientName
                clientEmail
                depositWebhookUrl
                createdAt
                updatedAt
            },
            total
        }
    }
`;
