<template>
  <div class="dashboard-balances" v-if="hasBalances">


    <div class="dashboard-balances__title-container">
      <div class="dashboard-balances__title-wrapper"><p class="dashboard-balances__label">
        {{ $t('dashboard.balance') }}
      </p>
        <div class="dashboard-balances__balance-container">

          <p class="dashboard-balances__balance-value"><span>$</span>{{ currentBalance }}</p>

          <div class="dashboard-balances__dynamic-container">
            <p class="dashboard-balances__balance-dynamic">${{ dayBalance }}</p>
            <slot/>
          </div>

        </div>
      </div>

    </div>
    <div :style="`--list-height: ${totalListHeight}px`"
         :class="['dashboard-balances__top-container', isBalanceCollapsed ? '' : 'dashboard-balances__top-container_showed']"
    >
      <div class="dashboard-balances__row" v-for="balance in balancesList" :key="balance.currency">
        <div class="dashboard-balances__currency">
          <CurrencyImg
              class="dashboard-balances__currency-img"
              :currency="balance.currency"
          />
          <p class="dashboard-balances__ticker">
            {{ balance.currency | getCurrencyTicker }}
          </p>
        </div>
        <p class="dashboard-balances__balance">
          {{ balance.balance }}
        </p>
      </div>
    </div>
    <button v-if=" Object.keys(balancesList).length > 3" @click.prevent="toggleBalances"
            class="dashboard-balances__button">
      <p class="dashboard-balances__button-title">
      {{ isBalanceCollapsed ? $t('dashboard.balances') : $t('dashboard.fold') }}
    </p>
    </button>
    <!--    -->
    <!--    <div class="dashboard-balances-row table-row "-->
    <!--         v-if="!hasBalances"-->
    <!--    >-->
    <!--      <div class="dashboard-balances-row__currency">-->
    <!--        {{ $t('dashboard.there_is_nothing') }}-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  -->
  </div>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import CurrencyImg from "/public/components/elements/CurrencyImg";
import BigNumber from "bignumber.js";
import {ORDER_STATUS_ENUM} from "../../../../common/constants/orders-status-enum";
import HourLabel from "../../common/hour-label";

export default {
    name: 'DashboardBalance',
    components: {
        HourLabel,
        CurrencyImg,
    },
    data() {
        return {
            isBalanceCollapsed: true,
            rowHeight: 53,
            rowsCollapsed: 3,
        };
    },
    methods: {
        toggleBalances() {
            this.isBalanceCollapsed = !this.isBalanceCollapsed;
        },
    },
    computed: {
    // ...mapState('balances', [
    //     'balances',
    // ]),
        ...mapState('app', [
            'currencies',
        ]),
        ...mapState('dashboard', [
            'orders',
        ]),
        ...mapGetters('balances', ['balances']),
        rates() {
            return this.currencies
                .reduce((acc, currency) => {
                    acc[currency.id] = currency.usdRate;

                    return acc;
                }, {});
        },

        hasBalances() {
            return !!this.balances.length;
        },
        balancesList() {

            return this.balances
                ?.reduce((acc, balance) => {
                    if (!acc[balance.currencyId]) {
                        acc[balance.currencyId] = {
                            currency: balance.currencyId,
                            balance: 0,
                        };
                    }

                    acc[balance.currencyId].balance = new BigNumber(acc[balance.currencyId].balance).plus(balance.balance);
                    acc[balance.currencyId].balanceUSD = new BigNumber(acc[balance.currencyId].balance)
                        .multipliedBy(this.rates[balance.currencyId] || 1)
                        .toFixed(2, 1);

                    return acc;
                }, {});

        },

        totalListHeight() {
            if (!this.isBalanceCollapsed) {
                return Object.keys(this.balancesList).length * this.rowHeight;
            } else {
                return (this.rowHeight * this.rowsCollapsed - 2);
            }
        },

        currentBalance() {
            const balances = Object.values(this.balancesList);

            return balances
                .reduce((acc, balance) => acc.plus(balance.balanceUSD), new BigNumber(0));
        },
        dayBalance() {
            return this.orders
                .reduce((acc, order) => {
                    if (order.status === ORDER_STATUS_ENUM.PROCESSED || order.status === ORDER_STATUS_ENUM.PARTIAL) {
                        acc = acc.plus(
                            new BigNumber(order.amount)
                                .multipliedBy(this.rates[order.currency]),
                        );
                    }

                    return acc;
                }, new BigNumber(0));
        },
    },
};

</script>
<style>
:root {

}
</style>
<style lang="scss" scoped>

.dashboard-balances {
  max-width: 446px;
  width: 100%;
  border-radius: $p30;
  background: var(--new-front-main-bg);
  display: flex;
  flex-direction: column;
  border: 1px solid var(--new-front-input-border);

  &__balance {
    font-family: $Gilroy;
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--new-front-primary-font-color);
    font-weight: 500;
  }

  &__ticker {
    font-family: $Gilroy;
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--new-front-primary-font-color);
    font-weight: 500;
  }


  &__button {
    display: none;
  }

  &__dynamic-container {
    display: flex;
    gap: 6px;
    align-items: center;
  }

  &__title-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__balance-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__balance-value {
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-size-h2);
    line-height: var(--line-height);
    color: var(--new-front-primary-font-color);

    & span {
      color: #8F949A;
    }
  }

  &__balance-dynamic {
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    text-align: right;


    color: #27AE60;
  }

  &__title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 35px;
    padding-right: 35px;
    background: var(--new-front-main-bg);
    min-height: 107px;
    width: 100%;
    border-bottom: 1px solid var(--new-front-input-border);
    border-radius: 30px 30px 0 0;
  }

  &__label {
    font-family: $Gilroy;
    font-size: var(--font-size-h2);
    line-height: var(--line-height);
    color: var(--new-front-primary-font-color);
    font-weight: 500;
  }

  &__currency-img {
    margin-right: 12px;
    width: 24px;
    height: 24px;
  }

  &__currency {
    display: flex;
    align-items: center;

  }

  &__top-container {
    max-height: 265px;
    overflow-y: scroll;
    padding-right: 35px;
    padding-left: 35px;
    min-height: 157px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    color: var(--new-front-primary-font-color);
    min-height: 53px;
    border-bottom: 1px solid var(--new-front-border-color);

  }


}
.dashboard-balances__button-title {
  color: var(--new-front-purple)
}
@media screen and (max-width: 1560px) {
  .dashboard-balances {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .dashboard-balances {
    padding-bottom: 0;

    &__title-container {
      min-height: 59px;
    }

    &__button-title {
      font-family: $Gilroy;
      font-style: normal;
      font-weight: 500;
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      text-align: center;
      color: var(--new-front-purple);
    }

    &__button {
      display: flex;
      justify-content: center;
      background: transparent;
      width: 100%;
      border: 1px solid var(--new-front-border-color);
      border-radius: 12px;
      min-height: 53px;
      align-self: center;
      align-items: center;
    }

    &__row {
      &:last-of-type {
        border-bottom: none;
      }
    }

    &__top-container {
      max-height: var(--list-height);
      overflow: hidden;
      transition: all .2s ease-in;

      &_showed {
        transition: all .2s ease-in;
        max-height: var(--list-height);

        margin-bottom: 15px;
      }
    }
  }
}
</style>
