import gql from 'graphql-tag';

export default gql`
        mutation($accountId: String, $addressId: String, $addressTo: String, $amount: String) {
           requestWithdrawal(accountId: $accountId, addressId: $addressId, addressTo: $addressTo, type: "WITHDRAWAL", amount: $amount) {
                type,
                status,
                reason,
                balanceId,
                addressId,
                currencyId,
                networkId,
                addressTo,
                amount,
                requestFee,
                totalFee,
                transactionHash
        }
    }
`;