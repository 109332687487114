<template>
<div class="container-new">
  <slot />
</div>
</template>
<script>
    export default{
        name: "Container"
    }
</script>
<style scoped lang="scss">
.container-new {
  padding: $p30;
  border-radius: $p30;
  background: var(--new-front-main-bg);
  @media (max-width: 576px) {
    padding: 0;
    border: 1px solid var(--new-front-divider-color);
  }
}
</style>
