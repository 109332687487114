<template>
  <div
    class="base-btn"
    :class="[ size, type, { disabled } ]"
    :style="{ height: height && `${height}px`}"
    @click="handleClick"
  >
    <slot name="prepend" />
    <slot name="label">
      <span
        v-if="label"
        class="label"
      >
        {{ label }}
      </span>
    </slot>
    <slot name="append" />
  </div>
</template>

<script>
export default {
  name: "base-button",
  props: {
    label: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'normal'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'primary'
    },
    height: {
      type: [Number, String],
      default: undefined
    }
  },
    methods: {
      handleClick () {
          if (this.disabled) {
              return
          }
          this.$emit('click')
      }
    }
}
</script>

<style scoped lang="scss">
.base-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  cursor: pointer;
  border-radius: 10px;
  &.big {
    height: 62px;
  }

  &.normal {
    height: 48px;
  }

  &.small {
    height: 34px;
  }

  .label {
    font-family: $Gilroy;

    font-style: normal;
    font-weight: 500;
    font-size: $h4;

    padding: 0 10px;

    white-space: nowrap;

    @include below_phone() {
      font-size: $h5;
    }
  }

  &.primary {
    background: var(--new-front-btn-bg-primary);

    .label {
      color: var(--new-front-btn-bg-primary-text);
    }

    &:hover {
      background: var(--new-front-btn-bg-primary-hover);
    }
    &:active {
      background: var(--new-front-btn-bg-primary-click);
    }

    &.disabled {
      background: var(--new-front-btn-bg-primary-disabled);
      cursor: not-allowed;

      .label {
        color: var(--new-front-btn-bg-primary-disabled-text);
      }
    }
  }

  &.secondary {
    background: var(--new-front-btn-bg-secondary);
    border: 1px solid var(--new-front-btn-bg-secondary-stroke);

    .label {
      color: var(--new-front-btn-bg-secondary-text);
    }

    &:hover {
      background: var(--new-front-btn-bg-secondary-hover);
    }
    &:active {
      background: var(--new-front-btn-bg-secondary-click);
    }

    &.disabled {
      background: var(--new-front-btn-bg-secondary-disabled);
      border: 1px solid var(--new-front-btn-bg-disabled-stroke);

      cursor: not-allowed;

      .label {
        color: var(--new-front-btn-bg-secondary-disabled-text);
      }
    }

    &.outline {
      border: none;
    }
    &.active {
      background: var(--new-front-btn-bg-secondary-hover);
    }
  }

  &.select {
    background: var(--new-front-bg-btn-select-bg-secondary);

    .label {
      color: var(--new-front-text-btn-select-bg-secondary);
    }

    &:hover {
      background: var(--new-front-bg-btn-select-bg-secondary-hover);
    }
    &:active {
      background: var(--new-front-bg-btn-select-bg-secondary-hover);
    }

    &.active {
      background: var(--new-front-bg-btn-select-bg-secondary-active);

      .label {
        color: var(--new-front-text-btn-select-bg-secondary-active);
      }
    }
  }

  &.whiteOnPurple {
    background: var(--new-front-bg-btn-primary-bg-purple);
    border: 1px solid var(--new-front-border-btn-primary-bg-purple);

    .label {
      color: var(--new-front-text-btn-primary-bg-purple);
    }

    &:hover {
      background: var(--new-front-bg-btn-primary-bg-purple-hover);
    }
    &:active {
      background: var(--new-front-bg-btn-primary-bg-purple-click);
    }

    &.disabled {
      background: var(--new-front-bg-btn-primary-bg-purple-disabled);
      border: 1px solid var(--new-front-border-btn-primary-bg-purple-disabled);

      cursor: not-allowed;

      .label {
        color: var(--new-front-text-btn-primary-bg-purple-disabled);
      }
    }

    &.outline {
      border: none;
    }
    &.active {
      background: var(--new-front-bg-btn-primary-bg-purple-click);
    }
  }
}
</style>
