<template>
  <svg
    v-if="name === 'csv'"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21.1248 6.82505H16.2498C16.1636 6.82505 16.0809 6.79081 16.02 6.72986C15.959 6.66891 15.9248 6.58624 15.9248 6.50005V1.62505C15.9248 1.53885 15.959 1.45619 16.02 1.39524C16.0809 1.33429 16.1636 1.30005 16.2498 1.30005C16.336 1.30005 16.4187 1.33429 16.4796 1.39524C16.5406 1.45619 16.5748 1.53885 16.5748 1.62505V6.17505H21.1248C21.211 6.17505 21.2937 6.20929 21.3546 6.27024C21.4156 6.33119 21.4498 6.41385 21.4498 6.50005C21.4498 6.58624 21.4156 6.66891 21.3546 6.72986C21.2937 6.79081 21.211 6.82505 21.1248 6.82505Z" fill="#0059F7"/>
    <path d="M21.1248 10.064C21.0386 10.064 20.9559 10.0298 20.895 9.96881C20.834 9.90786 20.7998 9.8252 20.7998 9.739V6.6346L16.1153 1.95005H6.4998C6.15503 1.95005 5.82437 2.08701 5.58057 2.33081C5.33678 2.57461 5.1998 2.90527 5.1998 3.25005V9.6311C5.1998 9.7173 5.16556 9.79996 5.10461 9.86091C5.04367 9.92186 4.961 9.9561 4.8748 9.9561C4.78861 9.9561 4.70594 9.92186 4.64499 9.86091C4.58405 9.79996 4.5498 9.7173 4.5498 9.6311V3.25005C4.5498 2.73288 4.75525 2.23689 5.12095 1.87119C5.48664 1.5055 5.98264 1.30005 6.4998 1.30005H16.2498C16.2925 1.29997 16.3348 1.30832 16.3743 1.3246C16.4138 1.34088 16.4497 1.36479 16.4799 1.39495L21.3549 6.26995C21.3851 6.30019 21.409 6.33607 21.4253 6.37556C21.4415 6.41504 21.4499 6.45734 21.4498 6.50005V9.739C21.4498 9.78168 21.4414 9.82394 21.4251 9.86337C21.4087 9.9028 21.3848 9.93863 21.3546 9.96881C21.3244 9.99899 21.2886 10.0229 21.2492 10.0393C21.2097 10.0556 21.1675 10.064 21.1248 10.064Z" fill="#0059F7"/>
    <path d="M19.4998 24.7H6.4998C5.98264 24.7 5.48664 24.4946 5.12095 24.1289C4.75525 23.7632 4.5498 23.2672 4.5498 22.75V17.875C4.5498 17.7889 4.58405 17.7062 4.64499 17.6452C4.70594 17.5843 4.78861 17.55 4.8748 17.55C4.961 17.55 5.04367 17.5843 5.10461 17.6452C5.16556 17.7062 5.1998 17.7889 5.1998 17.875V22.75C5.1998 23.0948 5.33678 23.4255 5.58057 23.6693C5.82437 23.9131 6.15503 24.05 6.4998 24.05H19.4998C19.8446 24.05 20.1752 23.9131 20.419 23.6693C20.6628 23.4255 20.7998 23.0948 20.7998 22.75V17.875C20.7998 17.7889 20.834 17.7062 20.895 17.6452C20.9559 17.5843 21.0386 17.55 21.1248 17.55C21.211 17.55 21.2937 17.5843 21.3546 17.6452C21.4156 17.7062 21.4498 17.7889 21.4498 17.875V22.75C21.4498 23.2672 21.2444 23.7632 20.8787 24.1289C20.513 24.4946 20.017 24.7 19.4998 24.7Z" fill="#0059F7"/>
    <path d="M17.8748 18.2H8.1248C8.03861 18.2 7.95594 18.1658 7.89499 18.1049C7.83405 18.0439 7.7998 17.9612 7.7998 17.875C7.7998 17.7889 7.83405 17.7062 7.89499 17.6452C7.95594 17.5843 8.03861 17.55 8.1248 17.55H17.8748C17.961 17.55 18.0437 17.5843 18.1046 17.6452C18.1656 17.7062 18.1998 17.7889 18.1998 17.875C18.1998 17.9612 18.1656 18.0439 18.1046 18.1049C18.0437 18.1658 17.961 18.2 17.8748 18.2Z" fill="#0059F7"/>
    <path d="M12.9998 21.45H8.1248C8.03861 21.45 7.95594 21.4158 7.89499 21.3549C7.83405 21.2939 7.7998 21.2112 7.7998 21.125C7.7998 21.0389 7.83405 20.9562 7.89499 20.8952C7.95594 20.8343 8.03861 20.8 8.1248 20.8H12.9998C13.086 20.8 13.1687 20.8343 13.2296 20.8952C13.2906 20.9562 13.3248 21.0389 13.3248 21.125C13.3248 21.2112 13.2906 21.2939 13.2296 21.3549C13.1687 21.4158 13.086 21.45 12.9998 21.45Z" fill="#0059F7"/>
    <path d="M21.1248 18.2001H4.8748C4.35764 18.2001 3.86164 17.9947 3.49595 17.629C3.13025 17.2633 2.9248 16.7673 2.9248 16.2501V11.2562C2.9248 10.739 3.13025 10.243 3.49595 9.87729C3.86164 9.5116 4.35764 9.30615 4.8748 9.30615H4.8878L21.1378 9.41405C21.6524 9.41884 22.1443 9.62621 22.507 9.99122C22.8697 10.3562 23.0739 10.8495 23.0755 11.3641V16.2501C23.0755 16.5062 23.025 16.7599 22.927 16.9965C22.8289 17.2331 22.6852 17.4481 22.5041 17.6292C22.3229 17.8103 22.1079 17.9539 21.8712 18.0519C21.6346 18.1498 21.3809 18.2002 21.1248 18.2001ZM4.8748 9.95615C4.53003 9.95615 4.19936 10.0931 3.95557 10.3369C3.71177 10.5807 3.5748 10.9114 3.5748 11.2562V16.2501C3.5748 16.5949 3.71177 16.9255 3.95557 17.1693C4.19936 17.4131 4.53003 17.5501 4.8748 17.5501H21.1248C21.4696 17.5501 21.8002 17.4131 22.044 17.1693C22.2878 16.9255 22.4248 16.5949 22.4248 16.2501V11.3641C22.4238 11.0209 22.2876 10.692 22.0457 10.4487C21.8038 10.2053 21.4757 10.0671 21.1326 10.0641L4.8826 9.95615H4.8748Z" fill="#0059F7"/>
    <path d="M10.9512 14.1363L11.5304 14.2013C11.5036 14.526 11.3608 14.8301 11.128 15.058C10.897 15.2825 10.5937 15.3947 10.218 15.3947C9.90125 15.3947 9.64428 15.3152 9.44711 15.1562C9.24068 14.9835 9.08368 14.7592 8.99211 14.5062C8.8827 14.2183 8.82846 13.9125 8.83221 13.6046C8.83221 13.0764 8.95745 12.6469 9.20791 12.3163C9.45838 11.9857 9.80288 11.8201 10.2414 11.8197C10.5578 11.8096 10.8657 11.9234 11.0994 12.1369C11.3278 12.3484 11.4716 12.6517 11.531 13.0469L10.9519 13.108C10.8453 12.585 10.61 12.3235 10.246 12.3235C10.0124 12.3235 9.83083 12.4318 9.70126 12.6485C9.5717 12.8651 9.5067 13.1804 9.50626 13.5942C9.50626 14.0141 9.5756 14.3311 9.71426 14.5452C9.85293 14.7592 10.0367 14.8663 10.2655 14.8663C10.3544 14.8669 10.4425 14.8479 10.5233 14.8106C10.604 14.7733 10.6756 14.7186 10.7328 14.6505C10.8607 14.5081 10.9376 14.3272 10.9512 14.1363Z" fill="#0059F7"/>
    <path d="M11.6611 14.5048L12.2682 14.3748C12.341 14.7184 12.6203 14.8902 13.1061 14.8902C13.3279 14.8902 13.4985 14.8469 13.6176 14.7602C13.6718 14.7248 13.7164 14.6767 13.7476 14.62C13.7787 14.5633 13.7955 14.4999 13.7964 14.4352C13.7961 14.3423 13.7678 14.2517 13.7151 14.1752C13.661 14.095 13.545 14.032 13.3674 13.9861L12.7044 13.8132C12.5268 13.7742 12.357 13.7059 12.2019 13.611C12.0887 13.5297 11.999 13.4198 11.9419 13.2925C11.8768 13.1552 11.8435 13.005 11.8444 12.8531C11.8396 12.7106 11.8683 12.5689 11.9282 12.4395C11.9882 12.3101 12.0777 12.1966 12.1896 12.1082C12.4197 11.921 12.7141 11.8274 13.0729 11.8274C13.3516 11.8224 13.6268 11.8896 13.8718 12.0224C13.9809 12.0785 14.077 12.1567 14.1541 12.2521C14.2313 12.3474 14.2876 12.4578 14.3196 12.5762L13.7255 12.7238C13.6341 12.4616 13.4209 12.3305 13.0859 12.3305C12.8914 12.3305 12.7427 12.3712 12.64 12.4527C12.5917 12.4885 12.5526 12.5353 12.5259 12.5892C12.4992 12.6431 12.4858 12.7026 12.4866 12.7628C12.4866 12.9608 12.6474 13.0997 12.9689 13.1794L13.5578 13.327C13.9149 13.4167 14.1543 13.549 14.2761 13.7241C14.3962 13.8937 14.46 14.0967 14.4587 14.3046C14.4647 14.4597 14.4327 14.6139 14.3657 14.7538C14.2986 14.8938 14.1985 15.0154 14.0739 15.108C13.8174 15.3034 13.4852 15.4011 13.0775 15.4011C12.7629 15.4084 12.4519 15.3341 12.1746 15.1853C12.0465 15.1136 11.9348 15.0162 11.8464 14.899C11.758 14.7818 11.6949 14.6476 11.6611 14.5048Z" fill="#0059F7"/>
    <path d="M16.2701 15.3401H15.635L14.5664 11.8743H15.2268L16.0218 14.5302L16.8349 11.8743H17.3549L16.2701 15.3401Z" fill="#0059F7"/>
  </svg>
  <svg
    v-else-if="name === 'excel'"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21.125 6.825H16.25C16.1639 6.825 16.0812 6.79076 16.0202 6.72981C15.9593 6.66886 15.925 6.5862 15.925 6.5V1.625C15.925 1.53881 15.9593 1.45614 16.0202 1.39519C16.0812 1.33424 16.1639 1.3 16.25 1.3C16.3362 1.3 16.4189 1.33424 16.4799 1.39519C16.5408 1.45614 16.575 1.53881 16.575 1.625V6.175H21.125C21.2112 6.175 21.2939 6.20924 21.3549 6.27019C21.4158 6.33114 21.45 6.41381 21.45 6.5C21.45 6.5862 21.4158 6.66886 21.3549 6.72981C21.2939 6.79076 21.2112 6.825 21.125 6.825Z" fill="#0F773D"/>
    <path d="M21.125 10.064C21.0389 10.064 20.9562 10.0297 20.8952 9.96877C20.8343 9.90782 20.8 9.82515 20.8 9.73895V6.63455L16.1155 1.95H6.50005C6.15527 1.95 5.82461 2.08697 5.58081 2.33076C5.33701 2.57456 5.20005 2.90522 5.20005 3.25V9.63105C5.20005 9.71725 5.16581 9.79992 5.10486 9.86086C5.04391 9.92181 4.96124 9.95605 4.87505 9.95605C4.78885 9.95605 4.70619 9.92181 4.64524 9.86086C4.58429 9.79992 4.55005 9.71725 4.55005 9.63105V3.25C4.55005 2.73283 4.75549 2.23684 5.12119 1.87115C5.48689 1.50545 5.98288 1.3 6.50005 1.3H16.25C16.2928 1.29993 16.3351 1.30827 16.3745 1.32455C16.414 1.34084 16.4499 1.36474 16.4801 1.3949L21.3551 6.2699C21.3853 6.30014 21.4092 6.33603 21.4255 6.37551C21.4418 6.41499 21.4501 6.4573 21.45 6.5V9.73895C21.45 9.78163 21.4416 9.8239 21.4253 9.86333C21.409 9.90276 21.385 9.93859 21.3549 9.96877C21.3247 9.99894 21.2889 10.0229 21.2494 10.0392C21.21 10.0555 21.1677 10.064 21.125 10.064Z" fill="#0F773D"/>
    <path d="M19.5 24.7H6.50005C5.98288 24.7 5.48689 24.4946 5.12119 24.1289C4.75549 23.7632 4.55005 23.2672 4.55005 22.75V17.875C4.55005 17.7888 4.58429 17.7061 4.64524 17.6452C4.70619 17.5842 4.78885 17.55 4.87505 17.55C4.96124 17.55 5.04391 17.5842 5.10486 17.6452C5.16581 17.7061 5.20005 17.7888 5.20005 17.875V22.75C5.20005 23.0948 5.33701 23.4254 5.58081 23.6692C5.82461 23.913 6.15527 24.05 6.50005 24.05H19.5C19.8448 24.05 20.1755 23.913 20.4193 23.6692C20.6631 23.4254 20.8 23.0948 20.8 22.75V17.875C20.8 17.7888 20.8343 17.7061 20.8952 17.6452C20.9562 17.5842 21.0389 17.55 21.125 17.55C21.2112 17.55 21.2939 17.5842 21.3549 17.6452C21.4158 17.7061 21.45 17.7888 21.45 17.875V22.75C21.45 23.2672 21.2446 23.7632 20.8789 24.1289C20.5132 24.4946 20.0172 24.7 19.5 24.7Z" fill="#0F773D"/>
    <path d="M17.875 18.2H8.12505C8.03885 18.2 7.95619 18.1658 7.89524 18.1048C7.83429 18.0439 7.80005 17.9612 7.80005 17.875C7.80005 17.7888 7.83429 17.7061 7.89524 17.6452C7.95619 17.5842 8.03885 17.55 8.12505 17.55H17.875C17.9612 17.55 18.0439 17.5842 18.1049 17.6452C18.1658 17.7061 18.2 17.7888 18.2 17.875C18.2 17.9612 18.1658 18.0439 18.1049 18.1048C18.0439 18.1658 17.9612 18.2 17.875 18.2Z" fill="#0F773D"/>
    <path d="M13 21.45H8.12505C8.03885 21.45 7.95619 21.4158 7.89524 21.3548C7.83429 21.2939 7.80005 21.2112 7.80005 21.125C7.80005 21.0388 7.83429 20.9561 7.89524 20.8952C7.95619 20.8342 8.03885 20.8 8.12505 20.8H13C13.0862 20.8 13.1689 20.8342 13.2299 20.8952C13.2908 20.9561 13.325 21.0388 13.325 21.125C13.325 21.2112 13.2908 21.2939 13.2299 21.3548C13.1689 21.4158 13.0862 21.45 13 21.45Z" fill="#0F773D"/>
    <path d="M21.125 18.2H4.87505C4.35788 18.2 3.86189 17.9946 3.49619 17.6289C3.13049 17.2632 2.92505 16.7672 2.92505 16.25V11.2561C2.92505 10.7389 3.13049 10.2429 3.49619 9.8772C3.86189 9.5115 4.35788 9.30605 4.87505 9.30605H4.88805L21.1381 9.41395C21.6526 9.41874 22.1445 9.62611 22.5072 9.99112C22.8699 10.3561 23.0742 10.8494 23.0757 11.364V16.25C23.0757 16.5061 23.0252 16.7598 22.9272 16.9964C22.8292 17.233 22.6855 17.448 22.5043 17.6291C22.3232 17.8102 22.1081 17.9538 21.8715 18.0518C21.6348 18.1497 21.3812 18.2001 21.125 18.2ZM4.87505 9.95605C4.53027 9.95605 4.19961 10.093 3.95581 10.3368C3.71201 10.5806 3.57505 10.9113 3.57505 11.2561V16.25C3.57505 16.5948 3.71201 16.9254 3.95581 17.1692C4.19961 17.413 4.53027 17.55 4.87505 17.55H21.125C21.4698 17.55 21.8005 17.413 22.0443 17.1692C22.2881 16.9254 22.4251 16.5948 22.4251 16.25V11.364C22.424 11.0208 22.2878 10.6919 22.0459 10.4486C21.804 10.2052 21.476 10.067 21.1329 10.064L4.88285 9.95605H4.87505Z" fill="#0F773D"/>
    <path d="M9.90874 15.34H7.65649V11.8742H9.88599V12.3897H8.28114V13.3289H9.57074V13.8314H8.28114V14.8012H9.90614L9.90874 15.34Z" fill="#0F773D"/>
    <path d="M12.3617 15.34H11.7L11.1566 14.406L10.5638 15.34H10.0659L10.92 14.0199L10.181 12.7758H10.844L11.2652 13.5298L11.7449 12.7758H12.2298L11.505 13.8879L12.3617 15.34Z" fill="#0F773D"/>
    <path d="M14.131 14.3981L14.6289 14.4488C14.5643 14.7704 14.4399 15.0087 14.2558 15.1638C14.0674 15.3191 13.829 15.4006 13.585 15.3933C13.2309 15.3933 12.9588 15.2633 12.7686 15.0033C12.5725 14.7268 12.4723 14.3937 12.4832 14.0549C12.4832 13.6688 12.5872 13.3497 12.7952 13.0975C12.8953 12.9743 13.0225 12.876 13.167 12.8103C13.3115 12.7447 13.4693 12.7135 13.6279 12.7192C14.1864 12.7192 14.519 13.0136 14.6256 13.6025L14.1277 13.6708C14.0844 13.3527 13.9277 13.1937 13.6578 13.1937C13.5687 13.1896 13.4805 13.2122 13.4043 13.2585C13.3281 13.3048 13.2675 13.3728 13.2301 13.4537C13.1363 13.6478 13.0906 13.8616 13.0968 14.0771C13.0968 14.3371 13.143 14.5368 13.2353 14.6763C13.2757 14.743 13.3331 14.7976 13.4017 14.8346C13.4702 14.8716 13.5474 14.8897 13.6253 14.8869C13.8796 14.8856 14.0482 14.7227 14.131 14.3981Z" fill="#0F773D"/>
    <path d="M16.7051 14.5223L17.2251 14.5932C17.1593 14.8225 17.0203 15.024 16.8293 15.1671C16.6154 15.3234 16.355 15.4031 16.0902 15.3933C15.7349 15.3933 15.4489 15.2739 15.2322 15.0352C15.0156 14.7964 14.9072 14.4731 14.9072 14.0654C14.9072 13.678 15.0156 13.3573 15.2322 13.1034C15.4489 12.8494 15.7451 12.7225 16.1208 12.7225C16.4865 12.7225 16.7682 12.8477 16.9658 13.0982C17.1634 13.3486 17.2633 13.6702 17.2654 14.0628V14.1278H15.5098C15.5055 14.2692 15.5222 14.4104 15.5592 14.547C15.5948 14.6561 15.6628 14.7518 15.7542 14.8213C15.8596 14.9016 15.9896 14.943 16.1221 14.9383C16.4098 14.9387 16.6042 14.8001 16.7051 14.5223ZM16.6694 13.7553C16.6752 13.5878 16.6155 13.4246 16.503 13.3003C16.4515 13.2459 16.3897 13.2024 16.3211 13.1723C16.2525 13.1422 16.1786 13.1261 16.1037 13.125C16.0289 13.1239 15.9545 13.1378 15.8851 13.1659C15.8157 13.194 15.7526 13.2357 15.6996 13.2886C15.585 13.4165 15.5185 13.5804 15.5117 13.7521L16.6694 13.7553Z" fill="#0F773D"/>
    <path d="M18.3763 15.34H17.7971V11.8742H18.3763V15.34Z" fill="#0F773D"/>
  </svg>
</template>

<script>
export default {
  name: "HistoryIcons",
  props: {
    name: {
      type: String,
      default: 'csv'
    },
  },
}
</script>