export const REGISTRY_STATUS_ENUM = {
    // Создали черновик на основе данных от пользователя, который требует повторного подтверждения
    DRAFT: 'draft',
    // Сохранили реестр для пользователя
    INIT: 'init',
    // Отправили реестр на обработку
    PENDING: 'pending',
    // Все выводы завершились с ошибкой
    ERROR: 'error',
    // Все успешно выведено
    PROCESSED: 'processed',
    // Часть вывели, часть не получилось вывести
    PARTIAL: 'partial',
};
