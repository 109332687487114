<script>
  import {mapActions, mapGetters, mapState} from "vuex";
  import BaseSelect from "@/public/components/common/uiKit/selects/base/index.vue";
  import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
  import BaseInput from "@/public/components/common/uiKit/input/base/index.vue";
  import BusinessWalletNetworkSelect
    from "@/public/components/elements/business-walet/Select/businessWalletNetworkSelect.vue";
  import {createBankAccount} from "@/public/store/modules/sepaSwift/actions/createAccount";
  import {fetchBankAccounts} from "@/public/store/modules/sepaSwift/actions/fetchBankAccount";
  const types = [
        {
          name: 'SEPA',
          value: 'SEPA',
        },
        {
          name: 'SWIFT',
          value: 'SWIFT',
        }
      ]
  export default {
    name: "CreateBankInvoice",
    components: {
      BusinessWalletNetworkSelect,
      BaseInput,
      BaseButton,
      BaseSelect,
      StoreModal: () => import(/* webpackChunkName: "/public/components/modals/StoreModal" */ '/public/components/modals/StoreModal'),
      NewSpinner: () => import (/* webpackChunkName: "Spinner" */ "/public/components/common/uiKit/spinner/index.vue"),
    },
    types,
    data () {
      return {
        type: null,
        selectedCurrency: null,
        isLoading: false,
        alias: '',
        iban: '',
        bic: '',
        account: '',
        companyName: '',
        bankAddress: '',
        bankName: '',
        bankCountryCode: '',
        postalCode: '',
        error: ''
      }
    },
    methods: {
      ...mapActions('app', [
        'closeModal',
      ]),
      ...mapActions('sepaSwift', [
        'createBankAccount',
          'fetchBankAccounts'
      ]),
      validateIban () {
        this.error = ''
        const regexp = new RegExp(this.validation.regexp)
        const country = this.validation.countries.find((item) => item.code === this.bankCountryCode)

        if(!country) {
          return this.error = 'countryCode';
        }

        if (!regexp.test(this.iban) || this.iban.length !== country.len) {
          return this.error = 'iban'
        }

        return this.error = ''
      },
      async handleCreate () {
        const {
          type,
          iban,
          bic,
          account,
          companyName,
          bankAddress,
          bankName,
          bankCountryCode,
          postalCode,
          alias,
        } = this
        this.validateIban()
        if(this.error) {
          return
        }


        this.isLoading = true
        const params = {
              type: type.value,
              iban,
              bic,
              account,
              companyName,
              bankAddress,
              bankName,
              bankCountryCode,
              postalCode,
              alias,
              currencyId: this.selectedCurrency.id
        }
        await this.createBankAccount(params)
            .then(() => {
              this.fetchBankAccounts()
              this.clearData()
              this.handleCancelCreate()
            })
            .catch((err) => console.log(err))
            .finally(() => {
              this.isLoading = false
            })
      },
      clearData() {
        this.type = null;
        this.selectedCurrency = null;
        this.isLoading = false;
        this.alias = '';
        this.iban = '';
        this.bic = '';
        this.account = '';
        this.companyName = '';
        this.bankAddress = '';
        this.bankName = '';
        this.bankCountryCode = '';
        this.postalCode = '';
        this.error = '';
      },
      handleCancelCreate() {
        const {
          closeModal,
        } = this;

        closeModal();
      },
    },
    computed: {
      ...mapGetters('app', [
        'sortedCurrencies',
        'isOpenModal',
      ]),
      ...mapState('app', ['currencies']),
      ...mapState('sepaSwift', ['validation']),
      isCreateButtonDisabled () {
        const {
          type,
          iban,
          bic,
          account,
          companyName,
          bankAddress,
          bankName,
          bankCountryCode,
          postalCode,
          selectedCurrency
        } = this
        return !type || !iban || !bic || !account || !companyName || !bankAddress || !bankName || !bankCountryCode || !postalCode || !selectedCurrency
      },
      filteredCurrencies () {
        const availableCurrencies = this.currencies.filter((item) => item.isFiat && item.allowAccounts)
        if (this.type?.value === 'SEPA') {
          return availableCurrencies.filter(item => item.currency === 'EUR')
        }
        if (this.type?.value === 'SWIFT') {
          return availableCurrencies.filter(item => item.currency === 'USD')
        }
        return availableCurrencies
      },
      isOpen() {

        const {
          $options: {
            name,
          },
          isOpenModal,
        } = this;

        return isOpenModal(name);
      },
      title() {
        return this.$t('bank.add-invoice');
      },
    }
  }
</script>

<template>
<StoreModal
    class="invoiceModal"
    :value="isOpen"
    :title="title"
    :show-close="true"
    :outside-close="false"
    name="CreateBankInvoice"
>
  <template #header>
      <div class="title">
        {{ title }}
      </div>
  </template>
  <template #body>
    <div v-if="!isLoading" class="content">
      <base-input
          v-model="alias"
          :label="$t('bank.alias')"
          :placeholder="$t('bank.enter-alias')"
          type="text"
      />
      <div class="row">
        <base-select
            v-model="type"
            :list="$options.types"
            :label="'SWIFT/SEPA'"
            :placeholder="$t('bank.enter-sepa-swift')"
            uniqKey="value"
        >
          <template #selected="{name}">
            <div class="select-item">
              {{ name }}
            </div>

          </template>
          <template #info="{name}" >
            <div class="select-item">
              {{ name }}
            </div>
          </template>

        </base-select>
        <business-wallet-network-select
            v-model="selectedCurrency"
            :list="filteredCurrencies"
            :label="$t('bank.wallet')"
            :placeholder="$t('bank.select-wallet')"
        />
      </div>
      <base-input
          v-model="companyName"
          :label="$t('bank.company')"
          :placeholder="$t('bank.enter-company')"
          type="text"
      />
      <base-input
          v-model="account"
          :label="$t('bank.account')"
          :placeholder="$t('bank.enter-account')"
          type="text"
      />
      <div>
        <base-input
            v-model="iban"
            :label="'IBAN'"
            :placeholder="$t('bank.enter-iban')"
            type="text"
        />
        <div class="error">
          <span v-if="error === 'iban'">{{ $t('bank.iban-error') }}</span>
        </div>
      </div>
      <base-input
          v-model="bankAddress"
          :label="$t('bank.bank-address')"
          :placeholder="$t('bank.enter-bank-address')"
          type="text"
      />
      <div class="row">
        <base-input
            v-model="bankName"
            :label="$t('bank.bank')"
            :placeholder="$t('bank.enter-bank')"
            type="text"
        />
        <div>
          <base-input
              v-model="bankCountryCode"
              :label="$t('bank.bank-code')"
              :placeholder="$t('bank.enter-bank-code')"
              type="text"
          />
          <div class="error">
            <span v-if="error === 'countryCode'">{{ $t('bank.country-code-error') }}</span>
          </div>
        </div>

      </div>
      <div class="row">
        <base-input
            v-model="bic"
            :label="'SWIFT/BIC'"
            :placeholder="$t('bank.enter-bic')"
            type="text"
        />
        <base-input
            v-model="postalCode"
            :label="$t('bank.postal-code')"
            :placeholder="$t('bank.enter-postal-code')"
            type="text"
        />
      </div>
    </div>
    <new-spinner v-else/>
  </template>
  <template v-if="!isLoading" #footer>
    <div class="footer">
      <base-button  type="secondary" :label="$t('create-invoice-modal.close')"  @click="handleCancelCreate" />
      <base-button   type="primary" :label="$t('create-invoice-modal.create')" :disabled="isCreateButtonDisabled" @click="handleCreate" />
    </div>
  </template>
</StoreModal>
</template>

<style scoped lang="scss">
.title {
  font-family: $Gilroy;
  font-size: $h1;
  font-weight: 500;
  color: var(--new-front-primary-font-color);
  text-align: center;
  @media (max-width: 450px) {
    font-size: $h2;
  }
}
.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.content {
  padding: 15px 30px 50px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.select-item {
  display: flex;
  align-items: center;
  gap: 7px;

  min-height: 32px;
}
::v-deep {
  .options-list-item {
    margin-bottom: 10px;
  }
  .main-modal-wrapper .main-modal-body {
    overflow: auto;
  }
  .base-select-wrapper .input-wrapper .input .placeholder {
    color: var(--new-front-placeholder-color);
  }
  .advanced-balances-select-wrapper .input-wrapper .input .placeholder {
    color: var(--new-front-placeholder-color);
  }
}
.error {
  color: var(--new-front-status-text-error);
}
</style>
