export const state = {
    type: '',
    listAddress: [],
    count: 0,
    pagesCount: 10,
    pages: [],
    page: {},
    address: [],
    currentNetwork: [],
    isInfoLoading: false,
    infoState: false,
    transaction: null,
    columns: [],
    defaultColumns: [
        {title: 'date_of_creation', active: true},
        {title: 'type', active: true},
        {title: 'risk', active: true},
        {title: 'coin', active: true},
        {title: 'network', active: true},
        {title: 'transfer_address', active: true},
        {title: 'write-off_address', active: true},
        {title: 'transaction', active: true},
        {title: 'sum', active: true},
        {title: 'status', active: true},
    ]
};
