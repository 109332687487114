import {request} from './actions/request';
import {apiRequest} from "./actions/apiRequest";
import {init} from './actions/init';
import {openModal} from "./actions/openModal";
import {closeModal} from "./actions/closeModal";
import {fetchCurrencies} from "./actions/fetchCurrencies";
import {fetchOrganizations} from "./actions/fetchOrganizations";
import {fetchSelectedOrganization} from "./actions/fetchSelectedOrganization";
import {clearAllStores} from "./actions/clearAllStores";
import {loginSubscriptions} from "@/public/store/modules/app/actions/loginSubscription";

export const actions = {
    request,
    apiRequest,
    init,
    openModal,
    closeModal,
    fetchCurrencies,
    fetchOrganizations,
    fetchSelectedOrganization,
    clearAllStores,
    loginSubscriptions,
};
