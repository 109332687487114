<template>
    <store-modal
      name="DepositRequestModal"
      class="deposit-request-modal"
    >
      <template #header>
        <div class="deposit-request-modal__header">
          <div class="title">
            {{ title }}
          </div>
          <div v-if="notCreated" class="subtitle">
            {{ subtitle }}
          </div>
        </div>
      </template>
      <template #body>
        <div class="deposit-request-modal__body">
          <network-and-currency-select
            v-if="notCreated"
            v-model="setCurrencyAndNetwork"
            :list="coinAndNetwork"
            uniq-key="uniq"
          />
          <template v-else>
            <div class="qr-block-wrapper">
              <div class="qr-code-wrapper">
                <div v-if="!timeIsOver">
                  <div
                    id="advance"
                    class="qr-code"
                  />
                </div>
                <div
                  v-else
                  class="cancel-icon-wrapper"
                >
                  <svg
                    class="cancel-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="86"
                    height="86"
                    viewBox="0 0 86 86"
                    fill="none"
                  >
                    <path
                      d="M14.875 14.875L71.125 71.125M43 83.5C37.6815 83.5 32.415 82.4524 27.5013 80.4171C22.5876 78.3818 18.1229 75.3986 14.3622 71.6378C10.6014 67.877 7.6182 63.4124 5.58288 58.4987C3.54756 53.585 2.5 48.3185 2.5 43C2.5 37.6815 3.54756 32.415 5.58288 27.5013C7.6182 22.5876 10.6014 18.1229 14.3622 14.3622C18.1229 10.6014 22.5876 7.61819 27.5013 5.58288C32.415 3.54756 37.6815 2.5 43 2.5C53.7413 2.5 64.0426 6.76695 71.6378 14.3622C79.233 21.9574 83.5 32.2587 83.5 43C83.5 53.7413 79.233 64.0426 71.6378 71.6378C64.0426 79.233 53.7413 83.5 43 83.5Z"
                      :stroke="themeStatus ? 'white' : 'black'"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div
                  v-if="currentNetworkAndCurrency"
                  class="qr-info-label"
                >
                  <currency-icon
                    :currency="currentNetworkAndCurrency.currency"
                  />
                  <span class="currency"> {{ currentNetworkAndCurrency.currency }} </span>
                  <span class="network"> - {{ networkShortName }} </span>
                </div>
              </div>
            </div>
            <fake-input class="payment-address">
              <template #label>
                <span class="payment-address-label">
                  {{ $t('advance_balances.payment-address') }}
                </span>
              </template>
              <template #value>
                <span :style="{ 'text-decoration' : timeIsOver ? 'line-through' : 'none'}">
                  {{ isPhoneView ? getTruncatedString(depositResultAddress) : depositResultAddress }}
                </span>
              </template>
              <template #suffix>
                <copy
                  v-if="!timeIsOver"
                  :value="depositResultAddress"
                />
              </template>
            </fake-input>
            <div class="progress-wrapper">
              <timer
                v-if="depositResultUntil"
                :started-at="depositResultFrom"
                :expires-at="depositResultUntil"
                :time-is-over.sync="timeIsOver"
              />
            </div>
          </template>
        </div>
      </template>
      <template #footer>
        <div
          class="deposit-request-modal__footer"
          :class="{ 'order-created': !notCreated }"
        >
          <template v-if="notCreated">
            <base-button
              :label="$t('advance_balances.btn-cancel')"
              type="secondary"
              @click="handleCancelCreate"
            />
            <base-button
              :label="$t('advance_balances.btn_get-an-address')"
              type="primary"
              :disabled="!currentNetworkAndCurrency"
              @click="handleCreateRequest"
            />
          </template>
          <template v-else>
            <base-button
              :label="$t('advance_balances.btn-close')"
              type="primary"
              @click="handleCancelCreate"
            />
          </template>
        </div>
      </template>
    </store-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import QRCodeStyling from "qr-code-styling";

import { getCurrencyTicker, getErrorMessage } from "/public/filters";

import debounce from 'lodash/debounce'

import {
  getTruncatedString
} from "@/entries/checkout/filters";

export default {
    name: 'DepositRequestModal',
    components: {
        StoreModal: () => import(/* webpackChunkName: "/public/components/modals/StoreModal" */ '/public/components/modals/StoreModal'),
        BaseButton: () => import(/* webpackChunkName: "/public/components/common/uiKit/button/base" */ '/public/components/common/uiKit/button/modals/bg-primary'),
        NetworkAndCurrencySelect: () => import(/* webpackChunkName: "/public/components/elements/advance-balances/NetworkAndCurrenciesSelect" */ '/public/components/elements/advance-balances/NetworkAndCurrenciesSelect'),
        CurrencyIcon: () => import(/* webpackChunkName: "/public/components/elements/advance-balances/NetworkAndCurrenciesSelect/CurrencyNetworkIcon" */ '/public/components/elements/advance-balances/NetworkAndCurrenciesSelect/CurrencyNetworkIcon'),
        FakeInput: () => import(/* webpackChunkName: "/public/components/common/uiKit/input/base" */ '/public/components/common/uiKit/input/FakeInput'),
        Copy: () => import(/* webpackChunkName: "/public/components/common/copy" */ '/public/components/common/copy'),

        Timer: () => import(/* webpackChunkName: "/public/components/common/uiKit/timer" */ '/public/components/common/uiKit/timer/index.vue'),
    },
    props: {
        currencyBalanceId: {
            type: String,
            default: '7ae2af85-bf07-4b36-a59f-2a07bf9ef152',
        },
    },
    data() {
        return {
            isLoading: false,
            isSuccess: false,
            loadingMessage: null,
            themeStatus: null,
            timeIsOver: undefined, //меняется через sync из дочернего компонента Timer
            currentNetworkAndCurrency: undefined,
            isPhoneView: false
        };
    },
    computed: {
        title() {
          return this.$t('advance_balances.replenishment');
        },
        subtitle() {
          return this.$t('advance_balances.replenishment_subtitle');
        },
        setCurrencyAndNetwork: {
          get() {
            return this.currentNetworkAndCurrency
          },
          set(v) {
            this.currentNetworkAndCurrency = v
          },
        },
        getTruncatedString() {
          return getTruncatedString
        },
        networkShortName({ currentNetworkAndCurrency }) {
          return currentNetworkAndCurrency ? currentNetworkAndCurrency.networks.shortName : ''
        },
        debounceOnResize: ({ onResize }) => debounce(onResize, 100),

        ...mapGetters('app', [
            'sortedCurrencies',
            'isOpenModal',
        ]),
        ...mapState('app', {
            currencies: state => state.currencies,
            payload: state => state.modal.payload,
        }),
        ...mapState('accounts', {
            accounts: state => state.accounts,

            deposit: state => state.deposit,
            depositLoading: state => state.deposit.loading,
            depositCreated: state => state.deposit.created,
            depositCode: state => state.deposit.code,
            depositResultAddress: state => state.deposit.result.address,
            depositResultTag: state => state.deposit.result.tag,
            depositResultFrom: state => state.deposit.result.from,
            depositResultUntil: state => state.deposit.result.until,
        }),
        notCreated() {
            return !this.successCreated;
        },
        successCreated() {
            return !!(this.depositCreated && this.depositCode === 0 && this.depositResultAddress);
        },
        // failCreated() {
        //     return !!(this.depositCreated && this.depositCode !== 0);
        // },

        depositPayload() {
            return {
                address: this.depositResultAddress,
                tag: this.depositResultTag,
                until: this.depositResultUntil,
            };
        },
        alertMessage() {
            if (!this.isOpen) {
                return {
                    type: '',
                    message: '',
                };
            }

            if (!this.currency) {
                return {
                    type: 'error',
                    message: this.$t('advance_balances.unknown'),
                };
            }

            if (!this.networks.length) {
                return {
                    type: 'warning',
                    message: this.$t('advance_balances.payments'),
                };
            }
            return {
                type: '',
                message: '',
            };
        },
        accountId() {
            return this.payload?.accountId;
        },
        account() {
            return this.accounts[0];
        },
        currency() {
            return this.currencies
                .find(currency => currency.id === this.account?.currencyId);
        },
        networks() {
            if (!this.currenciesStablecoins) {
                return [];
            }

            const currency = this.currencies
                .find(currency => currency.id === this.currencyId);

            if (!currency) {
                return [];
            }

            return currency.networks
                .filter(n => n.allowDeposit);
        },
        coinAndNetwork() {
            let allCoin = this.currencies.flatMap((list, i) => list.networks
                .map(current => ({
                    ...list,
                    networks: {
                      ...current,
                      shortName: this.shortNetworkName(current.alias)
                    },
                    uniq: i + '_' + current.id,
                    icon: `/assets/image/coins-logo/${list.currency.toUpperCase()}.png`,
                })));

            return allCoin.filter(arr => this.currenciesStablecoins.includes(arr.id) && arr.networks.allowDeposit);
        },
        isOpen() {
            const {
                $options: {name},
                isOpenModal,
            } = this;

            return isOpenModal(name);
        },
        currenciesStablecoins() {
            return this.account?.availableCurrenciesForDeposit || [];
        },
        colorQrCode() {
            const { themeStatus } = this
            if(themeStatus) {
                return {
                  bg: '#2D2844',
                  code: '#ffffff',
                };
            }

            return {
                bg: '#E7F0FF',
                code: '#000000',
            };
        },
    },
    watch: {
        timeIsOver(v) {
          const { depositResultAddress } = this

          if (!v && depositResultAddress) {
            this.themeStatus = localStorage.getItem('data-theme-private') === 'dark';
            this.qrCodeMobile(this.colorQrCode);
          }
        },
        isOpen(n, o) {
            if (n === o) {
                return;
            }

            if (!this.depositLoading) {
                this.clearAllData();
            }
        },
        depositLoading(n, o) {
            if (n === o) {
                return;
            }

            n ? this.showLoader() : this.closeLoader();
        },
        depositCreated(n) {
            if (!n) {
                return;
            }

            this.closeLoader();
        },
        depositCode(n, o) {
            if (n === o) {
                return;
            }

            if (n === 0) {
                return;
            }

            this.$Message.error({
                message: this.$t(getErrorMessage(n)),
                duration: 10000,
            });

            this.$nextTick(() => {
                this.clearDeposit();
            });
        },
    },
    created () {
      this.debounceOnResize()

      window.addEventListener(
          'resize',
          this.debounceOnResize,
          { passive: true }
      )
    },
    methods: {
        getClientWidth () {
          if (typeof document === 'undefined') return 0 // SSR
          return Math.max(
              document.documentElement.clientWidth,
              window.innerWidth || 0
          )
        },
        getClientHeight () {
          if (typeof document === 'undefined') return 0 // SSR
          return Math.max(
              document.documentElement.clientHeight,
              window.innerHeight || 0
          )
        },
        onResize () {
          const height = this.getClientHeight()
          const width = this.getClientWidth()


          this.isPhoneView = width <= 576
        },

        ...mapActions('accounts', [
            'accountDepositAddress',
            "clearDeposit",
        ]),
        ...mapActions('app', [
            'closeModal',
        ]),
        shortNetworkName( network ) {

          let shortName = network.match(/\((.+?)\)/)

          if (!shortName) {
            if (network === 'Fantom') {
              shortName = "FTM"
            } else if (network === 'Solana') {
              shortName = "SOL"
            } else {
              shortName = network
            }
          } else {
            shortName = shortName[1]
          }

          return shortName
        },
        qrCodeMobile(color) {
            const { depositResultAddress } = this

            const qrCode = new QRCodeStyling({
                width: 144,
                height: 144,
                type: "svg",
                data: depositResultAddress,
                dotsOptions: {
                    color: color.code,
                    type: "extra-rounded",
                },
                backgroundOptions: {
                    color: 'transparent',
                },
                imageOptions: {
                    crossOrigin: "anonymous",
                    margin: 20,
                },
                cornersSquareOptions: {
                    type: 'extra-rounded',
                    color: color.code,
                },
            });

            const qrDiv = document.getElementById("advance")

            if (!qrDiv) {
              // так как метод qrCodeMobile вызывается из вотчера timeIsOver только если время жизни не вышло и адреса
              // существует то остается дождаться когда отрендерится div по qr-код, если его еще нет, то перевызываем
              // метод qrCodeMobile через 200 мс
              setTimeout(() => {
                this.qrCodeMobile(color)
              }, 200)
            } else {
              this.$nextTick(() => {
                qrCode.append(qrDiv)
              })
            }
        },
        showLoader() {
            if (this.loadingMessage) {
                return;
            }

            this.loadingMessage = this.$Message.loading({
                message: this.$t('advance_balances.creating_an_address'),
                duration: 0,
            });
        },
        closeLoader() {
            if (!this.loadingMessage) {
                return;
            }

            this.loadingMessage();
        },

        clearAllData() {
            this.timeIsOver = undefined;
            this.currentNetworkAndCurrency = undefined;
            this.clearDeposit();
        },
        handleCreateRequest() {
            if (this.currentNetworkAndCurrency && this.account) {
                const network = this.currentNetworkAndCurrency['networks'].name
                const currency = getCurrencyTicker(this.currentNetworkAndCurrency.id);

                this.accountDepositAddress({
                    network,
                    accountId: this.account.accountId,
                    currency
                });
            } else {
                return;
            }
        },
        handleCancelCreate() {
            this.closeModal();
        }
    },
};

</script>

<style lang="scss" scoped>
  .deposit-request-modal {
    font-family: var(--new-front-font-family);

    &__header {
      text-align: center;

      .title {
        font-size: $h1; //32px
        font-weight: 500;
        margin-bottom: 20px;
        color: var(--new-front-text-primary-bg-modal-primary);
      }
      .subtitle {
        font-size: $h4; //16px
        color: var(--new-front-text-secondary-bg-modal-primary);
      }
    }
    &__body {
      padding: 0 20px;
      margin-bottom: 40px;

      .qr-block-wrapper {
        width: 100%;

        display: flex;

        justify-content: center;

        .qr-code-wrapper {
          border: 1px solid var(--border-color);
          border-radius: 30px;
          padding: 14px 0;

          .qr-code {
            width: 144px;
            height: 144px;

            margin: 0 14px;


            @media (min-width: 576px) {
              margin-top: 0;
              margin-bottom: 0;
            }
          }

          .qr-info-label {
            display: flex;

            border-top: 1px solid var(--border-color);
            margin-top: 10px;
            padding: 10px 10px 0 10px;

            align-items: center;
            justify-content: center;
            text-align: center;

            font-size: $h4;

            .currency {
              margin-left: 6px;
              color: var(--new-front-text-primary-bg-modal-primary);
            }
            .network {
              margin-left: 4px;
              color: var(--new-front-text-secondary-bg-modal-primary);
            }
          }

          .cancel-icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;
            min-width: 164px;
            height: 144px;
          }
        }
      }
      .payment-address {
        margin-top: 15px;
        .payment-address-label {
          font-size: $h6;
        }
      }
      .progress-wrapper {
        margin-top: 15px;
      }
    }
    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .base-btn {
        max-width: 280px;
        min-width: 260px;
      }

      &.order-created {
        .base-btn {
          min-width: 100%;
          max-width: 100%;
        }
      }
    }

    @include below_phone(){
      &__header {
        .title {
          font-size: $h3; //20px
          margin-bottom: 0;
          font-width: 500;
        }
        .subtitle {
          font-size: $h6; //13px
        }
      }
      &__body {
        .payment-address {
          .payment-address-label {
            font-size: $h8;
          }
        }
      }
      &__footer {
        justify-content: center;
        flex-wrap: wrap;

        .base-btn {
          margin-top: 10px;
          min-width: 100%;
          max-width: 100%;
        }

        &.order-created {
          .base-btn {
            margin-top: 0;
          }
        }
      }
    }
  }
</style>
