import getPriceQuery from "/public/graphQl/queries/getPrice";

export const getPrice = async ({ commit, dispatch }, payload) => {
    let price;

    try {
        price = await dispatch('app/request', {
            type: 'query',
            gql: getPriceQuery,
            variables: payload,
        }, { root: true });
    } catch (error) {
        return error;
    }

    commit('price', price);

    return price;
};