import getApiKeysQuery from "/public/graphQl/queries/getApiKeys";

export const fetchApiKeys = async ({commit, dispatch, rootGetters}) => {
    let apiKeysData;

    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    try {
        commit('app/toggleLoading', null, {root: true});
        apiKeysData = await dispatch('app/request', {
            type: 'query',
            gql: getApiKeysQuery,
            variables: { organizationId },
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (apiKeysData.message) {
        throw apiKeysData.message;
    }

    if (apiKeysData.errors) {
        throw apiKeysData.errors;
    }

    const {
        data: {getApiKeys},
    } = apiKeysData;

    commit('apiKeys', getApiKeys);

    return true;
};