export const es = {
    'invoice': {
        'invoice': 'Factura №:',
        'sum-invoice': 'Importe de la factura:',
        'select-invoice': 'Seleccione su moneda de pago',
        'coin-network': 'Moneda y red:',
        'rate': 'Cursos:',
        'sum-payment': 'Cantidad de pago:',
        'button': 'Pago',
        'search': 'Buscar',
    },
    'checkout': {
        'shop': 'Tienda',
        'payment': 'Pago',
        'back_to_the_store': 'Volver a la tienda',
        'to_make_a_payment': 'Para realizar el pago, envíe «{orderCurrency}» en línea «{orderAlias}» a la dirección especificada',
        'payment_completed': 'Pago realizado',
        'the_payment_is_invalid': 'El pago no es válido',
        'payment_rejected': 'Pago rechazado',
        'payment_pending': 'Pago en proceso',
        'payment_init': 'Pago pendiente',
        'lifetime_has_expired': 'Expiró el tiempo de vida del pago. No envíe monedas a la dirección especificada.',
        'contact_the_store_owner': 'Expiró el tiempo de vida del pago. No envíe monedas a la dirección especificada. Póngase en contacto con el propietario de la tienda para devolver las monedas o continuar con el pago',
        'order': 'Orden',
        'to_be_paid': 'Por pagar',
        'received': 'Recibí:',
        'description': 'Descripción:',
        'before_the_expiration_of_the_order': 'Plazo de pago:',
        'network': 'Red:',
        'payment_address': 'Dirección de pago:',
        'payment_address_not_found': 'Dirección de pago no encontrada',
        'tag': 'Etiqueta:',
        'contract_address': 'Dirección del contrato:',
        'send_to_this_address_only': 'Enviar a esta dirección solamente',
        'make_sure_the_network_is_correct': 'Asegúrese de que la red es correcta:',
        'network_is_correct': 'Asegúrese de que la red es correcta:',
        'contract_address_matches': 'Asegúrese de que la dirección del contrato coincida',
        'payment_page': 'Página de pago',
        'the_address_was_copied': 'Dirección copiada correctamente',
        'the_tag_was_copied': 'La etiqueta ha sido copiada',
        'invoice_was_successfully_paid': 'Orden ha sido pagado con éxito',
        'the_invoice_was_paid': 'Orden se pagó la suma',
        'important_info': 'Información importante',
        'the_contract_address_does_not_exist_on_this_network': 'La dirección del contrato no existe en esta red',
        'error': 'Error',
        'time_hours': 'horas',
        'time_minute': 'minutos',
        'time_second': 'segunda',
        'sellers_address': 'Dirección del vendedor verificada',
        'stored_table': 'Aquí será el historial de transacciones entrantes',
        'history_table': 'Historial de transacciones entrantes',
        'full_info': 'Mostrar información completa',
        'table_date': 'Fecha',
        'table_sum': 'Suma',
        'table_value': 'Moneda',
        'table_hash': 'tx_hash',
        'table_date_sum': 'Monto y fecha',
        'payment_partially_paid': 'Parcialmente pagado',
        'paid_timer': 'Pagado',
        'expired_timer': 'El pago ha expirado',
    },
    'new_checkout': {
        'details': {
            'title': 'Detalles',
            'order': 'Orden',
            'date': 'Fecha',
            'description': 'Descripción',
        },
        'notification': 'Solo envíe tokens <b>{orderCurrency}</b> en la red <b>{orderNetwork}</b> a esta dirección.',
        'payment_address': 'Dirección de Pago',
        'verified_notification': "La dirección del vendedor ha sido verificada",
        'paid_amount': "Monto de pago",
        'timer_notifications': {
            'remaining_time': 'Tiempo restante para pagar',
            'completed': 'El pago se ha completado con éxito',
            'expired_with_invoice': 'La vida útil del pedido ha expirado, pero puedes volver atrás y crear un nuevo pedido.',
            'expired_without_invoice': 'La vida útil del pago expiró',
            'dont_make_transactions': "No realice transacciones en este pedido!"
        },
        'statuses': {
            'not_paid': 'No pagado',
            'overpaid': 'En exceso',
            'partially_paid': 'Parcialmente pagado',
            'fully_paid': 'Totalmente pagado',
        },
        'history': {
            'title': 'Historial de pagos',
            'columns': {
                'date': 'Fecha',
                'amount': 'Cantidad',
                'tx_hash': 'Tx_hash'
            },
            'empty_notification': 'Todas sus transacciones enviadas a la dirección especificada estarán disponibles en esta ventana.',
            'ok': 'DE ACUERDO'
        },
        'orphan': {
            'title': 'Transacción huérfana',
            'description': 'Una de las transacciones de este pedido contiene la red o moneda incorrecta.',
            'contact': 'Póngase en contacto con el soporte técnico con ID',
        },
        'time-is-over': 'El tiempo se acabo',
        'payment-successful': 'Pago exitoso.',
        'partial-desc': 'El importe ha sido parcialmente pagado. el monto restante debe pagarse para que el pago se realice correctamente.',
        'cancel': 'Cancelar',
        'back_to_shop': 'Volver a la tienda',
        'back_to_invoice': 'Volver a la factura',
        'choose_another_currency': 'Elige otra moneda',
        'you_can_pay': '¡Podrás pagar la factura en múltiples monedas!'
    },
    'new_invoice': {
        'details': {
            title: 'Detalles',
            invoice: 'Factura',
            date: 'Fecha',
            description: 'Descripción'
        },
        'amount': 'Cantidad',
        'btn_label': 'Continuar',
        'btn_label_empty': 'Seleccione el tipo de moneda',
        'cancel': 'Cancelar',
        'search': 'Buscar...',
        'back_to_shop': 'Volver a la tienda',
        'choose-network': 'Elige red',
        'status': 'Estado',
        'you-payed': 'Tu pagaste',
        'statuses': {
            "ERROR": 'ERROR',
            "PENDING": 'PENDIENTE',
            "PROCESSED": 'PROCESADO',
            "REJECTED": 'RECHAZADO',
            'OVERPAID': 'SOBREPAGADO',
            "CREATED": 'CREADO',
            'PARTIAL': 'PAGADO PARCIAL',
            'INIT': 'INICIAR',
            'EXPIRED': 'VENCIÓ'
        },
        'active_order': 'Orden activa',
        'payment-successful': 'Pago exitoso'
    },
    'languages': {
        'select': 'Seleccione el idioma'
    },
    'preCheckout': {
        'errors': {
            'page_not_found': 'Página no encontrada',
            'page_not_found_text': 'Parece que no podemos encontrar la página que estás buscando',
        }
    }
};
