<template>
  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M13.5 24.75C19.7132 24.75 24.75 19.7132 24.75 13.5C24.75 7.2868 19.7132 2.25 13.5 2.25C7.2868 2.25 2.25 7.2868 2.25 13.5C2.25 19.7132 7.2868 24.75 13.5 24.75Z"
        fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.5 18H13.5117" stroke="#EB5757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.5 9V13.5" stroke="#EB5757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
    name: "icon-error-validation",
};
</script>

<style scoped>

</style>