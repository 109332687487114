import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!, $id: String!) {
        getOrder(organizationId: $organizationId, id: $id) {
            id
            currency
            amount
            address
            tag
            errorWebhook
            successWebhook
            returnUrl
            order
            description
            createdAt
            expiresAt
            status
            transactions {
                id
                type
                networkId
                status
                createdAt
                orderId
                currencyId
                transactionHash
                amount
                confirmations
                confirmationsExpected
                amountUSD
                priceUSD
            }
            link
            networkId
            received
            accountId
            contract

        }
    }
`;
