import gql from 'graphql-tag';

export default gql`
    query ($organizationId: String!, $id: String!) {
        orphanDeposit (organizationId: $organizationId,id: $id) {
            id
            organizationId
            orderId
            stage
            status
            message
            currencyId
            networkId
            amount
            canWithdrawal
            inTransaction {
                addressType
                addressId
                address
                txId
                amount
                status
                createdAt
            }
            outTransaction  {
                address
                txId
                amount
                status
                withdrawalId
                createdAt
            }
            createdAt
        }
    }
`;
