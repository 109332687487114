import gql from "graphql-tag";

export default gql`
    query($organizationId: String!, $pageId: String!) {
        getPreCheckoutPageCurrencies(organizationId: $organizationId, pageId: $pageId) {
           currencyId,
           networkId  
        }
    }
`;
