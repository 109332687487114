<script>

  import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
  import {mapActions} from "vuex";

  export default {
    name: "integrationsFaucetEth",
    components: {BaseButton},
    data () {
      return {
        isApollo: import.meta.env.VITE_PRODUCT_NAME === 'Apollopayment'
      }
    },
    methods: {
      ...mapActions('app', [
        'openModal',
      ]),
      openFaucet () {
        this.openModal({
          name: 'ModalFaucet',
        });
      }
    }
  }
</script>

<template>
<div class="docs">
  <div class="header">
    <div class="docs__title">
      Faucet ETH
    </div>
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="54" height="54" rx="27" fill="#627EEA"/>
      <mask id="mask0_20462_134790" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="16" y="11" width="22" height="32">
        <path d="M37.9996 11H16V43H37.9996V11Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_20462_134790)">
        <path d="M26.3963 11L26.1816 11.7292V32.8877L26.3963 33.1019L36.2177 27.2964L26.3963 11Z" fill="#C1CCF8"/>
        <path d="M26.3939 11L16.5723 27.2964L26.3939 33.1019V22.8322V11Z" fill="white"/>
        <path d="M26.3964 34.9626L26.2754 35.1102V42.6472L26.3964 43.0004L36.2237 29.1602L26.3964 34.9626Z" fill="#C1CCF8"/>
        <path d="M26.3939 43.0004V34.9626L16.5723 29.1602L26.3939 43.0004Z" fill="white"/>
        <path d="M26.4004 33.1018L36.2217 27.2963L26.4004 22.832V33.1018Z" fill="#8197EE"/>
        <path d="M16.5723 27.2963L26.3939 33.1018V22.832L16.5723 27.2963Z" fill="#C1CCF8"/>
      </g>
    </svg>
  </div>
  <div class="docs__links">
    <div class="docs__link"  @click="openFaucet">
        <span class="docs__link-title">{{ isApollo ? 'Apollopayment' : 'Onchainpay' }}</span>
        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.3755 0.505859C12.273 0.505859 13.0005 1.2334 13.0005 2.13086V11.8809C13.0005 12.7783 12.273 13.5059 11.3755 13.5059H1.62549C0.728026 13.5059 0.000488281 12.7783 0.000488281 11.8809V2.13086C0.000488281 1.2334 0.728026 0.505859 1.62549 0.505859H11.3755ZM4.79733 9.28353L8.12549 5.95538L8.12549 8.20419C8.12549 8.42856 8.30737 8.61044 8.53174 8.61044C8.7561 8.61044 8.93799 8.42856 8.93799 8.20419V4.9746C8.93799 4.75024 8.7561 4.56835 8.53174 4.56835L5.30219 4.56835C5.07782 4.56835 4.89593 4.75024 4.89593 4.9746C4.89593 5.19897 5.07782 5.38085 5.30219 5.38085L7.55096 5.38085L4.22281 8.70901C4.06416 8.86766 4.06416 9.12488 4.22281 9.28353C4.38146 9.44218 4.63868 9.44218 4.79733 9.28353Z" fill="var(--button-border-button-primary-default)"/>
        </svg>
    </div>
    <a href="https://faucet.chainstack.com/sepolia-testnet-faucet" target="_blank" class="docs__link">
      <span class="docs__link-title">Chainstack</span>
      <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.3755 0.505859C12.273 0.505859 13.0005 1.2334 13.0005 2.13086V11.8809C13.0005 12.7783 12.273 13.5059 11.3755 13.5059H1.62549C0.728026 13.5059 0.000488281 12.7783 0.000488281 11.8809V2.13086C0.000488281 1.2334 0.728026 0.505859 1.62549 0.505859H11.3755ZM4.79733 9.28353L8.12549 5.95538L8.12549 8.20419C8.12549 8.42856 8.30737 8.61044 8.53174 8.61044C8.7561 8.61044 8.93799 8.42856 8.93799 8.20419V4.9746C8.93799 4.75024 8.7561 4.56835 8.53174 4.56835L5.30219 4.56835C5.07782 4.56835 4.89593 4.75024 4.89593 4.9746C4.89593 5.19897 5.07782 5.38085 5.30219 5.38085L7.55096 5.38085L4.22281 8.70901C4.06416 8.86766 4.06416 9.12488 4.22281 9.28353C4.38146 9.44218 4.63868 9.44218 4.79733 9.28353Z" fill="var(--button-border-button-primary-default)"/>
      </svg>
    </a>
    <a href="https://faucet.quicknode.com/ethereum/sepolia" target="_blank" class="docs__link">
      <span class="docs__link-title">Quicknode</span>
      <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.3755 0.505859C12.273 0.505859 13.0005 1.2334 13.0005 2.13086V11.8809C13.0005 12.7783 12.273 13.5059 11.3755 13.5059H1.62549C0.728026 13.5059 0.000488281 12.7783 0.000488281 11.8809V2.13086C0.000488281 1.2334 0.728026 0.505859 1.62549 0.505859H11.3755ZM4.79733 9.28353L8.12549 5.95538L8.12549 8.20419C8.12549 8.42856 8.30737 8.61044 8.53174 8.61044C8.7561 8.61044 8.93799 8.42856 8.93799 8.20419V4.9746C8.93799 4.75024 8.7561 4.56835 8.53174 4.56835L5.30219 4.56835C5.07782 4.56835 4.89593 4.75024 4.89593 4.9746C4.89593 5.19897 5.07782 5.38085 5.30219 5.38085L7.55096 5.38085L4.22281 8.70901C4.06416 8.86766 4.06416 9.12488 4.22281 9.28353C4.38146 9.44218 4.63868 9.44218 4.79733 9.28353Z" fill="var(--button-border-button-primary-default)"/>
      </svg>
    </a>
  </div>
</div>
</template>

<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.docs {
  width: 100%;
  height: 100%;
  padding: 30px;
  border-radius: 30px;
  background: var(--bg-background-secondary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__links {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--border-border-input-primary-default);
    border-radius: 10px;
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--ocp-0-100-primary-primary98);
    border-bottom: 1px solid var(--border-border-input-primary-default);
    padding: 16px 20px;
    cursor: pointer;
    &:first-child {
      border-radius: 10px 10px 0 0;
    }
    &:last-child {
      border-radius: 0 0 10px 10px;
      border: 0;
    }
    &:hover {
      background: var(--bg-background-primary-purple, #744DB2);
      .docs__link-title{
        color: var(--ocp-white);
      }
      svg {
        path {
          fill: #FFFFFF;
        }
      }
    }
    &-title {
      color: var(--text-color-text-secondary);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
    }
  }
  &__title {
    color: var(--text-text-primary, #000);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }
}
</style>
