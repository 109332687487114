<template>
    <div class="exchange">
        <AtButton
            class="all"
            type="primary"
            icon="icon-arrow-left"
            @click="goToAllExchanges"
        >
            Все обмены
        </AtButton>
        <h1 class="title">
            Обмен
        </h1>
        <div
            v-if="hasExchange"
            class="info"
        >
            <div class="info-item">
                <span class="info-item-title">
                    id:
                </span>
                <span class="info-item-value">
                    {{ exchangeId }}
                </span>
            </div>
            <div class="info-item">
                <span class="info-item-title">
                    Пара:
                </span>
                <span class="info-item-value currency">

                    {{ pair }}
                </span>
            </div>
            <div class="info-item">
                <span class="info-item-title">
                    Сумма ухода:
                </span>
                <span class="info-item-value">
                    {{ amountFrom }}
                </span>
            </div>
            <div class="info-item">
                <span class="info-item-title">
                    Сумма прихода:
                </span>
                <span class="info-item-value">
                    {{ amountTo }}
                </span>
            </div>
            <div class="info-item">
                <span class="info-item-title">
                    Курс обмена:
                </span>
                <span class="info-item-value">
                    {{ rate }}
                </span>
            </div>
            <div class="info-item">
                <span class="info-item-title">
                    Error webhook:
                </span>
                <span class="info-item-value">
                    {{ errorWebhook }}
                </span>
            </div>
            <div class="info-item">
                <span class="info-item-title">
                     Success webhook:
                </span>
                <span class="info-item-value">
                    {{ successWebhook }}
                </span>
            </div>
            <div class="info-item">
                <span class="info-item-title">
                     Дата создания обмена:
                </span>
                <span class="info-item-value">
                    {{ createdAt }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

import AtButton from 'at-ui/src/components/button';
import CurrencyImg from "/public/components/elements/CurrencyImg";
import Moment from "moment";

export default {
    name: 'ExchangePage',
    components: {
        AtButton,
        CurrencyImg,
    },
    data () {
        return {
            exchange: null,
        };
    },
    computed: {
        ...mapGetters('app', [
            'getCurrencyNameById',
        ]),
        exchangeId () {
            return this.$route.params.id;
        },
        hasExchange () {
            return !!this.exchange;
        },
        pair () {
            const {
                exchange,
                getCurrencyNameById,
            } = this;

            return `${getCurrencyNameById(exchange?.currencyFrom)} / ${getCurrencyNameById(exchange?.currencyTo)}`;
        },
        amountFrom () {
            return this.exchange?.amountFrom;
        },
        amountTo () {
            return this.exchange?.amountTo;
        },
        rate () {
            return this.exchange?.exchangeRate;
        },
        errorWebhook () {
            return this.exchange?.meta?.errorWebhook || '-';
        },
        successWebhook () {
            return this.exchange?.meta?.successWebhook || '-';
        },
        createdAt () {
            return Moment(Number(this.exchange?.createdAt)).format('DD.MM.YYYY, HH:mm');
        },
    },
    mounted() {
        this.setRequest();
    },
    methods: {
        ...mapActions('exchanges', [
            'fetchExchange',
        ]),
        setRequest () {
            const {
                exchangeId,
                fetchExchange,
            } = this;

            fetchExchange({
                id: exchangeId,
            })
                .then(exchange => {
                    this.exchange = exchange;
                });
        },
        goToAllExchanges () {
            this.$router.push({ name: 'exchanges' });
        },
    },
};

</script>

<style lang="scss" scoped>

    .exchange {

        .all {
            margin-bottom: 16px;
        }

        .title {
            margin-bottom: 24px;
            @include h1;
        }

        .info {
            @include text;
        }

        .info-item {
            margin-bottom: 12px;
            display: flex;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .info-item-title {
            margin-right: 8px;
        }

        .info-item-value {
            font-weight: 700;

            &.currency {
                display: flex;
                align-items: center;
            }
        }

        .currency-img {
            margin-right: 8px;
            width: 24px;
            height: 24px;
        }
    }

</style>
