import {getRouteQuery} from "/public/utils/getRouteQuery";
import {getCurrenciesIdsByNames} from "/public/utils/getCurrenciesIdsByNames";
import {getFormatForUrlByArray} from "/public/utils/getFormatForUrlByArray";
import {getNetworksIdsByNames} from "/public/utils/getNetworksIdsByNames";
import getInvoices from "../../../../graphQl/queries/getInvoices";

export const fetchInvoices = async ({state, rootState, commit, dispatch, rootGetters}, payload) => {
    const {
        route,
        accountId,
        router,
        showUrlFilters = false,
        excludeParams = [],
        page,
        sortDate,
        currencies,
        id,
        currencyId,
        receivedCurrencyId,
        receivedNetworkId,
        status,
        date,
        to,
        from,
        networks,
    } = payload;
    const organizationId = rootGetters['app/getSelectedOrganization'].id;


    const {invoicesCountOnPage} = state;

    const currentPage = page ? page : getRouteQuery({
        route,
        queryName: 'page',
        defaultValue: 1,
        excludeQueries: excludeParams,
        preparedReturnValueFunc: (value) => Number(value),
    });
    const currentStatus = status
    const currentCurrencies = currencies
    const allCurrencies = rootState.app.currencies;

    const currentOffset = (currentPage - 1) * invoicesCountOnPage;

    const variables = {
        organizationId,
        limit: invoicesCountOnPage,
        offset: currentOffset,
        status: currentStatus,
        to,
        from,
        id,
        currencyId,
        receivedCurrencyId,
        receivedNetworkId,
        accountId,
        sortDate,
        currencies: getCurrenciesIdsByNames(currentCurrencies, allCurrencies),
    };

    let invoicesData;

    try {
        commit('app/toggleLoading', null, {root: true});
        invoicesData = await dispatch('app/request', {
            type: 'query',
            gql: getInvoices,
            variables,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (invoicesData.message) {
        throw invoicesData.message;
    }

    if (invoicesData.errors) {
        throw invoicesData.errors;
    }

    const {
        data: {
            invoices,
            invoicesCount,
            invoiceAdditionalFees
        },
    } = invoicesData;
    commit('totalInvoicesCount', invoicesCount);
    commit('invoices', invoices);
    commit('setAdditionalFees', invoiceAdditionalFees)
    // commit('totalInvoicesCount', totalCount);

    return true;
};
