import saveRegistry from "../../../../graphQl/mutations/saveRegistry";

export const saveRegistryMutation = async ({dispatch, commit}, payload) => {
    let uploadRegistryData;

    try {

        uploadRegistryData = await dispatch('app/request', {
            type: 'mutation',
            gql: saveRegistry,
            variables: payload,
        }, {root: true});
    } catch (error) {
        return error;
    }
    const {saveRegistry: uploadRegistryResponse} = uploadRegistryData.data;

    if (uploadRegistryData.message) {
        throw uploadRegistryData.message;
    }

    if (uploadRegistryData.errors) {
        throw uploadRegistryData.errors;
    }
    

    return uploadRegistryResponse;
};