export const bn = {
    // 'invoice': {
    //     'invoice': 'চালান:',
    //     'sum-invoice': 'Сума по сметката:',
    //     'select-invoice': 'Изберете валута на плащане',
    //     'coin-network': 'Валута и мрежа:',
    //     'rate': 'Добре:',
    //     'sum-payment': 'Сума за плащане:',
    //     'button': 'Плащане',
    //     'search': 'Търсене',
    // },
    // 'checkout': {
    //     'shop': 'Резултат',
    //     'payment': 'Плащане',
    //     'back_to_the_store': 'Обратно към магазина',
    //     'to_make_a_payment': 'Изпратете «{orderCurrency}» в мрежата «{orderAlias}» , за да извършите плащане до посочения адрес',
    //     'payment_completed': 'Плащането е извършено',
    //     'the_payment_is_invalid': 'Плащането не е валидно',
    //     'payment_rejected': 'Плащането е отхвърлено',
    //     'payment_pending': 'Плащането е в процес на обработка',
    //     'payment_init': 'Изчакващо плащане',
    //     'lifetime_has_expired': 'Срокът на действие на плащането е изтекъл. Не изпращайте монети на посочения адрес.',
    //     'contact_the_store_owner': 'Срокът на действие на плащането е изтекъл. Не изпращайте монети на посочения адрес. Свържете се със собственика на магазина, за да върнете монети или да продължите плащането',
    //     'order': 'Поръчка',
    //     'to_be_paid': 'Да платя',
    //     'received': 'получено:',
    //     'description': 'Описание:',
    //     'before_the_expiration_of_the_order': 'Срок на валидност на плащането:',
    //     'network': 'Нетно:',
    //     'payment_address': 'Адрес за плащане:',
    //     'payment_address_not_found': 'Адресът за плащане не е намерен',
    //     'tag': 'Етикет:',
    //     'contract_address': 'Адрес на договора:',
    //     'send_to_this_address_only': 'Изпращайте само на този адрес',
    //     'make_sure_the_network_is_correct': 'Проверете дали мрежата е правилна:',
    //     'network_is_correct': 'Проверете дали мрежата е правилна:',
    //     'contract_address_matches': 'Уверете се, че адресът на договора съвпада',
    //     'payment_page': 'Страница за плащане',
    //     'the_address_was_copied': 'Адресът е копиран успешно',
    //     'the_tag_was_copied': 'Етикетът е копиран',
    //     'invoice_was_successfully_paid': 'Поръчката е платена успешно',
    //     'the_invoice_was_paid': 'Поръчката е платена',
    //     'important_info': 'Важна информация',
    //     'the_contract_address_does_not_exist_on_this_network': 'Адресът на договора не съществува в тази мрежа',
    //     'error': 'Грешка',
    //     'time_hours': 'часа',
    //     'time_minute': 'минути',
    //     'time_second': 'секунди',
    //     'sellers_address': 'Адресът на продавача е потвърден',
    //     'stored_table': 'Тук ще бъде историята на входящите транзакции',
    //     'history_table': 'История на входящите транзакции',
    //     'full_info': 'Покажи пълните подробности',
    //     'table_date': 'датата',
    //     'table_sum': 'Сума',
    //     'table_value': 'Валута',
    //     'table_hash': 'tx_hash',
    //     'table_date_sum': 'Сума и дата',
    //     'payment_partially_paid': 'Частково сплачено',
    //     'paid_timer': 'Платено',
    //     'expired_timer': 'Плащането е изтекло',
    // },
    'new_checkout': {
        'details': {
            'title': 'বিস্তারিত / বিবরন',
            'order': 'আদেশ',
            'date': 'তারিখ',
            'description': 'বর্ণনা',
        },
        'notification': 'এই ঠিকানায় শুধুমাত্র <b>{orderNetwork}</b> নেটওয়ার্কে <b>{orderCurrency}</b> টোকেন পাঠান।',
        'payment_address': 'পেমেন্ট ঠিকানা',
        'verified_notification': "বিক্রেতার ঠিকানা যাচাই করা হয়েছে",
        'paid_amount': "জমা / দেওয়ার পরিমান",
        'timer_notifications': {
            'remaining_time': 'টাকা দিতে বাকি সময়:',
            'completed': 'অর্থ প্রদান সফল ভাবে সম্পন্ন হয়েছে',
            'expired_with_invoice': 'অর্ডারের জীবনকাল শেষ হয়ে গেছে, কিন্তু আপনি ফিরে গিয়ে একটি নতুন অর্ডার তৈরি করতে পারেন।',
            'expired_without_invoice': 'পেমেন্ট লাইফটাইম মেয়াদ শেষ হয়েছে',
            'dont_make_transactions': "এই অর্ডারে লেনদেন করবেন না!"
        },
        'statuses': {
            'not_paid': 'কোন অর্থ প্রদান করা হয় নি',
            'overpaid': 'অতিরিক্ত অর্থ প্রদান করা',
            'partially_paid': 'আংশিক অর্থ প্রদান',
            'fully_paid': 'সম্পূর্ণ পরিশোধ',
        },
        'history': {
            'title': 'অর্থ প্রদান ইতিহাস ',
            'columns': {
                'date': 'তারিখ',
                'amount': 'অর্থের পরিমান',
                'tx_hash': 'Tx_hash'
            },
            'empty_notification': 'নির্দিষ্ট ঠিকানায় পাঠানো আপনার সমস্ত লেনদেন এই উইন্ডো তে পাওয়া যাবে.',
            'ok': 'ঠিক আছে'
        },
        'cancel': 'বাতিল',
        'back_to_shop': 'দোকানে ফিরে যান',
        'back_to_invoice': 'চালানে ফিরে যান',
        'choose_another_currency': 'অন্য মুদ্রা চয়ন করুন',
        'you_can_pay': 'আপনি একাধিক মুদ্রায় চালান পরিশোধ করতে সক্ষম হবেন!'
    },
    'new_invoice': {
        'details': {
            title: 'বিস্তারিত / বিবরন',
            invoice: 'চালান',
            date: 'তারিখ',
            description: 'বর্ণনা'
        },
        'amount': 'অর্থের পরিমান',
        'btn_label': 'চালিয়ে যান',
        'btn_label_empty': 'মুদ্রা নির্ধারন',
        'cancel': 'বাতিল',
        'search': 'খোঁজা...',
        'back_to_shop': 'দোকানে ফিরে যান',
        'choose-network': 'নেটওয়ার্ক নির্বাচন করুন',
        'status': 'স্ট্যাটাস',
        'you-payed': 'আপনি অর্থ প্রদান করেছেন',
        'statuses': {
            "ERROR": 'ত্রুটি',
            "PENDING": 'মুলতুবি',
            "PROCESSED": 'প্রক্রিয়া করা',
            "REJECTED": 'প্রত্যাখ্যাত',
            'OVERPAID': 'অতিরিক্ত অর্থ প্রদান করা',
            "CREATED": 'তৈরি',
            'PARTIAL': 'চ্যাস্টিচনো অপারেটিং',
            'INIT': 'INIT',
            'EXPIRED': 'মেয়াদ শেষ'
        },
        'active_order': 'সক্রিয় আদেশ',
        'payment-successful': 'পেমেন্ট সফল'
    },
    'languages': {
        'select': 'ভাষা নির্ধারন'
    },
    'preCheckout': {
        'errors': {
            'page_not_found': 'পৃষ্ঠা খুঁজে পাওয়া যায়নি',
            'page_not_found_text': 'আপনি যে পৃষ্ঠাটি খুঁজছেন তা আমরা খুঁজে পাচ্ছি না',
        }
    }
};
