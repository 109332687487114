import gql from 'graphql-tag';

export default gql`
     mutation($organizationId: String!, $merchantId: String!) {
         uploadMerchantLink(organizationId: $organizationId, merchantId: $merchantId){
            code
            result
         }
    }
`;
