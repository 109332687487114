<template>
    <div>
        <div class="dashboard-transaction-header__label">
            {{ label }}
        </div>
        <div class="list-header row">
            <div class="list-header__item col col-5">
                id
            </div>
            <div class="list-header__item col">
                {{ $t('withdrawal.amount') }}
            </div>
            <div class="list-header__item col">
                {{ $t('withdrawal.currency') }}
            </div>
            <div class="list-header__item col col-4">
                {{ $t('transactions.date_of_creation') }}
            </div>
            <div class="list-header__item col col-5" v-show="deposit">
                {{ $t('transactions.order_id') }}
            </div>
            <div class="list-header__item col col-4" v-show="deposit">
                {{ $t('transactions.date_of_creation_order') }}
            </div>
            <div class="list-header__item col col-1"></div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'DashboardTransactionHeader',
    props: {
        label: String,
        deposit: {
            type: Boolean,
            default: false,
        },
    },
};

</script>

<style lang="scss" scoped>


    .dashboard-transaction-header {
        padding: 0;
        background-color: $white;
        border: none;
        &__label {
            @include h4;
            text-align: right;
            margin-top: .5em;
        }
    }

</style>
