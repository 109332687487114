import gql from 'graphql-tag';

export default gql`
    mutation($organizationId: String!, $advancedBalanceId: String!, $orphanDepositId: String!) {
        orphanWithdrawalToken(organizationId: $organizationId, advancedBalanceId: $advancedBalanceId, orphanDepositId: $orphanDepositId) {
        success
            code
            result {
                amount
                amountTo
                currencyId
                networkId
                feeSource
                serviceFee
                serviceFeeUSD
                blockchainFee
                blockchainFeeUSD
                price
                token
                expiresAt
            }
        }
    }
`;
