<template>
  <div>
    <div class="preview-api-keys">
      <div class="preview-api-keys__col preview-api-keys__col-alias">
        <CopyString
            class="preview-api-keys__alias"
            :string="alias"
            :successMessage="the_name_of_the_key_copied"
            :failMessage="the_name_of_the_key_not_copied"
            :title="notification"
            canCopy
        />
        <div class="preview-api-keys__col-mobile">
          <CopyString :string="secretKey"
                      :successMessage="$t('copy.the_private_key_copied')"
                      :failMessage="$t('copy.the_private_key_not_copied')"
                      :title="notification"
                      :canCopy="false"
          />
        </div>
      </div>
      <div class="preview-api-keys__col">
        <CopyString
            :string="publicKey"
            :successMessage="the_public_key_copied"
            :failMessage="the_public_key_not_copied"
            :title="notification"
            canCopy
        />
      </div>
      <div class="preview-api-keys__col preview-api-keys__col-desktop">
        <CopyString :string="secretKey"
                    :successMessage="$t('copy.the_private_key_copied')"
                    :failMessage="$t('copy.the_private_key_not_copied')"
                    :title="notification"
                    :can-copy="false"
        />
      </div>
      <div class="preview-api-keys__col-button">
        <div class="preview-api-keys__button-container">
<!--          <AtButton-->
<!--              class="preview-api-keys__button"-->
<!--              v-if="showEditBtn"-->
<!--              size="small"-->
<!--              icon="icon-edit"-->
<!--              :title="edit"-->
<!--              -->
<!--              -->
<!--          />-->
          <div class="rights-flag">
              <popper
                  trigger="hover"
                  :options="{
      placement: 'top',
      offset: [0, 10]
    }"
              >
                <div slot="reference">
                  <svg  width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.5 0.5C1.10218 0.5 0.720644 0.658035 0.43934 0.93934C0.158035 1.22064 0 1.60218 0 2V21.5H3V2C3 1.60218 2.84196 1.22064 2.56066 0.93934C2.27936 0.658035 1.89782 0.5 1.5 0.5ZM17.7195 8.792L3.75 14.447V2.303L17.7195 7.95725C17.8025 7.99092 17.8736 8.04858 17.9236 8.12287C17.9736 8.19716 18.0004 8.28468 18.0004 8.37425C18.0004 8.46381 17.9736 8.55134 17.9236 8.62563C17.8736 8.69992 17.8025 8.75833 17.7195 8.792Z" :fill="`var(${flagColor})`"/>
                  </svg>
                </div>

                  <div class="rights-flag__popup">
                    {{ $t(`api_keys.${rights}`) }}
                  </div>
              </popper>
          </div>

          <edit-button @click="editApiKey" :disabled="!isManageAllowed" />
            <delete-button               :disabled="!isManageAllowed"
                                         @click="remove" />
<!--          <AtButton-->
<!--              class="preview-api-keys__button"-->
<!--              size="small"-->
<!--              icon="icon-trash-2"-->
<!--              :title="deleteKey"-->

<!--          />-->
        </div>
      </div>
    </div>
      <div :class="['apiKeys-collapse', isCollapseActive ? 'apiKeys-collapse--active' : '']" @click="toggleCollapse">
          <div class="apiKeys-collapse__header" >
              <CopyString
                      class="preview-api-keys__alias"
                      :string="alias"
                      :successMessage="the_name_of_the_key_copied"
                      :failMessage="the_name_of_the_key_not_copied"
                      :title="notification"
                      canCopy
              />
              <edit-button @click="editApiKey" :disabled="!isManageAllowed" />
              <delete-button               :disabled="!isManageAllowed"
                                           @click="remove" />
              <svg :class="['chevron', isCollapseActive ?  'chevron--active' : '']" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.772 1.635C7.915 1.462 8 1.241 8 1C8 0.448 7.552 -3.91654e-08 7 -8.74228e-08L1 -6.11959e-07C0.448 -6.60217e-07 -3.91654e-08 0.447999 -8.74228e-08 0.999999C-1.08492e-07 1.241 0.0849999 1.462 0.228 1.635L3.192 5.589C3.374 5.838 3.668 6 4 6C4.332 6 4.626 5.838 4.808 5.589L7.772 1.635Z" fill="var(--new-front-primary-font-color)"/>
              </svg>
          </div>
          <div class="apiKeys-collapse__content">
              <div class="apiKeys-collapse__content-item">
                  <p class="text-secondary">{{ $t('api_keys.public_key') }}</p>
                  <CopyString
                          :string="publicKey"
                          :successMessage="the_public_key_copied"
                          :failMessage="the_public_key_not_copied"
                          :title="notification"
                          canCopy
                  />
              </div>
              <div class="apiKeys-collapse__content-item">
                  <p class="text-secondary">{{ $t('api_keys.private_key') }}</p>
                  <CopyString :string="secretKey"
                              :successMessage="$t('copy.the_private_key_copied')"
                              :failMessage="$t('copy.the_private_key_not_copied')"
                              :title="notification"
                              :canCopy="false"
                  />
              </div>
          </div>
<!--          <actions-popup class="actions">-->
<!--              <div :class="['actions__modal-item', !isManageAllowed ? 'disabled' : '']" @click="editApiKey">-->
<!--                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <path d="M11.811 0.695271L11.9406 0.565638C12.3531 0.153159 13.0219 0.153159 13.4344 0.565638C13.8468 0.978117 13.8468 1.64688 13.4344 2.05936L13.3047 2.18899C13.7651 2.70436 13.7479 3.49583 13.2531 3.99058L4.24687 12.9969C4.1908 13.0529 4.12055 13.0927 4.04362 13.1119L0.543619 13.9869C0.39453 14.0242 0.236816 13.9805 0.12815 13.8719C0.0194837 13.7632 -0.0242003 13.6055 0.0130719 13.4564L0.888072 9.95639C0.907304 9.87946 0.947081 9.80921 1.00315 9.75314L9.43925 1.31703C9.26852 1.19664 9.03091 1.21282 8.87815 1.36558L5.99687 4.24686C5.82601 4.41771 5.549 4.41771 5.37815 4.24686C5.2073 4.076 5.2073 3.79899 5.37815 3.62814L8.25943 0.746857C8.75466 0.25163 9.54717 0.23488 10.0625 0.696606C10.5593 0.251489 11.3137 0.251044 11.811 0.695271ZM11.2469 1.36558C11.076 1.19472 10.799 1.19472 10.6281 1.36558L1.70756 10.2862L1.0388 12.9612L3.71384 12.2925L12.6344 3.37186C12.8053 3.201 12.8053 2.92399 12.6344 2.75314L11.2469 1.36558Z" fill="var(&#45;&#45;new-front-secondary-font-color)"/>-->
<!--                  </svg>-->
<!--                  <span>{{ $t('profits.edit') }}</span>-->
<!--              </div>-->
<!--              <div :class="['actions__modal-item', !isManageAllowed ? 'disabled' : '']" @click="remove">-->
<!--                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <path d="M2.1875 0.875C1.70425 0.875 1.3125 1.26675 1.3125 1.75V2.625C1.3125 3.10825 1.70425 3.5 2.1875 3.5H2.625V11.375C2.625 12.3415 3.4085 13.125 4.375 13.125H9.625C10.5915 13.125 11.375 12.3415 11.375 11.375V3.5H11.8125C12.2957 3.5 12.6875 3.10825 12.6875 2.625V1.75C12.6875 1.26675 12.2957 0.875 11.8125 0.875H8.75C8.75 0.391751 8.35825 0 7.875 0H6.125C5.64175 0 5.25 0.391751 5.25 0.875H2.1875ZM4.8125 4.375C5.05412 4.375 5.25 4.57088 5.25 4.8125V10.9375C5.25 11.1791 5.05412 11.375 4.8125 11.375C4.57088 11.375 4.375 11.1791 4.375 10.9375L4.375 4.8125C4.375 4.57088 4.57088 4.375 4.8125 4.375ZM7 4.375C7.24162 4.375 7.4375 4.57088 7.4375 4.8125V10.9375C7.4375 11.1791 7.24162 11.375 7 11.375C6.75838 11.375 6.5625 11.1791 6.5625 10.9375V4.8125C6.5625 4.57088 6.75838 4.375 7 4.375ZM9.625 4.8125V10.9375C9.625 11.1791 9.42912 11.375 9.1875 11.375C8.94588 11.375 8.75 11.1791 8.75 10.9375V4.8125C8.75 4.57088 8.94588 4.375 9.1875 4.375C9.42912 4.375 9.625 4.57088 9.625 4.8125Z" fill="var(&#45;&#45;new-front-secondary-font-color)"/>-->
<!--                  </svg>-->
<!--                  <span>{{ $t('profits.delete') }}</span>-->
<!--              </div>-->
<!--          </actions-popup>-->
      </div>
  </div>
</template>

<script>

    import {mapActions, mapGetters} from 'vuex';

import CopyString from "/public/components/elements/CopyString";
import AtButtonGroup from 'at-ui/src/components/button-group';
import AtButton from 'at-ui/src/components/button';
    import EditButton from "@/public/components/common/uiKit/edit-button/index.vue";
    import DeleteButton from "@/public/components/common/uiKit/delete-button/index.vue";
    import ActionsPopup from "@/public/components/common/uiKit/actionsPopup/ActionsPopup.vue";
    import Popper from 'vue-popperjs';
    import 'vue-popperjs/dist/vue-popper.css';

export default {
    name: 'PreviewApiKeys',
    components: {
        ActionsPopup,
        DeleteButton,
        EditButton,
        CopyString,
        AtButtonGroup,
        AtButton,
      'popper': Popper,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            mode: 'view',
            canSave: false,
            isCollapseActive: false,
            flagColor: '',
            rights: '',
        };
    },
    beforeMount() {
      this.checkFlagColor()
    },
  computed: {
        ...mapGetters('organizations', ['userRoleRules']),
        isManageAllowed () {
            return this.userRoleRules['api_keys'].entities.manage_api_keys;
        },
        isEditMode() {
            return this.mode === 'edit';
        },
        showEditBtn() {
            const {
                isNew,
                isEditMode,
            } = this;

            if (isNew) {
                return false;
            } else {
                return !isEditMode;
            }
        },
        saveIcon() {
            return this.isLoading ? '' : 'icon-save';
        },
        id() {
            return this.data.id;
        },
        alias() {
            return this.data.alias;
        },
        publicKey() {
            return this.data.public;
        },
        ipv4 () {
          return this.data.ipv4
        },
        ipv6 () {
          return this.data.ipv6
        },
        rules () {
          return this.data.rules
        },
      readOnly () {
        return this.data.readOnly
      },
        secretKey() {
            return this.data.secret;
        },
        name_of_the_api_key() {
            return this.$t('api_keys.name_of_the_api_key');
        },
        enter_a_new_api_key_name() {
            return this.$t('api_keys.enter_a_new_api_key_name');
        },
        cancel() {
            return this.$t('api_keys.cancel');
        },
        the_name_of_the_key_copied() {
            return this.$t('copy.the_name_of_the_key_copied');
        },
        the_name_of_the_key_not_copied() {
            return this.$t('copy.the_name_of_the_key_not_copied');
        },
        the_public_key_copied() {
            return this.$t('copy.the_public_key_copied');
        },
        the_public_key_not_copied() {
            return this.$t('copy.the_public_key_not_copied');
        },
        notification() {
            return this.$t('copy.notification');
        },
        saveKey() {
            return this.$t('api_keys.create');
        },
        edit() {
            return this.$t('copy.edit');
        },
        deleteKey() {
            return this.$t('copy.deleteKey');
        },
    },
    methods: {
        ...mapActions('app', [
            'openModal',
        ]),
        ...mapActions('user', [
            'updateApiKeys',
            'fetchApiKeys',
        ]),
        toggleCollapse () {
            this.isCollapseActive = !this.isCollapseActive
        },
      checkFlagColor () {
          if (this.flagColor) return
        let viewAccessCount = 0
        let allTrueCount = 0
        let allRightsCount = 0
        let allViewCount = 0

        const rules = JSON.parse(this.rules)

        for (let key in rules) {
          for (const entity in rules[key]) {
            allRightsCount += 1
            if (entity === 'VIEW') {
              allViewCount+=1
              if (rules[key][entity]) {
                viewAccessCount += 1
              }
            }
            if (rules[key][entity]) {
              allTrueCount += 1
            }
          }
        }
        this.flagColor = (viewAccessCount === allTrueCount) && (viewAccessCount === allViewCount)
            ? '--new-front-bg-success'
            : allRightsCount === allTrueCount
                ?  '--new-front-bg-danger'
                : '--new-front-bg-warning'
        this.rights = (viewAccessCount === allTrueCount) && (viewAccessCount === allViewCount)
            ? 'none'
            : allRightsCount === allTrueCount
                ?  'full'
                : 'partial'
        // rights[]
      },
        editApiKey() {
          const {
            id,
            alias,
              ipv4,
              ipv6,
              rules,
              readOnly,
            publicKey
          } = this;
          this.$emit('editApiKey', {
            id,
            alias,
            ipv4,
            ipv6,
            rules,
            readOnly,
            public: publicKey
          })
            // this.$Modal.prompt({
            //     title: this.name_of_the_api_key,
            //     content: this.enter_a_new_api_key_name,
            //     cancelText: this.cancel,
            //     okText: this.saveKey,
            // })
            //     .then(event => {
            //         const {value} = event;
            //
            //         if (!value) {
            //             return false;
            //         }
            //
            //         this.save(value);
            //     })
            //     .catch(() => {
            //         return false;
            //     });
        },
        save(newAlias) {
            const {
                $Notify,
                id,
                updateApiKeys,
                fetchApiKeys,
            } = this;

            updateApiKeys({
                id,
                alias: newAlias,
            })
                .then(status => {
                    if (status) {
                        $Notify({
                            title: this.$t('copy.notification'),
                            message: this.$t('copy.api_key_successfully_updated'),
                            type: 'success',
                        });
                        fetchApiKeys();
                    } else {
                        $Notify({
                            title: this.$t('copy.notification'),
                            message: this.$t('copy.failed_to_update_api_key'),
                            type: 'error',
                        });
                    }
                });
        },
        remove() {
            const {
                id,
                alias,
            } = this;
            this.$emit('deleteKey', {id, alias})
        },
    },
};

</script>


<style lang="scss" scoped>
.rights-flag {
  position:relative;
  &__popup {
    position: absolute;
    top: -5px !important;
    color: var(--new-front-primary-font-color);
    background: var(--new-front-table-bg-secondary-bg-secondary);
    border: 1px solid var(--new-front-border-color);
    border-radius: 10px;
    padding: 10px;
    min-width: 140px;
    text-align: center;
  }
}
::v-deep .copy-string__string {
  font-size: $h4;
  color: var(--new-front-primary-font-color);
  font-family: $Gilroy;
  font-weight: 500;
}
.right-align {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.preview-api-keys {
  display: grid;
  grid-template-columns: repeat(3, 1fr) 150px;
  gap: 10px;
  padding-left: 30px;
  min-height: 88px;
  align-items: center;
  border-bottom: 1px solid var(--new-front-divider-color);


  &__button {
    width: 60px;
    height: 40px;
    background: var(--order-page-icon);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    transition: all 0.3s ease-in;

    ::v-deep .at-btn__icon {
      font-size: 20px;
      line-height: 1;
      color: var(--primary-font-color);
    }

    &:hover {
      border: 1px solid var(--blue-400);

      ::v-deep .at-btn__icon {
        color: var(--primary-font-color);
      }
    }
  }

  &__button-container {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-right: 40px;

  }

  &__col {
    display: flex;

    &-desktop {
      display: block;
    }

    &-mobile {
      display: none;
    }

    &-button {
      flex-direction: row;
      display: flex;
    }
  }

  &__input {
    width: 70%;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__copy-alias,
  &__copy-public-key {
    margin-left: 4px;
  }

  &__alias {
    word-break: break-all;
  }
}
.actions {
  display: none;
}
@media screen and (max-width: 950px) {
  ::v-deep .copy-string__string {
    font-size: $h6 !important;
    word-break: break-word;
  }
  .preview-api-keys {
    grid-template-columns: repeat(3, 1fr);
    padding-left: 10px;
    display: none;
    &__button {

      width: 100%;
      height: auto;

      ::v-deep .at-btn__icon {
        font-size: 18px;
        line-height: 1;
      }
    }

    &__button-container {
      padding-right: 40px;
    }

    &__col {
      display: flex;

      &-button {
        justify-self: flex-end;
      }

      &-alias {
        flex-direction: column;
      }

      &-desktop {
        display: none
      }

      &-mobile {
        display: block;

        &::v-deep .copy-string__string {
          font-size: $h4;
          color: var(--new-front-primary-font-color);
          font-weight: $Gilroy;
        }


      }
    }

  }
  .actions {
    display: inherit;
    align-items: center;
  }
  .actions__modal-item {
    display: flex;
    align-items: center;
    gap: 10px;
    min-width: 100px;
    width: 100%;
    border-bottom: 1px solid var(--new-front-input-border);
    color: var(--new-front-secondary-font-color);
    font-weight: 500;
    padding: $p10;
    transition: all .2s ease;
    cursor: pointer;
    &:hover {
      color:var(--new-front-primary-font-color) ;
      background: var(--new-font-nav-hover-bg);
    }
    &:first-of-type {
      &:hover {
        &::v-deep svg {
          path {
            stroke: #744DB2 !important;
          }
        }
      }
    }
    &:nth-child(2){
      border-bottom: unset;
      &:hover {
        &::v-deep svg {
          path {
            fill: #744DB2 !important;
          }
        }
      }
    }
  }
}
.apiKeys-collapse {
  gap: 20px;
  border-bottom: 1px solid var(--new-front-divider-color);
  max-height: 73px;
  overflow: hidden;
  transition: max-height .3s ease;
  &--active {
    max-height: 163px;
  }
  @media screen and (min-width: 950px){
    display: none;
  }
  &__header {
    display: grid;
    grid-template-columns: 1fr 40px 40px 10px;
    align-items: center;
    gap: 20px;
    min-height: 73px;
    padding: 10px 20px;
  }
  &__content {
    padding: 10px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__item {
    padding: 10px 15px;
  }
}
::v-deep .at-collapse {
  background: var(--secondary-color);
  /* Gray 6 */

  border: 1px solid var(--border-color);
  box-shadow: 0 8px 50px rgba(10, 17, 105, 0.08);
  //border-radius: 20px;
}

::v-deep .at-collapse__icon {
  right: 15px;
  top: 25px;
  font-size: 20px;
  left: unset;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  width: fit-content;
  height: fit-content;
}
::v-deep .at-collapse__item {
  @media (max-width: 950px) {
    position: relative;
  }
}
::v-deep .at-collapse__item--active > .at-collapse__header .at-collapse__icon {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

::v-deep .at-collapse__header {
  background: var(--secondary-color);
  padding: 20px 30px;
  @media (max-width: 950px) {
    padding: 15px 10px;
    position: unset;
  }
}
::v-deep .at-collapse__content  {
  padding: 0;
  border-top: 1px solid var(--border-color);
  background: var(--secondary-color);
}
::v-deep {
  .history {
    width: 40px;
    height: 40px;
  }
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
.text-secondary {
  color: var(--new-front-secondary-font-color);
  font-family: $Gilroy;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}
.chevron {
  transition: transform .3s ease;
  &--active {
    transform: rotate(180deg);
  }
}
</style>
