import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!, $organizationRoleId: String!) {
        getOrganizationRole(organizationId: $organizationId, organizationRoleId: $organizationRoleId) {
            name
            comment
            rules
        }
    }
`;

