<template>
  <div class="dashboard-filter-by-date">
    <label class="dashboard-filter-by-date__title">
      {{ $t('dashboard.period') }}
    </label>
    <DatePicker
        class="dashboard-filter-by-date__date"
        type="date"
        range
        value-type="date"
        v-model="picker"
        range-separator=" - "
        format="DD.MM.YYYY"
        :clearable="false"
        :lang="selectLang"
        :disabled-date="disabledRange"
    />
  </div>
</template>

<script>

import Moment from "moment";
import {mapActions, mapState} from 'vuex';

import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/locale/ru';

export default {
    name: 'DashboardFilterByDate',
    components: {
        DatePicker,
    },
    computed: {
        ...mapState('dashboard', [
            'dates',
        ]),
        ...mapState('user', [
            'user',
        ]),
        selectLang() {
            return this.user.lang;
        },
        picker: {
            get() {
                return this.dates;
            },
            set(dates) {
                this.fetchDashboard({
                    dateFrom: Moment(dates[0]).format('YYYY-MM-DD'),
                    dateTo: Moment(dates[1]).format('YYYY-MM-DD'),
                });
            },
        },
    },
    methods: {
        ...mapActions('dashboard', [
            'fetchDashboard',
        ]),
        disabledRange(date) {
            return date < new Date(2022, 0, 1) || date > new Date();
        },
    },
};

</script>

<style lang="scss" scoped>

.dashboard-filter-by-date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &__title {
    font-weight: 700;
    margin-right: 1em;
  }

  &__date::v-deep.mx-datepicker-range {
    width: 16em;
  }

  &__date ::v-deep .mx-input {
    height: 28px;
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }
}

</style>