<template>
<div @click="toggleTheme($event)" class="themeSwitch">
  <svg v-if="!themeStatus" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.00045 0.417456C9.27375 0.750653 9.36705 1.25282 9.12105 1.70419C8.28016 3.24711 7.80226 5.01453 7.80226 6.89529C7.80226 12.9259 12.7204 17.809 18.7802 17.809C19.5698 17.809 20.3391 17.7263 21.0801 17.5693C21.5868 17.462 22.0476 17.691 22.2947 18.0429C22.5495 18.406 22.603 18.9477 22.2478 19.3837C19.955 22.1978 16.4472 24 12.5154 24C5.60013 24 0 18.4295 0 11.565C0 6.3987 3.17122 1.96795 7.68531 0.0901317C8.20685 -0.126827 8.71843 0.0736398 9.00045 0.417456Z" fill="#6750A4"/>
    <path d="M16.1915 4.72236C16.2903 4.42588 16.7097 4.42588 16.8085 4.72236L17.3895 6.46515C17.6484 7.242 18.258 7.8516 19.0348 8.11055L20.7777 8.69148C21.0741 8.79031 21.0741 9.20968 20.7777 9.3085L19.0349 9.88944C18.258 10.1484 17.6484 10.758 17.3895 11.5348L16.8085 13.2776C16.7097 13.5741 16.2903 13.5741 16.1915 13.2776L15.6106 11.5348C15.3516 10.758 14.742 10.1484 13.9652 9.88944L12.2224 9.3085C11.9259 9.20968 11.9259 8.79031 12.2224 8.69148L13.9652 8.11054C14.742 7.85159 15.3516 7.24201 15.6106 6.46517L16.1915 4.72236Z" fill="#6750A4"/>
    <path d="M20.7943 0.14824C20.8602 -0.0494144 21.1398 -0.0494128 21.2057 0.148242L21.593 1.3101C21.7656 1.828 22.172 2.2344 22.6899 2.40703L23.8518 2.79432C24.0494 2.86021 24.0494 3.13978 23.8518 3.20567L22.6899 3.59296C22.172 3.76559 21.7656 4.17199 21.593 4.68989L21.2057 5.85176C21.1398 6.04941 20.8602 6.04941 20.7943 5.85176L20.407 4.68988C20.2344 4.17198 19.828 3.76559 19.3101 3.59296L18.1483 3.20567C17.9506 3.13978 17.9506 2.86021 18.1483 2.79432L19.3101 2.40703C19.828 2.2344 20.2344 1.82801 20.4071 1.31011L20.7943 0.14824Z" fill="#6750A4"/>
  </svg>
  <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z" fill="white"/>
    <path d="M12 0C12.4142 0 12.75 0.335786 12.75 0.75V3.75C12.75 4.16421 12.4142 4.5 12 4.5C11.5858 4.5 11.25 4.16421 11.25 3.75V0.75C11.25 0.335786 11.5858 0 12 0Z" fill="white"/>
    <path d="M12 19.5C12.4142 19.5 12.75 19.8358 12.75 20.25V23.25C12.75 23.6642 12.4142 24 12 24C11.5858 24 11.25 23.6642 11.25 23.25V20.25C11.25 19.8358 11.5858 19.5 12 19.5Z" fill="white"/>
    <path d="M24 12C24 12.4142 23.6642 12.75 23.25 12.75H20.25C19.8358 12.75 19.5 12.4142 19.5 12C19.5 11.5858 19.8358 11.25 20.25 11.25H23.25C23.6642 11.25 24 11.5858 24 12Z" fill="white"/>
    <path d="M4.5 12C4.5 12.4142 4.16421 12.75 3.75 12.75H0.75C0.335786 12.75 -1.81059e-08 12.4142 0 12C1.81059e-08 11.5858 0.335786 11.25 0.75 11.25H3.75C4.16421 11.25 4.5 11.5858 4.5 12Z" fill="white"/>
    <path d="M20.4853 3.51477C20.7782 3.80766 20.7782 4.28253 20.4853 4.57543L18.364 6.69675C18.0711 6.98964 17.5962 6.98964 17.3033 6.69675C17.0104 6.40385 17.0104 5.92898 17.3033 5.63609L19.4246 3.51477C19.7175 3.22187 20.1924 3.22187 20.4853 3.51477Z" fill="white"/>
    <path d="M6.6967 17.3033C6.98959 17.5962 6.98959 18.0711 6.6967 18.364L4.57538 20.4853C4.28248 20.7782 3.80761 20.7782 3.51472 20.4853C3.22182 20.1924 3.22182 19.7176 3.51472 19.4247L5.63604 17.3033C5.92893 17.0105 6.4038 17.0105 6.6967 17.3033Z" fill="white"/>
    <path d="M20.4853 20.4853C20.1924 20.7782 19.7175 20.7782 19.4246 20.4853L17.3033 18.364C17.0104 18.0711 17.0104 17.5963 17.3033 17.3034C17.5962 17.0105 18.0711 17.0105 18.364 17.3034L20.4853 19.4247C20.7782 19.7176 20.7782 20.1925 20.4853 20.4853Z" fill="white"/>
    <path d="M6.6967 6.69676C6.40381 6.98966 5.92893 6.98966 5.63604 6.69676L3.51472 4.57544C3.22182 4.28255 3.22183 3.80768 3.51472 3.51478C3.80761 3.22189 4.28249 3.22189 4.57538 3.51478L6.6967 5.6361C6.98959 5.929 6.98959 6.40387 6.6967 6.69676Z" fill="white"/>
  </svg>
</div>
</template>

<script>
  import {mapMutations} from "vuex";

  export default {
    name: "NewThemeSwitch",
    props: {
      lightMode: {
        type: String,
        default: 'light',
      },
      darkMode: {
        type: String,
        default: 'dark',
      },
    },
    data() {
      return {
        themeStatus: false,
      };
    },
    created() {
      const theme = localStorage.getItem('data-theme-private');
      const themeOsDark = window.matchMedia("(prefers-color-scheme: dark)");

      this.setTheme(theme === 'light' ? 'light' : 'dark') //пишем тему в стор
      if (theme === 'dark') {
        this.themeStatus = true;
        document.documentElement.setAttribute("data-theme-private", this.darkMode);
      } else if (theme === 'light') {
        this.themeStatus = false;
        document.documentElement.setAttribute("data-theme-private",  this.lightMode);
      } else if (themeOsDark.matches) {
        this.themeStatus = true;
        document.documentElement.setAttribute("data-theme-private", this.darkMode);
      } else {
        this.themeStatus = false;
        document.documentElement.setAttribute("data-theme-private",  this.lightMode);
      }
    },

    methods: {
      ...mapMutations('app', ['setTheme']),
      toggleTheme(event) {
        const themeOsDark = window.matchMedia("(prefers-color-scheme: dark)");
        let theme = localStorage.getItem('data-theme-private');


        if (event.target.nodeName !== 'LABEL') {
          if (theme === 'dark') {
            this.themeStatus = false;
            document.documentElement.setAttribute("data-theme-private", this.lightMode);
            localStorage.setItem("data-theme-private", this.lightMode);
          } else if (theme === 'light') {
            this.themeStatus = true;
            document.documentElement.setAttribute("data-theme-private", this.darkMode);
            localStorage.setItem("data-theme-private", this.darkMode);
          } else if (themeOsDark.matches) {
            this.themeStatus = false;
            document.documentElement.setAttribute("data-theme-private", this.lightMode);
            localStorage.setItem("data-theme-private", this.lightMode);
          } else {
            this.themeStatus = true;
            document.documentElement.setAttribute("data-theme-private", this.darkMode);
            localStorage.setItem("data-theme-private", this.darkMode);
          }
        }
        this.setTheme(localStorage.getItem("data-theme-private")) //пишем тему в стор
      },
    },
  }
</script>

<style lang="scss" scoped>
.themeSwitch {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 10px;
  border: 1px var(--theme-switcher-border) solid;
  background: var(--theme-switcher-bg);
  z-index: 4;
  &:hover {
    background: var(--new-front-adv-hover-bg);
  }
  &:active:hover {
    background: var(--new-front-adv-click-bg);
  }
}
</style>
