import getOrganizationSettingsQuery from "/public/graphQl/queries/getOrganizationSettings";

export const getOrganizationSettings = async ({commit, dispatch, rootGetters}) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    let settings;

    let variables = {
        organizationId,
        requestType: 'get'
    }

    try {
        settings = await dispatch('app/request', {
            type: 'query',
            gql: getOrganizationSettingsQuery,
            variables: {
                organizationId
            },
        }, { root: true });
    } catch (error) {
        return error;
    }
    const { data = {} } = settings
    const result = data.getOrganizationSettings || {}

    // commit('setSettings', result || []);

    return result || [];
};
