import { getSwapTransferToken } from "./actions/swapTransferToken"
import { setSwapTransfer } from "./actions/swapTransfer"
import { downloadCSV } from './actions/downloadCSV'
import { downloadXLSX } from './actions/downloadXLSX'
import { getSwapTransfersList } from './actions/swapTransfersList'

export const actions = {
    getSwapTransferToken,
    setSwapTransfer,
    getSwapTransfersList,
    downloadCSV,
    downloadXLSX
};