<template>
  <div class="account-filter-by-date">
    <p class="account-filter-by-date__title">
      Период
    </p>
    <DatePicker
        class="account-filter-by-date__date"
        type="date"
        :show-second="false"
        range
        confirm
        confirm-text="Выбрать даты"
        value-type="date"
        v-model="dates"
        @confirm="selectDates"
        @clear="clearDates"
    />
  </div>
</template>

<script>

import Moment from "moment";
import {mapActions} from 'vuex';

import DatePicker from "vue2-datepicker";

export default {
    name: 'AccountDataFilterByDate',
    components: {
        DatePicker,
    },
    data() {
        return {
            dates: [],
        };
    },
    beforeMount() {
        this.setDateFromUrl();
    },
    methods: {
        ...mapActions('orders', [
            'fetchOrders',
        ]),
        setDateFromUrl() {
            const dateFromUrl = this.$route.query.date;

            if (!dateFromUrl) {
                return false;
            }

            this.dates = dateFromUrl.split('to').map(date => new Date(date));
        },
        selectDates() {
            const {
                $route,
                $router,
                dates,
                fetchOrders,
            } = this;

            const dateFrom = Moment(dates[0]).format();
            const dateTo = Moment(dates[1])
                .add(23, 'hours')
                .add(59, 'minutes')
                .add(59, 'seconds')
                .format()
      ;

            this.fetchOrders({
                route: $route,
                router: $router,
                date: [dateFrom, dateTo],
                excludeParams: ['page'],
            });
        },
        clearDates() {
            const {
                $route,
                $router,
                fetchOrders,
            } = this;

            fetchOrders({
                route: $route,
                router: $router,
                date: [],
                excludeParams: ['page'],
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.account-filter-by-date {
  &__title {
    margin-bottom: 8px;
    font-weight: 700;
  }

  &__date::v-deep.mx-datepicker-range {
    width: 100%;
  }

  &__date ::v-deep .mx-input {
    height: 28px;
    font-family: var(--new-front-font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }
}

</style>
