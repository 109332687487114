import GetPersonalAddressesUserAddressesQuery from "../../../../graphQl/queries/personalAddressGetPersonAddresses";

export const getAddressesForPerson = async ({dispatch, commit, rootGetters}, payload) => {
  let resp;

  payload.organizationId = rootGetters['app/getSelectedOrganization'].id;

  try {
    resp = await dispatch('app/request', {
      type: 'query',
      gql: GetPersonalAddressesUserAddressesQuery ,
      variables: payload,
    }, {root: true});
  } catch (error) {
    return error;
  }

  const { data } = resp || {}
  const { getPersonalAddresses } = data || {}

  return getPersonalAddresses || []
};