import uploadRegistry from "../../../../graphQl/mutations/uploadRegistry";


export const uploadRegistryMutation = async ({dispatch, commit}, payload) => {
    let uploadRegistryData;

    try {

        uploadRegistryData = await dispatch('app/request', {
            type: 'mutation',
            gql: uploadRegistry,
            variables: payload,
        }, {root: true});
    } catch (error) {
        return error;
    }
    const {uploadRegistry: uploadRegistryResponse} = uploadRegistryData.data;
    commit('setRegistryFile', uploadRegistryResponse);

    if (uploadRegistryData.message) {
        throw uploadRegistryData.message;
    }

    if (uploadRegistryData.errors) {
        throw uploadRegistryData.errors;
    }

    const {
        data: {createWithdrawalRequest},
    } = uploadRegistryData;

    return createWithdrawalRequest;
};