import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!, $accountId: String!, $days: Int!) {
        getBalanceFee(organizationId: $organizationId, accountId: $accountId, days: $days) {
          amount,
          reason_type
        }
    }
`;
