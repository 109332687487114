import gql from 'graphql-tag';

export default gql`
    mutation($registryId: String!,$accountId: String!) {
        fetchRegistryInfo(accountId: $accountId,registryId: $registryId) {
            code
            result {
                currencies {
                    id
                    data {
                        amount
                        advanced_balance
                        wallet
                    }
                }
            }
        }
    }
`;
