<template>
  <RouterLink
    v-if="firstAccountId"
    :to="{ name: 'advanced-balances', params: { accountId: firstAccountId }, query: { page: 1 } }"
  >
    <div :class="['adv', accountBalance <= 0 ? 'adv-disabled' : '']">
      <div class="adv__icon">
        <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.6877 0C2.34534 0 1.25715 1.04467 1.25715 2.33333V18.6667C0.585974 18.6667 0.0418701 19.189 0.0418701 19.8333C0.0418701 20.4777 0.585974 21 1.25715 21H15.8405C16.5117 21 17.0558 20.4777 17.0558 19.8333C17.0558 19.189 16.5117 18.6667 15.8405 18.6667V12.8333H17.0558V15.75C17.0558 17.3608 18.416 18.6667 20.094 18.6667C21.7719 18.6667 23.1322 17.3608 23.1322 15.75V8.6499C23.1322 8.03108 22.8761 7.43758 22.4202 7L19.1304 3.8417C18.6558 3.3861 17.8863 3.3861 17.4117 3.8417C16.9372 4.29732 16.9372 5.03601 17.4117 5.49163L18.7497 6.77616C18.7178 6.81752 18.6872 6.85995 18.6581 6.90342C18.4306 7.24244 18.2986 7.63226 18.2749 8.03486C18.2512 8.43745 18.3365 8.83913 18.5227 9.20057C18.7088 9.562 18.9893 9.87105 19.3368 10.0975C19.6845 10.3238 20.0873 10.46 20.5059 10.4924C20.5712 10.4974 20.6365 10.5 20.7016 10.5V15.75C20.7016 16.0721 20.4295 16.3333 20.094 16.3333C19.7584 16.3333 19.4863 16.0721 19.4863 15.75V12.8333C19.4863 11.5446 18.3982 10.5 17.0558 10.5H15.8405V2.33333C15.8405 1.04467 14.7523 0 13.4099 0H3.6877ZM13.4099 8.16667V2.33333H3.6877V8.16667H13.4099Z" fill="var(--new-front-adv-font-color)"/>
        </svg>
      </div>
      <div v-if="!accountBalance" class="adv__title">{{ $t('advance_balances.title') }}:</div>
      <div class="adv__value">${{ accountBalance }}</div>
    </div>
  </RouterLink>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: "HeaderAdvBalance",
    computed: {
      ...mapState('accounts', ['accounts']),
      accountBalance () {
        return Number(this.accounts[0]?.balance) || 0
      },
      firstAccountId({ accounts }) {
        if (accounts.length) {
          return accounts[0].accountId
        } else {
          return null
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.adv {
  font-family: $Gilroy;
  height: 48px;
  background: var(--new-front-adv-bg);
  border: 1px var(--new-front-adv-border-color) solid;
  padding: 0 $p10;
  border-radius: 10px;
  display: flex;
  align-items: center;
  transition: background-color .2s ease;
  &:hover {
    background: var(--new-front-adv-hover-bg);
  }
  &:active:hover {
    background: var(--new-front-adv-click-bg);
  }
  &__title {
    color: var(--new-front-adv-font-color);
    font-size: $h4;
    margin-right: $p10;
  }
  &__value {
    font-size: $h4;
    color: var(--new-front-primary-font-color);
    font-weight: 500;
  }
  &__icon {
    margin-right: $p5;
    height: 24px;
  }
}
.adv-disabled {
  border: unset;
  background: #B24D4D;
  .adv__title {
    color: #ffffff;
  }
  .adv__icon {
    svg {
      path {
        fill: #ffffff;
      }
    }
  }
  &:hover {
    background: #A34D4D;
  }
  &:active:hover {
    background: #974646;
  }
  .adv__value {
    color: #ffffff;
  }
}
@media screen and (max-width: 576px) {
  .adv__title {
    display: none;
    font-size: $h6;
  }
  .adv__value {
    font-size: $h6;
  }
}
</style>
