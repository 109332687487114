import gql from 'graphql-tag';

export default gql`
     mutation($address: String!, $otpToken: String!, $key: String!) {
         createRewardTransfer (address: $address, otpToken: $otpToken, key: $key){
            code
            result {
                transaction
            }
         }
    }
`;
