export const mutations = {
    dates: (state, dates) => {
        state.dates = dates;
    },
    orders: (state, orders) => {
        state.orders = orders;
    },
    transactionsCount: (state, transactionsCount) => {
        state.transactionsCount = transactionsCount;
    },
    payments: (state, payments) => {
        state.payments = payments;
    },
    balances: (state, balances) => {
        state.balances = balances;
    },
    transactions: (state, transactions) => {
        state.transactions = transactions;
    },
    deposit: (state, deposit) => {
        state.deposit = deposit;
    },
    withdrawal: (state, withdrawal) => {
        state.withdrawal = withdrawal;
    },
    setDashboardInfoState : (state, payload) => {
        state.isInfoOpened = payload
    },

    setDashboardInfoPayload : (state, payload) => {
        state.infoPayload = payload
    },
    setCurrentInterval(state, payload) {
        state.currentInterval = payload
    },
    setDashboardRawData : (state, payload) => state.dashboardRawData = payload,
    setCurrentWallet : (state, payload) => state.currentWallet = payload,
    clearAllStates: (state) => {
        state.dates = [];
        state.orders = [];
        state.transactionsCount = [];
        state.payments = [];
        state.balances = [];
        state.transactions = [];
        state.deposit = [];
        state.withdrawal = [];
    },
};
