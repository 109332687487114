<template>
<div class="modal-wrapper">
  <div @click="toggleModal" class="modal-trigger"></div>
  <div v-if="isModalActive" class="modal-content__wrapper">
      <div class="modal-close" @click="toggleModal">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.96967 0.96967C1.26256 0.676777 1.73744 0.676777 2.03033 0.96967L6 4.93934L9.96967 0.96967C10.2626 0.676777 10.7374 0.676777 11.0303 0.96967C11.3232 1.26256 11.3232 1.73744 11.0303 2.03033L7.06066 6L11.0303 9.96967C11.3232 10.2626 11.3232 10.7374 11.0303 11.0303C10.7374 11.3232 10.2626 11.3232 9.96967 11.0303L6 7.06066L2.03033 11.0303C1.73744 11.3232 1.26256 11.3232 0.96967 11.0303C0.676777 10.7374 0.676777 10.2626 0.96967 9.96967L4.93934 6L0.96967 2.03033C0.676777 1.73744 0.676777 1.26256 0.96967 0.96967Z" fill="var(--new-front-primary-font-color)"/>
        </svg>
      </div>
      <div class="modal-title">Select language</div>
      <div class="modal-items">
        <div class="modal-item" v-for="item in $options.countries" @click="selectLang(item.value)">
          <div class="modal-item__img"><img :src="langImg(item.value)" alt=""></div>
          <div class="modal-item__label">{{ item.label }}</div>
        </div>
      </div>
      <NButton @click="toggleModal">Ok</NButton>
    </div>
  <div v-if="isModalActive" class="bg"></div>
</div>
</template>

<script>
  import NButton from "@/public/components/common/NButton";
  import {mapActions, mapState} from "vuex";
  import {i18n} from "@/public/i18n";
  import router from "@/public/router/router";

  const countries = [
    {
      label: 'Russia',
      value: 'ru'

    },
    {
      label: 'Britain',
      value: 'gb'

    },
    {
      label: 'China',
      value: 'cn'

    },
    {
      label: 'Spain',
      value: 'es'

    },
    {
      label: 'Japan',
      value: 'jp'
    },
    {
      label: 'France',
      value: 'fr'
    },
    {
      label: 'Korean',
      value: 'kr'
    },
    {
      label: 'Turkey',
      value: 'tr'
    },
    {
      label: 'Germany',
      value: 'de'
    },
    {
      label: 'Poland',
      value: 'pl'
    },
    {
      label: 'Ukraine',
      value: 'ua'
    },
    {
      label: 'Bulgarian',
      value: 'bg'
    },
    {
      label: 'Greece',
      value: 'gr'
    },
    {
      label: 'Italy',
      value: 'it'
    },
    {
      label: 'Latvia',
      value: 'lv'
    },
    {
      label: 'Lithuania',
      value: 'lt'
    },
    {
      label: 'Estonia',
      value: 'es'
    },
    {
      label: 'Finland',
      value: 'fn'
    },
  ]

  export default {
    name: "ChangeLanguageModal",
    components: {NButton},
    data () {
      return {
        isModalActive: false,
      }
    },
    countries,
    methods: {
      ...mapActions('user', [
        'changeLangQuery',
        'fetchUser',
      ]),
      ...mapActions(
          ['changeLanguage'],
      ),
      selectLang(value) {
        this.changeLanguage(value);
      },
      toggleModal () {
        this.isModalActive = !this.isModalActive
      },
      langImg(value) {
        return `/assets/image/countries/${value}.png`;
      },
    },
    created() {
      this.selectLang(this.lang);
    },

    computed: {
      ...mapState([
        'lang',
      ]),
      allLangs() {
        return this.countries;
      },
      selectedLang: {
        get() {
          return localStorage.getItem('lang')  || this.lang;
        },
        async set(value) {
          localStorage.setItem('lang', value);
          await this.selectLang(value);
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
.modal{
  &-wrapper {
    z-index: 4;
    position: relative;
  }
  &-trigger {
    position: absolute;
    top: 40px;
    right: 88px;
    width: 32px;
    height: 32px;
    border-radius: 9px;
    //border: 2px solid linear-gradient(90deg, rgba(73, 113, 255, 1) 0%, rgba(192, 13, 255, 1) 100%);
    background: linear-gradient(90deg, rgba(73,113,255,1) 0%, rgba(192,13,255,1) 100%);
    z-index: 4;
  }
  &-content {
    &__wrapper {
      position: absolute;
      transform: translate(-50%, 50%);
      top: 50%;
      left: 50%;
      background: var(--new-front-main-bg);
      border-radius: 30px;
      width: 640px;
      height: 530px;
      padding: 30px;
      z-index: 3;
      box-shadow: 0 0 21px #00000030;
    }
  }
  &-close {
    position: absolute;
    right: 30px;
    top: 30px;
  }
  &-title {
    font-weight: 500;
    font-size: 32px;
    color: var(--new-front-primary-font-color);
    margin-bottom: 25px;
  }
  &-items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(6, 1fr);
    margin-bottom: 40px;
    row-gap: 20px;
  }
  &-item {
    display: flex;
    align-items: center;
    &__img {
      background: #8B81A0;
      border: 3px solid #8B81A0;
      border-radius: 8px;
      width: 30px;
      height: 30px;
      margin-right: 5px;
      overflow: hidden;
      cursor: pointer;
    }
    &__label {
      font-weight: 500;
      font-size: 16px;
      color: var(--new-front-primary-font-color);
    }
  }

}
.bg {
  width: 100vw;
  height: 100vh ;
  background: rgba(255, 255, 255, .2);
  position: absolute;
}
@media (max-width: 556px) {
  .modal {
    &-trigger {
      top: 20px;
      right: 65px;
    }
  }
}
img {
  width: 26px;
  height: 26px;
}
</style>
