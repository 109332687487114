export const mutations = {
    setPinnedMenu(state, payload) {
        state.pinnedMenu = payload;
    },

    clearAllStates: (state) => {
        state.pinnedMenu = {}
    },

};
