<script>
  import DashboardTabs from "@/public/components/elements/dashboard/DashboardTabs.vue";
  import DashboardPaymentsBalance from "@/public/components/elements/dashboard/DashboardPaymentsBalance.vue";
  import {mapGetters, mapMutations} from "vuex";
  import MultiSelect from "@/public/components/elements/integration-settings/selects/currency/index.vue";
  import DashboardCurrencyMultiSelect
    from "@/public/components/elements/dashboard/selects/DashboardCurrencyMultiSelect.vue";
  import DashboardNetworkMultiSelect
    from "@/public/components/elements/dashboard/selects/DashboardNetworkMultiSelect.vue";

  export default {
    name: "DashboardBalances",
    components: {
      DashboardNetworkMultiSelect,
      DashboardCurrencyMultiSelect,
      MultiSelect,
      DashboardPaymentsBalance,
      DashboardTabs,
      CurrencySelect: () => import(/* webpackChunkName: "public/components/elements/business-walet/MainBlocks/Header/Web/selects/Currency" */ '/public/components/elements/business-walet/MainBlocks/Header/Web/selects/Currency'),
      NetworkSelect: () => import(/* webpackChunkName: "public/components/elements/business-walet/MainBlocks/Header/Web/selects/Network" */ '/public/components/elements/business-walet/MainBlocks/Header/Web/selects/Network'),
    },
    data() {
      return {
        currentTab: 'currency',
        networkModel: [],
        currencyModel: [],
      }
    },
    methods: {
      ...mapMutations('dashboard', ['setDashboardInfoState', 'setDashboardInfoPayload']),
      openMore (value) {
        this.setDashboardInfoPayload(value);
        this.setDashboardInfoState(true);
      }

    },
    computed: {
      ...mapGetters('dashboard', ['dashboardNetworks', 'dashboardCurrencies']),
      list () {
        return this.currentTab === 'currency' ? this.filteredCurrencies : this.filteredNetworks;
      },
      filteredCurrencies () {

        let arr = this.dashboardCurrencies
        arr.forEach(item => item.items.sort((a, b) => b.amount - a.amount ))

        if (this.currencyModel.length) {
          arr = arr.filter(item => this.currencyModel.some(curr => curr.id === item.id))
        }

        if (this.networkModel.length) {
          arr = arr.filter(item => item.items.some(addr =>  this.networkModel.some(net => net.id === addr.networkId)))
        }

        // if (this.networkModel.length && this.currencyModel.length) {
        //   arr = this.dashboardCurrencies.filter(item => this.currencyModel.some(curr => curr.id === item.id) && item.items.some(addr =>  this.networkModel.some(curr => curr.id === addr.networkId)))
        // }

        return arr
      },
      filteredNetworks () {

        let arr = this.dashboardNetworks
        arr.forEach(item => item.items.sort((a, b) => b.amount - a.amount))

        if (this.networkModel.length) {
          arr = arr.filter(item => this.networkModel.some(net => net.id === item.id))
        }

        if (this.currencyModel.length) {
          arr = arr.filter(item => item.items.some(addr =>  this.currencyModel.some(curr => curr.id === addr.currencyId)))
        }

        return arr
      }
    }
  }
</script>

<template>
<div class="balances">
  <div class="balances__header">
    <DashboardTabs v-model="currentTab" />
    <dashboard-network-multi-select
        v-if="currentTab !== 'currency'"
        v-model="networkModel"
        multiple
        class="network-select"
        clearable
    />
    <dashboard-currency-multi-select
        v-else
        v-model="currencyModel"
        :selected-network="networkModel"
        multiple
        class="currency-select"
        clearable
    />
  </div>
  <div class="balances__list">
    <DashboardPaymentsBalance
        v-for="item in list"
        :key="item.id"
        :item="item"
        :current-tab="currentTab"
        @openMore="openMore" />
  </div>
</div>
</template>

<style scoped lang="scss">
.balances {
  font-family: $Gilroy;
  border-radius: 30px;
  background: var(--bg-background-secondary);
  padding: 24px 30px;
  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-devider-primary);
  }
  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 1600px) {
  .balances__list {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 900px) {
  .balances {
    padding: 20px;
    border: 1px solid var(--border-border-neutral-variant);
  }
  .balances__list {
    grid-template-columns: 1fr;
  }
  .balances__header {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
@media screen and (max-width: 576px) {
  .balances {
    padding: 0;
  }
  .balances__header {
    padding: 20px;
  }
  .balances__list {
    padding: 20px;
  }
}
</style>
