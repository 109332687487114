<script>
  export default {
    name: "MiniLoader"
  }
</script>
<template>
<svg class="loader" width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M89.282 34.641C78.2363 15.5093 53.7727 8.9543 34.641 20C15.5093 31.0457 8.9543 55.5093 20 74.641" stroke="#744DB2" stroke-width="8"/>
  <path d="M89.282 34.6402C100.328 53.7719 93.7727 78.2356 74.641 89.2812C55.5093 100.327 31.0457 93.7719 20 74.6402" stroke="#D4D4DF" stroke-width="8"/>
</svg>
</template>

<style lang="scss" scoped>
.loader {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
