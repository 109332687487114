<template>
  <div class="pagination">
    <AtPagination :size="size" :page-size="pageSize" @page-change="changePage"
                  :current="current"
                  :total="total"
    />
  </div>
</template>

<script>
import AtPagination from "at-ui/src/components/pagination";

export default {
    name: "pagination",
    components: {
        AtPagination,
    },
    props: {
        total: {
            type: Number,
            default: 1,
        },
        size: {
            type: String,
            default: 'small',
        },
        pageSize: {
            type: Number,
            default: 1,
        },
        current: {
            type: Number,
            default: 1,
        },

    },
    methods: {
        changePage(pageNumber) {
            this.$emit('page-change', pageNumber);
        },
    },
};
</script>

<style scoped lang="scss">

.pagination {
  min-height: 84px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: var(--secondary-color);
  border-radius: 0 0 20px 20px;

  &::v-deep .at-pagination {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: flex-start;

  }

  &::v-deep .at-pagination__prev {
    border: none;
    background: var(--secondary-color);
    font-size: 24px;
    margin: 0;

    &:hover {
      color: var(--blue-300);
    }
  }

  &::v-deep .at-pagination__next {
    border: none;
    background: var(--secondary-color);
    font-size: 24px;
    margin: 0;

    &:hover {
      color: var(--blue-300);
    }
  }

  &::v-deep .at-pagination__item--jump-next {
    &:hover i {
      color: var(--blue-300);
    }
  }

  &::v-deep .at-pagination__item--jump-prev {
    &:hover i {
      color: var(--blue-300);
    }
  }

  &::v-deep .at-pagination__item {
    background-color: var(--secondary-color);
    float: unset;
    border: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--secondary-font-color);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    height: 24px;
  }

  &::v-deep .at-pagination__item--active {
    background-color: transparent;
    border: none;
    color: #0059F7;
  }

  @media screen and (max-width: 400px) {
    &::v-deep .at-pagination {
      gap: 8px;
    }
  }


}


</style>
