<script>
  import Container from "@/public/components/common/uiKit/Container.vue";
  import TableHeader from "@/public/components/common/uiKit/table/TableHeader.vue";
  import {mapActions, mapState} from "vuex";
  import PreviewBankInvoice from "@/public/components/elements/bankInvoice/PreviewBankInvoice.vue";
  import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
  import CreateBankInvoiceModal from "@/public/components/modals/bankInvoice/CreateBankInvoice.vue";
  import BankInvoiceInfo from "@/public/components/modals/bankInvoice/BankInvoiceInfo.vue";
  import {fetchBankAccounts} from "@/public/store/modules/sepaSwift/actions/fetchBankAccount";
  import EmptyTable from "@/public/components/elements/EmptyTable.vue";
  import InfoBankInvoice from "@/public/components/modals/bankInvoice/InfoBankInvoice.vue";

  export default {
    name: "BankInvoices",
    components: {
      InfoBankInvoice,
      EmptyTable,
      BankInvoiceInfo,
      CreateBankInvoiceModal,
      BaseButton,
      PreviewBankInvoice,
      TableHeader,
      Container
    },
    methods: {
      ...mapActions('app', [
        'openModal',
        'closeModal',
      ]),
      ...mapActions('sepaSwift', ['fetchBankAccounts', 'fetchBankAccountValidationData']),
      openCreateModal() {
        this.openModal({
          name: 'CreateBankInvoice',
        });
      },
    },
    computed: {
      ...mapState('user', ['lang']),
      ...mapState('sepaSwift', ['accounts', 'isInfoOpened']),
      headerList () {
        return [
          {
            title: this.$t('bank.alias'),
            slug: 'sepa',
          },
          {
            title: `${this.$t('bank.type')}/${this.$t('business-wallet.balance.currency')}`,
            slug: 'currency',
          },
          {
            title: 'IBAN',
            slug: 'IBAN',
          },
          {
            title: this.$t('bank.status'),
            slug: 'Status',
          },
        ]
      },
    },
    created() {
      this.fetchBankAccounts()
      this.fetchBankAccountValidationData()
    }
  }
</script>

<template>
<div class="sepa-page">
  <Container class="container">
    <div class="sepa-page__topbar">
      <base-button
          type="primary"
          class="sepa-page__btn"
          :label="$t('bank.add-invoice')"
          @click="openCreateModal"
      >
        <template #prepend>
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
          >
            <path
                d="M6 0C6.41421 0 6.75 0.335786 6.75 0.75V5.25H11.25C11.6642 5.25 12 5.58579 12 6C12 6.41421 11.6642 6.75 11.25 6.75H6.75V11.25C6.75 11.6642 6.41421 12 6 12C5.58579 12 5.25 11.6642 5.25 11.25V6.75H0.75C0.335786 6.75 0 6.41421 0 6C0 5.58579 0.335786 5.25 0.75 5.25H5.25V0.75C5.25 0.335786 5.58579 0 6 0Z"
                fill="white"
            />
          </svg>
        </template>
      </base-button>
    </div>
    <table-header
        class="table-header"
        :list="headerList"
        :key="lang"
    />
    <template v-if="accounts.length">
      <PreviewBankInvoice v-for="item in accounts" :item="item" />
    </template>
    <EmptyTable v-else/>
  </Container>
  <CreateBankInvoiceModal />
<!--  <InfoBankInvoice />-->
  <transition name="slide-fade">
    <BankInvoiceInfo v-if="isInfoOpened"  />
  </transition>
</div>
</template>

<style scoped lang="scss">
.sepa-page {
  padding: 0 30px 30px;
  @media screen and (max-width: 950px) {
      padding: 10px;
  }
  &__btn {
    max-width: 165px;
  }
  &__topbar {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    @media screen and (max-width: 576px) {
      margin-bottom: 10px;
      padding: 10px 10px 0 10px;
    }
  }
}
.container {
  width: 100%;
  @media screen and (max-width: 576px) {
    overflow: hidden;
  }
}
.table-header {
  @media screen and (max-width: 576px) {
    display: none;
  }
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.empty {
  padding-bottom: 0;
  margin-top: 30px;
}
</style>
