<template>
<div class="nav-accordion">
  <div :class="['nav-accordion__header', item.active ? 'nav-accordion__header--active' : '', $route.name === item?.link?.name ? 'nav-accordion__header--activePage' : '', isOpen ? 'nav-accordion__header--opened' : '']" @click="toggleOpen">
    <div class="nav-accordion__header-left">
      <span class="nav-accordion__header-icon"><Icon  :icon="item.icon"/></span>
      <p class="nav-accordion__header-title">{{ $t(item.title) }}</p>
    </div>
      <div class="right-block">
          <div v-if="item.isNew" class="new-label">
              {{ $t('navigation.new') }}
          </div>
          <span :class="['nav-accordion__header-arrow', isOpen ? 'nav-accordion__header-arrow--active' : '']">
      <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.228 4.365C0.085 4.538 0 4.759 0 5C0 5.552 0.448 6 1 6H7C7.552 6 8 5.552 8 5C8 4.759 7.915 4.538 7.772 4.365L4.808 0.411C4.626 0.162 4.332 0 4 0C3.668 0 3.374 0.162 3.192 0.411L0.228 4.365Z" :fill="themeStatus === 'dark' ? 'white' : 'black'"/>
      </svg>
    </span>
      </div>
  </div>
  <div class="nav-accordion__wrapper">
    <div :class="['nav-accordion__list', isOpen ? 'nav-accordion__list-opened' : '']">
        <div
          v-for="navItem in list"
          :key="navItem.title"
          class="nav-accordion__list-item"
          @mouseenter="toggleElementHover(navItem.title)"
          @mouseleave="toggleElementHover(navItem.title)"
        >
                <svg
                        :class="['pin-icon', navItem.pinned ? 'pin-icon--active' : '']"
                        @mouseenter="hoveredElement = navItem.title" v-if="hoveredElement === navItem.title && navItem.type !== 'link'"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="emitPin(navItem)"
                >
                    <path d="M15.5469 5.84375C15.375 6.01562 15.2083 6.17188 15.0469 6.3125C14.8854 6.45312 14.7135 6.57552 14.5312 6.67969C14.349 6.78385 14.1589 6.85938 13.9609 6.90625C13.763 6.95312 13.5312 6.98177 13.2656 6.99219C13.0885 6.99219 12.9193 6.97656 12.7578 6.94531L9.79688 9.90625C9.86458 10.1042 9.91406 10.3073 9.94531 10.5156C9.97656 10.724 9.99219 10.9323 9.99219 11.1406C9.99219 11.4792 9.95833 11.7865 9.89062 12.0625C9.82292 12.3385 9.72656 12.599 9.60156 12.8438C9.47656 13.0885 9.32031 13.3203 9.13281 13.5391C8.94531 13.7578 8.73958 13.9844 8.51562 14.2188L5.5 11.2031L1.0625 15.6484L0 16L0.351562 14.9375L4.79688 10.5L1.78125 7.48438C2.01042 7.25521 2.23438 7.04948 2.45312 6.86719C2.67188 6.6849 2.90365 6.53125 3.14844 6.40625C3.39323 6.28125 3.65365 6.18229 3.92969 6.10938C4.20573 6.03646 4.51562 6.0026 4.85938 6.00781C5.06771 6.00781 5.27604 6.02344 5.48438 6.05469C5.69271 6.08594 5.89583 6.13542 6.09375 6.20312L9.05469 3.24219C9.02344 3.08073 9.00781 2.91146 9.00781 2.73438C9.00781 2.47917 9.03385 2.25 9.08594 2.04688C9.13802 1.84375 9.21615 1.65104 9.32031 1.46875C9.42448 1.28646 9.54427 1.11719 9.67969 0.960938C9.8151 0.804688 9.97396 0.635417 10.1562 0.453125L15.5469 5.84375Z" fill="var(--new-front-secondary-font-color)"/>
                </svg>
          <RouterLink v-if="navItem.type !== 'link'" :to="navItem.link" style="width: 100%">
            <div class="item-wrapper">
              <div class="info-wrapper">
                <div v-if="navItem.active" class="nav-accordion__list-item__square" />
                <span :class="['nav-accordion__list-link', navItem.active ? 'nav-accordion__list-link--active' : '']">{{ $t(navItem.title) }}</span>
              </div>
                <div v-if="navItem.isNew && hoveredElement !== navItem.title" class="new-label new-label--small">{{ $t('navigation.new') }}</div>
              <div
                  v-if="!!navItem.summary && sectionParser(navItem) && hoveredElement !== navItem.title"
                  @mouseenter="hoveredElement = navItem.title"
                  class="summary"
              >
                <span class="value">{{ amount(navItem) }}</span>
                <span class="currency">USD</span>
              </div>
            </div>
          </RouterLink>
<!--      :TODO Делалось на скорую руку, переписать принцип подстановки компонента      -->
<!--      Тэг component в этом случае нормально не работает      -->
            <a v-else :href="navItem.link" target="_blank" style="width: 100%">
                <div class="item-wrapper">
                    <div class="info-wrapper">
                        <div v-if="navItem.active" class="nav-accordion__list-item__square" />
                        <span :class="['nav-accordion__list-link', navItem.active ? 'nav-accordion__list-link--active' : '']">{{ $t(navItem.title) }}</span>
                    </div>
                    <div
                            v-if="!!navItem.summary && sectionParser(navItem)"
                            class="summary"
                    >
                        <span class="value">{{ amount(navItem) }}</span>
                        <span class="currency">USD</span>
                    </div>
                </div>
            </a>
        </div>
    </div>
  </div>
</div>
</template>

<script>
  import Icon from "@/public/components/common/uiKit/Icon";
  import {mapGetters, mapState} from "vuex";
  import { prettifyAmountWithAbbreviations } from '/public/filters'

  export default {
    name: "NavAccordion",
    components: {Icon},
    props: {
      item: {
        type: Object,
        default: () => ({})
      },
        pinned: {
            type: Array,
            required: true,
        }
    },
    data () {
      return {
        isOpen: false,
        hoveredElement: ''
      }
    },
    methods: {
      toggleOpen () {
        this.isOpen = !this.isOpen
        if (this.item.link) {
          if (this.item.roleAlias === 'collecting' && !this.userRoleRules['collecting'].entities.view_dashboard) return

          this.$router.push(this.item.link)
        }
      },
        toggleElementHover (id) {
            this.hoveredElement = this.hoveredElement === id ? '' : id
        },
      sectionParser(item) {
        const { link } = item
        const { name } = link || {}

        let parseName = ''

        switch (name) {
          case "wallets":
            parseName = 'PAY_IN'
            break;
          case "businessWallet":
            parseName = 'BUSINESS'
            break;
          case "payout-wallet-balance":
            parseName = 'PAY_OUT'
            break;
          case "recurrentsAddress":
            parseName = 'RECURRENT'
            break;
          case "personal-addresses":
            parseName = 'PERSONAL'
            break;
          default:
            parseName = ''
        }

        return parseName
      },
      amount(navItem) {
        const name = this.sectionParser(navItem)
        return name ? (this.summary[name] ? prettifyAmountWithAbbreviations( this.summary[name] ) : '0') : '0'
      },
        emitPin (item) {
          this.$emit('pin', item)
        }
    },
    computed: {
        ...mapState('app', {
            themeStatus: state => state.theme
        }),
      ...mapState('summary',
          ['summary'],
      ),
      ...mapGetters('organizations', ['userRoleRules']),
        list () {
            // if (!this.pinned) return this.item.list
            return this.item.list.map((el => {
                const pinned = this.pinned.some(pin => el.navAlias ===  pin)
                return {
                    ...el,
                    pinned
                }
            }))
        },
    }
  }
</script>

<style lang="scss" scoped>
.pin-icon {
  position: absolute;
  right: 0;
  z-index: 5;
  cursor: pointer;
  &--active {
    path {
      fill: var(--new-front-purple)
    }
  }
  &:hover {
    path {
      fill: var(--new-front-purple)
    }
  }
}
.right-block {
  display: flex;
  align-items: center;
  gap: 10px;
}
.new-label {
  color: var(--new-front-bg-success);
  font-size: $h5;
  &--small {
    font-size: $h6;
  }
}
.nav-accordion {
  font-family: $Gilroy;
  padding: $p5 $p30;

  &__list-item {
    display: flex;
    align-items: center;
    position: relative;
    .item-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;

      .info-wrapper {
        display: flex;
        align-items: center;
      }

      .summary {
        font-family: var(--new-front-font-family);
        font-size: $h5;
        padding-right: 20px;
        z-index: -1;

        .value {
          color: var(--new-front-text-success-bg-primary);
        }
        .currency {
          color: var(--new-front-text-primary-bg-primary);
          margin-left: 5px;
        }
      }

      @include below_phone() {
        .summary {
          font-size: $h6;
        }
      }
    }
  }

  &:hover {
    .nav-accordion__header-title {
      color: var(--new-front-primary-font-color);
      font-weight: 500;
    }
    .nav-accordion__header-icon {
      &::v-deep svg {
        path {
          fill: #744DB2 !important;
        }
      }
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $p15 $p20;
    border-radius: 10px;
    transition: all .3s ease;
    cursor: pointer;
    &--opened {
      .nav-accordion__header-icon {
        &::v-deep svg {
          path {
            fill: #744DB2 !important;
          }
        }
      }
      .nav-accordion__header-title {
        color: var(--new-front-primary-font-color);
        font-weight: 500;
      }
    }
    &--active {
      background: var(--new-font-nav-hover-bg);
      .nav-accordion__header {
        background: var(--new-font-nav-hover-bg);
      }
      .nav-accordion__header-title {
        color: var(--new-front-primary-font-color);
      }
      .nav-accordion__header-icon {
        &::v-deep svg {
          path {
            fill: #744DB2 !important;
          }
        }
      }
    }
    &-left {
      display: flex;
      align-items: center;
    }
    &-title {
      color: var(--new-front-secondary-font-color);
      margin-left: $p10;
      font-size: $h4;
    }
    &-arrow {
      transition: transform .3s ease;
      transform: rotate(180deg);
      &--active {
        transform: rotate(0deg);
      }
    }
    &-icon {
      &::v-deep svg {
        path {
          fill: var(--new-front-secondary-font-color) !important;
        }
      }
    }
  }
  &__wrapper {
    max-height: fit-content;
  }
  &__list {
    max-height: 0;
    overflow: hidden;
    transition: max-height .3s ease;
    padding-left: $p30;
    &-opened {
      max-height: 250px;
    }
    &-item {
      margin: $p15 0;
      padding-left: 15px;
      > a {
        display: flex;
        align-items: center;
        position: relative;
      }
      &__square {
        width: 5px;
        height: 5px;
        background: #744DB2;
        border-radius: 1px;
        position: absolute;
        left: -15px;
      }
    }
    &-link {
      font-size: $h5;
      color: var(--new-front-secondary-font-color);
      &:hover {
        color: var(--new-front-primary-font-color)
      }
      &--active {
        color: var(--new-front-primary-font-color);
      }
    }
  }
}
.nav-accordion__header--activePage {
  background: var(--new-font-nav-hover-bg);
  .nav-accordion__header-title {
    color: var(--new-front-primary-font-color);
  }
  .nav-accordion__header-icon {
    &::v-deep svg {
      path {
        fill: #744DB2 !important;
      }
    }
  }
}
@media (max-width: 1180px) {
  .nav-accordion {
    padding: $p5 0;
  }
}
</style>
