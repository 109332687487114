<template>
    <div class="rolling-reserve-top-bar row">
        <div class="left-side">
            <RollingReserveSortByStatus class="filter-item" />
            <RollingReserveSortByCurrency class="filter-item" />
            <RollingReserveFilterByDate class="filter-item" />
        </div>
        <AtButton
            size="small"
            :icon="refreshIcon"
            :loading="isLoading"
            @click="update"
        />
    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';

import AtButton from 'at-ui/src/components/button';
import RollingReserveSortByStatus from "./RollingReserveSortByStatus";
import RollingReserveSortByCurrency from "./RollingReserveSortByCurrency";
import RollingReserveFilterByDate from "./RollingReserveFilterByDate";

export default {
    name: 'RollingReserveTopBar',
    components: {
        RollingReserveFilterByDate,
        RollingReserveSortByCurrency,
        RollingReserveSortByStatus,
        AtButton,
    },
    data () {
        return {
            isLoading: false,
        };
    },
    computed: {
        ...mapState('rollingReserve', [
            'countOnPage',
        ]),
        refreshIcon () {
            return this.isLoading ? '' : 'icon-refresh-cw';
        },
    },
    methods: {
        ...mapActions('rollingReserve', [
            'fetchRollingReserves',
        ]),
        update () {
            const {
                $route,
                $router,
                fetchRollingReserves,
            } = this;

            this.isLoading = true;

            fetchRollingReserves({
                route: $route,
                router: $router,
            })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};

</script>

<style lang="scss" scoped>
    .rolling-reserve-top-bar {
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: nowrap;

        .left-side {
            width: 50%;

            .filter-item {
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
</style>
