<template>
  <div class="orders-filter-by-date">
    <p class="orders-filter-by-date__title">
      {{ $t('orders.period') }}
    </p>
    <DatePicker
        class="orders-filter-by-date__date"
        type="date"
        :show-second="false"
        range
        :lang="selectLang"
        confirm
        :confirm-text="select_dates"
        value-type="date"
        v-model="dates"
        @confirm="selectDates"
        @clear="clearDates"
        :disabled-date="disabledRange"
    />
  </div>
</template>

<script>

import Moment from "moment";
import {mapActions, mapState} from 'vuex';

import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/locale/ru';

export default {
    name: 'DonationsFilterByDate',
    components: {
        DatePicker,
    },
    data() {
        return {
            dates: [],
        };
    },
    beforeMount() {
        this.setDateFromUrl();
    },
    computed: {
        ...mapState('user', [
            'user',
        ]),
        donationId() {
            return this.$route.params.id;
        },
        disabledAfter() {
            return new Date();
        },
        selectLang() {
            return this.user.lang;
        },
        select_dates() {
            return this.$t('orders.select_dates');
        },
    },
    methods: {
        ...mapActions('donations', [
            'fetchPreCheckoutOrders',
        ]),
        disabledRange(date) {
            return date < new Date(2022, 0, 1) || date > new Date();
        },

        setDateFromUrl() {
            const dateFromUrl = this.$route.query.date;

            if (!dateFromUrl) {
                return false;
            }

            this.dates = dateFromUrl.split('to').map(date => new Date(date));
        },
        selectDates() {
            const {
                $route,
                $router,
                dates,
                fetchPreCheckoutOrders,
                donationId,
            } = this;
            const dateFrom = Moment(dates[0]).format();
            const dateTo = Moment(dates[1])
                .add(23, 'hours')
                .add(59, 'minutes')
                .add(59, 'seconds')
                .format()
      ;

            fetchPreCheckoutOrders({
                pageId: donationId,
                route: $route,
                router: $router,
                date: [dateFrom, dateTo],
                excludeParams: ['page'],
            });
        },
        clearDates() {
            const {
                $route,
                $router,
                fetchPreCheckoutOrders,
                donationId,
            } = this;

            fetchPreCheckoutOrders({
                pageId: donationId,
                route: $route,
                router: $router,
                date: [],
                excludeParams: ['page'],
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.orders-filter-by-date {
  &__title {
    margin-bottom: 8px;
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__date::v-deep.mx-datepicker-range {
    width: 100%;
  }

  &__date ::v-deep .mx-input {
    height: 46px;
    background: var(--secondary-color);
    border: 1px solid var(--border-color);
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__date ::v-deep .mx-icon-clear svg {
    width: 24px;
    height: 24px;
  }

  &__date ::v-deep .mx-icon-calendar {
    color: #8F949A;
  }
}

</style>