import getOrphans from "/public/graphQl/queries/getOrphanDeposits";

export const getOrphanDeposits = async ({dispatch, commit, rootGetters}, variables) => {
    let orphanData;
    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    try {
        commit('app/toggleLoading', null, {root: true});
        orphanData = await dispatch('app/request', {
            type: 'query',
            gql: getOrphans,
            variables: {
                ...variables,
                organizationId
            }
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (orphanData.message) {
        throw orphanData.message;
    }

    if (orphanData.errors) {
        throw orphanData.errors;
    }

    const {
        data: {orphanDeposits},
    } = orphanData;
    commit('setOrphanList', orphanDeposits)
    return orphanDeposits;
};
