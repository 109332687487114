<template>
<div class="history" @click="emitClick">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 19.875C7.65076 19.875 4.125 16.3492 4.125 12C4.125 7.65076 7.65076 4.125 12 4.125C16.3492 4.125 19.875 7.65076 19.875 12C19.875 16.3492 16.3492 19.875 12 19.875ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" :fill="!themeStatus ? '#6750A4' : '#FFFFFF'"/>
        <path d="M13.047 10.4114L10.4696 10.7344L10.3774 11.1628L10.8849 11.2551C11.2145 11.3342 11.2804 11.4529 11.2079 11.7825L10.3774 15.6848C10.1598 16.6934 10.496 17.168 11.287 17.168C11.9001 17.168 12.612 16.8845 12.935 16.4956L13.0339 16.0276C12.8097 16.2253 12.4801 16.3044 12.2626 16.3044C11.9528 16.3044 11.8407 16.0869 11.9198 15.7046L13.047 10.4114Z" :fill="!themeStatus ? '#6750A4' : '#FFFFFF'"/>
        <path d="M13.125 8.0625C13.125 8.68382 12.6213 9.1875 12 9.1875C11.3787 9.1875 10.875 8.68382 10.875 8.0625C10.875 7.44118 11.3787 6.9375 12 6.9375C12.6213 6.9375 13.125 7.44118 13.125 8.0625Z" :fill="!themeStatus ? '#6750A4' : '#FFFFFF'"/>
    </svg>

</div>
</template>
<script>
    import {mapState} from "vuex";

    export default {
        name: "InfoButton",
        computed: {
            ...mapState(['themeStatus']),
        },
        methods: {
            emitClick () {
                this.$emit('click')
            }
        }
    }
</script>
<style scoped lang="scss">
.history {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: var(--new-front-main-bg);
  border: 1px solid #744DB2;
    border-radius: 10px;
  transition: background .2s ease;
    cursor: pointer;
  &:hover {
    background: var(--new-front-bg-btn-secondary-bg-modal-primary-hover);
  }
}
</style>
