import swapTransferMutation from "../../../../graphQl/mutations/swapTransfer";
import {v4 as UUID} from "uuid";

export const setSwapTransfer = async ({ dispatch, commit, rootGetters }, payload) => {
    let response;
    const key = UUID();
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const variables = {
        ...payload,
        organizationId,
        key
    }

    try {
        response = await dispatch('app/request', {
            type: 'mutation',
            gql: swapTransferMutation,
            variables,
        }, { root: true });
    } catch (error) {
        return error;
    }

    return ( response.data && response.data.createCrosschainSwap ) ? response.data.createCrosschainSwap : {};
};
