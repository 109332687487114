export const mutations = {
    setAccounts: (state, accounts) => {
        state.accounts = accounts
            .map(account => Object.freeze(account));
    },
    setLoading: (state, loading) => {
        state.loading = loading;
    },
    setDepositLoading(state, loading) {
        state.deposit.loading = loading;
    },
    setDepositCreated: (state, created) => {
        state.deposit.created = created;
    },
    setDeposit(state, {code, result}) {
        state.deposit.code = code;

        if(code === 0) {
            state.deposit.result.address = result.address || '';
            state.deposit.result.tag = result.tag || '';
            state.deposit.result.until = result.until || '';
            state.deposit.result.from = result.from || '';
        }
    },

    setAccountData(state, {count, logs}) {
        state.history.total = count;
        state.history.list = logs;
    },
    filtersType: (state, type) => {
        state.filters.type = type;
    },
    getTariffs: (state, tariffs) => {
        state.tariffs = tariffs;
    },

    setBalance(state, payload) {
        state.sumBalance = payload;
    },

    setAccountBalance (state, { accountId, balance }) {
        state.accounts = state.accounts.map((item) => {
            return {
                ...item,
                balance: item.accountId === accountId ? balance : item.balance
            }
        })
    },

    clearAllState (state) {
        state.loading = false;
        state.accounts = [];
        state.accountData = [];

        state.history = {
            onPage: 10,
            total: 0,
            list: [],
        };

        state.filters = {
            type: [],
        };
        state.deposit = {
            loading: false,
            created: false,

            code: 0,
            result: {
                address: '',
                tag: '',
                until: '',
            },
        };
        state.tariffs = [],
        state.sumBalance = 0;
    },

    setUpdatesSub(state, sub) {
        if(sub) {
            state.updatesSub = sub;
        } else {
            if(state.updatesSub && state.updatesSub.unsubscribe) {
                state.updatesSub.unsubscribe();

                state.updatesSub = undefined;
            }
        }
    },
};
