<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      style="background: none; display: block; shape-rendering: auto; animation-play-state: running; animation-delay: 0s;"
      :width="`${width}px`"
      :height="`${height}px`"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
  >
    <circle
        cx="50"
        cy="50"
        fill="none"
        :stroke="color"
        stroke-width="8"
        r="35"
        stroke-dasharray="164.93361431346415 56.97787143782138"
        style="animation-play-state: running; animation-delay: 0s;"
        transform="rotate(228.123 50 50)"
    >
      <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
          style="animation-play-state: running; animation-delay: 0s;"
      />
    </circle>
  </svg>
</template>

<script>
export default {
    name: "BlueLoader",
    inheritAttrs: true,
    props: {
        height: {
            type: String,
            default: '24',
        },
        width: {
            type: String,
            default: '24',
        },
        color: {
            type: String,
            default: "#2939ec",
        },
    },
};
</script>

<style scoped>

</style>