<template>
  <div class="requests-top-bar">
    <div class="requests-top-bar__left-side">
      <div class="button">


        <AtButton
            size="small"
            class="requests-top-bar__new-button"
            type="primary"
            icon="icon-plus"
            @click="openCreateWithdrawalRequestModal"
        >
          {{ $t('withdrawal.create_a_withdrawal_request') }}
        </AtButton>

        <div class="requests-top-bar__button-container">
          <AtButton
              class="requests-top-bar__download-button"
              size="small"
              icon="icon-download"
              @click="download"
          />
          <AtButton
              class="requests-top-bar__download-button"
              size="small"
              @click="downloadXLSXQuery"
          >
            <svg class="requests-top-bar__icon-xls" fill="#000000" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 50 50" width="50px" height="50px">
              <path
                  d="M 28.875 0 C 28.855469 0.0078125 28.832031 0.0195313 28.8125 0.03125 L 0.8125 5.34375 C 0.335938 5.433594 -0.0078125 5.855469 0 6.34375 L 0 43.65625 C -0.0078125 44.144531 0.335938 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 29.101563 50.023438 29.402344 49.949219 29.632813 49.761719 C 29.859375 49.574219 29.996094 49.296875 30 49 L 30 44 L 47 44 C 48.09375 44 49 43.09375 49 42 L 49 8 C 49 6.90625 48.09375 6 47 6 L 30 6 L 30 1 C 30.003906 0.710938 29.878906 0.4375 29.664063 0.246094 C 29.449219 0.0546875 29.160156 -0.0351563 28.875 0 Z M 28 2.1875 L 28 6.53125 C 27.867188 6.808594 27.867188 7.128906 28 7.40625 L 28 42.8125 C 27.972656 42.945313 27.972656 43.085938 28 43.21875 L 28 47.8125 L 2 42.84375 L 2 7.15625 Z M 30 8 L 47 8 L 47 42 L 30 42 L 30 37 L 34 37 L 34 35 L 30 35 L 30 29 L 34 29 L 34 27 L 30 27 L 30 22 L 34 22 L 34 20 L 30 20 L 30 15 L 34 15 L 34 13 L 30 13 Z M 36 13 L 36 15 L 44 15 L 44 13 Z M 6.6875 15.6875 L 12.15625 25.03125 L 6.1875 34.375 L 11.1875 34.375 L 14.4375 28.34375 C 14.664063 27.761719 14.8125 27.316406 14.875 27.03125 L 14.90625 27.03125 C 15.035156 27.640625 15.160156 28.054688 15.28125 28.28125 L 18.53125 34.375 L 23.5 34.375 L 17.75 24.9375 L 23.34375 15.6875 L 18.65625 15.6875 L 15.6875 21.21875 C 15.402344 21.941406 15.199219 22.511719 15.09375 22.875 L 15.0625 22.875 C 14.898438 22.265625 14.710938 21.722656 14.5 21.28125 L 11.8125 15.6875 Z M 36 20 L 36 22 L 44 22 L 44 20 Z M 36 27 L 36 29 L 44 29 L 44 27 Z M 36 35 L 36 37 L 44 37 L 44 35 Z"/>
            </svg>
          </AtButton>
          <btn-refresh
              size="small"
              :icon="refreshIcon"
              :loading="isLoading"
              @update="updateRequests"
          />

        </div>

      </div>
      <div class="requests-top-bar__filter-container">
        <div class="left">
          <RequestsSortByStatus class="requests-top-bar__filter-item"/>
          <!--            <RequestsSortByPlatform class="requests-top-bar__filter-item" />-->
          <RequestsSortByCurrency class="requests-top-bar__filter-item"/>

        </div>
        <div class="right">
          <RequestsSortByNetwork class="requests-top-bar__filter-item"/>
          <RequestsFilterByDate class="requests-top-bar__filter-item"/>
          <!--            </div>-->

          <!--        <div class="requests-top-bar__right-side">-->

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions} from 'vuex';

import RequestsSortByStatus from "./RequestsSortByStatus";
import RequestsSortByPlatform from "./RequestsSortByPlatform";
import RequestsSortByCurrency from "./RequestsSortByCurrency";
import RequestsFilterByDate from "./RequestsFilterByDate";
import RequestsSortByNetwork from "./RequestsSortByNetwork";
import AtButton from 'at-ui/src/components/button';
import BtnRefresh from "../../common/btn-refresh";

export default {
    name: 'RequestsTopBar',
    components: {
        BtnRefresh,
        RequestsSortByStatus,
        RequestsSortByPlatform,
        RequestsSortByCurrency,
        RequestsFilterByDate,
        RequestsSortByNetwork,
        AtButton,
    },
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        refreshIcon() {
            return this.isLoading ? '' : 'icon-rotate-cw';
        },
    },
    methods: {
        ...mapActions('app', [
            'openModal',
        ]),
        // ...mapActions('balances', [
        //     'fetchBalances',
        // ]),
        ...mapActions('withdrawalRequests', [
            'fetchWithdrawalRequests',
            'downloadCSV',
            'downloadXLSX',
        ]),

        download() {
            const {
                $route,
            } = this;

            this.downloadCSV({
                route: $route,
            });
        },
        downloadXLSXQuery() {
            const {
                $route,
            } = this;

            this.downloadXLSX({
                route: $route,
            });
        },
        updateRequests() {
            const {
                $route,
                $router,
                fetchWithdrawalRequests,
            } = this;

            this.isLoading = true;

            fetchWithdrawalRequests({
                route: $route,
                router: $router,
            })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        openCreateWithdrawalRequestModal() {
            const {
                // fetchBalances,
                openModal,
            } = this;

            // fetchBalances();
            openModal({
                name: 'CreateWithdrawalRequestModal',
                payload: {withoutCurrency: true},
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.requests-top-bar {
  justify-content: space-between;
  align-items: flex-end;
  background: #FFFFFF;

  &__icon-xls {
    width: 18px;
    height: 18px;
    stroke: #0059F7;
    fill: #0059F7;
  }

  &__download-button {
    height: 46px;
    width: 60px;
    background: #FFFFFF;
    border-radius: 12px;
    border: 1px solid #0059F7;
    padding: 0;
    transition: all 0.3s ease-in;

    &:hover {
      background: var(--grey-100);
    }
  }

  &__download-button ::v-deep .at-btn__icon {
    color: #0059F7;
    font-size: 18px;
    line-height: 18px;
  }

  &__refresh-button {
    height: 46px;
    width: 60px;
    background: #F9F9F9;
    border-radius: 12px;
    border: none;
  }

  &__refresh-button ::v-deep .at-btn__icon {
    color: #0059F7;
    font-size: 18px;
    line-height: 18px;
  }

  &__new-button {
    height: 46px;
    max-width: 291px;
    background: #0059F7;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    transition: all 0.3s ease-in;

    &:hover {
      background: var(--blue-400);
    }
  }

  &__new-button ::v-deep .at-btn__icon {
    font-size: 24px;
  }

  &__new-button ::v-deep .at-btn__text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin: 0;
  }

  &__left-side {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-left: 45px;
    padding-right: 45px;
    padding-top: 25px;
    padding-bottom: 40px;
  }

  &__button-container {
    display: flex;
    justify-content: flex-end;
    gap: 25px;
    margin-bottom: 10px;
  }

  &__filter-container {
    display: flex;
    justify-content: space-between;
    gap: 25px;

  }

  &__filter-item {

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.left {
  width: 100%;
  max-width: 630px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.right {
  width: 100%;
  max-width: 630px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 25px;
  margin-bottom: 5px;
  padding-top: 26px;
}

.button_update {
  float: right;
}

@media screen and (max-width: 950px) {
  .button {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0;
  }
  .requests-top-bar {
    &__left-side {
      padding-left: 10px;
      padding-right: 10px;
    }

    &__new-button {
      max-width: 190px;
    }

    &__filter-container {
      flex-direction: column;

    }

  }

  .left {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .right {
    max-width: 100%;
  }

}

@media screen and (max-width: 375px) {
  .requests-top-bar {
    &__button-container {
      gap: 25px;
      justify-content: flex-start;
    }
  }
  .button {
    flex-direction: column;
    gap: 25px;
  }

}

</style>