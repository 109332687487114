<script>
  import SvgSkull from "@/public/components/elements/dashboard/assets/SvgSkull.vue";
  import SvgDust from "@/public/components/elements/dashboard/assets/SvgDust.vue";
  import SvgCalendar from "@/public/components/elements/dashboard/assets/SvgCalendar.vue";
  import {mapGetters} from "vuex";
  import moment from "moment";

  export default {
    name: "DashboardCollection",
    components: {SvgCalendar, SvgDust, SvgSkull},
    computed: {
      ...mapGetters('dashboard', ['availableToCollect', 'dustAmount', 'currentSymbol', 'dirtyAmount', 'getPercentOf']),
      ...mapGetters('app', ['getSelectedOrganization']),
      moment() {
        return moment
      },
      nextCollectingDate () {
        return moment.utc(this.getSelectedOrganization.nextCollectingAt).format('DD/MM/YY')
      },
      nextCollectingTime () {
        return moment.utc(this.getSelectedOrganization.nextCollectingAt).format('hh:mm')
      },
      daysTranslate () {
        const interval = this.getSelectedOrganization.collectingInterval
        if (interval === 1) {
          return this.$t('payments.day')
        } else if (interval > 1 && interval <= 4) {
          return this.$t('payments.short-days')
        } else if (interval > 5) {
          return this.$t('payments.days')
        }
      }
    }
  }
</script>

<template>
<div class="collection">
  <div class="collection__left">
    <div>
      <div class="collection__title">{{ $t('payments.amount-next-collection') }}</div>
      <div class="collection__left-value">{{ currentSymbol }}{{ availableToCollect }}</div>
    </div>
<!--    <div class="collection__left-items">-->
<!--      <div class="collection__left-item">-->
<!--        <SvgSkull />-->
<!--        <div>-->
<!--          <div class="collection__title">{{ $t('payments.dirty-crypto') }}</div>-->
<!--          <div class="collection__left-item-value">{{ currentSymbol }}{{ dirtyAmount }} <span class="red-text">({{ getPercentOf(dirtyAmount, availableToCollect) }}%)</span> </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="collection__left-item">-->
<!--        <SvgDust />-->
<!--        <div>-->
<!--          <div class="collection__title">{{ $t('payments.dust') }}</div>-->
<!--          <div class="collection__left-item-value">{{ currentSymbol }}{{ dustAmount }} <span class="yellow-text">({{getPercentOf(dustAmount, availableToCollect) }}%)</span> </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
  <div class="collection__right">
    <div>
      <div class="collection__next">
        <SvgCalendar />
        {{ $t('payments.next-collection') }}
      </div>
      <div class="collection__dateBlock" v-if="getSelectedOrganization.nextCollectingAt">
        <div class="collection__date">
          {{ nextCollectingDate }}
        </div>
        <div class="collection__time">
          {{ $t('payments.date-collection', { time: nextCollectingTime }) }}
<!--          в {{ nextCollectingTime }} UTC-->
        </div>
      </div>
      <div v-else class="collection__date">-</div>
    </div>
    <div class="collection__right-items">
      <div class="collection__right-divider"></div>
      <div class="collection__right-items-value">
        <div class="collection__right-item">
          <div class="collection__right-item-title">
            {{ $t('payments.frequency-collection') }}
          </div>
          <div v-if="getSelectedOrganization.collectingInterval" class="collection__right-item-value">
            {{ getSelectedOrganization.collectingInterval }} {{ daysTranslate }}
          </div>
          <div v-else class="collection__right-item-value">
            -
          </div>
        </div>
        <div class="collection__right-item">
          <div class="collection__right-item-title">
            {{ $t('payments.minimum-collection') }}
          </div>
          <div class="collection__right-item-value">
            ${{ getSelectedOrganization.collectingMinimumAmount }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.collection {
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 30px;
  overflow: hidden;
  &__dateBlock {
    display: flex;
    gap: 7px;
    align-items: flex-end;
    margin-bottom: 20px;
  }
  &__title {
    color: var(--text-color-text-tertiary);
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
    &.small {
      font-size: 14px;
    }
  }
  &__left {
    height: 100%;
    padding: 30px;
    background: var(--bg-background-secondary, #FFF);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-item {
      display: flex;
      align-items: center;
      gap: 10px;
      &:first-child {
        margin-bottom: 20px;
      }
      &-value {
        color: var(--text-text-primary);
        font-size: 16px;
        font-weight: 500;
        line-height: 100%;
        margin-top: 4px;
      }
    }
    &-value {
      color: var(--text-text-primary);
      font-size: 52px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      margin-top: 10px;
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 30px 20px;
    background: var(--bg-background-primary-purple, #744DB2);
    &-divider {
      margin-bottom: 10px;
      width: 100%;
      height: 1px;
      border-top: 1px dashed var(--border-border-input-primary-default);
      opacity: 0.4;
    }
    &-item {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      &-title {
        opacity: .4;
        color: var(--text-text-white, #FFF);
        font-size: 14px;
        font-weight: 500;
        line-height: 100%;
        margin-bottom: 5px;
      }
      &-value {
        color: var(--text-text-white, #FFF);
        font-size: 16px;
        font-weight: 500;
        line-height: 100%;
      }
    }
    &-items {
    }
  }
  &__next {
    display: flex;
    align-items: center;
    gap: 2px;
    opacity: 0.4;
    color: var(--text-text-white);
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
    margin-bottom:  5px;
  }
  &__date {
    color: var(--text-text-white, #FFF);
    font-size: 28px;
    font-weight: 500;
    line-height: 90%;
  }
  &__time {
    color: var(--text-text-white, #FFF);
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
  }
}
.red-text {
  color: var(--OCP-0-100-error-error40, #B04444);
}
.yellow-text {
  color: var(--OCP-status-medium-medium-orange-primary, #E4A062);
}
@media screen and (max-width: 1600px) {
  .collection {
    grid-template-columns: 1fr;
  }
  .collection__left-value {
    font-size: 48px;
  }
  .collection__right {
    padding: 30px;
  }
  .collection__right-divider {
    margin: 20px 0;
  }
}
@media screen and (max-width: 900px) {
  .collection__right-divider {
    margin: 15px 0;
  }
  .collection__right-items-value {
    display: flex;
    justify-content: space-between;
  }
  .collection__title {
    font-size: 13px;
  }
  .collection__right {
    padding: 20px;
  }
  .collection__left {
    padding: 20px 20px 25px 20px;
  }
  .collection__left-items {
    margin-top: 20px;
  }
  .collection__next {
    font-size: 13px;
  }
}
</style>
