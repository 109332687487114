import {ApolloClient} from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';

import {split} from 'apollo-link';
import {createHttpLink} from 'apollo-link-http';
import {WebSocketLink} from 'apollo-link-ws';

import {API_HTTP_URL, API_SOCK_URL, API_USE_SOCK} from "@/config";

const parseUrl = (proto, url) => {
    if(url[0] === '/') {
        // relative path

        url = window.location.origin + '' + url;
    } else if(url.indexOf('http') === 0) {
        // full url
    } else {
        throw new Error('Unknown URL: ' + url);
    }

    return url.replace('http', proto);
};

export default () => {
    // if(!window.$$gqlPromise) {
    //     await new Promise(r => {
    //         window.$$gqlPromise = r;
    //     });
    // }

    let wsLink;

    const link = (() => {
        const pathToRequest = parseUrl('http', API_HTTP_URL);
        const pathToSubscribe = parseUrl('ws', API_SOCK_URL);

        const httpLink = createHttpLink({
            uri: pathToRequest,
            opts: {
                credentials: 'include',
            },
            credentials: 'include',
        });

        if (API_USE_SOCK !== '1') {
            return httpLink;
        }

        // Create a WebSocket link:
        wsLink = new WebSocketLink({
            uri: pathToSubscribe,
            options: {
                reconnect: true,
                credentials: 'include',
            },
            credentials: 'include',
        });

        return split(
            // split based on operation type
            () => true,
            wsLink,
            httpLink,
        );
    })();

    const cacheStorage = new InMemoryCache();
    cacheStorage.write = (valueToStore) => {
        return valueToStore.result;
    };

    const client = new ApolloClient({
        // Provide required constructor fields
        cache: cacheStorage,
        link: link,
        queryDeduplication: false,
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'ignore',
            },
            query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            },
        },
        fetchOptions: {
            credentials: 'include',
        },
        credentials: 'include',
        request: operation => {
            operation.setContext({
                fetchOptions: {
                    credentials: "include",
                },
            });
        },
    });

    client.$$wsLink = wsLink;

    return client;
};
