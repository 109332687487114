import gql from "graphql-tag";

export default gql`
    query($organizationId: String!, $pageId: String!) {
        getPreCheckoutPage(organizationId: $organizationId, pageId: $pageId) {
           id,
           accountId,
           url,
           alias,
           dailyTurnover,
           info {
            title,
            description,
            avatarLink,
            theme,
           },
           createdAt,    
            
        }
    }
`;
