import getNetworkFees from "/public/graphQl/queries/getNetworkFees";

let loading = false;

export const fetchNetworkFees = async ({ dispatch, state, commit }) => {
    if(state.fees.length)
        return state.fees;

    if(loading)
        return [];

    loading = true;

    try {
        const {data: {getNetworkFees: networkFees}} = await dispatch('app/request', {
            type: 'query',
            gql: getNetworkFees,
        }, { root: true });

        commit('setNetworkFees', networkFees);

        return networkFees;
    } catch (error) {
        console.error('fetch network fees', error);
        return [];
    } finally {
        loading = false;
    }
};