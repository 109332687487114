const TITLE = 'Уведомление';

export const copyToClipboard = async (text) => {
    if (window.clipboardData && window.clipboardData.setData) {

        // Internet Explorer-specific code path to prevent textarea
        // being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);

    } else if (
        document.queryCommandSupported &&
        document.queryCommandSupported("copy")
    ) {
        const textarea = document.createElement("textarea");

        textarea.textContent = text;

        // Prevent scrolling to bottom of page in Microsoft Edge.
        textarea.style.position = "fixed";

        document.body.appendChild(textarea);
        textarea.select();

        try {
            // Security exception may be thrown by some browsers.
            return document.execCommand("copy");
        } catch (ex) {
            throw ex;
        } finally {
            document.body.removeChild(textarea);
        }
    }
};

const mixin = {
    data() {
        return {
            showCopyIcon: true
        }
    },
    methods: {
        copyToClipboard(
            text,
            showSpawn = false,
            {
                title = 'Уведомление',
                successMessage = 'Адрес скопирован',
                failMessage = 'Адрес не скопирован',
            },
        ) {
            copyToClipboard(text)
                .then(() => {
                    if (showSpawn) {
                        this.$Notify({
                            title: title,
                            message: successMessage,
                            type: 'success',
                        });

                        this.showCopyIcon = false;
                        setTimeout( () => this.showCopyIcon = true, 1000);
                    }
                })
                .catch(() => {
                    if (showSpawn) {
                        this.$Notify({
                            title: title,
                            message: failMessage,
                            type: 'error',
                        });
                    }
                });
        },
    },
};

export default mixin;
