<template>
<div class="item">
    <div class="item__element">
        <div class="item__element-value--black">
            {{ item.settingAlias }}
        </div>
    </div>
    <div class="item__element">
        <div class="item__element-value--black">
            {{ walletType(item.addressFromType) }}
        </div>
    </div>
    <div class="item__element">
        <div class="item__element-value--black">
            {{ item.savedFeeAmount }}
        </div>
    </div>
    <div class="item__element">
        <div class="item__element-value--black">
            {{ moment(item.createdAt).format('DD/MM/YY HH:mm') }}
        </div>
    </div>
    <div class="item__element">
        <div class="item__element-value--black">
            {{ item.amount }}
        </div>
    </div>
    <div class="item__element">
        <div class="item__element-value--black">
            {{ item.settingAmount }}
        </div>
    </div>
    <div class="item__value item__element">
        <div class="item__asset">
            <currency-network-icon :currency="getCurrencyInfoById(item.currencyId)?.currency " :network="getNetworkSystemName(item.networkId).toUpperCase()" />
            <div class="item__asset-currency">
                {{ getCurrencyTicker(item.currencyId) }}
            </div>
            <div class="item__asset-network">
                {{ getNetworkSystemName(item.networkId) | getNetworkShortNameByNetworkName }}
            </div>
        </div>
    </div>
    <div class="item__element">
        <div class="item__element-value">
            {{ item.addressFrom | getPrettyAddress }}
        </div>
        <copy v-if="item.addressFrom"  :value="item.addressFrom" />
    </div>
    <div class="item__element">
        <div class="item__element-value">
            {{ item.addressTo | getPrettyAddress }}
        </div>
        <copy v-if="item.addressTo"  :value="item.addressTo" />
    </div>
    <div class="item__element">
        <div class="item__element-value">
            {{ item.txId | getPrettyAddress }}
        </div>
        <copy v-if="item.txId"  :value="item.txId" />
    </div>
    <div class="item__element">
        <div :class="['orphan__statusLabel', `orphan__statusLabel--${ status.status }`]">
            {{ status.label }}
        </div>
    </div>
    <div class="item__element">
        <div class="item__element-value--black">
            {{ item.transactionsAmount }}
        </div>
    </div>
<!--    <div @click.stop="goToDetails" class="item__arrow">-->
<!--        <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.635 0.228C1.462 0.0850001 1.241 5.42458e-08 1 4.37114e-08C0.448 1.95827e-08 -1.95827e-08 0.448 -4.37114e-08 1L-3.0598e-07 7C-3.30108e-07 7.552 0.448 8 1 8C1.241 8 1.462 7.915 1.635 7.772L5.589 4.808C5.838 4.626 6 4.332 6 4C6 3.668 5.838 3.374 5.589 3.192L1.635 0.228Z" fill="var(&#45;&#45;new-front-text-primary-bg-primary)"/>-->
<!--        </svg>-->
<!--    </div>-->
</div>
</template>
<script >

    import Copy from "@/public/components/common/copy.vue";
    import moment from "moment";
    import BigNumber from "bignumber.js";
    import CurrencyNetworkIcon from "@/public/components/elements/business-walet/Select/CurrencyNetworkIcon.vue";
    import {mapState} from "vuex";
    import {getCurrencyInfoById, getCurrencyTicker, getNetworkSystemName, getPrettyAddress} from "@/public/filters";

    export default {
        name: "CollectingHistoryItem",
        components: {CurrencyNetworkIcon, Copy},
        props: {
            item: {
                type: Object,
                default: () => ({})
            }
        },
        computed: {
            ...mapState('app', ['networks', 'currencies']),
            network () {
                return this.networks.find(({ id }) => id === this.item.networkId)
            },
            currency () {
                return this.currencies.find(({ id }) => id === this.item.currencyId)
            },
            moment() {
                return moment
            },
            amount () {
                return new BigNumber(this.item.amount)
                    .toFixed()
            },
            orphanOutHash () {
                if (!this.item.outTransaction || !this.item.outTransaction.txId)  {
                    return ''
                }
                return getPrettyAddress(this.item.outTransaction.txId)
            },
            status () {
                return {
                    status: this.item.status.toLowerCase(),
                    label: this.$t(`orphan.status-${this.item.status}`)
                }
            },
            eventLabel () {
                return this.$t(`webhooks.${this.item.event.toLowerCase()}`)
            },
            id () {
                if (!this.item?.id) return ''
                return getPrettyAddress(this.item?.id)
            },
            eventId () {
                if (!this.item.eventId) return
                return getPrettyAddress(this.item.eventId)
            },
            requestUrl () {
                if (!this.item?.requestUrl) return
                return this.item?.requestUrl
            }
        },
        methods: {
            getCurrencyInfoById,
            getNetworkSystemName,
            getCurrencyTicker,
            goToDetails () {
                this.$router.push(`/webhooks/${ this.item.id }`)
            },
            walletType (type) {
                if (!type) return ''
                return type === `PAY_IN` ? 'Pay-in' : this.$t('profits.personal-wallet')
            },
        }
    }
</script>
<style scoped lang="scss">
.item {
  padding: 0 $p30;
  min-height: 105px;
  //border-bottom: 1px var(--new-front-divider-color) solid;
  display: grid;
  grid-template-columns: repeat(12, 17%);
  grid-auto-flow: column;
  align-items: center;
  position: relative;
  //&:hover {
  //  background: var(--new-front-bg-btn-primary-bg-primary-hover);
  //}
  &__element {
    display: flex;
    align-items: center;
    //justify-content: space-between;
    gap: 10px;
      min-height: 105px;
      border-bottom: 1px var(--new-front-divider-color) solid;
      padding-right: 5px;
      &:last-child {
      }
    &-value {
      color: var(--new-front-purple);
      text-decoration: underline;
      font-weight: 500;
      font-size: $h4;
      &--noUnderline {
        text-decoration: none !important;
      }
      &--black {
        color: var(--new-front-primary-font-color);
        font-weight: 500;
        font-size: $h4;
      }
    }

  }
  &__asset {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &-currency {
      font-weight: 500;
      font-size: $h4;
      color: var(--new-front-primary-font-color);
      margin: 0 10px;
    }
    &-network {
      font-weight: 500;
      font-size: $h4;
      color: var(--new-front-secondary-font-color);
    }
  }
}
.item__status {
  width: 10px;
  height: 10px;
  border-radius:2px;
  background: #44B070;
  &--error {
    background: #CF5252;
  }
  &--pending {
    background: #BE9B40;
  }
  &--processed {
    background: #44B070;
  }
}
.item__arrow {
  position: absolute;
  right: 0;
  width: 20px;
  height: 100%;
  cursor: pointer;
  > svg {
    position: relative;
    top: 44px;
  }
}
.orphan__statusLabel {
  padding: 4px $p15;
  background: var(--new-front-status-processed);;
  color: var(--new-front-status-text-processed);
  border-radius: 10px;
  font-size: $h4;
  font-weight: 500;
  &--error {
    background: var(--new-front-status-error);
    color: var(--new-front-status-text-error);
  }
  &--pending {
    background: var(--new-front-status-pending);
    color: var(--new-front-status-text-pending);
  }
  &--processed {
    background: var(--new-front-status-processed);
    color: var(--new-front-status-text-processed);
  }
}
::v-deep     .copy-wrapper {
    margin: 0;
}
//
//PENDING
//PROCESSED
//ERROR
//REJECTED
</style>
