export const clearAllStores = ({commit}) => {
    commit('accounts/clearAllState', null, { root: true });
    commit('balances/clearAllStates', null, { root: true });
    commit('dashboard/clearAllStates', null, { root: true });
    commit('donations/clearAllStates', null, {root: true});
    commit('exchanges/clearAllStates', null, { root: true });
    commit('historyAddresses/clearAllStates', null, {root: true});
    commit('invoices/clearAllStates', null, {root: true});
    commit('orders/clearAllStates', null, {root: true});
    commit('orders/clearAllStates', null, {root: true});
    commit('payoutBalances/clearAllStates', null, {root: true});
    commit('rollingReserve/clearAllStates', null, {root: true});
    commit('user/clearAllStates', null, {root: true});
};
