<template>
  <div>
    <div class="dashboard-transaction-row">
      <div class="dashboard-transaction-row__type-container">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="data.type === 'in'">
          <path d="M7.28638 11.2L12.2864 16M12.2864 16L17.2864 11.2M12.2864 16L12.2864 4" stroke="#27AE60"
                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path
              d="M2.94154 15.7867C3.47593 17.8466 4.68989 19.7275 6.48279 21.0754C9.94394 23.6773 14.7125 23.6533 18.1402 21.0166C19.8915 19.6695 21.073 17.8095 21.5925 15.7774"
              stroke="#27AE60" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="data.type === 'out'">
              <path d="M17.2864 8.79999L12.2864 4M12.2864 4L7.28638 8.79998M12.2864 4L12.2864 16" stroke="#F2C94C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2.94154 15.787C3.47593 17.8468 4.68989 19.7278 6.48279 21.0756C9.94394 23.6775 14.7125 23.6535 18.1402 21.0169C19.8915 19.6698 21.073 17.8097 21.5925 15.7776" stroke="#F2C94C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          <p class="dashboard-transaction-row__value">{{ $t(type) }}</p>
      </div>
      <p class="dashboard-transaction-row__value">{{ amount }}</p>
      <p class="dashboard-transaction-row__value">{{ network | getNetworkName }}</p>

      <div class="dashboard-transaction-row__currency col">
        <CurrencyImg
            class="dashboard-transaction-row__currency-img"
            :currency="currency"
        />
        <p class="dashboard-transaction-row__value">{{ currency }}</p>
      </div>
    </div>
    <div class="dashboard-transaction-row dashboard-transaction-row_mobile">
      <div class="dashboard-transaction-row__type-container">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.28638 11.2L12.2864 16M12.2864 16L17.2864 11.2M12.2864 16L12.2864 4" stroke="#27AE60"
                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path
              d="M2.94154 15.7867C3.47593 17.8466 4.68989 19.7275 6.48279 21.0754C9.94394 23.6773 14.7125 23.6533 18.1402 21.0166C19.8915 19.6695 21.073 17.8095 21.5925 15.7774"
              stroke="#27AE60" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p class="dashboard-transaction-row__type">{{ $t(type) }}</p>
      </div>
      <p class="dashboard-transaction-row__value">{{ amount }}</p>
      <div class="dashboard-transaction-row__currency col">
        <div class="dashboard-transaction-row__currency-wrap">
          <CurrencyImg
              class="dashboard-transaction-row__currency-img"
              :currency="currency"
          />
          <p class="dashboard-transaction-row__value">{{ currency }}</p>
        </div>
        <p class="dashboard-transaction-row__network">{{ network | getNetworkName }}</p>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from 'vuex';

import CurrencyImg from "/public/components/elements/CurrencyImg";

export default {
    name: 'DashboardTransactionCountRow',
    components: {
        CurrencyImg,
    },
    types: {
        in: "dashboard.deposits",
        out: "dashboard.withdrawals",
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState('app', [
            'currencies',
        ]),
        type() {
            return this.$options.types[this.data.type];
        },
        amount() {
            return this.data.amount;
        },
        network() {
            return this.data.network;
        },
        currency() {
            const {
                currencies,
                data: {
                    currency,
                },
            } = this;
            if (!currency) return null;
            return currencies.find(c => c.id === currency)?.currency ?? '';

        },
    },
};

</script>

<style lang="scss" scoped>
.dashboard-transaction-row {
  position: relative;
  align-items: center;
  color: var(--new-front-primary-font-color);
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-left: 45px;
  min-height: 55px;
  border-bottom: 1px solid var(--border-color);

  &__value {
    font-family: $Gilroy;
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--new-front-primary-font-color);
    font-weight: 500;
  }

  &_mobile {
    display: none;
  }

  &__type-container {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &__currency {
    display: flex;
    align-items: center;
  }

  &__currency-img {
    margin-right: 12px;
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 1020px) {
  .dashboard-transaction-row {
    &__type {
      display: none;
    }
  }
}

@media screen and (max-width: 907px) {

  .dashboard-transaction-row {
    display: none;
    padding-left: 20px;

    &__type-container {
      padding-right: 20px;
    }

    &_mobile {
      display: grid;
      grid-template-columns: min-content 1fr 1fr;
    }

    &__currency {
      flex-direction: column;
      align-items: start;
      justify-content: start;
    }

    &__currency-wrap {
      display: flex;
      align-items: center;
    }

    &__network {
      font-size: var(--font-size-text-caption);
      color: #8F949A;
    }
  }

}

@media screen and (max-width: 768px) {
  .dashboard-transaction-row {
    &_mobile {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    &__type {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);

      display: block;

    }
  }
}

@media screen and (max-width: 442px) {

  .dashboard-transaction-row {
    &_mobile {
      display: grid;
      grid-template-columns: min-content 1fr 1fr;
    }

    &__type {
      display: none;
    }
  }
}
</style>
