import updateOrganizationMutation from "/public/graphQl/mutations/updateOrganization.js";

export const updateOrganization = async ({commit, dispatch, rootState, rootGetters}, payload) => {
    const selectedOrganizationId = rootGetters['app/getSelectedOrganizationId'];
    let currenciesData;

    try {
        if (!rootState.app.isLoading) {
            commit('app/setLoading', true, {root: true});
        }
        currenciesData = await dispatch('app/request', {
            type: 'query',
            gql: updateOrganizationMutation,
            variables: {
                organizationId: selectedOrganizationId,
                name: payload.name,
                logoUrl: payload.logoUrl,
                telegramUrl: payload.telegramUrl || null,
                websiteUrl: payload.websiteUrl || null,
                mainCurrencyId: payload.mainCurrencyId || null,
                timezone: payload.timezone || null
            },
        }, {root: true});
    } catch (error) {
        throw new Error(error.message);
    } finally {
        dispatch('getOrganization');
        commit('app/setLoading', false, {root: true});
    }

    if (currenciesData.code === 0) {
        throw currenciesData.message;
    }

    return true;
};
