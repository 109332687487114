<template>
<div class="comingSoon">
  <p>Coming soon...</p>
</div>
</template>

<script>
export default {
    name: "coming-soon",
};
</script>

<style lang="scss" scoped>
.comingSoon {
  color: var(--primary-font-color);
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  > p {
    font-size: 70px;
    position: absolute;
    top: 30%;
  }
}
</style>
