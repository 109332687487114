import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!, $id: String!) {
        getPreCheckoutPageOrder(organizationId: $organizationId, id: $id) {
            id
            pageId
            amount
            orderId
            currencyId
            networkId
            status
            amount
            received
            priceUSD
            amountUSD
            receivedUSD
            utmSource
            utmMedium
            utmCampaign
            utmContent
            nickname
            message
            createdAt
        }
    }
`;
