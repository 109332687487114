<template>
    <div class="settings"></div>
</template>

<script>

export default {
    name: 'SettingsPage',
};

</script>