<template>
    <BaseModal
            class="create-withdrawal-request-modal"
            width="100%"
            :value="isOpen"
            :title="title"
            :show-close="true"
            :show-head="true"
            :show-footer="true"
            @on-confirm="createWithdrawal"
            @on-cancel="handleCancelCreate"
            :loading="withdrawalCreating"
    >
        <template v-if="withdrawalCreated">
            <!--        <template>-->
            <div class="complete-title">
                <div v-if="_withdrawalStatus === 'init'">
                    <p class="complete__title">{{ $t('create-withdrawal-request-modal.output_created') }}</p>
                    <div class="complete__row">
                        <div class="complete__text-container">
                            <p class="complete__main-text">ID: </p>
                            <p class="complete__main-text">{{ _withdrawalId }}</p>
                        </div>
                    </div>
                </div>
                <div v-else-if="_withdrawalStatus === 'error'">
                    <p class="complete__title">{{ $t('create-withdrawal-request-modal.error') }}</p>
                </div>
                <div v-else-if="_withdrawalStatus === 'pending'">
                    <p class="complete__title">{{ $t('create-withdrawal-request-modal.pending') }}</p>
                </div>
                <div v-else-if="_withdrawalStatus === 'processed'">
                    <p class="complete__title">{{
                            $t('create-withdrawal-request-modal.the_withdrawal_is_completed')
                        }}</p>
                    <div class="complete__row">
                        <div class="complete__text-container">
                            <p class="complete__main-text">ID: </p>
                            <p class="complete__main-text">{{ _withdrawalId }}</p>
                        </div>
                        <div class="complete__text-container">
                            <p class="complete__main-text">{{
                                    $t('create-withdrawal-request-modal.transaction_hash')
                                }} </p>
                            <CopyString
                                    :string="_withdrawalTX"
                                    canCopy
                                    :successMessage="$t('copy.transaction_hash')"
                                    :failMessage="$t('copy.transaction_hash_failed')"
                                    :title="$t('copy.notification')"
                            />

                        </div>
                    </div>
                </div>
                <div v-else-if="_withdrawalStatus === 'rejected'">
                    <p class="complete__title">{{ $t('create-withdrawal-request-modal.withdrawal_rejected') }}</p>
                </div>
            </div>


        </template>
<!--        <LoadingSpinner-->
<!--                v-else-if="withdrawalCreating"-->
<!--                :title="creating_an_application"-->
<!--        />-->
        <template v-else>

            <div>
                <div
                        :class="['create-withdrawal-request-modal__select-currency', addressId ? 'create-withdrawal-request-modal__select-currency_selected' : '']">
                  <div class="address-block">
                    <div class="address-block__description">
                      <CurrencyImg
                          class="currency-img"
                          :currency="addressInfo.currencyId"
                      />
                      <p class="address-block__title">
                        {{ addressInfo.currencyId | getCurrencyTicker }}
                        <span class="secondary-font">
                          ({{
                            addressInfo.networkId | getNetworkName
                          }})
                        </span>
                      </p>

                    </div>
                    <p class="address-block__title">
                      {{ rewardBalance }}
                    </p>
                  </div>
                </div>
            </div>


            <br/>

            <!--            если выбран адрес, то показываем эти блоки-->
            <template v-if="addressId">
                <div class="create-withdrawal-request-modal__title  ">
                    {{ $t('create-withdrawal-request-modal.receiving_address') }}
                </div>
              3
                <div class="create-withdrawal-request-modal__select-address">
                    <AtInput
                            v-model.trim="address"
                            :status="(address && !addressValid) ? 'error' : ''"
                            :placeholder="enter_address"
                    />
                    <div class="create-withdrawal-request-modal__address-book">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.5 22C5.1 22 4.75 21.85 4.45 21.55C4.15 21.25 4 20.9 4 20.5V3.5C4 3.1 4.15 2.75 4.45 2.45C4.75 2.15 5.1 2 5.5 2H18.5C18.9 2 19.25 2.15 19.55 2.45C19.85 2.75 20 3.1 20 3.5V20.5C20 20.9 19.85 21.25 19.55 21.55C19.25 21.85 18.9 22 18.5 22H5.5ZM5.5 20.5H18.5V3.5H17V10.15L14.575 8.75L12.15 10.15V3.5H5.5V20.5ZM5.5 20.5V3.5V20.5ZM12.15 10.15L14.575 8.75L17 10.15L14.575 8.75L12.15 10.15Z" fill="#0059F7"/>
                        </svg>
                        <AtSelect
                                v-model="value"
                                @on-change="handleSelectCurrency"
                        >
                            <AtOption
                                    v-for="address in filteredAddressesList"
                                    :key="address.id"
                                    :value="address.id"
                                    :label="address.label"
                            >
                                <div class="address-option">
                                    <div class="info-block">
                                        <div class="address">
                                            {{ address.address | preparedString(7) }}
                                        </div>

                                        <div class="info">
                                            {{ mobileText(address.alias) }}
                                            <div class="info-icon"
                                                 @click.self="changeAddress(address)"
                                            >
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12V16C11 16.2652 11.1054 16.5196 11.2929 16.7071C11.4804 16.8946 11.7348 17 12 17C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16V12C13 11.7348 12.8946 11.4804 12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11ZM12.38 7.08C12.1365 6.97998 11.8635 6.97998 11.62 7.08C11.4973 7.12759 11.3851 7.19896 11.29 7.29C11.2017 7.3872 11.1306 7.49882 11.08 7.62C11.024 7.73868 10.9966 7.86882 11 8C10.9992 8.13161 11.0245 8.26207 11.0742 8.38391C11.124 8.50574 11.1973 8.61656 11.29 8.71C11.3872 8.79833 11.4988 8.86936 11.62 8.92C11.7715 8.98224 11.936 9.00632 12.099 8.99011C12.2619 8.97391 12.4184 8.91792 12.5547 8.82707C12.691 8.73622 12.8029 8.61328 12.8805 8.46907C12.9582 8.32486 12.9992 8.16378 13 8C12.9963 7.73523 12.8927 7.48163 12.71 7.29C12.6149 7.19896 12.5028 7.12759 12.38 7.08ZM12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z"
                                                          fill="#8F949A"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </AtOption>
                            <div class="create-withdrawal-request-modal__address-btn">
                                <AtButton
                                        type="primary"
                                        @click="addAddress"
                                >
                                    {{ $t('create-withdrawal-request-modal.add_btn') }}
                                </AtButton>
                            </div>
                        </AtSelect>
                    </div>
                </div>
<!--                <p v-if="depositWithdrawalAddressesMatch" class=" create-withdrawal-request-modal__title-special">-->
<!--                    {{ $t('create-withdrawal-request-modal.deposit_withdrawal_addresses_match') }}-->
<!--                </p>-->
                <p v-if="(address && !addressValid)" class=" create-withdrawal-request-modal__title-special">
                    {{ $t('create-withdrawal-request-modal.invalid_address') }}
                </p>
            </template>
        </template>
        <template #footer>
            <template v-if="!withdrawalCreated">
                <div v-if="feeAvailableSeconds !== -1"
                     class="create-withdrawal-request-modal__btn">
                    <AtButton
                            type="primary"
                            :disabled="actionButtonDisabled"
                            @click="createWithdrawal"
                    >
                        {{ actionButtonTitle }}
                    </AtButton>
                </div>
                <div class="create-withdrawal-request-modal__btn"
                     v-else
                >
                    <AtButton

                            @click="fetchFee"
                    >
                        {{ $t('create-withdrawal-request-modal.update') }}
                    </AtButton>
                </div>
            </template>
            <template v-else>
                <AtButton
                        type="primary"
                        @click="handleCancelCreate"
                >
                    {{ $t('create-withdrawal-request-modal.close') }}
                </AtButton>
            </template>
        </template>
    </BaseModal>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';

import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';
import AtModal from 'at-ui/src/components/modal';
import CurrencyImg from "/public/components/elements/CurrencyImg";
import AtInput from 'at-ui/src/components/input';
import AtRadioGroup from 'at-ui/src/components/radio-group';
import AtRadio from 'at-ui/src/components/radio';
import LoadingSpinner from "/public/components/elements/LoadingSpinner";
import AtButton from 'at-ui/src/components/button';
import WithdrawalAddress from "../../modals/requests/components/WithdrawalAddress";
import AtAlert from 'at-ui/src/components/alert';
import BaseModal from "../BaseModal";
import CopyString from "/public/components/elements/CopyString";
import createRewardTransfer from "../../../graphQl/mutations/createRewardTransfer";

import {getCurrencyTicker, getNetworkName, preparedString} from '/public/filters';
import BigNumber from "bignumber.js";
import {FEE_TOKEN_SOURCE} from "../../../../common/constants/fee-token-source";

export default {
    name: 'ReferralCreateWithdrawalRequestModalPayout',
    components: {
        BaseModal,
        AtSelect,
        AtOption,
        AtModal,
        CurrencyImg,
        AtInput,
        AtRadioGroup,
        AtRadio,
        LoadingSpinner,
        AtButton,
        WithdrawalAddress,
        AtAlert,
        CopyString,
    },
    data() {
        return {
            accountId: '',
            addressId: '',
            networkId: '',
            value: '',

            address: '',
            tag: '',
            amount: '',
            isDebouncing: null,
            withdrawalCreating: false,
            addressBookId: '',
            currentModal: 'ReferralCreateWithdrawalRequestModalPayout',
        };
    },
    computed: {
        ...mapState('app', {
            currencies: state => state.currencies,
            networks: state => state.networks,
            addressWithdrawal: state => state.modal.payload.addressId,
            modalPayload: state => state.modal.payload,
        }),
        ...mapState('accounts', [
            'accounts',
        ]),
        ...mapState('balances', {
            balancesGroupedBusiness: (state) => state.balancesGroupedBusiness.currencyGroups,
        }),
        ...mapGetters('balances', [
            'balances',
        ]),
        ...mapGetters('app', [
            'isOpenModal',
        ]),
        ...mapState('withdrawalRequestModal', [
            'withdrawal',
        ]),
        ...mapState('addressBook', {
            listAddress: state => state.listCurrenciesAddress,
        }),
        ...mapState('balances', {
            balancesGroupedBusiness: (state) => state.balancesGroupedBusiness.currencyGroups,
        }),
        ...mapState('withdrawalRequestModal', {
            _feePending: state => state.fee.pending,
            _feeComplete: state => state.fee.complete,
            _feeCode: state => state.fee.code,
            _feeAmount: state => state.fee.amount,

            _tariffFee: state => state.fee.tariffFee,
            _tariffFeeUSD: state => state.fee.tariffFeeUSD,
            _networkFee: state => state.fee.networkFee,
            _networkFeeUSD: state => state.fee.networkFeeUSD,

            _feeUSD: state => state.fee.usdAmount,
            _feeUntil: state => state.fee.until,
            _feeCurrency: state => state.fee.currency,
            _feeToken: state => state.fee.token,
            _feeSource: state => state.fee.source,

            _withdrawalPending: state => state.withdrawal.pending,
            _withdrawalComplete: state => state.withdrawal.complete,
            _withdrawalError: state => state.withdrawal.error,
            _withdrawalId: state => state.withdrawal.id,
            _withdrawalStatus: state => state.withdrawal.status,
            _withdrawalTX: state => state.withdrawal.tx,
        }),
        filteredAddressesList () {
            return this.listAddress.filter(({networks}) => networks.some(item => item === this.addressModel.networkId))
        },
        rewardBalance () {
            return this.modalPayload ? this.modalPayload.rewardBalance : '';
        },
        addressInfo () {
            return this.modalPayload ? this.modalPayload.addressInfo : { currencyId: '', networkId: '' };
        },
        withdrawalCreated() {
            return !this._withdrawalPending && this._withdrawalComplete && this._withdrawalId;
        },

        fetchingFee() {
            return this._feePending;
        },
        fetchedSuccessFee() {
            return !this.fetchingFee && this._feeComplete && this._feeCode === 0;
        },
        fetchedFailFee() {
            return !this.fetchingFee && this._feeComplete && this._feeCode !== 0;
        },

        feeSourceTitle() {
            if (this._feeSource === FEE_TOKEN_SOURCE.ADVANCED_BALANCE) {
                return this.$t('create-withdrawal-request-modal.advance_balance');
            } else if (this._feeSource === FEE_TOKEN_SOURCE.WALLET) {
                return this.$t('create-withdrawal-request-modal.withdrawal_amount');
            }

            return '';
        },
        tariffFee() {
            if (!this.fetchedSuccessFee) {
                return '0';
            }

            return Number(this._tariffFee);
        },
        tariffFeeUSD() {
            if (!this.fetchedSuccessFee) {
                return '0';
            }

            return Number(this._tariffFeeUSD);
        },
        networkFee() {
            if (!this.fetchedSuccessFee) {
                return '0';
            }

            return Number(this._networkFee);
        },
        networkFeeUSD() {
            if (!this.fetchedSuccessFee) {
                return '0';
            }

            return Number(this._networkFeeUSD);
        },

        feeAmount() {
            if (!this.fetchedSuccessFee) {
                return '0';
            }

            return Number(this._feeAmount);
        },
        feeUSD() {
            if (!this.fetchedSuccessFee) {
                return '0';
            }

            return this._feeUSD;
        },
        feeCurrency() {
            if (!this.fetchedSuccessFee) {
                return '';
            }

            return this._feeCurrency;
        },
        feeAvailableSeconds() {

            return 0;
        },

        account() {
            return this.accounts.find(a => a.accountId === this.accountId);
        },
        accountCurrency() {
            return this.currencies.find(c => c.id === this.account?.currencyId);
        },
        accountBalance() {
            return this.account?.balance || '0';
        },

        accountCurrencyId() {
            return this.accountCurrency?.id;
        },

        addressesList() {
            return this.balances
                .map(b => ({
                    ...b,
                    label: `${preparedString(b.address, 7)} | ${b.balance} ${getCurrencyTicker(b.currencyId)}`,
                }));
        },



        balanceValid() {
            if (this._feeSource === FEE_TOKEN_SOURCE.ADVANCED_BALANCE) {
                return new BigNumber(this.accountBalance).gte(this.feeAmount);
            }

            return true;
        },
        addressValid() {
            const network = this.networks.find(({id}) => id === this.addressInfo.networkId);
            return network && network.addressRegex && new RegExp(network.addressRegex).test(this.address);
        },

        amountToReceive() {
            if (this._feeSource === FEE_TOKEN_SOURCE.WALLET) {
                const amount = new BigNumber(this.amount || '0')
                    .minus(this.networkFee)
                    .dp(8, BigNumber.ROUND_HALF_UP);

                if (amount.lte(0)) {
                    return 0;
                }

                return amount.toNumber();
            }

            return new BigNumber(this.amount || '0')
                .dp(8, BigNumber.ROUND_HALF_UP)
                .toNumber();
        },

        actionButtonDisabled() {
            if (!this.addressValid) {
                return true;
            }

            return false;
        },
        actionButtonTitle() {
            let title = this.$t('create-withdrawal-request-modal.send');

            if (this.feeAvailableSeconds !== -1 && this.amount !== '' && !this.isDebouncing && this.address.length) {
                title += ` (${this.feeAvailableSeconds}s)`;
            }

            return title;
        },

        isOpen() {
            const {
                $options: {name},
                isOpenModal,
            } = this;

            return isOpenModal(name);
        },
        title() {
            return this.$t(this.isSuccess ? 'create-withdrawal-request-modal.application_created' : 'create-withdrawal-request-modal.creating_application');
        },
        hasErrors() {
            return Boolean(this.errors.length);
        },
        creating_an_application() {
            return this.$t('create-withdrawal-request-modal.creating_an_application');
        },
        select_address() {
            return this.$t('create-withdrawal-request-modal.select_address');
        },
        enter_address() {
            return this.$t('create-withdrawal-request-modal.enter_address');
        },
        enter_tag() {
            return this.$t('create-withdrawal-request-modal.enter_tag');
        },
        enter_amount() {
            return this.$t('create-withdrawal-request-modal.enter_amount');
        },
        showMinWithdrawalTip() {
            return this.minWithdrawal !== 0;
        },
        getCurrencyImageForSelect() {

            if (this.addressId){
                return this.addressesList.find((i) => i.id === this.addressId).currencyId};
            return '';
        },

    },
    methods: {
        ...mapActions('app', [
            'closeModal',
            'openModal',
            'request',
        ]),
        ...mapActions('accounts', [
            'getAllNetworks',
        ]),
        ...mapActions('balances', [
            // 'getAllBalances',
            // 'fetchBalances',
            'getAccountBalancesGrouped',
            'getPrice',
        ]),
        ...mapActions('addressBook', [
            'getAddressBook',
            'networkAddressBook',
            'ChangeAddressBook',
        ]),
        ...mapActions('withdrawalRequests', {
            refreshRequests: 'fetchWithdrawalRequests',
            createWithdrawalRequest: 'createWithdrawalRequest',
            fetchNetworkFee: 'fetchNetworkFee',
            requestWithdrawal: 'requestWithdrawal',
        }),
        ...mapActions('withdrawalRequestModal', {
            clearFee: 'clearFee',
            clearWithdrawal: 'clearWithdrawal',

            _fetchFee: 'fetchFee',
            _createWithdrawal: 'createWithdrawal',
        }),

        mobileText(text) {
            if (window.innerWidth <= 576 && text !== null) {
                return text.length > 6 ? `${text.substr(0, 6)}...` : text;
            }
            return text;
        },

        changeAddress(item) {
            const {openModal} = this;
            openModal({
                name: 'ChangeAddressBook',
                payload: {
                    currentId: item.id,
                    currentIdNetwork: item.networks[0],
                    address: item.address,
                    comment: item.comment,
                    noteText: item.alias,
                },
            });
        },

        handleSelectCurrency(value) {
            if (value) this.address = this.listAddress.find(item => item.id === value).address;
        },

        maxAmount() {
            this.amount = this.balance.balance;
        },
        clearAllData() {
            this.accountId = '';
            this.addressId = '';
            this.address = '';
            this.tag = '';
            this.amount = '';
            this.description = '';

            this.clearFee();
            this.clearWithdrawal();
        },
        handleCancelCreate() {
            const {
                clearAllData,
                closeModal,
            } = this;
            closeModal();
            if (this.withdrawalCreated && this.accountId.length) this.getAccountBalancesGrouped({accountId: this.accountId});

            setTimeout(() => {
                clearAllData();
            }, 500);
        },

        // selectCurrency(balanceId) {
        //     if (!balanceId) {
        //         return false;
        //     }
        //
        //     this.currencyBalance = this.accountBalances.find(balance => balance.id === balanceId);
        //
        //     this.networkFee = this.currencies.find(el => el.id === this.currencyBalance.currencyId).networks.find(network => network.id === this.currencyBalance.networkId).networkFee;
        //
        //     this.getPrice({
        //         from: `${getCurrencyTicker(this.account.currencyId)}`,
        //         to: `${getCurrencyTicker(this.currencyBalance.currencyId)}`,
        //     });
        //
        //
        // },

        startTimer() {
            if (this.timerId) {
                return;
            }

            this.timerId = setInterval(() => {
                this.time = new Date().toJSON();
            }, 1000);
        },
        stopTimer() {
            clearInterval(this.timerId);

            this.timerId = 0;
        },

        fetchFee() {
            if (!(this.addressId && this.accountId && this.amount)) {
                return;
            }
            this.clearFee();
            this._fetchFee({
                accountId: this.accountId,
                addressId: this.addressId,
                amount: this.amount,
            });
        },
        async createWithdrawal() {
            if (this.actionButtonDisabled) {
                return;
            }

            const payload = {
                ...this.modalPayload,
                address: this.address,
            };
          this.openModal({
            name: 'TwoFaConfirmation',
            payload
          });
        },

        setMaxAmount() {
            if (!(this.addressId && this.accountId)) {
                return;
            }
            this.amount = `${this.balanceAmount}`;
        },

        addAddress() {
            const {openModal} = this;

            openModal({
                name: 'NewAddressModal',
            });
        },
    },
    watch: {
        withdrawalCreated(n, o) {
            if (!(typeof n !== "undefined" && typeof o !== "undefined")) {
                return;
            }

            if (n === o) {
                return;
            }

            if (!n) {
                return;
            }

            this.refreshRequests({
                route: this.$route,
                router: this.$router,
            });
        },
        isOpen(status) {
            if (!status) {
                this.clearAllData();
                this.stopTimer();

                return;
            }

            this.startTimer();

            if (this.accounts.length) {
                this.accountId = this.accounts[0].accountId;
            }
            this.$nextTick(() => {
                if (this.addressWithdrawal) {
                    this.addressId = this.addressWithdrawal;
                }
            });

            this.networkAddressBook();
        },
        accounts() {
            if (this.accounts.length) {
                this.accountId = this.accounts[0].accountId;
            }
        },
        _withdrawalError(message) {
            if (!message) {
                return;
            }

            this.$Message.error(this.$t(message));

            this.clearWithdrawal();
        },
        addressId(n, o) {
            this.value = '';
            this.address = '';
            let networkTicker;
            const network = this.addressesList.find(item => item.networkId === this.addressInfo.networkId);

            if (network) networkTicker = getNetworkName(network.networkId);

            this.getAddressBook({
                route: this.$route,
                router: this.$router,
                page: 1,
                showUrlFilters: false,
                networks: [`${networkTicker}`],
            });

            if (!n) {
                return;
            }

            if (n === o) {
                return;
            }
            if (this.isDebouncing === null) {
                this.isDebouncing = false;
            }
            this.fetchFee();
        },
    },
};
</script>
<style lang="css" scoped>
.create-withdrawal-request-modal ::v-deep .at-modal__header p, .at-modal__header .at-modal__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
}

.create-withdrawal-request-modal ::v-deep .at-modal__header {
    min-height: 107px;
    display: flex;
    align-items: center;
    padding-left: 60px;
    border-bottom: 1px solid var(--border-color);
    border-radius: 20px 20px 0 0;
}

.create-withdrawal-request-modal ::v-deep .at-modal {
    border: 1px solid var(--border-color);
    box-sizing: border-box;
    border-radius: 20px;
}

.create-withdrawal-request-modal ::v-deep .at-modal__close {
    font-size: 24px;
    color: #8F949A;
    top: 37px;
    right: 37px;
}

.create-withdrawal-request-modal ::v-deep .at-modal__body {
    padding: 26px 60px 0 60px;
}

::v-deep .at-modal {
    max-width: 764px;
}


.create-withdrawal-request-modal__select-currency ::v-deep .at-select__selection {
    padding-left: 30px;
    border: 1px solid var(--border-color);
    background: transparent;
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    min-height: 46px;
    display: flex;
    align-items: center;
}

.create-withdrawal-request-modal__select-currency_selected ::v-deep .at-select__selection {
    padding-left: 68px;
}

.create-withdrawal-request-modal ::v-deep .at-select__dropdown .at-select__option:hover {
    background: var(--hover-color-select);
}

.create-withdrawal-request-modal ::v-deep .at-select__dropdown .at-select__option {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
}

.create-withdrawal-request-modal ::v-deep .at-select__dropdown {
    background-color: var(--secondary-color);
}

.create-withdrawal-request-modal__address-book ::v-deep .at-select  {
    width: 46px;
    height: 46px;
    margin: 0;
    padding: 0;
    min-width: auto;
}

.create-withdrawal-request-modal__wrapper-comment ::v-deep .at-textarea__original {
    background: var(--secondary-color);
}

.create-withdrawal-request-modal__address-book ::v-deep .at-select__selection  {
    width: 46px;
    height: 46px;
    border-radius: 12px;
    border: 1px solid #0059F7;
    background: var(--secondary-color);
}

.create-withdrawal-request-modal__address-book ::v-deep .at-select__selected  {
    display: none;
}

.create-withdrawal-request-modal__address-book ::v-deep .at-select__arrow  {
    display: none;
}

.create-withdrawal-request-modal__address-book ::v-deep .at-select__dropdown {
    max-height: 410px;
    min-width: 450px;
    left: auto !important; /* костыль для позициоирования по левую сторону от кнопки */
    right: 0 !important; /* костыль для позициоирования по левую сторону от кнопки */
    margin-top: 5px;
    background-color: var(--secondary-color);
    border: 1px solid var(--border-color);
    box-shadow: 0 8px 50px rgba(10, 17, 105, 0.08);
    border-radius: 12px;
    padding: 0;
}

.create-withdrawal-request-modal__address-book ::v-deep .at-select__placeholder {
    display: none;
}

.create-withdrawal-request-modal__address-book ::v-deep .at-select__option {
    padding: 25px 30px;
}

@media screen and (max-width: 576px) {
    .create-withdrawal-request-modal__address-book ::v-deep .at-select__dropdown {
        min-width: 300px;
    }

    .create-withdrawal-request-modal__address-book ::v-deep .at-select__option {
        padding: 20px 15px;
    }
}




.create-withdrawal-request-modal ::v-deep .at-select__option--selected {
    background: var(--hover-color-select);
}

.create-withdrawal-request-modal ::v-deep .at-select__placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
}

.create-withdrawal-request-modal ::v-deep .at-select__selection {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
}

.create-withdrawal-request-modal ::v-deep .at-select__arrow {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
}

.create-withdrawal-request-modal ::v-deep .at-input__original {
    border: 1px solid var(--border-color);
    box-sizing: border-box;
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    min-height: 46px;
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    padding-left: 30px;
}

.create-withdrawal-request-modal ::v-deep .at-textarea__original {
    border: 1px solid var(--border-color);
    box-sizing: border-box;
    box-shadow: inset 0 4px 20px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    min-height: 140px;
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    padding: 15px;
}

.create-withdrawal-request-modal ::v-deep .at-modal__footer {
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 151px;
    padding-right: 60px;
    padding-left: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.create-withdrawal-request-modal ::v-deep .at-btn {
    height: 60px;
    max-width: 311px;
    border-radius: 12px;
}

.create-withdrawal-request-modal__btn ::v-deep .at-btn {
    min-width: 100%;
    height: 60px;
    max-width: 311px;
    border-radius: 12px;
}

.create-withdrawal-request-modal ::v-deep .at-btn--default {
    color: #0059F7;
    border: 1px solid #0059F7;
}

.create-withdrawal-request-modal ::v-deep .at-btn--default:hover {
    color: #0059F7;
    border: 1px solid #0059F7;
}

.create-withdrawal-request-modal ::v-deep .at-btn--default > .at-btn__text {
    color: #0059F7;
}

.create-withdrawal-request-modal ::v-deep .at-input {
    width: 88%;
}

.create-withdrawal-request-modal ::v-deep .at-btn__text {

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
}

.at-input--error ::v-deep .at-input__original {
    border-color: red;
}

@media screen and (max-width: 950px) {
    .create-withdrawal-request-modal ::v-deep .at-modal__header {
        padding-left: 30px;
        min-height: 48px;
    }

    .create-withdrawal-request-modal ::v-deep .at-input__original {
        font-size: var(--font-size-text-secondary);
        padding-left: 20px;
    }

    .create-withdrawal-request-modal ::v-deep .at-select__selection {
        font-size: var(--font-size-text-secondary);
    }

    .create-withdrawal-request-modal ::v-deep .at-modal__footer {
        padding-left: 10px;
        padding-right: 10px;
        flex-direction: column-reverse;
    }

    .create-withdrawal-request-modal ::v-deep .at-modal__body {
        padding-left: 10px;
        padding-right: 10px;
    }

    .create-withdrawal-request-modal ::v-deep .at-modal__close {
        font-size: 24px;
        color: #8F949A;
        top: 12px;
        right: 27px;
    }

    .create-withdrawal-request-modal ::v-deep .at-modal__header p, .at-modal__header .at-modal__title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
    }

    .create-withdrawal-request-modal__select-currency ::v-deep .at-select__selection {
        padding-left: 20px;
    }

    .create-withdrawal-request-modal__select-currency_selected ::v-deep .at-select__selection {
        padding-left: 52px;
    }

    .create-withdrawal-request-modal__select-address ::v-deep .at-input--error {
        border-color: #FF4949;
    }

}

.create-withdrawal-request-modal__address-btn ::v-deep .at-btn {
    max-width: 100%;
}
</style>
<style lang="scss" scoped>

.complete {
  &__title {
    font-family: var(--font-family);
    font-size: var(--font-size-h2);
    color: var(--primary-font-color);
    margin-bottom: 20px;
  }

  &__row {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    border: 1px solid var(--border-color);
    border-radius: 12px;
    padding: 20px;
    align-items: center;

  }

  &__text-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__main-text {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    color: var(--primary-font-color);
  }
}

.currency-img_preview {
  position: absolute;
  z-index: 2;
  top: 11px;
  width: 24px;
  height: 24px;
  left: 30px;
}


.create-withdrawal-request-modal {
  &__select-currency {
    position: relative;
  }


  &__btn {
    width: 100%;
  }

  &__info-container {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    gap: 30px;
    padding: 30px;
    border: 1px solid var(--border-color);
    border-radius: 12px;
  }

  .danger {
    color: #fa5d4f;;
  }

  &__row {
    margin-bottom: 16px;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--primary-font-color);
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;

    span {
      font-size: 12px;
    }

    &-special {
      color: #EB5757;
      font-family: var(--font-family);
      font-size: var(--font-size-text-caption);
      line-height: var(--line-height);
    }
  }

  &__title-end {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--primary-font-color);
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
  }

  &__title1 {
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    font-family: 'Roboto';
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    color: var(--primary-font-color);

    &.disabled {
      color: #E0E0E0;
    }
  }

  &__address-btn {
    width: calc(100% - 60px);
    margin: auto;
    margin-top: 15px;
    margin-bottom: 30px;
    background: #0059F7;
    border-radius: 12px;

    @media screen and (max-width: 576px) {
      margin-top: 20px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__wrapper-comment {
    //margin-top: -20px;

    @media screen and (max-width: 576px) {
      margin-top: 0;
    }
  }

  &__title2 {
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    font-family: 'Roboto';
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    color: var(--primary-font-color);

    &.disabled {
      color: #E0E0E0;
    }

    @media screen and (max-width: 576px) {
      font-size: 14px;

      span {
        white-space: nowrap;
      }
    }
  }

  &__address-book {
    width: 46px;
    height: 46px;
    margin-left: 20px;
    border: 1px solid #0059F7;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: var(--secondary-color);

    svg {
      position: absolute;
      z-index: 1;
      pointer-events: none;
    }

    & .address-option {
      & .info-block {
        width: 100%;
        display: flex;
        justify-content: space-between;

        & .address {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 23px;
          color: var(--primary-font-color);

          @media screen and (max-width: 576px) {
            font-size: 16px;
          }
        }

        & .info {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 23px;
          color: var(--primary-font-color);
          display: flex;

          @media screen and (max-width: 576px) {
            font-size: 16px;
          }
        }
      }

      & .info-icon {
        width: 24px;
        height: 24px;
        margin-left: 15px;

        @media screen and (max-width: 576px) {
          width: 20px;
          height: 20px;
          margin-left: 17px;
        }
      }
    }
  }

  &__select-address {
    display: flex;
  }

  &__title2-sub {
    color: var(--secondary-font-color);

    &.disabled {
      color: #E0E0E0;
    }
  }

  &__currency {
    display: flex;
    align-items: center;
  }

  &__currency-img {
    margin-left: 8px;
    margin-right: 4px;
    width: 33px;
    height: 33px;
  }

  &__withdrawal-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__full-width-btn {
    width: 100%;
  }

  &__table-header {
    margin-left: 0;
    margin-right: 0;
    padding: 4px 12px;
    background-color: $gray;
    border: 1px solid $gray2;
  }

  &__add-address {
    margin-top: 12px;
  }

  .address-option {
    display: flex;

    justify-content: flex-start;
    align-items: center;


    .info-block {
      padding-left: 12px;

      .address {
      }

      .info {
        span {
          color: grey;
        }
      }
    }
  }

  .amount-input {
    display: flex;
    align-items: center;
    align-content: center;
    border: 1px solid var(--border-color);
    box-sizing: border-box;
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    width: 100%;
    color: var(--primary-font-color);
    font-size: 12px;
    background-color: var(--secondary-color);
    outline: none;
    min-height: 46px;
    padding-left: 18px;

    &:hover, &:focus, &:active {
      border-color: #79A1EB;
    }

    &.error {
      border-color: #FF4949;
    }

    .input {
      padding: 6px 12px;
      background-color: transparent;
      outline: none;
      border: 0;

      width: calc(100% - 70px);
    }

    .max-button {
      display: block;

      text-align: center;

      width: 70px;

      border-left: 1px solid #79A1EB;
    }
  }
}

@media screen and (max-width: 950px) {
  .currency-img_preview {
    left: 18px;
  }

  .create-withdrawal-request-modal {
    .amount-input {
      padding-left: 8px;
    }

    &__info-container {
      padding: 14px;
      margin-top: 30px;
    }

    &__title1 {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }
  }

}
.address-block {
  .currency-img {
    margin-right: 10px;
    width: 33px;
    height: 33px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__description {
    display: flex;
    align-items: center;
  }
  &__title {
    font-size: 20px;
    color: var(--primary-font-color);
  }
}
.secondary-font {
  color: var(--secondary-font-color);
}
.df-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}
::v-deep .at-select--disabled .at-select__selected {
  color: var(--primary-font-color) !important;
}
::v-deep .at-select__arrow {
  display: none !important;
}
</style>
