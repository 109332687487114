<template>
    <div class="transactions-filter-by-date">
        <p class="transactions-filter-by-date__title">
            Период
        </p>
        <DatePicker
            class="transactions-filter-by-date__date"
            type="date"
            :showSecond="false"
            range
            confirm
            confirm-text="Выбрать даты"
            value-type="date"
            v-model="dates"
            @confirm="selectDates"
            @clear="clearDates"
        />
    </div>
</template>

<script>

import Moment from "moment";
import { mapActions } from 'vuex';

import DatePicker from "vue2-datepicker";

export default {
    name: 'RollingReserveFilterByDate',
    components: {
        DatePicker,
    },
    data () {
        return {
            dates: [],
        };
    },
    beforeMount() {
        this.setDateFromUrl();
    },
    methods: {
        ...mapActions('rollingReserve', [
            'fetchRollingReserves',
        ]),
        setDateFromUrl () {
            const dateFromUrl = this.$route.query.date;

            if (!dateFromUrl) {
                return false;
            }

            this.dates = dateFromUrl.split('to').map(date => new Date(date));
        },
        selectDates () {
            const {
                $route,
                $router,
                dates,
                fetchRollingReserves,
            } = this;
            const dateFrom = Moment(dates[0]).format();
            const dateTo = Moment(dates[1])
                .add(23, 'hours')
                .add(59, 'minutes')
                .add(59, 'seconds')
                .format()
            ;

            fetchRollingReserves({
                route: $route,
                router: $router,
                date: [dateFrom, dateTo],
                excludeParams: ['page'],
            });
        },
        clearDates () {
            const {
                $route,
                $router,
                fetchRollingReserves,
            } = this;

            fetchRollingReserves({
                route: $route,
                router: $router,
                date: [],
                excludeParams: ['page'],
            });
        },
    },
};

</script>

<style lang="scss" scoped>

    .transactions-filter-by-date {
        &__title {
            margin-bottom: 8px;
            font-weight: 700;
        }

        &__date::v-deep.mx-datepicker-range {
            width: 100%;
        }

        &__date ::v-deep .mx-input {
            height: 28px;
        }
    }

</style>
