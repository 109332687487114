<template>
    <footer>
        <div class="wrapper"></div>
    </footer>
</template>

<script>

export default {
    name: 'VFooter',
};

</script>