<template>
<button v-bind="$attrs" v-on="$listeners" class="new-front-button" ><slot /></button>
</template>

<script>
  export default {
    name: "NButton",
  }
</script>

<style lang="scss" scoped>
.new-front-button {
    font-weight: 500;
    font-size: 18px;
    background: #744DB2;
    border-radius: 10px;
    line-height: 28px;
    color: #ffffff;
    padding: 10px;
    width: 100%;
    outline: none;
    border: none;
    margin-top: 30px;
  cursor: pointer;
}
</style>
