import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!) {
        getOrganizationRoles(organizationId: $organizationId) {
            id
            name
            comment
        }
    }
`;

