import getAuthorizationHistoryQuery from "/public/graphQl/queries/getAuthorizationHistory";

export const getAuthorizationHistory = async ({dispatch, commit, rootGetters}, payload) => {
    let authData;
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const variables = {
        organizationId,
        ...payload
    }

    try {
        commit('app/toggleLoading', null, {root: true});
        authData = await dispatch('app/request', {
            type: 'query',
            gql: getAuthorizationHistoryQuery,
            variables
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (authData.message) {
        throw authData.message;
    }

    if (authData.errors) {
        throw authData.errors;
    }

    const {
        data: {organizationAuthorizationHistory},
    } = authData;
    commit('setHistory', organizationAuthorizationHistory)
    return organizationAuthorizationHistory;
};
