<template>
<div class="webhook-info">
<!--    <h1 class="webhook-info__title">-->
<!--        {{ $t('webhook-info.title') }} -->
<!--    </h1>-->
    <h3 class="webhook-info__subtitle">{{ moment(webhook.createdAt).format('DD/MM/YY HH:mm') }} <span class="title-rightBlock"><new-spinner v-if="isLoading" class="spinner" /> <span v-else  :class="['webhook-info__statusLabel',  `webhook-info__statusLabel--${webhookStatus}`]">{{ webhook.responseCode }}</span></span></h3>
    <router-link class="webhook-info__return" to="/webhooks">
        <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.07812 0.583127C6.81906 0.454574 6.50723 0.476409 6.27015 0.639703L0.825704 4.3897C0.621307 4.53048 0.5 4.75779 0.5 5C0.5 5.24221 0.621307 5.46952 0.825704 5.6103L6.27015 9.3603C6.50723 9.52359 6.81906 9.54543 7.07812 9.41687C7.33717 9.28832 7.5 9.03094 7.5 8.75V1.25C7.5 0.969062 7.33717 0.71168 7.07812 0.583127Z" fill="#6750A4" stroke="#6750A4" stroke-linejoin="round"/>
        </svg>
        {{ $t('orphan.return') }}
    </router-link>
    <container>
        <div class="webhook-info__header">
            <div class="webhook-info__header-left"></div>
            <div class="webhook-info__header-right">
                <base-button :disabled="isLoading || !isSendAgainShow" @click="handleSendWebhook" type="primary" :label="$t('webhook-info.send-hook')" />
            </div>
        </div>
        <div class="webhook-info__content">
            <div class="webhook-info__left">
                <div class="webhook-info__listItem">
                    <div class="webhook-info__listItem-title">ID: </div>
                    <div class="webhook-info__listItem-value--black">
                        {{ webhook.id | getPrettyAddress }}
                        <copy :value="webhook.id" />
                    </div>
                </div>
                <div class="webhook-info__listItem event-block">
                    <div class="webhook-info__listItem-title">{{ $t('webhooks.event') }}: </div>
                    <div class="webhook-info__listItem-value">
                        <span class="webhook-info__listItem-value--black">{{ eventLabel + ',' }}&ensp;</span><span class="text-underline">ID: {{ webhook.eventId | getPrettyAddress }}</span>
                    </div>
                </div>
                <div class="webhook-info__listItem">
                    <div class="webhook-info__listItem-title">{{ $t('webhooks.event-date') }}: </div>
                    <div class="webhook-info__listItem-value--black">
                        {{ moment(webhook.sentAt).format('DD/MM/YY HH:mm') }}
                    </div>
                </div>
                <div class="webhook-info__listItem">
                    <div class="webhook-info__listItem-title">URL: </div>
                    <div class="webhook-info__listItem-value text-underline">
                        <copy :value="webhook.requestUrl" />
                        <a :href="webhook.requestUrl" target="_blank">
                            {{ webhook.requestUrl | getPrettyUrl }}
                        </a>
                    </div>
                </div>
                <div v-if="apiKeyAlias" class="webhook-info__listItem">
                    <div class="webhook-info__listItem-title">{{ $t('webhook-info.api-key-alias') }}: </div>
                    <div class="webhook-info__listItem-value--black">
                        {{ apiKeyAlias }}
                    </div>
                </div>
                <div v-if="signature" class="webhook-info__listItem">
                    <div class="webhook-info__listItem-title">{{ $t('webhook-info.signature') }}: </div>
                    <div class="webhook-info__listItem-value--black">
                        {{ signature | getPrettyAddress }}
                        <copy :value="signature"/>
                    </div>
                </div>
            </div>
            <div class="webhook-info__right">
                <div class="webhook-info__right-item">
                    <div class="webhook-info__right-label">
                        <span>{{ $t('webhook-info.request-headers') }}</span> <copy :value="JSON.stringify(requestHeaders)" />
                    </div>
                    <div class="webhook-info__right-content">
                        <pre>{{ requestHeaders }}</pre>
                    </div>
                </div>
                <div class="webhook-info__right-item">
                    <div class="webhook-info__right-label">
                        <span>{{ $t('webhook-info.request-body') }}</span> <copy :value="JSON.stringify(requestBody)" />
                    </div>
                    <div class="webhook-info__right-content" >
                        <pre>{{ requestBody }}</pre>
                    </div>
                </div>
                <div class="webhook-info__right-item">
                    <div class="webhook-info__right-label">
                        <span>{{ $t('webhook-info.response-body') }}</span> <copy :value="JSON.stringify(responseBody)" />
                    </div>
                    <div class="webhook-info__right-content" >
                        <pre>{{ responseBody }}</pre>
                    </div>
                </div>
            </div>
        </div>
    </container>
</div>
</template>
<script>
    import Container from "@/public/components/common/uiKit/Container.vue";
    import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
    import Copy from "@/public/components/common/copy.vue";
    import {mapActions, mapGetters, mapState} from "vuex";
    import moment from "moment";

    export default  {
        name: 'WebhookInfo',
        rulesAlias: 'webhooks',
        components: {
            Copy,
            BaseButton,
            Container,
            NewSpinner: () => import (/* webpackChunkName: "Spinner" */ "/public/components/common/uiKit/spinner/index.vue"),
        },
        created() {
            const isAllowed = this.userRoleRules[this.$options.rulesAlias].isAllowed;
            if (!isAllowed) {
                this.$Message.error(this.$t('rules.not_allowed'));
                this.$router.push('/merchant/dashboard');
            }
          const id = this.$route.params.id
          this.getWebhook(id)
        },
        methods: {
            ...mapActions('webhooks', ['getWebhook', 'sendWebhook']),
            handleSendWebhook () {
                const id = this.$route.params.id
                this.sendWebhook(id)
            }
        },
        computed: {
            ...mapState('webhooks', ['webhook']),
            ...mapState('app', ['isLoading']),
            ...mapGetters('organizations', ['userRoleRules']),
            moment() {
                return moment
            },
            eventLabel () {
                if (!this.webhook?.event) return ''

              return this.$t(`webhooks.${this.webhook?.event.toLowerCase()}`)
            },
            webhookStatus () {
                if (!this.webhook.responseStatus) {
                    return ''
                }
               return  this.webhook.responseStatus.toLowerCase()
            },
            isSendAgainShow () {
                return this.userRoleRules[this.$options.rulesAlias].entities.send_webhook_again
            },
            requestHeaders() {
                try {
                    return JSON.stringify(JSON.parse(this.webhook.requestHeaders), null, 2);
                } catch (e) {
                    return this.webhook.requestHeaders;
                }
            },
            requestBody () {
                try {
                    return JSON.stringify(JSON.parse(this.webhook.requestBody), null, 2);
                } catch (e) {
                    return this.webhook.requestBody;
                }
            },
            responseBody () {
                try {
                    const stringifiesJson = JSON.stringify(this.webhook.responseBody, null, 2);
                    const parsedJson = JSON.parse(stringifiesJson)
                    parsedJson.body = JSON.stringify(JSON.parse(parsedJson.body), null, 2);
                    parsedJson.headers = JSON.stringify(JSON.parse(parsedJson.headers), null, 2);
                    return JSON.stringify(parsedJson, null, 2);
                } catch (e) {
                    return this.webhook.responseBody;
                }
                // return JSON.stringify(this.webhook.responseBody)
            },
            signature () {
                if (!this.webhook.signature) {
                    return false
                }
                return this.webhook.signature
            },
            apiKeyAlias () {
                if (!this.webhook.apiKeyAlias) {
                    return false
                }
                return this.webhook.apiKeyAlias
            }
        },
    }
</script>
<style scoped lang="scss">
.webhook-info {
  padding: 0 30px 30px 30px;
  margin-top: -30px;
  font-family: $Gilroy;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  height: 100%;
  &::v-deep {
    .container-new {
      padding-bottom: 0;
    }
  }
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $p30;
        &-right {
            width: 300px;
        }
    }
    &__content {
        display: grid;
        grid-template-columns: 35% 65%;
        gap: $p10;
    }
    &__left {
        height: 100%;
        overflow: auto;
    }
    &__right {
        padding: 0 $p30;
        &-item {
            margin-bottom: $p20;
        }
        &-label {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 16px;
            color: var(--new-front-secondary-font-color);
            margin-bottom: $p20;
        }
        &-content {
            width: 100%;
            background: var(--new-front-bg-primary);
            padding: $p20;
            color: var(--new-front-primary-font-color);
            border-bottom: 1px solid var(--new-front-divider-color);
            max-width: 95vw;
            overflow: hidden;
            > p, pre {
                text-wrap: wrap;
                word-break: break-all;
                inline-size: 100%;
                overflow-wrap: anywhere;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: pre-line;
            }
        }
    }
    &__listItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        //padding: 0 $p15;
        min-height: 60px;
        border-bottom: 1px var(--new-front-divider-color) solid;
        &-title {
            font-weight: 500;
            font-size: 16px;
            color: var(--new-front-secondary-font-color);
        }
        &-value {
            display: flex;
            align-items: center;
            color: var(--new-front-purple);
            font-size: $h4;
            font-weight: 500;
            &--black {
                display: flex;
                align-items: center;
                color: var(--new-front-primary-font-color);
                font-weight: 500;
                font-size: $h4;
            }
        }
    }
  &__statusLabel {
    padding: 4px $p15;
    background: var(--new-front-status-processed);;
    color: var(--new-front-status-text-processed);
    border-radius: 10px;
    font-size: $h4;
    font-weight: 500;
      margin-left: $p10;
    &--error {
      background: var(--new-front-status-error);
      color: var(--new-front-status-text-error);
    }
    &--pending {
      background: var(--new-front-status-pending);
      color: var(--new-front-status-text-pending);
    }
    &--processed {
      background: var(--new-front-status-processed);
      color: var(--new-front-status-text-processed);
    }
  }
  &__title {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: $h2;
    color: var(--new-front-text-primary-bg-modal-primary);
    margin-bottom: $p5;
    line-height: 31px;
    > svg {
      margin-left: 5px;
    }
  }
  &__subtitle {
    display: flex;
    align-items: center;
    font-size: $h4;
    font-weight: 500;
    color: var(--new-front-secondary-font-color);
      margin-bottom: $p30;
  }
    &__return {
        margin-top: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--new-front-bg-btn-primary-bg-modal-primary);
        font-size: $h4;
        font-weight: 500;
        margin-bottom: $p20;
    }
}
.text-underline {
    text-decoration: underline;
}
.event-block {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 5px 0;
}
.title-rightBlock {
    position: relative;
}
.spinner {
    position: absolute !important;
    transform: scale(.4);
    top: -95px;
    right: -190px;
}
@media (max-width: 1180px) {
    .event-block {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 5px 0;
    }
  .webhook-info {

    &::v-deep {
      .container-new {
        width: 100%;
        //margin: 0 $p10;
      }
    }
  }
    pre {
        max-width: 100%;
    }
}
@media (max-width: 960px) {
    .event-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
    }
  .webhook-info {
      .webhook-info__subtitle {
          //padding-left: 10px;
      }
      .webhook-info__return {
          //padding-left: 10px;
      }
      &__content {
          font-size: 13px;
          grid-template-columns: 1fr;
          padding: 0;
      }
      .webhook-info__right {
          padding: 5px;
          margin-top: $p30;
      }
    &::v-deep {
      .container-new {
        padding: 10px !important;
      }
    }
  }
    .webhook-info__listItem-title {
        font-size: 13px;
    }
    .webhook-info__listItem-value--black {
        font-size: 13px;
    }
    .webhook-info__listItem-value {
        font-size: 13px;
    }
}
@media (max-width: 576px) {
  .webhook-info {
    padding: 0 10px 10px 10px;
    margin-top: -10px;
  }
}
@media (max-width: 320px) {
    .webhook-info {
        padding: 0 0;
        &__content {
            grid-template-columns: 1fr;
        }
        .webhook-info__right {
            padding: 0px;
        }
        .webhook-info__title {
            padding-left: 10px;
        }
        .webhook-info__subtitle {
            padding-left: 10px;
        }
        .webhook-info__return {
            padding-left: 10px;
        }

        &::v-deep {
            .container-new {
                padding: 20px !important;
            }
        }
    }
}
a {
    color: var(--new-front-purple) !important;
}
</style>
