<template>
  <div class="balance table-row row">
    <div class="balance__amount col">
      {{ index }}
    </div>
    <div class="balance__amount col">
      {{ type }}
    </div>
    <div class="balance__currency col">
      {{amount}}
    </div>
    <div class="balance__currency col">
      {{ date }}
    </div>
    <div class="balance__currency col">
      {{ balance }}
    </div>
    <div class="balance__currency col">
      {{ orderId }}
    </div>
  </div>
</template>

<script>

import { mapState} from 'vuex';


export default {
    name: 'Transaction',
    components: {

    },
    props: {
        
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState('balances', {
            rollingReserveBalances: state => state.rollingReserve,
        }),
        type(){
            return this.data?.type;
        },
        amount(){
            return this.data?.amount;
        },
        balance(){
            return this.data?.balance;
        },
        date(){
            return this.data?.date;
        },
        orderId(){
            return this.data?.orderId;
        },
        index(){
            return this.data?.index;

        },
    },
    methods: {
    },
};

</script>

<style lang="scss" scoped>

.balance {

  &__currency {
    display: flex;
    align-items: center;
  }

  &__currency-img {
    margin-right: 12px;
    width: 24px;
    height: 24px;
  }

  &__amount {
    display: flex;
    align-items: center;
  }

  &__currency-name {
    margin-left: 6px;
    color: #778899;
  }
}

</style>
