<template>
<div class="filter-toggler">
    <div
      v-show="isFiltered"
      class="marker"
    />
    <div
      class="filter-toggler__trigger"
      @click="toggleFilter"
    >
        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.5 2C11.6716 2 11 2.67157 11 3.5C11 4.32843 11.6716 5 12.5 5C13.3284 5 14 4.32843 14 3.5C14 2.67157 13.3284 2 12.5 2ZM9.2326 3C9.70821 3 10.1003 2.65031 10.3404 2.23976C10.7741 1.49816 11.5789 1 12.5 1C13.4211 1 14.2259 1.49816 14.6596 2.23976C14.8997 2.65031 15.2918 3 15.7674 3H16.5C16.7761 3 17 3.22386 17 3.5C17 3.77614 16.7761 4 16.5 4H15.7674C15.2918 4 14.8997 4.34969 14.6596 4.76024C14.2259 5.50184 13.4211 6 12.5 6C11.5789 6 10.7741 5.50184 10.3404 4.76024C10.1003 4.34969 9.70821 4 9.2326 4H1.5C1.22386 4 1 3.77614 1 3.5C1 3.22386 1.22386 3 1.5 3H9.2326ZM5.5 7C4.67157 7 4 7.67157 4 8.5C4 9.32843 4.67157 10 5.5 10C6.32843 10 7 9.32843 7 8.5C7 7.67157 6.32843 7 5.5 7ZM2.2326 8C2.70821 8 3.10031 7.65031 3.34041 7.23976C3.77411 6.49816 4.57888 6 5.5 6C6.42112 6 7.22589 6.49816 7.65959 7.23976C7.89969 7.65031 8.29179 8 8.76739 8H16.5C16.7761 8 17 8.22386 17 8.5C17 8.77614 16.7761 9 16.5 9H8.7674C8.29179 9 7.89969 9.34969 7.65959 9.76023C7.22589 10.5018 6.42112 11 5.5 11C4.57888 11 3.77411 10.5018 3.34041 9.76024C3.10031 9.34969 2.70821 9 2.2326 9H1.5C1.22386 9 1 8.77614 1 8.5C1 8.22386 1.22386 8 1.5 8H2.2326ZM12.5 12C11.6716 12 11 12.6716 11 13.5C11 14.3284 11.6716 15 12.5 15C13.3284 15 14 14.3284 14 13.5C14 12.6716 13.3284 12 12.5 12ZM9.2326 13C9.70821 13 10.1003 12.6503 10.3404 12.2398C10.7741 11.4982 11.5789 11 12.5 11C13.4211 11 14.2259 11.4982 14.6596 12.2398C14.8997 12.6503 15.2918 13 15.7674 13H16.5C16.7761 13 17 13.2239 17 13.5C17 13.7761 16.7761 14 16.5 14H15.7674C15.2918 14 14.8997 14.3497 14.6596 14.7602C14.2259 15.5018 13.4211 16 12.5 16C11.5789 16 10.7741 15.5018 10.3404 14.7602C10.1003 14.3497 9.70821 14 9.2326 14H1.5C1.22386 14 1 13.7761 1 13.5C1 13.2239 1.22386 13 1.5 13H9.2326Z"
              fill="var(--new-front-text-link-bg-modal-secondary)"
              stroke="var(--new-front-text-link-bg-modal-secondary)"
              stroke-width="0.8"
            />
        </svg>
    </div>

    <transition name="slide-fade">
        <div v-show="isFilterOpened" class="filter-toggler__content" v-click-outside="closeModal">
            <slot />
            <base-button @click="confirm" type="primary" :label="$t('bridge.modals.execute.confirm')" />
            <base-button @click="$emit('reset')" type="secondary" :label="$t('common-components.filter-toggler.btn-reset-label')"/>
        </div>
    </transition>
</div>
</template>
<script>
    import {mapState} from "vuex";
    import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
    import vClickOutside from 'v-click-outside'
    export default {
        name: "FilterMobile",
        components: {BaseButton},
        directives: {
            clickOutside: vClickOutside.directive
        },
        props: {
          closeOutside: {
            type: Boolean,
            default: true
          },
          isFiltered: {
            type: Boolean,
            default: true
          }
        },
        data () {
          return {
              isFilterOpened: false
          }
        },
        computed: {
            ...mapState(['themeStatus']),
        },
        methods: {
            emitClick () {
                this.$emit('click')
            },
            toggleFilter (event) {
                this.isFilterOpened = !this.isFilterOpened
                if (!!this.isFilterOpened) this.$emit('opened')
            },
            confirm () {
                this.$emit('confirm')
                this.isFilterOpened = false
            },
            closeModal (event) {
                // if (event?._prevClass === 'settings__trigger' || event?.parentElement._prevClass === 'settings__trigger') return;
                if (!this.closeOutside) return
                if (event && (event.target.tagName === 'svg' || event.target.tagName === 'path' || event.target.className === 'settings__trigger')) return
                this.isModalOpen = false
            },
        }
    }
</script>
<style lang="scss">
.filter-toggler {
  position: relative;
    z-index: 4;
    &__trigger {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      background: transparent!important;
      border: 1px solid var(--new-front-border-btn-primary-bg-primary);
      border-radius: 10px;
      transition: background .2s ease;
      cursor: pointer;

      &:hover {
        background: var(--new-front-bg-btn-secondary-bg-modal-primary-hover);
      }
    }
    &__content {
      position: absolute;
      right: 0;
      top: 58px;
      padding: $p20;
      background: var(--new-front-main-bg);
      border: 1px solid var(--new-front-input-border);
      border-radius: 10px;
      min-width: 100px;
      min-height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .marker {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(45%, -50%);

      width: 16px;
      height: 16px;
      background: #FFFFFF;
      border-radius: 50%;;
      border: 5px solid #744DB2
    }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
    transition: all .3s ease;
}
.slide-fade-leave-active {
    transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(10px);
    opacity: 0;
}
</style>
