export const mutations = {
    isInit(state, status) {
        state.isInit = status;
    },

    addListCurrenciesAddress(state, payload) {
        state.listCurrenciesAddress = payload;
    },

    setTotalPage(state, payload) {
        state.count = payload;
    },

    count: (state, payload) => {
        state.count = payload;
    },
    pages: (state, payload) => {
        state.pages = payload;
    },

    setNetworks(state, payload) {
        state.getNetworks = payload;
    },
};