<template>
<div>
<!--<BaseModal-->
<!--    class="remove-api-keys-modal"-->
<!--    width="100%"-->
<!--    :value="isOpen"-->
<!--    :title="title"-->
<!--    :show-close="true"-->
<!--    :show-head="!isLoading"-->
<!--    :show-footer="!isLoading"-->
<!--    @on-confirm="handleRemoveRequest"-->
<!--    @on-cancel="handleCancelCreate"-->
<!--&gt;-->
  <template v-if="!isLoading">
    <template v-if="!isSuccess">

      <div class="remove-api-keys-modal__text-container"><p class="remove-api-keys-modal__message">
        {{ $t('upload-avatar.upload_description') }}
      </p>

        <p class="remove-api-keys-modal__alias">{{ $t('upload-avatar.max_file_size') }}</p>
        <p class="remove-api-keys-modal__error" v-if="isError">{{ errorMessage }}</p></div>
    </template>
    <template v-else>
      <p class="remove-api-keys-modal__message">
        {{ $t('api_keys.successful_deletion') }}
      </p>
    </template>
  </template>
  <LoadingSpinner
      v-else
      :title="deleting_an_api_key"
  />

<!--  <template #footer>-->
<!--    <template v-if="!isSuccess">-->
<!--      <AtButton class="remove-api-keys-modal__full-width-btn"-->
<!--                type="primary">-->
<!--        {{ $t('upload-avatar.choose_file') }}-->
<!--        <input-->
<!--            class="remove-api-keys-modal__image-upload"-->
<!--            :accept="allowedMimes"-->
<!--            type="file"-->
<!--            @change="handleUploadImage"/>-->
<!--      </AtButton>-->
<!--    </template>-->
<!--    <AtButton-->
<!--        v-else-->
<!--        class="remove-api-keys-modal__full-width-btn"-->
<!--        type="primary"-->
<!--        @click="handleUploadImage"-->
<!--    >-->
<!--      {{ $t('api_keys.close') }}-->
<!--    </AtButton>-->
<!--  </template>-->
</div>
</template>

<script>

import {mapState, mapGetters, mapActions} from 'vuex';
import AtModal from 'at-ui/src/components/modal';
import LoadingSpinner from "/public/components/elements/LoadingSpinner";
import AtButton from 'at-ui/src/components/button';
import BaseModal from "../BaseModal";

export default {
    name: 'UploadImageAvatar',
    components: {
        BaseModal,
        AtModal,
        LoadingSpinner,
        AtButton,
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        modalPayload: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            isLoading: false,
            isSuccess: false,
            isError: false,
            errorMessage: '',
        };
    },
    computed: {
        // ...mapState('app', {
        //     modalPayload: state => state.modal.payload,
        // }),
        ...mapGetters('app', [
            'isOpenModal',
        ]),
        // isOpen() {
        //     const {
        //         $options: {
        //             name,
        //         },
        //         isOpenModal,
        //     } = this;
        //
        //     return isOpenModal(name);
        // },
        title() {
            return this.$t('upload-avatar.title');
        },
        allowedMimes() {
            const {modalPayload} = this;
            return modalPayload?.allowedMimes.join(',') ?? null;
        },
        alias() {
            return this.modalPayload?.alias ?? '';
        },
        hideFooter() {
            const {
                isLoading,
                isSuccess,
            } = this;

            return isLoading || isSuccess;
        },
        deleting_an_api_key() {
            return this.$t('api_keys .deleting_an_api_key');
        },
    },
    methods: {
        ...mapActions('app', [
            'closeModal',
        ]),
        ...mapActions('donations', [
            'removePreCheckoutPage',
            'fetchPreCheckoutPages',
        ]),
        clearAllData() {
            this.isLoading = false;
            this.isSuccess = false;
        },
        handleUploadImage(value) {
            const {modalPayload} = this;
            const fileSize = value.target.files[0].size;
            const fileType = value.target.files[0].type;
            this.errorMessage = '';
            if (fileSize < modalPayload.minImageSize) {
                this.isError = true;
                this.errorMessage = 'Слишком маленький файл';
            } else if (fileSize > modalPayload.maxImageSize) {
                this.isError = true;
                this.errorMessage = 'Слишком большой файл';
            } else {
                this.$emit('uploadImage', value);
                // this.closeModal();
                this.$emit('closeModal');
            }
        },

        handleRemoveRequest() {
            const {
                $route,
                $router,
                pageNumber,
                modalPayload: {
                    id,
                },
                removePreCheckoutPage,
                fetchPreCheckoutPages,
            } = this;

            this.isLoading = true;
            removePreCheckoutPage({pageId: id})
                .then(status => {
                    this.isSuccess = status;
                    fetchPreCheckoutPages({
                        route: $route,
                        router: $router,
                        page: pageNumber,
                    });
                })
                .finally(() => {
                    // this.closeModal();
                    this.$emit('closeModal');
                    this.isLoading = false;
                });
        },
        handleCancelCreate() {
            const {
                closeModal,
                clearAllData,
            } = this;

            // closeModal();
            this.$emit('closeModal');

            setTimeout(() => {
                clearAllData();
            }, 500);

        },
    },
};

</script>

<style lang="scss" scoped>

.remove-api-keys-modal {
  color: var(--primary-font-color);
  &__full-width-btn {
    width: 100%;
    position: relative;
  }

  &__text-container {
    color: var(--primary-font-color);
    min-height: 130px;
  }

  &__error {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    font-weight: 300;
    color: #EB5757;
  }

  &__image-upload {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
  }

  &__alias {
    color: var(--primary-font-color);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-top: 25px;
  }

  //::v-deep .at-modal__footer {
  //  padding-left: 16px;
  //  padding-right: 16px;
  //}
}
p {
  color: var(--primary-font-color) !important;
}
</style>
