<script>
  import {mapState} from "vuex";
  import {STEP_CONSTRUCTOR, STEP_PAY_SELECT, STEP_START_PAGE} from "@/public/pages/widgets/constants";

  export default {
    name: "widgetsManageSteps",
    computed: {
      ...mapState('widgetsManage', ['widget', 'currentStep']),
      activeSecondStep () {
       return this.currentStep === STEP_PAY_SELECT || this.currentStep === STEP_CONSTRUCTOR
      },
      activeThirdStep () {
        return this.currentStep === STEP_CONSTRUCTOR
      }
    }
  }
</script>

<template>
<div class="steps">
  <div class="step active">1</div>
  <div class="separator"></div>
  <div :class="['step', { active: activeSecondStep}]">2</div>
  <div class="separator"></div>
  <div :class="['step', {active: activeThirdStep}]">3</div>
</div>
</template>

<style scoped lang="scss">
.steps {
  border-top: 1px solid var(--border-devider-primary, #353537);
  border-bottom: 1px solid var(--border-devider-primary, #353537);
  background: var(--bg-background-input-primary, #2A2A2A);
  display: grid;
  grid-template-columns: 54px 1fr 54px 1fr 54px;
  align-items: center;
  gap: 10px;
  padding: 15px 30px 15px 30px;
}
.separator {
  height: 1px;
  width: 100%;
  border-bottom: 2.5px dashed var(--bg-background-primary-purple);
}
.step {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  border: 2.5px solid var(--bg-background-primary-purple, #6750A4);
  color: var(--text-text-primary-accent, #6750A4);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  &.active {
    background: var(--bg-background-primary-purple, #6750A4);
    color: #fff;
  }
}
</style>
