import createIFrameWidgetQuery from "../../../../graphQl/mutations/createIframeWidget";
import {PAYIN_CRYPTO_TYPE_INVOICE} from "@/public/pages/widgets/constants";


function removeEmptyFields(obj) {
    // Создаем новый объект, чтобы не изменять исходный
    const cleanedObject = {};

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            // Проверяем, что значение не пустая строка
            if (obj[key] !== '') {
                cleanedObject[key] = obj[key];
            }
        }
    }

    return cleanedObject;
}

export const createWidget = async ({ rootState, dispatch, rootGetters, commit }, payload) => {
    let apiKeysData;

    const widget = rootState.widgetsManage.widget

    console.log(widget, 'create widget')

    const clearedWidget = removeEmptyFields(widget)

    const payoutCryptoCurrenciesFrom = widget.payoutCryptoCurrenciesFrom.length ? widget.payoutCryptoCurrenciesFrom.map(item => item.currency) : []
    const payinCryptoInvoiceCurrencies = widget.payinCryptoInvoiceCurrencies.length ? widget.payinCryptoInvoiceCurrencies.map(item => item.currency) : []

    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    const variables = {
        organizationId,
        widget: {
            ...clearedWidget,
            payinCryptoType: widget.payinCryptoType ? widget.payinCryptoType : PAYIN_CRYPTO_TYPE_INVOICE,
            payoutCryptoCurrenciesFrom,
            payinCryptoInvoiceCurrencies,
        },
    };

    commit('setLoading', true);

    try {
        apiKeysData = await dispatch('app/request', {
            type: 'mutation',
            gql: createIFrameWidgetQuery,
            variables,
        }, { root: true });
    } catch (error) {
        return error;
    } finally {
        commit('setLoading', false);
    }

    const {
        data: { createIFrameWidget },
    } = apiKeysData;

    if (!createIFrameWidget.success) {
        return { success: false }
    }

    commit('setWidget', createIFrameWidget.result);
    commit('setNextStep')

    return createIFrameWidget;
};
