<template>
  <base-select
    v-bind="$attrs"
    v-model="valueModel"
    :label="label"
    :placeholder="$t('addressRisks.placeholder')"
    :list="list"
    :with-search="false"
    class="advanced_balances__network-select"
    uniq-key="value"
    clearable
    @closed="search = undefined"
  >
    <template #selected="data">
      <item
        v-bind="data"
      />
    </template>
    <template #info="data">
      <item
        v-bind="data"
        height="22px"
      />
    </template>
  </base-select>
</template>

<script>
    import { mapState } from "vuex";
    import placeholder from "lodash/fp/placeholder";

    export default {
        name: "AddressRisksSelect",
        components: {
            Search: () => import(/* webpackChunkName: "/public/components/elements/advanced-balances/NetworkAndCurrenciesList/Search" */ './Search'),
            BaseSelect: () => import(/* webpackChunkName: "/public/components/elements/advance-balances/bh-select" */ '/public/components/elements/advance-balances/bh-select'),
            Item: () => import(/* webpackChunkName: "/public/components/elements/advanced-balances/NetworkAndCurrenciesList/Item" */ './Item'),
        },
        data() {
            return {
                search: undefined
            }
        },
        props: {
            value: {
                type: [ String, Object ],
                default: () => {}
            },
            withSearch: {
                type: Boolean,
                default: false
            },
            label: {
                type: String,
                default: ''
            },
        },
        computed: {
            ...mapState({
                currentCurrencyInfo: state => state.currency,
            }),
          list () {
              return [
                {
                  title: this.$t('addressRisks.white'),
                  value: 'white',
                },
                {
                  title: this.$t('addressRisks.green'),
                  value: 'green',
                },
                {
                  title: this.$t('addressRisks.yellow'),
                  value: 'yellow',
                },
                {
                  title: this.$t('addressRisks.red'),
                  value: 'red',
                },
                {
                  title: this.$t('addressRisks.black'),
                  value: 'black',
                },
                {
                  title: this.$t('addressRisks.unchecked'),
                  value: null,
                }
              ]
          },
            valueModel: {
                get({ value }) {
                    return value
                },
                set(v) {
                    this.$emit('input', v)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.advanced_balances {
  &__network-select {
    margin: 20px 0;

    .search {
      margin-bottom: 15px;
    }
  }
}
.advanced_balances__network-select {
    margin: 0 !important;
    ::v-deep .sublabel {
        display: none !important;
    }
    ::v-deep  .label {
        color: var(--new-front-input-label-font-color)!important;
    }
    ::v-deep .info {
        width: 100%;
    }
}
::v-deep .advanced-balances-select-wrapper {
    .sublabel {
        display: none;
    }
}
::v-deep {
  .advanced-balances-select-wrapper .input-wrapper .options-list .list__item {
    padding: 10px 20px !important;
  }
}
</style>
