<script>
  import BhSelectNew from '/public/components/elements/advance-balances/selects/Base'
  import {mapMutations, mapState} from "vuex";
  import {CURRENCIES_LIST} from "@/public/components/elements/dashboard/constants";
  export default {
    name: "DashboardWalletSelect",
    components: {
      BhSelectNew
    },
    data() {
      return {
        list: [],
        value: null,
      }
    },
    computed: {
      ...mapState('dashboard', ['currentWallet']),
    },
    created() {
      const selectArr = []

      for (const [key, value] of Object.entries(CURRENCIES_LIST)) {
        selectArr.push({
          label: `${value}${key.toUpperCase()}`,
          value: key,
          id: key,
          img: new URL(`./assets/currenciesSelectIcons/${key}.svg`, import.meta.url).href
        })
      }

      this.list = selectArr
      this.value = this.currentWallet ? selectArr.find(item => item.value === this.currentWallet) : selectArr[0]
    },
    methods: {
      ...mapMutations('dashboard', ['setCurrentWallet']),
      handleSelectCurrentWallet (value) {
        this.setCurrentWallet(value.value)
      }
    }
  }
</script>

<template>
<bh-select-new class="select" v-model="value" :list="list" @input="handleSelectCurrentWallet">
  <template #info="item">
    <div class="item">
      <div class="icon" >
        <img :src="item.img" :alt="item.label">
      </div>
      <div class="label">{{ item.label }}</div>
    </div>
  </template>
  <template #selected="item">
    <div class="item">
      <div class="icon" >
        <img :src="item.img" :alt="item.label">
      </div>
      <div class="label">{{ item.label }}</div>
    </div>
  </template>
</bh-select-new>
</template>

<style scoped lang="scss">
.select {
  width: 137px;
}
.item {
  display: flex;
  align-items: center;
  gap: 5px;
}
.label {
  color: var(--text-text-white, #FFF);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  padding-bottom: 0;
}
.icon {
  height: 20px;
  width: 20px;
}
::v-deep {
  .options-list {
    padding: 0 !important;
  }
  .options-list-item {
    border-radius: 0 !important;
    padding: 10px 20px !important;
    height: 48px !important;
  }
  .options-list-item:hover {
    background: var(--bg-background-primary-purple) !important;
    .label {
      color: var(--text-text-white) !important;
    }
  }
  .new-bh-select-wrapper .input-wrapper .options-list .options-list-item.active {
    background: var(--bg-background-primary-purple) !important;
    .label {
      color: var(--text-text-white) !important;
    }
  }
  .options-list-item {
    display: flex;
    align-items: center;
  }
}
</style>
