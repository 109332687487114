<template>
  <div class="api-documentation">
    <h1 class="api-documentation__title">
      {{ $t('api_documentation.title') }}
    </h1>
    <p class="api-documentation__paragraph">
      {{ $t('api_documentation.description', {
      productName: productName
    }) }}
      <br>
    </p>
    <div class="api-documentation__list">
      <a
          v-for="link in links"
          v-bind:key="link.link"
          v-bind:href="link.link"
          class="api-documentation__link"
          target="_blank"
      >
        <div class="api-documentation__container">
          <svg v-if="link.source === 'PDF'" width="106" height="106" viewBox="0 0 106 106" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M79.8313 6.86349L98.262 26.076V99.1365H29.4117V99.375H98.4972V26.3178L79.8313 6.86349Z"
                  fill="#909090"/>
            <path d="M79.6027 6.625H29.1765V99.1365H98.262V26.0793L79.5994 6.625" fill="#F4F4F4"/>
            <path d="M28.6697 11.5938H7.50281V34.2082H74.0841V11.5938H28.6697Z" fill="#7A7B7C"/>
            <path d="M74.4385 33.8239H7.93344V11.1929H74.4385V33.8239Z" fill="#DD2025"/>
            <path
                d="M29.9847 15.0189H25.6553V30.9189H29.0606V25.5559L29.8125 25.599C30.5431 25.5864 31.2669 25.4555 31.9557 25.2114C32.5596 25.0037 33.1151 24.6758 33.5887 24.2475C34.0707 23.8395 34.4507 23.3245 34.6984 22.7436C35.0307 21.778 35.1493 20.7518 35.0462 19.7359C35.0256 19.0101 34.8983 18.2914 34.6686 17.6026C34.4595 17.1054 34.1492 16.6572 33.7574 16.2865C33.3656 15.9159 32.9009 15.6308 32.3929 15.4495C31.9537 15.2905 31.4999 15.1751 31.0381 15.105C30.6884 15.051 30.3353 15.0223 29.9814 15.0189H29.9847ZM29.3554 22.6178H29.0606V17.7153H29.6999C29.982 17.6949 30.2652 17.7382 30.5283 17.842C30.7915 17.9458 31.028 18.1074 31.2203 18.3148C31.6189 18.8482 31.8318 19.4974 31.8265 20.1632C31.8265 20.9781 31.8265 21.7168 31.0911 22.2368C30.5613 22.5282 29.9583 22.6594 29.3554 22.6144V22.6178ZM41.5156 14.9758C41.1479 14.9758 40.7901 15.0023 40.5384 15.0123L39.75 15.0321H37.1662V30.9321H40.2071C41.3692 30.964 42.5263 30.767 43.6124 30.3524C44.4866 30.0057 45.2607 29.4467 45.8649 28.726C46.4524 27.9988 46.8741 27.152 47.1004 26.2449C47.3607 25.2176 47.4876 24.1611 47.4781 23.1014C47.5423 21.8498 47.4455 20.5951 47.1899 19.3682C46.9473 18.4651 46.4931 17.6327 45.8649 16.9401C45.3719 16.3809 44.7685 15.9297 44.0927 15.6151C43.5123 15.3465 42.9017 15.1486 42.2741 15.0255C42.0245 14.9843 41.7718 14.9654 41.5189 14.9692L41.5156 14.9758ZM40.916 28.0105H40.5847V17.861H40.6278C41.3107 17.7824 42.0017 17.9056 42.6153 18.2154C43.0647 18.5743 43.4309 19.0263 43.6886 19.5404C43.9667 20.0815 44.127 20.6753 44.1589 21.2828C44.1887 22.0116 44.1589 22.6078 44.1589 23.1014C44.1724 23.6699 44.1358 24.2386 44.0496 24.8007C43.9475 25.3778 43.7588 25.9362 43.4898 26.4569C43.1854 26.9411 42.774 27.349 42.2874 27.6494C41.8787 27.9138 41.3947 28.0371 40.9094 28.0006L40.916 28.0105ZM57.7369 15.0321H49.6875V30.9321H53.0927V24.6251H57.399V21.6704H53.0927V17.9869H57.7302V15.0321"
                fill="#464648"/>
            <path
                d="M72.1496 67.0947C72.1496 67.0947 82.7098 65.1801 82.7098 68.7874C82.7098 72.3947 76.1676 70.9272 72.1496 67.0947ZM64.342 67.3696C62.6642 67.7403 61.0291 68.2832 59.4627 68.9894L60.7877 66.0082C62.1127 63.0269 63.4874 58.9625 63.4874 58.9625C65.0685 61.6235 66.9081 64.1222 68.9795 66.4222C67.4174 66.6552 65.8692 66.9737 64.342 67.3762V67.3696ZM60.1616 45.8384C60.1616 42.6948 61.1786 41.8369 61.9703 41.8369C62.7619 41.8369 63.653 42.2178 63.6828 44.9473C63.4249 47.6919 62.8502 50.3975 61.9703 53.0099C60.7649 50.8165 60.1411 48.351 60.1583 45.8483L60.1616 45.8384ZM44.7618 80.6726C41.5222 78.7348 51.5558 72.769 53.3743 72.5769C53.3644 72.5802 48.1538 82.6999 44.7618 80.6726V80.6726ZM85.7938 69.2147C85.7606 68.8834 85.4625 65.2165 78.9369 65.3722C76.2169 65.3283 73.4982 65.5201 70.8113 65.9453C68.2086 63.3231 65.9672 60.3654 64.1466 57.1506C65.2936 53.8362 65.9878 50.382 66.2103 46.8818C66.1142 42.9068 65.1635 40.6278 62.116 40.6609C59.0685 40.6941 58.6246 43.3606 59.0254 47.329C59.4181 49.9957 60.1586 52.5995 61.2283 55.0736C61.2283 55.0736 59.8204 59.4561 57.9588 63.8153C56.0972 68.1746 54.8252 70.4602 54.8252 70.4602C51.588 71.514 48.5405 73.0797 45.7986 75.0977C43.0691 77.6384 41.9594 79.5894 43.3971 81.5405C44.6359 83.2232 48.972 83.6042 52.8476 78.5261C54.9069 75.9033 56.7882 73.1456 58.4789 70.2714C58.4789 70.2714 64.3884 68.6516 66.2268 68.2077C68.0653 67.7638 70.2879 67.4127 70.2879 67.4127C70.2879 67.4127 75.684 72.8419 80.8879 72.6497C86.0919 72.4576 85.8401 69.5393 85.807 69.2213"
                fill="#DD2025"/>
            <path d="M79.3476 6.88007V26.3344H98.0069L79.3476 6.88007Z" fill="#909090"/>
            <path d="M79.6027 6.625V26.0793H98.262L79.6027 6.625Z" fill="#F4F4F4"/>
            <path
                d="M29.7297 14.7638H25.4003V30.6638H28.8188V25.3042L29.574 25.3472C30.3046 25.3347 31.0284 25.2038 31.7172 24.9597C32.321 24.7519 32.8765 24.424 33.3503 23.9957C33.8286 23.5866 34.2051 23.0717 34.45 22.4919C34.7822 21.5263 34.9009 20.5001 34.7978 19.4841C34.7771 18.7584 34.6499 18.0396 34.4202 17.3509C34.2111 16.8537 33.9008 16.4055 33.509 16.0348C33.1172 15.6641 32.6525 15.379 32.1445 15.1977C31.7033 15.0372 31.2472 14.9207 30.7831 14.8499C30.4334 14.796 30.0802 14.7672 29.7264 14.7638H29.7297ZM29.1003 22.3627H28.8055V17.4602H29.4481C29.7303 17.4398 30.0134 17.4832 30.2766 17.5869C30.5398 17.6907 30.7762 17.8523 30.9686 18.0597C31.3671 18.5931 31.58 19.2423 31.5748 19.9081C31.5748 20.723 31.5748 21.4617 30.8394 21.9817C30.3096 22.2731 29.7066 22.4043 29.1036 22.3594L29.1003 22.3627ZM41.2605 14.7207C40.8928 14.7207 40.5351 14.7472 40.2833 14.7572L39.5049 14.7771H36.9211V30.6771H39.962C41.1241 30.7089 42.2811 30.512 43.3673 30.0974C44.2415 29.7506 45.0156 29.1917 45.6198 28.4709C46.2073 27.7437 46.6289 26.897 46.8553 25.9899C47.1156 24.9626 47.2425 23.906 47.2329 22.8463C47.2972 21.5947 47.2003 20.34 46.9447 19.1131C46.7022 18.21 46.248 17.3777 45.6198 16.6851C45.1268 16.1258 44.5234 15.6746 43.8476 15.3601C43.2671 15.0915 42.6566 14.8935 42.029 14.7704C41.7794 14.7292 41.5267 14.7104 41.2738 14.7141L41.2605 14.7207ZM40.6709 27.7554H40.3396V17.6059H40.3827C41.0656 17.5274 41.7565 17.6506 42.3702 17.9604C42.8196 18.3192 43.1857 18.7713 43.4434 19.2854C43.7215 19.8264 43.8819 20.4203 43.9138 21.0277C43.9436 21.7565 43.9138 22.3527 43.9138 22.8463C43.9273 23.4149 43.8907 23.9835 43.8045 24.5456C43.7024 25.1228 43.5137 25.6811 43.2447 26.2019C42.9402 26.686 42.5289 27.094 42.0423 27.3944C41.6336 27.6587 41.1496 27.782 40.6643 27.7455L40.6709 27.7554ZM57.4818 14.7771H49.4324V30.6771H52.8377V24.3701H57.1439V21.4153H52.8377V17.7318H57.4752V14.7771"
                fill="white"/>
          </svg>
          <svg v-else width="106" height="106" viewBox="0 0 106 106" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_719_5020)">
              <path
                  d="M105.566 59.7298C109.276 30.6911 88.7684 4.1427 59.7828 0.433523C30.7971 -3.27606 4.1427 17.2316 0.433523 46.2172C-3.27606 75.2029 17.2316 101.804 46.2172 105.566C75.2559 109.276 101.804 88.7684 105.566 59.7298Z"
                  fill="#FF6C37"/>
              <path
                  d="M72.1297 34.0293L49.7674 56.3916L43.4612 50.0854C65.4525 28.0946 67.6251 30.0021 72.1293 34.0293H72.1297Z"
                  fill="white"/>
              <path
                  d="M49.7674 56.9212C49.6084 56.9212 49.5024 56.8682 49.3964 56.7622L43.0377 50.4564C42.9875 50.4086 42.9475 50.3511 42.9202 50.2873C42.8929 50.2236 42.8788 50.155 42.8788 50.0856C42.8788 50.0163 42.8929 49.9477 42.9202 49.8839C42.9475 49.8202 42.9875 49.7627 43.0377 49.7148C65.3995 27.3526 67.7311 29.4721 72.4473 33.7113C72.5533 33.8173 72.6063 33.9233 72.6063 34.0823C72.6063 34.2413 72.5533 34.3473 72.4473 34.4533L50.0854 56.7622C50.0324 56.8682 49.8734 56.9212 49.7674 56.9212V56.9212ZM44.2037 50.0854L49.7674 55.6496L71.3347 34.0823C67.4135 30.5851 64.7635 29.5251 44.2037 50.0858V50.0854Z"
                  fill="#FF6C37"/>
              <path
                  d="M56.1792 62.8033L50.0854 56.7092L72.4473 34.3473C78.4354 40.3885 69.4801 50.1914 56.1792 62.8033Z"
                  fill="white"/>
              <path
                  d="M56.1792 63.3334C56.0202 63.3334 55.9142 63.2804 55.8086 63.1744L49.7144 57.0802C49.6084 56.9742 49.6084 56.8682 49.6084 56.7092C49.6084 56.5506 49.6614 56.4442 49.7674 56.3386L72.1297 33.9763C72.1775 33.9261 72.2351 33.886 72.2989 33.8587C72.3626 33.8314 72.4313 33.8173 72.5007 33.8173C72.5701 33.8173 72.6388 33.8314 72.7025 33.8587C72.7663 33.886 72.8239 33.9261 72.8717 33.9763C73.5483 34.6024 74.0828 35.3663 74.4393 36.2164C74.7958 37.0665 74.9659 37.9831 74.9383 38.9045C74.8323 44.7867 68.1559 52.258 56.6036 63.1744C56.4446 63.2804 56.2856 63.3334 56.1796 63.3334H56.1792ZM50.8274 56.7092C54.2186 60.1538 55.6496 61.5314 56.1792 62.0614C65.0815 53.583 73.7189 44.8927 73.7723 38.9045C73.8253 37.5269 73.2953 36.1489 72.3943 35.0893L50.8274 56.7092V56.7092Z"
                  fill="#FF6C37"/>
              <path
                  d="M43.5677 50.2444L48.0718 54.7486C48.1778 54.8546 48.1778 54.9606 48.0718 55.0666C48.0188 55.1196 48.0188 55.1196 47.9658 55.1196L38.6395 57.1332C38.1625 57.1862 37.7385 56.8682 37.6325 56.3916C37.5795 56.1266 37.6855 55.8616 37.8445 55.7026L43.2497 50.2974C43.3557 50.1914 43.5147 50.1384 43.5677 50.2444V50.2444Z"
                  fill="white"/>
              <path
                  d="M38.4805 57.6632C37.6855 57.6632 37.1029 57.0272 37.1029 56.2326C37.1029 55.8616 37.2619 55.4906 37.5269 55.2256L42.9317 49.8204C43.2497 49.5554 43.6737 49.5554 43.9917 49.8204L48.4958 54.3246C48.8138 54.5896 48.8138 55.0666 48.4958 55.3846C48.3898 55.4906 48.2838 55.5436 48.1248 55.5966L38.7985 57.6102C38.6925 57.6102 38.5865 57.6632 38.4805 57.6632ZM43.4087 50.8274L38.2155 56.0206C38.1095 56.1266 38.0565 56.2856 38.1625 56.4446C38.2155 56.6036 38.3745 56.6562 38.5335 56.6036L47.2768 54.6956L43.4087 50.8274V50.8274Z"
                  fill="#FF6C37"/>
              <path
                  d="M83.9462 21.6298C80.555 18.3442 75.0969 18.4502 71.8117 21.8948C68.5261 25.339 68.6321 30.7441 72.0767 34.0293C73.4362 35.343 75.1922 36.1697 77.0709 36.3806C78.9496 36.5916 80.8453 36.1748 82.4622 35.1953L76.421 29.1541L83.9458 21.6298H83.9462Z"
                  fill="white"/>
              <path
                  d="M78.0114 36.9439C72.9773 36.9439 68.8971 32.8637 68.8971 27.8296C68.8971 22.7954 72.9773 18.7152 78.0114 18.7152C80.3426 18.7152 82.6216 19.6162 84.3176 21.2588C84.4232 21.3648 84.4762 21.4708 84.4762 21.6298C84.4762 21.7888 84.4232 21.8948 84.3172 22.0008L77.1634 29.1541L82.7806 34.7713C82.9926 34.9833 82.9926 35.3013 82.7806 35.5133L82.6746 35.6193C81.2966 36.4669 79.654 36.9443 78.0114 36.9443V36.9439ZM78.0114 19.8282C73.5603 19.8282 69.9567 23.4314 70.0097 27.8826C70.0097 32.3337 73.6133 35.9373 78.0644 35.8843C79.283 35.8843 80.502 35.6193 81.6146 35.0363L76.0508 29.5251C75.9448 29.4191 75.8918 29.3131 75.8918 29.1541C75.8918 28.9956 75.9448 28.8891 76.0508 28.7836L83.1516 21.6824C81.7206 20.4638 79.919 19.8282 78.0114 19.8282V19.8282Z"
                  fill="#FF6C37"/>
              <path
                  d="M84.1052 21.7888L83.9992 21.6828L76.4219 29.1541L82.4096 35.1423C82.9926 34.7713 83.5752 34.3473 84.0522 33.8703C84.8551 33.084 85.4938 32.1461 85.9315 31.111C86.3692 30.076 86.5971 28.9644 86.6021 27.8406C86.607 26.7169 86.3888 25.6033 85.9602 24.5644C85.5316 23.5256 84.9011 22.5822 84.1052 21.7888V21.7888Z"
                  fill="white"/>
              <path
                  d="M82.4626 35.7253C82.3036 35.7253 82.1976 35.6723 82.0916 35.5663L76.0504 29.5251C75.9449 29.4191 75.8918 29.3131 75.8918 29.1541C75.8918 28.9956 75.9448 28.8891 76.0508 28.7835L83.5752 21.2588C83.623 21.2085 83.6806 21.1685 83.7444 21.1412C83.8081 21.1138 83.8768 21.0997 83.9462 21.0997C84.0156 21.0997 84.0842 21.1138 84.148 21.1412C84.2118 21.1685 84.2693 21.2085 84.3172 21.2588L84.4762 21.3648C88.0268 24.915 88.0268 30.6381 84.5292 34.2413C83.9992 34.7713 83.4166 35.2483 82.7806 35.6193C82.6216 35.6723 82.5156 35.7253 82.4626 35.7253V35.7253ZM77.1634 29.1541L82.5156 34.5063C82.9396 34.2413 83.3636 33.8703 83.6812 33.5527C86.7018 30.5321 86.8608 25.604 83.9462 22.4244L77.1638 29.1541H77.1634Z"
                  fill="#FF6C37"/>
              <path
                  d="M73.0303 34.9833C72.4186 34.3726 71.5896 34.0296 70.7252 34.0296C69.8608 34.0296 69.0318 34.3726 68.4201 34.9833L48.4428 54.9606L51.781 58.2992L72.9243 39.7525C74.3023 38.5865 74.4079 36.5199 73.2423 35.1423C73.1363 35.0893 73.0833 35.0363 73.0303 34.9833V34.9833Z"
                  fill="white"/>
              <path
                  d="M51.728 58.8292C51.569 58.8292 51.463 58.7762 51.357 58.6702L48.0188 55.3316C47.9686 55.2837 47.9286 55.2262 47.9012 55.1624C47.8739 55.0986 47.8598 55.03 47.8598 54.9606C47.8598 54.8912 47.8739 54.8225 47.9012 54.7588C47.9286 54.695 47.9686 54.6374 48.0188 54.5896L67.9961 34.6123C68.3468 34.2596 68.7638 33.9798 69.2231 33.7888C69.6823 33.5978 70.1748 33.4995 70.6722 33.4995C71.1696 33.4995 71.662 33.5978 72.1213 33.7888C72.5806 33.9798 72.9975 34.2596 73.3483 34.6123C73.7009 34.963 73.9808 35.38 74.1718 35.8393C74.3628 36.2985 74.4611 36.791 74.4611 37.2884C74.4611 37.7858 74.3628 38.2783 74.1718 38.7375C73.9808 39.1968 73.7009 39.6138 73.3483 39.9645L73.1893 40.1235L52.046 58.6702C51.993 58.7762 51.887 58.8292 51.728 58.8292V58.8292ZM49.1844 54.9606L51.781 57.5572L72.5533 39.3285C73.7193 38.3745 73.8253 36.6259 72.8713 35.4603C71.9177 34.2943 70.1687 34.1883 69.0031 35.1423C68.9501 35.1953 68.8971 35.2483 68.7911 35.3013L49.1844 54.961V54.9606Z"
                  fill="#FF6C37"/>
              <path
                  d="M33.1283 77.6934C32.9167 77.7994 32.8107 78.0115 32.8633 78.2235L33.7643 82.0386C33.9763 82.5686 33.6583 83.2046 33.0753 83.3636C32.6517 83.5226 32.1747 83.3636 31.9097 83.0456L26.0806 77.2694L45.1043 58.2462L51.6754 58.3522L56.1266 62.8033C55.0666 63.7039 48.6548 69.9041 33.1283 77.6934V77.6934Z"
                  fill="white"/>
              <path
                  d="M32.7047 83.8932C32.2807 83.8932 31.8567 83.7346 31.5917 83.4166L25.8156 77.6404C25.71 77.5344 25.657 77.4284 25.657 77.2699C25.657 77.1109 25.71 77.0049 25.816 76.8989L44.8393 57.8752C44.9453 57.7692 45.1043 57.7162 45.2103 57.7162L51.7814 57.8222C51.94 57.8222 52.0464 57.8752 52.152 57.9812L56.6032 62.4323C56.7092 62.5383 56.7622 62.6973 56.7622 62.8563C56.7622 63.0153 56.7092 63.1213 56.5502 63.2273L56.1792 63.5449C50.5624 68.4731 42.9317 73.4013 33.4463 78.1174L34.3473 81.88C34.5063 82.5686 34.1883 83.3106 33.5523 83.6812C33.2343 83.8406 32.9693 83.8936 32.7047 83.8936V83.8932ZM26.8756 77.2699L32.3337 82.6746C32.4927 82.9396 32.8107 83.0456 33.0753 82.8866C33.3403 82.7276 33.4463 82.4096 33.2873 82.1446L32.3867 78.3294C32.2807 77.8524 32.4927 77.4284 32.9167 77.2169C42.2957 72.5007 49.8734 67.6255 55.4376 62.8033L51.5164 58.8822L45.4223 58.7762L26.8756 77.2699V77.2699Z"
                  fill="#FF6C37"/>
              <path
                  d="M21.5768 81.827L26.1335 77.2699L32.9167 84.0522L22.1064 83.3106C21.6294 83.2576 21.3114 82.8336 21.3648 82.3566C21.3648 82.1446 21.4178 81.9326 21.5764 81.8266L21.5768 81.827Z"
                  fill="white"/>
              <path
                  d="M32.9167 84.5292L22.0534 83.7876C21.2584 83.7346 20.7284 83.0456 20.7818 82.2506C20.8348 81.9326 20.9408 81.615 21.2058 81.403L25.763 76.8459C25.8108 76.7957 25.8683 76.7557 25.9321 76.7284C25.9958 76.7011 26.0644 76.687 26.1338 76.687C26.2031 76.687 26.2717 76.7011 26.3355 76.7284C26.3992 76.7557 26.4567 76.7957 26.5046 76.8459L33.2873 83.6282C33.4463 83.7876 33.4993 83.9996 33.3933 84.2112C33.2873 84.4232 33.1283 84.5292 32.9167 84.5292ZM26.1336 78.0119L21.9474 82.198C21.7884 82.3036 21.7884 82.5686 21.9474 82.6746C22.0004 82.7276 22.0534 82.7806 22.1594 82.7806L31.5387 83.4166L26.1336 78.0114V78.0119ZM43.2497 60.6838C42.9317 60.6838 42.7197 60.4187 42.7197 60.1537C42.7197 59.9947 42.7727 59.8888 42.8787 59.7828L48.0188 54.6426C48.0667 54.5923 48.1242 54.5523 48.188 54.525C48.2518 54.4976 48.3204 54.4835 48.3898 54.4835C48.4592 54.4835 48.5279 54.4976 48.5917 54.525C48.6554 54.5523 48.713 54.5923 48.7608 54.6426L52.099 57.9812C52.258 58.1402 52.311 58.2992 52.258 58.5112C52.205 58.6702 52.046 58.8292 51.834 58.8822L43.3557 60.6833H43.2497V60.6838ZM48.3898 55.7556L44.8923 59.2528L50.6154 58.0342L48.3898 55.7556V55.7556Z"
                  fill="#FF6C37"/>
              <path
                  d="M51.675 58.3522L45.8463 59.6238C45.4223 59.7298 44.9983 59.4648 44.8923 59.0408C44.8393 58.7758 44.8923 58.5108 45.1043 58.2992L48.3368 55.0666L51.675 58.3522V58.3522Z"
                  fill="white"/>
              <path
                  d="M45.7402 60.1537C45.5657 60.1555 45.3926 60.1225 45.2311 60.0565C45.0695 59.9905 44.9228 59.893 44.7994 59.7696C44.676 59.6462 44.5784 59.4994 44.5125 59.3379C44.4465 59.1763 44.4135 59.0032 44.4152 58.8287C44.4152 58.4582 44.5742 58.1402 44.7862 57.8752L48.0188 54.6426C48.0667 54.5923 48.1242 54.5523 48.188 54.525C48.2518 54.4976 48.3204 54.4835 48.3898 54.4835C48.4592 54.4835 48.5279 54.4976 48.5916 54.525C48.6554 54.5523 48.713 54.5923 48.7608 54.6426L52.099 57.9812C52.258 58.1402 52.311 58.2992 52.258 58.5112C52.205 58.6702 52.046 58.8292 51.834 58.8822L46.0052 60.1537H45.7402ZM48.3898 55.7556L45.5282 58.6168C45.4222 58.7232 45.4222 58.8292 45.4752 58.9347C45.5282 59.0407 45.6342 59.0937 45.7932 59.0937L50.6684 58.0337L48.3898 55.7556V55.7556ZM84.1582 26.8756C84.0522 26.558 83.6812 26.399 83.3632 26.505C83.0456 26.611 82.8866 26.9816 82.9926 27.3C82.9926 27.3526 83.0456 27.4056 83.0456 27.4586C83.3636 28.0946 83.2576 28.8896 82.8336 29.4721C82.6216 29.7371 82.6746 30.1081 82.8866 30.3201C83.1516 30.5321 83.5222 30.4791 83.7342 30.2141C84.5292 29.2071 84.6882 27.9356 84.1582 26.8756V26.8756Z"
                  fill="#FF6C37"/>
            </g>
            <defs>
              <clipPath id="clip0_719_5020">
                <rect width="106" height="106" fill="white"/>
              </clipPath>
            </defs>
          </svg>

          <span>{{ link.source }}</span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

import {DOCS_API} from "@/config";

export default {
    name: 'ApiDocumentationPage',
    title: 'api_documentation.title',
    data () {
      return  {
          productName: import.meta.env.VITE_PRODUCT_NAME
      }
    },
    computed: {
        ...mapState('user', [
            'lang',
        ]),
        links() {
            let link = DOCS_API[this.lang];
            let noPdfFilter = link?.filter((item) => item.source === 'Postman');

            return noPdfFilter || [];
        },
        // lang() {
        //     return 'config';
        // },
    },
};

</script>

<style lang="scss" scoped>

.api-documentation {
  padding-left: var(--padding-left-container);
  padding-right: var(--padding-right-container);
  padding-top: var(--padding-top-container);

  &__paragraph {
    font-family: var(--font-family);
    font-size: var(--font-size-h3);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__title {
    margin-bottom: var(--margin-h1-bottom);
    font-family: var(--font-family);
    font-size: var(--font-size-h1);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    font-weight: normal;
  }

  &__list {
    display: flex;
    min-height: 281px;
    background: var(--secondary-color);
    border-radius: 20px;
    box-shadow: var(--shadow-container);
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    gap: 20px;
    margin-bottom: var(--margin-container-bottom);
    margin-top: var(--margin-container-top);
    width: 50%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  &__link {
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 213px;
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    color: var(--primary-font-color);
    border: 1px solid var(--border-color);
    border-radius: 20px;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;

    & svg, span {
      transition: all 0.3s ease-in-out;
      transform: scale(1);
    }

    &:hover {
      background: var(--secondary-color);
      transition: all 0.3s ease-in-out;

      & svg, span {
        transition: all 0.3s ease-in-out;
        transform: scale(1.1);
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 950px) {
  .api-documentation {
    &__list {
      flex-direction: column;
      padding-top: 45px;
      padding-bottom: 45px;
    }


    &__paragraph {
      padding-left: 10px;
    }

    &__title {
      padding-left: 10px;
    }

    &__link {
      min-height: 160px;
    }

    &__container {
      gap: 20px
    }
  }
}

</style>
