<script>
export default {
  name: "BuyCrypto"
}
</script>

<template>
 <div class="buyCrypto-container">
   <div class="iframe-container">
     <iframe  width="100%" height="100%" src="https://widget.apollopayment.io/423d514f-9252-47ec-b081-b9a35321af81/payin-fiat?accentColor=purple&extUserId=test1" frameborder="0"></iframe>

   </div>
 </div>
</template>

<style scoped lang="scss">
.buyCrypto-container {
  width: 100%;
  height: 100%;
  padding: 0 30px 30px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding: 0 10px 10px 10px;
  }
}
.iframe-container {
  width: 700px;
  height: 838px;
  border-radius: 10px;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
}
</style>