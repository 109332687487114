<template>
  <preloader v-if="isLoading"/>

  <div v-else class="payout-wallet__mobile">
    <Container class="payout-wallet__container-top">
      <PayoutWalletRegistryFilterByDate/>
      <btn background-color="--blue-300" icon="icon-plus" :text="$t('payout-wallet.button-open-csv')"
           @click="openPayoutUploadCSVModal"></btn>
    </Container>
    <Container class="payout-wallet__container" v-for="(registry, index) in fullRegistryData"
               :key="registry.id">
      <div class="payout-wallet__registry-button-container">
        <btn v-if="registry.status === 'init'" background-color="--blue-300" :size="'s'"
             :text="$t('payout-wallet.button-execute-registry')"
             @click="openPayoutRegistryExecuteModal({registryId: registry.id, registryTitle: registry.title})"></btn>
        <p class="payout-wallet__registry-status-text"
           :style="`--status-color:var(${registryStatusColor(registry.status)})`"
           v-if="registry.status !== 'init'">
          {{ registryStatus(registry.status) }}</p>
        <btn :is-hover-border="true" background-color="--white-100" text-color="--black-200"
             border-color="--grey-250" :size="'s'"
             :text="$t('payout-wallet.button-info')"
        ></btn>
      </div>
      <AtCollapse accordion @on-change="fetchRegistryData({val: registry.id})" :value="registry.id">
        <AtCollapseItem data="">
          <div slot="title" class="payout-wallet__preview">
            <PayoutWalletRegistryPreview :registry="registry"></PayoutWalletRegistryPreview>
          </div>

          <div>
            <div>
              <PayoutWalletRegistryHeader/>
              <div v-for="registryInfo in registry.registryItem.currencies" :key="registryInfo.id"
                   class="payout-wallet__registry-info-container">
                <div class="payout-wallet__registry-image-container">
                  <CurrencyImg class="payout-wallet__registry-currency-image" :currency="registryInfo.id"/>
                  <p class="payout-wallet__registry-currency-ticker">
                    {{ getCurrencyNameById(registryInfo.id) }}</p>
                </div>
                <p>{{ registryInfo.data.amount }}</p>
                <p>{{ registryInfo.data.wallet ? registryInfo.data.wallet : '' }}</p>
                <p>{{ registryInfo.data.advanced_balance ? registryInfo.data.advanced_balance : '' }}</p>
              </div>

            </div>
          </div>
        </AtCollapseItem>
      </AtCollapse>
    </Container>
    <Container>
      <pagination :size="'small'"
                  :total="registriesData.totalRegistriesCount"
                  :page-size="registriesData.registriesCountOnPage"
                  :current="currentPage"
                  @page-change="changePage"/>
    </Container>
  </div>

</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Container from "../../common/Container";
import preloader from "../../common/preloader";
import CurrencyImg from "../CurrencyImg";
import btn from "../../common/btn";
import pagination from "../../common/pagination";
import PayoutWalletRegistryPreview from "./PayoutWalletRegistryPreview";
import PayoutWalletRegistryHeader from "./PayoutWalletRegistryHeader";
import PayoutWalletRegistryFilterByDate from "./PayoutWalletRegistryFilterByDate";
import {registryStatusTypeColor} from "../../../filters";

export default {
    name: "PayoutWalletRegistry",
    components: {
        Container,
        CurrencyImg,
        btn,
        preloader,
        PayoutWalletRegistryHeader,
        PayoutWalletRegistryPreview,
        pagination,
        PayoutWalletRegistryFilterByDate,
    },
    data() {
        return {
            isLoading: true,
        };
    },
    mounted() {
        const {
            $route,

        } = this;
        this.fetchRegistries({
            route: $route,
            showUrlFilters: false,
        }).finally(() => {
            this.isLoading = false;
        });

    },
    methods: {
        ...mapActions('payoutBalances', ['fetchPayoutBalances', 'fetchRegistries', 'fetchRegistry', 'fetchRegistryInfoAction']),
        ...mapActions("app", ["openModal"]),
        registryStatus(status) {
            return this.$t(this.$options.filters.registryStatusType(status));
        },
        openPayoutUploadCSVModal() {
            this.openModal({
                name: 'PayoutModalUploadCSV',
            });
        },
        registryStatusColor(status) {
            return this.$options.filters.registryStatusTypeColor(status);

        },
        openPayoutRegistryExecuteModal({registryId, registryTitle}) {
            this.openModal({name: 'PayoutModalRegistryExecute', payload: {registryId, registryTitle}});
        },
        fetchRegistryData(value) {
            const accountId = this.accounts[0].accountId;
            if (this.registriesData.registriesItems.some((item) => item.registryId === value.val)) {
                return;
            }
            this.fetchRegistryInfoAction({accountId: accountId, registryId: value.val});
        },
        changePage(pageNumber) {

            const {
                $route,
                $router,
                fetchRegistries,
            } = this;

            fetchRegistries({
                route: $route,
                router: $router,
                page: pageNumber,
            });
        },
    },
    computed: {
        ...mapState('payoutBalances', ['registriesData']),
        ...mapGetters('app', [
            'getCurrencyNameById',
        ]),
        ...mapState('accounts', ['accounts']),
        fullRegistryData() {
            let result = this.registriesData.registries.map((i) => {
                const registryItem = this.registriesData.registriesItems.find((item) => item.registryId === i.id);
                i.registryItem = registryItem?.result ?? [];
                return i;
            });
            return result;
        },
        currentPage() {
            const pageFromUrl = this.$route.query.page;

            if (!pageFromUrl) {
                return 1;
            }

            return Number(pageFromUrl);
        },
    },
};
</script>

<style lang="scss" scoped>
.payout-wallet {
  max-width: 1420px;
  padding-top: var(--padding-top-container);
  padding-left: var(--padding-left-container);
  padding-right: var(--padding-right-container);


  &__title-text {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 300;
    font-size: var(--font-size-text-secondary);
    line-height: var(--line-height);
    color: var(--grey-300);
    margin-bottom: 5px;
  }

  &__value {
    font-family: var(--font-family);
    font-style: normal;
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }


  &__new-button {
    height: 46px;
    max-width: 211px;
    width: 100%;
    background: #0059F7;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    transition: all 0.3s ease-in;

    &:hover {
      background: var(--blue-400);
    }
  }

  &__container {
    margin-top: 10px;
    margin-bottom: 10px;

    &-top {
      padding: 30px 35px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: 10px;
    }
  }

  &__currency {
    &-container {
      display: flex;
      align-items: center;
    }

    &-image {
      height: 26px;
      width: 26px;
      margin-right: 16px;
    }
  }

  &__wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 20px 40px;
  }

  &__title {
    margin-bottom: 13px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-h1);
    line-height: var(--line-height);
    color: #2C405A;
  }

  &__subtitle {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-h3);
    line-height: var(--line-height);
  }

  &__tab {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 66px;
    border-radius: 16px;


    &-underlay {
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;
      min-height: 66px;
      border-radius: 16px;
      justify-content: flex-start;

      &_registry {
        justify-content: flex-end;
      }
    }

    &_right {
      position: absolute;
      right: 5px;
      width: calc(50% + 25px);
      padding-left: 25px;
      background: var(--grey-200);
      z-index: 1;
      border-top: 1px solid #F2F2F2;
      border-right: 1px solid #F2F2F2;
      border-bottom: 1px solid #F2F2F2;
    }

    &_left {
      position: absolute;
      left: 0px;
      width: calc(50% + 25px);
      padding-left: 25px;
      background: var(--grey-200);
      z-index: 1;
      border-top: 1px solid #F2F2F2;
      border-left: 1px solid #F2F2F2;
      border-bottom: 1px solid #F2F2F2;

    }


    &_left-active {
      position: relative;
      z-index: 2;
      background: var(--white-100);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
      border: none;
    }

    &_right-active {
      position: relative;
      z-index: 2;
      background: var(--white-100);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
      border: none;
    }
  }

  &__tab-container {
    min-height: 76px;
    padding: 10px;
    border-radius: 15px;
    background: var(--white-100);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  &__preview {
    width: 100%;
  }


  &__mobile {
    display: block;


    ::v-deep .at-collapse {
      border: 1px solid #F2F2F2;
      border-right: none;
      border-left: none;
    }

    ::v-deep .at-collapse__content {
      padding: 0;
    }

    ::v-deep .at-collapse__header {
      background: #FFFFFF;
      min-height: 50px;
      display: flex;
      align-items: center;
      padding-left: 40px;
      padding-right: 10px;
      padding-top: 0;
      padding-bottom: 0;
    }

    ::v-deep .at-collapse__icon {
      right: 10px;
      top: 17px;
      font-size: 20px;
      left: unset;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }

    ::v-deep .at-collapse__item--active > .at-collapse__header .at-collapse__icon {
      -webkit-transform: rotate(270deg);
      transform: rotate(270deg);
    }

  }

  &__registry-title {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

  &__registry-status-text {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--status-color);
  }

  &__registry-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    gap: 10px;
  }

  &__registry-currency-image {
    width: 26px;
    height: 26px;
  }

  &__registry-image-container {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &__registry-info-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding-left: 40px;
    padding-right: 40px;
    min-height: 55px;
    align-items: center;
    border-bottom: var(--border-table);
  }
}


</style>