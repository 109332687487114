import createOrganizationRoleMutation from "/public/graphQl/mutations/createOrganizationRole.js";

export const createOrganizationRole = async ({commit, dispatch, rootGetters, rootState}, payload) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    let currenciesData;

    let variables = {
        organizationId,
        name: payload.name,
        rules: JSON.stringify(payload.rules),
        comment: payload.comment,
    };

    try {
        if (!rootState.app.isLoading) {
            commit('app/setLoading', true, {root: true});
        }
        currenciesData = await dispatch('app/request', {
            type: 'query',
            gql: createOrganizationRoleMutation,
            variables,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/setLoading', false, {root: true});
    }

    if (currenciesData.message) {
        throw currenciesData.message;
    }

    if (currenciesData.errors) {
        throw currenciesData.errors;
    }

    return true;
};
