import GetAccountBalancesQueryRecurrent from "/public/graphQl/queries/getRecurrentAddresses";

export const getAccountBalancesRecurrent = async ({commit, dispatch,  rootGetters}, payload) => {
    let balancesData;

    const variables = {
      ...payload
    }

    if (!variables.organizationId) {
        variables.organizationId = rootGetters['app/getSelectedOrganization'].id
    }

    try {
        balancesData = await dispatch('app/request', {
            type: 'query',
            gql: GetAccountBalancesQueryRecurrent,
            variables
        }, {root: true});
    } catch (error) {
        return error;
    }

    const { data } = balancesData || {}
    const { getRecurrentAddresses } = data || {}

    return getRecurrentAddresses || [];
};
