<template>
  <div class="fake-input-wrapper">
    <div class="label">
      <slot name="label" />
    </div>
    <div class="fake-input">
      <div class="value">
        <slot name="value" />
      </div>
      <div class="suffix">
        <slot name="suffix" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FakeInput"
}
</script>

<style lang="scss">
  .fake-input-wrapper {
    .label {
      font-size: var(--new-front-input-label-font-size);
      color: var(--new-front-input-label-font-color);
      margin-bottom: 5px;
    }
    .fake-input {
      position: relative;
      padding: 14px 20px;

      border: 1px solid var(--new-front-input-border);
      border-radius: 10px;

      cursor: not-allowed;

      .value {
        font-size: $h4;
        font-weight: 500;

        color: var(--new-front-fake-input-font-color);
      }
      .suffix {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translate(0, -50%);
      }
    }
  }
</style>