import getAllNetworksQuery from "/public/graphQl/queries/getAllNetworks";

export const getAllNetworks = async ({ commit, dispatch }) => {

    let data;
    try {
        data = await dispatch('app/request', {
            type: 'query',
            gql: getAllNetworksQuery,
        }, { root: true });
    } catch (error) {
        return error;
    }

    commit('networks', data);

    return data;
};