import Store from "../../index";

export class AccountsController {
    static fetchAccounts() {
        return Store.dispatch("accounts/fetchAccounts");
    }

    static getAccountData(accountId, page, limit) {
        return Store.dispatch("accounts/getAccountData", {accountId, page, limit});
    }
}
