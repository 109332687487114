<template>
  <div class="orders-top-bar">

    <div class="orders-top-bar__left-side">
      <div class="left">
        <donations-filter-by-date class="orders-top-bar__filter-by-date"/>
      </div>
      <div class="right">

      </div>

    </div>


  </div>
</template>

<script>

import {mapActions} from 'vuex';
import AtButton from 'at-ui/src/components/button';
import BtnRefresh from "../../common/btn-refresh";
import DonationsFilterByDate from "./donations-filter-by-date";

export default {
    name: 'DonationsTopBar',
    components: {
        DonationsFilterByDate,
        BtnRefresh,
        AtButton,
    },
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
    // refreshIcon() {
    //     return this.isLoading ? '' : 'icon-rotate-cw';
    // },
    },
    methods: {
        ...mapActions('app', [
            'openModal',
        ]),
        ...mapActions('orders', [
            'fetchOrders', 'downloadCSV',
        ]),
        openNewOrderModal() {
            this.openModal({
                name: 'NewOrderModal',
            });
        },
        updateOrders() {
            const {
                $route,
                $router,
                fetchOrders,
            } = this;

            this.isLoading = true;
            fetchOrders({
                route: $route,
                router: $router,
            })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        download() {
            const {
                $route,
            } = this;

            this.downloadCSV({
                route: $route,
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.orders-top-bar {
  justify-content: space-between;
  align-items: flex-end;


  &__new-button {
    height: 46px;
    max-width: 211px;
    width: 100%;
    background: #0059F7;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    transition: all 0.3s ease-in;

    &:hover {
      background: var(--blue-400);
    }
  }

  &__new-button ::v-deep .at-btn__icon {
    font-size: 24px;
  }

  &__new-button ::v-deep .at-btn__text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin: 0;
  }


  &__download-button {
    height: 46px;
    width: 60px;
    background: #FFFFFF;
    border-radius: 12px;
    border: 1px solid #0059F7;
    padding: 0;
    transition: all 0.3s ease-in;

    &:hover {
      background: var(--grey-100);
    }
  }

  &__download-button ::v-deep .at-btn__icon {
    color: #0059F7;
    font-size: 18px;
    line-height: 18px;
  }

  &::v-deep .at-select {
    background: #FFFFFF;
    box-sizing: border-box;
  }

  &::v-deep .at-select__selection {
    border: 1px solid #F2F2F2;
    border-radius: 12px;
    min-height: 46px;
    display: flex;
    padding-left: 30px;
    align-items: center;
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    flex-wrap: wrap;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &::v-deep .at-select__arrow {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  ::v-deep .at-select__clear {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  &__left-side {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    gap: 22px 40px;
  }

  &__sort-by-network,
  &__sort-by-status,
  &__sort-by-currency {

  }
}

.left {
  width: inherit;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.right {
  width: inherit;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 25px;
  margin-bottom: 5px;
  padding-top: 26px;
}

.button_update {
}

@media screen and (max-width: 950px) {
  .orders-top-bar {
    &__new-button {
      max-width: 157px;
    }

    &__left-side {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
  .button {
    gap: 8px;
  }
}
</style>