<script>
  export default {
    name: "SvgRedArrowDown"
  }
</script>

<template>
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.6995 5.80562C3.57437 5.65425 3.5 5.46088 3.5 5.25C3.5 4.767 3.892 4.375 4.375 4.375H9.625C10.108 4.375 10.5 4.767 10.5 5.25C10.5 5.46088 10.4256 5.65425 10.3005 5.80562L7.707 9.26538C7.54775 9.48325 7.2905 9.625 7 9.625C6.7095 9.625 6.45225 9.48325 6.293 9.26538L3.6995 5.80562Z" fill="#B04444"/>
</svg>
</template>
