import uploadPreCheckoutLink from "/public/graphQl/mutations/uploadPreCheckoutLink";
import axios from "axios";

export const uploadPreCheckoutLinkQuery = async ({dispatch, rootGetters}, payload) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    let avatarLinkData;
    try {
        avatarLinkData = await dispatch('app/request', {
            type: 'mutation',
            gql: uploadPreCheckoutLink,
            variables: {
                organizationId,
                pageId: payload.pageId,
            },
        }, {root: true});


    } catch (error) {
        return error;
    }

    const {
        data: {
            uploadPreCheckoutLink: {
                code,
                result,
            },
        },
    } = avatarLinkData;
    let uploadedAvatar;
    const fileType = payload.avatarFile.get('file').type;

    try {
        uploadedAvatar = axios.put(result, payload.avatarFile, {
            headers: {
                'Content-Type': fileType,
            },
        });
    } catch (e) {
        console.log(e.response);
    }
    return uploadedAvatar;
};
