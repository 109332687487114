<template>
  <div
      class="video-info-modal-wrapper"
      @click="openInfoModal"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      fill="#FFFFFF"
      height="800px"
      width="800px"
      viewBox="0 0 60 60"
      xml:space="preserve"
      class="video-info-modal-icon"
    >
      <g>
        <path d="M45.563,29.174l-22-15c-0.307-0.208-0.703-0.231-1.031-0.058C22.205,14.289,22,14.629,22,15v30   c0,0.371,0.205,0.711,0.533,0.884C22.679,45.962,22.84,46,23,46c0.197,0,0.394-0.059,0.563-0.174l22-15   C45.836,30.64,46,30.331,46,30S45.836,29.36,45.563,29.174z M24,43.107V16.893L43.225,30L24,43.107z"/>
        <path d="M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M30,58C14.561,58,2,45.439,2,30   S14.561,2,30,2s28,12.561,28,28S45.439,58,30,58z"/>
      </g>
    </svg>
    <StoreModal
      name="PlayInfoModal"
      @on-cancel="handleClose"
      @on-confirm="handleClose"
    >
        <template #body>
          <div class="player-wrapper">
            <iframe
              width="560"
              height="315"
              :src="source"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen>
            </iframe>
          </div>
        </template>
    </StoreModal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import BaseModal from "../BaseModal";
import BhButton from "/public/components/common/bh-button"
import Icon from "/public/components/common/icon";
import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";

export default {
  name: "PlayInfoModal",
  components: {
    BaseButton,
    BaseModal,
    BhButton,
    Icon,
    StoreModal: () => import(/* webpackChunkName: "/public/components/modals/StoreModal" */ '/public/components/modals/StoreModal')
  },
  props: {
    source: {
      type: String,
      default: undefined
    },
  },
  computed: {
    ...mapGetters('app', [
      'isOpenModal',
    ]),
    componentId({ _uid }) {
      return _uid
    },
    isOpen() {
      const {
        isOpenModal,
      } = this;

      return isOpenModal(`PlayInfoModal`);
    },
  },
  methods: {
    ...mapActions('app', [
      'openModal',
      'closeModal',
    ]),
    handleClose() {
      this.closeModal();
    },
    openInfoModal() {
      this.openModal({ name: `PlayInfoModal` })
    }
  },
}
</script>

<style lang="scss">
.video-info-modal-wrapper {
  width: 22px;
  height: 22px;
  border-radius: 6px;
  border: 1px solid #ff0000;
  background-color: #ff0000;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  .player-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .video-info-modal-icon {
    width: 16px;
    height: 16px;
  }
  .video-info-modal {
    .at-modal__body {
      padding-top: 10px;
      padding-bottom: 10px;
      max-height: 50vh;
      overflow-y: scroll;
    }
  }
}
</style>
