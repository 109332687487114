<template>
  <div
      class="tfa-banner"
  >

    <div class="tfa-banner__container">
      <div class="tfa-banner__text-container">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M14.25 8.25H3.75C2.92157 8.25 2.25 8.92157 2.25 9.75V15C2.25 15.8284 2.92157 16.5 3.75 16.5H14.25C15.0784 16.5 15.75 15.8284 15.75 15V9.75C15.75 8.92157 15.0784 8.25 14.25 8.25Z"
              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path
              d="M5.25 8.25012V5.25012C5.24907 4.32015 5.59371 3.42301 6.21703 2.73287C6.84035 2.04272 7.69787 1.60879 8.62313 1.51533C9.54839 1.42187 10.4754 1.67554 11.2241 2.2271C11.9729 2.77866 12.4899 3.58875 12.675 4.50012"
              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <p class=tfa-banner__text-main>{{ $t('dashboard.enable_two-factor_authentication') }}</p>
      </div>
      <AtButton class="tfa-banner__button" :icon="'icon-check'" @click="enable_authentication">
        {{ $t('dashboard.enable') }}
      </AtButton>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import Icon from "./icon";

export default {
    name: "tfa-banner",
    components: {Icon},
    methods: {
        ...mapActions('app', [
            'openModal',
        ]),
        enable_authentication() {
            if (!this.userTwoFactorAuth) {
                this.openModal({
                    name: 'QRCodeModal',
                });
            }
        },
    },
    computed: {
        ...mapState('user', {
            userId: state => state.user.id,
            userOtpEnabled: state => state.user.otpToken,
            reserveCodes: state => state.reserveCodes.result,
        }),
        userTwoFactorAuth() {
            return !!this.userOtpEnabled;
        },
    },
};
</script>

<style lang="scss" scoped>
.tfa-banner {
  overflow: hidden;
  width: 100%;
  padding-left: var(--padding-left-container);
  padding-right: var(--padding-right-container);
  padding-top: 10px;
  background: var(--bg-main);

  &__container {
    width: 100%;
    background: var(--new-front-purple);
    //background: linear-gradient(91.07deg, #0059F7 -92.25%, #BD00FF 107.02%);
    border-radius: 12px;
    display: flex;
    align-items: center;
    min-height: 56px;
    padding-left: var(--padding-left-container);
    padding-right: 10px;
    justify-content: space-between;
  }

  &__text-container {
    display: flex;
    gap: 15px;
    align-items: center;


  }

  &__text-main {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--white-100);
  }

  &__button {
    background: transparent;
    border-radius: 8px;
    border: 1px solid var(--white-100);
    transition: all 0.3s ease-in;

    &:hover {
      border: 1px solid var(--blue-400);
      background: var(--blue-400);
    }

    ::v-deep .at-btn__text {
      color: var(--white-100);
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
    }

    ::v-deep .at-btn__icon {
      color: var(--white-100);
      font-size: var(--font-size-text-main);
    }
  }
}

@media screen and (max-width: 375px) {
  .tfa-banner {
    padding-left: 10px;
    padding-right: 10px;

    &__container {
      padding-left: 10px;
    }
  }

}
</style>
