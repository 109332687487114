import gql from 'graphql-tag';

export default gql`
    query ($from: String!, $to: String!){
        getPrice(from: $from, to: $to) {
            from,
            to,
            price,
        }
    }
`;