<template>
<div class="top-bar__container">
  <div class="top-bar__wrapper">
    <div v-for="(tab, i) in tabs"
         :class="['top-bar__tab', {'active': tab.position === activeTab}]"
         :key="i"
         @click="switchTab(tab.position)"
    >
      {{ tab.name }}
    </div>
  </div>
</div>
</template>

<script>
  import {mapActions, mapState} from "vuex";

  export default {
    name: 'Tabs',

    data() {
      return {
        activeTab: 0
      }
    },

    computed: {
      ...mapState('organizations', [
        'currentTabSelect',
      ]),

      tabs() {
        return [
          {
            name: this.$t('bank.deposit'),
            position: 0,
          },
          {
            name: this.$t('bank.withdraw'),
            position: 1,
          },
        ];
      },
    },

    methods: {
      switchTab(tab) {
        this.activeTab = tab
        this.$emit('switchTab', tab)
      },
    },
  };
</script>

<style scoped lang="scss">
.top-bar {
  &__container {
    width: 100%;
    height: 76px;

    @media screen and (max-width: 768px) {
      //border-radius: 16px;
      height: auto;
      box-shadow: none;
      margin-bottom: 6px;
      padding: 20px;
    }
  }

  &__wrapper {
    width: 100%;
    height: 64px;
    //border: 1px solid var(--border-color);
    //box-shadow: 0 4px 20px rgba(0, 0, 0, 0.04);
    //border-radius: 15px;
    //background: var(--toggle-theme-background);
    display: flex;
    align-items: center;
    gap: 5px;

    @media screen and (max-width: 768px) {
      border: none;
      height: 42px;
    }
  }

  &__tab {
    width: 100%;
    max-width: 160px;
    height: 48px;
    background: transparent;
    border-radius: 15px;
    margin-top: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--new-front-secondary-font-color);
    cursor: pointer;
    transition: all .3s ease;

    @media screen and (max-width: 768px) {
      font-size: 13px;
    }

    &:first-child {
      margin-left: -1px;
    }

    //&:last-child {
    //  width: calc(33.3333333% + 2px);
    //}

    &.active {
      background: var(--new-front-select-item-bg-active);
      color: var(--new-front-primary-font-color);
    }

    &:hover {
      background: var(--new-front-select-item-bg-active);
      color: var(--new-front-primary-font-color);
    }
  }
}
</style>

