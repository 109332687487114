<script>

  import WidgetsDemoBannerImg from "@/public/components/elements/widgets-manage/settings/WidgetsDemoBannerImg.vue";
  import WidgetsConstructorItem from "@/public/components/elements/widgets-manage/settings/WidgetsConstructorItem.vue";
  import WidgetsMultiWalletFiat from "@/public/components/elements/widgets-manage/settings/WidgetsMultiWalletFiat.vue";
  import {mapMutations, mapState} from "vuex";
  import BaseInput from "@/public/pages/integration-settings/components/Input/index.vue";
  import BaseTextarea from "@/public/components/common/uiKit/textarea/index.vue";
  import WidgetsMultiCurrencySelect
    from "@/public/components/elements/widgets-manage/settings/WidgetMultiWalletSelect.vue";
  import {COLORS, PAYIN_CRYPTO_TYPE_INVOICE, PAYIN_CRYPTO_TYPE_PERSONAL} from "@/public/pages/widgets/constants";
  import WidgetsDemoLinkBlock from "@/public/components/elements/widgets-manage/settings/WidgetsDemoLinkBlock.vue";
  import WidgetsDemoScriptBlock from "@/public/components/elements/widgets-manage/settings/WidgetsDemoScriptBlock.vue";

  export default {
    name: "WidgetsManageDemoConstructor",
    components: {
      WidgetsDemoScriptBlock,
      WidgetsDemoLinkBlock,
      WidgetsMultiCurrencySelect,
      BaseTextarea, BaseInput, WidgetsMultiWalletFiat, WidgetsConstructorItem, WidgetsDemoBannerImg},
    PAYIN_CRYPTO_TYPE_INVOICE,
    PAYIN_CRYPTO_TYPE_PERSONAL,
    data () {
      return {
        demo: {
          accentColor: '',
          payinCryptoInvoiceAmount: '',
          payinCryptoInvoiceCurrencies: [],
          payinCryptoCurrenciesTo: [],
          payinCryptoInvoiceId: '',
          payinCryptoInvoiceDesc: '',
          payinCryptoInvoicePayerEmail: '',
          payinCryptoInvoicePayerEmailRequired: '',
          payinCryptoInvoicePayerEmailAllow: '',
          payinCryptoInvoiceLifetime: '',
          payinCryptoWebhook: '',
          payoutCryptoCurrenciesTo: [],
          payoutCryptoWebhook: '',
          payoutCryptoWebhookApproval: '',
          payoutCryptoReceiverEmail: '',
          payoutCryptoCurrenciesFrom: [],
          payoutCryptoAmountFrom: ''
        },
        colors: COLORS,
      }
    },
    methods: {
        ...mapMutations('widgetsManage', ['setDemoObj']),
    },
    computed: {
      ...mapState('widgetsManage', ['widget']),
      isDoubleLinks () {
        return !this.widget.showStartPage && this.widget.payinCrypto && this.widget.payoutCrypto
      }
    },
    watch: {
      'widget.accentColor': {
        handler (val) {
          this.demo.accentColor = this.widget.accentColor
        },
        immediate: true,
      },
      demo: {
        deep: true,
        handler (val, oldVal) {
          if (val.payoutCryptoCurrenciesTo.length !== oldVal.payoutCryptoCurrenciesTo.length && val.payoutCryptoCurrenciesTo.length !== 1) {
            this.setDemoObj({
              ...val,
              payoutCryptoAmountFrom: ''
            })
          }
          this.setDemoObj(val)
        }
      }
    }
  }
</script>

<template>
<div :class="['constructor', {doubleLinks: isDoubleLinks}]">
  <div class="links">
    <WidgetsDemoScriptBlock />
  </div>
<!--  <div class="topHeader">-->
<!--&lt;!&ndash;    <div @click="" class="topHeader__back">&ndash;&gt;-->
<!--&lt;!&ndash;      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">&ndash;&gt;-->
<!--&lt;!&ndash;        <path d="M11.0781 3.58313C10.8191 3.45457 10.5072 3.47641 10.2701 3.6397L4.8257 7.3897C4.62131 7.53048 4.5 7.75779 4.5 8C4.5 8.24221 4.62131 8.46952 4.8257 8.6103L10.2701 12.3603C10.5072 12.5236 10.8191 12.5454 11.0781 12.4169C11.3372 12.2883 11.5 12.0309 11.5 11.75V4.25C11.5 3.96906 11.3372 3.71168 11.0781 3.58313Z" fill="var(&#45;&#45;text-color-text-secondary)" stroke="var(&#45;&#45;text-color-text-secondary)" stroke-linejoin="round"/>&ndash;&gt;-->
<!--&lt;!&ndash;      </svg>&ndash;&gt;-->
<!--&lt;!&ndash;      Back&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--    <div class="topHeader__title">-->
<!--      {{ $t('widgets.demo') }}-->
<!--    </div>-->
<!--    <div class="topHeader__desc">-->
<!--      {{ $t('widgets.demoModeDesc') }}-->
<!--    </div>-->
<!--  </div>-->
  <div class="constructor__inner">
<!--    <div class="demoBanner">-->
<!--      <div class="demoBanner__title">{{ $t('widgets.demoMode') }}</div>-->
<!--&lt;!&ndash;      <div class="demoBanner__desc">{{ $t('widgets.demoModeDesc') }}</div>&ndash;&gt;-->
<!--      <div class="demoBanner__img">-->
<!--        <WidgetsDemoBannerImg />-->
<!--      </div>-->
<!--    </div>-->
    <div>
      <div class="title">{{ $t('widgets.mainStyle') }}</div>
      <div class="theme-wrapper">
        <div class="colors">
          <div class="colors__item" v-for="(color, key) in colors" :key="key" :style="{background: color}" @click="demo.accentColor = key">
            <svg v-if="key === demo.accentColor" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.9393 9.93934C22.5251 9.35355 23.4748 9.35355 24.0606 9.93934C24.6392 10.5179 24.6463 11.4516 24.0819 12.0389L16.0975 22.0195C16.0859 22.0339 16.0736 22.0476 16.0606 22.0607C15.4748 22.6464 14.5251 22.6464 13.9393 22.0607L8.64637 16.7678C8.06058 16.182 8.06058 15.2322 8.64637 14.6464C9.23216 14.0607 10.1819 14.0607 10.7677 14.6464L14.9547 18.8335L21.8995 9.98421C21.9118 9.96847 21.9251 9.95349 21.9393 9.93934Z" fill="white"/>
            </svg>
          </div>
          <!--            <popper-->
          <!--                trigger="clickToOpen"-->
          <!--                :options="{-->
          <!--                   placement: 'right-start',-->
          <!--                }"-->
          <!--          >-->
          <!--          <div class="colors__item addBtn" slot="reference" ref="actionsTrigger">-->
          <!--            <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">-->
          <!--              <path d="M16.5 8C17.0523 8 17.5 8.44772 17.5 9V15H23.5C24.0523 15 24.5 15.4477 24.5 16C24.5 16.5523 24.0523 17 23.5 17H17.5V23C17.5 23.5523 17.0523 24 16.5 24C15.9477 24 15.5 23.5523 15.5 23V17H9.5C8.94772 17 8.5 16.5523 8.5 16C8.5 15.4477 8.94772 15 9.5 15H15.5V9C15.5 8.44772 15.9477 8 16.5 8Z" fill="white"/>-->
          <!--            </svg>-->
          <!--          </div>-->
          <!--          <WidgetsColorPicker />-->
          <!--        </popper>-->
        </div>
      </div>
    </div>
    <div v-if="widget.payinCryptoType === $options.PAYIN_CRYPTO_TYPE_PERSONAL && widget.payinCrypto">
      <div class="title">{{ $t('widgets.personal') }}</div>
      <div class="theme-wrapper">
        <div class="inputWrapper constructorItem">
          <WidgetsConstructorItem
              :label="$t('widgets.paymentCurrency')"
              :field-label="'&payinCryptoCurrenciesTo'"
              :tooltip-title="$t('widgets.paymentCurrency')"
              :tooltip-text="$t('widgets.tooltip.paymentCurrency')"
          >
            <WidgetsMultiCurrencySelect :list="widget.payinCryptoCurrenciesTo" v-model="demo.payinCryptoCurrenciesTo" />
          </WidgetsConstructorItem>
        </div>
      </div>
    </div>
    <div v-if="widget.payinCryptoType === $options.PAYIN_CRYPTO_TYPE_INVOICE && widget.payinCrypto">
      <div class="title">{{ $t('widgets.invoices') }}</div>
      <div class="theme-wrapper">
        <div v-if="!widget.payinCryptoInvoiceFree" class="inputWrapper constructorItem">
          <WidgetsConstructorItem
              :label="$t('widgets.invoiceCurrency')"
              :field-label="'&payinCryptoInvoiceCurrencies'"
              :tooltip-title="$t('widgets.invoiceCurrency')"
              :tooltip-text="$t('widgets.tooltip.invoiceCurrency')"
          >
            <WidgetsMultiCurrencySelect :list="widget.payinCryptoInvoiceCurrencies" :with-networks="false" :is-fiat-enabled="true" v-model="demo.payinCryptoInvoiceCurrencies" />
          </WidgetsConstructorItem>
        </div>
        <div class="inputWrapper constructorItem">
          <WidgetsConstructorItem
              :label="$t('widgets.paymentCurrency')"
              :field-label="'&payinCryptoCurrenciesTo'"
              :tooltip-title="$t('widgets.paymentCurrency')"
              :tooltip-text="$t('widgets.tooltip.paymentCurrency')"
          >
            <WidgetsMultiCurrencySelect :list="widget.payinCryptoCurrenciesTo" v-model="demo.payinCryptoCurrenciesTo" />
          </WidgetsConstructorItem>
        </div>
        <div v-if="!widget.payinCryptoInvoiceFree" class="inputWrapper constructorItem">
          <WidgetsConstructorItem
              :is-locked="demo.payinCryptoInvoiceCurrencies.length !== 1"
              :locked-text="$t('widgets.lockedAmount')"
              :label="$t('widgets.invoiceAmount')"
              :field-label="'&payinCryptoInvoiceAmount'"
              :tooltip-text="$t('widgets.tooltip.invoiceAmount')"
              :tooltip-title="$t('widgets.invoiceAmount')"
          >
            <base-input type="number" v-model="demo.payinCryptoInvoiceAmount" :placeholder="$t('widgets.enterSum')" />
          </WidgetsConstructorItem>
        </div>
        <div class="inputWrapper constructorItem">
          <WidgetsConstructorItem
              :label="$t('widgets.orderId')"
              :field-label="'&payinCryptoInvoiceId'"
              :tooltip-title="$t('widgets.orderId')"
              :tooltip-text="$t('widgets.tooltip.orderId')"
          >
            <base-input v-model="demo.payinCryptoInvoiceId" :placeholder="$t('widgets.enterOrderId')" />
          </WidgetsConstructorItem>
        </div>
        <div class="inputWrapper constructorItem">
          <WidgetsConstructorItem
              :label="$t('widgets.description')"
              :field-label="'&payinCryptoInvoiceDesc'"
              :tooltip-title="$t('widgets.description')"
              :tooltip-text="$t('widgets.tooltip.description')"
          >
            <base-textarea v-model="demo.payinCryptoInvoiceDesc" :placeholder="$t('widgets.enterDescription')" />
          </WidgetsConstructorItem>
        </div>
        <div v-if="widget.payinCryptoInvoicePayerEmailAllow" class="inputWrapper constructorItem">
          <WidgetsConstructorItem
              :label="$t('widgets.email')"
              :field-label="'&payinCryptoInvoicePayerEmail'"
              :tooltip-title="$t('widgets.email')"
              :tooltip-text="$t('widgets.tooltip.email')"
          >
            <base-input v-model="demo.payinCryptoInvoicePayerEmail" :placeholder="$t('widgets.enterEmail')" />
          </WidgetsConstructorItem>
        </div>
      </div>
    </div>
    <div v-if="widget.payoutCrypto">
      <div class="title">{{ $t('widgets.payout') }}</div>
      <div class="theme-wrapper">
        <div class="inputWrapper constructorItem">
          <WidgetsConstructorItem
              :label="$t('widgets.payoutCurrency')"
              :field-label="'&payoutCryptoCurrenciesFrom'"
              :tooltip-text="$t('widgets.tooltip.payoutCurrency')"
          >
            <WidgetsMultiCurrencySelect :is-fiat-enabled="true"  :with-networks="false" :list="widget.payoutCryptoCurrenciesFrom" v-model="demo.payoutCryptoCurrenciesFrom" />
          </WidgetsConstructorItem>
        </div>
        <div class="inputWrapper constructorItem">
          <WidgetsConstructorItem
              :is-locked="demo.payoutCryptoCurrenciesFrom.length !== 1"
              :locked-text="$t('widgets.lockedAmount')"
              :label="$t('widgets.payoutAmount')"
              :field-label="'&payoutCryptoAmountFrom'"
              :tooltip-text="$t('widgets.tooltip.payoutAmount')"
          >
            <base-input v-model="demo.payoutCryptoAmountFrom" :placeholder="$t('widgets.enterSum')" />
          </WidgetsConstructorItem>
        </div>
        <div v-if="widget.payinCryptoInvoicePayerEmailAllow" class="inputWrapper constructorItem">
          <WidgetsConstructorItem
              :label="$t('widgets.email')"
              :is-column="true"
              :field-label="'&payoutCryptoReceiverEmail'"
              :tooltip-text="$t('widgets.tooltip.email')"
          >
            <base-input v-model="demo.payoutCryptoReceiverEmail" :placeholder="$t('widgets.enterEmail')" />
          </WidgetsConstructorItem>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.links {
  padding: 10px 10px 0 10px;
  @media (min-width: 850px) {
    display: none;
  }
}
.demoBanner {
  position: relative;
  width: 100%;
  height: 78px;
  border-radius: 10px;
  background: var(--bg-background-primary-purple, #6750A4);
  overflow: hidden;
  padding-left: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  &__img {
    position: absolute;
    bottom: -8px;
    right: 0;
  }
  &__title {
    color: var(--text-text-white, #FFF);
    text-align: center;
    font-family: $Gilroy;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    @media (max-width: 850px) {
      font-size: 28px;
    }
  }
  &__desc {
    color: var(--text-text-white, #FFF);
    text-align: center;
    font-family: $Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    margin-top: 12px;
    opacity: 0.6;
    width: 70%;
    @media (max-width: 850px) {
      font-size: 13px;
      width: 80%;
    }
  }
}
.constructor {
  position: relative;
  padding: 0 0 70px 0;
  &.doubleLinks {
    padding: 0 0 70px 0;
  }
  @media (max-width: 850px) {
    padding: 0 0 40px 0;
  }
  &__inner {
    padding: 30px 30px 0 30px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    @media (max-width: 850px) {
      padding: 10px 10px 0 10px;
    }
  }
  .toggleWrapper {
    &__title {
      color: var(--text-text-primary, #FFF);
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
    }
    &__toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .title {
    color: var(--text-text-primary, #FFF);
    font-size: 22px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 15px;
  }
  .theme {
    &-wrapper {
      border-radius: 10px;
      border: 1px solid var(--border-border-input-primary-default, #464646);
      background: var(--bg-background-input-primary, #2A2A2A);
      padding-top: 20px;
    }
  }
  .constructorItem {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px 20px;

    border-bottom: 1px solid var(--border-devider-primary);
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
  .colors {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0 15px 15px 15px;
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 54px;
      height: 54px;
      border-radius: 8px;
      border: 1.5px solid rgba(255, 255, 255, 0.15);
      background: var(--Linear-illustration, linear-gradient(153deg, #744DB2 -33.83%, #8D4CF5 33.16%, #744DB2 96.25%));
      cursor: pointer;
      &.addBtn {
        background: var(--bg-background-input-secondary, #333);

      }
    }
  }
  .inputWrapper {
    &__labels {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
    }
    &__label {
      color: var(--text-color-text-secondary);
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      align-items: center;
      gap: 5px;
      &-left {
        font-weight: 400;
      }
    }
  }
}
::v-deep {
  .base-input__wrapper input {
    border-radius: 10px;
    border: 1px solid var(--border-border-input-primary-default, #464646);
    background: var(--bg-background-input-secondary, #333);
    padding: 0 15px 0 15px;
    color: var(--text-text-primary, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .base-input__wrapper textarea {
    border-radius: 10px;
    border: 1px solid var(--border-border-input-primary-default, #464646);
    background: var(--bg-background-input-secondary, #333);
    padding: 15px 15px 0 15px;
    color: var(--text-text-primary, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .new-bh-select-wrapper .input-wrapper .options-list .checkbox svg {
    path {
      fill: var(--bg-background-input-secondary);
    }
  }
  .new-bh-select-wrapper .input-wrapper .options-list .options-list-item .prepend-info {
    margin-right: 0;
  }
  .new-bh-select-wrapper .input-wrapper .input {
    border-radius: 10px;
    border: 1px solid var(--border-border-input-primary-default, #464646);
    background: var(--bg-background-input-secondary, #333);
    padding: 0 15px 0 15px;
    color: var(--text-text-primary, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .new-bh-select-wrapper .input-wrapper.expanded .options-list {
    border-radius: 10px;
    border: 1px solid var(--border-border-input-primary-default, #464646);
    background: var(--bg-background-input-secondary, #333);

    /* Shadow modals */
    box-shadow: 0px 0px 30px 0px rgba(20, 20, 20, 0.15);
  }
  .new-bh-select-wrapper .input-wrapper .options-list .options-list-item.bordered-options {
    border-radius: 10px;
    border: 1px solid var(--border-border-input-primary-default, #464646);
    min-height: 48px;
    padding: 8px 9px;
  }
  .copy-wrapper {
    margin: 0;
  }

  .new-bh-select-wrapper .input-wrapper .options-list .options-list-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 5px;
    padding: 0 0;
    flex-direction: column;
    font-size: 16px;
    color: var(--text-text-primary, #FFF);
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    .counter {
      color: var(--text-color-text-secondary, #7E7E7E);
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }
  }
  .new-bh-select-wrapper .input-wrapper .input .placeholder {
    color: var(--text-color-text-secondary, #7E7E7E);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}

::v-deep {
  .mx-time-item  {
    background: var(--bg-background-input-secondary) !important;
    color: var(--new-front-primary-font-color) !important;
    .active {
      background: var(--bg-background-primary-purple, #6750A4) !important;
      color: #ffffff !important;

    }
    &:hover {
      background: var(--new-front-select-item-bg-active) !important;
    }
  }
  .mx-input {
    border-radius: 10px;
    border: 1px solid var(--border-border-input-primary-default, #464646);
    background: var(--bg-background-input-secondary, #333);
    padding: 0 15px 0 15px;
    color: var(--text-text-primary, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    min-height: 48px;
  }
  .mx-time-columns {
    background: var(--bg-background-input-secondary);
  }
  .new-order-modal__time-picker-popup .mx-time-column .mx-time-list::after {
    height: 0 !important;
  }
  .mx-datepicker-main {
    border: 1px solid var(--border-border-input-primary-default);
  }
  .mx-datepicker-popup {
    margin-top: 10px;
  }
  .new-order-modal__time-picker-popup>.mx-datepicker-content {
    background: var(--bg-background-input-secondary) !important;
  }
}

.topHeader{
  width: 100%;
  padding: 0 30px 30px;
  border-bottom: 1px solid var(--border-border-input-primary-default);
  @media (max-width: 850px) {
    padding: 30px 20px 20px;
    border-bottom: 0;
  }
  &__back {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    max-width: 72px;
    height: 28px;
    border-radius: 6px;
    background: var(--bg-background-table);
    color: var(--text-color-text-secondary);
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
    margin-bottom: 15px;
    cursor: pointer;
  }
  &__title {
    color: var(--text-text-primary, #FFF);
    font-family: $Gilroy;
    font-size: 62px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media (max-width: 850px) {
      font-size: 32px;
    }
  }
  &__desc {
    color: var(--text-color-text-secondary);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 10px;
    @media (max-width: 850px) {
      font-size: 13px;
    }
  }
}
</style>
