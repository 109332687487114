import getAllDepositsQuery from "/public/graphQl/queries/getAllDeposits";

export const getAllDeposits = async ({ commit, dispatch }, payload) => {

    let data;
    try {
        data = await dispatch('app/request', {
            type: 'query',
            gql: getAllDepositsQuery,
            variables: payload,
        }, { root: true });
    } catch (error) {
        return error;
    }
   // commit('CreatedDeposits', data.data.getAllDeposits);
    return data;
};