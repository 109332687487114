<template>
<div class="currency-network-icon-wrapper">
    <img
            v-if="currency"
            :src="currencyImg"
            :alt="currency"
            class="currency-network-icon__currency"
    />
</div>
</template>

<script>
    export default {
        name: "CurrencyIcon",
        props: {
            /**
             * Примеры пропса.
             * @param currency 'BCH' 'BUSD' 'LTC'
             */
            currency: {
                type: String,
                default: undefined
            },
        },
        computed: {
            currencyImg({ currency }) {
                return new URL(`../../../assets/image/advanced_balances/currencies/${currency.toUpperCase()}.svg`, import.meta.url).href;
            },
        },
    }
</script>

<style lang="scss">
.currency-network-icon-wrapper {
  position: relative;

  display: flex;

  .currency-network-icon__currency {
    width: 22px;
    height: 22px;

    border: none;
  }
  .currency-network-icon__network {
    width: 18px;
    height: 18px;

    position: absolute;

    top: 0;
    left: 0;

    transform: translate(-45%, -45%);
  }
}

</style>
