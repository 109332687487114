<template>
  <img v-if="ticker"
       class="currency-img"
       :src="currentCurrencyImg"
       :alt="currency"
  >
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: 'CurrencyImg',
    props: {
        currency: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters('app', [
            'getCurrencyNameById',
        ]),
        ticker() {
            const ticker = this.getCurrencyNameById(this.currency);

            return ticker === '(удалена)' ? this.currency : ticker;
        },
        currentCurrencyImg() {
            return `/assets/image/advanced_balances/currencies/${this.ticker.toUpperCase()}.svg`
        },
    },
};

</script>
