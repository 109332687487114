export const mutations = {
    setWebhookList(state, payload) {
        state.webhooksList = payload;
    },
    setWebhooksTotal(state, payload) {
        state.webhooksTotal = payload;
    },
    setWebhook(state, payload) {
        state.webhook = payload;
    },

    clearAllStates: (state) => {
        state.webhook = {}
        state.webhooksList = []
    },

};
