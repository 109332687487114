<script>
import BaseButton from '../../base/index.vue'

export default {
  name: "base-button",
  extends: BaseButton,
}
</script>

<style scoped lang="scss">
.base-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  cursor: pointer;
  border-radius: 10px;

  &.normal {
    height: 48px;
  }

  &.small {
    height: 34px;
  }

  .label {
    font-family: $Gilroy;

    font-style: normal;
    font-weight: 500;
    font-size: $h4;

    padding: 0 10px;

    white-space: nowrap;

    @include below_phone() {
      font-size: $h5;
    }
  }

  &.primary {
    background: var(--new-front-bg-btn-primary-bg-modal-primary);
    border: 1px solid var(--new-front-border-btn-primary-bg-modal-primary);

    .label {
      color: var(--new-front-text-btn-primary-bg-modal-primary);
    }

    &:hover {
      background: var(--new-front-bg-btn-primary-bg-modal-primary-hover);
      border: 1px solid var(--new-front-border-btn-primary-bg-modal-primary-hover);
    }
    &:active {
      background: var(--new-front-bg-btn-primary-bg-modal-primary-click);
      border: 1px solid var(--new-front-border-btn-primary-bg-modal-primary-click);
    }

    &.disabled {
      background: var(--new-front-bg-btn-primary-bg-modal-primary-disabled);
      border: 1px solid var(--new-front-border-btn-primary-bg-modal-primary-disabled);
      cursor: not-allowed;

      .label {
        color: var(--new-front-text-btn-primary-bg-modal-primary-disabled);
      }
    }
  }

  &.secondary {
    background: var(--new-front-bg-btn-secondary-bg-modal-primary);
    border: 1px solid var(--new-front-border-btn-secondary-bg-modal-primary);

    .label {
      color: var(--new-front-text-btn-secondary-bg-modal-primary);
    }

    &:hover {
      background: var(--new-front-bg-btn-secondary-bg-modal-primary-hover);
      border: 1px solid var(--new-front-border-btn-secondary-bg-modal-primary-hover);
    }
    &:active {
      background: var(--new-front-bg-btn-secondary-bg-modal-primary-click);
      border: 1px solid var(--new-front-border-btn-secondary-bg-modal-primary-click);
    }

    &.disabled {
      background: var(--new-front-bg-btn-secondary-bg-modal-primary-disabled);
      border: 1px solid var(--new-front-border-btn-secondary-bg-modal-primary-disabled);
      cursor: not-allowed;

      .label {
        color: var(--new-front-text-btn-secondary-bg-modal-primary-disabled);
      }
    }
  }
}
</style>
