import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!) {
        getOrganizationUsers(organizationId: $organizationId) {
            id
            email
            name
            role {
                id
                name
                isOwner
            }
            lastLoginAt
        }
    }
`;

