<template>
<BaseModal
    class="two-fa-confirmation"
    :class="{ 'loading': isLoading }"
    width="100%"
    :value="isOpen"
    :title="title"
    :show-close="true"
    :show-head="!isLoading"
    :show-footer="!isLoading"
    @on-confirm="handleCreate"
    @on-cancel="handleCancel"
>
  <template #header>
    <!--  полностью переписываем слот заголовка модалки-->
    <div class="two-fa-confirmation__header">
      {{ title }}
    </div>
  </template>
  <template v-if="isLoading">
    <preloader  />
  </template>
  <template v-else>
    <base-input
      v-model="code"
      class="amount-input item"
      :placeholder="$t('two-fa-confirmation.input-placeholder')"
      :label="$t('two-fa-confirmation.input-label')"
      :error="errorLabel"
      type="number"
      :maxLength="6"
    />
  </template>
  <template #footer>
    <base-button
        style="width: 100% !important;"
        type="secondary"
        class="button"
        :label="$t('two-fa-confirmation.cancel')"
        @click="handleCancel"
    />
    <base-button
        style="width: 100% !important;"
        type="primary"
        class="button"
        :label="$t('two-fa-confirmation.next')"
        @click="handleCreate"
    />
  </template>
</BaseModal>
</template>

<script>
    import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
  import BaseModal from "../BaseModal";
  import BaseInput from "../../common/uiKit/input/base";
  import Preloader from "@/public/components/common/uiKit/spinner/index.vue";
  import createRewardTransfer from "@/public/graphQl/mutations/createRewardTransfer";
  import {v4 as UUID} from "uuid";
    import {getOrganization} from "@/public/store/modules/organizations/actions/getOrganization";
    import {log} from "qrcode/lib/core/galois-field";

  export default {
    name: "TwoFaConfirmation",
    components: {
      Preloader,
      BaseInput,
      BaseModal,
      BaseButton: () => import(/* webpackChunkName: "@/public/components/common/uiKit/button/base/index.vue" */ '@/public/components/common/uiKit/button/base/index.vue')
    },
    props: {
      modalName: {
        type: String,
        default: 'CreateWithdrawalRequestModal'
      },
    },
    data() {
      return {
        code: '',
        textError: '',
        isLoading: false
      }
    },
    methods: {
      ...mapActions('app', [
        'request',
        'closeModal',
        'openModal'
      ]),
      ...mapActions('withdrawalRequestModal', {
        _createWithdrawal: 'createWithdrawal',
          _orphanCreateWithdrawal: 'createOrphanWithdrawal',
          clearFee: 'clearFee',
      }),
      ...mapActions('user', [
        'createApiKeys',
        'fetchApiKeys',
        'updateApiKeys',
        'removeApiKeys',
      ]),
      ...mapMutations('user', ['setCreatedApiKeys']),
        ...mapActions('collectingProfits', ['createSetting', 'updateSetting']),
        ...mapActions('collectingProfits', ['deleteCollectingSetting']),
        ...mapActions('collectingProfits', ['getCollectingSettings']),
        ...mapActions('organizations', ['changeOrganizationOwner', 'getOrganization']),
      handleCancel () {
        this.clearData()
        this.openModal({
          name: this.modalPayload.actionName || this.modalName,
          payload: {
            ...this.modalPayload,
          },
        });
      },
      async handleUpdateApiKeys () {
        const payload = {
          ...this.modalPayload,
          otpToken: this.code
        }

        const {
          $Notify,
        } = this;

        this.isLoading = true;
        await this.updateApiKeys(payload)
            .then(status => {
              if (status) {
                $Notify({
                  title: this.$t('copy.notification'),
                  message: this.$t('copy.api_key_successfully_updated'),
                  type: 'success',
                });
                this.fetchApiKeys();
                this.closeModal();
                this.$emit('closeModal')
              } else {
                $Notify({
                  title: this.$t('copy.notification'),
                  message: this.$t('copy.failed_to_update_api_key'),
                  type: 'error',
                });
              }
            }).catch(err => {
                this.$Message.error({
                    message: err,
                    duration: 5000,
                });
            }).finally(() => {this.isLoading = false;
              this.clearData()
            });
      },
        handleRemoveApiKeys() {
            const {
                modalPayload: {
                    apiKeysId,
                },
                removeApiKeys,
                fetchApiKeys,
                $Notify,
            } = this;
            const payload = {
                ...this.modalPayload,
                otpToken: this.code
            }
            this.isLoading = true;
            removeApiKeys(payload)
                .then(status => {
                    if (status) {
                        // $Notify({
                        //     title: this.$t('copy.notification'),
                        //     message: this.$t('copy.api_key_successfully_updated'),
                        //     type: 'success',
                        // });
                        this.fetchApiKeys();
                        this.closeModal();
                    } else {
                        $Notify({
                            title: this.$t('copy.notification'),
                            message: this.$t('copy.failed_to_update_api_key'),
                            type: 'error',
                        });
                    }
                }).catch(err => {
                this.$Message.error({
                    message: err,
                    duration: 5000,
                });
            }).finally(() => {
                this.isLoading = false;
                this.clearData()
            });
        },
      async handleCreteReferralWithdraw () {
          const id = UUID();
        const payload = {
          ...this.modalPayload,
          otpToken: this.code,
            key: id
        }
        this.isLoading = true;
        const { data: { createRewardTransfer: req } } = await this.request({
          type: 'mutation',
          gql: createRewardTransfer,
          variables: payload,
        });

        if (req.code === 0) {
          this.closeModal();
          this.$Message.success({
            message: this.$t('referral.success-withdraw'),
            duration: 10000,
          });
          this.isLoading = false;
        } else {
          this.clearData()
          this.closeModal();
          this.$Message.error({
            message: this.$t('referral.error-withdraw'),
            duration: 10000,
          });
          this.withdrawalCreating = false;
        }
      },
      async handleCreateApiKeys () {
        const payload = {
          ...this.modalPayload,
          otpToken: this.code
        }

        if (!payload.alias) {
          return false;
        }

        this.isLoading = true;

        await this.createApiKeys(payload)
            .then(req => {

                this.fetchApiKeys()
                this.clearData()
              this.setCreatedApiKeys(req)
              this.closeModal()
                // this.openModal({
                //     name: this.modalName,
                //     payload: {
                //         ...req,
                //     },
                // });
            })
            .catch(err => {
                this.$Message.error({
                message: err,
                duration: 5000,
              });
            })
            .finally(() => {
              this.clearData()
              this.isLoading = false;
            });
      },
      async handleCreateWithdrawal () {
        const payload = {
          ...this.modalPayload,
          otpToken: this.code
        }
        this.isLoading = true
        const res = await this._createWithdrawal(payload);
        this.isLoading = false

        if (this.isRequestComplete && this.error !== 'error.error_two-fa') {
            this.clearData()
          await this.openModal({
            name: this.modalName,
            payload: {
              ...this.modalPayload,
            },
          });
          // this.$Message.success({
          //   message: this.$t('create-withdrawal-request-modal.the_withdrawal_is_completed'),
          //   duration: 10000,
          // });
        }
      },
        async handleCreateOrphanWithdrawal () {
            const payload = {
                ...this.modalPayload,
                otpToken: this.code
            }
            this.isLoading = true
            const req = await this._orphanCreateWithdrawal(payload);
            this.isLoading = false
            if (this.isRequestComplete && this.error !== 'error.error_two-fa') {
                this.clearData()
                await this.openModal({
                    name: this.modalName,
                    payload: {
                        ...this.modalPayload,
                    },
                });
                // this.$Message.success({
                //   message: this.$t('create-withdrawal-request-modal.the_withdrawal_is_completed'),
                //   duration: 10000,
                // });
            }
        },
        async handleCreateCollectSettings () {
            const payload = {
                ...this.modalPayload,
                otpToken: this.code
            }
            this.isLoading = true
            const req = await this.createSetting(payload);
            this.isLoading = false
            if (this.settingError !== 'error.error_two-fa' && !this.isAppLoading) {
                await this.getCollectingSettings()
                this.clearData()
                await this.openModal({
                    name: this.modalName,
                    payload: {
                        ...this.modalPayload,
                    },
                })
            }
;
        },
        async handleUpdateCollectSettings () {
            const payload = {
                ...this.modalPayload,
                otpToken: this.code
            }
            this.isLoading = true
            const req = await this.updateSetting(payload);
            this.isLoading = false
            if (this.settingError !== 'error.error_two-fa') {
                this.clearData()
                await this.getCollectingSettings()
                this.openModal({
                    name: 'UpdateSettingsModal',
                    payload: {
                        ...this.modalPayload,
                    },
                })
            }
        },
        async handleDeleteSetting () {
          const payload = {
              ...this.modalPayload,
              otpToken: this.code
          }
          const req = await this.deleteCollectingSetting(payload)
          await this.getCollectingSettings()
            this.clearData()
            this.openModal({
                name: 'DeleteSettingModal',
                payload: {
                    ...this.modalPayload,
                },
            })
        },
        async handleCreateOrganizationTransfer () {
          const payload = {
              ...this.modalPayload,
              otpToken: this.code
          }
          await this.changeOrganizationOwner(payload)
              .then((data) => {
                  this.getOrganization()
                  this.clearData()
                  this.openModal({
                      name: 'TransferOrganizationModal',
                      payload: {
                          ...this.modalPayload,
                          success: true,
                          finish: true
                      },
                  })
              })
              .catch((data) => {
                  this.clearData()
                  if (data.twoFa) {
                      this.textError = data.message
                  } else {
                      this.openModal({
                          name: 'TransferOrganizationModal',
                          payload: {
                              ...this.modalPayload,
                              success: false,
                              finish: true,
                              traceId: data.message,
                          },
                      })
                  }
              })
        },
      async handleCreate () {
        if (this.code.length < 6) {
          this.textError = 'two-fa-confirmation.error_length'
          return
        }
        if (this.modalName === 'CreateNewApiKeysModal') {
          await this.handleCreateApiKeys()
          return
        }
        if (this.modalName === 'ReferralCreateWithdrawalRequestModalPayout') {
          await this.handleCreteReferralWithdraw()
          return
        }
        if (this.modalName === 'EditApiKeyModal') {
          await this.handleUpdateApiKeys()
          return
        }
          if (this.modalPayload.actionName === 'deleteCollectSetting') {
              await this.handleDeleteSetting()
              return
          }
          if (this.modalPayload.actionName === 'updateCollectionSetting') {
              await this.handleUpdateCollectSettings()
              return
          }
          if (this.modalName === 'CreateSettingsModal') {
              await this.handleCreateCollectSettings()
              return
          }
          if (this.modalName === 'OrphanCreateWithdrawalRequestModalPayout') {
              await this.handleCreateOrphanWithdrawal()
              return
          }
          if (this.modalName === 'OrphanCreateWithdrawalRequestModalPayout') {
              await this.handleCreateOrphanWithdrawal()
              return
          }
          if (this.modalName === 'TransferOrganizationModal') {
              await this.handleCreateOrganizationTransfer()
              return
          }
          if (this.modalName === 'RemoveApiKeysModal') {
              await this.handleRemoveApiKeys()
              return
          }

        await this.handleCreateWithdrawal()
      },
      clearData () {
        this.code = ''
        this.isLoading = false
        this.textError = ''
      }
    },
    computed: {
      ...mapGetters('app', [
        'isOpenModal',
      ]),
      ...mapState('user', {
        userOtpEnabled: state => state.user.otpToken,
      }),
      ...mapState('app', {
        modalPayload: state => state.modal.payload,
          isAppLoading: state => state.isLoading
      }),
      ...mapState('withdrawalRequestModal', {
        error: state => state.withdrawal.error,
        isRequestComplete: state => state.withdrawal.complete,
        status: state => state.withdrawal.status,
      }),
        ...mapState('collectingProfits', {
            settingError: state => state.setting?.errorMessage,
            settingCode: state => state.setting?.code
        }),
      errorLabel () {
        return this.$t(this.textError || this.error || this.settingError)
      },
      userTwoFactorAuth() {
        return !!this.userOtpEnabled;
      },
      title () {
        return this.$t('two-fa-confirmation.title')
      },
      isOpen() {
        const {
          $options: {name},
          isOpenModal,
        } = this;

        return isOpenModal(name);
      },
    }
  }
</script>

<style lang="scss">
.two-fa-confirmation {
  .at-modal {
    background: var(--new-front-modal-bg)!important;
  }
  .at-modal__header {
    background: transparent!important;
    border: 0!important;
    min-height: 100px!important;

    .at-modal__title {
      width: 100%;
    }
  }
  &.loading {
    .at-modal__body {
      min-height: 250px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    font-family: $Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: $h1;
    color: var(--new-front-primary-font-color);
    @media screen and  (max-width: 576px) {
      font-size: $h3;
    }
  }

  &__description {
    font-size: 16px;
    color: var(--primary-font-color);
  }
  .amount-input {
   &.item {
     width: 100%!important;
   }
  }
}
::v-deep {
  .search-input {
    background: var(--secondary-color) !important;
    border: 1px solid var(--border-color) !important;
    box-sizing: border-box !important;
    box-shadow: inset 0 4px 20px #00000008 !important;
    border-radius: 12px !important;
    font-family: var(--font-family) !important;
    font-size: var(--font-size-text-main) !important;
    line-height: var(--line-height) !important;
    color: var(--primary-font-color) !important;
  }
  .at-modal__body {
    min-height: 250px;
  }
  .label {
    color: var(--primary-font-color) !important;
    font-size: 16px !important;
  }
  div.error {
    display: none;
  }
  .subItem-block {
    .error {
      display: block !important;
    }
  }
}
::v-deep .base-btn {
  width: 100% !important;
}
</style>
