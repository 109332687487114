import getAccountBalancesGroupedPayin from "../../../../graphQl/queries/getAccountBalancesGroupedPayin";

export const getAccountBalancesGroupedQueryPayin = async ({commit, dispatch}, payload) => {

    let balancesData;
    // commit('app/toggleLoading', true, {root: true});
    try {
        balancesData = await dispatch('app/request', {
            type: 'query',
            gql: getAccountBalancesGroupedPayin,
            variables: payload,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        // commit('app/toggleLoading', null, {root: true});
    }

    commit('accountBalancesGroupedPayin', balancesData.data.getAccountBalancesGrouped);

    return balancesData;
};
