import getTransactionsQuery from "/public/graphQl/queries/getTransactions";
import {getRouteQuery} from "/public/utils/getRouteQuery";
import {getCurrenciesIdsByNames} from "/public/utils/getCurrenciesIdsByNames";
import {getFormatForUrlByArray} from "/public/utils/getFormatForUrlByArray";
import {getNetworksIdsByNames} from "../../../../utils/getNetworksIdsByNames";

export const fetchTransactions = async ({state, rootState, rootGetters, commit, dispatch}, payload) => {
    const {
        route,
        router,
        showUrlFilters = true,
        excludeParams = [],
        page,
        sortDate,
        type,
        currencyId,
        date,
        networks,
        tx
    } = payload;
    const {transactionsCountOnPage} = state;
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const variables = {
        organizationId,
        limit: transactionsCountOnPage,
        offset: page,
        sortDate,
        type,
        currencyId,
        networks,
        date,
        tx
    };
    let transactionsData;

    try {
        commit('app/toggleLoading', null, {root: true});
        transactionsData = await dispatch('app/request', {
            type: 'query',
            gql: getTransactionsQuery,
            variables,
        }, {root: true});
    } catch (error) {
        console.error(error);
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    // Если нет доступа или данных - незачем блокировать всю страницу
    // if (transactionsData.message) {
    //     throw transactionsData.message;
    // }
    //
    // if (transactionsData.errors) {
    //     throw transactionsData.errors;
    // }

    if (!transactionsData.message && !transactionsData.errors) {
        const {
            data: {
                getTransactions: {
                    transactions,
                    totalCount,
                },
            },
        } = transactionsData;

        commit('transactions', transactions);
        commit('totalTransactionsCount', totalCount);
    }

    return true;
};
