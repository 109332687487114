<template>
  <div class="transactions-sort-by-currency-from">
    <p class="transactions-sort-by-currency-from__title">
      Валюта
    </p>
    <AtSelect
        v-if="hasCurrencies"
        class="transactions-sort-by-currency-from__select"
        placeholder="Выберите валюту"

        clearable
        v-model="selectedCurrencies"
        @on-change="changeSelect"
    >
      <AtOption
          v-for="(currency, key) in currencies"
          :key="key"
          :value="currency.currency"
      >
        {{ currency.currency }}
      </AtOption>
    </AtSelect>
  </div>
</template>

<script>

import {mapState, mapGetters, mapActions} from "vuex";

import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';

export default {
    name: 'RollingReserveSortByCurrency',
    components: {
        AtSelect,
        AtOption,
    },
    data() {
        return {
            selectedCurrencies: [],
        };
    },
    computed: {
        ...mapState('app', [
            'currencies',
        ]),
        ...mapGetters('app', [
            'hasCurrencies',
        ]),
    },
    mounted() {
        this.setCurrenciesFromUrl();
    },
    methods: {
        ...mapActions('rollingReserve', [
            'fetchRollingReserves',
        ]),
        setCurrenciesFromUrl() {
            const currencies = this.$route.query.currencies;

            if (!currencies) {
                return false;
            }

            this.selectedCurrencies = currencies
                .split(',');
        },
        changeSelect() {
            const {
                $route,
                $router,
                fetchRollingReserves,
            } = this;

            fetchRollingReserves({
                route: $route,
                router: $router,
                currencies: this.selectedCurrencies,
                excludeParams: ['page'],
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.transactions-sort-by-currency-from {
  &__title {
    margin-bottom: 8px;
    font-size: var(--font-size-text-main);
    font-family: var(--font-family);
    line-height: var(--line-height);
    color: var(--black-200);
  }

  &__select ::v-deep .at-select__selection {
    min-height: 28px;
  }

  ::v-deep .at-select__dropdown .at-select__option {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

  ::v-deep .at-select__placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }

  ::v-deep .at-select__selection {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

}

</style>
