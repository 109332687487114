import VueRouter from 'vue-router';
import {routes} from './routes';
import {i18n} from '../i18n';
import Vue from "vue";

Vue.use(VueRouter);

export const router = new VueRouter({
    mode: 'history',
    // linkActiveClass: 'is-active',
    // linkExactActiveClass: 'is-exact-active',
    routes,
});

export default router;

router.updateTitle = () => {
    const route = router.currentRoute;
    const componentTitle = route.matched?.[0]?.components?.default?.title;

    document.title = componentTitle ? i18n.t(componentTitle) : 'Page';
};

router.afterEach(() => {
    router.updateTitle();

    if(window.$$checkRoute) {
        window.$$checkRoute();
    }
});

const DEFAULT_TITLE = import.meta.env.VITE_PRODUCT_NAME;
router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

