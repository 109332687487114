import deleteWidgetQuery from "../../../../graphQl/mutations/deleteWidget";

export const deleteWidget = async ({commit, dispatch, rootGetters }, widgetId) => {
    let deleteWidgetData;

    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    const variables = {
        organizationId,
        widgetId,
    };

    try {
        commit('setLoading', true);
        deleteWidgetData = await dispatch('app/request', {
            type: 'mutation',
            gql: deleteWidgetQuery,
            variables,
        }, { root: true });
    } catch (error) {
        return error;
    } finally {
        commit('setLoading', false);
    }

    if (deleteWidgetData.message) {
        throw deleteWidgetData.message;
    }

    if (deleteWidgetData.errors) {
        throw deleteWidgetData.errors;
    }

    const {
        data: { IFrameWidgetDeleteResponse  },
    } = deleteWidgetData ;

    return IFrameWidgetDeleteResponse ;
};
