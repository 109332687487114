<template>
  <div class="swap-modal-wrapper">
    <store-modal
      :name="$options.name"
      :loading="commissionLoading || processing"
    >
      <template #header>
        {{ $t('swap.modals.execute.title') }}
      </template>
      <template #body>
        <div class="first-line">
          <div class="info-item long">
            <div class="label">
              {{ $t('swap.modals.execute.currency') }}
            </div>
            <div class="value">
              <currency-img :currency="currencyFromName"/>
              <span class="currency-alias">
                {{ currencyFrom.alias }}
              </span>
              <span class="currency-ticker">
                {{ (networkFromName || '') | getNetworkShortNameByNetworkName }}
              </span>
            </div>
          </div>
          <div class="info-item">
            <div class="label">
              {{ $t('swap.modals.execute.amount') }}
            </div>
            <div class="value">
              {{ amountFrom }}
            </div>
          </div>
          <div class="info-item">
            <div class="label">
              {{ $t('swap.modals.execute.address') }}
            </div>
            <div class="value">
              {{ addressFrom }}
            </div>
          </div>
        </div>
        <div class="cross-chain-modal-divider">
          <div class="central-swap-icon">
            <icon
              class="icon"
              name="arrow_down"
              color="#8B81A0"
            />
          </div>
        </div>
        <div class="second-line">
          <div class="info-item long">
            <div class="label">
              {{ $t('swap.modals.execute.currency') }}
            </div>
            <div class="value">
              <currency-img :currency="currencyToName"/>
              <span class="currency-alias">
                {{ currencyTo.alias }}
              </span>
              <span class="currency-ticker">
                {{ (networkToName || '') | getNetworkShortNameByNetworkName }}
              </span>
            </div>
          </div>
          <div class="info-item">
            <div class="label">
              {{ $t('swap.modals.execute.amount') }}
            </div>
            <div class="value">
              {{ amountTo }}
            </div>
          </div>
          <div class="info-item">
            <div class="label">
              {{ $t('swap.modals.execute.address') }}
            </div>
            <div class="value">
              {{ addressTo }}
            </div>
          </div>
        </div>
        <div class="commissions">
          <div class="line">
            <div class="amount">
              <div class="left">{{ $t('swap.modals.execute.service_commission') }}</div>
              <div class="right">{{ !!feeToken.serviceFeeUSD ? prettyAmountWithHideZeros( Number(feeToken.serviceFeeUSD), 3 ) : 0 }} USD</div>
            </div>
            <div class="source">
              <div class="left">{{ $t('swap.modals.execute.source') }}</div>
              <div class="right">{{ $t('swap.modals.execute.advance_balance') }}</div>
            </div>
          </div>
          <div class="line">
            <div class="amount">
              <div class="left">{{ $t('swap.modals.execute.network_commission') }}</div>
              <div class="right">{{ !!blockchainFee ? prettyAmountWithHideZeros(blockchainFee, 3) : 0 }} USD</div>
            </div>
            <div class="source">
              <div class="left">{{ $t('swap.modals.execute.source') }}</div>
              <div class="right">{{ $t('swap.modals.execute.swap_amount') }}</div>
            </div>
          </div>
          <div class="line last">
            <div class="amount">
              <div class="left">{{ $t('swap.modals.execute.you_receive') }}</div>
              <div class="right">
                ≈ {{ amountTo }} {{ currencyToName }} <span class="timer"> ({{ timer }}s) </span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="actions">
          <base-button
            :label="$t('swap.modals.execute.cancel_btn')"
            type="secondary"
            class="action-item"
            @click="handleClose"
          />
          <base-button
            :label="$t('swap.modals.execute.confirm')"
            type="primary"
            :disabled="commissionLoading || processing"
            class="action-item"
            @click="confirm"
          />
        </div>
      </template>
    </store-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import Icon from "/public/components/common/icon";

import {
  getPrettyAddress, prettyAmountWithHideZeros
} from "/public/filters";

export default {
  name: "SwapModal",
  components: {
    Icon,
    BaseButton: () => import(/* webpackChunkName: "public/components/common/uiKit/button/base/index.vue" */ '/public/components/common/uiKit/button/base/index.vue'),
    StoreModal: () => import(/* webpackChunkName: "public/components/modals/StoreModal" */ '/public/components/modals/StoreModal'),
    CurrencyImg: () => import(/* webpackChunkName: "public/components/common/uiKit/currencyImg" */ '/public/components/common/uiKit/currencyImg'),
  },
  data() {
    return {
      processing: false
    }
  },
  props: {
    commissionLoading: {
      type: Boolean,
      default: false
    },
    feeToken: {
      type: Object,
      default: () => ({})
    },
    timer: {
      type: Number,
      default: undefined
    }
  },
  computed: {
    ...mapGetters('app', [
      'isOpenModal',
    ]),
    ...mapState('app', {
      payload: state => state.modal.payload,
    }),
    ...mapState('accounts', ['accounts']),
    accountId({ accounts }) {
      return accounts.find(({ accountId }) => !!accountId).accountId
    },
    amountFrom({ payload }) {
      return payload.amountFrom
    },
    amountTo({ payload }) {
      return payload.amountTo
    },
    addressFrom({ payload }) {
      return payload.addressFrom ? getPrettyAddress(payload.addressFrom.address) : undefined
    },
    blockchainFee({ feeToken = {} }) {
      if (feeToken.blockchainFeeFromUSD && feeToken.blockchainFeeToUSD) {
        return Number(feeToken.blockchainFeeFromUSD) + Number(feeToken.blockchainFeeToUSD)
      }
    },
    currencyAndNetworkFrom({ payload }) {
      return payload.currencyAndNetworkFrom
    },
    currencyAndNetworkTo({ payload }) {
      return payload.currencyAndNetworkTo
    },
    currencyFrom({ payload }) {
      const { currency } = payload.currencyAndNetworkFrom || {}

      return currency
    },
    currencyTo({ payload }) {
      const { currency } = payload.currencyAndNetworkTo || {}

      return currency
    },
    currencyFromName({ payload }) {
      const { currency } = payload.currencyAndNetworkFrom || {}

      return currency?.currency
    },
    currencyToName({ payload }) {
      const { currency } = payload.currencyAndNetworkTo || {}

      return currency?.currency
    },
    networkFrom({ payload }) {
      const { network } = payload.currencyAndNetworkFrom || {}
      return network
    },
    networkFromName({ networkFrom = {} }) {
      return networkFrom?.name
    },
    networkTo({ payload }) {
      return payload.networkTo || {}
    },
    networkToName({ networkTo = {} }) {
      return networkTo?.name
    },
    addressTo({ payload }) {
      return payload.addressTo ? getPrettyAddress(payload.addressTo.address) : undefined
    },
    isOpen() {
      const {
        $options: {
          name,
        },
        isOpenModal,
      } = this;

      return isOpenModal(name);
    },
    serviceCommission({ payload }) {
      return payload.serviceCommission
    }
  },
  methods: {
    prettyAmountWithHideZeros,
    ...mapActions('app', [
      'closeModal',
    ]),
    ...mapActions('swap', [
      'setSwapTransfer'
    ]),
    handleClose() {
      this.closeModal();
    },
    async confirm() {
      this.processing = true

      const payload = {
        accountId: this.accountId,
        clientId: null,
        addressFromId: this.payload.addressFrom.id,
        addressToId: this.payload.addressTo.id,
        token: this.feeToken.token,
      }

      const result = await this.setSwapTransfer(payload)

      this.processing = false

      this.$emit("swap-result", result)
      this.handleClose()
    }
  }
}
</script>

<style lang="scss">
  .swap-modal-wrapper {
    position: relative;

    font-family: var(--new-front-font-family);

    &.loading {
      .main-modal-header {
        opacity: 0.5
      }
      .main-modal-body {
        opacity: 0.5
      }
      .main-modal-footer {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .main-modal-header {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      font-size: $h1;
      font-weight: 500;
      color: var(--new-front-text-primary-bg-modal-primary);
    }

    .main-modal-body {
      padding: 0;

      .cross-chain-modal-divider {
        position: relative;

        height: 1px;
        width: 100%;
        margin: 40px 0;

        background: var(--new-front-divider-color);

        .central-swap-icon {
          position: absolute;
          width: 60px;
          height: 60px;
          left: 50%;
          top: 50%;

          background: var(--new-front-divider-color);
          border-radius: $h6;
          transform: translate(-50%, -50%) rotate(-45deg);

          .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }
      }

      .first-line, .second-line {
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;

        padding: 20px 52px;

        .info-item {
          width: 22%;

          &.long {
            width: 43%;
          }

          .label, .value {
            display: flex;
            align-items: center;

            gap: 7px;

            font-style: normal;
          }
          .label {
            color: var(--new-front-text-secondary-bg-modal-primary);
            font-size: $h6;
            line-height: $h6;
            margin-bottom: 10px;

            font-weight: 400;
          }
          .value {
            color: var(--new-front-text-primary-bg-modal-primary);
            font-size: $h4;
            line-height: $h4;

            font-weight: 500;

            .currency-ticker {
              color: var(--new-front-text-secondary-bg-modal-primary)
            }
          }
        }
      }

      .commissions {
        padding: 20px 0;

        .line {
          display: flex;
          flex-direction: column;
          gap: 5px;

          padding: 10px 50px;
          border-top: 1px dotted var(--new-front-stroke-modal-bg-primary);
          &.last {
            border-bottom: 1px dotted var(--new-front-stroke-modal-bg-primary);
          }

          font-size: $h4;
          font-weight: 500;

          .amount, .source {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left {
              text-align: left;
            }
            .right {
              text-align: right;
            }
          }
          .amount {
            color: var(--new-front-text-primary-bg-modal-primary);
          }
          .source {
            color: var(--new-front-text-secondary-bg-modal-primary);
          }
        }
      }
    }

    .main-modal-footer {
      .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        gap: 20px;

        padding: 0 10px 20px;

        .action-item {
          max-width: 320px;
        }
      }
    }

    @include below_phone() {
      .main-modal-header {
        font-size: $h3;
      }
      .main-modal-body {
        .first-line, .second-line {
          padding: 10px 30px;

          .info-item {
            .label {
              font-size: $h8;
              line-height: $h8;
            }
            .value {
              font-size: $h6;
              line-height: $h6;
            }
          }
        }
        .commissions {
          .line {
            padding: 10px 30px;

            font-size: $h6;
            line-height: $h6;
          }
        }
      }
    }
  }
</style>