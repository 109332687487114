import advancedBalanceSubscription from "@/public/graphQl/subscriptions/advancedBalanceSubscription";

import {apiClient} from "@/public/graphQl/apiClient";

export const accountsSubscriptions = async ({ state, commit, dispatch, rootGetters }, payload) => {
    const client = apiClient

    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const sub$ = await client.subscribe({
        query: advancedBalanceSubscription,
        variables: {
            organizationId
        }
    })

    const updatesSub = sub$.subscribe({
        next: ({ data: { advancedBalanceUpdate } }) => {
            commit('accounts/setAccountBalance', advancedBalanceUpdate, {root: true})
        },
    });

    commit('setUpdatesSub', updatesSub);
}
