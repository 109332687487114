<template>
    <div class="test-api">
        <h1 class="test-api__title">
            Api
        </h1>
        <ApiKeys class="test-api__keys" />
        <ApiNav class="test-api__nav" />
        <RouterView />
    </div>
</template>

<script>

import ApiKeys from "/public/components/elements/test-api/ApiKeys";
import ApiNav from "/public/components/elements/test-api/ApiNav";

export default {
    name: 'TestApiPage',
    components: {
        ApiKeys,
        ApiNav,
    },
};

</script>

<style lang="scss" scoped>

    .test-api {

        &__title {
            margin-bottom: 24px;
            @include h1;
        }

        &__keys {
            margin-bottom: 36px;
        }

        &__nav {
            margin-bottom: 32px;
        }
    }

</style>