<script>
  import Copy from "@/public/components/common/copy.vue";
  import Preloader from "/public/components/common/uiKit/spinner/index.vue";
  import {mapGetters, mapMutations, mapState} from "vuex";
  import CurrencyIcon from "@/public/components/common/uiKit/CurrencyIcon.vue";
  import BigNumber from "bignumber.js";
  import {
    getCurrencyInfoById,
    getCurrencyTicker,
    getNetworkSystemName,
    transactionHistoryBasis, transactionHistorySource,
    transactionHistoryStatus,
    transactionHistoryType
  } from "@/public/filters";
  import NetworkIcon from "@/public/components/common/uiKit/NetworkIcon.vue";
  import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
  import {TRANSACTION_HISTORY_STATUS_ENUM} from "@/common/constants/transaction-history-status-enum";
  import {BlockchainLinks} from "@/public/mixins/blockchain-links";
  import CurrencyNetworkIcon from "@/public/components/elements/business-walet/Select/CurrencyNetworkIcon.vue";
  export default {
    name: "BankInvoiceInfo",
    props: {
      statusEnum: {
        type: Object,
        required: false,
      },
    },
    mixins: [
      BlockchainLinks,
    ],
    components: {CurrencyNetworkIcon, BaseButton, NetworkIcon, CurrencyIcon, Copy, Preloader},
    computed: {
      ...mapState('sepaSwift', ['isLoading', 'account']),
      ...mapState('app', ['networks', 'currencies']),
      ...mapGetters('app', ['getCurrencyNameById']),
      currency () {
        return this.currencies.find(({ id }) => id === this.account.currencyId)
      },
    },
    methods: {
      ...mapMutations('sepaSwift', ['setBankAccountInfoState', 'setBankAccount']),
      closeInfo () {
        this.setBankAccountInfoState(false)
        this.setBankAccount(null)
      },
    }
  }
</script>

<template>
<div class="info">
  <div class="info__bg" @click="closeInfo"></div>
  <div  class="info__content">
    <div class="info__close" @click="closeInfo">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.712087 0.712087C1.0782 0.345971 1.6718 0.345971 2.03791 0.712087L7 5.67418L11.9621 0.712087C12.3282 0.345971 12.9218 0.345971 13.2879 0.712087C13.654 1.0782 13.654 1.6718 13.2879 2.03791L8.32582 7L13.2879 11.9621C13.654 12.3282 13.654 12.9218 13.2879 13.2879C12.9218 13.654 12.3282 13.654 11.9621 13.2879L7 8.32582L2.03791 13.2879C1.6718 13.654 1.0782 13.654 0.712087 13.2879C0.345971 12.9218 0.345971 12.3282 0.712087 11.9621L5.67418 7L0.712087 2.03791C0.345971 1.6718 0.345971 1.0782 0.712087 0.712087Z" fill="var(--new-front-primary-font-color)"/>
      </svg>
    </div>
    <transition name="slide-fade" mode="out-in">
      <div v-if="!isLoading || account">
        <div class="info__header">
          <div class="info__header-topRow">
            <div class="info__header-title">{{ $t('bank.info-title') }}</div>
          </div>
        </div>
        <div class="info__item">
          <div class="info__item-title">
            {{ $t('bank.alias') }}:
          </div>
          <div class="info__item-value">
            {{ account.alias }}
          </div>
        </div>
        <div class="info__item">
          <div class="info__item-title">
            {{ $t('bank.type') }}:
          </div>
          <div class="info__item-value">
            {{ account.type }}
          </div>
        </div>
        <div class="info__item">
          <div class="info__item-title">
            {{ $t('business-wallet.balance.currency') }}:
          </div>
          <div class="info__item-value">
            <currency-network-icon :currency="currency.currency" />
            <div class="item__asset-currency">
              {{ currency.currency }}
            </div>
          </div>
        </div>
        <div class="info__item">
          <div class="info__item-title">
            {{ $t('bank.company') }}:
          </div>
          <div class="info__item-value">
            {{ account.companyName }}
          </div>
        </div>
        <div class="info__item">
          <div class="info__item-title">
            {{ $t('bank.account') }}:
          </div>
          <div class="info__item-value">
            {{ account.account }}
          </div>
        </div>
        <div class="info__item">
          <div class="info__item-title">
            IBAN:
          </div>
          <div class="info__item-value">
            {{ account.iban }}
          </div>
        </div>
        <div class="info__item">
          <div class="info__item-title">
            {{ $t('bank.bank-address') }}:
          </div>
          <div class="info__item-value">
            {{ account.bankAddress }}
          </div>
        </div>
        <div class="info__item">
          <div class="info__item-title">
            {{ $t('bank.bank') }}:
          </div>
          <div class="info__item-value">
            {{ account.bankName }}
          </div>
        </div>
        <div class="info__item">
          <div class="info__item-title">
            {{ $t('bank.bank-code') }}:
          </div>
          <div class="info__item-value">
            {{ account.bankCountryCode }}
          </div>
        </div>
        <div class="info__item">
          <div class="info__item-title">
            SWIFT/BIC:
          </div>
          <div class="info__item-value">
            {{ account.bic }}
          </div>
        </div>
        <div class="info__item">
          <div class="info__item-title">
            {{ $t('bank.postal-code') }}:
          </div>
          <div class="info__item-value">
            {{ account.postalCode }}
          </div>
        </div>
        <div class="info__item">
          <div class="info__item-title">
            {{ $t('bank.status') }}:
          </div>
          <div class="info__item-value">
            <div :class="['statusLabel', `statusLabel--${ account.status.toLowerCase() }`]">
              {{ $t(`bank.${account.status}`) }}
            </div>
          </div>
        </div>
      </div>
      <preloader v-else />
    </transition>
  </div>
</div>
</template>

<style scoped lang="scss">
.info {
  font-family: $Gilroy;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  // Огромный z-index для перекрытия виджета интеркома
  z-index: 2147483001;
  &__rate-icon {
    margin: 0 10px;
  }
  &__btn {
    margin-top: 50px;
  }

  &__close {
    position: absolute;
    top: 30px;
    cursor: pointer;
  }
  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .50);
    height: 100vh;
    width: 100vw;
    z-index: 1;
    cursor: pointer;
  }
  &__content {
    width: 530px;
    height: 100vh;
    box-sizing: border-box;
    padding: 100px 50px 30px 50px;
    background: var(--new-front-main-bg);
    position: absolute;
    right: 0;
    z-index: 2;
    overflow: auto;
  }
  &__item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 58px;
    border-bottom: 1px solid var(--new-front-divider-color);
    &:last-of-type {
      border-bottom: 0;
    }
    &-title {
      font-size: $h4;
      font-weight: 500;
      color: var(--new-front-secondary-font-color);
    }
    &-value {
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 500;
      font-size: $h4;
      color: var(--new-front-primary-font-color);
      &--secondary {
        color: var(--new-front-secondary-font-color);
      }
      &--link {
        color: var(--new-front-purple);
        text-decoration: underline;
        a {
          color: var(--new-front-purple);
        }
        &::v-deep {
          .copy-wrapper {
            margin-right: 5px !important;
          }
        }
      }
    }
  }
  &__header {
    margin-bottom: 20px;
    &-topRow {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-title {
      font-size: $h1;
      color: var(--new-front-primary-font-color)
    }
    &-subtitle {
      margin-top: 5px;
      font-size: $h4;
      color: var(--new-front-secondary-font-color)
    }
  }
}
.statusLabel {
  padding: 4px $p15;
  background: var(--new-front-status-processed);;
  color: var(--new-front-status-text-processed);
  border-radius: 10px;
  font-size: $h4;
  font-weight: 500;
  &--reject {
    background: var(--new-front-status-error);
    color: var(--new-front-status-text-error);
  }
  &--moderation {
    background: var(--new-front-status-pending);
    color: var(--new-front-status-text-pending);
  }
  &--pre_moderation {
    color: var(--new-front-status-text-expired);
    background: var(--new-front-status-expired);
  }
  &--success {
    background: var(--new-front-status-processed);
    color: var(--new-front-status-text-processed);
  }
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
::v-deep .copy-wrapper {
  margin: 0;
}
@media screen and (max-width: 576px) {
  .info__header-title {
    font-size: $h2;
  }
  .info__item-title {
    font-size: $h5;
  }
  .info__item-value {
    font-size: $h5;
  }
  .info__header-subtitle {
    font-size: $h5;
  }
  .info__content {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 70px 20px 30px;
    overflow: auto;
  }
}
</style>
