import addressBalanceSubscription from "@/public/graphQl/subscriptions/addressBalanceSubscription";
import {apiClient} from "@/public/graphQl/apiClient";


export const addressBalancesSubscription = async ({commit, dispatch, rootGetters}, payload) => {
    const client = apiClient;

    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const sub$ = await client.subscribe({
        query: addressBalanceSubscription,
        variables: {
            organizationId
        }
    })

    const updatesSub = sub$.subscribe({
        next: ({ data: { addressBalanceUpdate } }) => {
            commit('balances/setLastUpdateAddress', addressBalanceUpdate, {root: true})

            if (addressBalanceUpdate.type === 'PAY_IN' || addressBalanceUpdate.type === 'BUSINESS' || addressBalanceUpdate.type === 'PERSONAL') {
                commit('balances/setNewAddressBalance', addressBalanceUpdate, {root: true})
            }
            if (addressBalanceUpdate.type === 'RECURRENT') {
                commit('subscriptions/updateAddresses', addressBalanceUpdate, {root: true})
            }
            if (addressBalanceUpdate.type === 'PAY_OUT') {
                commit('payoutBalances/updateAddresses', addressBalanceUpdate, {root: true})
            }
        },
    });

    commit('setUpdatesSub', updatesSub);
}
