<template>
    <div>
        <div class="organization-list-header">
            <div class="organization-list-header__item list-header__item--sorting">
                {{ $t('organization.organization_user') }}
            </div>
            <div class="organization-list-header__item">
                {{ $t('organization.organization_role') }}
            </div>
            <div class="organization-list-header__item">
                {{ $t('organization.organization_last_login') }}
            </div>
            <div class="organization-list-header__item"></div>
        </div>
<!--        <div class="organization-list-header_mobile">-->
<!--            <div class="organization-list-header__item list-header__item&#45;&#45;sorting">-->
<!--                {{ $t('organization.organization_user_and_role') }}-->
<!--            </div>-->
<!--            <div class="organization-list-header__item">-->
<!--                {{ $t('organization.organization_last_login') }}-->
<!--            </div>-->
<!--            <div class="organization-list-header__item"></div>-->
<!--        </div>-->
    </div>
</template>

<script>

export default {
    name: 'OrganisationListHeader',
};

</script>

<style lang="scss" scoped>
.organization-list-header {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr .75fr;
  align-items: center;
    background: var(--new-front-select-item-bg-active);
    border-radius: 10px;
  @media screen and (max-width: 768px) {
    display: none;
  }

  &_mobile {
    display: none;
    padding: 0;
    grid-template-columns: 48% 33% 19%;
    align-items: center;

    @media screen and (max-width: 768px) {
      display: grid;
    }
  }

  &__item {
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    //line-height: 16px;
    min-height: 56px;
    //border-top: 1px solid var(--new-front-divider-color);
    //border-bottom: 1px solid var(--border-color);
    text-align: start;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--new-front-secondary-font-color);

    @media screen and (max-width: 768px) {
      font-size: 12px;
      line-height: 14px;
      min-height: 28px;
    }

    &:first-of-type {
      padding-left: 50px;

      @media screen and (max-width: 768px) {
        padding-left: 0;
      }
    }

    &:last-of-type {
      padding-right: 50px;
      justify-content: flex-end;

      @media screen and (max-width: 768px) {
        padding-right: 0;
      }
    }

    &-text {
      font-family: $Gilroy;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;

      &_deposit {
        color: var(--green-200);
      }

      &_credit {
        color: var(--red-200);
      }
    }
  }
}

@media screen and (max-width: 1330px) {
  .transactions-list-header {
    &__item {
      &:first-of-type {
        padding-left: 15px;
      }

      &:last-of-type {
        padding-right: 15px;
      }
    }
  }

}

@media screen and (max-width: 950px) {
  .transactions-list-header {
    display: none;

    &_mobile {
      display: block;
    }
  }
}
</style>
