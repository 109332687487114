import gql from 'graphql-tag';

export default gql`
     mutation($organizationId: String!, $addressId: String!, $alias: String, $comment: String) {
         updatePayoutAddressInfo(organizationId: $organizationId, addressId: $addressId, alias: $alias, comment: $comment){
            code
            result {
                id
                currencyId
                networkId
                address
                tag
                balance
                nativeBalance
                alias
                comment
                riskLevel
                isBanned
                isActive
                useForAutoSwap
            }
         }
    }
`;
