import apiClient from "/public/graphQl/apiClient";

export const request = async (_, payload) => {
    const {
        type,
        gql,
        variables = {},
    } = payload;
    let data;
    let requestData = null;

    if (type === 'query') {
        requestData = {
            type: 'query',
            key: 'query',
        };
    } else if (type === 'mutation') {
        requestData = {
            type: 'mutate',
            key: 'mutation',
        };
    }

    if (!requestData) {
        throw false;
    }

    try {
        data = await apiClient[requestData.type]({
            [requestData.key]: gql,
            variables,
        });
    } catch (error) {
        console.error('Ошибка запроса', error);
        return error;
    }

    return data;
};
