import getRegistryData from "../../../../graphQl/queries/getRegistryData";
import _ from "lodash";

export const fetchRegistry = async ({dispatch, commit, rootState}, payload) => {

    let orderData;

    try {
        // commit('app/toggleLoading', null, {root: true});
        orderData = await dispatch('app/request', {
            type: 'query',
            gql: getRegistryData,
            variables: payload,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        // commit('app/toggleLoading', null, {root: true});
    }

    if (orderData.message) {
        throw orderData.message;
    }

    if (orderData.errors) {
        throw orderData.errors;
    }

    const {
        data: {getRegistry: {items, registry}},
    } = orderData;
    const accountId = rootState.accounts.accounts[0].accountId;
    const feeData = await dispatch('fetchFeeForRegistry', {accountId, registryId: registry.id});
    const {
        data: {requestFeeForRegistry: {code, result}},
    } = feeData;


    items.map((item) => {
        const feeDataItem = result.find((fee) => fee.itemId === item.id);
        item.fee = {...feeDataItem.fee};
        return item;
    },
    );
    commit('setRegistriesItems', {items, registry});
    return orderData;
};