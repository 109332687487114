export const state = {
    loading: false,
    accounts: [],
    accountData: [],

    updatesSub: undefined,

    history: {
        onPage: 10,
        total: 0,
        list: [],
    },

    filters: {
        type: [],
    },
    deposit: {
        loading: false,
        created: false,

        code: 0,
        result: {
            address: '',
            tag: '',
            until: '',
            from: ''
        },
    },
    tariffs: [],
    sumBalance: 0,
};
