export const getters = {
    balances: state => {
        let balances = [];
        for (const currency of state.balancesGrouped.currencyGroups) {
            for (const currencyNetwork of currency.networkGroups) {
                for (const balanceAddress of currencyNetwork.addresses) {
                    balances.push(balanceAddress);
                }
            }
        }
        return balances;
    },
};