<template>
  <div
      class="advanced_balances__network-currency-list-item"
      :class="{ active }"
      :style="{ height }"
  >
    <div class="left-block">
      <icon
        class="icon"
        :currency="currencyName"
        :network="networkName"
      />
      <div class="currency-and-network-info-block">
        <div class="currency-alias" v-if="!isNetwork">
            {{ title }}
          <span v-if="currencyName && showCurrencyName" class="secondary-text">({{ currencyName }})</span>
          <span v-if="ticker && !showCurrencyName" class="secondary-text">({{ ticker }})</span>
        </div>
        <div class="currency-alias" v-else>
            {{ name | getNetworkFullNameByNetworkName }}<span class="secondary-text">{{ name | getNetworkShortNameByNetworkName }}</span>
        </div>
      </div>
    </div>
    <div class="right-block" />
  </div>
</template>

<script>

export default {
  name: "NetworkAndCurrencyItem",
  components: {
    Icon: () => import(/* webpackChunkName: "/public/components/elements/advance-balances/NetworkAndCurrenciesSelect/CurrencyNetworkIcon" */ '/public/components/elements/advance-balances/NetworkAndCurrenciesSelect/CurrencyNetworkIcon'),
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    alias: {
      type: String,
      default: ''
    },
    label: {
      name: String,
        default: ''
    },
    name: {
      type: String,
        default: ''
    },
    ticker: {
      type: String,
      default: ''
    },
    currency: {
      type: String,
      default: ''
    },
    nativeBalance: {
      type: String,
        default: ''
    },
    nativeBalanceTicker: {
      type: String,
        default: ''
    },
    networks: {
      type: [Object, Array],
      default: () => ({})
    },
    __typename: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '48px'
    },
    showCurrencyName: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isNetwork ({ __typename }) {
      return __typename === 'Network'
    },
    title ({ label, alias }) {
      if (label) return label
      return alias;
    },
    currencyName({ currency }) {
        return currency;
    },
    networkAlias({ networks: { alias }}) {
      return alias;
    },
    networkName({ name }) {
      return name ? name.toUpperCase() : '';
    },
      isNativeBalance ({ nativeBalance, nativeBalanceTicker }) {
        return nativeBalance && nativeBalanceTicker
      }
  },
}
</script>

<style lang="scss">
.secondary-text {
  color: var(--new-front-secondary-font-color);
  margin-left: 5px;
}
.advanced_balances {
  &__network-currency-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    //background-color: var(--dashboard-secondary-bg-color);

    &.active {
      background-color: var(--list-item-active);
    }

    //border: 1px solid var(--border-color);
    //border-radius: 10px;
    //padding: 16px 10px;

    color: var(--primary-font-color);
    cursor: pointer;

    .left-block {
      display: flex;
      align-items: center;

      .currency-and-network-info-block {
        display: flex;
        align-items: center;

        margin-left: 10px;

        .currency-alias {
          color: var(--new-front-primary-font-color);
          font-size: $h4;
          font-weight: 500;
        }
        .network-alias {
          color: var(--secondary-font-color);
          font-size: $h4;
          margin-left: 6px;
        }
      }
    }
    .right-block {
      display: flex;
      align-items: center;
      justify-content: end;

      .check-block {
        display: flex;
        align-items: center;
        justify-content: center;

        min-width: 20px;
        padding-left: 10px;
      }
    }

    @include below_phone {
      padding: 16px 12px;

      .left-block {
        .currency-and-network-info-block {
          .currency-alias {
            color: var(--new-front-primary-font-color);
            font-size: $h6;
          }
          .network-alias {
            color: var(--secondary-font-color);
            font-size: $h6;
          }
        }
      }
    }
  }
}
</style>
