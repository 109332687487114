import getBusinessAddressesGrouped from "../../../../graphQl/queries/getBusinessAddressesGrouped";

export const getAccountBalancesGroupedQueryBusiness = async ({commit, dispatch, rootGetters, rootState}, payload = {}) => {
    let balancesData;
    // commit('app/toggleLoading', true, {root: true});
    const organizationId = rootGetters['app/getSelectedOrganizationId'];
    const accountId = rootState.accounts.accounts[0].accountId;


    if (!payload.organizationId) {
        payload.organizationId = organizationId
    }
    if (!payload.accountId) {
        payload.accountId = accountId
    }

    try {
        balancesData = await dispatch('app/request', {
            type: 'query',
            gql: getBusinessAddressesGrouped,
            variables: payload,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        // commit('app/toggleLoading', null, {root: true});
    }

    commit('accountBalancesGroupedBusiness', balancesData.data?.getBusinessAddressesGrouped);

    return balancesData;
};
