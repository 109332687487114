<script>
  export default {
    name: "SvgDust"
  }
</script>

<template>
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="20" cy="20" r="20" fill="var(--bg-background-table)"/>
  <g clip-path="url(#clip0_22358_42811)">
    <path d="M28.2422 13.5156C29.213 13.5156 30 12.7286 30 11.7578C30 10.787 29.213 10 28.2422 10C27.2714 10 26.4844 10.787 26.4844 11.7578C26.4844 12.7286 27.2714 13.5156 28.2422 13.5156Z" fill="#744DB2"/>
    <path d="M11.7578 30C12.7286 30 13.5156 29.213 13.5156 28.2422C13.5156 27.2714 12.7286 26.4844 11.7578 26.4844C10.787 26.4844 10 27.2714 10 28.2422C10 29.213 10.787 30 11.7578 30Z" fill="#744DB2"/>
    <path d="M28.2031 26.4453C28.5267 26.4453 28.7891 26.183 28.7891 25.8594C28.7891 25.5358 28.5267 25.2734 28.2031 25.2734C27.8795 25.2734 27.6172 25.5358 27.6172 25.8594C27.6172 26.183 27.8795 26.4453 28.2031 26.4453Z" fill="#744DB2"/>
    <path d="M11.7969 14.7266C12.1205 14.7266 12.3828 14.4642 12.3828 14.1406C12.3828 13.817 12.1205 13.5547 11.7969 13.5547C11.4733 13.5547 11.2109 13.817 11.2109 14.1406C11.2109 14.4642 11.4733 14.7266 11.7969 14.7266Z" fill="#744DB2"/>
    <path d="M27.3277 16.1874C26.7223 14.6001 25.2265 13.5547 23.5156 13.5547C22.6459 13.5547 21.823 13.8248 21.1307 14.3271C20.3937 13.1387 19.0811 12.3828 17.6562 12.3828C15.5803 12.3828 13.8602 13.9323 13.5913 15.9351C11.5886 16.2046 10 17.9246 10 20C10 21.7109 11.085 23.2066 12.6723 23.8126C13.2777 25.3999 14.7735 26.4453 16.4844 26.4453C17.3541 26.4453 18.177 26.1752 18.8693 25.6729C19.6063 26.8613 20.9189 27.6172 22.3438 27.6172C24.4197 27.6172 26.1398 26.0677 26.4087 24.0649C28.4114 23.7954 30 22.0754 30 20C30 18.2891 28.915 16.7934 27.3277 16.1874ZM14.1406 20.5859C13.817 20.5859 13.5547 20.3236 13.5547 20C13.5547 19.6763 13.817 19.4141 14.1406 19.4141C14.4642 19.4141 14.7266 19.6763 14.7266 20C14.7266 20.3236 14.4642 20.5859 14.1406 20.5859ZM16.4844 22.9297C16.1608 22.9297 15.8984 22.6673 15.8984 22.3438C15.8984 22.0201 16.1608 21.7578 16.4844 21.7578C16.808 21.7578 17.0703 22.0201 17.0703 22.3438C17.0703 22.6673 16.808 22.9297 16.4844 22.9297ZM17.6562 18.2422C17.3327 18.2422 17.0703 17.9798 17.0703 17.6562C17.0703 17.3326 17.3327 17.0703 17.6562 17.0703C17.9798 17.0703 18.2422 17.3326 18.2422 17.6562C18.2422 17.9798 17.9798 18.2422 17.6562 18.2422ZM20 20.5859C19.6764 20.5859 19.4141 20.3236 19.4141 20C19.4141 19.6763 19.6764 19.4141 20 19.4141C20.3236 19.4141 20.5859 19.6763 20.5859 20C20.5859 20.3236 20.3236 20.5859 20 20.5859ZM22.3438 22.9297C22.0202 22.9297 21.7578 22.6673 21.7578 22.3438C21.7578 22.0201 22.0202 21.7578 22.3438 21.7578C22.6673 21.7578 22.9297 22.0201 22.9297 22.3438C22.9297 22.6673 22.6673 22.9297 22.3438 22.9297ZM23.5156 18.2422C23.192 18.2422 22.9297 17.9798 22.9297 17.6562C22.9297 17.3326 23.192 17.0703 23.5156 17.0703C23.8392 17.0703 24.1016 17.3326 24.1016 17.6562C24.1016 17.9798 23.8392 18.2422 23.5156 18.2422ZM25.8594 20.5859C25.5358 20.5859 25.2734 20.3236 25.2734 20C25.2734 19.6763 25.5358 19.4141 25.8594 19.4141C26.183 19.4141 26.4453 19.6763 26.4453 20C26.4453 20.3236 26.183 20.5859 25.8594 20.5859Z" fill="#744DB2"/>
    <path d="M17.6562 28.7891C17.9799 28.7891 18.2422 28.5267 18.2422 28.2031C18.2422 27.8795 17.9799 27.6172 17.6562 27.6172C17.3326 27.6172 17.0703 27.8795 17.0703 28.2031C17.0703 28.5267 17.3326 28.7891 17.6562 28.7891Z" fill="#744DB2"/>
    <path d="M21.1719 12.3828C21.4955 12.3828 21.7578 12.1205 21.7578 11.7969C21.7578 11.4733 21.4955 11.2109 21.1719 11.2109C20.8483 11.2109 20.5859 11.4733 20.5859 11.7969C20.5859 12.1205 20.8483 12.3828 21.1719 12.3828Z" fill="#744DB2"/>
  </g>
  <defs>
    <clipPath id="clip0_22358_42811">
      <rect width="20" height="20" fill="white" transform="translate(10 10)"/>
    </clipPath>
  </defs>
</svg>
</template>
