import swapTransferTokenMutation from "../../../../graphQl/mutations/swapTransferToken";

export const getSwapTransferToken = async ({ dispatch, commit, rootGetters }, payload) => {
    const selectedOrganizationId = rootGetters['app/getSelectedOrganizationId'];
    let response;

    const variables = {
        ...payload,
        organizationId: selectedOrganizationId,
    };

    try {
        response = await dispatch('app/request', {
            type: 'mutation',
            gql: swapTransferTokenMutation,
            variables,
        }, { root: true });
    } catch (error) {
        return error;
    }

    return ( response.data && response.data.crosschainSwapToken ) ? response.data.crosschainSwapToken : {};
};
