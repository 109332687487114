import gql from 'graphql-tag';

export default gql`
    mutation(
        $organizationId: String!,
        $accountId: String!,
        $addressId: String!,
        $amount: String!,
        $from: BalanceType!,
        $to: BalanceType!,
        $feeToken: String!,
        $key: String!
    ) {
        makeTransferRequest(
            organizationId: $organizationId,
            accountId: $accountId,
            addressId: $addressId,
            from: $from,
            to: $to,
            amount: $amount,
            feeToken: $feeToken,
            key: $key
        ) {
            code
            result {
                id
                addressFromId
                accountId
                from
                to
                status
                amount
                feeAmount
                createdAt
            }
        }
    }
`;
