<script>
  import Verte from 'verte';
  import 'verte/dist/verte.css';

  export default {
    name: "WidgetsColorPicker",
    components: {Verte},
    data () {
      return {
        color: '#744DB2'
      }
    }
  }
</script>

<template>
<verte v-model="color" display="widget" picker="square" model="rgb" :showHistory="null"></verte>
</template>

<style scoped lang="scss">
::v-deep {
  .verte__inputs {
    display: none;
  }
  .verte__controller {
    display: none;
  }
}
</style>
