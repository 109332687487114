import gql from 'graphql-tag';

export default gql`
     mutation($organizationId: String!, $pageId: String!, $url: String!) {
         changePreCheckoutURL(organizationId: $organizationId, pageId: $pageId, url: $url){
            code
            result
         }
    }
`;
