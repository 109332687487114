<template>
  <div :class="['content-container', overflow ? 'content-container_without-overflow' : '' ]">
    <slot/>
  </div>
</template>

<script>
export default {
    name: "Container",
    props: {
        overflow: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.content-container {
  box-shadow: var(--shadow-container);
  border-radius: 20px;
  background: var(--secondary-color);
  overflow: hidden;
  margin-top: var(--margin-container-top);
  margin-bottom: var(--margin-container-bottom);

  &_without-overflow {
    overflow: visible;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
</style>