<template>
    <div class="balances-list-header list-header row">
        <div class="list-header__item col header-list">
            {{ $t('advance_balances.type_of_operation') }}
        </div>
        <div class="list-header__item col header-list">
            {{ $t('advance_balances.price') }}
        </div>
        <div class="list-header__item col header-list">
            {{ $t('advance_balances.currency') }}
        </div>
    </div>
</template>

<script>


export default {
    name: 'AccountsTariffsHeader',
};

</script>
<style lang="scss" scoped>
.header-list{
    text-align: center;
}
</style>