import getRecurrentAddresses from "@/public/graphQl/queries/getRecurrentAddresses";

export const getAddresses = async ({ dispatch, commit, rootGetters }, payload) => {
    const selectedOrganizationId = rootGetters['app/getSelectedOrganizationId'];
    let response;

    const variables = {
        organizationId: selectedOrganizationId,
    };
    // const {data: {getRecurrentAddresses: req}} = await this.request({
    //     type: 'query',
    //     gql: getRecurrentAddresses,
    //     variables,
    // });
    try {
        response = await dispatch('app/request', {
            type: 'query',
            gql: getRecurrentAddresses,
            variables,
        }, {root: true});

        commit('subscriptions/setAddresses', response.data.getRecurrentAddresses, {root: true})
    } catch (error) {
        return error;
    }

    return ( response.data && response.data.getRecurrentAddresses ) ? response.data.getRecurrentAddresses : {};
};
