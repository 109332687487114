import gql from 'graphql-tag';

export default gql`
     mutation {
         createAccount {
           accountId,
           currencyId,
           availableNetworks,
           blocked,
           blockReason,
           balance
        }
    }
`;