<script>
  import {mapActions, mapGetters} from "vuex";
  import BaseButton from "@/public/components/common/uiKit/button/base/bg-secondary/index.vue";

  export default {
    name: "ModalFaucet",
    components: {
      BaseButton,
      StoreModal: () => import(/* webpackChunkName: "/public/components/modals/StoreModal" */ '/public/components/modals/StoreModal'),
    },
    data () {
      return {
        isApollo: import.meta.env.VITE_PRODUCT_NAME === 'Apollopayment'
      }
    },
    computed: {
      ...mapGetters('app', [
        'sortedCurrencies',
        'isOpenModal',
      ]),
      isOpen() {

        const {
          $options: {
            name,
          },
          isOpenModal,
        } = this;

        return isOpenModal(name);
      },
    },
    methods: {
      ...mapActions('app', [
        'closeModal',
      ]),
      close() {
        this.closeModal()
      }
    },
  }
</script>

<template>
<StoreModal
    class="new-order-modal"
    :value="isOpen"
    :show-close="true"
    name="ModalFaucet"
    @closeModal="close"
>
  <template #body>
    <div class="content">
      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_21097_350191)">
          <rect width="60" height="60" rx="10" fill="var(--bg-background-table)"/>
          <path d="M30.75 22.0299C31.7304 20.997 33.423 20.7011 35.4185 20.9023C37.2692 21.089 39.1725 21.6865 40.5 22.2412V37.1264C39.1234 36.6016 37.3396 36.0885 35.569 35.9099C33.929 35.7445 32.1523 35.8536 30.75 36.6487V22.0299ZM30 20.6744C28.5226 19.4045 26.3802 19.2133 24.431 19.4099C22.1598 19.639 19.8669 20.4184 18.4396 21.0671C18.1719 21.1888 18 21.4558 18 21.7499V38.2499C18 38.5049 18.1296 38.7424 18.344 38.8805C18.5584 39.0185 18.8282 39.0382 19.0604 38.9327C20.3831 38.3314 22.5152 37.6108 24.5815 37.4024C26.6935 37.1893 28.4662 37.5332 29.4143 38.7184C29.5567 38.8963 29.7722 38.9999 30 38.9999C30.2278 38.9999 30.4433 38.8963 30.5857 38.7184C31.5338 37.5332 33.3065 37.1893 35.4185 37.4024C37.4848 37.6108 39.6169 38.3314 40.9396 38.9327C41.1718 39.0382 41.4416 39.0185 41.656 38.8805C41.8704 38.7424 42 38.5049 42 38.2499V21.7499C42 21.4558 41.8281 21.1888 41.5604 21.0671C40.1331 20.4184 37.8402 19.639 35.569 19.4099C33.6198 19.2133 31.4774 19.4045 30 20.6744Z" fill="#744DB2"/>
        </g>
        <defs>
          <clipPath id="clip0_21097_350191">
            <rect width="60" height="60" fill="white"/>
          </clipPath>
        </defs>
      </svg>


      <div class="title">{{ isApollo ? $t('testnet.faucet-title-apollo') : $t('testnet.faucet-title')}}</div>
      <div class="subtitle">
        {{ $t('testnet.faucet-desc') }}
      </div>
      <div class="actions">
        <base-button v-if="isApollo" type="secondary" :label="$t('testnet.close')" @click="close"></base-button>
        <a v-else href="mailto:support@onchainpay.org" target="_blank">
          <base-button type="primary" :label="'E-mail'">
            <template #prepend>
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.44118 0.434513C8.85294 0.120787 8.14706 0.120787 7.55882 0.434513L1.55882 3.63451C0.907097 3.9821 0.5 4.66059 0.5 5.39922V5.71272L7.20853 9.64472L8.5 8.92723L9.79147 9.64472L16.5 5.71272V5.39922C16.5 4.66059 16.0929 3.9821 15.4412 3.63451L9.44118 0.434513Z" fill="white"/>
                <path d="M16.5 6.87183L10.8067 10.2088L16.5 13.3717V6.87183Z" fill="white"/>
                <path d="M16.4411 14.4829L8.5 10.0712L0.558888 14.4829C0.77519 15.3538 1.56222 15.9992 2.5 15.9992H14.5C15.4378 15.9992 16.2248 15.3538 16.4411 14.4829Z" fill="white"/>
                <path d="M0.5 13.3717L6.19326 10.2088L0.5 6.87183V13.3717Z" fill="white"/>
              </svg>

            </template>
          </base-button>
        </a>
        <a :href="isApollo ? 'https://t.me/apollopayment' : 'https://t.me/onchainpay'" target="_blank">
          <base-button type="primary" :label="'Telegram'" @click="close">
            <template #prepend>
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 8C16.5 12.4183 12.9183 16 8.5 16C4.08172 16 0.5 12.4183 0.5 8C0.5 3.58172 4.08172 0 8.5 0C12.9183 0 16.5 3.58172 16.5 8ZM8.78668 5.90595C8.00857 6.2296 6.45342 6.89946 4.12126 7.91555C3.74256 8.06615 3.54417 8.21348 3.52611 8.35754C3.49559 8.601 3.80047 8.69687 4.21565 8.82741C4.27212 8.84517 4.33064 8.86357 4.39062 8.88307C4.79909 9.01585 5.34855 9.17118 5.63419 9.17735C5.8933 9.18295 6.18249 9.07613 6.50176 8.85689C8.68077 7.386 9.80559 6.64254 9.87621 6.62651C9.92603 6.6152 9.99507 6.60099 10.0418 6.64256C10.0886 6.68414 10.084 6.76288 10.0791 6.784C10.0489 6.91276 8.85208 8.0254 8.23275 8.60119C8.03967 8.78069 7.90272 8.90801 7.87472 8.93709C7.812 9.00223 7.74809 9.06385 7.68665 9.12307C7.30718 9.48889 7.02261 9.76321 7.70241 10.2112C8.02909 10.4265 8.29051 10.6045 8.55131 10.7821C8.83612 10.9761 9.12019 11.1695 9.48774 11.4104C9.58139 11.4718 9.67082 11.5356 9.75793 11.5977C10.0894 11.834 10.3872 12.0463 10.7551 12.0124C10.9688 11.9928 11.1896 11.7917 11.3018 11.1922C11.5668 9.77543 12.0878 6.70564 12.2081 5.44065C12.2187 5.32982 12.2054 5.18798 12.1948 5.12572C12.1841 5.06345 12.1618 4.97474 12.0809 4.90907C11.9851 4.83129 11.8371 4.81489 11.7709 4.81606C11.47 4.82136 11.0084 4.98188 8.78668 5.90595Z" fill="white"/>
              </svg>
            </template>
          </base-button>
        </a>
      </div>
    </div>
  </template>
</StoreModal>
</template>

<style lang="scss" scoped>
.subtitle {
  color: var(--text-color-text-secondary, #8B81A0);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 10px;
}
.content {
  font-family: $Gilroy;
  padding: 0 $p30;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 450px) {
    padding: 0 $p10;
  }
}
.title {
  color: var(--text-text-primary, #000);
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.38px;
  margin-top: 20px;
}
.actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 30px;
  width: 100%;
}
</style>
