<template>
  <div>
    <preloader v-if="isLoading || !isInit"/>
    <div v-else class="accounts">
      <AtButton
          class="accounts__all"
          type="primary"
          icon="icon-arrow-left"
          @click="goBack"
      >
        {{ $t('advance_balances.return') }}
      </AtButton>
      <h1 class="accounts__title">
        {{ $t('advance_balances.balance_history') }}
      </h1>
      <p class="accounts__paragraph">
        {{ $t('advance_balances.history_of_actions') }}
      </p>
      <div class="accounts__container">
          <div class="accounts__currency-container">
              <AccountsHistoryTopBar class="accounts__top-bar"/>
              <div class="accounts__currency-info">
                  <div class="accounts__button-container">
                      <AtButton
                              class="accounts__download-button"
                              size="small"
                              @click="downloadXLSXQuery"
                      >
                          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M21.1248 6.82505H16.2498C16.1636 6.82505 16.0809 6.79081 16.02 6.72986C15.959 6.66891 15.9248 6.58624 15.9248 6.50005V1.62505C15.9248 1.53885 15.959 1.45619 16.02 1.39524C16.0809 1.33429 16.1636 1.30005 16.2498 1.30005C16.336 1.30005 16.4187 1.33429 16.4796 1.39524C16.5406 1.45619 16.5748 1.53885 16.5748 1.62505V6.17505H21.1248C21.211 6.17505 21.2937 6.20929 21.3546 6.27024C21.4156 6.33119 21.4498 6.41385 21.4498 6.50005C21.4498 6.58624 21.4156 6.66891 21.3546 6.72986C21.2937 6.79081 21.211 6.82505 21.1248 6.82505Z" fill="var(--icon-exel-fill)"/>
                              <path d="M21.1248 10.064C21.0386 10.064 20.9559 10.0298 20.895 9.96881C20.834 9.90786 20.7998 9.8252 20.7998 9.739V6.6346L16.1153 1.95005H6.4998C6.15502 1.95005 5.82436 2.08701 5.58057 2.33081C5.33677 2.57461 5.1998 2.90527 5.1998 3.25005V9.6311C5.1998 9.7173 5.16556 9.79996 5.10461 9.86091C5.04367 9.92186 4.961 9.9561 4.8748 9.9561C4.78861 9.9561 4.70594 9.92186 4.64499 9.86091C4.58405 9.79996 4.5498 9.7173 4.5498 9.6311V3.25005C4.5498 2.73288 4.75525 2.23689 5.12095 1.87119C5.48664 1.5055 5.98263 1.30005 6.4998 1.30005H16.2498C16.2925 1.29997 16.3348 1.30832 16.3743 1.3246C16.4138 1.34088 16.4497 1.36479 16.4799 1.39495L21.3549 6.26995C21.3851 6.30019 21.409 6.33607 21.4253 6.37556C21.4415 6.41504 21.4499 6.45734 21.4498 6.50005V9.739C21.4498 9.78168 21.4414 9.82394 21.4251 9.86337C21.4087 9.9028 21.3848 9.93863 21.3546 9.96881C21.3244 9.99899 21.2886 10.0229 21.2492 10.0393C21.2097 10.0556 21.1675 10.064 21.1248 10.064Z" fill="var(--icon-exel-fill)"/>
                              <path d="M19.4998 24.7H6.4998C5.98263 24.7 5.48664 24.4946 5.12095 24.1289C4.75525 23.7632 4.5498 23.2672 4.5498 22.75V17.875C4.5498 17.7889 4.58405 17.7062 4.64499 17.6452C4.70594 17.5843 4.78861 17.55 4.8748 17.55C4.961 17.55 5.04367 17.5843 5.10461 17.6452C5.16556 17.7062 5.1998 17.7889 5.1998 17.875V22.75C5.1998 23.0948 5.33677 23.4255 5.58057 23.6693C5.82436 23.9131 6.15502 24.05 6.4998 24.05H19.4998C19.8446 24.05 20.1752 23.9131 20.419 23.6693C20.6628 23.4255 20.7998 23.0948 20.7998 22.75V17.875C20.7998 17.7889 20.834 17.7062 20.895 17.6452C20.9559 17.5843 21.0386 17.55 21.1248 17.55C21.211 17.55 21.2937 17.5843 21.3546 17.6452C21.4156 17.7062 21.4498 17.7889 21.4498 17.875V22.75C21.4498 23.2672 21.2444 23.7632 20.8787 24.1289C20.513 24.4946 20.017 24.7 19.4998 24.7Z" fill="var(--icon-exel-fill)"/>
                              <path d="M17.8748 18.2H8.1248C8.03861 18.2 7.95594 18.1658 7.89499 18.1049C7.83405 18.0439 7.7998 17.9612 7.7998 17.875C7.7998 17.7889 7.83405 17.7062 7.89499 17.6452C7.95594 17.5843 8.03861 17.55 8.1248 17.55H17.8748C17.961 17.55 18.0437 17.5843 18.1046 17.6452C18.1656 17.7062 18.1998 17.7889 18.1998 17.875C18.1998 17.9612 18.1656 18.0439 18.1046 18.1049C18.0437 18.1658 17.961 18.2 17.8748 18.2Z" fill="var(--icon-exel-fill)"/>
                              <path d="M12.9998 21.45H8.1248C8.03861 21.45 7.95594 21.4158 7.89499 21.3549C7.83405 21.2939 7.7998 21.2112 7.7998 21.125C7.7998 21.0389 7.83405 20.9562 7.89499 20.8952C7.95594 20.8343 8.03861 20.8 8.1248 20.8H12.9998C13.086 20.8 13.1687 20.8343 13.2296 20.8952C13.2906 20.9562 13.3248 21.0389 13.3248 21.125C13.3248 21.2112 13.2906 21.2939 13.2296 21.3549C13.1687 21.4158 13.086 21.45 12.9998 21.45Z" fill="var(--icon-exel-fill)"/>
                              <path d="M21.1248 18.2001H4.8748C4.35763 18.2001 3.86164 17.9947 3.49595 17.629C3.13025 17.2633 2.9248 16.7673 2.9248 16.2501V11.2562C2.9248 10.739 3.13025 10.243 3.49595 9.87729C3.86164 9.5116 4.35763 9.30615 4.8748 9.30615H4.8878L21.1378 9.41405C21.6524 9.41884 22.1443 9.62621 22.507 9.99122C22.8697 10.3562 23.0739 10.8495 23.0755 11.3641V16.2501C23.0755 16.5062 23.025 16.7599 22.927 16.9965C22.8289 17.2331 22.6852 17.4481 22.5041 17.6292C22.3229 17.8103 22.1079 17.9539 21.8712 18.0519C21.6346 18.1498 21.3809 18.2002 21.1248 18.2001ZM4.8748 9.95615C4.53002 9.95615 4.19936 10.0931 3.95557 10.3369C3.71177 10.5807 3.5748 10.9114 3.5748 11.2562V16.2501C3.5748 16.5949 3.71177 16.9255 3.95557 17.1693C4.19936 17.4131 4.53002 17.5501 4.8748 17.5501H21.1248C21.4696 17.5501 21.8002 17.4131 22.044 17.1693C22.2878 16.9255 22.4248 16.5949 22.4248 16.2501V11.3641C22.4238 11.0209 22.2876 10.692 22.0457 10.4487C21.8038 10.2053 21.4757 10.0671 21.1326 10.0641L4.88261 9.95615H4.8748Z" fill="var(--icon-exel-fill)"/>
                              <path d="M9.9085 15.3401H7.65625V11.8743H9.88575V12.3897H8.2809V13.329H9.5705V13.8314H8.2809V14.8012H9.9059L9.9085 15.3401Z" fill="var(--icon-exel-fill)"/>
                              <path d="M12.3612 15.34H11.6995L11.1561 14.406L10.5633 15.34H10.0654L10.9195 14.0199L10.1805 12.7758H10.8435L11.2647 13.5298L11.7444 12.7758H12.2293L11.5045 13.8879L12.3612 15.34Z" fill="var(--icon-exel-fill)"/>
                              <path d="M14.131 14.3982L14.6289 14.4489C14.5643 14.7705 14.4399 15.0088 14.2558 15.1639C14.0674 15.3192 13.829 15.4007 13.585 15.3934C13.2309 15.3934 12.9588 15.2634 12.7686 15.0034C12.5725 14.7269 12.4723 14.3938 12.4832 14.055C12.4832 13.6689 12.5872 13.3498 12.7952 13.0976C12.8953 12.9744 13.0225 12.8761 13.167 12.8104C13.3115 12.7448 13.4693 12.7136 13.6279 12.7193C14.1864 12.7193 14.519 13.0137 14.6256 13.6026L14.1277 13.6709C14.0844 13.3528 13.9277 13.1938 13.6578 13.1938C13.5687 13.1897 13.4805 13.2123 13.4043 13.2586C13.3281 13.3049 13.2675 13.3729 13.2301 13.4538C13.1363 13.6479 13.0906 13.8617 13.0968 14.0771C13.0968 14.3371 13.143 14.5369 13.2353 14.6764C13.2757 14.7431 13.3331 14.7977 13.4017 14.8347C13.4702 14.8717 13.5474 14.8898 13.6253 14.887C13.8796 14.8857 14.0482 14.7228 14.131 14.3982Z" fill="var(--icon-exel-fill)"/>
                              <path d="M16.7051 14.5224L17.2251 14.5932C17.1593 14.8226 17.0203 15.0241 16.8293 15.1672C16.6154 15.3235 16.355 15.4032 16.0902 15.3934C15.7349 15.3934 15.4489 15.274 15.2322 15.0352C15.0156 14.7965 14.9072 14.4732 14.9072 14.0654C14.9072 13.678 15.0156 13.3574 15.2322 13.1034C15.4489 12.8495 15.7451 12.7225 16.1208 12.7225C16.4865 12.7225 16.7682 12.8478 16.9658 13.0982C17.1634 13.3487 17.2633 13.6702 17.2654 14.0628V14.1278H15.5098C15.5055 14.2693 15.5222 14.4105 15.5592 14.5471C15.5948 14.6562 15.6628 14.7519 15.7542 14.8214C15.8596 14.9017 15.9896 14.943 16.1221 14.9384C16.4098 14.9388 16.6042 14.8002 16.7051 14.5224ZM16.6694 13.7554C16.6752 13.5879 16.6155 13.4247 16.503 13.3004C16.4515 13.246 16.3897 13.2025 16.3211 13.1724C16.2525 13.1423 16.1786 13.1262 16.1037 13.1251C16.0289 13.124 15.9545 13.1379 15.8851 13.166C15.8157 13.1941 15.7526 13.2358 15.6996 13.2887C15.585 13.4166 15.5185 13.5805 15.5117 13.7521L16.6694 13.7554Z" fill="var(--icon-exel-fill)"/>
                              <path d="M18.376 15.3401H17.7969V11.8743H18.376V15.3401Z" fill="var(--icon-exel-fill)"/>
                          </svg>
                      </AtButton>
                      <AtButton
                              class="accounts__download-button"
                              size="small"
                              @click="download"
                      >
                          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M21.1248 6.82505H16.2498C16.1636 6.82505 16.0809 6.79081 16.02 6.72986C15.959 6.66891 15.9248 6.58624 15.9248 6.50005V1.62505C15.9248 1.53885 15.959 1.45619 16.02 1.39524C16.0809 1.33429 16.1636 1.30005 16.2498 1.30005C16.336 1.30005 16.4187 1.33429 16.4796 1.39524C16.5406 1.45619 16.5748 1.53885 16.5748 1.62505V6.17505H21.1248C21.211 6.17505 21.2937 6.20929 21.3546 6.27024C21.4156 6.33119 21.4498 6.41385 21.4498 6.50005C21.4498 6.58624 21.4156 6.66891 21.3546 6.72986C21.2937 6.79081 21.211 6.82505 21.1248 6.82505Z" fill="var(--icon-csv-fill)"/>
                              <path d="M21.1248 10.064C21.0386 10.064 20.9559 10.0298 20.895 9.96881C20.834 9.90786 20.7998 9.8252 20.7998 9.739V6.6346L16.1153 1.95005H6.4998C6.15502 1.95005 5.82436 2.08701 5.58057 2.33081C5.33677 2.57461 5.1998 2.90527 5.1998 3.25005V9.6311C5.1998 9.7173 5.16556 9.79996 5.10461 9.86091C5.04367 9.92186 4.961 9.9561 4.8748 9.9561C4.78861 9.9561 4.70594 9.92186 4.64499 9.86091C4.58405 9.79996 4.5498 9.7173 4.5498 9.6311V3.25005C4.5498 2.73288 4.75525 2.23689 5.12095 1.87119C5.48664 1.5055 5.98263 1.30005 6.4998 1.30005H16.2498C16.2925 1.29997 16.3348 1.30832 16.3743 1.3246C16.4138 1.34088 16.4497 1.36479 16.4799 1.39495L21.3549 6.26995C21.3851 6.30019 21.409 6.33607 21.4253 6.37556C21.4415 6.41504 21.4499 6.45734 21.4498 6.50005V9.739C21.4498 9.78168 21.4414 9.82394 21.4251 9.86337C21.4087 9.9028 21.3848 9.93863 21.3546 9.96881C21.3244 9.99899 21.2886 10.0229 21.2492 10.0393C21.2097 10.0556 21.1675 10.064 21.1248 10.064Z" fill="var(--icon-csv-fill)"/>
                              <path d="M19.4998 24.7H6.4998C5.98263 24.7 5.48664 24.4946 5.12095 24.1289C4.75525 23.7632 4.5498 23.2672 4.5498 22.75V17.875C4.5498 17.7889 4.58405 17.7062 4.64499 17.6452C4.70594 17.5843 4.78861 17.55 4.8748 17.55C4.961 17.55 5.04367 17.5843 5.10461 17.6452C5.16556 17.7062 5.1998 17.7889 5.1998 17.875V22.75C5.1998 23.0948 5.33677 23.4255 5.58057 23.6693C5.82436 23.9131 6.15502 24.05 6.4998 24.05H19.4998C19.8446 24.05 20.1752 23.9131 20.419 23.6693C20.6628 23.4255 20.7998 23.0948 20.7998 22.75V17.875C20.7998 17.7889 20.834 17.7062 20.895 17.6452C20.9559 17.5843 21.0386 17.55 21.1248 17.55C21.211 17.55 21.2937 17.5843 21.3546 17.6452C21.4156 17.7062 21.4498 17.7889 21.4498 17.875V22.75C21.4498 23.2672 21.2444 23.7632 20.8787 24.1289C20.513 24.4946 20.017 24.7 19.4998 24.7Z" fill="var(--icon-csv-fill)"/>
                              <path d="M17.8748 18.2H8.1248C8.03861 18.2 7.95594 18.1658 7.89499 18.1049C7.83405 18.0439 7.7998 17.9612 7.7998 17.875C7.7998 17.7889 7.83405 17.7062 7.89499 17.6452C7.95594 17.5843 8.03861 17.55 8.1248 17.55H17.8748C17.961 17.55 18.0437 17.5843 18.1046 17.6452C18.1656 17.7062 18.1998 17.7889 18.1998 17.875C18.1998 17.9612 18.1656 18.0439 18.1046 18.1049C18.0437 18.1658 17.961 18.2 17.8748 18.2Z" fill="var(--icon-csv-fill)"/>
                              <path d="M12.9998 21.45H8.1248C8.03861 21.45 7.95594 21.4158 7.89499 21.3549C7.83405 21.2939 7.7998 21.2112 7.7998 21.125C7.7998 21.0389 7.83405 20.9562 7.89499 20.8952C7.95594 20.8343 8.03861 20.8 8.1248 20.8H12.9998C13.086 20.8 13.1687 20.8343 13.2296 20.8952C13.2906 20.9562 13.3248 21.0389 13.3248 21.125C13.3248 21.2112 13.2906 21.2939 13.2296 21.3549C13.1687 21.4158 13.086 21.45 12.9998 21.45Z" fill="var(--icon-csv-fill)"/>
                              <path d="M21.1248 18.2001H4.8748C4.35763 18.2001 3.86164 17.9947 3.49595 17.629C3.13025 17.2633 2.9248 16.7673 2.9248 16.2501V11.2562C2.9248 10.739 3.13025 10.243 3.49595 9.87729C3.86164 9.5116 4.35763 9.30615 4.8748 9.30615H4.8878L21.1378 9.41405C21.6524 9.41884 22.1443 9.62621 22.507 9.99122C22.8697 10.3562 23.0739 10.8495 23.0755 11.3641V16.2501C23.0755 16.5062 23.025 16.7599 22.927 16.9965C22.8289 17.2331 22.6852 17.4481 22.5041 17.6292C22.3229 17.8103 22.1079 17.9539 21.8712 18.0519C21.6346 18.1498 21.3809 18.2002 21.1248 18.2001ZM4.8748 9.95615C4.53002 9.95615 4.19936 10.0931 3.95557 10.3369C3.71177 10.5807 3.5748 10.9114 3.5748 11.2562V16.2501C3.5748 16.5949 3.71177 16.9255 3.95557 17.1693C4.19936 17.4131 4.53002 17.5501 4.8748 17.5501H21.1248C21.4696 17.5501 21.8002 17.4131 22.044 17.1693C22.2878 16.9255 22.4248 16.5949 22.4248 16.2501V11.3641C22.4238 11.0209 22.2876 10.692 22.0457 10.4487C21.8038 10.2053 21.4757 10.0671 21.1326 10.0641L4.8826 9.95615H4.8748Z" fill="var(--icon-csv-fill)"/>
                              <path d="M10.9512 14.1363L11.5304 14.2013C11.5036 14.526 11.3608 14.8301 11.128 15.058C10.897 15.2825 10.5937 15.3947 10.218 15.3947C9.90125 15.3947 9.64428 15.3152 9.44711 15.1562C9.24068 14.9835 9.08368 14.7592 8.99211 14.5062C8.8827 14.2183 8.82846 13.9125 8.83221 13.6046C8.83221 13.0764 8.95745 12.6469 9.20791 12.3163C9.45838 11.9857 9.80288 11.8201 10.2414 11.8197C10.5578 11.8096 10.8657 11.9234 11.0994 12.1369C11.3278 12.3484 11.4716 12.6517 11.531 13.0469L10.9519 13.108C10.8453 12.585 10.61 12.3235 10.246 12.3235C10.0124 12.3235 9.83083 12.4318 9.70126 12.6485C9.5717 12.8651 9.5067 13.1804 9.50626 13.5942C9.50626 14.0141 9.5756 14.3311 9.71426 14.5452C9.85293 14.7592 10.0367 14.8663 10.2655 14.8663C10.3544 14.8669 10.4425 14.8479 10.5233 14.8106C10.604 14.7733 10.6756 14.7186 10.7328 14.6505C10.8607 14.5081 10.9376 14.3272 10.9512 14.1363Z" fill="var(--icon-csv-fill)"/>
                              <path d="M11.6611 14.5048L12.2682 14.3748C12.341 14.7184 12.6203 14.8902 13.1061 14.8902C13.3279 14.8902 13.4985 14.8469 13.6176 14.7602C13.6718 14.7248 13.7164 14.6767 13.7476 14.62C13.7787 14.5633 13.7955 14.4999 13.7964 14.4352C13.7961 14.3423 13.7678 14.2517 13.7151 14.1752C13.661 14.095 13.545 14.032 13.3674 13.9861L12.7044 13.8132C12.5268 13.7742 12.357 13.7059 12.2019 13.611C12.0887 13.5297 11.999 13.4198 11.9419 13.2925C11.8768 13.1552 11.8435 13.005 11.8444 12.8531C11.8396 12.7106 11.8683 12.5689 11.9282 12.4395C11.9882 12.3101 12.0777 12.1966 12.1896 12.1082C12.4197 11.921 12.7141 11.8274 13.0729 11.8274C13.3516 11.8224 13.6268 11.8896 13.8718 12.0224C13.9809 12.0785 14.077 12.1567 14.1541 12.2521C14.2313 12.3474 14.2876 12.4578 14.3196 12.5762L13.7255 12.7238C13.6341 12.4616 13.4209 12.3305 13.0859 12.3305C12.8914 12.3305 12.7427 12.3712 12.64 12.4527C12.5917 12.4885 12.5526 12.5353 12.5259 12.5892C12.4992 12.6431 12.4858 12.7026 12.4866 12.7628C12.4866 12.9608 12.6474 13.0997 12.9689 13.1794L13.5578 13.327C13.9149 13.4167 14.1543 13.549 14.2761 13.7241C14.3962 13.8937 14.46 14.0967 14.4587 14.3046C14.4647 14.4597 14.4327 14.6139 14.3657 14.7538C14.2986 14.8938 14.1985 15.0154 14.0739 15.108C13.8174 15.3034 13.4852 15.4011 13.0775 15.4011C12.7629 15.4084 12.4519 15.3341 12.1746 15.1853C12.0465 15.1136 11.9348 15.0162 11.8464 14.899C11.758 14.7818 11.6949 14.6476 11.6611 14.5048Z" fill="var(--icon-csv-fill)"/>
                              <path d="M16.2701 15.3401H15.635L14.5664 11.8743H15.2268L16.0218 14.5302L16.8349 11.8743H17.3549L16.2701 15.3401Z" fill="var(--icon-csv-fill)"/>
                          </svg>
                      </AtButton>
                      <btn-refresh class="accounts__btn-refresh" :icon="'icon-rotate-cw'" :isLoading="isLoading" @update="loadHistory"/>
                  </div>
              </div>
          </div>


        <div class="accounts__list">

          <template v-if="hasHistory">
            <PreviewAccountHistory
                v-for="(log, key) in list"
                :key="key"
                class="accounts__log"
                :class="{
                        last: key === (list.length - 1)
                    }"
                :data="log"
            />

            <div class="accounts__pagination">
              <pagination
                  :size="'small'"
                  :total="total"
                  :page-size="onPage"
                  :current="currentPage"
                  @page-change="changePage"
              />
            </div>
          </template>
          <EmptyTable v-else/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex';
import pagination from "../components/common/pagination";
import AtPagination from "at-ui/src/components/pagination";
import EmptyTable from "/public/components/elements/EmptyTable";
import AccountsHistoryTopBar from "../components/elements/accounts/AccountsTopBar";
import AccountsHistoryListHeader from "../components/elements/accounts/AccountsHistoryListHeader";
import PreviewAccountHistory from "../components/elements/accounts/PreviewAccountHistory";
import CurrencyImg from "../components/elements/CurrencyImg";
import BtnRefresh from "../components/common/btn-refresh";
import CopyString from "../components/elements/CopyString";
import Preloader from "/public/components/common/uiKit/spinner/index.vue";
import Pagination from "../components/common/pagination";

export default {
    name: 'AccountHistory',
    title: 'advance_balances.balance_history',
    components: {
        Preloader,
        pagination,
        CopyString,
        BtnRefresh,
        PreviewAccountHistory,
        AccountsHistoryListHeader,
        AccountsHistoryTopBar,
        AtPagination,
        EmptyTable,
        CurrencyImg,
        Pagination,
    },
    data() {
        return {
            // isLoading: false,
        };
    },
    computed: {
        ...mapState('app', [
            'isInit',
            'isLoading',
        ]),
        ...mapGetters('app', ['isFiat']),
        ...mapState('accounts', {
            onPage: state => state.history.onPage,
            total: state => state.history.total,
            list: state => state.history.list,
            account: state => state.accounts[0],
        }),
        accountId() {
            return this.$route.params.accountId;
        },
        currentPage() {
            const pageFromUrl = this.$route.query.page;

            if (!pageFromUrl) {
                return 1;
            }

            return Number(pageFromUrl);
        },
        hasHistory() {
            return !!this.list.length;
        },
        id_copied() {
            return this.$t('copy.id_copied');
        },
        id_not_copied() {
            return this.$t('copy.id_not_copied');
        },
        notification() {
            return this.$t('copy.notification');
        },
        balance() {
            return this.account?.balance;
        },
        currencyId() {
            return this.account?.currencyId;
        },
        tariffs() {
            return this.account?.tariffs;
        },
    },
    mounted() {
        const {
            isInit,
        } = this;

        if (isInit) {
            this.loadHistory();
        }
    },
    methods: {
        ...mapActions('accounts', ['getAccountHistory', "downloadXLSX", "downloadCSV"]),
        feeTariffType(tariff) {
            const feeTariffType = {
                // Старые типы
                'TRANSACTION_INIT': 'advance_balances.creating_an_order',
                'TRANSACTION_PROCESSED': 'advance_balances.deposit_by_order',
                'TRANSFER': 'advance_balances.transfer_fee',

                'INTERNAL_TRANSFER': 'advance_balances.transfer_fee',
                'ORDER_INIT': 'advance_balances.creating_an_order',
                'ORDER_DEPOSIT': 'advance_balances.deposit_by_order',
                'WALLET_WITHDRAWAL': "advance_balances.wallet_withdrawal_fee",
                'RECURRENT_WITHDRAWAL': "advance_balances.recurrent_withdrawal_fee",
                'PERSONAL_WITHDRAWAL': "advance_balances.personal_withdrawal_fee",
                'PAYOUT_WITHDRAWAL': "advance_balances.payout_withdrawal_fee",
                'WALLET_DEPOSIT': "advance_balances.wallet_deposit_fee",
                'RECURRENT_DEPOSIT': "advance_balances.recurrent_deposit_fee",
                'PERSONAL_DEPOSIT': "advance_balances.personal_deposit_fee",
                'PAYOUT_DEPOSIT': "advance_balances.payout_deposit_fee",
                'KYT_TRANSACTION_FEE': 'advance_balances.kyt-transaction-fee',
                'KYT_WITHDRAWAL_ADDRESS_FEE': 'advance_balances.kyt-withdrawal-address-fee',
                'EXCHANGE_AUTO': "advance_balances.exchange-auto",
                'ORPHAN_DEPOSIT_WITHDRAWAL_FEE': "advance_balances.orphan-deposit-withdrawal",
                'ORPHAN_DEPOSIT_WITHDRAWAL': "advance_balances.orphan-deposit-withdrawal",
                // TODO: добавить перевод
                // 'EXCHANGE_INTERNAL': "advance_balances.exchange_internal_fee",
                // 'EXCHANGE_AUTO': "advance_balances.exchange_auto_fee",
                // 'BRIDGE_INTERNAL': "advance_balances.bridge_internal_fee",
                // 'BRIDGE_EXTERNAL': "advance_balances.bridge_external_fee",
            };
            return this.$t(feeTariffType[tariff]) || this.$t('advance_balances.unknown_tariff');
        },
        changePage(pageNumber) {
            this.loadHistory(pageNumber);
        },
        loadHistory(page) {
          this.getAccountHistory({
                router: this.$router,
                route: this.$route,
                page: page,
            });

        },

        download() {
            const {
                $route,
            } = this;

            this.downloadCSV({
                route: $route,
            });
        },
        downloadXLSXQuery() {
            const {
                $route,
            } = this;

            this.downloadXLSX({
                route: $route,
            });
        },

        goBack() {
            if (window.history.length > 2)
                this.$router.go(-1);
            else this.$router.push({name: 'advanced-balances'});
        },
    },
    watch: {
        isInit() {
            this.loadHistory();
        },
    },
};

</script>

<style lang="scss" scoped>

.accounts {
  padding-left: var(--padding-left-container);
  padding-right: var(--padding-right-container);
  padding-top: var(--padding-top-container);

  &__list {
    background: var(--secondary-color);
    border-radius: 0 0 20px 20px;
  }


  &__button-container {
    display: flex;
    gap: 10px;
  }

  &__icon-xls {
    width: 18px;
    height: 18px;
    stroke: #0059F7;
    fill: #0059F7;
  }

  &__download-button {
    height: 46px;
    width: 46px;
    border-radius: 12px;
    padding: 0;
    border: none;

    &:first-child {
      background: var(--icon-exel)
    }

    &:nth-child(2) {
      background: var(--icon-csv)
    }
  }

  &__icon-xls {
    width: 18px;
    height: 18px;
    stroke: #0059F7;
    fill: #0059F7;
  }


  &__download-button ::v-deep .at-btn__icon {
    color: #0059F7;
    font-size: 18px;
    line-height: 18px;
  }

  &__text-main {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);

    &_desktop {
      display: block;
    }

    &_mobile {
      display: none;
    }
  }

  &__all {
    background: #0059F7;
    margin-bottom: 16px;
    border-radius: 6px;
    border: none;
    width: 100%;
    max-width: 125px;
    min-height: 40px;
    transition: all 0.3s ease-in;

    &:hover {
      background: var(--blue-400);
    }

    ::v-deep .at-btn__text {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: var(--white-100);
    }

    ::v-deep .at-btn__icon {
      font-size: var(--font-size-text-main);
    }
  }

  &__container {
    border-radius: 20px;
    //overflow: hidden;
    box-shadow: var(--shadow-container);
    margin-bottom: var(--margin-container-bottom);
    margin-top: var(--margin-container-top);
    border: 1px solid var(--border-color);
  }

  &__history-header {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
  }

  &__currency-container {
    display: flex;
    background: var(--secondary-color);
    border-radius: 20px 20px 0 0;

    @media (min-width: 320px) {
      flex-direction: column-reverse;
    }
  }

  &__currency-tariff {
    width: 100%;
    max-width: 380px;
  }

  &__currency-info {
    position: relative;
    display: flex;
    padding-left: 40px;
    padding-right: 40px;

    @media (min-width: 320px) {
      width: 100%;
      flex-direction: column-reverse;
      padding-top: 15px;
      align-items: flex-start;
      padding-bottom: 15px;
    }

    @media (min-width: 992px) {
      border-top: var(--border-color);
      justify-content: flex-end;
      align-items: flex-end;
      padding-bottom: 25px;
      flex-direction: column;
    }
  }

  &__percent-fee-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__all {
    margin-bottom: 16px;
  }

  &__title {
    margin-bottom: var(--margin-h1-bottom);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-h1);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__paragraph {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-h3);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__currency-container {
    min-height: 136px;
    border-bottom: var(--border-color);
  }

  &__top-bar {
  }

  &__log.last {
    border-bottom: none;
  }

  &__empty {
    border: none;
  }

  &__pagination {
    border-radius: 0 0 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 85px;
    background: var(--secondary-color);

    &::v-deep .at-pagination {
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: flex-start;
    }

    &::v-deep .pagination {
      background: var(--secondary-color);
    }

    &::v-deep .at-pagination__prev {
      border: none;
      background: var(--secondary-color);
      font-size: 24px;
      margin: 0;
    }

    &::v-deep .at-pagination__next {
      border: none;
      background: var(--secondary-color);
      font-size: 24px;
      margin: 0;
    }

    &::v-deep .at-pagination__item {
      float: unset;
      border: none;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #8F949A;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      height: 24px;
      background: var(--secondary-color);
    }

    &::v-deep .at-pagination__item--active {
      background-color: transparent;
      border: none;
      color: #0059F7;
    }

    @media screen and (max-width: 400px) {
      &::v-deep .at-pagination {
        gap: 8px;
      }
    }

  }

  &__currency-img {
    margin-right: 8px;
    width: 50px;
    height: 50px;
  }

  &__info-id {
    display: flex;
    justify-content: space-between;
    min-height: 80px;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
    background: #FFFFFF;
    border-radius: 20px 20px 0 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__info-id-container {
    display: flex;
    gap: 15px
  }

  &__info-item-title {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
    margin-right: 8px;
  }

  &__info-id-value {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

  &__info-item-value {
    font-weight: 700;

    &_mobile {
      display: none;
    }

    &.link {
      color: #5ECAE5;

      cursor: pointer;
    }

    &--currency {
      display: flex;
      align-items: center;
    }
  }

  &__btn-refresh {

    &::v-deep .at-btn__icon {
      color: #0059F7;
    }

    @media (min-width: 320px) {
      position: absolute;
      right: 15px;
    }

    @media (min-width: 992px) {
      position: static;
      right: auto;
    }
  }
}

@media screen and (max-width: 1600px) {
  .accounts {


    &__info-id {

    }
  }

}

@media screen and (max-width: 1180px) {
  .accounts {
    &__currency-info {
      padding-left: 10px;
      padding-right: 10px;
    }

    &__info-id {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}


@media screen and (max-width: 950px) {
  .accounts {
    &__text-main {
      &_desktop {
        display: none;
      }

      &_mobile {
        display: block;
      }
    }

    &__currency-container {
      display: flex;
    }


    &__currency-info {
      padding-right: 10px;
      padding-left: 10px;
    }

    &__top-bar {
      padding-left: 10px;
      padding-right: 10px;
    }

    &__currency-tariff {
      max-width: 100%;
    }

    &__currency-img {
      width: 24px;
      height: 24px;
    }

    &__info-item-value {
      display: none;

      &_mobile {
        display: block;
      }
    }

    &__info-currency {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }

}

@media screen and (max-width: 375px) {
  .accounts {
    &__all {
      margin-left: 10px;
    }

    &__title {
      padding-left: 10px;
    }

    &__paragraph {
      padding-left: 10px;
    }
  }

}

@media screen and (max-width: 360px) {
  .accounts {
    &__info-id {
      padding: 10px;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
  }

}

</style>
