import getPreCheckoutPages from "../../../../graphQl/queries/getPrecheckoutPages";
import {getRouteQuery} from "../../../../utils/getRouteQuery";

export const fetchPreCheckoutPages = async ({state, rootState, rootGetters, commit, dispatch}, payload) => {
    const {
        route,
        router,
        showUrlFilters = true,
        excludeParams = [],
        page,
    } = payload;
    const {pagesCount} = state;

    /** Даты если не переданы указывваю начальные значения */
    commit('app/toggleLoading', null, {root: true});
    const currentPage = page ? page : getRouteQuery({
        route,
        queryName: 'page',
        defaultValue: 0,
        excludeQueries: excludeParams,
        preparedReturnValueFunc: (value) => Number(value),
    });

    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const variables = {
        organizationId,
        limit: pagesCount,
        offset: ((currentPage === 1 || currentPage === 0 ? 0 : ((currentPage - 1) * pagesCount))),
    };


    if (showUrlFilters) {
        router.push({
            query: {
                page: currentPage,
            },
        }).catch((error) => {
            if (error.name !== 'NavigationDuplicated') {
                throw error;
            }
        });
    }

    /** Получение данных для счетчик ордеров */
    let preCheckoutPages;

    try {
        commit('app/toggleLoading', null, {root: true});
        preCheckoutPages = await dispatch('app/request', {
            type: 'query',
            gql: getPreCheckoutPages,
            variables,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (preCheckoutPages.data) {
        const {
            data: {
                getPreCheckoutPages: {
                    pages,
                    count,
                },
            },
        } = preCheckoutPages;
        commit('pages', pages);
        commit('count', count);
    }
    commit('app/toggleLoading', null, {root: true});
    return preCheckoutPages;
};

