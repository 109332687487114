<script>
  import ActionsPopup from "@/public/components/common/uiKit/actionsPopup/ActionsPopup.vue";
  import {mapActions, mapMutations, mapState} from "vuex";
  import CurrencyNetworkIcon from "@/public/components/elements/business-walet/Select/CurrencyNetworkIcon.vue";

  export default {
    name: "PreviewBankInvoice",
    components: {CurrencyNetworkIcon, ActionsPopup},
    props: {
      item: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      ...mapState('app', ['networks', 'currencies']),
      currency () {
        return this.currencies.find(({ id }) => id === this.item.currencyId)
      },
    },
    methods: {
      ...mapMutations('sepaSwift', ['setBankAccountInfoState', 'setBankAccount']),
      ...mapActions('app', ['openModal']),
      openInfo() {
        this.setBankAccount(this.item)
        this.setBankAccountInfoState(true)
      }
    }
  }
</script>

<template>
<div class="preview-invoice" @click="openInfo">
  <div class="preview-invoice__item">
    {{ item.alias }}
  </div>
  <div class="preview-invoice__item">
    <span>{{ item.type }}</span>
    /
    <div class="preview-invoice__item-currency">
      <currency-network-icon :currency="currency.currency" />
      <span>{{ currency.currency }}</span>
    </div>
  </div>
  <div class="preview-invoice__item">
    <span class="desktopIban">{{ item.iban }}</span>
    <span class="mobileIban">{{ item.iban | preparedString }}</span>
  </div>
  <div class="preview-invoice__item">
    <div :class="['statusLabel', `statusLabel--${ item.status.toLowerCase() }`]">
      {{ $t(`bank.${item.status}`) }}
    </div>
  </div>
  <div class="preview-invoice__actions">
<!--    <actions-popup>-->
<!--      <div :class="['actions__modal-item']" @click="openInfo(item)">-->
<!--        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path d="M10.6667 0.166504H1.33341C0.689998 0.166504 0.166748 0.689754 0.166748 1.33317V11.8332L3.27766 9.49984H10.6667C11.3102 9.49984 11.8334 8.97659 11.8334 8.33317V1.33317C11.8334 0.689754 11.3102 0.166504 10.6667 0.166504ZM10.6667 8.33317H2.88916L1.33341 9.49984V1.33317H10.6667V8.33317Z" fill="#8B81A0"/>-->
<!--        </svg>-->
<!--        <span>{{ $t('bank.info') }}</span>-->
<!--      </div>-->
<!--    </actions-popup>-->
  </div>
</div>
</template>

<style scoped lang="scss">
.preview-invoice {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(105px,1fr));
  grid-gap: 5px;
  grid-auto-flow: column;
  min-height: 88px;
  background: var(--new-front-main-bg);
  color: var(--new-front-primary-font-color);
  border-bottom: 1px solid var(--new-front-divider-color);
  cursor: pointer;
  padding: 0 $p30;
  &__item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: $Gilroy;
    font-size: 16px;
    font-weight: 500;
    color: var(--new-front-primary-font-color);
    &-currency {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
  &__actions {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  @media screen and (max-width: 576px) {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: unset;
    &:last-child {
      border: none;
    }
    &__item {
      &:nth-child(even) {
        justify-content: flex-end;
      }
    }
  }
}
.desktopIban {
  display: inline-block;
  @media screen and (max-width: 900px) {
    display: none;
  }
}
.mobileIban {
  display: none;
  @media screen and (max-width: 900px) {
    display: inline-block;
  }
}
.statusLabel {
  padding: 4px $p15;
  background: var(--new-front-status-processed);;
  color: var(--new-front-status-text-processed);
  border-radius: 10px;
  font-size: $h4;
  font-weight: 500;
  &--reject {
    background: var(--new-front-status-error);
    color: var(--new-front-status-text-error);
  }
  &--moderation {
    background: var(--new-front-status-pending);
    color: var(--new-front-status-text-pending);
  }
  &--pre_moderation {
    color: var(--new-front-status-text-expired);
    background: var(--new-front-status-expired);
  }
  &--success {
    background: var(--new-front-status-processed);
    color: var(--new-front-status-text-processed);
  }
}
.actions__modal-item {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 100px;
  width: 100%;
  border-bottom: 1px solid var(--new-front-input-border);
  color: var(--new-front-secondary-font-color);
  font-weight: 500;
  padding: $p10;
  transition: all .2s ease;
  cursor: pointer;
  &:hover {
    color:var(--new-front-primary-font-color) ;
    background: var(--new-font-nav-hover-bg);
  }
  &:first-of-type {
    &:hover {
      &::v-deep svg {
        path {
          stroke: #744DB2 !important;
        }
      }
    }
  }
  &:nth-child(2){
    border-bottom: unset;
    &:hover {
      &::v-deep svg {
        path {
          fill: #744DB2 !important;
        }
      }
    }
  }
}
</style>
