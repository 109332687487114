<template>
  <store-modal
    name="LanguagesModal"
    class="languages-modal"
    height="529px"
  >
    <template #header>
      <div class="title">
        {{ $t('languages.select') }}
      </div>
    </template>
    <template #body>
      <div class="languages-body">
        <div
          v-for="(l, index) in langs"
          :key="index"
          class="language-block"
          @click="setLanguage(l.name)"
        >
          <languages-icons
            :id="`lang-icon-${l.name}`"
            :key="`lang-icon-${l.name}`"
            :name="l.name"
            :active="l.name === lang"
          />
          <span
            class="description"
            :class="{ 'active': l.name === lang }"
          >
            {{ l.description }}
          </span>
        </div>
      </div>
    </template>
    <template #footer>
      <NButton
        @click="closeModal"
      >{{ $t('new_checkout.history.ok') }}</NButton>
    </template>
  </store-modal>
</template>

<script>
  import {mapActions, mapMutations, mapState} from "vuex";
  import NButton from "../NButton";
  import {i18n} from "../../../i18n";

  export default {
  name: "LanguagesModal",
  components: {
    NButton,
    StoreModal: () => import(/* webpackChunkName: "/entries/checkout/components/Modal/StoreModal" */ '/public/components/modals/StoreModal'),
    LanguagesIcons: () => import(/* webpackChunkName: "@/entries/checkout/components/LanguagesIcons" */ './LanguagesIcons'),
  },
  data() {
    return {
      langs: [
        {
          name: 'en',
          description: 'English',
        },
        {
          name: 'ru',
          description: 'Русский',
        },
        // {
        //   name: 'lt',
        //   description: 'Lietuva',
        // },
        // {
        //   name: 'es',
        //   description: 'España',
        // },
        // {
        //   name: 'lv',
        //   description: 'Latvia',
        // },
        //
        // {
        //   name: 'ua',
        //   description: 'Україна',
        // },
        // {
        //   name: 'pl',
        //   description: 'Polska',
        // },
        // {
        //   name: 'kr',
        //   description: '한국',
        // },
        // {
        //   name: 'de',
        //   description: 'Deutschland',
        // },
        // {
        //   name: 'tp',
        //   description: 'Portugal',
        // },
        // {
        //   name: 'tr',
        //   description: 'Türkiye',
        // },
        // {
        //   name: 'fi',
        //   description: 'Finland',
        // },
        // {
        //   name: 'fr',
        //   description: 'France',
        // },
        // {
        //   name: 'ee',
        //   description: 'Eesti',
        // },
        // {
        //   name: 'bg',
        //   description: 'България',
        // },
        // {
        //   name: 'gr',
        //   description: 'Ελλάδα',
        // },
        // {
        //   name: 'it',
        //   description: 'Italia',
        // },
        // {
        //   name: 'cn',
        //   description: '中国 (简化的)',
        // },
        // {
        //   name: 'jp',
        //   description: '日本',
        // }
      ],
    };
  },
  computed: {
    ...mapState('user',[
      'user',
    ]),
    lang () {
      return i18n.locale
    }
  },
  methods: {
    ...mapActions('app', [
      'closeModal',

    ]),
    ...mapMutations ('user', ['changeLangMutations']),
      ...mapActions('user', ['changeLangQuery']),
    setLanguage(language) {
      // this.changeLanguage(language);
      this.closeModal()
      document.cookie = `lang=${ language }`
      i18n.locale = language;
      localStorage.setItem('lang', language);
      this.changeLangMutations(language)
        this.changeLangQuery({ lang: language })
    }
  }
}
</script>

<style lang="scss">
.languages-modal {
  font-family: 'Gilroy';

  .title {
    font-weight: 500;

    text-align: left;

    color: var(--primary-font-color);
    font-size: var(--font-size-extra);
  }
  .languages-body {
    display: flex;
    flex-wrap: wrap;

    padding: 0 32px;
    .language-block {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      cursor: pointer;

      width: 143px;
      min-width: 143px;
      max-width: 143px;

      margin: 8px 0;

      .description {
        margin-left: 8px;
        font-size: var(--font-size-normal);
        color: var(--secondary-font-color);

        &.active {
          color: var(--primary-font-color);
        }
      }
    }

    @media screen and (max-width: 480px) {
      padding: 0 18px;

      .language-block {
        width: 120px;
        min-width: 120px;
        max-width: 120px;
      }
    }
  }
}
</style>
