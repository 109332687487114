<template>
    <div class="item">
        <div class="item__element">
            <div class="item__element-value--black">
                {{ moment(item.createdAt).format('DD/MM/YY HH:mm') }}
            </div>
        </div>
        <div class="item__element">
            <div class="item__element-value--black">
                {{ item.connectingIp }}
            </div>
            <copy v-if="item.connectingIp"  :value="item.connectingIp" />
        </div>
        <div class="item__element">
            <div class="item__element-value--black">
                {{ getPrettyUserAgent(item.userAgent) }}
            </div>
            <copy v-if="item.userAgent"  :value="item.userAgent" />
        </div>
    </div>
</template>
<script >

    import Copy from "@/public/components/common/copy.vue";
    import moment from "moment";
    import BigNumber from "bignumber.js";
    import CurrencyNetworkIcon from "@/public/components/elements/business-walet/Select/CurrencyNetworkIcon.vue";
    import {mapState} from "vuex";
    import {getPrettyAddress} from "@/public/filters";

    export default {
        name: "AuthorizationHistoryItem",
        components: {CurrencyNetworkIcon, Copy},
        props: {
            item: {
                type: Object,
                default: () => ({})
            }
        },
        computed: {
            ...mapState('app', ['networks', 'currencies']),
            id () {
                if (!this.item?.id) return ''
                return getPrettyAddress(this.item?.id)
            },
            moment () {
                return moment
            }
        },
        methods: {
            goToDetails () {
                this.$router.push(`/webhooks/${ this.item.id }`)
            },
            getPrettyUserAgent (txt) {
                if (txt.length <= 15) {
                    return txt
                }
                return `${txt.substr(0, 30)}...`
            }
        }
    }
</script>
<style scoped lang="scss">
.item {
  padding: 0 $p30;
  min-height: 105px;
  border-bottom: 1px var(--new-front-divider-color) solid;
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(105px,1fr));
  grid-auto-flow: column;
    gap: 8px;
  align-items: center;
    position: relative;
  //&:hover {
  //  background: var(--new-front-bg-btn-primary-bg-primary-hover);
  //}
  &__element {
    display: flex;
    align-items: center;
    //justify-content: space-between;
    //gap: 10px;
      &-value {
          color: var(--new-front-purple);
          text-decoration: underline;
          font-weight: 500;
          font-size: $h4;
          &--noUnderline {
              text-decoration: none !important;
          }
          &--black {
              color: var(--new-front-primary-font-color);
              font-weight: 500;
              font-size: $h4;
          }
      }

  }
  &__asset {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &-currency {
      font-weight: 500;
      font-size: $h4;
      color: var(--new-front-primary-font-color);
      margin: 0 10px;
    }
    &-network {
      font-weight: 500;
      font-size: $h4;
      color: var(--new-front-secondary-font-color);
    }
  }
}
.item__status {
  width: 10px;
  height: 10px;
  border-radius:2px;
    background: #44B070;
    &--error {
        background: #CF5252;
    }
    &--pending {
        background: #BE9B40;
    }
    &--processed {
        background: #44B070;
    }
}
.item__arrow {
    position: absolute;
    right: 0;
    width: 20px;
    height: 100%;
    cursor: pointer;
    > svg {
        position: relative;
        top: 44px;
    }
}
.orphan__statusLabel {
  padding: 4px $p15;
  background: var(--new-front-status-processed);;
  color: var(--new-front-status-text-processed);
  border-radius: 10px;
  font-size: $h4;
  font-weight: 500;
  &--error {
    background: var(--new-front-status-error);
    color: var(--new-front-status-text-error);
  }
  &--pending {
    background: var(--new-front-status-pending);
    color: var(--new-front-status-text-pending);
  }
  &--processed {
    background: var(--new-front-status-processed);
    color: var(--new-front-status-text-processed);
  }
}
//
//PENDING
//PROCESSED
//ERROR
//REJECTED
</style>
