import getHistoryAddressQuery from "/public/graphQl/queries/getHistoryAddress";

export const fetchHistoryAddress = async ({dispatch, commit, rootGetters}, payload) => {
    let historyData;
    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    commit('setTransactionInfoState', true);
    try {
        // commit('app/toggleLoading', null, {root: true});
        commit('setFetching', true);
        historyData = await dispatch('app/request', {
            type: 'query',
            gql: getHistoryAddressQuery,
            variables: {
                organizationId,
                ...payload
            },
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        // commit('app/toggleLoading', null, {root: true});
        commit('setFetching', false);
    }

    if (historyData.message) {
        throw historyData.message;
    }

    if (historyData.errors) {
        throw historyData.errors;
    }

    const {
        data: {getAddressTransaction},
    } = historyData;
    commit('setTransaction', getAddressTransaction);
    return getAddressTransaction;
};
