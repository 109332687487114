<template>
  <div class="registry-preview__container">

    <div class="registry-preview__currency-container">

      <p class="registry-preview__registry-title">
        {{ registry.title }}
      </p>


    </div>
    <p class="registry-preview__date-register">
      {{
        dateCreated
      }}
    </p>


  </div>
</template>

<script>


import moment from "moment";

export default {
    name: "PayoutWalletRegistryPreview",
    props: {
        registry: {
            type: Object,
            required: true,
        },
    },
    computed: {
        dateCreated() {
            return moment(this.registry.createdAt).format("DD.MM.YYYY");
        },
    },
};

</script>

<style lang="scss" scoped>
.registry-preview {
  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-right: 60px;
    align-items: center;
    width: 100%;
  }

  &__registry-title {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

  &__date-register {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

}
</style>