<template>
  <BaseModal
      class="remove-api-keys-modal"
      width="100%"
      :value="isOpen"
      :title="title"
      :show-close="true"
      :show-head="!isLoading"
      :show-footer="!isLoading"
      @on-cancel="handleCancelCreate"
  >
    <template v-if="!isLoading">
      <template v-if="!isSuccessUpload">

        <div class="remove-api-keys-modal__text-container"><p class="remove-api-keys-modal__message">
          {{ $t('payout_modal_upload.subtitle') }}
        </p>
          <p class="remove-api-keys-modal__error" v-if="isError">{{ errorMessage }}</p></div>
        <div class="remove-api-keys-modal__row ">
          <div class="remove-api-keys-modal__title">
            {{ $t('payout_modal_upload.label') }}
          </div>
          <div>
            <AtInput
                v-model="titleModel"
                :maxlength="255"
                size="normal"
                @focus="clearTitleError"
            />
          </div>
          <span class="remove-api-keys-modal__error" v-if="error.title">{{ error.title }}</span>
        </div>

        <div class="remove-api-keys-modal__dnd-container" ref="dragArea" @dragenter.prevent="dragEnter"
             @dragover.prevent="dragover"
             @dragleave.prevent="dragleave"
             @drop.prevent='drop'>
          <label for="file-input" class="remove-api-keys-modal__input-label">
            <svg v-if="!isDropped " width="42" height="42" viewBox="0 0 42 42" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M21 8.75V33.25" stroke="#8F949A" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M8.75 21H33.25" stroke="#8F949A" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
            <svg v-if="isDropped" width="42" height="42" viewBox="0 0 42 42" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M35 10.5L15.75 29.75L7 21" stroke="#27AE60" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
            <p v-if="isDropped">{{ $t('payout_modal_upload.placeholder-file.uploaded') }}</p>
            <p v-if="!isActive && !isDropped">{{ $t('payout_modal_upload.placeholder-file.drag') }}</p>
            <p v-if="!isActive && !isDropped">{{ $t('payout_modal_upload.placeholder-file.press') }}</p>
            <p v-if="isActive">{{ $t('payout_modal_upload.placeholder-file.drop') }}</p>
            <input
                class="remove-api-keys-modal__image-upload"
                type="file"
                id="file-input"
                @change="handleSelectImage" ref="file"/>
          </label>

          <span class="remove-api-keys-modal__error" v-if="error.file">{{ error.file }}</span>

        </div>
        <div class="remove-api-keys-modal__error-container" v-if="registryFile.result.errors">
          <div v-for="(error, index) in registryFile.result.errors" :key="index">
            <PayoutModalUploadErrors :error="error"/>
          </div>

        </div>

      </template>
      <template v-else-if="isSuccessUpload && !isSuccess">
        <p class="remove-api-keys-modal__message">
          {{ `Реестр ${registryFile.result.registry.title} успешно загружен` }}
        </p>
      </template>
      <template v-else-if="isSuccess">
        <p class="remove-api-keys-modal__message">
          {{ `Реестр успешно сохранен ${registryUploaded}` }}
        </p>
      </template>

    </template>
    <LoadingSpinner
        v-else-if="isLoading"
        :title="deleting_an_api_key"
    />

    <template #footer>
      <template v-if="!isSuccessUpload">
        <AtButton @click="handleCancelCreate">
          {{ $t('payout_modal_upload.button.cancel') }}
        </AtButton>
        <AtButton
            class="remove-api-keys-modal__full-width-btn"
            type="primary"
            @click="handleUploadImage"
        >
          {{ $t('payout_modal_upload.button.upload') }}
        </AtButton>

      </template>
      <template v-else-if="isSuccessUpload">
        <AtButton @click="handleCancelCreate">
          {{ $t('create-order-modal.close') }}
        </AtButton>
        <AtButton
            v-if="!isSuccessSaved"
            class="remove-api-keys-modal__full-width-btn"
            type="primary"
            @click="handleUploadRegistry"
        >
          {{ $t('payout_modal_upload.button.save') }}
        </AtButton>
      </template>
      <!--      <AtButton-->
      <!--          v-else-->
      <!--          class="remove-api-keys-modal__full-width-btn"-->
      <!--          type="primary"-->
      <!--          @click="handleUploadImage"-->
      <!--      >-->
      <!--        {{ $t('api_keys.close') }}-->
      <!--      </AtButton>-->
    </template>

  </BaseModal>
</template>

<script>

import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
import AtModal from 'at-ui/src/components/modal';
import LoadingSpinner from "/public/components/elements/LoadingSpinner";
import AtButton from 'at-ui/src/components/button';
import BaseModal from "../BaseModal";
import {uploadRegistryErrors} from "../../../filters";
import PayoutModalUploadErrors from "../../elements/payoutWallet/PayoutModalUploadErrors";

export default {
    name: 'PayoutModalUploadCSV',
    components: {
        PayoutModalUploadErrors,
        BaseModal,
        AtModal,
        LoadingSpinner,
        AtButton,
    },
    data() {
        return {
            isLoading: false,
            isSuccessUpload: false,
            isSuccess: false,
            isSuccessSaved: false,
            isError: false,
            error: {
                title: null,
                file: null,
            },
            errorMessage: '',
            titleModel: '',
            file: '',
            registryUploaded: '',
            inActiveTimeout: null,
            isActive: false,
            isDropped: false,
        };
    },
    computed: {
        ...mapState('app', {
            modalPayload: state => state.modal.payload,
        }),
        ...mapGetters('app', [
            'isOpenModal',
        ]),
        ...mapState('payoutBalances', ['registryFile']),
        isOpen() {
            const {
                $options: {
                    name,
                },
                isOpenModal,
            } = this;

            return isOpenModal(name);
        },
        title() {
            return this.$t('payout_modal_upload.title');
        },
        allowedMimes() {
            const {modalPayload} = this;
            return modalPayload?.allowedMimes.join(',') ?? null;
        },
        alias() {
            return this.modalPayload?.alias ?? '';
        },
        hideFooter() {
            const {
                isLoading,
                isSuccessUpload,
            } = this;

            return isLoading || isSuccessUpload;
        },
        deleting_an_api_key() {
            return this.$t('api_keys .deleting_an_api_key');
        },
    },
    methods: {
        ...mapActions('app', [
            'closeModal',
        ]),
        clearTitleError() {
            this.error.title = null;
        },
        setActive() {
            this.isActive = true;
            clearTimeout(this.inActiveTimeout);
        },
        setInactive() {
            this.inActiveTimeout = setTimeout(() => {
                this.isActive = false;
            }, 50);
        },
        handleUploadRegistry() {
            this.isLoading = true;
            this.saveRegistryMutation({registryId: this.registryFile.result.registry.id}).then((res) => {
                this.isSuccessSaved = true;
                this.registryUploaded = res.title;
            }).finally(() => {
                this.isLoading = false;
            });
        },
        ...mapMutations('payoutBalances', ['clearRegistryFile']),
        ...mapActions('payoutBalances', ['uploadRegistryMutation', 'saveRegistryMutation', 'fetchRegistries']),
        clearAllData() {
            this.isLoading = false;
            this.isSuccessUpload = false;
            this.isSuccess = false;
            this.titleModel = '';
            this.file = '';
            this.isActive = false;
            this.isDropped = false;
            this.error = {
                title: null,
                file: null,
            };
            this.clearRegistryFile();
        },
        handleSelectImage(e) {
            const value = this.$refs.file.files;
            this.clearRegistryFile();
            this.error.file = null;
            this.isDropped = true;
            this.file = value[0];
        },
        handleUploadImage() {
            this.error = {
                title: null,
                file: null,
            };

            if (!this.titleModel) this.error.title = 'Введите название';
            if (!this.file) this.error.file = 'Загрузите файл';
            if (!this.titleModel || !this.file) return;
            this.isLoading = true;
            this.uploadRegistryMutation({file: this.file, title: this.titleModel}).then((res) => {
                if (this.registryFile.code === 0) {
                    this.isSuccessUpload = true;
                    this.isSuccess = false;
                    this.isLoading = false;
                    return;
                }
                this.isSuccessUpload = false;
                this.isDropped = false;
                this.isLoading = false;
            }).finally(() => {
                this.isLoading = false;
            });
            // const {modalPayload} = this;
            // const fileSize = value.target.files[0].size;
            // const fileType = value.target.files[0].type;
            // this.errorMessage = '';
            // if (fileSize < modalPayload.minImageSize) {
            //     this.isError = true;
            //     this.errorMessage = 'Слишком маленький файл';
            // } else if (fileSize > modalPayload.maxImageSize) {
            //     this.isError = true;
            //     this.errorMessage = 'Слишком большой файл';
            // } else {
            //     this.$emit('uploadImage', value);
            //     this.closeModal();
            // }
        },
        drop(event) {
            event.preventDefault();
            this.setInactive();
            const dragArea = this.$refs.dragArea;
            dragArea.classList.remove('remove-api-keys-modal__dnd-container_drag-over');
            this.isDropped = true;
            this.$refs.file.files = event.dataTransfer.files;
            this.file = event.dataTransfer.files[0];
            this.error.file = null;
            // if (this.titleModel) return;
            // this.handleUploadImage(this.$refs.file);
        },
        dragover() {
            const dragArea = this.$refs.dragArea;
            this.setActive();
            dragArea.classList.add('remove-api-keys-modal__dnd-container_drag-over');
        },
        dragleave() {
            this.setInactive();
            const dragArea = this.$refs.dragArea;
            dragArea.classList.remove('remove-api-keys-modal__dnd-container_drag-over');
        },
        dragEnter(event) {
            this.setActive();
        },
        handleCancelCreate() {
            const {
                closeModal,
                clearAllData,
                fetchRegistries,
                $route,
            } = this;

            closeModal();

            if (this.isSuccessSaved) {
                fetchRegistries({
                    route: $route,
                    showUrlFilters: false,
                });
            }
            setTimeout(() => {
                clearAllData();
            }, 500);

        },
    },
};

</script>

<style lang="scss" scoped>

.remove-api-keys-modal {
  ::v-deep .at-input__original {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);

    &::placeholder {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
    }
  }

  &__input-label {
    cursor: pointer;
    display: grid;
    place-items: center;

    ::v-deep input[type=file]:not(:focus-visible) {
      position: absolute !important;
      width: 1px !important;
      height: 1px !important;
      padding: 0 !important;
      margin: -1px !important;
      overflow: hidden !important;
      clip: rect(0, 0, 0, 0) !important;
      white-space: nowrap !important;
      border: 0 !important;
    }
  }

  &__row {
    margin-bottom: 35px;
    align-items: center;
    min-height: 30px;
    width: 100%;
    position: relative;
  }

  &__dnd-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    min-width: 200px;
    border: 1px dashed #f2f2f2;
    position: relative;

    &_drag-over {
      opacity: 0.5;
    }
  }

  &__full-width-btn {
    width: 100%;
    position: relative;
  }

  &__text-container {
    margin-bottom: 55px;
  }

  &__error-container {
    display: grid;
    row-gap: 10px;
  }

  &__error {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    font-weight: 300;
    color: #EB5757;
    position: absolute;
    bottom: -25px;
    left: 0;
  }

  &__image-upload {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    color: #000;
  }

  &__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2C405A;
    margin-bottom: 10px;

    &_slider {
      margin-bottom: 35px;
    }
  }

  &__alias {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #2C405A;
    margin-top: 25px;
  }

  //::v-deep .at-modal__footer {
  //  padding-left: 16px;
  //  padding-right: 16px;
  //}
}

</style>
