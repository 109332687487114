import getSwapTransfersGQL from "../../../../graphQl/queries/getSwapTransfers";

export const getSwapTransfersList = async ({commit, dispatch, rootGetters}, payload) => {
    const selectedOrganizationId = rootGetters['app/getSelectedOrganizationId'];
    let response;

    const variables = {
        ...payload,
        organizationId: selectedOrganizationId
    }

    try {
        response = await dispatch('app/request', {
            type: 'query',
            gql: getSwapTransfersGQL,
            variables,
        }, { root: true });
    } catch (error) {
        return error;
    }

    return ( response.data && response.data.crosschainSwaps ) ? response.data.crosschainSwaps : {}
};
