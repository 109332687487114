export const state = {
    transactionsCountOnPage: 10,
    transactions: [],
    totalTransactionsCount: null,
    isLoading: false,
    transaction: null,
    isInfoOpened: false,
    columns: [],
    defaultColumns: [
        {title: 'date_of_creation', active: true},
        {title: 'type', active: true},
        {title: 'basis', active: true},
        {title: 'source', active: true},
        {title: 'method', active: true},
        {title: 'CurrencyNetwork', active: true},
        {title: 'address_from', active: true},
        {title: 'address_to', active: true},
        {title: 'tx_hash', active: true},
        {title: 'status', active: true},
        {title: 'debitCredit', active: true},
    ]
};
