<template>
  <BaseModal
      class="payout-modal-transfer"
      width="100%"
      :value="isOpen"
      :title="title"
      :show-close="true"
      :show-head="!withdrawalCreating"
      :show-footer="!withdrawalCreating"
      @on-confirm="createWithdrawal"
      @on-cancel="handleCancelCreate"
  >
    <LoadingSpinner
        v-if="withdrawalCreating"
        :title="creating_an_application"
    />
    <template v-if="withdrawalCreated">
      <div v-if="_withdrawalError" class="payout-modal-transfer__result">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M20 0C31.046 0 40 8.956 40 20C40 31.044 31.046 40 20 40C8.954 40 0 31.044 0 20C0 8.956 8.954 0 20 0ZM20.004 26.008C19.7364 25.9995 19.4699 26.0449 19.2202 26.1415C18.9705 26.238 18.7428 26.3838 18.5505 26.57C18.3583 26.7563 18.2054 26.9793 18.101 27.2258C17.9966 27.4723 17.9428 27.7373 17.9428 28.005C17.9428 28.2727 17.9966 28.5377 18.101 28.7842C18.2054 29.0307 18.3583 29.2537 18.5505 29.44C18.7428 29.6262 18.9705 29.772 19.2202 29.8685C19.4699 29.9651 19.7364 30.0105 20.004 30.002C20.5227 29.9856 21.0147 29.768 21.3758 29.3953C21.7369 29.0226 21.9388 28.524 21.9388 28.005C21.9388 27.486 21.7369 26.9874 21.3758 26.6147C21.0147 26.242 20.5227 26.0244 20.004 26.008ZM20 10C19.5098 10.0001 19.0368 10.1803 18.6707 10.5062C18.3045 10.8321 18.0709 11.2811 18.014 11.768L18 12L18.004 22.002L18.018 22.236C18.0758 22.7221 18.3099 23.1701 18.6759 23.4952C19.0419 23.8202 19.5145 23.9998 20.004 23.9998C20.4935 23.9998 20.9661 23.8202 21.3321 23.4952C21.6981 23.1701 21.9322 22.7221 21.99 22.236L22.004 22.002L22 12L21.986 11.766C21.9287 11.2795 21.6948 10.8309 21.3287 10.5054C20.9627 10.1799 20.4899 10.0001 20 10Z"
              fill="#EB5757"/>
        </svg>
        <p class="payout-modal-transfer__result-message">{{ $t(_withdrawalError) }}</p>
      </div>
      <div class="payout-modal-transfer__result" v-else>
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M24 45C35.598 45 45 35.598 45 24C45 12.402 35.598 3 24 3C12.402 3 3 12.402 3 24C3 35.598 12.402 45 24 45Z"
              fill="#4CAF50"/>
          <path d="M34.6 14.6L21 28.2L15.4 22.6L12.6 25.4L21 33.8L37.4 17.4L34.6 14.6Z" fill="#CCFF90"/>
        </svg>

        <p class="payout-modal-transfer__result-message"> {{ $t('payout-modal-transfer.output_created') }} </p>
      </div>
    </template>

    <template v-else-if="!withdrawalCreating && !withdrawalCreated">

      <div class="payout-modal-transfer__select-currency-container">
        <div class="payout-modal-transfer__title ">
          {{ $t('payout-modal-transfer.output_address') }}
        </div>
        <div
            :class="['payout-modal-transfer__select-currency', addressId ? 'payout-modal-transfer__select-currency_selected' : '']">
          <div class="payout-modal-transfer__select-currency-container-selected" v-if="addressId">
            <div class="payout-modal-transfer__select-currency-image-container">
              <CurrencyImg
                  v-if="getCurrencyImageForSelect"
                  class="currency-img_preview"
                  :currency="getCurrencyImageForSelect"
              />
              <span class="payout-modal-transfer__select-currency-title">
                {{ getCurrencyImageForSelect | getCurrencyTicker }}

              </span></div>
            <p class="payout-modal-transfer__select-currency-title">
              {{ getNetworkIdForSelect | getNetworkName }}

            </p>

            <p class="payout-modal-transfer__select-currency-title">
              {{ getSelectedAddress.balance }}

            </p>
          </div>

          <AtSelect
              :placeholder="select_address"
              v-model="addressId"
              class="payout-modal-transfer__select-currency-select"
              clearable
          >
            <AtOption
                v-for="address in addressesList"
                :key="address.id"
                :value="address.id"
                :label="address.label"
            >

              <div class="payout-modal-transfer__currency-item" v-if="address.id">
                <div class="payout-modal-transfer__currency-info">
                  <CurrencyImg class="payout-modal-transfer__currency-input-selected-image"
                               :currency="address.currencyId"/>
                  <div class="payout-modal-transfer__currency-text-container">
                    <p class="payout-modal-transfer__currency-text-title">{{
                        address.currencyId | getCurrencyTicker
                      }}</p>
                    <p class="payout-modal-transfer__currency-text-subtitle">{{
                        address.alias
                      }}</p>
                  </div>
                </div>
                <p class="payout-modal-transfer__currency-text">{{ address.networkId | getNetworkName }}</p>
                <p class=" payout-modal-transfer__currency-text-title">{{ address.balance }}</p>
              </div>

            </AtOption>
          </AtSelect>

        </div>

        <svg class="payout-modal-transfer__arrow" width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M12 4L12 20M19 13L12 20L5 13" stroke="#8F949A" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>

      </div>


      <!--            если выбран адрес, то показываем эти блоки-->
      <template>
        <div class="payout-modal-transfer__title  ">
          {{ $t('payout-modal-transfer.receiving_address') }}
        </div>
        <div class="payout-modal-transfer__select-currency payout-modal-transfer__select-currency_address">

          <AtSelect
              :placeholder="select_address"
              v-model="addressTo"
              clearable
          >
            <AtOption
                v-for="address in balanceType"
                :key="address.label"
                :value="address.value"
                :label="address.label"
            >
              {{ $t(address.label) }}
            </AtOption>
          </AtSelect>
          <p class="payout-modal-transfer__status">{{ $t(selectedReceivingAddress) }}</p>
        </div>


        <template v-if="hasTag">
          <div class="payout-modal-transfer__title  ">
            {{ $t('payout-modal-transfer.tag') }}
          </div>
          <div class="">
            <AtInput
                v-model.trim="tag"
                :status="tag && !tagValid ? 'error' : ''"
                :placeholder="enter_tag"
            />
          </div>
        </template>

        <div class="payout-modal-transfer__line"/>
        <div class="payout-modal-transfer__title  ">
          {{ $t('payout-modal-transfer.amount') }}
        </div>
        <div class="">

          <div class="amount-input" :class="{'error': amount && !amountValid}">
            <input
                type="number"
                :min="minWithdrawal"
                :max="balanceAmount"
                :placeholde="enter_amount"
                class="input"
                v-model="amount"
                @input="getFeeForTransferInputWrap"
            />
            <a href="#" class="max-button"
               @click.prevent="setMaxAmount">{{ $t('payout-modal-transfer.max') }}</a>
          </div>

        </div>

        <div v-if="showMinWithdrawalTip" class=" payout-modal-transfer__title-special">
          {{ $t('payout-modal-transfer.minimum_withdrawal') }} {{ minWithdrawal }}
          {{ balanceCurrencyId | getCurrencyTicker }}
        </div>
        <LoadingSpinner class="payout-modal-transfer__info-container" v-if="isDebouncing || _feePending"/>
        <div class="payout-modal-transfer__info-container"
             v-if="addressId && amount && (isDebouncing !== null && !isDebouncing) && !_feePending">
          <div class="payout-modal-transfer__title1">
            {{ $t('payout-modal-transfer.advance_balance') }}: <span
              :class="{danger: !balanceValid}"><b>{{
              accountBalance
            }} {{ accountCurrencyId | getCurrencyTicker }}</b></span>
          </div>
          <div class="payout-modal-transfer__title1">
            {{ $t('payout-modal-transfer.address_balance') }}: <span><b>{{
              balanceAmount
            }} {{ balanceCurrencyId | getCurrencyTicker }}</b></span>
          </div>


          <div class="payout-modal-transfer__title1" v-if="feeSourceTitle">
            {{ $t('payout-modal-transfer.commission_source') }} <b>{{ feeSourceTitle }}</b>
          </div>
          <div class="payout-modal-transfer__title1">

            {{ $t('payout-modal-transfer.commission') }}
            <div>
              <b>{{ feeAmount }}
                {{ feeCurrency | getCurrencyTicker }}</b> <span
                style="color: grey;">(${{ feeUSD | prettifyAmount(2) }})</span>
              <span v-if="feeAvailableSeconds !== -1" style="color: grey;">({{ feeAvailableSeconds }}s)</span>
              <a v-else href="#" @click="fetchFee">{{ $t('payout-modal-transfer.update') }}</a>
              <!--                    </AtTooltip>-->
            </div>
          </div>


          <div class="payout-modal-transfer__title1">
            {{ $t('payout-modal-transfer.receive') }} <b>{{ amountToReceive }}
            {{ balanceCurrencyId | getCurrencyTicker }}</b>
          </div>
        </div>
      </template>
    </template>
    <template #footer>
      <template v-if="!withdrawalCreated">
        <AtButton @click="handleCancelCreate">
          {{ $t('payout-modal-transfer.close') }}
        </AtButton>
        <AtButton
            type="primary"
            @click="createWithdrawal"
            :disabled="actionButtonDisabled"
        >
          {{ actionButtonTitle }}
        </AtButton>
      </template>
      <AtButton
          v-else
          class="payout-modal-transfer__full-width-btn"
          type="primary"
          @click="handleCancelCreate"
      >
        {{ $t('payout-modal-transfer.close') }}
      </AtButton>
    </template>
  </BaseModal>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';
import AtModal from 'at-ui/src/components/modal';
import CurrencyImg from "/public/components/elements/CurrencyImg";
import AtInput from 'at-ui/src/components/input';
import AtRadioGroup from 'at-ui/src/components/radio-group';
import AtRadio from 'at-ui/src/components/radio';
import LoadingSpinner from "/public/components/elements/LoadingSpinner";
import AtButton from 'at-ui/src/components/button';
import AtAlert from 'at-ui/src/components/alert';
import BaseModal from "../BaseModal";
import CopyString from "/public/components/elements/CopyString";
import moment from "moment";
import bchaddr from 'bchaddrjs';
import BigNumber from "bignumber.js";
import {FEE_TOKEN_SOURCE} from "../../../../common/constants/fee-token-source";
import _ from "lodash";
import Preloader from "../../common/preloader";


export default {
    name: 'PayoutModalTransfer',
    components: {
        Preloader,
        BaseModal,
        AtSelect,
        AtOption,
        AtModal,
        CurrencyImg,
        AtInput,
        AtRadioGroup,
        AtRadio,
        LoadingSpinner,
        AtButton,
        AtAlert,
        CopyString,
    },
    balanceType: [
        {
            value: 'ADVANCE',
            label: 'payout-modal-transfer.advance',
        },
        {
            value: 'PAY_OUT',
            label: 'payout-modal-transfer.pay-out',
        },
    ],
    data() {
        return {
            accountId: '',
            addressId: '',
            addressTo: '',
            address: '',
            tag: '',
            amount: '',
            isDebouncing: null,
            time: new Date().toJSON(),
            timerId: 0,
        };
    },
    computed: {
        ...mapState('app', {
            currencies: state => state.currencies,
            addressWithdrawal: state => state.modal.payload.addressId,
        }),
        ...mapState('accounts', [
            'accounts',
        ]),
        ...mapGetters('balances', [
            'balances',
        ]),
        ...mapGetters('app', [
            'isOpenModal',
        ]),
        ...mapState('payoutBalances', {
            _feePending: state => state.fee.pending,
            _feeComplete: state => state.fee.complete,
            _feeCode: state => state.fee.code,
            _feeAmount: state => state.fee.amount,
            _feeUSD: state => state.fee.usdAmount,
            _feeUntil: state => state.fee.until,
            _feeCurrency: state => state.fee.currency,
            _feeToken: state => state.fee.token,
            _feeSource: state => state.fee.source,
            _withdrawalPending: state => state.deposit.pending,
            _withdrawalComplete: state => state.deposit.complete,
            _withdrawalError: state => state.deposit.error,
            _withdrawalId: state => state.deposit.id,
            _withdrawalStatus: state => state.deposit.status,
            _withdrawalTX: state => state.deposit.tx,
        }),
        balanceType() {
            if (this.addressId && !this.account.availableCurrenciesForDeposit.some((i) => i === this.balanceCurrencyId)) {
                return this.$options.balanceType.filter((i) => i.value === 'PAY_OUT');
            }
            return this.$options.balanceType;

        },
        withdrawalCreated() {
            return !this._withdrawalPending && this._withdrawalComplete;
        },
        withdrawalCreating() {
            return this._withdrawalPending;
        },
        selectedReceivingAddress() {
            return this.$options.balanceType.find((balance) => balance.value === this.addressTo)?.label;
        },
        fetchingFee() {
            return this._feePending;
        },
        fetchedSuccessFee() {
            return !this.fetchingFee && this._feeComplete && this._feeCode === 0;
        },
        fetchedFailFee() {
            return !this.fetchingFee && this._feeComplete && this._feeCode !== 0;
        },

        feeSourceTitle() {
            if (this._feeSource === FEE_TOKEN_SOURCE.ADVANCED_BALANCE) {
                return this.$t('payout-modal-transfer.advance_balance');
            } else if (this._feeSource === FEE_TOKEN_SOURCE.WALLET) {
                return this.$t('payout-modal-transfer.address_balance');
            }

            return '';
        },
        feeAmount() {
            if (!this.fetchedSuccessFee) {
                return '0';
            }

            return Number(this._feeAmount);
        },
        feeUSD() {
            if (!this.fetchedSuccessFee) {
                return '0';
            }

            return this._feeUSD;
        },
        feeCurrency() {
            if (!this.fetchedSuccessFee) {
                return '';
            }

            return this._feeCurrency;
        },
        feeAvailableSeconds() {
            const now = moment(this.time);
            const until = moment(this._feeUntil);

            const delta = until.diff(now, 'seconds');

            if (delta > 0) {
                return delta;
            }

            if (delta <= -1) {
                return -1;
            }

            return 0;
        },

        account() {
            return this.accounts.find(a => a.accountId === this.accountId);
        },
        accountCurrency() {
            return this.currencies.find(c => c.id === this.account?.currencyId);
        },
        accountBalance() {
            return this.account?.balance || '0';
        },

        accountCurrencyId() {
            return this.accountCurrency?.id;
        },

        addressesList() {
            let balance = this.balances
                .filter(b => b.accountId === this.account?.accountId && b.balance !== '0')
                .map(b => {
                    const currency = this.currencies.find((i) => i.id === b.currencyId);
                    b = {
                        id: b.id,
                        address: b.address,
                        currencyId: b.currencyId,
                        networkId: b.networkId,
                        balance: b.balance,
                        alias: currency.alias,
                        allowPayout: currency.allowPayout,
                    };
                    return b;
                });
            balance = balance.filter((b) => b.allowPayout);
            if (this.addressTo === 'ADVANCE') {
                return balance = balance.filter((b) => this.account.availableCurrenciesForDeposit.some(c => c === b.currencyId));
            }
            return balance;
        },

        balance() {
            return this.balances.find(a => a.id === this.addressId);
        },
        balanceCurrency() {
            return this.currencies.find(c => c.id === this.balance?.currencyId);
        },
        balanceAmount() {
            return Number(this.balance?.balance || '0');
        },
        balanceNetwork() {
            return this.balanceCurrency?.networks.find(n => n.id === this.balance.networkId);
        },

        balanceCurrencyId() {
            return this.balanceCurrency?.id;
        },
        balanceNetworkId() {
            return this.balanceNetwork?.id;
        },

        hasTag() {
            return this.balance?.tagRegex;
        },

        balanceValid() {
            if (this._feeSource === FEE_TOKEN_SOURCE.ADVANCED_BALANCE) {
                return new BigNumber(this.accountBalance).gte(this.feeAmount);
            }

            return true;
        },
        addressValid() {
            if (this.addressId) {
                if (this.balanceCurrency?.currency === "BCH") {
                    return bchaddr.isValidAddress(this.address);
                }
            }

            return this.balanceNetwork && this.balanceNetwork.addressRegex && new RegExp(this.balanceNetwork.addressRegex).test(this.address);
        },
        tagValid() {
            return this.balanceNetwork && this.balanceNetwork.tagRegex && new RegExp(this.balanceNetwork.tagRegex).test(this.tag);
        },
        amountValid() {
            if (new BigNumber(this.balanceAmount).lt(this.balanceNetwork?.minWithdrawal)) {
                return false;
            }

            if (new BigNumber(this.amount).lt(this.balanceNetwork?.minWithdrawal)) {
                return false;
            }

            if (new BigNumber(this.amount).gt(this.balanceAmount)) {
                return false;
            }

            return true;
        },

        minWithdrawal() {
            return this.balanceNetwork?.minWithdrawal || 0;
        },

        amountToReceive() {
            if (this._feeSource === 'wallet') {
                const amount = new BigNumber(this.amount || '0').minus(this._feeAmount)
                    .dp(8, BigNumber.ROUND_HALF_UP);

                if (amount.lte(0)) {
                    return 0;
                }

                return amount.toNumber();
            }

            return new BigNumber(this.amount || '0')
                .dp(8, BigNumber.ROUND_HALF_UP)
                .toNumber();
        },

        actionButtonDisabled() {
            if (!this.addressId) {
                return true;
            }

            if (this.fetchingFee) {
                return true;
            }

            if (!this.balanceValid && this.addressTo !== 'ADVANCE') {
                return true;
            }

            if (new BigNumber(this.amountToReceive).lte(0)) {
                return true;
            }

            if (this.feeAvailableSeconds < 4) {
                return true;
            }

            if (this.tag && !this.tagValid) {
                return true;
            }

            if (!this.amountValid) {
                return true;
            }

            return false;
        },
        actionButtonTitle() {
            return this.$t('payout-modal-transfer.send');
        },

        isOpen() {
            const {
                $options: {name},
                isOpenModal,
            } = this;

            return isOpenModal(name);
        },
        title() {
            return this.$t(this.isSuccess ? 'payout-modal-transfer.application_created' : 'payout-modal-transfer.creating_application');
        },
        hasErrors() {
            return Boolean(this.errors.length);
        },
        creating_an_application() {
            return this.$t('payout-modal-transfer.creating_an_application');
        },
        select_address() {
            return this.$t('payout-modal-transfer.select_address');
        },
        enter_address() {
            return this.$t('payout-modal-transfer.enter_address');
        },
        enter_tag() {
            return this.$t('payout-modal-transfer.enter_tag');
        },
        enter_amount() {
            return this.$t('payout-modal-transfer.enter_amount');
        },
        showMinWithdrawalTip() {
            return this.minWithdrawal !== 0;
        },
        getCurrencyImageForSelect() {
            if (this.addressId) return this.addressesList.find((i) => i.id === this.addressId).currencyId;
            return '';
        },
        getNetworkIdForSelect() {
            if (this.addressId) return this.addressesList.find((i) => i.id === this.addressId).networkId;
            return '';
        },
        getSelectedAddress() {
            if (this.addressId) return this.addressesList.find((i) => i.id === this.addressId);
            return null;
        },
        getCurrencyName() {
            if (this.getCurrencyImageForSelect) return this.currencies.find((i) => i.id === this.getCurrencyImageForSelect);
            return '';
        },

    },
    methods: {
        ...mapMutations('payoutBalances', ['clearDepositData', 'clearFee', 'setFeePending']),
        ...mapActions('app', [
            'closeModal',
        ]),
        ...mapActions('accounts', [
            'getAllNetworks',
        ]),
        ...mapActions('balances', [
            'getAccountBalancesGrouped',
            'getPrice',
        ]),
        ...mapActions('payoutBalances', [
            'makeTransferRequestMutation',
            'fetchFeeForTransfer',
        ]),

        maxAmount() {
            this.amount = this.balance.balance;
        },
        clearAllData() {
            this.accountId = '';
            this.addressId = '';
            this.address = '';
            this.addressTo = '';
            this.tag = '';
            this.amount = null;
            this.clearFee();
            this.clearDepositData();
        },
        handleCancelCreate() {
            const {
                clearAllData,
                closeModal,
            } = this;
            closeModal();
            if (this.withdrawalCreated) this.getAccountBalancesGrouped({accountId: this.accountId});
            clearAllData();

        },
        startTimer() {
            if (this.timerId) {
                return;
            }

            this.timerId = setInterval(() => {
                this.time = new Date().toJSON();
            }, 1000);
        },
        stopTimer() {
            clearInterval(this.timerId);

            this.timerId = 0;
        },

        fetchFee() {
            if (!(this.addressId && this.accountId && this.amount)) {
                return;
            }
            this.clearFee();
            this.fetchFeeForTransfer({
                accountId: this.accountId,
                addressId: this.addressId,
                amount: this.amount,
            });
        },
        async getFeeForTransferInputWrap() {
            if (!(this.addressId && this.accountId && this.amount)) {
                return;
            }
            this.clearFee();
            this.isDebouncing = true;
            if (this.addressId) {

                await this.getFeeForTransferInput();

            }

        },
        getFeeForTransferInput: _.debounce(function () {

            this.fetchFeeForTransfer({
                accountId: this.accountId,
                addressId: this.addressId,
                amount: this.amount,
            }).finally(() => {
                this.isDebouncing = false;
            });
        }, 1000)
        ,

        createWithdrawal() {
            if (this._withdrawalPending) {
                return;
            }

            const payload = {
                accountId: this.accountId,
                addressId: this.addressId,
                from: 'PAY_IN',
                to: this.addressTo,
                amount: new BigNumber(this.amount)
                    .toFixed(8, BigNumber.ROUND_HALF_DOWN),
                feeToken: this._feeToken,
            };

            this.makeTransferRequestMutation(payload);
        },

        setMaxAmount() {
            if (!(this.addressId && this.accountId)) {
                return;
            }
            this.amount = `${this.balanceAmount}`;
            this.clearFee();
            this.fetchFeeForTransfer({
                accountId: this.accountId,
                addressId: this.addressId,
                amount: this.amount,
            }).finally(() => this.isDebouncing = false);
        },
    },
    watch: {
        withdrawalCreated(n, o) {
            if (!(typeof n !== "undefined" && typeof o !== "undefined")) {
                return;
            }

            if (n === o) {
                return;
            }

            if (!n) {
                return;
            }
        },
        isOpen(status) {
            if (!status) {
                this.clearAllData();
                this.stopTimer();

                return;
            }

            this.startTimer();

            if (this.accounts.length) {
                this.accountId = this.accounts[0].accountId;
            }
            this.$nextTick(() => {
                if (this.addressWithdrawal) {
                    this.addressId = this.addressWithdrawal;
                }
            });
        },
        accounts() {
            if (this.accounts.length) {
                this.accountId = this.accounts[0].accountId;
            }
        },
        _withdrawalError(message) {
            if (!message) {
                return;
            }

            this.$Message.error(this.$t(message));


        },
        amount(n, o) {
            if (!n) {
                return;
            }

            if (n === o) {
                return;
            }
        },
        addressId(n, o) {
            if (!n) {
                return;
            }

            if (n === o) {
                return;
            }
            if (this.isDebouncing === null) {
                this.isDebouncing = false;
            }
            this.fetchFee();
        },
    },
};
</script>
<style lang="css" scoped>
.payout-modal-transfer ::v-deep .at-modal__header p, .at-modal__header .at-modal__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
}

.payout-modal-transfer ::v-deep .at-modal__header {
    min-height: 107px;
    display: flex;
    align-items: center;
    padding-left: 60px;
    border-bottom: 1px solid var(--border-color);
    border-radius: 20px 20px 0 0;
}


.payout-modal-transfer ::v-deep .at-select__dropdown {
    border: 1px solid var(--border-color);
    background: var(--bg-select);
}

.payout-modal-transfer ::v-deep .at-modal {
    border: 1px solid var(--border-color);
    box-sizing: border-box;
    border-radius: 20px;
}

.payout-modal-transfer ::v-deep .at-modal__close {
    font-size: 24px;
    color: #8F949A;
    top: 37px;
    right: 37px;
}

.payout-modal-transfer ::v-deep .at-modal__body {
    padding-left: 60px;
    padding-right: 60px;
}

::v-deep .at-modal {
    max-width: 764px;
}

.payout-modal-transfer__select-currency ::v-deep .at-select__selection {
    padding-left: 30px;
    border: 1px solid var(--border-color);
    background: transparent;
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    min-height: 46px;
    display: flex;
    align-items: center;
}

.payout-modal-transfer__select-currency_selected ::v-deep .at-select__selection {
    padding-left: 68px;

}

.payout-modal-transfer ::v-deep .at-select__dropdown .at-select__option {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    border-radius: 12px;
}

.payout-modal-transfer ::v-deep .at-select__dropdown .at-select__option:hover {
    background: var(--hover-color-select);
}

.payout-modal-transfer ::v-deep .at-select__option--selected {
    background: var(--hover-color-select);
}

.payout-modal-transfer ::v-deep .at-select__placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
}

.payout-modal-transfer ::v-deep .at-select__selection {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
}

.payout-modal-transfer__select-currency-title {
    color: var(--primary-font-color);
}

.payout-modal-transfer ::v-deep .at-select__arrow {
    font-size: 24px;
    color: var(--primary-font-color);
    margin-top: 0;
    top: calc(50% - 12px);
}

.payout-modal-transfer ::v-deep .at-select__clear {
    font-size: 24px;
    color: var(--primary-font-color);
    margin-top: 0;
    top: calc(50% - 12px);
}

.payout-modal-transfer ::v-deep .at-input__original {
    border: 1px solid var(--border-color);
    box-sizing: border-box;
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    min-height: 46px;
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    padding-left: 30px;
}

.payout-modal-transfer__select-currency ::v-deep .at-select__selected {
    display: none;
}

.payout-modal-transfer ::v-deep .at-modal__footer {
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 151px;
    padding-right: 60px;
    padding-left: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.payout-modal-transfer ::v-deep .at-btn {
    width: 100%;
    height: 46px;
    max-width: 311px;
    border-radius: 12px;
}

.payout-modal-transfer ::v-deep .at-btn__text {

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;

}


@media screen and (max-width: 950px) {
    .payout-modal-transfer ::v-deep .at-modal__header {
        padding-left: 30px;
        min-height: 48px;
    }

    .payout-modal-transfer ::v-deep .at-input__original {
        font-size: var(--font-size-text-secondary);
        padding-left: 20px;
    }

    .payout-modal-transfer ::v-deep .at-select__selection {
        font-size: var(--font-size-text-secondary);
    }

    .payout-modal-transfer ::v-deep .at-modal__footer {
        padding-left: 10px;
        padding-right: 10px;
        flex-direction: column-reverse;
    }

    .payout-modal-transfer ::v-deep .at-modal__body {
        padding-left: 10px;
        padding-right: 10px;
    }

    .payout-modal-transfer ::v-deep .at-modal__close {
        font-size: 24px;
        color: var(--primary-font-color);
        top: 12px;
        right: 27px;
    }

    .payout-modal-transfer ::v-deep .at-modal__header p, .at-modal__header .at-modal__title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
    }

    .payout-modal-transfer__select-currency ::v-deep .at-select__selection {
        padding-left: 20px;
    }

    .payout-modal-transfer__select-currency_selected ::v-deep .at-select__selection {
        padding-left: 52px;
    }
}
</style>
<style lang="scss" scoped>

.complete {
  &__title {
    font-family: var(--font-family);
    font-size: var(--font-size-h2);
    color: var(--primary-font-color);
    margin-bottom: 20px;
  }

  &__row {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    border: 1px solid var(--border-color);
    border-radius: 12px;
    padding: 20px;
    align-items: center;

  }

  &__text-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__main-text {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    color: var(--primary-font-color);
  }
}

.currency-img_preview {
  width: 24px;
  height: 24px;
}


.payout-modal-transfer {
  ::v-deep input {
    &::-webkit-inner-spin-button {
      display: none;
      margin: 0;
    }
  }

  &__result {
    display: grid;
    place-items: center;
    gap: 30px;

    &-message {
      font-family: var(--font-family);
      line-height: var(--line-height);
      font-size: var(--font-size-h2);
      color: var(--primary-font-color);
      font-weight: 400;
    }
  }

  &__currency {
    &-img {
      width: 34px;
      height: 34px;
      z-index: 2;
    }

    &-input-email {
      ::v-deep .at-input__original {
        padding-left: 32px;
        border-radius: 12px;
        font-family: var(--font-family);
        font-size: var(--font-size-h3);
        line-height: var(--line-height);
        color: var(--primary-font-color);
        height: 45px;
        border: 1px solid var(--border-color);
        box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
      }
    }

    &-input-comment {
      ::v-deep .at-textarea__original {
        padding-left: 32px;
        border-radius: 12px;
        padding-top: 10px;
        font-family: var(--font-family);
        font-size: var(--font-size-h3);
        line-height: var(--line-height);
        color: var(--primary-font-color);
        border: 1px solid var(--border-color);
        box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
        min-height: 115px !important;
      }
    }

    &-amount {
      &-container {
        width: 100%;
        max-width: 672px;
        display: flex;
        flex-direction: column;

        ::v-deep .at-input__original {
          padding-left: 32px;
          border-radius: 12px;
          font-family: var(--font-family);
          font-size: var(--font-size-h3);
          line-height: var(--line-height);
          color: var(--primary-font-color);
          height: 64px;
        }

        ::v-deep .input {
          background: black;

          &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }

      &-usd {
        padding-left: 32px;
        font-family: var(--font-family);
        font-size: var(--font-size-text-main);
        line-height: var(--line-height);
        color: var(--primary-font-color);
      }
    }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:first-of-type .payout-modal-transfer__check-input {
        border-top: var(--border-table);
      }
    }

    &-input-selected {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      height: 100%;
      width: 100%;
      background: white;
      border-radius: 12px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      padding-left: 32px;

      ::v-deep .at-select__selected {
        color: transparent;
        display: none;
      }

      &-image {
        height: 34px;
        width: 34px;
      }

      &-currency {
        font-family: var(--font-family);
        font-size: var(--font-size-h3);
        font-weight: 500;
        line-height: var(--line-height);
        color: var(--primary-font-color);

        &_network {
          font-weight: 400;
          font-size: 20px;
          line-height: 23px;
          text-align: center;
          max-width: 190px;
          color: var(--primary-font-color);
        }
      }

      &-container {
        display: flex;
        align-items: center;
        gap: 15px;
      }
    }

    &-input-container {
      width: 100%;
      max-width: 672px;
      position: relative;
      background: var(--white-100);
      border-radius: 12px;
      margin-bottom: 20px;

      &-wrap {
        width: 100%;
        max-width: 672px;
        display: flex;
        flex-direction: column;
        margin-top: 45px;
      }
    }

    &-item {
      min-height: 60px;
      display: grid;
      align-content: center;
      position: relative;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 5px;
    }

    &-info {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    &-title {
      font-family: var(--font-family);
      font-size: var(--font-size-h3);
      line-height: var(--line-height);
      color: var(--primary-font-color);
    }

    &-select-all {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    &-text-container {
      display: flex;
      flex-direction: column;
    }

    &-text {
      z-index: 2;
      font-family: var(--font-family);
      font-size: var(--font-size-h3);
      line-height: var(--line-height);
      color: var(--primary-font-color);
    }

    &-text-title {
      font-family: var(--font-family);
      font-size: var(--font-size-h3);
      line-height: var(--line-height);
      font-weight: 500;
      z-index: 2;
      justify-self: flex-end;
      color: var(--primary-font-color);
    }

    &-text-subtitle {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: var(--primary-font-color);
    }

  }


  &__line {
    width: 100%;
    height: 1px;
    background: var(--border-color);
    margin-top: 50px;
    margin-bottom: 25px;
  }

  &__status {
    position: absolute;
    top: 12px;
    left: 30px;
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__select-currency {
    position: relative;
    width: 100%;

    &-select {
      position: relative;
      z-index: 2;
    }

    &-image-container {
      display: flex;
      align-items: center;
      gap: 10px;
      color: var(--primary-font-color);
    }

    &_address {
      ::v-deep .at-select__selected {
        display: none;
      }
    }


    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      &-selected {
        position: absolute;
        z-index: 2;
        width: 100%;
        left: 0;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        height: 100%;
        padding-right: 45px;
        padding-left: 20px;
      }
    }
  }


  &__arrow {
    margin-top: 30px;
    margin-bottom: 30px;
  }


  &__info-container {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    gap: 25px;
    padding: 30px;
    border: 1px solid var(--border-color);
    border-radius: 12px;
  }

  .danger {
    color: #fa5d4f;;
  }

  &__row {
    margin-bottom: 16px;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--primary-font-color);
    margin-bottom: 10px;
    width: 100%;

    &-special {
      color: grey;

      font-size: 12px;
    }
  }

  &__title1 {
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    font-family: 'Roboto';
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    color: var(--primary-font-color);
  }

  &__currency {
    display: flex;
    align-items: center;
  }

  &__currency-img {
    margin-left: 8px;
    margin-right: 4px;
    width: 24px;
    height: 24px;
  }

  &__withdrawal-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__full-width-btn {
    width: 100%;
  }

  &__table-header {
    margin-left: 0;
    margin-right: 0;
    padding: 4px 12px;
    background-color: $gray;
    border: 1px solid var(--border-color);
  }

  &__add-address {
    margin-top: 12px;
  }

  .address-option {
    display: flex;

    justify-content: flex-start;
    align-items: center;

    .currency-img {
      margin-left: 8px;
      margin-right: 4px;
      width: 20px;
      height: 20px;
    }

    .info-block {
      padding-left: 12px;

      .address {
      }

      .info {
        span {
          color: grey;
        }
      }
    }
  }

  .amount-input {
    display: flex;
    align-items: center;
    align-content: center;
    border: 1px solid var(--border-color);;
    box-sizing: border-box;
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    width: 100%;
    color: #3F536E;
    font-size: 12px;
    background-color: var(--secondary-color);
    outline: none;
    min-height: 46px;
    padding-left: 18px;

    &:hover, &:focus, &:active {
      border-color: #79A1EB;
    }

    &.error {
      border-color: #FF4949;
    }

    .input {
      padding: 6px 12px;
      background-color: transparent;
      outline: none;
      border: 0;
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: var(--primary-font-color);
      width: calc(100% - 70px);
    }

    .max-button {
      display: block;

      text-align: center;

      width: 70px;

      border-left: 1px solid #79A1EB;
    }
  }
}

@media screen and (max-width: 950px) {
  .currency-img_preview {
    left: 18px;
  }

  .payout-modal-transfer {
    .amount-input {
      padding-left: 8px;
    }

    &__info-container {
      padding: 14px;
      margin-top: 30px;
    }

    &__title1 {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }
  }

}

</style>
