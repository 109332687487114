import { getCrosschainTransferToken } from "./actions/crossChainTransferToken";
import { setCrosschainTransfer } from "./actions/crossChainTransfer";
import { getCrosschainTransfersAmountLimits } from "./actions/crossChainTransfersAmountLimits";
import { getCrosschainSwapsAmountLimits } from "./actions/crossChainSwapsAmountLimits";
import { getCrosschainTransfersList } from "./actions/crossChainTransfersList";
import { downloadCSV } from './actions/downloadCSV';
import { downloadXLSX } from './actions/downloadXLSX';

export const actions = {
    getCrosschainTransfersAmountLimits,
    getCrosschainSwapsAmountLimits,
    getCrosschainTransferToken,
    getCrosschainTransfersList,
    setCrosschainTransfer,
    downloadCSV,
    downloadXLSX
};