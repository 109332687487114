export const mutations = {
    setSettingsList(state, payload) {
        state.settingsList = payload;
    },
    setWebhooksTotal(state, payload) {
        state.webhooksTotal = payload;
    },
    setSetting(state, payload) {
        state.setting = payload;
    },
    setRequestState(state, payload) {
        state.isRequestProcessed = payload;
    },
    setSettingsHistory (state, payload) {
        state.settingsHistory = payload;
    },

    setMinimumAmount (state, payload) {
        state.minimumAmount = payload;
    },

    clearAllStates: (state) => {
        state.webhook = {}
        state.webhooksList = []
    },

};
