import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!, $id: String!) {
        getTransaction(organizationId: $organizationId, id: $id) {
            id
            type
            networkId
            addressFrom
            addressTo
            status
            txId
            createdAt
            orderId
            orderCreatedAt
            credit
            deposit
            currencyId
            comment
        }
    }
`;
