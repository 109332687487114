<script>
  export default {
    name: "SvgGreenArrowUp"
  }
</script>

<template>
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.6995 8.19438C3.57437 8.34575 3.5 8.53913 3.5 8.75C3.5 9.233 3.892 9.625 4.375 9.625H9.625C10.108 9.625 10.5 9.233 10.5 8.75C10.5 8.53913 10.4256 8.34575 10.3005 8.19438L7.707 4.73462C7.54775 4.51675 7.2905 4.375 7 4.375C6.7095 4.375 6.45225 4.51675 6.293 4.73462L3.6995 8.19438Z" fill="#44B070"/>
</svg>
</template>
