import getAllAddressBookMutation from "/public/graphQl/queries/getAllAddressBook";
import {getRouteQuery} from "../../../../utils/getRouteQuery";
import {getNetworksIdsByNames} from "../../../../utils/getNetworksIdsByNames";
import {getFormatForUrlByArray} from "../../../../utils/getFormatForUrlByArray";

export const getAddressBook = async ({state, commit, rootState, dispatch, rootGetters}, payload) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    let currenciesData;

    const {
        route,
        router,
        page,
        showUrlFilters = false,
        excludeParams = [],
        networkId,
    } = payload;
    const { pagesCount } = state;

    const limit = payload.limit ? payload.limit : pagesCount

    /** Даты если не переданы указывваю начальные значения */
    const currentPage = page ? page : getRouteQuery({
        route,
        queryName: 'page',
        defaultValue: 1,
        excludeQueries: excludeParams,
        preparedReturnValueFunc: (value) => Number(value),
    });

    const currentNetworks = networkId ? networkId : getRouteQuery({
        route,
        queryName: 'networks',
        defaultValue: [],
        preparedReturnValueFunc: (value) => value.split(','),
    });

    const allNetworks = rootState.app.networks;


    const variables = {
        organizationId,
        limit: limit,
        page: currentPage - 1,
        networkId,
    };

    if (showUrlFilters) {
        router.push({
            query: {
                page: currentPage,
                networks: networkId,
            },
        }).catch((error) => {
            if (error.name !== 'NavigationDuplicated') {
                throw error;
            }
        });
    }

    try {
        if (showUrlFilters) commit('app/toggleLoading', null, {root: true});
        currenciesData = await dispatch('app/request', {
            type: 'query',
            gql: getAllAddressBookMutation,
            variables,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        if (showUrlFilters) commit('app/toggleLoading', null, {root: true});
    }

    const {
        data: { getAddressBookRecords },
    } = currenciesData;

    const {
        data: {
            getAddressBookRecords: {
                pages,
                count,
            },
        },
    } = currenciesData;

    commit('pages', pages);
    commit('count', count);
    commit('addListCurrenciesAddress', getAddressBookRecords.addresses);

    return true;
};
