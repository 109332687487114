import axios from 'axios';
import crypto from 'crypto';

const createSign = (dataToSign, secret, digest = 'hex') => {
    return crypto
        .createHmac('SHA256', secret)
        .update(dataToSign)
        .digest(digest);
};

export const apiRequest = async ({ rootState }, payload) => {
    const {
        method,
        data,
    } = payload;
    const nonce = Date.now();
    const dataToSend = {
        ...data,
        nonce,
    };
    const stringData = JSON.stringify(dataToSend);
    const { testPublicKey, testSecretKey } = rootState.user;

    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: `/api-gateway/${method}`,
            data: {
                ...dataToSend,
            },
            headers: {
                'x-api-signature': createSign(stringData, testSecretKey),
                'x-api-public-key': testPublicKey,
            },
        })
            .then(resp => {
                resolve(resp);
            })
            .catch(error => {
                reject(error);
            });
    });
};