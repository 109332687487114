<script>
    import Copy from "@/public/components/common/copy.vue";
    import Preloader from "/public/components/common/uiKit/spinner/index.vue";
    import {mapGetters, mapMutations, mapState} from "vuex";
    import CurrencyIcon from "@/public/components/common/uiKit/CurrencyIcon.vue";
    import BigNumber from "bignumber.js";
    import {
        getCurrencyInfoById,
        getCurrencyTicker,
        getNetworkShortNameByNetworkName,
        getNetworkSystemName
    } from "@/public/filters";
    import {BlockchainLinks} from "../../../mixins/blockchain-links";
    import NetworkIcon from "@/public/components/common/uiKit/NetworkIcon.vue";
    import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
    export default {
        name: "OrderInfo",
        props: {
            statusEnum: {
                type: Object,
                required: true,
            },
            isInfoOpened: false,
            openedInfoItem: ''
        },
        components: {BaseButton, NetworkIcon, CurrencyIcon, Copy, Preloader},
        mixins: [
            BlockchainLinks,
        ],
        computed: {
            ...mapState('orders', ['isLoading', 'order']),
            ...mapGetters('app', ['getCurrencyNameById']),
            rate () {
                if (!this.order.rate) {
                    return ''
                }
                return BigNumber(this.order.rate).toFixed(3)
            },
            currency () {
                if (!this.order.currency) {
                    return '-'
                }
                return this.getCurrencyNameById(this.order.currency)
            },
            receivedCurrency () {
                if (!this.order.receivedCurrency) {
                    return '-'
                }
                return this.getCurrencyNameById(this.order.receivedCurrency)
            },
            amount () {
                if (!this.order.amount) {
                    return '-'
                }
                let number = new BigNumber(this.order.amount).decimalPlaces(8);
                return number.toFixed();
            },
            id () {
                if (!this.order.id) {
                    return '-'
                }
                return this.order.id
            },
            orderAmount () {
                if (!this.order.orderAmount) {
                    return '-'
                }
                let number = new BigNumber(this.order.orderAmount).decimalPlaces(8);
                return number.toFixed();
            },
            receivedAmount () {
                if (!this.order.receivedAmount) {
                    return '-'
                }
                let number = new BigNumber(this.order.receivedAmount).decimalPlaces(8);
                return number.toFixed();
            },
            receivedNetwork () {
                if (!this.order.networkId) {
                    return ''
                }
                return getNetworkSystemName(this.order.networkId)
            },
            orderId () {
                if (!this.order.orderId) {
                    return '-'
                }
                return this.order.orderId
            },
            status() {
                const {
                    statusEnum,
                    order,
                } = this;

                return statusEnum[order.status];
            },
            currencyAlias () {
                return getCurrencyInfoById(this.order.currency).alias
            },
            contractLink() {
                return this.$contractLink(this.order.currency, this.order.networkId);
            },
            received() {
                return Number(this.order.received)
            },
        },
        methods: {
            getCurrencyInfoById,
            getNetworkShortNameByNetworkName,
            getCurrencyTicker,
            ...mapMutations('orders', ['setOrderInfoState', 'setOrder']),
            toggleTransactionsInfo () {
                this.isInfoOpened = !this.isInfoOpened
            },
            openTransactionInfoItem (id) {
              this.openedInfoItem = this.openedInfoItem === id ? '' : id
            },
            closeInfo () {
                this.setOrderInfoState(false)
                this.setOrder(null)
            },
            goToOrder () {
                if (!this.order.orderLink) return

                window.open(this.order.orderLink, '_blank')
            },
            txLink(txId, networkId) {
                return this.$txLink(txId, networkId);
            },
        }
    }
</script>

<template>
<div class="info">
    <div class="info__bg" @click="closeInfo"></div>
    <div  class="info__content">
        <div class="info__close" @click="closeInfo">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.712087 0.712087C1.0782 0.345971 1.6718 0.345971 2.03791 0.712087L7 5.67418L11.9621 0.712087C12.3282 0.345971 12.9218 0.345971 13.2879 0.712087C13.654 1.0782 13.654 1.6718 13.2879 2.03791L8.32582 7L13.2879 11.9621C13.654 12.3282 13.654 12.9218 13.2879 13.2879C12.9218 13.654 12.3282 13.654 11.9621 13.2879L7 8.32582L2.03791 13.2879C1.6718 13.654 1.0782 13.654 0.712087 13.2879C0.345971 12.9218 0.345971 12.3282 0.712087 11.9621L5.67418 7L0.712087 2.03791C0.345971 1.6718 0.345971 1.0782 0.712087 0.712087Z" fill="var(--new-front-primary-font-color)"/>
            </svg>
        </div>
        <transition name="slide-fade" mode="out-in">
            <div v-if="!isLoading">
                <div class="info__header">
                    <div class="info__header-topRow">
                        <div class="info__header-title">{{ $t('orders.info_title') }}</div>
                        <div :class="['item__status', `item__status--${order.status}`]">
                            {{ $t(`orders.${order.status}`) }}
                        </div>
                    </div>
                    <div class="info__header-subtitle">№ {{ order.order }}</div>
                </div>
                <div v-if="order.transactions.length" :class="['info__collected', isInfoOpened ? 'info__collected--opened' : '']">
                    <div class="info__collected-header" @click="toggleTransactionsInfo">
                        <p class="info__collected-title">
                            <span>{{ $t('orders.received-order-amount') }}:</span>
                             {{ received }} {{ order.currency | getCurrencyTicker }}
                        </p>
                        <div class="info__collected-count">
                            {{ order.transactions.length }}
                        </div>
                        <svg :class="['info__collected-chevron', isInfoOpened ? 'info__collected-chevron--opened' : '']" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.772 1.635C7.915 1.462 8 1.241 8 1C8 0.448 7.552 3.91654e-08 7 8.74228e-08L1 6.11959e-07C0.448 6.60217e-07 3.91654e-08 0.448001 8.74228e-08 1C1.08492e-07 1.241 0.0850002 1.462 0.228 1.635L3.192 5.589C3.374 5.838 3.668 6 4 6C4.332 6 4.626 5.838 4.808 5.589L7.772 1.635Z" fill="var(--new-front-primary-font-color)"/>
                        </svg>
                    </div>
                    <div class="info__collected-content">
                        <div v-for="(item, idx) in order.transactions" :class="['info__collected-item', idx === order.transactions.length - 1 ? 'border-hidden' : '']">
                            <div>{{ $t(`orders.type-${item.type}`) }}</div>
                            <div :class="[`info__collected-item--${item.status}`]">{{$t(`request.${item.status}`)}}</div>
                            <div>{{ item.amount }}</div>
                            <div class="info__item-value--link"><a :href="txLink(item.transactionHash, item.networkId)" target="_blank">{{ item.transactionHash | preparedString }}</a> <copy :value="txLink(item.transactionHash, item.networkId)" /></div>
                            <div>{{ item.createdAt | getFormatDate('DD/MM/YYYY, HH:mm:ss') }}</div>
                            <div>{{ item.currencyId | getCurrencyTicker }} <span class="info__item-value--secondary">{{ item.networkId | getNetworkSystemName | getNetworkShortNameByNetworkName }}</span></div>
                        </div>
                        <div v-for="(item, idx) in order.transactions" :class="['info__collected-mobileWrapper', item.id === openedInfoItem ? 'info__collected-mobileWrapper--opened' : '', idx === order.transactions.length - 1 ? 'border-hidden' : '']" @click="openTransactionInfoItem(item.id)">
                            <div :class="['info__collected-item', 'info__collected-item--mobile', item.id === openedInfoItem ? 'info__collected-item--mobile--opened' : '']" >
                                <div :class="['info__collected-status', `info__collected-status--${order.status}`]"></div>
                                <div>{{ $t(`orders.type-${item.type}`) }}</div>
                                <div>{{ item.amount }}</div>
                                <div>{{ item.createdAt | getFormatDate('DD/MM/YYYY, HH:mm:ss') }}</div>
                                <div>{{ item.currencyId | getCurrencyTicker }} <span class="info__item-value--secondary">{{ item.networkId | getNetworkSystemName | getNetworkShortNameByNetworkName }}</span></div>
                                <svg :class="['info__collected-item-chevron', item.id === openedInfoItem ? 'info__collected-chevron--opened' : '']" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.772 1.635C7.915 1.462 8 1.241 8 1C8 0.448 7.552 3.91654e-08 7 8.74228e-08L1 6.11959e-07C0.448 6.60217e-07 3.91654e-08 0.448001 8.74228e-08 1C1.08492e-07 1.241 0.0850002 1.462 0.228 1.635L3.192 5.589C3.374 5.838 3.668 6 4 6C4.332 6 4.626 5.838 4.808 5.589L7.772 1.635Z" fill="var(--new-front-primary-font-color)"/>
                                </svg>
                            </div>
                            <div class="info__collected-mobileContent">
                                <div class="info__item">
                                    <div class="info__item-title">
                                        {{ $t('orders.status') }}:
                                    </div>
                                    <div class="info__item-value">
                                        <div :class="['item__status', `item__status--${item.status}`]">
                                            {{$t(`request.${item.status}`)}}
                                        </div>
                                    </div>
                                </div>
                                <div class="info__item">
                                    <div class="info__item-title">
                                        TXhash:
                                    </div>
                                    <div class="info__item-value info__item-value--link">
                                        <a :href="txLink(item.transactionHash, item.networkId)" target="_blank">{{ item.transactionHash | preparedString }}</a>
                                        <copy :value="txLink(item.transactionHash, item.networkId)"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="info__item">
                    <div class="info__item-title">
                        {{ $t('orders.link') }}:
                    </div>
                    <div class="info__item-value info__item-value--link">
                        <a :href="order.link" target="_blank">{{ order.link | getPrettyUrl }}</a>
                        <copy :value="order.link"/>
                    </div>
                </div>
                <div class="info__item">
                    <div class="info__item-title">
                        ID:
                    </div>
                    <div class="info__item-value">
                        {{ id | preparedString }}
                        <copy :value="order.id"/>
                    </div>
                </div>
                <div class="info__item">
                    <div class="info__item-title">
                        {{ $t('orders.date_of_creation') }}:
                    </div>
                    <div class="info__item-value">
                        {{ order.createdAt | getFormatDate('DD/MM/YYYY, HH:mm:ss') }}
                    </div>
                </div>
                <div class="info__item">
                    <div class="info__item-title">
                        {{ $t('orders.currencies') }}:
                    </div>
                    <div v-if="currency" class="info__item-value">
                        <CurrencyIcon :currency="currency"/>
                        {{ currencyAlias }} <span class="info__item-value--secondary">({{ currency }})</span>
                    </div>
                    <div v-else class="info__item-value">
                        -
                    </div>
                </div>
                <div class="info__item">
                    <div class="info__item-title">
                        {{ $t('orders.network') }}:
                    </div>
                    <div class="info__item-value" v-if="receivedNetwork">
                        <NetworkIcon :network="receivedNetwork"/>
                        {{ receivedNetwork | getNetworkFullNameByNetworkName }} <span  class="info__item-value--secondary">{{ receivedNetwork | getNetworkShortNameByNetworkName }}</span>
                    </div>
                    <div v-else class="info__item-value">
                        -
                    </div>
                </div>
                <div class="info__item">
                    <div class="info__item-title">
                        {{ $t('orders.amount') }}:
                    </div>
                    <div class="info__item-value">
                        {{ amount }}
                    </div>
                </div>
                <div class="info__item">
                    <div class="info__item-title">
                        {{ $t('orders.recipient-address') }}:
                    </div>
                    <div v-if="order.address" class="info__item-value">
                        {{ order.address | preparedString }}
                        <copy :value="order.address"/>
                    </div>
                    <div v-else class="info__item-value">
                        -
                    </div>
                </div>
                <div class="info__item">
                    <div class="info__item-title">
                        {{ $t('orders.contract') }}:
                    </div>
                    <div v-if="order.contract" class="info__item-value info__item-value--link">
                        <a :href="contractLink" target="_blank">{{ order.contract | preparedString }}</a>
                        <copy :value="contractLink" />
                    </div>
                    <div v-else class="info__item-value">
                        -
                    </div>
                </div>
                <div class="info__item">
                    <div class="info__item-title">
                        {{ $t('orders.validity') }}:
                    </div>
                    <div v-if="order.expiresAt" class="info__item-value">
                        {{ order.expiresAt | getFormatDate('DD/MM/YYYY, HH:mm:ss') }}
                    </div>
                    <div v-else class="info__item-value">
                        -
                    </div>
                </div>
                <div class="info__item info__item-description">
                    <div class="info__item-title">
                        {{ $t('orders.description') }}:
                    </div>
                    <div class="info__item-value info__item-value-description">
                        {{ order.description }}
                    </div>
                </div>
                <div class="info__item">
                    <div class="info__item-title">
                        {{ $t('orders.advance-balance') }}:
                    </div>
                    <div v-if="order.accountId" class="info__item-value">
                        {{ order.accountId | preparedString }}
                        <copy :value="order.accountId"/>
                    </div>
                    <div v-else class="info__item-value">
                        -
                    </div>
                </div>
                <div class="info__item">
                    <div class="info__item-title">
                        {{ $t('orders.address-tag') }}:
                    </div>
                    <div v-if="order.tag" class="info__item-value">
                        {{ order.tag }}
                    </div>
                    <div v-else class="info__item-value">
                        -
                    </div>
                </div>
                <div class="info__item">
                    <div class="info__item-title">
                        {{ $t('orders.return-url') }}:
                    </div>
                    <div v-if="order.returnUrl" class="info__item-value">
                        {{ order.returnUrl }}
                    </div>
                    <div v-else class="info__item-value">
                        -
                    </div>
                </div>
                <div class="info__item info__item--error">
                    <div class="info__item-title info__item-title--error">
                        {{ $t('orders.error-webhook') }}:
                    </div>
                    <div v-if="order.errorWebhook" class="info__item-value info__item-value--link">
                        <copy :value="order.errorWebhook" />
                        <a class="webhook-link" :href="order.errorWebhook" target="_blank">{{ order.errorWebhook }}</a>
                    </div>
                    <div v-else class="info__item-value">
                        -
                    </div>
                </div>
                <div class="info__item info__item--success">
                    <div class="info__item-title info__item-title--success">
                        {{ $t('orders.success-webhook') }}:
                    </div>
                    <div v-if="order.successWebhook" class="info__item-value">
                        <copy :value="order.successWebhook" />
                        <a class="webhook-link" :href="order.successWebhook" target="_blank">{{ order.successWebhook }}</a>
                    </div>
                    <div v-else class="info__item-value">
                        -
                    </div>
                </div>
            </div>
            <preloader v-else />
        </transition>
    </div>
</div>
</template>
<style scoped lang="scss">
.item__status {
    width: 120px;
    height: 32px;
    border-radius: 10px;
    text-align: center;
    padding: 5px 15px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    &--init {
        color: var(--new-front-status-text-active);
        background: var(--new-front-status-active);
    }
    &--expired {
        color: var(--new-front-status-text-expired);
        background: var(--new-front-status-expired);
    }
    &--processed {
        color: var(--new-front-status-text-processed);
        background: var(--new-front-status-processed);
    }
    &--overpaid {
        color: var(--new-front-status-text-processed);
        background: var(--new-front-status-processed);
    }
    &--pending {
        color: var(--new-front-status-text-pending);
        background: var(--new-front-status-pending);
    }
    &--partial {
        color: var(--new-front-status-text-pending);
        background: var(--new-front-status-pending);
    }
    &--error {
        color: var(--new-front-status-text-error);
        background: var(--new-front-status-error);
    }
}
.info__collected {
    max-height: 63px;
    overflow: hidden;
    background: var(--new-front-info-accordion-bg);
    border-radius: 10px;
    padding: 0 20px;
    transition: max-height .2s ease;
    @media (max-width: 950px) {
        padding: 0;
    }
    &--opened {
        max-height: fit-content;
    }
    &-mobileWrapper {
        cursor: pointer;
        display: none;
        @media (max-width: 950px) {
            display: block;
        }
        max-height: 94px;
        overflow: hidden;
        transition: max-height .2s ease;
        position: relative;
        &::before {
            content: '';
            width: calc(100% - 40px);
            display: block;
            border-bottom: 1px solid var(--new-front-divider-color);
            position: absolute;
            left: 20px;
            bottom: 0;
        }
        &--opened {
            max-height: fit-content;
            &::before {
                display: none !important;
                border: 0;
            }
        }
        &:not([style*="display: none"]):last-of-type {
            border: none;
        }
    }
    &-mobileContent {
        border: 2px solid var(--new-front-info-accordion-bg);
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        background: var(--new-front-main-bg);
        padding: 0 20px;
    }
    &-status {
        width: 3px;
        height: 55px;
        border-radius: 13px;
        position: absolute;
        left: 7px;
        padding: 0;
        &--init {
            background: var(--new-front-status-text-active);
        }
        &--expired {
            background: var(--new-front-status-text-expired);
        }
        &--processed {
            background: var(--new-front-status-text-processed);
        }
        &--pending {
            background: var(--new-front-status-text-active);
        }
        &--partial {
            background: var(--new-front-status-text-pending);
        }
        &--error {
            background: var(--new-front-status-text-error);
        }
    }
    &-chevron {
        transition: transform .2s ease;
        &--opened {
            transform: rotate(180deg);
        }
    }
    &-item {
        display: grid;
        grid-template-columns: 0.7fr 1fr 0.7fr;
        grid-gap: 15px;
        align-items: center;
        color: var(--new-front-primary-font-color);
        font-size: 15px;
        font-weight: 500;
        border-bottom: 1px solid var(--new-front-divider-color);
        padding: 20px 0;
        position: relative;
        div {
            display: flex;
            align-items: center;
            gap: 5px;
        }
         &:nth-child(3n+3) {
             justify-content: flex-end;
         }
        &:last-of-type {
            border-bottom: none;
        }
        &--init {
            color: var(--new-front-status-text-active);
        }
        &--expired {
            color: var(--new-front-status-text-expired);
        }
        &--processed{
            color: var(--new-front-status-text-processed);
        }
        &--pending {
            color: var(--new-front-status-text-active);
        }
        &--partial {
            color: var(--new-front-status-text-pending);
        }
        &--error {
            color: var(--new-front-status-text-error);
        }
        &--refund {
            color: var(--new-front-status-text-error);
        }
        &-chevron {
            transition: transform .2s ease;
            position: absolute;
            right: 20px;
        }
        &--mobile {
            display: none;
        }
        @media (max-width: 950px) {
            display: none;
            &--mobile {
                display: grid;
                grid-template-columns: 1fr 1fr;
                position: relative;
                font-size: $h6;
                padding: 20px 40px 20px 20px;
                &--opened {
                    border: unset;
                }
                div {
                    &:nth-child(3) {
                        justify-content: flex-end;
                        text-align: right;
                    }
                    &:nth-child(5) {
                        justify-content: flex-end;
                        text-align: right;
                    }
                }
            }
        }
    }
    &-content {
        //padding: 20px 0;
        &-chevron {
            position: absolute;
        }
    }
    &-header {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 32px 10px;
        align-items: center;
        gap: 10px;
        height: 64px;
        //border-bottom: 1px solid var(--new-front-divider-color);
        position: relative;
        &::before {
            content: '';
            width: calc(100% - 40px);
            display: block;
            border-bottom: 1px solid var(--new-front-divider-color);
            position: absolute;
            left: 20px;
            bottom: 0;
        }
        @media (max-width: 950px) {
            padding: 0 20px;
            border-bottom: unset;
        }
    }
    &-title {
        font-size: $h4;
        font-weight: 500;
        color: var(--new-front-primary-font-color);

        > span {
            color:var(--new-front-secondary-font-color);
        }
        @media (max-width: 950px) {
            font-size: $h6;
        }
    }
    &-count {
        font-size: $h5;
        font-weight: 500;
        border: 1px var(--new-front-adv-border-color) solid;
        border-radius: 10px;
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: var(--new-front-adv-font-color);
        background: var(--new-front-adv-hover-bg);
    }
}
</style>
<style scoped lang="scss">
.info {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  // Огромный z-index для перекрытия виджета интеркома
  z-index: 2147483001;
    &__status {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
  &__rate-icon {
    margin: 0 10px;
  }
  &__btn {
    margin-top: 50px;
  }

  &__close {
    position: absolute;
    top: 30px;
    cursor: pointer;
  }
  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .50);
    height: 100vh;
    width: 100vw;
    z-index: 1;
    cursor: pointer;
  }
  &__content {
    width: 530px;
    height: 100vh;
    box-sizing: border-box;
    padding: 100px 50px 30px 50px;
    background: var(--new-front-main-bg);
    position: absolute;
    right: 0;
    z-index: 2;
    overflow: auto;
  }
  &__item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 58px;
    border-bottom: 1px solid var(--new-front-divider-color);
      &--error {
          background: var(--new-front-status-error);
          border-radius: 10px;
          margin-top: 20px;
          border-bottom: 0;
          padding: 0 10px;
      }
      &--success {
          background: var(--new-front-status-processed);
          border-radius: 10px;
          margin-top: 20px;
          border-bottom: 0;
          padding: 0 10px;
      }
      &-description {
          flex-direction: column;
          align-items: flex-start;
          border: 0;
          padding-top: 10px;
          height: fit-content;
      }
    &:last-of-type {
      border-bottom: 0;
    }
    &-title {
      font-size: $h4;
      font-weight: 500;
      color: var(--new-front-secondary-font-color);
        &--error {
            color: var(--new-front-status-text-error)
        }
        &--success {
            color: var(--new-front-status-text-processed)
        }

    }
    &-value {
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 500;
      font-size: $h4;
      color: var(--new-front-primary-font-color);
      &--secondary {
        color: var(--new-front-secondary-font-color);
      }
        &-description {
            width: 100%;
            border-bottom: 1px solid var(--new-front-divider-color);
            padding-bottom: 10px;
        }
        &--link {
            color: var(--new-front-purple);
            text-decoration: underline;
            a {
                color: var(--new-front-purple);
            }
            &::v-deep {
                .copy-wrapper {
                    margin-right: 5px !important;
                }
            }
        }
    }
  }
  &__header {
    margin-bottom: 20px;
    &-topRow {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-title {
      font-size: $h1;
      color: var(--new-front-primary-font-color);
      //text-align: center;
        width: 100%;
    }
    &-subtitle {
      margin-top: 5px;
      font-size: $h4;
      color: var(--new-front-secondary-font-color)
    }
  }
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
::v-deep .copy-wrapper {
  margin: 0;
}
@media screen and (max-width: 576px) {
  .info__header-title {
    font-size: $h2;
  }
  .info__item-title {
    font-size: $h5;
      text-align: center;
  }
  .info__item-value {
    font-size: $h5;
  }
  .info__header-subtitle {
    font-size: $h5;
  }
  .info__content {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 70px 20px 30px;
    overflow: auto;
  }
    .info__close {
        right: 20px !important;
    }
    .item__status {
        font-size: $h6;
        width: 100px;
    }
}
.border-hidden {
    border: none !important;
    &:before {
        border: none;
    }
}
.webhook-link {
    color: var(--new-front-primary-font-color) !important;
    text-decoration: underline;
    a {
        color: var(--new-front-primary-font-color) !important;
        text-decoration: underline;
    }
}
</style>
