import executeRegistry from "../../../../graphQl/mutations/executeRegistry";
import {v4 as UUID} from "uuid";

export const executeRegistryMutation = async ({dispatch, commit, rootState}, payload) => {
    let uploadRegistryData;
    const accountId = rootState.accounts.accounts[0].accountId;
    const feeData = await dispatch('fetchFeeForRegistry', {accountId, registryId: payload.registryId});
    const id = UUID();
    const {
        data: {requestFeeForRegistry: {code, result}},
    } = feeData;

    const res = result.map((i) => {
        i = {fee: {token: i.fee.token}, itemId: i.itemId};
        return i;
    });

    try {
        uploadRegistryData = await dispatch('app/request', {
            type: 'mutation',
            gql: executeRegistry,
            variables: {accountId, registryId: payload.registryId, feeTokens: res, key: id},
        }, {root: true});
    } catch (error) {
        return error;
    }
    // const {saveRegistry: uploadRegistryResponse} = uploadRegistryData.data;
    //
    // if (uploadRegistryData.message) {
    //     throw uploadRegistryData.message;
    // }
    //
    // if (uploadRegistryData.errors) {
    //     throw uploadRegistryData.errors;
    // }
    //

    return uploadRegistryData;
};
