export const EVENTS_TYPES = {
    ACCOUNTS: {
        CREATE: '1000.0010',
        CHANGE_BALANCE: '1000.0020',
        DEPOSIT: '1000.0030',
        BLOCK: '1000.0050',
        UNBLOCK: '1000.0060',
    },
    ADDRESSES: {
        CREATE: '2000.0010',
        CHANGE_BALANCE: '2000.0010',
    },
    ORDERS: {
        CREATE: '3000.0010',
        UPDATE: '3000.0020',
    },
    WITHDRAWALS: {
        UPDATE: '4000.0010',
    },
    PRE_CHECKOUT: {
        CREATE: '5000.0010',
        CHANGE_URL: '5000.0020',
        CHANGE_ALIAS: '5000.0030',
        CHANGE_INFO: '5000.0040',
        CHANGE_COINS: '5000.0050',
        CREATE_ORDER: '5000.0060',
        UPDATE_ORDER: '5000.0070',
        DELETE: '5000.0080',
    },
};
