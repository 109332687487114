<template>
  <img
     class="currency-img"
     :class="[ size ]"
     :src="currentCurrencyImg"
     :alt="currency"
  >
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'CurrencyImg',
  props: {
    currencyId: {
      type: String,
      required: undefined,
    },
    currency: {
      type: String,
      required: undefined,
    },
    size: {
      type: String,
      default: 'normal'
    }
  },
  computed: {
    ...mapGetters('app', [
      'getCurrencyNameById',
    ]),
    currencyNameById({ currencyId }) {
      let currencyName = this.getCurrencyNameById(currencyId)

      if (currencyName === '(удалена)') {
        return 'USDT'
      } else {
        return currencyName
      }
    },
    currentCurrencyImg({ currency, currencyNameById }) {
      const ticker = currency || currencyNameById
      return `/assets/image/currenciesAndNetworks/currencies/${ticker.toUpperCase()}.svg`;
    },
  },
};
</script>

<style lang="scss">
  .currency-img {
    &.small { //размер поставлен пока просто так
      width: 16px;
      height: 16px;
    }
    &.normal { //размер используется
      width: 20px;
      height: 20px;
    }
    &.big {
      width: 24px;
      height: 24px;
    }
  }
</style>
