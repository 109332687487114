<template>
<div class="navItem__wrapper">
    <div class="pin-icon-wrapper" :class="['pin-icon', isPinned ? 'pin-icon--active' : '']" v-show="isHover" @click="emitPin" @mouseenter="isHover = true">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5469 5.84375C15.375 6.01562 15.2083 6.17188 15.0469 6.3125C14.8854 6.45312 14.7135 6.57552 14.5312 6.67969C14.349 6.78385 14.1589 6.85938 13.9609 6.90625C13.763 6.95312 13.5312 6.98177 13.2656 6.99219C13.0885 6.99219 12.9193 6.97656 12.7578 6.94531L9.79688 9.90625C9.86458 10.1042 9.91406 10.3073 9.94531 10.5156C9.97656 10.724 9.99219 10.9323 9.99219 11.1406C9.99219 11.4792 9.95833 11.7865 9.89062 12.0625C9.82292 12.3385 9.72656 12.599 9.60156 12.8438C9.47656 13.0885 9.32031 13.3203 9.13281 13.5391C8.94531 13.7578 8.73958 13.9844 8.51562 14.2188L5.5 11.2031L1.0625 15.6484L0 16L0.351562 14.9375L4.79688 10.5L1.78125 7.48438C2.01042 7.25521 2.23438 7.04948 2.45312 6.86719C2.67188 6.6849 2.90365 6.53125 3.14844 6.40625C3.39323 6.28125 3.65365 6.18229 3.92969 6.10938C4.20573 6.03646 4.51562 6.0026 4.85938 6.00781C5.06771 6.00781 5.27604 6.02344 5.48438 6.05469C5.69271 6.08594 5.89583 6.13542 6.09375 6.20312L9.05469 3.24219C9.02344 3.08073 9.00781 2.91146 9.00781 2.73438C9.00781 2.47917 9.03385 2.25 9.08594 2.04688C9.13802 1.84375 9.21615 1.65104 9.32031 1.46875C9.42448 1.28646 9.54427 1.11719 9.67969 0.960938C9.8151 0.804688 9.97396 0.635417 10.1562 0.453125L15.5469 5.84375Z" fill="var(--new-front-secondary-font-color)"/>
        </svg>
    </div>
  <RouterLink :to="item.link">
    <div :class="['navItem', item.active ? 'navItem--active' : '']" @mouseenter="isHover = true" @mouseleave="isHover = false">
      <div class="info-wrapper">
        <div class="navItem__icon"><Icon :icon="item.icon"/> </div>
        <div class="navItem__title">{{ $t(item.title) }}</div>
      </div>
      <div
        v-if="!!item.summary && sectionParser && !isHover"
        class="summary"
      >
        <span class="value">{{ amount | prettifyAmountWithAbbreviations }}</span>
        <span class="currency">USD</span>
      </div>
    </div>
  </RouterLink>
</div>

</template>

<script>
  import {mapState} from "vuex";
  import Icon from "../../common/uiKit/Icon";
  import { prettifyAmountWithAbbreviations } from '/public/filters'

  export default {
    name: "NavItem",
    components: { Icon },
    props: {
      item: {
        type: Object,
        required: true,
      },
        pinned: {
            type: Array,
            required: true,
        }
    },
      data() {
        return {
            isHover: false,
        }
      },
    computed: {
      ...mapState('summary',
          ['summary'],
      ),
      amount({ sectionParser, summary }) {
        return sectionParser ? (summary[sectionParser] ? Number(summary[sectionParser]).toLocaleString('ru-RU') : '0') : '0'
      },
      showSummary({ sectionParser }) {
        return !!sectionParser
      },
      sectionParser({ item }) {
        const { link } = item
        const { name } = link || {}

        let parseName = ''

        switch (name) {
          case "dashboard":
            parseName = 'TOTAL'
            break;
          case "wallets":
            parseName = 'PAY_IN'
            break;
          case "businessWallet":
            parseName = 'BUSINESS'
            break;
          case "payout-wallet-balance":
            parseName = 'PAY_OUT'
            break;
          case "recurrentsAddress":
            parseName = 'RECURRENT'
            break;
          case "personal-addresses":
            parseName = 'PERSONAL'
            break;
          default:
            parseName = ''
        }

        return parseName
      },
      prettifyAmountWithAbbreviations() {
        return prettifyAmountWithAbbreviations
      },
        isPinned () {
          return this.pinned.some(el => this.item.navAlias ===  el)
        }
    },
    methods: {
      emitPin () {
          this.$emit('pin', this.item)
      }
    },
  }
</script>

<style lang="scss" scoped>
.pin-icon {
  position: absolute;
  right: 35px;
  top: 40%;
  z-index: 5;
  cursor: pointer;
  width: 25px;
  height: 25px;
  &--active {
    path {
      fill: var(--new-front-purple)
    }
  }
  &:hover {
    path {
      fill: var(--new-front-purple)
    }
  }
}
.navItem {
  display: flex;
  align-items: center;
  padding: $p15 $p20;
  border-radius: 10px;
  transition: all .3s ease;
  font-family: $Gilroy;

  justify-content: space-between;

  .info-wrapper {
    display: flex;
    align-items: center;
  }
  .summary {
    font-family: var(--new-front-font-family);
    font-size: $h5;

    .value {
      color: var(--new-front-text-success-bg-primary);
    }
    .currency {
      color: var(--new-front-text-primary-bg-primary);
      margin-left: 5px;
    }
  }

  @include below_phone() {
    .summary {
      font-size: $h6;
    }
  }

  &:hover {
    .navItem__title {
      color: var(--new-front-primary-font-color);
    }
    .navItem__icon {
      &::v-deep svg {
        path {
          fill: #744DB2 !important;
        }
      }
    }
  }
  &--active {
    background: var(--new-font-nav-hover-bg);
    .navItem__title {
      color: var(--new-front-primary-font-color);
    }
    .navItem__icon {
      &::v-deep svg {
        path {
          fill: #744DB2 !important;
        }
      }
    }
  }
  &__icon {
    height: 16px;
    &::v-deep svg {
      path {
        fill: var(--new-front-secondary-font-color) !important;
      }
    }
  }
  &__title {
    color: var(--new-front-secondary-font-color);
    margin-left: $p10;
    font-size: $h4;
  }
  &__wrapper {
    padding: $p5 $p30;
    position: relative;
  }
}
@media (max-width: 1180px) {
  .navItem__wrapper {
    padding: $p5 0;
  }
}
</style>
