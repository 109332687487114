<template>
  <div>
    <preloader v-if="isLoading || !isInit"/>
    <div class="personal-address">
<!--      <div class="personal-address__title-wrapper">-->
<!--        <div class="personal-address__title">-->
<!--          {{ $t('personal-addresses.title') }}-->
<!--          <common-info-modal-->
<!--            :description="$t('info_modals.personal_addresses.description')"-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="personal-address__paragraph-block">-->
<!--        <p class="personal-address__paragraph">-->
<!--          {{ $t('personal-addresses.description') }}-->
<!--        </p>-->
<!--      </div>-->
      <div class="personal-address__container">
        <zero-state
          v-if="showZeroState"
          :meta="zeroStateMeta"
          @open-add-new-user-modal="addNewUser"
        />

        <template v-else>
          <div class="first-line-block">
            <persons-search
              v-model="paginationObject.query"
              class="person-search-field"
              :placeholder="$t('personal-addresses.inputs.search.placeholder.persons')"
            />
            <base-button
              type="primary"
              class="add-new-user-btn"
              :label="$t('personal-addresses.buttons.add-new-user')"
              :disabled="!isUserCreateAllowed"
              @click="addNewUser"
            >
              <template #prepend>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                >
                  <path
                      d="M6 0C6.41421 0 6.75 0.335786 6.75 0.75V5.25H11.25C11.6642 5.25 12 5.58579 12 6C12 6.41421 11.6642 6.75 11.25 6.75H6.75V11.25C6.75 11.6642 6.41421 12 6 12C5.58579 12 5.25 11.6642 5.25 11.25V6.75H0.75C0.335786 6.75 0 6.41421 0 6C0 5.58579 0.335786 5.25 0.75 5.25H5.25V0.75C5.25 0.335786 5.58579 0 6 0Z"
                      fill="white"
                  />
                </svg>
              </template>
            </base-button>
            <base-button
                :label="$t('addresses.vacuum')"
                class="vacuum-btn"
                @click="$router.push({ name: 'payments' })"
            >
            </base-button>
          </div>
          <div class="second-line-block">
            <base-button
                type="primary"
                class="add-new-user-btn"
                :label="$t('personal-addresses.buttons.add-new-user')"
                :disabled="!isUserCreateAllowed"
                @click="addNewUser"
            >
              <template #prepend>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                >
                  <path
                      d="M6 0C6.41421 0 6.75 0.335786 6.75 0.75V5.25H11.25C11.6642 5.25 12 5.58579 12 6C12 6.41421 11.6642 6.75 11.25 6.75H6.75V11.25C6.75 11.6642 6.41421 12 6 12C5.58579 12 5.25 11.6642 5.25 11.25V6.75H0.75C0.335786 6.75 0 6.41421 0 6C0 5.58579 0.335786 5.25 0.75 5.25H5.25V0.75C5.25 0.335786 5.58579 0 6 0Z"
                      fill="white"
                  />
                </svg>
              </template>
            </base-button>
            <persons-search
                v-model="paginationObject.query"
                class="person-search-field"
                :placeholder="$t('personal-addresses.inputs.search.placeholder.persons')"
            />
            <div class="search-block-actions">
              <downloader
                v-model="selectedDownloadType"
                class="file-downloader"
                type="secondary"
              >
                <template #icon>
                  <svg
                      id="btn-select-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                  >
                    <path
                        d="M8.72933 14.9529C8.33425 15.3743 7.66533 15.3743 7.27025 14.9529L0.924369 8.18392C0.32562 7.54525 0.778465 6.49998 1.65391 6.49998L4.49979 6.49998L4.49979 5.49998C4.49979 4.94769 4.94751 4.49998 5.49979 4.49998L10.4998 4.49998C11.0521 4.49998 11.4998 4.94769 11.4998 5.49998L11.4998 6.49998L14.3457 6.49998C15.2211 6.49998 15.674 7.54525 15.0752 8.18392L8.72933 14.9529Z"
                        fill="#744DB2"
                    />
                    <path
                        d="M11.4998 2.49998C11.4998 3.05226 11.0521 3.49998 10.4998 3.49998L5.49979 3.49998C4.94751 3.49998 4.49979 3.05226 4.49979 2.49998L4.49979 1.49998C4.49979 0.947692 4.94751 0.499977 5.49979 0.499977L10.4998 0.499978C11.0521 0.499978 11.4998 0.947693 11.4998 1.49998L11.4998 2.49998Z"
                        fill="#744DB2"
                    />
                  </svg>
                </template>
              </downloader>
              <refresh-button @click="reloadPersonsList" />
            </div>
          </div>
          <div class="third-line-block">
            <base-button
                :label="$t('addresses.vacuum')"
                class="vacuum-btn--mobile"
                @click="$router.push({ name: 'payments' })"
            >
            </base-button>
          </div>
          <div class="users-list">
            <persons-list
                :list="persons"
                :loading="personsLoading"
                :empty-list-title="$t('personal-addresses.persons-list.empty.title')"
                :empty-list-subtitle="$t('personal-addresses.persons-list.empty.subtitle')"
            >
              <template #item="{ item }">
                <person-item-web
                    v-bind="item"
                    class="web-item"
                    :opened-person-id.sync="openedPersonId"
                />
                <person-item-mobile
                    v-bind="item"
                    class="mobile-item"
                    :opened-person-id.sync="openedPersonId"
                />
              </template>
            </persons-list>
          </div>
          <div class="pagination-block">
            <pagination
                v-if="paginationObject.total > persons.length"
                :value="paginationObject"
            />
          </div>
        </template>
        <add-new-user-modal @update="reloadPersonsList" />
      </div>
    </div>
      <new-address-modal />
  </div>
</template>
<script>
import {mapGetters, mapActions, mapState} from 'vuex';
import BtnRefresh from "@/public/components/common/btn-refresh.vue";
import RefreshButton from "@/public/components/common/uiKit/button/refresh/index.vue";
import NewAddressModal from "@/public/components/modals/addressBook/NewAddressModal.vue";

export default {
    name: "crossChain",
    title: 'navigation.personal-address',
    rulesAlias: 'personal',
    components: {
        NewAddressModal,
        RefreshButton,
        BtnRefresh,
        AddNewUserModal: () => import(/* webpackChunkName: "public/components/modals/personal-addresses/AddNewUser" */ '/public/components/modals/personal-addresses/AddNewUser'),
        BaseButton: () => import(/* webpackChunkName: "public/components/common/uiKit/button/base/bg-secondary" */ '/public/components/common/uiKit/button/base/bg-secondary'),
        CommonInfoModal: () => import(/* webpackChunkName: "public/components/modals/commonInfo" */ '/public/components/modals/commonInfo'),
        Downloader: () => import(/* webpackChunkName: "public/components/elements/personal-addresses/Select/Downloader" */ '/public/components/elements/personal-addresses/Select/Downloader'),
        Preloader: () => import(/* webpackChunkName: "public/components/common/preloader" */ '/public/components/common/preloader'),
        PersonsList: () => import(/* webpackChunkName: "public/components/elements/personal-addresses/List" */ '/public/components/elements/personal-addresses/List'),
        PersonsSearch: () => import(/* webpackChunkName: "public/components/elements/personal-addresses/Search" */ '/public/components/elements/personal-addresses/Search'),
        PersonItemWeb: () => import(/* webpackChunkName: "public/components/elements/personal-addresses/List/Items/PersonWeb" */ '/public/components/elements/personal-addresses/List/Items/PersonWeb'),
        PersonItemMobile: () => import(/* webpackChunkName: "public/components/elements/personal-addresses/List/Items/PersonMobile" */ '/public/components/elements/personal-addresses/List/Items/PersonMobile'),
        Pagination: () => import(/* webpackChunkName: "public/components/elements/personal-addresses/Pagination" */ '/public/components/elements/personal-addresses/Pagination'),
        ZeroState: () => import(/* webpackChunkName: "public/pages/zeroState" */ '/public/pages/zeroState')
    },
    data() {
        return {
          paginationObject: {
            offset: 0,
            limit: 10,
            total: 0,
            query: ''
          },
          selectedDownloadType: undefined,
          openedPersonId: undefined,
          persons: [],
          personsLoading: false
        }
    },
    created() {
        const isAllowed = this.userRoleRules[this.$options.rulesAlias].isAllowed;
        const isViewAllowed = this.userRoleRules[this.$options.rulesAlias].entities.view_users;
        if (!isAllowed || !isViewAllowed) {
          this.$Message.error(this.$t('rules.not_allowed'));
          this.$router.push('/merchant/dashboard');
        }
    },
    watch: {
      'paginationObject.offset': {
        handler() {
          this.getList()
        },
        immediate: true
      },
      'paginationObject.query'() {
        if (this.paginationObject.offset !== 0) {
          this.$set(this.paginationObject, 'offset', 0) //при начале поиска сбрасываем offset
        } else {
          this.getList()
        }
      },
      selectedDownloadType(v) {
        if (!!v) {
          const payload = JSON.stringify({
            'organizationId': this.getSelectedOrganization.id
          })

          window.open(`/downloads/${v.id}-personal-users-report?_=` + payload)
          this.selectedDownloadType = undefined
        }
      },
      lastUpdateAddress: {
        handler() {
          this.getList()
        },
        deep: true
      }
    },
    computed: {
        ...mapState('balances', ['lastUpdateAddress']),
        ...mapState({
            themeStatus: state => state.themeStatus,
        }),
        ...mapState('app', [
            'isInit',
            'isLoading'
        ]),
        ...mapState('accounts', {
            accounts: state => state.accounts
        }),
        ...mapGetters('app', [
          'getSelectedOrganization'
        ]),
        ...mapGetters('organizations', ['userRoleRules']),
        isUserCreateAllowed () {
            return this.userRoleRules['personal'].entities.manage_users;
        },
        showZeroState({ paginationObject = {}, persons }) {
          return !paginationObject.query && !persons.length
        },
        zeroStateMeta() {
          return {
            title: this.$t('zero-states.personal-addresses.title'),
            subtitle: this.$t('zero-states.personal-addresses.subtitle'),
            actions: [
              {
                title: this.$t('zero-states.personal-addresses.actions.add-user.title'),
                info: this.$t('zero-states.personal-addresses.actions.add-user.info'),
                btnName: this.$t('zero-states.personal-addresses.actions.add-user.btn-name'),
                action: 'open-add-new-user-modal'
              },
              {
                title: this.$t('zero-states.personal-addresses.actions.api-integration.title'),
                info: this.$t('zero-states.personal-addresses.actions.api-integration.info'),
                btnName: this.$t('zero-states.personal-addresses.actions.api-integration.btn-name'),
                btnSecondary: true,
                withoutIcon: true,
                apiMethod: '/api-reference/personal_addresses/post_create-user'
              }
            ],
            type: 'default'
          }
        }
    },
    methods: {
      ...mapActions('app', [
        'closeModal',
        'openModal',
      ]),
      ...mapActions('personalAddresses', [
        'getPersons',
      ]),
      addNewUser() {
        const { openModal } = this;
        if (!this.isUserCreateAllowed) {
            return
        }
        openModal({
          name: 'AddNewUserModal',
          payload: {},
        });
      },
      async getList() {
        this.personsLoading = true

        const result = await this.getPersons({
          ...this.paginationObject
        })

        this.paginationObject.total = result['total'] ? Number(result['total']) : 0

        this.persons = result['users'] || []

        setTimeout(() => {
          this.personsLoading = false
        }, 200)
      },
      reloadPersonsList() {
        this.$set(this.paginationObject, 'offset', 0)
        this.getList()
      }
    },
};
</script>

<style lang="scss">
.personal-address {
  font-family: var(--new-front-font-family);

  padding: 0 30px 30px 30px;

  width: 78vw;
  max-width: 1550px;

  @include below_content(){
    width: 100vw;
    padding: 0 10px 10px 10px;
  }

  &__title-wrapper {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 30px;
  }

  &__title {
    display: flex;
    align-items: center;

    font-style: normal;
    font-weight: 400;

    font-size: $h1;
    line-height: var(--line-height);
    color: var(--new-front-text-primary-bg-primary);
  }

  &__paragraph-block {
    display: flex;
    align-items: start;
  }

  &__paragraph {
    font-style: normal;
    font-weight: 400;
    font-family: var(--font-family);
    font-size: var(--font-size-h3);
    line-height: var(--line-height);
    color: var(--primary-font-color);

    @include below_360() {
      padding: 0 10px;
    }
  }

  &__container {
    background: var(--new-front-bg-secondary);
    border-radius: 20px;
    box-shadow: 0 8px 50px rgba(10, 17, 105, 0.08);
    //border: 1px solid var(--border-color);
    //margin-top: var(--margin-container-top);
    margin-bottom: var(--margin-container-bottom);

    .first-line-block {
      display: flex;
      gap: 10px;
      justify-content: end;

      padding: 30px 30px 0;
      margin-bottom: 20px;

      .person-search-field {
        display: none;
      }

      .add-new-user-btn {
        width: fit-content;
        padding: 0 20px;
      }
    }
    .second-line-block {
      display: flex;
      align-items: center;
      justify-content: space-between;

      flex-wrap: nowrap;

      padding: 0 30px;

      .add-new-user-btn {
        display: none;
      }
      .person-search-field {
        width: 100%;
      }
      .search-block-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        flex-wrap: nowrap;

        width: 115px;

        margin-left: 10px;

        .file-downloader {
          width: 48px;
        }
        .reload-btn {
          width: 48px;
          margin-left: 10px;
        }
      }
    }
    .users-list {
      margin-top: 20px;
      padding: 0 30px;

      .web-item {
        display: block;
      }
      .mobile-item {
        display: none;
      }
    }
    .pagination-block {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 15px 0;
    }
  }
.vacuum-btn {
  max-width: 170px;
}
  .third-line-block {
    display: none;
  }
  @include below_phone(){
    &__title {
      font-size: $h3;
    }
    &__container {
      .first-line-block {
        padding: 20px 20px 0 20px;

        .add-new-user-btn {
          display: none;
        }
        .person-search-field {
          width:  100%;
          display: flex;
        }
      }
      .second-line-block {
        padding: 0 20px;

        .person-search-field {
          display: none;
        }
        .add-new-user-btn {
          max-width: 200px;
          min-width: 150px;

          display: flex;
        }
      }
      .third-line-block {
        display: flex;
        padding: 0 20px;
        margin-top: 20px;
      }
      .users-list {
        padding: 0 20px;

        .web-item {
          display: none;
        }
        .mobile-item {
          display: block;
        }
      }
    }
    .vacuum-btn {
      display: none;
    }
    .vacuum-btn--mobile {
      max-width: 200px;
    }
  }
}
</style>
