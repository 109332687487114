<template>
  <div class="history-top-bar">
    <div class="filter-web">
      <types-select
        v-model="typesModel"
        class="history-top-bar__types-select"
      />
      <date-picker
        v-model="datePickerModel"
        class="history-top-bar__date-select"
        range
        :close-method.sync="pickerClose"
        clearable
      />
    </div>

    <filter-mobile class="filter-mobile" :is-filtered="isFiltered">
      <types-select
        v-model="typesModel"
        class="history-top-bar__types-select"
      />
      <date-picker
        v-model="datePickerModel"
        class="history-top-bar__date-select"
        range
        :close-method.sync="pickerClose"
        clearable
      />
    </filter-mobile>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'AccountsHistoryTopBar',
    components: {
        FilterMobile: () => import(/* webpackChunkName: "public/components/elements/accounts/History/FilterToggler" */ '/public/components/elements/accounts/History/FilterToggler'),
        DatePicker: () => import(/* webpackChunkName: "public/components/elements/analytics/selects/DatePicker" */ '/public/components/elements/analytics/selects/DatePicker'),
        TypesSelect: () => import(/* webpackChunkName: "public/components/elements/advance-balances/selects/Types" */ '/public/components/elements/advance-balances/selects/Types')
    },
    data() {
        return {
            isLoading: false,
            pickerClose: () => {}
        };
    },
    props: {
      filters: {
        type: Object,
        default: () => ({})
      },
    },
    computed: {
        refreshIcon() {
            return this.isLoading ? '' : 'icon-refresh-cw';
        },
        datePickerModel: {
          get({ filters }) {
            return filters.date
          },
          set(v) {
            if (v[0] === 'Invalid date') {
              this.$set(this.filters, 'date', [])
            } else {
              this.$set(this.filters, 'date', v)
            }
          }
        },
        typesModel: {
          get({ filters }) {
            return filters.types
          },
          set(v) {
            this.$set(this.filters, 'types', v)
          }
        },
        isFiltered({ filters: { date, types } }) {
          return  (!!date && !!date.length) || (!!types && !!types.length)
        }
    },
    methods: {
        refresh() {
            this.$emit('refresh-list')
        },
    },
};

</script>

<style lang="scss">

.history-top-bar {
  .filter-web {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-right: 10px;

    .history-top-bar__types-select {
      width: 100%;
      min-width: 320px;
      max-width: 380px;
    }
    .history-top-bar__date-select {
      width: 100%;
      min-width: 240px;
      max-width: 360px;
    }
  }

  .filter-mobile {
    display: none;
  }

  @include below_880() {
    display: flex;
    justify-content: end;

    .filter-web {
      display: none;
    }

    .filter-mobile {
      display: block;

      .history-top-bar__types-select {
        width: 95vw;
        max-width: 320px;
        margin-bottom: 20px;
      }
      .history-top-bar__date-select {
        width: 95vw;
        max-width: 320px;
        margin-bottom: 20px;
      }
      .filter-toggler__content {
        right: -135px;
      }
    }
  }
  @include below_375() {
    .filter-mobile {
      .filter-toggler__content {
        padding: 20px 5px;
      }
    }
  }
}
</style>
