<script lang="ts">
  import {defineComponent} from 'vue'
  import BaseInput from "@/public/pages/integration-settings/components/Input/index.vue";
  import WidgetsTooltip from "@/public/components/elements/widgets-manage/settings/WidgetsTooltip.vue";
  import Copy from "@/public/components/common/copy.vue";

  export default defineComponent({
    name: "WidgetsConstructorItem",
    components: {Copy, WidgetsTooltip, BaseInput},
    props: {
      label: {
        type: String,
        default: ''
      },
      isTooltip: {
        type: Boolean,
        default: true,
      },
      fieldLabel: String,
      tooltipText: String,
      tooltipTitle: String,
      isRequired: Boolean,
      copy: String,
      lockedText: String,
      isLocked: {
        type: Boolean,
        default: false
      },
      isColumn: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      copyValue () {
        return this.copy || this.fieldLabel
      },
      tooltipTitleValue () {
        return this.tooltipTitle || this.fieldLabel
      },
    }
  })
</script>

<template>
  <div class="inputWrapper__inner">
    <div :class="['inputWrapper__labels', { column: isColumn }]">
      <div class="inputWrapper__label label-left" v-if="fieldLabel">
        <WidgetsTooltip v-if="isTooltip" :text="tooltipText" :title="tooltipTitleValue" />
        <div>{{ fieldLabel }}</div>
        <copy :value="copyValue" />
      </div>
      <div class="inputWrapper__label label-right">
         <span v-if="isRequired" class="required-star">*</span>{{ label }} <WidgetsTooltip v-if="!fieldLabel && isTooltip" :text="tooltipText" :title="tooltipTitleValue" />
      </div>
    </div>
    <slot />
    <div v-if="isLocked" class="locked">
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.41033 28.6641C7.74744 28.6641 7.18 28.4281 6.708 27.9561C6.236 27.4841 6 26.9166 6 26.2537V13.7411C6 13.0782 6.236 12.5107 6.708 12.0387C7.18 11.5667 7.74744 11.3307 8.41033 11.3307H10V8.66406C10 6.99917 10.5838 5.58295 11.7513 4.4154C12.9189 3.24784 14.3351 2.66406 16 2.66406C17.6649 2.66406 19.0811 3.24784 20.2487 4.4154C21.4162 5.58295 22 6.99917 22 8.66406V11.3307H23.5897C24.2526 11.3307 24.82 11.5667 25.292 12.0387C25.764 12.5107 26 13.0782 26 13.7411V26.2537C26 26.9166 25.764 27.4841 25.292 27.9561C24.82 28.4281 24.2526 28.6641 23.5897 28.6641H8.41033ZM16 22.3307C16.6478 22.3307 17.1987 22.1038 17.6527 21.6501C18.1064 21.1961 18.3333 20.6452 18.3333 19.9974C18.3333 19.3496 18.1064 18.7987 17.6527 18.3447C17.1987 17.891 16.6478 17.6641 16 17.6641C15.3522 17.6641 14.8013 17.891 14.3473 18.3447C13.8936 18.7987 13.6667 19.3496 13.6667 19.9974C13.6667 20.6452 13.8936 21.1961 14.3473 21.6501C14.8013 22.1038 15.3522 22.3307 16 22.3307ZM12 11.3307H20V8.66406C20 7.55295 19.6111 6.60851 18.8333 5.83073C18.0556 5.05295 17.1111 4.66406 16 4.66406C14.8889 4.66406 13.9444 5.05295 13.1667 5.83073C12.3889 6.60851 12 7.55295 12 8.66406V11.3307Z" fill="#7E7E7E"/>
      </svg>
      <div class="locked__text">
        {{ lockedText }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.required-star {
  position: relative;
  top: -5px;
  color: var(--ocp-0-100-error-error40);
  font-size: 26px;
  font-weight: 700;
  line-height: 20px;
}
.inputWrapper {
  &__inner {
    position: relative;
    .locked {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #000;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: var(--bg-background-locked, rgba(52, 52, 52, 0.60));
      backdrop-filter: blur(1.5px);
      border-radius: 8px;
      border: 1px solid var(--border-border-input-primary-default, #464646);
      z-index: 4;
      &__text {
        color: var(--text-color-text-secondary, #7E7E7E);
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        padding: 0 10px;
        text-align: center;
        @media screen and (max-width: 850px) {
          font-size: 11px;
        }
        //margin-top: 3px;
      }
    }
    .column {
      flex-direction: column-reverse;
      align-items: flex-start !important;
    }
  }
  &__labels {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    @media (max-width: 850px) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }
  &__label {
    color: var(--text-color-text-secondary);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 5px;
    @media (max-width: 850px) {
      font-size: 10px;
      line-height: normal;
    }
    &-left {
      font-weight: 400;
    }
  }
}
::v-deep {
  .copy-wrapper {
    margin: 0;
    @media (max-width: 850px) {
      svg {
        width: 13px;
        height: 13px;
      }
    }
  }
}
</style>
