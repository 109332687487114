import {getCollectingSettings} from "./actions/getCollectingSettings";
import {getWebhook} from "./actions/getWebhook";
import {createSetting} from "./actions/createSetting";
import {deleteCollectingSetting} from "@/public/store/modules/collecting-profits/actions/deleteSetting";
import {updateSetting} from "@/public/store/modules/collecting-profits/actions/updateSetting";
import {getCollectingHistory} from "@/public/store/modules/collecting-profits/actions/getCollectingHistory";
import {getMinimumAmount} from "@/public/store/modules/collecting-profits/actions/getMinimumAmount";

export const actions = {
    getCollectingSettings,
    getWebhook,
    createSetting,
    deleteCollectingSetting,
    updateSetting,
    getCollectingHistory,
    getMinimumAmount
};
