import gql from 'graphql-tag';

export default gql`
mutation($organizationId: String!, $address: String!, $networks: [String!]!, $alias: String, $comment: String) {
    createAddressBookRecord(
        organizationId: $organizationId,
        address: $address,
        networks: $networks,
        comment: $comment,
        alias: $alias,
    ) {
        code
        result {
            id
            address
            networks
            comment
            alias
        }
    }
}
`;

