<template>
<div class="down-commission__container">
  <div class="down-commission">
    <div class="down-commission__close" @click="emitClose">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.96967 13.0303C0.676777 12.7374 0.676777 12.2626 0.96967 11.9697L5.93934 7L0.96967 2.03033C0.676777 1.73744 0.676777 1.26256 0.96967 0.96967C1.26256 0.676777 1.73744 0.676777 2.03033 0.96967L7 5.93934L11.9697 0.969671C12.2626 0.676777 12.7374 0.676777 13.0303 0.969671C13.3232 1.26256 13.3232 1.73744 13.0303 2.03033L8.06066 7L13.0303 11.9697C13.3232 12.2626 13.3232 12.7374 13.0303 13.0303C12.7374 13.3232 12.2626 13.3232 11.9697 13.0303L7 8.06066L2.03033 13.0303C1.73744 13.3232 1.26256 13.3232 0.96967 13.0303Z" fill="#8F949A"/>
      </svg>
    </div>
    <p class="down-commission__title">{{ $t('downCommission.title') }}</p>
    <div class="down-commission__desc">
      <div class="down-commission__desc-text">
        {{ $t('downCommission.description') }}
      </div>
      <div class="down-commission__desc-actions">
        <AtButton class="down-commission__desc-btn" @click="createFeedBack">
          {{ $t('downCommission.confirm') }}
        </AtButton>
        <AtButton class="down-commission__desc-btnOutlined" @click="updateFeedBack('0')">
          {{ $t('downCommission.cancel') }}
        </AtButton>
      </div>
    </div>
    <div class="down-commission__stats-container">
      <div
          v-for="card in tariffs"
          class="down-commission__stats"
      >
        <div class="down-commission__stats-item" v-for="({ amount, action, minAmount, maxAmount, type }) in card">
          <div class="down-commission__stats-title" v-if="type === 'PERCENT'">
            {{ formatNumber(amount) }} %
          </div>
          <div class="down-commission__stats-title" v-else>
            {{ amount }} {{ accounts[0].currencyId | getCurrencyTicker }}
          </div>
          <div class="down-commission__stats-label">
            {{ feeTariffType(action) }}
          </div>
          <div class="down-commission__stats-desc">
           % min: {{ minAmount }}&nbsp;{{ accounts[0].currencyId | getCurrencyTicker }} - % max {{ maxAmount }}&nbsp;{{ accounts[0].currencyId | getCurrencyTicker }}
          </div>
        </div>
      </div>
    </div>
    <div class="down-commission__desc-actionsMobile">
      <AtButton class="down-commission__desc-btn" @click="createFeedBack">
        {{ $t('downCommission.confirm') }}
      </AtButton>
      <AtButton class="down-commission__desc-btnOutlined" @click="updateFeedBack('0')">
        {{ $t('downCommission.cancel') }}
      </AtButton>
    </div>
  </div>
  <DownCommissionModal @close="emitClose"/>
</div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import createFeedback from "../../../graphQl/mutations/createFeedback";
import updateUserContent from "../../../graphQl/mutations/updateUserContent";
import DownCommissionModal from "../../modals/downCommission/DownCommissionModal";
import BigNumber from "bignumber.js";

const FALSE = '0';
export default {
    name: "DashBoardDownCommission",
    components: {DownCommissionModal},
    data () {
        return {
            firstStatsItem: [
                {
                    title: '5%',
                    label: 'Прием платежей',
                    desc: '% min: 0.1 USD % max: 0.2 USD',
                },
                {
                    title: '5%',
                    label: 'Прием платежей',
                    desc: '% min: 0.1 USD % max: 0.2 USD',
                },
                {
                    title: '5%',
                    label: 'Прием платежей',
                    desc: '% min: 0.1 USD % max: 0.2 USD',
                },
            ],
        };
    },
    computed: {
        ...mapState('accounts', {
            accounts: state => state.accounts,
        }),
        tariffs () {
            const firstBlockItemCount = 6;
            const firstTariff = this.accounts[0].tariffs.slice(0, firstBlockItemCount);
            const secondTariff = this.accounts[0].tariffs.slice(firstBlockItemCount, this.accounts[0].tariffs.length);

            return [firstTariff, secondTariff];
        },
    },
    methods: {
        ...mapActions('app', [
            'request',
            'openModal',
        ]),
        formatNumber (amount) {
            const percent = amount * 100;
            return new BigNumber(percent).decimalPlaces(2).toFixed();
        },
        emitClose () {
            sessionStorage.setItem('downCommission', FALSE);
            this.$emit('close');
        },
        feeTariffType(tariff) {
            const feeTariffType = {
                // Старые типы
                'TRANSACTION_INIT': 'advance_balances.creating_an_order',
                'TRANSACTION_PROCESSED': 'advance_balances.deposit_by_order',
                'TRANSFER': 'advance_balances.transfer_fee',

                'INTERNAL_TRANSFER': 'advance_balances.transfer_fee',
                'ORDER_INIT': 'advance_balances.creating_an_order',
                'ORDER_DEPOSIT': 'advance_balances.deposit_by_order',
                'WALLET_WITHDRAWAL': "advance_balances.wallet_withdrawal_fee",
                'RECURRENT_WITHDRAWAL': "advance_balances.recurrent_withdrawal_fee",
                'PERSONAL_WITHDRAWAL': "advance_balances.personal_withdrawal_fee",
                'PAYOUT_WITHDRAWAL': "advance_balances.payout_withdrawal_fee",
                'WALLET_DEPOSIT': "advance_balances.wallet_deposit_fee",
                'RECURRENT_DEPOSIT': "advance_balances.recurrent_deposit_fee",
                'PERSONAL_DEPOSIT': "advance_balances.personal_deposit_fee",
                'PAYOUT_DEPOSIT': "advance_balances.payout_deposit_fee",
                'BRIDGE_EXTERNAL': 'advance_balances.bridge_external_fee',
                'BRIDGE_INTERNAL': 'advance_balances.bridge_internal_fee',
                'KYT_TRANSACTION_FEE': 'advance_balances.kyt-transaction-fee',
                'KYT_WITHDRAWAL_ADDRESS_FEE': 'advance_balances.kyt-withdrawal-address-fee',
                'KYT_TRANSACTION': 'advance_balances.kyt-transaction',
                'KYT_WITHDRAWAL_ADDRESS': 'advance_balances.kyt-withdrawal-address',
                'EXCHANGE_AUTO': "advance_balances.exchange-auto",
                'EXCHANGE_INTERNAL': "advance_balances.commissions.actions.exchange_internal",
                'ORPHAN_DEPOSIT_WITHDRAWAL': "advance_balances.commissions.actions.orphan_deposit_withdrawal",
                'SEPA_WITHDRAWAL': 'advance_balances.commissions.actions.sepa-withdrawal',
                'SEPA_DEPOSIT': 'advance_balances.commissions.actions.sepa-deposit',
                'FIAT_CRYPTO_EXCHANGE': 'advance_balances.commissions.actions.fiat-exchange',
            };
            return this.$t(feeTariffType[tariff]) || this.$t('advance_balances.unknown_tariff');
        },
        async createFeedBack () {
            const variables = {
                formName: 'Dashboard dropdown commission',
                formContent: JSON.stringify({ empty: '' }),
            };
            const { data: { createFeedback: { code } } } = await this.request({
                type: 'mutation',
                gql: createFeedback,
                variables,
            });
            if (code === 0) {
                const variables = {
                    showCommissionWindow: false,
                };
                await this.request({
                    type: 'mutation',
                    gql: updateUserContent,
                    variables,
                });
                this.openModal({
                    name: 'DownCommissionModal',
                });
            }
        },
        async updateFeedBack (state) {
            const variables = {
                showCommissionWindow: Boolean(+state),
            };
            const { data: { updateUserContent: { code } } } = await this.request({
                type: 'mutation',
                gql: updateUserContent,
                variables,
            });
            if (code === 0) {
                sessionStorage.setItem('downCommission', state);
                this.$emit('close');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.down-commission {
  width: 100%;
  padding: 50px;
  background: var(--secondary-color);
  border-radius: 20px;
  box-shadow: 0 8px 50px rgba(10, 17, 105, 0.08);
  position: relative;
  @media (max-width: 1536px) {
    padding: 20px;
  }
  @media (max-width: 1336px) {
    padding: 40px;
  }
  @media (max-width: 500px) {
    padding: 30px 15px;
  }
  &__title {
    font-weight: 700;
    font-size: 54px;
    color: var(--primary-font-color);
    margin-bottom: 50px;
    @media (max-width: 1536px) {
      font-size: 46px;
    }
    @media (max-width: 950px) {
      text-align: center;
    }
    @media (max-width: 500px) {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
  &__desc {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px;
    @media (max-width: 950px) {
      text-align: center;
    }
    @media (max-width: 500px) {
      margin-bottom: 20px;
    }
    &-text {
      font-size: 24px;
      color: var(--primary-font-color);
      line-height: 170%;
      margin-right: 70px;
      @media (max-width: 1536px) {
        margin-right: 20px;
      }
      @media (max-width: 500px) {
        font-size: 14px;
      }
    }
    &-actions {
      display: flex;
      flex-direction: column;
      @media (max-width: 950px) {
        display: none;
      }
    }
    &-actionsMobile {
      display: flex;
      flex-direction: column;
      @media (min-width: 950px) {
        display: none;
      }
    }
    &-btn {
      margin-bottom: 15px;
      @media (max-width: 950px) {
        margin-top: 15px;
      }
    }
  }
  &__stats {
    padding: 30px 50px;
    border-radius: 20px;
    background: var(--bg-modal-color);
    @media (max-width: 1536px) {
      padding: 30px;
    }
    @media (max-width: 1536px) {
      padding: 20px;
    }
    &-item {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-title {
      font-weight: 500;
      font-size: 36px;
      color: var(--additional-font-color);
      @media (max-width: 500px) {
        font-size: 24px;
      }
    }
    &-label {
      font-weight: 500;
      font-size: 20px;
      color: var(--primary-font-color);
      @media (max-width: 500px) {
        font-size: 16px;
      }
    }
    &-desc {
      font-size: 16px;
      color: var(--primary-font-color);
      @media (max-width: 500px) {
        font-size: 14px;
      }
    }
    &-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px;
      @media (max-width: 950px) {
        grid-template-columns: 1fr ;
        grid-template-rows: 1fr 1fr;
      }
    }
  }
  &__container {
    padding: var(--padding-top-container) var(--padding-right-container);
    @media (max-width: 1650px) {
      padding: 20px;
    }
    @media (max-width: 500px) {
      padding: 0;
      margin-top: 20px;
    }
  }
  &__close {
    position: absolute;
    right: 36px;
    top: 36px;
    width: fit-content;
    cursor: pointer;
    @media (max-width: 1536px) {
      right: 20px;
      top: 20px;
    }
    @media (max-width: 1336px) {
      right: 36px;
      top: 36px;
    }
    @media (max-width: 500px) {
      right: 20px;
      top: 20px;
    }
  }
}
.down-commission__desc-btnOutlined {
  background: transparent;
  border-radius: 12px;
  border: 1px solid var(--blue-state-color);
  transition: all 0.3s ease-in;
  min-height: 60px;
  min-width: 300px;
  @media (max-width: 1536px) {
    min-width: 240px;
  }
  @media (max-width: 500px) {
    min-width: 100%;
  }
  //&:hover {
  //  border: 1px solid var(--blue-400);
  //  background: var(--blue-400);
  //
  //  ::v-deep .at-btn__text {
  //    color: var(--white-100);
  //  }
  //}

  ::v-deep .at-btn__text {
    color: var(--primary-font-color);
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }
}
.down-commission__desc-btn {
  border-radius: 12px;
  background: var(--blue-400);
  border: 1px solid var(--blue-400);
  transition: all 0.3s ease-in;
  min-height: 60px;
  min-width: 300px;
  @media (max-width: 1536px) {
    min-width: 240px;
  }
  @media (max-width: 500px) {
    min-width: 100%;
  }

  //&:hover {
  //  background: transparent;
  //  border: 1px solid var(--blue-state-color);
  //  ::v-deep .at-btn__text {
  //    color: var(--white-100);
  //  }
  //}

  ::v-deep .at-btn__text {
    color: var(--white-100);
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }
}
</style>
