import {fetchFee} from "./actions/fetchFee";
import {createWithdrawal} from "./actions/createWithdrawal";
import {clearWithdrawal} from "./actions/clearWithdrawal";
import {clearFee} from "./actions/clearFee";
import {fetchOrphanFee} from "@/public/store/modules/withdrawal-request-modal/actions/fetchOrphanFee";
import {createOrphanWithdrawal} from "@/public/store/modules/withdrawal-request-modal/actions/createOrphanWithdrawal";

export const actions = {
    clearWithdrawal,
    clearFee,
    fetchOrphanFee,
    fetchFee,
    createWithdrawal,
    createOrphanWithdrawal
};
