<template>
<div :class="['history', disabled ? 'disabled' : '']" @click="emitClick">
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.811 0.69534L11.9406 0.565707C12.3531 0.153228 13.0219 0.153228 13.4344 0.565707C13.8468 0.978186 13.8468 1.64695 13.4344 2.05943L13.3047 2.18906C13.7651 2.70443 13.7479 3.49589 13.2531 3.99064L4.24687 12.9969C4.1908 13.053 4.12055 13.0928 4.04362 13.112L0.543619 13.987C0.39453 14.0243 0.236816 13.9806 0.12815 13.8719C0.0194837 13.7633 -0.0242003 13.6055 0.0130719 13.4565L0.888072 9.95646C0.907304 9.87953 0.947081 9.80928 1.00315 9.75321L9.43925 1.3171C9.26852 1.19671 9.03091 1.21289 8.87815 1.36564L5.99687 4.24693C5.82601 4.41778 5.549 4.41778 5.37815 4.24693C5.2073 4.07607 5.2073 3.79906 5.37815 3.62821L8.25943 0.746925C8.75466 0.251699 9.54717 0.234948 10.0625 0.696675C10.5593 0.251558 11.3137 0.251113 11.811 0.69534ZM11.2469 1.36564C11.076 1.19479 10.799 1.19479 10.6281 1.36564L1.70756 10.2862L1.0388 12.9613L3.71384 12.2925L12.6344 3.37193C12.8053 3.20107 12.8053 2.92406 12.6344 2.75321L11.2469 1.36564Z" :fill="!themeStatus ? '#6750A4' : '#FFFFFF'"/>
    </svg>
</div>
</template>
<script>
    import {mapState} from "vuex";

    export default {
        name: "EditButton",
        props: {
          disabled: {
              type: Boolean,
              default: false
          }
        },
        computed: {
            ...mapState(['themeStatus']),
        },
        methods: {
            emitClick () {
                this.$emit('click')
            }
        }
    }
</script>
<style scoped lang="scss">
.disabled {
    opacity: .5;
    cursor: not-allowed;
    pointer-events: none;
}
.history {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: var(--new-front-main-bg);
  border: 1px solid #744DB2;
    border-radius: 10px;
  transition: background .2s ease;
    cursor: pointer;
  &:hover {
    background: var(--new-front-bg-btn-secondary-bg-modal-primary-hover);
  }
}
</style>
