a
<template>
  <div class="transactions-sort-by-currency-to">
    <p class="transactions-sort-by-currency-to__title">
      {{ $t('transactions.replenishment_currencies') }}
    </p>
    <AtSelect
        v-if="hasCurrencies"
        class="transactions-sort-by-currency-to__select"
        :placeholder="select_the_replenishment_currencies"

        clearable
        :value="selectedCurrencies[0]"
        @on-change="changeSelect"
    >
      <AtOption
          v-for="(currency, key) in sortCurrencies"
          :key="key"
          :value="currency.currency"
      />
    </AtSelect>
  </div>
</template>

<script>

import {mapState, mapGetters, mapActions} from "vuex";

import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';

export default {
    name: 'TransactionsSortByCurrencyFrom',
    components: {
        AtSelect,
        AtOption,
    },
    data() {
        return {
            selectedCurrencies: [],
        };
    },
    computed: {
        ...mapState('app', [
            'currencies',
        ]),
        ...mapGetters('app', [
            'hasCurrencies',
        ]),
        select_the_replenishment_currencies() {
            return this.$t('transactions.select_the_replenishment_currencies');
        },
        sortCurrencies() {
            return this.currencies.filter(el => el.isFiat === false);
        },
    },
    beforeMount() {
        this.setCurrenciesFromUrl();
    },
    methods: {
        ...mapActions('transactions', [
            'fetchTransactions',
        ]),
        setCurrenciesFromUrl() {
            const currenciesFromUrl = this.$route.query.currenciesTo;

            if (!currenciesFromUrl) {
                return false;
            }


            this.selectedCurrencies = currenciesFromUrl.split(',');
        },
        changeSelect(values) {
            const {
                $route,
                $router,
                fetchTransactions,
            } = this;
            const val = values ? [values] : [];
            fetchTransactions({
                route: $route,
                router: $router,
                currenciesTo: val,
                excludeParams: ['page'],
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.transactions-sort-by-currency-to {
  &__title {
    margin-bottom: 8px;

    font-size: var(--font-size-text-main);
    font-family: var(--font-family);
    line-height: var(--line-height);
    color: var(--black-200);

  }

  &__select ::v-deep .at-select {
    background: #FFFFFF;
    box-sizing: border-box;


  }

  ::v-deep .at-select__arrow {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  ::v-deep .at-select__clear {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  &__select::v-deep .at-select__selection {
    border: 1px solid #F2F2F2;
    border-radius: 12px;
    min-height: 46px;
    display: flex;
    padding-left: 30px;
    align-items: center;
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    flex-wrap: wrap;
  }

  ::v-deep .at-select__dropdown .at-select__option {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

  ::v-deep .at-select__placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }

  ::v-deep .at-select__selection {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

}

</style>