import gql from 'graphql-tag';

export default gql`
    mutation createPayinAddress(
        $organizationId: String!, 
        $accountId: String!, 
        $currencyId: String!,
        $networkId: String!,  
    ) {
        createPayinAddress(
            organizationId: $organizationId, 
            accountId: $accountId, 
            currencyId: $currencyId,
            networkId: $networkId
        ) {
            code
            result {
              id
              currencyId
              networkId
              address
              tag
              balance
            }
        }
    }
`;
