import deleteCollectingSettingQuery from "../../../../graphQl/mutations/deleteCollectingSetting";

export const deleteCollectingSetting = async ({dispatch, rootGetters, commit}, payload) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    let removeAddress;

    try {
        removeAddress = await dispatch('app/request', {
            type: 'mutation',
            gql: deleteCollectingSettingQuery,
            variables: {
                organizationId,
                ...payload,
            },
        }, {root: true});
    } catch (error) {
        return error;
    }

    if (removeAddress.message) {
        throw removeAddress.message;
    }

    if (removeAddress.errors) {
        throw removeAddress.errors;
    }
    commit('setRequestState', true)
    return removeAddress;
};
