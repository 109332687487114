<template>
    <div class="example-api">
        <component :is="apiComponent" />
    </div>
</template>

<script>

import GetBalance from "/public/components/elements/example-api/GetBalance";
import ActiveCurrencies from "/public/components/elements/example-api/ActiveCurrencies";
import CreateNewOrder from "/public/components/elements/example-api/CreateNewOrder";
import GetOrderInfo from "/public/components/elements/example-api/GetOrderInfo";
import EstimatedFee from "/public/components/elements/example-api/EstimatedFee";
import Withdraw from "/public/components/elements/example-api/Withdraw";
import ExchangeRate from "/public/components/elements/example-api/ExchangeRate";
import ExchangeBalanceOrder from "/public/components/elements/example-api/ExchangeBalanceOrder";
import GetOrders from "/public/components/elements/example-api/GetOrders";
import GetTransactions from "/public/components/elements/example-api/GetTransactions";
import RollingReserveInfo from "/public/components/elements/example-api/RollingReserveInfo";

export default {
    name: 'ExampleApiPage',
    components: {
        GetBalance,
        ActiveCurrencies,
        CreateNewOrder,
        GetOrderInfo,
        EstimatedFee,
        Withdraw,
        ExchangeRate,
        ExchangeBalanceOrder,
        GetOrders,
        GetTransactions,
        RollingReserveInfo,
    },
    computed: {
        api () {
            return this.$route.params.type;
        },
        apiComponent () {
            const { api } = this;
            let component = null;

            switch (api) {
            case 'get-balance':
                component = 'GetBalance';
                break;
            case 'active-currencies':
                component = 'ActiveCurrencies';
                break;
            case 'create-new-order':
                component = 'CreateNewOrder';
                break;
            case 'get-order-info':
                component = 'GetOrderInfo';
                break;
            case 'estimated-fee':
                component = 'EstimatedFee';
                break;
            case 'withdraw':
                component = 'Withdraw';
                break;
            case 'exchange-rate':
                component = 'ExchangeRate';
                break;
            case 'exchange-balance-order':
                component = 'ExchangeBalanceOrder';
                break;
            case 'get-orders':
                component = 'GetOrders';
                break;
            case 'get-transactions':
                component = 'GetTransactions';
                break;
            case 'rolling-reserve-info':
                component = 'RollingReserveInfo';
                break;
            }

            return component;
        },
    },
};

</script>