<template>
<div>
  <LinesBackground class="linesBackground" />
  <div class="logo">
    <logo />
  </div>
  <div class="switcher">
    <NewThemeSwitch />
  </div>
  <div class="lang-change-trigger">
    <LanguageSwitcher />
  </div>
  <div class="login__wrapper">
<!--    <div v-if="pending">-->
<!--      грузим-->
<!--    </div>-->
    <div class="login-form__wrapper">
      <h1 class="login-form__title">{{ $t('authorization.login-title') }}</h1>
      <form @submit.prevent="onSubmit" >
        <div class="login-input__wrapper">
          <label class="login-input__label" for="username">{{ $t('authorization.email') }}</label>
          <input  class="login-input" name="Username" v-model="username" :placeholder="$t('authorization.email')" />
        </div>
        <div class="login-input__wrapper">
          <label  class="login-input__label" for="password">{{ $t('authorization.password') }}</label>
          <NInputPassword v-model="password" />
            <p class="forgotten-password" @click="toggleRestoreModal">{{ $t('authorization.forgot') }}</p>
        </div>
        <div v-if="errorCode" class="error-banner">
          <svg class="error-banner__icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.80977 0.21967C6.95042 0.0790176 7.14118 0 7.3401 0H16.6599C16.8588 0 17.0496 0.0790176 17.1902 0.21967L23.7803 6.80977C23.921 6.95042 24 7.14118 24 7.3401V16.6599C24 16.8588 23.921 17.0496 23.7803 17.1902L17.1902 23.7803C17.0496 23.921 16.8588 24 16.6599 24H7.3401C7.14118 24 6.95042 23.921 6.80977 23.7803L0.21967 17.1902C0.0790176 17.0496 0 16.8588 0 16.6599V7.3401C0 7.14118 0.0790176 6.95042 0.21967 6.80977L6.80977 0.21967ZM7.65076 1.5L1.5 7.65076V16.3492L7.65076 22.5H16.3492L22.5 16.3492V7.65076L16.3492 1.5H7.65076Z" fill="#BE4040"/>
            <path d="M10.5023 16.5C10.5023 15.6716 11.1739 15 12.0023 15C12.8307 15 13.5023 15.6716 13.5023 16.5C13.5023 17.3284 12.8307 18 12.0023 18C11.1739 18 10.5023 17.3284 10.5023 16.5Z" fill="#BE4040"/>
            <path d="M10.6493 7.49256C10.5693 6.69343 11.1969 6 12 6C12.8031 6 13.4307 6.69343 13.3507 7.49256L12.8246 12.7537C12.7823 13.1774 12.4258 13.5 12 13.5C11.5742 13.5 11.2177 13.1774 11.1754 12.7537L10.6493 7.49256Z" fill="#BE4040"/>
          </svg>
          <span class="error-banner__text">{{ $t('authorization.incorrect-data') }}</span>
        </div>
        <button class="login-button" type="submit">{{ $t('authorization.login-title') }}</button>
      </form>
      <div class="login-description">
        {{ $t('authorization.dont-have-acc') }} <RouterLink class="login-description__link" :to="`/registration${utm}`">{{ $t('authorization.registration-title') }}</RouterLink>
      </div>
    </div>
  </div>
    <transition name="slide-fade">
      <div v-if="isRestoreModalOpened" class="password-modal__wrapper" @click="toggleRestoreModal">
          <div class="password-modal">
              <div class="password-modal__title">{{ $t('authorization.restore-title') }}</div>
              <div class="password-modal__secondaryDesc">{{ $t('authorization.restore-desc') }}</div>
              <div class="password-modal__desc">{{ $t('authorization.restore-subDesc') }}</div>
              <base-button @click="toggleRestoreModal" :label="$t('authorization.close')"/>
          </div>
      </div>
    </transition>
</div>


</template>

<script>
import axios from "axios";
import {v4 as UUID} from "uuid";
import LinesBackground from "@/public/components/elements/LinesBackground";
import {mapActions, mapMutations, mapState} from "vuex";
import NewThemeSwitch from "@/public/components/elements/NewThemeSwitch";
import NInputPassword from "@/public/components/common/NInputPassword";
import ChangeLanguageModal from "@/public/components/modals/ChangeLanguageModal";
import NButton from "@/public/components/common/NButton";
import LanguageSwitcher from "@/public/components/common/LanguageSwitcher";
import {lkLogoLoader} from "@/public/utils/logo-loader";
import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";

export default {
    name: "login",
    components: {
        BaseButton,
      LanguageSwitcher,
      NButton, ChangeLanguageModal, NInputPassword, NewThemeSwitch, LinesBackground, Logo: lkLogoLoader()},
  data() {
        return {
            username: '',
            password: '',

            pending: false,
          errorCode: 0,
          errorMsg: '',
          isTwoFactor: false,
          twoFactorCode: '',
            isRestoreModalOpened: false
        };
    },
  methods: {
      ...mapMutations('app', ['setAuthLogin', 'setAuthTwoFa']),
      ...mapActions('app', ['init']),
        toggleRestoreModal () {
            this.isRestoreModalOpened = !this.isRestoreModalOpened
        },
        async onSubmit() {
        if(this.pending) {
          return;
        }

            this.pending = true;

            try {
              window.$$beforeLoginEventTrigger = false;

              const loc = window.location.href || null;
              const ref = document.referrer || null;
              let ga = null;

              const _ga = (document.cookie || '').matchAll(/_ga=([A-Z0-9.]+);/g);

              if(_ga) {
                let __ga = _ga.next();

                if(__ga && __ga.value && __ga.value[1]) {
                  __ga = __ga.value[1].split('.').reverse();

                  if(__ga.length >= 2) {
                    ga = __ga[1] + '.' + __ga[0];
                  }
                }
              }

                const {data} = await axios.post('/public-api/login', {
                    username: this.username,
                    password: this.password,

                  ga: ga,
                  location: loc,
                  referrer: ref,
                  rid: UUID(),
                });

                if (data.code === 2000 || data.code === 3004) {
                    this.pending = false;
                    this.$router.push({name: 'closed-session'});
                    return
                }
                if(!data.success) {
                    /*
                        USERNAME_AND_PASSWORD_REQUIRED: 3000,
                        USERNAME_OR_PASSWORD_INCORRECT: 3001,
                    * */
                    this.errorCode = data.code

                  this.pending = false;
                } else {

                  if(window.$$beforeLoginEventTrigger) {
                    delete window.$$beforeLoginEventTrigger;
                  } else {
                    const timer = setTimeout(() => {
                      window.location.href = window.location.href+'';
                    }, 5000);

                    window.$$beforeLoginEventTrigger = () => {
                      clearTimeout(timer);

                      this.pending = false;
                    };
                  }

                    if(data.msg === '2fa') {
                        // дождемся ивента

                        // this.$store.commit('app/setAuthLogin', true);
                        // this.isTwoFactor = true;
                        //
                        // await this.$router.push({name: 'login-2fa'})
                        //     .catch(() => {});

                        return;
                    }

                    // дождемся ивента

                    // this.$store.commit('app/setAuthLogin', true);
                    // this.setAuthTwoFa(true)
                    //
                    // await this.$store.dispatch('app/init', null);
                    //
                    // await this.$router.push({name: 'dashboard'})
                    //     .catch(() => {});
                }
            } catch (e) {
                this.pending = false;

                await new Promise(r => setTimeout(r, 1000));

                return this.onSubmit();
            }
        },
      async onConfirmVerification() {
        this.pending = true;

        try {
          const {data} = await axios.post('/public-api/login/2fa', {
            code: this.twoFactorCode,
          });


          if(!data.success) {
            /*
            TWO_FA_CODE_REQUIRED: 3002,
            TWO_FA_CODE_INCORRECT: 3003,
            * */
            this.errorCode = data.code
            switch (data.code) {
              case 3002:
                alert('Не введен код');
                this.errorMsg = 'Enter code'
                break;
              case 3003:
                this.errorMsg = 'Verification code is incorrect'
                break;
                case 3004:
                    await this.$router.push({name: 'closed-session'});
                return;
                case 2000:
                    await this.$router.push({name: 'closed-session'});
                    return ;
              default:
                alert('Internal error, code: ' + data.code);
                break;
            }
          } else {
            this.$store.commit('app/setAuthTwoFa', true);
            await this.$store.dispatch('app/init', null);

            this.$router.push({name: 'dashboard'});
          }

          this.pending = false;
        } catch (e) {
          await new Promise(r => setTimeout(r, 1000));

          return this.onSubmit();
        }
      }
    },
  computed: {
      utm () {
        return document.location.search
      }
  }
}
</script>

<style lang="scss" scoped>
* {
  transition: all .2s ease;
}
.forgotten-password {
  color: #744DB2;
  font-family: $Gilroy;
  font-size: 18px;
  margin-top: 10px;
  font-weight: 500;
  cursor: pointer;
}
.password-modal {
  font-family: $Gilroy;
  max-width: 640px;
  padding: 30px;
  background: var(--new-front-main-bg);
  border-radius: 30px;
  font-weight: 500;
  @media screen and (max-width: 576px) {
    border-radius: 20px;
    padding: 20px;
  }
  &__title {
    font-size: $h1;
    color: var(--new-front-primary-font-color);
    @media screen and (max-width: 576px) {
      font-size: $h2;
    }
  }
  &__secondaryDesc {
    margin-top: 10px;
    font-size: 18px;
    color: var(--new-front-secondary-font-color);
    margin-bottom: 5px;
    @media screen and (max-width: 576px) {
      font-size: 14px;
    }
  }
  &__desc {
    font-size: 16px;
    color: #BE9B40;
    margin-bottom: 50px;
    border-radius: 30px;
    @media screen and (max-width: 576px) {
      font-size: 13px;
    }
  }
  &__wrapper {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0,0,0,.4);
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
.login {
  &__wrapper {
    font-family: 'Gilroy';
    background: var(--new-front-gradient-bg);
    height: 100vh;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-form {
    &__title {
      font-size: 36px;
      margin-bottom: 50px;
      font-weight: 500;
      color: var(--new-front-primary-font-color);
    }
    &__wrapper {
      z-index: 2;
      padding: 30px;
      background: var(--new-front-main-bg);
      border-radius: 30px;
      max-width: 640px;
      width: 100%;
    }
  }
  &-input {
    &__label {
      color: var(--new-front-primary-label-color);
      font-size: 18px;
      margin-bottom: 15px;
    }
    &__wrapper {
      margin-bottom: 20px;
    }
  }
  &-button {
    font-weight: 500;
    font-size: 18px;
    background: #744DB2;
    border-radius: 10px;
    line-height: 28px;
    color: #ffffff;
    padding: 10px;
    width: 100%;
    outline: none;
    border: none;
    margin-top: 30px;
    cursor: pointer;
  }
  &-description {
    margin-top: 30px;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: var(--new-front-primary-font-color);
    &__link {
      color:#6750A4;
    }
  }
}
.login-input {
  width: 100%;
  border-radius: 9px;
  outline: none;
  padding: 15px;
  border: 1px var(--new-front-input-border) solid;
  font-size: 18px;
  margin-top: 10px;
  background: var(--new-front-input-bg);
  color: var(--new-front-primary-font-color);
}
.logo {
  position: absolute;
  top: 30px;
  left: 30px;
}
.linesBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.switcher {
  position: absolute;
  top: 30px;
  right: 30px;
}
.error-banner {
  margin-left: 12px;
  display: flex;
  align-items: center;
  &__icon {
    margin-right: 20px;
  }
  &__text {
    color: #BE4040;
    font-weight: 500;
    font-size: 16px;
  }
}
.twoFactor {
  z-index: 2;
  padding: 30px;
  background: var(--new-front-main-bg);
  border-radius: 30px;
  max-width: 640px;
  width: 100%;
  &__title {
    font-weight: 500;
    font-size: 36px;
    color: var(--new-front-primary-font-color);
    text-align: center;
    margin-bottom: 30px;
  }
  &__subtitle {
    font-weight: 400;
    font-size: 18px;
    color: var(--new-front-primary-label-color);
    text-align: center;
    margin-bottom: 50px;
  }
  &__btn {
    margin: 50px 0;
  }
  &__logout {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
  }
}
.lang-change-trigger {
  position: absolute;
  right: 132px;
  top: 38px;
}
@media (max-width: 556px) {
  .login {
    &-description {
      font-size: 13px;
    }
    &__wrapper {
      padding: 10px;
    }
     &-form {
       &__wrapper {
         padding: 20px;
         border: 1px solid var(--new-front-border-color);
       }
       &__title {
         font-size: 24px;
         margin-bottom: 30px;
       }
     }
  }
  .login-input {
    font-size: 13px;
    &__label {
      font-size: 13px;
      margin-top: 10px;
    }
  }
  .error-banner {
    margin-left: 12px;
    display: flex;
    align-items: center;
    &__icon {
      margin-right: 15px;
    }
    &__text {
      color: #BE4040;
      font-weight: 500;
      font-size: 13px;
    }
  }

  .logo {
    left: 10px;
    top: 10px;
  }
  .switcher {
    right: 10px;
    top: 10px;
  }
  .lang-change-trigger {
    position: absolute;
    right: 105px;
    top: 17px;
  }
}
/* Анимации появления и исчезновения могут иметь */
/* различные продолжительности и динамику.       */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
