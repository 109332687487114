import gql from 'graphql-tag';

export default gql`
    mutation updateMerchant(
        $merchantId: String!,
        $organizationId: String!, 
        $name: String, 
        $logoUrl: String,
        $currencies: [[String!]!],  
    ) {
        updateMerchant(
            merchantId: $merchantId,
            organizationId: $organizationId, 
            name: $name, 
            logoUrl: $logoUrl,
            currencies: $currencies,  
        ) {
            code
            result
        }
    }
`;
