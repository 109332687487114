<template>
  <div class="period-table">
    <table-row
      v-for="(row, index) in analytics"
      :grouped-by-wallet="isWalletsGrouping"
      :info="row"
      :key="index"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PeriodTable",
  components: {
    TableRow: () => import(/* webpackChunkName: "/public/components/elements/analytics/tables/Advanced/Period/Row" */ '/public/components/elements/analytics/tables/Advanced/Period/Row'),
  },
  props: {
    isWalletsGrouping: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('analytics', [
      'analytics'
    ]),
  },
}
</script>

<style scoped lang="scss">
.period-table {
  min-width: 980px;
  max-width: 100%;

  font-weight: 500;

  @include below_1180() {
    min-width: 1080px;
  }
}
</style>