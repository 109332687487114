import gql from 'graphql-tag';

export default gql`
    query ($limit: Int, $offset: Int, $date: [String], $type: UserReferralsBufferBalanceType ) {
        getUserRewardBalance (limit: $limit, offset: $offset, date: $date, type: $type) {
        code
        result {
        totalCount
        history {
            amount
            type
            date 
            address 
            transaction
           }
        }
      }
    }
`;
