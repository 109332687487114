<script>
  import {
    COLORS, PAYIN_CRYPTO_TYPE_INVOICE, PAYIN_CRYPTO_TYPE_PERSONAL
  } from "@/public/pages/widgets/constants";
  import WidgetsColorPicker from "@/public/components/elements/widgets-manage/settings/WidgetsColorPicker.vue";
  import Popper from 'vue-popperjs';
  import 'vue-popperjs/dist/vue-popper.css';
  import BaseInput from "@/public/pages/integration-settings/components/Input/index.vue";
  import WidgetsTooltip from "@/public/components/elements/widgets-manage/settings/WidgetsTooltip.vue";
  import Copy from "@/public/components/common/copy.vue";
  import WidgetsToggle from "@/public/components/elements/widgets-manage/settings/WidgetsToggle.vue";
  import WidgetsConstructorItem from "@/public/components/elements/widgets-manage/settings/WidgetsConstructorItem.vue";
  import WidgetsMultiCurrencySelect
    from "@/public/components/elements/widgets-manage/settings/WidgetMultiWalletSelect.vue";
  import WidgetsMultiWalletFiat from "@/public/components/elements/widgets-manage/settings/WidgetsMultiWalletFiat.vue";
  import BigNumber from "bignumber.js";
  import _ from "lodash";
  import moment from "moment/moment";
  import DatePicker from 'vue2-datepicker';
  import WidgetsConstructorEmail
    from "@/public/components/elements/widgets-manage/settings/WidgetsConstructorEmail.vue";
  import WidgetsApiKeysSelect from "@/public/components/elements/widgets-manage/settings/WidgetApiKeysSelect.vue";
  import WidgetsIconSelect from "@/public/components/elements/widgets-manage/settings/WidgetIconsSelect.vue";
  import WidgetsThemeSelect from "@/public/components/elements/widgets-manage/settings/WidgetsThemeSelect.vue";
  import {mapMutations, mapState} from "vuex";
  import validUrl from 'valid-url'

  export default {
    name: "WidgetsManageConstructor",
    components: {
      WidgetsThemeSelect,
      WidgetsIconSelect,
      WidgetsApiKeysSelect,
      WidgetsConstructorEmail,
      WidgetsMultiWalletFiat,
      WidgetsMultiCurrencySelect,
      WidgetsConstructorItem,
      DatePicker,
      WidgetsToggle, Copy, WidgetsTooltip, BaseInput, WidgetsColorPicker,  'popper': Popper
    },
    PAYIN_CRYPTO_TYPE_INVOICE,
    PAYIN_CRYPTO_TYPE_PERSONAL,
    data () {
      return {
        colors: COLORS,
      }
    },
    computed: {
      ...mapState('widgetsManage', ['widget']),
      maxValue() {
        const {selectedNetworkObject} = this;
        const maxValue = new BigNumber(6).div(3600);
        return maxValue ?? 0;
      },
      lifetimeRange() {
        // const {step, minValue, maxValue} = this;
        // const upperLimit = maxValue.plus(step);
        // // minValue.integerValue(BigNumber.ROUND_DOWN).toNumber()
        const lowerlimit = 0;
        return _.range(lowerlimit, +7, 1);
      },
      isPayinWebhookValid() {
        if (this.widget.payinCryptoWebhookURL === '') return false
        return !validUrl.isHttpUri(this.widget.payinCryptoWebhookURL, true);
      },
      isPayoutWebhookValid() {
        if (this.widget.payoutCryptoWebhookURL === '') return false
        return !validUrl.isHttpUri(this.widget.payoutCryptoWebhookURL, true);
      },
      isPayoutApprovalWebhookValid() {
        if (this.widget.payoutCryptoApprovalWebhookURL === '') { return false; }
        return !validUrl.isHttpUri(this.widget.payoutCryptoApprovalWebhookURL, true);
      },
    },
    methods: {
      ...mapMutations('widgetsManage', [
        'setWebhook',
        'setPayinCryptoInvoiceFree',
        'setPayinCryptoInvoiceCommissionOnPayer',
        'setPayinCryptoInvoiceCurrencies',
        'setPayinCryptoInvoiceTolerancePercent',
        'setPayinCryptoInvoiceSlippagePercent',
        'setPayinCryptoCurrenciesTo',
        'setPayinCryptoInvoicePayerEmailRequired',
        'setPayinCryptoInvoicePayerEmailAllow',
        'setPayoutCryptoWebhookURL',
        'setPayoutCryptoApprovalWebhookURL',
        'setPayoutCryptoCurrenciesTo',
        'setTheme',
        'setApiKey',
        'setPayoutCryptoCurrenciesFrom',
        'setAccent',
        'setPayinCryptoInvoiceInsurancePercent'
      ]),
      minMaxValue(date) {
        const dateFormated = moment(date).format('HH:mm');
        let seconds = moment.duration(dateFormated).asSeconds();
        return (seconds > this.selectedNetworkObject.maxFreezeTime || seconds < this.selectedNetworkObject.minFreezeTime);
      },
      handleEnterWebhook (val) {
        this.setWebhook(val)
      },
      handleApiKeySelect (val) {
        this.setApiKey(val)
      },
      handleFreePayment (val) {
        this.setPayinCryptoInvoicePayerEmailRequired(false)
        this.setPayinCryptoInvoicePayerEmailAllow(false)
        this.setPayinCryptoInvoiceFree(val)
      },
      handleComissionOnPayer (val) {
        this.setPayinCryptoInvoiceCommissionOnPayer(val)
      },
      handleTolerancePercent (val) {
        this.setPayinCryptoInvoiceTolerancePercent(val)
      },
      handleInvoiceInsurancePercent (val) {
        this.setPayinCryptoInvoiceInsurancePercent(val)
      },
      handleSlippagePercent (val) {
        this.setPayinCryptoInvoiceSlippagePercent(val)
      },
      handlePayinInvoiceCurrencies (val) {
        this.setPayinCryptoInvoiceCurrencies(val)
      },
      handlePayinCryptoCurrenciesTo (val) {
        this.setPayinCryptoCurrenciesTo(val)
      },
      handlePayoutCryptoWebhookURL (val) {
        this.setPayoutCryptoWebhookURL(val)
      },
      handlePayoutCryptoApprovalWebhookURL (val) {
        this.setPayoutCryptoApprovalWebhookURL(val)
      },
      handlePayoutCryptoCurrenciesTo (val) {
        this.setPayoutCryptoCurrenciesTo(val)
      },
      handlePayoutCryptoCurrenciesFrom (val) {
        this.setPayoutCryptoCurrenciesFrom(val)
      },
      handleTheme (val) {
        this.setTheme(val)
      },
      handleSelectedColor (val) {
        this.setAccent(val)
      }
    }
  }
</script>

<template>
<div class="constructor">
  <div>
    <div class="title">{{ $t('widgets.mainStyle') }}</div>
    <div class="theme-wrapper">
      <div class="colors">
        <div class="colors__item" v-for="(color, key) in colors" :key="key" :style="{background: color}" @click="handleSelectedColor(key)">
          <svg v-if="key === widget.accentColor" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.9393 9.93934C22.5251 9.35355 23.4748 9.35355 24.0606 9.93934C24.6392 10.5179 24.6463 11.4516 24.0819 12.0389L16.0975 22.0195C16.0859 22.0339 16.0736 22.0476 16.0606 22.0607C15.4748 22.6464 14.5251 22.6464 13.9393 22.0607L8.64637 16.7678C8.06058 16.182 8.06058 15.2322 8.64637 14.6464C9.23216 14.0607 10.1819 14.0607 10.7677 14.6464L14.9547 18.8335L21.8995 9.98421C21.9118 9.96847 21.9251 9.95349 21.9393 9.93934Z" fill="white"/>
          </svg>
        </div>
<!--            <popper-->
<!--                trigger="clickToOpen"-->
<!--                :options="{-->
<!--                   placement: 'right-start',-->
<!--                }"-->
<!--          >-->
<!--          <div class="colors__item addBtn" slot="reference" ref="actionsTrigger">-->
<!--            <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M16.5 8C17.0523 8 17.5 8.44772 17.5 9V15H23.5C24.0523 15 24.5 15.4477 24.5 16C24.5 16.5523 24.0523 17 23.5 17H17.5V23C17.5 23.5523 17.0523 24 16.5 24C15.9477 24 15.5 23.5523 15.5 23V17H9.5C8.94772 17 8.5 16.5523 8.5 16C8.5 15.4477 8.94772 15 9.5 15H15.5V9C15.5 8.44772 15.9477 8 16.5 8Z" fill="white"/>-->
<!--            </svg>-->
<!--          </div>-->
<!--          <WidgetsColorPicker />-->
<!--        </popper>-->
      </div>
    </div>
  </div>
  <div>
    <div class="title">{{ $t('widgets.apiKey') }}</div>
    <div class="theme-wrapper">
      <div class="inputWrapper constructorItem">
        <WidgetsConstructorItem
            :label="$t('widgets.apiKey')"
            :tooltip-title="$t('widgets.apiKey')"
            :is-required="true"
            :tooltip-text="$t('widgets.tooltip.apiKey')"
        >
          <WidgetsApiKeysSelect :value="widget.apiKeyId" @input="handleApiKeySelect" />
        </WidgetsConstructorItem>
      </div>
     </div>
  </div>
  <template v-if="widget.payinCrypto">
    <div v-if="widget.payinCryptoType === $options.PAYIN_CRYPTO_TYPE_INVOICE">
      <div class="title">{{ $t('widgets.invoices') }}</div>
      <div class="theme-wrapper">
        <div class="inputWrapper constructorItem">
          <WidgetsConstructorItem
              :label="'Webhook'"
              :tooltip-title="'Webhook'"
              :tooltip-text="$t('widgets.tooltip.webhook')"
              :is-required="true"
          >
            <base-input @input="handleEnterWebhook" :error="isPayinWebhookValid" :value="widget.payinCryptoWebhookURL" :placeholder="$t('widgets.enterWebhook')" />
          </WidgetsConstructorItem>
        </div>
        <div class="toggleWrapper constructorItem">
          <div class="toggleWrapper__toggle">
            <div class="toggleWrapper__title">
              {{ $t('widgets.freePayment') }}
              <WidgetsTooltip :text="$t('widgets.tooltip.freePayment')" :title="$t('widgets.freePayment')" />
            </div>
            <WidgetsToggle @input="handleFreePayment" :value="widget.payinCryptoInvoiceFree" />
          </div>
        </div>
        <div class="toggleWrapper constructorItem">
          <div class="toggleWrapper__toggle">
            <div class="toggleWrapper__title">
              {{ $t('widgets.commissionOnPayer') }}
              <WidgetsTooltip :text="$t('widgets.tooltip.commissionOnPayer')" :title="$t('widgets.commissionOnPayer')" />
            </div>
            <WidgetsToggle @input="handleComissionOnPayer" :value="widget.payinCryptoInvoiceCommissionOnPayer" />
          </div>
        </div>
        <div class="inputWrapper constructorItem">
          <WidgetsConstructorItem
              :label="$t('widgets.allowableUnderpayment')"
              :tooltip-title="$t('widgets.allowableUnderpayment')"
              :tooltip-text="$t('widgets.tooltip.underpayment')"
          >
            <base-input type="number" @input="handleTolerancePercent" :value="widget.payinCryptoInvoiceTolerancePercent" :placeholder="$t('widgets.enterAllowableUnderpayment')" />
          </WidgetsConstructorItem>
        </div>
        <div class="inputWrapper constructorItem">
          <WidgetsConstructorItem
              :label="$t('widgets.allowablePercent')"
              :tooltip-title="$t('widgets.allowablePercent')"
              :tooltip-text="$t('widgets.tooltip.allowablePercent')"
          >
            <base-input type="number" @input="handleInvoiceInsurancePercent" :value="widget.payinCryptoInvoiceInsurancePercent" :placeholder="$t('widgets.enterAllowablePercent')" />
          </WidgetsConstructorItem>
        </div>
        <div class="inputWrapper constructorItem">
          <WidgetsConstructorItem
              :isColumn="true"
              :label="$t('widgets.allowableSlippage')"
              :tooltip-title="$t('widgets.allowableSlippage')"
              :tooltip-text="$t('widgets.tooltip.allowableSlippage')"
          >
            <base-input type="number" @input="handleSlippagePercent" :value="widget.payinCryptoInvoiceSlippagePercent" :placeholder="`${$t('widgets.enterSlippagePercent')}`" />
          </WidgetsConstructorItem>
        </div>
        <div class="inputWrapper constructorItem">
          <WidgetsConstructorItem
              :is-required="!widget.payinCryptoInvoiceFree"
              :label="$t('widgets.invoiceCurrency')"
              :is-locked="widget.payinCryptoInvoiceFree"
              :locked-text="$t('widgets.lockedFreePayment')"
              :tooltip-title="$t('widgets.invoiceCurrency')"
              :tooltip-text="$t('widgets.tooltip.invoiceCurrency')"
          >
            <WidgetsMultiCurrencySelect @input="handlePayinInvoiceCurrencies" :is-fiat-enabled="true" :with-networks="false" :value="widget.payinCryptoInvoiceCurrencies" />
          </WidgetsConstructorItem>
        </div>
        <div class="inputWrapper constructorItem">
          <WidgetsConstructorItem
              :is-required="true"
              :label="$t('widgets.paymentCurrency')"
              :tooltip-title="$t('widgets.paymentCurrency')"
              :tooltip-text="$t('widgets.tooltip.paymentCurrency')"
          >
            <WidgetsMultiCurrencySelect @input="handlePayinCryptoCurrenciesTo" :value="widget.payinCryptoCurrenciesTo" :is-fiat="true" />
          </WidgetsConstructorItem>
        </div>
        <!--      <div class="inputWrapper constructorItem">-->
        <!--        <WidgetsConstructorItem-->
        <!--            :label="'Время жизни ордера'"-->
        <!--            :field-label="'&payinCryptoInvoiceLifetime'"-->
        <!--            :tooltip-text="'Необходимо вставить ссылку для вебхуков, которые мы будем отправлять при оплатах'"-->
        <!--        >-->
        <!--          <date-picker-->
        <!--              popup-class="new-order-modal__time-picker-popup"-->
        <!--              class="new-order-modal__time-picker"-->
        <!--              v-model="widget.payinCryptoInvoiceLifetime"-->
        <!--              :append-to-body="false"-->
        <!--              :minute-step="30"-->
        <!--              :hour-options="lifetimeRange"-->
        <!--              type="time"-->
        <!--              value-type="HH:mm"-->
        <!--              format="HH:mm"-->
        <!--              placeholder="Выберите время жизни инвойса"-->
        <!--              :showSecond="false"-->
        <!--          >-->
        <!--&lt;!&ndash;                          :disabled-time="minMaxValue" &ndash;&gt;-->
        <!--            <template v-slot:header>-->
        <!--              <div class="new-order-modal__label-container">-->
        <!--                <span class="new-order-modal__time-label">{{ $t('create-order-modal.hours') }}</span>-->
        <!--                <span class="new-order-modal__time-label new-order-modal__time-label_divider"></span>-->
        <!--                <span class="new-order-modal__time-label">{{ $t('create-order-modal.minutes') }}</span>-->
        <!--              </div>-->
        <!--            </template>-->
        <!--          </date-picker>-->
        <!--        </WidgetsConstructorItem>-->
        <!--      </div>-->
        <div class="inputWrapper constructorItem">
          <WidgetsConstructorEmail />
        </div>
      </div>
    </div>
    <div v-if="widget.payinCryptoType === $options.PAYIN_CRYPTO_TYPE_PERSONAL">
      <div class="title">{{ $t('widgets.personal') }}</div>
      <div class="theme-wrapper">
        <div class="inputWrapper constructorItem">
          <WidgetsConstructorItem
              :label="'Webhook'"
              :is-required="true"
              :tooltip-title="'Webhook'"
              :tooltip-text="$t('widgets.tooltip.webhook')"
          >
            <base-input @input="handleEnterWebhook" :error="isPayinWebhookValid" :value="widget.payinCryptoWebhookURL" :placeholder="$t('widgets.enterWebhook')" />
          </WidgetsConstructorItem>
        </div>
        <div class="inputWrapper constructorItem">
          <WidgetsConstructorItem
              :is-required="true"
              :label="$t('widgets.paymentCurrency')"
              :tooltip-title="$t('widgets.paymentCurrency')"
              :tooltip-text="$t('widgets.tooltip.paymentCurrency')"
          >
            <WidgetsMultiCurrencySelect @input="handlePayinCryptoCurrenciesTo" :value="widget.payinCryptoCurrenciesTo" />
          </WidgetsConstructorItem>
        </div>
        <!--      <div class="inputWrapper constructorItem">-->
        <!--        <WidgetsConstructorItem-->
        <!--            :label="'Иконка для акции'"-->
        <!--            :field-label="'&payinCryptoPersonalOfferIcon'"-->
        <!--            :tooltip-text="'Необходимо вставить ссылку для вебхуков, которые мы будем отправлять при оплатах'"-->
        <!--        >-->
        <!--          <WidgetsIconSelect v-model="widget.payinCryptoPersonalOfferIcon" />-->
        <!--        </WidgetsConstructorItem>-->
        <!--      </div>-->
      </div>
    </div>
  </template>
  <div v-if="widget.payoutCrypto">
    <div class="title">{{ $t('widgets.payout') }}</div>
    <div class="theme-wrapper">
      <div class="inputWrapper constructorItem">
        <WidgetsConstructorItem
            :is-required="true"
            :label="'Webhook'"
            :tooltip-title="'Webhook'"
            :tooltip-text="$t('widgets.tooltip.webhook')"
        >
          <base-input @input="handlePayoutCryptoWebhookURL" :error="isPayoutWebhookValid" :value="widget.payoutCryptoWebhookURL" :placeholder="$t('widgets.enterWebhook')" />
        </WidgetsConstructorItem>
        <WidgetsConstructorItem
            :is-required="true"
            :label="$t('widgets.approvalWebhook')"
            :tooltip-text="$t('widgets.tooltip.approvalWebhook')"
            :is-column="true"
        >
          <base-input :placeholder="$t('widgets.enterApprovalWebhook')" :error="isPayoutApprovalWebhookValid" @input="handlePayoutCryptoApprovalWebhookURL" :value="widget.payoutCryptoApprovalWebhookURL" />
        </WidgetsConstructorItem>
      </div>
      <div class="inputWrapper constructorItem">
        <WidgetsConstructorItem
            :is-required="true"
            :label="$t('widgets.payoutCurrency')"
            :tooltip-text="$t('widgets.tooltip.payoutCurrency')"
        >
          <WidgetsMultiCurrencySelect :with-networks="false" :is-fiat-enabled="true" @input="handlePayoutCryptoCurrenciesFrom" :value="widget.payoutCryptoCurrenciesFrom" />
        </WidgetsConstructorItem>
      </div>
      <div class="inputWrapper constructorItem">
        <WidgetsConstructorItem
            :is-required="true"
            :label="$t('widgets.paymentCurrency')"
            :tooltip-text="$t('widgets.tooltip.paymentCurrency')"
        >
          <WidgetsMultiCurrencySelect @input="handlePayoutCryptoCurrenciesTo" :value="widget.payoutCryptoCurrenciesTo" />
        </WidgetsConstructorItem>
      </div>
      <!--      <div class="inputWrapper constructorItem">-->
      <!--        <WidgetsConstructorItem-->
      <!--            :label="'Иконка для акции'"-->
      <!--            -->
      <!--            :tooltip-text="'Необходимо вставить ссылку для вебхуков, которые мы будем отправлять при оплатах'"-->
      <!--        >-->
      <!--          <WidgetsIconSelect v-model="widget.payinCryptoPersonalOfferIcon" />-->
      <!--        </WidgetsConstructorItem>-->
      <!--      </div>-->
    </div>
  </div>
  <div>
    <div class="title">{{ $t('widgets.theme') }}</div>
    <WidgetsThemeSelect @input="handleTheme" :value="widget.theme" />
  </div>
</div>
</template>

<style scoped lang="scss">
.constructor {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 30px 30px 70px 30px;
  @media (max-width: 850px) {
    padding: 10px 10px 40px 10px;
  }
  .toggleWrapper {
    &__title {
      color: var(--text-text-primary, #FFF);
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      display: flex;
      align-items: center;
      gap: 5px;
      @media (max-width: 850px) {
        font-size: 13px;
      }
    }
    &__toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .title {
    color: var(--text-text-primary, #FFF);
    font-size: 22px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 15px;
  }
  .theme {
    &-wrapper {
      border-radius: 10px;
      border: 1px solid var(--border-border-input-primary-default, #464646);
      background: var(--bg-background-input-primary, #2A2A2A);
      padding-top: 20px;
      @media (max-width: 850px) {
        padding-top: 15px;
      }
    }
  }
  .constructorItem {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px 20px;

    border-bottom: 1px solid var(--border-devider-primary);
    @media (max-width: 850px) {
      padding: 30px 10px;
    }
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
  .colors {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0 15px 15px 15px;
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 54px;
      height: 54px;
      border-radius: 8px;
      border: 1.5px solid rgba(255, 255, 255, 0.15);
      background: var(--Linear-illustration, linear-gradient(153deg, #744DB2 -33.83%, #8D4CF5 33.16%, #744DB2 96.25%));
      cursor: pointer;
      &.addBtn {
        background: var(--bg-background-input-secondary, #333);

      }
    }
  }
  .inputWrapper {
    &__labels {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
    }
    &__label {
      color: var(--text-color-text-secondary);
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      align-items: center;
      gap: 5px;
      &-left {
        font-weight: 400;
      }
    }
  }
}
::v-deep {
  .base-input__wrapper input {
    border-radius: 10px;
    border: 1px solid var(--border-border-input-primary-default, #464646);
    background: var(--bg-background-input-secondary, #333);
    padding: 0 15px 0 15px;
    color: var(--text-text-primary, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .new-bh-select-wrapper .input-wrapper .options-list .checkbox svg {
    path {
      fill: var(--bg-background-input-secondary);
    }
  }
  .new-bh-select-wrapper .input-wrapper .options-list .options-list-item .prepend-info {
    margin-right: 0;
  }
  .new-bh-select-wrapper .input-wrapper .input {
    border-radius: 10px;
    border: 1px solid var(--border-border-input-primary-default, #464646);
    background: var(--bg-background-input-secondary, #333);
    padding: 0 15px 0 15px;
    color: var(--text-text-primary, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .new-bh-select-wrapper .input-wrapper.expanded .options-list {
    border-radius: 10px;
    border: 1px solid var(--border-border-input-primary-default, #464646);
    background: var(--bg-background-input-secondary, #333);

    /* Shadow modals */
    box-shadow: 0px 0px 30px 0px rgba(20, 20, 20, 0.15);
  }
  .new-bh-select-wrapper .input-wrapper .options-list .options-list-item.bordered-options {
    border-radius: 10px;
    border: 1px solid var(--border-border-input-primary-default, #464646);
    min-height: 48px;
    padding: 8px 9px;
  }
  .copy-wrapper {
    margin: 0;
  }

  .new-bh-select-wrapper .input-wrapper .options-list .options-list-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 5px;
    padding: 0 0;
    flex-direction: column;
    font-size: 16px;
    color: var(--text-text-primary, #FFF);
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    .counter {
      color: var(--text-color-text-secondary, #7E7E7E);
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }
  }
  .new-bh-select-wrapper .input-wrapper .input .placeholder {
    color: var(--text-color-text-secondary, #7E7E7E);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}

::v-deep {
  .mx-time-item  {
    background: var(--bg-background-input-secondary) !important;
    color: var(--new-front-primary-font-color) !important;
    .active {
      background: var(--bg-background-primary-purple, #6750A4) !important;
      color: #ffffff !important;

    }
    &:hover {
      background: var(--new-front-select-item-bg-active) !important;
    }
  }
  .mx-input {
    border-radius: 10px;
    border: 1px solid var(--border-border-input-primary-default, #464646);
    background: var(--bg-background-input-secondary, #333);
    padding: 0 15px 0 15px;
    color: var(--text-text-primary, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    min-height: 48px;
  }
  .mx-time-columns {
    background: var(--bg-background-input-secondary);
  }
  .new-order-modal__time-picker-popup .mx-time-column .mx-time-list::after {
    height: 0 !important;
  }
  .mx-datepicker-main {
    border: 1px solid var(--border-border-input-primary-default);
  }
  .mx-datepicker-popup {
    margin-top: 10px;
  }
  .new-order-modal__time-picker-popup>.mx-datepicker-content {
    background: var(--bg-background-input-secondary) !important;
  }
}
</style>
