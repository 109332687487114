
export const mutations = {
    setWithdrawalRequestId(state, value) {
        state.withdrawalRequestId = value;
    },
    setWithdrawalPending(state, value) {
        state.withdrawal.pending = value;
    },
    setWithdrawalComplete(state, value) {
        state.withdrawal.complete = value;
    },
     setWithdrawalError(state, value) {
        state.withdrawal.error = value;
    },

    setFeeRequestId(state, value) {
        state.feeRequestId = value;
    },
    setFeePending(state, value) {
        state.fee.pending = value;
    },
    setFeeComplete(state, value) {
        state.fee.complete = value;
    },
    setFeeError(state, value) {
        state.fee.error = value;
    },

    setWithdrawal(state, payload) {
        state.withdrawal.id = payload.id || null;
        state.withdrawal.status = payload.status || null;
        state.withdrawal.address = payload.address || null;
        state.withdrawal.tx = payload.tx || null;
        state.withdrawal.tag = payload.tag || null;
        state.withdrawal.traceId = payload.traceId || null;
        state.withdrawal.addressId = payload.addressId || null;
        state.withdrawal.networkId = payload.networkId || null;
    },
    setFee(state, payload) {
        state.fee.code = payload.code ?? null;
        state.fee.currency = payload.currency || null;
        state.fee.amount = payload.amount || null;
        state.fee.amountToReceive = payload.amountToReceive || null;
        state.fee.usdAmount = payload.usdAmount || null;
        state.fee.source = payload.source || null;

        state.fee.until = payload.until || null;

        state.fee.token = payload.token || null;
        state.fee.tariffFee = payload.tariffFee || null;
        state.fee.tariffFeeUSD = payload.tariffFeeUSD || null;
        state.fee.networkFee = payload.networkFee || null;
        state.fee.networkFeeUSD = payload.networkFeeUSD || null;
    }
};
