import gql from 'graphql-tag';

export default gql`
    query {
        getOrganizations {
            id
            name
            roleName
            isOwner
            logoUrl
            isTest
            collectingMinimumAmount
            collectingInterval
            nextCollectingAt
        }
    }
`;
