<template>
    <div class="dashboard-transaction" v-if="hasDeposit">
        <div class="dashboard-transaction__title">
            {{ title }}
        </div>
        <div class="list-header row">
            <div class="list-header__item col">
                id
            </div>
            <div class="list-header__item col">
                {{ $t('withdrawal.amount') }}
            </div>
            <div class="list-header__item col">
                {{ $t('withdrawal.currency') }}
            </div>
            <div class="list-header__item list-header__item--sorting col">
                {{ $t('transactions.date_of_creation_order') }}
            </div>
            <div class="list-header__item col col-1"></div>
        </div>
        <dashboard-deposit-row
            v-for="(deposit, key) in deposit"
            :key="key"
            :data="deposit"
        />
    </div>
</template>

<script>

import {mapState} from "vuex";
import DashboardDepositRow from "./DashboardDepositRow";

export default {
    name: 'DashboardDeposit',
    components: {
        DashboardDepositRow,
    },
    props: {
        title: {
            type: String,
            default: 'Выполненные ордера',
        },
    },
    computed: {
        ...mapState('dashboard', [
            'deposit',
        ]),
        hasDeposit () {
            return Boolean(this.deposit.length);
        },
    },
    methods: {
    },
};

</script>

<style lang="scss" scoped>
.dashboard-transaction {
    border: 1px solid $gray2;
    box-shadow: 2px 2px 4px 0 $gray2 ;
    border-radius: 1em;
    padding: 1em;
    margin-top: 1em;
    margin-bottom: 1em;

    &__title {
        @include h3;
        margin-bottom: .5em;
    }
}


</style>