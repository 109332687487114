import gql from 'graphql-tag';

export default gql`
query (
        $organizationId: String!,
        $name: String!
) {
    getUserInterfaceSettings (
        organizationId: $organizationId,
        name: $name
) {
        name
        entities
    }
}
`
