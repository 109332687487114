<template>
    <div>
        <preloader v-if="!isInit"/>
        <div class="history-addresses" v-else>
            <div class="history-addresses__head-wrapper">
                <back-button :action="goBack" />
            </div>
            <div class="history-addresses__container">
              <HistoryAddressesTopBar
                class="history-addresses__top-bar"
                :filters.sync="filters"
                @update="getAddressesList"
              />

              <new-spinner v-if="isLoading" />
              <template v-else>
                <HistoryAddress
                  :filters="filters"
                />
              </template>

              <pagination
                size="small"
                :total="count"
                :page-size="pagesCount"
                :current="pagination.page"
                @page-change="getAddressesList"
              />
            </div>
        </div>
        <transition name="slide-fade">
          <history-transaction-info v-if="infoState" />
        </transition>
    </div>
</template>

<script>
import Preloader from "/public/components/common/uiKit/spinner/index.vue";
import HistoryAddress from "../components/elements/history-addresses/HistoryAddress";
import TopBarHistory from "../components/elements/history-addresses/TopBarHistory";
import {mapActions, mapState} from "vuex";
import OrdersTopBar from "@/public/components/elements/orders/OrdersTopBar.vue";
import Pagination from "@/public/components/common/uiKit/Pagination.vue";
import HistoryTransactionInfo from "@/public/components/elements/history-addresses/HistoryTransactionInfo.vue";

export default {
    name: "historyAddresses",
    title: 'historyAddresses.title_main',
    data() {
      return {
        sortValue: null,
        isEmptyFilters: false,
        pagination: {
          page: 1
        },
        filters: {
          sort: 'DESC'
        }
      }
    },
    components: {
      HistoryTransactionInfo,
        Preloader,
        HistoryAddress,
        TopBarHistory,
        Pagination,
        BackButton: () => import(/* webpackChunkName: "/public/components/elements/analytics/BackButton" */ '/public/components/elements/analytics/BackButton'),
        HistoryAddressesTopBar: () => import(/* webpackChunkName: "/public/components/elements/history-addresses/TopBarHistoryNew" */ '/public/components/elements/history-addresses/TopBarHistoryNew'),
        NewSpinner: () => import (/* webpackChunkName: "public/components/common/uiKit/spinner" */ "/public/components/common/uiKit/spinner/index.vue")
    },

    watch: {
      filters: {
        handler() {
          this.getAddressesList()
        },
        deep: true
      },
      lastUpdateAddress: {
        handler() {
          this.getAddressesList()
        },
        deep: true
      }
    },

    computed: {
        ...mapState('balances', ['lastUpdateAddress']),
        ...mapState('app', ['isLoading', 'isInit', 'networks', 'currencies']),
        ...mapState('historyAddresses', ['pages', 'count', 'pagesCount', 'type', 'infoState'])
    },

    mounted() {
        const pageFromUrl = this.$route.query.page
        if (pageFromUrl) {
          this.pagination.page = pageFromUrl
        }

        const {
            $route,
            isInit
        } = this;

        if (isInit) {
          this.getAddressesList()
        }


        this.currentCurrency($route.query.networks);
    },

    methods: {
        ...mapActions('historyAddresses', [
            'addressTransactions',
        ]),
        getAddressesList(page = 1) {
          this.pagination.page = page

          const {
            $route,
            $router,
            addressTransactions,
            filters
          } = this;

          const payload = {
            sortDate: filters.sort,
            route: $route,
            router: $router,
            addressType: ['BUSINESS'],
            page: this.pagination.page
          }

          if (filters.search) {
            payload.search = filters.search
          }
          if (filters.networks) {
            payload.networks = filters.networks.map(({ alias }) => alias)
          }
          if (filters.currencies) {
            payload.currencyId = filters.currencies.map(({ id }) => id)
          }
          if (filters.type && filters.type.length) {
            payload.type = filters.type
          }
          if (filters.status && filters.status.length) {
            payload.status = filters.status[0].value
          }
          if (filters.date && filters.date.length) {
            payload.date = filters.date
          }

          if (filters.riskLevel) {
            payload.riskLevel = filters.riskLevel.value
          }

          addressTransactions(payload);
        },
        currentCurrency(values) {
            if (!values) return;
            const currenciesCurrentNetwork = Array.from(new Set(this.currencies.filter((item) => {
                let status = null;
                item.networks.forEach(network => {
                    if (network.alias === values) {
                        status = true;
                    }
                });
                return status;
            })));

            this.$store.commit('historyAddresses/currentNetwork', currenciesCurrentNetwork);
        },

        goBack() {
            if (window.history.length > 2) this.$router.go(-1);
            else this.$router.push({name: 'historyAddresses'});
        },
    },
};
</script>

<style scoped lang="scss">
.history-addresses {
  max-width: 1545px;
  padding: 0 30px 30px 30px;

  font-family: var(--new-front-font-family);

  @media screen and (max-width: 576px) {
    padding: 0 10px 10px 10px;
  }

  &__container {
    background: var(--new-front-bg-secondary);
    border-radius: 20px;
    box-shadow: 0 8px 50px rgba(10, 17, 105, 0.08);

    padding: 30px 0 10px;

    .history-addresses__top-bar {
      padding: 0 30px;
    }
  }

  &__title {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-h1);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    width: 100%;
  }

  &__head-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    margin: 30px 0 20px 0;
  }
}

@media screen and (max-width: 950px) {
  .history-addresses {

    &__head-title {
      display: none;
    }

    &__head-wrapper {
      flex-direction: unset;
      padding-left: 15px;
      margin-bottom: 20px;
    }

    &__title {
      font-size: 20px;
      line-height: 23px;
      margin: 0;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &__container {
      .history-addresses__top-bar {
        padding: 0 30px;
      }
    }
  }
}
</style>
