import GetAccountBalancesQuery from "/public/graphQl/queries/getAccountBalances";

export const getAccountBalances = async ({commit, dispatch}, payload) => {
    let balancesData;
    commit('app/toggleLoading', true, {root: true});
    try {
        balancesData = await dispatch('app/request', {
            type: 'query',
            gql: GetAccountBalancesQuery,
            variables: payload,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    commit('accountBalances', balancesData.data.getAccountBalances);

    return balancesData;
};
