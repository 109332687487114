import gql from 'graphql-tag';

export default gql`
    mutation($registryId: String!) {
        saveRegistry( registryId: $registryId) {
            id
            title
            status
            createdAt
            updatedAt
        }
    }
`;