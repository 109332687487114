<template>
<StoreModal
    class="create-business-modal create-new-api-keys-modal"
    name="AddBusinessAddress"
    @closeModal="handleCancelCreate"
>
    <template v-if="!addressCreated" #header>
        <div class="create-business-modal__header">
            <div class="title">
                {{ title }}
            </div>
<!--            <div v-if="notCreated" class="subtitle">-->
<!--                {{ subtitle }}-->
<!--            </div>-->
        </div>
    </template>
    <template  #body>
        <div v-if="!isLoading && !addressCreated" class="create-business-modal__body">
            <div v-if="step === $options.ADDRESS_STEP" class="create-business-modal__padding-wrapper">
                <div class="business-modal__select-container">
                    <business-wallet-network-select
                      :value="networksValue"
                      :list="filteredNetworks"
                      :label="$t('orders.network')"
                      :placeholder="networkSelectPlaceholder"
                      uniq-key="id"
                      @input="handleSelectNetwork"
                    />
                </div>
                <div class="business-modal__select-container">
                    <business-wallet-network-select
                      v-model="currencyValue"
                      :list="filteredCurrencies"
                      :label="$t('business-wallet.currency')"
                      :placeholder="currencySelectPlaceholder"
                      uniq-key="id"
                    />
                </div>
            </div>
            <template v-if="step === $options.INFO_STEP">
                    <p class="business-modal__label create-business-modal__padding-wrapper">{{ $t('business-wallet.currency-and-select') }}</p>
                    <div class="business-modal__asset create-business-modal__padding-wrapper">
                        <currency-network-icon :currency="currencyValue.currency" :network="networksValue.name.toUpperCase()" />
                        <div class="business-modal__asset-currency">
                            {{ currencyValue.alias }} |
                        </div>
                        <div class="business-modal__asset-network">
                            {{ networksValue.alias }}
                        </div>
                    </div>
                <div class="business-modal__inputs create-business-modal__padding-wrapper">
                    <div class="business-modal__row">
                        <div>
                            <base-input
                                v-model="alias"
                                class="amount-input"
                                :placeholder="$t('business-wallet.enter-alias')"
                                :max-length="16"
                                :label="$t('business-wallet.pseudo')"
                            >
                                <template #label-left>
                                    {{ $t('business-wallet.add-modal.alias-placeholder') }}
                                </template>
                            </base-input>
                        </div>
                    </div>
                    <div class="business-modal__row">
<!--                        <div class="business-modal__label">-->
<!--                            {{ $t('business-wallet.comment') }}-->
<!--                        </div>-->
                        <div>
<!--                            <AtTextarea-->
<!--                                v-model="comment"-->
<!--                                :placeholder="$t('business-wallet.add-modal.comment-placeholder')"-->
<!--                                :maxlength="255"-->
<!--                                size="large"-->
<!--                                resize="none"-->
<!--                            />-->
                            <base-textarea
                                v-model="comment"
                                :placeholder="$t('business-wallet.add-modal.comment-placeholder')"
                                :label="$t('business-wallet.comment')"
                                :max-length="255"
                                resize="none"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div v-else-if="!isLoading && addressCreated">
            <div class="complete__img">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_9739_70606)">
                        <rect width="60" height="60" rx="10" fill="#5CA450"/>
                        <path d="M36.3105 23.5605C36.9329 22.9382 37.942 22.9382 38.5644 23.5605C39.1791 24.1753 39.1867 25.1674 38.587 25.7914L30.1036 36.3957C30.0913 36.411 30.0782 36.4256 30.0644 36.4395C29.442 37.0619 28.4329 37.0619 27.8105 36.4395L22.1868 30.8158C21.5644 30.1934 21.5644 29.1842 22.1868 28.5618C22.8092 27.9395 23.8183 27.9395 24.4407 28.5618L28.8894 33.0106L36.2682 23.6082C36.2813 23.5915 36.2954 23.5756 36.3105 23.5605Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_9739_70606">
                            <rect width="60" height="60" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <p class="complete__title">{{
                $t('business-wallet.added-address-title')
                }}</p>
            <div class="complete__qr-code-wrapper">
              <QrCode :string="addressInfo.address" />
            </div>
            <p class="complete__main-text">
                {{
                $t('business-wallet.added-address-desc', {addressString: addressString})
                }}
            </p>
            <div class="complete__text-container">
                <p class="complete__main-text complete__main-text--center">
                   {{ $t('business-wallet.added-address-subtitle') }}:
                </p>
                <span class="copyAddressHash">
                    {{ addressHash }}
                    <copy class="copyAddressHash--copy" :value="addressHash" />
                </span>
                <span class="copyAddressHash__mobile">
                    {{ addressHash | preparedString(4) }}
                    <copy class="copyAddressHash--copy--mobile" :value="addressHash" />
                </span>
<!--                <CopyString class="copyAddressHash" :string="addressHash" :hash="false" :successMessage="$t('copy.addresses_copied')" can-copy />-->
<!--                <CopyString class="copyAddressHash__mobile" :string="addressHash" :hash="true" :successMessage="$t('copy.addresses_copied')" can-copy />-->

                <!--                        <CopyString-->
                <!--                            :string="transactionHash"-->
                <!--                            canCopy-->
                <!--                            :successMessage="$t('copy.transaction_hash')"-->
                <!--                            :failMessage="$t('copy.transaction_hash_failed')"-->
                <!--                            :title="$t('copy.notification')"-->
                <!--                        />-->
<!--                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M14 0C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2C0 0.895431 0.895431 0 2 0H14ZM5.9038 10.8033L10 6.7071L10 9.47487C10 9.75101 10.2239 9.97487 10.5 9.97487C10.7761 9.97487 11 9.75101 11 9.47487V5.49999C11 5.22385 10.7761 4.99999 10.5 4.99999L6.52517 4.99999C6.24902 4.99999 6.02516 5.22385 6.02516 5.49999C6.02516 5.77614 6.24902 5.99999 6.52517 5.99999L9.29289 5.99999L5.1967 10.0962C5.00144 10.2914 5.00144 10.608 5.1967 10.8033C5.39196 10.9986 5.70854 10.9986 5.9038 10.8033Z" fill="#6750A4"/>-->
<!--                </svg>-->

            </div>
            <!--                        <div class="complete__row">-->
            <!--&lt;!&ndash;                            <div class="complete__text-container">&ndash;&gt;-->
            <!--&lt;!&ndash;                                <p class="complete__main-text">ID: </p>&ndash;&gt;-->
            <!--&lt;!&ndash;                                <p class="complete__main-text">{{ _withdrawalId }}</p>&ndash;&gt;-->
            <!--&lt;!&ndash;                            </div>&ndash;&gt;-->

            <!--                        </div>-->
        </div>
        <LoadingSpinner
            v-if="isLoading"
            :title="creating_an_api_keys"
        />

    </template>


  <template  v-if="!isLoading" #footer>
    <div v-if="!addressHash" class="create-business-modal__footer">
      <base-button
        :label="$t('business-wallet.add-modal.cancel')"
        type="secondary"
        @click="handleCancelCreate"
      />
      <base-button
        :label="createButtonTitle"
        type="primary"
        :disabled="disabledSaveButton"
        @click="handleCreateClick"
      />
    </div>
      <div v-else>
        <base-button
          :label="$t('business-wallet.close')"
          type="primary"
          :disabled="disabledSaveButton"
          @click="handleCancelCreate"
        />
      </div>
  </template>

</StoreModal>
</template>

<script>

import {mapGetters, mapActions, mapState} from 'vuex';

import copyToClipboard from "/public/mixins/copyToClipboard";

import AtModal from 'at-ui/src/components/modal';
import AtInput from 'at-ui/src/components/input';
import LoadingSpinner from "/public/components/elements/LoadingSpinner";
import AtButton from 'at-ui/src/components/button';
import AtAlert from 'at-ui/src/components/alert';
import BaseModal from "../BaseModal";
import createBusinessAddress from "../../../graphQl/mutations/createBusinessAddress";
import updateBusinessAddress from "../../../graphQl/mutations/updateBusinessAddress";
import CopyString from "../../elements/CopyString";
import CurrencyImg from "../../elements/CurrencyImg";
import BusinessWalletNetworkSelect from "@/public/components/elements/business-walet/Select/businessWalletNetworkSelect.vue";
import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
import BaseInput from "@/public/components/common/uiKit/input/base/index.vue";
import BaseTextarea from "@/public/components/common/uiKit/textarea/index.vue";
import CurrencyNetworkIcon from "@/public/components/elements/business-walet/Select/CurrencyNetworkIcon.vue";
import Copy from "@/public/components/common/copy.vue";

const ADDRESS_STEP = 0;
const INFO_STEP = 1;

export default {
    name: 'AddBusinessAddress',
    components: {
        Copy,
        CurrencyNetworkIcon,
        BaseTextarea,
        BaseInput,
        BaseButton,
        BusinessWalletNetworkSelect,
        CurrencyImg,
        CopyString,
        BaseModal,
        AtModal,
        AtInput,
        LoadingSpinner,
        AtButton,
        AtAlert,
        StoreModal: () => import(/* webpackChunkName: "/public/components/modals/StoreModal" */ '/public/components/modals/StoreModal'),
        QrCode: () => import(/* webpackChunkName: "/public/components/common/uiKit/qr-code" */ '/public/components/common/uiKit/qr-code'),
    },
    mixins: [copyToClipboard],
    ADDRESS_STEP,
    INFO_STEP,
    data() {
        return {
            isLoading: false,
            alias: '',
            step: ADDRESS_STEP,
            networksValue: null,
            currencyValue: null,
            addressInfo: {},
            comment: '',
            addressCreated: false,
            addressHash: '',
        };
    },
    computed: {
        ...mapGetters('app', [
            'isOpenModal',
        ]),
        ...mapState('app', [
            'networks',
            'currencies',
            'selectedOrganizationId',
        ]),
        ...mapState('accounts', ['accounts']),
        ...mapGetters('app', [
            'getCurrencyNameById',
        ]),
        ...mapGetters('organizations', ['userRoleRules']),
        isActionsAllowed() {
            return this.userRoleRules['wallets_business'].entities.manage_business_address;
        },
        currencySelectPlaceholder () {
          return this.$t('orders.select_currencies')
        },
        networkSelectPlaceholder () {
            return this.$t('orders.select_network')
        },
        filteredNetworks() {
            const {
                currencies,
            } = this;

            const networks = {};

            for(const currency of currencies) {
              if(!currency.allowBusiness) {
                continue;
              }

              for(const network of currency.networks) {
                if(!network.allowDeposit) {
                  continue;
                }

                networks[network.id] = network;
              }
            }

            return Object.values(networks);
        },
        filteredCurrencies () {
            const {
                currencies,
            } = this;
            let sortedCurrencies = currencies.filter(({allowBusiness, isFiat}) => allowBusiness && !isFiat);

            if (this.networksValue) {
                sortedCurrencies = sortedCurrencies.filter((i) => {
                    return i.networks.find(({id}) => id === this.networksValue.id);
                });
                return sortedCurrencies;
            }

            return sortedCurrencies;
        },
        isOpen() {
            const {
                $options: {
                    name,
                },
                isOpenModal,
            } = this;

            return isOpenModal(name);
        },
        title() {
            return this.$t('business-wallet.add-modal.title');
        },
        currenciesForDropdown () {
            return this.currencies.filter(({ allowBusiness }) => allowBusiness);
        },
        createButtonTitle () {
            return  this.step === ADDRESS_STEP ? this.$t('business-wallet.add-modal.continue') : this.$t('business-wallet.add-modal.confirm');
        },
        be_sure_to_keep() {
            return this.$t('business-wallet.add-modal.creating');
        },
        creating_an_api_keys() {
            return this.$t('business-wallet.add-modal.creating');
        },
        disabledSaveButton () {
            return !this.currencyValue || !this.networksValue
        },
        addressString () {
            if (!this.networksValue && !this.currencyValue) return;
            return this.currencyValue.currency + ' ' + this.networksValue.alias
        }

    },
    methods: {
        ...mapActions('app', [
            'closeModal',
            'request',
        ]),
        ...mapActions('user', [
            'createApiKeys',
            'fetchApiKeys',
        ]),
        ...mapActions('balances', ['getAccountBalancesBusiness']),
        handleSelectNetwork(value) {
            this.networksValue = {
                ...value,
                active: true
            };
            this.currencyValue = '';
        },
        handleSelectCurrency(value) {
            this.currencyValue = value.id;
        },
        clearAllData() {
            this.isLoading = false;
            this.alias = '';
            this.step = ADDRESS_STEP;
            this.networksValue = null;
            this.currencyValue = null;
            this.addressInfo = {};
            this.addressHash = ''
            this.addressCreated = false
            this. comment = '';
        },
        async handleCreateRequest() {
            const {
                networksValue,
                currencyValue,
            } = this;

            const variables = {
                organizationId: this.selectedOrganizationId,
                accountId: this.accounts[0].accountId,
                currencyId: currencyValue.id,
                networkId: networksValue.id,
            };
            this.isLoading = true;
            const {data: { createBusinessAddress: res}} = await this.request({
                type: 'mutation',
                gql: createBusinessAddress,
                variables,
            });
            if ( res.code === 0 ) {
                await this.getAccountBalancesBusiness({organizationId: this.selectedOrganizationId, accountId: this.accounts[0].accountId, includeArchived: true});
                this.isLoading = false;
                this.addressInfo = res.result;
                if (!this.isActionsAllowed) {
                    // this.handleCancelCreate()
                    this.addressHash = res.result.address
                    this.addressCreated = true
                    return
                }
                this.step = INFO_STEP;
                this.addressInfo = res.result;
            }
            this.isLoading = false;
        },
        async handleUpdateRequest() {
            const {
                networksValue,
                currencyValue,
                alias,
                comment,
                addressInfo,
                selectedOrganizationId: organizationId,
            } = this;

            const variables = {
                organizationId,
                addressId: addressInfo.id,
                accountId: this.accounts[0].accountId,
                currencyId: currencyValue,
                networkId: networksValue,
                comment,
                alias,
            };
            this.isLoading = true;
            const {data: { updateBusinessAddress: res}} = await this.request({
                type: 'mutation',
                gql: updateBusinessAddress,
                variables,
            });
            if ( res.code === 0 ) {
                await this.getAccountBalancesBusiness({organizationId: this.selectedOrganizationId, accountId: this.accounts[0].accountId, includeArchived: true});
                this.isLoading = false;
                this.addressCreated = true
                this.addressHash = res.result.address
                this.$Message.success({
                    message: this.$t('business-wallet.success'),
                    duration: 10000,
                });
            }
            this.isLoading = false;
        },
        handleCreateClick () {
            if (this.step === ADDRESS_STEP) {
                this.handleCreateRequest();
                return;
            }
            this.handleUpdateRequest();
        },
        copyPublicKey() {
            const {
                publicKey,
                copyToClipboard,
            } = this;

            copyToClipboard(publicKey, true, {
                title: this.$t('copy.notification'),
                successMessage: this.$t('copy.the_public_key_copied'),
                failMessage: this.$t('copy.the_public_key_not_copied'),
            });
        },
        copySecretKey() {
            const {
                secretKey,
                copyToClipboard,
            } = this;

            copyToClipboard(secretKey, true, {
                title: this.$t('copy.notification'),
                successMessage: this.$t('copy.the_private_key_copied'),
                failMessage: this.$t('copy.the_private_key_not_copied'),
            });
        },
        handleCancelCreate() {
            const {
                clearAllData,
                closeModal,
                fetchApiKeys,
                hasApiKeys,
            } = this;
            closeModal();
            if (hasApiKeys) {
                fetchApiKeys();
            }
            setTimeout(() => {
                clearAllData();
            }, 500);
        },
    },
};

</script>

<style lang="scss" scoped>
.complete {
  &__qr-code-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 20px 0;
  }
  &__title {
    font-family: $Gilroy;
    font-size: $h1;
    font-weight: 500;
    color: var(--new-front-primary-font-color);
    text-align: center;
  }
  &__img {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  &__row {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    border: 1px solid var(--border-color);
    border-radius: 12px;
    padding: 20px;
    align-items: center;

  }

  &__text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  &__link {
    color: #6750A4;
  }
  &__main-text {
    font-family: $Gilroy;
    font-size: $h4;
    color: var(--new-front-secondary-font-color);
    text-align: center;
    line-height: 18px;
    margin-right: 5px;
  }
}
.button {
  ::v-deep .at-btn__text {

    font-family: var(--new-front-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

  }
}
.create-business-modal {
    font-family: var(--new-front-font-family);
  &__padding-wrapper {
    padding: 0 $p30;
  }
  &__header {
    text-align: center;

    .title {
      font-size: $h1;
      font-weight: 500;
      color: var(--new-front-primary-font-color);
    }
    .subtitle {
      font-size: $h4;
      color: var(--secondary-font-color);
    }
  }
    &__body {
        padding: 0 0 $p30 0;
    }
    &__footer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
    &::v-deep {
        .list {
            max-height: 290px !important;
        }
    }
}
.create-new-api-keys-modal {

  ::v-deep .at-select {
    background: var(--secondary-color);
    box-sizing: border-box;


  }

  ::v-deep .at-select__arrow {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  ::v-deep .at-select__clear {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  ::v-deep .at-select__selection {
    border-radius: 12px;
    min-height: 46px;
    display: flex;
    padding-left: 30px;
    align-items: center;
    box-shadow: inset 0 4px 20px rgba(0, 0, 0, 0.03);
    flex-wrap: wrap;
    border: 1px solid var(--border-color);
    background: var(--secondary-color);
    color: var(--primary-font-color);
  }

  &::v-deep .at-select__dropdown {
    border: 1px solid var(--border-color);
    background: var(--bg-select);
  }

  ::v-deep .at-select__option--selected {
    background: var(--hover-color-select);
  }

  ::v-deep .at-select__dropdown .at-select__option {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    padding: 20px;
    display: flex;
    align-items: center;
    &:hover {
      background: var(--hover-color-select);
    }
  }

  ::v-deep .at-select__placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }

  ::v-deep .at-select__selection {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    padding-left: 30px;
  }

  ::v-deep .at-modal {
    max-width: 764px;
    border-radius: 20px;
  }

  ::v-deep .at-input__original {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);

    &::placeholder {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
    }
  }

  ::v-deep .at-modal__body {
    padding: 30px 50px;
    @media (max-width: 950px) {
      padding: 20px 10px;
    }
  }

  ::v-deep .at-modal__header {
    display: flex;
    align-items: center;
    min-height: 107px;
    border-radius: 20px 20px 0 0;
    background: var(--modal-header-bg);
    border-bottom: 1px solid var(--border-color);
  }

  ::v-deep .at-modal__header p, .at-modal__header .at-modal__title {
    font-family: var(--new-front-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-h2);
    line-height: var(--line-height);
    max-width: 100%;
  }

  ::v-deep .col {
    width: 100%;

    font-family: var(--new-front-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--primary-font-color);
  }

  ::v-deep .at-input__original {
    background: var(--secondary-color);
    border: 1px solid var(--border-color);
    box-sizing: border-box;
    box-shadow: inset 0 4px 20px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
  }

  ::v-deep .at-modal__footer {
    border: none;
    min-height: 106px;
    display: flex;
    //justify-content: space-between;
    gap: 15px;
    @media (max-width: 950px) {
      flex-direction: column-reverse;
    }
  }

  ::v-deep .at-btn {
    width: 100%;
    height: 46px;
    max-width: 315px;
    border-radius: 12px;
    @media (max-width: 950px) {
      max-width: 100%;
    }
  }

  ::v-deep .at-btn.create-new-api-keys-modal__api-keys-copy-value {
    width: 24px;
    height: 24px;
    border: none;
  }

  ::v-deep .at-btn__icon.icon.icon-copy {
    font-size: 20px;
    color: var(--primary-font-color);
    line-height: 0;
  }

  ::v-deep .at-btn--primary {
    background: #0059F7;
  }

  ::v-deep .at-btn--default {
    border: 1px solid var(--border-color-modal);
  }

  ::v-deep .at-modal__close {
    font-size: 24px;
    color: #8F949A;
    top: 35px;
    right: 35px;
  }


  &__row {
    display: flex;
    align-items: center;

    flex-direction: column;
    gap: 11px;
  }

  &__title {
    font-weight: 500;
  }

  &__sub-title {
    margin-bottom: 6px;
    @include secondaryText;
    font-weight: 700;
    color: var(--primary-font-color);
  }

  &__api-keys-item {
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
  }

  &__api-keys-value {
    display: flex;
    align-items: center;
    width: 100%;
    word-break: break-word;
  }

  &__api-keys-value-input {
    width: 100%;
  }

  &__api-keys-copy-value {
    margin-left: 8px;
    width: 24px;
    height: 24px;
  }

  &__full-width-btn {
    width: 100%;
  }
}

.business-modal {
  &__select {
    &-container {
      margin-bottom: 20px;
    }
  }
  &__label {
    font-family: var(--font-family);
    font-size: $h6;
    line-height: var(--line-height);
    color: var(--new-front-secondary-font-color);
  }
  &__currency {
    &-ticker {
      color: var(--secondary-font-color);
      margin-left: 5px;
    }
  }
    &__asset {
        display: flex;
        align-items: center;
        margin-top: 10px;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--new-front-border-color);
        &-currency {
            font-weight: 500;
            font-size: $h3;
            color: var(--new-front-primary-font-color);
            margin: 0 10px;
        }
        &-network {
            font-weight: 500;
            font-size: $h3;
            color: var(--new-front-secondary-font-color);
        }
    }
  &__currency {
    height: 34px;
    width: 34px;
    margin-right: 10px;
    @media (max-width: 576px) {
      width: 24px;
      height: 24px;
    }
    &-option {
      margin-right: 15px;
      width: 30px;
      height: 30px;
    }
  }
  &__inputs {
    margin-top: 20px;
  }
  &__row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
::v-deep .at-modal__body {
  padding: 0 50px;
}
::v-deep .at-textarea__original {
  background: var(--secondary-color);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  box-shadow: inset 0 4px 20px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  min-height: 88px;
  font-family: var(--font-family);
  font-size: var(--font-size-text-main);
  line-height: var(--line-height);
  color: var(--primary-font-color);
}
::v-deep .icon  {
  .currency-network-icon__network {
    width: 22px !important;
    height: 22px !important;

    border: none !important;
    position: unset !important;

    top: unset !important;
    left: unset !important;

    transform: unset !important;
  }
}
@media (max-width: 575px) {
  .create-business-modal__header {
    .title {
      font-size: 20px;
    }
  }
  .create-business-modal__padding-wrapper {
    padding: 0 20px;
  }
  .create-business-modal__footer {
    display: flex;
    flex-direction: column-reverse;
    grid-gap: 10px;
  }
  .create-business-modal__body {
    padding: 0;
  }
  .business-modal__asset-network {
    font-size: 13px;
  }
  .business-modal__asset-currency {
    font-size: 13px;
  }
  .business-modal__asset {
    padding-bottom: 10px;
  }
}
.copyAddressHash {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: var(--new-front-primary-font-color);
  margin-left: 5px;
  font-weight: 500;
  font-size: $h4;
}
.copyAddressHash--copy {
  position: absolute;
  right: -35px;
}
.copyAddressHash--copy--mobile {
  position: absolute;
  top: -1px;
  right: -35px;
  &::v-deep {
    svg {
      position: absolute;
      top: 4px;
      right: 0;
    }
    svg:not(.clipboard-icon) {
      position: absolute;
      top: -4px;
      right: 0;
    }
  }
}
.copyAddressHash__mobile {
  color: var(--new-front-primary-font-color);
  display: none;
}
@media (max-width: 500px) {
  .copyAddressHash {
    display: none;
  }
  .copyAddressHash__mobile {
    display: unset;
    position: relative;
  }
  .complete__title {
    font-size: $h2;
  }
}
::v-deep .copy-string__string {
  font-family: $Gilroy;
  color: var(--new-front-primary-font-color);
}
</style>
