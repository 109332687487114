import gql from 'graphql-tag';

export default gql`
    mutation createInvoice(
        $organizationId: String!,
        $accountId: String!, 
        $itemId: String!, 
        $currencyId: String!,
        $itemDescription: String,  
        $amount: String!,
        $includeFee: Boolean!,
        $insurancePercent: String!,
        $slippagePercent: String!,
        $currencies: [[String!]!]!
        $additionalFees: [String!]
    ) {
        createInvoice(
            organizationId: $organizationId,
            accountId: $accountId, 
            itemId: $itemId,
            itemDescription: $itemDescription, 
            currencyId: $currencyId, 
            amount: $amount,
            includeFee: $includeFee,
            insurancePercent: $insurancePercent,
            currencies: $currencies,
            slippagePercent: $slippagePercent,
            additionalFees: $additionalFees
        ) {
            success
            code
            result {
                id
                externalId
                accountId
                apiKeyId
                invoiceLink
                orderId
                orderLink
                status
                itemId
                itemDescription
                currencyId
                currencyName
                amount
                amountUSD
                receivedCurrency
                receivedCurrencyName
                receivedAmount
                receivedAmountUSD
                includeFee
                insurancePercent
                slippagePercent
                webhookURL
                returnURL
                applyAt
                processedAt
                expiresAt
                createdAt
                currencies {
                    id
                    currency
                    orderIndex
                    allowDeposit
                    networks {
                        id
                        name
                        alias
                        underMaintenance
                        isDefault
                        allowDeposit
                    }
                }
            }
        }
    }
`;
