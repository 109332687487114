import {fetchWithdrawalRequests} from './actions/fetchWithdrawalRequests';
import {createWithdrawalRequest} from "./actions/createWithdrawalRequest";
import {fetchWithdrawalRequest} from "./actions/fetchWithdrawalRequest";
import {fetchNetworkFee} from "./actions/fetchNetworkFee";
import {fetchNetworkFees} from "./actions/fetchNetworkFees";
import {requestWithdrawal} from "./actions/createWithdrawalRequestNew";
import {downloadXLSX} from "./actions/downloadXLSX";
import {downloadCSV} from "./actions/downloadCSV";

export const actions = {
    fetchWithdrawalRequests,
    createWithdrawalRequest,
    fetchWithdrawalRequest,
    fetchNetworkFee,
    fetchNetworkFees,
    requestWithdrawal,
    downloadCSV,
    downloadXLSX,
};