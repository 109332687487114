import gql from 'graphql-tag';

export default gql`
    mutation createOrganization($name: String!, $testnet: Boolean) {
        createOrganization(name: $name, testnet: $testnet) {
            code
            result
        }
    }
`;
