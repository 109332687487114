import gql from 'graphql-tag';

export default gql`
    mutation($organizationId: String!, $accountId: String!, $clientId: String, $addressFromId: String!, $addressToId: String!, $token: String!, $key: String!) {
        createCrosschainSwap(organizationId: $organizationId, accountId: $accountId, clientId: $clientId, addressFromId: $addressFromId, addressToId: $addressToId, token: $token, key: $key){
          success
          code
          result {            
            id
            clientId
            accountId
            status
            rejectMessage
            currencyFromId
            networkFromId
            currencyToId
            networkToId
            addressFromId
            addressFrom
            addressToId
            addressTo
            amountFrom
            amountFromUSD
            amountTo
            amountToUSD
            price
            blockchainFeeSource
            blockchainFeeFrom
            blockchainFeeFromUSD
            blockchainFeeTo
            blockchainFeeToUSD
            serviceFee
            serviceFeeUSD
            createdAt
          }
        }
    }
`;