import updateBusinessAddressGQL from "../../../../graphQl/mutations/updateBusinessAddress";

export const updateBusinessAddress = async ({ dispatch, commit, rootGetters }, payload) => {
  const organizationId = rootGetters['app/getSelectedOrganization'].id;

  let response;

  try {
    response = await dispatch('app/request', {
      type: 'mutation',
      gql: updateBusinessAddressGQL,
      variables: {
        organizationId,
        ...payload,
      },
    }, {root: true});
  } catch (error) {
    return error;
  }

  return ( response.data && response.data.updateBusinessAddress ) ? response.data.updateBusinessAddress : {}
};