export const mutations = {
    setOrphanList(state, payload) {
        state.orphanList = payload;
    },
    setOrphan(state, payload) {
        state.orphan = payload;
    },
    setFetching: (state, payload) => {
        state.isLoading = payload
    },
    setInfoState : (state, payload) => {
        state.isInfoOpened = payload
    },
    clearAllStates: (state) => {
        state.type = '';
        state.listAddress = [];
        state.count = 0;
        state.pagesCount = 10;
        state.pages = [];
        state.page = {};
        state.address = [];
        state.currentNetwork = [];
    },

};
