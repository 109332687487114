import {addressTransactions} from "./actions/getHistory";
import {fetchHistoryAddress} from "./actions/getCurrentAddress";
import {getColumns} from "./actions/getColumns";
import {sendColumns} from "./actions/sendColumns";
import {downloadCSV} from "./actions/downloadCSV";
import {downloadXLSX} from "./actions/downloadXLSX";

export const actions = {
    addressTransactions,
    getCurrentAddress: fetchHistoryAddress,
    downloadCSV,
    downloadXLSX,
    getColumns,
    sendColumns
};
