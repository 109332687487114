<template>
  <div>
    <div class="loading-wrapper" v-if="isLoading">
      <preloader />
    </div>
    <div v-if="!isLoading" class="dashboard__container">
      <div class="dashboard" >
<!--        <dashboard-top-bar/>-->

        <div class="dashboard-row">
          <dashboard-balance/>
          <dashboard-deposit-chart>
            <hour-label/>
          </dashboard-deposit-chart>
        </div>
        <div class="dashboard__tablet-container">
          <dashboard-orders/>
        </div>
        <dashboard-transaction-count>
          <hour-label/>
        </dashboard-transaction-count>
        <!--        <dashboard-deposit-->
        <!--        :title="executed_orders"-->
        <!--        />-->
        <!--        <dashboard-withdrawal-->
        <!--        :title="completed_withdrawal_requests"/>-->
        <!--        <dashboard-transaction />-->

      </div>
    </div>
<!--    v-if="!isLoading && isDashboardOpen"-->
    <dash-board-down-commission v-if="false" @close="closeDownCommission"/>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import DashboardTopBar from "../components/elements/dashboard/DashboardTopBar";
import DashboardOrders from "../components/elements/dashboard/DashboardOrders";
import DashboardBalance from "../components/elements/dashboard/DashboardBalance";
import DashboardTransaction from "../components/elements/dashboard/DashboardTransaction";
import DashboardTransactionCount from "../components/elements/dashboard/DashboardTransactionCount";
import DashboardDeposit from "../components/elements/dashboard/DashboardDeposit";
import DashboardDepositChart from "../components/elements/dashboard/DashboardDepositChart";
import DashboardWithdrawal from "../components/elements/dashboard/DashboardWithdrawal";
import HourLabel from "../components/common/hour-label";
import Preloader from "/public/components/common/uiKit/spinner/index.vue";
import DashBoardDownCommission from "../components/elements/dashboard/DashBoardDownCommission";
import getUserContent from "../graphQl/queries/getUserContent";

export default {
    name: 'DashboardPage',
    title: 'dashboard.main',
    components: {
        DashBoardDownCommission,
        Preloader,
        DashboardTopBar,
        DashboardOrders,
        DashboardBalance,
        DashboardTransaction,
        DashboardTransactionCount,
        DashboardDeposit,
        DashboardWithdrawal,
        DashboardDepositChart,
        HourLabel,
    },
    data () {
        return {
            isDashboardOpen: 0, // false
        };
    },
    async beforeMount() {
        await this.fetchDashboard();
        // await this.checkDownCommissionState();
    },
    computed: {
        ...mapState('user', {
            userId: state => state.user.id,
            userOtpEnabled: state => state.user.otpToken,
        }),
        ...mapState('app', ['isLoading', 'isInit']),
        completed_withdrawal_requests() {
            return this.$t('dashboard.completed_withdrawal_requests');
        },
        executed_orders() {
            return this.$t('dashboard.executed_orders');
        },
    },
    methods: {
        ...mapActions('dashboard', [
            'fetchDashboard',
        ]),
        ...mapActions('app', [
            'init',
            'openModal',
            'request',
        ]),
        async checkDownCommissionState () {
            const storageState = sessionStorage.getItem('downCommission');
            if (storageState === null) {
                const {data: {getUserContent: {code, result}}} =  await this.request({
                    type: 'query',
                    gql: getUserContent,
                });
                if (code === 0) {
                    const formattedBoolean = Number(result.showCommissionWindow);
                    this.isDashboardOpen = formattedBoolean;
                    sessionStorage.setItem('downCommission', formattedBoolean);
                    return;
                }
            }
            this.isDashboardOpen = Number(storageState);
        },
        closeDownCommission () {
            this.isDashboardOpen = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.loading-wrapper {
  height: calc(100vh - 108px);
  display: flex;
  @media screen and (max-width: 576px) {
    height: calc(100vh - 114px);
  }
}
.dashboard {
  padding: 0 30px 30px;
  width: 100%;
  max-width: 1180px;
  font-family: $Gilroy;
  &__dashboard-two-auth {
    background: #fff;
    color: #3F536E;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 45px;
    font-size: 36px;
    cursor: pointer;
  }

  &__tablet-container {
    display: none;
  }

  &__orders {
    display: none;
  }

  &-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
  }

}

@media screen and (max-width: 1560px) {
  .dashboard {
    &-row {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 1335px) {
  .dashboard {
    padding-top: var(--padding-top-container);
    padding-left: var(--padding-left-container);
    padding-right: var(--padding-right-container);

    &__tablet-container {
      display: block;
    }
  }
}

@media screen and (max-width: 768px) {
  .dashboard {
    padding-top: 20px;

    &__orders {
      display: block;
    }
  }
}

</style>
