<template>
    <store-modal
            class="new-order-modal"
            name="ChangeAddressBook"
            @close="handleCancelAddress"
    >
        <template #header>
            <div class="new-order-modal__header">
                <div class="title">
                    {{ title }}
                </div>
            </div>
        </template>
        <template #body >
            <template>
                <div class="new-order-modal__padding-wrapper">
                    <div class="new-order-modal__row ">
                        <div>
                            <base-input
                                v-model="noteText"
                                :maxlength="16"
                                :placeholder="$t('address_book.add_modal_placeholder')"
                                :label="$t('address_book.note_line')"
                                size="normal"
                            />
                        </div>
                    </div>
                    <div class="new-order-modal__currency-container ">
                        <div class="new-order-modal__row ">
                            <div class="new-order-modal__title">
                                <base-input
                                    v-model="address"
                                    :maxlength="16"
                                    :placeholder="$t('address_book.add_modal_placeholder')"
                                    :label="$t('address_book.address_line')"
                                    :disabled="true"
                                />
                            </div>
                        </div>
                        <div class="new-order-modal__row">
                            <fake-input>
                                <template #label>
                                    {{ $t('address_book.network_line') }}
                                </template>
                                <template #value>
                                    <div class="selected-network"><currency-network-icon :network="getNetworkSystemName(network).toUpperCase()" /> <span class="selected-network__title">{{ network | getNetworkName }}</span></div>
                                </template>
                            </fake-input>
                        </div>
                        <div class="new-order-modal__row ">
                            <div>
                                <base-textarea
                                    v-model="description"
                                    :placeholder="$t('address_book.add_modal_placeholder')"
                                    :label="$t('address_book.add_modal_comment')"
                                    :maxlength="255"
                                    size="large"
                                    resize="none"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
<!--            <new-spinner v-else />-->
        </template>
        <template #footer>
            <div class="new-order-modal__footer">
                <base-button
                    type="secondary"
                    :label="$t('address_book.cancel')"
                    @click="handleCancelAddress"
                />
                <base-button
                        type="primary"
                        :label="$t('address_book.save')"
                        @click="handleCreateAddress"
                        :disabled="isLoading"
                />
            </div>
        </template>
    </store-modal>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex';
import AtModal from 'at-ui/src/components/modal';
import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';
import AtInput from 'at-ui/src/components/input';
import AtButton from 'at-ui/src/components/button';
import BaseModal from "../BaseModal";
import CopyString from "/public/components/elements/CopyString";
import {changeAddressBook} from "../../../store/modules/addressBook/actions/changeAddressBook";
import BaseInput from "@/public/components/common/uiKit/input/base/index.vue";
import BaseTextarea from "@/public/components/common/uiKit/textarea/index.vue";
import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
import FakeInput from "@/public/components/common/uiKit/input/FakeInput.vue";
import Icon from "@/public/components/common/icon.vue";
import CurrencyNetworkIcon from "@/public/components/elements/orphan/Select/CurrencyNetworkIcon.vue";
import {getNetworkShortNameByNetworkName, getNetworkSystemName} from "@/public/filters";

export default {
    name: 'ChangeAddressBook',

    components: {
        CurrencyNetworkIcon,
        Icon,
        FakeInput,
        BaseButton,
        BaseTextarea,
        BaseInput,
        BaseModal,
        AtModal,
        AtSelect,
        AtOption,
        AtInput,
        AtButton,
        CopyString,
        StoreModal: () => import(/* webpackChunkName: "/public/components/modals/StoreModal" */ '/public/components/modals/StoreModal'),
        NewSpinner: () => import (/* webpackChunkName: "Spinner" */ "/public/components/common/uiKit/spinner/index.vue"),
    },

    data() {
        return {
            isLoading: false,
            currencyFrom: '',
            networkFrom: '',
            noteText: null,
            description: null,
        };
    },

    computed: {
        ...mapState('app', [
            'currencies',
        ]),

        ...mapGetters('app', [
            'sortedCurrencies',
            'isOpenModal',
        ]),

        ...mapState('app', {
            modalPayload: state => state.modal.payload,
        }),

        notification() {
            return this.$t('copy.notification');
        },

        isOpen() {
            const {
                $options: {
                    name,
                },
                isOpenModal,
            } = this;

            return isOpenModal(name);
        },

        title() {
            return this.$t('address_book.change_modal_title');
        },

        address() {
            return this.modalPayload?.address ?? '';
        },

        network() {
            return this.modalPayload?.currentIdNetwork ?? '';
        },

        comment() {
            return this.modalPayload?.comment ?? '';
        },

        modalTitle() {
            return this.modalPayload?.noteText ?? '';
        },
    },

    methods: {
        getNetworkShortNameByNetworkName,
        getNetworkSystemName,
        ...mapActions('app', [
            'closeModal',
        ]),

        ...mapActions('addressBook', [
            'getAddressBook',
            'changeAddressBook',
        ]),

        clearAllData() {
            this.isLoading = false;
            this.currencyFrom = '';
            this.networkFrom = '';
            this.noteText = null;
            this.description = null;
        },

        stringAddress(string) {
            if (window.innerWidth > 576) return string;

            return `${string.substr(0, 8)}****${string.substr(-6)}`;
        },

        handleCreateAddress() {
            const {
                modalPayload: {
                    currentId,
                }, changeAddressBook, getAddressBook} = this;

            if (this.isLoading) {
                return
            }
            this.isLoading = true;
            this.isSuccess = true;

            changeAddressBook({
                comment: this.description,
                alias: this.noteText,
                addressId: currentId,
            }).then(status => {
                this.isSuccess = status.data.updateAddressBookRecord.code;
                getAddressBook({
                    route: this.$route,
                    router: this.$router,
                });
            }).finally(() => {
                this.closeModal();
                this.isLoading = false;
            });
        },

        handleCancelAddress() {
            const {clearAllData, closeModal} = this;
            closeModal();
            setTimeout(() => {
                clearAllData();
            }, 500);
        },
    },

    watch: {
        isOpen(status) {
            if (status) {
                this.description = this.comment;
                this.noteText = this.modalTitle;
            }
        },
    },
};

</script>
<style lang="scss" scoped>
.selected-network {
    display: flex;
    gap: 30px;
    ::v-deep .currency-network-icon__network {
        transform: unset;
        top: 2px;
    }
    &__title {
        font-weight: 500;
        color: var(--new-front-primary-font-color);
        font-size: $h4;
    }
}
::v-deep .fake-input {
    opacity: .6;
}
.new-order-modal {
    font-family: $Gilroy;
    &__padding-wrapper {
        padding: 0 $p30;
    }
    &__header {
        text-align: center;

        .title {
            font-size: $h1;
            font-weight: 500;
            color: var(--new-front-primary-font-color);
        }
        .subtitle {
            font-size: $h4;
            color: var(--secondary-font-color);
        }
    }
    &__body {
        padding: 0 0 $p30 0;
    }
    &__footer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
    &::v-deep {
        .list {
            max-height: 290px !important;
        }
    }
}
::v-deep .copy-string__string {
  display: none;
}


//Tick width & range thumb width need to be in sync, that's why I'm using the $unit variable.
$unit: 10px;


//Some range styling.
//Using mixins because each major browser needs a separate selector to style parts of the range input, combining selectors would invalidate the whole selector on each browser
@mixin range-track {
  -webkit-appearance: none;
  width: 100%;
  height: 7px;
  color: transparent;
  background: #F2F2F2;
  background-image: linear-gradient(90deg, var(--blue-300) var(--percentage), transparent var(--percentage));
  border-radius: 999px;
  border: none;
}

@mixin range-thumb {
  -webkit-appearance: none;
  height: 35px;
  width: 38px;
  border-radius: 12px;
  background: var(--blue-300);
  margin-top: -14px;
}

input[type=range] {
  -webkit-appearance: none;
  display: block;
  margin: 0;
  width: 100%;
  background: transparent;
}


input[type=range]::-webkit-slider-runnable-track {
  @include range-track();
}

input[type=range]::-moz-range-track {
  @include range-track();
}

input[type=range]::-ms-track {
  @include range-track();
}

input[type=range]::-ms-fill-lower {
  display: none;
}

input[type=range]::-ms-fill-upper {
  display: none;
}

input[type=range]::-webkit-slider-thumb {
  @include range-thumb();
}

input[type=range]::-moz-range-thumb {
  @include range-thumb();
}

input[type=range]::-ms-thumb {
  @include range-thumb();
}


//And now the ticks
.ticks {
  display: flex;
  justify-content: space-between;

  padding: $unit*2 $unit*2;
}

.tick {
  position: relative;
  display: flex;
  justify-content: center;
  background: gray;
  height: $unit;
  width: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: var(--primary-font-color);

  &_active {
    color: var(--blue-300);
  }
}

.new-order-modal ::v-deep .at-textarea__original {
  background: var(--secondary-color);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  box-shadow: inset 0 4px 20px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  min-height: 88px;
  font-family: var(--font-family);
  font-size: var(--font-size-text-main);
  line-height: var(--line-height);
  color: var(--primary-font-color);
}


.new-order-modal {
  ::v-deep textarea {
    &::placeholder {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      overflow: visible;
    }
  }
}


::v-deep .at-input__original {
  font-family: var(--font-family);
  font-size: var(--font-size-text-main);
  line-height: var(--line-height);

  &::placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }
}

.new-order-modal {
  max-width: 764px;

  &__error-text {
    font-size: var(--font-size-text-secondary);
    font-family: var(--font-family);
    line-height: var(--line-height);
    color: #EB5757;
  }

  &__time-picker-popup {
    background: black;
  }

  &__time-picker-popup::v-deep .mx-datepicker-header {
    padding: 0;

  }

  &__time-picker {
    width: 100%;
    max-width: 312px;
  }

  &__time-picker::v-deep.mx-datepicker-range {
    width: 100%;
  }

  &__time-picker ::v-deep .mx-input {
    height: 46px;
    background: var(--secondary-color);
    border: 1px solid var(--border-color);
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }


  &__input {
    width: 100%;
  }

  &__amount {
    max-width: 312px;

    &::before {
      content: '';
      width: calc(100% + 32px);
      border-bottom: 1px solid var(--border-color);
      background: black;
      position: absolute;
      left: -16px;
      bottom: 0;
    }
  }

  &__currency-container {
    display: flex;
    //gap: 25px;
    justify-content: space-between;
    flex-direction: column;
  }

  &__select-container {
    position: relative;
  }

  &__option-container {
    display: flex;
    gap: 15px;
  }

  &__select-container ::v-deep .at-select__selection {
    background: var(--secondary-color);
    border: 1px solid var(--border-color);
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    min-height: 46px;
    display: flex;
    align-items: center;
    background: var(--secondary-color);
    color: var(--primary-font-color);
  }

  &::v-deep .at-select__dropdown {
    border: 1px solid var(--border-color);
    background: var(--bg-select);
  }

  ::v-deep .at-select__option--selected {
    background: var(--hover-color-select);
  }

  ::v-deep .at-select__arrow {
    font-size: 24px;
    color: var(--primary-font-color);
    margin-top: 0;
    top: calc(50% - 12px);
  }

  ::v-deep .at-select__clear {
    font-size: 24px;
    color: var(--primary-font-color);
    margin-top: 0;
    top: calc(50% - 12px);
  }

  ::v-deep .at-select__dropdown .at-select__option {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);

    &:hover {
      background: var(--hover-color-select);
    }
  }

  ::v-deep .at-select__placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }

  ::v-deep .at-select__selection {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    padding-left: 30px;
  }

  &__select-container_img ::v-deep .at-select__selection {
    padding-left: 68px;
  }

  &__label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 52px;
    border-bottom: 1px solid var(--border-color);
  }

  &__time-label {

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: var(--primary-font-color);
    width: 100%;

    &_divider {
      width: auto;
    }
  }

  &__row {
    margin-bottom: 20px;
    align-items: center;
    min-height: 30px;
    width: 100%;
    position: relative;

    ::v-deep .at-input--error .at-input__original {
      border-color: #EB5757;
    }

    &_amount {
      padding-bottom: 35px;
      margin-bottom: 37px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--primary-font-color);
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &_slider {
      margin-bottom: 35px;
    }
  }

  &__title-address {
    font-size: 16px;

    @media screen and (max-width: 576px) {
      font-size: 14px;
    }
  }

  &__select {
    width: 100%;
    height: 32px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    outline: none;
    transition: .2s ease border-color;

    &:hover {
      border-color: #79A1EB;
    }
  }

  &__date.mx-datepicker {
    width: 100%;
  }

  &__spinner {
    margin: 16px 0 18px;
  }

  &__full-width-btn {
    width: 100%;
  }

  &.link {
    color: #5ECAE5;
    min-width: 220px;
    cursor: pointer;
  }

  .currency-img {
    width: 20px;
    height: 20px;

    &_preview {
      position: absolute;
      z-index: 2;
      top: 11px;
      width: 24px;
      height: 24px;
      left: 30px;
    }
  }


}

@media screen and (max-width: 450px) {
  .new-order-modal {
    ::v-deep .currency-img_preview {
      left: 17px;
    }

    &__select-container_img {
      ::v-deep .at-select__selection {
        padding-left: 53px;
      }
    }

    ::v-deep .at-modal__title {
      max-width: 200px;
    }

    ::v-deep .at-modal__footer {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
    }

    &__amount {
      max-width: 100%;
    }

    &__row {
      margin-bottom: 10px;

      &_amount {
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
    }

    &__currency-container {
      flex-direction: column;
      gap: 10px;
      margin-bottom: 10px;
    }
  }

}

</style>
