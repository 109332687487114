<template>
    <div class="dashboard-transaction-row table-row row">
        <div class="dashboard-transaction-row__id col col-5">
            <CopyString
                :string="id"
                canCopy
            />
        </div>
        <template v-if="amountFrom">
            <div class="col">
                {{ amountFrom }}
            </div>
        </template>
        <template v-if="amountTo">
            <div class="col">
                {{ amountTo }}
            </div>
        </template>
        <template v-if="currencyFrom">
            <div class="dashboard-transaction-row__currency col">
                <CurrencyImg
                    class="dashboard-transaction-row__currency-img"
                    :currency="currencyFrom"
                />
                {{ currencyFrom }}
            </div>
        </template>
        <template v-if="currencyTo">
            <div class="dashboard-transaction-row__currency col">
                <CurrencyImg
                    class="dashboard-transaction-row__currency-img"
                    :currency="currencyTo"
                />
                {{ currencyTo }}
            </div>
        </template>
        <div class="col col-4">
            {{ createdAt | getFormatDate('DD.MM.YYYY, HH:mm') }}
        </div>
        <div class="col col-5" v-show="deposit">
            <CopyString
                v-if="hasOrderId"
                :string="orderId"
                canCopy
            />
        </div>
        <div class="col col-4" v-show="deposit">
            {{ orderCreatedAt | getFormatDate('DD.MM.YYYY, HH:mm') }}
        </div>
        <div class="col col-1">
            <RouterLink :to="{ name: 'transaction', params: { id } }">
                <Icon icon="eye" />
            </RouterLink>
        </div>
    </div>
</template>

<script>

import Moment from 'moment';
import { mapState } from 'vuex';

import CopyString from "/public/components/elements/CopyString";
import CurrencyImg from "/public/components/elements/CurrencyImg";
import Icon from "/public/components/elements/Icon";

export default {
    name: 'DashboardTransactionRow',
    components: {
        CopyString,
        CurrencyImg,
        Icon,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        deposit: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState('app', [
            'currencies',
        ]),
        id () {
            return this.data.id;
        },
        type () {
            return this.data.type;
        },
        createdAt () {
            return this.data.createdAt;
        },
        currencyFrom () {
            const {
                currencies,
                data: {
                    currencyFrom,
                },
            } = this;

            if (!currencyFrom) {
                return null;
            }

            return currencies.find(currency => currency.id === currencyFrom).currency;
        },
        currencyTo () {
            const {
                currencies,
                data: {
                    currencyTo,
                },
            } = this;

            if (!currencyTo) {
                return null;
            }

            return currencies.find(currency => currency.id === currencyTo).currency;
        },
        hasCurrencyFrom () {
            return Boolean(this.currencyFrom);
        },
        hasCurrencyTo () {
            return Boolean(this.currencyTo);
        },
        orderId () {
            return this.data.orderId;
        },
        hasOrderId () {
            return Boolean(this.orderId);
        },
        orderCreatedAt () {
            if (this.data.orderCreatedAt) {
                return this.data.orderCreatedAt;
            } else {
                return '';
            }
        },
        amountFrom () {
            return this.data.amountFrom;
        },
        amountTo () {
            return this.data.amountTo;
        },
    },
};

</script>

<style lang="scss" scoped>
    .dashboard-transaction-row {
        position: relative;
        align-items: center;
        color: #3F536E;

        &__id {
            position: relative;
            z-index: 2;
        }

        &__currency {
            display: flex;
            align-items: center;
        }

        &__currency-img {
            margin-right: 12px;
            width: 24px;
            height: 24px;
        }
    }
</style>
