import crosschainTransferTokenMutation from "../../../../graphQl/mutations/crosschainTransferToken";

export const getCrosschainTransferToken = async ({ dispatch, commit, rootGetters }, payload) => {
    const selectedOrganizationId = rootGetters['app/getSelectedOrganizationId'];
    let response;

    const variables = {
        ...payload,
        organizationId: selectedOrganizationId,
    };

    try {
        response = await dispatch('app/request', {
            type: 'mutation',
            gql: crosschainTransferTokenMutation,
            variables,
        }, {root: true});
    } catch (error) {
        return error;
    }

    return ( response.data && response.data.crosschainTransferToken ) ? response.data.crosschainTransferToken : {};
};
