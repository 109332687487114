<template>
  <div class="dashboard-orders__card">
    <div class="dashboard-orders__card-counter">
      <div class="dashboard-orders__card-info-container">
        <div class="dashboard-orders__card-head">
          <div class="dashboard-orders__card-count-container">
            <p class="dashboard-orders__card-counter-count">{{ data.count }}</p>
          </div>
          <div class="dashboard-orders__card-label-container">
            <p class="dashboard-orders__card-label" v-if="label">{{ $t(label) }}</p>
            <div class="dashboard-orders__card-label-type-container">
              <slot></slot>
              <p class="dashboard-orders__card-label-type" v-if="type">{{ $t(type) }}</p>
            </div>
          </div>
        </div>
        <div class="dashboard-orders__card-hour-container">
          <hour-label/>
        </div>
      </div>
      <div class="dashboard-orders__card-counter-amount">
        <div v-for="(value, name) in data.amount" class="dashboard-orders__card-container">
          <div class="dashboard-orders__card-currency-container">
            <CurrencyImg
                class="dashboard-orders__card-currency-image"
                :currency="name"
            />
            <p class="dashboard-orders__card-currency-title">{{ name }}</p>
          </div>
          <p class="dashboard-orders__card-currency-value">{{ value }}</p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import hourLabel from "../../common/hour-label";
import CurrencyImg from "../CurrencyImg";

export default {
    name: 'DashboardOrdersCard',
    components: {
        hourLabel,
        CurrencyImg,
    },

    props: {
        label: String,
        type: String,
        data: {
            type: Object,
            default: function () {
                return {
                    count: 0,
                    amount: [],
                };
            },
        },
    },
};

</script>

<style lang="scss" scoped>

.dashboard-orders__card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  font-family: $Gilroy;
  &-currency-title {
    color: var(--new-front-primary-font-color);
    font-weight: 500;
  }

  &-currency-container {
    display: flex;
    gap: 18px;
  }

  &-currency-image {
    height: 24px;
    width: 24px;
  }

  &-currency-value {
    color: var(--new-front-primary-font-color);
  }

  &-info-container {
    display: flex;
    background: var(--new-front-main-bg);
    align-items: flex-start;
    padding-right: 18px;
    padding-left: 18px;
    justify-content: space-between;
    border-top: 1px solid var(--new-front-input-border);
    border-left: 1px solid var(--new-front-input-border);
    border-right: 1px solid var(--new-front-input-border);
    border-radius: 12px 12px 0 0;
  }

  &-hour-container {
    padding-top: 18px;
  }

  &-count-container {
    min-width: 75px;
    min-height: 53px;
    background: var(--new-front-main-bg);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
  }

  &-counter-count {
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    width: fit-content;
    color: var(--new-front-primary-font-color);
  }

  &-head {
    min-height: 89px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }

  &-counter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0px 10px;

    &-amount {
      padding: 25px 20px;
      display: flex;
      flex-direction: column;
      text-align: right;
      gap: 30px;
      max-height: 277px;
      overflow-y: auto;
      border: 1px solid var(--new-front-input-border);
      min-height: 200px;
      border-radius: 0 0 12px 12px;
      background: var(--new-front-main-bg);
    }
  }

  &-label {
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--new-front-primary-font-color);
  }

  &-label-type {
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--new-front-secondary-font-color);
  }

  &-label-type-container {
    display: flex;
    gap: 6px;
  }

  &:first-child::after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: -38px;
    border: 1px solid var(--new-front-input-border);

  }

  &:last-child {
    margin-right: 0;
  }

  &-container {
    display: flex;
    justify-content: space-between;
  }

  &-label-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}


@media screen and (max-width: 1335px) {
  .dashboard-orders__card {
    box-shadow: 0px 8px 50px rgba(10, 17, 105, 0.08);
    border-radius: 12px;
    background: var(--white-100);

    &:first-child::after {

      display: none;

    }

    margin: 0;

    &-info-container {
      border-radius: 12px 12px 0px 0px;
      background: var(--new-front-main-bg);
    }

    &-counter-amount {
      border: none;
      background: var(--new-front-main-bg);
    }

    &-counter {
      margin: 0;
    }
  }
  @media screen and (max-width: 1016px) {
    .dashboard-orders__card {
      box-shadow: none;

      &-info-container {
        border-radius: 0;
        padding-left: 35px;
        padding-right: 35px;
        border: none;
        align-items: center;
        background: var(--new-front-main-bg);
      }

      &-hour-container {
        padding-top: 0;
      }

      &-label-type {
        display: none;
      }
    }

  }
}

</style>
