<template>
  <div class="order-list-header">
    <div class=" order-list-header__item ">
      {{ $t('payout-wallet.currency') }}
    </div>
    <div class=" order-list-header__item ">
      {{ $t('payout-wallet.withdrawal_amount') }}
    </div>
    <div class=" order-list-header__item ">
      {{ $t('payout-wallet.fee.advance') }}
    </div>
    <div
        class=" order-list-header__item"
    >
      {{ $t('payout-wallet.fee.address') }}
    </div>
  </div>
</template>

<script>

import {mapActions} from 'vuex';

import Icon from "/public/components/elements/Icon";

export default {
    name: 'PayoutWalletRegistryHeader',
    data() {
        return {};
    },
    beforeMount() {
    },


};

</script>
<style lang="scss" scoped>
.order-list-header {
  background: var(--grey-200);
  border-bottom: var(--border-table);
  border-top: var(--border-table);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  min-height: 46px;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;

  &__item {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: var(--font-size-text-secondary);
    line-height: var(--line-height);
    color: #2C405A;
  }
}

@media screen and (max-width: 1300px) {
  .order-list-header {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 950px) {
  .order-list-header {
    display: none;
  }

}
</style>