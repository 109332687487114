<template>
  <AtModal
      :value="value"
      :title="title"
      :content="content"
      :cancelText="cancelText"
      :okText="okText"
      :maskClosable="maskClosable"
      :showHead="showHead"
      :showClose="showClose"
      :showFooter="showFooter"
      :showInput="showInput"
      :width="width"
      :closeOnPressEsc="closeOnPressEsc"
      :styles="styles"
      @on-cancel="(e) => $emit('on-cancel', e)"
      @on-confirm="(e) => $emit('on-confirm', e)"
      class="base-modal"
  >
    <template v-slot:header>
      <slot name="header">
        <div v-if="loading" class="loader-wrapper">
          <div class="loader-dashboard"/>
          <preloader />
        </div>
      </slot>
    </template>
    <template v-slot:default>
      <slot name="default"/>
    </template>
    <template v-slot:footer>
      <slot name="footer"/>
    </template>
  </AtModal>
</template>

<script>
import {v4 as UUID} from 'uuid';
import Preloader from '/public/components/common/preloader';

export default {
    inheritAttrs: true,
    name: "BaseModal",
    components: {
      Preloader
    },
    props: {
        value: Boolean,
        title: String,
        content: String,
        cancelText: String,
        okText: String,
        maskClosable: Boolean,
        showHead: Boolean,
        showClose: Boolean,
        showFooter: Boolean,
        showInput: Boolean,
        width: [String, Number],
        closeOnPressEsc: Boolean,
        styles: Object,
        loading: Boolean
    },
    methods: {
        createMBBlock() {
            const block = document.createElement('div');

            block.setAttribute('id', this.id);
            block.style.height = '200px';

            return block;
        },
        getMBBlock() {
            return document.getElementById(this.id);
        },
        applyOverflowHidden() {
            this.prevBodyOverflowState = document.body.style.overflow || '';

            document.body.style.overflow = 'hidden';

            const wrapper = this.$el.querySelector('.at-modal__wrapper');

            if (!wrapper)
                return;

            wrapper.style.overflow = 'auto';
            wrapper.append(this.createMBBlock());
        },
        inapplyOverflowHidden() {
            document.body.style.overflow = this.prevBodyOverflowState;

            this.getMBBlock()?.remove();
        },
    },
    watch: {
        value(v) {
            v ? this.applyOverflowHidden() : this.inapplyOverflowHidden();
        },
    },
    data() {
        return {
            id: UUID(),
            prevBodyOverflowState: '',
        };
    },
};
</script>

<style lang="scss" scoped>
.base-modal ::v-deep .at-modal__footer .at-btn + .at-btn {
  margin: 0;
}

.base-modal ::v-deep .at-modal {
    max-width: 764px;
    border-radius: 20px;
    background: var(--new-front-bg-secondary);
}

.base-modal ::v-deep .at-modal__body {
  padding-top: 26px;
  padding-bottom: 52px;
  padding-left: var(--padding-left-container);
  padding-right: var(--padding-right-container);
}

.base-modal ::v-deep .at-modal__header {
  display: flex;
  align-items: center;
  min-height: 107px;
  border-radius: 20px 20px 0 0;
  background: var(--modal-header-bg);
  border-bottom: 1px solid var(--border-color);
  padding-left: var(--padding-left-container);
  padding-right: var(--padding-right-container);
}

.base-modal ::v-deep .at-modal__header p, .at-modal__header .at-modal__title {
  font-family: var(--new-front-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-h2);
  line-height: var(--line-height);
    color: var(--primary-font-color);
}

.base-modal ::v-deep .col {
  width: 100%;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-text-main);
  line-height: var(--line-height);
    color: var(--primary-font-color);
}

.base-modal ::v-deep .at-input__original {
  background:  var(--secondary-color);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  min-height: 46px;
    color: var(--primary-font-color);
}

.base-modal ::v-deep .at-modal__footer {
  border: none;
  min-height: 106px;
  display: flex;
  justify-content: center;
  gap: 15px;
  padding-left: var(--padding-left-container);
  padding-right: var(--padding-right-container);
}

.base-modal ::v-deep .at-btn {
  width: 100%;
  height: 46px;
  max-width: 311px;
  border-radius: 12px;
}

.base-modal ::v-deep .at-btn:disabled {
    background: var(--disabled-color-modal);
    border: 1px solid var(--disabled-color-modal);
}

.base-modal ::v-deep .at-btn:disabled:hover {
    background: var(--disabled-color-modal);
}

.base-modal ::v-deep .at-btn--primary {
    background: #0059F7;
    transition: all 0.3s ease-in;
    border: 1px solid #0059F7

}

.base-modal ::v-deep .at-btn--primary > .at-btn__text {
  font-family: var(--font-family);
  font-size: var(--font-size-text-main);
  color: var(--white-100);

}

.base-modal ::v-deep .at-btn--default > .at-btn__text {
  font-family: var(--font-family);
  font-size: var(--font-size-text-main);
  color: var(--primary-font-color);

}

.base-modal ::v-deep .at-btn--primary:hover {
  background: var(--blue-400);
}


.base-modal ::v-deep .at-btn--default {
  border: 1px solid var(--border-color-modal);
  background: transparent;
  transition: all 0.3s ease-in;
}

.base-modal ::v-deep .at-btn--default:hover {
  border: 1px solid var(--border-color-modal);
}

.base-modal ::v-deep .at-modal__close {
  font-size: 24px;
  color: #8F949A;
  top: 35px;
  right: var(--padding-right-container);
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border-radius: 20px;
  .loader-dashboard {
    height: 100%;
    width: 100%;

    background: #FFFFFF;
    opacity: 0.75;

    border-radius: 20px;
  }

  .loader {
    position: absolute;
    transform: translate(-50%, -50%);
  }
}




@media screen and (max-width: 950px) {
  .base-modal {

  }

}

@media screen and (max-width: 450px) {
  .base-modal ::v-deep .at-modal__header {
    min-height: 44px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .base-modal ::v-deep .at-modal__footer {
    min-height: 0;
    padding-bottom: 35px;
    padding-left: 10px;
    padding-right: 10px;

  }

  .base-modal ::v-deep .at-modal__body {
    padding-bottom: 35px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .base-modal ::v-deep .at-modal__close {
    top: 15px;
    right: 10px;
  }
}
</style>
