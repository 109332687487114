<script>

  export default {
    name: "SvgDustClear"
  }
</script>

<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_23028_24806)">
    <path d="M14.5938 2.8125C15.3704 2.8125 16 2.1829 16 1.40625C16 0.6296 15.3704 0 14.5938 0C13.8171 0 13.1875 0.6296 13.1875 1.40625C13.1875 2.1829 13.8171 2.8125 14.5938 2.8125Z" fill="#928F98"/>
    <path d="M1.40625 16C2.1829 16 2.8125 15.3704 2.8125 14.5938C2.8125 13.8171 2.1829 13.1875 1.40625 13.1875C0.6296 13.1875 0 13.8171 0 14.5938C0 15.3704 0.6296 16 1.40625 16Z" fill="#928F98"/>
    <path d="M14.5625 13.1562C14.8214 13.1562 15.0312 12.9464 15.0312 12.6875C15.0312 12.4286 14.8214 12.2188 14.5625 12.2188C14.3036 12.2188 14.0938 12.4286 14.0938 12.6875C14.0938 12.9464 14.3036 13.1562 14.5625 13.1562Z" fill="#928F98"/>
    <path d="M1.4375 3.78125C1.69638 3.78125 1.90625 3.57138 1.90625 3.3125C1.90625 3.05362 1.69638 2.84375 1.4375 2.84375C1.17862 2.84375 0.96875 3.05362 0.96875 3.3125C0.96875 3.57138 1.17862 3.78125 1.4375 3.78125Z" fill="#928F98"/>
    <path d="M13.8621 4.94991C13.3778 3.68009 12.1812 2.84375 10.8125 2.84375C10.1167 2.84375 9.45844 3.05981 8.90453 3.46172C8.31494 2.51097 7.26484 1.90625 6.125 1.90625C4.46422 1.90625 3.08819 3.14588 2.87306 4.74806C1.27088 4.96366 0 6.33969 0 8C0 9.36872 0.868031 10.5653 2.13788 11.0501C2.62219 12.3199 3.81878 13.1562 5.1875 13.1562C5.88331 13.1562 6.54156 12.9402 7.09547 12.5383C7.68506 13.489 8.73516 14.0938 9.875 14.0938C11.5358 14.0938 12.9118 12.8541 13.1269 11.2519C14.7291 11.0363 16 9.66031 16 8C16 6.63128 15.132 5.43469 13.8621 4.94991ZM3.3125 8.46875C3.05363 8.46875 2.84375 8.25887 2.84375 8C2.84375 7.74106 3.05363 7.53125 3.3125 7.53125C3.57137 7.53125 3.78125 7.74106 3.78125 8C3.78125 8.25887 3.57137 8.46875 3.3125 8.46875ZM5.1875 10.3438C4.92863 10.3438 4.71875 10.1339 4.71875 9.875C4.71875 9.61606 4.92863 9.40625 5.1875 9.40625C5.44637 9.40625 5.65625 9.61606 5.65625 9.875C5.65625 10.1339 5.44637 10.3438 5.1875 10.3438ZM6.125 6.59375C5.86613 6.59375 5.65625 6.38387 5.65625 6.125C5.65625 5.86606 5.86613 5.65625 6.125 5.65625C6.38387 5.65625 6.59375 5.86606 6.59375 6.125C6.59375 6.38387 6.38387 6.59375 6.125 6.59375ZM8 8.46875C7.74113 8.46875 7.53125 8.25887 7.53125 8C7.53125 7.74106 7.74113 7.53125 8 7.53125C8.25887 7.53125 8.46875 7.74106 8.46875 8C8.46875 8.25887 8.25887 8.46875 8 8.46875ZM9.875 10.3438C9.61613 10.3438 9.40625 10.1339 9.40625 9.875C9.40625 9.61606 9.61613 9.40625 9.875 9.40625C10.1339 9.40625 10.3438 9.61606 10.3438 9.875C10.3438 10.1339 10.1339 10.3438 9.875 10.3438ZM10.8125 6.59375C10.5536 6.59375 10.3438 6.38387 10.3438 6.125C10.3438 5.86606 10.5536 5.65625 10.8125 5.65625C11.0714 5.65625 11.2812 5.86606 11.2812 6.125C11.2812 6.38387 11.0714 6.59375 10.8125 6.59375ZM12.6875 8.46875C12.4286 8.46875 12.2188 8.25887 12.2188 8C12.2188 7.74106 12.4286 7.53125 12.6875 7.53125C12.9464 7.53125 13.1562 7.74106 13.1562 8C13.1562 8.25887 12.9464 8.46875 12.6875 8.46875Z" fill="#928F98"/>
    <path d="M6.125 15.0312C6.38388 15.0312 6.59375 14.8214 6.59375 14.5625C6.59375 14.3036 6.38388 14.0938 6.125 14.0938C5.86612 14.0938 5.65625 14.3036 5.65625 14.5625C5.65625 14.8214 5.86612 15.0312 6.125 15.0312Z" fill="#928F98"/>
    <path d="M8.9375 1.90625C9.19638 1.90625 9.40625 1.69638 9.40625 1.4375C9.40625 1.17862 9.19638 0.96875 8.9375 0.96875C8.67862 0.96875 8.46875 1.17862 8.46875 1.4375C8.46875 1.69638 8.67862 1.90625 8.9375 1.90625Z" fill="#928F98"/>
  </g>
  <defs>
    <clipPath id="clip0_23028_24806">
      <rect width="16" height="16" fill="white"/>
    </clipPath>
  </defs>
</svg>
</template>

<style scoped lang="scss">

</style>
