<template>
  <btn-select
    v-bind="$attrs"
    v-model="valueModel"
    :list="downloadTypes"
    class="downloader"
    align-list="right"
    dont-show-active
    close-outside
  >
    <template #icon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M8.72933 14.9529C8.33425 15.3743 7.66533 15.3743 7.27025 14.9529L0.924369 8.18392C0.32562 7.54525 0.778465 6.49998 1.65391 6.49998L4.49979 6.49998L4.49979 5.49998C4.49979 4.94769 4.94751 4.49998 5.49979 4.49998L10.4998 4.49998C11.0521 4.49998 11.4998 4.94769 11.4998 5.49998L11.4998 6.49998L14.3457 6.49998C15.2211 6.49998 15.674 7.54525 15.0752 8.18392L8.72933 14.9529Z"
          fill="#744DB2"
        />
        <path
          d="M11.4998 2.49998C11.4998 3.05226 11.0521 3.49998 10.4998 3.49998L5.49979 3.49998C4.94751 3.49998 4.49979 3.05226 4.49979 2.49998L4.49979 1.49998C4.49979 0.947692 4.94751 0.499977 5.49979 0.499977L10.4998 0.499978C11.0521 0.499978 11.4998 0.947693 11.4998 1.49998L11.4998 2.49998Z"
          fill="#744DB2"
        />
      </svg>
    </template>
    <template #info="data">
      <downloader-item
        v-bind="data"
        height="22px"
      />
    </template>
  </btn-select>
</template>

<script>

export default {
  name: "Downloader",
  components: {
    BtnSelect: () => import(/* webpackChunkName: "public/components/common/uiKit/selects/btn-select" */ '/public/components/common/uiKit/selects/btn-select'),
    DownloaderItem: () => import(/* webpackChunkName: "public/components/common/uiKit/selects/btn-select/Item" */ './Item'),
  },
  data() {
    return {
      search: undefined,
      downloadTypes: [
        {
          id: 'csv',
          label: 'CSV',
        },
        {
          id: 'xlsx',
          label: 'XLSX',
        }
      ]
    }
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    list: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    valueModel: {
      get({ value }) {
        return value
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  },
}
</script>

<style lang="scss">
.downloader {
  .btn-wrapper {
    .base-btn {
      background: transparent!important;
      border: 1px solid var(--new-front-border-btn-primary-bg-primary)!important;
      transition: background .2s ease !important;
      &:hover {
        background: var(--new-front-bg-btn-secondary-bg-modal-primary-hover) !important;
      }
    }
    .options-list {
      width: 105px!important;
      padding: 0!important;

      .list {
        &__item {
          margin: 0!important;
          border: 0!important;
        }
      }
    }
  }
}
</style>
