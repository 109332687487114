<template>
<div class="refresh" @click="emitClick">
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 2.15942C5.22205 2.15942 2.15942 5.22205 2.15942 9C2.15942 12.7779 5.22205 15.8406 9 15.8406C12.7776 15.8406 15.84 12.7785 15.8406 9.00104C15.8406 8.68087 16.1002 8.42137 16.4204 8.42142C16.7405 8.42146 17 8.68105 17 9.00121C16.9993 13.419 13.4178 17 9 17C4.58172 17 0.999999 13.4183 1 9C1 4.58172 4.58172 0.999999 9 1C10.6719 1 12.2629 1.51573 13.5883 2.44545L14.1649 1.78214C14.4687 1.4327 15.0393 1.56281 15.1616 2.00939L15.8514 4.52939C15.9607 4.92838 15.6289 5.31009 15.2185 5.25746L12.6271 4.92502C12.1678 4.86611 11.9595 4.31914 12.2633 3.9697L12.8228 3.32604C11.7101 2.57451 10.3883 2.15942 9 2.15942Z" :fill="!themeStatus ? '#6750A4' : '#FFFFFF'" :stroke="!themeStatus ? '#6750A4' : '#FFFFFF'"/>
    </svg>
</div>
</template>
<script>
    import {mapState} from "vuex";

    export default {
        name: "RefreshButton",
        computed: {
            ...mapState(['themeStatus']),
        },
        methods: {
            emitClick () {
                this.$emit('click')
            }
        }
    }
</script>
<style scoped lang="scss">
.refresh {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
    min-width: 48px;
  background: var(--new-front-main-bg);
  border: 1px solid #744DB2;
    border-radius: 10px;
  transition: background .2s ease;
    cursor: pointer;
  &:hover {
    background: var(--new-front-bg-btn-secondary-bg-modal-primary-hover);
  }
}
</style>
