<template>
  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13.5" cy="13.5" r="13.5" fill="white"/>
    <path d="M19.3845 9.69238L11.7692 17.3078L8.30762 13.8462" stroke="#27AE60" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
    name: "icon-checkmark-validation",
};
</script>

<style scoped>

</style>