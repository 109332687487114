<template>
<store-modal
    class="notEnoughBalanceModal"
    name="NotEnoughAdvanceBalance"
    @close="handleClose"
>
  <template #body>
    <div class="notEnoughBalanceModal__container">
      <div class="notEnoughBalanceModal__icon">
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="60" height="60" rx="10" fill="#B24D4D"/>
              <path d="M24.8098 18.2197C24.9504 18.079 25.1412 18 25.3401 18H34.6599C34.8588 18 35.0496 18.079 35.1902 18.2197L41.7803 24.8098C41.921 24.9504 42 25.1412 42 25.3401V34.6599C42 34.8588 41.921 35.0496 41.7803 35.1902L35.1902 41.7803C35.0496 41.921 34.8588 42 34.6599 42H25.3401C25.1412 42 24.9504 41.921 24.8098 41.7803L18.2197 35.1902C18.079 35.0496 18 34.8588 18 34.6599V25.3401C18 25.1412 18.079 24.9504 18.2197 24.8098L24.8098 18.2197ZM25.6508 19.5L19.5 25.6508V34.3492L25.6508 40.5H34.3492L40.5 34.3492V25.6508L34.3492 19.5H25.6508Z" fill="white"/>
              <path d="M24.9697 24.9697C25.2626 24.6768 25.7374 24.6768 26.0303 24.9697L30 28.9393L33.9697 24.9697C34.2626 24.6768 34.7374 24.6768 35.0303 24.9697C35.3232 25.2626 35.3232 25.7374 35.0303 26.0303L31.0607 30L35.0303 33.9697C35.3232 34.2626 35.3232 34.7374 35.0303 35.0303C34.7374 35.3232 34.2626 35.3232 33.9697 35.0303L30 31.0607L26.0303 35.0303C25.7374 35.3232 25.2626 35.3232 24.9697 35.0303C24.6768 34.7374 24.6768 34.2626 24.9697 33.9697L28.9393 30L24.9697 26.0303C24.6768 25.7374 24.6768 25.2626 24.9697 24.9697Z" fill="white"/>
          </svg>

      </div>
      <div class="notEnoughBalanceModal__title">
        {{ $t('not-enough-advance-balance-modal.title') }}
      </div>
      <div class="notEnoughBalanceModal__desc">
        <span class="notEnoughBalanceModal__error-title">{{ $t('not-enough-advance-balance-modal.error-text') }}</span> <span class="notEnoughBalanceModal__error">{{ advanceBalanceAmount }}</span>
      </div>
    </div>
  </template>
    <template #footer>
        <base-button
                class="button"
                type="primary"
                :label="createButtonTitle"
                @click="handleConfirm"
        />
    </template>
</store-modal>
</template>

<script>

import BaseModal from "../BaseModal";
import {mapActions, mapGetters, mapState} from "vuex";
import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
export default {
    name: "NotEnoughAdvanceBalance",
    components: {
        BaseButton,
        BaseModal,
        StoreModal: () => import(/* webpackChunkName: "/public/components/modals/StoreModal" */ '/public/components/modals/StoreModal'),

    },
    data () {
        return {
            advanceBalanceAmount: '',
        };
    },
    computed: {
        ...mapGetters('app', [
            'isOpenModal',
        ]),
        ...mapState('app', [
            'modal',
        ]),
        createButtonTitle () {
            return this.$t('not-enough-advance-balance-modal.btn-label');
        },
        isOpen() {
            const {
                $options: {
                    name,
                },
                isOpenModal,
            } = this;

            return isOpenModal(name);
        },
    },
    watch: {
        isOpen(status) {

            if (this.modal.opened !== this.$options.name || !status) {
                return;
            }
            this.$nextTick(() => {
                if (this.modal.payload.advanceBalanceAmount) {
                    this.advanceBalanceAmount = this.modal.payload.advanceBalanceAmount;
                }
            });
        },
    },
    methods: {
        ...mapActions('app', [
            'closeModal',
        ]),
        handleConfirm () {
            this.closeModal();
            this.$router.push('/advanced-balances');
        },
        handleClose () {
            this.closeModal();
        },
    },
};
</script>

<style lang="scss" scoped>
.notEnoughBalanceModal {
  font-family: $Gilroy;
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__icon {
    margin-bottom: 30px;
  }
  &__title {
    font-size: $h1;
    color: var(--new-front-primary-font-color);
    margin-bottom: 20px;
  }
  &__error {
    font-size: $h4;
    //margin-bottom: 64px;
    color: #B24D4D;
    text-align: center;
    &-title {
      font-size: $h4;
      color: var(--new-front-secondary-font-color)
    }
  }
  &__desc {
    margin-bottom: 30px;
  }
  ::v-deep .at-btn {
    min-width: 100%;
  }
}
@media (max-width: 500px) {
  .notEnoughBalanceModal__title {
    font-size: $h2;
  }
  .notEnoughBalanceModal__desc {
    text-align: center;
  }
}
::v-deep {
  .main-modal-header {
    padding-bottom: 0 !important;
  }
}
</style>
