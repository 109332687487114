import gql from 'graphql-tag';

export default gql`
   query ($organizationId: String!, $webhookId: String!) {
  getWebhook (organizationId: $organizationId, webhookId: $webhookId) {
      id
      event
      eventId
      requestUrl
      requestHeaders
      requestBody
      responseCode
      responseStatus
      responseBody
      sentAt
      createdAt
      updatedAt
      signature
      apiKeyAlias
} 
}
`;
