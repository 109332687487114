import getCurrenciesQuery from "/public/graphQl/queries/getCurrencies";

export const fetchCurrencies = async ({ commit, dispatch, rootGetters }) => {
    let currenciesData;
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    try {
        currenciesData = await dispatch('request', {
            type: 'query',
            gql: getCurrenciesQuery,
            variables: {
                organizationId: organizationId,
            },
        });
    } catch (error) {
        return error;
    }

    if (currenciesData.message) {
        throw currenciesData.message;
    }

    if (currenciesData.errors) {
        throw currenciesData.errors;
    }

    const {
        data: { getCurrencies },
    } = currenciesData;

    commit('currencies', getCurrencies);

    return true;
};
