<template>
  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M5.96955 18.0303C5.67665 17.7374 5.67665 17.2625 5.96955 16.9697L10.9392 12L5.96955 7.03031C5.67665 6.73742 5.67665 6.26255 5.96955 5.96966C6.26244 5.67676 6.73731 5.67676 7.03021 5.96966L11.9999 10.9393L16.9695 5.96966C17.2624 5.67676 17.7373 5.67676 18.0302 5.96966C18.3231 6.26255 18.3231 6.73742 18.0302 7.03031L13.0605 12L18.0302 16.9697C18.3231 17.2625 18.3231 17.7374 18.0302 18.0303C17.7373 18.3232 17.2624 18.3232 16.9695 18.0303L11.9999 13.0606L7.03021 18.0303C6.73731 18.3232 6.26244 18.3232 5.96955 18.0303Z"
          :fill="color"/>
  </svg>
</template>

<script>
export default {
    name: "cross",
    props: {
        color: {
            type: String,
            default: '#8F949A',
        },
    },
};
</script>

<style scoped>

</style>