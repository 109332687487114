<script>
  import BhSelectNew from '/public/components/elements/advance-balances/selects/Base'
  import {mapActions, mapMutations, mapState} from "vuex";
  import {getDashboard} from "@/public/store/modules/dashboard/actions/getDashboard";
  export default {
    name: "DashboardPeriodSelect",
    components: {
      BhSelectNew
    },
    data() {
      return {
        value:  {}
      }
    },
    computed: {
      ...mapState('dashboard', ['currentInterval']),
      ...mapState('user', ['user', 'lang']),
      list () {
        return [
          {
            label: `1`,
            value: 1,
          },
          {
            label: `3`,
            value: 3,
          },
          {
            label: `7`,
            value: 7,
          },
          {
            label: `14`,
            value: 14,
          },
          {
            label: `30`,
            value: 30,
          }
        ]
      },
      translates () {
        return {
          1: this.$t('payments.day'),
          3: this.$t('payments.short-days'),
          7: this.$t('payments.days'),
          14: this.$t('payments.days'),
          30: this.$t('payments.days'),
        }
      }
    },
    methods: {
      ...mapActions('dashboard', ['getDashboard']),
      ...mapMutations('dashboard', ['setCurrentInterval']),
      setDays (value) {
        this.setCurrentInterval(value.value)
        this.getDashboard(value.value)
      }
    },
    watch: {
      'user.lang': {
        immediate: true,
        handler () {
          this.value = this.list.find(({ value }) => value === this.currentInterval)
        }
      }
    },
    // created() {
    //   this.value = this.list.find(({ value }) => value === this.currentInterval)
    // }
  }
</script>

<template>
<bh-select-new class="select" v-model="value" :list="list" :key="user.lang" :uniq-key="'value'" @input="setDays">
  <template #info="item">
    <div class="item" :key="user.lang">
      <div class="label">{{ item.label }} {{ translates[item.value] }}</div>
    </div>
  </template>
  <template #selected="item" >
    <div class="item" :key="user.lang">
      <div class="label">{{ item.label }} {{ translates[item.value] }}</div>
    </div>
  </template>
</bh-select-new>
</template>

<style scoped lang="scss">
.select {
  width: 137px;
}
.item {
  display: flex;
  align-items: center;
  gap: 5px;
}
.label {
  color: var(--text-text-white, #FFF);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  padding-bottom: 0;
}
.icon {
  height: 20px;
  width: 20px;
}
::v-deep {
  .options-list {
    padding: 0 !important;
  }
  .options-list-item {
    border-radius: 0 !important;
    padding: 10px 20px !important;
    height: 48px !important;
  }
  .options-list-item:hover {
    background: var(--bg-background-primary-purple) !important;
    .label {
      color: var(--text-text-white) !important;
    }
  }
  .new-bh-select-wrapper .input-wrapper .options-list .options-list-item.active {
    background: var(--bg-background-primary-purple) !important;
    .label {
      color: var(--text-text-white) !important;
    }
  }
  .options-list-item {
    display: flex;
    align-items: center;
  }
}
</style>
