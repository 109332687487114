<template>
    <div>
        <div v-for="(role, i) in paginatedSortedFees"
             :key="i"
             class="organization-list-body"
        >
            <div class="organization-list-body__item">
                <span>{{ role.name }}</span>
            </div>
            <div class="organization-list-body__item">
                <div class="organization-list-body__icon-wrapper">
                    <info-button @click="changeRole(role)" />
                    <delete-button @click="deleteRole(role)"/>
                </div>
            </div>
        </div>
        <div class="organization-list-body_mobile"></div>
        <pagination :page-size="pageSize" :current="page" @page-change="paginate"
                    :total="sortedFees.length"/>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import pagination from "@/public/components/common/uiKit/Pagination.vue";
import _ from "lodash";
import InfoButton from "@/public/components/common/uiKit/info-button/index.vue";
import DeleteButton from "@/public/components/common/uiKit/delete-button/index.vue";

export default {
    name: 'OrganisationListBody',

    components: {
        DeleteButton, InfoButton,
        pagination,
    },

    data() {
        return {
            page: 1,
            pageSize: 10,
        };
    },

    methods: {
        ...mapActions('organizations', [
            'getOrganizationRole',
            'deleteOrganizationRole',
            'getOrganizationRoles',
        ]),
        ...mapActions('app', ['openModal']),

        deleteRole(currentRole) {
            this.$store.commit('organizations/setDetailRoleId', currentRole.id);
            this.openModal({
                name: 'RemoveRoleModal',
                payload: currentRole.name,
            });
        },

        changeRole(currentRole) {
            this.$store.commit('organizations/changeDetailRole', true);
            this.$store.commit('organizations/setDetailRoleId', currentRole.id);
            this.$store.commit('organizations/setChangeRoleStatus', true);
            this.getOrganizationRole();
        },

        paginate(page) {
            this.page = page;
        },
    },

    computed: {
        ...mapState('organizations', [
            'listRoles',
        ]),

        mobileWidth() {
            return window.innerWidth <= 767;
        },

        sortedFees() {
            return _.sortBy(this.listRoles);
        },

        paginatedSortedFees() {
            return this.sortedFees.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
        },
    },
};
</script>

<style lang="scss" scoped>
.organization-list-body {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  align-items: center;
  position: relative;

  &_mobile {
    display: none;
  }

  &__item {
      font-family: $Gilroy;
      font-style: normal;
      font-weight: 500;
      font-size: $h4;
      line-height: 23px;
      min-height: 88px;
      //background: var(--secondary-color);
      //border-top: 1px solid var(--border-color);
      border-bottom: 1px solid var(--new-front-divider-color);
      text-align: start;
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--new-front-primary-font-color);

    @media screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 16px;
    }

    span {
      z-index: 2;
      pointer-events: none;
    }

    &:first-of-type {
      padding-left: 30px;

      @media screen and (max-width: 768px) {
        padding-left: 20px;
      }
    }

    &:nth-child(2) {
      padding-right: 50px;
      justify-content: flex-end;

      @media screen and (max-width: 768px) {
        padding-right: 20px;
      }
    }

    &-text {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;

      &_deposit {
        color: var(--green-200);
      }

      &_credit {
        color: var(--red-200);
      }
    }
  }

  &__icon-wrapper {
    z-index: 2;
    display: flex;
      gap: 10px;
  }

  &__icon {
    width: 60px;
    height: 40px;
    margin-right: 15px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s ease;

    @media screen and (max-width: 768px) {
      width: auto;
      height: auto;
      border: none;
      border-radius: 0;
    }

    &:hover {
      border: 1px solid #0059F7;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 1330px) {
  .transactions-list-body {
    &__item {
      &:first-of-type {
        padding-left: 15px;
      }

      &:last-of-type {
        padding-right: 15px;
      }
    }
  }

}

@media screen and (max-width: 950px) {
  .transactions-list-body {
    display: none;

    &_mobile {
      display: block;
    }

  }

}
</style>
