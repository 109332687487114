import {rebuild_client} from "@/public/graphQl/apiClient";
import {getPinnedMenu} from "@/public/store/modules/menu/actions/getPinnedMenu";

export const init = async ({state, commit, dispatch }) => {
    if (!state.authorization.login && !state.authorization.twoFa) return

    await dispatch('user/fetchUser', null, { root: true });
    await dispatch('fetchSelectedOrganization');
    await dispatch('fetchOrganizations');
    await dispatch('fetchCurrencies');
    await dispatch('accounts/fetchAccounts', null, { root: true });

    // await dispatch('organizations/getOrganizationRoles', null, { root: true });
    await dispatch('organizations/getUserOrganizationRole', null, { root: true });
    await dispatch('organizations/getOrganization', null, { root: true });
    await dispatch('accounts/accountsSubscriptions', null, { root: true });
    await dispatch('balances/addressBalancesSubscription', null, { root: true });
    await dispatch('menu/getPinnedMenu', null, { root: true });

    await dispatch('summary/getSummary', null, { root: true });
    await dispatch('balances/getAccountBalancesBusiness', { includeArchived: true }, { root: true });

    setInterval(() => {
        //обновляем саммари балансов каждые 30 секунд
        dispatch('summary/getSummary', null, { root: true });
    }, 30000)
    commit('isInit', true);
};
