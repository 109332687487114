<script>
    export default {
        name: "BaseCheckbox",
        props: {
            value: {
                type: [String, Number, Boolean, Array],
                default: false
            },
            label: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false,
            }
        },
        data () {
            return {
                model: false,
            }
        },
        watch: {
            model (v) {
                this.$emit('input', v)
            },
            value: {
                immediate: true,
                handler (v) {
                    if (v !== this.model) {
                        this.model = v
                    }
                }
            }
        }
    }
</script>

<template>
  <at-checkbox :class="['checkbox', model ? 'checkbox--checked' : '', disabled ? 'disabled' : '']" v-model="model"><slot><span class="checkbox-label">{{ label }}</span></slot></at-checkbox>
</template>

<style scoped lang="scss">
.disabled {
  opacity: .6;
  pointer-events: none;
}
.checkbox-label {
  font-family: $Gilroy;
  font-size: $h4;
  color: var(--new-front-primary-font-color)
}
.checkbox--checked {
  ::v-deep {
    .at-checkbox__inner {
      border-color: var(--new-front-purple) !important;
      background-color: var(--new-front-purple) !important;
      border: 0 !important;
    }
  }
}
::v-deep {

  .at-checkbox__inner {
    width: 20px;
    height: 20px;
    border: 2px solid var(--new-front-input-border);
    background: transparent;
    &:hover {
      border-color: var(--theme-switcher-border);
    }
  }

  .at-checkbox__inner::after {
    position: relative !important;
    top: -2px !important;
    border-radius: 1px;
    width: 8px;
    height: 12px;
  }
  .at-checkbox + .at-checkbox {
    margin: 0 !important;
  }
}
.checkbox {
  margin-left: 0 !important;
}
</style>
