<script>

  import SvgGreenArrowUp from "@/public/components/elements/dashboard/assets/SvgGreenArrowUp.vue";
  import SvgRedArrowDown from "@/public/components/elements/dashboard/assets/SvgRedArrowDown.vue";
  import {mapGetters} from "vuex";

  export default {
    name: "WidgetsPercentLabel",
    components: {SvgRedArrowDown, SvgGreenArrowUp},
    props: {
      percent: {
        type: [String, Number],
        default: "0",
      },
      value: {
        type: [String, Number],
        default: "0",
      },
      isCurrency: {
        type: Boolean,
        default: true,
      }
    },
    computed: {
      ...mapGetters('dashboard', ['currentSymbol']),
    }
  }

</script>

<template>
<div :class="['percent', { down: value < 0 }]">
  <SvgGreenArrowUp v-if="value >= 0" />
  <SvgRedArrowDown v-else />
  {{ value > 0 ? '+' : '' }}{{ value }}
  <template v-if="isCurrency">
    $
  </template>
  <template v-if="percent">
    ({{ percent }}%)
  </template>
</div>
</template>

<style scoped lang="scss">
.percent {
  height: 26px;
  width: fit-content;
  border-radius: 6px;
  background: var(--ocp-status-success-success-bg);
  display: flex;
  align-items: center;
  padding: 6px 8px;
  gap: 2px;
  color: var(--ocp-status-success-success-primary);
  font-size: 14px;
  font-weight: 500;
  &.down {
    background: var(--ocp-0-100-error-error90);
    color: var(--ocp-0-100-error-error40);
  }
}
</style>
