import gql from 'graphql-tag';

export default gql`
    mutation($organizationId: String!, $pageId: String!) {
        deletePreCheckout(organizationId: $organizationId, pageId: $pageId) {
            code,
            result
        }
    }
`;
