import {fetchWidgets} from "@/public/store/modules/widgetsManage/actions/fetchWidgets";
import {createWidget} from "@/public/store/modules/widgetsManage/actions/createWidget";
import {fetchWidget} from "@/public/store/modules/widgetsManage/actions/fetchWidget";
import {deleteWidget} from "@/public/store/modules/widgetsManage/actions/deleteWidget";
import {updateWidget} from "@/public/store/modules/widgetsManage/actions/updateWidget";
import {fetchWidgetHost} from "@/public/store/modules/widgetsManage/actions/fetchWidgetHost";

export const actions = {
    fetchWidgets,
    fetchWidget,
    fetchWidgetHost,
    createWidget,
    deleteWidget,
    updateWidget,
};
