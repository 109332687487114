import gql from 'graphql-tag';

// источник обмена Payin wallet

export default gql`
    query($organizationId: String!, $accountId: String!) {
        getAccountBalancesGrouped(organizationId: $organizationId, accountId: $accountId) {
            currencyGroups {
                currencyId
                balance
                networkGroups {
                    networkId
                    balance
                    addresses {
                        id
                        accountId
                        currencyId
                        networkId
                        address
                        tag
                        balance
                        isBanned
                    }
                }
            }
        }
    }
`;
