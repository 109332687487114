<template>
    <div class="withdraw-address example__table-row">
        <div class="example__table-col col">
            <AtInput
                class="withdraw-address__input"
                size="small"
                :status="amountStatus"
                v-model="data.amount"
            />
        </div>
        <div class="example__table-col col">
            <AtInput
                class="withdraw-address__input"
                size="small"
                :status="addressStatus"
                v-model="data.address"
            />
        </div>
        <div class="example__table-col col">
            <AtInput
                class="withdraw-address__input"
                size="small"
                v-model="data.tag"
            />
        </div>
        <div class="example__table-col col-1">
            <AtButton
                size="small"
                icon="icon-trash-2"
                @click="$emit('removeAddress', index)"
            />
        </div>
    </div>
</template>

<script>

import AtInput from 'at-ui/src/components/input';
import AtButton from "at-ui/src/components/button";

export default {
    name: 'WithdrawAddress',
    components: {
        AtInput,
        AtButton,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    computed: {
        amountStatus () {
            return !this.data.amount ? 'error' : '';
        },
        addressStatus () {
            return !this.data.address ? 'error' : '';
        },
    },
};

</script>

<style lang="scss" scoped>

    .withdraw-address {
        &__input {
            width: 90%;
        }
    }

</style>