export const PRODUCT_NAME = VITE_PRODUCT_NAME;

export const API_HTTP_URL = '/api';
export const API_SOCK_URL = '/api';
export const API_USE_SOCK = '1';

export const SOCKET_IO_URL = '/';
export const SOCKET_IO_PATH = '/stream';

export const LOGIN_URL = '/login';

export const CHECKOUT_FETCH_URL = '/';
export const INVOICE_FETCH_URL = '/';

export const isApollo = import.meta.env.VITE_PRODUCT_NAME === 'Apollopayment'
export const isAlbertCrypto = import.meta.env.VITE_PRODUCT_NAME === 'AlbertCrypto'
export const isOnchainpay = import.meta.env.VITE_PRODUCT_NAME === 'Onchainpay'

export const DOCS_API = {
    "en": [
        {
            source: 'PDF',
            link: import.meta.env.VITE_DOCS_EN_PDF,
        },
        {
            source: 'Postman',
            link: import.meta.env.VITE_DOCS_EN_POSTMAN,
        }],
    "ru": [
        {
            source: 'PDF',
            link: import.meta.env.VITE_DOCS_RU_PDF,
        },
        {
            source: 'Postman',
            link: import.meta.env.VITE_DOCS_RU_POSTMAN,
        },
    ],
};
