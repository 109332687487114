import gql from 'graphql-tag';

export default gql`
    mutation deleteUserRole($organizationId: String!, $userId: String!) {
        deleteUserRole(organizationId: $organizationId, userId: $userId) {
            code
            result
        }
    }
`;
