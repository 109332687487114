<template>
  <img
     class="currency-img"
     :class="[ size ]"
     :src="currentNetworkImg"
     :alt="network"
  >
</template>

<script>

export default {
  name: 'NetworkImg',
  props: {
    network: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'normal'
    }
  },
  computed: {
    currentNetworkImg({ network }) {
      return `/assets/image/currenciesAndNetworks/networks/${network.toUpperCase()}.svg`;
    },
  },
};
</script>

<style lang="scss">
  .currency-img {
    &.small { //размер поставлен пока просто так
      width: 16px;
      height: 16px;
    }
    &.normal { //размер используется
      width: 20px;
      height: 20px;
    }
    &.big {
      width: 24px;
      height: 24px;
    }
  }
</style>
