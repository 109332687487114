<template>
    <div class="transactions">
        <h1 class="title">
            Rolling Reserve
        </h1>
        <RollingReserveTopBar class="top-bar" />
        <div class="transactions__list">
            <div class="transactions__table">
                <RollingReserveListHeader class="list-header" />
                <template v-if="hasItems">
                    <PreviewRollingReserve
                        v-for="(rr, key) in result"
                        :key="key"
                        class="transaction"
                        :data="rr"
                    />
                </template>
                <EmptyTable
                    v-else
                    class="empty"
                >
                    Нет данных
                </EmptyTable>
            </div>
            <div v-if="hasItems" class="pagination">
                <AtPagination
                    :total="totalCount"
                    :page-size="countOnPage"
                    :current="currentPage"
                    @page-change="changePage"
                />
            </div>
        </div>
    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';

import AtPagination from "at-ui/src/components/pagination";
import EmptyTable from "/public/components/elements/EmptyTable";
import RollingReserveTopBar from "../components/elements/rolling-reserve/RollingReserveTopBar";
import RollingReserveListHeader from "../components/elements/rolling-reserve/RollingReserveListHeader";
import PreviewRollingReserve from "../components/elements/rolling-reserve/PreviewRollingReserve";

export default {
    name: 'RollingReservePage',
    components: {
        PreviewRollingReserve,
        RollingReserveListHeader,
        RollingReserveTopBar,
        AtPagination,
        EmptyTable,
    },
    computed: {
        ...mapState('app', [
            'isInit',
        ]),
        ...mapState('rollingReserve', [
            'countOnPage',
            'result',
            'totalCount',
        ]),
        currentPage () {
            const pageFromUrl = this.$route.query.page;

            if (!pageFromUrl) {
                return 1;
            }

            return Number(pageFromUrl);
        },
        hasItems () {
            return !!this.result.length;
        },
    },
    mounted () {
        const {
            $route,
            isInit,
            fetchRollingReserves,
        } = this;

        if (isInit) {
            fetchRollingReserves({
                route: $route,
                showUrlFilters: false,
            });
        }
    },
    methods: {
        ...mapActions('rollingReserve', [
            'fetchRollingReserves',
        ]),
        changePage (pageNumber) {
            const {
                $route,
                $router,
                fetchRollingReserves,
            } = this;

            fetchRollingReserves({
                route: $route,
                router: $router,
                page: pageNumber,
            });
        },
    },
    watch: {
        isInit () {
            const {
                $route,
                fetchRollingReserves,
            } = this;

            fetchRollingReserves({
                route: $route,
                showUrlFilters: false,
            });
        },
    },
};

</script>

<style lang="scss" scoped>

    .transactions {

        .title {
            margin-bottom: 24px;
            @include h1;
        }

        .top-bar {
            margin-bottom: 42px;
        }

        .list {
            margin-left: -4px;
            margin-right: -4px;
        }

        .table {
            width: 100%;
            overflow: hidden;
            overflow-x: auto;
        }

        .pagination {
            margin-top: 24px;
            display: flex;
            justify-content: center;
        }

        .empty {
            margin-left: 0;
            margin-right: 0;
        }
    }

</style>
