import gql from 'graphql-tag';

export default gql`
     mutation($formName: String!, $formContent: Json!) {
         createFeedback(formName: $formName, formContent: $formContent) {
            code
            result
        }
    }
`;
