<template>
    <Example>
        <template #title>
            Текущее значение курса (exchange-rate)
        </template>
        <template #settings>
            <AtButton
                type="primary"
                size="large"
                @click="makeRequest"
            >
                Сделать запрос
            </AtButton>
        </template>
        <template
            v-if="hasResponse"
            #response
        >
            {{ response }}
        </template>
    </Example>
</template>

<script>

import Example from './Example';
import AtButton from "at-ui/src/components/button";

export default {
    name: 'ExchangeRate',
    components: {
        Example,
        AtButton,
    },
    data () {
        return {
            response: null,
        };
    },
    computed: {
        hasResponse () {
            return Boolean(this.response);
        },
    },
    methods: {
        makeRequest () {},
    },
};

</script>