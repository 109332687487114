import createPreCheckoutMutation from "/public/graphQl/mutations/createPreCheckout";
import uploadPreCheckoutLink from "/public/graphQl/mutations/uploadPreCheckoutLink";
import axios from "axios";

export const createPreCheckoutDonation = async ({dispatch, rootGetters}, {
    accountId,
    url,
    alias,
    title,
    description,
    avatarLink,
    theme,
    currencies,
    avatarFile,
}) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    let preCheckoutData;

    try {
        preCheckoutData = await dispatch('app/request', {
            type: 'mutation',
            gql: createPreCheckoutMutation,
            variables: {
                organizationId,
                accountId,
                url,
                alias,
                title,
                description,
                avatarLink,
                theme,
                currencies,
            },
        }, {root: true});


    } catch (error) {
        return error;
    }


    if (preCheckoutData.message) {
        throw preCheckoutData.message;
    }

    if (preCheckoutData.errors) {
        throw preCheckoutData.errors;
    }

    const {
        data: {
            createPreCheckout,
        },
    } = preCheckoutData;

    if (avatarFile) {

        let avatarLinkData;
        try {
            avatarLinkData = await dispatch('app/request', {
                type: 'mutation',
                gql: uploadPreCheckoutLink,
                variables: {
                    organizationId,
                    pageId: preCheckoutData.data.createPreCheckout.result,
                },
            }, {root: true});


        } catch (error) {
            return error;
        }

        const {
            data: {
                uploadPreCheckoutLink: {
                    code,
                    result,
                },
            },
        } = avatarLinkData;
        let uploadedAvatar;
        const fileType = avatarFile.get('file').type;
        const avatarFileRaw = avatarFile.get('file');
        try {
            uploadedAvatar = axios.put(result, avatarFile, {
                headers: {
                    'Content-Type': fileType,
                },
            });
        } catch (e) {
            console.log(e.response);
        }
    }
    return createPreCheckout;
};