import getBankAccountValidationDataQuery from "/public/graphQl/queries/getBankAccountValidationData";

export const fetchBankAccountValidationData = async ({state, rootState, rootGetters, commit, dispatch}) => {
    let accountsData;

    try {
        commit('app/toggleLoading', null, {root: true});
        accountsData = await dispatch('app/request', {
            type: 'query',
            gql: getBankAccountValidationDataQuery,
        }, {root: true});
    } catch (error) {
        console.error(error);
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (!accountsData.message && !accountsData.errors) {
        const {
            data: {
                getBankAccountValidationData
            },
        } = accountsData;

        commit('setBankAccountsValidation', getBankAccountValidationData);
    }

    return true;
};
