<script>
  export default {
    name: "apolloPasswordValidation",
    props:{
      password: {
        type: String,
        default: '',
      }
    },
    data () {
      return {
        rules: [
          {
            rule: 'length',
            title: 'minimum-characters',
            passed: false
          },
          {
            rule: 'case',
            title: 'uppercase-restriction',
            passed: false
          },
          {
            rule: 'specChar',
            title: 'spec-char',
            passed: false
          },
          {
            rule: 'number',
            title: 'number-char',
            passed: false
          },
        ],
      }
    },
    computed: {
      title () {
        const title = this.rules.find(item => !item.passed)?.title
        return title ? title : false
      },
      passedRulesCount () {
        return this.rules.filter(item => item.passed).length
      },
      lineColor () {
        const {passedRulesCount, rules} = this;
        return passedRulesCount === rules.length ? '#5BB24D' : passedRulesCount === 2 || passedRulesCount === 3 ? '#BE9B40' : '#BE4040'
      }
    },
    watch: {
      password: {
        handler (value) {
          this.rules[0].passed = value.length >= 6

          const caseRegexp = /^(?=.*[a-z])(?=.*[A-Z]).*$/g
          this.rules[1].passed = value.match(caseRegexp)

          const specCharRegexp = /\W+/g
          this.rules[2].passed = value.match(specCharRegexp)

          const numberRegexp = /\d/g
          this.rules[3].passed = value.match(numberRegexp)
        }
      },
    },
  }

</script>

<template>
<div class="validation">
  <div class="bar">
    <div class="line" :style="{width: `${passedRulesCount ? 25 * passedRulesCount : 25}%`, background: lineColor}"></div>
  </div>
  <div class="rules">
    <span><template v-if="title">{{ $t(`authorization.${title}`) }}</template></span>
    <span>{{ passedRulesCount }}/{{ rules.length }}</span>
  </div>
</div>
</template>

<style scoped lang="scss">
.validation {
  font-family: $Gilroy;
  margin-bottom: 20px;
}
.bar {
  width: 100%;
  height: 8px;
  border-radius: 999px;
  background: #000;
  position: relative;
  margin-bottom: 10px;
}
.line {
  position: absolute;
  left: 0;
  width: 33%;
  height: 8px;
  border-radius: 999px;
  background: #BE4040;
  transition: width .2s ease, background .2s ease;
}
.rules {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: #000000;
    font-weight: 500;
  }
}
</style>
