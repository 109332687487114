<template>
  <div
    class="cross-chain-history__top-bar"
  >
    <div class="first-line">
      <div class="long">
        <currency-select
          v-model="currencyModel"
          :filter-fields="[ 'allowCrosschainTransfer' ]"
          style="flex-grow: 1"
        />
      </div>
      <div class="short">
        <filters-mobile
          :filters.sync="filters"
          class="filters-mobile"
          @networks="networks = $event"
        />
        <date-picker
          v-model="periodModel"
          :close-method.sync="pickerClose"
          range
          clearable
          class="date-picker"
          style="flex-grow: 1"
        />
        <downloader
          v-model="selectedDownloadType"
          class="file-downloader"
          type="secondary"
        />
        <refresh-button @click="parseUrl" />
      </div>
    </div>
    <div class="second-line">
      <div class="long">
        <div class="network_from item">
          <network-select
            v-model="networkFromModel"
            :placeholder="$t('bridge.history.network_from_placeholder')"
            networks.sync="networks"
            clearable
          />
        </div>
        <div class="network_to item">
          <network-select
            v-model="networkToModel"
            :placeholder="$t('bridge.history.network_to_placeholder')"
            :networks.sync="networks"
            clearable
          />
        </div>
      </div>
      <div class="short">
        <div class="status-select item">
          <status-select
            v-model="statusModel"
            :placeholder="$t('bridge.history.status_placeholder')"
            :statuses.sync="statusesList"
            clearable
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'

import BhSelectNew from "/public/components/common/bh-select-new"

import BaseInput from "/public/components/elements/cross-chain/base/input"

import PeriodSelect from "/public/components/elements/cross-chain/History/selects/periodSelect"

// import { networkAddressBook } from "../../../../store/modules/addressBook/actions/networkAddressBook"

import { getFormatForUrlByArray } from "/public/utils/getFormatForUrlByArray"

import isArray from "lodash/isArray"

import { Router } from "/public/mixins/router"
import _ from "lodash";

export default {
    name: 'TransactionsTopBar',
    components: {
      BaseInput,
      BhSelectNew,
      PeriodSelect,

      StatusSelect: () =>  import(/* webpackChunkName: "public/components/elements/cross-chain/History/selects/statusSelect" */ '/public/components/elements/cross-chain/History/selects/statusSelect'),
      Downloader: () => import(/* webpackChunkName: "public/components/common/uiKit/downloader" */ '/public/components/common/uiKit/downloader'),
      RefreshButton: () => import(/* webpackChunkName: "public/components/common/uiKit/button/refresh" */ '/public/components/common/uiKit/button/refresh'),
      DatePicker: () => import(/* webpackChunkName: "public/components/common/uiKit/DatePickerNew" */ '/public/components/common/uiKit/DatePickerNew'),
      CurrencySelect: () => import(/* webpackChunkName: "public/components/elements/cross-chain/History/selects/currencySelect" */ '/public/components/elements/cross-chain/History/selects/currencySelect'),
      NetworkSelect: () => import(/* webpackChunkName: "public/components/elements/cross-chain/History/selects/networkSelect" */ '/public/components/elements/cross-chain/History/selects/networkSelect'),

      FiltersMobile: () => import(/* webpackChunkName: "public/components/elements/cross-chain/History/filtersMobile" */ '/public/components/elements/cross-chain/History/filtersMobile'),
    },
    data() {
        return {
            currentOffset: 0,
            showFilters: true,

            filters: {
              currencyId: [],
              createdAt: [],
              networkToId: [],
              networkFromId: [],
              status: [],

              id: [],
              clientId: [],
              amount: [],
              addressFromId: [],
              addressToId: []
            },
            urlParsing: true,
            selectedDownloadType: undefined,
            pickerClose: () => {},
            networks: [],
            statusesList: []
        };
    },
    mixins:[ Router ],
    watch: {
      pagination: {
        handler(v) {
          //дописываем данные пагинации в фильтры
          ['offset', 'limit', 'sort'].forEach(item => {
            this.$set(this.filters, item, v[item])
          })
        },
        immediate: true,
        deep: true
      },
      filters: {
        handler(v) {
          //важный момент - если любой из фильтров изменился но при этом не меняется offset
          //то offset надо сбрасывать на 0 так как количество элементов в списке уменьшилось
          //а если мы например были на 3 странице то ее может уже не существовать...
          //для этого сразвниваем новый offset с последним сохраненным - currentOffset
          if (v.offset === this.currentOffset && v.offset !== 0) {
            this.$emit('go-to-first-page') //шлем родителю события для сброса страницы в пагинации
            return
          }

          this.currentOffset = v.offset

          if (!this.urlParsing) {
            this.getTransfers()
          }
        },
        immediate: false,
        deep: true
      },
      selectedDownloadType(v) {
        if (!!v) {
          const {
            filters
          } = this;

          const payload = {
            currencyId: filters.currencyId.map(({ id }) => id),
            networkToId: filters.networkToId.map(({ id }) => id),
            networkFromId: filters.networkFromId.map(({ id }) => id),
            status: filters.status.map(({ id }) => id),
            createdAt: filters.createdAt
          }

          Object.keys(payload).forEach((key) => {
            if (!payload[key].length) {
              this.$delete(payload, key)
            }
          })

          if (v.id === 'csv') {
            this.downloadCSV({ payload })
          } else {
            this.downloadXLSX({ payload })
          }

          this.selectedDownloadType = undefined
        }
      },
      isEmptyFilters: {
        handler(v) {
          this.$nextTick(() => {
            this.$emit('is-empty-filters', v)
          })
        },
        immediate: true
      }
    },
    props: {
      pagination: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
        ...mapState('transactions', [
            'transactionsCountOnPage',
        ]),
        ...mapState('app', [
          'currencies',
        ]),
        currencyModel: {
          get({ filters }) {
            return filters.currencyId[0]
          },
          set(v) {
            if (v) {
              this.$set(this.filters.currencyId, 0, v)
            } else {
              this.$set(this.filters, 'currencyId', [])
            }
          },
        },
        periodModel: {
          get({ filters }) {
            return filters.createdAt
          },
          set(v) {
            this.$set(this.filters, 'createdAt', v)
          },
        },
        networkFromModel: {
          get({ filters }) {
            return filters.networkFromId[0]
          },
          set(v) {
            if (v) {
              this.$set(this.filters.networkFromId, 0, v)
            } else {
              this.$set(this.filters, 'networkFromId', [])
            }
          },
        },
        networkToModel: {
          get({ filters }) {
            return filters.networkToId[0]
          },
          set(v) {
            if (v) {
              this.$set(this.filters.networkToId, 0, v)
            } else {
              this.$set(this.filters, 'networkToId', [])
            }
          },
        },
        statusModel: {
          get({ filters }) {
            return filters.status[0]
          },
          set(v) {
            if (v) {
              this.$set(this.filters.status, 0, v)
            } else {
              this.$set(this.filters, 'status', [])
            }
          }
        },
        isEmptyFilters({ currencyModel, periodModel, networkFromModel, networkToModel, statusModel }) {
          return _.isEmpty(currencyModel) && _.isEmpty(periodModel) && _.isEmpty(networkFromModel) && _.isEmpty(networkToModel) && _.isEmpty(statusModel)
        }
    },
    async mounted() {
      await this.networkAddressBook()
      this.parseUrl()
    },
    methods: {
        ...mapActions('crossChain', [
            'downloadCSV',
            'downloadXLSX',
            'getCrosschainTransfersList'
        ]),
        ...mapActions('addressBook', [
          'networkAddressBook',
        ]),
        parseUrl() {
          this.urlParsing = true

          const { $route } = this

          Object.keys($route.query).forEach(key => {
            const stringKeys = ["offset", "limit"]

            if ( $route.query[key] || $route.query[key] === "0" ) {
              if (stringKeys.includes(key)) {
                this.filters[key] =  Number($route.query[key])
              } else {
                let value = $route.query[key]

                if (key.includes("currencyId")) {
                  value = this.currencies.find(({ id }) => id === value)
                }
                if (key.includes("networkFromId")) {
                  value = this.networks.find(({ id }) => id === value)
                }
                if (key.includes("networkToId")) {
                  value = this.networks.find(({ id }) => id === value)
                }
                if (key.includes("status")) {
                  value = this.statusesList.find(({ id }) => id === value)
                }
                if (key.includes("startDate")) {
                  this.$set(this.filters['createdAt'], 0, new Date( this.$route.query[key]))
                  return
                }
                if (key.includes("endDate")) {
                  this.$set(this.filters['createdAt'], 1, new Date( this.$route.query[key]))
                  return
                }

                if (key.includes("sort")) {
                  this.$set(this.filters, 'sort', this.$route.query[key])
                  return
                }

                this.filters[key] = [ value ]
              }
            }
          })

          this.urlParsing = false
          this.getTransfers()
        },
        async getTransfers() {
          this.$emit('loading', true)

          const data = Object.keys(this.filters).reduce((acc, key) => {
            if ( isArray(this.filters[key]) ) {
              //преобразуем массивы объектов в массивы id-ников
              if (this.filters[key].length) {
                const { id } = this.filters[key][0]

                if (id) {
                  acc[key] = [id]
                } else {
                  acc[key] = this.filters[key]
                }
              } else {
                acc[key] = []
              }
            } else {
              acc[key] = this.filters[key]
            }

            return acc
          }, {})

          const { offset, limit, sort } = this.pagination

          const params = {
            offset,
            limit,
            sort,
            currencyId: getFormatForUrlByArray(data.currencyId, (array) => array.join(',')),
            networkFromId: getFormatForUrlByArray(data.networkFromId, (array) => array.join(',')),
            networkToId: getFormatForUrlByArray(data.networkToId, (array) => array.join(',')),
            status: getFormatForUrlByArray(data.status, (array) => array.join(',')),
            startDate: data.createdAt[0] ? data.createdAt[0].toString() : undefined,
            endDate: data.createdAt[1] ? data.createdAt[1].toString() : undefined,
          }

          this.setRouterParams(params)

          const result = await this.getCrosschainTransfersList(data)

          this.$emit('loading', false)

          this.$emit("transfers-list", result.transfers || [])
          this.pagination.total = result.count || 0
        },
        setDefaultDates() {
            const dateFromUrl = this.$route.query.date;

            if (!dateFromUrl) {
              return false;
            }

            this.filters.createdAt = dateFromUrl.split('to').map(date => new Date(date));
        }
    }
};

</script>

<style lang="scss">
.cross-chain-history__top-bar {
  display: flex;
  flex-direction: column;
  gap: 30px;

  margin-bottom: 30px;

  .first-line {
    display: flex;
    align-items: end;
    gap: 10px;
    width: 100%;

    .long {
      display: flex;
      align-items: center;

      flex-grow: 2;
      flex-basis: 0;
    }
    .short {
      display: flex;
      align-items: center;
      gap: 10px;

      flex-grow: 1;
      flex-basis: 0;

      .filters-mobile {
        display: none;
      }
    }
  }
  .second-line {
    display: flex;
    flex-wrap: wrap;

    gap: 10px;
    width: 100%;

    .long {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      gap: 10px;

      .network_from, .network_to {
        flex-grow: 1;
      }

      flex-grow: 2;
      flex-basis: 0;
    }
    .short {
      display: flex;
      align-items: center;

      .status-select {
        flex-grow: 1;
      }

      flex-grow: 1;
      flex-basis: 0;
    }

    @include below_1180() {
      flex-direction: column;
      .long {
        flex-wrap: wrap;
        .network_from, .network_to {
          min-width: 320px;
        }
      }
      .short {
        min-width: 320px;
      }
    }
  }

  .filters {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: fit-content!important;

    width: 100%;

    .item {
      width: 47%;

      .cross-chain-history__period {
        .base-period-select__title {
          margin-bottom: 5px;
          font-size: $h4;
          line-height: $h4;
        }
        .base-period-select__date {
          .mx-input-wrapper {
            input {
              background: var(--main-bg);
              box-shadow: inset 0 4px 20px var(--main-bg);
              border: 1px solid var(--main-bg);

              font-size: 16px;
            }
            .mx-icon-clear {
              color: var(--secondary-font-color);
            }
          }
        }
      }

      &:not(.filters) {
        margin-bottom: 25px;
      }
    }
  }

  @include below_1180 {
    .first-line {
      .long,.date-picker {
        display: none;
      }
      .short {
        justify-content: end;

        .filters-mobile {
          display: block;

          .date-picker {
            display: block;
          }
        }
      }
    }
    .second-line {
      display: none;
    }
  }
}
</style>