import GetAccountBalancesQueryBusiness from "/public/graphQl/queries/getBusinessAddresses";

export const getAccountBalancesBusiness = async ({commit, dispatch, rootGetters, rootState}, payload) => {

    const isAllowed = rootGetters['organizations/userRoleRules']['wallets_business'].isAllowed;

    if(!isAllowed) { return };

    let balancesData;

    const accountId = rootState.accounts.accounts[0].accountId;

    if (!payload.includeArchived) {
        payload.includeArchived = false
    }

    const variables = {
        ...payload
    }

    if (!variables.organizationId) {
        variables.organizationId = rootGetters['app/getSelectedOrganization'].id
    }

    if (!variables.accountId) {
        variables.accountId = accountId
    }

    try {
        balancesData = await dispatch('app/request', {
            type: 'query',
            gql: GetAccountBalancesQueryBusiness,
            variables
        }, {root: true});
    } catch (error) {
        return error;
    }

    const { data: { getBusinessAddresses } = {} } = balancesData || {}

    commit('accountBalancesBusiness', getBusinessAddresses)

    return getBusinessAddresses || [];
};
