import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!) {
         getDefaultMerchantSettings(organizationId: $organizationId) {
            minSpendInterval
            maxSpendAmountUSD
            maxMonthSpendAmountUSD
            approveAmountUSD
            allowFreeSpend
        }
    }
`;
