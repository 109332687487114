<template>
<StoreModal
        name="EditApiKeyModal"
        class="create-new-api-keys-modal"
        :title="title"
        @closeModal="handleCancelCreate"
>
    <template #header>
        <div class="create-new-api-keys-modal__header">
            <div class="title">
                {{ title }}
            </div>
        </div>
    </template>
      <template #body>
          <div class="create-new-api-keys-modal__container">
              <div class="create-new-api-keys-modal__row">
                      <base-input
                              :status="aliasStatus"
                              :label="$t('api_keys.name_of_the_api_key')"
                              :placeholder="$t('api_keys.enter_a_new_api_key_name')"
                              v-model="alias"
                      />
                  <p class="error" v-if="aliasError">{{ aliasError }}</p>
              </div>
              <div class=" create-new-api-keys-modal__row create-new-api-keys-modal__api-keys-item">
                  <p class="create-new-api-keys-modal__api-keys-value">
                      <base-textarea
                              v-model="ipsString"
                              :placeholder="$t('api_keys.ip_addresses_placeholder')"
                              :label="$t('api_keys.ip_addresses')"
                              :maxlength="255"
                              size="large"
                              resize="none"
                      />
                  </p>
                  <p class="error" v-if="ipsError">{{ ipsError }}</p>
              </div>
          </div>
      </template>
    <template #footer>
      <div class="footer">
          <base-button type="secondary" class="button" :label="$t('api_keys.close')" @click="handleCancelCreate" />
          <base-button
                  class="button"
                  type="primary"
                  :label="$t('api_keys.edit')"
                  @click="save"
          />
      </div>
    </template>

  </StoreModal>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BaseModal from "../BaseModal";
import BaseInput from "@/public/components/common/uiKit/input/base/index.vue";
import BaseTextarea from "@/public/components/common/uiKit/textarea/index.vue";
import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";

export default {
    name: "EditApiKeyModal",
    components: {
        BaseButton,
        BaseTextarea,
        BaseInput,
        BaseModal,
        StoreModal: () => import(/* webpackChunkName: "/public/components/modals/StoreModal" */ '/public/components/modals/StoreModal'),
    },
    data() {
        return {
            isLoading: false,
            // apiKeys: null,
            alias: null,

            canSendForm: false,
            ipsString: '',
            ipsError: '',
            aliasError: '',
        };
    },
    computed: {
        ...mapState('app', {
            modalPayload: state => state.modal.payload,
        }),
        apiKeys () {
            if (this.modalPayload && this.modalPayload.public) {
                return {
                    public: this.modalPayload.public,
                    secret: this.modalPayload.secret
                }
            }
        },
        ipArray () {
            return this.ipsString.split(' ')
        },
        aliasStatus() {
            const {
                alias,
                canSendForm,
            } = this;

            return !alias && canSendForm ? 'error' : '';
        },
        name_of_the_api_key() {
            return this.$t('api_keys.name_of_the_api_key');
        },
        enter_a_new_api_key_name() {
            return this.$t('api_keys.enter_a_new_api_key_name');
        },
        cancel() {
            return this.$t('api_keys.cancel');
        },
        ...mapGetters('app', [
            'isOpenModal',
        ]),
        ...mapState('app', {
            modalPayload: state => state.modal.payload,
        }),
        title() {
            return this.$t('api_keys.editing_an_api_key');

        },
        isOpen() {
            const {
                $options: {
                    name,
                },
                isOpenModal,
            } = this;
            return isOpenModal(name);
        },
    },
    watch: {
        ipsString(newVal) {
            let re = /[^\d\s\.]/g;
            this.$set(this, 'ipsString', newVal.replace(re, ''));
        },
        isOpen (val) {
            if (val) {
                if (this.modalPayload.ips) {
                    this.ipsString = this.modalPayload.ips.join(' ')
                }
                this.alias = this.modalPayload.alias
            }
        }
    },
    methods: {
        ...mapActions('app', [
            'closeModal',
            'openModal',
        ]),
        ...mapActions('user', [
            'updateApiKeys',
            'fetchApiKeys',

        ]),
        clearAllData() {
            this.isLoading = false;
            // this.apiKeys = null;
            this.alias = null;
            this.canSendForm = false;
            this.ipsError = ''
            this.ipsString = ''
            this.aliasError = ''
        },
        handleCancelCreate() {
            const {
                clearAllData,
                closeModal,
            } = this;

            closeModal();

            setTimeout(() => {
                clearAllData();
            }, 500);
        },

        save() {
            const {
                modalPayload: {
                    apiKeysId,

                },
                $Notify,
                alias,
                updateApiKeys,
                fetchApiKeys,
            } = this;
            this.canSendForm = true;

            if (!alias) {
                this.aliasError = this.$t('api_keys.alias_error')
                return false;
            }
            this.aliasError = ''

            const ips = this.ipArray.filter(item => item.length)

            if (!ips.length) {
                this.ipsError = this.$t('api_keys.ip_exist')
                return;
            }

            ips.forEach(ip => {

                    if (
                        /^(10)\.(.*)\.(.*)\.(.*)$/.test(ip) ||
                        /^(172)\.(1[6-9]|2[0-9]|3[0-1])\.(.*)\.(.*)$/.test(ip) ||
                        /^(192)\.(168)\.(.*)\.(.*)$/.test(ip) ||
                        !/^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/.test(ip)
                    ) {
                        this.ipsError = `${this.$t('api_keys.ip_invalid')}: ${ip}`

                        return
                    }
                    this.ipsError = ''
                }
            )
            if (this.ipsError) {
                return;
            }
          this.openModal({
            name: 'TwoFaConfirmation',
            payload: {
                id: apiKeysId,
                alias,
                ips
            }
          });
        },
    },
};

</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  align-items: center;
  gap: 20px;
}
.create-new-api-keys-modal {
  &__container {
    padding: 0 20px;
  }
  &__header {
    text-align: center;

    .title {
      font-size: $h1;
      font-weight: 500;
      color: var(--new-front-primary-font-color);
      @media (max-width: 450px) {
        font-size: $h2;
      }
    }
  }
}
.create-new-api-keys-modal__row {
    display: flex;
    align-items: center;

    flex-direction: column;
    gap: 11px;
    &:first-child {
        margin-bottom: 20px;
    }
}
.create-new-api-keys-modal__api-keys-value {
  width: 100%;
}
textarea {
    background: var(--secondary-color);
    outline: none;
}

textarea {
    border: 1px solid var(--border-color);
    box-sizing: border-box;
    box-shadow: inset 0 4px 20px #00000008;
    border-radius: 12px;
    min-height: 140px;
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    padding: 15px;
}
textarea {
    width: 100%;
}
.error {
    color: var(--new-front-input-font-color-error);
    width: 100%;
}
.title {
  font-family: $Gilroy;
  font-size: $h1;
  font-weight: 500;
  color: var(--new-front-primary-font-color);
  @media (max-width: 576px) {
    font-size: $h3;
  }
}
</style>
