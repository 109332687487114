<template>
  <div class="new-subscription-modal-wrapper">
    <store-modal
      :name="$options.name"
      :loading="isLoading"
      @closeModal="handleCancelCreate"
    >
        <template #header>
          {{
            isAvatarModalShowed ? $t('upload-avatar.title') :
            ( isUpdateMerchant ? $t('create-subscription.title_edit') :  $t('create-subscription.title_create') )
          }}
        </template>

        <template #body>
          <div class="image-block">
            <div
                v-if="isAvatarModalShowed"
                class="image-params"
            >
              <div>
                {{ $t('upload-avatar.upload_formats_description') }}
                <span class="bold">{{ $t('upload-avatar.upload_formats') }}</span>
              </div>
              <div>
                {{ $t('upload-avatar.max_file_size_description') }}
                <span class="bold">{{ $t('upload-avatar.max_file_size') }}</span>
              </div>
            </div>
            <template v-if="!isAvatarModalShowed">
              <user-pic
                :img-url="imgUrl"
                @click.native="toggleImageModal"
              />
              <div class="under-image-text">
                {{ underImageText }}
              </div>
            </template>
            <div
                v-if="isFileError"
                class="file-error-message"
            >
              {{ errorMessage }}
            </div>
          </div>
          <template v-if="!isAvatarModalShowed">
            <div class="merchant-name">
              <base-input
                :label="$t('create-subscription.inputs.merchant_name.label')"
                :placeholder="$t('create-subscription.inputs.merchant_name.placeholder')"
                max-length="24"
                v-model="name"
              />
            </div>
            <div class="image-link">
              <base-input
                :label="$t('create-subscription.inputs.merchant_image_link.label')"
                :placeholder="$t('create-subscription.inputs.merchant_image_link.placeholder')"
                v-model="logoUrl"
              />
            </div>
          </template>

          <div
            v-if="!isAvatarModalShowed"
            class="info-block"
          >
            <div class="info-title">
              {{ $t('create-subscription.restrictions') }}
            </div>
            <div class="info-item">
              <div class="info-label">{{ $t('create-subscription.allowFreeSpend') }}</div>
              <div class="info-value">
                <svg v-if="defaultSettings.allowFreeSpend === 'false'" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.969548 13.0303C0.676655 12.7374 0.676655 12.2625 0.969548 11.9696L5.93922 6.99997L0.969548 2.0303C0.676655 1.73741 0.676655 1.26253 0.969548 0.96964C1.26244 0.676746 1.73731 0.676746 2.03021 0.96964L6.99988 5.93931L11.9695 0.96964C12.2624 0.676747 12.7373 0.676747 13.0302 0.96964C13.3231 1.26253 13.3231 1.73741 13.0302 2.0303L8.06054 6.99997L13.0302 11.9696C13.3231 12.2625 13.3231 12.7374 13.0302 13.0303C12.7373 13.3232 12.2624 13.3232 11.9695 13.0303L6.99988 8.06063L2.03021 13.0303C1.73731 13.3232 1.26244 13.3232 0.969548 13.0303Z" fill="#EB5757"/>
                </svg>
                <svg v-else width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 1.5L4.75 9.75L1 6" stroke="#27AE60" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
            <div class="info-item">
              <div class="info-label">{{ $t('create-subscription.maxSpendAmountUSD') }}</div>
              <div class="info-value">
                {{ defaultSettings.maxSpendAmountUSD }} USDT
              </div>
            </div>
            <div class="info-item">
              <div class="info-label">{{ $t('create-subscription.approveAmountUSD') }}</div>
              <div class="info-value">
                {{ defaultSettings.approveAmountUSD }} USDT
              </div>
            </div>
            <div class="info-item">
              <div class="info-label">{{ $t('create-subscription.interval') }}</div>
              <div class="info-value">
                {{ interval }}
              </div>
            </div>
          </div>
        </template>

        <template #footer>
          <div class="actions">
              <base-button
                type="secondary"
                :label="$t('create-subscription.actions.cancel')"
                class="action-item"
                @click="isAvatarModalShowed ? isAvatarModalShowed = false : handleCancelCreate()"
              />
              <base-button
                v-show="!isAvatarModalShowed"
                type="primary"
                :label="isUpdateMerchant ? $t('create-subscription.actions.save') : $t('create-subscription.actions.create')"
                :disabled="validateButton"
                class="action-item"
                @click="handleCreate"
              />
              <base-button
                v-show="isAvatarModalShowed"
                type="primary"
                :label="$t('create-subscription.actions.select_file')"
                class="action-item"
                @click="openUploadFileModal"
              >
                <template #prepend>
                  <input
                    id="upload-file-input"
                    class="remove-api-keys-modal__image-upload"
                    :accept="imageLimits.allowedMimes"
                    style="display: none"
                    type="file"
                    @change="handleUploadImage"
                  />
                </template>
              </base-button>
          </div>
        </template>
    </store-modal>
  </div>
</template>

<script>
import BaseModal from "../../BaseModal";
import {mapActions, mapGetters, mapState} from "vuex";
import CurrencyImg from "../../../elements/CurrencyImg";
import IconCheckmarkValidation from "../../../common/icon-checkmark-validation";
import IconErrorValidation from "../../../common/icon-error-validation";
import UploadImageDonationModal from "../../donation/UploadImageDonationModal";
import UploadImageAvatar from "../UploadImageAvatar";
import createMerchant from "../../../../graphQl/mutations/createMerchant";
import getDefaultMerchantSettings from "../../../../graphQl/queries/getDefaultMerchantSettings";
import uploadMerchantLink from "../../../../graphQl/mutations/uploadMerchantLink";
import axios from "axios";
import _ from "lodash";
import updateMerchant from "../../../../graphQl/mutations/updateMerchant";

export default {
    name: "NewSubscriptionModal",
    components: {
        UploadImageAvatar,
        IconErrorValidation,
        IconCheckmarkValidation,
        CurrencyImg,
        BaseModal,

        BaseButton: () => import(/* webpackChunkName: "public/components/common/uiKit/button/base/index.vue" */ '/public/components/common/uiKit/button/base/index.vue'),
        BaseInput: () => import(/* webpackChunkName: "public/components/common/uiKit/input/base/index.vue" */ '/public/components/common/uiKit/input/base/index.vue'),
        StoreModal: () => import(/* webpackChunkName: "public/components/modals/StoreModal" */ '/public/components/modals/StoreModal'),
        UserPic: () => import(/* webpackChunkName: "public/components/modals/subscriptions/NewSubscriptionModal/components/userPic" */ '/public/components/modals/subscriptions/NewSubscriptionModal/components/userPic'),
    },
    props: {
        merchant: {
            type: Object,
            default: () => ({}),
        },
    },
    data () {
        return {
            isLoading: false,
            currencyFrom: '',
            currenciesModel: [],
            name: '',
            logoUrl: '',
            isAvatarModalShowed: false,
            avatarFile: null,
            previewURl: '',
            imageLimits: {
                maxImageSize: 7 * 1024 * 1024,
                minImageSize: 10 * 1024,
                allowedMimes: ['image/jpeg', 'image/png', 'image/webp'],
            },
            defaultSettings: {
                maxSpendAmountUSD: '',
                allowFreeSpend: '',
                approveAmountUSD: '',
                minSpendInterval: '',
            },
            validationError: {
                currenciesModel: {
                    message: null,
                    isError: null,
                },
                title: {
                    message: null,
                },
            },
            isFileError: false,
            errorMessage: undefined
        };
    },
    async mounted() {
        const {data:{ getDefaultMerchantSettings: req}} = await this.request({
            type: 'query',
            gql: getDefaultMerchantSettings,
            variables: {
              organizationId: this.getSelectedOrganization.id,
            },
        });
        this.defaultSettings = req;
    },
    watch: {
        isOpen () {
            if(this.isUpdateMerchant) {
                this.name = this.merchant.name;
                this.logoUrl = this.merchant.logoUrl;
                this.defaultSettings.allowFreeSpend = this.merchant.allowFreeSpend;
                this.defaultSettings.maxSpendAmountUSD = this.merchant.maxSpendAmountUSD;
                this.defaultSettings.minSpendInterval = this.merchant.minSpendInterval;
                this.defaultSettings.approveAmountUSD = this.merchant.approveAmountUSD;
            }
        },
    },
    computed: {
        ...mapGetters('app', [
            'sortedCurrencies',
            'isOpenModal',
            'getSelectedOrganization',
        ]),
        underImageText({ imgUrl }) {
          return imgUrl ? this.$t('create-subscription.change') : this.$t('create-subscription.upload')
        },
        isUpdateMerchant () {
            return Object.entries(this.merchant).length;
        },
        ...mapState('app', ['currencies']),
        imgUrl () {
            return this.logoUrl ? this.logoUrl : this.previewURl;
        },
        validateButton () {
            return !this.name;
        },
        totalCurrencies() {
            if (this.flatCurrencies) return this.flatCurrencies.length;
            return 0;
        },
        selectedCurrencies() {
            if (this.currenciesModel) {
                return this.currenciesModel.length;
            }
            return 0;
        },
        isOpen() {
            const {
                $options: {name},
                isOpenModal,
            } = this;
            return isOpenModal(name);
        },
        currenciesForFrom() {
            return this.sortedCurrencies.filter(currency => currency.allowInvoice);
        },
        isAllCurrenciesSelected() {
            if (this.currenciesModel && this.flatCurrencies) return this.currenciesModel.length === this.flatCurrencies.length;
            return false;
        },
        flatCurrencies() {
            let currenciesFlat = [];
            for (const currency of this.currencies) {
                for (const currencyNetwork of currency.networks) {
                    currenciesFlat.push({...currency, network: currencyNetwork});
                }
            }
            return currenciesFlat;
        },
        selectedCurrenciesId () {
            const arr = [];
            this.currencies.forEach(({id, networks}) => {
                networks.forEach(({id: networkId}) => {
                    arr.push([id, networkId]);
                });
            });
            return arr;
        },
        interval () {
            const intervals = {
                '15_MINUTES': this.$t('create-subscription.15min'),
                'DAY': this.$t('create-subscription.day'),
                'WEEK': this.$t('create-subscription.week'),
                'MONTH': this.$t('create-subscription.month'),
                'QUARTER': this.$t('create-subscription.quarter'),
                'YEAR': this.$t('create-subscription.year'),
            };
            return intervals[this.defaultSettings.minSpendInterval];
        },
    },
    methods: {
        ...mapActions('app', [
            'openModal',
            'closeModal',
            'request',
        ]),
        handleCancelCreate () {
          this.clearData()
          this.closeModal()
        },
        openUploadFileModal() {
          document.getElementById("upload-file-input").click()
        },
        toggleImageModal() {
            this.isAvatarModalShowed = !this.isAvatarModalShowed;
        },
        handleUploadImage(value) {
          const { imageLimits } = this
          const fileSize = value.target.files[0].size
          // const fileType = value.target.files[0].type

          if (fileSize < imageLimits.minImageSize || fileSize > imageLimits.maxImageSize) {
            this.isFileError = true;
            this.errorMessage = this.$t('upload-avatar.error')
          } else {
            this.isFileError = false;
            this.toggleImageModal()
            this.filesChange(value)
          }
        },
        filesChange(fileList) {
            const payload = new FormData();
            payload.append("file", fileList.target.files[0]);
            payload.append("Content-Type", fileList.target.files[0].type);
            const img = new File([fileList.target.files[0]], 'test.jpeg', {
                type: 'image/jpeg',
            });
            this.previewURl = URL.createObjectURL(img);
            this.logoUrl = ''
            this.avatarFile = payload;
        },
        toggleAllSelected() {
            if (this.isAllCurrenciesSelected) {
                this.currenciesModel = [];
            } else {
                this.currenciesModel = [];
                this.currenciesModel = [...this.flatCurrencies];
            }
        },
        async uploadFile (merchantId) {
            const variables = {
                organizationId: this.getSelectedOrganization.id,
                merchantId,
            };


            const {data: { uploadMerchantLink: req }} = await this.request({
                type: 'mutation',
                gql: uploadMerchantLink,
                variables,
            });

            return await axios.put(req.result, this.avatarFile, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        },
        async handleCreate () {
            if (this.isUpdateMerchant) {
              await this.updateMerchantQuery()
            } else {
              await this.createMerchant()
            }
        },
        async updateMerchantQuery () {
            this.isLoading = true;
            const variables = {
                merchantId: this.merchant.id,
                organizationId: this.getSelectedOrganization.id,
                name: this.name,
                logoUrl: this.logoUrl ? this.logoUrl : '',
                currencies: this.selectedCurrenciesId,
            };
            await this.request({
                type: 'mutation',
                gql: updateMerchant,
                variables,
            });

            if (this.avatarFile) {
                await this.uploadFile(this.merchant.id);
            }

            this.$emit('updateMerchant');
            this.handleCancelCreate();
            this.isLoading = false;
        },
        async createMerchant () {
            this.isLoading = true;

            const variables = {
                organizationId: this.getSelectedOrganization.id,
                name: this.name,
                logoUrl: this.logoUrl ? this.logoUrl : '',
                currencies: this.selectedCurrenciesId,
            };

            const response = await this.request({
                type: 'mutation',
                gql: createMerchant,
                variables,
            })

            const { data } = response || {}
            const { createMerchant: req } = data || {}

            if (!req) {
              const { message = '' } = response

              this.handleCancelCreate()

              if (message.includes('is not allowed')) {
                this.$emit('has-not-allowed-error')
              }

              this.isLoading = false;

              return
            }

            if (this.avatarFile) {
              await this.uploadFile(req.id);
            }

            this.$emit('createMerchant');

            setTimeout(() => {
              this.isLoading = false;

              this.handleCancelCreate();
            }, 0)

        },
        clearData() {
          this.name = ''
          this.logoUrl = ''
          this.previewURl = ''
          this.isAvatarModalShowed = false
        }
    },
};
</script>

<style lang="scss">
.new-subscription-modal-wrapper {
  position: relative;

  font-family: var(--new-front-font-family);

  &.loading {
    .main-modal-header {
      opacity: 0.5
    }
    .main-modal-body {
      opacity: 0.5
    }
    .main-modal-footer {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .main-modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    font-size: $h1;
    font-weight: 500;
    color: var(--new-front-text-primary-bg-modal-primary);
  }

  .main-modal-body {
    .image-block {
      display: flex;
      align-items: center;
      flex-direction: column;

      margin-bottom: 20px;

      .image-params {
        color: var(--new-front-text-secondary-bg-secondary);
        font-size: $h4;
        line-height: $h2;

        text-align: center;

        margin-bottom: 20px;

        .bold {
          font-weight: 500;
          margin-left: 5px;

          color: var(--new-front-text-primary-bg-secondary);
        }
      }

      .under-image-text {
        margin-top: 8px;

        color: var(--new-front-secondary-font-color);
        font-size: $h8;
      }

      .file-error-message {
        margin-top: 8px;

        color: var(--new-front-text-error-bg-modal-primary);
        font-size: $h6;
      }
    }

    .merchant-name, .image-link {
      margin: 0 30px 20px;
    }

    .info-block {
      margin: 0 30px 20px;
      font-weight: 500;

      .info-title {
        text-align: left;
        font-size: $h3;
        color: var(--new-front-text-primary-bg-modal-primary);

        margin-bottom: 20px;
      }

      .info-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .info-label {
          font-size: $h4;
          line-height: $h2;
          color: var(--new-front-text-secondary-bg-modal-primary);
        }
        .info-value {
          font-size: $h4;
          line-height: $h2;
          color: var(--new-front-text-primary-bg-modal-primary);
        }
      }
    }
  }

  .main-modal-footer {
    .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;

      gap: 20px;

      padding: 0 10px 20px;

      .action-item {
        flex-grow: 1;
      }
    }
  }

  @include below_phone() {
    .main-modal-header {
      font-size: $h3;
    }
    .main-modal-body {
      .info-block {
        .info-title {
          font-size: $h4;
          margin-bottom: 10px;
        }

        .info-item {
          .info-label {
            font-size: $h6;
            line-height: $h4;
          }
          .info-value {
            font-size: $h6;
            line-height: $h4;
          }
        }
      }
    }
  }
}

//.subscription-modal {
//  &__upload {
//    width: 100px;
//    height: 100px;
//    border: 1px solid var(--blue-state-color);
//    background: #E7F0FF;
//    display: flex;
//    flex-direction: column;
//    align-items: center;
//    justify-content: center;
//    border-radius: 10px;
//    > span {
//      font-size: 10px;
//      color: var(--additional-font-color);
//      margin-top: 13px;
//    }
//  }
//  &__nameBlock {
//    display: grid;
//    grid-template-columns: 100px 1fr;
//    grid-gap: 35px;
//    margin-bottom: 20px;
//  }
//  &__option-container {
//    display: flex;
//    gap: 15px;
//  }
//  &__currency-container {
//    display: grid;
//    grid-template-columns: 1fr 1fr;
//    grid-gap: 35px;
//  }
//  &__input {
//    margin-bottom: 20px;
//    &-title {
//      color: var(--primary-font-color);
//      font-size: 16px;
//      margin-bottom: 5px;
//    }
//  }
//  &__info {
//    margin-top: 30px;
//    &-title {
//      text-align: center;
//      color: var(--primary-font-color);
//      font-size: 20px;
//      font-weight: 700;
//      margin-bottom: 20px;
//    }
//    &-item {
//      display: flex;
//      align-items: center;
//      justify-content: space-between;
//      padding: 21px 0;
//      border-bottom: 1px solid var(--border-color);
//    }
//    &-label {
//      font-size: 16px;
//      color: var(--primary-font-color);
//    }
//    &-value {
//      font-size: 16px;
//      color: var(--primary-font-color);
//      font-weight: 700;
//    }
//  }
//}
//::v-deep .at-select {
//  background: var(--secondary-color);
//  box-sizing: border-box;
//}
//
//::v-deep .at-select__selection {
//  border: 1px solid var(--border-color);
//  border-radius: 12px;
//  min-height: 46px;
//  display: flex;
//  padding-left: 30px;
//  align-items: center;
//  box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
//  flex-wrap: wrap;
//  padding-top: 5px;
//  padding-bottom: 5px;
//}
//
//::v-deep .at-select__arrow {
//  font-size: 24px;
//  color: #8F949A;
//  margin-top: 0;
//  top: calc(50% - 12px);
//}
//
//::v-deep .at-select__clear {
//  font-size: 24px;
//  color: #8F949A;
//  margin-top: 0;
//  top: calc(50% - 12px);
//}
//::v-deep .at-select__dropdown .at-select__option {
//  font-family: var(--font-family);
//  font-size: var(--font-size-text-main);
//  line-height: var(--line-height);
//  color: var(--primary-font-color);
//
//  &:hover {
//    background: var(--hover-color-select);
//  }
//}
//
//::v-deep .at-select__dropdown {
//  border: 1px solid var(--border-color);
//  background: var(--bg-select);
//}
//
//::v-deep .at-select__option--selected {
//  background: var(--hover-color-select);
//}
//
//::v-deep .at-select__placeholder {
//  font-family: var(--font-family);
//  font-size: var(--font-size-text-main);
//  line-height: var(--line-height);
//}
//
//::v-deep .at-select__selected {
//  display: none;
//}
//::v-deep textarea {
//  &::placeholder {
//    font-family: var(--font-family);
//    font-size: var(--font-size-text-main);
//    line-height: var(--line-height);
//    overflow: visible;
//  }
//}
//
//
//::v-deep .at-input__original {
//  font-family: var(--font-family);
//  font-size: var(--font-size-text-main);
//  line-height: var(--line-height);
//  color: var(--primary-font-color);
//
//  &::placeholder {
//    font-family: var(--font-family);
//    font-size: var(--font-size-text-main);
//    line-height: var(--line-height);
//  }
//}
//::v-deep .at-textarea__original {
//  border: 1px solid var(--border-color);
//  box-sizing: border-box;
//  box-shadow: inset 0 4px 20px rgba(0, 0, 0, 0.03);
//  border-radius: 12px;
//  min-height: 140px;
//  font-family: var(--font-family);
//  font-size: var(--font-size-text-main);
//  line-height: var(--line-height);
//  color: var(--primary-font-color);
//  padding: 15px;
//}
//.grayColor {
//  color: var(--secondary-font-color)
//}
//
//.new-donation-modal {
//  padding-right: var(--padding-right-container);
//  padding-left: var(--padding-left-container);
//  padding-top: var(--padding-top-container);
//
//  &__title {
//    font-family: var(--font-family);
//    font-size: var(--font-size-h2);
//    line-height: var(--line-height);
//    color: var(--primary-font-color);
//    margin-bottom: var(--margin-container-bottom);
//    font-weight: 400;
//    width: 100%;
//    max-width: 1125px;
//
//    &-container {
//      display: flex;
//      justify-content: center;
//    }
//  }
//
//  &__subtitle {
//    font-family: var(--font-family);
//    font-size: var(--font-size-h3);
//    line-height: var(--line-height);
//    color: var(--primary-font-color);
//    margin-bottom: var(--margin-container-bottom);
//    font-weight: 400;
//    width: 100%;
//    max-width: 1125px;
//
//    &-container {
//      display: flex;
//      justify-content: center;
//    }
//  }
//
//
//  &__language {
//    max-width: 50px;
//    align-self: flex-end;
//    margin-bottom: 10px;
//
//    ::v-deep .at-select__selection {
//      background: transparent;
//      color: var(--white-100) !important;
//    }
//
//    ::v-deep .at-select__arrow {
//      color: var(--white-100) !important;
//    }
//  }
//
//
//  &__avatar {
//    position: relative;
//    width: 100%;
//    height: 100%;
//    //border-radius: 50%;
//
//
//    &-text {
//      font-family: var(--font-family);
//      font-size: 10px;
//      font-weight: 500;
//      line-height: var(--line-height);
//      color: var(--white-100);
//      text-align: center;
//    }
//
//    &-hover {
//      position: absolute;
//      top: 5px;
//      right: 5px;
//      bottom: 5px;
//      left: 5px;
//      width: 96px;
//      height: 96px;
//      opacity: 0;
//      display: flex;
//      align-items: center;
//      justify-content: center;
//      flex-direction: column;
//      gap: 10px;
//      border-radius: 50%;
//      z-index: 2;
//      cursor: pointer;
//
//      &:hover {
//        transition: all ease 0.2s;
//        opacity: 1;
//        background: #BABEEE;
//      }
//
//
//    }
//
//    &__avatar_placeholder {
//      z-index: 1;
//    }
//
//
//    &-img {
//      width: 100%;
//      height: 100%;
//      border-radius: 50%;
//      object-fit: contain;
//      object-position: center;
//      position: relative;
//      z-index: 1;
//
//    }
//  }
//
//  &__all {
//    background: #0059F7;
//    margin-bottom: 16px;
//    border-radius: 6px;
//    border: none;
//    width: 100%;
//    max-width: 125px;
//    min-height: 40px;
//    transition: all 0.3s ease-in;
//    cursor: pointer;
//
//    &:hover {
//      background: var(--blue-400);
//
//    }
//
//
//    ::v-deep .at-btn {
//      max-width: 125px;
//    }
//
//    ::v-deep .at-btn__icon {
//      font-size: var(--font-size-text-main);
//    }
//
//    ::v-deep .at-btn__text {
//      font-family: var(--font-family);
//      font-size: var(--font-size-text-main);
//      line-height: var(--line-height);
//      color: var(--white-100);
//    }
//
//    ::v-deep .at-btn__icon {
//      font-size: var(--font-size-text-main);
//    }
//
//  }
//
//
//  &__description {
//    &-title {
//      font-family: var(--font-family);
//      font-size: var(--font-size-h2);
//      line-height: var(--line-height);
//      font-weight: 500;
//      color: var(--white-100);
//    }
//
//    &-subtitle {
//      font-family: var(--font-family);
//      font-size: var(--font-size-text-main);
//      line-height: var(--line-height);
//      color: var(--white-100);
//      line-clamp: 4;
//      word-break: break-all;
//    }
//  }
//
//  &__button {
//    min-height: 60px;
//    border-radius: 12px;
//    background: var(--blue-300);
//    max-width: 673px;
//    color: var(--white-100);
//    font-family: var(--font-family);
//    font-size: var(--font-size-text-main);
//    line-height: var(--line-height);
//    align-self: center;
//    margin-left: auto;
//    margin-right: auto;
//    width: 100%;
//    margin-top: 50px;
//    margin-bottom: 25px;
//    border: none;
//    cursor: pointer;
//
//    ::v-deep .at-btn__text {
//      font-family: var(--font-family);
//      font-size: var(--font-size-text-main);
//      line-height: var(--line-height);
//    }
//
//    &:hover {
//      background: var(--blue-400);
//    }
//
//    &:disabled {
//      &:hover {
//        cursor: not-allowed;
//        color: #D2D2D2;
//        border-color: #ECECEC;
//        background-color: #F7F7F7;
//      }
//    }
//
//  }
//
//
//  &__text {
//    &-main {
//      font-family: var(--font-family);
//      font-size: var(--font-size-h3);
//      line-height: var(--line-height);
//      color: var(--black-200);
//    }
//  }
//
//  &__option {
//    display: block;
//    width: 16px;
//    height: 16px;
//    cursor: pointer;
//  }
//
//  /* Check */
//
//  &__check {
//  }
//
//  &__check-input {
//    position: absolute;
//    -webkit-appearance: none;
//    -moz-appearance: none;
//    appearance: none;
//
//    &_list {
//      width: calc(100% + 80px);
//      height: 100%;
//      top: 0;
//      left: -40px;
//      border-bottom: var(--border-table);
//      z-index: 3;
//      cursor: pointer;
//    }
//  }
//
//
//  &__check-box {
//    position: absolute;
//    width: 16px;
//    height: 16px;
//    z-index: 2;
//  }
//
//  &__check-focus {
//    fill: none;
//  }
//
//  &__check-square {
//    fill: #ffffff;
//  }
//
//
//  /* Checked */
//
//  &__check-input:checked + * &__check-square {
//    fill: #2939EC;
//    stroke: transparent;
//  }
//
//  &__check-input:checked ~ &__check-input-bg {
//    background: #E7F0FF;
//    width: calc(100% + 80px);
//    height: 100%;
//    top: 0;
//    left: -40px;
//    z-index: 1;
//    position: absolute;
//  }
//
//  /* Focused */
//
//  &__check-input:focus + * &__check-focus {
//    fill: #7ed321;
//  }
//
//  /* Disabled */
//
//  &__check-input:disabled + * &__check-square {
//    stroke: #9b9b9b;
//  }
//
//  &__check-input:checked:disabled + * &__check-square {
//    fill: #9b9b9b;
//  }
//
//  &__currency {
//    &-img {
//      width: 34px;
//      height: 34px;
//      z-index: 2;
//    }
//
//    &-error {
//      font-family: var(--font-family);
//      font-size: var(--font-size-text-caption);
//      line-height: var(--line-height);
//      color: var(--red-200);
//
//    }
//
//    &-item {
//      display: flex;
//      justify-content: space-between;
//      align-items: center;
//
//      &:first-of-type .new-donation-modal__check-input {
//        border-top: var(--border-table);
//      }
//    }
//
//    &-container {
//      background: var(--white-100);
//      padding: 20px 40px;
//      height: 100%;
//      width: 100%;
//      border-radius: 12px;
//      overflow: auto;
//      //box-shadow: 0px 8px 50px rgba(10, 17, 105, 0.08);
//
//      &-info {
//        display: flex;
//        justify-content: space-between;
//        align-items: center;
//        min-height: 65px;
//      }
//
//      &-title {
//        display: flex;
//        align-items: center;
//        gap: 15px;
//        z-index: 2;
//      }
//    }
//
//    &-item {
//      min-height: 60px;
//      display: grid;
//      align-content: center;
//      justify-content: space-between;
//      position: relative;
//      grid-template-columns: 1fr 1fr;
//    }
//
//    &-info {
//      display: flex;
//      align-items: center;
//      gap: 20px;
//    }
//
//    &-title {
//      font-family: var(--font-family);
//      font-size: var(--font-size-h3);
//      line-height: var(--line-height);
//      color: var(--black-200);
//
//      &-container {
//        display: flex;
//        justify-content: space-between;
//        align-items: flex-start;
//
//      }
//
//      &-label {
//        min-height: 30px;
//      }
//
//
//    }
//
//    &-select-all {
//      display: flex;
//      align-items: center;
//      gap: 20px;
//    }
//
//    &-text-container {
//      display: flex;
//      flex-direction: column;
//    }
//
//    &-text {
//      z-index: 2;
//      font-family: var(--font-family);
//      font-size: var(--font-size-h3);
//      line-height: var(--line-height);
//      color: var(--grey-300);
//    }
//
//    &-text-title {
//      font-family: var(--font-family);
//      font-size: var(--font-size-h3);
//      line-height: var(--line-height);
//      font-weight: 500;
//      z-index: 2;
//    }
//
//    &-text-subtitle {
//      font-family: var(--font-family);
//      font-size: var(--font-size-text-main);
//      line-height: var(--line-height);
//      color: var(--grey-300);
//    }
//
//  }
//
//
//  &__donation-info {
//    width: 100%;
//    display: flex;
//    flex-direction: column;
//    align-items: center;
//  }
//
//  &__label {
//    font-family: var(--font-family);
//    font-size: var(--font-size-text-secondary);
//    line-height: var(--line-height);
//    color: var(--white-100);
//
//    &-container {
//      display: flex;
//      min-height: 33px;
//      justify-content: space-between;
//      align-items: center;
//    }
//  }
//
//  &__user-info {
//    display: flex;
//    gap: 40px;
//    align-items: flex-start;
//    justify-content: center;
//    justify-content: center;
//    margin-bottom: 40px;
//    width: 100%;
//
//    &-text {
//      font-family: var(--font-family);
//      font-size: var(--font-size-h2);
//      line-height: var(--line-height);
//      color: var(--white-100);
//
//      &_url {
//        font-size: var(--font-size-h3);
//        word-break: break-word;
//      }
//    }
//
//
//  }
//
//  &__input {
//    &-container {
//      display: grid;
//      gap: 10px;
//      position: relative;
//    }
//
//    &-error {
//      position: absolute;
//      bottom: -25px;
//      right: 0;
//      font-family: var(--font-family);
//      font-size: var(--font-size-text-caption);
//      line-height: var(--line-height);
//      color: var(--white-100);
//    }
//
//
//    &-file {
//      width: 100%;
//      height: 100%;
//      position: absolute;
//      left: 0;
//      top: 0;
//      appearance: none;
//      opacity: 0;
//      cursor: pointer;
//      z-index: 2;
//    }
//
//    ::v-deep .at-input__original {
//      min-height: 55px;
//      font-family: var(--font-family);
//      font-size: var(--font-size-text-secondary);
//      line-height: var(--line-height);
//      color: var(--black-200);
//      border-radius: 12px;
//      padding: 20px;
//
//      &::placeholder {
//        font-family: var(--font-family);
//        font-size: var(--font-size-text-main);
//        line-height: var(--line-height);
//      }
//    }
//
//  }
//
//
//  &__textarea {
//    ::v-deep .at-textarea__original {
//      min-height: 150px;
//      font-family: var(--font-family);
//      font-size: var(--font-size-text-secondary);
//      line-height: var(--line-height);
//      color: var(--black-200);
//      border-radius: 12px;
//      padding: 20px;
//
//      &::placeholder {
//        font-family: var(--font-family);
//        font-size: var(--font-size-text-main);
//        line-height: var(--line-height);
//      }
//
//    }
//  }
//
//  &__textarea-container {
//    display: flex;
//    align-items: flex-start;
//    justify-content: space-between;
//    border-bottom: 1px solid var(--white-100);
//  }
//
//  &__textarea-prepend {
//    padding: 0 0 10px;
//    color: var(--white-100);
//    font-family: var(--font-family);
//    font-size: var(--font-size-h3);
//  }
//
//  &__donation-text-container {
//    max-width: 672px;
//    display: flex;
//    justify-content: center;
//    flex-direction: column;
//    gap: 20px;
//    width: 100%;
//  }
//
//  &__text-container {
//    display: flex;
//    flex-direction: column;
//    gap: 25px;
//    align-self: center;
//  }
//
//
//  &__user-info-input {
//    width: 100%;
//
//    ::v-deep .at-input__original {
//      background: transparent;
//      border: none;
//      color: var(--white-100);
//      caret-color: var(--white-100);
//      padding: 0 0 5px;
//      font-family: var(--font-family);
//      font-size: var(--font-size-h3);
//
//    }
//
//    ::v-deep .at-input-group__prepend {
//      background: transparent;
//      border: none;
//      padding: 0 0 5px;
//      color: var(--white-100);
//      font-family: var(--font-family);
//      font-size: var(--font-size-h3);
//    }
//
//    &_alias {
//
//      ::v-deep .at-input__original {
//        font-size: var(--font-size-h2);
//      }
//    }
//
//    ::v-deep .at-textarea__original {
//
//      background: transparent;
//      border: none;
//      color: var(--white-100);
//      caret-color: var(--white-100);
//      padding: 0 0 5px;
//      font-family: var(--font-family);
//      font-size: var(--font-size-h3);
//    }
//  }
//
//
//  &__url-input {
//    border-bottom: 1px solid var(--white-100);
//
//    ::v-deep .at-input__original {
//      background: transparent;
//      border: none;
//      padding: 0 0 10px;
//      color: var(--white-100);
//      caret-color: var(--white-100);
//      font-family: var(--font-family);
//      font-size: var(--font-size-h3);
//    }
//
//    ::v-deep .at-input-group__prepend {
//      background: transparent;
//      border: none;
//      padding: 0 0 10px;
//      color: var(--white-100);
//      font-family: var(--font-family);
//      font-size: var(--font-size-h3);
//    }
//  }
//
//  &__container {
//    position: relative;
//    height: 980px;
//    background: #FFFFFF;
//    width: 100%;
//    max-width: 1125px;
//    -webkit-clip-path: url(#my-clip-path);
//    clip-path: url(#my-clip-path);
//  }
//
//  &__top-container {
//    padding: 20px;
//    display: flex;
//    flex-direction: column;
//    align-items: center;
//    height: 711px;
//    background: var(--bg-color);
//    border-radius: 12px;
//    margin: 15px;
//  }
//
//  &__color {
//    max-width: 165px;
//
//    &-placeholder {
//      top: 0;
//      left: 0;
//      position: absolute;
//      display: flex;
//      flex-direction: column;
//      align-items: center;
//      justify-content: center;
//      height: 100%;
//      width: 100%;
//
//      &-text {
//        color: var(--white-100);
//        font-size: var(--font-size-text-secondary);
//        line-height: var(--line-height);
//        font-family: var(--font-family);
//      }
//    }
//
//    &-container {
//      position: relative;
//      width: 100%;
//      max-width: 165px;
//    }
//
//
//    ::v-deep .at-select__selection {
//      background: transparent;
//      border: 1px dashed var(--white-100);
//      min-height: 96px;
//    }
//
//    ::v-deep .at-select__dropdown {
//      width: calc(100% + 135px);
//      top: unset !important;
//      left: unset !important;
//      right: 0;
//      bottom: -185px;
//    }
//
//    ::v-deep .at-select__arrow {
//      display: none;
//    }
//
//    ::v-deep .at-select__dropdown .at-select__list {
//      display: grid;
//      grid-template-columns: repeat(5, 1fr);
//      gap: 10px;
//      padding: 10px;
//
//      border-radius: 12px;
//    }
//
//    ::v-deep .at-select__option {
//      width: 48px;
//      height: 48px;
//      background: var(--bg-color-option);
//      color: transparent;
//      border-radius: 12px;
//    }
//
//    ::v-deep .at-select__selected {
//      color: transparent;
//      display: none;
//    }
//  }
//
//
//}
//.new-donation-modal__currency-container-count {
//  font-size: 16px;
//  color: var(--primary-font-color)
//}
//
//@media screen and (max-width: 950px) {
//  .new-donation-modal {
//    &__user-info {
//      display: grid;
//      grid-template-areas: 'avatar text'
//                           'color color';
//      max-width: 100%;
//      justify-content: center;
//      gap: 10px;
//
//      &-input {
//        font-size: var(--font-size-text-main);
//
//        ::v-deep .at-textarea__original {
//          padding: 0;
//          font-size: var(--font-size-text-main);
//        }
//
//        &_alias {
//          ::v-deep .at-input__original {
//            font-size: var(--font-size-h3);
//            font-weight: 600;
//            padding: 0;
//          }
//        }
//      }
//
//
//    }
//
//    &__textarea-prepend {
//      padding: 0;
//      font-size: var(--font-size-text-main);
//    }
//
//    &__text-container {
//      gap: 10px;
//    }
//
//    &__color-container {
//      grid-area: color;
//      max-width: 100%;
//    }
//
//    &__color {
//      max-width: 100%;
//
//      ::v-deep .at-select__dropdown {
//        width: 100%;
//      }
//
//      ::v-deep .at-select__dropdown .at-select__list {
//        justify-items: center;
//      }
//
//      ::v-deep .at-select__selection {
//        min-height: 50px;
//      }
//
//    }
//
//    &__avatar {
//      position: relative;
//      height: 57px;
//      width: 57px;
//
//      &-hover {
//        width: 57px;
//        height: 57px;
//        top: 0;
//        left: 0;
//        right: 0;
//        bottom: 0;
//        gap: 5px;
//      }
//
//
//      &-text {
//        display: none;
//      }
//
//      &-img {
//        height: 57px;
//        width: 57px;
//      }
//
//      &_placeholder {
//        height: 57px;
//        width: 57px;
//      }
//    }
//
//    &__textarea {
//      ::v-deep .at-textarea__original {
//        min-height: 115px;
//      }
//    }
//
//    &__input {
//      &-container {
//        gap: 5px
//      }
//
//      ::v-deep.at-input__original {
//        height: 40px;
//      }
//    }
//
//
//  }
//}
//
//@media screen and (max-width: 1180px) {
//  .loader {
//    height: 24px;
//    padding: 2px;
//    max-width: 24px;
//  }
//
//}
//
//@media screen and (max-width: 768px) {
//  .new-donation-modal {
//    padding-left: 10px;
//    padding-right: 10px;
//
//
//    &__check-input:checked ~ &__check-input-bg {
//      background: #E7F0FF;
//      width: calc(100% + 20px);
//      height: 100%;
//      top: 0;
//      left: -10px;
//      z-index: 1;
//      position: absolute;
//    }
//
//
//    &__input {
//      ::v-deep .at-input__original {
//        padding: 15px;
//      }
//    }
//
//
//    &__text-main {
//      font-size: var(--font-size-text-main);
//    }
//
//    &__currency-count-text {
//      font-family: var(--font-family);
//      font-size: var(--font-size-text-main);
//      line-height: var(--line-height);
//    }
//
//    &__currency-select-all {
//      gap: 5px;
//    }
//
//    &__currency-container {
//      max-width: calc(100% - 40px);
//      max-height: 300px;
//      padding: 10px 10px 0 10px;
//    }
//
//    &__check-input {
//      &_list {
//        width: calc(100% + 20px);
//        height: 100%;
//        top: 0;
//        left: -10px;
//      }
//    }
//
//    &__currency-text {
//      font-size: var(--font-size-text-main);
//      justify-self: flex-end;
//      text-align: end;
//    }
//
//    &__currency-img {
//      width: 26px;
//      height: 26px;
//    }
//  }
//}
//
//@media screen and (max-width: 500px) {
//  .new-donation-modal {
//    &__textarea-prepend {
//      display: none;
//    }
//
//    &__color {
//      ::v-deep .at-select__dropdown {
//        bottom: -156px;
//      }
//
//      ::v-deep .at-select__dropdown .at-select__list {
//        justify-items: center;
//      }
//
//      ::v-deep .at-select__option {
//        width: 35px;
//        height: 35px;
//        border-radius: 10px;
//      }
//    }
//
//  }
//
//  .subscription-modal {
//    &__nameBlock {
//      grid-template-columns: 1fr;
//    }
//    &__upload {
//      justify-self: center;
//    }
//    &__info {
//      &-title {
//        font-size: 16px;
//        margin-bottom: 0;
//      }
//      &-label {
//        font-size: 14px;
//      }
//      &-value {
//        font-size: 14px;
//      }
//    }
//  }
//  ::v-deep .at-modal__footer {
//    flex-direction: column-reverse !important;
//    align-items: center !important;
//  }
//}
//.imageUploaded {
//  border-color: transparent;
//  background: transparent;
//}
</style>
