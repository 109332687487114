import removeApiKeysMutation from "../../../../graphQl/mutations/removeApiKeys";

export const removeApiKeys = async ({ dispatch, rootGetters }, payload) => {
    let removeStatusData;

    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    const variables = {
        organizationId,
        ...payload,
    };

    try {
        removeStatusData = await dispatch('app/request', {
            type: 'mutation',
            gql: removeApiKeysMutation,
            variables,
        }, { root: true });
    } catch (error) {
        return error;
    }

    if (removeStatusData.message) {
        throw removeStatusData.message;
    }

    if (removeStatusData.errors) {
        throw removeStatusData.errors;
    }

    const {
        data: { removeApiKeys },
    } = removeStatusData;

    return removeApiKeys;
};