<script>

  import BaseInput from "@/public/pages/integration-settings/components/Input/index.vue";
  import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
  import {mapActions, mapMutations, mapState} from "vuex";
  import InfoButton from "@/public/components/common/uiKit/info-button/index.vue";
  import DeleteButton from "@/public/components/common/uiKit/delete-button/index.vue";

  export default {
    name: "OrganizationNewRoles",
    components: {DeleteButton, InfoButton, BaseButton, BaseInput},
    methods: {
      ...mapMutations('organizations', [
        'switchTab',
        'changeDetailRole',
        'setDetailRoleId',
        'setChangeRoleStatus'
      ]),
      ...mapActions('organizations', [
        'getOrganizationUsers',
        'getOrganizationRole',
        'deleteOrganizationRole',
        'getOrganizationRoles',
      ]),
      ...mapActions('app', ['openModal']),
      handleAddRole() {
        this.switchTab(2)
        this.changeDetailRole(false)
        this.getOrganizationRoles()
      },

      deleteRole(currentRole) {
        this.$store.commit('organizations/setDetailRoleId', currentRole.id);
        this.openModal({
          name: 'RemoveRoleModal',
          payload: currentRole.name,
        });
      },

      changeRole(currentRole) {
        this.switchTab(2)
        this.changeDetailRole(true);
        this.setDetailRoleId(currentRole.id)
        this.setChangeRoleStatus(true)
        this.getOrganizationRole();
      },
    },
    computed: {
      ...mapState('organizations', [
        'organizationData',
      ]),
      list () {
        if (!this.organizationData.organizationRoles.length) return []
        return this.organizationData.organizationRoles.slice(0, 5)
      }
    }
  }
</script>

<template>
<div class="organization-information">
  <div class="header">
    <div class="header__left">
      <div class="header__title">
        {{ $t('organization.organization-new-roles') }}
      </div>
    </div>
  </div>
  <div class="list">
    <div class="header">
      <div class="col">{{ $t('organization.list.role') }}</div>
    </div>
    <template v-if="list.length">
      <div v-for="item in list" class="item">
        <div class="item__value">{{ item.name }}</div>
        <div class="item__actions">
          <info-button @click="changeRole(item)" />
          <delete-button @click="deleteRole(item)"/>
<!--          <div class="item__actions-btn" @click="changeRole(item)">-->
<!--            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M12 19.875C7.65076 19.875 4.125 16.3492 4.125 12C4.125 7.65076 7.65076 4.125 12 4.125C16.3492 4.125 19.875 7.65076 19.875 12C19.875 16.3492 16.3492 19.875 12 19.875ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="var(&#45;&#45;button-border-button-primary-default)"/>-->
<!--              <path d="M13.047 10.4114L10.4696 10.7344L10.3774 11.1628L10.8849 11.2551C11.2145 11.3342 11.2804 11.4529 11.2079 11.7825L10.3774 15.6848C10.1598 16.6934 10.496 17.168 11.287 17.168C11.9001 17.168 12.612 16.8845 12.935 16.4956L13.0339 16.0276C12.8097 16.2253 12.4801 16.3044 12.2626 16.3044C11.9528 16.3044 11.8407 16.0869 11.9198 15.7046L13.047 10.4114Z" fill="var(&#45;&#45;button-border-button-primary-default)"/>-->
<!--              <path d="M13.125 8.0625C13.125 8.68382 12.6213 9.1875 12 9.1875C11.3787 9.1875 10.875 8.68382 10.875 8.0625C10.875 7.44118 11.3787 6.9375 12 6.9375C12.6213 6.9375 13.125 7.44118 13.125 8.0625Z" fill="var(&#45;&#45;button-border-button-primary-default)"/>-->
<!--            </svg>-->
<!--          </div>-->
<!--          <div class="item__actions-btn" @click="deleteRole(item)">-->
<!--            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M5.23077 4.2C4.55103 4.2 4 4.73726 4 5.4V6.6C4 7.26274 4.55103 7.8 5.23077 7.8H5.84615V18.6C5.84615 19.9255 6.94822 21 8.30769 21H15.6923C17.0518 21 18.1538 19.9255 18.1538 18.6V7.8H18.7692C19.449 7.8 20 7.26274 20 6.6V5.4C20 4.73726 19.449 4.2 18.7692 4.2H14.4615C14.4615 3.53726 13.9105 3 13.2308 3H10.7692C10.0895 3 9.53846 3.53726 9.53846 4.2H5.23077ZM8.92308 9C9.26294 9 9.53846 9.26863 9.53846 9.6V18C9.53846 18.3314 9.26294 18.6 8.92308 18.6C8.58321 18.6 8.30769 18.3314 8.30769 18V9.6C8.30769 9.26863 8.58321 9 8.92308 9ZM12 9C12.3399 9 12.6154 9.26863 12.6154 9.6V18C12.6154 18.3314 12.3399 18.6 12 18.6C11.6601 18.6 11.3846 18.3314 11.3846 18V9.6C11.3846 9.26863 11.6601 9 12 9ZM15.6923 9.6V18C15.6923 18.3314 15.4168 18.6 15.0769 18.6C14.7371 18.6 14.4615 18.3314 14.4615 18V9.6C14.4615 9.26863 14.7371 9 15.0769 9C15.4168 9 15.6923 9.26863 15.6923 9.6Z" fill="var(&#45;&#45;button-border-button-primary-default)"/>-->
<!--            </svg>-->
<!--          </div>-->
        </div>
      </div>
    </template>
    <p class="empty" v-else> {{ $t('organization.no-roles') }} </p>

  </div>
  <div class="more-btn">
    <base-button @click="handleAddRole" :label="list.length ? $t('organization.more') : $t('organization.organization_btn_add_role')" />
  </div>
</div>
</template>

<style scoped lang="scss">
.organization-information {
  border-radius: 20px;
  background: var(--bg-background-secondary, #202020);
  padding: 30px;
  font-family: $Gilroy;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    &__title {
      color: var(--text-text-primary, #FFF);
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    &__left {
      display: flex;
      align-items: center;
      gap: 5px;
    }
    &__right {
      display: grid;
      grid-template-columns: 48px 1fr;
      gap: 10px;
    }
    &__clear {
      border-radius: 10px;
      border: 1px solid var(--button-border-button-primary-default, #6750A4);
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__button {
      width: 180px;
    }
  }
  .list {
    margin-bottom: 30px;
    .empty {
      color: var(--text-color-text-secondary, #7E7E7E);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 30px;
    }
    .header {
      border-radius: 10px;
      background: var(--bg-background-table, #333);
      padding: 0 20px;
      height: 56px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 0;
      .col {
        color: var(--text-color-text-secondary, #7E7E7E);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        align-items: center;
        gap: 5px;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
    .item {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      border-bottom:   1px solid var(--border-devider-primary, #353537);
      padding: 10px 20px 10px 20px;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border-bottom: none;
      }
      &__value {
        color: var(--text-text-primary, #FFF);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }
      &__actions {
        display: grid;
        grid-template-columns: 48px 48px;
        gap: 10px;
        justify-content: end;
        &-btn {
          cursor: pointer;
          height: 48px;
          width: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          border: 1px solid var(--button-border-button-primary-default, #6750A4);
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .organization-information {
    padding: 0;
    //.header {
    //  display: none;
    //}
    .header {
      padding: 20px 0 15px 20px;
      margin-bottom: 0;
      border-bottom: 1px solid var(--border-devider-primary, #353537);;
    }
    .header__left {
      .header__title {
        font-size: 16px;
      }
    }
    .list {
      .header {
        display: none;
      }
      .item {
        padding-top: 20px;
        &:first-child {
          padding-top: 20px;
        }
      }
      //.item {
      //  padding: 16px 20px 20px 20px;
      //  height: unset;
      //  grid-template-columns: 1fr;
      //  border-bottom: 1px solid var(--border-devider-primary, #353537);
      //  &:last-child {
      //    border-bottom: 0;
      //  }
      //  &__value {
      //    color: var(--text-color-text-secondary, #7E7E7E);
      //    font-size: 13px;
      //    &:first-child {
      //      color: var(--text-text-primary, #FFF);
      //    }
      //    &:nth-child(even) {
      //      display: flex;
      //      justify-content: flex-end;
      //    }
      //  }
      //  &__values {
      //    grid-template-columns: 1fr 1fr;
      //  }
      //  &__actions {
      //    margin-top: 20px;
      //    grid-template-columns: 1fr 48px 48px;
      //    &-btn {
      //      gap: 8px;
      //      &.history {
      //        width: unset;
      //        color: var(--bg-background-primary-purple, #6750A4);
      //        text-align: right;
      //        font-size: 13px;
      //        font-weight: 500;
      //        line-height: 28px;
      //        span {
      //          display: inline-block;
      //        }
      //      }
      //    }
      //  }
      //}
    }
    .more-btn {
      margin-top: 30px;
      padding: 0 20px 20px 20px;
    }

  }
}
</style>
