<template>
  <div class="dashboard-orders">

    <div class="dashboard-orders__column">
      <div class="dashboard-orders__tab-container">
        <button @click.prevent="showCreated"
                :class="['dashboard-orders__button-tab', isCreatedVisible ? 'dashboard-orders__button-tab_active' : '']">
          <svg class="dashboard-orders__icon-mobile" width="18" height="18" viewBox="0 0 18 18" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.75 10.5V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V3.75C2.25 3.35218 2.40804 2.97064 2.68934 2.68934C2.97064 2.40804 3.35218 2.25 3.75 2.25H7.5V3.75H3.75V14.25H14.25V10.5H15.75Z"
                fill="var(--new-front-purple)"/>
            <path d="M15.75 5.25H12.75V2.25H11.25V5.25H8.25V6.75H11.25V9.75H12.75V6.75H15.75V5.25Z" fill="var(--new-front-purple)"/>
          </svg>
          <p class="dashboard-orders__tab-text">{{ $t('dashboard.created') }}</p>
        </button>
        <button @click.prevent="showProcessed"
                :class="['dashboard-orders__button-tab', isProcessedVisible ? 'dashboard-orders__button-tab_active' : '']">
          <svg class="dashboard-orders__icon-mobile" width="14" height="11" viewBox="0 0 14 11" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1.5L4.75 9.75L1 6" stroke="var(--new-front-purple)" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
          <p class="dashboard-orders__tab-text">{{ $t('dashboard.completed') }}</p>
        </button>
      </div>
      <dashboard-orders-card
          class="dashboard-orders__all"
          label="dashboard.orders_decl"
          type="dashboard.created_decl"
          :data="ordersAll"
          v-if="isCreatedVisible"
      >
        <svg class="dashboard-orders__icon-desktop" width="18" height="18" viewBox="0 0 18 18" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
              d="M15.75 10.5V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V3.75C2.25 3.35218 2.40804 2.97064 2.68934 2.68934C2.97064 2.40804 3.35218 2.25 3.75 2.25H7.5V3.75H3.75V14.25H14.25V10.5H15.75Z"
              fill="var(--new-front-purple)"/>
          <path d="M15.75 5.25H12.75V2.25H11.25V5.25H8.25V6.75H11.25V9.75H12.75V6.75H15.75V5.25Z" fill="#8F949A"/>
        </svg>
      </dashboard-orders-card>
      <dashboard-orders-card
          v-if="isProcessedVisible"
          label="dashboard.orders_decl"
          type="dashboard.executed_decl"
          :data="ordersProcessed"
      >
        <svg class="dashboard-orders__icon-desktop" width="14" height="11" viewBox="0 0 14 11" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M13 1.5L4.75 9.75L1 6" stroke="var(--new-front-purple)" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
      </dashboard-orders-card>

    </div>

  </div>
</template>

<script>

import DashboardOrdersCard from "./DashboardOrdersCard";
import {mapGetters, mapState} from "vuex";
import BigNumber from "bignumber.js";

export default {
    name: 'DashboardOrders',
    data() {
        return {
            isCreatedVisible: true,
            isProcessedVisible: true,
            width: 0,
        };
    },
    watch: {
        width(width) {
            if (width > 1016) {
                this.isProcessedVisible = true;
                this.isCreatedVisible = true;
            } else if (width <= 1016 && this.isProcessedVisible && this.isCreatedVisible) {
                this.showCreated();
            }
        },
    },
    components: {
        DashboardOrdersCard,
    },
    methods: {
        showProcessed() {
            this.isProcessedVisible = true;
            this.isCreatedVisible = false;
        },
        showCreated() {
            this.isProcessedVisible = false;
            this.isCreatedVisible = true;
        },
        handleResize() {
            this.width = window.innerWidth;
        },
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    computed: {
        ...mapGetters('app', [
            'getCurrencyNameById',
        ]),
        ...mapState('dashboard', [
            'orders',
        ]),
        ordersAll() {
            let count = 0, amount = {};
            if (this.orders instanceof Array)
                for (let order of this.orders) {
                    count += Number(order.count);

                    let currency = amount[this.getCurrencyNameById(order.currency)];
                    amount[this.getCurrencyNameById(order.currency)] = (typeof currency === 'undefined')
                        ? new BigNumber(order.amount).toFixed(2) : new BigNumber(+currency + order.amount).toFixed(2);
                }
            return {count, amount};
        },
        ordersProcessed() {
            let count = 0, amount = {};
            if (this.orders instanceof Array)
                for (let order of this.orders) {
                    if (order.status === 'processed') {
                        count += Number(order.count);
                        let currency = amount[this.getCurrencyNameById(order.currency)];
                        amount[this.getCurrencyNameById(order.currency)] = (typeof currency === 'undefined')
                            ? new BigNumber(order.amount).toFixed(2) : new BigNumber(+currency + order.amount).toFixed(2);
                    }
                }
            return {count, amount};
        },
    },
};

</script>

<style lang="scss" scoped>

.dashboard-orders {
  width: 100%;

  &__tab-text {
    font-family: $Gilroy;
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--new-front-primary-font-color);
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 75px;
  }

  &__tab-container {
    display: none;
  }
}

@media screen and (max-width: 1336px) {
  .dashboard-orders {
    &__column {
      gap: 20px;
      flex-direction: row;
    }
  }
}

@media screen and (max-width: 1016px) {
  .dashboard-orders {

    &__icon-desktop {
      display: none;
    }

    &__tab-container {
      display: flex;
      width: 100%;
      min-height: 41px;
      box-sizing: border-box;
      border: 1px solid var(--new-front-input-border);
      overflow: hidden;
      border-radius: 12px 12px 0px 0px;
      justify-content: space-between;
      background: transparent;
    }

    &__button-tab {
      width: 100%;
      display: flex;
      gap: 7px;
      background: var(--main-page-tab);
      align-items: center;
      justify-content: center;
      border: none;

      &_active {
        background: var(--secondary-head-color);
      }

      &:first-of-type.dashboard-orders__button-tab_active {
        border-right: 1px solid var(--new-front-input-border);
        border-radius: 12px 12px 0px 0px;
      }

      &:last-of-type.dashboard-orders__button-tab_active {
        border-left: 1px solid var(--new-front-input-border);
        border-radius: 12px 12px 0px 0px;
      }
    }

    &__column {
      flex-direction: column;
      gap: 0;
      border-radius: 12px;
      box-shadow: 0px 8px 50px rgba(10, 17, 105, 0.08);
    }
  }
}

</style>
