import gql from 'graphql-tag';

export default gql`
    mutation(
        $organizationId: String!,
        $accountId: String!,
        $addressId: String!,
        $address: String!,
        $tag: String,
        $amount: String!,
        $feeToken: String!,
        $comment: String,
        $otpToken: String!,
        $isAsync: Boolean,
        $key: String!
    ) {
        createWithdrawalRequest(
            organizationId: $organizationId,
            accountId: $accountId,
            addressId: $addressId,
            address: $address,
            tag: $tag,
            amount: $amount,
            feeToken: $feeToken,
            comment: $comment,
            otpToken: $otpToken,
            isAsync: $isAsync,
            key: $key
        ) {
            traceId
            code
            result {
                id
                currencyId
                networkId
                accountId
                addressId
                organizationId
                tx
                status
                reason
                amount
                feeAmount
                address
                tag
                createdAt
                comment
            }
        }
    }
`;
