import gql from 'graphql-tag';

export default gql`
    mutation inviteUser($organizationId: String!, $email: String!  $name: String!, $organizationRoleId: String!) {
        inviteUser(organizationId: $organizationId, email: $email, name: $name, organizationRoleId: $organizationRoleId) {
            code
            result
        }
    }
`;

