<template>
  <div class="requests-list-header">
    <p class="requests-list-header__item ">
      id
    </p>
    <p class="requests-list-header__item ">
      {{ $t('withdrawal.currency') }}
    </p>
    <p class="requests-list-header__item ">
      {{ $t('withdrawal.network') }}
    </p>
    <p class="requests-list-header__item ">
      {{ $t('withdrawal.amount') }}
    </p>
    <div
        class="requests-list-header__item list-header__item--sorting "
        @click="sortByDate"
    >
      {{ $t('withdrawal.date_of_creation') }}
      <Icon
          class="list-header__icon list-header__chart-icon"
          :class="{ 'list-header__chart-icon--asc': sortByDateStatus === 'ASC' }"
          icon="bar-chart"
      />
    </div>
    <p class="requests-list-header__item">
      {{ $t('withdrawal.status') }}
    </p>
  </div>
</template>

<script>

import {mapActions} from 'vuex';

import Icon from "/public/components/elements/Icon";

export default {
    name: 'RequestsListHeader',
    components: {
        Icon,
    },
    data() {
        return {
            sortByDateStatus: 'DESC',
        };
    },
    beforeMount() {
        this.setSortByDateFromUrl();
    },
    methods: {
        ...mapActions('withdrawalRequests', [
            'fetchWithdrawalRequests',
        ]),
        setSortByDateFromUrl() {
            const sortDateFromUrl = this.$route.query.sortDate;

            if (!sortDateFromUrl) {
                return false;
            }

            this.sortByDateStatus = sortDateFromUrl;
        },
        sortByDate() {
            const {
                $route,
                $router,
                sortByDateStatus,
                fetchWithdrawalRequests,
            } = this;

            if (sortByDateStatus === 'DESC') {
                this.sortByDateStatus = 'ASC';
            } else {
                this.sortByDateStatus = 'DESC';
            }

            fetchWithdrawalRequests({
                route: $route,
                router: $router,
                sortDate: this.sortByDateStatus,
            });
        },
    },
};

</script>
<style scoped lang="scss">
.requests-list-header {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  min-height: 45px;
  align-items: center;
  border-top: 1px solid #F2F2F2;
  border-bottom: 1px solid #F2F2F2;
  background: #FCFCFC;

  &__item {
    &:first-child {
      padding-left: 45px;
    }


  }
}

@media screen and (max-width: 1440px) {
  .requests-list-header {
    &__item {
      &:first-child {
        padding-left: 20px;
      }


    }
  }
}

@media screen and (max-width: 950px) {
  .requests-list-header {
    display: none;
  }
}

</style>