export const state = {
    requestsCountOnPage: 10,
    requests: [],
    totalRequestsCount: null,
    filters: {
        status: [],
    },
    request: null,
    fees: [],
    withdrawalData: {},
};
