import gql from 'graphql-tag';

export const getRollingReserveQuery = gql`
    query($limit: Float!, $offset: Float!, $sortDate: String, $statuses: [String], $currencies: [String], $date: [String]) {
        getRollingReserves(limit: $limit, offset: $offset, sortDate: $sortDate, statuses: $statuses, currencies: $currencies, date: $date) {
            result {
                id
                status
                orderId
                currency
                amount
                availableAt
                createdAt
            }
            totalCount
        }
    }
`;
