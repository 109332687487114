import gql from 'graphql-tag';

export default gql`
    mutation($organizationId: String!, $newOwnerId: String!, $otpToken: String!) {
        changeOrganizationOwner(organizationId: $organizationId, newOwnerId: $newOwnerId, otpToken: $otpToken) {
            code
            result
            traceId
        }
    }
`;
