export const state = {
    isInit: false,
    isLoading: false,
    currencies: [],
    getNetworks: [],
    listCurrenciesAddress: [],
    modal: {
        opened: null,
        payload: null,
    },
    count: 0,
    pagesCount: 1000,
    pages: [],
    page: {},
};
