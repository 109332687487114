<template>
<StoreModal
        name="CreateNewApiKeysModal"
        class="create-new-api-keys-modal"
        :title="title"
        @closeModal="handleCancelCreate"
>
<!--    -->
    <template #header>
        <div class="create-new-api-keys-modal__header">
            <div class="title">
                {{ title }}
            </div>
        </div>
    </template>
    <template #body>
        <div class="create-new-api-keys-modal__container" v-if="!hasApiKeys">
          <div class="create-new-api-keys-modal__row">
            <base-input
                class="create-new-api-keys-modal__api-keys-value-input"
                :status="aliasStatus"
                :label="$t('api_keys.name_of_the_api_key')"
                :placeholder="$t('api_keys.enter_a_new_api_key_name')"
                v-model="alias"
                :maxlength="255"
            />
            <p class="error" v-if="aliasError">{{ aliasError }}</p>
          </div>
          <div class=" create-new-api-keys-modal__row create-new-api-keys-modal__api-keys-item">
            <base-textarea
                сlas="textarea"
                v-model="ipsString"
                :placeholder="$t('api_keys.ip_addresses_placeholder')"
                :label="$t('api_keys.ip_addresses')"
                size="large"
                resize="none"
            />
            <p class="error" v-if="ipsError">{{ ipsError }}</p>
          </div>
          <div class=" create-new-api-keys-modal__row create-new-api-keys-modal__api-keys-item">
            <base-textarea
                сlas="textarea"
                v-model="ipv6String"
                :placeholder="$t('api_keys.ipv6_addresses_placeholder')"
                :label="$t('api_keys.ipv6_addresses')"
                size="large"
                resize="none"
            />
            <p class="error" v-if="ipsError">{{ ipsError }}</p>
          </div>
          <div class="rules">
            <div class="rules__item">
              <base-checkbox  :label="'only read'"/>
            </div>
            <div class="rules__item" v-for="(item, key) in rules">
              <div class="rules__title">{{ key }}</div>
              <div class="rules__list">
<!--               entity type: VIEW UPDATE CREATE REMOVE PAYMENT  -->
                <base-checkbox v-model="rules[key][entityKey]"  v-for="(entity, entityKey) in item" :label="entityKey"/>
              </div>
            </div>
          </div>
        </div>
        <div class="create-new-api-keys-modal__container" v-else>
          <div class="create-new-api-keys-modal__api-keys-item">
            <div class="create-new-api-keys-modal__api-keys-value">
              <base-input
                  class="create-new-api-keys-modal__api-keys-value-input inputSecondary"
                  :value="publicKey"
                  :label="$t('api_keys.public_key')"
                  :placeholder="$t('api_keys.enter_a_new_api_key_name')"
                  readonly
              />
              <copy class="copy-key" :value="publicKey"/>
            </div>
          </div>
          <div class="create-new-api-keys-modal__api-keys-item">
            <div class="create-new-api-keys-modal__api-keys-value">
              <base-input
                  class="create-new-api-keys-modal__api-keys-value-input inputSecondary"
                  :value="secretKey"
                  :label="$t('api_keys.private_key')"
                  readonly
              />
              <copy class="copy-key" :value="secretKey"/>
            </div>
          </div>
          <div class="alert">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.80977 0.21967C6.95042 0.0790176 7.14118 0 7.3401 0H16.6599C16.8588 0 17.0496 0.0790176 17.1902 0.21967L23.7803 6.80977C23.921 6.95042 24 7.14118 24 7.3401V16.6599C24 16.8588 23.921 17.0496 23.7803 17.1902L17.1902 23.7803C17.0496 23.921 16.8588 24 16.6599 24H7.3401C7.14118 24 6.95042 23.921 6.80977 23.7803L0.21967 17.1902C0.0790176 17.0496 0 16.8588 0 16.6599V7.3401C0 7.14118 0.0790176 6.95042 0.21967 6.80977L6.80977 0.21967ZM7.65076 1.5L1.5 7.65076V16.3492L7.65076 22.5H16.3492L22.5 16.3492V7.65076L16.3492 1.5H7.65076Z" fill="#BE9B40"/>
              <path d="M10.5023 16.5C10.5023 15.6716 11.1739 15 12.0023 15C12.8307 15 13.5023 15.6716 13.5023 16.5C13.5023 17.3284 12.8307 18 12.0023 18C11.1739 18 10.5023 17.3284 10.5023 16.5Z" fill="#BE9B40"/>
              <path d="M10.6493 7.49256C10.5693 6.69343 11.1969 6 12 6C12.8031 6 13.4307 6.69343 13.3507 7.49256L12.8246 12.7537C12.7823 13.1774 12.4258 13.5 12 13.5C11.5742 13.5 11.2177 13.1774 11.1754 12.7537L10.6493 7.49256Z" fill="#BE9B40"/>
            </svg>
            <p class="alert__message">{{ be_sure_to_keep }}</p>
          </div>
        </div>
    </template>


    <template #footer>
      <div class="footer" v-if="!hasApiKeys">
        <base-button :label="$t('api_keys.close')" class="button" type="secondary" @click="handleCancelCreate" />
        <base-button
            class="button"
            type="primary"
            :label="$t('api_keys.create')"
            @click="handleCreateRequest"
        />
      </div>
      <base-button
          v-else
          class="button"
          type="primary"
          :label="$t('api_keys.close')"
          @click="handleCancelCreate"
      />
    </template>

</StoreModal>
</template>

<script>

    import {mapGetters, mapActions, mapState} from 'vuex';

import copyToClipboard from "/public/mixins/copyToClipboard";

import AtModal from 'at-ui/src/components/modal';
import AtInput from 'at-ui/src/components/input';
import LoadingSpinner from "/public/components/common/uiKit/spinner/index.vue";;
import AtButton from 'at-ui/src/components/button';
import AtAlert from 'at-ui/src/components/alert';
import BaseModal from "../BaseModal";
    import BaseInput from "@/public/components/common/uiKit/input/base/index.vue";
    import BaseTextarea from "@/public/components/common/uiKit/textarea/index.vue";
    import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
    import Copy from "@/public/components/common/copy.vue";
    import BaseCheckbox from "@/public/components/common/uiKit/Checkbox/index.vue";

export default {
    name: 'CreateNewApiKeysModal',
    components: {
      BaseCheckbox,
        Copy,
        BaseButton,
        BaseTextarea,
        BaseInput,
        BaseModal,
        AtModal,
        AtInput,
        LoadingSpinner,
        AtButton,
        AtAlert,
        StoreModal: () => import(/* webpackChunkName: "/public/components/modals/StoreModal" */ '/public/components/modals/StoreModal'),
    },
    mixins: [copyToClipboard],
    data() {
        return {
            // apiKeys: null,
            alias: null,
            canSendForm: false,
            ipsString: '',
            ipv6String: '',
            ipsError: '',
            aliasError: '',
            rules: {}
        };
    },
    computed: {
        ...mapGetters('app', [
            'isOpenModal',
        ]),
        ...mapState('app', {
            modalPayload: state => state.modal.payload,
        }),
      ...mapState('user', ['apiKeysPlaceholder']),
        apiKeys () {
            if (this.modalPayload && this.modalPayload.public) {
                return {
                    public: this.modalPayload.public,
                    secret: this.modalPayload.secret
                }
            }
            return null
        },
        ipArray () {
            return this.ipsString.split(' ')
        },
        hasApiKeys() {
            return Boolean(this.apiKeys);
        },
        publicKey() {
            return this.apiKeys.public;
        },
        secretKey() {
            return this.apiKeys.secret;
        },
        isOpen() {
            const {
                $options: {
                    name,
                },
                isOpenModal,
            } = this;

            return isOpenModal(name);
        },
        title() {
            return this.$t(this.hasApiKeys ? 'api_keys.api_key_created' : 'api_keys.creating_an_api_key');
        },
        aliasStatus() {
            const {
                alias,
                canSendForm,
            } = this;

            return !alias && canSendForm ? 'error' : '';
        },
        be_sure_to_keep() {
            return this.$t('api_keys.be_sure_to_keep');
        },
        creating_an_api_keys() {
            return this.$t('api_keys.creating_an_api_keys');
        },
    },
    watch: {
      apiKeysPlaceholder () {
        this.apiKeysPlaceholder.forEach(item => {
          this.rules[item.section] = {}
          item.entities.forEach(entity => {
            this.rules[item.section][entity] = false
          })
        })
      },
        ipsString(newVal) {
            if (!newVal) {
                return
            }
            let re = /[^\d\s\.]/g;
            this.$set(this, 'ipsString', newVal.replace(re, ''));
        },
    },
  methods: {
        ...mapActions('app', [
            'closeModal',
            'openModal'
        ]),
        ...mapActions('user', [
            'createApiKeys',
            'fetchApiKeys',
        ]),
        clearAllData() {
            this.isLoading = false;
            // this.apiKeys = null;
            this.alias = null;
            this.canSendForm = false;
            this.ipsError = ''
            this.ipsString = ''
            this.aliasError = ''
        },
        handleCreateRequest() {
          const {
              alias,
              createApiKeys,
          } = this;

          this.canSendForm = true;

            if (!alias) {
                this.aliasError = this.$t('api_keys.alias_error')
                return false;
            }

            this.aliasError = ''

            const ips = this.ipArray.filter(item => item.length)

            if (!ips.length) {
                this.ipsError = this.$t('api_keys.ip_exist')
                return
            }

            ips.forEach(ip => {
                    if (
                        /^(10)\.(.*)\.(.*)\.(.*)$/.test(ip) ||
                        /^(172)\.(1[6-9]|2[0-9]|3[0-1])\.(.*)\.(.*)$/.test(ip) ||
                        /^(192)\.(168)\.(.*)\.(.*)$/.test(ip) ||
                        !/^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/.test(ip)
                    ) {
                        this.ipsError = `${this.$t('api_keys.ip_invalid')}: ${ip}`

                        return
                    }
                    this.ipsError = ''
                }
            )
            if (this.ipsError) {
                return;
            }

          this.openModal({
            name: 'TwoFaConfirmation',
            payload: {
              alias,
                ips
            }
          });
        },
        copyPublicKey() {
            const {
                publicKey,
                copyToClipboard,
            } = this;

            copyToClipboard(publicKey, true, {
                title: this.$t('copy.notification'),
                successMessage: this.$t('copy.the_public_key_copied'),
                failMessage: this.$t('copy.the_public_key_not_copied'),
            });
        },
        copySecretKey() {
            const {
                secretKey,
                copyToClipboard,
            } = this;

            copyToClipboard(secretKey, true, {
                title: this.$t('copy.notification'),
                successMessage: this.$t('copy.the_private_key_copied'),
                failMessage: this.$t('copy.the_private_key_not_copied'),
            });
        },
        handleCancelCreate() {
            const {
                clearAllData,
                closeModal,
                fetchApiKeys,
                hasApiKeys,
            } = this;
            closeModal();
            if (hasApiKeys) {
                fetchApiKeys();
            }
            setTimeout(() => {
                clearAllData();
            }, 500);
        },
    },
};

</script>

<style lang="scss" scoped>
.alert {
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 15px;
  &__message {
    font-weight: $Gilroy;
    color:  #BE9B40;
    font-size: 16px;
  }
}
//.textarea {
//  ::v-deep {
//    textarea {
//      &::placeholder {
//        color: unset !important;
//      }
//    }
//  }
//}
.copy-key {
  position: absolute;
  right: 0;
  top: 33px;
}
.button {
  ::v-deep .at-btn__text {

    font-family: $Gilroy;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

  }
}
.footer {
  display: flex;
  align-items: center;
  gap: 20px;
}
.create-new-api-keys-modal {
  &__container {
    padding: 0 20px;
  }
  &__header {
    text-align: center;

    .title {
      font-size: $h1;
      font-weight: 500;
      color: var(--new-front-primary-font-color);
      @media (max-width: 450px) {
        font-size: $h2;
      }
    }
  }
  ::v-deep .at-modal {
    max-width: 764px;
    border-radius: 20px;
  }

  ::v-deep .at-input__original {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);

    &::placeholder {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
    }
  }

  ::v-deep .at-modal__body {
    padding-top: 26px;
    padding-bottom: 52px;
  }

  ::v-deep .at-modal__header {
    display: flex;
    align-items: center;
    min-height: 107px;
    border-radius: 20px 20px 0 0;
    background: var(--modal-header-bg);
    border-bottom: 1px solid var(--border-color);
  }

  ::v-deep .at-modal__header p, .at-modal__header .at-modal__title {
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-h2);
    line-height: var(--line-height);
    max-width: 100%;
  }

  ::v-deep .col {
    width: 100%;

    font-family: $Gilroy;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--primary-font-color);
  }

  ::v-deep .at-input__original {
    background: var(--secondary-color);
    border: 1px solid var(--border-color);
    box-sizing: border-box;
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
  }

  ::v-deep .at-modal__footer {
    border: none;
    min-height: 106px;
    display: flex;
    //justify-content: space-between;
    gap: 15px;
  }

  ::v-deep .at-btn {
    width: 100%;
    height: 46px;
    max-width: 315px;
    border-radius: 12px;
  }

  ::v-deep .at-btn.create-new-api-keys-modal__api-keys-copy-value {
    width: 24px;
    height: 24px;
    border: none;
  }

  ::v-deep .at-btn__icon.icon.icon-copy {
    font-size: 20px;
    color: var(--primary-font-color);
    line-height: 0;
  }

  ::v-deep .at-btn--primary {
    background: #0059F7;
  }

  ::v-deep .at-btn--default {
    border: 1px solid var(--border-color-modal);
  }

  ::v-deep .at-modal__close {
    font-size: 24px;
    color: #8F949A;
    top: 35px;
    right: 35px;
  }


  &__row {
    display: flex;
    align-items: center;

    flex-direction: column;
    gap: 11px;
    &:first-child {
      margin-bottom: 20px;
    }
  }

  &__title {
    font-weight: 500;
  }

  &__sub-title {
    margin-bottom: 6px;
    @include secondaryText;
    font-weight: 700;
    color: var(--primary-font-color);
  }

  &__api-keys-item {
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
  }

  &__api-keys-value {
    display: flex;
    align-items: center;
    width: 100%;
    word-break: break-word;
    position: relative;
  }

  &__api-keys-value-input {
    width: 100%;
  }

  &__api-keys-copy-value {
    margin-left: 8px;
    width: 24px;
    height: 24px;
  }

  &__full-width-btn {
    width: 100%;
  }
}
.error {
  color: var(--new-front-input-font-color-error);
  width: 100%;
}
::v-deep {
  .inputSecondary {
    input {
      color: var(--new-front-secondary-font-color);
      &::placeholder {
        color: var(--new-front-secondary-font-color);
        font-weight: 500;
      }
    }
  }
  .create-new-api-keys-modal__api-keys-value-input {
    input {
      &::placeholder {
        color: var(--new-front-secondary-font-color);
        font-weight: 500;
      }
    }
  }
}
.title {
  font-family: $Gilroy;
  font-size: $h1;
  font-weight: 500;
  color: var(--new-front-primary-font-color);
  @media (max-width: 576px) {
    font-size: $h3;
  }
}
</style>
