<template>
  <div class="integration-settings-page-wrapper">
    <div class="dashboard">
      <div
        v-if="isLoading"
        class="loader-block"
        style="min-height: 200px"
      >
        <new-spinner />
      </div>
      <template v-else>
        <setting-item
          v-for="(item, index) in settings"
          :key="index"
          :item="item"
          :is-opened.sync="openedSettingsItemsState[item.id]"
          :class="{ 'first': index === 0}"
          @update="mergeSetting"
        />
      </template>
    </div>
    <integration-settings-info-modal />
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: 'IntegrationSettingsPage',
  components: {
    SettingItem: () => import(/* webpackChunkName: "public/pages/integration-settings/components/SettingItem" */ '/public/pages/integration-settings/components/SettingItem'),
    IntegrationSettingsInfoModal: () => import(/* webpackChunkName: "public/components/modals/integration-settings/InfoModal" */ '/public/components/modals/integration-settings/InfoModal'),
    NewSpinner: () => import (/* webpackChunkName: "/public/components/common/uiKit/spinner" */ "/public/components/common/uiKit/spinner/index.vue"),
  },
  data: () => {
    return {
      settings: [
        {
          id: 'CreateUser',
          method: {
            doc: `https://docs.${location.href.includes('apollo') ? 'apollopayment.io/v/english-2' : 'onchainpay.io'}/api-reference/personal_addresses/post_create-user`,
            url: `https://${location.href.includes('apollo') ? 'app.apollopayment.io' : 'ocp.onchainpay.io'}/api-gateway/personal-addresses/create-user`,
            type: 'POST',
            api: 'personal-addresses/create-user'
          },
          params: [
            {
              name: 'depositWebhookUrl',
              type: 'String',
              component: 'Big Input',
              isTemplate: true,
              templateType: 'url',
              value: undefined
            },
            {
              name: 'createAddresses',
              type: 'Boolean',
              component: 'Toggler',
              value: false
            },
            {
              name: 'groupByBlockchain',
              type: 'Boolean',
              component: 'Toggler',
              value: true
            }
          ]
        },
        {
          id: 'MakeOrder',
          method: {
            doc: `https://docs.${location.href.includes('apollo') ? 'apollopayment.io/v/english-2' : 'onchainpay.io'}/api-reference/05orders/post_make-order`,
            url: `https://${location.href.includes('apollo') ? 'app.apollopayment.io' : 'ocp.onchainpay.io'}/api-gateway/make-order`,
            type: 'POST',
            api: 'make-order'
          },
          params: [
            {
              name: 'paymentTolerancePercent',
              type: 'Integer',
              component: 'Short Input',
              isTemplate: false,
              templateType: null,
              value: undefined
            },
            {
              name: 'errorWebhook',
              type: 'String',
              component: 'Big Input',
              isTemplate: true,
              templateType: 'url',
              value: undefined
            },
            {
              name: 'successWebhook',
              type: 'String',
              component: 'Big Input',
              isTemplate: true,
              templateType: 'url',
              value: undefined
            },
            {
              name: 'returnUrl',
              type: 'String',
              component: 'Big Input',
              isTemplate: true,
              templateType: 'url',
              value: undefined
            },
            {
              name: 'lifetime',
              type: 'Integer',
              component: 'Time Picker',
              isTemplate: false,
              templateType: null,
              value: undefined,
              required: true
            }
          ]
        },
        {
          id: 'MakeInvoice',
          method: {
            doc: `https://docs.${location.href.includes('apollo') ? 'apollopayment.io/v/english-2' : 'onchainpay.io'}/api-reference/07invoices/post_make-invoice`,
            url: `https://${location.href.includes('apollo') ? 'app.apollopayment.io' : 'ocp.onchainpay.io'}/api-gateway/make-invoice`,
            type: 'POST',
            api: 'make-invoice'
          },
          params: [
            {
              name: 'includeFee',
              type: 'Boolean',
              component: 'Toggler',
              isTemplate: false,
              templateType: null,
              value: undefined
            },
            {
              name: 'insurancePercent',
              type: 'Integer',
              component: 'Short Input',
              isTemplate: false,
              templateType: null,
              value: undefined
            },
            {
              name: 'slippagePercent',
              type: 'Integer',
              component: 'Short Input',
              isTemplate: false,
              templateType: null,
              value: undefined
            },
            {
              name: 'paymentTolerancePercent',
              type: 'Integer',
              component: 'Short Input',
              isTemplate: false,
              templateType: null,
              value: undefined
            },
            {
              name: 'webhookURL',
              type: 'String',
              component: 'Big Input',
              isTemplate: true,
              templateType: 'url',
              value: undefined
            },
            {
              name: 'returnURL',
              type: 'String',
              component: 'Big Input',
              isTemplate: true,
              templateType: 'url',
              value: undefined
            },
            {
              name: 'lifetime',
              type: 'Integer',
              component: 'Time Picker',
              isTemplate: false,
              templateType: null,
              value: undefined,
              required: true
            },
            {
              name: 'currencies',
              type: 'Array of Any',
              component: 'Multiselect',
              isTemplate: false,
              templateType: null,
              value: undefined,
              required: true
            }
          ],
        }
      ],
      settingsFromServer: [],
      isLoading: false,
      openedSettingsItemsState: {
        'CreateUser': false,
        'MakeOrder': false,
        'MakeInvoice': false
      }
    }
  },
  watch: {
    'user.lang': {
      handler() {
        setTimeout(() => {
          this.updateTranslates()
        }, 0)
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('user', [
      'user',
    ]),
    isApollo() {
      const { host } = location
      return host.includes('apollo')
    },
    translates() {
      //офромлены в виде computed так как добавление переводов в data !не реагирует! на смену языка
      return [
        {
          id: 'CreateUser',
          name: this.$t('integration-settings.settings.create-user.name'),
          params: [
            {
              description: this.$t('integration-settings.settings.create-user.params.description.depositWebhookUrl'),
              placeholder: this.$t('integration-settings.elements.inputs.placeholders.url'),
            },
            {
              description: this.$t('integration-settings.settings.create-user.params.description.createAddresses'),
            },
            {
              description: this.$t('integration-settings.settings.create-user.params.description.groupByBlockchain'),
            }
          ]
        },
        {
          id: 'MakeOrder',
          name: this.$t('integration-settings.settings.make-order.name'),
          params: [
            {
              description: this.$t('integration-settings.settings.make-order.params.description.paymentTolerancePercent'),
              placeholder: this.$t('integration-settings.elements.inputs.placeholders.percent'),
            },
            {
              description: this.$t('integration-settings.settings.make-order.params.description.errorWebhook'),
              placeholder: this.$t('integration-settings.elements.inputs.placeholders.url'),
            },
            {
              description: this.$t('integration-settings.settings.make-order.params.description.successWebhook'),
              placeholder: this.$t('integration-settings.elements.inputs.placeholders.url'),
            },
            {
              description: this.$t('integration-settings.settings.make-order.params.description.returnUrl'),
              placeholder: this.$t('integration-settings.elements.inputs.placeholders.url'),
            },
            {
              description: this.$t('integration-settings.settings.make-order.params.description.lifetime'),
            }
          ]
        },
        {
          id: 'MakeInvoice',
          name: this.$t('integration-settings.settings.make-invoice.name'),
          params: [
            {
              description: this.$t('integration-settings.settings.make-invoice.params.description.includeFee'),
            },
            {
              description: this.$t('integration-settings.settings.make-invoice.params.description.insurancePercent'),
              placeholder: this.$t('integration-settings.elements.inputs.placeholders.percent'),
            },
            {
              description: this.$t('integration-settings.settings.make-invoice.params.description.slippagePercent'),
              placeholder: this.$t('integration-settings.elements.inputs.placeholders.percent'),
            },
            {
              description:  this.$t('integration-settings.settings.make-invoice.params.description.paymentTolerancePercent'),
              placeholder: this.$t('integration-settings.elements.inputs.placeholders.percent'),
            },
            {
              description: this.$t('integration-settings.settings.make-invoice.params.description.webhookURL'),
            },
            {
              description: this.$t('integration-settings.settings.make-invoice.params.description.returnURL'),
            },
            {
              description: this.$t('integration-settings.settings.make-invoice.params.description.lifetime'),
            },
            {
              description: this.$t('integration-settings.settings.make-invoice.params.description.currencies'),
            }
          ]
        }
      ]
    }
  },
  created() {
    this.mergeSetting()
  },
  methods: {
    ...mapActions('organizationSettings', [
      'getOrganizationSettings'
    ]),
    updateTranslates() {
      // обновляем переводы при смене языка
      this.settings.forEach((setting, index) => {
        const translatesSetting = this.translates[index]

        if (!!translatesSetting) {
          this.$set(setting, 'name', this.translates[index].name)

          setting.params.forEach((param, index) => {
            this.$set(param, 'description', translatesSetting.params[index].description)
            if (translatesSetting.params[index].placeholder) {
              this.$set(param, 'placeholder', translatesSetting.params[index].placeholder)
            }
          })
        }
      })
    },
    async mergeSetting() {
      this.isLoading = true

      this.settingsFromServer = await this.getOrganizationSettings()

      this.settingsFromServer.map(serverParam => {
        //мерджим с серверными данными
        this.settings.forEach(setting => {
          if (setting.method.api === serverParam.method) {
            setting.params.forEach(param => {
              if (param.name === serverParam.field) {
                this.$set(param, 'value', serverParam.value)
              }
            })
          }
        })
      })

      this.isLoading = false
    }
  },
}
</script>

<style scoped lang="scss">
  .integration-settings-page-wrapper {
    padding: 0 30px;

    .dashboard {
      margin-bottom: 30px;
      padding: 30px;
      background: var(--bg-background-secondary);
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;

      .loader-block {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      @include below_phone() {
        padding: 10px;
        border-radius: 10px;
      }
    }
  }
</style>