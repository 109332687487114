<template>
<Container class="payout-wallet-balance__container"
>
  <div class="payout-wallet-balance__wrap">
    <div class="payout-wallet-balance__row">
      <p class="payout-wallet-balance__title-text">{{ $t('orders.network') }}</p>

      <p class="payout-wallet-balance__value">{{ item.networkId | getNetworkName }}</p>
    </div>
    <div class="payout-wallet-balance__row">
      <p class="payout-wallet-balance__title-text">{{ $t('orders.currency') }}</p>
      <div class="payout-wallet-balance__currency-container">

        <CurrencyImg class="payout-wallet-balance__currency-image" :currency="item.currencyId"/>
        <p class="payout-wallet-balance__value">
          {{ getCurrencyNameById(item.currencyId) }}</p>
      </div>
    </div>
    <div class="payout-wallet-balance__row">
      <p class="payout-wallet-balance__title-text">{{ $t('business-wallet.address') }}</p>
      <CopyString
          :string="item.address"
          :successMessage="$t('copy.addresses_copied')"
          :failMessage="$t('copy.addresses_not_copied')"
          :title="$t('copy.notification')"
          :placement="'left'"
          canCopy
      />
    </div>
    <div class="payout-wallet-balance__row">
      <p class="payout-wallet-balance__title-text">{{ $t('business-wallet.amount') }}</p>

      <p class="payout-wallet-balance__value">{{ item.balance }}</p>
    </div>
    <div class="payout-wallet-balance__row business__row-buttons">

      <div class="payout-wallet-balance__withdraw">
        <RouterLink
            class=""
            :to="`/history-address/${ item.id }`"
        >
          <svg width="60" height="40" viewBox="0 0 60 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 13.5C28.0773 13.5055 26.2301 14.2492 24.84 15.5775V14.25C24.84 14.0511 24.761 13.8603 24.6203 13.7197C24.4797 13.579 24.2889 13.5 24.09 13.5C23.8911 13.5 23.7003 13.579 23.5597 13.7197C23.419 13.8603 23.34 14.0511 23.34 14.25V17.625C23.34 17.8239 23.419 18.0147 23.5597 18.1553C23.7003 18.296 23.8911 18.375 24.09 18.375H27.465C27.6639 18.375 27.8547 18.296 27.9953 18.1553C28.136 18.0147 28.215 17.8239 28.215 17.625C28.215 17.4261 28.136 17.2353 27.9953 17.0947C27.8547 16.954 27.6639 16.875 27.465 16.875H25.665C26.6293 15.8673 27.9146 15.227 29.2999 15.0643C30.6852 14.9015 32.0839 15.2265 33.2555 15.9833C34.4271 16.7401 35.2985 17.8814 35.7197 19.2111C36.141 20.5408 36.0859 21.9756 35.5638 23.269C35.0417 24.5625 34.0854 25.6336 32.8591 26.2982C31.6329 26.9629 30.2134 27.1796 28.8447 26.911C27.476 26.6424 26.2436 25.9054 25.3594 24.8266C24.4753 23.7479 23.9946 22.3948 24 21C24 20.8011 23.921 20.6103 23.7803 20.4697C23.6397 20.329 23.4489 20.25 23.25 20.25C23.0511 20.25 22.8603 20.329 22.7197 20.4697C22.579 20.6103 22.5 20.8011 22.5 21C22.5 22.4834 22.9399 23.9334 23.764 25.1668C24.5881 26.4001 25.7594 27.3614 27.1299 27.9291C28.5003 28.4968 30.0083 28.6453 31.4632 28.3559C32.918 28.0665 34.2544 27.3522 35.3033 26.3033C36.3522 25.2544 37.0665 23.918 37.3559 22.4632C37.6453 21.0083 37.4968 19.5003 36.9291 18.1299C36.3614 16.7594 35.4001 15.5881 34.1668 14.764C32.9334 13.9399 31.4834 13.5 30 13.5ZM30 18C29.8011 18 29.6103 18.079 29.4697 18.2197C29.329 18.3603 29.25 18.5511 29.25 18.75V21C29.25 21.1989 29.329 21.3897 29.4697 21.5303C29.6103 21.671 29.8011 21.75 30 21.75H31.5C31.6989 21.75 31.8897 21.671 32.0303 21.5303C32.171 21.3897 32.25 21.1989 32.25 21C32.25 20.8011 32.171 20.6103 32.0303 20.4697C31.8897 20.329 31.6989 20.25 31.5 20.25H30.75V18.75C30.75 18.5511 30.671 18.3603 30.5303 18.2197C30.3897 18.079 30.1989 18 30 18Z" fill="#0059F7"/>
            <rect x="0.5" y="0.5" width="59" height="39" rx="11.5" stroke="var(--border-color)"/>
          </svg>

        </RouterLink>
        <button
            :disabled="Number(item.balance) <= 0 || !isWithdrawalAllowed"
            class="payout-wallet-balance__button-withdraw"
            @click="openCreateWithdrawalRequestModal(item.id)"
        >
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.2861 8.79999L12.2861 4M12.2861 4L7.28613 8.79998M12.2861 4L12.2861 16" :stroke="item.balance <= 0 || !isWithdrawalAllowed ? '#8F949A' : '#0059F7'"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M2.94154 15.7867C3.47593 17.8466 4.68989 19.7275 6.48279 21.0754C9.94394 23.6773 14.7125 23.6533 18.1402 21.0166C19.8915 19.6695 21.073 17.8095 21.5925 15.7774"
                :stroke="item.balance <= 0 || !isWithdrawalAllowed ? '#8F949A' : '#0059F7'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p class="payout-wallet-balance__text-main payout-wallet-balance__button-withdraw-text">
            {{ $t('addresses.withdraw') }}
          </p>
        </button>
      </div>


    </div>
  </div>
  <div class="payout-wallet-balance__wrap payout-wallet-balance__wrap_mobile">
    <div class="subscriptionsItem__mobileHeader">
      <div class="subscriptionsItem__mobileHeader-left">
        <CurrencyImg
            class="subscriptionsItem__mobileHeader-img"
            :currency="item.currencyId"
        />
        <div class="subscriptionsItem__mobileHeader-desc">
          <span>{{ item.currencyId | getCurrencyTicker }}</span>
          <span class="subscriptionsItem__mobileHeader-status">{{ item.networkId | getNetworkName }}</span>
        </div>
      </div>
      <span>{{ item.balance }}</span>
    </div>
    <div class="business__address-container">
      <div class="business__address-item">
        <div class="business__address-label">{{ $t('business-wallet.address') }}</div>
        <div class="business__address-value">
          <CopyString
              class="business__address-copyString"
              :string="item.address"
              :successMessage="$t('copy.addresses_copied')"
              :failMessage="$t('copy.addresses_not_copied')"
              :title="$t('copy.notification')"
              :placement="'left'"
              canCopy
          />
        </div>
      </div>

      <div class="business__address-buttons">
        <RouterLink
            class=""
            :to="`/history-address/${ item.id }`"
        >
          <svg width="60" height="40" viewBox="0 0 60 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 13.5C28.0773 13.5055 26.2301 14.2492 24.84 15.5775V14.25C24.84 14.0511 24.761 13.8603 24.6203 13.7197C24.4797 13.579 24.2889 13.5 24.09 13.5C23.8911 13.5 23.7003 13.579 23.5597 13.7197C23.419 13.8603 23.34 14.0511 23.34 14.25V17.625C23.34 17.8239 23.419 18.0147 23.5597 18.1553C23.7003 18.296 23.8911 18.375 24.09 18.375H27.465C27.6639 18.375 27.8547 18.296 27.9953 18.1553C28.136 18.0147 28.215 17.8239 28.215 17.625C28.215 17.4261 28.136 17.2353 27.9953 17.0947C27.8547 16.954 27.6639 16.875 27.465 16.875H25.665C26.6293 15.8673 27.9146 15.227 29.2999 15.0643C30.6852 14.9015 32.0839 15.2265 33.2555 15.9833C34.4271 16.7401 35.2985 17.8814 35.7197 19.2111C36.141 20.5408 36.0859 21.9756 35.5638 23.269C35.0417 24.5625 34.0854 25.6336 32.8591 26.2982C31.6329 26.9629 30.2134 27.1796 28.8447 26.911C27.476 26.6424 26.2436 25.9054 25.3594 24.8266C24.4753 23.7479 23.9946 22.3948 24 21C24 20.8011 23.921 20.6103 23.7803 20.4697C23.6397 20.329 23.4489 20.25 23.25 20.25C23.0511 20.25 22.8603 20.329 22.7197 20.4697C22.579 20.6103 22.5 20.8011 22.5 21C22.5 22.4834 22.9399 23.9334 23.764 25.1668C24.5881 26.4001 25.7594 27.3614 27.1299 27.9291C28.5003 28.4968 30.0083 28.6453 31.4632 28.3559C32.918 28.0665 34.2544 27.3522 35.3033 26.3033C36.3522 25.2544 37.0665 23.918 37.3559 22.4632C37.6453 21.0083 37.4968 19.5003 36.9291 18.1299C36.3614 16.7594 35.4001 15.5881 34.1668 14.764C32.9334 13.9399 31.4834 13.5 30 13.5ZM30 18C29.8011 18 29.6103 18.079 29.4697 18.2197C29.329 18.3603 29.25 18.5511 29.25 18.75V21C29.25 21.1989 29.329 21.3897 29.4697 21.5303C29.6103 21.671 29.8011 21.75 30 21.75H31.5C31.6989 21.75 31.8897 21.671 32.0303 21.5303C32.171 21.3897 32.25 21.1989 32.25 21C32.25 20.8011 32.171 20.6103 32.0303 20.4697C31.8897 20.329 31.6989 20.25 31.5 20.25H30.75V18.75C30.75 18.5511 30.671 18.3603 30.5303 18.2197C30.3897 18.079 30.1989 18 30 18Z" fill="#0059F7"/>
            <rect x="0.5" y="0.5" width="59" height="39" rx="11.5" stroke="var(--border-color)"/>
          </svg>

        </RouterLink>
        <button
            :disabled="item.balance <= 0 || !isWithdrawalAllowed"
            class="payout-wallet-balance__button-withdraw"
            @click="openCreateWithdrawalRequestModal(item.id)"
        >
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.2861 8.79999L12.2861 4M12.2861 4L7.28613 8.79998M12.2861 4L12.2861 16" :stroke="item.balance <= 0 || !isWithdrawalAllowed ? '#8F949A' : '#0059F7'"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M2.94154 15.7867C3.47593 17.8466 4.68989 19.7275 6.48279 21.0754C9.94394 23.6773 14.7125 23.6533 18.1402 21.0166C19.8915 19.6695 21.073 17.8095 21.5925 15.7774"
                :stroke="item.balance <= 0 || !isWithdrawalAllowed ? '#8F949A' : '#0059F7'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p class="payout-wallet-balance__text-main payout-wallet-balance__button-withdraw-text">
            {{ $t('addresses.withdraw') }}
          </p>
        </button>
      </div>
    </div>
  </div>
</Container>
</template>

<script>
import Container from "../../common/Container";
import CopyString from "../CopyString";
import CurrencyImg from "../CurrencyImg";
import {mapGetters} from "vuex";
export default {
    name: "SubscriptionsAddressesItem",
    props: {
        item: {
            type: Object,
            default: () => ({
                accountId: "",
                address: "",
                alias: null,
                balance: "",
                comment: null,
                currencyId: "",
                id: "",
                isBanned: false,
                networkId: "",
                tag: "",
            }),
        },
    },
    components: {CurrencyImg, CopyString, Container},
    methods: {
        openCreateWithdrawalRequestModal () {
            if (!this.isWithdrawalAllowed) {
                return
            }
            this.$emit('openModal', this.item.id);
        },
    },
    computed: {
        ...mapGetters('organizations', ['userRoleRules']),
        ...mapGetters('app', [
            'getCurrencyNameById',
        ]),
        isWithdrawalAllowed () {
            if (!this.userRoleRules['recurrent']) { return }
            return this.userRoleRules['recurrent'].entities.make_withdrawal
        }
    },
};
</script>

<style lang="scss" scoped>
.payout-wallet-balance {

  &__title-text {
    font-family: var(--font-family);
    font-style: normal;
    font-size: var(--font-size-text-secondary);
    line-height: var(--line-height);
    color: var(--new-front-text-secondary-bg-secondary);
    margin-bottom: 5px;
  }

  &__value {
    font-family: var(--font-family);
    font-style: normal;
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__label {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__select-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 470px;
  }

  &__new-button {
    height: 46px;
    max-width: 211px;
    width: 100%;
    background: #0059F7;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    transition: all 0.3s ease-in;

    &:hover {
      background: var(--blue-400);
    }
  }

  &__container {
    margin-top: 10px;
    margin-bottom: 10px;

    font-weight: 500;

    &-top {
      padding: 30px 35px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  &__top-button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 10px;
  }

  &__filter-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;
  }

  &__currency {
    &-container {
      display: flex;
      align-items: center;
    }

    &-image {
      height: 26px;
      width: 26px;
      margin-right: 16px;
    }
  }

  &__wrap {
    display: grid;
    grid-template-columns: 1.2fr 1fr 1fr .5fr 1fr;
    padding: 20px 30px;

    &_mobile {
      display: none;
    }
  }

  &__withdraw {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    min-width: 230px;
  }

  &__button-withdraw {
    min-height: 40px;
    width: 115px;
    border: 1px solid var(--border-color);
    background: var(--secondary-color);
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 5px;
    cursor: pointer;
    padding-right: 5px;

    p {
      color: var(--additional-font-color);
    }

    &:hover {
      border: 1px solid var(--blue-400);
    }
    &:disabled {
      cursor: unset;
      p {
        color: var(--secondary-font-color);
      }
      &:hover {
        border: 1px solid var(--border-color);
      }
    }
  }

  &__title {
    margin-bottom: 13px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-h1);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__subtitle {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-h3);
    line-height: var(--line-height);
  }

  &__tab {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 66px;
    border-radius: 16px;


    &-underlay {
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;
      min-height: 66px;
      border-radius: 16px;
      justify-content: flex-start;

      &_registry {
        justify-content: flex-end;
      }
    }

    &_right {
      position: absolute;
      right: 5px;
      width: calc(50% + 25px);
      padding-left: 25px;
      background: var(--grey-200);
      z-index: 1;
      border-top: 1px solid var(--border-color);
      border-right: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);
    }

    &_left {
      position: absolute;
      left: 0px;
      width: calc(50% + 25px);
      padding-left: 25px;
      background: var(--grey-200);
      z-index: 1;
      border-top: 1px solid var(--border-color);
      border-left: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);

    }


    &_left-active {
      position: relative;
      z-index: 2;
      background: var(--white-100);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
      border: none;
    }

    &_right-active {
      position: relative;
      z-index: 2;
      background: var(--white-100);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
      border: none;
    }
  }

  &__tab-container {
    min-height: 76px;
    padding: 10px;
    border-radius: 15px;
    background: var(--white-100);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  &__preview {
    width: 100%;
  }


  &__mobile {
    display: block;


    ::v-deep .at-collapse {
      border: 1px solid var(--border-color);
      border-right: none;
      border-left: none;
    }

    ::v-deep .at-collapse__content {
      padding: 0;
      background: var(--secondary-color);
    }

    ::v-deep .at-collapse__header {
      background: var(--secondary-color);
      min-height: 50px;
      display: flex;
      align-items: center;
      padding-left: 40px;
      padding-right: 10px;
      padding-top: 0;
      padding-bottom: 0;
    }

    ::v-deep .at-collapse__icon {
      right: 10px;
      top: 17px;
      font-size: 20px;
      left: unset;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }

    ::v-deep .at-collapse__item--active > .at-collapse__header .at-collapse__icon {
      -webkit-transform: rotate(270deg);
      transform: rotate(270deg);
    }

  }

  &__registry-title {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__registry-status-text {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__registry-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    gap: 10px;
  }

  &__registry-currency-image {
    width: 26px;
    height: 26px;
  }

  &__registry-image-container {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &__registry-info-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding-left: 40px;
    padding-right: 40px;
    min-height: 55px;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
  }
}

@media screen and (max-width: 1200px) {
  .payout-wallet-balance {
    &__withdraw {
      min-width: unset;
      align-items: flex-end;
    }
  }
}

@media screen and (max-width: 950px) {
  .payout-wallet-balance {
    &__filter-container {
      flex-direction: column;
    }

    &__container-top {
      padding: 10px;
    }

    &__top-button-container {

      ::v-deep .button {
        justify-content: center;
        max-width: 190px;
      }
    }

    &__select-container {
      max-width: 100%;
    }

    &__withdraw {
      flex-direction: row;
      justify-content: center;
    }

    &__button-withdraw {
      width: 100%;
      max-width: 115px;
    }

    &__currency {
      display: flex;
      align-items: center;
    }

    &__preview {
      display: flex;
      justify-content: space-between;
      flex: 1;
      padding-right: 60px;
      align-items: center;
    }

    &__row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 40px;
      border-top: 1px solid var(--border-color);

      &:last-of-type {
        border-bottom: none;
      }
    }

    &__wrap {
      display: none;

      &_mobile {
        display: block;
        padding: 0;

      }
    }
  }

}

@media screen and (max-width: 700px) {
  .payout-wallet-balance {
    &__top-button-container {
      flex-direction: column;
    }
  }

}

.subscriptionsItem {
  &__mobileHeader {
    display: none;
  }
}
.business {
  &__address {
    &-container {
      padding: 15px;
    }
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-label {
      color: var(--secondary-font-color);
      font-size: 14px;
      font-weight: 400;

    }
    &-value {
      color: var(--primary-font-color);
      font-size: 14px;
      font-weight: 400;
      display: flex;
      align-items: center;
    }
    &-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
    &-currencyImg {
      width: 24px;
      margin-right: 10px;
    }
  }
  &__container {
    max-width: 1420px;
    padding-top: var(--padding-top-container);
    padding-left: var(--padding-left-container);
    padding-right: var(--padding-right-container);
  }
  &__title {
    margin-bottom: 13px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-h1);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    @media (max-width: 950px) {
      text-align: center;
    }
  }

  &__subtitle {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-h3);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    @media (max-width: 950px) {
      text-align: center;
    }
  }
  &__container-btn {
    display: flex;
    //margin-bottom: 20px;

    //@media screen and (max-width: 576px) {
    //  flex-direction: column;
    //}
  }
  &__top-button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 950px) {
      display: none;
    }
  }
  &__top-mobile-button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    @media (min-width: 950px) {
      display: none;
    }
    &__row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:first-child {
        margin-bottom: 10px;
      }
    }
  }
  &__address-btn {
    width: 176px;
    height: 46px;
    background: #0059F7;
    border-radius: 12px;
    transition: all .3s ease;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #fff;

    svg {
      margin-right: 10px;
    }

    &:hover {
      background: #0028F7;
    }
  }

  &__download {
    &-csv {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 46px;
      height: 46px;
      background: var(--icon-csv);
      border-radius: 12px;
      cursor: pointer;
      margin-right: 32px;
      @media (max-width: 950px) {
        margin-right: 15px;
      }
    }
    &-xls {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 46px;
      height: 46px;
      background: var(--icon-exel);
      border-radius: 12px;
      cursor: pointer;
      margin-right: 10px;
      @media (max-width: 950px) {
        margin-right: 0;
      }
    }
  }
  &__history {
    display: flex;
    align-items: center;
    margin-left: 32px;
  }
  &__row-buttons {
    display: flex;
    align-items: center;
  }
  &__empty-balances {
    margin-right: 4px;
    background: var(--secondary-color);
    border: 1px solid  var(--border-color);
    box-sizing: border-box;
    border-radius: 12px;
    min-height: 46px;
    transition: all 0.3s ease-in;
    color: var(--primary-font-color);

    &:hover {
      border: 1px solid var(--blue-400);
    }

    ::v-deep .at-btn__text {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
    }

    ::v-deep .at-btn__icon {
      font-size: var(--font-size-text-main);
    }
    @media (max-width: 950px) {
      min-width: 234px;
    }
  }

  &__currency {
    &-option {
      margin-right: 15px;
      width: 30px;
      height: 30px;
    }
  }

  &__infoBtn {
    cursor: pointer;
  }
  &__addressBtn {
    @media (max-width: 950px) {
      min-width: 177px !important;
    }
  }
}
@media screen and (max-width: 950px) {
  .subscriptionsItem {
    &__mobileHeader {
      display: flex;
      align-items: center;
      padding: 15px;
      justify-content: space-between;
      //border-bottom: 1px solid var(--border-color);
      > span {
        color: var(--primary-font-color);
        font-size: 16px;
      }
      &-left {
        display: flex;
        align-items: center;
      }
      &-desc {
        font-size: 14px;
        color: var(--primary-font-color);
        display: flex;
        flex-direction: column;
      }

      &-img {
        width: 28px;
        height: 28px;
        margin-right: 10px;
      }

      &-status {
        font-size: 12px;
      }
    }
  }
}
</style>
