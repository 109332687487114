import makeTransferRequest from "../../../../graphQl/mutations/makeTransferRequest";
import {getErrorMessage} from "../../../../filters";
import {v4 as UUID} from "uuid";


export const makeTransferRequestMutation = async ({dispatch, commit, rootGetters}, payload) => {
    let withdrawalRequestData;
    commit('setDepositLoading', true);
    const id = UUID();
    try {

        const organizationId = rootGetters['app/getSelectedOrganization'].id;
        const variables = {
            organizationId,
            ...payload,
            key: id
        };

        withdrawalRequestData = await dispatch('app/request', {
            type: 'mutation',
            gql: makeTransferRequest,
            variables,
        }, {root: true});
        if (withdrawalRequestData.data && withdrawalRequestData.data.makeTransferRequest) {
            const {data: {makeTransferRequest: {code, result}}} = withdrawalRequestData;


            if (code === 0) {
                commit('setDeposit', {code, result});

            } else {
                commit('setDeposit', {code, result});
                commit('setDepositError', getErrorMessage(code));
            }
            commit('setDepositLoading', false);
            commit('setDepositCreated', true);

        }
    } catch (error) {

        commit('setDepositLoading', false);
        commit('setDepositCreated', true);
        return error;
    }


};
