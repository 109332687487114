import gql from 'graphql-tag';

export default gql`
    query ($organizationId: String!, $userId: String) {
        getPersonalAddresses(organizationId:$organizationId, userId:$userId) {
            id
            currencyId
            networkId
            address
            tag
            balance
            alias
            comment
            isBanned
            isActive
            riskLevel
        }
    }
`;
