<template>
    <div class="transaction-info">
        <div class="transaction-info__title-container">
            <div class="transaction-info__title">
                {{ $t('historyAddresses.title') }}
            </div>
            <div class="transaction-info__hash-container">
                <a class="transaction-info__hash-link" :href="viewTxHash(networkId, data.txId)" target="_blank">{{ stringAddress(data.txId) }}</a>
                <CopyString
                        class="transaction-info__item-value"
                        :string="data.txId"
                        :successMessage="$t('copy.transaction_hash')"
                        :failMessage="$t('copy.transaction_hash_failed')"
                        :title="$t('copy.notification')"
                        canCopy
                />
            </div>
        </div>
        <div class="transaction-info__info-container">
            <div class="transaction-info__item " v-if="createdAt">
            <span class="transaction-info__item-title">
            {{ $t('transaction.date_of_creation') }}
            </span>
                <span class="transaction-info__item-value">
                {{ createdAt | getFormatDate }}
            </span>
            </div>
            <div class="transaction-info__item">
            <span class="transaction-info__item-title">
            {{ $t('historyAddresses.name') }}
            </span>
                <span class="transaction-info__item-value">
                {{ data.alias ? data.alias : '-' }}
            </span>
            </div>
            <div class="transaction-info__item">
            <span class="transaction-info__item-title">
            {{ $t('historyAddresses.type_transaction') }}
            </span>
                <div class="transaction-info__item-type">
                    <span class="transaction-info__item-value">
                        {{ data.type === 'deposit' ? $t('historyAddresses.input') : $t('historyAddresses.conclusion') }}
                    </span>
                    <svg v-if="data.type === 'deposit'" width="25" height="24" viewBox="0 0 25 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.8 16.7136L9 11.7136M9 11.7136L13.8 6.71362M9 11.7136L21 11.7136" stroke="#27AE60"
                              stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9.21327 21.0585C7.15341 20.5241 5.27246 19.3101 3.92465 17.5172C1.32272 14.0561 1.34674 9.28748 3.98337 5.85977C5.33047 4.10848 7.19051 2.92699 9.22265 2.40749"
                              stroke="#27AE60" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg v-else width="25" height="24" viewBox="0 0 25 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.2 16.7136L16 11.7136M16 11.7136L11.2 6.71362M16 11.7136L4 11.7136" stroke="#EB5757"
                              stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15.7867 21.0585C17.8466 20.5241 19.7275 19.3101 21.0754 17.5172C23.6773 14.0561 23.6533 9.28748 21.0166 5.85977C19.6695 4.10848 17.8095 2.92699 15.7774 2.40749"
                              stroke="#EB5757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div class="transaction-info__item" v-if="addressFrom">
            <span class="transaction-info__item-title">
            {{ $t('historyAddresses.write-off_address') }}
            </span>
                <CopyString
                        v-if="!isAddressFromTransferType"
                        class="transaction-info__item-value"
                        :string="addressFrom"
                        :successMessage="$t('copy.addresses_copied')"
                        :failMessage="$t('copy.addresses_not_copied')"
                        :title="$t('copy.notification')"
                        canCopy
                />
                <span class="transaction-info__item-value" v-if="isAddressFromTransferType">
                {{ addressFromTransfer }}
            </span>
            </div>
            <div class="transaction-info__item" v-if="addressTo">
            <span class="transaction-info__item-title">
            {{ $t('historyAddresses.transfer_address') }}
            </span>
                <CopyString
                        v-if="!isAddressToTransferType"
                        class="transaction-info__item-value"
                        :string="addressTo"
                        :successMessage="$t('copy.addresses_copied')"
                        :failMessage="$t('copy.addresses_not_copied')"
                        :title="$t('copy.notification')"
                        canCopy
                />
                <span class="transaction-info__item-value" v-if="isAddressToTransferType">
                {{ addressToTransfer }}
            </span>
            </div>

            <div class="transaction-info__item">
                <span class="transaction-info__item-title">
                    {{ $t('transaction.network') }}
                </span>
                <span class="transaction-info__item-value">
                    {{ networkId | getNetworkName }}
                </span>
            </div>
            <div class="transaction-info__item">
            <span class="transaction-info__item-title">
            {{ $t('historyAddresses.write-off_currency') }}
            </span>
                <span class="transaction-info__item-value transaction-info__item-value--currency">
                <CurrencyImg
                        class="transaction-info__currency-img"
                        :currency="data.currencyId"
                />
                {{ data.currencyId | getCurrencyTicker }}
            </span>
            </div>
            <div class="transaction-info__item">
                <span class="transaction-info__item-title">
                    {{ $t('historyAddresses.sum') }}
                </span>
                <span class="transaction-info__item-value">
                    {{data.amount}}
                </span>
            </div>
            <div class="transaction-info__item">
                <span class="transaction-info__item-title">
                     {{ $t('historyAddresses.status') }}
                </span>

                <div class="transaction-info__col-status"
                     :class="{pending: data.status === 'pending'}"
                     :style="`--colorStatus:${mobileStatusTextColor};`"
                >
                    {{ $t(status) }}
                </div>
            </div>
            <div class="transaction-info__item transaction-info__item_transaction">
                <span class="transaction-info__item-title">
                    {{ $t('historyAddresses.comment') }}
                </span>
                <span class="transaction-info__item-value">
                    {{ data.comment ? data.comment : '-' }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>

import {mapState} from 'vuex';

import CurrencyImg from "/public/components/elements/CurrencyImg";
import CopyString from "../../../components/elements/CopyString";
import BigNumber from "bignumber.js";
import {TRANSACTION_HISTORY_STATUS_ENUM} from "../../../../common/constants/transaction-history-status-enum";
import {getNetworkInfoById} from "@/public/filters";

export default {
    name: 'HistoryAddressInfo',
    components: {
        CurrencyImg,
        CopyString,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState('app', {
            currencies: state => state.currencies,
        }),
        addressToTransfer() {
            return this.$t(this.$options.filters.transferAddressType(this.data.addressTo));
        },
        addressFromTransfer() {
            return this.$t(this.$options.filters.transferAddressType(this.data.addressFrom));
        },
        isAddressToTransferType() {
            const ADDRESS_TYPE = {
                PAY_IN: 'transactions.transfer_type.pay_in',
                PAY_OUT: 'transactions.transfer_type.pay_out',
                ADVANCE: 'transactions.transfer_type.advance',
            };
            return ADDRESS_TYPE[this.data.addressTo] || false;
        },
        isAddressFromTransferType() {
            const ADDRESS_TYPE = {
                PAY_IN: 'transactions.transfer_type.pay_in',
                PAY_OUT: 'transactions.transfer_type.pay_out',
                ADVANCE: 'transactions.transfer_type.advance',
            };
            return ADDRESS_TYPE[this.data.addressFrom] || false;
        },
        id() {
            return this.data.id;
        },
        networkId() {
            return this.data.networkId;
        },
        status() {
            return this.transactionHistoryStatus(this.data.status);
        },

        credit() {
            return this.data.credit;
        },
        deposit() {
            return this.data.deposit;
        },
        amountFrom() {
            return this.data.credit ? BigNumber(this.data.credit).decimalPlaces(8) : '0.00';
        },
        mobileStatusTextColor() {
            switch (this.data.status) {
            case TRANSACTION_HISTORY_STATUS_ENUM.INIT:
                return '#6190e8';
            case TRANSACTION_HISTORY_STATUS_ENUM.PROCESSED:
                return '#13ce66';
            case TRANSACTION_HISTORY_STATUS_ENUM.PENDING:
                return '#F2F2F2';
            case TRANSACTION_HISTORY_STATUS_ENUM.ERROR:
                return '#EB5757';
            case TRANSACTION_HISTORY_STATUS_ENUM.REJECTED:
                return '#EB5757';
            default:
                return '#8F949A';
            }
        },
        amountTo() {
            return this.data.deposit ? BigNumber(this.data.deposit).decimalPlaces(8) : '0.00';
        },
        addressFrom() {
            return this.data.addressFrom;
        },
        tagFrom() {
            return this.data.tagFrom;
        },
        addressTo() {
            return this.data.addressTo;
        },
        tagTo() {
            return this.data.tagTo;
        },
        txId() {
            return this.data.txId;
        },
        createdAt() {
            return this.data.createdAt;
        },
        orderId() {
            return this.data.orderId;
        },
    },

    methods: {
        stringAddress(string) {
            return `${string.substr(0, 8)}****${string.substr(-6)}`;
        },
        transactionHistoryStatus(status) {
            const statuses = {
                [TRANSACTION_HISTORY_STATUS_ENUM.INIT]: 'transactions.init',
                [TRANSACTION_HISTORY_STATUS_ENUM.ERROR]: 'transactions.error',
                [TRANSACTION_HISTORY_STATUS_ENUM.PENDING]: 'historyAddresses.pending',
                [TRANSACTION_HISTORY_STATUS_ENUM.PROCESSED]: 'historyAddresses.executed',
                [TRANSACTION_HISTORY_STATUS_ENUM.REJECTED]: 'transactions.rejected',
            };

            return statuses[status] || 'transactions.empty';
        },

        viewTxHash(networkId, hash) {
          const networkName = getNetworkInfoById(networkId).name;
          if (networkName === 'bsc') {
            return `https://bscscan.com/tx/${hash}`;
          } else if (networkName === 'bsc-testnet') {
            return `https://testnet.bscscan.com/tx/${hash}`;
          } else if (networkName === 'fantom') {
            return `https://ftmscan.com/tx/${hash}`;
          } else if (networkName === 'ethereum') {
            return `https://etherscan.io/tx/${hash}`;
          } else if (networkName === 'sepolia') {
            return `https://sepolia.etherscan.io/tx/${hash}`;
          } else if (networkName === 'tron') {
            return `https://tronscan.org/#/transaction/${hash}`;
          } else if (networkName === 'solana') {
            return `https://solscan.io/tx/${hash}`;
          } else if (networkName === 'bitcoin') {
            return `https://blockchair.com/bitcoin/transaction/${hash}`;
          } else if (networkName === 'litecoin') {
            return `https://blockchair.com/litecoin/transaction/${hash}`;
          } else if (networkName === 'bitcoincash') {
            return `https://blockchair.com/bitcoin-cash/transaction/${hash}`;
          } else if (networkName === 'polygon') {
            return `https://polygonscan.com/tx/${hash}`;
          } else if (networkName === 'ton') {
            return `https://tonscan.org/tx/${hash}`;
          }
        },
    },
};

</script>

<style scoped lang="scss">
::v-deep .copy-string__string {
  font-size: 20px;
}

::v-deep .copy-string__copy {
  font-size: 20px;
}

.transaction-info {
  min-width: 764px;
  border-radius: 20px;
  box-shadow: 0 8px 50px rgba(10, 17, 105, 0.08);


  &__title-container {
    min-height: 107px;
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    padding-left: 30px;
    border-radius: 20px 20px 0 0;
    justify-content: space-between;
    padding-right: 30px;
  }

  &__col-status {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 10px;
    background: var(--colorStatus);
    border-radius: 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    width: 93px;

    &.pending {
      color: #2C405A;
    }
  }

  &__hash-container {
    display: flex;

    ::v-deep .copy-string__string {
      display: none;
    }

    ::v-deep .copy-string__copy {
      font-size: 22px;
    }
  }

  &__item-type {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: var(--primary-font-color);
    display: flex;

    span {
      margin-right: 20px;
    }
  }

  &__hash-link {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #0059F7;
    margin-right: 15px;
    text-decoration: underline;
  }

  &__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: var(--primary-font-color);
  }

  &__info-container {
    background: var(--secondary-color);
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 0 0 20px 20px;
    border: 1px solid var(--border-color);
  }

  &__item {
    min-height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    padding-top: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: var(--primary-font-color);

    &:last-of-type {
      border: none;
      padding-bottom: 40px;
    }

    &_transaction {
      align-items: flex-start;
      flex-direction: column;

      span {
        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }

  &__item-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 19px;
    color: #8F949A;
  }

  &__item-value {
    white-space: normal;
    word-break: break-all;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 19px;
    color: var(--primary-font-color);
  }

}

@media screen and (max-width: 950px) {
  .transaction-info {
    max-width: 740px;
    min-width: 100%;

    &__info-container {
      padding-left: 10px;
      padding-right: 10px;
    }

    &__item {
      align-items: flex-start;
      gap: 10px;
    }

    &__item-value {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
    }

    &__item-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }

    &__title-container {
      align-items: start;
      padding-left: 15px;
      padding-right: 15px;
      flex-direction: column;
      justify-content: center;
    }

    &__title {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 5px;
    }

    &__hash-link {
      font-size: 16px;
      line-height: 19px;
    }

    &__hash-container {

      ::v-deep .copy-string__copy {
        font-size: 16px;
      }
    }

    ::v-deep .copy-string__string {
      font-size: 16px;
    }

    ::v-deep .copy-string__copy {
      font-size: 16px;
    }
  }
}


</style>

