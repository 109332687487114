import gql from 'graphql-tag';

export const getAddressSnapshotsGroupedByNetwork = gql`
    query($organizationId: String!, $startDate: Date!, $endDate: Date, $type: [AddressType!]) {
         getAddressSnapshots(groupBy: NETWORK, organizationId: $organizationId, startDate: $startDate, endDate: $endDate, type: $type) {
             currencyGroups {
                  currencyId
                  incr
                  decr
                  
                  startDate
                  endDate
                  
                  startBalance
                  endBalance
                  startBalanceUSD
                  endBalanceUSD
                  
                  networkGroups {
                      networkId
                      incr
                      decr
                      
                      startBalance
                      endBalance
                      startBalanceUSD
                      endBalanceUSD
                      walletGroups {
                          type
                          incr
                          decr
                
                          startBalance
                          endBalance
                          startBalanceUSD
                          endBalanceUSD
                          
                          addresses {
                              id
                              currencyId
                              networkId
                              alias
                              address
                              incr
                              decr
                  
                              startBalance
                              endBalance
                              startBalanceUSD
                              endBalanceUSD
                          }
                      }
                  }
            }
        }
    }
`;

export const getAddressSnapshotsGroupedByWallet = gql`
    query($organizationId: String!, $startDate: Date!, $endDate: Date, $type: [AddressType!]) {
         getAddressSnapshots(groupBy: WALLET, organizationId: $organizationId, startDate: $startDate, endDate: $endDate, type: $type) {
              currencyGroups {
                    currencyId
                    incr
                    decr
              
                    startDate
                    endDate
                
                    startBalance
                    endBalance
                    startBalanceUSD
                    endBalanceUSD
                    
                    walletGroups {
                        type
                        incr
                        decr
                        
                        startBalance
                        endBalance
                        startBalanceUSD
                        endBalanceUSD
                        networkGroups {
                            networkId
                            incr
                            decr
                        
                            startBalance
                            endBalance
                            startBalanceUSD
                            endBalanceUSD                      
                            addresses {
                                id
                                currencyId
                                networkId
                                alias
                                address
                                incr
                                decr
                          
                                startBalance
                                endBalance
                                startBalanceUSD
                                endBalanceUSD
                            }
                        }
                    }
              }
        }
    }
`;

export const getAdvBalanceSnapshots = gql`
    query($organizationId: String!, $startDate: Date!, $endDate: Date) {
         getAdvBalancesSnapshots(organizationId: $organizationId, startDate: $startDate, endDate: $endDate) {
              id
              startBalance
              endBalance
              incr
              decr
              refund
              blockchainFees
              tariffFees {
                tariff
                amount
                includedFees {
                    networkId
                    amount
                }
              }
              startDate
              endDate
        }
    }
`;
