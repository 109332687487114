import AtNotification from "at-ui/src/components/notification";
import {createExchangeMutation} from "../../../../graphQl/mutations/createExchange";

export const createExchange = async ({ dispatch, rootGetters }, payload) => {
    let createExchangeData;
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const variables = {
        ...payload,
        organizationId
    }
    try {
        createExchangeData = await dispatch('app/request', {
            type: 'mutation',
            gql: createExchangeMutation,
            variables,
        }, { root: true });
    } catch (error) {
        return error;
    }

    if (createExchangeData.graphQLErrors) {
        createExchangeData.graphQLErrors?.forEach(e => {
            if(e?.extensions?.exception?.code && typeof e?.extensions?.exception?.code === "number") {
                AtNotification({
                    title: 'Ошибка',
                    message: e.message,
                    type: 'error',
                });
            }
        });

        throw createExchangeData;
    }

    if (createExchangeData.message) {

        AtNotification({
            title: 'Ошибка',
            message: `Ошибка при совершении обмена, напишите в службу поддержки Обратитесь в службу поддержки на почту ${import.meta.env.VITE_SUPPORT_EMAIL} для уточнения подробностей`,
            type: 'error',
        });

        throw createExchangeData.message;
    }

    const {
        data: { createExchange },
    } = createExchangeData;

    return createExchange;
};
