import Store from "../../index";

export class OrderController {
    static createOrder({
        accountId,
        network,
        currency,
        amount,
        availableTill,
        errorWebhook,
        successWebhook,
        returnUrl,
        order,
        description,
        lifetime,
    }) {
        return Store.dispatch('orders/createOrder', {
            accountId,
            network,
            currency,
            amount,
            availableTill,
            errorWebhook,
            successWebhook,
            returnUrl,
            order,
            description,
            lifetime,
        });
    }
}