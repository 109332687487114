import gql from 'graphql-tag';

export default gql`
    query($limit: Float!, $offset: Float!, $sortDate: String,  $date: [String]) {
        getRegistries(limit: $limit, offset: $offset, sortDate: $sortDate,  date: $date) {
            registries {
                id
                title
                createdAt
                status
                updatedAt
            }
            totalCount
        }
    }
`;
