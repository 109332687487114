<template>
    <div class="requests-list-header list-header row">
        <div class="list-header__item col col-5">
            id
        </div>
        <div class="list-header__item col">
            Пара
        </div>
        <div class="list-header__item col">
            Уход
        </div>
        <div class="list-header__item col">
            Приход
        </div>
        <div
            class="list-header__item list-header__item--sorting col col-4"
            @click="sortByDate"
        >
            Дата создания
            <Icon
                class="list-header__icon list-header__chart-icon"
                :class="{ 'list-header__chart-icon--asc': sortByDateStatus === 'ASC' }"
                icon="bar-chart"
            />
        </div>
        <div class="list-header__item col col-3">
            Статус
        </div>
    </div>
</template>

<script>

import { mapActions } from 'vuex';

import Icon from "/public/components/elements/Icon";

export default {
    name: 'ExchangesListHeader',
    components: {
        Icon,
    },
    data () {
        return {
            sortByDateStatus: 'DESC',
        };
    },
    beforeMount() {
        this.setSortByDateFromUrl();
    },
    methods: {
        ...mapActions('exchanges', [
            'fetchExchanges',
        ]),
        setSortByDateFromUrl () {
            const sortDateFromUrl = this.$route.query.sortDate;

            if (!sortDateFromUrl) {
                return false;
            }

            this.sortByDateStatus = sortDateFromUrl;
        },
        sortByDate () {
            const {
                $route,
                $router,
                sortByDateStatus,
                fetchExchanges,
            } = this;

            if (sortByDateStatus === 'DESC') {
                this.sortByDateStatus = 'ASC';
            } else {
                this.sortByDateStatus = 'DESC';
            }

            fetchExchanges({
                route: $route,
                router: $router,
                sortDate: this.sortByDateStatus,
            });
        },
    },
};

</script>
