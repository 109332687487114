<template>
<div :class="['history', disabled ? 'disabled' : '']" @click="emitClick">
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.1875 0.875C1.70425 0.875 1.3125 1.26675 1.3125 1.75V2.625C1.3125 3.10825 1.70425 3.5 2.1875 3.5H2.625V11.375C2.625 12.3415 3.4085 13.125 4.375 13.125H9.625C10.5915 13.125 11.375 12.3415 11.375 11.375V3.5H11.8125C12.2957 3.5 12.6875 3.10825 12.6875 2.625V1.75C12.6875 1.26675 12.2957 0.875 11.8125 0.875H8.75C8.75 0.391751 8.35825 0 7.875 0H6.125C5.64175 0 5.25 0.391751 5.25 0.875H2.1875ZM4.8125 4.375C5.05412 4.375 5.25 4.57088 5.25 4.8125V10.9375C5.25 11.1791 5.05412 11.375 4.8125 11.375C4.57088 11.375 4.375 11.1791 4.375 10.9375L4.375 4.8125C4.375 4.57088 4.57088 4.375 4.8125 4.375ZM7 4.375C7.24162 4.375 7.4375 4.57088 7.4375 4.8125V10.9375C7.4375 11.1791 7.24162 11.375 7 11.375C6.75838 11.375 6.5625 11.1791 6.5625 10.9375V4.8125C6.5625 4.57088 6.75838 4.375 7 4.375ZM9.625 4.8125V10.9375C9.625 11.1791 9.42912 11.375 9.1875 11.375C8.94588 11.375 8.75 11.1791 8.75 10.9375V4.8125C8.75 4.57088 8.94588 4.375 9.1875 4.375C9.42912 4.375 9.625 4.57088 9.625 4.8125Z" :fill="!themeStatus ? '#6750A4' : '#FFFFFF'"/>
    </svg>
</div>
</template>
<script>
    import {mapState} from "vuex";

    export default {
        name: "DeleteButton",
        props: {
            disabled: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapState(['themeStatus']),
        },
        methods: {
            emitClick () {
                this.$emit('click')
            }
        }
    }
</script>
<style scoped lang="scss">
.disabled {
    opacity: .5;
    cursor: not-allowed;
    pointer-events: none;
}
.history {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;

  height: 48px;
  background: var(--new-front-main-bg);
  border: 1px solid #744DB2;
    border-radius: 10px;
  transition: background .2s ease;
    cursor: pointer;
  &:hover {
    background: var(--new-front-bg-btn-secondary-bg-modal-primary-hover);
  }
}
</style>
