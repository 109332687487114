<template>
<base-select
    v-bind="$attrs"
    v-model="valueModel"
    :label="label"
    :placeholder="placeholder"
    :list="filteredList"
    :with-search="false"
    :is-custom-trigger="true"
    uniq-key="id"
    class="advanced_balances__network-select"
    @closed="search = undefined"
>
    <template #trigger="data">
        <div
            class="select-trigger"
            :id="data.id"
        >
            <svg
                width="18"
                height="24"
                viewBox="0 0 18 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style="pointer-events: none"
            >
                <path d="M15 0H3C1.34315 0 0 1.34314 0 3V21C0 22.6569 1.34315 24 3 24H15C16.6569 24 18 22.6569 18 21V3C18 1.34315 16.6569 0 15 0ZM4.5 6H13.5C13.9142 6 14.25 6.33579 14.25 6.75C14.25 7.16421 13.9142 7.5 13.5 7.5H4.5C4.08579 7.5 3.75 7.16421 3.75 6.75C3.75 6.33579 4.08579 6 4.5 6ZM3.75 9.75C3.75 9.33579 4.08579 9 4.5 9H13.5C13.9142 9 14.25 9.33579 14.25 9.75C14.25 10.1642 13.9142 10.5 13.5 10.5H4.5C4.08579 10.5 3.75 10.1642 3.75 9.75ZM4.5 12H13.5C13.9142 12 14.25 12.3358 14.25 12.75C14.25 13.1642 13.9142 13.5 13.5 13.5H4.5C4.08579 13.5 3.75 13.1642 3.75 12.75C3.75 12.3358 4.08579 12 4.5 12ZM4.5 15H9C9.41421 15 9.75 15.3358 9.75 15.75C9.75 16.1642 9.41421 16.5 9 16.5H4.5C4.08579 16.5 3.75 16.1642 3.75 15.75C3.75 15.3358 4.08579 15 4.5 15Z" fill="#6750A4"/>
            </svg>
        </div>
    </template>
    <template #selected="data">
        <item
                v-bind="data"
        />
    </template>
    <template #search>
        <search
                id="search-input"
                class="search"
                v-model="search"
        />
    </template>
    <template class="options-list" #info="data">
        <item
                v-bind="data"
                height="22px"
        />
    </template>
    <template  #options-footer>
        <base-button
            type="secondary"
            @click="addAddress"
            :label="$t('create-withdrawal-request-modal.add_btn')"
        />
    </template>
</base-select>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
    export default {
        name: "AddressBookSelect",
        components: {
            BaseButton,
            Search: () => import(/* webpackChunkName: "/public/components/elements/advanced-balances/NetworkAndCurrenciesList/Search" */ './Search'),
            BaseSelect: () => import(/* webpackChunkName: "/public/components/elements/advance-balances/bh-select" */ '/public/components/elements/advance-balances/bh-select'),
            Item: () => import(/* webpackChunkName: "/public/components/elements/advanced-balances/NetworkAndCurrenciesList/Item" */ './Item'),
        },
        data() {
            return {
                search: undefined
            }
        },
        props: {
            value: {
                type: [ String, Object ],
                default: () => {}
            },
            enableAddAddress: {
                type: Boolean,
              default: true
            },
            invoiceAmount: {
                type: String,
                default: ''
            },
            invoiceCurrency: {
                type: String,
                default: ''
            },
            list: {
                type: Array,
                default: () => ([])
            },
            withSearch: {
                type: Boolean,
                default: false
            },
            label: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: ''
            }
        },
        computed: {
            ...mapState({
                currentCurrencyInfo: state => state.currency,
            }),
            valueModel: {
                get({ value }) {
                    return value
                },
                set(v) {
                    this.$emit('input', v)
                }
            },
            filteredList ({ list, search }) {
                if (search) {
                    return list.filter(({ address }) => address.toLowerCase().includes(search.toLowerCase()))
                }
                return list
            },
            // filteredList({ list, search }) {
            //     //метод фильтрации общего списка валют
            //     if (search) {
            //         return list.filter((netCurr = {}) => {
            //             const currencyName = netCurr['currency'] ? netCurr['currency'].toLowerCase() : ''
            //             const currencyAlias = netCurr['alias'] ? netCurr['alias'].toLowerCase() : ''
            //             const networkName = ( netCurr['networks'] && netCurr['networks']['name'] ) ? netCurr['networks']['name'].toLowerCase() : ''
            //             const networkAlias = ( netCurr['networks'] && netCurr['networks']['alias'] ) ? netCurr['networks']['alias'].toLowerCase() : ''
            //
            //             return networkName.includes(search.toLowerCase()) || networkAlias.includes(search.toLowerCase()) || currencyName.includes(search.toLowerCase()) || currencyAlias.includes(search.toLowerCase())
            //         })
            //     } else {
            //         return list
            //     }
            // }
        },
        methods: {
            ...mapActions('app', [
                'closeModal',
                'openModal',
            ]),
            addAddress() {
                const {openModal} = this;

                openModal({
                    name: 'NewAddressModal',
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
.advanced_balances {
  &__network-select {
    margin: 20px 0;

    .search {
      margin-bottom: 15px;
    }
      .options-list {
          width: 580px;
          right: 0 !important;
          left: unset !important;
      }
  }
}
.select-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border: 1px solid var(--theme-switcher-border);
    border-radius: 10px;
    transition: background .2s ease;
    position: relative;

    &:hover {
        background: var(--new-front-adv-hover-bg);
    }
}
.select-clickBlock {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    &:hover {
        .select-trigger {
            background: var(--new-front-adv-hover-bg);
        }
    }
}
::v-deep.advanced-balances-select-wrapper  {
        .input-wrapper {
            .options-list {
                width: 580px;
                right: 0 !important;
                left: unset !important;
            }
            .list__item {
                .action {
                    display: none;
                }
            }
        }
    @media (max-width: 640px) {
        .input-wrapper {
            .options-list {
                width: calc(100vw - 60px);
                right: 0!important;
                left: unset!important;
            }
        }
    }
    @media (max-width: 575px) {
      .input-wrapper {
        .options-list {
          width: calc(100vw - 40px);
        }
      }
    }
}
::v-deep .base-btn {
    margin-top: 5px;
    .label {
        color: var(--new-front-btn-bg-secondary-text) !important;
    }
}
</style>
