import gql from 'graphql-tag';

export default gql`
subscription ($organizationId: String!) {
  addressBalanceUpdate (organizationId: $organizationId,) {
    organizationId
    addressId
    currencyId
    networkId
    balance
    type
  }
}
`
