import Vue from 'vue';
import App from '@/public';
import store from '@/public/store';
import router from '@/public/router/router';
import {ioVue} from "@/public/constants/io-client";
import AtComponents from 'at-ui';

import '@/public/filters';
import {ioMixin} from "@/public/mixins/io-mixin";
import {i18n} from '@/public/i18n';
import {BlockchainLinks} from "@/public/mixins/blockchain-links";
import Buffer from 'buffer'

Vue.use(ioVue);
Vue.use(AtComponents);
globalThis.Buffer = Buffer
new Vue({
    el: "#app",
    mixins: [
        ioMixin,
        BlockchainLinks,
    ],
    render: h => h(App),
    router,
    store,
    i18n,
});
