export const mutations = {
    invoices: (state, invoices) => {
        state.invoices = invoices;
    },
    totalInvoicesCount: (state, count) => {
        state.totalInvoicesCount = count;
    },
    filtersStatus: (state, status) => {
        state.filters.status = status;
    },
    setAdditionalFees: (state, payload) => {
      state.additionalFees = payload
    },
    setFetching: (state, payload) => {
        state.isLoading = payload
    },
    setInvoice: (state, payload) => {
        state.invoice = payload
    },
    setInvoiceInfoState : (state, payload) => {
      state.isInfoOpened = payload
    },
    clearFilters: (state) => {
        state.filters = {
            status: [],
        };
    },
    clearAllStates: (state) => {
        state.stateinvoicesCountOnPage = 10;
        state.invoices = [];
        state.totalInvoicesCount = 0;
        state.filters = {
            status: [],
        };
        state.orderTransaction = null;
    },
};
