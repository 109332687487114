<template>
  <div class="preview-order-in-modal" >
      <div class="preview-order-in-modal__row">
          <div class="preview-order-in-modal__checkout-link-wrapper">
              <div class="preview-order-in-modal__checkout-link-label">{{ $t('create-order-modal.order-link-label') }}</div>
              <a
                      class="preview-order-in-modal__checkout-link"
                      :href="`${link}`"
                      target="_blank"
              >
                  <span class="preview-order-in-modal__checkout-link-text">{{ link }}</span>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14 0C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2C0 0.895431 0.895431 0 2 0H14ZM5.9038 10.8033L10 6.7071L10 9.47487C10 9.75101 10.2239 9.97487 10.5 9.97487C10.7761 9.97487 11 9.75101 11 9.47487V5.49999C11 5.22385 10.7761 4.99999 10.5 4.99999L6.52517 4.99999C6.24902 4.99999 6.02516 5.22385 6.02516 5.49999C6.02516 5.77614 6.24902 5.99999 6.52517 5.99999L9.29289 5.99999L5.1967 10.0962C5.00144 10.2914 5.00144 10.608 5.1967 10.8033C5.39196 10.9986 5.70854 10.9986 5.9038 10.8033Z" fill="#744DB2"/>
                  </svg>
              </a>
          </div>
          <base-button
            type="primary"
            :label="$t('create-order-modal.copy')"
            height="41"
            @click="copyLink"
          >
              <template #prepend>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2C0 0.89543 0.895431 0 2 0H10C11.1046 0 12 0.895431 12 2V4H14C15.1046 4 16 4.89543 16 6V14C16 15.1046 15.1046 16 14 16H6C4.89543 16 4 15.1046 4 14V12H2C0.895431 12 0 11.1046 0 10V2ZM2 1C1.44772 1 1 1.44772 1 2V10C1 10.5523 1.44772 11 2 11H10C10.5523 11 11 10.5523 11 10V2C11 1.44772 10.5523 1 10 1H2Z" fill="white"/>
                  </svg>
              </template>
          </base-button>
      </div>
      <div class="preview-order-in-modal__qr-wrapper">
          <div class="preview-order-in-modal__qr-border">
              <QrCode
                      class="preview-order-in-modal__qr"
                      :string="address"
              />
          </div>
      </div>
    <div class="preview-order-in-modal__row">

      <div class="preview-order-in-modal__info">
        <div class="preview-order-in-modal__info-item">
                    <span class="preview-order-in-modal__info-item-title">
                        ID:
                    </span>
            <div class="preview-order-in-modal__currency-container">
              <span class="preview-order-in-modal__info-item-value">
                        {{ id | preparedString }}
                  <copy :value="id" />
              </span>
            </div>
        </div>
        <div class="preview-order-in-modal__info-item">
                    <span class="preview-order-in-modal__info-item-title">
                        {{ $t('preview-order-in-modal.currency') }}
                    </span>
          <div class="preview-order-in-modal__currency-container">
            <span class="preview-order-in-modal__info-item-value">
                        {{ currency | getCurrencyTicker }}
                    </span>
          </div>
        </div>
        <div class="preview-order-in-modal__info-item">
                    <span class="preview-order-in-modal__info-item-title">
                        {{ $t('preview-order-in-modal.network') }}
                    </span>
          <span class="preview-order-in-modal__info-item-value">
                        {{ network | getNetworkName }}
                    </span>
        </div>
          <div class="preview-order-in-modal__info-item">
                    <span class="preview-order-in-modal__info-item-title">
                        {{ $t('preview-order-in-modal.address') }}
                    </span>
              <div class="preview-order-in-modal__currency-container">
              <span class="preview-order-in-modal__info-item-value">
                        {{ address }}
                  <copy :value="address" />
              </span>
              </div>
          </div>
<!--        <div v-if="shortContract" class="preview-order-in-modal__info-item">-->
<!--                    <span class="preview-order-in-modal__info-item-title">-->
<!--                       {{ $t('checkout.contract_address') }}-->
<!--                    </span>-->
<!--          <span class="preview-order-in-modal__info-item-value link" @click="viewContract">-->
<!--                        {{ shortContract }}-->
<!--                    </span>-->
<!--        </div>-->
        <div class="preview-order-in-modal__info-item">
                    <span class="preview-order-in-modal__info-item-title">
                        {{ $t('preview-order-in-modal.amount') }}
                    </span>
          <span class="preview-order-in-modal__info-item-value">
                        {{ amount }} {{ currency | getCurrencyTicker }}
                    </span>
        </div>
          <div class="preview-order-in-modal__info-item">
                    <span class="preview-order-in-modal__info-item-title">
                        {{ $t('orders.order') }}:
                    </span>
              <span class="preview-order-in-modal__info-item-value">
                        {{ order }}
                    </span>
          </div>
        <div class="preview-order-in-modal__info-item">
                    <span class="preview-order-in-modal__info-item-title">
                        {{ $t('preview-order-in-modal.valid_until') }}
                    </span>
          <span class="preview-order-in-modal__info-item-value">
                        {{ expiresAt | getFormatDate('DD/MM/YYYY, HH:mm') }}
                    </span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';

import AtButton from 'at-ui/src/components/button';
import CopyString from "../CopyString";
import CurrencyImg from "../CurrencyImg";
import QrCode from "/public/components/elements/QrCode";
import AtPopover from 'at-ui/src/components/popover';
import {getNetworkName} from "../../../filters";
import {BlockchainLinks} from "../../../mixins/blockchain-links";
import Copy from "@/public/components/common/copy.vue";
import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
import QRBlock from "@/entries/checkout/components/QR.vue";

export default {
    name: 'PreviewOrderInModal',
    components: {
        QRBlock,
        BaseButton,
        Copy,
        AtButton,
        CopyString,
        CurrencyImg,
        QrCode,
        AtPopover,
    },
    mixins: [
        BlockchainLinks,
    ],
    props: {
        data: {
            type: Object,
            required: true,
            default: () => ({
              id: '',
              address: '',
              currency: '',
                networkId: '',
                amount: '',
                link: '',
                expiresAt: ''
            })
        },
        order: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapState('app', [
            'currencies',
        ]),
        ...mapGetters('app', [
            'getCurrencyNameById',
        ]),
        id() {
            return this.data ? this.data.id : '';
        },
        address() {
            return this.data  ? this.data.address : '';
        },
        currency() {
            return this.data  ? this.data.currency : '';
        },
        network() {
            return this.data  ? this.data.networkId : '';
        },
        amount() {
            return this.data  ? this.data.amount : '';
        },
        link() {
            return this.data  ? this.data.link : '';
        },
        expiresAt() {
            return this.data  ? this.data.expiresAt : '';
        },

        contract() {
            const {
                currency,
                getCurrencyNameById,
                currencies,
            } = this;
            if (!currency) {
                return ''
            }
            return currencies.find(el => el.currency === getCurrencyNameById(currency)).networks.find(e => e.alias === getNetworkName(this.network)).contract ?
                currencies.find(el => el.currency === getCurrencyNameById(currency)).networks.find(e => e.alias === getNetworkName(this.network)).contract :
                '';

        },
        shortContract() {
            return this.$linkName(this.contract, this.network);
        },
        contractLink() {
            return this.$contractLink(this.currency, this.network);
        },
    },
    methods: {
        handlePrint() {
            window.print();
        },
        async copyLink() {
            const { link } = this;
            await navigator.clipboard.writeText(link)
        },
        viewContract() {
            // let link, source;
            //
            // if (getNetworkName(this.network) === 'Fantom') {
            //     link = `https://ftmscan.com//token/${this.contract}`;
            //     source = 'FTMScan';
            // } else if (getNetworkName(this.network) === 'Ethereum') {
            //     link = `https://etherscan.io/token/${this.contract}`;
            //     source = 'EtherScan';
            // } else if (getNetworkName(this.network) === 'Tron') {
            //     link = `https://tronscan.org/#/token20/${this.contract}`;
            //     source = 'TronScan';
            // } else if (getNetworkName(this.network) === 'Solana') {
            //     link = `https://solscan.io/token/${this.contract}`;
            //     source = 'SolScan';
            // }
            //
            // if (!link) {
            //     return;
            // }

            window.open(this.contractLink);
        },
    },
};

</script>

<style lang="scss" scoped>

.preview-order-in-modal {
  &__currency-container {
    display: flex;
    align-items: center;
    gap: 14px;
  }

  &__currency-img {
    width: 24px;
    height: 24px;
  }

  &__row {
    display: flex;
    justify-content: center;
    //border: 1px solid var(--border-color);
    //border-radius: 12px;
    //padding: 20px;
    align-items: center;

    ::v-deep .at-btn.preview-order-in-modal__print {
      border: none;
      width: unset;
      height: unset;
    }

    &:first-of-type {
      display: flex;
      align-items: end;
      gap: 10px;
      border: none;
      margin-bottom: 20px;
      padding: 0;
    }
  }

  &__print {
    border: none;
    width: unset;
    height: unset;
    align-self: start;
    padding: 0;
    @media print {
      visibility: hidden;
    }


    ::v-deep .at-btn__icon {
      font-size: 24px;
      line-height: 1;
      color: #0059F7;
    }
  }
  &__checkout-link-label {
    font-size: $h6;
    color: var(--new-front-input-label-font-color);
    margin-bottom: 5px;
  }
  &__checkout-link-wrapper {
    width: 70%;
  }
  &__checkout-link {
    padding: 10px 0 10px 15px;
    border: 1px solid var(--new-front-input-border);
    border-radius: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
  }
  &__checkout-link-text {

    font-family: $Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--new-front-btn-bg-primary);
    text-overflow: ellipsis;
    max-width: 90%;
    text-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
  }

  &__external-link {
    font-size: 19px;
    line-height: 1;
    color: #0059F7;
  }

  &__qr {
    width: 200px;
    height: 200px;
    &-wrapper {
      display: flex;
      width: 100%;
      margin: 20px 0;
      justify-content: center;
    }
    &-border {
      padding: 20px;
      border: 1px solid var(--new-front-input-border);
      border-radius: 30px;
    }
  }

  &__info {
    width: 100%;
    max-width: 650px;
    position: relative;
    padding: 20px 0;
    //&:before {
    //  content: '';
    //  display: block;
    //  width: 110%;
    //  left: -29px;
    //  height: 1px;
    //  position: absolute;
    //  top: 0;
    //  border-bottom: 1px solid var(--new-front-input-border);
    //}
    //&:after {
    //  content: '';
    //  display: block;
    //  width: 110%;
    //  left: -29px;
    //  height: 1px;
    //  position: absolute;
    //  bottom: 0;
    //  border-bottom: 1px solid var(--new-front-input-border);
    //}
  }

  &__info-item {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__info-item {
    word-break: break-all;
  }

  &__info-item-title {
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--new-front-secondary-font-color);
  }

  &__info-item-value {
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--new-front-primary-font-color);
    display: flex;
    align-items: center;
    &::v-deep {
      .copy-wrapper {
        margin-right: 0;
      }
    }

    &.link {
      color: #0059F7;
      cursor: pointer;
    }
  }

  .qr-code__container {
    position: relative;
  }

  .border-square {
    position: absolute;
    background-image: linear-gradient(#2C405A, #2C405A);
  }

  .border-square_left-top {
    top: -20px;
    left: -20px;
    height: 2px;
    width: 23px;
  }

  .border-square_left-top-horizontal {
    top: -20px;
    left: -20px;
    height: 23px;
    width: 2px;
  }

  .border-square_right-top {
    top: -20px;
    right: -20px;
    height: 2px;
    width: 23px;
  }

  .border-square_right-top-horizontal {
    top: -20px;
    right: -20px;
    height: 23px;
    width: 2px;
  }

  .border-square_left-bottom {
    bottom: -20px;
    left: -20px;
    height: 2px;
    width: 23px;
  }

  .border-square_left-bottom-horizontal {
    bottom: -20px;
    left: -20px;
    height: 23px;
    width: 2px;
  }

  .border-square_right-bottom {
    bottom: -20px;
    right: -20px;
    height: 2px;
    width: 23px;
  }

  .border-square_right-bottom-horizontal {
    bottom: -20px;
    right: -20px;
    height: 23px;
    width: 2px;
  }

}

@media screen and (max-width: 950px) {
  .preview-order-in-modal {
    &__row {
      &:first-of-type {
        margin-bottom: 35px;
      }
    }
  }


}

@media screen and (max-width: 450px) {
  .preview-order-in-modal {
    &__row {
      padding-left: 10px;
      padding-right: 10px;

      &:first-of-type {
        padding: 0;
      }
    }

    &__info-item {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__info-item-title {
      font-size: 14px;
      line-height: 16px;
    }

    &__checkout-link-text {
      font-size: 14px;
      line-height: 16px;
    }

    &__info-item-value {
      font-size: 14px;
      line-height: 16px;
    }
  }
    // todo: проверить отсутствие этого
  //&__select-container_img {
  //  padding-left: 53px;
  //}

}

</style>
