<template>
  <div class="preview-network 13223">
    <AtCollapse accordion>
      <AtCollapseItem  class="collapse-at" data="">
        <div class="preview-network__preview" slot="title">
            <div class="preview-network__element currency">
                <icon :currency="currencyTicker" :network="networkName" />
                <p class="preview-network__title">{{ currencyId | getCurrencyTicker }} <span class="secondary-text">{{ networkList.networkId | getNetworkSystemName | getNetworkShortNameByNetworkName }} </span></p>
            </div>
<!--          <p class="preview-network__title">{{ networkList.networkId | getNetworkName }}</p>-->
            <div class="preview-network__element mobileHidden">
                <p class="preview-network__sub">{{ $t('addresses.card.title.addresses') }}</p>
                <p class="preview-network__title">{{ networkList.addresses.length }}</p>
            </div>
            <div class="preview-network__element balance">
                <p class="preview-network__sub">{{ $t('addresses.card.title.balance') }}</p>
                <p class="preview-network__title">{{ networkList.balance | separatePeriods }} {{ currencyId | getCurrencyTicker }}&ensp; <span class="secondary-text">{{ networkList.networkId | getNetworkSystemName | getNetworkShortNameByNetworkName }}</span></p>
            </div>
        </div>
        <div class="preview-network__detailed-info" v-for="address in networkList.addresses" :key="address.id">
          <div class="preview-network__row">
              <div class="preview-network__element currency-detailed-info">
                  <p class="preview-network__sub">{{ $t('addresses.card.title.address') }}</p>
                  <p class="preview-network__title">{{ address.address | preparedString }} <copy :value="address.address"/></p>
              </div>
            <address-risks :status="address.riskLevel"  />
              <div class="preview-network__element balance">
                  <p class="preview-network__sub">{{ $t('addresses.card.title.singleBalance') }}</p>
                <p class="preview-network__title">{{ new BigNumber(address.balance).decimalPlaces(8).toFixed() | separatePeriods }}&ensp;<span class="secondary-text">{{ currencyId | getCurrencyTicker }} {{ networkList.networkId | getNetworkSystemName | getNetworkShortNameByNetworkName }}</span></p>
              </div>

            <div class="preview-network__withdraw actions">
                <actions-popup>
                    <div :class="['actions__modal-item', Number(address.balance) <= 0 || !isWithdrawalAllowed ? 'disabled' : '']" @click="openCreateWithdrawalRequestModal(address)">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 4.19999L7 1M7 1L4 4.19999M7 1L7 9" stroke="var(--new-front-secondary-font-color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1.48944 7.66118C1.80506 8.82546 2.52204 9.88861 3.58095 10.6504C5.62516 12.1211 8.44156 12.1075 10.466 10.6172C11.5004 9.85583 12.1982 8.80449 12.505 7.65588" stroke="var(--new-front-secondary-font-color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>{{ $t('addresses.withdraw') }}</span>
                    </div>
                    <div class="actions__modal-item" @click="$router.push(`/history-address/${ address.id }`)">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 1C5.46183 1.00439 3.98409 1.59935 2.872 2.662V1.6C2.872 1.44087 2.80879 1.28826 2.69626 1.17574C2.58374 1.06321 2.43113 1 2.272 1C2.11287 1 1.96026 1.06321 1.84774 1.17574C1.73521 1.28826 1.672 1.44087 1.672 1.6V4.3C1.672 4.45913 1.73521 4.61174 1.84774 4.72426C1.96026 4.83678 2.11287 4.9 2.272 4.9H4.972C5.13113 4.9 5.28374 4.83678 5.39626 4.72426C5.50878 4.61174 5.572 4.45913 5.572 4.3C5.572 4.14087 5.50878 3.98826 5.39626 3.87574C5.28374 3.76321 5.13113 3.7 4.972 3.7H3.532C4.30347 2.89381 5.3317 2.38159 6.43992 2.2514C7.54814 2.12121 8.66709 2.38118 9.60439 2.98663C10.5417 3.59207 11.2388 4.50515 11.5758 5.56887C11.9128 6.6326 11.8687 7.78051 11.451 8.81524C11.0334 9.84996 10.2683 10.7069 9.28729 11.2386C8.30629 11.7703 7.17069 11.9436 6.07573 11.7288C4.98077 11.5139 3.99488 10.9243 3.28755 10.0613C2.58023 9.19831 2.19567 8.11583 2.2 7C2.2 6.84087 2.13679 6.68826 2.02426 6.57573C1.91174 6.46321 1.75913 6.4 1.6 6.4C1.44087 6.4 1.28826 6.46321 1.17574 6.57573C1.06321 6.68826 1 6.84087 1 7C1 8.18669 1.35189 9.34672 2.01118 10.3334C2.67047 11.3201 3.60754 12.0891 4.7039 12.5433C5.80025 12.9974 7.00665 13.1162 8.17054 12.8847C9.33443 12.6532 10.4035 12.0818 11.2426 11.2426C12.0818 10.4035 12.6532 9.33443 12.8847 8.17054C13.1162 7.00665 12.9974 5.80025 12.5433 4.7039C12.0891 3.60754 11.3201 2.67047 10.3334 2.01118C9.34672 1.35189 8.18669 1 7 1ZM7 4.6C6.84087 4.6 6.68826 4.66321 6.57573 4.77574C6.46321 4.88826 6.4 5.04087 6.4 5.2V7C6.4 7.15913 6.46321 7.31174 6.57573 7.42426C6.68826 7.53678 6.84087 7.6 7 7.6H8.2C8.35913 7.6 8.51174 7.53678 8.62426 7.42426C8.73678 7.31174 8.8 7.15913 8.8 7C8.8 6.84087 8.73678 6.68826 8.62426 6.57573C8.51174 6.46321 8.35913 6.4 8.2 6.4H7.6V5.2C7.6 5.04087 7.53678 4.88826 7.42426 4.77574C7.31174 4.66321 7.15913 4.6 7 4.6Z" fill="var(--new-front-secondary-font-color)"/>
                        </svg>
                        <span>{{ $t('profits.history') }}</span>
                    </div>
                </actions-popup>
            </div>

          </div>
        </div>
      </AtCollapseItem>
    </AtCollapse>
  </div>
</template>

<script>
import CopyString from "../CopyString";
import {mapActions, mapGetters, mapState} from "vuex";
import Copy from "@/public/components/common/copy.vue";
import {getCurrencyTicker, getNetworkInfoById, getNetworkSystemName} from "@/public/filters";
import ActionsPopup from "@/public/components/common/uiKit/actionsPopup/ActionsPopup.vue";
import AddressRisks from "@/public/components/elements/address-risks/AddressRisks.vue";
import BigNumber from "bignumber.js";

export default {
    name: "BalanceCardNetworkList",
    components: {
      AddressRisks,
        ActionsPopup,
        Copy,
        CopyString,
        Icon: () => import(/* webpackChunkName: "/public/components/elements/advance-balances/NetworkAndCurrenciesSelect/CurrencyNetworkIcon" */ '/public/components/elements/advance-balances/NetworkAndCurrenciesSelect/CurrencyNetworkIcon'),
    },
    props: {
        networkList: {
            type: Object,
            required: true,
        },
        currencyId: {
            type: String,
            required: true
        }
    },
    methods: {
      BigNumber,
        getNetworkSystemName,
        ...mapActions('app', [
            'openModal',
        ]),

        openCreateWithdrawalRequestModal(addressObj) {
            const {
                openModal,
            } = this;

            if (!this.userOtpEnabled) {
              openModal({
                name: 'TwoFaRestriction',
              });
              return;
            }

            if (Number(this.accounts[0].balance) < 0) {
                this.$emit('openNotEnoughModal');
                return;
            }
            if (!this.isWithdrawalAllowed) {
                return;
            }
            openModal({
                name: 'CreateWithdrawalRequestModal',
                payload: {
                    addressObj,
                },
            });
        },

    },
    computed: {
        ...mapState('accounts', ['accounts']),
      ...mapState('user', {
        userOtpEnabled: state => state.user.otpToken,
      }),
        ...mapGetters('organizations', ['userRoleRules']),
        isWithdrawalAllowed () {
            return this.userRoleRules['wallets_payin'].entities.make_withdrawal;
        },

        currencyTicker () {
            return getCurrencyTicker(this.currencyId)
        },
        networkName () {
            return getNetworkInfoById(this.networkList.networkId).name.toUpperCase()
        }
    },

};
</script>

<style scoped lang="scss">
.preview-network {

  &__button-withdraw-text {
    color: var(--additional-font-color);
  }

  &:last-child {

    ::v-deep .at-collapse {
      border-style: none;
    }

    padding-bottom: 10px;

  }

  &__address {
    padding-left: 45px;
  }

  &__row {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 50px;
    gap: 10px;
    align-items: center;
    min-height: 70px;
    color: var(--new-front-primary-font-color);

  }

  ::v-deep .at-collapse {
    border-bottom: none;
    border-right: none;
    border-left: none;
    border-top: none;
    overflow: unset !important;
  }

  ::v-deep .at-collapse__content {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    background: var(--new-front-main-bg);
    overflow: unset !important;
    border: 1px solid var(--new-front-divider-color);
    border-radius: 10px;
    margin-bottom: 10px;
    &:before {
      content: '';
      display: none;
      //border-right: 2px dashed var(--border-color);
    }
  }

  ::v-deep .at-collapse__header {
    border-radius: 10px;
    background: var(--new-front-select-item-bg-active);
    min-height: 65px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 10px;
    .icon-chevron-right:before {
      content: '';
      display: block;
      width: 8px;
      height: 6px;
      transform: rotate(180deg);
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.228 1.635C0.085 1.462 0 1.241 0 1C0 0.448 0.448 0 1 0H7C7.552 0 8 0.448 8 1C8 1.241 7.915 1.462 7.772 1.635L4.808 5.589C4.626 5.838 4.332 6 4 6C3.668 6 3.374 5.838 3.192 5.589L0.228 1.635Z' fill='%23000000'/%3E%3C/svg%3E%0A");
    }
    :root[data-theme-private="dark"] {
      &::v-deep .icon-chevron-right:before {
        content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.228 6.635C4.085 6.462 4 6.241 4 6C4 5.448 4.448 5 5 5H11C11.552 5 12 5.448 12 6C12 6.241 11.915 6.462 11.772 6.635L8.808 10.589C8.626 10.838 8.332 11 8 11C7.668 11 7.374 10.838 7.192 10.589L4.228 6.635Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
      }
    }
  }
  ::v-deep .at-collapse__icon {
    right: 60px;
    top: 27px;
    font-size: 20px;
    left: unset;
    transform: rotate(180deg);

  }

  ::v-deep .at-collapse__item--active > .at-collapse__header .at-collapse__icon {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  ::v-deep .at-collapse__content:before {
    border: none;
  }
  &__element {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  &__title {
    font-family: $Gilroy;
    font-size: $h4;
    color: var(--new-front-primary-font-color);
    display: flex;
    align-items: center;
    font-weight: 500;
  }
  &__sub {
    font-family: $Gilroy;
    font-size: $h4;
    color: var(--new-front-secondary-font-color);
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  &__detailed-info {
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid var(--new-front-divider-color);
    position: relative;
    &:last-of-type {
      border-bottom: none;
    }
    &:before {
      content: '';
      position: absolute;
      left: 78px;
      top: calc(50%);
      width: 26px;
      //border-bottom: 2px dashed var(--border-color);
    }

    &:only-child {
      border: none;
    }
  }


  &__withdraw {
    display: flex;
    align-items: center;
    justify-self: end;
    color: var(--primary-font-color);
    > a {
      margin-right: 10px;
    }
  }

  &__button-withdraw {
    min-height: 40px;
    width: 115px;
    border: 1px solid var(--border-color);
    background: var(--secondary-color);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: all 0.3s ease-in;
    cursor: pointer;

    &:hover {
      border: 1px solid var(--blue-400);
    }
  }

  &__preview {
    display: grid;
    grid-template-columns: .5fr .5fr .7fr .1fr;
    width: 100%;
    gap: 10px;
    background: var(--new-front-select-item-bg-active);
  }


}
.actions__modal-item {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 100px;
  width: 100%;
  border-bottom: 1px solid var(--new-front-input-border);
  color: var(--new-front-secondary-font-color);
  font-weight: 500;
  padding: $p10;
  transition: all .2s ease;
  cursor: pointer;
  &:hover {
    color:var(--new-front-primary-font-color) ;
    background: var(--new-font-nav-hover-bg);
  }
  &:first-of-type {
    &:hover {
      &::v-deep svg {
        path {
          stroke: #744DB2 !important;
        }
      }
    }
  }
  &:nth-child(2){
    border-bottom: unset;
    &:hover {
      &::v-deep svg {
        path {
          fill: #744DB2 !important;
        }
      }
    }
  }
}
.secondary-text {
  color: var(--new-front-secondary-font-color)
}
@media screen and (max-width: 850px) {
  .preview-network {
    ::v-deep .at-collapse__content {


      &:before {
        content: '';
        display: none;
      }
    }
    ::v-deep .at-collapse {
      border-bottom: none;
      border-right: none;
      border-left: none;
      border-top: none;
      overflow: unset !important;
    }

    &__row {
      gap: 0;
      grid-template-columns: 1fr 50px;
      grid-template-areas:
              'currency actions'
              'balance actions'
    ;
      min-height: 70px;

    }
    &__preview {
      grid-template-areas:
              'currency'
              'balance'
    ;
      grid-template-columns: 1fr;
      padding-top: 15px;
      padding-bottom: 5px;
    }
    &__button-withdraw {
      width: 100%;
      border: none;

      &-text {
        display: none;
      }
    }
    &__address {
      padding-left: 10px;
    }
    &__withdraw-history {
      margin-right: 0;
      rect {
        display: none;
      }
    }
    &__detailed-info {
      padding-left: 10px;
      padding-right: 10px;

      &:before {
        content: '';
        display: none;
      }
    }

    &__title {
      font-size: var(--font-size-text-main);
    }

    ::v-deep .at-collapse__header {
      padding: 0 10px;
    }

    ::v-deep .at-collapse__icon {
      right: 10px;
    }
  }
  .mobileHidden {
    display: none;
  }
  .balance {
    grid-area: balance;
  }
  .currency {
    grid-area: currency;
    padding-left: 10px;
  }
  .currency-detailed-info {
    grid-area: currency;
  }
  .actions {
    grid-area: actions;
  }
}
.disabled {
  opacity: .5;
  cursor: not-allowed! important;
}
</style>
