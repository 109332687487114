import gql from 'graphql-tag';

export default gql`
  query(
    $organizationId: String!,
    $id: [String!]!
    $clientId: [String!]!
    $status: [CrosschainSwapStatus!]!
    $currencyFromId: [String!]!
    $currencyToId: [String!]!
    $networkFromId: [String!]!
    $networkToId: [String!]!
    $addressFromId: [String!]!
    $addressToId: [String!]!
    $amount: [String!]!
    $createdAt: [String!]!
    $offset: Int!
    $limit: Int!
    $sort: String!
  ) {
    crosschainSwaps(
      organizationId: $organizationId,
      id: $id, 
      clientId: $clientId,
      status: $status,
      currencyFromId: $currencyFromId,
      currencyToId: $currencyToId,
      networkFromId: $networkFromId,
      networkToId: $networkToId,
      addressFromId: $addressFromId,
      addressToId: $addressToId,
      amount: $amount,
      createdAt: $createdAt,
      offset: $offset,
      limit: $limit,
      sort: $sort
    ) {
      count,
      swaps {
        id
        clientId
        accountId
        status
        rejectMessage
        currencyFromId
        networkFromId
        currencyToId
        networkToId
        addressFromId
        addressFrom
        addressToId
        addressTo
        amountFrom
        amountFromUSD
        amountTo
        amountToUSD
        price
        blockchainFeeSource
        blockchainFeeFrom
        blockchainFeeFromUSD
        blockchainFeeTo
        blockchainFeeToUSD
        serviceFee
        serviceFeeUSD
        createdAt
      }
    }
  }
`;
