import gql from 'graphql-tag';

export default gql`
     mutation($organizationId: String!, $pageId: String!, $alias: String!) {
         changePreCheckoutAlias(organizationId: $organizationId, pageId: $pageId, alias: $alias){
            code
            result
         }
    }
`;
