import gql from 'graphql-tag';

export default gql`
    query {
        getUserContent {
            code
            result {
                showCommissionWindow
            }
        }
    }
`;
