import {v4 as UUID} from 'uuid';

import CreateWithdrawalRequest from '/public/graphQl/mutations/createWithdrawalRequest';
import {getErrorMessage} from "../../../../filters";

export const createWithdrawal = async ({ state, commit, dispatch, rootGetters }, payload) => {

    dispatch('clearFee');
    dispatch('clearWithdrawal');

    if(state.withdrawal.pending) {
        return;
    }

    const id = UUID();

    commit('setWithdrawalRequestId', id);
    commit('setWithdrawalPending', true);

    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    try {
        const withdrawalRequestData = await dispatch('app/request', {
            type: 'mutation',
            gql: CreateWithdrawalRequest,
            variables: {
                ...payload,
                comment: payload.comment ? payload.comment : payload?.description,
                organizationId,
                key: id,
                isAsync: false
            },
        }, { root: true });

        if(id !== state.withdrawalRequestId) {
            return;
        }

        if (withdrawalRequestData.graphQLErrors) {
            if (withdrawalRequestData.graphQLErrors[0].extensions.code === "INCORRECT_2FA") {
                commit('setWithdrawalError', getErrorMessage(withdrawalRequestData.graphQLErrors[0].extensions.code));
                return
            }
            commit('setWithdrawal', {
                id: '',
                status: withdrawalRequestData.graphQLErrors[0].extensions.code !== 0 ? 'error' : '',
                address: '',
                tx: '',
                tag: '',
                addressId: '',
            });
            commit('setWithdrawalError', withdrawalRequestData.graphQLErrors[0].message);
        } else {
            const {data: {createWithdrawalRequest: {code, result, traceId}}} = withdrawalRequestData;

            if(code === 0) {
                commit('setWithdrawal', {
                    id: result.id,
                    status: result.status,
                    address: result.address,
                    tx: result.tx,
                    tag: result.tag,
                    traceId,
                    addressId: result.addressId,
                    networkId: result.networkId,
                });
            } else {
                commit('setWithdrawal', {
                    id: '',
                    status: code !== 0 ? 'error' : '',
                    address: '',
                    tx: '',
                    tag: '',
                    addressId: '',
                    traceId
                });
                commit('setWithdrawalError', getErrorMessage(code));
            }

        }
        commit('setWithdrawalComplete', true);
        commit('setWithdrawalPending', false);
    } catch (error) {
        if(id !== state.withdrawalRequestId) {
            return;
        }

        commit('setWithdrawalPending', false);
        commit('setWithdrawalComplete', true);

        return error;
    }
};
