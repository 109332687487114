<template>
    <div class="transactions-list-header list-header">
        <div class="transactions-list-header__item list-header__item col col-5">
            id
        </div>
        <div class="transactions-list-header__item list-header__item col col-3">
            Статус
        </div>
        <div class="transactions-list-header__item list-header__item col col-5">
            id ордера
        </div>
        <div class="transactions-list-header__item list-header__item col col-3">
            Валюта
        </div>
        <div class="transactions-list-header__item list-header__item col col-3">
            Сумма
        </div>
        <div
            class="transactions-list-header__item list-header__item col col-4"
        >
            Будет доступна
        </div>
        <div
            class="transactions-list-header__item list-header__item list-header__item--sorting col col-4"
            @click="sortByDate"
        >
            Дата создания
            <Icon
                class="list-header__icon list-header__chart-icon"
                :class="{ 'list-header__chart-icon--asc': sortByDateStatus === 'ASC' }"
                icon="bar-chart"
            />
        </div>
    </div>
</template>

<script>

import { mapActions } from 'vuex';

import Icon from "/public/components/elements/Icon";

export default {
    name: 'RollingReserveListHeader',
    components: {
        Icon,
    },
    data () {
        return {
            sortByDateStatus: 'DESC',
        };
    },
    beforeMount() {
        this.setSortByDateFromUrl();
    },
    methods: {
        ...mapActions('transactions', [
            'fetchTransactions',
        ]),
        setSortByDateFromUrl () {
            const sortDateFromUrl = this.$route.query.sortDate;

            if (!sortDateFromUrl) {
                return false;
            }

            this.sortByDateStatus = sortDateFromUrl;
        },
        sortByDate () {
            const {
                $route,
                $router,
                sortByDateStatus,
                fetchTransactions,
            } = this;

            if (sortByDateStatus === 'DESC') {
                this.sortByDateStatus = 'ASC';
            } else {
                this.sortByDateStatus = 'DESC';
            }

            fetchTransactions({
                route: $route,
                router: $router,
                sortDate: this.sortByDateStatus,
            });
        },
    },
};

</script>

<style lang="scss" scoped>

    .transactions-list-header {
        padding: 0;
        background-color: $white;
        border: none;

        &__item {
            padding: 6px 0 6px;
            background-color: $gray;
            border-top: 1px solid $gray2;
            border-bottom: 1px solid $gray2;

            &:first-child {
                padding-left: 16px;
                border-left: 1px solid $gray2;
            }

            &:last-child {
                padding-right: 16px;
                border-right: 1px solid $gray2;
            }
        }
    }

</style>
