<template>
  <div class="transactions-sort-by-currency-from">
    <p class="transactions-sort-by-currency-from__title">
      {{ $t('transactions.debiting_currencies') }}
    </p>
    <AtSelect
        v-if="hasCurrencies"
        class="transactions-sort-by-currency-from__select"
        :placeholder="select_the_debiting_currencies"
        clearable
        :value="selectedCurrencies[0]"
        @on-change="changeSelect"
    >
<!--      <AtOption-->
<!--          v-for="(currency, key) in sortCurrencies"-->
<!--          :key="key"-->
<!--          :value="currency.currency"-->
<!--      />-->
      <AtOption v-for="currency in sortCurrencies" :key="currency.currency" :value="currency.currency" :label="currency.currency">
        <CurrencyImg class="currencyImg" :currency="currency.currency"/>
        {{ currency.currency }}
      </AtOption>
    </AtSelect>
  </div>
</template>

<script>

import {mapState, mapGetters, mapActions} from "vuex";

import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';
import CurrencyImg from "../CurrencyImg";

export default {
    name: 'TransactionsSortByCurrencyFrom',
    components: {
        CurrencyImg,
        AtSelect,
        AtOption,
    },
    data() {
        return {
            selectedCurrencies: [],
        };
    },
    computed: {
        ...mapState('app', [
            'currencies',
        ]),
        ...mapGetters('app', [
            'hasCurrencies',
        ]),
        select_the_debiting_currencies() {
            return this.$t('transactions.select_the_debiting_currencies');
        },
        sortCurrencies() {
            return this.currencies.filter(el => el.isFiat === false);
        },
    },
    beforeMount() {
        this.setCurrenciesFromUrl();
    },
    methods: {
        ...mapActions('transactions', [
            'fetchTransactions',
        ]),
        setCurrenciesFromUrl() {
            const currenciesFromUrl = this.$route.query.currencyId;

            if (!currenciesFromUrl) {
                return false;
            }

            this.selectedCurrencies = currenciesFromUrl.split(',');
        },
        changeSelect(values) {
            const {
                $route,
                $router,
                fetchTransactions,
            } = this;
            const val = values ? [values] : [];
            fetchTransactions({
                route: $route,
                router: $router,
                currencyId: val,
                excludeParams: ['page'],
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.transactions-sort-by-currency-from {
  &__title {
    margin-bottom: 8px;
    font-size: var(--font-size-text-main);
    font-family: var(--font-family);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__select ::v-deep .at-select {
    background: var(--secondary-color);
    box-sizing: border-box;
  }

  ::v-deep .at-select__arrow {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  ::v-deep .at-select__clear {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  &__select::v-deep .at-select__selection {
    border-radius: 12px;
    min-height: 46px;
    display: flex;
    padding-left: 30px;
    align-items: center;
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    flex-wrap: wrap;
    border: 1px solid var(--border-color);
    background: var(--secondary-color);
    color: var(--primary-font-color);
  }

  &::v-deep .at-select__dropdown {
    border: 1px solid var(--border-color);
    background: var(--bg-select);
  }

  ::v-deep .at-select__option--selected {
    background: var(--hover-color-select);
  }

  ::v-deep .at-select__dropdown .at-select__option {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);

    &:hover {
      background: var(--hover-color-select);
    }
  }

  ::v-deep .at-select__placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }

  ::v-deep .at-select__selection {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

}

.currencyImg {
  margin-right: 15px;
  width: 30px;
  height: 30px;
}
::v-deep .at-select__dropdown .at-select__option {
  font-family: var(--font-family);
  font-size: var(--font-size-text-main);
  line-height: var(--line-height);
  color: var(--primary-font-color);

  padding: 10px;
  display: flex;
  align-items: center;

  &:hover {
    background: var(--hover-color-select);
  }
}
</style>
