<template>
<div>
<p class="label">{{ label }}</p>
<div
        class="advanced_balances__network-currency-list-item"
        :class="{ active }"
        :style="{ height }"
>
    <div class="left-block">
        <icon
                class="icon"
                :currency="currencyName"
                :network="networkAlias"
        />
        <div class="currency-and-network-info-block">
            <div class="currency-alias">
                {{ title.title }}
                <span class="network-alias"> {{ title.subtitle }}</span>
            </div>
        </div>
    </div>
    <div class="right-block" >
        {{ amount | separatePeriods }}
    </div>
</div>

</div>
</template>

<script>
    import {getNetworkShortNameByNetworkName} from "@/public/filters";

    export default {
        name: "SelectedAddressOrphanWithdrawal",
        components: {
            Icon: () => import(/* webpackChunkName: "/public/components/elements/advance-balances/NetworkAndCurrenciesSelect/CurrencyNetworkIcon" */ '/public/components/elements/advance-balances/NetworkAndCurrenciesSelect/CurrencyNetworkIcon'),
        },
        props: {
            active: {
                type: Boolean,
                default: false
            },
            amount: {
                type: [Number, String],
                default: 0
            },
            alias: {
                type: String,
                default: ''
            },
            label: {
                name: String,
                default: ''
            },
            currency: {
                type: [String, Object],
                default: ''
            },
            networks: {
                type: [Object, Array],
                default: () => ({})
            },
            height: {
                type: String,
                default: '48px'
            }
        },
        computed: {
            title ({ label, alias, currency, networks: { name } }) {
                return {
                    title: `${currency.currency}`,
                    subtitle: `${ getNetworkShortNameByNetworkName(name) }`
                }
            },
            currencyName({ currency }) {
                return currency.currency;
            },
            networkAlias({ networks: { name }}) {
                return name.toUpperCase();
            },
        },
    }
</script>

<style lang="scss" scoped>
.label {
    font-size: $h6;
    color: var(--new-front-input-label-font-color);
    text-align: left;
    margin-bottom: $p5;
}
.advanced_balances {
  &__network-currency-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
      margin-bottom: $p20;
      border: 1px solid var(--new-front-input-border);
      border-radius: 10px;
      padding: 0 20px;

    //background-color: var(--dashboard-secondary-bg-color);

    &.active {
      background-color: var(--list-item-active);
    }

    //border: 1px solid var(--border-color);
    //border-radius: 10px;
    //padding: 16px 10px;

    color: var(--primary-font-color);
    //cursor: pointer;

    .left-block {
      display: flex;
      align-items: center;

      .currency-and-network-info-block {
        display: flex;
        align-items: center;

        margin-left: 10px;

        .currency-alias {
          font-size: $h4;
          font-weight: 500;
            color: var(--new-front-primary-font-color);
        }
        .network-alias {
          color: var(--secondary-font-color);
          font-size: $h4;
          margin-left: 6px;
        }
      }
    }
    .right-block {
      display: flex;
      align-items: center;
      justify-content: end;
        font-size: $h4;
        color: var(--new-front-primary-font-color);
      .check-block {
        display: flex;
        align-items: center;
        justify-content: center;

        min-width: 20px;
        padding-left: 10px;
      }
    }

    @include below_phone {
      padding: 16px 12px;

      .left-block {
        .currency-and-network-info-block {
          .currency-alias {
            font-size: $h6;
          }
          .network-alias {
            color: var(--secondary-font-color);
            font-size: $h6;
          }
        }
      }
    }
  }
}
</style>
