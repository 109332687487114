<template>
  <div class="transactions-sort-by-network">
    <p class="transactions-sort-by-network__title">
      {{ $t('transactions.network') }}
    </p>
    <AtSelect
        class="transactions-sort-by-network__select"
        :placeholder="select_network"
        clearable
        :value="selectedNetworks[0]"
        @on-change="selectNetworks"
    >
      <AtOption
          v-for="(network, key) in networks"
          :key="key"
          :value="network.alias"
      />
    </AtSelect>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapState} from 'vuex';

import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';

export default {
    name: 'SortByNetwork',
    components: {
        AtSelect,
        AtOption,
    },
    data() {
        return {
            selectedNetworks: [],
        };
    },
    computed: {
        ...mapState('app', {
            networks: state => state.networks,
            currencies: state => state.currencies,
        }),
        ...mapState('historyAddresses', ['type']),
        ...mapGetters('app', [
            'hasNetworks',
        ]),
        select_network() {
            return this.$t('transactions.select_network');
        },
    },
    beforeMount() {
        this.setNetworksFromUrl();
    },
    methods: {
        ...mapActions('historyAddresses', [
            'addressTransactions',
        ]),
        setNetworksFromUrl() {
            const networksFromUrl = this.$route.query.networks;

            if (!networksFromUrl) {
                return false;
            }

            return this.selectedNetworks = networksFromUrl.split(',');
        },
        selectNetworks(values) {
            const currenciesCurrentNetwork = Array.from(new Set(this.currencies.filter((item) => {
                let status = null;
                item.networks.forEach(network => {
                    if (network.alias === values) {
                        status = true;
                    }
                });
                return status;
            })));

            this.$store.commit('historyAddresses/currentNetwork', currenciesCurrentNetwork);

            const {
                $route,
                $router,
                addressTransactions,
            } = this;
            const val = values ? [values] : [];
            addressTransactions({
                route: $route,
                router: $router,
                networks: val,
                excludeParams: ['page'],
                type: this.type,
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.transactions-sort-by-network {
  &__title {
    margin-bottom: 8px;
    font-size: var(--font-size-text-main);
    font-family: var(--font-family);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__select ::v-deep .at-select {
    background: var(--secondary-color);
    box-sizing: border-box;
  }

  ::v-deep .at-select__arrow {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  ::v-deep .at-select__clear {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  ::v-deep .at-select__arrow {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  ::v-deep .at-select__clear {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  &__select::v-deep .at-select__selection {
    border-radius: 12px;
    min-height: 46px;
    display: flex;
    padding-left: 20px;
    align-items: center;
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    flex-wrap: wrap;
    border: 1px solid var(--border-color);
    background: var(--secondary-color);
    color: var(--primary-font-color);
  }

  &::v-deep .at-select__dropdown {
    border: 1px solid var(--border-color);
    background: var(--bg-select);
  }

  ::v-deep .at-select__option--selected {
    background: var(--hover-color-select);
  }

  ::v-deep .at-select__dropdown .at-select__option {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);

    &:hover {
      background: var(--hover-color-select);
    }
  }

  ::v-deep .at-select__placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }

  ::v-deep .at-select__selection {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

}

</style>