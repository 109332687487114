import getWebhooksQuery from "/public/graphQl/queries/getWebhooks";

export const getWebhooks = async ({dispatch, commit, rootGetters}, variables) => {
    let webhookData;

    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const params = {
        organizationId,
        ...variables,
    }

    try {
        commit('app/toggleLoading', null, {root: true});
        webhookData = await dispatch('app/request', {
            type: 'query',
            gql: getWebhooksQuery,
            variables: params
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (webhookData.message) {
        throw webhookData.message;
    }

    if (webhookData.errors) {
        throw webhookData.errors;
    }

    const {
        data: {getWebhooks},
    } = webhookData;
    commit('setWebhookList', getWebhooks.webhooks)
    commit('setWebhooksTotal', getWebhooks.total)
    return getWebhooks;
};
