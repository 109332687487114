import getOrdersQuery from "/public/graphQl/queries/getOrders";
import {getRouteQuery} from "/public/utils/getRouteQuery";
import {getCurrenciesIdsByNames} from "/public/utils/getCurrenciesIdsByNames";
import {getFormatForUrlByArray} from "/public/utils/getFormatForUrlByArray";
import {getNetworksIdsByNames} from "/public/utils/getNetworksIdsByNames";

export const fetchOrders = async ({state, rootState, rootGetters, commit, dispatch}, payload) => {
    const {
        route,
        router,
        showUrlFilters = false,
        excludeParams = [],
        page,
        sortDate,
        currencies,
        date,
        networks,
        status,
        id
    } = payload;
    const {ordersCountOnPage} = state;

    const currentPage = page
    const currentSortDate = sortDate
    const currentStatus = status
    const currentDate = date
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const variables = {
        organizationId,
        limit: ordersCountOnPage,
        offset: currentPage,
        sortDate: currentSortDate,
        status: currentStatus,
        currencies,
        networks,
        id,
        date: currentDate,
    };
    let ordersData;

    try {
        commit('app/toggleLoading', null, {root: true});
        ordersData = await dispatch('app/request', {
            type: 'query',
            gql: getOrdersQuery,
            variables,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (ordersData.message) {
        throw ordersData.message;
    }

    if (ordersData.errors) {
        throw ordersData.errors;
    }

    const {
        data: {
            getOrders: {
                orders,
                totalCount,
            },
        },
    } = ordersData;

    commit('orders', orders);
    commit('totalOrdersCount', totalCount);

    return true;
};
