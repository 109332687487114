<template>
  <div class="dashboard-deposit-chart">
    <div class="dashboard-deposit-chart__text-container">
      <div class="dashboard-deposit-chart__text-wrapper">

        <h2 class="dashboard-deposit-chart__label">
          {{ $t('dashboard.orders') }}
        </h2>
        <slot/>


      </div>
    </div>
    <div class="dashboard-deposit-chart__container" ref="chartContainer">
      <canvas ref="deposit-chart"></canvas>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import {Chart, registerables} from 'chart.js';

Chart.register(...registerables);
import Moment from "moment";
import 'moment/locale/en-au';
import 'moment/locale/ru';
import _ from 'lodash';

export default {
    name: 'DashboardDepositChart',
    components: {
        Chart,
    },
    data: function () {
        return {
            chart: Chart,
            width: 0,
            padding: 35,
        };
    },
    computed: {
        ...mapState('dashboard', [
            'dates',
            'payments',
        ]),
        ...mapState('user', [
            'user',
        ]),
        sortCreatedByDate() {
            let createdDates = this.payments.created ?? [];
            return _.sortBy(createdDates, 'hour');
        },
        sortProcessedByDate() {
            let createdDates = this.payments.processed ?? [];
            return _.sortBy(createdDates, 'hour');
        },
        getLabels() {
            const createdDates = this.sortCreatedByDate.map((i) => {
                const date = new Date(i.hour);
                return date.toISOString();
            });
            return createdDates;
        },
        getCreatedValues() {
            const createdDates = this.sortCreatedByDate.map((i) => {
                return +i.count;
            });
            return createdDates;
        },
        getMaxCreatedValues() {
            return (_.max(this.getCreatedValues) + 1);
        },
        getProcessedValues() {
            const createdDates = this.sortProcessedByDate.map((i) => {
                return +i.count;
            });
            return createdDates;
        },
        getMaxProcessedValues() {
            return (_.max(this.getProcessedValues) + 1);
        },
        getMaxValueForYAxis() {
            if (this.getMaxCreatedValues && this.getMaxCreatedValues) {
                return Math.max(this.getMaxCreatedValues, this.getMaxProcessedValues);
            }
            return 0;
        },
    },
    watch: {
        "$store.state.user.user.lang"() {
            this.renderChart();
        },
        payments() {
            this.renderChart();
        },
    },

    mounted() {
        window.addEventListener('resize', this.onResize);
        const ctx = this.$refs["deposit-chart"];
        const plugin = {
            id: 'legendBackground',
            beforeDraw: (chart) => {
                const {
                    legend,
                } = chart;
                legend.legendItems = legend.legendItems.map((i) => {
                    i.fillStyle = i.strokeStyle;
                    i.borderRadius = 4;
                    return i;
                });

            },
        };
        const cfg = {
            maintainAspectRatio: false,
            responsive: true,
            onResize(chart, size) {
                if (size.width < 350) {
                    chart.config.options.plugins.legend.labels.boxHeight = 14;
                    chart.config.options.plugins.legend.labels.boxWidth = 14;
                    chart.config.options.plugins.legend.labels.padding = 10;
                } else {
                    chart.config.options.plugins.legend.labels.boxHeight = 24;
                    chart.config.options.plugins.legend.labels.boxWidth = 24;
                    chart.config.options.plugins.legend.labels.padding = 24;
                }
            },
            interaction: {
                intersect: false,
                mode: 'index',
            },
            layout: {
                padding: {
                    left: 35,
                    right: 35,
                    bottom: 35,
                },
            },
            elements: {
                point: {
                    hitRadius: 8,
                },
            },
            scales: {
                yAxis: {
                    type: 'linear',
                    padding: 5,
                    grid: {
                        drawOnChartArea: false,
                    },
                    min: 0,
                    ticks: {
                        stepSize: 2,
                        color: '#8F949A',
                        font: {
                            family: 'Gilroy',
                            size: 10,

                        },
                    },
                },

                xAxis: {
                    grid: {
                        drawOnChartArea: false,
                    },
                    type: 'category',
                    ticks: {
                        autoSkip: true,
                        autoSkipPadding: 0,
                        labelOffset: 0,
                        includeBounds: true,
                        callback: (val, index) => {
                            const a = (index % 2 === 0) ? Moment(this.getLabels[index]).format('HH') : '';
                            return a;
                        },

                        stepSize: 2,
                        color: '#8F949A',
                        font: {
                            family: 'Gilroy',
                            size: 12,

                        },
                    },
                },

            },
            plugins: {

                tooltip: {
                    boxPadding: 5,
                    callbacks: {
                        title: (context) => {
                            return Moment(context[0].label).format('HH:mm:ss');
                        },
                        labelColor: (context) => {

                            return {
                                borderColor: context.element.options.borderColor,
                                backgroundColor: context.element.options.borderColor,
                                borderWidth: 3,

                                borderRadius: 1,
                            };
                        },
                    },
                },
                legend: {

                    position: 'bottom',
                    align: 'start',

                    labels: {
                        color: '#8F949A',
                        padding: 24,
                        boxWidth: 24,
                        boxHeight: 24,

                    },
                },

            },
        };
        this.chart = new Chart(ctx, {type: 'line', data: {datasets: []}, options: cfg, plugins: [plugin]});
        this.renderChart();
    },
    methods: {
        onResize() {
            this.width = window.innerWidth;
        },
        renderChart() {
            const ctx = this.$refs["deposit-chart"];
            let context = ctx.getContext('2d');

            let gradient = context.createLinearGradient(0, 0, 0, 195);
            let gradient2 = context.createLinearGradient(0, 0, 0, 195);
            gradient2.addColorStop(0.01, 'rgba(189, 0, 255, 0.2)');
            gradient2.addColorStop(0.99, 'rgba(189, 0, 255, 0)');

            gradient.addColorStop(0.0013, 'rgba(0, 89, 247, 0.2)');
            gradient.addColorStop(0.9969, 'rgba(0, 89, 247, 0)');
            this.chart.data.labels = this.getLabels;
            this.chart.config.options.scales.yAxis.max = this.getMaxValueForYAxis;
            this.chart.data.datasets = [{
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                label: this.$t('dashboard.created'),
                data: this.getCreatedValues,
                tension: 0.4,
                backgroundColor: gradient,
                pointRadius: 0,
                borderWidth: 2,
                fill: true,
                type: 'line',
                borderColor: '#8B81A0',
            }, {
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                pointRadius: 0,
                label: this.$t('dashboard.completed'),
                data: this.getProcessedValues,
                tension: 0.4,
                backgroundColor: gradient2,
                borderWidth: 2,
                fill: true,
                borderColor: '#6750A4',

            }];
            this.chart.update();
        },
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
};

</script>

<style lang="scss" scoped>

.dashboard-deposit-chart {
  width: 100%;
  position: relative;
  border-radius: $p30;
  background: var(--new-front-main-bg);
  font-family: $Gilroy;
  max-width: 580px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--new-front-input-border);

  &__text-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 35px;
    padding-right: 35px;
    min-height: 107px;
  }

  &__text-wrapper {
    display: flex;
    justify-content: space-between;
    min-height: 62px;
    width: 100%;
  }

  &__container {
    width: 100%;
    height: 300px;
    position: relative;
  }

  &__label {
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size-h2);
    line-height: var(--line-height);

    color: var(--new-front-primary-font-color);
  }
}

@media screen and (max-width: 1560px) {

  .dashboard-deposit-chart {
    max-width: 100%;
    padding: 0;
    margin-bottom: 20px;

    &__container {
      width: 99%;
    }

    &__text-container {
      padding: 35px;
    }
  }
}


</style>
