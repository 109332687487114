<template>
  <div class="balance table-row row">
    <div class="balance__amount col">
      {{ type }}
    </div>
    <div class="balance__currency col">
      {{ amount }}
    </div>
    <div class="balance__amount col">
      {{ balance }}
    </div>
    <div class="balance__amount col">
      {{ date }}
    </div>
    <div class="col">
      {{ orderId }}
    </div>
  </div>
</template>

<script>

import {mapActions} from 'vuex';

export default {
    name: 'AccountData',
    components: {

    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        balance () {
            return this.data.balance;
        },
        orderId () {
            return this.data.orderId;
        },
        type () {
            let names = { 
                DEPOSIT: "Пополнение",
                WITHDRAWAL: "Вывод",
                WITHDRAWAL_INIT: "Комиссия вывода",
                TRANSACTION_INIT: "Создание ордера",
                TRANSACTION_PROCESSED: "Депозит по ордеру",
                SYSTEM_INCR: "Пополнение",
                SYSTEM_DECR: "Списание",
            };
            return names[this.data.type];
        },
        date () {
            return this.data.date.replace(/(.*)[A-Z](.*)\..*/, "$1 $2");
        },
        amount () {
            return this.data.amount;
        },
    },
    methods: {

    },
};

</script>

<style lang="scss" scoped>

.balance {

  &__currency {
    display: flex;
    align-items: center;
  }

  &__currency-img {
    margin-right: 12px;
    width: 24px;
    height: 24px;
  }

  &__amount {
    display: flex;
    align-items: center;
  }

  &__currency-name {
    margin-left: 6px;
    color: #778899;
  }
}

</style>
