import CreateUsersAddress from "../../../../graphQl/mutations/personalAddressCreateUsersAddress";

export const createUsersAddress = async ({ dispatch, commit, rootGetters }, payload) => {
  let resp;

  payload.organizationId = rootGetters['app/getSelectedOrganization'].id;

  let variables = {
    organizationId: payload.organizationId,
    userId: payload.userId,
    currencyId: payload.currencyId,
    networkId: payload.networkId
  }

  try {
    resp = await dispatch('app/request', {
      type: 'mutation',
      gql: CreateUsersAddress,
      variables: variables,
    }, {root: true});
  } catch (error) {
    console.error('Has error when add address ---> ', error)
    return error;
  }

  const { data } = resp || {}

  const { createPersonalAddress } = data || {}

  return createPersonalAddress || { result: null, code: 404 }
};