<template>
  <div :class="['hour', size === 's' ? 'hour_s' : '']">
    <p :class="['hour__text', size === 's' ? 'hour__text_s' : '' ]">24h</p>
  </div>
</template>

<script>
export default {
    name: "hour-label",
    props: {
        size: {
            type: String,
            default: 'm',
        },
    },
};
</script>

<style lang="scss" scoped>
.hour {
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--new-front-adv-bg);
  border: 1px var(--new-front-adv-border-color) solid;
  border-radius: 8px;
  min-height: 34px;
  width: 60px;

  &_s {
    width: 27px;
    min-height: 18px;
    border-radius: 4px;
  }

  &__text {
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--new-front-adv-font-color);

    &_s {
      font-size: var(--font-size-text-caption);
    }
  }
}
</style>
