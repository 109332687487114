<template>
<div class="burger" @click="toggleNav">
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="3" width="18" height="3" rx="1" fill="var(--new-front-adv-font-color)"/>
    <rect x="3" y="10" width="18" height="3" rx="1" fill="var(--new-front-adv-font-color)"/>
    <rect x="3" y="17" width="18" height="3" rx="1" fill="var(--new-front-adv-font-color)"/>
  </svg>
</div>
</template>

<script>
  import {mapMutations} from "vuex";

  export default {
    name: "HeaderBurger",
    methods: {
      ...mapMutations('app', ['toggleNavState']),
      toggleNav () {
        this.toggleNavState()
      }
    }
  }
</script>

<style lang="scss" scoped>
.burger {
  background: var(--new-front-adv-bg);
  border: 1px var(--new-front-adv-border-color) solid;
  border-radius: 10px;
  height: 48px;
  width: 48px;
  min-width: 48px;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: $p5;
  transition: background .2s ease;
  &:hover {
    background: var(--new-front-adv-hover-bg);
  }
}
@media screen and (max-width: 1180px) {
  .burger {
    display: flex;
  }
}
</style>
