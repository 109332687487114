import changePreCheckoutInfo from "../../../../graphQl/mutations/changePreCheckoutInfo";
import uploadPreCheckoutLink from "../../../../graphQl/mutations/uploadPreCheckoutLink";
import axios from "axios";

export const changePreCheckoutInfoQuery = async ({dispatch, commit, rootGetters}, {
    pageId,
    title,
    description,
    avatarFile,
    theme,
}) => {

    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    let avatarLinkData = {
        data: {
            uploadPreCheckoutLink: {
                code: null,
                result: null,
            },
        },
    };
    if (avatarFile) {
        try {
            avatarLinkData = await dispatch('app/request', {
                type: 'mutation',
                gql: uploadPreCheckoutLink,
                variables: {
                    organizationId,
                    pageId,
                },
            }, {root: true});


        } catch (error) {
            return error;
        }

        const {
            data: {
                uploadPreCheckoutLink: {
                    code,
                    result,
                },
            },
        } = avatarLinkData;
        let uploadedAvatar;
        const fileType = avatarFile.get('file').type;

        try {
            uploadedAvatar = axios.put(result, avatarFile, {
                headers: {
                    'Content-Type': fileType,
                },
            });
        } catch (e) {
            console.log(e.response);
        }
    }
    const {
        data: {
            uploadPreCheckoutLink: {
                code,
                result,
            },
        },
    } = avatarLinkData;
    let changeLangData;
    commit('app/toggleLoading', null, {root: true});
    try {
        changeLangData = await dispatch('app/request', {
            type: 'mutation',
            gql: changePreCheckoutInfo,
            variables: {
                organizationId,
                pageId,
                title,
                description,
                theme,
                avatarLink: result ?? null,
            },
        }, {root: true});
    } catch (error) {
        commit('app/toggleLoading', null, {root: true});
        return error;

    }

    if (changeLangData.message) {
        throw changeLangData.message;
    }

    if (changeLangData.errors) {
        throw changeLangData.errors;
    }
    commit('app/toggleLoading', null, {root: true});
    const {
        data: {
            changePreCheckoutInfo: changePreCheckoutInfoResponse,
        },
    } = changeLangData;
    return changePreCheckoutInfoResponse;

};
