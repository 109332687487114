<template>
    <StoreModal
            class="update-user-modal"
            :value="isOpen"
            name="UpdateUserModal"
            @closeModal="handleCancelCreate"
    >
        <template #header>
            <div class="update-user-modal__header">
                <div class="title">
                    {{ $t('organization.modal_delete_info') }}
                </div>
                <!--            <div v-if="notCreated" class="subtitle">-->
                <!--                {{ subtitle }}-->
                <!--            </div>-->
            </div>
        </template>
        <template #body>
            <div class="modal-wrapper">
                <div class="update-user-modal__input-wrapper">
<!--                    <span class="update-user-modal__input-title">-->
<!--                     {{ $t('organization.modal_email') }}-->
<!--                    </span>-->
<!--                    <span class="update-user-modal__user-email" v-if="modalPayload && isOpen">{{ modalPayload.user.email }}</span>-->
                    <base-input v-if="modalPayload && isOpen" :value=" modalPayload.user.email" :label="$t('organization.modal_email')" class="update-user-modal__input" :disabled="true" />
                </div>
                <div class="update-user-modal__input-wrapper">
                    <base-input
                            class="update-user-modal__input"
                            size="small"
                            :label="$t('organization.modal_name')"
                            :placeholder="$t('organization.organization_enter_name')"
                            v-model="userName"
                    />
                </div>
                <div class="update-user-modal__input-wrapper">
<!--                    <span class="update-user-modal__input-title">-->
<!--                        {{  }}-->
<!--                    </span>-->
                    <organisation-role-select
                       v-model="roleId"
                       :placeholder="$t('organization.modal_take_role')"
                       :label="$t('organization.organization_role')"
                       :list="listRoles"
                       uniq-key="id"
                       clearable
                       @input="handleSelectRole"
                    />
                </div>
            </div>
        </template>

        <template #footer>
            <template>
                <base-button
                        type="primary"
                        @click="handleInviteRequest"
                        :label="$t('organization.organization_btn_save')"
                />
            </template>
        </template>

    </StoreModal>
</template>

<script>

import {mapGetters, mapActions, mapState} from 'vuex';

import AtModal from 'at-ui/src/components/modal';
import LoadingSpinner from "/public/components/elements/LoadingSpinner";
import AtButton from 'at-ui/src/components/button';
import BaseModal from "../BaseModal";
import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
import BaseInput from "@/public/components/common/uiKit/input/base/index.vue";
import OrganisationRoleSelect from "@/public/components/elements/organizations/Select/organisationRoleSelect.vue";

export default {
    name: 'UpdateUserModal',
    components: {
        OrganisationRoleSelect,
        BaseInput,
        BaseButton,
        BaseModal,
        AtModal,
        LoadingSpinner,
        AtButton,
        StoreModal: () => import(/* webpackChunkName: "/public/components/modals/StoreModal" */ '/public/components/modals/StoreModal'),
        BaseSelect: () => import(/* webpackChunkName: "/public/components/elements/advance-balances/bh-select" */ '/public/components/elements/advance-balances/bh-select'),
    },
    data() {
        return {
            isLoading: false,
            isSuccess: false,
            userName: '',
            email: '',
            roleId: { id: ''},
        };
    },

    watch: {
        modalPayload() {
            if (this.modalPayload && this.isOpen) {
                this.userName = this.modalPayload.user.name;
                this.roleId = this.modalPayload.user.role;
            }
        },
    },

    computed: {
        ...mapGetters('app', [
            'isOpenModal',
        ]),

        ...mapState('app', {
            modalPayload: state => state.modal.payload,
        }),

        ...mapState('organizations', [
            'listRoles',
            'inviteUrl',
        ]),

        isOpen() {
            const {
                $options: {
                    name,
                },
                isOpenModal,
            } = this;

            return isOpenModal(name);
        },
    },

    methods: {
        ...mapActions('app', [
            'closeModal',
        ]),

        ...mapActions('organizations', [
            'updateOrganizationUser',
            'getOrganizationUsers',
            'getOrganization'
        ]),

        clearAllData() {
            this.isLoading = false;
            this.isSuccess = false;
            this.userName = '';
            this.roleId = { id: ''};
        },

        handleInviteRequest() {
            this.updateOrganizationUser({
                name: this.userName,
                userId: this.modalPayload.user.id,
                roleId: this.roleId.id,
            }).then(() => {
                this.closeModal();
                this.clearAllData();
                this.getOrganizationUsers();
              this.getOrganization()
            });
        },

        handleSelectRole(value) {
            this.roleId = value;
        },

        handleCancelCreate() {
            const {
                closeModal,
                clearAllData,
            } = this;

            closeModal();
            this.$store.commit('organizations/setSelectedRole', null);
            this.$store.commit('organizations/setDetailRoleId', null);

            setTimeout(() => {
                clearAllData();
            }, 500);
        },
    },
};

</script>

<style lang="scss" scoped>
.option {
  color: var(--new-front-primary-font-color);
}
.modal-wrapper {
    padding: 0 30px;
}
.update-user-modal {
    font-family: $Gilroy;
    &::v-deep {
      .list {
        max-height: 290px !important;
      }
    }
    &__header {
        text-align: center;
        .title {
            font-size: $h1;
            font-weight: 500;
            color: var(--new-front-primary-font-color);
            @media (max-width: 450px) {
                font-size: $h2;
            }
        }
    }
  ::v-deep .at-select {
    background: var(--main-bg);
    box-sizing: border-box;
  }

  ::v-deep .at-select__dropdown {
    background: var(--main-bg);
    border: 1px solid var(--border-color);
    z-index: 1;
  }

  ::v-deep .at-select__arrow {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  ::v-deep .at-select__clear {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  ::v-deep .at-select__selection {
    border: 1px solid var(--border-color);
    border-radius: 12px;
    min-height: 46px;
    display: flex;
    padding-left: 30px;
    align-items: center;
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    flex-wrap: wrap;
    background: var(--secondary-head-color);
  }

  ::v-deep .at-select__dropdown .at-select__option {
    font-family: var(--font-family);
    font-size: 20px;
    line-height: var(--line-height);
    color: var(--primary-font-color);
    padding: 25px 30px;

    &:hover {
      background-color: var(--bg-modal-color);
    }
  }

  ::v-deep .at-select__placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }

  ::v-deep .at-select__option--selected {
    background: var(--bg-modal-color);
  }

  ::v-deep .at-select__selection {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    padding-left: 20px;
  }

  ::v-deep .at-btn {
    min-width: 100%;
    background: #0059F7;
    border-radius: 8px;
    color: #FFFFFF;
    transition: all 0.3s ease-in;
    border: none;

    &:hover {
      background: var(--blue-400);
    }
  }

  ::v-deep .at-btn__text {
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    font-family: var(--font-family);
  }

  &__full-width-btn {
    width: 100%;
  }

  &__message {
    color: var(--primary-font-color);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;

    span {
      color: #0059F7;
    }
  }

  &__alias {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: var(--primary-font-color);
    margin-top: 25px;
  }

  &__input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__input-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--primary-font-color);
    margin-bottom: 10px;
  }

  &__input {
    &::v-deep .at-input__original {
      background: var(--secondary-color);
      border: 1px solid var(--border-color);
      box-sizing: border-box;
      border-radius: 12px;
      min-height: 47px;
      color: var(--primary-font-color);
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 148.8%;
      padding-left: 30px;

      &::placeholder {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 148.8%;
        color: #8F949A;
      }
    }
  }

  &__user-email {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--primary-font-color);
  }
}

</style>
