<script>
  import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
  import {mapActions, mapGetters, mapState} from "vuex";
  import BaseCheckbox from "@/public/components/common/uiKit/Checkbox/index.vue";

  export default {
    name: "DashboardZeroStateAml",
    components: {BaseCheckbox, BaseButton},
    data () {
      return {
        aml: true
      }
    },
    computed: {
      ...mapState('organizations', ['organizationKycStatus']),
      ...mapGetters('app', ['isOrganizationOwner']),
    },
    methods: {
      ...mapActions('app', [
        'openModal',
        'closeModal',
      ]),
      enablePayments() {
        if (!this.organizationKycStatus && this.isOrganizationOwner) {
          this.closeModal();
          this.openModal({
            name: 'KYCModal',
          });
          return
        }
        this.$emit('enableCollecting', { aml: this.aml })
      }
    }
  }
</script>

<template>
<div class="aml">
  <div>
    <div class="aml__top">
      <div class="title">{{ $t('payments.zero-state.main-addresses') }}</div>
      <div class="desc">{{ $t('payments.zero-state.enable-access') }}</div>
    </div>
    <div class="aml__check">
      <base-checkbox
          v-model="aml"
          label=""
      />
      <div class="subtitle">{{ $t('payments.zero-state.enable-aml') }}</div>
    </div>
    <div class="desc">{{ $t('payments.zero-state.convenient') }}</div>
  </div>
  <base-button class="aml__btn" @click="enablePayments" :label="$t('payments.zero-state.enable')" type="primary" />
</div>
</template>

<style scoped lang="scss">
.aml {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: $Gilroy;
  padding: 40px 30px 30px 30px;
  border-radius: 30px;
  background: var(--bg-background-secondary, #FFF);
  &__top {
    padding-bottom: 15px;
    border-bottom: 1px solid var(--border-border-input-primary-default);
    margin-bottom: 15px;
  }
  &__check {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 10px;
  }
  .title {
    color: var(--text-text-primary);
    font-size: 32px;
    font-weight: 500;
    line-height: 100%;
    margin-bottom: 10px;
  }
  .desc {
    color: var(--text-color-text-secondary);
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
  }
  .subtitle {
    color: var(--text-text-primary);
    font-size: 22px;
    font-weight: 500;
    line-height: 100%;
  }
}
@media screen and (max-width: 1600px) {
  .aml__btn {
    margin-top: 20px;
  }
}
@media screen and (max-width: 900px) {
  .aml__btn {
    margin-top: 20px;
  }
  .title {
    font-size: 20px;
  }
  .desc {
    font-size: 13px;
  }
  .subtitle {
    font-size: 20px;
  }
}
</style>
