<template>
    <div class="dashboard-transaction" v-if="hasWithdrawal">
        <div class="dashboard-transaction__title">
            {{ title }}
        </div>
        <div class="requests-list-header list-header row">
            <div class="list-header__item col col-5">
                id
            </div>
            <div class="list-header__item col">
                {{ $t('withdrawal.currency') }}
            </div>
            <div class="list-header__item col">
                {{ $t('withdrawal.network') }}
            </div>
            <div class="list-header__item col">
                {{ $t('withdrawal.amount') }}
            </div>
            <div class="list-header__item col col-3">
                {{ $t('withdrawal.date') }}
            </div>
            <div class="col col-1"></div>
        </div>
        <dashboard-withdrawal-row
            v-for="(data, key) in withdrawal"
            :key="key"
            :data="data"
        />
    </div>
</template>

<script>

import {mapState} from "vuex";
import DashboardWithdrawalRow from "./DashboardWithdrawalRow";

export default {
    name: 'DashboardWithdrawal',
    components: {
        DashboardWithdrawalRow,
    },
    props: {
        title: {
            type: String,
            default: 'Выполненные заявки на вывод',
        },
    },
    computed: {
        ...mapState('dashboard', [
            'withdrawal',
        ]),
        hasWithdrawal () {
            return !!this.withdrawal.length;
        },
    },
    methods: {
    },
};

</script>

<style lang="scss" scoped>
.dashboard-transaction {
    border: 1px solid $gray2;
    box-shadow: 2px 2px 4px 0 $gray2 ;
    border-radius: 1em;
    padding: 1em;
    margin-top: 1em;
    margin-bottom: 1em;

    &__title {
        @include h3;
        margin-bottom: 1em;
    }
}


</style>
