<template>
<bh-select-new
    v-model="innerModel"
    :placeholder="$t('api_keys.choose-api-keys')"
    class="grouping-select item"
    :list="list"
    :label="''"
    :options-title="$t('api_keys.list-of-available')"
    uniq-key="id"
    clearable
    gap
>
  <template #search>
    <base-button @click="$router.push({ name: 'apiKeys' })" class="createBtn" >
      <template #label>
        <span class="button-label">{{ $t('widgets.createApiKey') }}</span>
      </template>
    </base-button>
  </template>
  <template #info="item">
    <div class="api-key-item">
      <div class="alias">
        {{ item.alias }}
      </div>
      <div class="key">
        {{ item.public | preparedString}}
      </div>
    </div>
  </template>
  <template
      #selected="item"
  >
    <div class="api-key-item">
      <div class="key">
        {{ item.public | preparedString}}
      </div>
    </div>
  </template>
  <template #options-list-empty>
    <base-button class="createBtn" >
      <template #label>
        <span class="button-label">{{ $t('widgets.createApiKey') }}</span>
      </template>
    </base-button>
  </template>
</bh-select-new>
</template>

<script>
  import BhSelectNew from '/public/components/elements/advance-balances/selects/Base'
  import {mapState} from "vuex";
  import NetworkAndCurrencyItem from "@/public/components/elements/orders/Select/Item.vue";
  import CurrencyImg from "@/public/components/elements/CurrencyImg.vue";
  import CurrencyNetworkIcon from "@/public/components/elements/business-walet/Select/CurrencyNetworkIcon.vue";
  import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";

  export default {
    name: "WidgetsApiKeysSelect",
    components: {
      BaseButton,
      CurrencyNetworkIcon,
      CurrencyImg,
      NetworkAndCurrencyItem,
      BhSelectNew,
      Search: () => import(/* webpackChunkName: "public/components/common/uiKit/search" */ '/public/components/common/uiKit/search'),
    },
    props: {
      value:  {
        type: [String, null],
        default: () => ({})
      }
    },
    data () {
      return {
        search: ''
      }
    },
    computed: {
      ...mapState('user', ['apiKeys']),
      innerModel: {
        get({ value, list }) {
          return list.find(item => item.id === value) || null
        },
        set(v) {
          this.$emit('input', v ? v.id : '')
        }
      },
      list ({ currencies }) {
          return this.apiKeys
      },
    },

  }
</script>

<style lang="scss" scoped>
.createBtn {
  margin: 10px 0;
}
.button-label {
  color: var(--text-text-white, #FFF);
  text-align: center;
  font-family: $Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.api-key-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .alias {
    color: var(--text-color-text-secondary, #7E7E7E);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }
  .key {
    color: var(--text-text-primary, #FFF);
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    padding-right: 15px;
  }
}
.selected-quantity {
  color: var(--new-front-primary-font-color);
  padding-left: 5px
}
.search {
  margin: 5px 0 15px 0;
}
.item__title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .network {
    color: var(--text-color-text-secondary);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }
  .currency {
    color: var(--text-text-primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    margin-bottom: 5px;
  }
}
::v-deep {
  .options-list-item {
    font-family: $Gilroy;
    height: 48px;
    padding: 16px 15px !important;
    border-radius: 10px !important;
    border: 1px solid var(--border-border-input-primary-default, #464646);
  }
  .new-order-modal__row {
    width: 100%;
  }
  .placeholder {
    font-weight: 400;
  }
  .label {
    font-size: 13px;
  }
  .currency-network-icon-wrapper .currency-network-icon__currency {
    width: 32px;
    height: 32px;
  }
  @media screen and (max-width: 576px) {
    .placeholder {
      font-size: 13px;
    }
    .label {
      font-size: 10px;
    }
    .currency {
      font-size: 13px;
    }
    .network {
      font-size: 13px;
    }
    .currency-network-icon-wrapper .currency-network-icon__network {
      width: 14px;
      height: 14px;
    }
    .currency-network-icon-wrapper .currency-network-icon__currency {
      width: 18px;
      height: 18px;
    }
  }
}
.grouping-select {
  width: 100%;
}
.currency-network-option-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 500;
  font-style: normal;

  min-height: 32px;
  margin-left: 15px;
  padding-top: 7px;

  .currency-and-network-info {
    display: flex;
    align-items: center;

    color: #8F949A;

    gap: 15px;

    .currency {
      color: var(--new-front-text-primary-bg-secondary);
    }
    .network {
      color: var(--new-front-text-secondary-bg-secondary);
    }
  }
  .amount {
    display: flex;
    align-items: end;
    gap: 8px;

    .amount-label {
      font-weight: 400;
      color: var(--new-front-text-secondary-bg-secondary);
    }
    .amount-value {
      color: var(--new-front-text-primary-bg-secondary);
    }
  }

  @include below_880() {
    .column {
      width: 100%;
      &.amount {
        display: none;
      }
    }
  }

  @include below_phone(){
    .currency-left-block {
      .currency-info {
        width: 100%;
        padding: 0 15px;

        .name {}
      }

      .network-info {
        display: none;
        width: 60%;
      }
    }
    ::v-deep {
      .grouping-select {
        .label {
          font-size: 13px;
        }
      }
      .new-bh-select-wrapper .input-wrapper .input .placeholder {
        font-size: 13px;
      }
    }
  }
}
@include below_phone(){
  ::v-deep {
    .grouping-select {
      .label {
        font-size: 13px;
      }
    }
    .new-bh-select-wrapper .input-wrapper .input .placeholder {
      font-size: 13px;
    }
  }
}
</style>
