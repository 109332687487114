<template>
  <div>
    <div class="preview-transaction">
      <RouterLink
          class="preview-transaction__link"
          :to="{ name: routeName, params: { id } }"
      />
      <div class="preview-transaction__inner-row">
        <p class="preview-transaction__col ">
          {{ createdAt | getFormatDate }}
        </p>

        <p class="preview-transaction__col preview-transaction__col_status"
           :style="`--colorStatus:${mobileStatusTextColor};`">
          {{ $t(status) }}
        </p>
        <!--        <p class="preview-transaction__col"-->
        <!--        >-->
        <!--          - -->
        <!--        </p>-->
        <!--        <p class="preview-transaction__col"-->
        <!--        >-->
        <!--          {{ $t(basis) || '-' }}-->
        <!--        </p>-->
        <!--        <p class="preview-transaction__col"-->
        <!--        >-->
        <!--          {{ $t(source) || '-' }}-->
        <!--        </p>-->
        <!--        <div class="preview-transaction__col preview-transaction__id">-->
        <!--          <CopyString-->
        <!--              v-if="data.addressFrom"-->
        <!--              :string="data.addressFrom"-->
        <!--              :successMessage="$t('copy.addresses_copied')"-->
        <!--              :failMessage="$t('copy.addresses_not_copied')"-->
        <!--              :title="notification"-->
        <!--              canCopy-->
        <!--          />-->
        <!--          <p v-else>{{ '-' }}</p>-->
        <!--        </div>-->
        <!--        <div class="preview-transaction__col preview-transaction__id">-->
        <!--          <CopyString-->
        <!--              v-if="data.addressTo"-->
        <!--              :string="data.addressTo"-->
        <!--              :successMessage="$t('copy.addresses_copied')"-->
        <!--              :failMessage="$t('copy.addresses_not_copied')"-->
        <!--              :title="notification"-->
        <!--              canCopy-->
        <!--          />-->
        <!--          <p v-else>{{ '-' }}</p>-->
        <!--        </div>-->


        <div class="preview-transaction__col preview-transaction__currency ">
          <template v-if="currencyTo">
            <CurrencyImg
                class="preview-transaction__currency-img"
                :currency="currencyTo"
            />
            {{ currencyTo | getCurrencyTicker }}
          </template>
        </div>
        <div class="preview-transaction__col">
          {{ networkId | getNetworkName }}
        </div>
        <div class="preview-transaction__col preview-transaction__id">
          <CopyString
              v-if="data.transactionHash"
              :string="data.transactionHash"
              :successMessage="$t('copy.transaction_hash')"
              :failMessage="$t('copy.transaction_hash_failed')"
              :title="$t('copy.notification')"
              canCopy
          />
          <p v-else>{{ '-' }}</p>
        </div>
        <p class="preview-transaction__col ">
          {{ $t(type) }}
        </p>
        <p class="preview-transaction__col " v-if="data.amount">
          {{ data.amount | prettifyAmount | hideZeros | zeroNumberMask('-') }}
        </p>
        <p class="preview-transaction__col " v-if="credit">
          - {{ credit | prettifyAmount | hideZeros | zeroNumberMask('-') }}
        </p>
        <!--        <div class="preview-transaction__col preview-transaction__currency_write-off ">-->
        <!--          <template v-if="currencyFrom">-->
        <!--            <CurrencyImg-->
        <!--                class="preview-transaction__currency-img"-->
        <!--                :currency="currencyFrom"-->
        <!--            />-->
        <!--            <p class="preview-transaction__col">{{ currencyFrom | getCurrencyTicker }}</p>-->
        <!--          </template>-->
        <!--          <template v-else>-->
        <!--            - -->
        <!--          </template>-->

        <!--        </div>-->


        <!--        <p class="preview-transaction__col ">-->
        <!--          {{ credit | zeroNumberMask('-') }}-->
        <!--        </p>-->


        <!--        <div class="preview-transaction__col preview-transaction__id">-->
        <!--          <CopyString-->
        <!--              v-if="data.addressFrom"-->
        <!--              :string="data.addressFrom"-->
        <!--              :successMessage="id_copied"-->
        <!--              :failMessage="id_not_copied"-->
        <!--              :title="notification"-->
        <!--              canCopy-->
        <!--          />-->
        <!--          <p v-else>{{ '-' }}</p>-->
        <!--        </div>-->
        <!--        <p class="preview-transaction__col ">-->
        <!--          {{ orderCreatedAt | getFormatDate }}-->
        <!--        </p>-->
        <!--        <p class="preview-transaction__col ">-->
        <!--          {{ createdAt | getFormatDate }}-->
        <!--        </p>-->
      </div>
    </div>
    <div class="preview-transaction_mobile">
      <div class="preview-transaction__mobile">
        <AtCollapse accordion :value="id">
          <AtCollapseItem data="">
            <div class="preview-transaction__preview" slot="title">
              <div class=" preview-transaction__currency" :style="`--colorStatus:${mobileStatusTextColor};`">
                <CurrencyImg
                    class="preview-transaction__currency-img"
                    :currency="currencyTo"
                />
                <div class="preview-transaction__currency-container">

                  <p class="preview-transaction__currency-text">
                    {{ $t(type) }}
                  </p>
                  <p class="preview-transaction__currency-status">{{ $t(status) }}</p>
                </div>

              </div>

              <div class="preview-transaction__amount-container">
                <p class="preview-transaction__preview-text " v-if="data.amount">
                  {{ data.amount | prettifyAmount | hideZeros | zeroNumberMask('-') }}
                </p>

                <p class="preview-transaction__preview-text  " v-if="credit">
                  - {{ credit | prettifyAmount | hideZeros | zeroNumberMask('-') }}
                </p>
              </div>
            </div>
            <div class="preview-transaction__detailed-info">
              <div class="preview-transaction__row">
                <p class="preview-transaction__title-text  preview-transaction__title-text_secondary">
                  {{ $t('transactions.date_of_creation') }}</p>
                <p class="preview-transaction__preview-text"> {{ createdAt | getFormatDate }}</p>
              </div>
              <!--              <div class="preview-transaction__row">-->
              <!--                <p class="preview-transaction__title-text preview-transaction__title-text_secondary">-->
              <!--                  {{ $t('transactions.source') }}</p>-->
              <!--                <p class="preview-transaction__preview-text"> {{ $t(source) || '-' }}</p>-->
              <!--              </div>-->

              <!--              <div class="preview-transaction__row">-->
              <!--                <p class="preview-transaction__title-text  preview-transaction__title-text_secondary">-->
              <!--                  {{ $t('transactions.address_from') }}</p>-->
              <!--                <CopyString-->
              <!--                    v-if="data.addressFrom"-->
              <!--                    :string="data.addressFrom"-->
              <!--                    :successMessage="id_copied"-->
              <!--                    :failMessage="id_not_copied"-->
              <!--                    :title="notification"-->
              <!--                    canCopy-->
              <!--                />-->
              <!--                <p class="preview-transaction__preview-text" v-else>-</p>-->
              <!--              </div>-->
              <!--              <div class="preview-transaction__row">-->
              <!--                <p class="preview-transaction__title-text  preview-transaction__title-text_secondary">-->
              <!--                  {{ $t('transactions.address_to') }}</p>-->
              <!--                <CopyString-->
              <!--                    v-if="data.addressTo"-->
              <!--                    :string="data.addressTo"-->
              <!--                    :successMessage="$t('copy.addresses_copied')"-->
              <!--                    :failMessage="$t('copy.addresses_not_copied')"-->
              <!--                    :title="notification"-->
              <!--                    canCopy-->
              <!--                />-->
              <!--                <p class="preview-transaction__preview-text" v-else>-</p>-->
              <!--              </div>-->
              <div class="preview-transaction__row">
                <p class="preview-transaction__title-text  preview-transaction__title-text_secondary">
                  tx_id </p>
                <CopyString
                    v-if="data.transactionHash"
                    :string="data.transactionHash"
                    :successMessage="$t('copy.transaction_hash')"
                    :failMessage="$t('copy.transaction_hash_failed')"
                    :title="$t('copy.notification')"
                    canCopy
                />
                <p class="preview-transaction__preview-text" v-else>-</p>
              </div>
              <div class="preview-transaction__row">
                <p class="preview-transaction__title-text preview-transaction__title-text_secondary">
                  {{ $t('transactions.type') }}</p>
                <p class="preview-transaction__preview-text"> {{ $t(type) || '-' }}</p>
              </div>
              <RouterLink
                  class=" preview-transaction__read-more"
                  :to="{ name: routeName, params: { id } }"
              >{{ $t('orders.read_more') }}
              </RouterLink>
            </div>
          </AtCollapseItem>
        </AtCollapse>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from 'vuex';
import AtCollapse from 'at-ui/src/components/collapse/';
import AtCollapseItem from 'at-ui/src/components/collapse-item/';
import CopyString from "/public/components/elements/CopyString";
import CurrencyImg from "/public/components/elements/CurrencyImg";
import {
    orderTransactionType,
    transactionHistoryBasis,
    transactionHistorySource,
    transactionHistoryStatus,
} from "../../../filters";
import {TRANSACTION_HISTORY_STATUS_ENUM} from "../../../../common/constants/transaction-history-status-enum";

export default {
    name: 'PreviewOrderTransaction',
    components: {
        CopyString,
        CurrencyImg,
        AtCollapse,
        AtCollapseItem,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        routeName: {
            type: String,
            default: 'transaction',
        },
    },
    computed: {
        ...mapState('app', [
            'currencies',
        ]),
        id() {
            return this.data.id;
        },
        type() {
            return orderTransactionType(this.data.type);
        },
        status() {
            return transactionHistoryStatus(this.data.status);
        },
        basis() {
            return transactionHistoryBasis(this.data.basis);
        },
        source() {
            return transactionHistorySource(this.data.side);
        },
        createdAt() {
            return this.data.createdAt;
        },
        networkId() {
            return this.data.networkId;
        },
        currencyFrom() {
            const {
                data: {
                    currencyFrom,
                },
            } = this;

            return currencyFrom;
        },
        currencyTo() {
            const {
                data: {
                    currencyId,
                },
            } = this;

            return currencyId;
        },
        mobileStatusTextColor() {
            switch (this.data.status) {
            case TRANSACTION_HISTORY_STATUS_ENUM.INIT:
                return '#6190e8';
            case TRANSACTION_HISTORY_STATUS_ENUM.PROCESSED:
                return '#13ce66';
            case TRANSACTION_HISTORY_STATUS_ENUM.PENDING:
                return '#6190e8';
            case TRANSACTION_HISTORY_STATUS_ENUM.ERROR:
                return '#EB5757';
            case TRANSACTION_HISTORY_STATUS_ENUM.REJECTED:
                return '#EB5757';
            default:
                return '#8F949A';
            }
        },
        hasCurrencyFrom() {
            return Boolean(this.currencyFrom);
        },
        hasCurrencyTo() {
            return Boolean(this.currencyTo);
        },
        orderId() {
            return this.data.orderId;
        },
        hasOrderId() {
            return Boolean(this.orderId);
        },
        orderCreatedAt() {
            return this.data.orderCreatedAt;
        },
        credit() {
            return this.data.credit;
        },
        deposit() {
            return this.data.deposit;
        },
        id_copied() {
            return this.$t('copy.id_copied');
        },
        id_not_copied() {
            return this.$t('copy.id_not_copied');
        },
        notification() {
            return this.$t('copy.notification');
        },

    },
};

</script>

<style lang="scss" scoped>

.preview-transaction {
  padding: 0;
  position: relative;
  color: var(--primary-font-color);

  &_mobile {
    display: none;
  }

  &:hover {

    & .preview-transaction__col {
      background-color: var(--main-page-line);
    }

  }

  &__inner-row {
    display: grid;
    grid-template-columns: repeat( 7, 20%);
    width: 100%;
    min-height: 55px;
    align-items: center;
  }

  &__write_off_currency {
    text-align: start;

  }

  &__col {
    font-size: var(--font-size-text-main);
    font-family: var(--font-family);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    text-align: start;
    min-height: 55px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);

    &_status {
      color: var(--colorStatus);
    }

    &:first-child {
      padding-left: 45px;
    }

    &:last-child {
      padding-right: 45px;
      justify-content: flex-end;
    }
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(7 * 20%);
    height: 100%;
    z-index: 0;
  }

  &__id {
    position: relative;
    z-index: 2;
  }

  &__currency {
    display: flex;
    align-items: center;
    justify-content: start;
  }

  &__currency_write-off {
    align-items: center;
  }

  &__currency-img {
    margin-right: 12px;
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 1330px) {
  .preview-transaction {
    &__col {
      &:first-child {
        padding-left: 15px;
      }

      &:last-child {
        padding-right: 15px;
      }
    }
  }

}

@media screen and (max-width: 950px) {
  .preview-transaction {

    display: none;

    &__title-text {
      font-size: var(--font-size-text-main);
      font-family: var(--font-family);
      line-height: var(--line-height);
      color: var(--primary-font-color);
      text-align: right;

      &_secondary {
        color: var(--grey-300);
      }
    }

    &__row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 40px;
      border-top: 1px solid var(--border-color);

      &:last-of-type {
        border-bottom: none;
      }

      ::v-deep .copy-string__string {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        color: var(--primary-font-color);
      }
    }

    &__preview-text {
      font-size: var(--font-size-text-main);
      font-family: var(--font-family);
      line-height: var(--line-height);
      color: var(--primary-font-color);
      text-align: right;
    }

    &__currency {
      &-status {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: var(--font-size-text-caption);
        line-height: var(--line-height);
        color: var(--colorStatus);
      }

      &-text {
        font-size: var(--font-size-text-main);
        font-family: var(--font-family);
        line-height: var(--line-height);
        color: var(--primary-font-color);


      }
    }

    &_mobile {
      display: block;
    }

    &__preview {
      display: flex;
      justify-content: space-between;
      flex: 1;
      padding-right: 60px;
      align-items: center;
    }

    &__read-more {
      width: 100%;
      max-width: 300px;
      border-radius: 12px;
      background: #0059F7;
      font-size: var(--font-size-text-main);
      font-family: var(--font-family);
      line-height: var(--line-height);
      text-align: center;
      color: var(--white-100);
      min-height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 25px;
      margin-bottom: 20px;
    }

    &__detailed-info {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    &__mobile {
      display: block;

      ::v-deep .at-collapse {
        border: 1px solid var(--border-color);
        border-right: none;
        border-left: none;
      }

      ::v-deep .at-collapse__content {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 0;
        padding-bottom: 0;
      }

      ::v-deep .at-collapse__header {
        background: #FFFFFF;
        min-height: 50px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 0;
        padding-bottom: 0;
      }

      ::v-deep .at-collapse__icon {
        right: 10px;
        top: 17px;
        font-size: 20px;
        left: unset;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }

      ::v-deep .at-collapse__item--active > .at-collapse__header .at-collapse__icon {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
      }
    }
  }
}


</style>
