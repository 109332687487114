import updateApiKeysMutation from "../../../../graphQl/mutations/updateApiKeys";
import {i18n} from "@/public/i18n";

export const updateApiKeys = async ({ dispatch, rootGetters }, payload) => {
    let updateStatusData;

    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    const variables = {
        organizationId,
        ...payload,
    };

    try {
        updateStatusData = await dispatch('app/request', {
            type: 'mutation',
            gql: updateApiKeysMutation,
            variables,
        }, { root: true });
    } catch (error) {
        return error;
    }

    if (updateStatusData.graphQLErrors && updateStatusData.graphQLErrors[0].extensions.code === "INCORRECT_2FA") {
        throw i18n.t('error.error_two-fa');
    }

    if (updateStatusData.message) {
        throw updateStatusData.message;
    }

    if (updateStatusData.errors) {
        throw updateStatusData.errors;
    }

    const {
        data: { updateApiKeys },
    } = updateStatusData;

    return updateApiKeys;
};
