import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!, $id: String!) {
        getBlockchainTransaction(organizationId: $organizationId, id: $id) {
            id
            type
            networkId
            status
            createdAt
            orderId
            currencyId
            transactionHash
            amount
            amountUSD
            updatedAt
            deletedAt
            priceUSD
            recipients
            senders
        }
    }
`;
