import gql from 'graphql-tag';

export default gql`
  query(
    $organizationId: String!,
    $id: [String!]!
    $clientId: [String!]!
    $status: [CrosschainTransferStatus!]!
    $currencyId: [String!]!
    $networkFromId: [String!]!
    $networkToId: [String!]!
    $addressFromId: [String!]!
    $addressToId: [String!]!
    $amount: [String!]!
    $createdAt: [String!]!
    $offset: Int!
    $limit: Int!
    $sort: String!
  ) {
    crosschainTransfers(
      organizationId: $organizationId,
      id: $id, 
      clientId: $clientId,
      status: $status,
      currencyId: $currencyId,
      networkFromId: $networkFromId,
      networkToId: $networkToId,
      addressFromId: $addressFromId,
      addressToId: $addressToId,
      amount: $amount,
      createdAt: $createdAt,
      offset: $offset,
      limit: $limit
      sort: $sort
    ) {
      count,
      transfers {
        id
        accountId
        clientId
        status
        rejectMessage
        currencyId
        networkFromId
        networkToId
        addressFromId
        addressFrom
        addressToId
        addressTo
        amount
        amountUSD
        blockchainFee
        blockchainFeeUSD
        serviceFeeUSD
        createdAt
      }
    }
  }
`;
