<script>
  import Container from "@/public/components/common/uiKit/Container.vue";
  import Tabs from "@/public/components/elements/sepaSwift/Tabs.vue";

  export default {
    name: "SepaSwift",
    components: {Container, Tabs}
  }
</script>

<template>
<div class="sepaSwift">
  <Container>
    <Tabs />
    <div class="sepaSwift__content">
      <div class="sepaSwift__create">
        <div class="sepaSwift__create-title"></div>
        <div class="sepaSwift__create-subtitle"></div>
      </div>
    </div>
  </Container>
</div>
</template>

<style scoped lang="scss">
.sepaSwift {
  padding: 0 $p30 $p30;
}
</style>
