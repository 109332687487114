<script>
  import {mapActions, mapState} from "vuex";
  import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";

  export default {
    name: 'TopBarOrganization',
    components: {BaseButton},

    computed: {
      ...mapState('organizations', [
        'currentTabSelect',
      ]),

      tabs() {
        return [
          {
            name: this.$t('organization.organization'),
            position: 0,
          },
          {
            name: this.$t('organization.organization_users'),
            position: 1,
          },
          {
            name: this.$t('organization.organization_roles'),
            position: 2,
          },
        ];
      },
    },

    methods: {
      ...mapActions('organizations', [
        'getOrganizationUsers',
        'getOrganizationRoles',
      ]),

      switchTab(tab) {
        this.$store.commit('organizations/switchTab', tab.position);
        this.$store.commit('organizations/changeDetailRole', false);
        if (tab.position === 1) this.getOrganizationUsers();
        if (tab.position === 2) this.getOrganizationRoles();
      },
    },
  };
</script>

<template>
<div class="top-bar-organization">
  <base-button
      class="transfer-btn"
      type="primary"
      :label="$t('organization.organization_btn_transfer')"
      @click="$emit('transfer')"
  />
  <div class="top-bar__container">
    <div class="top-bar__wrapper">
      <div v-for="(tab, i) in tabs"
           :class="['top-bar__tab', {'active': tab.position === currentTabSelect}]"
           :key="i"
           @click="switchTab(tab)"
      >
        {{ tab.name }}
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.transfer-btn {
  width: 247px;
}
.top-bar-organization {
  display: flex;
  align-items: center;
  gap: 15px;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.top-bar {
  &__container {
    width: 100%;
    height: 48px;
    //
    //box-shadow: 0 8px 50px rgba(10, 17, 105, 0.08);
    //border-radius: 20px;
    //padding: 5px;
    //background: var(--secondary-color);
    //margin-bottom: 30px;
    padding-left: 15px;
    border-left: 1px solid var(--new-front-divider-color);
    @media screen and (max-width: 1440px) {
      border-left: unset;
    }

    @media screen and (max-width: 768px) {
      //border-radius: 16px;
      //height: auto;
      //box-shadow: none;
      //margin-bottom: 6px;
      //padding: 20px;
    }
  }

  &__wrapper {
    width: 100%;
    height: 48px;
    //border: 1px solid var(--border-color);
    //box-shadow: 0 4px 20px rgba(0, 0, 0, 0.04);
    //border-radius: 15px;
    //background: var(--toggle-theme-background);
    display: flex;
    align-items: center;
    gap: 15px;

    @media screen and (max-width: 768px) {
      border: none;
      height: 42px;
    }
  }

  &__tab {
    width: 100%;
    max-width: 160px;
    height: 48px;
    background: transparent;
    border-radius: 15px;
    margin-top: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--new-front-secondary-font-color);
    cursor: pointer;
    transition: all .3s ease;
    min-width: 125px;

    @media screen and (max-width: 768px) {
      font-size: 13px;
    }

    &:first-child {
      margin-left: -1px;
      min-width: 180px;
    }

    //&:last-child {
    //  width: calc(33.3333333% + 2px);
    //}

    &.active {
      background: var(--new-front-select-item-bg-active);
      color: var(--new-front-primary-font-color);
    }

    &:hover {
      background: var(--new-front-select-item-bg-active);
      color: var(--new-front-primary-font-color);
    }
  }
}
@media screen and (max-width: 1440px) {
  .transfer-btn {
    display: none;
  }
}
</style>
