import gql from 'graphql-tag';

export default gql`
    mutation($organizationId: String!, $accountId: String!, $currencyId: String!, $networkFromId: String!, $networkToId: String!, $amount: String!) {
        crosschainTransferToken(organizationId: $organizationId, accountId: $accountId, currencyId: $currencyId, networkFromId: $networkFromId, networkToId: $networkToId, amount: $amount){
            success
            code
            result {
              accountId
              currencyId
              networkFromId
              networkToId
              blockchainFee
              blockchainFeeUSD
              serviceFeeUSD
              amount
              amountUSD
              token
              expiresAt
            }
        }
    }
`;
