<template>
<div>
  <preloader v-if="isLoading || localLoading"/>
  <div v-else class="referral">
<!--    <h1 class="referral__title">-->
<!--      {{ $t('referral.title') }}-->
<!--      <common-info-modal-->
<!--        :description="$t('info_modals.referral.description')"-->
<!--      />-->
<!--    </h1>-->
<!--    <div class="referral__paragraph-block">-->
<!--        <span class="referral__paragraph">-->
<!--          {{ $t('referral.description') }}-->
<!--        </span>-->
<!--    </div>-->
    <div class="referral__topBar container">
        <div class="referral__topBar-item">
          <div class="referral__topBar-label">ref code:</div>
          <div class="referral__topBar-value">
            <CopyString
                :string="refCode"
                :successMessage="code_copied"
                :title="notification"
                canCopy
            />
          </div>
        </div>
        <div class="referral__topBar-item">
          <div class="referral__topBar-label">ref link:</div>
          <div class="referral__topBar-value">
            <CopyString
                :string="refLink"
                :successMessage="link_copied"
                :title="notification"
                canCopy
                hash
            />
          </div>
        </div>
        <div class="referral__topBar-item">
          <div class="referral__topBar-label">{{ $t('referral.active_user') }}</div>
          <div class="referral__topBar-value">
            {{ referralData.activeReferrals }}
          </div>
        </div>
        <div class="referral__topBar-item">
          <div class="referral__topBar-label">{{ $t('referral.zero_user') }}</div>
          <div class="referral__topBar-value">
            {{ referralData.inactiveReferrals }}
          </div>
        </div>
    </div>
    <div class="referral__balances">
      <div class="referral__balanceCard container">
        <RouterLink
            class="referral__history"
            :to="'/referral/buffer-balance-history'"
        >
          <svg width="50" height="40" viewBox="0 0 50 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M25 12.5C23.0773 12.5055 21.2301 13.2492 19.84 14.5775V13.25C19.84 13.0511 19.761 12.8603 19.6203 12.7197C19.4797 12.579 19.2889 12.5 19.09 12.5C18.8911 12.5 18.7003 12.579 18.5597 12.7197C18.419 12.8603 18.34 13.0511 18.34 13.25V16.625C18.34 16.8239 18.419 17.0147 18.5597 17.1553C18.7003 17.296 18.8911 17.375 19.09 17.375H22.465C22.6639 17.375 22.8547 17.296 22.9953 17.1553C23.136 17.0147 23.215 16.8239 23.215 16.625C23.215 16.4261 23.136 16.2353 22.9953 16.0947C22.8547 15.954 22.6639 15.875 22.465 15.875H20.665C21.6293 14.8673 22.9146 14.227 24.2999 14.0643C25.6852 13.9015 27.0839 14.2265 28.2555 14.9833C29.4271 15.7401 30.2985 16.8814 30.7197 18.2111C31.141 19.5408 31.0859 20.9756 30.5638 22.269C30.0417 23.5625 29.0854 24.6336 27.8591 25.2982C26.6329 25.9629 25.2134 26.1796 23.8447 25.911C22.476 25.6424 21.2436 24.9054 20.3594 23.8266C19.4753 22.7479 18.9946 21.3948 19 20C19 19.8011 18.921 19.6103 18.7803 19.4697C18.6397 19.329 18.4489 19.25 18.25 19.25C18.0511 19.25 17.8603 19.329 17.7197 19.4697C17.579 19.6103 17.5 19.8011 17.5 20C17.5 21.4834 17.9399 22.9334 18.764 24.1668C19.5881 25.4001 20.7594 26.3614 22.1299 26.9291C23.5003 27.4968 25.0083 27.6453 26.4632 27.3559C27.918 27.0665 29.2544 26.3522 30.3033 25.3033C31.3522 24.2544 32.0665 22.918 32.3559 21.4632C32.6453 20.0083 32.4968 18.5003 31.9291 17.1299C31.3614 15.7594 30.4001 14.5881 29.1668 13.764C27.9334 12.9399 26.4834 12.5 25 12.5ZM25 17C24.8011 17 24.6103 17.079 24.4697 17.2197C24.329 17.3603 24.25 17.5511 24.25 17.75V20C24.25 20.1989 24.329 20.3897 24.4697 20.5303C24.6103 20.671 24.8011 20.75 25 20.75H26.5C26.6989 20.75 26.8897 20.671 27.0303 20.5303C27.171 20.3897 27.25 20.1989 27.25 20C27.25 19.8011 27.171 19.6103 27.0303 19.4697C26.8897 19.329 26.6989 19.25 26.5 19.25H25.75V17.75C25.75 17.5511 25.671 17.3603 25.5303 17.2197C25.3897 17.079 25.1989 17 25 17Z"
                fill="#0059F7"/>
            <rect x="0.5" y="0.5" width="49" height="39" rx="11.5" stroke="var(--border-color)"/>
          </svg>

        </RouterLink>
        <div class="referral__balanceCard-titleBlock">
          <div class="referral__balanceCard-title">
            {{ $t('referral.buffer_balance') }}
<!--            <common-info-modal-->
<!--                :description="$t('info_modals.referral.buffer_balance.description')"-->
<!--            />-->
<!--            <div class="referral__balanceCard-tooltip">-->
<!--              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M10 9C9.73479 9 9.48043 9.10536 9.2929 9.29289C9.10536 9.48043 9 9.73478 9 10V14C9 14.2652 9.10536 14.5196 9.2929 14.7071C9.48043 14.8946 9.73479 15 10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14V10C11 9.73478 10.8946 9.48043 10.7071 9.29289C10.5196 9.10536 10.2652 9 10 9ZM10.38 5.08C10.1365 4.97998 9.86347 4.97998 9.62 5.08C9.49725 5.12759 9.38511 5.19896 9.29 5.29C9.20167 5.3872 9.13065 5.49882 9.08 5.62C9.02402 5.73868 8.99662 5.86882 9 6C8.99924 6.13161 9.02447 6.26207 9.07423 6.38391C9.124 6.50574 9.19732 6.61656 9.29 6.71C9.38721 6.79833 9.49882 6.86936 9.62 6.92C9.7715 6.98224 9.93597 7.00632 10.099 6.99011C10.2619 6.97391 10.4184 6.91792 10.5547 6.82707C10.691 6.73622 10.8029 6.61328 10.8805 6.46907C10.9582 6.32486 10.9992 6.16378 11 6C10.9963 5.73523 10.8927 5.48163 10.71 5.29C10.6149 5.19896 10.5028 5.12759 10.38 5.08ZM10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18Z" fill="#8F949A"/>-->
<!--              </svg>-->
<!--            </div>-->
          </div>
          <div class="referral__balanceCard-subtitle"></div>
        </div>
        <div class="referral__balanceCard-balanceBlock">
          <div class="referral__balanceCard-title">
            {{ $t('referral.balance') }}
          </div>
          <div class="referral__balanceCard-title referral__balanceCard-sum">{{ referralData.bufferBalance }} USD</div>
        </div>
        <div class="referral__balanceCard-info">
          <div class="referral__balanceCard-info__item">
            <div class="referral__balanceCard-info__title">{{ $t('referral.frozen_balance') }}</div>
            <div class="referral__balanceCard-info__value">{{ referralData.freezingBufferBalance }} USD</div>
          </div>
          <div class="referral__balanceCard-info__item">
            <div class="referral__balanceCard-info__title">
              {{ $t('referral.daily_charges') }}
<!--              <div class="referral__balanceCard-tooltip">-->
<!--                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path d="M10 9C9.73479 9 9.48043 9.10536 9.2929 9.29289C9.10536 9.48043 9 9.73478 9 10V14C9 14.2652 9.10536 14.5196 9.2929 14.7071C9.48043 14.8946 9.73479 15 10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14V10C11 9.73478 10.8946 9.48043 10.7071 9.29289C10.5196 9.10536 10.2652 9 10 9ZM10.38 5.08C10.1365 4.97998 9.86347 4.97998 9.62 5.08C9.49725 5.12759 9.38511 5.19896 9.29 5.29C9.20167 5.3872 9.13065 5.49882 9.08 5.62C9.02402 5.73868 8.99662 5.86882 9 6C8.99924 6.13161 9.02447 6.26207 9.07423 6.38391C9.124 6.50574 9.19732 6.61656 9.29 6.71C9.38721 6.79833 9.49882 6.86936 9.62 6.92C9.7715 6.98224 9.93597 7.00632 10.099 6.99011C10.2619 6.97391 10.4184 6.91792 10.5547 6.82707C10.691 6.73622 10.8029 6.61328 10.8805 6.46907C10.9582 6.32486 10.9992 6.16378 11 6C10.9963 5.73523 10.8927 5.48163 10.71 5.29C10.6149 5.19896 10.5028 5.12759 10.38 5.08ZM10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18Z" fill="#8F949A"/>-->
<!--                </svg>-->
<!--              </div>-->
            </div>
            <div class="referral__balanceCard-info__value">{{ referralData.dailyIncome }} USD</div>
          </div>
        </div>
      </div>
      <div class="referral__balanceCard container">
        <RouterLink
            class="referral__history"
            :to="'/referral/remuneration-balance-history'"
        >
          <svg width="50" height="40" viewBox="0 0 50 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M25 12.5C23.0773 12.5055 21.2301 13.2492 19.84 14.5775V13.25C19.84 13.0511 19.761 12.8603 19.6203 12.7197C19.4797 12.579 19.2889 12.5 19.09 12.5C18.8911 12.5 18.7003 12.579 18.5597 12.7197C18.419 12.8603 18.34 13.0511 18.34 13.25V16.625C18.34 16.8239 18.419 17.0147 18.5597 17.1553C18.7003 17.296 18.8911 17.375 19.09 17.375H22.465C22.6639 17.375 22.8547 17.296 22.9953 17.1553C23.136 17.0147 23.215 16.8239 23.215 16.625C23.215 16.4261 23.136 16.2353 22.9953 16.0947C22.8547 15.954 22.6639 15.875 22.465 15.875H20.665C21.6293 14.8673 22.9146 14.227 24.2999 14.0643C25.6852 13.9015 27.0839 14.2265 28.2555 14.9833C29.4271 15.7401 30.2985 16.8814 30.7197 18.2111C31.141 19.5408 31.0859 20.9756 30.5638 22.269C30.0417 23.5625 29.0854 24.6336 27.8591 25.2982C26.6329 25.9629 25.2134 26.1796 23.8447 25.911C22.476 25.6424 21.2436 24.9054 20.3594 23.8266C19.4753 22.7479 18.9946 21.3948 19 20C19 19.8011 18.921 19.6103 18.7803 19.4697C18.6397 19.329 18.4489 19.25 18.25 19.25C18.0511 19.25 17.8603 19.329 17.7197 19.4697C17.579 19.6103 17.5 19.8011 17.5 20C17.5 21.4834 17.9399 22.9334 18.764 24.1668C19.5881 25.4001 20.7594 26.3614 22.1299 26.9291C23.5003 27.4968 25.0083 27.6453 26.4632 27.3559C27.918 27.0665 29.2544 26.3522 30.3033 25.3033C31.3522 24.2544 32.0665 22.918 32.3559 21.4632C32.6453 20.0083 32.4968 18.5003 31.9291 17.1299C31.3614 15.7594 30.4001 14.5881 29.1668 13.764C27.9334 12.9399 26.4834 12.5 25 12.5ZM25 17C24.8011 17 24.6103 17.079 24.4697 17.2197C24.329 17.3603 24.25 17.5511 24.25 17.75V20C24.25 20.1989 24.329 20.3897 24.4697 20.5303C24.6103 20.671 24.8011 20.75 25 20.75H26.5C26.6989 20.75 26.8897 20.671 27.0303 20.5303C27.171 20.3897 27.25 20.1989 27.25 20C27.25 19.8011 27.171 19.6103 27.0303 19.4697C26.8897 19.329 26.6989 19.25 26.5 19.25H25.75V17.75C25.75 17.5511 25.671 17.3603 25.5303 17.2197C25.3897 17.079 25.1989 17 25 17Z"
                fill="#0059F7"/>
            <rect x="0.5" y="0.5" width="49" height="39" rx="11.5" stroke="var(--border-color)"/>
          </svg>

        </RouterLink>
        <div class="referral__balanceCard-titleBlock">
          <div class="referral__balanceCard-title">
            {{ $t('referral.reward_balance') }}
<!--            <common-info-modal-->
<!--              :description="$t('info_modals.referral.reward_balance.description')"-->
<!--            />-->
<!--            <div class="referral__balanceCard-tooltip">-->
<!--              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M10 9C9.73479 9 9.48043 9.10536 9.2929 9.29289C9.10536 9.48043 9 9.73478 9 10V14C9 14.2652 9.10536 14.5196 9.2929 14.7071C9.48043 14.8946 9.73479 15 10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14V10C11 9.73478 10.8946 9.48043 10.7071 9.29289C10.5196 9.10536 10.2652 9 10 9ZM10.38 5.08C10.1365 4.97998 9.86347 4.97998 9.62 5.08C9.49725 5.12759 9.38511 5.19896 9.29 5.29C9.20167 5.3872 9.13065 5.49882 9.08 5.62C9.02402 5.73868 8.99662 5.86882 9 6C8.99924 6.13161 9.02447 6.26207 9.07423 6.38391C9.124 6.50574 9.19732 6.61656 9.29 6.71C9.38721 6.79833 9.49882 6.86936 9.62 6.92C9.7715 6.98224 9.93597 7.00632 10.099 6.99011C10.2619 6.97391 10.4184 6.91792 10.5547 6.82707C10.691 6.73622 10.8029 6.61328 10.8805 6.46907C10.9582 6.32486 10.9992 6.16378 11 6C10.9963 5.73523 10.8927 5.48163 10.71 5.29C10.6149 5.19896 10.5028 5.12759 10.38 5.08ZM10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18Z" fill="#8F949A"/>-->
<!--              </svg>-->
<!--            </div>-->
          </div>
          <div class="referral__balanceCard-subtitle"></div>
        </div>
        <div class="referral__balanceCard-balanceBlock">
          <div class="referral__balanceCard-title">{{ $t('referral.balance') }}</div>
          <div class="referral__balanceCard-title referral__balanceCard-sum">{{ referralData.rewardBalance }} USD</div>
        </div>
        <div class="referral__balanceCard-info">
          <div class="referral__balanceCard-info__item">
            <div class="referral__balanceCard-info__title">{{ $t('referral.last_reward') }}</div>
            <div class="referral__balanceCard-info__value">{{ referralData.lastReward }} USD</div>
          </div>
          <div class="referral__balanceCard-info__item">
            <div class="referral__balanceCard-info__title">
              {{ $t('referral.next_reward') }}
<!--              <div class="referral__balanceCard-tooltip">-->
<!--                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path d="M10 9C9.73479 9 9.48043 9.10536 9.2929 9.29289C9.10536 9.48043 9 9.73478 9 10V14C9 14.2652 9.10536 14.5196 9.2929 14.7071C9.48043 14.8946 9.73479 15 10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14V10C11 9.73478 10.8946 9.48043 10.7071 9.29289C10.5196 9.10536 10.2652 9 10 9ZM10.38 5.08C10.1365 4.97998 9.86347 4.97998 9.62 5.08C9.49725 5.12759 9.38511 5.19896 9.29 5.29C9.20167 5.3872 9.13065 5.49882 9.08 5.62C9.02402 5.73868 8.99662 5.86882 9 6C8.99924 6.13161 9.02447 6.26207 9.07423 6.38391C9.124 6.50574 9.19732 6.61656 9.29 6.71C9.38721 6.79833 9.49882 6.86936 9.62 6.92C9.7715 6.98224 9.93597 7.00632 10.099 6.99011C10.2619 6.97391 10.4184 6.91792 10.5547 6.82707C10.691 6.73622 10.8029 6.61328 10.8805 6.46907C10.9582 6.32486 10.9992 6.16378 11 6C10.9963 5.73523 10.8927 5.48163 10.71 5.29C10.6149 5.19896 10.5028 5.12759 10.38 5.08ZM10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18Z" fill="#8F949A"/>-->
<!--                </svg>-->
<!--              </div>-->
            </div>
            <div class="referral__balanceCard-info__value">{{ referralData.nextReward }} USD</div>
          </div>
        </div>
        <div class="referral__balanceCard-withdrawBtn">
          <btn
              :class="{disabledBtn: Number(referralData.rewardBalance) <= 0}"
              :disabled="Number(referralData.rewardBalance) <= 0"
              :text-color="Number(referralData.rewardBalance) <= 0 ? '--grey-300' : '--white-100'"
              @click="openCreateWithdrawalRequestModal"
              size="s"
          >
            {{ $t('referral.withdrawal') }}</btn>
        </div>
      </div>
    </div>
    <div class="referral__list">
      <ReferralItem v-for="user in paginateSortedUsers" :value="user"/>
    </div>
    <pagination :size="'small'"
                :total="totalCount"
                :page-size="pageSize"
                :current="page"
                @page-change="changePage"
                v-if="totalCount"
                />
    <ReferralCreateWithdrawalRequestModalPayout />
    <NewAddressModal />
<!--    @page-change="changePage"-->
  </div>
  <TwoFaConfirmation :modal-name="'ReferralCreateWithdrawalRequestModalPayout'" />
  <TwoFaRestriction />
</div>

</template>

<script>
import comingSoon from "@components/elements/coming-soon";
import Preloader from "/public/components/common/uiKit/spinner/index.vue";
import {mapActions, mapState} from "vuex";
import CopyString from "../components/elements/CopyString";
import ReferralItem from "../components/elements/referral/ReferralItem";
import Pagination from "../components/common/pagination";
import getUserReferralsResponse from "../graphQl/queries/getUserReferralsResponse";
import btn from "../components/common/btn";
import BigNumber from "bignumber.js";
import ReferralCreateWithdrawalRequestModalPayout
    from "../components/modals/referral/ReferralCreateWithdrawalRequestModalPayout";
import NewAddressModal from "../components/modals/addressBook/NewAddressModal";

import CommonInfoModal from "/public/components/modals/commonInfo";
import TwoFaConfirmation from "@/public/components/modals/twoFactorAuthorization/TwoFaConfirmation.vue";
import TwoFaRestriction from "@/public/components/modals/twoFactorAuthorization/TwoFaRestriction.vue";

export default {
    name: "referral",
    title: 'navigation.referral',

    components: {
      TwoFaRestriction,
      TwoFaConfirmation,
        NewAddressModal,
        ReferralCreateWithdrawalRequestModalPayout,
        ReferralItem,
        CopyString,
        Preloader,
        comingSoon,
        Pagination,
        btn,
        CommonInfoModal
    },
    data () {
        return {
            localLoading: false,
            referralData: {},
            pageSize: 4,
            page: 1,
        };
    },
    computed: {
        ...mapState('app', [
            'isInit',
            'isLoading',
        ]),
      ...mapState('user', {
        userOtpEnabled: state => state.user.otpToken,
      }),
        notification() {
            return this.$t('copy.notification');
        },
        refCode () {
            return this.referralData?.referralCode || '';
        },
        refLink() {
            return window.location.origin + '/registration?refCode=' + this.refCode;
        },
        totalCount () {
            return this.referralData.totalCount;
        },
        code_copied() {
            return this.$t('referral.ref_code_copied');
        },
        link_copied() {
            return this.$t('referral.ref_link_copied');
        },
        paginateSortedUsers() {
            if (!this.referralData.userReferral) return;

            return this.filteredUsers;
        },
        filteredUsers () {
            if (!this.referralData.userReferral) return;

            return this.referralData.userReferral.map((item) => {
                const dailyIncome = item.reduce(
                    (accumulator, currentValue) =>  this.reduceCommission(accumulator, currentValue, 'dailyIncome'),
                    0,
                );
                const monthIncome = item.reduce(
                    (accumulator, currentValue) =>  this.reduceCommission(accumulator, currentValue, 'monthIncome'),
                    0,
                );
                const totalBalance = item.reduce(
                    (accumulator, currentValue) =>  this.reduceCommission(accumulator, currentValue, 'totalBalance'),
                    0,
                );
                return {
                    email: item[0].email,
                    referencePercent: item[0].referencePercent,
                    dailyIncome: BigNumber(dailyIncome).decimalPlaces(8),
                    monthIncome: BigNumber(monthIncome).decimalPlaces(8),
                    totalBalance: BigNumber(totalBalance).decimalPlaces(8),
                    commissions: [...item],
                };
            });
        },
    },
    async mounted() {
        this.localLoading = true;
        await this.getReferrals().then(() => {
            this.localLoading = false;
        });
    },
    methods: {
        ...mapActions('app', [
            'openModal',
            'request',
        ]),
        reduceCommission (accumulator, currentValue, key) {
            if (currentValue.advReasonType === 'REFUND') {
                return Number(accumulator) - Number(currentValue[key]);
            }
            return Number(accumulator) +  Number(currentValue[key]);
        },
        openCreateWithdrawalRequestModal() {
            const {
                openModal,
                referralData,
            } = this;

            if (referralData.rewardBalance <= 0) {
                return;
            }

          if (!this.userOtpEnabled) {
            this.openModal({
              name: 'TwoFaRestriction',
            });
            return;
          }

            openModal({
                name: 'ReferralCreateWithdrawalRequestModalPayout',
                payload: {
                    // Temporary decision waiting for backend
                    addressId: '4d2de354-c4b6-45c8-90f8-e97e70a046a7',
                    addressInfo: referralData.addressInfo,
                    rewardBalance: referralData.rewardBalance,
                },
            });
        },
        async changePage (pageNumber) {
            this.localLoading = true;
            await this.getReferrals(pageNumber).then(() => {
                this.localLoading = false;
            });
        },
        async getReferrals (pageNumber = 1) {
            const req = await this.request({
                type: 'query',
                gql: getUserReferralsResponse,
                variables: {
                    limit: this.pageSize,
                    offset: pageNumber,
                },
            });
            this.page = pageNumber;
            this.referralData = req.data.getUserReferrals.result;
        },
    },
};
</script>

<style lang="scss" scoped>
.referral {
  padding-left: var(--padding-left-container);
  padding-right: var(--padding-right-container);
  width: 100%;
  //@media (max-width: 950px) {
  //  padding: 0 10px;
  //}
  &__history {
    position: absolute;
    top: 30px;
    right: 30px;
    @media (max-width: 950px) {
      top: 15px;
      right: 15px;
    }
  }
  &__title {
    display: flex;
    align-items: center;

    margin-top: var(--margin-h1-bottom);
    margin-bottom: var(--margin-h1-bottom);
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-h1);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    @media (max-width: 950px) {
      margin-top: var(--margin-h1-bottom);
      text-align: center;
    }
  }

  &__paragraph-block {
    display: flex;
    align-items: start;
    margin-bottom: 30px;
  }

  &__paragraph {
    font-style: normal;
    font-weight: 400;
    font-family: var(--font-family);
    font-size: var(--font-size-h3);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__topBar {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    width: 100%;

    padding: 15px 30px;
    margin-bottom: 10px;
    @media (max-width: 950px) {
      grid-template-columns: 1fr 2fr;
      grid-template-rows: 1fr 1fr;
    }
    @media (max-width: 776px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      padding: 10px;
    }
    &-item {
      display: flex;
      align-items: center;
      justify-content: center;

      gap: 7px;

      border-right: 1px solid var(--border-color);
      padding-left: 15px;
      @media (max-width: 950px) {
        border-bottom: 1px solid var(--border-color);
        border-right: none;
      }
      @media (max-width: 776px) {
        padding: 10px 0;
        justify-content: space-between;
        &:last-child {
          border-bottom: none;
        }
      }
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        border-right: none;
      }
    }
    &-label {
      font-size: $h4;
      color: var(--secondary-font-color);
      @media (max-width: 776px) {
        font-size: $h4;
      }
    }
    &-value {
      font-size: $h4;
      color: var(--primary-font-color);
      margin-left: 5px;
      @media (max-width: 776px) {
        font-size: $h4;
      }
    }
  }
  &__balances {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    @media (max-width: 950px) {
      grid-template-columns: 1fr;
    }
  }
  &__balanceCard {
    position: relative;
    &-withdrawBtn {
      display: flex;
      justify-content: flex-end;
      padding-right: 50px;
      padding-bottom: 15px;
      @media (max-width: 950px) {
        padding-right: 15px;
        padding-top: 15px;
      }
    }
    &-info {
      padding: 30px 50px;
      @media (max-width: 950px) {
        padding: 15px;
      }
      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
      }
      &__title {
        font-size: 18px;
        color: var(--secondary-font-color);
        @media (max-width: 950px) {
          font-size: 14px;
        }
      }
      &__value {
        font-size: 18px;
        color: var(--primary-font-color);
        @media (max-width: 950px) {
          font-size: 14px;
        }
      }
    }
    &-titleBlock {
      padding-left: 50px;
      padding-top: 50px;
      @media (max-width: 950px) {
        padding-left: 15px;
        padding-top: 15px;
      }
    }
    &-title {
      display: flex;
      align-items: center;

      font-weight: 700;
      font-size: 24px;
      color: var(--primary-font-color);
      @media (max-width: 950px) {
        font-size: $h4;
      }
    }
    &-subtitle {
      font-weight: 400;
      font-size: 20px;
      color: var(--primary-font-color);
      margin-bottom: 20px;
      @media (max-width: 950px) {
        font-size: 14px;
      }
    }
    &-balanceBlock {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 50px 15px 50px;
      border-bottom: 1px solid var(--border-color);
      @media (max-width: 950px) {
        padding: 0 15px 5px 15px;
      }
    }
    &-sum {
      padding: 4px 8px;
      border-radius: 10px;
      background-color: var(--bg-modal-color);
    }
  }

  ::v-deep .pagination,
  ::v-deep .at-pagination__prev,
  ::v-deep .at-pagination__next,
  ::v-deep .at-pagination__item
  {
    background: transparent;
  }
}
.container {
  background: var(--secondary-color);
  /* Gray 6 */

  border: 1px solid var(--border-color);
  box-shadow: 0 8px 50px rgba(10, 17, 105, 0.08);
  border-radius: 20px;
  @media screen and (min-width: 768px) {
    width: 100%;
  }
  @media screen and (min-width: 992px) {
    width: 100%;
  }
}
.comingSoon {
  height: 100%;
  margin-top: -170px;
}
::v-deep .copy-string__string {
  font-size: 18px;
}

.disabledBtn {
  background: var(--main-bg) !important;
  color: #B6BCC3 !important;
  cursor: not-allowed;
}
</style>
