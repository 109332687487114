<script>
  export default {
    name: "WidgetsRadioBtn",
    props: {
      active: Boolean,
    }
  }
</script>

<template>
<div class="radio" :class="{ active }"></div>
</template>

<style scoped lang="scss">
.radio {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid var(--ocp-0-100-tertiary-tertiary90);
  &.active {
    border: 2px solid var(--border-border-input-primary-active);
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      background: var(--border-border-input-primary-active);
      border-radius: 50%;
    }
  }
}
</style>
