<script>

  import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
  import {mapActions} from "vuex";

  export default {
    name: "integrationsFaucetBnb",
    components: {BaseButton},
    data () {
      return {
        isApollo: import.meta.env.VITE_PRODUCT_NAME === 'Apollopayment'
      }
    },
    methods: {
      ...mapActions('app', [
        'openModal',
      ]),
      openFaucet () {
        this.openModal({
          name: 'ModalFaucet',
        });
      }
    }
  }
</script>

<template>
<div class="docs">
  <div class="header">
    <div class="docs__title">
      Faucet BNB
    </div>
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="54" height="54" rx="27" fill="#F0B90B"/>
      <path d="M17.992 26.9998L18.0064 32.2883L22.5 34.9325V38.0287L15.3766 33.8508V25.4534L17.992 26.9998ZM17.992 21.7114V24.7931L15.375 23.245V20.1633L17.992 18.6152L20.6218 20.1633L17.992 21.7114ZM24.3766 20.1633L26.9936 18.6152L29.6234 20.1633L26.9936 21.7114L24.3766 20.1633Z" fill="white"/>
      <path d="M19.8828 31.1922V28.096L22.4998 29.6441V32.7258L19.8828 31.1922ZM24.3764 36.0415L26.9934 37.5896L29.6232 36.0415V39.1232L26.9934 40.6713L24.3764 39.1232V36.0415ZM33.3764 20.1633L35.9934 18.6152L38.6232 20.1633V23.245L35.9934 24.7931V21.7114L33.3764 20.1633ZM35.9934 32.2883L36.0078 26.9998L38.6248 25.4518V33.8492L31.5014 38.0271V34.9309L35.9934 32.2883Z" fill="white"/>
      <path d="M34.117 31.1919L31.5 32.7255V29.6438L34.117 28.0957V31.1919Z" fill="white"/>
      <path d="M34.1168 22.8073L34.1312 25.9034L29.6248 28.5477V33.849L27.0078 35.3826L24.3908 33.849V28.5477L19.8844 25.9034V22.8073L22.5126 21.2592L26.9918 23.9163L31.4982 21.2592L34.128 22.8073H34.1168ZM19.8828 17.5204L26.9934 13.3281L34.1168 17.5204L31.4998 19.0685L26.9934 16.4115L22.4998 19.0685L19.8828 17.5204Z" fill="white"/>
    </svg>
  </div>
  <div class="docs__links">
    <div class="docs__link" @click="openFaucet">
        <span class="docs__link-title">{{ isApollo ? 'Apollopayment' : 'Onchainpay' }}</span>
        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.3755 0.505859C12.273 0.505859 13.0005 1.2334 13.0005 2.13086V11.8809C13.0005 12.7783 12.273 13.5059 11.3755 13.5059H1.62549C0.728026 13.5059 0.000488281 12.7783 0.000488281 11.8809V2.13086C0.000488281 1.2334 0.728026 0.505859 1.62549 0.505859H11.3755ZM4.79733 9.28353L8.12549 5.95538L8.12549 8.20419C8.12549 8.42856 8.30737 8.61044 8.53174 8.61044C8.7561 8.61044 8.93799 8.42856 8.93799 8.20419V4.9746C8.93799 4.75024 8.7561 4.56835 8.53174 4.56835L5.30219 4.56835C5.07782 4.56835 4.89593 4.75024 4.89593 4.9746C4.89593 5.19897 5.07782 5.38085 5.30219 5.38085L7.55096 5.38085L4.22281 8.70901C4.06416 8.86766 4.06416 9.12488 4.22281 9.28353C4.38146 9.44218 4.63868 9.44218 4.79733 9.28353Z" fill="var(--button-border-button-primary-default)"/>
        </svg>
    </div>
    <a href="https://www.bnbchain.org/en/testnet-faucet" target="_blank" class="docs__link">
      <span class="docs__link-title">Bnbchain</span>
      <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.3755 0.505859C12.273 0.505859 13.0005 1.2334 13.0005 2.13086V11.8809C13.0005 12.7783 12.273 13.5059 11.3755 13.5059H1.62549C0.728026 13.5059 0.000488281 12.7783 0.000488281 11.8809V2.13086C0.000488281 1.2334 0.728026 0.505859 1.62549 0.505859H11.3755ZM4.79733 9.28353L8.12549 5.95538L8.12549 8.20419C8.12549 8.42856 8.30737 8.61044 8.53174 8.61044C8.7561 8.61044 8.93799 8.42856 8.93799 8.20419V4.9746C8.93799 4.75024 8.7561 4.56835 8.53174 4.56835L5.30219 4.56835C5.07782 4.56835 4.89593 4.75024 4.89593 4.9746C4.89593 5.19897 5.07782 5.38085 5.30219 5.38085L7.55096 5.38085L4.22281 8.70901C4.06416 8.86766 4.06416 9.12488 4.22281 9.28353C4.38146 9.44218 4.63868 9.44218 4.79733 9.28353Z" fill="var(--button-border-button-primary-default)"/>
      </svg>
    </a>
    <a href="https://faucet.chainstack.com/bnb-testnet-faucet" target="_blank" class="docs__link">
      <span class="docs__link-title">Chainstack</span>
      <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.3755 0.505859C12.273 0.505859 13.0005 1.2334 13.0005 2.13086V11.8809C13.0005 12.7783 12.273 13.5059 11.3755 13.5059H1.62549C0.728026 13.5059 0.000488281 12.7783 0.000488281 11.8809V2.13086C0.000488281 1.2334 0.728026 0.505859 1.62549 0.505859H11.3755ZM4.79733 9.28353L8.12549 5.95538L8.12549 8.20419C8.12549 8.42856 8.30737 8.61044 8.53174 8.61044C8.7561 8.61044 8.93799 8.42856 8.93799 8.20419V4.9746C8.93799 4.75024 8.7561 4.56835 8.53174 4.56835L5.30219 4.56835C5.07782 4.56835 4.89593 4.75024 4.89593 4.9746C4.89593 5.19897 5.07782 5.38085 5.30219 5.38085L7.55096 5.38085L4.22281 8.70901C4.06416 8.86766 4.06416 9.12488 4.22281 9.28353C4.38146 9.44218 4.63868 9.44218 4.79733 9.28353Z" fill="var(--button-border-button-primary-default)"/>
      </svg>
    </a>
  </div>
</div>
</template>

<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.docs {
  width: 100%;
  height: 100%;
  padding: 30px;
  border-radius: 30px;
  background: var(--bg-background-secondary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__links {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--border-border-input-primary-default);
    border-radius: 10px;
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--ocp-0-100-primary-primary98);
    border-bottom: 1px solid var(--border-border-input-primary-default);
    padding: 16px 20px;
    cursor: pointer;
    &:first-child {
      border-radius: 10px 10px 0 0;
    }
    &:last-child {
      border-radius: 0 0 10px 10px;
      border: 0;
    }
    &:hover {
      background: var(--bg-background-primary-purple, #744DB2);
      .docs__link-title{
        color: var(--ocp-white);
      }
      svg {
        path {
          fill: #FFFFFF;
        }
      }
    }
    &-title {
      color: var(--text-color-text-secondary);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
    }
  }
  &__title {
    color: var(--text-text-primary, #000);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }
}
</style>
