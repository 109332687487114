<template>
    <div class="list-header row">
<!--        <div class="centered list-header__item col">-->
<!--            Тип-->
<!--        </div>-->
<!--        <div class="list-header__item col">-->
<!--            Сумма-->
<!--        </div>-->
    </div>
</template>

<script>
export default {
    name: 'AccountsHistoryListHeader',
};
</script>

<style scoped lang="scss">
.list-header {
    height: 0;
    padding: 0;

    border-bottom: none;
}
</style>
