<template>
  <div class="dashboard-transaction">
    <div class="dashboard-transaction__title-container">
      <h2 class="dashboard-transaction__title"> {{ $t('dashboard.successful_transactions') }}</h2>
      <slot/>
    </div>
    <div class="dashboard-transaction__header">
      <div class="list-header__item">{{ $t('dashboard.type') }}</div>
      <div class="list-header__item">{{ $t('dashboard.amount') }}</div>
      <div class="list-header__item">{{ $t('orders.network') }}</div>
      <div class="list-header__item">{{ $t('dashboard.currency') }}</div>
    </div>
    <div class="dashboard-transaction__header dashboard-transaction__header_mobile">
      <div class="list-header__item list-header__item_type">{{ $t('dashboard.type') }}</div>
      <div class="list-header__item">{{ $t('dashboard.amount') }}</div>

      <div class="list-header__item list-header__item_currency">
        <p class="list-header__item">{{ $t('dashboard.currency') }}</p>
        /
        <p class="list-header__item">{{ $t('orders.network') }}</p>
      </div>
    </div>
    <!-- remove Transaction test -->
    <dashboard-transaction-count-row v-if="hasTransactions" v-for="(data, key) in transactions"
                                     :key="key"
                                     :data="data"
    />

    <div class="dashboard-transaction__pagination">
      <EmptyTable v-if="!hasTransactions"/>
      <pagination size="small" :page-size="pageSize" :current="page" @page-change="changePage"
                  :total="transactionsCount.length"
                  v-if="transactionsCount.length > 10"/>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import DashboardTransactionCountRow from "./DashboardTransactionCountRow";
import EmptyTable from "../EmptyTable";
import Pagination from "../../common/pagination";

export default {
    name: 'DashboardTransactionCount',
    data() {
        return {
            page: 1,
            pageSize: 10,
        };
    }
    ,
    components: {
        DashboardTransactionCountRow,
        EmptyTable,
        Pagination,
    },
    computed: {
        ...mapState('dashboard', [
            'transactionsCount',
        ]),
        hasTransactions() {
            return Boolean(this.transactionsCount.length);
        },

        transactions() {
            return this.transactionsCount.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
        },
    },
    methods: {
        changePage(page) {
            this.page = page;
        },
    },
};

</script>

<style lang="scss" scoped>
.dashboard-transaction {
  border-radius: $p30;
  background: var(--new-front-main-bg);
  border: 1px solid var(--new-front-input-border);
  margin-top: 45px;
  width: 100%;

  &__title-container {
    min-height: 70px;
    display: flex;
    align-items: center;
    padding-left: 35px;
    padding-right: 35px;
    gap: 10px;
  }

  &__title {
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: var(--new-front-primary-font-color);
  }

  &__header {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    min-height: 46px;
    background: var(--new-front-main-bg);
    padding-left: 45px;
    border-top: 1px solid var(--new-front-input-border);
    border-bottom: 1px solid var(--new-front-input-border);
    align-items: center;
    gap: 10px;
    color: var(--new-front-primary-font-color);
  }

  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 53px;
  }
}

.list-header__item_currency {
  display: flex;
}
.list-header__item {
  color: var(--new-front-primary-font-color);
}
.dashboard-transaction__header_mobile {
  display: none;
}

@media screen and (max-width: 1336px) {
  .dashboard-transaction {
    &__title-container {
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 907px) {
  .dashboard-transaction {
    &__title-container {
      padding-left: 20px;
      padding-right: 20px;
      justify-content: space-between;
    }

    &__header {
      display: none;
      padding-left: 20px;

      &_mobile {
        display: grid;
        grid-template-columns: min-content 1fr 1fr;
      }
    }
  }
  .list-header__item_type {
    padding-right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .dashboard-transaction {
    &__header {
      &_mobile {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
}

@media screen and (max-width: 441px) {
  .dashboard-transaction {
    &__header {
      &_mobile {
        grid-template-columns: min-content 1fr 1fr;
      }
    }
  }
}
</style>
