<template>
  <div class="bh-select-wrapper">
    <div v-if="label" class="label">
      <slot name="label">
        {{ label }}
      </slot>
    </div>
    <div
      class="input-wrapper"
      :class="{ expanded }"
      @click="expanded = !expanded"
    >
      <div
        :id="`select-${_uid}`"
        class="input"
        :class="{ 'filled': valueModel && clearable }"
      >
        <div
          v-if="!valueModel"
          class="placeholder"
        >
          <slot name="placeholder">
            {{ placeholder }}
          </slot>
        </div>
        <div
          v-else
          class="selected"
        >
          <slot
            name="selected"
            v-bind="valueModel"
          >
            <div v-if="!multiple">
              {{ valueModel.label || valueModel.id }}
            </div>
            <div v-else>
              <span>
                {{ valueModel.label || valueModel.id }}
              </span>
              <span
                v-if="value.length > 1"
                style="padding-left: 5px"
              >
                +{{value.length - 1}}
              </span>
            </div>
          </slot>
        </div>
        <svg
          class="chevron"
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
        >
          <path
            d="M13 1L7 7L1 1"
            stroke="#8F949A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          v-if="clearable"
          class="clear"
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          @click="clear"
        >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.96967 13.0303C0.676777 12.7374 0.676777 12.2626 0.96967 11.9697L5.93934 7L0.96967 2.03033C0.676777 1.73744 0.676777 1.26256 0.96967 0.96967C1.26256 0.676777 1.73744 0.676777 2.03033 0.96967L7 5.93934L11.9697 0.969671C12.2626 0.676777 12.7374 0.676777 13.0303 0.969671C13.3232 1.26256 13.3232 1.73744 13.0303 2.03033L8.06066 7L13.0303 11.9697C13.3232 12.2626 13.3232 12.7374 13.0303 13.0303C12.7374 13.3232 12.2626 13.3232 11.9697 13.0303L7 8.06066L2.03033 13.0303C1.73744 13.3232 1.26256 13.3232 0.96967 13.0303Z"
            fill="#8F949A"
          />
        </svg>
      </div>
      <slot name="options-list">
        <div
          v-if="expanded && list.length"
          class="options-list"
          :class="{ flat }"
        >
          <div
            v-for="(item, key) in list"
            class="options-list-item"
            :class="{ 'active': valueModel && item.id === valueModel.id }"
            :key="key"
            @click="valueModel = item"
          >
            <slot name="option">
              <div class="info">
                <slot
                  name="info"
                  v-bind="item"
                >
                  {{ item.id }}
                </slot>
              </div>
              <div class="action">
                <slot
                  name="action"
                  v-bind="item"
                >
                  <svg
                    v-if="valueModel && item.id === valueModel.id"
                    class="check"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="11"
                    viewBox="0 0 15 11"
                    fill="none"
                  >
                    <path
                      d="M13.7499 1.5L5.43737 9.75L1.65894 6"
                      stroke="#0028F7"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </slot>
              </div>
            </slot>
          </div>
        </div>
        <div
          v-else
          class="options-list empty"
        >
          {{ $t('common.empty') }}
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import AtSelect from 'at-ui/src/components/select';

export default {
  name: "bh-select",
  components: {
    AtSelect
  },
  data() {
    return {
      expanded: false
    }
  },
  props: {
    label: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    },
    list: {
      type: Array,
      default: () => ([])
    },
    value: {
      type: [Array, Object],
      default: undefined
    },
    clearable: {
      type: Boolean,
      default: false
    },
    //пропс отвечает за представление листа без паддингов - обычное представление списка
    flat: {
      type: Boolean,
      default: false
    },
    //пока заготовка под мультиселект - множетсвенный выбор не реализован до конца
    multiple: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.addOuterClickListener()
  },
  computed: {
    valueModel: {
      get({ value, multiple }) {
        if (multiple) {
          return value[0]
        } else {
          return value
        }
      },
      set(v) {
        if (this.multiple) {
          this.$emit('input', [...this.value, v])
        } else {
          this.$emit('input', v)
        }
      }
    }
  },
  methods: {
    addOuterClickListener() {
      document.addEventListener('click', (el) => {
        if ( el.target.id !== `select-${this._uid}` ) {
          this.expanded = false
        }
      })
    },
    clear() {
      this.valueModel = undefined
    }
  },
}
</script>

<style lang="scss">
  .bh-select-wrapper {
    .label {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;

      padding-bottom: 5px;

      color: var(--primary-font-color)!important;
    }

    .input-wrapper {
      position: relative;

      .input {
        display: flex;
        align-items: center;

        width: 100%;

        min-height: 47px;
        line-height: 47px;
        background: var(--main-bg);
        border: 1px solid var(--main-bg);
        box-shadow: inset 0 4px 20px var(--main-bg);
        border-radius: 12px;
        padding: 0 30px;

        cursor: pointer;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 16px;

        .selected {
          pointer-events: none;
          color: var(--primary-font-color);
        }

        .placeholder {
          pointer-events: none;
          color: var(--secondary-font-color);
        }

        &.filled {
          &:hover {
            svg.chevron {
              display: none;
            }
            svg.clear {
              display: flex;
            }
          }
        }


        svg.chevron {
          position: absolute;
          top: 50%;
          right: 20px;

          display: block;

          transition: all .3s;
          transform: translateY(-50%) rotate(0);

          width: 10px;

          pointer-events: none;
        }

        svg.clear {
          display: none;

          position: absolute;
          top: 50%;
          right: 20px;

          transform: translateY(-50%);

          width: 10px;
        }
      }


      .options-list {
        transition: all .3s;

        position: absolute;
        top: 57px;
        left: 0;

        opacity: 0;
        pointer-events: none;

        width: 100%;
        max-height: 500px;

        color: var(--secondary-font-color);
        background: var(--secondary-color);

        border: 1px solid var(--border-color);
        border-radius: 12px;

        z-index: 5;

        padding: 30px;

        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 0; /* width of the entire scrollbar */
        }

        &::-webkit-scrollbar-track {
          background: transparent; /* color of the tracking area */
        }

        &::-webkit-scrollbar-thumb {
          background: transparent; /* color of the tracking area */
          width: 0;
        }

        .options-list-item {
          width: 100%;
          margin: 5px 0;

          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;

          padding: 15px;

          display: flex;
          align-items: center;
          justify-content: space-between;

          cursor: pointer;

          border: 1px solid var(--border-color);
          border-radius: 12px;

          color: var(--primary-font-color);

          &:hover {
            transition: all .3s;
            background: var(--bg-modal-color);
            color: var(--primary-font-color);
          }

          &.active {
            background: var(--bg-modal-color);
          }

          .info {
            width: -webkit-fill-available;
          }

          .action {
            width: fit-content;
            white-space: nowrap;

            svg.check {
              width: 13px;
            }
          }
        }

        &.flat {
          padding: 0;
          .options-list-item {
            box-shadow: none;
            border-radius: 0;
            margin: 0;
          }
        }

        &.empty {
          color: #8F949A;
        }
      }

      &.expanded {
        svg.chevron {
          transition: all .3s;

          transform: translateY(-50%) rotate(180deg);
        }

        .options-list {
          opacity: 1;
          pointer-events: visible;
        }
      }
    }

    @include below_phone() {
      .input-wrapper {
        .options-list {
          padding: 15px;
        }
      }
    }
  }
</style>