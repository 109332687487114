<template>
  <div class="orders-sort-by-network">
    <p class="orders-sort-by-network__title">
      {{ $t('orders.network') }}
    </p>
    <AtSelect
        v-if="hasNetworks"
        class="orders-sort-by-network__select"
        :placeholder="select_network"
        clearable
        :value="selectedNetworks[0]"
        @on-change="changeSelect"
    >
      <AtOption
          v-for="(network, key) in networks"
          :key="key"
          :value="network.alias"
      />
    </AtSelect>
  </div>
</template>

<script>

import {mapState, mapGetters, mapActions} from 'vuex';

import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';

export default {
    name: 'OrdersSortByNetwork',
    components: {
        AtSelect,
        AtOption,
    },
    data() {
        return {
            selectedNetworks: [],
        };
    },
    computed: {
        ...mapState('app', {
            networks: state => state.networks,
        }),
        ...mapGetters('app', [
            'hasNetworks',
        ]),
        select_network() {
            return this.$t('orders.select_network');
        },
    },
    beforeMount() {
        this.setNetworksFromUrl();
    },
    methods: {
        ...mapActions('orders', [
            'fetchOrders',
        ]),
        setNetworksFromUrl() {
            const networksFromUrl = this.$route.query.networks;

            if (!networksFromUrl) {
                return false;
            }

            this.selectedNetworks = networksFromUrl.split(',');
        },
        changeSelect(values) {
            const {
                $route,
                $router,
                fetchOrders,
            } = this;
            const val = values ? [values] : [];
            fetchOrders({
                route: $route,
                router: $router,
                networks: val,
                excludeParams: ['page'],
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.orders-sort-by-network {
  width: 100%;
  &__title {
    margin-bottom: 8px;
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__select ::v-deep .at-select__selection {
    min-height: 28px;
    border: 1px solid var(--border-color);
    background: var(--secondary-color);
    color: var(--primary-font-color);
  }

  &::v-deep .at-select__dropdown {
    border: 1px solid var(--border-color);
    background: var(--bg-select);
  }

  ::v-deep .at-select__option--selected {
    background: var(--hover-color-select);
  }

  ::v-deep .at-select__dropdown .at-select__option {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);

    &:hover {
      background: var(--hover-color-select);
    }
  }

  ::v-deep .at-select__selection {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  ::v-deep .at-select__placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }

  &__select ::v-deep .at-select__arrow {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  &__select ::v-deep .at-select__clear {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

}

</style>
