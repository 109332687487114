<template>
    <div class="headerTable">
        <orphan-search class="mobileHidden" :placeholder="$t('addresses.search_address')" v-model="search" />
        <SortByNetwork class="headerTable__filter-item mobileHidden" @has-selected-network="$emit('has-selected-network', $event)"/>
        <base-button
          class="headerTable__new-button"
          type="primary"
          icon="icon-plus"
          :disabled="!isAddAddressAllowed"
          @click="openNewAddressModal"
        >
            <template #prepend>
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.5 3.33325V12.6666" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3.83325 8H13.1666" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </template>
            <template #label>
                <span class="label">{{ $t('address_book.button') }}</span>
            </template>

        </base-button>
        <filter-mobile
          :is-filtered="!isEmptyFilters"
          class="filter"
          @reset="resetFilters"
        >
            <orphan-search :placeholder="$t('addresses.search_address')" v-model="search" />
            <SortByNetwork class="headerTable__filter-item" @has-selected-network="$emit('has-selected-network', $event)" @select-network-method="selectNetworks = $event"/>
        </filter-mobile>
    </div>
</template>

<script>
import AtButton from 'at-ui/src/components/button';
import SortByNetwork from "./SortByNetwork";
import SortByCurrencyFrom from "./SortByCurrencyFrom";
import {mapActions, mapGetters} from "vuex";
import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
import OrphanSearch from "@/public/components/elements/orphan-transactions/OrphanSearch.vue";
import FilterMobile from "@/public/components/elements/orphan-transactions/filter/index.vue";

export default {
    name: 'HeaderTable',

    components: {
        FilterMobile,
        OrphanSearch,
        BaseButton,
        AtButton,
        SortByNetwork,
        SortByCurrencyFrom,
    },
    data () {
      return {
          search: '',
          selectNetworks: () => {}
      }
    },
    props: {
      hasSelectedNetwork: {
        type: Boolean,
        default: false
      },
    },
    watch: {
      search (value) {
        this.$emit('search', value)
      }
    },
    created() {
      if (this.searchFromUrl) {
        this.search = this.searchFromUrl
      }
    },
    computed: {
        ...mapGetters('organizations', ['userRoleRules']),
        searchFromUrl({ $route }) {
          return $route.query.search
        },
        isAddAddressAllowed () {
            return this.userRoleRules['address_book'].entities.manage_address_book
        },
        isEmptyFilters({ search, hasSelectedNetwork }) {
          return !search && !hasSelectedNetwork
        }
    },
    methods: {
        ...mapActions('app', [
            'openModal',
        ]),

        openNewAddressModal() {
            const {openModal} = this;

            openModal({
                name: 'NewAddressModal',
            });
        },
        resetFilters() {
            this.search = ''
            this.selectNetworks('')

            this.$router.replace({
              query: {
                page: 1
              }
            }).catch(() => {})
        }
    },
};
</script>

<style scoped lang="scss">
.filter {
    display: none;
}
.headerTable {
  width: 100%;
  margin-bottom: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr 200px;
    align-items: center;
    grid-gap: 10px;

  &__new-button {
    height: 46px;
    max-width: 216px;
    width: 100%;
    background: #0059F7;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    transition: all 0.3s ease-in;
    border: none;
      @media (max-width: 560px) {
          max-width: 180px;
      }

    ::v-deep .at-btn__text {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
    }

    ::v-deep .at-btn__icon, .at-btn__loading {
      font-size: 24px;
    }

    &:hover {
      background: var(--blue-400);
    }
  }

  &__filter-item {
    width: 100%;
    //margin-bottom: 20px;


    &:last-child {
      margin-right: 0;
    }
  }
}
@media (max-width: 860px) {
    .mobileHidden {
        display: none;
    }
    .filter {
        display: inherit;
    }
    .headerTable {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }
    ::v-deep {
        .filter__content {
            min-width: 300px;
        }
        .currency-search-wrapper {
            width: 100%;
        }
    }
}
@media (max-width: 576px) {
        .headerTable {
            padding: 20px 20px 10px 20px;
        }
}
::v-deep .currency-network-icon-wrapper {
  position: relative;

  display: flex;

  .currency-network-icon__currency {
    width: 22px;
    height: 22px;

    border: none;

    position: unset;
    //
    //top: 0;
    //left: 0;
    //
    transform: translate(0, 0);
    @media screen and (max-width: 850px) {
      left: -8px;
    }
  }
}
</style>
