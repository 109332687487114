import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!, $limit: Int!, $offset: Int!, $status: [InvoiceStatus!], $accountId: String!, $from: Date, $to: Date, $id: String, $currencyId: String, $receivedCurrencyId: String, $receivedNetworkId: String,$sortDate: String) {
        invoicesCount(organizationId: $organizationId, status: $status, from: $from, to: $to, id: $id, currencyId: $currencyId, receivedCurrencyId: $receivedCurrencyId, receivedNetworkId: $receivedNetworkId)
        invoiceAdditionalFees(organizationId: $organizationId, accountId: $accountId) {
            name
            amount
        }
        invoices(organizationId: $organizationId, limit: $limit, offset: $offset, status: $status, from: $from, to: $to, id: $id, currencyId: $currencyId, receivedCurrencyId: $receivedCurrencyId, receivedNetworkId: $receivedNetworkId, sortDate: $sortDate) {
            id
            externalId
            accountId
            apiKeyId
            orderId
            status
            itemId
            itemDescription
            currencyId
            currencyName
            amount
            amountUSD
            receivedCurrency
            receivedCurrencyName
            receivedAmount
            receivedAmountUSD
            includeFee
            insurancePercent
            slippagePercent
            webhookURL
            returnURL
            applyAt
            processedAt
            expiresAt
            createdAt
            receivedNetworkId
            rate
            orderAmount 
        }
      
    }
`;
