import getBlockchainTransaction from "/public/graphQl/queries/getBlockchainTransaction";

export const fetchBlockChainTransaction = async ({dispatch, commit, rootGetters}, payload) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    let transactionData;

    try {
        commit('app/toggleLoading', null, {root: true});
        transactionData = await dispatch('app/request', {
            type: 'query',
            gql: getBlockchainTransaction,
            variables: {
                organizationId,
                ...payload,
            },
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (transactionData.message) {
        throw transactionData.message;
    }

    if (transactionData.errors) {
        throw transactionData.errors;
    }

    const {
        data: {getBlockchainTransaction: getTransaction},
    } = transactionData;

    return getTransaction;
};
