import gql from 'graphql-tag';

export default gql`
   query (    
    $userId: String!,
    $organizationId: String!,
    $limit: Int!,
    $offset: Int!
    ) {
  organizationAuthorizationHistory(
    userId: $userId
    organizationId: $organizationId
    limit: $limit
    offset: $offset
    ) {
        rows {
            userId
            userAgent
            connectingIp
            createdAt
        }
        count
    } 
}
`;
