import getCrosschainTransfersAmountLimitsGQL from "../../../../graphQl/queries/getCrosschainTransfersAmountLimits";

export const getCrosschainTransfersAmountLimits = async ({ dispatch, rootGetters }) => {
  let response;
  const organizationId = rootGetters['app/getSelectedOrganization'].id;

  try {
    response = await dispatch('app/request', {
      type: 'query',
      gql: getCrosschainTransfersAmountLimitsGQL,
      variables: {
        organizationId: organizationId,
      },
    }, {root: true});
  } catch (error) {
    return error;
  }

  return ( response.data && response.data.crosschainTransfersAmountLimits ) ? response.data.crosschainTransfersAmountLimits : {}
};
