import createCrosschainTransferMutation from "../../../../graphQl/mutations/crosschainTransfer";
import {v4 as UUID} from "uuid";

export const setCrosschainTransfer = async ({ dispatch, commit, rootGetters }, payload) => {
    let response;
    const id = UUID();
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const variables = {
        ...payload,
        organizationId,
        key: id,
    }
    try {
        response = await dispatch('app/request', {
            type: 'mutation',
            gql: createCrosschainTransferMutation,
            variables,
        }, {root: true});
    } catch (error) {
        return error;
    }

    return ( response.data && response.data.createCrosschainTransfer ) ? response.data.createCrosschainTransfer : {};
};
