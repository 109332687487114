<template>
    <div>
        <div class="preview-transaction" @click="handlePreviewClick" >
<!--            <RouterLink-->
<!--                    class="preview-transaction__link"-->
<!--                    :to="{ name: routeName, params: { id } }"-->
<!--            />-->
            <div :class="['preview-transaction__inner-row', !isActiveColBorder ? 'border-bottom' : '']">
                <template v-for="({title, active}) in columns">
                    <p class="preview-transaction__col " v-if="title === 'date_of_creation' && active">
                        {{ createdAt | getFormatDate('DD/MM/YY HH:mm') }}
                    </p>
                    <div class="preview-transaction__col" v-else-if="title === 'type' && active">
                        <svg v-if="data.type === 'deposit'" class="preview-transaction__col-typeIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="24" height="24" rx="8" fill="var(--new-front-status-processed)"/>
                            <path d="M13.2 8L10 12M10 12L13.2 16M10 12L18 12" stroke="var(--new-front-status-text-processed)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M11.179 5.64164C9.86021 6.00582 8.65599 6.83312 7.7931 8.05495C6.12731 10.4137 6.14269 13.6634 7.8307 15.9993C8.69313 17.1928 9.88396 17.9979 11.185 18.352" stroke="var(--new-front-status-text-processed)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <svg v-else width="24" class="preview-transaction__col-typeIcon" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="24" height="24" rx="8" fill="var(--new-front-status-error)"/>
                            <path d="M10.8 16L14 12M14 12L10.8 8M14 12L6 12" stroke="var(--new-front-status-text-error)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12.821 18.3584C14.1398 17.9942 15.344 17.1669 16.2069 15.9451C17.8727 13.5863 17.8573 10.3366 16.1693 8.0007C15.3069 6.80723 14.116 6.00206 12.815 5.64804" stroke="var(--new-front-status-text-error)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        {{ $t(`historyAddresses.${data.type}`) }}
                    </div>
                  <div class="preview-transaction__col" v-else-if="title === 'risk' && active">
                    <address-risks :status="riskLevel" />
                  </div>
                    <div class="preview-transaction__col preview-transaction__currency " v-else-if="title === 'coin' && active">
                        <template v-if="currencyTo">
                            <CurrencyImg
                                class="preview-transaction__currency-img"
                                :currency="currencyTo"
                            />
                            {{ getCurrencyInfoById(currencyTo).alias }}&ensp; <span class="secondary-text">({{ currencyTo | getCurrencyTicker }})</span>
                        </template>
                    </div>
                    <div class="preview-transaction__col preview-transaction__currency " v-else-if="title === 'network' && active">
                        <template v-if="currencyTo">
                            <NetworkIcon
                                class="preview-transaction__currency-img"
                                :network="getNetworkSystemName(networkId)"
                            />
                            {{ getNetworkInfoById(networkId).name | getNetworkFullNameByNetworkName }}&ensp; <span class="secondary-text">{{ getNetworkInfoById(networkId).name | getNetworkShortNameByNetworkName }}</span>
                        </template>
                    </div>
                    <template v-if="title === 'write-off_address' && active">
                      <div class="preview-transaction__col preview-transaction__id"
                           v-if="data.addressFrom && !isAddressFromTransferType"
                      >
                        <CopyString
                            v-if="data.type === 'deposit'"
                            :string="data.addressFrom"
                            :successMessage="$t('copy.addresses_copied')"
                            :failMessage="$t('copy.addresses_not_copied')"
                            :title="notification"
                            canCopy
                        />
                        <CopyString
                            v-else
                            :string="data.addressFrom"
                            :successMessage="$t('copy.addresses_copied')"
                            :failMessage="$t('copy.addresses_not_copied')"
                            :title="notification"
                            canCopy
                        />
                        <div class="secondary-text" v-if="data.addressBookAlias">{{ data.addressBookAlias | getPrettyUrl }}</div>
                      </div>
                      <div v-else class="preview-transaction__col">
                        -
                      </div>
                    </template>
                  <template v-if="title === 'transfer_address' && active">
                    <div class="preview-transaction__col preview-transaction__id"
                         v-if="data.addressTo && !isAddressToTransferType">
                      <CopyString
                          v-if="data.type === 'deposit'"
                          :string="data.addressTo"
                          :successMessage="$t('copy.addresses_copied')"
                          :failMessage="$t('copy.addresses_not_copied')"
                          :title="notification"
                          canCopy
                      />
                      <CopyString
                          v-else
                          :string="data.addressTo"
                          :successMessage="$t('copy.addresses_copied')"
                          :failMessage="$t('copy.addresses_not_copied')"
                          :title="notification"
                          canCopy
                      />
                      <div class="secondary-text" v-if="data.addressToBookAlias">{{ data.addressToBookAlias | getPrettyUrl }}</div>
                    </div>
                    <div v-else class="preview-transaction__col">
                      -
                    </div>
                  </template>
                    <template v-if="title === 'transaction' && active">
                        <div v-if="data.txId" class="preview-transaction__col txid">
                            <a class="link" :href="viewTxHash(networkId, data.txId)" target="_blank">{{ data.txId | preparedString }}</a>
                            <CopyString
                                :string="viewTxHash(networkId, data.txId)"
                                :successMessage="$t('copy.transaction_hash')"
                                :failMessage="$t('copy.transaction_hash_failed')"
                                :title="notification"
                                canCopy
                            />
                        </div>
                        <div v-else class="preview-transaction__col">
                            -
                        </div>
                    </template>
                    <p :class="['preview-transaction__col', data.type === 'deposit' ? 'preview-transaction__col_deposit' : 'preview-transaction__col_credit'  ]"
                       v-if="title === 'sum' && active">
                        {{ data.type === 'deposit' ? '+' : '-' }} {{ amount }}
                    </p>
                    <div class="preview-transaction__col" v-if="title === 'status' && active">
                        <div :class="['statusLabel', `statusLabel--${ data.status }`]">
                            {{ $t(status) }}
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div :class="['preview-transaction_mobile', isDetailsOpen ? 'preview-transaction_mobile--active' : '']">
            <div class="preview-transaction__preview" @click="openDetails">
                <div :class="['preview-transaction__info-status', `preview-transaction__info-status--${data.status}`]"></div>
                <div class="preview-transaction__currency" >
                    <!--                  <CurrencyImg-->
                    <!--                          class="preview-transaction__currency-img"-->
                    <!--                          :currency="currencyTo"-->
                    <!--                  />-->
                    <div class="preview-transaction__currency-container">

                        <p class="preview-transaction__preview-text">
                            <svg v-if="data.type === 'deposit'" class="preview-transaction__col-typeIcon" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" rx="8" fill="var(--new-front-status-processed)"/>
                                <path d="M13.2 8L10 12M10 12L13.2 16M10 12L18 12" stroke="var(--new-front-status-text-processed)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M11.179 5.64164C9.86021 6.00582 8.65599 6.83312 7.7931 8.05495C6.12731 10.4137 6.14269 13.6634 7.8307 15.9993C8.69313 17.1928 9.88396 17.9979 11.185 18.352" stroke="var(--new-front-status-text-processed)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <svg v-else  class="preview-transaction__col-typeIcon" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" rx="8" fill="var(--new-front-status-error)"/>
                                <path d="M10.8 16L14 12M14 12L10.8 8M14 12L6 12" stroke="var(--new-front-status-text-error)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12.821 18.3584C14.1398 17.9942 15.344 17.1669 16.2069 15.9451C17.8727 13.5863 17.8573 10.3366 16.1693 8.0007C15.3069 6.80723 14.116 6.00206 12.815 5.64804" stroke="var(--new-front-status-text-error)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            {{ $t(type) }}
                        </p>
                        <p class="preview-transaction__preview-text secondary-text"> {{ createdAt | getFormatDate('DD/MM/YY HH:mm') }}</p>
                    </div>

                </div>

                <div class="preview-transaction__amount-container">
                    <p :class="['preview-transaction__preview-text', data.type === 'deposit' ? 'preview-transaction__col_deposit' : 'preview-transaction__col_credit']">
                        {{ data.type === 'deposit' ? '+' : '-' }}{{ amount }}
                    </p>
                    <span class="preview-transaction__preview-text">{{ currencyTo | getCurrencyTicker }}<template v-if="networkId">&nbsp;<span class="secondary-text">{{ getNetworkInfoById(networkId).name | getNetworkShortNameByNetworkName }}</span></template></span>
                </div>
                <svg @click="openDetails" :class="['chevron', isDetailsOpen ? 'chevron--active' : '']" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.712375 1.635C0.569375 1.462 0.484375 1.241 0.484375 1C0.484375 0.448 0.932375 0 1.48438 0H7.48438C8.03638 0 8.48438 0.448 8.48438 1C8.48438 1.241 8.39937 1.462 8.25637 1.635L5.29238 5.589C5.11037 5.838 4.81637 6 4.48438 6C4.15237 6 3.85837 5.838 3.67637 5.589L0.712375 1.635Z" fill="var(--new-front-primary-font-color)"/>
                </svg>
            </div>
            <div class="preview-transaction__details">
                <div class="preview-transaction__details-item">
                    <p class="preview-transaction__preview-text">
                        {{ $t('historyAddresses.write-off_address') }}:
                    </p>
                    <div class="preview-transaction__preview-text column">

                        <CopyString
                            :string="data.addressFrom"
                            :successMessage="$t('copy.addresses_copied')"
                            :failMessage="$t('copy.addresses_not_copied')"
                            :title="notification"
                            canCopy
                        />
                      <div class="secondary-text" v-if="data.addressBookAlias">{{ data.addressBookAlias | getPrettyUrl  }}</div>
                    </div>
                </div>
                <div class="preview-transaction__details-item">
                    <p class="preview-transaction__preview-text">
                        {{ $t('historyAddresses.transfer_address') }}:
                    </p>
                    <div class="preview-transaction__preview-text column">
                        <CopyString
                            :string="data.addressTo"
                            :successMessage="$t('copy.addresses_copied')"
                            :failMessage="$t('copy.addresses_not_copied')"
                            :title="notification"
                            canCopy
                        />
                      <div class="secondary-text" v-if="data.addressToBookAlias">{{ data.addressToBookAlias | getPrettyUrl }}</div>
                    </div>
                </div>
              <div class="preview-transaction__details-item">
                <p class="preview-transaction__preview-text">
                  {{ $t('addressRisks.placeholder') }}:
                </p>
                <div class="preview-transaction__preview-text column">
                  <address-risks :status="riskLevel" />
                </div>
              </div>
                <div class="preview-transaction__details-item">
                    <p class="preview-transaction__preview-text">
                        TX_hash:
                    </p>
                    <p v-if="data.txId" class="preview-transaction__preview-text txid">
                        <a class="link" :href="viewTxHash(networkId, data.txId)" target="_blank">{{ data.txId | preparedString }}</a>
                        <CopyString
                            :string="viewTxHash(networkId, data.txId)"
                            :successMessage="$t('copy.transaction_hash')"
                            :failMessage="$t('copy.transaction_hash_failed')"
                            :title="notification"
                            canCopy
                        />
                    </p>
                    <p v-else class="preview-transaction__preview-text">-</p>
                </div>
                <div class="preview-transaction__details-item">
                    <p class="preview-transaction__preview-text">
                        {{ $t('historyAddresses.status') }}
                    </p>
                    <div class="preview-transaction__preview-text">
                        <div :class="['statusLabel', `statusLabel--${ data.status }`]">
                            {{ $t(status) }}
                        </div>
                    </div>
                </div>
                <base-button @click="handlePreviewClick" class="preview-transaction__linkBtn" :label="$t('orphan.info-btn')" type="primary" />
            </div>
        </div>
    </div>
</template>

<script>

import {mapActions, mapState} from 'vuex';
import AtCollapse from 'at-ui/src/components/collapse/';
import AtCollapseItem from 'at-ui/src/components/collapse-item/';
import CopyString from "/public/components/elements/CopyString";
import CurrencyImg from "/public/components/elements/CurrencyImg";
import {
    getCurrencyInfoById, getNetworkInfoById, getNetworkShortNameByNetworkName, getNetworkSystemName,
    preparedString,
    transactionHistoryBasis,
    transactionHistorySource,
    transactionHistoryType,
} from "../../../filters";
import {TRANSACTION_HISTORY_STATUS_ENUM} from "../../../../common/constants/transaction-history-status-enum";
import BigNumber from "bignumber.js";
import NetworkImg from "@/public/components/common/uiKit/networkImg/index.vue";
import NetworkIcon from "@/public/components/common/uiKit/NetworkIcon.vue";
    import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
    import {BlockchainLinks} from "@/public/mixins/blockchain-links";
import AddressRisks from "@/public/components/elements/address-risks/AddressRisks.vue";

export default {
    name: 'PreviewTransaction',
    components: {
      AddressRisks,
        BaseButton,
        NetworkIcon,
        NetworkImg,
        CopyString,
        CurrencyImg,
        AtCollapse,
        AtCollapseItem
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        routeName: {
            type: String,
            default: 'transaction',
        },
    },
    data () {
      return {
          isDetailsOpen: false,
      }
    },
    mixins: [
        BlockchainLinks,
    ],
    methods: {
        getNetworkShortNameByNetworkName,
        getNetworkInfoById,
        getNetworkSystemName,
        getCurrencyInfoById,
        preparedString,
        ...mapActions('historyAddresses', ['getCurrentAddress']),
        handlePreviewClick() {
            this.getCurrentAddress({ id: this.id })
        },
        openDetails () {
          this.isDetailsOpen = !this.isDetailsOpen
        },
        stringAddress(string) {
            if (!string) {
                return '-'
            }
            return `${string.substr(0, 8)}****${string.substr(-6)}`;
        },

        transactionHistoryStatus(status) {
            const statuses = {
                [TRANSACTION_HISTORY_STATUS_ENUM.INIT]: 'orders.init',
                [TRANSACTION_HISTORY_STATUS_ENUM.ERROR]: 'orders.error',
                [TRANSACTION_HISTORY_STATUS_ENUM.PENDING]: 'orders.pending',
                [TRANSACTION_HISTORY_STATUS_ENUM.PROCESSED]: 'orders.processed',
                [TRANSACTION_HISTORY_STATUS_ENUM.REJECTED]: 'orders.rejected',
            };

            return statuses[status] || 'transactions.empty';
        },
        viewTxHash(networkId, hash) {
            const networkName = getNetworkInfoById(networkId).name;
            if (networkName === 'bsc') {
                return `https://bscscan.com/tx/${hash}`;
            } else if (networkName === 'bsc-testnet') {
                return `https://testnet.bscscan.com/tx/${hash}`;
            } else if (networkName === 'fantom') {
                return `https://ftmscan.com/tx/${hash}`;
            } else if (networkName === 'ethereum') {
                return `https://etherscan.io/tx/${hash}`;
            } else if (networkName === 'sepolia') {
                return `https://sepolia.etherscan.io/tx/${hash}`;
            } else if (networkName === 'tron') {
                return `https://tronscan.org/#/transaction/${hash}`;
            } else if (networkName === 'solana') {
                return `https://solscan.io/tx/${hash}`;
            } else if (networkName === 'bitcoin') {
                return `https://blockchair.com/bitcoin/transaction/${hash}`;
            } else if (networkName === 'litecoin') {
                return `https://blockchair.com/litecoin/transaction/${hash}`;
            } else if (networkName === 'bitcoincash') {
                return `https://blockchair.com/bitcoin-cash/transaction/${hash}`;
            } else if (networkName === 'polygon') {
              return `https://polygonscan.com/tx/${hash}`;
            } else if (networkName === 'ton') {
              return `https://tonscan.org/tx/${hash}`;
            }
        }
    },

    computed: {
        ...mapState('app', [
            'currencies',
        ]),
        ...mapState('historyAddresses', ['columns']),
      riskLevel () {
          if (!this.data.risks) { return null }
          return this.data.risks.level
      },
        activeColumnsCount () {
            return this.columns.filter(({active}) => active).length
        },
        isActiveColBorder () {
            return this.activeColumnsCount > 4
        },
        id() {
            return this.data.id;
        },
        type({ data }) {
            return this.$t(`transactions.basis_enum.${ data.type }`);
        },
        status() {
            return this.transactionHistoryStatus(this.data.status);
        },
        basis() {
            return transactionHistoryBasis(this.data.basis);
        },
        source() {
            return transactionHistorySource(this.data.side);
        },
        createdAt() {
            return this.data.createdAt;
        },

        amount() {
            return new BigNumber(this.data.amount).toNumber();
        },

        networkId() {
            return this.data.networkId;
        },
        isAddressToTransferType() {
            const ADDRESS_TYPE = {
                PAY_IN: 'transactions.transfer_type.pay_in',
                PAY_OUT: 'transactions.transfer_type.pay_out',
                ADVANCE: 'transactions.transfer_type.advance',
            };
            return ADDRESS_TYPE[this.data.addressTo] || false;
        },
        isAddressFromTransferType() {
            const ADDRESS_TYPE = {
                PAY_IN: 'transactions.transfer_type.pay_in',
                PAY_OUT: 'transactions.transfer_type.pay_out',
                ADVANCE: 'transactions.transfer_type.advance',
            };
            return ADDRESS_TYPE[this.data.addressFrom] || false;
        },
        currencyFrom() {
            const {
                data: {
                    currencyFrom,
                },
            } = this;

            return currencyFrom;
        },
        currencyTo() {
            const {
                data: {
                    currencyId,
                },
            } = this;

            return currencyId;
        },
        mobileStatusTextColor() {
            switch (this.data.status) {
            case TRANSACTION_HISTORY_STATUS_ENUM.INIT:
                return '#6190e8';
            case TRANSACTION_HISTORY_STATUS_ENUM.PROCESSED:
                return '#13ce66';
            case TRANSACTION_HISTORY_STATUS_ENUM.PENDING:
                return '#F2F2F2';
            case TRANSACTION_HISTORY_STATUS_ENUM.ERROR:
                return '#EB5757';
            case TRANSACTION_HISTORY_STATUS_ENUM.REJECTED:
                return '#EB5757';
            default:
                return '#8F949A';
            }
        },
        hasCurrencyFrom() {
            return Boolean(this.currencyFrom);
        },
        hasCurrencyTo() {
            return Boolean(this.currencyTo);
        },
        orderId() {
            return this.data.orderId;
        },
        hasOrderId() {
            return Boolean(this.orderId);
        },
        orderCreatedAt() {
            return this.data.orderCreatedAt;
        },
        credit() {
            if (this.data.credit) {
                const credit = new BigNumber(this.data.credit);
                return credit.decimalPlaces(8).toNumber();
            }
            return null;
        },
        deposit() {
            if (this.data.deposit) {
                const credit = new BigNumber(this.data.deposit);
                return credit.decimalPlaces(8).toNumber();
            }
            return null;
        },
        id_copied() {
            return this.$t('copy.id_copied');
        },
        id_not_copied() {
            return this.$t('copy.id_not_copied');
        },
        notification() {
            return this.$t('copy.notification');
        },
        addressToTransfer() {
            return this.$t(this.$options.filters.transferAddressType(this.data.addressTo));
        },
        addressFromTransfer() {
            return this.$t(this.$options.filters.transferAddressType(this.data.addressFrom));
        }
    }
};

</script>

<style lang="scss" scoped>
.border-bottom {
    border-bottom: 1px solid var(--new-front-divider-color);
    .preview-transaction__col {
        border-bottom: 0;
    }
}
.txid {
  display: flex;
  ::v-deep .copy-string__string {
    display: none;
  }
}
::v-deep .copy-string__string {
    @media screen and (max-width: 576px) {
        font-size: 13px !important;
    }
}
.statusLabel {
    padding: 4px $p15;
    background: var(--new-front-status-processed);;
    color: var(--new-front-status-text-processed);
    border-radius: 10px;
    font-size: $h4;
    font-weight: 500;
    min-width: 130px;
    text-align: center;
    &--error {
        background: var(--new-front-status-error);
        color: var(--new-front-status-text-error);
    }
    &--pending {
        background: var(--new-front-status-pending);
        color: var(--new-front-status-text-pending);
    }
    &--processed {
        background: var(--new-front-status-processed);
        color: var(--new-front-status-text-processed);
    }
    &--rejected {
        background: var(--new-front-status-error);
        color: var(--new-front-status-text-error);
    }
}
.chevron {
    position: absolute;
    right: 20px;
    transition: transform .2s ease;
    &--active {
        transform: rotate(180deg);
    }
}
.preview-transaction {
  padding: 0;
  position: relative;
  color: var(--primary-font-color);
    cursor: pointer;
    &__linkBtn {
        margin: 15px 0;
    }
    &__details {
        padding: 0 20px;
        &-item {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid var(--new-front-divider-color);
            padding: 15px 0;
        }
    }
    &__preview-text {
        font-size: $h6;
        font-weight: 500;
        font-family: var(--new-front-font-family);
        flex-wrap: nowrap;
        white-space: nowrap;
        line-height: var(--line-height);
        color: var(--new-front-primary-font-color);
        display: flex;
        align-items: center;
        @media screen and (max-width: 576px) {
            font-size: 13px;
        }
    }
  &_mobile {
    display: none;
  }

  &__coin {
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--primary-font-color);
    margin-bottom: 2px;
  }

  &__date {
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #8F949A;
  }

  //&:hover {
  //
  //  & .preview-transaction__col {
  //    background-color: var(--main-page-line);
  //  }
  //
  //}

  &__inner-row {
    display: grid;
    grid-template-columns: repeat(10,25%);
    width: 100%;
    min-height: 55px;
    align-items: center;
  }

  &__write_off_currency {
    text-align: start;

  }

  &__col {
      font-size: $h4;
      font-weight: 500;
      font-family: $Gilroy;
      line-height: var(--line-height);
      color: var(--new-front-primary-font-color);
      text-align: start;
      min-height: 85px;
      display: flex;
      align-items: center;
      word-break: break-word;
      border-bottom: 1px solid var(--new-front-divider-color);
&-typeIcon {
    margin-right: 5px;
}
    &_deposit {
      color: var(--green-200);
    }

    &_credit {
      color: var(--red-200);
    }

    &_status {
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px 10px;
      background: var(--colorStatus);
      border-radius: 8px;
      font-family: $Gilroy;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      width: 93px;
      margin-right: -30px;

      &.pending {
        color: #2C405A;
      }
    }

    &:first-child {
      padding-left: 25px;
    }

    &:last-child {
      padding-right: 25px;
      justify-content: flex-start;
    }
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 150%;
    height: 100%;
    z-index: 0;
  }

  &__id {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
  }

  &__currency {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
  }

  &__currency_write-off {
    align-items: center;
  }

  &__currency-img {
    margin-right: 6px;
    width: 24px;
    height: 24px;
  }

  &__currency-container {
    display: flex;
    flex-direction: column;
      gap: 10px;

    width: 100%;
  }
    &__amount-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;

        width: 100%;
    }
}

@media screen and (max-width: 1330px) {
  .preview-transaction {
    &__col {
      &:first-child {
        padding-left: 15px;
      }
    }
  }

}

@media screen and (max-width: 576px) {
  .preview-transaction {
    display: none;

    &__col_status {
      margin: 0;
    }

    &__title-text {
      font-size: var(--font-size-text-main);
      font-family: var(--font-family);
      line-height: var(--line-height);
      color: var(--primary-font-color);
      text-align: right;

      &_secondary {
        color: var(--primary-font-color);
      }
    }

    &__row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 40px;
      border-top: 1px solid var(--border-color);

      &:last-of-type {
        border-bottom: none;
      }

      ::v-deep .copy-string__string {
        font-family: $Gilroy;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        color: var(--primary-font-color);
      }
    }

    &__item-value {
      margin-right: 10px;
    }

    &__currency {
      &-status {
        font-family: $Gilroy;
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size-text-caption);
        line-height: var(--line-height);
        color: var(--colorStatus);
      }

      &-status-mobile {
        font-family: $Gilroy;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        align-items: center;
        display: flex;
        color: var(--primary-font-color);
      }

      &-text {
        font-size: var(--font-size-text-main);
        font-family: var(--font-family);
        line-height: var(--line-height);
        color: var(--primary-font-color);
      }
    }

    &_mobile {
      display: block;
    }

    &__preview {
      display: flex;
      justify-content: space-between;
      flex: 1;
      padding-right: 40px;
        padding-left: 20px;
        padding-top: 15px;
      align-items: center;
    }

    &__read-more {
      width: 100%;
      max-width: 300px;
      border-radius: 12px;
      background: #0059F7;
      font-size: var(--font-size-text-main);
      font-family: var(--font-family);
      line-height: var(--line-height);
      text-align: center;
      color: var(--white-100);
      min-height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 25px;
      margin-bottom: 20px;
    }

    &__detailed-info {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

      &_mobile {
          display: block;
          max-height: 78px;
          //margin-bottom: 20px;
          overflow: hidden;
          transition: max-height .3s ease;
          border-bottom: 1px solid var(--new-front-divider-color);
          position: relative;
          &--active {
              max-height: 350px;
          }
      }
  }
}

.link {
    color: var(--new-front-purple);
    text-decoration: underline;
}
.secondary-text {
    color: var(--new-front-secondary-font-color)
}
.preview-transaction__info-status {
    width: 3px;
    height: 55px;
    border-radius: 13px;
    position: absolute;
    left: 6px;
    padding: 0;
    &--init {
        background: var(--new-front-status-text-active);
    }
    &--expired {
        background: var(--new-front-status-text-expired);
    }
    &--processed {
        background: var(--new-front-status-text-processed);
    }
    &--pending {
        background: var(--new-front-status-text-pending);
    }
    &--partial {
        background: var(--new-front-status-text-pending);
    }
    &--error {
        background: var(--new-front-status-text-error);
    }
    &--rejected {
        background: var(--new-front-status-text-error);
    }
}
.column {
  display: flex;
  flex-direction: column;
}
</style>
