<template>
  <StoreModal
      class="new-invoice-modal"
      :value="isOpen"
      :show-head="!isLoading"
      :show-footer="!isLoading"
      :title="title"
      :show-close="true"
      name="NewOrderModal" @closeModal="handleCancelCreate"
  >
      <template v-if="!isLoading" #header>
          <div class="new-invoice-modal__header">
              <div class="title">
                  {{ title }}
              </div>
              <!--            <div v-if="notCreated" class="subtitle">-->
              <!--                {{ subtitle }}-->
              <!--            </div>-->
          </div>
      </template>
      <template #body>
          <div class="new-invoice-modal__content">
              <template v-if="!isLoading">
                  <template v-if="!hasOrder">
                      <div class="new-invoice-modal__row ">
                          <base-input
                              v-model="merchantOrder"
                              :maxlength="255"
                              :label="$t('create-invoice-modal.invoice')"
                              :placeholder="invoice_placeholder"
                              size="normal"
                          />
                      </div>
                      <div class="new-invoice-modal__row ">
                          <base-textarea
                              v-model="merchantDescription"
                              class="textarea"
                              :placeholder="description_of_order"
                              :label="$t('create-invoice-modal.description')"
                              :maxlength="255"
                              size="large"
                              resize="none"
                          />
                      </div>
                      <div class="new-invoice-modal__currency-container">
                          <div class="new-invoice-modal__row ">
                              <div class="new-invoice-modal__select-container new-invoice-modal__select-container_img">
                                  <business-wallet-network-select
                                      v-model="currencyFrom"
                                      :list="currenciesForFrom"
                                      :placeholder="$t('invoice.select_invoice_currency')"
                                      :label="$t('create-invoice-modal.currency')"
                                      clearable
                                      with-search
                                      uniq-key="id"
                                  />
                              </div>
                          </div>
                          <div class="new-invoice-modal__row new-invoice-modal__row_amount">
                              <div class="new-invoice-modal__amount">
                                  <base-max-input
                                      v-model="amount"
                                      :min="0"
                                      :label="$t('create-invoice-modal.amount')"
                                      :error="amountStatus"
                                      size="normal"
                                      type="number"
                                      placeholder="0"
                                  >
                                      <template #label-left>
                                          {{ $t('create-invoice-modal.amount') }}
                                      </template>
                                  </base-max-input>
                              </div>
                          </div>
                      </div>
                      <div class="new-invoice-modal__currency-container new-invoice-modal__row">
                          <invoices-multi-currency-select v-model="allowedCurrencies"  />
                      </div>
                      <div class="new-invoice-modal__currency-container">
                          <div class="new-invoice-modal__row new-invoice-modal__row_amount">
                              <div class="new-invoice-modal__amount">
                                  <base-max-input
                                      v-model="exchangeRate"
                                      :min="0"
                                      :error="exchangeRateStatus"
                                      :label="$t('create-invoice-modal.exchange_rate_percent')"
                                      size="normal"
                                      type="number"
                                      placeholder="0"
                                  >
                                      <template #label-left>
                                          {{ $t('create-invoice-modal.exchange_rate_percent') }}
                                      </template>
                                  </base-max-input>
                              </div>
                          </div>
                          <div class="new-invoice-modal__row new-invoice-modal__row_amount">
                              <div class="new-invoice-modal__amount">
                                  <base-max-input
                                      v-model="allowablePrice"
                                      :min="0"
                                      :has-action-btn="false"
                                      :error="allowablePriceStatus"
                                      :label="$t('create-invoice-modal.allowable_price')"
                                      size="normal"
                                      type="number"
                                      placeholder="0"
                                  >
                                      <template #label-left>
                                          {{ $t('create-invoice-modal.allowable_price') }}
                                      </template>
                                  </base-max-input>
                              </div>
                          </div>
                      </div>
                      <div class="new-invoice-modal__currency-container">
                          <base-checkbox v-model="commission" :label="$t('create-invoice-modal.checkbox_label')" />
                      </div>
                      <div class="new-invoice-modal__fees">
                          <base-checkbox v-for="item in additionalFees" :label="getCheckboxLabel(item)" @input="handleFeeInput(item.name)" />
                      </div>
                      <div
                          v-if="hasErrors"
                          class="new-invoice-modal__row"
                      >
                          <AtAlert
                              v-for="(error, key) in errors"
                              :key="key"
                              type="error"
                              :message="error"
                          />
                      </div>
                  </template>
                  <PreviewOrderInModal
                      v-else
                      class="new-invoice-modal__preview"
                      :data="order"
                  />
              </template>
            <div  v-else class="modal-spinner">
              <LoadingSpinner
                  class="new-invoice-modal__spinner"
                  :title="creating_order"
              />
            </div>

          </div>
      </template>

    <template v-if="!isLoading" #footer>
      <div v-if="!hasOrder" class="new-invoice-modal__footer">
          <base-button  type="secondary" :label="$t('create-invoice-modal.close')" @click="handleCancelCreate" />
          <base-button  :disabled="actionButtonDisabled" type="primary" :label="$t('create-invoice-modal.create')" @click="handleCreateOrder" />
      </div>
        <base-button v-else style="width: 100% !important;" :label="$t('create-invoice-modal.close')" type="primary" @click="handleCancelCreate" />
    </template>


  </StoreModal>
</template>

<script>

import 'vue2-datepicker/index.css';

import Moment from "moment";

// Moment.locale('ru');
import {mapState, mapGetters, mapActions} from 'vuex';

import AtModal from 'at-ui/src/components/modal';
import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';
import AtInput from 'at-ui/src/components/input';
import Icon from "/public/components/elements/Icon";
import AtButton from 'at-ui/src/components/button';
import DatePicker from 'vue2-datepicker';
import LoadingSpinner from "/public/components/elements/LoadingSpinner";
import PreviewOrderInModal from "/public/components/elements/orders/PreviewOrderInModal";
import AtAlert from 'at-ui/src/components/alert';
import isURL from 'validator/lib/isURL';
import BaseModal from "../BaseModal";
import {OrderController} from "../../../store/modules/orders/controller";
import {getErrorMessage, getNetworkName} from "../../../filters";
import {BlockchainLinks} from "../../../mixins/blockchain-links";
import CurrencyImg from "../../elements/CurrencyImg";
import BigNumber from "bignumber.js";
import _ from "lodash";
import moment from "moment";
import {invoiceController} from "../../../store/modules/invoices/controller";
import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
import BaseInput from "@/public/components/common/uiKit/input/base/index.vue";
import BaseTextarea from "@/public/components/common/uiKit/textarea/index.vue";
import BusinessWalletNetworkSelect
    from "@/public/components/elements/business-walet/Select/businessWalletNetworkSelect.vue";
import BaseCheckbox from "@/public/components/common/uiKit/Checkbox/index.vue";
import InvoicesMultiCurrencySelect from "@/public/components/elements/bills/InvoicesMultiCurrencySelect.vue";

export default {
    name: 'NewOrderModal',
    mixins: [
        BlockchainLinks,
    ],
    components: {
        InvoicesMultiCurrencySelect,
        BaseCheckbox,
        BusinessWalletNetworkSelect,
        BaseTextarea,
        BaseInput,
        BaseButton,
        CurrencyImg,
        BaseModal,
        AtModal,
        AtSelect,
        AtOption,
        AtInput,
        Icon,
        DatePicker,
        AtButton,
        LoadingSpinner,
        PreviewOrderInModal,
        AtAlert,
        BaseMaxInput: () => import(/* webpackChunkName: "/public/components/elements/advance-balances/input" */ '/public/components/elements/advance-balances/input'),
        StoreModal: () => import(/* webpackChunkName: "/public/components/modals/StoreModal" */ '/public/components/modals/StoreModal'),
    },
    data() {
        return {
            isLoading: false,
            invoice: null,
            currencyFrom: null,
            networkFrom: '',
            amount: null,
            exchangeRate: null,
            allowablePrice: null,
            merchantOrder: null,
            merchantDescription: null,
            errors: [],
            additionalFeesArray: [],
            lifetime: null,
            commission: false,
            allowedCurrencies: []
        };
    },
    computed: {
        ...mapState('app', [
            'currencies',
        ]),
        ...mapState('orders', [
            'ordersCountOnPage',
        ]),
        ...mapState('invoices', [
            'additionalFees',
        ]),
        ...mapState('accounts', {
            accounts: state => state.accounts,
        }),
        ...mapGetters('app', [
            'sortedCurrencies',
            'isOpenModal',
        ]),
        // availableCurrencies () {
        //     const currenciesArr = [];
        //     this.currencies.forEach(({ id, networks }) => {
        //         networks.forEach(item => {
        //             currenciesArr.push([id, item.id]);
        //         });
        //     });
        //     return  currenciesArr;
        // },
        selectedCurrencyId () {
            return this.currencies.find(({ currency }) => currency === this.currencyFrom.currency).id;
        },
        step() {
            const {minValue} = this;
            // const step = minValue.isGreaterThan(0.5) ? 1 : minValue.toFixed(2);
            return 1;
        },
        percentage() {
            const {minValue, maxValue, lifetime} = this;
            const percentage = (lifetime - minValue) / (maxValue - minValue) * 100;
            return percentage;
        },
        isOpen() {

            const {
                $options: {
                    name,
                },
                isOpenModal,
            } = this;

            return isOpenModal(name);
        },
        hideFooter() {
            const {
                isLoading,
                order,
            } = this;

            return isLoading || order;
        },
        hasOrder() {
            return Boolean(this.order);
        },

        accountId() {
            return this.accounts?.[0]?.accountId;
        },
        title() {
            const {hasOrder, order} = this;
            return hasOrder ? `${this.$t(`create-invoice-modal.invoice`)} ${order.id}` : this.$t(`create-invoice-modal.new_invoice`);
        },
        currenciesForFrom() {
            return this.sortedCurrencies.filter(currency => currency.allowInvoice);
        },
        amountStatus() {
            const {amount} = this;
            if (amount === null || amount === '') {
                return '';
            }
            return (amount <= 0) ? this.$t('create-invoice-modal.invalid-number') : '';
        },
        exchangeRateStatus() {
            const {exchangeRate} = this;
            if (exchangeRate === null || exchangeRate === '') {
                return '';
            }
            return (exchangeRate <= 0) ? this.$t('create-invoice-modal.invalid-number') : '';
        },
        allowablePriceStatus() {
            const {allowablePrice} = this;
            if (allowablePrice === null || allowablePrice === '') {
                return '';
            }
            return (!Number(allowablePrice) || allowablePrice <= 0  ) ? this.$t('create-invoice-modal.invalid-number') : '';
        },
        hasErrors() {
            return Boolean(this.errors.length);
        },
        actionButtonDisabled() {
            if (!this.currencyFrom) {
                return true;
            }
            if (!this.amount) {
                return true;
            }
            if (!this.exchangeRate) {
                return true;
            }
            if (!this.allowablePrice) {
                return true;
            }
            if( !this.allowedCurrencies.length) {
                return true
            }
            return false;
        },
        invoice_placeholder() {
            return this.$t('create-invoice-modal.invoice_placeholder');
        },
        description_of_order() {
            return this.$t('create-invoice-modal.description_of_order');
        },
        creating_order() {
            return this.$t('create-invoice-modal.creating_order');
        },

    },
    methods: {
        handleFeeInput (name) {
            if (this.additionalFeesArray.some(item => item === name)) {
                this.additionalFeesArray = this.additionalFeesArray.filter(item => name !== item)
                return
            }
            this.additionalFeesArray.push(name)
        },
        minMaxValue(date) {
            const dateFormated = moment(date).format('HH:mm');
            let seconds = moment.duration(dateFormated).asSeconds();
            return (seconds > this.selectedNetworkObject.maxFreezeTime || seconds < this.selectedNetworkObject.minFreezeTime);

        },
        getCheckboxValue (name) {
          return this.additionalFeesArray.some(item => item === name)
        },
        getCheckboxLabel ({name, amount}) {
            const percent = BigNumber(amount).multipliedBy(100)

            switch (name) {

                case 'SEPA_WITHDRAWAL':

                    return `${this.$t('invoice.sepa-withdrawal')} ${percent}%`

                case 'SEPA_DEPOSIT':

                    return `${this.$t('invoice.sepa-deposit')} ${percent}%`

                case 'FIAT_CRYPTO_EXCHANGE':

                    return `${this.$t('invoice.fiat-exchange')}  ${percent}%`

            }
        },
        ...mapActions('app', [
            'closeModal',
        ]),
        ...mapActions('orders', [
            'createOrder',
            'fetchOrders',
        ]),
        checkUrl(url) {
            return isURL(`${url}`, {
                protocols: ['http', 'https'],
                require_protocol: true,
                host_blacklist: ['localhost', '127.0.0.1'],
            });
        },
        disabledDateForExpiresAt(date) {
            const today = Moment().add(-1, 'days').valueOf();
            return date <= today;
        },
        clearAllData() {
            this.isLoading = false;
            this.order = null;
            this.currencyFrom = '';
            this.networkFrom = '';
            this.amount = null;
            this.errorWebhook = null;
            this.successWebhook = null;
            this.returnUrl = null;
            this.merchantOrder = null;
            this.allowablePrice = null;
            this.exchangeRate = null;
            this.merchantDescription = null;
            this.additionalFeesArray = [];
            this.commission = false;
            this.expiresAt = Moment().add(1, 'days').valueOf();
            this.errors = [];
            this.allowedCurrencies = []
        },
        handleCreateOrder() {
            const {
                currencyFrom,
                accountId,
                amount,
                allowablePrice,
                exchangeRate,
                setErrors,
                merchantOrder,
                merchantDescription,
                commission,
                availableCurrencies,
                selectedCurrencyId,
            } = this;
            const urlPage = this.$route.query.page;
            const currentPage = urlPage ? Number(urlPage) : 1;

            if (!currencyFrom || !amount || !allowablePrice || !exchangeRate) {
                return false;
            }
            this.isLoading = true;
            invoiceController.createInvoice({
                accountId,
                currencyId: selectedCurrencyId,
                amount,
                itemId: merchantOrder,
                itemDescription: merchantDescription,
                includeFee: commission,
                insurancePercent: exchangeRate,
                slippagePercent: allowablePrice,
                currencies: this.allowedCurrencies,
                additionalFees: this.additionalFeesArray
            })
                .then(invoice => {
                    if (invoice?.code === 0) {
                        this.invoice = invoice.result;
                        this.$Message.success({
                            message: '',
                            duration: 10000,
                        });
                        this.$emit('openInvoice', invoice.result.id)
                        this.$emit('updateInvoices')
                        // this.$router.push({ name: 'invoice', params: { id: invoice.result.id } });
                        this.closeModal();
                    } else {
                        this.$Message.error({
                            message: this.$t(getErrorMessage(invoice.code)),
                            duration: 10000,
                        });
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        handleCancelCreate() {
            const {
                clearAllData,
                closeModal,
                ordersCountOnPage,
                currentPage,
                fetchOrders,
            } = this;
            if (this.order) {
                fetchOrders({
                    route: this.$route,
                    router: this.$router,
                    limit: ordersCountOnPage,
                    offset: currentPage,
                });
            }
            closeModal();

            setTimeout(() => {
                clearAllData();
            }, 500);
        },
        viewContract() {
            window.open(this.contractLink);
        },
    },
    watch: {
        isOpen(status) {

            if (!status) {
                this.clearAllData();
                return;
            }
            if (this.currenciesForFrom.length) {
                this.currencyFrom = this.currenciesForFrom[0];

            }
        },
    },
};

</script>

<style lang="scss">
.new-invoice-modal {
    ::v-deep textarea {
        &::placeholder {
            font-family: var(--font-family);
            font-size: var(--font-size-text-main);
            line-height: var(--line-height);
            overflow: visible;
        }
    }
  &__fees {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-top: 1px solid var(--new-front-divider-color);
    padding-top: 20px;
    margin-top: 20px;
    label {
      padding-left: 20px;
    }
  }
    &__header {
        text-align: center;
        .title {
            font-size: $h1;
            font-weight: 500;
            color: var(--new-front-primary-font-color);
            @media (max-width: 450px) {
                font-size: $h2;
            }
        }
    }
    &__content {
        padding: 0 $p30;
        @media (max-width: 450px) {
            padding: 0 $p10;
        }
    }
    &__footer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        @media (max-width: 450px) {
            display: flex;
            flex-direction: column-reverse;
            grid-template-columns: unset;
        }
    }
}

.new-invoice-modal__time-picker-popup {
    width: 100%;
    max-width: 312px;
    top: 50px !important;
    background: var(--secondary-color);
    border: 1px solid var(--border-color);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.currency__option-text {
    color: var(--primary-font-color);
}

.new-invoice-modal__time-picker-popup > * > .mx-datepicker-header {
    padding: 0;
    border: none;
}

.new-invoice-modal__time-picker-popup > .mx-datepicker-content {
    border-radius: 8px;
    overflow: hidden;
    background: var(--secondary-color);
}

.new-invoice-modal__time-picker-popup > * > * > .mx-time {
    width: 100%;
    background: var(--secondary-color);
}

.new-invoice-modal__time-picker-popup .mx-time-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.new-invoice-modal__time-picker-popup .mx-time-column .mx-time-item.active {
    width: 128px;
    height: 40px;
    color: var(--blue-300);
    background: var(--secondary-color);
    border-radius: 8px;
}

.new-invoice-modal__time-picker-popup .mx-time-item:hover {
    background: var(--modal-header-bg);
}

.new-invoice-modal__time-picker-popup .mx-time-item {
    width: 128px;
    height: 40px;
    background: var(--primary-color);
    border-radius: 8px;
    margin-bottom: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: var(--primary-font-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-invoice-modal__time-picker-popup .mx-time-item.disabled {
    background: var(--primary-color);
}

.new-invoice-modal__time-picker .mx-input-wrapper .mx-icon-calendar {
    color: var(--new-front-primary-font-color);
}

.new-invoice-modal__time-picker .mx-input-wrapper .mx-icon-clear {
  color: var(--new-front-primary-font-color);
}



.new-invoice-modal__time-picker-popup .mx-time-column {
    border: none;
}


</style>
<style lang="scss" scoped>


//Tick width & range thumb width need to be in sync, that's why I'm using the $unit variable.
$unit: 10px;


//Some range styling.
//Using mixins because each major browser needs a separate selector to style parts of the range input, combining selectors would invalidate the whole selector on each browser
@mixin range-track {
  -webkit-appearance: none;
  width: 100%;
  height: 7px;
  color: transparent;
  background: #F2F2F2;
  background-image: linear-gradient(90deg, var(--blue-300) var(--percentage), transparent var(--percentage));
  border-radius: 999px;
  border: none;
}

@mixin range-thumb {
  -webkit-appearance: none;
  height: 35px;
  width: 38px;
  border-radius: 12px;
  background: var(--blue-300);
  margin-top: -14px;
}

input[type=range] {
  -webkit-appearance: none;
  display: block;
  margin: 0;
  width: 100%;
  background: transparent;
}


input[type=range]::-webkit-slider-runnable-track {
  @include range-track();
}

input[type=range]::-moz-range-track {
  @include range-track();
}

input[type=range]::-ms-track {
  @include range-track();
}

input[type=range]::-ms-fill-lower {
  display: none;
}

input[type=range]::-ms-fill-upper {
  display: none;
}

input[type=range]::-webkit-slider-thumb {
  @include range-thumb();
}

input[type=range]::-moz-range-thumb {
  @include range-thumb();
}

input[type=range]::-ms-thumb {
  @include range-thumb();
}


//And now the ticks
.ticks {
  display: flex;
  justify-content: space-between;

  padding: $unit*2 $unit*2;
}

.tick {
  position: relative;
  display: flex;
  justify-content: center;
  background: gray;
  height: $unit;
  width: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: var(--primary-font-color);

  &_active {
    color: var(--blue-300);
  }
}

.new-invoice-modal ::v-deep .at-textarea__original {
  background: var(--secondary-color);
  border: 1px solid var(--border-color);
  color: var(--primary-font-color);
  box-sizing: border-box;
  box-shadow: inset 0 4px 20px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  min-height: 88px;
  font-family: var(--font-family);
  font-size: var(--font-size-text-main);
  line-height: var(--line-height);
  color: var(--primary-font-color);
}





::v-deep .at-input__original {
  font-family: var(--font-family);
  font-size: var(--font-size-text-main);
  line-height: var(--line-height);
  color: var(--primary-font-color);

  &::placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }
}

.new-invoice-modal {
  max-width: 764px;
  &__error-text {
    font-size: var(--font-size-text-secondary);
    font-family: var(--font-family);
    line-height: var(--line-height);
    color: #EB5757;
  }

  &__time-picker-popup {
    background: black;
  }

  &__time-picker-popup::v-deep .mx-datepicker-header {
    padding: 0;

  }

  &__time-picker {
    width: 100%;
    max-width: 312px;
  }

  &__time-picker::v-deep.mx-datepicker-range {
    width: 100%;
  }

  &__time-picker ::v-deep .mx-input {
    height: 46px;
    background: var(--secondary-color);
    border: 1px solid var(--border-color);
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }


  &__input {
    width: 100%;
  }

  &__amount {
    max-width: 312px;

    //&::before {
    //  content: '';
    //  width: calc(100% + 32px);
    //  border-bottom: 1px solid #F2F2F2;
    //  background: black;
    //  position: absolute;
    //  left: -16px;
    //  bottom: 0;
    //}
  }

  &__currency-container {
    display: flex;
    gap: 25px;
    justify-content: space-between;
    color: var(--primary-font-color);
  }

  &__select-container {
    position: relative;
  }

  &__option-container {
    display: flex;
    gap: 15px;
  }

  &__select-container ::v-deep .at-select__selection {
    background: var(--secondary-color);
    border: 1px solid var(--border-color);
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    min-height: 46px;
    display: flex;
    align-items: center;
    background: var(--secondary-color);
    color: var(--primary-font-color);
  }

  &::v-deep .at-select__dropdown {
    border: 1px solid var(--border-color);
    background: var(--bg-select);
  }

  ::v-deep .at-select__option--selected {
    background: var(--hover-color-select);
  }

  ::v-deep .at-select__arrow {
    font-size: 24px;
    color: var(--primary-font-color);
    margin-top: 0;
    top: calc(50% - 12px);
  }

  ::v-deep .at-select__clear {
    font-size: 24px;
    color: var(--primary-font-color);
    margin-top: 0;
    top: calc(50% - 12px);
  }

  ::v-deep .at-select__dropdown .at-select__option {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);

    &:hover {
      background: var(--hover-color-select);
    }
  }

  ::v-deep .at-select__placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }

  ::v-deep .at-select__selection {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    padding-left: 30px;
  }

  &__select-container_img ::v-deep .at-select__selection {
    padding-left: 68px;
  }

  &__label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 52px;
    border-bottom: 1px solid var(--border-color);
  }

  &__time-label {

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: var(--primary-font-color);
    width: 100%;

    &_divider {
      width: auto;
    }
  }

  &__checkboxLabel {
    font-size: 16px;
    color: var(--primary-font-color);
  }

  &__row {
    margin-bottom: 35px;
    align-items: center;
    min-height: 30px;
    width: 100%;
    position: relative;

    ::v-deep .at-input--error .at-input__original {
      border-color: #EB5757;
    }

    &_amount {
      padding-bottom: 35px;
      margin-bottom: 37px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    color: var(--primary-font-color);
    margin-bottom: 10px;

    &_slider {
      margin-bottom: 35px;
    }
  }

  &__select {
    width: 100%;
    height: 32px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    outline: none;
    transition: .2s ease border-color;

    &:hover {
      border-color: #79A1EB;
    }
  }

  &__date.mx-datepicker {
    width: 100%;
  }

  &__spinner {
    margin: 16px 0 18px;
  }

  &__full-width-btn {
    width: 100%;
  }

  &.link {
    color: #5ECAE5;
    min-width: 220px;
    cursor: pointer;
  }

  .currency-img {
    width: 20px;
    height: 20px;

    &_preview {
      position: absolute;
      z-index: 2;
      top: 11px;
      width: 24px;
      height: 24px;
      left: 30px;
    }
  }


}

@media screen and (max-width: 450px) {
  .new-invoice-modal {
    ::v-deep .currency-img_preview {
      left: 17px;
    }

    &__select-container_img {
      ::v-deep .at-select__selection {
        padding-left: 53px;
      }
    }

    ::v-deep .at-modal__title {
      max-width: 150px;
    }

    ::v-deep .at-modal__footer {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
    }

    &__amount {
      max-width: 100%;
    }

    &__row {
      margin-bottom: 10px;

      &_amount {
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
    }

    &__currency-container {
      flex-direction: column;
      gap: 10px;
      margin-bottom: 10px;
    }
  }
}
::v-deep {
  .advanced-balances-select-wrapper .input-wrapper .options-list .list {
    max-height: 300px !important;
  }
    @media screen and (max-width: 576px) {
        .label {
            font-size: 10px !important;
        }
        textarea {
            font-size: 13px !important;
        }
    }
    .textarea {
        textarea::placeholder {
            font-weight: 500;
            //color: unset;
        }
    }
    .main-modal-body {
        overflow: auto;
        max-height: 100%;
    }
}
.modal-spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
