<template>
  <div :class="['info-btn', disabled ? 'disabled' : '']">
    <secondary-button
      :disabled="disabled"
      type="new-secondary"
      @click="$emit('click')"
    >
      <template #prepend>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
          <path d="M7 13.125C3.61726 13.125 0.875 10.3827 0.875 7C0.875 3.61726 3.61726 0.875 7 0.875C10.3827 0.875 13.125 3.61726 13.125 7C13.125 10.3827 10.3827 13.125 7 13.125ZM7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" :fill="!themeStatus ? '#744DB2' : '#FFFFFF'"/>
          <path d="M7.81437 5.7644L5.80973 6.01562L5.73795 6.34888L6.13272 6.42065C6.38907 6.48218 6.44034 6.57446 6.38395 6.83081L5.73795 9.86597C5.56876 10.6504 5.83023 11.0195 6.44547 11.0195C6.92228 11.0195 7.47599 10.7991 7.72721 10.4966L7.80411 10.1326C7.6298 10.2864 7.37345 10.3479 7.20426 10.3479C6.96329 10.3479 6.87613 10.1787 6.93766 9.88135L7.81437 5.7644Z" :fill="!themeStatus ? '#744DB2' : '#FFFFFF'"/>
          <path d="M7.875 3.9375C7.875 4.42075 7.48325 4.8125 7 4.8125C6.51675 4.8125 6.125 4.42075 6.125 3.9375C6.125 3.45425 6.51675 3.0625 7 3.0625C7.48325 3.0625 7.875 3.45425 7.875 3.9375Z" :fill="!themeStatus ? '#744DB2' : '#FFFFFF'"/>
        </svg>
      </template>
    </secondary-button>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "InfoButton",
  components: {
    SecondaryButton: () => import(/* webpackChunkName: "public/components/common/uiKit/button/base/bg-secondary" */ '/public/components/common/uiKit/button/base/bg-secondary'),
  },
    props: {
      disabled: {
          type: Boolean,
          default: false
      }
    },
  computed: {
    ...mapState(['themeStatus']),
  }
}
</script>

<style lang="scss">
  .info-btn {
    width: 48px;
    min-width: 48px;

    .new-secondary {
      background-color: var(--new-front-bg-secondary)!important
    }
  }
  .disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
</style>