<template>
    <div class="preview-transaction table-row">
        <div class="preview-transaction__inner-row">
            <div class="preview-transaction__col preview-transaction__id col col-5">
                <CopyString
                    :string="id"
                    canCopy
                />
            </div>
            <div class="preview-transaction__col col col-3">
                <AtTag :color="statusColor">{{statusTitle}}</AtTag>
            </div>
            <div class="preview-transaction__col col col-5">
                <CopyString
                    :string="orderId"
                    canCopy
                />
            </div>
            <div class="preview-transaction__col preview-transaction__currency col col-3">
                <CurrencyImg
                    class="preview-transaction__currency-img"
                    :currency="currency"
                />
                {{ currency }}
            </div>
            <div class="preview-transaction__col col col-3">
                {{ amount }}
            </div>
            <div class="preview-transaction__col col col-4">
                {{ availableAt }}
            </div>
            <div class="preview-transaction__col col col-4">
                {{ createdAt }}
            </div>
        </div>
    </div>
</template>

<script>
import Moment from 'moment';
import { mapState } from 'vuex';

import CopyString from "/public/components/elements/CopyString";
import CurrencyImg from "/public/components/elements/CurrencyImg";

import {ROLLING_RESERVE_HISTORY_STATUS_ENUM} from "../../../../common/constants/rolling-reserve-history-status-enum";

export const translated = [
    {
        status: ROLLING_RESERVE_HISTORY_STATUS_ENUM.AWAIT,
        title: 'В ожидании',
        color: 'info',
    },
    {
        status: ROLLING_RESERVE_HISTORY_STATUS_ENUM.RETURN,
        title: 'Возвращен',
        color: 'success',
    },
    {
        status: ROLLING_RESERVE_HISTORY_STATUS_ENUM.HOLD,
        title: 'Удержан',
        color: 'fail',
    },
];

export default {
    name: 'PreviewRollingReserve',
    components: {
        CopyString,
        CurrencyImg,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState('app', [
            'currencies',
        ]),
        id () {
            return this.data?.id;
        },
        status () {
            return translated.find(t => t.status === this.data?.status);
        },
        statusTitle(){
            return this.status?.title || '';
        },
        statusColor() {
            return this.status?.color || 'default';
        },
        orderId () {
            return this.data?.orderId;
        },
        currency () {
            return this.currencies.find(c => c?.id === this.data?.currency)?.currency;
        },
        amount () {
            return this.data?.amount;
        },
        availableAt () {
            return Moment(this.data?.availableAt).format('DD.MM.YYYY HH:mm');
        },
        createdAt () {
            return Moment(this.data?.createdAt).format('DD.MM.YYYY HH:mm');
        },
    },
};

</script>

<style lang="scss" scoped>

    .preview-transaction {
        padding: 0;
        position: relative;
        color: #3F536E;
        border: none;

        &:hover {

            & .preview-transaction__col {
                background-color: $gray2;
            }

        }

        &__inner-row {
            display: flex;
            width: 100%;
        }

        &__col {
            padding: 6px 0 6px;
            line-height: 32px;
            border-bottom: 1px solid $gray2;

            &:first-child {
                padding-left: 16px;
                border-left: 1px solid $gray2;
            }

            &:last-child {
                padding-right: 16px;
                border-right: 1px solid $gray2;
            }
        }

        &__link {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
        }

        &__id {
            position: relative;
            z-index: 2;
        }

        &__currency {
            display: flex;
            align-items: center;
        }

        &__currency-img {
            margin-right: 12px;
            width: 24px;
            height: 24px;
        }
    }

</style>
