<script>

  import DashboardSwapAddressSelect from "@/public/components/elements/dashboard/DashboardSwapAddressSelect.vue";
  import {mapActions, mapGetters, mapState} from "vuex";

  export default {
    name: "DashboardSwapAddressSelectWrapper",
    components: {DashboardSwapAddressSelect},
    props: {
      currencyAndNetworkFrom: {
        type: Object,
        required: true,
      },
      addressFrom: {
        type: String,
        default: ''
      },
      value: {
        type: [Object, undefined],
      }
    },
    data() {
      return {
        addressTo: undefined,
        balancesSummaryTo: {
          business: [],
          payin: [],
          payout: [],
          personal: [],
          recurrent: []
        },
        isLoading: false,
      }
    },
    computed: {
      ...mapState('app', [
        'selectedOrganizationId'
      ]),
      ...mapGetters('organizations', ['userRoleRules']),
      ...mapState('accounts', ['accounts']),
      ...mapGetters('dashboard', ['availableBalances']),
      ...mapState('balances', ['balancesGrouped', 'balancesGroupedPayin', 'balancesGroupedBusiness']),
      ...mapState('app', ['currencies', 'networks']),
      currenciesWithChainTo({ currencies, currencyAndNetworkFrom }) {
        return currencies.reduce(( acc, curr ) => {
          if (curr.networks) {
            const { allowDeposit, id: currId } = curr
            const { currency: currencyFrom } = currencyAndNetworkFrom || {}
            const { id: currencyFromId } = currencyFrom || {}

            if ( allowDeposit && currencyFromId !== currId ) {
              curr.networks.forEach(network => {

                const { allowCrosschainSwaps, allowCrosschainSwapTo } = network

                if ( allowCrosschainSwaps && allowCrosschainSwapTo )  {
                  const currencyWithNetwork = {
                    id: `${curr.id}_${network.id}`,
                    currency: curr,
                    network: network,
                  }

                  acc.push(currencyWithNetwork)
                }
              })
            }
          }

          return acc
        }, [])
      },
      walletsListFull() {
        return [
          {
            id: 'business',
            label: this.$t('common-labels.wallets.business_wallets')
          },
          {
            id: 'payin',
            label: this.$t('common-labels.wallets.pay_in')
          },
          {
            id: 'payout',
            label: this.$t('common-labels.wallets.pay_out')
          },
          {
            id: 'recurrent',
            label: this.$t('common-labels.wallets.payment_addresses')
          },
          {
            id: 'main',
            label: this.$t('common-labels.wallets.main')
          }
        ]
      },
      walletsListTo({ allowedRoles, walletsListFull }) {
        // адреса вывода не могут быть персональными
        return walletsListFull.filter(wallet => {
          return allowedRoles.includes(wallet.id)
        })
      },
      allowedRoles({ userRoleRules }) {
        //собираем в массив имена допустимых кошельков из 5-ти возможных в мостах
        const roles = [
          {
            role_name: 'wallets_business',
            id: 'business'
          },
          {
            role_name: 'wallets_payin',
            id: 'payin'
          },
          {
            role_name: 'payout_balances',
            id: 'payout'
          },
          {
            role_name: 'personal',
            id: 'personal'
          },
          {
            role_name: 'main',
            id: 'main'
          },
          {
            role_name: 'recurrent',
            id: 'recurrent'
          }
        ]

        return roles.reduce((acc, role) => {
          if (role.role_name === 'main') {
            acc.push(role.id)
            return acc
          }
          if (userRoleRules[role.role_name]?.isAllowed) {
            acc.push(role.id)
          }
          return acc
        }, [])
      },
      accountId({ accounts }) {
        return accounts.find(({ accountId }) => !!accountId).accountId;
      },
      addressModelTo: {
        get({ value }) {
          return value
        },
        set(v) {
          this.$emit('input', v)
        }
      },
    },
    methods: {
      ...mapActions('balances', [
        'getAccountBalancesPayin',
        'getAccountBalancesBusiness',
        'getAccountBalancesRecurrent'
      ]),
      ...mapActions('payoutBalances', [
        'fetchPayoutBalances'
      ]),
      ...mapActions('personalAddresses', [
        'getAddressesForPerson'
      ]),
      async getBalancesSummary() {
        const { accountId, selectedOrganizationId: organizationId, balancesSummaryFrom, balancesSummaryTo, currenciesWithChainFrom, currenciesWithChainTo } = this

        const walletsNames =  [ 'business', 'payin', 'payout', 'recurrent', 'main', ]

        const balancesRequests = await Promise.all([
          this.getAccountBalancesBusiness({ accountId, organizationId }).catch(() => {}),
          this.getAccountBalancesPayin({ accountId, organizationId }).catch(() => {}),
          this.fetchPayoutBalances({ organizationId }).catch(() => {}),
          this.getAccountBalancesRecurrent({ organizationId }).catch(() => {}),
          this.availableBalances.addresses.map(item => ({ ...item, balance: item.amount }))
        ])


        walletsNames.forEach((walletName, index) => {
          [ [balancesSummaryTo, currenciesWithChainTo] ].forEach((pair) => {
            pair[0][walletName] = pair[1].map(({ currency, network }) => {
              let addresses = []

              if (!!balancesRequests[index]) {
                addresses = balancesRequests[index].filter(addr => {
                  return currency.id === addr.currencyId && network.id === addr.networkId
                })
              }

              return {
                currencyId: currency.id,
                networkId: network.id,
                min_amount: addresses.length ? Math.min.apply(Math, addresses.map(({ balance }) => Number(balance))) : 0,
                max_amount: addresses.length ? Math.max.apply(Math, addresses.map(({ balance }) => Number(balance))) : 0,
                summary: addresses.length ? addresses.reduce(( acc, addr ) => {
                  acc += Number(addr.balance)
                  return acc
                }, 0) : 0,
                addresses
              }
            })
          })
        })
      },
    },
    created() {
      this.getBalancesSummary();
    },
    watch: {
      addressFrom () {
        this.getBalancesSummary()
      },
    }
  }
</script>

<template>
<dashboard-swap-address-select
    v-model="addressModelTo"
    :disabled="!currencyAndNetworkFrom"
    :currencyAndNetworkFrom="currencyAndNetworkFrom"
    :wallets-list="walletsListTo"
    :balances-summary="balancesSummaryTo"
    :account-id="accountId"
    :label="$t('swap.address_select.label_to')"
    :show-nullish-balances-for="['business', 'payout']"
    :hidden-address="addressFrom"
    hide-nullish-balances
    class="item"
    :retry="getBalancesSummary"
/>
</template>

<style scoped lang="scss">

</style>
