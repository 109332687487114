export const state = {
    isInit: false,
    isLoading: false,
    currencies: [],
    networks: [],
    organizations: [],
    selectedOrganizationId: null,
    isNavOpen: false,
    modal: {
        opened: null,
        payload: null,
    },
    authorization: {
        login: false,
        twoFa: false,
    },
    theme: undefined
};
