import changePreCheckoutAlias from "../../../../graphQl/mutations/changePreCheckoutAlias";

export const changePreCheckoutAliasQuery = async ({dispatch, commit, rootGetters}, payload) => {
    let changeLangData;
    commit('app/toggleLoading', null, {root: true});

    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    const variables = {
        organizationId,
        ...payload,
    };

    try {
        changeLangData = await dispatch('app/request', {
            type: 'mutation',
            gql: changePreCheckoutAlias,
            variables,
        }, {root: true});
    } catch (error) {
        commit('app/toggleLoading', null, {root: true});
        return error;

    }

    if (changeLangData.message) {
        throw changeLangData.message;
    }

    if (changeLangData.errors) {
        throw changeLangData.errors;
    }
    commit('app/toggleLoading', null, {root: true});

    const {
        data: {
            changePreCheckoutAlias: changePreCheckoutAliasResponse,
        },
    } = changeLangData;
    return changePreCheckoutAliasResponse;

};
