import {shortText} from "../filters";

const chainsLinks = {
    bitcoin: {
        name: 'BTCScan',
        contract: 'https://blockchair.com/bitcoin/transaction/',
        tx: 'https://blockchair.com/bitcoin/transaction/',
        address: 'https://blockchair.com/bitcoin/address/',
    },
    litecoin: {
        name: 'LTCScan',
        contract: 'https://blockchair.com/litecoin/transaction/',
        tx: 'https://blockchair.com/litecoin/transaction/',
        address: 'https://blockchair.com/litecoin/address/',
    },
    bitcoincash: {
        name: 'BCHScan',
        contract: 'https://blockchair.com/bitcoin-cash/transaction/',
        tx: 'https://blockchair.com/bitcoin-cash/transaction/',
        address: 'https://blockchair.com/bitcoincash/address/',
    },
    ripple: {
        name: 'XRPScan',
        contract: '',
        tx: 'https://xrpscan.com/ledger/',
        address: 'https://xrpscan.com/account/',
    },
    solana: {
        name: 'SolScan',
        contract: 'https://solscan.io/token/',
        tx: 'https://solscan.io/tx/',
        address: 'https://solscan.io/address/',
    },
    bsc: {
        name: 'BSCScan',
        contract: 'https://bscscan.com/token/',
        tx: 'https://bscscan.com/tx/',
        address: 'https://bscscan.com/address/',
    },
    "bsc-testnet": {
        name: 'BSCScan',
        contract: 'https://testnet.bscscan.com/token/',
        tx: 'https://testnet.bscscan.com/tx/',
        address: 'https://testnet.bscscan.com/address/',
    },
    tron: {
        name: 'TronScan',
        contract: 'https://tronscan.org/#/token20/',
        tx: 'https://tronscan.org/#/transaction/',
        address: 'https://tronscan.org/#/address/',
    },
    ethereum: {
        name: 'EtherScan',
        contract: 'https://etherscan.io/token/',
        tx: 'https://etherscan.io/tx/',
        address: 'https://etherscan.io/address/',
    },
    sepolia: {
        name: 'EtherScan',
        contract: 'https://sepolia.etherscan.io/token/',
        tx: 'https://sepolia.etherscan.io/tx/',
        address: 'https://sepolia.etherscan.io/address/',
    },
    fantom: {
        name: 'FTMScan',
        contract: 'https://ftmscan.com/token/',
        tx: 'https://ftmscan.com/tx/',
        address: 'https://ftmscan.com/address/',
    },
    polygon: {
        name: 'POLYGONScan',
        contract: 'https://polygonscan.com/token/',
        tx: 'https://polygonscan.com/tx/',
        address: 'https://polygonscan.com/address/',
    },
    ton: {
        name: 'TONScan',
        contract: 'https://tonscan.org/jetton/',
        tx: 'https://tonscan.org/tx/',
        address: 'https://tonscan.org/address/',
    },
};

export const BlockchainLinks = {
    methods: {
        $linkName(hash, net) {
            const networks = this.$store.state["app"]["networks"];
            const network = networks.find(n => n.id === net || n.name === net || n.alias === net);

            if(!(network && chainsLinks[network.name].name && hash)) {
                return '';
            }

            const link = `${shortText(hash)} (${chainsLinks[network.name].name})`;

            return link;
        },
        $contractLink(coin, net) {
            const currencies = this.$store.state.app.currencies;
            const currency = currencies
                .find(c => c.id === coin || c.name === coin || c.alias === coin || c.currency === coin);

            const network = currency
                ?.networks.find(n => n.id === net || n.name === net || n.alias === net);

            if(!(network && network.contract)) {
                return '';
            }

            const link = chainsLinks[network.name].contract + network.contract;

            return link;
        },
        $addressLink(address, net) {
            const networks = this.$store.state["app/networks"];

            const network = networks
                ?.networks.find(n => n.id === net || n.name === net || n.alias === net);

            if(!(network && chainsLinks[network.name].address)) {
                return '';
            }

            const link = chainsLinks[network.name].address + network.contract;

            return link;
        },
        $txLink(tx, net) {
            const networks = this.$store.state["app"]["networks"]
            // const networks = this.$store.state.app.networks;
            const network = networks.find(n => n.id === net || n.name === net || n.alias === net)


            if(!(network && chainsLinks[network.name]?.tx)) {
                return tx
            }

            return chainsLinks[network.name]?.tx + tx
        }
    }
}
