<script>
  export default {
    name: "SvgCalendar"
  }
</script>

<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
    <path d="M12.6668 3.33301H12.0002V1.99967C12.0002 1.82286 11.9299 1.65329 11.8049 1.52827C11.6799 1.40325 11.5103 1.33301 11.3335 1.33301C11.1567 1.33301 10.9871 1.40325 10.8621 1.52827C10.7371 1.65329 10.6668 1.82286 10.6668 1.99967V3.33301H5.3335V1.99967C5.3335 1.82286 5.26326 1.65329 5.13823 1.52827C5.01321 1.40325 4.84364 1.33301 4.66683 1.33301C4.49002 1.33301 4.32045 1.40325 4.19543 1.52827C4.0704 1.65329 4.00016 1.82286 4.00016 1.99967V3.33301H3.3335C2.80306 3.33301 2.29436 3.54372 1.91928 3.91879C1.54421 4.29387 1.3335 4.80257 1.3335 5.33301V5.99967H14.6668V5.33301C14.6668 4.80257 14.4561 4.29387 14.081 3.91879C13.706 3.54372 13.1973 3.33301 12.6668 3.33301ZM1.3335 12.6663C1.3335 13.1968 1.54421 13.7055 1.91928 14.0806C2.29436 14.4556 2.80306 14.6663 3.3335 14.6663H12.6668C13.1973 14.6663 13.706 14.4556 14.081 14.0806C14.4561 13.7055 14.6668 13.1968 14.6668 12.6663V7.33301H1.3335V12.6663Z" fill="var(--text-text-white)"/>
  </g>
</svg>
</template>
