import requestWithdrawalMutation from '/public/graphQl/mutations/requestWithdrawal';

export const requestWithdrawal = async ({ commit, dispatch }, payload) => {
    let withdrawalRequestData;

    try {
        withdrawalRequestData = await dispatch('app/request', {
            type: 'mutation',
            gql: requestWithdrawalMutation,
            variables: payload,
        }, { root: true });
    } catch (error) {
        return error;
    }

    commit('withdrawalData', withdrawalRequestData);

    return withdrawalRequestData;
};