<template>
  <div class="base-max-input">
    <div class="label">
      <slot name="label">
        <div
            v-if="$slots['label-left']"
            class="label-left"
        >
          <slot name="label-left" />
        </div>
        <div
            v-if="$slots['label-right']"
            class="label-right"
        >
          <slot name="label-right" />
        </div>
      </slot>
    </div>
    <div class="input-wrapper">
      <template v-if="!isTextArea">
        <input
            v-model="innerModel"
            v-bind="$attrs"
            :placeholder="placeholder"
            :type="type"
            @blur="checkLimits"
        />
        <div
            v-if='hasActionBtn'
            class="action"
            @click="$emit('action-btn-click')"
        >
          <slot name="action-btn-slot">
            MAX
          </slot>
        </div>
        <div
            class="bottom-label"
            v-if="$slots['bottom-label']"
        >
          <slot name="bottom-label" />
        </div>
        <div
            class="bottom-left-label"
            v-if="$slots['bottom-left-label']"
        >
          <slot name="bottom-left-label" />
        </div>
      </template>
      <textarea v-else v-model="innerModel" :placeholder="placeholder" rows="4" />
    </div>
  </div>
</template>

<script>
export default {
  name: "MaxInputElement",
  props: {
    placeholder: {
      type: String,
      default: function() {
        return this.$t('common-labels.placeholders.input')
      }
    },
    hasActionBtn: {
      type: Boolean,
      default: false
    },
    min: {
      type: [String, Number],
      default: undefined
    },
    max: {
      type: [String, Number],
      default: undefined
    },
    value: {
      type: [String, Number],
      default: undefined
    },
    type: {
      type: String,
      default: 'number'
    },
  },
  computed: {
    innerModel: {
      get({ value }) {
        return value
      },
      set(v) {
        const { max } = this
        if (max && Number(v) > Number(max)) {
          this.$emit('input', undefined)
          this.$nextTick(() => {
            this.$emit('input', Number(max))
          })
        } else {
          if (this.type === 'number') {
            this.$emit('input', Number(v))
          } else {
            this.$emit('input', v)
          }
        }
      }
    },
    isTextArea({ type }) {
      return type === 'textarea'
    }
  },
  methods: {
    checkLimits() {
      // отключено временно - после тестирования если все ок - удаляем все что с этим связано
      // this.checkLowestThenMin()
      // this.checkHighestThenMax()
    },
    checkLowestThenMin() {
      const { min, value } = this

      if (this.min &&  Number(value) < Number(min)) {
        this.$emit('input', undefined)
        this.$nextTick(() => {
          this.$emit('input', Number(min))
        })
      }
    },
    checkHighestThenMax() {
      const { max, value } = this

      if (this.max &&  Number(value) > Number(max)) {
        this.$emit('input', undefined)
        this.$nextTick(() => {
          this.$emit('input', Number(max))
        })
      }
    }
  },
}
</script>

<style scoped lang="scss">
.base-max-input {
  font-family: var(--new-front-font-family);

  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-style: normal;
    font-weight: 400;
    font-size: $h6; //13px
    line-height: $h6;

    padding-bottom: 5px;

    color: var(--new-front-input-label-font-color);

    .label-right, .label-left {}
  }
  .input-wrapper {
    width: 100%;
    position: relative;

    input {
      display: flex;
      align-items: center;

      width: 100%;

      min-height: 47px;
      background: transparent;
      border: 1px solid var(--new-front-input-border);
      box-shadow: inset 0 4px 20px var(--new-front-gradient-bg);
      border-radius: 12px;
      padding: 0 20px;

      cursor: pointer;

      font-style: normal;
      font-weight: 500;
      font-size: $h4; //16px
      line-height: $h4;

      color: var(--new-front-primary-font-color);

      &:focus {
        outline: none !important;
        border: 1px solid #445DE1;
      }
    }
    textarea {
      width: 100%;

      padding: 20px 30px;

      background: var(--main-bg);
      border: 1px solid var(--main-bg);
      box-shadow: inset 0 4px 20px var(--main-bg);

      border-radius: 12px;

      font-style: normal;
      font-weight: 300;
      font-size: $h4;
      line-height: $h4;
      color: #8F949A;

      &:focus {
        outline: none !important;
        border: 1px solid #445DE1;
      }
    }

    .action {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 73px;
      height: 90%;

      display: flex;
      align-items: center;
      justify-content: center;

      font-weight: 500;
      font-size: $h4;

      border-left: 1px solid var(--new-front-input-border);

      color: var(--new-front-input-max-btn-color);

      cursor: pointer;
    }

    ::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-style: normal;
      font-weight: 500;
      font-size: $h4;
      line-height: $h4;

      color: var(--new-front-secondary-font-color);
      opacity: 1; /* Firefox */

      @include below_phone() {
        font-size: $h6;
        line-height: $h6;
      }
    }

    .bottom-label {
      position: absolute;
      top: 40px;
      right: 0;

      display: flex;

      width: fit-content;

      transform: translateY(100%);

      text-align: right;

      font-size: $h6;
      line-height: $h6;
    }
    .bottom-left-label {
      position: absolute;
      top: 40px;
      left: 0;

      display: flex;

      width: fit-content;

      transform: translateY(100%);

      text-align: right;

      font-size: $h6;
      line-height: $h6;
    }
  }

  @include below_phone() {
    .label {
      font-weight: 400;
      font-size: $h8;
      line-height: $h8;

      .label-right {
        display: none;
      }
    }
    .input-wrapper {
      input {
        font-size: $h6;
        line-height: $h6;
      }
      .action {
        font-size: $h6;
        line-height: $h6;
      }
      .bottom-label {
        font-weight: 400;
        font-size: $h8;
        line-height: $h8;
      }
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
</style>
