import getPreCheckoutPageOrders from "../../../../graphQl/queries/getPreCheckoutPageOrders";
import moment from "moment";
import {getRouteQuery} from "../../../../utils/getRouteQuery";
import {getFormatForUrlByArray} from "../../../../utils/getFormatForUrlByArray";

export const fetchPreCheckoutOrders = async ({state, dispatch, commit, rootGetters}, payload) => {
    const {
        route,
        router,
        pageId,
        showUrlFilters = true,
        excludeParams = [],
        page,
        date,
    } = payload;
    const {pageOrdersSize} = state;

    const currentPage = page ? page : getRouteQuery({
        route,
        queryName: 'page',
        defaultValue: 1,
        excludeQueries: excludeParams,
        preparedReturnValueFunc: (value) => Number(value),
    });

    const currentDate = date ? date : getRouteQuery({
        route,
        queryName: 'date',
        defaultValue: [],
        preparedReturnValueFunc: (value) => value.split('to'),
    });

    let [from, to] = currentDate;
    from = from ? moment(from).toDate() : null;
    to = from ? moment(to).toDate() : null;
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const variables = {
        organizationId,
        pageId,
        limit: pageOrdersSize,
        offset: ((currentPage === 1 || currentPage === 0 ? 0 : ((currentPage - 1) * pageOrdersSize))),
        from,
        to,
    };

    if (showUrlFilters) {
        router.push({
            query: {
                page: currentPage,
                date: getFormatForUrlByArray(
                    currentDate,
                    (array) => array.join('to'),
                ),
            },
        });
    }

    let orderData;
    try {
        commit('app/toggleLoading', null, {root: true});
        orderData = await dispatch('app/request', {
            type: 'query',
            gql: getPreCheckoutPageOrders,
            variables,
        }, {root: true});
    } catch (error) {

        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (orderData.message) {
        throw orderData.message;
    }

    if (orderData.errors) {
        throw orderData.errors;
    }

    const {
        data: {getPreCheckoutPageOrders: preCheckoutPageOrders},
    } = orderData;
    commit('pageOrders', preCheckoutPageOrders.orders);
    commit('pageOrdersCount', preCheckoutPageOrders.count);
    return preCheckoutPageOrders;
};