<script>

  import BaseInput from "@/public/pages/integration-settings/components/Input/index.vue";
  import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
  import {mapActions, mapMutations, mapState} from "vuex";
  import BusinessWalletNetworkSelect
    from "@/public/components/elements/business-walet/Select/businessWalletNetworkSelect.vue";
  import OrganizationInformationTimeZoneSelect
    from "@/public/components/elements/organizations/OrganizationInformationTimeZoneSelect.vue";
  import validUrl from "valid-url";

  export default {
    name: "OrganizationInformation",
    components: {OrganizationInformationTimeZoneSelect, BusinessWalletNetworkSelect, BaseButton, BaseInput},
    data() {
      return {
        websiteUrl: null,
        telegramUrl: null,
        ownerEmail: '',
      }
    },
    methods: {
      ...mapMutations('organizations', [
        'setOrganizationDataInputValue',
          'setOrganizationMainCurrencyId',
          'setOrganizationMainTimezone'
      ]),
      ...mapActions('organizations', [
        'updateOrganization',
      ]),
      ...mapActions('app', [
        'fetchOrganizations'
      ]),
      async saveOrganization() {
        if (this.isWebsiteInvalid || this.isTgInvalid || !this.isNeedToSave) return
        try {
          await this.updateOrganization({
            name: this.organizationName,
            logoUrl: this.organizationImgLink,
            telegramUrl: this.telegramUrl,
            websiteUrl: this.websiteUrl
          })

          // await this.fetchOrganizations()
        } catch (e) {
          console.log(e);
        }
      },
      clear () {
        if (!this.isNeedToSave) return
        this.telegramUrl = this.organizationData.telegramUrl
        this.websiteUrl = this.organizationData.websiteUrl
      }
    },
    watch: {
      organizationData: {
        deep: true,
        handler (value) {
          console.log(value, 'organizationData watch')
          this.websiteUrl = value.websiteUrl
          this.telegramUrl = value.telegramUrl
        },
        immediate: true
      }
    },
    computed: {
      ...mapState('app', [
        'currencies',
      ]),
      ...mapState('organizations', [
        'organizationName',
        'organizationId',
        'organizationData',
      ]),
      fiatCurrencies () {
        return this.currencies.filter(({allowInvoice, isFiat}) => allowInvoice && isFiat).sort((a, b) => a.orderIndex - b.orderIndex);
      },
      mainCurrency () {
        return this.currencies.find(({ id }) => id === this.organizationData.mainCurrencyId)
      },
      isWebsiteInvalid() {
        if (!this.websiteUrl) { return false; }
        return !validUrl.isHttpUri(this.websiteUrl, true);
      },
      isTgInvalid () {
        if (!this.telegramUrl) return  false;
        return !/^@?[a-zA-Z0-9_]{5,}$/.test(this.telegramUrl)
      },
      isNeedToSave () {
        console.log(this.organizationData.websiteUrl, this.websiteUrl, this.organizationData.telegramUrl, this.telegramUrl, 'organizationData isNeedToSave')
        return this.organizationData.websiteUrl !== this.websiteUrl || this.organizationData.telegramUrl !== this.telegramUrl
      }
    }
  }
</script>

<template>
<div class="organization-information">
  <div class="header">
    <div class="header__left">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 12C10.9 12 9.95833 11.6083 9.175 10.825C8.39167 10.0417 8 9.1 8 8C8 6.9 8.39167 5.95833 9.175 5.175C9.95833 4.39167 10.9 4 12 4C13.1 4 14.0417 4.39167 14.825 5.175C15.6083 5.95833 16 6.9 16 8C16 9.1 15.6083 10.0417 14.825 10.825C14.0417 11.6083 13.1 12 12 12ZM4 20V17.2C4 16.6333 4.14583 16.1125 4.4375 15.6375C4.72917 15.1625 5.11667 14.8 5.6 14.55C6.63333 14.0333 7.68333 13.6458 8.75 13.3875C9.81667 13.1292 10.9 13 12 13C13.1 13 14.1833 13.1292 15.25 13.3875C16.3167 13.6458 17.3667 14.0333 18.4 14.55C18.8833 14.8 19.2708 15.1625 19.5625 15.6375C19.8542 16.1125 20 16.6333 20 17.2V20H4Z" fill="#7E7E7E"/>
      </svg>
      <div class="header__title">
        {{ $t('organization.organization-information') }}
      </div>
    </div>
    <div :class="['header__right', { solo: !isNeedToSave }]">
      <div v-if="isNeedToSave" @click="clear" class="header__clear">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.96967 6.96967C7.26256 6.67678 7.73744 6.67678 8.03033 6.96967L12 10.9393L15.9697 6.96967C16.2626 6.67678 16.7374 6.67678 17.0303 6.96967C17.3232 7.26256 17.3232 7.73744 17.0303 8.03033L13.0607 12L17.0303 15.9697C17.3232 16.2626 17.3232 16.7374 17.0303 17.0303C16.7374 17.3232 16.2626 17.3232 15.9697 17.0303L12 13.0607L8.03033 17.0303C7.73744 17.3232 7.26256 17.3232 6.96967 17.0303C6.67678 16.7374 6.67678 16.2626 6.96967 15.9697L10.9393 12L6.96967 8.03033C6.67678 7.73744 6.67678 7.26256 6.96967 6.96967Z" fill="#6750A4"/>
        </svg>
      </div>
      <base-button @click="saveOrganization" class="header__button" :disabled="!isNeedToSave"  :label="$t('organization.save')" />
    </div>
  </div>
  <div class="form">
    <div class="form__input-wrapper">
      <div class="form__input-label">{{ $t('organization.organization-form.website') }}</div>
      <base-input :error="isWebsiteInvalid" :value="websiteUrl"  :placeholder="$t('organization.organization-form.enter-website')" @input="(value) => websiteUrl = value" />
    </div>
    <div class="form__input-wrapper">
      <div class="form__input-label">{{ $t('organization.organization-form.telegram') }}</div>
      <base-input :error="isTgInvalid" :value="telegramUrl" :placeholder="$t('organization.organization-form.enter-telegram')" @input="(value) => telegramUrl = value" />
    </div>
    <div class="form__input-wrapper">
      <div class="form__input-label">{{ $t('organization.organization-form.email') }}</div>
      <base-input :disabled="true" :value="organizationData.ownerEmail" :placeholder="$t('organization.organization-form.enter-email')" />
    </div>
    <div class="mobile-save">
      <base-button @click="saveOrganization" :disabled="!isNeedToSave" class="header__button"  :label="$t('organization.save')" />
      <div v-if="isNeedToSave" @click="clear" class="header__clear">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.96967 6.96967C7.26256 6.67678 7.73744 6.67678 8.03033 6.96967L12 10.9393L15.9697 6.96967C16.2626 6.67678 16.7374 6.67678 17.0303 6.96967C17.3232 7.26256 17.3232 7.73744 17.0303 8.03033L13.0607 12L17.0303 15.9697C17.3232 16.2626 17.3232 16.7374 17.0303 17.0303C16.7374 17.3232 16.2626 17.3232 15.9697 17.0303L12 13.0607L8.03033 17.0303C7.73744 17.3232 7.26256 17.3232 6.96967 17.0303C6.67678 16.7374 6.67678 16.2626 6.96967 15.9697L10.9393 12L6.96967 8.03033C6.67678 7.73744 6.67678 7.26256 6.96967 6.96967Z" fill="#6750A4"/>
        </svg>
      </div>
    </div>
<!--    <div class="form__input-wrapper">-->
<!--      <div class="form__input-label">{{ $t('organization.organization-form.time-zone') }}</div>-->
<!--      <OrganizationInformationTimeZoneSelect-->
<!--          :value="organizationData.timezone"-->
<!--          @input="setOrganizationMainTimezone"-->
<!--          :placeholder="$t('organization.organization-form.choose-time-zone')"-->
<!--          clearable-->
<!--      />-->
<!--    </div>-->
<!--    <div class="form__input-wrapper">-->
<!--      <div class="form__input-label">{{ $t('organization.organization-form.main-currency') }}</div>-->
<!--      <business-wallet-network-select-->
<!--          :value="mainCurrency"-->
<!--          :list="fiatCurrencies"-->
<!--          @input="setOrganizationMainCurrencyId"-->
<!--          :placeholder="$t('organization.organization-form.choose-main-currency')"-->
<!--          clearable-->
<!--          with-search-->
<!--          uniq-key="id"-->
<!--      />-->
<!--    </div>-->
  </div>
</div>
</template>

<style scoped lang="scss">
.mobile-save {
  display: grid;
  grid-template-columns: 1fr 48px;
  gap: 10px;
  margin-top: 30px;
  @media screen and (min-width: 768px) {
    display: none;
  }
}
.organization-information {
  border-radius: 20px;
  background: var(--bg-background-secondary, #202020);
  padding: 20px 30px 30px;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    height: 48px;
    &__title {
      color: var(--text-text-primary, #FFF);
      font-family: Gilroy;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    &__left {
      display: flex;
      align-items: center;
      gap: 5px;
    }
    &__right {
      display: grid;
      grid-template-columns: 48px 1fr;
      gap: 10px;
      &.solo {
        grid-template-columns: 1fr;
      }
    }
    &__clear {
      border-radius: 10px;
      border: 1px solid var(--button-border-button-primary-default, #6750A4);
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    &__button {
      width: 180px;
    }
  }
  .form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    &__input {
      &-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
      &-label {
        color: var(--text-color-text-secondary);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .organization-information {
    padding: 20px;
    .header__left {
      .header__title {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .header__right {
      display: none;
    }

    .form {
      grid-template-columns: 1fr;
    }
  }
  .header__button {
    width: 100% !important;
  }
}
::v-deep {
  .advanced-balances-select-wrapper .input-wrapper .input .placeholder {
    color: var(--text-color-text-secondary, #7E7E7E) !important;
  }
  .advanced-balances-select-wrapper .input-wrapper .input {
    box-shadow: unset !important;
    border-radius: 10px;
    border: 1px solid var(--border-border-input-primary-default, #464646) !important;
    background: var(--bg-background-input-primary, #2A2A2A) !important;
    height: 48px;
  }
  .base-input__wrapper input {
    border-radius: 10px;
    border: 1px solid var(--border-border-input-primary-default, #464646) !important;
    background: var(--bg-background-input-primary, #2A2A2A) !important;
    &::placeholder {
      color: var(--text-color-text-secondary, #7E7E7E) !important;
    }
  }
  .advanced-balances-select-wrapper .input-wrapper .options-list .list__item {
    margin-bottom: 10px;
    height: 48px;
  }
  .advanced-balances-select-wrapper .input-wrapper .options-list.with-search .list {
    max-height: 300px !important;
  }
}
</style>
