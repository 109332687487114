import payoutAddress from "../../../../graphQl/mutations/payoutAddress";


export const getPayoutAddress = async ({commit, dispatch, rootGetters}, payload) => {

    commit('setDepositLoading', true);

    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    const variables = {
        organizationId,
        ...payload,
    };

    try {
        const {data: {payoutAddress: payoutAddressData}} = await dispatch('app/request', {
            type: 'mutation',
            gql: payoutAddress,
            variables,
        }, {root: true});

        commit("setDeposit", payoutAddressData);
        commit('setDepositCreated', true);
    } catch (error) {
        console.error('Fail create deposit', error);
    } finally {
        commit('setDepositLoading', false);
    }
};