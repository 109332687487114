<template>
  <div class="transaction">
    <AtButton
        class="transaction__all"
        type="primary"
        icon="icon-arrow-left"
        @click="goBack"
    >
      {{ $t('transaction.return') }}
    </AtButton>
    <div class="transaction-info">

      <h1 class="transaction-info__title">
        {{ $t('donation-order.title') }}
      </h1>

      <div class="transaction-info__info-container">

        <div class="transaction-info__item" v-if="status">
                <span class="transaction-info__item-title">
                {{ $t('donation-order.status') }}
                </span>
          <span class="transaction-info__item-value">
                    {{ $t(status) }}
                </span>
        </div>
        <div class="transaction-info__item">
                <span class="transaction-info__item-title">
                {{ $t('donation-order.system_id') }}
                </span>


          <CopyString
              class="transaction-info__item-value"
              :string="id"
              :successMessage="$t('copy.id_copied')"
              :failMessage="$t('copy.id_not_copied')"
              :title="$t('copy.notification')"
              canCopy
          />
        </div>

        <div class="transaction-info__item" v-if="orderId">
                <span class="transaction-info__item-title">
                {{ $t('donation-order.orderId') }}
                </span>
          <CopyString
              class="transaction-info__item-value"
              :string="orderId"
              :successMessage="$t('copy.id_copied')"
              :failMessage="$t('copy.id_not_copied')"
              :title="$t('copy.notification')"
              canCopy
          />

        </div>
        <div class="transaction-info__item">
                <span class="transaction-info__item-title">
                {{ $t('donation-order.pageId') }}
                </span>


          <CopyString
              class="transaction-info__item-value"
              :string="pageId"
              :successMessage="$t('copy.id_copied')"
              :failMessage="$t('copy.id_not_copied')"
              :title="$t('copy.notification')"
              canCopy
          />
        </div>
        <div class="transaction-info__item">
                <span class="transaction-info__item-title">
                {{ $t('donation-order.network') }}
                </span>
          <span class="transaction-info__item-value">
                    {{ networkId | getNetworkName }}
                </span>
        </div>

        <div class="transaction-info__item" v-if="donationOrder.currencyId">
                <span class="transaction-info__item-title">
                {{ $t('donation-order.currency') }}
                </span>
          <span class="transaction-info__item-value transaction-info__item-value--currency">
                    <CurrencyImg
                        class="transaction-info__currency-img"
                        :currency="donationOrder.currencyId"
                    />
                    {{ donationOrder.currencyId | getCurrencyTicker }}
                </span>
        </div>
        <div class="transaction-info__item" v-if="received">
                      <span class="transaction-info__item-title">
                      {{ $t('donation-order.received') }}
                      </span>
          <span class="transaction-info__item-value">
                          {{ received }}
                      </span>
        </div>
        <div class="transaction-info__item" v-if="receivedUSD">
                      <span class="transaction-info__item-title">
                      {{ $t('donation-order.received_usd') }}
                      </span>
          <span class="transaction-info__item-value">
                          {{ receivedUSD }}
                      </span>
        </div>

        <div class="transaction-info__item" v-if="priceUSD">
                      <span class="transaction-info__item-title">
                      {{ $t('donation-order.price_usd') }}
                      </span>
          <span class="transaction-info__item-value">
                          {{ priceUSD }}
                      </span>
        </div>
        <div class="transaction-info__item" v-if="nickname">
                      <span class="transaction-info__item-title">
                      {{ $t('donation-order.nickname') }}
                      </span>
          <span class="transaction-info__item-value">
                          {{ nickname }}
                      </span>
        </div>
        <div class="transaction-info__item" v-if="message">
                      <span class="transaction-info__item-title">
                      {{ $t('donation-order.message') }}
                      </span>
          <span class="transaction-info__item-value">
                          {{ message }}
                      </span>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

import {mapActions, mapState} from 'vuex';

import CurrencyImg from "/public/components/elements/CurrencyImg";
import CopyString from "/public/components/elements/CopyString";
import {preCheckoutPageOrderStatus, transactionHistoryStatus, transactionHistoryType} from "../../public/filters";
import BigNumber from "bignumber.js";

export default {
    name: 'DonationOrderInfo',
    components: {
        CurrencyImg,
        CopyString,
    },
    computed: {
        ...mapState('app', {
            currencies: state => state.currencies,
        }),
        ...mapState('donations', ['donationOrder']),
        addressToTransfer() {
            return this.$t(this.$options.filters.transferAddressType(this.donationOrder.addressTo));
        },
        addressFromTransfer() {
            return this.$t(this.$options.filters.transferAddressType(this.donationOrder.addressFrom));
        },
        isAddressToTransferType() {
            const ADDRESS_TYPE = {
                PAY_IN: 'transactions.transfer_type.pay_in',
                PAY_OUT: 'transactions.transfer_type.pay_out',
                ADVANCE: 'transactions.transfer_type.advance',
            };
            return ADDRESS_TYPE[this.donationOrder.addressTo] || false;
        },
        isAddressFromTransferType() {
            const ADDRESS_TYPE = {
                PAY_IN: 'transactions.transfer_type.pay_in',
                PAY_OUT: 'transactions.transfer_type.pay_out',
                ADVANCE: 'transactions.transfer_type.advance',
            };
            return ADDRESS_TYPE[this.donationOrder.addressFrom] || false;
        },
        id() {
            return this.donationOrder.id;
        },
        networkId() {
            return this.donationOrder.networkId;
        },
        status() {
            return preCheckoutPageOrderStatus(this.donationOrder.status);
        },
        type() {
            return transactionHistoryType(this.donationOrder.type);
        },
        priceUSD() {
            if (this.donationOrder.priceUSD) {
                const priceUSD = new BigNumber(this.donationOrder.priceUSD);
                return priceUSD.decimalPlaces(8).toNumber();
            }
            return null;
        },
        receivedUSD() {
            if (this.donationOrder.receivedUSD) {
                const receivedUSD = new BigNumber(this.donationOrder.receivedUSD);
                return receivedUSD.decimalPlaces(8).toNumber();
            }
            return null;
        },
        received() {
            if (this.donationOrder.received) {
                const received = new BigNumber(this.donationOrder.received);
                return received.decimalPlaces(8).toNumber();
            }
            return null;
        },
        nickname() {
            return this.donationOrder.nickname;
        },
        message() {
            return this.donationOrder.message;
        },
        createdAt() {
            return this.donationOrder.createdAt;
        },
        currency() {
            return this.donationOrder.currency;
        },
        pageId() {
            return this.donationOrder.pageId;
        },
        orderId() {
            return this.donationOrder.orderId;
        },
    },
    created() {
        const id = this.$route.params.id;
        this.fetchDonationOrderInfo({id});
    },
    methods: {
        ...mapActions('donations', ['fetchDonationOrderInfo']),
        goBack() {
            if (window.history.length > 2)
                this.$router.go(-1);
            else this.$router.push({name: 'requests'});
        },
    },
};

</script>

<style scoped lang="scss">
.transaction {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: var(--padding-left-container);
  padding-right: var(--padding-right-container);
  padding-top: var(--padding-top-container);

  &__info {
    margin-bottom: var(--margin-container-bottom);
    margin-top: var(--margin-container-top);
  }

  &__all {
    background: #0059F7;
    border-radius: 6px;
    border: none;
    width: 100%;
    max-width: 125px;
    min-height: 40px;
    align-self: flex-start;
    transition: all 0.3s ease-in;

    &:hover {
      background: var(--blue-400);
    }

    ::v-deep .at-btn__text {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: var(--white-100);
    }

    ::v-deep .at-btn__icon {
      font-size: var(--font-size-text-main);
    }
  }

  &__title {
    margin-bottom: 24px;
    min-height: 107px;
  }
}


@media screen and (max-width: 1180px) {
  .transaction {
    &__all {

    }
  }

}

@media screen and (max-width: 950px) {
  .transaction {
    padding-left: 20px;
    padding-right: 20px;
  }

}

@media screen and (max-width: 375px) {
  .transaction {
    padding-right: 0;
    padding-left: 0;

    &__all {
      margin-left: 10px;
    }
  }

}

.transaction-info {
  min-width: 764px;
  border-radius: 20px;
  box-shadow: 0px 8px 50px rgba(10, 17, 105, 0.08);
  margin-bottom: var(--margin-container-bottom);
  margin-top: var(--margin-container-top);
  border: 1px solid var(--border-color);

  &__title {
    min-height: 107px;
    background: var(--modal-header-bg);
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 60px;
    border-radius: 20px 20px 0 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: var(--primary-font-color);
  }

  &__info-container {
    background: var(--secondary-color);
    padding-left: 60px;
    padding-right: 60px;
    border-radius: 0 0 20px 20px;
  }

  &__item {
    min-height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 16px;
    padding-top: 16px;
    gap: 20px;

    &:last-of-type {
      border: none;
      padding-bottom: 40px;
    }

    &_transaction {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &__item-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--primary-font-color);
  }

  &__item-value {
    white-space: normal;
    word-break: break-all;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: var(--primary-font-color);
  }

}

@media screen and (max-width: 950px) {
  .transaction-info {
    max-width: 740px;
    min-width: 100%;

    &__title {
      padding-left: 10px;
      min-height: 47px;
      font-size: 16px;
      line-height: 19px;
    }

    &__info-container {
      padding-left: 10px;
      padding-right: 10px;
    }

    &__item {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
    }

    &__item-value {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }

    &__item-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }

}


</style>

