<template>
  <a
      class="merchant-area"
      href="/logout"
      @click="deleteDownCommissionState"
  >
    <Icon icon="log-out"/>
    <p class="merchant-area__logout">{{ $t('exit') }}</p>
  </a>
</template>

<script>

import Icon from "/public/components/elements/Icon.vue";

export default {
    name: 'HeaderMerchantArea',
    components: {
        Icon,
    },
    methods: {
        deleteDownCommissionState () {
            sessionStorage.removeItem('downCommission');
        },
    },
};

</script>

<style lang="scss" scoped>

.merchant-area {
  min-height: 46px;
  padding-left: 19px;
  padding-right: 19px;
  background: var(--blue-300);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in;

  &:hover {
    background: var(--blue-400);
  }

  &::v-deep .icon {
    font-size: var(--font-size-text-main);
    color: var(--white-100);
  }

  &__logout {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: #FFFFFF;
  }
}

</style>
