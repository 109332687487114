<template>
<div class="referralItem">
  <AtCollapse accordion>
    <AtCollapseItem>
      <div class="referralItem__header" slot="title">
        <div class="referralItem__header-item">
          <div class="referralItem__header-title">{{ $t('referral.referral') }}</div>
          <div class="referralItem__header-value">{{ value.email }}</div>
        </div>
        <div class="referralItem__header-item">
          <div class="referralItem__header-title">%</div>
          <div class="referralItem__header-value">{{ value.referencePercent }}</div>
        </div>
        <div class="referralItem__header-item">
          <div class="referralItem__header-title">24 h</div>
          <div class="referralItem__header-value"><span class="greenColor">+ {{ value.dailyIncome }}</span>USD</div>
        </div>
        <div class="referralItem__header-item">
          <div class="referralItem__header-title">{{ $t('referral.curr_month') }}</div>
          <div class="referralItem__header-value"><span class="greenColor">+ {{ value.monthIncome }}</span>USD</div>
        </div>
        <div class="referralItem__header-item">
          <div class="referralItem__header-title">{{ $t('referral.total') }}</div>
          <div class="referralItem__header-value"><span class="greenColor">+ {{ value.totalBalance }}</span>USD</div>
        </div>
      </div>
      <div class="referralItem__data">
        <template v-for="item in value.commissions">
          <div class="referralItem__data-item">
            <div class="referralItem__data-title">{{ feeTariffType(item.advReasonType) }}</div>
            <div class="referralItem__data-value">{{ item.referencePercent }}</div>
            <div class="referralItem__data-value"><span :class="[item.advReasonType === 'REFUND' ? 'redColor' : 'greenColor']">
              {{ item.advReasonType === 'REFUND' ? '- ' : '+ ' }} {{ item.dailyIncome }}
            </span>
              USD</div>
            <div class="referralItem__data-value"><span :class="[item.advReasonType === 'REFUND' ? 'redColor' : 'greenColor']">
              {{ item.advReasonType === 'REFUND' ? '- ' : '+ ' }} {{ item.monthIncome }}
            </span>
              USD</div>
            <div class="referralItem__data-value"><span :class="[item.advReasonType === 'REFUND' ? 'redColor' : 'greenColor']">
              {{ item.advReasonType === 'REFUND' ? '- ' : '+ ' }} {{ item.totalBalance }}
            </span>
              USD</div>
          </div>
          <div class="referralItem__data-item--mobile">
            <div class="referralItem__data-title referralItem__data-title--mobile">{{ feeTariffType(item.advReasonType) }}</div>
            <div class="referralItem__data-info--mobile">
              <div class="referralItem__header-title">%</div>
              <div class="referralItem__data-value">{{ item.referencePercent }}</div>
            </div>
            <div class="referralItem__data-info--mobile">
              <div class="referralItem__header-title">24h</div>
              <div class="referralItem__data-value">
                <span :class="[item.dailyIncome > 0 ? 'greenColor' : '']">
                  {{ item.advReasonType === 'REFUND' ? '- ' : '+ ' }} {{ item.dailyIncome }}
                </span>
                USD</div>
            </div>
            <div class="referralItem__data-info--mobile">
              <div class="referralItem__header-title">{{ $t('referral.curr_month') }}</div>
              <div class="referralItem__data-value">
                <span :class="[item.monthIncome > 0 ? 'greenColor' : '']">
                  {{ item.advReasonType === 'REFUND' ? '- ' : '+ ' }} {{ item.monthIncome }}
                </span>
                USD</div>
            </div>
            <div class="referralItem__data-info--mobile">
              <div class="referralItem__header-title">{{ $t('referral.total') }}</div>
              <div class="referralItem__data-value">
                <span :class="[item.totalBalance > 0 ? 'greenColor' : '']">
                  {{ item.advReasonType === 'REFUND' ? '- ' : '+ ' }} {{ item.totalBalance }}
                </span>
                USD</div>
            </div>
          </div>
        </template>
      </div>
    </AtCollapseItem>
  </AtCollapse>
</div>
</template>

<script>
export default {
    name: "ReferralItem",
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
    },
    methods: {
        feeTariffType(tariff) {
            const feeTariffType = {
                'ORDER_DEPOSIT_FEE': 'referral-commission-type.deposit_by_order_fee',
                'WALLET_WITHDRAWAL_FEE': "referral-commission-type.wallet_withdrawal_fee",
                'PAYOUT_WITHDRAWAL_FEE': "referral-commission-type.payout_withdrawal_fee",
                'WALLET_DEPOSIT_FEE': "referral-commission-type.deposit_by_wallet_fee",
                'PAYOUT_DEPOSIT_FEE': "referral-commission-type.payout_deposit_fee",
                'BRIDGE_EXTERNAL_FEE': 'referral-commission-type.bridge_external_fee',
                'BRIDGE_INTERNAL_FEE': 'referral-commission-type.bridge_internal_fee',
                'EXCHANGE_INTERNAL_FEE': "referral-commission-type.exchange_internal_fee",
                'EXCHANGE_AUTO_FEE': "referral-commission-type.exchange_auto_fee",
                'REFUND': 'referral-commission-type.refund',
                'RECURRENT_DEPOSIT_FEE': 'referral-commission-type.recurrent_deposit_fee',
                'RECURRENT_WITHDRAWAL_FEE': 'referral-commission-type.recurrent_withdrawal_fee',
                'KYT_TRANSACTION_FEE': 'advance_balances.kyt-transaction-fee',
                'KYT_WITHDRAWAL_ADDRESS_FEE': 'advance_balances.kyt-withdrawal-address-fee',
                'EXCHANGE_AUTO': "advance_balances.exchange-auto",
            };
            return this.$t(feeTariffType[tariff]) || '-';
        },
    },
};
</script>

<style lang="scss" scoped>
.referralItem {
  margin-top: 10px;
  background: var(--secondary-color);
  /* Gray 6 */

  border: 1px solid var(--border-color);
  box-shadow: 0 8px 50px rgba(10, 17, 105, 0.08);
  border-radius: 20px;
  &__header {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    @media (max-width: 950px) {
      grid-template-columns: 1fr;
    }
    &-item {
      @media (max-width: 950px) {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--border-color);
        padding: 10px 0;
        &:last-child {
          border-bottom: none;
        }
      }
    }
    &-title {
      font-weight: 300;
      font-size: 14px;
      color: var(--secondary-font-color);
      margin-bottom: 10px;
      @media (max-width: 950px) {
        margin-bottom: 0;
      }
    }
    &-value {
      font-weight: 400;
      font-size: 16px;
      color: var(--primary-font-color);
    }
  }

  &__data {
    padding: 0 30px;
    &-item {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
      border-bottom: 1px solid var(--border-color);
      padding: 17px 0;
      @media (max-width: 950px) {
        display: none;
      }
      &--mobile {
        @media (min-width: 950px) {
          display: none;
        }
      }
    }
    &-title {
      font-weight: 300;
      font-size: 16px;
      color: var(--secondary-font-color);
      &--mobile {
        margin: 30px 0 15px 0;
        text-align: center;
      }
    }
    &-value {
      font-weight: 400;
      font-size: 16px;
      color: var(--primary-font-color);
    }
    &-info--mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--border-color);
      padding: 15px 0;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
  ::v-deep .at-collapse {
    background: var(--secondary-color);
    /* Gray 6 */

    border: 1px solid var(--border-color);
    box-shadow: 0 8px 50px rgba(10, 17, 105, 0.08);
    border-radius: 20px;
  }

  ::v-deep .at-collapse__icon {
    right: 30px;
    top: 32px;
    font-size: 20px;
    left: unset;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    width: fit-content;
    height: fit-content;
    @media (max-width: 950px) {
      right: unset;
      top: unset;
      left: 45%;
      bottom: 0;
    }
  }
  ::v-deep .at-collapse__item {
    @media (max-width: 950px) {
      position: relative;
    }
  }
  ::v-deep .at-collapse__item--active > .at-collapse__header .at-collapse__icon {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  ::v-deep .at-collapse__header {
    background: var(--secondary-color);
    padding: 20px 30px;
    @media (max-width: 950px) {
      padding: 0 10px 15px;
      position: unset;
    }
  }
  ::v-deep .at-collapse__content  {
    padding: 0;
    border-top: 1px solid var(--border-color);
    background: var(--secondary-color);
  }
}
.redColor {
  color: #ED6A5E;
}
.greenColor {
  color:#27AE60;
  margin-right: 5px;
}
</style>
