export const state = {
    invoicesCountOnPage: 10,
    invoices: [],
    totalInvoicesCount: 0,
    filters: {
        status: [],
    },
    additionalFees: [],
    orderTransaction: null,
    isLoading: false,
    invoice: null,
    isInfoOpened: false,
};
