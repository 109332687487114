import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!, $offset: Int, $limit: Int) {
         getBankAccounts(organizationId: $organizationId, offset: $offset, limit: $limit) {
         size
         rows {
                id
                organizationId
                alias
                type
                status
                moderationMessage
                currencyId
                iban
                bic
                account
                companyName
                bankAddress
                bankName
                bankCountryCode
                postalCode
                createdAt
            }
        }
    }
`;
