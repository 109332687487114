<template>
<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_8298_57238)">
    <path d="M-6.5 225.501C119.333 197.001 368.9 168.501 360.5 282.501C350 425.001 279.5 460.5 442.5 543.5C605.5 626.5 1365.5 370.5 1663.5 440C1901.9 495.6 2016.5 596.833 2044 640.5" :stroke="lineColor"/>
    <path d="M-33.5 241.501C80.8333 227.167 302.8 225.001 276 331.001C242.5 463.501 177.5 538 382.5 601.5C587.5 665 1122.5 555 1404.5 465.5C1630.1 393.9 1892.83 556.333 1996 646.5" :stroke="lineColor"/>
    <path d="M-37.5 157.5C98.1667 128.333 394.1 80.8001 492.5 124C615.5 178 520.5 296 630.5 329.5C740.5 363 940 392.5 1440 284.5C1840 198.1 2171.33 443.833 2287 577.5" :stroke="lineColor"/>
    <path d="M1936.5 294C1884.67 257.667 1712.4 195.9 1438 239.5C1095 294 658.5 359 636 187.5C618 50.3001 187.167 90.6668 -26 128" :stroke="lineColor"/>
    <path d="M-23.5 66.9996C262.333 27.1663 826.9 -26.6004 798.5 76.9996C763 206.5 1114.5 258.5 1429 172.5C1680.6 103.7 1924.17 154.5 2014.5 188.5" :stroke="lineColor"/>
    <path d="M1938 143.501C1886 109.334 1675.4 66.0009 1249 166.001C1120 189.334 857.1 200.301 837.5 57.5009C817.9 -85.2991 95.3333 19.6676 -263.5 90.0009" :stroke="lineColor"/>
    <path d="M954 -33.5C960.167 44.3333 1112.4 168 1672 40C2231.6 -88 3350.83 896.667 3840.5 1405" :stroke="lineColor"/>
    <path d="M-9.5 882.5C110.5 928.167 463.8 963.6 917 740C1370.2 516.4 1865.83 852.167 2057 1048" :stroke="lineColor"/>
    <path d="M-41.5 1012.5C68 1026.5 390.9 1011.3 806.5 838.499C1326 622.499 1812 815.499 1948.5 1109" :stroke="lineColor"/>
  </g>
  <defs>
    <clipPath id="clip0_8298_57238">
      <rect width="1920" height="1080" fill="white"/>
    </clipPath>
  </defs>
</svg>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: "LinesBackground",
    computed: {
      ...mapState('app', ['theme']),
      lineColor () {
        return this.theme === 'light' ? '#C3B2F5' : '#462666'
      }
    }
  }
</script>

<style scoped>

</style>
