<script>
    import vClickOutside from "v-click-outside";
    import {mapState} from "vuex";
    import Popper from 'vue-popperjs';
    import 'vue-popperjs/dist/vue-popper.css';

    export default{
        name: "ActionsPopup",
        directives: {
            clickOutside: vClickOutside.directive
        },
        components: {
            'popper': Popper
        },
        props: {
            closeOutside: {
                type: Boolean,
                default: true
            },
        },
        data () {
            return {
                isModalOpen: false
            }
        },
        computed: {
            ...mapState(['themeStatus']),
        },
        methods: {
            emitClick (type) {
                this.$emit('click', type)
                this.closeModal()
            },
            toggleFilter (event) {
                this.isModalOpen = !this.isModalOpen
            },
            confirm () {
                this.$emit('confirm')
                this.isModalOpen = false
            },
            closeModal (event) {
                // if (event?._prevClass === 'settings__trigger' || event?.parentElement._prevClass === 'settings__trigger') return;
                this.isModalOpen = false
            },
        }
    }
</script>

<template>
<div class="actions">
    <transition name="slide-fade">
        <popper
            trigger="clickToOpen"
            :options="{
      placement: 'left-start',
    }"
        >
            <div class="actions__trigger" slot="reference" ref="actionsTrigger">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.5 3C6.5 2.17157 7.17157 1.5 8 1.5C8.82843 1.5 9.5 2.17157 9.5 3C9.5 3.82843 8.82843 4.5 8 4.5C7.17157 4.5 6.5 3.82843 6.5 3ZM6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8ZM6.5 13C6.5 12.1716 7.17157 11.5 8 11.5C8.82843 11.5 9.5 12.1716 9.5 13C9.5 13.8284 8.82843 14.5 8 14.5C7.17157 14.5 6.5 13.8284 6.5 13Z" fill="var(--new-front-primary-font-color)"/>
                </svg>
            </div>
          <div class="actions__modal">
            <slot/>
          </div>
        </popper>
    </transition>
</div>
</template>

<style scoped lang="scss">
.actions {
    position: relative;
    z-index: 3;
  &__trigger {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      cursor: pointer;
      z-index: 1;
  }
  &__modal {
      position: absolute;
      right: -5px;
      top: 5px;
      background: var(--new-front-main-bg);
      border: 1px solid var(--new-front-input-border);
      min-width: 100px;
      width: fit-content;
      max-width: 160px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 10px;
      z-index: 5;
      overflow: hidden;
    &-item {
      display: flex;
      align-items: center;
      gap: 10px;
      min-width: 100px;
      width: 100%;
      border-bottom: 1px solid var(--new-front-input-border);
      color: var(--new-front-secondary-font-color);
      font-weight: 500;
      padding: $p10;
      transition: all .2s ease;
      cursor: pointer;
      &:hover {
        color:var(--new-front-primary-font-color) ;
        background: var(--new-font-nav-hover-bg);
        &::v-deep svg {
          path {
            fill: #744DB2 !important;
          }
        }
      }
      &:last-of-type {
        border-bottom: unset;
      }
    }
  }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
    transition: all .3s ease;
}
.slide-fade-leave-active {
    transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(10px);
    opacity: 0;
}
</style>
