import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!) {
        getUserOrganizationRole(organizationId: $organizationId) {
            id
            name
            comment
            rules
        }
    }
`;

