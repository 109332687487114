<template>
  <div class="loader" :style="`
  --width: ${width}px;
  --height: ${height}px;
  --bg-color: var(${background})
  `">
  </div>
</template>

<script>
export default {
    name: "preloader-dots",
    props: {
        width: {
            type: Number,
            default: 10,
        },
        height: {
            type: Number,
            default: 10,
        },
        background: {
            type: String,
            default: '--white-100',
        },
    },
};
</script>

<style scoped lang="scss">

.loader {
  position: relative;
  height: 20px;
  width: 20px;
  display: inline-block;
  animation: around 5.4s infinite;
}

@keyframes around {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

.loader::after, .loader::before {
  content: "";
  background: transparent;
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-color: #FFFFFF #FFFFFF transparent transparent;
  border-style: solid;
  border-radius: 20px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  animation: around 0.7s ease-in-out infinite;
}

.loader::after {
  animation: around 0.7s ease-in-out 0.1s infinite;
  background: transparent;
}
</style>
