<template>
  <div
    class="btn-toggler"
    @click="toggle"
  >
    <div class="inside-btn" :class="{ active: !value }">
      <slot name="left-btn-icon" v-bind="{ active: !value }" />
    </div>
    <div class="inside-btn" :class="{ active: value }">
      <slot name="right-btn-icon" v-bind="{ active: value }" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BtnToggler',
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    toggle() {
      this.$emit('input', !this.value)
    }
  }
}
</script>

<style scoped lang="scss">
  .btn-toggler {
    width: 95px;
    height: 48px;

    padding: 4px 5px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: var(--new-front-modal-bg);
    border: 1px solid var(--new-front-border-bg-secondary);
    border-radius: 10px;

    cursor: pointer;

    .inside-btn {
      width: 40px;
      height: 40px;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 10px;

      &.active {
        background: var(--new-front-btn-bg-primary);
      }
    }
  }
</style>