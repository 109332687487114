<template>
    <div class="transaction-info">
        <div class="transaction-info__item">
            <span class="transaction-info__item-title">
                id ордера:
            </span>
            <span class="transaction-info__item-value">
                <RouterLink
                    v-if="orderId"
                    :to="{ name: 'order', params: { id: orderId } }"
                >
                    {{ orderId }}
                </RouterLink>
                <template v-else>
                    -
                </template>
            </span>
        </div>
        <div class="transaction-info__item">
            <span class="transaction-info__item-title">
                id в системе:
            </span>
            <span class="transaction-info__item-value">
                {{ id }}
            </span>
        </div>
        <div class="transaction-info__item">
            <span class="transaction-info__item-title">
                Валюта:
            </span>
            <span class="transaction-info__item-value transaction-info__item-value--currency">
                <CurrencyImg
                    class="transaction-info__currency-img"
                    :currency="currencyFrom"
                />
                {{ currencyFrom }}
            </span>
        </div>
        <div class="transaction-info__item">
            <span class="transaction-info__item-title">
                Сумма:
            </span>
            <span class="transaction-info__item-value">
                {{ amountFrom }}
            </span>
        </div>
        <div class="transaction-info__item">
            <span class="transaction-info__item-title">
                Адрес отправки:
            </span>
            <span class="transaction-info__item-value">
                {{ addressTo }}
            </span>
        </div>
        <div class="transaction-info__item">
            <span class="transaction-info__item-title">
                Тег адреса отправки:
            </span>
            <span class="transaction-info__item-value">
                {{ tagTo }}
            </span>
        </div>
        <div class="transaction-info__item">
            <span class="transaction-info__item-title">
                id в блокчейне:
            </span>
            <span class="transaction-info__item-value">
                {{ txId }}
            </span>
        </div>
        <div class="transaction-info__item">
            <span class="transaction-info__item-title">
                Ссылка на транзакцию:
            </span>
            <span class="transaction-info__item-value"></span>
        </div>
        <div class="transaction-info__item">
            <span class="transaction-info__item-title">
                Дата создания:
            </span>
            <span class="transaction-info__item-value">
                {{ createdAt }}
            </span>
        </div>
    </div>
</template>

<script>

import Moment from "moment";
import { mapGetters } from 'vuex';

import CurrencyImg from "/public/components/elements/CurrencyImg";

export default {
    name: 'WithdrawalTransactionInfo',
    components: {
        CurrencyImg,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters('app', [
            'getCurrencyNameById',
        ]),
        orderId () {
            return this.data.orderId;
        },
        id () {
            return this.data.id;
        },
        currencyFrom () {
            const {
                data: { currencyFrom },
                getCurrencyNameById,
            } = this;

            return getCurrencyNameById(currencyFrom);
        },
        amountFrom () {
            return this.data.amountFrom;
        },
        addressTo () {
            return this.data.addressTo;
        },
        tagTo () {
            return this.data.tagTo || '-';
        },
        txId () {
            return this.data.txId || '-';
        },
        createdAt () {
            return Moment(Number(this.data.createdAt)).format('DD.MM.YYYY, HH:mm');
        },
    },
};

</script>