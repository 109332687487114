<template>
<base-select
    v-bind="$attrs"
    v-model="valueModel"
    :label="label"
    :placeholder="placeholder"
    :list="selectedList"
    :with-search="false"
    class="advanced_balances__network-select"
    uniq-key="value"
    @closed="search = undefined"
>
  <template #selected="data">
    <div class="item">
      {{ data.name }} <span class="item-zone">{{ data.zone }}</span>
    </div>
  </template>
  <template v-if="withSearch" #search>
    <network-and-currency-search
        id="search-input"
        class="search"
        v-model="search"
    />
  </template>
  <template #info="data">
    <div class="item">
      {{ data.name }} <span class="item-zone">{{ data.zone }}</span>
    </div>
<!--    <net-curr-item-->
<!--        v-bind="data"-->
<!--        height="22px"-->
<!--        :showCurrencyName="showCurrencyName"-->
<!--    />-->
  </template>
</base-select>
</template>

<script>
  import { mapState } from "vuex";
  import placeholder from "lodash/fp/placeholder";
  import NetworkAndCurrencySearch from "@/public/components/common/uiKit/search/index.vue";
  const timeZones = [
    { name: 'Afghanistan', zone: 'UTC +04:30', value: 270 },
    { name: 'Alaska', zone: 'UTC -09:00', value: -540 },
    { name: 'Arabian Standard Time', zone: 'UTC +03:00', value: 180 },
    { name: 'Argentina', zone: 'UTC -03:00', value: -180 },
    { name: 'Australia Central', zone: 'UTC +09:30', value: 570 },
    { name: 'Australia Eastern', zone: 'UTC +10:00', value: 600 },
    { name: 'Australia Western', zone: 'UTC +08:00', value: 480 },
    { name: 'Bangladesh', zone: 'UTC +06:00', value: 360 },
    { name: 'Brazil', zone: 'UTC -03:00', value: -180 },
    { name: 'British', zone: 'UTC +00:00', value: 0 },
    { name: 'Canada Atlantic', zone: 'UTC -04:00', value: -240 },
    { name: 'Canada Central', zone: 'UTC -06:00', value: -360 },
    { name: 'Canada Eastern', zone: 'UTC -05:00', value: -300 },
    { name: 'Canada Mountain', zone: 'UTC -07:00', value: -420 },
    { name: 'Canada Pacific', zone: 'UTC -08:00', value: -480 },
    { name: 'Central European', zone: 'UTC +01:00', value: 60 },
    { name: 'China', zone: 'UTC +08:00', value: 480 },
    { name: 'Dubai', zone: 'UTC +04:00', value: 240 },
    { name: 'Eastern European', zone: 'UTC +02:00', value: 120 },
    { name: 'Greenwich Mean Time', zone: 'UTC +00:00', value: 0 },
    { name: 'Hawaii', zone: 'UTC -10:00', value: -600 },
    { name: 'India Standard Time', zone: 'UTC +05:30', value: 330 },
    { name: 'Japan', zone: 'UTC +09:00', value: 540 },
    { name: 'Korea', zone: 'UTC +09:00', value: 540 },
    { name: 'Moscow Standard Time', zone: 'UTC +03:00', value: 180 },
    { name: 'New Zealand', zone: 'UTC +12:00', value: 720 },
    { name: 'Philippine', zone: 'UTC +08:00', value: 480 },
    { name: 'Singapore', zone: 'UTC +08:00', value: 480 },
    { name: 'South Africa Standard Time', zone: 'UTC +02:00', value: 120 },
    { name: 'Taiwan', zone: 'UTC +08:00', value: 480 },
    { name: 'Thailand', zone: 'UTC +07:00', value: 420 },
    { name: 'US Eastern', zone: 'UTC -05:00', value: -300 },
    { name: 'US Mountain', zone: 'UTC -07:00', value: -420 },
    { name: 'US Pacific', zone: 'UTC -08:00', value: -480 },
    { name: 'Venezuela', zone: 'UTC -04:30', value: -270 },
    { name: 'Western European', zone: 'UTC +00:00', value: 0 }
  ]
  export default {
    name: "OrganizationInformationTimeZoneSelect",
    components: {
      NetworkAndCurrencySearch,
      BaseSelect: () => import(/* webpackChunkName: "/public/components/elements/advance-balances/bh-select" */ '/public/components/elements/advance-balances/bh-select'),
    },
    data() {
      return {
        search: undefined
      }
    },
    props: {
      value: {
        type: [ String, Object ],
        default: () => {}
      },
      withSearch: {
        type: Boolean,
        default: true
      },
      label: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
    },
    computed: {
      ...mapState({
        currentCurrencyInfo: state => state.currency,
      }),
      valueModel: {
        get({ value }) {
          return value
        },
        set(v) {
          this.$emit('input', v)
        }
      },
      selectedList () {
        return this.filteredList
      },
      filteredList({ search }) {
        //метод фильтрации общего списка валют
        if (search) {
          return timeZones.filter(({name, value, zone}) => {
            const zoneName = name ? name.toLowerCase() : ''
            const zoneValue = value ? value.toLowerCase() : ''
            const zoneDesc = zone ? zone.toLowerCase() : ''

            return zoneName.includes(search.toLowerCase()) || zoneValue.includes(search.toLowerCase()) || zoneDesc.includes(search.toLowerCase())
          })
        } else {
          return timeZones
        }
      }
    },
  }
</script>

<style scoped lang="scss">
.item {
  //height: 48px;
  //border-radius: 10px;
  //border: 1px solid var(--border-border-input-primary-default, #464646);
  //background: var(--bg-background-secondary, #202020);
  color: var(--text-text-primary, #FFF);
  font-family: $Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
  &-zone {
    color: var(--text-color-text-secondary, #7E7E7E);
  }
}
.advanced_balances {
  &__network-select {
    margin: 20px 0;

    .search {
      margin-bottom: 15px;
    }
  }
}
.advanced_balances__network-select {
  ::v-deep  .label {
    color: var(--new-front-input-label-font-color)!important;
  }
}
</style>
