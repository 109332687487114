import gql from 'graphql-tag';

export default gql`
    mutation createOrder(
        $organizationId: String!,
        $accountId: String!, 
        $network: String!, 
        $currency: String!, 
        $amount: String!, 
        $availableTill: String, 
        $errorWebhook: String, 
        $successWebhook: String, 
        $returnUrl: String, 
        $order: String, 
        $description: String,
        $lifetime: Number
    ) {
        createOrder(
            organizationId: $organizationId,
            accountId: $accountId,
            network: $network,
            currency: $currency, 
            amount: $amount, 
            availableTill: $availableTill, 
            errorWebhook: $errorWebhook, 
            successWebhook: $successWebhook, 
            returnUrl: $returnUrl, 
            order: $order, 
            description: $description,
            lifetime: $lifetime
        ) {
            code
            result {
                id
                address
                currency
                networkId
                amount
                expiresAt
                link
            }
        }
    }
`;
