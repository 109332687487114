<template>
  <div class="transactions-sort-by-type">
    <p class="transactions-sort-by-type__title">
      Статус
    </p>
    <AtSelect
        class="transactions-sort-by-type__select"
        placeholder="Выберите статус"

        clearable
        v-model="origSelectedStatuses"
        @on-change="selectStatuses"
    >
      <AtOption
          v-for="(status, key) in statuses"
          :key="key"
          :value="status.status"
      >{{ status.title }}
      </AtOption>
    </AtSelect>
  </div>
</template>

<script>

import {mapActions} from 'vuex';

import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';

import {ROLLING_RESERVE_HISTORY_STATUS_ENUM} from "../../../../common/constants/rolling-reserve-history-status-enum";

export const translated = [
    {
        status: ROLLING_RESERVE_HISTORY_STATUS_ENUM.AWAIT,
        title: 'В ожидании',
        color: 'info',
    },
    {
        status: ROLLING_RESERVE_HISTORY_STATUS_ENUM.RETURN,
        title: 'Возвращены',
        color: 'success',
    },
    {
        status: ROLLING_RESERVE_HISTORY_STATUS_ENUM.HOLD,
        title: 'Удержаны',
        color: 'fail',
    },
];

export default {
    name: 'RollingReserveSortByStatus',
    components: {
        AtSelect,
        AtOption,
    },
    data() {
        return {
            origSelectedStatuses: [],
        };
    },
    computed: {
        statuses() {
            return translated;
        },
    },
    mounted() {
        this.setStatusesFromUser();
    },
    methods: {
        ...mapActions('rollingReserve', [
            'fetchRollingReserves',
        ]),
        setStatusesFromUser() {
            const statuses = this.$route.query.statuses;

            if (!statuses) {
                return false;
            }

            return this.origSelectedStatuses = statuses.split(',');
        },
        selectStatuses(items) {
            const {
                $route,
                $router,
                fetchRollingReserves,
            } = this;

            fetchRollingReserves({
                route: $route,
                router: $router,
                statuses: this.origSelectedStatuses,
                excludeParams: ['page'],
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.transactions-sort-by-type {
  &__title {
    margin-bottom: 8px;
    font-size: var(--font-size-text-main);
    font-family: var(--font-family);
    line-height: var(--line-height);
    color: var(--black-200);
  }

  &__select ::v-deep .at-select__selection {
    min-height: 28px;
  }

  ::v-deep .at-select__dropdown .at-select__option {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

  ::v-deep .at-select__placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }

  ::v-deep .at-select__selection {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

}

</style>
