import Moment from "moment";

import getDashboard_24Query from "/public/graphQl/queries/getDashboard_24";
// import getBalancesQuery from "/public/graphQl/queries/getBalances";
import getTransactionsQuery from "/public/graphQl/queries/getTransactions";
import getOrdersQuery from "/public/graphQl/queries/getOrders";
import getWithdrawalRequestsQuery from "/public/graphQl/queries/getWithdrawalRequests";

function dashboardDates() {
    let date = {
        from: Moment().add(-1, 'd').toISOString(),
        to: Moment().toISOString(),
    };

    return [
        date.from,
        date.to,
    ];
}

export const fetchDashboard = async ({commit, rootGetters, dispatch}, payload) => {
    /** Даты если не переданы указывваю начальные значения */
    commit('app/toggleLoading', null, {root: true});
    const dates = dashboardDates();
    commit('dates', dates);

    /** Получение данных для счетчик ордеров */
    let dashboardData;

    const organizationId = rootGetters['app/getSelectedOrganization']?.id;

    const variables = {
        organizationId,
        // dateFrom: Moment(dates[0]).startOf('day').toISOString(),
        // dateTo:   Moment(dates[1]).endOf('day').toISOString(),
        // dateFrom: dates[0],
        // dateTo:  dates[1],
    };

    try {
        dashboardData = await dispatch('app/request', {
            type: 'query',
            gql: getDashboard_24Query,
            variables,
        }, {root: true});
    } catch (error) {

        commit('app/toggleLoading', null, {root: true});
        return error;
    }
    if (dashboardData.message) throw dashboardData.message;
    if (dashboardData.errors) throw dashboardData.errors;

    const {
        data: {

            dashboard: {
                orders,
                payments,
                transactions: transactionCount,
            },
        },
    } = dashboardData;

    commit('orders', orders);
    commit('payments', payments);
    commit('transactionsCount', transactionCount);

    /** Получение балансов */
    // let balancesData;
    //
    // try {
    //     balancesData = await dispatch('app/request', {
    //         type: 'query',
    //         gql: getBalancesQuery,
    //     }, { root: true });
    // } catch (error) {
    //     return error;
    // }
    //
    // if (balancesData.message) throw balancesData.message;
    // if (balancesData.errors) throw balancesData.errors;
    //
    // const {
    //     data: { getBalances },
    // } = balancesData;
    //
    // const balances = getBalances.filter(balance => {
    //     return balance.amount > 0;
    // });
    //
    // commit('balances', balances);

    /** получение транзакций */
    let transactions = [];
    for (let type of ['deposit', 'withdrawal']) {
        let transactionsData;
        const transactionsVariables = {
            organizationId,
            limit: 25,
            offset: 1,
            type: [type],
            currenciesFrom: [],
            currenciesTo: [],
            date: [variables.dateFrom, variables.dateTo],
        };
        try {
            transactionsData = await dispatch('app/request', {
                type: 'query',
                gql: getTransactionsQuery,
                variables: transactionsVariables,
            }, {root: true});
        } catch (error) {
            commit('app/toggleLoading', null, {root: true});
            return error;
        }


        // if (transactionsData.message) throw transactionsData.message;
        // if (transactionsData.errors) throw transactionsData.errors;

        // Если нет доступа или данных - незачем блокировать всю страницу
        if (transactionsData.message || transactionsData.errors) continue;

        const {
            data: {
                getTransactions: {
                    transactions: tr,
                },
            },
        } = transactionsData;

        transactions = transactions.concat(tr);
    }
    commit('transactions', transactions);

    /** Входящие оплаты */
    let depositData = [];
    const depositVariables = {
        organizationId,
        limit: 25,
        offset: 1,
        status: ['processed'],
        currencies: [],
        date: [variables.dateFrom, variables.dateTo],
    };
    try {
        depositData = await dispatch('app/request', {
            type: 'query',
            gql: getOrdersQuery,
            variables: depositVariables,
        }, {root: true});
    } catch (error) {
        commit('app/toggleLoading', null, {root: true});
        return error;
    }

    // Если нет доступа или данных - незачем блокировать всю страницу
    // if (depositData.message) throw depositData.message;
    // if (depositData.errors) throw depositData.errors;

    if (!(depositData.message || depositData.errors)) {
        const {
            data: {
                getOrders: {
                    orders: deposit,
                },
            },
        } = depositData;

        commit('deposit', deposit);
    }


    /** Исходящие выводы */
    const withdrawalVariables = {
        organizationId,
        limit: 25,
        offset: 1,
        status: ['processed'],
        platform: [],
        currencies: [],
        date: [variables.dateFrom, variables.dateTo],
    };
    let withdrawalData;
    try {

        withdrawalData = await dispatch('app/request', {
            type: 'query',
            gql: getWithdrawalRequestsQuery,
            variables: withdrawalVariables,
        }, {root: true});
    } catch (error) {
        commit('app/toggleLoading', null, {root: true});
        return error;
    }

    // Если нет доступа или данных - незачем блокировать всю страницу
    // if (withdrawalData.message) throw withdrawalData.message;
    // if (withdrawalData.errors) throw withdrawalData.errors;

    if (!(withdrawalData.message || withdrawalData.errors)) {
        const {
            data: {
                getWithdrawalRequests: {
                    requests: withdrawal,
                },
            },
        } = withdrawalData;

        commit('withdrawal', withdrawal);
    }

    commit('app/toggleLoading', null, {root: true});
    return true;
};

