<template>
    <div class="historyBusiness">
        <HistoryAddress/>
    </div>
</template>

<script>
import HistoryAddress from "../components/elements/history-addresses/HistoryAddress";

export default {
    name: "historyBusiness",

    components: {
        HistoryAddress,
    },
};
</script>

<style scoped lang="scss">

</style>