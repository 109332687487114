import sendWebhookQuery from "@/public/graphQl/mutations/sendWebhook";
import createCollectingSettings from "@/public/graphQl/mutations/createCollectingSettings";
import {getErrorMessage} from "@/public/filters";

export const createSetting = async ({dispatch, commit, rootState, rootGetters}, payload) => {
    let settingResponse;

    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    try {
        commit('app/toggleLoading', null, {root: true});
        settingResponse = await dispatch('app/request', {
            type: 'mutation',
            gql: createCollectingSettings,
            variables: {organizationId, ...payload},
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }
    if (settingResponse.graphQLErrors) {
        if (settingResponse.graphQLErrors[0].extensions.code === "INCORRECT_2FA") {
            commit('setSetting', { errorMessage: getErrorMessage(settingResponse.graphQLErrors[0].extensions.code)})
            return
        }
        commit('setSetting', {code: 'error', errorMessage: withdrawalRequestData.graphQLErrors[0].message})
        return
    }
    const { data: { createCollectingSetting: { code, result, traceId } } } = settingResponse

    if (code !== 0) {
        commit('setSetting', { code, ...result, errorMessage: getErrorMessage(code)})
        return
    }


    commit('setSetting', {code, ...result, errorMessage: ''})
    return settingResponse;
};
