<template>
    <div :class="['balance-card', isOpened ? 'balance-card--active' : '']">
      <div class="balance-card__header">
        <div class="balance-card__header-currency-container">
          <icon class="balance-card__header-currency-image" :currency="getCurrencyTicker(balance.currencyId).toUpperCase()"/>
          <p class="balance-card__header-currency-ticker">{{ balance.currencyId | getCurrencyTicker }}</p>
        </div>
        <div class="balance-card__header-text-container balance-card__header-text-container_networks mobileHidden">
          <p class="balance-card__header-text-title">{{ $t('addresses.card.title.network') }}&#x2009;</p>
          <p class="balance-card__header-text-value">{{ networksCount }}</p>
        </div>
        <div class="balance-card__header-text-container balance-card__header-text-container_addresses mobileHidden">
          <p class="balance-card__header-text-title">{{ $t('addresses.card.title.addresses') }}&#x2009;</p>
          <p class="balance-card__header-text-value">{{ addressesCount }}</p>
        </div>
        <div class="balance-card__header-text-container balance-card__header-text-container_balance">
          <p class="balance-card__header-text-title">{{ $t('addresses.card.title.balance') }}&#x2009;</p>
          <p class="balance-card__header-text-value">{{ balance.balance | separatePeriods }}</p>
        </div>
          <div @click="toggleOpen" class="balance-card__header-chevron-container">
              <svg :class="['balance-card__header-chevron', isOpened ? 'balance-card__header-chevron--active' : '']" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.228 1.635C0.085 1.462 0 1.241 0 1C0 0.448 0.448 0 1 0H7C7.552 0 8 0.448 8 1C8 1.241 7.915 1.462 7.772 1.635L4.808 5.589C4.626 5.838 4.332 6 4 6C3.668 6 3.374 5.838 3.192 5.589L0.228 1.635Z" fill="var(--new-front-primary-font-color)"/>
              </svg>
          </div>

      </div>

      <div class="balance-card__header balance-card__header_mobile">

        <div class="balance-card__header-top-container">
          <div class="balance-card__header-currency-container">
            <p class="balance-card__header-currency-title">{{ $t('addresses.card.title.currency') }}</p>
            <CurrencyImg class="balance-card__header-currency-image" :currency="balance.currencyId"/>
            <p class="balance-card__header-currency-ticker">{{ balance.currencyId | getCurrencyTicker }}</p>
          </div>

          <div class="balance-card__header-info-container">
            <div class="balance-card__header-text-container balance-card__header-text-container_balance">
              <p class="balance-card__header-text-title">{{ $t('addresses.card.title.balance') }}&#x2009;</p>
              <p class="balance-card__header-text-value">{{ balance.balance | separatePeriods }}</p>
            </div>
          </div>


        </div>
        <div class="balance-card__header-detailed-info">
          <div class="balance-card__header-text-container balance-card__header-text-container_networks">
            <p class="balance-card__header-text-title">{{ $t('addresses.card.title.network') }}&#x2009;</p>
            <p class="balance-card__header-text-value">{{ networksCount }}</p>
          </div>
          <div class="balance-card__header-text-container balance-card__header-text-container_addresses">
            <p class="balance-card__header-text-title">{{ $t('addresses.card.title.addresses') }}&#x2009;</p>
            <p class="balance-card__header-text-value">{{ addressesCount }}</p>
          </div>
        </div>

      </div>
<!--        <BalancesListHeader class="balances__list-header"/>-->
        <div class="balance-card__list">
            <balance-card-network-list v-for="network in balance.networkGroups" :currency-id="balance.currencyId" :network-list="network"
                                       :key="network.networkId" @openNotEnoughModal="openNotEnoughModal"/>
        </div>
    </div>
</template>

<script>
import Container from "@/public/components/common/uiKit/Container.vue";
import CurrencyImg from "../CurrencyImg";
import BalancesListHeader from "./BalancesListHeader";
import BalanceCardNetworkList from "./BalanceCardNetworkList";
import {mapActions, mapState} from "vuex";
import {getCurrencyTicker} from "@/public/filters";


export default {
    name: "BalanceCard",
    props: {
        balance: {
            type: Object,
            required: true,
        },

    },
    components: {
        Icon: () => import(/* webpackChunkName: "/public/components/elements/advance-balances/NetworkAndCurrenciesSelect/CurrencyNetworkIcon" */ '/public/components/elements/advance-balances/NetworkAndCurrenciesSelect/CurrencyNetworkIcon'),
        BalanceCardNetworkList,
        BalancesListHeader,
        Container,
        CurrencyImg
    },
    data () {
      return {
          isOpened: false
      }
    },
    methods: {
        getCurrencyTicker,
        ...mapActions('app', [
            'openModal',
        ]),
        openNotEnoughModal () {
            this.$emit('openNotEnoughModal');
        },
        toggleOpen () {
            this.isOpened = !this.isOpened
        }
    },
    computed: {
        networksCount() {
            return this.balance?.networkGroups?.length ?? 0;
        },
        addressesCount() {
            let count = 0;
            for (const balanceList of this.balance?.networkGroups) {
                count += balanceList?.addresses.length ?? 0;
            }
            return count;
        },
    },
};
</script>

<style scoped lang="scss">
.balance-card {
  border: 1px solid var(--new-front-divider-color);
  border-radius: 10px;
  margin-bottom: 20px;
  max-height: 70px;
  overflow: hidden;
  &--active {
    max-height: fit-content !important;
    overflow: unset !important;
  }
  &__list {
    padding: 0 20px;
  }
  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 75px;
    align-items: center;
    padding: 20px;
    &-chevron {
      transition: transform .2s ease;
      &-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
      &--active {
        transform: rotate(180deg);
      }
    }
    &_mobile {
      display: none;
    }


    &-text {
      &-container {
        display: flex;
        align-items: center;
      }

      &-title {
        font-family: $Gilroy;
        font-style: normal;
        font-size: $h4;
        font-weight: 500;
        color: var(--new-front-secondary-font-color);
      }

      &-value {
        font-family: $Gilroy;
        font-style: normal;
        font-size:$h4;
        font-weight: 500;
        line-height: var(--line-height);
        color: var(--new-front-primary-font-color);
      }
    }

    &-currency {
      &-title {
        grid-area: title;
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size-text-secondary);
        line-height: var(--line-height);
        color: var(--primary-font-color);
      }

      &-container {
        display: flex;
        //align-items: center;
        gap: 5px;
      }

      &-ticker {
        grid-area: ticker;
        font-family: $Gilroy;
        font-style: normal;
        font-size: $h4;
        font-weight: 500;
        line-height: var(--line-height);
        color: var(--new-front-primary-font-color);
        margin-left: 5px;
      }

      &-image {
        grid-area: image;
        height: 20px;
        width: 20px;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .balance-card {
    max-height: 70px;
    &__list {
      padding: 0 10px;
    }
    &__header {
      padding: 10px;
      //display: none;
      grid-template-columns: 1fr 30px;
      grid-template-areas:
              'currency chevron'
              'balance chevron'
    ;

      &-chevron-container {
        grid-area: chevron;
      }

      &-currency {
        &-title {
          font-size: var(--font-size-h2);
        }

        &-container {
          grid-area: currency;
          row-gap: 10px;
          column-gap: 10px;
          justify-items: flex-start;
        }
      }

      &-top-container {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
      }

      &-info-container {
        display: flex;
        flex-direction: column;
        align-items: end;
        gap: 15px;
      }

      &-detailed-info {
        display: flex;
        gap: 16px;
      }

      &_mobile {
        display: none;
        flex-direction: column;
        align-items: none;
      }

      &-text-container {
        &_balance {
          grid-area: balance;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          margin-bottom: 8px;
        }
      }
    }


  }
  .mobileHidden {
    display: none;
  }
}
@media screen and (max-width: 600px) {
}
</style>
