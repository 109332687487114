import gql from 'graphql-tag';

export default gql`
    mutation (
        $organizationId: String!,
        $addressId: String!,
        $enable: Boolean!
    ) {
        setUseForAutoSwap(addressId: $addressId, enable: $enable, organizationId: $organizationId)
    }
`;
