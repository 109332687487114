import gql from 'graphql-tag';

export default gql`
    mutation updateUserRole($organizationId: String!, $userId: String! $name: String, $organizationRoleId: String) {
        updateUserRole(organizationId: $organizationId, userId: $userId, name: $name, organizationRoleId: $organizationRoleId) {
            code
            result
        }
    }
`;



