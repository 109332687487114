import gql from 'graphql-tag';

export default gql`
    mutation ($organizationId: String!, $userId: String!, $currencyId: String!, $networkId: String!) {
        createPersonalAddress(organizationId:$organizationId, userId:$userId, currencyId:$currencyId, networkId:$networkId) {
            traceId
            code
            result {
                id
                userId
                currencyId
                networkId
                address
                tag
                balance
                isActive
            }
        }
    }
`;