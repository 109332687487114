<template>
  <div class="exchanges-filter-by-date">
    <p class="title">
      Период
    </p>
    <DatePicker
        class="date"
        type="date"
        :showSecond="false"
        range
        confirm
        confirm-text="Выбрать даты"
        value-type="date"
        v-model="dates"
        @confirm="selectDates"
        @clear="clearDates"
    />
  </div>
</template>

<script>

import Moment from "moment";
import {mapActions} from 'vuex';

import DatePicker from "vue2-datepicker";

export default {
    name: 'ExchangesFilterByDate',
    components: {
        DatePicker,
    },
    data() {
        return {
            dates: [],
        };
    },
    beforeMount() {
        this.setDateFromUrl();
    },
    methods: {
        ...mapActions('exchanges', [
            'fetchExchanges',
        ]),
        setDateFromUrl() {
            const dateFromUrl = this.$route.query.date;

            if (!dateFromUrl) {
                return false;
            }

            this.dates = dateFromUrl.split('to').map(date => new Date(date));
        },
        selectDates() {
            const {
                $route,
                $router,
                dates,
                fetchExchanges,
            } = this;
            const dateFrom = Moment(dates[0]).format();
            const dateTo = Moment(dates[1])
                .add(23, 'hours')
                .add(59, 'minutes')
                .add(59, 'seconds')
                .format()
      ;

            fetchExchanges({
                route: $route,
                router: $router,
                date: [dateFrom, dateTo],
                excludeParams: ['page'],
            });
        },
        clearDates() {
            const {
                $route,
                $router,
                fetchExchanges,
            } = this;

            fetchExchanges({
                route: $route,
                router: $router,
                date: [],
                excludeParams: ['page'],
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.exchanges-filter-by-date {
  .title {
    margin-bottom: 8px;
    font-weight: 700;
  }

  .date {
    &::v-deep.mx-datepicker-range {
      width: 100%;
    }

    &::v-deep .mx-input {
      height: 28px;
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: var(--black-200);
    }
  }
}

</style>
