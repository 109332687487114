<script>
    import Copy from "@/public/components/common/copy.vue";
    import Preloader from "/public/components/common/uiKit/spinner/index.vue";
    import {mapGetters, mapMutations, mapState} from "vuex";
    import CurrencyIcon from "@/public/components/common/uiKit/CurrencyIcon.vue";
    import BigNumber from "bignumber.js";
    import {
        getCurrencyInfoById,
        getCurrencyTicker,
        getNetworkSystemName,
        transactionHistoryBasis, transactionHistorySource,
        transactionHistoryStatus,
        transactionHistoryType
    } from "@/public/filters";
    import NetworkIcon from "@/public/components/common/uiKit/NetworkIcon.vue";
    import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
    import {TRANSACTION_HISTORY_STATUS_ENUM} from "@/common/constants/transaction-history-status-enum";
    import {BlockchainLinks} from "@/public/mixins/blockchain-links";
    export default {
        name: "TransactionInfo",
        props: {
            statusEnum: {
                type: Object,
                required: false,
            },
        },
        mixins: [
            BlockchainLinks,
        ],
        components: {BaseButton, NetworkIcon, CurrencyIcon, Copy, Preloader},
        computed: {
            ...mapState('transactions', ['isLoading', 'transaction']),
            ...mapGetters('app', ['getCurrencyNameById']),
            id() {
                return this.transaction.id;
            },
            type({ transaction }) {
                return transactionHistoryType(transaction.type);
            },
            status() {
                return transactionHistoryStatus(this.transaction.status);
            },
            basis({ transaction }) {
                return transactionHistoryBasis(transaction.basis);
            },
            source() {
                return transactionHistorySource(this.transaction.side);
            },
            createdAt() {
                return this.transaction.createdAt;
            },
            method() {
                return this.$options.filters.transactionHistoryMethod(this.transaction.source);
            },
            networkId() {
                return getNetworkSystemName(this.transaction.networkId);
            },
            currencyId () {
              return getCurrencyTicker(this.transaction.currencyId)
            },
            isAddressToTransferType() {
                const ADDRESS_TYPE = {
                    PAY_IN: 'transactions.transfer_type.pay_in',
                    PAY_OUT: 'transactions.transfer_type.pay_out',
                    ADVANCE: 'transactions.transfer_type.advance',
                };
                return ADDRESS_TYPE[this.transaction.addressTo] || false;
            },
            isAddressFromTransferType() {
                const ADDRESS_TYPE = {
                    PAY_IN: 'transactions.transfer_type.pay_in',
                    PAY_OUT: 'transactions.transfer_type.pay_out',
                    ADVANCE: 'transactions.transfer_type.advance',
                };
                return ADDRESS_TYPE[this.transaction.addressFrom] || false;
            },
            currencyFrom() {
                const {
                    transaction: {
                        currencyFrom,
                    },
                } = this;

                return currencyFrom;
            },
            currencyTo() {
                const {
                    transaction: {
                        currencyId,
                    },
                } = this;

                return currencyId;
            },
            hasCurrencyFrom() {
                return Boolean(this.currencyFrom);
            },
            hasCurrencyTo() {
                return Boolean(this.currencyTo);
            },
            orderId() {
                return this.transaction.orderId;
            },
            hasOrderId() {
                return Boolean(this.orderId);
            },
            orderCreatedAt() {
                return this.transaction.orderCreatedAt;
            },
            credit() {
                if (this.transaction.credit) {
                    const credit = new BigNumber(this.transaction.credit);
                    return credit.decimalPlaces(8).toNumber();
                }
                return null;
            },
            deposit() {
                if (this.transaction.deposit) {
                    const credit = new BigNumber(this.transaction.deposit);
                    return credit.decimalPlaces(8).toNumber();
                }
                return null;
            },
            id_copied() {
                return this.$t('copy.id_copied');
            },
            id_not_copied() {
                return this.$t('copy.id_not_copied');
            },
            notification() {
                return this.$t('copy.notification');
            },
            addressToTransfer() {
                return this.$t(this.$options.filters.transferAddressType(this.transaction.addressTo));
            },
            addressFromTransfer() {
                return this.$t(this.$options.filters.transferAddressType(this.transaction.addressFrom));
            },
            // amountFrom() {
            //     return this.data.credit ? BigNumber(this.data.credit).decimalPlaces(8) : '0.00';
            // },
            // amountTo() {
            //     return this.data.deposit ? BigNumber(this.data.deposit).decimalPlaces(8) : '0.00';
            // },
            addressFrom() {
                return this.data.addressFrom;
            },
            getCurrencyInfo () {
                return getCurrencyInfoById(this.transaction.currencyId)
            }
        },
        methods: {
            ...mapMutations('transactions', ['setTransactionInfoState', 'setTransaction']),
            closeInfo () {
                this.setTransactionInfoState(false)
                this.setTransaction(null)
            },
            txLink(txId, networkId) {
                return this.$txLink(txId, networkId);
            },
        }
    }
</script>

<template>
<div class="info">
    <div class="info__bg" @click="closeInfo"></div>
    <div  class="info__content">
        <div class="info__close" @click="closeInfo">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.712087 0.712087C1.0782 0.345971 1.6718 0.345971 2.03791 0.712087L7 5.67418L11.9621 0.712087C12.3282 0.345971 12.9218 0.345971 13.2879 0.712087C13.654 1.0782 13.654 1.6718 13.2879 2.03791L8.32582 7L13.2879 11.9621C13.654 12.3282 13.654 12.9218 13.2879 13.2879C12.9218 13.654 12.3282 13.654 11.9621 13.2879L7 8.32582L2.03791 13.2879C1.6718 13.654 1.0782 13.654 0.712087 13.2879C0.345971 12.9218 0.345971 12.3282 0.712087 11.9621L5.67418 7L0.712087 2.03791C0.345971 1.6718 0.345971 1.0782 0.712087 0.712087Z" fill="var(--new-front-primary-font-color)"/>
            </svg>
        </div>
        <transition name="slide-fade" mode="out-in">
            <div v-if="!isLoading">
                <div class="info__header">
                    <div class="info__header-topRow">
                        <div class="info__header-title">{{ $t('transaction.title') }}</div>

                    </div>
                </div>
                <div class="info__item">
                    <div class="info__item-title">
                        {{ $t('transaction.id_in_the_system') }}
                    </div>
                    <div class="info__item-value">
                        {{ id | preparedString }}
                        <copy :value="transaction.id"/>
                    </div>
                </div>
                <div class="info__item">
                    <div class="info__item-title">
                        {{ $t('transaction.order_id') }}
                    </div>
                    <div class="info__item-value" v-if="orderId">
                        {{ orderId | preparedString }}
                        <copy v-if="orderId" :value="orderId" />
                    </div>
                    <div v-else class="info__item-value">
                        -
                    </div>
                </div>
                <div class="info__item">
                    <div class="info__item-title">
                        {{ $t('transaction.date_of_creation') }}
                    </div>
                    <div class="info__item-value">
                        {{ transaction.createdAt | getFormatDate('DD/MM/YYYY, HH:mm:ss') }}
                    </div>
                </div>
                <div class="info__item">
                    <div class="info__item-title">
                        {{ $t('transaction.type') }}
                    </div>
                    <div class="info__item-value">
                        {{ $t(type) }}
                    </div>
                </div>
                <div class="info__item">
                    <div class="info__item-title">
                        {{ $t('transaction.status') }}
                    </div>
                    <div class="info__item-value">
                                                <div :class="['item__status', `item__status--${transaction.status}`]">
                                                    {{ $t(status) }}
                                                </div>
                    </div>
                </div>
                <div class="info__item">
                    <div class="info__item-title">
                        {{ $t('transaction.transfer-amount') }}
                    </div>
                    <div class="info__item-value" v-if="credit || deposit">
                        <span v-if="credit !== 0 && credit && !deposit">{{ credit }}</span>
                        <span v-else-if="deposit !== 0 && deposit && !credit">{{ deposit }}</span>
                        <span v-else-if="deposit === 0 || credit === 0">0</span>
                    </div>
                </div>
                <div class="info__item">
                    <div class="info__item-title">
                        {{ $t('order.currency') }}
                    </div>
                    <div class="info__item-value" v-if="currencyId">
                        <CurrencyIcon :currency="currencyId"/>
                        {{ getCurrencyInfo.alias }}<span class="info__item-value--secondary">({{ currencyId }})</span>
                    </div>
                    <div class="info__item-value" v-else>
                        -
                    </div>
                </div>
                <div class="info__item">
                    <div class="info__item-title">
                        {{ $t('transaction.network') }}
                    </div>
                    <div class="info__item-value"  v-if="transaction.networkId">
                        <NetworkIcon :network="networkId"/>
                        {{ networkId | getNetworkFullNameByNetworkName }} <span class="info__item-value--secondary">{{ networkId | getNetworkShortNameByNetworkName }}</span>
                    </div>
                    <div class="info__item-value" v-else>
                        -
                    </div>
                </div>
                <div class="info__item">
                    <div class="info__item-title">
                        {{ $t('transaction.transfer_address') }}
                    </div>
                    <div class="info__item-value" v-if="transaction.addressTo && !isAddressToTransferType">
                        {{ transaction.addressTo | getPrettyAddress }}
                        <copy :value="transaction.addressTo" />
                    </div>
                    <div class="info__item-value" v-else-if="transaction.addressTo && isAddressToTransferType">
                        {{ addressToTransfer }}
                    </div>
                    <div class="info__item-value" v-else>
                        -
                    </div>
                </div>
                <div class="info__item">
                    <div class="info__item-title">
                        {{ $t('transaction.write-off_address') }}
                    </div>
                    <div class="info__item-value" v-if="transaction.addressFrom && !isAddressFromTransferType">
                        {{ transaction.addressFrom | getPrettyAddress }}
                        <copy :value="transaction.addressFrom" />
                    </div>
                    <div class="info__item-value" v-else-if="transaction.addressFrom && isAddressFromTransferType">
                        {{ addressFromTransfer }}
                    </div>
                    <div class="info__item-value" v-else>
                        -
                    </div>
                </div>
                <div class="info__item">
                    <div class="info__item-title">
                        {{ $t('transaction.transaction_in_the_blockchain') }}
                    </div>
                    <div class="info__item-value info__item-value--link" v-if="transaction.txId && transaction.networkId">
                        <a :href="txLink(transaction.txId, transaction.networkId)" target="_blank">{{ transaction.txId | preparedString }}</a>
                        <copy :value="txLink(transaction.txId, transaction.networkId)"/>
                    </div>
                    <div class="info__item-value" v-else>
                        -
                    </div>
                </div>
              <div class="info__item" style="border-bottom: 0">
                <div class="info__item-title">
                  {{ $t('transaction.comment') }}
                </div>
              </div>
              <div class="info__item-value">
                {{ transaction.comment ? transaction.comment : '-' }}
              </div>
            </div>
            <preloader v-else />
        </transition>
    </div>
</div>
</template>

<style scoped lang="scss">
.info {
    font-family: $Gilroy;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  // Огромный z-index для перекрытия виджета интеркома
  z-index: 2147483001;
  &__rate-icon {
    margin: 0 10px;
  }
  &__btn {
    margin-top: 50px;
  }

  &__close {
    position: absolute;
    top: 30px;
    cursor: pointer;
  }
  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .50);
    height: 100vh;
    width: 100vw;
    z-index: 1;
    cursor: pointer;
  }
  &__content {
    width: 530px;
    height: 100vh;
    box-sizing: border-box;
    padding: 100px 50px 30px 50px;
    background: var(--new-front-main-bg);
    position: absolute;
    right: 0;
    z-index: 2;
    overflow: auto;
  }
  &__item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 58px;
    border-bottom: 1px solid var(--new-front-divider-color);
    &:last-of-type {
      border-bottom: 0;
    }
    &-title {
      font-size: $h4;
      font-weight: 500;
      color: var(--new-front-secondary-font-color);
    }
    &-value {
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 500;
      font-size: $h4;
      color: var(--new-front-primary-font-color);
      &--secondary {
        color: var(--new-front-secondary-font-color);
      }
        &--link {
            color: var(--new-front-purple);
            text-decoration: underline;
            a {
                color: var(--new-front-purple);
            }
            &::v-deep {
                .copy-wrapper {
                    margin-right: 5px !important;
                }
            }
        }
    }
  }
  &__header {
    margin-bottom: 20px;
    &-topRow {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-title {
      font-size: $h1;
      color: var(--new-front-primary-font-color)
    }
    &-subtitle {
      margin-top: 5px;
      font-size: $h4;
      color: var(--new-front-secondary-font-color)
    }
  }
}
.item__status {
    width: 120px;
    height: 32px;
    border-radius: 10px;
    text-align: center;
    padding: 5px 15px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    &--init {
        color: var(--new-front-status-text-active);
        background: var(--new-front-status-active);
    }
    &--rejected {
        color: var(--new-front-status-text-error);
        background: var(--new-front-status-error);
    }
    &--expired {
        color: var(--new-front-status-text-expired);
        background: var(--new-front-status-expired);
    }
    &--processed {
        color: var(--new-front-status-text-processed);
        background: var(--new-front-status-processed);
    }
    &--overpaid {
        color: var(--new-front-status-text-processed);
        background: var(--new-front-status-processed);
    }
    &--pending {
        color: var(--new-front-status-text-pending);
        background: var(--new-front-status-pending);
    }
    &--partial {
        color: var(--new-front-status-text-pending);
        background: var(--new-front-status-pending);
    }
    &--error {
        color: var(--new-front-status-text-error);
        background: var(--new-front-status-error);
    }
    @media (max-width: 950px) {
        font-size: 13px;
    }
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
::v-deep .copy-wrapper {
  margin: 0;
}
@media screen and (max-width: 576px) {
  .info__header-title {
    font-size: $h2;
  }
  .info__item-title {
    font-size: $h5;
  }
  .info__item-value {
    font-size: $h5;
  }
  .info__header-subtitle {
    font-size: $h5;
  }
  .info__content {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 70px 20px 30px;
    overflow: auto;
  }
}
</style>
