import gql from 'graphql-tag';

export default gql`
    mutation($organizationId: String!,$accountId: String!, $clientId: String, $addressFromId: String!, $addressToId: String!, $token: String!, $key: String!) {
        createCrosschainTransfer(organizationId: $organizationId, accountId: $accountId, clientId: $clientId, addressFromId: $addressFromId, addressToId: $addressToId, token: $token, key: $key){
          success
          code
          result {
            id
            clientId
            accountId
            status
            rejectMessage
            currencyId
            networkFromId
            networkToId
            addressFromId
            addressToId
            amount
            amountUSD
            blockchainFee
            blockchainFeeUSD
            serviceFeeUSD
            createdAt
          }
        }
    }
`;
