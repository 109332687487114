import getDonationOrderInfo from "../../../../graphQl/queries/getDonationOrderInfo";

export const fetchDonationOrderInfo = async ({dispatch, commit, rootGetters}, payload) => {

    let orderData;
    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    const variables = {
        organizationId,
        ...payload,
    };

    try {
        commit('app/toggleLoading', null, {root: true});
        orderData = await dispatch('app/request', {
            type: 'query',
            gql: getDonationOrderInfo,
            variables,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (orderData.message) {
        throw orderData.message;
    }

    if (orderData.errors) {
        throw orderData.errors;
    }

    const {
        data: {getPreCheckoutPageOrder},
    } = orderData;
    commit('addDonationOrder', getPreCheckoutPageOrder);

    return getPreCheckoutPageOrder;
};