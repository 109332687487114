import getPreCheckoutPage from '/public/graphQl/queries/getPreCheckoutPage';

export const fetchPreCheckoutPage = async ({dispatch, commit, rootGetters}, payload) => {
    let preCheckoutPage;

    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    const variables = {
        organizationId,
        ...payload,
    };

    try {
        commit('app/toggleLoading', null, {root: true});
        preCheckoutPage = await dispatch('app/request', {
            type: 'query',
            gql: getPreCheckoutPage,
            variables,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (preCheckoutPage.message) {
        throw preCheckoutPage.message;
    }

    if (preCheckoutPage.errors) {
        throw preCheckoutPage.errors;
    }

    const {
        data: {getPreCheckoutPage: page},
    } = preCheckoutPage;
    commit('page', page);
    return page;
};