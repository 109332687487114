<template>
  <div>
    <div class="preview-request">
      <RouterLink
          class="preview-request__link"
          :to="{ name: 'request', params: { id } }"
      />
      <div class="preview-request__id preview-request__item ">
        <CopyString
            :string="id"
            :successMessage="id_copied"
            :failMessage="id_not_copied"
            :title="notification"
            canCopy
        />
      </div>
      <div class="preview-request__currency preview-request__item">
        <CurrencyImg
            class="preview-request__currency-img"
            :currency="currency"
        />
        <p class="preview-request__text">{{ currency | getCurrencyTicker }}</p>
      </div>
      <p class="preview-request__item preview-request__text">
        {{ network | getNetworkName }}
      </p>
      <p class="preview-request__item preview-request__text">
        {{ amount }}
      </p>
      <p class="preview-request__item preview-request__text">
        {{ createdAt | getFormatDate('DD.MM.YYYY, HH:mm') }}
      </p>
      <div class="preview-request__item preview-request__tag">
        <AtTag :color="status.colorTag">
          {{ $t(status.title) }}
        </AtTag>
      </div>

    </div>


    <div class="preview-request__mobile" :style="`--colorStatus:${mobileStatusTextColor};`">
      <AtCollapse accordion :value="id">
        <AtCollapseItem data="">
          <div class="preview-request__preview" slot="title">
            <div class=" preview-request__currency">
              <CurrencyImg
                  class="preview-request__currency-img"
                  :currency="currency"
              />
              <div class="preview-request__currency-container">

                <p class="preview-request__currency-text">
                  {{ currency | getCurrencyTicker }}
                </p>
                <p class="preview-request__currency-status">{{ $t(status.title) }}</p>
              </div>

            </div>
            <p class="preview-request__currency-amount">
              {{ amount }}
            </p>
          </div>
          <div class="preview-request__detailed-info">
            <div class="preview-request__row">
              <p class="preview-request__title-text">id</p>
              <CopyString
                  :string="id"
                  :successMessage="id_copied"
                  :failMessage="id_not_copied"
                  :title="notification"
                  canCopy
              />
            </div>

            <div class="preview-request__row">
              <p class="preview-request__title-text"> {{ $t('withdrawal.network') }}</p>
              <p class="preview-request__network-name preview-request__text ">
                {{ network | getNetworkName }}
              </p>
            </div>
            <div class="preview-request__row">
              <p class="preview-request__title-text">
                {{ $t('withdrawal.amount') }}
              </p>
              <p class="preview-request__text">
                {{ amount }}
              </p>
            </div>
            <div class="preview-request__row">
              <p class="preview-request__title-text">
                {{ $t('withdrawal.date_of_creation') }}
              </p>
              <p class="preview-request__text">
                {{ createdAt | getFormatDate('DD.MM.YYYY, HH:mm') }}
              </p>
            </div>
            <RouterLink
                class=" preview-request__read-more "
                :to="{ name: 'request', params: { id } }"
            >{{ $t('orders.read_more') }}
            </RouterLink>
          </div>
        </AtCollapseItem>
      </AtCollapse>
    </div>

  </div>
</template>

<script>

import Moment from "moment";
import {mapGetters} from 'vuex';

import copyToClipboard from "/public/mixins/copyToClipboard";

import CopyString from "/public/components/elements/CopyString";
import CurrencyImg from "/public/components/elements/CurrencyImg";
import AtTag from 'at-ui/src/components/tag';
import {ORDER_STATUS_ENUM} from "../../../../common/constants/orders-status-enum";

export default {
    name: 'PreviewRequest',
    statuses: [
        {
            value: 'init',
            title: 'withdrawal.in_processing',
            colorTag: 'primary',
        },
        {
            value: 'pending',
            title: 'withdrawal.in_processing',
            colorTag: 'primary',
        },
        {
            value: 'error',
            title: 'withdrawal.error',
            colorTag: 'error',
        },
        {
            value: 'processed',
            title: 'withdrawal.completed',
            colorTag: 'success',
        },
        {
            value: 'rejected',
            title: 'withdrawal.rejected',
            colorTag: 'error',
        },
    ],
    components: {
        CopyString,
        CurrencyImg,
        AtTag,
    },
    mixins: [copyToClipboard],
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters('app', [
            'getCurrencyNameById',
        ]),
        id() {
            return this.data.id;
        },
        currency() {
            return this.data.currencyId;
        },
        network() {
            return this.data.networkId;
        },
        amount() {
            return this.data.amount;
        },
        createdAt() {
            return this.data.createdAt;
        },
        status() {
            const {
                $options: {statuses},
                data: {status},
            } = this;

            return statuses.find(statusItem => statusItem.value === status);
        },
        mobileStatusTextColor() {
            switch (this.data.status) {
            case ORDER_STATUS_ENUM.INIT:
                return '#6190e8';
            case ORDER_STATUS_ENUM.PENDING:
                return '#6190e8';
            case ORDER_STATUS_ENUM.PROCESSED:
                return '#13ce66';
            case ORDER_STATUS_ENUM.ERROR:
                return '#EB5757';
            case ORDER_STATUS_ENUM.REFUND:
                return '#ffc82c';
            case ORDER_STATUS_ENUM.EXPIRED:
                return '#8F949A';
            case ORDER_STATUS_ENUM.PARTIAL:
                return '#ffc82c';
            default:
                return '#8F949A';
            }
        },
        id_copied() {
            return this.$t('copy.id_copied');
        },
        id_not_copied() {
            return this.$t('copy.id_not_copied');
        },
        notification() {
            return this.$t('copy.notification');
        },

    },
};

</script>

<style lang="scss" scoped>

.preview-request {
  position: relative;
  align-items: center;
  color: #3F536E;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  min-height: 55px;
  border-bottom: 1px solid #F2F2F2;
  background: #FFFFFF;

  &:hover {
    background: var(--grey-200);
  }

  &__text {
    font-size: var(--font-size-text-main);
    font-family: var(--font-family);
    line-height: var(--line-height);
    color: var(--black-200);
  }

  &__item {
    &:nth-child(2) {
      padding-left: 45px;
    }
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  &__id {
    position: relative;
    z-index: 2;
  }

  &__currency {
    display: flex;
    align-items: center;
  }

  &__currency-img {
    margin-right: 12px;
    width: 24px;
    height: 24px;
  }

  &__tag ::v-deep .at-tag {
    min-height: 30px;
    display: flex;
    align-items: center;
    max-width: 100px;
    border-radius: 8px;
    justify-content: center;
  }

  &__tag ::v-deep .at-tag__text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    color: var(--colorText);
    white-space: normal;
  }

  &__mobile {
    display: none;
  }
}

@media screen and (max-width: 1440px) {
  .preview-request {
    &__item {
      &:first-of-type {
        padding-left: 20px;
      }
    }
  }
}


@media screen and (max-width: 950px) {
  .preview-request {
    display: none;

    &__detailed-info {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    &__read-more {
      width: 100%;
      max-width: 300px;
      border-radius: 12px;
      background: #0059F7;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #FFFFFF;
      min-height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 25px;
      margin-bottom: 20px;
    }

    &__currency-text {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #2C405A
    }

    &__currency-status {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: var(--colorStatus);
    }

    &__currency-amount {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: right;
      color: #2C405A;
    }

    &__currency-container {
      display: flex;
      flex-direction: column;
    }

    &__row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 40px;
      border-top: 1px solid #F2F2F2;

      &:last-of-type {
        border-bottom: none;
      }
    }

    &__title-text {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #8F949A;
    }

    &__preview {
      display: flex;
      justify-content: space-between;
      flex: 1;
      padding-right: 60px;
      align-items: center;
    }


    &__mobile {
      display: block;

      ::v-deep .at-collapse {
        border: 1px solid #F2F2F2;
        border-right: none;
        border-left: none;
      }

      ::v-deep .at-collapse__content {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 0;
      }

      ::v-deep .at-collapse__header {
        background: #FFFFFF;
        min-height: 50px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 0;
        padding-bottom: 0;
      }

      ::v-deep .at-collapse__icon {
        right: 10px;
        top: 17px;
        font-size: 20px;
        left: unset;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }

      ::v-deep .at-collapse__item--active > .at-collapse__header .at-collapse__icon {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
      }
    }
  }
}
</style>
