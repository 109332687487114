import changeLang from "../../../../graphQl/mutations/changeLang";

export const changeLangQuery = async ({dispatch, commit}, payload) => {
    let changeLangData;
    commit('app/toggleLoading', null, {root: true});
    try {
        changeLangData = await dispatch('app/request', {
            type: 'mutation',
            gql: changeLang,
            variables: payload,
        }, {root: true});
    } catch (error) {
        commit('app/toggleLoading', null, {root: true});
        return error;

    }

    if (changeLangData.message) {
        throw changeLangData.message;
    }

    if (changeLangData.errors) {
        throw changeLangData.errors;
    }
    commit('app/toggleLoading', null, {root: true});
    return true;

};
