import checkPreCheckoutURL from "/public/graphQl/queries/checkPreCheckoutURL";


export const checkPreCheckoutURLQuery = async ({dispatch, rootGetters}, payload) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    let orderData;

    payload.organizationId = organizationId;

    try {
        orderData = await dispatch('app/request', {
            type: 'query',
            gql: checkPreCheckoutURL,
            variables: payload,
        }, {root: true});
    } catch (error) {
        return error;
    }

    if (orderData.message) {
        throw orderData.message;
    }

    if (orderData.errors) {
        throw orderData.errors;
    }

    const {
        data: {
            checkPreCheckoutURL: result,
        },
    } = orderData;
    return result;
};