import gql from 'graphql-tag';

export default gql`
    mutation($accountId: String!, $registryId: String!) {
        requestFeeForRegistry(accountId: $accountId, registryId: $registryId) {
            code
            result {
                itemId
                fee {
                    source
                    currencyId
                    currencyTicker
                    amount
                    usdAmount
                    until
                    token
                }
            }
        }
    }
`;
