export const mutations = {
    updateAddresses: (state, { balance, addressId }) => {
        if (!state.payoutBalances.length) return

        const address = state.payoutBalances.find(({ id }) => id === addressId )

        if (!address) return

        address.balance = balance
    },
    setRegistryFile: (state, data) => {
        state.registryFile = data;
    },
    payoutBalances: (state, data) => {
        state.payoutBalances = data;
    },
    setDepositLoading(state, loading) {
        state.deposit.pending = loading;
    },
    setDepositCreated: (state, created) => {
        state.deposit.complete = created;
    },
    setDepositError(state, value) {
        state.deposit.error = value;
    },
    setDeposit(state, {code, result}) {
        state.deposit.code = code;

        if (code === 0) {
            state.deposit.result.id = result.id || '';
            state.deposit.result.balance = result.balance || '';
            state.deposit.result.address = result.address || '';
            state.deposit.result.tag = result.tag || '';
            state.deposit.result.currencyId = result.currencyId || '';
            state.deposit.result.networkId = result.networkId || '';
        }
    },
    setFee(state, payload) {
        state.fee.code = payload.code ?? null;
        state.fee.currency = payload.currency || null;
        state.fee.amount = payload.amount || null;
        state.fee.usdAmount = payload.usdAmount || null;
        state.fee.source = payload.source || null;

        state.fee.until = payload.until || null;

        state.fee.token = payload.token || null;

        state.fee.networkFee = payload.networkFee || null;
        state.fee.networkFeeUSD = payload.networkFeeUSD || null;
        state.fee.tariffFee = payload.tariffFee || null;
        state.fee.tariffFeeUSD = payload.tariffFeeUSD || null;
    },
    setFeeRequestId(state, value) {
        state.feeRequestId = value;
    },
    setFeePending(state, value) {
        state.fee.pending = value;
    },
    setFeeComplete(state, value) {
        state.fee.complete = value;
    },
    setFeeError(state, value) {
        state.fee.error = value;
    },
    clearFee(state) {
        state.fee = {
            pending: false,
            complete: false,

            error: null,
            code: null,
            source: null,
            currencyId: null,
            currencyTicker: null,
            amount: null,
            usdAmount: null,
            until: null,
            token: null,

            networkFee: null,
            networkFeeUSD: null,
            tariffFee: null,
            tariffFeeUSD: null,
        };
    },
    registries: (state, registries) => {
        state.registriesData.registries = registries;
    },
    setRegistriesItems(state, items) {
        if (state.registriesData.registriesItems.some((i) => i.registryId === items.registryId)) return;
        state.registriesData.registriesItems.push(items);
    },
    totalRegistriesCount: (state, count) => {
        state.registriesData.totalRegistriesCount = count;
    },
    clearRegistryFile(state) {
        state.registryFile = {
            code: null,
            result: {
                errors: null,
                items: [],
                registryId: null,
                title: null,
            },
        };
    },
    clearDepositData(state) {
        state.deposit = {
            pending: false,
            complete: false,
            error: null,
            code: 0,
            result: {
                id: '',
                networkId: '',
                currencyId: '',
                balance: '',
                address: '',
                tag: '',
            },
        };
    },
    clearAllStates (state) {
        state.deposit = {
            pending: false,
            complete: false,
            error: null,
            code: 0,
            result: {
                id: '',
                networkId: '',
                currencyId: '',
                balance: '',
                address: '',
                tag: '',
            },
        };
        state.registryFile = {
            code: null,
            result: {
                errors: null,
                items: [],
                registryId: null,
                title: null,
            },
        };
        state.fee = {
            pending: false,
            complete: false,

            error: null,
            code: null,
            source: null,
            currencyId: null,
            currencyTicker: null,
            amount: null,
            usdAmount: null,
            until: null,
            token: null,

            networkFee: null,
            networkFeeUSD: null,
            tariffFee: null,
            tariffFeeUSD: null,
        };
        state.registriesData = {
            registriesCountOnPage: 7,
            totalRegistriesCount: 0,
            registries: [],
            registriesItems: [],
        },
        state.payoutBalances = [];
    },
};
