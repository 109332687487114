import { fetchTransactions } from "./actions/fetchTransactions";
import { fetchTransaction } from "./actions/fetchTransaction";
import { downloadCSV } from './actions/downloadCSV';
import { downloadXLSX } from './actions/downloadXLSX';
import {sendColumns} from "@/public/store/modules/transactions/actions/sendColumns";
import {getColumns} from "@/public/store/modules/transactions/actions/getColumns";

export const actions = {
    fetchTransactions,
    fetchTransaction,
    downloadCSV,
    downloadXLSX,
    sendColumns,
    getColumns
};
