<template>
  <div class="cross-chain__history-list">
    <div
      v-if="transfersList.length"
      style="padding-bottom: 5px"
    >
      <div class="header">
        <div
          v-for="value in headers"
          class="header-item"
          :style="{ 'cursor': value === 'date' ? 'pointer' : 'default'}"
          @click="value === 'date' ? toggleSorting() : null"
        >
          {{  $t(`bridge.history.table.headers.${value}`) }}
          <sort-icon v-if="value === 'date'" :value="sort" />
        </div>
      </div>
      <history-item
        v-for="(item, index) in transfersList"
        :key="index"
        v-bind="item"
        :last="transfersList.length - 1 === index"
      />
    </div>
  </div>

</template>

<script>
export default {
  name: "HistoryList",
  components: {
    HistoryItem: () => import(/* webpackChunkName: "public/components/elements/cross-chain/History/List/Item" */ './Item'),
    SortIcon: () => import(/* webpackChunkName: "public/components/common/uiKit/sortIcon" */ '/public/components/common/uiKit/sortIcon'),
  },
  props: {
    transfersList: {
      type: Array,
      default: () => ([])
    },
    pagination: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    headers() {
      return ['date', 'id', 'state', 'currency', 'networkFrom', 'addressFrom', 'networkTo', 'addressTo', 'amount', 'networkCommission', 'serviceCommission'];
    },
    sort({ pagination }) {
      return pagination.sort
    }
  },
  methods: {
    toggleSorting() {
      const { sort } = this.pagination

      this.$set(this.pagination, 'sort', sort === 'ASC' ? 'DESC' : 'ASC')
    }
  }
}
</script>

<style scoped lang="scss">
 .cross-chain {
   &__history-list {
      width: 100%;
      overflow-x: scroll;

     @include below_phone () {
       overflow-x: unset;
     }

      font-family: var(--new-front-font-family);

      .header {
        display: flex;
        flex-direction: row;
        align-items: center;

        width: fit-content;

        height: 56px;

        background: var(--new-front-advanced-balance-table-bg-header-bg-secondary);
        border-radius: 10px;

        .header-item {
          width: 180px;

          display: flex;
          align-items: center;
          gap: 7px;

          min-width: 180px;
          padding-left: 30px;

          font-size: $h4;
          font-weight: 500;

          color: var(--new-front-advanced-balance-table-text-header-bg-secondary);
        }
      }

      @include below_880 () {
        margin-top: 20px;
      }

      @include below_phone () {
       .header {
         display: none;
       }
      }
   }
 }
</style>