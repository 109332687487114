import getCollectingHistoryQuery from "@/public/graphQl/queries/getCollectingSettingsHistory";

export const getCollectingHistory = async ({dispatch, commit, rootGetters}, variables) => {
    let collectingData;

    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const params = {
        organizationId,
        ...variables,
    }

    try {
        commit('app/toggleLoading', null, {root: true});
        collectingData = await dispatch('app/request', {
            type: 'query',
            gql: getCollectingHistoryQuery,
            variables: params
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (collectingData.message) {
        throw collectingData.message;
    }

    if (collectingData.errors) {
        throw collectingData.errors;
    }

    const {
        data: {getCollectingHistory},
    } = collectingData;
    commit('setSettingsHistory', getCollectingHistory)
    // commit('setWebhooksTotal', getCollectingSettings)
    return getCollectingHistory;
};
