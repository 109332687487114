import gql from 'graphql-tag';

export default gql`
    mutation createMerchant(
        $organizationId: String!, 
        $name: String!, 
        $logoUrl: String,
        $currencies: [[String!]!]!,  
    ) {
        createMerchant(
            organizationId: $organizationId, 
            name: $name, 
            logoUrl: $logoUrl,
            currencies: $currencies,  
        ) {
            id
            organizationId
            name
            logoUrl
            minSpendInterval
            maxSpendAmountUSD
            maxMonthSpendAmountUSD
            approveAmountUSD
            allowFreeSpend
            createdAt
            currencies
        }
    }
`;
