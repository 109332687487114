import {getRouteQuery} from "/public/utils/getRouteQuery";
import {getCurrenciesIdsByNames} from "/public/utils/getCurrenciesIdsByNames";
import {getNetworksIdsByNames} from "/public/utils/getNetworksIdsByNames";

export const downloadXLSX = async ({state, rootState, commit, dispatch, rootGetters}, {
    route,
    sortDate,
    currencies,
    type,
    date,
    networks,
}) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const currentSortDate = sortDate
    const currentType = type
    const currentCurrencies = currencies

    const currentNetworks = networks

    let currentDate = date
    if (currentDate.length) {
        currentDate = currentDate.map((i) => {
                i = new Date(i);
                return i.toISOString();
            },
        );
    }

    const payload = JSON.stringify({
        organizationId,
        sortDate: currentSortDate,
        type: currentType ? [currentType] : null,
        currencies: currentCurrencies ? [currentCurrencies] : null,
        networks: currentNetworks ? [currentNetworks] : null,
        date: currentDate,
    });
    window.open('/downloads/xlsx-transactions?_=' + payload);
};
