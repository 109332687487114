import { getAddressSnapshotsGroupedByWallet as Query } from "/public/graphQl/queries/getAnalytics";

export const getAddressAnalyticsByWallet = async ({dispatch, rootGetters, commit}, payload) => {
  const organizationId = rootGetters['app/getSelectedOrganization'].id;

  let snapshotsData;

  commit('setAnalyticsLoading', true)

  try {
    snapshotsData = await dispatch('app/request', {
      type: 'query',
      gql: Query,
      variables: {
        organizationId,
        ...payload,
      },
    }, {root: true});
  } catch (error) {
    return error;
  }

  const { data } = snapshotsData || {}
  const { getAddressSnapshots } = data || {}

  const { currencyGroups = {} } = getAddressSnapshots || {}

  commit('setAnalyticsLoading', false)
  commit('setAnalyticsInit', true)
  commit('setAnalytics', currencyGroups)

  return currencyGroups;
};