import getUserOrganizationRoleQuery from "../../../../graphQl/queries/getUserOrganizationRole";

export const getUserOrganizationRole = async ({commit, dispatch,rootState, rootGetters, state}) => {
    const selectedOrganizationId = rootGetters['app/getSelectedOrganizationId'];
    let currenciesData;

    let variables = {
        organizationId: selectedOrganizationId,
    };

    try {
        if (!rootState.app.isLoading) {
            commit('app/setLoading', true, {root: true});
        }
        currenciesData = await dispatch('app/request', {
            type: 'query',
            gql: getUserOrganizationRoleQuery,
            variables,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/setLoading', false, {root: true});
    }

    if (currenciesData.message) {
        throw currenciesData.message;
    }

    if (currenciesData.errors) {
        throw currenciesData.errors;
    }

    const {
        data: {
            getUserOrganizationRole: req,
        },
    } = currenciesData;

    commit('setCurrentRole', req);

    return true;
};
