import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!, $limit: Int! $offset: Int!) {
         IFrameWidgets(organizationId: $organizationId, offset: $offset, limit: $limit) {
             widgets {
                id
                organizationId
                alias
                theme
                link
                siteURL
                showStartPage
                payinCrypto
                payoutCrypto
                payinCryptoType
                createdAt
                stats {
                  payin {
                    all {
                       count
                       amountUSD
                    }
                    period {
                        currency {
                           count
                           amountUSD
                        }
                        previous {
                           count
                           amountUSD
                        }
                    }
                  }   
                }
             }
             count
        }
    }
`;
