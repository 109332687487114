import getInvoiceQuery from "/public/graphQl/queries/getInvoice";

export const fetchInvoice = async ({dispatch, commit, rootGetters}, payload) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    let invoicesData;
    commit('setInvoiceInfoState', true);
    try {
        // commit('app/toggleLoading', null, {root: true});
        commit('setFetching', true);
        invoicesData = await dispatch('app/request', {
            type: 'query',
            gql: getInvoiceQuery,
            variables: {
                organizationId,
                ...payload,
            },
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        // commit('app/toggleLoading', null, {root: true});
        commit('setFetching', false);
    }

    if (invoicesData.message) {
        throw invoicesData.message;
    }

    if (invoicesData.errors) {
        throw invoicesData.errors;
    }

    const {
        data: {invoice},
    } = invoicesData;
    commit('setInvoice', invoice);
    return invoice;
};
