<template>
  <div>
    <preloader v-if="isLoading"/>
    <div v-else class="order">
      <AtButton
          class="order__all"
          type="primary"
          icon="icon-arrow-left"
          @click="goBack"
      >
        {{ $t('order.return') }}
      </AtButton>
      <h1 class="order__title">
        {{ $t('order.title') }}
      </h1>
      <div
          v-if="hasOrder"
          class="order__info"
      >
        <div class="order-basic-info__container">
          <div class="order-basic-info__header">
            <div class="order-basic-info__header-item">
              <div class="order-basic-info__icon">
                <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M11.8536 20.9215L7.43694 22.1049C7.12227 22.1892 6.79587 22.0008 6.71155 21.6861C6.62726 21.3715 6.8157 21.045 7.13033 20.9607L11.547 19.7773C11.8617 19.693 12.1881 19.8814 12.2724 20.1961C12.3568 20.5107 12.1683 20.8371 11.8536 20.9215ZM9.80867 7.34842C9.76911 7.88683 9.46456 8.1903 8.99173 8.18261C8.52097 8.17502 8.17836 7.85238 8.18966 7.36108C8.20062 6.88305 8.50034 6.56903 9.0102 6.57461C9.51973 6.5801 9.7723 6.88118 9.80867 7.34842ZM15.4709 5.53389C13.7139 2.63196 10.2339 1.222 7.00091 2.16658C4.17158 2.99327 2.32681 4.86143 1.65603 7.73453C0.992092 10.5783 1.8185 13.0242 3.91001 15.0726C4.40051 15.553 4.8515 16.1349 5.12801 16.7559C5.40523 17.3783 5.49411 17.5729 6.16934 17.4095C7.10712 17.1826 8.03234 16.9038 8.96994 16.6761C9.45059 16.5593 9.63322 16.291 9.63003 15.8064C9.61719 13.8508 9.6215 11.895 9.62862 9.93935C9.63017 9.49666 9.49105 9.13216 9.01475 9.1211C8.53259 9.10989 8.38498 9.46221 8.38901 9.91352C8.40017 11.1814 8.39239 12.4495 8.3923 13.7174C8.39225 15.7572 8.3923 15.7572 6.38666 16.0503C5.62419 15.1766 4.92987 14.3913 4.24667 13.5963C1.79464 10.7432 2.24305 6.52558 5.30337 4.34116C7.502 2.77174 9.86384 2.68441 12.2186 4.00952C14.405 5.23999 15.5945 7.77499 15.2793 10.2273C15.0687 11.8654 14.2864 13.1731 13.1262 14.2972C12.4259 14.9756 11.7879 15.706 11.5582 16.687C11.4508 17.1458 11.1935 17.3734 10.749 17.4829C10.8321 17.4057 5.95831 18.7713 5.95831 18.7713C5.64997 18.8539 5.46533 19.1738 5.54797 19.4821L5.56067 19.5298C5.64331 19.8381 5.96314 20.0228 6.27148 19.9402C6.27148 19.9402 11.8637 18.4375 11.937 18.4167C12.4549 18.2737 12.7367 17.905 12.7485 17.3788C12.7662 16.5933 13.1075 15.9818 13.663 15.4599C13.9603 15.1804 14.2564 14.8984 14.5363 14.6018C16.8461 12.1519 17.2273 8.4348 15.4709 5.53385V5.53389Z"
                      fill="var(--primary-font-color)"/>
                </svg>
              </div>
              <div class="order-basic-info__mobile-container">
            <span class="order-basic-info__title">
                    id:
                </span>
                <div class="order-basic-info__value-mobile">
                  <CopyString
                      :string="orderId"
                      :successMessage="$t('copy.id_copied')"
                      :failMessage="$t('copy.id_not_copied')"
                      :title="$t('copy.notification')"
                      canCopy
                  />
                </div>
              </div>
            </div>
            <div class="order-basic-info__header-item">
              <div class="order-basic-info__icon">
                  <span class="material-icons-outlined">receipt_long</span>
              </div>
              <div class="order-basic-info__mobile-container">
              <span class="order-basic-info__title">
                            {{ $t('order.title') }}:
                </span>
                <div class="order-basic-info__value-mobile">
                  <CopyString
                      :string="merchantOrder"
                      :successMessage="$t('copy.order_title')"
                      :failMessage="$t('copy.order_title_failed')"
                      :title="$t('copy.notification')"
                      canCopy
                  />
                </div>
              </div>
            </div>
            <div class="order-basic-info__header-item">
              <div class="order-basic-info__icon">
                  <span class="material-icons-outlined">local_gas_station</span>
              </div>
              <div class="order-basic-info__mobile-container">
              <span class="order-basic-info__title">
                           {{ $t('order.advance_balance') }}
                </span>
                <p class="order-basic-info__value-mobile"> {{ account }}</p>
              </div>
            </div>
          </div>
          <div class="order-basic-info__values">
            <CopyString
                class="order-basic-info__value"
                :string="orderId"
                :successMessage="$t('copy.id_copied')"
                :failMessage="$t('copy.id_not_copied')"
                :title="$t('copy.notification')"
                canCopy
            />


            <CopyString
                class="order-basic-info__value"
                :string="merchantOrder"
                :successMessage="$t('copy.order_title')"
                :failMessage="$t('copy.order_title_failed')"
                :title="$t('copy.notification')"
                canCopy
            />


            <span class="order-basic-info__value">
                      {{ account }}
                  </span>

          </div>
        </div>
        <div class="order__info-description__container">
          <p class="order__info-description__title">
            {{ $t('order.description') }}
          </p>
          <p class="order__info-description__value">
            {{ merchantDescription }}
          </p>
        </div>
        <div class="order-detailed-info__container">
          <div class="order-detailed-info__header">
            <div class="order-detailed-info__header-container">
              <p class="order-detailed-info__title">
                {{ $t('order.network') }}
              </p>
              <p class="order-detailed-info__value order-detailed-info__value_mobile">
                {{ networkId | getNetworkName }}
              </p>
            </div>
            <div class="order-detailed-info__header-container">
          <span class="order-detailed-info__title">
            {{ $t('order.currency') }}
                </span>

              <span class="order-detailed-info__value order-detailed-info__value_mobile">
                    <CurrencyImg
                        class="order__currency-img"
                        :currency="currency"
                    />
                    {{ currency | getCurrencyTicker }}
                </span>
            </div>
            <div class="order-detailed-info__header-container">
          <span class="order-detailed-info__title">
            {{ $t('order.amount_received') }}
                  </span>
              <p class="order-detailed-info__value order-detailed-info__value_mobile"> {{ received }}</p>
            </div>
            <div class="order-detailed-info__header-container">
          <span class="order-detailed-info__title">
            {{ $t('order.amount') }}
                </span>
              <p class="order-detailed-info__value order-detailed-info__value_mobile"> {{ amount }}</p>
            </div>
          </div>
          <div class="order-detailed-info__values">
            <span class="order-detailed-info__value">
                    {{ networkId | getNetworkName }}
                </span>
            <span class="order-detailed-info__value">
                    <CurrencyImg
                        class="order__currency-img"
                        :currency="currency"
                    />
                    {{ currency | getCurrencyTicker }}
                </span>
            <span class="order-detailed-info__value">
                      {{ received }}
                  </span>
            <span class="order-detailed-info__value">
                      {{ amount }}
                  </span>
          </div>
        </div>
        <div class="order-contract__container">
          <div class="order-contract__header">
            <div class="order-contract__title-container">
                <span class="order-contract__title">
                       {{ $t('order.payment_address') }}
                  </span>

              <CopyString
                  :string="address"
                  :successMessage="$t('copy.addresses_copied')"
                  :failMessage="$t('copy.addresses_not_copied')"
                  :title="$t('copy.notification')"
                  canCopy
              />
            </div>
            <div class="order-contract__title-container">
                <span class="order-contract__title">
            {{ $t('order.payment_address_tag') }}
                </span>
              <span class="order-contract__value">
                    {{ tag }}
                </span>
            </div>
            <div class="order-contract__title-container">
                     <span class="order-contract__title">
                    {{ $t('checkout.contract_address') }}
                  </span>
              <a v-if="shortContract" class="order-contract__value order-contract__value_link" :href="contractLink"
                 target="_blank">
                {{ shortContract }}
              </a>
              <p v-else class="order-contract__value">-</p>
            </div>
          </div>
          <!--            -->
          <!--            <div class="order-contract__values">-->
          <!--                  <span class="order-contract__value" @click="viewContract">-->
          <!--                      {{ shortContract }}-->
          <!--                  </span>-->
          <!--              <span class="order-contract__value">-->
          <!--                    {{ tag }}-->
          <!--                </span>-->
          <!--            </div>-->
          <!--         -->
        </div>


        <div class="order__link-container" v-if="link">
                <span class="order__link-title">
            {{ $t('order.link_for_payment') }}
                </span>

          <CopyString
              class="order__link"
              v-if="link"
              :is-link="true"
              :string="link"
              :hash="false"
              :success-message="$t('copy.addresses_copied')"
              :title="$t('copy.notification')"
              canCopy
          />


        </div>
        <div class="order__info-container">
          <div class="order-hooks__info-container">
                <span class="order-hooks__info-item-title">
                    Error webhook:
                </span>
            <span class="order-hooks__info-item-value">
                    {{ errorWebhook }}
                </span>
          </div>
          <div class="order-hooks__info-container">
                <span class="order-hooks__info-item-title">
                    Success webhook:
                </span>
            <span class="order-hooks__info-item-value">
                    {{ successWebhook }}
                </span>
          </div>
          <div class="order-hooks__info-container">
                <span class="order-hooks__info-item-title">
                    Return URL:
                </span>
            <span class="order-hooks__info-item-value">
                    {{ returnUrl }}
                </span>
          </div>
          <div class="order-hooks__info-container">
                <span class="order-hooks__info-item-title order-hooks__info-item-title_date">
            {{ $t('order.date_of_order_creation') }}
                </span>
            <span class="order-hooks__info-item-value">
                    {{ createdAt | getFormatDate('DD.MM.YYYY, HH:mm') }}
                </span>
          </div>
          <div class="order-hooks__info-container">
                <span class="order-hooks__info-item-title order-hooks__info-item-title_date">
            {{ $t('order.validity_period_of_the_order') }}
                </span>
            <span class="order-hooks__info-item-value ">
                    {{ expiresAt | getFormatDate('DD.MM.YYYY, HH:mm') }}
                </span>
          </div>

        </div>

        <div class="order__transactions">
          <h2 class="order__sub-title">
            {{ $t('order.transactions') }}
          </h2>
          <div class="order__transactions-list">
            <OrderTransactionHeader v-if="hasTransactions" class="order__transactions-list-header"/>
            <template v-if="hasTransactions">
              <PreviewOrderTransaction
                  v-for="(transaction, key) in transactions"
                  :key="key"
                  class="order__transactions-transaction"
                  :data="transaction"
                  :route-name="'orders-transaction'"

              />
            </template>
            <p v-else class="order__no-data">
              {{ $t('order.no_data_available') }}
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {mapGetters, mapActions, mapState} from 'vuex';

import AtButton from 'at-ui/src/components/button';
import CurrencyImg from "/public/components/elements/CurrencyImg";
import TransactionsListHeader from "/public/components/elements/transactions/TransactionsListHeader";
import PreviewTransaction from "/public/components/elements/transactions/PreviewTransaction";
import EmptyTable from "/public/components/elements/EmptyTable";
import CopyString from "../components/elements/CopyString";
import {BlockchainLinks} from "../mixins/blockchain-links";
import BigNumber from "bignumber.js";
import Preloader from "/public/components/common/uiKit/spinner/index.vue";
import OrderTransactionHeader from "../components/elements/orders/OrderTransactionHeader";
import PreviewOrderTransaction from "../components/elements/orders/PreviewOrderTransaction";

export default {
    name: 'OrderPage',
    title: 'order.title',
    mixins: [
        BlockchainLinks,
    ],
    components: {
        PreviewOrderTransaction,
        OrderTransactionHeader,
        Preloader,
        AtButton,
        CurrencyImg,
        TransactionsListHeader,
        PreviewTransaction,
        EmptyTable,
        CopyString,
    },
    data() {
        return {
            order: null,
        };
    },
    computed: {
        ...mapGetters('app', [
            'getCurrencyNameById',

        ]),
        ...mapState('app', ['isLoading']),
        orderId() {
            return this.$route.params.id;
        },
        account() {
            return this.order.accountId;
        },
        merchantOrder() {
            return this.order.order || '-';
        },
        merchantDescription() {
            return this.order.description || '-';
        },
        hasOrder() {
            return Boolean(this.order);
        },
        currency() {
            return this.order.currency;
        },
        networkId() {
            return this.order.networkId;
        },
        received() {
            let number = BigNumber(this.order.received).decimalPlaces(8);
            return number.toFixed();
        },
        amount() {
            let number = new BigNumber(this.order.amount).decimalPlaces(8);
            return number.toFixed();
        },
        address() {
            return this.order.address;
        },
        tag() {
            return this.order.tag || '-';
        },
        errorWebhook() {
            return this.order.errorWebhook || '-';
        },
        successWebhook() {
            return this.order.successWebhook || '-';
        },
        returnUrl() {
            return this.order.returnUrl || '-';
        },
        createdAt() {
            return this.order.createdAt;
        },
        expiresAt() {
            return this.order.expiresAt;
        },
        transactions() {
            return this.order.transactions;
        },
        link() {
            return this.order.link;
        },
        hasTransactions() {
            return Boolean(this.transactions.length);
        },
        contract() {
            return this.order?.contract;
        },
        shortContract() {
            return this.$linkName(this.contract, this.networkId);
        },
        contractLink() {
            return this.$contractLink(this.currency, this.networkId);
        },

    },
    beforeMount() {
        this.setOrder();
    },
    methods: {
        ...mapActions('orders', [
            'fetchOrder',
        ]),
        setOrder() {
            const {
                orderId,
                fetchOrder,
            } = this;

            fetchOrder({
                id: orderId,
            })
                .then(order => {
                    this.order = order;
                });
        },
        goToLink() {
            if (this.link) {
                window.open(this.link);
            }
        },
        goBack() {
            if (window.history.length > 2)
                this.$router.go(-1);
            else this.$router.push({name: 'advanced-balances'});
        },
    },
};

</script>

<style lang="scss" scoped>

.order {
  padding-left: var(--padding-left-container);
  padding-right: var(--padding-right-container);
  padding-top: var(--padding-top-container);
  max-width: 1425px;

  &__link-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--primary-font-color);
  }

  &__link {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;

    &::v-deep .copy-string__copy {
      color: var(--primary-font-color);
    }

    &::v-deep .copy-string__string {
      color: var(--blue-300);
      word-break: break-all;
    }
  }

  &__transactions-list-header {
    min-width: 0;
  }

  &-hooks {

    &__info-container {
      padding-left: 45px;
      padding-right: 45px;
      display: grid;
      grid-template-columns: minmax(min-content, 216px) 1fr;
    }

    &__info-item-title {
      background: var(--main-page-line);
      border: 1px solid var(--border-color);
      min-height: 40px;
      padding-left: 45px;
      display: flex;
      align-items: center;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: var(--primary-font-color);

      &_date {
        font-weight: 500;
      }
    }

    &__info-item-value {
      border: 1px solid var(--border-color);
      min-height: 40px;
      padding-left: 45px;
      display: flex;
      align-items: center;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;

      color: var(--primary-font-color);
    }
  }

  &__link-container {
    min-height: 105px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 45px;
    border-bottom: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
  }

  &-contract {
    &__header {
      display: grid;
      grid-template-columns: 2fr repeat(2, 1fr);
    }

    &__title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: var(--primary-font-color);
    }

    &__value {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: var(--primary-font-color);
      word-break: break-all;

      &_link {
        cursor: pointer;
        color: #0059F7;
      }
    }

    &__title-container {
      min-height: 135px;
      padding-left: 45px;
      border-bottom: 1px solid var(--border-color);
      border-right: 1px solid var(--border-color);
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-right: 10px;

      &:last-of-type {
        border-right: none;
      }
    }
  }

  &-detailed-info {
    &__header {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      background: var(--main-page-line);
    }

    &__title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: var(--primary-font-color);
      min-height: 75px;
      padding-left: 45px;
      border-bottom: 1px solid var(--border-color);
      border-right: 1px solid var(--border-color);
      display: flex;
      align-items: center;

      &:last-of-type {
        border-right: none;
      }
    }

    &__values {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

    }

    &__value {
      min-height: 75px;
      padding-left: 45px;
      border-bottom: 1px solid var(--border-color);
      border-right: 1px solid var(--border-color);
      display: flex;
      align-items: center;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: var(--primary-font-color);

      &_mobile {
        display: none;
      }

      &:last-of-type {
        border-right: none;
      }
    }
  }

  &__info-description {
    &__container {
      min-height: 175px;
      padding-left: 45px;
      padding-top: 22px;
    }

    &__title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: var(--primary-font-color);
    }

    &__value {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: var(--primary-font-color);
    }
  }

  &-basic-info {


    &__title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: var(--primary-font-color);
    }

    &__values {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    &__value {
      min-height: 75px;
      border-bottom: 1px solid var(--border-color);
      border-right: 1px solid var(--border-color);
      display: flex;
      align-items: center;
      padding-left: 45px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: var(--primary-font-color);

      &-mobile {
        display: none;
      }

      &:last-of-type {
        border-right: none;
      }


    }


    &__header {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      background: var(--main-page-line);

      &-item {
        display: flex;
        gap: 18px;
        align-items: center;
        padding-left: 45px;
        min-height: 75px;
        border-bottom: 1px solid var(--border-color);
        border-right: 1px solid var(--border-color);

        &:last-of-type {
          border-right: none;
        }
      }
    }

    &__icon {
      width: 40px;
      height: 40px;
      background: var(--order-page-icon);
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;

      & .material-icons-outlined {
        font-family: 'Material Icons Outlined';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
        color: var(--primary-font-color);
      }
    }
  }

  &__transactions-list {
    z-index: 5;
    overflow-x: scroll;
    padding-bottom: 25px;
  }

  &__all {
    background: #0059F7;
    margin-bottom: 16px;
    border-radius: 6px;
    border: none;
    width: 100%;
    max-width: 125px;
    min-height: 40px;
    transition: all 0.3s ease-in;

    &:hover {
      background: var(--blue-400);
    }

    ::v-deep .at-btn__icon {
      font-size: var(--font-size-text-main);
    }

    ::v-deep .at-btn__text {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: var(--white-100);
    }

    ::v-deep .at-btn__icon {
      font-size: var(--font-size-text-main);
    }

  }


  &__title {
    font-family: var(--font-family);
    font-size: var(--font-size-h1);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    font-weight: normal;

  }

  &__sub-title {

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    padding-left: 45px;
    color: var(--primary-font-color);
    margin-bottom: 30px;
  }

  &__info {
    background: var(--secondary-color);
    border-radius: 20px;
    overflow: hidden;
    box-shadow: var(--shadow-container);
    margin-bottom: var(--margin-container-bottom);
    margin-top: var(--margin-container-top);
    border: 1px solid var(--border-color);
  }

  &__info-item {
    margin-bottom: 12px;
    display: flex;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__info-item-title {
    margin-right: 8px;
  }

  &__info-item-value {
    font-weight: 700;

    &.link {
      color: #5ECAE5;

      cursor: pointer;
    }

    &--currency {
      display: flex;
      align-items: center;
    }
  }

  &__info-container {
    min-height: 290px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid var(--border-color);
  }

  &__currency-img {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }

  &__transactions {
    padding-top: 44px;
    padding-bottom: 43px;
  }

  &__no-data {
    text-align: center;
    font-size: 18px;
    font-family: 'Roboto';
  }
}

@media screen and (max-width: 1180px) {
  .order {
    padding-top: 36px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 950px) {
  .order {
    padding-right: 10px;
    padding-left: 10px;

    &__sub-title {
      padding-left: 10px;
    }

    &-basic-info {
      &__header {
        display: flex;
        flex-direction: column;
        background: var(--secondary-color);

        &-item {
          padding-left: 10px;
        }
      }

      &__values {
        display: none;
      }

      &__value-mobile {
        display: block;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: var(--primary-font-color);
      }

      &__mobile-container {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    &-detailed-info {


      &__title {
        border: none;
        min-height: 0;
        padding-left: 10px;
        font-size: 14px;
        line-height: 16px;
      }

      &__header {
        background: var(--secondary-color);
        display: flex;
        flex-direction: column;

        &-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-height: 50px;
          border: 1px solid var(--border-color);
        }
      }

      &__values {
        display: none;
      }

      &__value {
        min-height: 0;
        padding-right: 10px;
        padding-left: 0;

        &_mobile {
          display: flex;
          border: none;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }


    &__info-description__container {
      padding-left: 10px;
      min-height: 100px;
    }

    &-contract {

      &__header {
        display: flex;
        flex-direction: column;
      }

      &__title-container {
        min-height: 76px;
        padding-left: 10px;
        padding-right: 10px;
        gap: 10px;
      }

      &__value {
        font-size: 14px;
        line-height: 16px;

      }
    }

    &__link {
      font-size: 14px;
      line-height: 21px;
    }

    &__link-container {
      padding-left: 10px;
      padding-right: 10px;
      min-height: 76px;
      gap: 10px;
    }

    &__info-container {
      min-height: 280px;
    }

    &-hooks {
      &__info-container {
        min-height: 40px;
        padding-left: 0;
        padding-right: 0;
      }

      &__info-item-title {
        padding-left: 10px;

      }

      &__info-item-value {
        padding-left: 10px;
        font-size: 12px;
      }
    }
  }

}
</style>
