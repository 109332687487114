<template>
  <AtButton
      :class="['button',buttonClass, hoverClass]"
      size="small"
      type="primary"
      :icon="icon"
      :disabled="disabled"
      :style="`
      --bg-color: var(${backgroundColor});
      --hover-color: var(${hoverColor});
      --border-color: var(${borderColor});
      --text-color: var(${textColor});
      `"
      @click="$emit('click')"
  >
    <slot></slot>
    {{ text }}

  </AtButton>
</template>

<script>
export default {
    name: "btn",
    props: {
        size: {
            type: String,
            default: 'm',
        },
        isHoverBorder: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        backgroundColor: {
            type: String,
            default: '--blue-300',
        },
        hoverColor: {
            type: String,
            default: '--blue-400',
        },
        borderColor: {
            type: String,
            default: 'transparent',
        },
        text: {
            type: String,
            default: '',
        },
        textColor: {
            type: String,
            default: '--white-100',
        },
        icon: {
            type: String,
            default: '',
        },

    },
    computed: {
        buttonClass() {
            switch (this.size) {
            case 's':
                return 'button_s';
            case 'm':
                return 'button_m';
            case 'l':
                return 'button_l';
            case 'xl':
                return 'button_xl';
            case 'xxl':
                return 'button_xxl';
            }
        },
        hoverClass() {
            return this.isHoverBorder ? 'button_border' : '';
        },
    },
};
</script>

<style scoped lang="scss">
.button {
  background: var(--bg-color);
  border-radius: 12px;
  border-color: var(--border-color);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transition: all 0.3s ease-in;

  ::v-deep .at-btn__icon {
    font-size: 24px;
  }

  ::v-deep .at-btn__text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--text-color);
    margin: 0;
  }

  &:hover {
    background: var(--hover-color);
  }

  &_border {
    &:hover {
      border-color: var(--hover-color);
      background: var(--background-color);
    }
  }

  &_s {
    min-width: 125px;
    height: 40px;
  }

  &_m {
    min-width: 211px;
    height: 46px;
  }

  &_l {
    min-width: 338px;
    height: 60px;
  }

  &_xl {
    min-width: 650px;
    height: 60px;
  }


}

@media screen and (max-width: 950px) {
  .button {
    &_m {
      min-width: 127px;
    }
  }
}
</style>
