<template>
  <div
      class="advanced_balances__network-currency-list-item"
      :class="{ active }"
      :style="{ height }"
  >
    <div class="left-block">
      <div class="currency-and-network-info-block">
        <div :class="['item__status', `item__status--${value}`]">
          {{ localTitle }}
        </div>
      </div>
    </div>
    <div class="right-block" />
  </div>
</template>

<script>
export default {
  name: "NetworkAndCurrencyItem",
  components: {
    Icon: () => import(/* webpackChunkName: "/public/components/elements/advance-balances/NetworkAndCurrenciesSelect/CurrencyNetworkIcon" */ '/public/components/elements/advance-balances/NetworkAndCurrenciesSelect/CurrencyNetworkIcon'),
  },
  props: {
      title: {
        name: String,
          default: ''
      },
      value: {
          name: String,
          default: ''
      },
      height: {
          type: String,
          default: '48px'
      },
      active: {
          type: Boolean,
          default: false
      },
  },
    computed: {
        localTitle ({ title }) {
            return title || this.$t(`orders.${this.value.toLowerCase()}`)
        }
    }
}
</script>

<style lang="scss">
.item__status {
  width: 90%;
  height: 32px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  &--init {
    color: var(--new-front-status-text-active);
    background: var(--new-front-status-active);
  }
  &--expired {
    color: var(--new-front-status-text-expired);
    background: var(--new-front-status-expired);
  }
  &--processed {
    color: var(--new-front-status-text-processed);
    background: var(--new-front-status-processed);
  }
  &--pending {
    color: var(--new-front-status-text-pending);
    background: var(--new-front-status-pending);
  }
  &--partial {
    color: var(--new-front-status-text-pending);
    background: var(--new-front-status-pending);
  }
  &--overpaid {
    color: var(--new-front-status-text-processed);
    background: var(--new-front-status-processed);
  }
  &--error {
    color: var(--new-front-status-text-error);
    background: var(--new-front-status-error);
  }
  &--refund {
    color: var(--new-front-status-text-error);
    background: var(--new-front-status-error);
  }
  &--rejected {
    color: var(--new-front-status-text-error);
    background: var(--new-front-status-error);
  }

  }
.advanced_balances {
  &__network-currency-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    //background-color: var(--dashboard-secondary-bg-color);

    &.active {
      background-color: var(--list-item-active);
    }

    //border: 1px solid var(--border-color);
    //border-radius: 10px;
    //padding: 16px 10px;

    color: var(--primary-font-color);
    cursor: pointer;

    .left-block {
      display: flex;
      align-items: center;
      width: 100%;
      .currency-and-network-info-block {
        display: flex;
        align-items: center;

        margin-left: 10px;
        width: 100%;

        .currency-alias {
          font-size: $h4;
          font-weight: 500;
          margin-left: 6px;
        }
        .network-alias {
          color: var(--secondary-font-color);
          font-size: $h4;
          margin-left: 6px;
        }
      }
    }
    .right-block {
      display: flex;
      align-items: center;
      justify-content: end;

      .check-block {
        display: flex;
        align-items: center;
        justify-content: center;

        min-width: 20px;
        padding-left: 10px;
      }
    }

    @include below_phone {
      padding: 16px 12px;

      .left-block {
        .currency-and-network-info-block {
          .currency-alias {
            font-size: $h6;
          }
          .network-alias {
            color: var(--secondary-font-color);
            font-size: $h6;
          }
        }
      }
    }
  }
}
</style>
