import gql from "graphql-tag";

export default gql`
    query($organizationId: String!, $limit: Int!, $offset: Int!, $pageId: String, $from: Date, $to: Date ) {
        getPreCheckoutPageOrders(organizationId: $organizationId, limit: $limit, offset: $offset, pageId: $pageId, from: $from, to: $to) {
            count
            orders {
                id
                pageId
                currencyId
                networkId
                status
                amount
                received
                priceUSD
                amountUSD
                receivedUSD
                utmSource
                utmMedium
                utmCampaign
                utmContent
                nickname
                message
                createdAt
            }
        }
    }
`;
