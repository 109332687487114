export const mutations = {
    result: (state, result) => {
        state.result = result;
    },
    totalCount: (state, count) => {
        state.totalCount = count;
    },
    clearAllStates: (state) => {
        state.countOnPage =  20
        state.result =  []
        state.totalCount = null
    }
};
