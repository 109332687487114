<script>

  import Container from "@/public/components/common/uiKit/Container.vue";
  import Copy from "@/public/components/common/copy.vue";
  import BaseInput from "@/public/components/common/uiKit/input/base/index.vue";
  import BaseCheckbox from "@/public/components/common/uiKit/Checkbox/index.vue";
  import BaseTextarea from "@/public/components/common/uiKit/textarea/index.vue";
  import {mapActions, mapGetters, mapState} from "vuex";
  import copyToClipboard from "/public/mixins/copyToClipboard";
  import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
  import TwoFaRestriction from "@/public/components/modals/twoFactorAuthorization/TwoFaRestriction.vue";
  import TwoFaConfirmation from "@/public/components/modals/twoFactorAuthorization/TwoFaConfirmation.vue";
const  FULL_RIGHTS = 1
  const PARTIAL_RIGHTS = 2
  const READ_ONLY = 3
  export default {
    name: "ApiKeysEdit",
    components: {TwoFaConfirmation, TwoFaRestriction, BaseButton, BaseTextarea, BaseCheckbox, BaseInput, Copy, Container},
    data() {
      return {
        // apiKeys: null,
        alias: null,
        canSendForm: false,
        ipsString: '',
        ipv6String: '',
        ipsError: '',
        ipv6Error: '',
        aliasError: '',
        rightsType: READ_ONLY,
        rules: {}
      };
    },
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    FULL_RIGHTS,
    PARTIAL_RIGHTS,
    READ_ONLY,
    mixins: [copyToClipboard],
    computed: {
      ...mapGetters('app', [
        'isOpenModal',
          'getSelectedOrganization'
      ]),
      ...mapState('app', {
        modalPayload: state => state.modal.payload,
      }),
      ...mapState('user', ['apiKeysPlaceholder', 'createdApiKeys']),
      ...mapGetters('organizations', ['userRoleRules']),
      isAddKeysAllowed () {
        return this.userRoleRules['api_keys'].entities.manage_api_keys
      },
      ipArray () {
        return this.ipsString.split(' ')
      },
      ipv6Array () {
        return this.ipv6String.split(' ')
      },
      publicKey() {
        return this.data.public;
      },
      isOpen() {
        const {
          $options: {
            name,
          },
          isOpenModal,
        } = this;

        return isOpenModal(name);
      },
      title() {
        return this.$t('api_keys.editing_an_api_key');
      },
      aliasStatus() {
        const {
          alias,
          canSendForm,
        } = this;

        return !alias && canSendForm ? 'error' : '';
      },
      be_sure_to_keep() {
        return this.$t('api_keys.be_sure_to_keep');
      },
      creating_an_api_keys() {
        return this.$t('api_keys.creating_an_api_keys');
      },
    },
    watch: {
      ipsString(newVal) {
        if (!newVal) {
          return
        }
        let re = /[^\d\s\.]/g;
        this.$set(this, 'ipsString', newVal.replace(re, ''));
      },
      ipv6String(newVal) {
        if (!newVal) {
          return
        }
        let re = /[^\d\s\.]/g;
        this.$set(this, 'ipv6String', newVal);
      },
      rightsType (value) {
        if (value === PARTIAL_RIGHTS) return

        this.setRightsType(value)
      }
    },
    async beforeMount() {
      await this.setRules()
      this.alias = this.data.alias
      this.ipsString = this.data.ipv4.length ? this.data.ipv4.join(' ') : ''
      this.ipv6String = this.data.ipv6.length ? this.data.ipv6.join(' ') : ''
    },
    methods: {
      ...mapActions('app', [
        'closeModal',
        'openModal'
      ]),
      ...mapActions('user', [
        'createApiKeys',
        'fetchApiKeys',
        'fetchApiRulesMap',
      ]),
      checkRights () {
        let viewAccessCount = 0
        let allTrueCount = 0
        let allRightsCount = 0
        let allViewCount = 0

        for (let key in this.rules) {
          for (const entity in this.rules[key]) {
            allRightsCount += 1
            if (entity === 'VIEW') {
              allViewCount+=1
              if (this.rules[key][entity]) {
                viewAccessCount += 1
              }
            }
            if (this.rules[key][entity]) {
              allTrueCount += 1
            }
          }
        }

        this.rightsType = (viewAccessCount === allTrueCount) && (viewAccessCount === allViewCount)
            ? READ_ONLY
            : allRightsCount === allTrueCount
                ? FULL_RIGHTS
                : PARTIAL_RIGHTS
        // rights[]
      },
      setRightsType (type) {
        for (let key in this.rules) {
          for (const entity in this.rules[key]) {
            this.rules[key][entity] = type === READ_ONLY ? entity === 'VIEW' : true
          }
        }
      },
      setRules () {
        this.rules = JSON.parse(this.data.rules)
        // Колхоз, лучше тут отфильтровывать с бэка
        if (this.getSelectedOrganization.isTest) {
          delete this.rules.SWAPS
          delete this.rules.BRIDGE
        }
        this.$forceUpdate()
      },
      clearAllData() {
        this.isLoading = false;
        // this.apiKeys = null;
        this.alias = null;
        this.canSendForm = false;
        this.ipsError = ''
        this.ipsString = ''
        this.aliasError = ''
        this.rules = {}
        this.rightsType = READ_ONLY
      },
      handleCreateRequest() {
        const {
          alias,
          createApiKeys,
            rules,
        } = this;

        this.canSendForm = true;

        if (!alias) {
          this.aliasError = this.$t('api_keys.alias_error')
          return false;
        }

        this.aliasError = ''

        const ips = this.ipArray.filter(item => item.length)
        const ipv6 = this.ipv6Array.filter(item => item.length)

        if (!ips.length && !ipv6.length && this.rightsType !== READ_ONLY) {
          this.ipsError = this.$t('api_keys.ip_exist')
          return
        }
        const v4Seg = '(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])';
        const v4Str = `(${v4Seg}[.]){3}${v4Seg}`;
        const v6Seg = '(?:[0-9a-fA-F]{1,4})';
        const IPv6Reg = new RegExp('^(' +
            `(?:${v6Seg}:){7}(?:${v6Seg}|:)|` +
            `(?:${v6Seg}:){6}(?:${v4Str}|:${v6Seg}|:)|` +
            `(?:${v6Seg}:){5}(?::${v4Str}|(:${v6Seg}){1,2}|:)|` +
            `(?:${v6Seg}:){4}(?:(:${v6Seg}){0,1}:${v4Str}|(:${v6Seg}){1,3}|:)|` +
            `(?:${v6Seg}:){3}(?:(:${v6Seg}){0,2}:${v4Str}|(:${v6Seg}){1,4}|:)|` +
            `(?:${v6Seg}:){2}(?:(:${v6Seg}){0,3}:${v4Str}|(:${v6Seg}){1,5}|:)|` +
            `(?:${v6Seg}:){1}(?:(:${v6Seg}){0,4}:${v4Str}|(:${v6Seg}){1,6}|:)|` +
            `(?::((?::${v6Seg}){0,5}:${v4Str}|(?::${v6Seg}){1,7}|:))` +
            ')(%[0-9a-zA-Z-.:]{1,})?$');

        ips.forEach(ip => {
              if (
                  /^(10)\.(.*)\.(.*)\.(.*)$/.test(ip) ||
                  /^(172)\.(1[6-9]|2[0-9]|3[0-1])\.(.*)\.(.*)$/.test(ip) ||
                  /^(192)\.(168)\.(.*)\.(.*)$/.test(ip) ||
                  !/^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/.test(ip)
              ) {
                this.ipsError = `${this.$t('api_keys.ip_invalid')}: ${ip}`

                return
              }
              this.ipsError = ''
            }
        )
        ipv6.forEach(ip => {
              if (
                  !IPv6Reg.test(ip)
              ) {
                this.ipv6Error = `${this.$t('api_keys.ip_invalid')}: ${ip}`

                return
              }
              this.ipsError = ''
            }
        )
        if (this.ipsError || this.ipv6Error) {
          return;
        }
        const readOnly = this.rightsType === 3

        const allIps = [...ips, ...ipv6]
        this.openModal({
          name: 'TwoFaConfirmation',
          payload: {
            alias,
            ips: allIps,
            rules: JSON.stringify(rules),
            readOnly,
            id: this.data.id
          }
        });
      },
      handleCancelCreate() {
        const {
          clearAllData,
          closeModal,
          fetchApiKeys,
          hasApiKeys,
        } = this;
        closeModal();
        if (hasApiKeys) {
          fetchApiKeys();
        }
        setTimeout(() => {
          clearAllData();
        }, 500);
      },
      back () {
        this.$emit('return')
      }
    },
  }
</script>

<template>
<div class="apiKeysCreate">
  <Container class="apiKeysCreate__container">
    <a class="apiKeysCreate__return" @click.prevent="back">
      <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.07812 0.583127C6.81906 0.454574 6.50723 0.476409 6.27015 0.639703L0.825704 4.3897C0.621307 4.53048 0.5 4.75779 0.5 5C0.5 5.24221 0.621307 5.46952 0.825704 5.6103L6.27015 9.3603C6.50723 9.52359 6.81906 9.54543 7.07812 9.41687C7.33717 9.28832 7.5 9.03094 7.5 8.75V1.25C7.5 0.969062 7.33717 0.71168 7.07812 0.583127Z" fill="#6750A4" stroke="#6750A4" stroke-linejoin="round"/>
      </svg>
      {{ $t('orphan.return') }}
    </a>
    <p class="apiKeysCreate__title">
        {{ $t('api_keys.editing_an_api_key') }}
    </p>
    <div class="create-new-api-keys-modal__container">
      <div class="publicKey__wrapper">
        <p class="publicKey__label">
          {{ $t('api_keys.public_key') }}
        </p>
        <p class="publicKey">{{ publicKey }}<copy :value="publicKey" /></p>
      </div>
      <div class="create-new-api-keys-modal__row">
        <base-input
            class="create-new-api-keys-modal__api-keys-value-input"
            :status="aliasStatus"
            :label="$t('api_keys.name_of_the_api_key')"
            :placeholder="$t('api_keys.enter_a_new_api_key_name')"
            v-model="alias"
            :max-length="255"
        />
        <p class="error" v-if="aliasError">{{ aliasError }}</p>
      </div>
      <div class="rules">
        <div class="rules__radio">
          <at-radio-group v-model="rightsType">
            <at-radio :label="$options.FULL_RIGHTS">{{ $t('api_keys-create.full-rights') }}</at-radio>
            <at-radio :label="$options.PARTIAL_RIGHTS">{{ $t('api_keys-create.partial-rights') }}</at-radio>
            <at-radio :label="$options.READ_ONLY">{{ $t('api_keys-create.read-only') }}</at-radio>
          </at-radio-group>
        </div>
        <div class="rules__list-wrapper">
          <div class="rules__item" v-for="(item, key) in rules" :key="key">
            <div class="rules__title">{{ $t(`api_keys-create.${key.toLowerCase()}`) }}</div>
            <div class="rules__list">
              <!--               entity type: VIEW UPDATE CREATE REMOVE PAYMENT  -->
              <base-checkbox v-model="rules[key][entityKey]" :value="rules[key][entityKey]" v-for="(entity, entityKey) in item" :key="`${entityKey}-${rules[key][entityKey]}`" :label="$t(`api_keys-create.${entityKey.toLowerCase()}`)" @input="checkRights"/>
            </div>
          </div>
        </div>
      </div>
      <div class="apiKeysCreate__ips">
        <div class=" create-new-api-keys-modal__row create-new-api-keys-modal__api-keys-item">
          <base-textarea
              сlas="textarea"
              v-model="ipsString"
              :placeholder="$t('api_keys.ip_addresses_placeholder')"
              :label="$t('api_keys.ip_addresses')"
              size="large"
              resize="none"
          />
          <p class="error" v-if="ipsError">{{ ipsError }}</p>
        </div>
        <div class=" create-new-api-keys-modal__row create-new-api-keys-modal__api-keys-item">
          <base-textarea
              сlas="textarea"
              v-model="ipv6String"
              :placeholder="$t('api_keys.ipv6_addresses_placeholder')"
              :label="$t('api_keys.ipv6_addresses')"
              size="large"
              resize="none"
          />
          <p class="error" v-if="ipv6Error">{{ ipv6Error }}</p>
        </div>
      </div>
    </div>
    <div class="apiKeysCreate__button">
      <base-button
          class="button"
          type="primary"
          :label="$t('api_keys.edit')"
          @click="handleCreateRequest"
      />
    </div>
  </Container>
</div>
</template>

<style scoped lang="scss">

.publicKey {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  color: var(--new-front-primary-font-color);
  font-size: 16px;
  font-weight: 500;
  word-break: break-all;
  &__wrapper {
    margin-bottom: 30px;
  }
  &__label {
    color: var(--new-front-input-label-font-color);
    font-size: $h6;
    line-height: $h6;
    margin-bottom: 5px;
  }
}
.rules {
  font-family: $Gilroy;
  &__title {
    font-weight: 500;
    font-size: $h4;
    margin-bottom: 20px;
    color: var(--new-front-primary-font-color);
  }
  &__list {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    &-wrapper {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 10px;
      row-gap: 0;
      @media screen and (max-width: 700px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media screen and (max-width: 430px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    &::v-deep {
      .checkbox {
        margin-bottom: 20px;
      }
      .checkbox-label {
        font-weight: 500;
      }
    }
  }
  &__radio {
    margin: 30px 0;
  }
}
.apiKeysCreate {
  font-family: $Gilroy;
  &__return {
    display: flex;
    align-items: center;
    color: var(--new-front-bg-btn-primary-bg-modal-primary);
    font-size: $h4;
    font-weight: 500;
    margin-bottom: $p30;
    gap: 10px;
  }
  &__title {
    font-family: $Gilroy;
    font-size: $h2;
    color: var(--new-front-primary-font-color);
    font-weight: 500;
    margin-bottom: 30px;
    width: 100%;
    @media screen and (max-width: 950px) {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  &__ips {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-bottom: 50px;
  }
  &__button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .base-btn {
      max-width: 280px;
    }
  }
}
// Radio styles
::v-deep {
  .at-radio {
    .at-radio__label {
      font-family: $Gilroy;
      font-size: $h4;
      font-weight: 500;
      color: var(--new-front-primary-font-color);
    }
    .at-radio__inner {
      border: 2px solid var(--new-front-input-border);
      width: 20px;
      height: 20px;
    }
    .at-radio__inner.at-radio--checked {
      border-color: var(--new-front-bg-btn-primary-bg-modal-primary);
    }
    .at-radio__inner::after {
      width: 11px;
      height: 11px;
      background: var(--new-front-bg-btn-primary-bg-modal-primary);
    }
  }
}
@media screen and (max-width: 576px){
  .apiKeysCreate {
    &__ips {
      grid-template-columns: 1fr;
    }
  }
  .apiKeysCreate__container {
    padding: 20px;
  }
  ::v-deep {
    .at-radio-group {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
      .at-radio+.at-radio {
        margin-left: 0;
      }
    }
    .apiKeysCreate__button .base-btn {
      max-width: 100%;
    }
  }
}
.error {
  color: var(--new-front-input-font-color-error);
  width: 100%;
}
.alert {
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 15px;
  &__message {
    font-weight: $Gilroy;
    color:  #BE9B40;
    font-size: 16px;
  }
}
.create-new-api-keys-modal__api-keys-value {
  display: flex;
  align-items: center;
  width: 100%;
  word-break: break-word;
  position: relative;
  margin-bottom: 20px;
}
.copy-key {
  position: absolute;
  right: 0;
  top: 33px;
}
</style>
