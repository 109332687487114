import getRegistriesData from "../../../../graphQl/queries/getRegistriesData";
import {getRouteQuery} from "/public/utils/getRouteQuery";
import {getFormatForUrlByArray} from "/public/utils/getFormatForUrlByArray";

export const fetchRegistries = async ({state, rootState, commit, dispatch}, payload) => {
    const {
        route,
        router,
        showUrlFilters = true,
        excludeParams = [],
        page,
        date,
    } = payload;
    const {registriesData: {registriesCountOnPage}} = state;

    const currentPage = page ? page : getRouteQuery({
        route,
        queryName: 'page',
        defaultValue: 1,
        excludeQueries: excludeParams,
        preparedReturnValueFunc: (value) => Number(value),
    });
    const currentDate = date ? date : getRouteQuery({
        route,
        queryName: 'date',
        defaultValue: [],
        preparedReturnValueFunc: (value) => value.split('to'),
    });


    const variables = {
        limit: registriesCountOnPage,
        offset: currentPage,
        date: currentDate,
    };

    let ordersData;

    if (showUrlFilters) {
        router.push({
            query: {
                page: currentPage,

                date: getFormatForUrlByArray(
                    currentDate,
                    (array) => array.join('to'),
                ),
            },
        }).catch((error) => {
            if (!(error instanceof NavigationDuplicated)) {
                throw error;
            }
        });
    }

    try {
        // commit('app/toggleLoading', null, {root: true});
        ordersData = await dispatch('app/request', {
            type: 'query',
            gql: getRegistriesData,
            variables,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        // commit('app/toggleLoading', null, {root: true});
    }

    if (ordersData.message) {
        throw ordersData.message;
    }

    if (ordersData.errors) {
        throw ordersData.errors;
    }


    const {
        data: {
            getRegistries: {
                registries,
                totalCount,
            },
        },
    } = ordersData;

    commit('registries', registries);
    commit('totalRegistriesCount', totalCount);

    return true;
};