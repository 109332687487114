<template>
<div>
  <div
      v-if="string"
      id="canvas"
      class="banner__code"
  />
</div>

</template>

<script>

import {mapState} from "vuex";
import debounce from "lodash/debounce";
import QRCodeStyling from "qr-code-styling";

export default {
    name: 'QrCode',
    props: {
        string: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            qr: null,
        };
    },
  computed: {
    ...mapState('app', ['theme']),

    colorQrCode() {
      if(this.theme === 'dark' || localStorage.getItem('data-theme-private') === 'dark') {
        return {
          bg: '#202020',
          code: '#ffffff',
        };
      }

      return {
        bg: '#FFFFFF',
        code: '#000000',
      };
    },
    sizeCode() {
      return '200';
    },
    debounceOnResize: ({ onResize }) => debounce(onResize, 100),
  },
  created() {
    this.debounceOnResize()

    window.addEventListener(
        'resize',
        this.debounceOnResize,
        { passive: true }
    )
  },
  mounted() {
    setTimeout(() => {
      this.createQR()
    }, 500)
  },
  methods: {
    createQR() {
      this.qrCode(this.colorQrCode);
    },
    qrCode(color) {
      const { sizeCode } = this

      const qrCode = new QRCodeStyling({
        width: sizeCode,
        height: sizeCode,
        margin: 0,
        type: "svg",
        data: this.string,
        dotsOptions: {
          color: color.code,
          type: "extra-rounded",
        },
        backgroundOptions: {
          color: color.bg,
        },
        imageOptions: {
          crossOrigin: "anonymous",
          margin: 0,
        },
        cornersSquareOptions: {
          type: 'extra-rounded',
          color: color.code,
        },
      });

      const canvas = document.getElementById("canvas")

      if (canvas) {
        qrCode.append(canvas);

        if (document.getElementById('canvas').children.length > 1) {
          document.getElementById('canvas').children[0].remove();
        }
      }
    },
    getClientWidth () {
      if (typeof document === 'undefined') return 0
      return Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0
      )
    },
    onResize () {
      this.width = this.getClientWidth()
    }
  }
};

</script>
