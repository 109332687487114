export const IO_EVENTS = {
    'TEST': 'TEST',
    'SERVER_TIME': 'SERVER_TIME',
    'EXCHANGE_RATE': 'EXCHANGE_RATE',
    'INTERNAL_EXCHANGE_RATE': 'INTERNAL_EXCHANGE_RATE',
    'MERCHANTS_EXCHANGE_RATE': 'MERCHANTS_EXCHANGE_RATE',
    'WIDGET_REALTIME_START': 'WIDGET_REALTIME_START',
    'WIDGET_REALTIME_END': 'WIDGET_REALTIME_END',
    'TX_INFO': 'TX_INFO',
    'NOTIFICATIONS_START': 'NOTIFICATIONS_START',
    'NOTIFICATIONS_END': 'NOTIFICATIONS_END',
    'NOTIFICATIONS': 'NOTIFICATIONS',
    'PRICE_RATE': 'PRICE_RATE',
    'SUBSCRIBE_WALLET_DEPOSIT_UPDATE': 'SUBSCRIBE_WALLET_DEPOSIT_UPDATE',
    'UNSUBSCRIBE_WALLET_DEPOSIT_UPDATE': 'UNSUBSCRIBE_WALLET_DEPOSIT_UPDATE',
    'WALLET_DEPOSIT_UPDATE': 'WALLET_DEPOSIT_UPDATE',
};
