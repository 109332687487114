import {getExchangeMutation} from "../../../../graphQl/queries/getExchange";

export const fetchExchange = async ({ dispatch, rootGetters }, payload) => {
    let requestData;
    // const selectedOrganizationId = rootGetters['app/getSelectedOrganizationId'];
    //
    // const variables = {
    //     ...payload,
    //     organizationId: selectedOrganizationId,
    // };

    try {
        requestData = await dispatch('app/request', {
            type: 'query',
            gql: getExchangeMutation,
            variables: payload,
        }, { root: true });
    } catch (error) {
        return error;
    }

    if (requestData.message) {
        throw requestData.message;
    }

    if (requestData.errors) {
        throw requestData.errors;
    }

    const {
        data: { getExchange },
    } = requestData;

    return getExchange;
};
