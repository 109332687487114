import createAccountMutation from "/public/graphQl/mutations/createAccount";

export const createAccount = async ({ commit, dispatch }) => {

    let data;
    try {
        data = await dispatch('app/request', {
            type: 'mutation',
            gql: createAccountMutation,
        }, { root: true });
    } catch (error) {
        return error;
    }

    commit('account', data);

    return true;
};