import getWithdrawalRequestsQuery from "/public/graphQl/queries/getWithdrawalRequests";
import {getRouteQuery} from "/public/utils/getRouteQuery";
import {getCurrenciesIdsByNames} from "/public/utils/getCurrenciesIdsByNames";
import {getFormatForUrlByArray} from "/public/utils/getFormatForUrlByArray";
import {getNetworksIdsByNames} from "../../../../utils/getNetworksIdsByNames";

export const fetchWithdrawalRequests = async ({state, rootState, commit, dispatch, rootGetters}, payload) => {
    const {
        route,
        router,
        showUrlFilters = true,
        excludeParams = [],
        page,
        sortDate,
        platform,
        currencies,
        date,
        networks,
    } = payload;
    const {requestsCountOnPage, filters: {status}} = state;
    const currentPage = page ? page : getRouteQuery({
        route,
        queryName: 'page',
        defaultValue: 1,
        excludeQueries: excludeParams,
        preparedReturnValueFunc: (value) => Number(value),
    });
    const currentSortDate = sortDate ? sortDate : getRouteQuery({
        route,
        queryName: 'sortDate',
        defaultValue: undefined,
    });
    const currentStatus = status ? status : getRouteQuery({
        route,
        queryName: 'status',
        defaultValue: [],
        preparedReturnValueFunc: (value) => value.split(','),
    });
    const currentPlatform = platform ? platform : getRouteQuery({
        route,
        queryName: 'platform',
        defaultValue: [],
        preparedReturnValueFunc: (value) => value.split(','),
    });
    const currentCurrencies = currencies ? currencies : getRouteQuery({
        route,
        queryName: 'currencies',
        defaultValue: [],
        preparedReturnValueFunc: (value) => value.split(','),
    });
    const currentDate = date ? date : getRouteQuery({
        route,
        queryName: 'date',
        defaultValue: [],
        preparedReturnValueFunc: (value) => value.split('to'),
    });
    const currentNetworks = networks ? networks : getRouteQuery({
        route,
        queryName: 'networks',
        defaultValue: [],
        preparedReturnValueFunc: (value) => value.split(','),
    });

    const allCurrencies = rootState.app.currencies;
    const allNetworks = rootState.app.networks;
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    const variables = {
        organizationId,
        limit: requestsCountOnPage,
        offset: currentPage,
        sortDate: currentSortDate,
        status: currentStatus,
        platform: currentPlatform,
        currencies: getCurrenciesIdsByNames(currentCurrencies, allCurrencies),
        networks: getNetworksIdsByNames(currentNetworks, allNetworks),
        date: currentDate,
    };
    let withdrawalRequestsData;

    if (showUrlFilters) {
        router.push({
            query: {
                page: currentPage,
                sortDate: currentSortDate,
                status: getFormatForUrlByArray(currentStatus, (array) => array.join(',')),
                platform: getFormatForUrlByArray(currentPlatform, (array) => array.join(',')),
                currencies: getFormatForUrlByArray(currentCurrencies, (array) => array.join(',')),
                date: getFormatForUrlByArray(currentDate, (array) => array.join('to')),
                networks: getFormatForUrlByArray(currentNetworks, (array) => array.join(',')),
            },
        });
    }

    try {
        commit('app/toggleLoading', null, {root: true});
        withdrawalRequestsData = await dispatch('app/request', {
            type: 'query',
            gql: getWithdrawalRequestsQuery,
            variables,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (withdrawalRequestsData.message) {
        throw withdrawalRequestsData.message;
    }

    if (withdrawalRequestsData.errors) {
        throw withdrawalRequestsData.errors;
    }

    const {
        data: {
            getWithdrawalRequests: {
                requests,
                totalCount,
            },
        },
    } = withdrawalRequestsData;

    commit('requests', requests);
    commit('totalRequestsCount', totalCount);

    return true;
};