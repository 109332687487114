import gql from 'graphql-tag';

export default gql`
    mutation createOrganizationRole($organizationId: String!, $name: String!, $comment: String, $rules: String) {
        createOrganizationRole(organizationId: $organizationId, name: $name, comment: $comment, rules: $rules) {
            code
            result
        }
    }
`;
