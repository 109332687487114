<template>
  <div class="exchange-sort-by-status">
    <p class="title">
      Статус
    </p>
    <AtSelect
        class="select"
        placeholder="Выберите статус"

        clearable
        :value="isActiveStatus"
        @on-change="selectStatus"
    >
      <AtOption
          v-for="(status, key) in $options.statuses"
          :key="key"
          :value="status.value"
          :label="status.title"
      />
    </AtSelect>
  </div>
</template>

<script>

import {mapState, mapMutations, mapActions} from 'vuex';

import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';
import {TRANSACTION_REQUEST_STATE_ENUM} from "../../../../common/constants/transaction-request-state-enum";

export default {
    name: 'ExchangesSortByStatus',
    statuses: [
        {
            value: TRANSACTION_REQUEST_STATE_ENUM.INIT,
            title: 'В обработке',
        },
        {
            value: TRANSACTION_REQUEST_STATE_ENUM.PROCESSED,
            title: 'Выполнена',
        },
        {
            value: TRANSACTION_REQUEST_STATE_ENUM.REJECTED,
            title: 'Отклонена',
        },
        {
            value: TRANSACTION_REQUEST_STATE_ENUM.PENDING,
            title: 'Ожидание',
        },
        {
            value: TRANSACTION_REQUEST_STATE_ENUM.READY,
            title: 'Обмен',
        },
    ],
    components: {
        AtSelect,
        AtOption,
    },
    computed: {
        ...mapState('exchanges', {
            isActiveStatus: state => state.filters.status,
        }),
    },
    beforeMount() {
        this.setStatusFromUrl();
    },
    methods: {
        ...mapMutations('exchanges', [
            'filtersStatus',
        ]),
        ...mapActions('exchanges', [
            'fetchExchanges',
        ]),
        setStatusFromUrl() {
            const {
                $route: {query},
                filtersStatus,
            } = this;
            const statusFromUrl = query.status;

            if (!statusFromUrl) {
                return false;
            }

            filtersStatus(statusFromUrl.split(','));
        },
        selectStatus(values) {
            const {
                $route,
                $router,
                filtersStatus,
                fetchExchanges,
            } = this;

            filtersStatus(values);
            fetchExchanges({
                route: $route,
                router: $router,
                excludeParams: ['page'],
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.exchange-sort-by-status {
  & .title {
    margin-bottom: 8px;
    font-weight: 700;
  }

  & .select ::v-deep .at-select__selection {
    min-height: 28px;
  }

  ::v-deep .at-select__dropdown .at-select__option {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

  ::v-deep .at-select__placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }

  ::v-deep .at-select__selection {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }


}

</style>
