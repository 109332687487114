import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!, $accountId: String!, $page: Int!, $limit: Int!, $type: [String]!, $date: [Date!]!, $sortDate: String) {
        getAccountData(organizationId: $organizationId, accountId: $accountId, page: $page, limit: $limit, type: $type, date: $date, sortDate: $sortDate) {
            logs {
                id
                type
                amount
                balance
                date
                transactionData {
                    id
                    currencyId
                    networkId
                    amount
                    type
                }
            }
            count
        }
    }
`;
