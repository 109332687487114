import gql from 'graphql-tag';

export default gql`
    mutation deleteOrganizationRole($organizationId: String!, $organizationRoleId: String!) {
        deleteOrganizationRole(organizationId: $organizationId, organizationRoleId: $organizationRoleId) {
            code
            result
        }
    }
`;
