<template>
  <div class="transactions-filter-by-date">
    <DatePicker
        class="transactions-filter-by-date__date"
        type="date"
        :showSecond="false"
        range
        confirm
        :confirm-text="select_dates"
        value-type="date"
        v-model="dates"
        :placeholder="$t('webhooks.period')"
        @confirm="selectDates"
        @input="selectDates"
        @clear="clearDates"
        :lang="selectLang"
        :disabled-date="disabledRange"
    />
  </div>
</template>

<script>

import Moment from "moment";
import {mapActions, mapState} from 'vuex';

import DatePicker from "@/public/components/common/uiKit/Datepicker/Datepicker.vue";
import 'vue2-datepicker/locale/ru';

export default {
    name: 'TransactionsFilterByDate',
    components: {
        DatePicker,
    },
    data() {
        return {
            dates: [],
        };
    },
    computed: {
        ...mapState('user', [
            'user',
        ]),
        ...mapState('historyAddresses', ['type']),
        selectLang() {
            return this.user.lang;
        },
        select_dates() {
            return this.$t('transactions.select_dates');
        },

        addressId() {
            return this.$route.params.id;
        },
    },
    // beforeMount() {
    //     this.setDateFromUrl();
    // },
    methods: {
        ...mapActions('historyAddresses', [
            'addressTransactions',
        ]),
        disabledRange(date) {
            return date < new Date(2022, 0, 1) || date > new Date();
        },
        selectDates() {
            const {
                dates,
            } = this;
            if (!dates) {
                this.$emit('selectDate', [])
                return
            }
            const dateFrom = Moment(dates[0]).format();
            const dateTo = Moment(dates[1])
                .add(23, 'hours')
                .add(59, 'minutes')
                .add(59, 'seconds')
                .format()
      ;
            this.$emit('selectDate', [dateFrom, dateTo])
        },
        clearDates() {
            this.$emit('selectDate', null)
        },
    },
};

</script>

<style lang="scss" scoped>

.transactions-filter-by-date {
  &__title {
    margin-bottom: 8px;
    font-size: var(--font-size-text-main);
    font-family: var(--font-family);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }
}

</style>
