<template>
  <base-select
    v-model="innerModel"
    :placeholder="$t('advance_balances.history.filters.operation_type.placeholder')"
    class="settings-currency-select"
    :list="filteredList"
    multiple
    :canBeClosed="false"
    counterTextTranslate="integration-settings.elements.currency-select.selected-label"
    clearable
    gap
  >
    <template #selected="data">
      <div class="selected-block">
        <net-curr-item v-bind="data" style="height: 46px; max-height: 46px"/>
        <div class="counter">
          {{ counter }}
        </div>
      </div>
    </template>
    <template #search>
      <search
        id="search-input"
        class="search"
        v-model="search"
        :clearWhenDestroyed="false"
      />
    </template>
    <template #info="data">
      <net-curr-item v-bind="data" style="height: 46px; max-height: 46px"/>
    </template>
  </base-select>
</template>

<script>
import BaseSelect from '/public/components/common/uiKit/selects/base'
import { mapState } from "vuex";

export default {
  name: "TypesSelect",
  components: {
    BaseSelect,
    Search: () => import(/* webpackChunkName: "public/components/common/uiKit/search" */ '/public/components/common/uiKit/search'),
    NetCurrItem: () => import(/* webpackChunkName: "public/components/elements/integration-settings/selects/currency/Option" */ './Option'),
  },
  props: {
    value:  {
      type: Array,
      default: () => ([])
    },
  },
  data() {
    return {
      search: ''
    }
  },
  computed: {
    ...mapState('app', ['currencies']),
    networksAndCurrencies({currencies}) {
      return currencies.reduce((acc, curr) => {
        if (curr.networks) {
          const {allowInvoice} = curr

          if (allowInvoice) {
            curr.networks.forEach(network => {
              if (!network.allowDeposit) {
                return
              }
              const currencyWithNetwork = {
                id: `${curr.id}_${network.id}`,
                networkId: network.id,
                currencyId: curr.id,
                currency: curr,
                network: network,
                label: `${curr.currency} (${network.name.toUpperCase()})`,
              }

              acc.push(currencyWithNetwork)
            })
          }
        }

        return acc
      }, [])
    },
    filteredList({networksAndCurrencies, search}) {
      //метод фильтрации общего списка валют
      if (search) {
        return networksAndCurrencies.filter((netCurr = {}) => {
          const networkName = netCurr['network']['name'] ? netCurr['network']['name'].toLowerCase() : ''
          const networkAlias = netCurr['network']['alias'] ? netCurr['network']['alias'].toLowerCase() : ''
          const currencyName = (netCurr['currency'] && netCurr['currency']['currency']) ? netCurr['currency']['currency'].toLowerCase() : ''
          const currencyAlias = (netCurr['currency'] && netCurr['currency']['alias']) ? netCurr['currency']['alias'].toLowerCase() : ''

          return networkName.includes(search.toLowerCase()) || networkAlias.includes(search.toLowerCase()) || currencyName.includes(search.toLowerCase()) || currencyAlias.includes(search.toLowerCase())
        })
      } else {
        return networksAndCurrencies
      }
    },
    innerModel: {
      get({value, networksAndCurrencies}) {
        return value.map(arr => networksAndCurrencies.find(item => item.currencyId === arr[0] && item.networkId === arr[1]))
      },
      set(v) {
        if (!v) {
          return this.$emit('input', v)
        }

        this.$emit('input', v.map(({networkId, currencyId}) => [currencyId, networkId]))
      }
    },
    counter({ innerModel }) {
      return !!innerModel && innerModel.length > 1 ? `+${innerModel.length - 1}` : undefined
    }
  }
}
</script>

<style scoped lang="scss">
  .settings-currency-select {
    .selected-block {
      display: flex;
      align-items: center;
      gap: 5px;

      .counter {
        font-size: $h6;
        color: var(--text-color-text-secondary, #818CA0);
      }
    }
    .search {
      margin-bottom: 15px;
    }
  }
</style>