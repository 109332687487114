export const state = {
    settingsList: [],
    webhooksTotal: 0,
    setting: {
        errorMessage: ''
    },
    settingsHistory: [],
    minimumAmount: 0,
    isRequestProcessed: false
};
