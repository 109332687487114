import CreateUserMutation from "../../../../graphQl/mutations/personalAddressCreateUser";

export const createUser = async ({dispatch, commit, rootGetters}, payload) => {
  let resp;

  payload.organizationId = rootGetters['app/getSelectedOrganization'].id;

  try {
    resp = await dispatch('app/request', {
      type: 'mutation',
      gql: CreateUserMutation ,
      variables: payload,
    }, {root: true});
  } catch (error) {
    console.error('Has error when add address ---> ', error)
    return error;
  }

  const { data } = resp || {}
  const { createPersonalUser } = data || {}

  return createPersonalUser || { result: null, code: 404 }
};