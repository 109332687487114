import {getOrganizationUsers} from "./actions/getOrganizationUsers";
import {getOrganizationRoles} from "./actions/getOrganizationRoles";
import {getOrganizationRole} from "./actions/getOrganizationRole";
import {updateOrganizationRole} from "./actions/updateOrganizationRole";
import {createOrganizationRole} from "./actions/createOrganizationRole";
import {deleteOrganizationRole} from "./actions/deleteOrganizationRole";
import {createOrganizationUser} from "./actions/createOrganizationUser";
import {updateOrganizationUser} from "./actions/updateOrganizationUser";
import {deleteOrganizationUser} from "./actions/deleteOrganizationUser";
import {getOrganization} from "./actions/getOrganization";
import {updateOrganization} from "./actions/updateOrganization";
import {getUserOrganizationRole} from "./actions/getUserOrganizationRole";
import {changeOrganizationOwner} from "@/public/store/modules/organizations/actions/changeOrganizationOwner";
import {createOrganization} from "@/public/store/modules/organizations/actions/createOrganization";

export const actions = {
    getOrganizationUsers,
    getOrganizationRoles,
    getOrganizationRole,
    getUserOrganizationRole,
    updateOrganizationRole,
    createOrganizationRole,
    deleteOrganizationRole,
    createOrganizationUser,
    updateOrganizationUser,
    deleteOrganizationUser,
    getOrganization,
    updateOrganization,
    changeOrganizationOwner,
    createOrganization
};
