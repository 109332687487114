<template>
<div class="orphan">
    <div class="orphan__wrapper">
        <Container >
            <div class="orphan__header">
                <div class="orphan__header-left">
                    <h1 class="orphan__title">{{ $t('orphan.info') }}</h1>
                    <h2 class="orphan__subtitle">{{ moment(orphan.createdAt).format('DD/MM/YY HH:mm') }}</h2>
                </div>
                <div :class="['orphan__statusLabel', `orphan__statusLabel--${ status.status }`]">
                    {{ status.label }}
                </div>
            </div>
            <div class="orphan__items">
                <div class="orphan__item">
                    <div class="orphan__item-title">
                        tx hash in:
                    </div>
                    <div class="orphan__item-value">
                        {{ orphanInHash }}
                        <copy :value="this.orphan.inTransaction?.txId"/>
                    </div>
                </div>
                <div class="orphan__item">
                    <div class="orphan__item-title">
                        tx in status:
                    </div>
                        <div :class="['orphan__statusLabel', `orphan__statusLabel--${ statusIn.status }`]">
                            {{ statusIn.label }}
                        </div>
                </div>
                <div class="orphan__item">
                    <div class="orphan__item-title">
                        {{ $t('orphan.amount') }}:
                    </div>
                    <div class="orphan__item-value--black">
                        {{ amount | separatePeriods }}

                    </div>
                </div>
                <div class="orphan__item">
                    <div class="orphan__item-title">
                        {{ $t('orphan.currency-network') }}:
                    </div>
                    <div class="orphan__asset create-business-modal__padding-wrapper">
                        <currency-network-icon :currency="ticker" :network="networkName?.toUpperCase()" />
                        <div class="orphan__asset-currency">
                            {{ alias }} |
                        </div>
                        <div class="orphan__asset-network">
                            {{ (networkName || '') | getNetworkShortNameByNetworkName }}
                        </div>
                    </div>
                </div>
                <div class="orphan__item">
                    <div class="orphan__item-title">
                        {{ $t('orphan.where-found') }}:
                    </div>
                    <div class="orphan__item-value">
                            {{ (orphan.inTransaction?.address || '') | getPrettyAddress(4) }}
                        <copy :value="orphan.inTransaction?.address" />
                    </div>
                </div>
                <div class="orphan__item">
                    <div class="orphan__item-title">
                        tx hash out:
                    </div>
                    <div class="orphan__item-value">
                        {{ orphanOutHash }}
                        <copy v-if="orphanOutHash" :value="this.orphan.outTransaction?.txId"/>
                    </div>
                </div>
                <div class="orphan__item">
                    <div class="orphan__item-title">
                        tx out status:
                    </div>
                        <div v-if="orphan.outTransaction" :class="['orphan__statusLabel', `orphan__statusLabel--${statusOut?.status}`]">
                            {{  statusOut.label }}
                        </div>
                </div>
                <div class="orphan__item">
                    <div class="orphan__item-title">
                        {{ $t('orphan.transfer-address') }}:
                    </div>
                    <div class="orphan__item-value">
                        {{ orphanOutAddress }}
                        <copy v-if="orphanOutAddress" :value="orphan.outTransaction.address"/>
                    </div>
                </div>
                <div class="orphan__comment">
                    <div class="orphan__comment-title">{{ $t('orphan.withdrawal-comment') }}:</div>
                    <div class="orphan__comment-value">
                        {{ orphan.message }}
                    </div>
                </div>
                <div v-if="orphan.canWithdrawal" class="orphan__btn">
                    <base-button :disabled="!isWithdrawAllowed" @click="openCreateWithdrawalRequestModal" type="primary" :label="$t('create-withdrawal-request-modal.send')" />
                </div>
            </div>
        </Container>
        <router-link class="orphan__return" to="/orphan-transactions">
                <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.07812 0.583127C6.81906 0.454574 6.50723 0.476409 6.27015 0.639703L0.825704 4.3897C0.621307 4.53048 0.5 4.75779 0.5 5C0.5 5.24221 0.621307 5.46952 0.825704 5.6103L6.27015 9.3603C6.50723 9.52359 6.81906 9.54543 7.07812 9.41687C7.33717 9.28832 7.5 9.03094 7.5 8.75V1.25C7.5 0.969062 7.33717 0.71168 7.07812 0.583127Z" fill="#6750A4" stroke="#6750A4" stroke-linejoin="round"/>
                </svg>
            {{ $t('orphan.return') }}
        </router-link>
    </div>
    <QRCodeModal />
    <NotEnoughAdvanceBalance />
    <TwoFaRestriction />
    <TwoFaConfirmation modal-name="OrphanCreateWithdrawalRequestModalPayout"/>
    <OrphanCreateWithdrawalRequestModalPayout @update="loadOrphanDeposit"/>
</div>
</template>

<script >
import Container from "@/public/components/common/uiKit/Container.vue";
import Pagination from "@/public/components/common/uiKit/Pagination.vue";
import OrphanSearch from "@/public/components/elements/orphan-transactions/OrphanSearch.vue";
import BusinessWalletNetworkSelect
    from "@/public/components/elements/business-walet/Select/businessWalletNetworkSelect.vue";
import Table from "@/public/components/elements/orphan-transactions/table/Table.vue";
import RefreshButton from "@/public/components/common/uiKit/button/refresh/index.vue";
import TableItem from "@/public/components/elements/orphan-transactions/table/TableItem.vue";
import Copy from "@/public/components/common/copy.vue";
import {mapActions, mapGetters, mapState} from "vuex";
import orphan from "../store/modules/orphan";
import moment from "moment";
import BigNumber from "bignumber.js";
import CurrencyNetworkIcon from "@/public/components/elements/business-walet/Select/CurrencyNetworkIcon.vue";
import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
import NotEnoughAdvanceBalance from "@/public/components/modals/business-wallet/NotEnoughAdvanceBalance.vue";
import OrphanCreateWithdrawalRequestModalPayout
    from "@/public/components/modals/orphan/OrphanCreateWithdrawalRequestModalPayout.vue";
import TwoFaRestriction from "@/public/components/modals/twoFactorAuthorization/TwoFaRestriction.vue";
import TwoFaConfirmation from "@/public/components/modals/twoFactorAuthorization/TwoFaConfirmation.vue";
import QRCodeModal from "@/public/components/modals/twoFactorAuthorization/QRCodeModal.vue";
import {getPrettyAddress} from "@/public/filters";

export default  {
    name: 'OrphanInfo',
    components: {
        QRCodeModal,
        TwoFaConfirmation,
        TwoFaRestriction,
        OrphanCreateWithdrawalRequestModalPayout,
        NotEnoughAdvanceBalance,
        BaseButton,
        CurrencyNetworkIcon,
        Copy, TableItem, RefreshButton, Table, BusinessWalletNetworkSelect, OrphanSearch, Container, Pagination},
    created() {
        this.loadOrphanDeposit()
    },
    methods: {
      ...mapActions('orphan', ['getOrphanDeposit']),
        ...mapActions('app', ['openModal']),
        loadOrphanDeposit () {
            this.getOrphanDeposit(this.addressId)
        },
        openCreateWithdrawalRequestModal(addressId) {
            const {
                // currency,
                // amount,
                openModal,
                accounts,
                orphan
            } = this;

            if (!this.userOtpEnabled) {
                openModal({
                    name: 'TwoFaRestriction',
                });
                return;
            }
            if (Number(accounts[0].balance) < 0) {
                openModal({
                    name: 'NotEnoughAdvanceBalance',
                    payload: {
                        advanceBalanceAmount: accounts[0].balance,
                    },
                });
                return;
            }

            openModal({
                name: 'OrphanCreateWithdrawalRequestModalPayout',
                payload: {
                    orphan,
                },
            });
        },
    },
    computed: {
        ...mapState('app', ['networks', 'currencies']),
        ...mapState('orphan', ['orphan']),
        ...mapState('accounts', ['accounts']),
        ...mapState('user', {
            userOtpEnabled: state => state.user.otpToken,
        }),
        ...mapGetters('organizations', ['userRoleRules']),
        isWithdrawAllowed () {
            return this.userRoleRules['orphan'].entities.make_withdrawal
        },
        orphanOutHash () {
          if (!this.orphan.outTransaction || !this.orphan.outTransaction.txId)  {
              return ''
          }
          return getPrettyAddress(this.orphan.outTransaction.txId)
        },
        orphanInHash () {
            if (!this.orphan.inTransaction || !this.orphan.inTransaction.txId)  {
                return ''
            }
            return getPrettyAddress(this.orphan.inTransaction.txId)
        },
        orphanOutAddress () {
            if (!this.orphan.outTransaction)  {
                return ''
            }
            return getPrettyAddress(this.orphan.outTransaction.address)
        },
        network () {
            return this.networks.find(({ id }) => id === this.orphan.networkId)
        },
        networkName () {
          return this.network?.name
        },
        moment() {
            return moment
        },
        currency () {
            return this.currencies.find(({ id }) => id === this.orphan.currencyId)
        },
        ticker ( ) {
          return this.currency?.currency
        },
        alias () {
            return this.currency?.alias
        },
        addressId() {
            return this.$route.params.id;
        },
        amount () {
            return new BigNumber(this.orphan.amount)
                .toFixed()
        },
        status () {
            if (!this.orphan?.status) {
                return {
                    status: '',
                    label: ''
                }
            }
                return {
                    status: this.orphan.status.toLowerCase(),
                    label: this.$t(`orphan.status-${this.orphan.status.toLowerCase()}`)
                }
        },
        statusOut () {
            if (!this.orphan?.outTransaction) {
                return {
                    status: '',
                    label: ''
                }
            }
            return {
                status: this.orphan.outTransaction.status.toLowerCase(),
                label: this.$t(`orphan.status-${this.orphan.outTransaction.status.toLowerCase()}`)
            }
        },
        statusIn () {
            if (!this.orphan?.inTransaction) {
                return {
                    status: '',
                    label: ''
                }
            }
            return {
                status: this.orphan.inTransaction.status.toLowerCase(),
                label: this.$t(`orphan.status-${this.orphan.inTransaction.status.toLowerCase()}`)
            }
        }
    }
}
</script>

<style scoped lang="scss">
.orphan__statusLabel--rejected {
    background: var(--new-front-status-error);
    color: var(--new-front-status-text-error);
}
.orphan {
  padding: 30px;
    font-family: $Gilroy;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    &::v-deep {
        .container-new {
            width: 665px;
        }
    }
    &__btn {
        margin-top: $p30;
    }
    &__comment {
        margin-top: $p15;
        font-weight: 500;
        font-size: 16px;
        &-title {
            color: var(--new-front-secondary-font-color);
        }
        &-value {
            color: var(--new-front-primary-font-color);
        }
    }
    &__asset {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        text-decoration: none;
        &-currency {
            font-weight: 500;
            font-size: $h4;
            color: var(--new-front-primary-font-color);
            margin: 0 10px;
        }
        &-network {
            font-weight: 500;
            font-size: $h4;
            color: var(--new-front-secondary-font-color);
        }
    }
    &__return {
        margin-top: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--new-front-bg-btn-primary-bg-modal-primary);
        font-size: $h4;
        font-weight: 500;
    }
    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        //padding: 0 $p15;
        height: 60px;
        border-bottom: 1px var(--new-front-divider-color) solid;
        &-title {
            font-weight: 500;
            font-size: 16px;
            color: var(--new-front-secondary-font-color);
        }
        &-value {
            display: flex;
            align-items: center;
            color: var(--new-front-purple);
            text-decoration: underline;
            font-size: $h4;
            font-weight: 500;
            &--black {
                color: var(--new-front-primary-font-color);
                font-weight: 500;
                font-size: $h4;
            }
        }
    }
    &__statusLabel {
        padding: 4px $p15;
        background: var(--new-front-status-processed);;
        color: var(--new-front-status-text-processed);
        border-radius: 10px;
        font-size: $h4;
        font-weight: 500;
        &--error {
            background: var(--new-front-status-error);
            color: var(--new-front-status-text-error);
        }
        &--rejected {
            background: var(--new-front-status-error);
            color: var(--new-front-status-text-error);
        }
        &--pending {
            background: var(--new-front-status-pending);
            color: var(--new-front-status-text-pending);
        }
        &--processed {
            background: var(--new-front-status-processed);
            color: var(--new-front-status-text-processed);
        }
    }
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $p30;
    }
  &__gridRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
    &__searchRow {
        display: flex;
        align-items: center;
        gap: 10px;
        .search {
            width: 93%;
        }
    }
    &__title {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: $h2;
        color: var(--new-front-text-primary-bg-modal-primary);
        margin-bottom: $p5;
        line-height: 31px;
        > svg {
            margin-left: 5px;
        }
    }
    &__subtitle {
        font-size: $h4;
        font-weight: 400;
        color: var(--new-front-secondary-font-color);
    }
}
::v-deep {
    .advanced-balances-select-wrapper .sublabel {
        display: none;
    }
}
@media (max-width: 665px) {
    .orphan {
        margin-top: $p10;
        padding: 0 20px 20px 20px;

        &::v-deep {
            .container-new {
                width: 100%;
                margin: 0 $p10;
            }
        }
    }
}
@media (max-width: 576px) {
    .orphan {
        padding: 0 20px 20px 20px;

        &::v-deep {
            .container-new {
                padding: 20px !important;
            }
        }
    }
}
</style>
