export const PROCESSING_FACADE_ERROR_CODES = {
    // server errors
    UNKNOWN_ERROR: -1,
    USER_IS_REQUIRED: 1,
    TRACE_ID_IS_REQUIRED: 2,

    FAIL_FETCH_ORGANIZATION_ACCOUNT: 3,
    FAIL_FETCH_ACCOUNT_BALANCES: 4,
    SERVER_NETWORK_NOT_FOUND: 5,
    FAIL_VALIDATE_FEE: 6,
    FAIL_FETCH_PAYOUT_ADDRESSES: 7,

    // validation errors
    CURRENCY_IS_REQUIRED: 1001,
    CURRENCY_IS_NOT_VALID: 1002,
    AMOUNT_IS_REQUIRED: 1003,
    AMOUNT_IS_NOT_VALID: 1004,
    AMOUNT_LTE_ZERO: 1005,
    ERROR_WEBHOOK_IS_REQUIRED: 1006,
    ERROR_WEBHOOK_IS_NOT_VALID: 1007,
    SUCCESS_WEBHOOK_IS_REQUIRED: 1008,
    SUCCESS_WEBHOOK_IS_NOT_VALID: 1009,
    AVAILABLE_TILL_IS_NOT_VALID: 1010,
    WITHDRAWAL_ITEMS_IS_NOT_ARRAY: 1011,
    WITHDRAWAL_ADDRESS_IS_REQUIRED: 1012,
    WITHDRAWAL_ADDRESS_NOT_VALID: 1013,
    WITHDRAWAL_TAG_NOT_VALID: 1014,
    WITHDRAWAL_ITEM_AMOUNT_IS_REQUIRED: 1015,
    WITHDRAWAL_ITEM_AMOUNT_IS_NOT_VALID: 1016,
    WITHDRAWAL_ITEM_AMOUNT_LTE_ZERO: 1017,
    WITHDRAWAL_ITEM_ADDRESS_NOT_VALIDATED: 1018,
    CURRENCY_NOT_FOUND: 1019,
    NO_HAS_BALANCE: 1020,
    BALANCE_LESS_THEN_REQUESTED_AMOUNT: 1021,
    WITHDRAWAL_OR_EXCHANGE_FOR_CURRENCY_ALREADY_STARTED: 1022,
    CURRENCY_FROM_IS_REQUIRED: 1023,
    CURRENCY_FROM_IS_NOT_VALID: 1024,
    CURRENCY_TO_IS_REQUIRED: 1025,
    CURRENCY_TO_IS_NOT_VALID: 1026,
    AMOUNT_FROM_IS_REQUIRED: 1027,
    AMOUNT_FROM_IS_NOT_VALID: 1028,
    AMOUNT_FROM_LTE_ZERO: 1029,
    CURRENCY_FROM_NOT_FOUND: 1030,
    CURRENCY_TO_NOT_FOUND: 1031,
    ORDER_ID_IS_REQUIRED: 1032,
    ORDER_ID_IS_NOT_VALID: 1033,
    ESTIMATED_FEE_ITEMS_IS_NOT_ARRAY: 1034,
    ESTIMATED_FEE_ITEM_ADDRESS_IS_REQUIRED: 1035,
    ESTIMATED_FEE_ITEM_ADDRESS_NOT_VALID: 1036,
    ESTIMATED_FEE_ITEM_TAG_NOT_VALID: 1037,
    ESTIMATED_FEE_ITEM_AMOUNT_IS_REQUIRED: 1038,
    ESTIMATED_FEE_ITEM_AMOUNT_IS_NOT_VALID: 1039,
    ESTIMATED_FEE_ITEM_AMOUNT_LTE_ZERO: 1040,
    ESTIMATED_FEE_ITEM_ADDRESS_NOT_VALIDATED: 1041,
    RETURN_URL_IS_NOT_VALID: 1042,
    MERCHANT_ORDER_IS_TOO_LARGE: 1043,
    MERCHANT_DESCRIPTION_IS_TOO_LARGE: 1044,
    NETWORK_ID_IS_REQUIRED: 1045,
    NETWORK_ID_IS_NOT_VALID: 1046,
    ACCOUNT_ID_IS_REQUIRED: 1047,
    ACCOUNT_ID_IS_NOT_VALID: 1048,
    ADDRESS_ID_IS_REQUIRED: 1049,
    ADDRESS_ID_IS_NOT_VALID: 1050,
    FEE_TOKEN_IS_REQUIRED: 1051,
    FEE_TOKEN_IS_INVALID: 1052,
    ACCOUNT_BALANCE_LESS_THAN_REQUESTED_FEE_AMOUNT: 1053,
    AMOUNT_LESS_THAN_WITHDRAWAL_MIN_AMOUNT: 1054,
    NETWORK_IS_REQUIRED: 1055,
    NETWORK_IS_NOT_VALID: 1056,
    SOURCE_BALANCE_LESS_THAN_REQUESTED_FEE_AMOUNT: 1057,
    FEE_TOKEN_EXPIRED: 1058,
    FEE_TOKEN_GENERATED_FOR_OTHER_ACCOUNT_OR_ADDRESS: 1059,
    UNKNOWN_SOURCE: 1060,
    FAIL_CONVERT_BCH_ADDRESS_TO_LEGACY:1061,
    LIFETIME_IS_NOT_VALID: 1062,
    LIFETIME_IS_REQUIRED: 1063,
    FAIL_PAYOUT_ADDRESS_ID_DOESNT_EXIST: 1064,
    REGISTRY_ID_IS_REQUIRED: 1065,
    REGISTRY_ID_IS_NOT_VALID: 1066,
    REGISTRY_TITLE_IS_REQUIRED: 1067,
    REGISTRY_TITLE_IS_NOT_VALID: 1068,
    NETWORK_NOT_FOUND: 1069,
    REGISTRY_NOT_FOUND: 1070,
    FAIL_REQUEST_FEE_FOR_ITEM: 1071,
    FAIL_GET_REGISTRY: 1072,
    FAIL_GET_REGISTRY_ITEMS: 1073,
    ERROR_FEE_TOKENS_ARE_REQUIRED: 1074,
    FAIL_GET_REGISTRIES: 1075,
    ERROR_SOME_REGISTRY_ITEMS_INVALID: 1076,
    FAIL_CSV_INVALID_DELIMITER: 1077,
    FAIL_CSV_INVALID_AMOUNT_OF_COLUMNS: 1078,
    FAIL_CSV_REQUIRED_FIELDS_ARE_EMPTY: 1079,
    ADVANCED_BALANCE_ID_NOT_OWNED_USER: 1080,
    ORDER_ID_NOT_OWNED_USER: 1081,
    ACCOUNT_NOT_OWNED_BY_ORGANIZATION: 1082,
    INACTIVE_CURRENCY: 1083,
    INACTIVE_NETWORK: 1084,
    WITHDRAWAL_NOT_ALLOWED_BY_CURRENCY_SETTINGS: 1085,
    WITHDRAWAL_NOT_ALLOWED_BY_NETWORK_SETTINGS: 1086,
    NETWORK_NOT_EXIST: 1087,
    NETWORK_DEPOSITS_DISABLED: 1088,
    LIFETIME_OUT_OF_RANGE: 1089,
    CURRENCY_IS_NOT_EXIST: 1090,
    ADDRESS_NOT_OWNED_BY_ACCOUNT: 1091,
    FAIL_CSV_TOO_MANY_LINES: 1092,
    ERROR_FILE_SIZE_TOO_BIG: 1093,
    INSURANCE_PERCENT_IS_INVALID: 1094,
    SLIPPAGE_PERCENT_IS_INVALID: 1095,
    CURRENCIES_IS_INVALID: 1096,
    DUPLICATE_EXTERNAL_ID: 1097,
    INVOICE_ID_IS_REQUIRED: 1098,
    INVOICE_ID_IS_INVALID: 1099,
    INVOICE_NOT_FOUND: 1100,
    ORGANIZATION_ID_IS_REQUIRED: 1101,
    ORGANIZATION_ID_IS_NOT_VALID: 1102,
    ADDRESS_NOT_FOUND: 1103,
    ADDRESS_IS_REQUIRED: 1104,
    ADDRESS_IS_INVALID: 1105,
    NETWORKS_ARE_REQUIRED: 1106,
    NETWORKS_ARE_INVALID: 1107,
    NETWORK_FROM_IS_REQUIRED: 1108,
    NETWORK_FROM_IS_NOT_VALID: 1109,
    NETWORK_FROM_IS_DISABLED: 1110,
    NETWORK_TO_IS_REQUIRED: 1111,
    NETWORK_TO_IS_NOT_VALID: 1112,
    NETWORK_TO_IS_DISABLED: 1113,
    CURRENCY_IS_DISABLED: 1114,
    CLIENT_ID_IS_INVALID: 1115,
    ID_IS_REQUIRED: 1116,
    ID_IS_INVALID: 1117,
    NAME_IS_REQUIRED: 1118,
    NAME_IS_NOT_VALID: 1119,
    ERROR_LOGO_URL_IS_NOT_VALID: 1120,
    EMAIL_IS_REQUIRED: 1121,
    EMAIL_IS_NOT_VALID: 1122,
    MERCHANT_ID_IS_REQUIRED: 1123,
    MERCHANT_ID_IS_NOT_VALID: 1124,
    CLIENT_ID_IS_REQUIRED: 1125,
    CLIENT_ID_IS_NOT_VALID: 1126,
    MERCHANT_NOT_FOUND: 1127,
    WEBHOOK_URL_IS_NOT_VALID: 1128,
    BILLING_LINK_ID_IS_REQUIRED: 1130,
    BILLING_LINK_ID_IS_INVALID: 1131,
    TITLE_IS_REQUIRED: 1132,
    TITLE_IS_INVALID: 1133,
    DESCRIPTION_IS_INVALID: 1134,
    SPEND_INTERVAL_IS_REQUIRED: 1135,
    SPEND_INTERVAL_IS_NOT_VALID: 1136,
    SUBSCRIPTION_ID_IS_REQUIRED: 1137,
    SUBSCRIPTION_ID_IS_INVALID: 1138,
    CANCEL_INITIALIZER_IS_REQUIRED: 1139,
    CANCEL_INITIALIZER_IS_INVALID: 1140,
    BILLING_LINK_NOT_FOUND: 1141,
    BILLING_LINK_STATUS_NOT_SUCCESS: 1142,
    SPEND_INTERVAL_IS_LESS_THAN_ALLOWED: 1143,
    AMOUNT_MORE_THAN_MERCHANT_PAYMENT_LIMIT: 1144,
    SUBSCRIPTION_NOT_FOUND: 1145,
    ALREADY_CANCELED: 1146,
    BROKEN_SUBSCRIPTION: 1147,
    AMOUNT_MORE_THAN_MERCHANT_MONTHLY_PAYMENT_LIMIT: 1148,
    AMOUNT_MORE_THAN_APPROVAL_LIMIT: 1149,
    AMOUNT_MORE_THAN_ADDRESS_BALANCE: 1150,

    //lifetime errors
    NETWORK_LIFETIME_LESS_MIN: 2005,
    NETWORK_LIFETIME_MORE_MAX: 2006,

    // not allowed errors
    EXCHANGE_NOT_ALLOWED: 3001,
    NETWORK_UNDER_MAINTENANCE: 3005,
    DEPOSIT_OR_WITHDRAWAL_NOT_ALLOWED_BY_CURRENCY_SETTINGS: 3007,
    RENEW_ONLY_PAY_OUT_ADDRESSES: 3008,
    FAIL_WRONG_REGISTRY_STATUS: 3009,
    ADDRESS_MATCH: 3010,
    AMOUNT_LESS_THAN_MIN_AMOUNT: 3011,
    AMOUNT_GREAT_THAN_MAX_AMOUNT: 3012,
    ADDRESS_IS_NOW_USING: 3013,
    ORGANIZATION_IS_NOT_ALLOWED_TO_USE_RECURRENT: 3014,

    // access errors
    INCORRECT_2FA: 'INCORRECT_2FA'
};
