export const mutations = {
    exchanges: (state, exchanges) => {
        state.exchanges = exchanges;
    },
    totalExchangesCount: (state, count) => {
        state.totalExchangesCount = count;
    },
    filtersStatus: (state, status) => {
        state.filters.status = status;
    },
    clearAllStates: (state) => {
        state.exchangesCountOnPage = 20;
        state.exchanges = [];
        state.totalExchangesCount = null;
        state.filters = {
            status: [],
        };
    },
};
