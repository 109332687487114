import gql from 'graphql-tag';

export default gql`
    mutation updateOrganization(
    $organizationId: String!,
    $name: String!,
    $logoUrl: String,
    $telegramUrl: String,
    $websiteUrl: String,
    $mainCurrencyId: String,
    $timezone: Int

      ) {
        updateOrganization(
        organizationId: $organizationId,
        name: $name, 
        logoUrl: $logoUrl,
        telegramUrl: $telegramUrl,
        websiteUrl: $websiteUrl,
        mainCurrencyId: $mainCurrencyId,
        timezone: $timezone
         ) {
            code
            result
        }
    }
`;
