<template>
  <div class="balances-top-bar">
    <div class="balances-top-bar__search mobileHidden">
        <orphan-search :placeholder="$t('addresses.search_address')" v-model="search" />
        <refresh-button @click="updateBalances"/>
      <div class="balances-top-bar__right-buttons mobileHidden">
        <base-button
            class="balances-top-bar__empty-balances"
            :type="emptyBalancesColor"
            @click="toggleEmptyBalances"
        >
          <template #prepend>
            <svg v-if="hideEmptyBalances" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.2904 12.9117L9.67617 11.2975C9.30858 11.4286 8.91263 11.5 8.5 11.5C6.567 11.5 5 9.93303 5 8.00004C5 7.58741 5.0714 7.19146 5.20253 6.82387L3.14112 4.76246C1.43872 6.27906 0.5 8.00004 0.5 8.00004C0.5 8.00004 3.5 13.5 8.5 13.5C9.51539 13.5 10.4483 13.2732 11.2904 12.9117Z" fill="#744DB2"/>
              <path d="M5.70967 3.08837C6.55172 2.72686 7.48462 2.50004 8.5 2.50004C13.5 2.50004 16.5 8.00004 16.5 8.00004C16.5 8.00004 15.5613 9.72101 13.8589 11.2376L11.7975 9.17618C11.9286 8.8086 12 8.41266 12 8.00004C12 6.06704 10.433 4.50004 8.5 4.50004C8.08738 4.50004 7.69144 4.57144 7.32386 4.70256L5.70967 3.08837Z" fill="#744DB2"/>
              <path d="M6.02485 7.64619C6.00847 7.76178 6 7.87992 6 8.00004C6 9.38075 7.11929 10.5 8.5 10.5C8.62012 10.5 8.73825 10.4916 8.85385 10.4752L6.02485 7.64619Z" fill="#744DB2"/>
              <path d="M10.9752 8.35386L8.14618 5.52488C8.26176 5.50851 8.37989 5.50004 8.5 5.50004C9.88071 5.50004 11 6.61933 11 8.00004C11 8.12014 10.9915 8.23827 10.9752 8.35386Z" fill="#744DB2"/>
              <path d="M14.1464 14.3536L2.14645 2.35359L2.85355 1.64648L14.8536 13.6465L14.1464 14.3536Z" fill="#744DB2"/>
            </svg>
            <svg v-else width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.8388 4.45625C14.0119 3.1775 12.9391 2.09125 11.7366 1.31469C10.4063 0.454688 8.93751 0 7.48939 0C6.16064 0 4.85408 0.379688 3.60595 1.12844C2.33314 1.89188 1.18001 3.00719 0.17845 4.44312C0.0653832 4.60541 0.00311256 4.79764 -0.000448591 4.9954C-0.00400975 5.19315 0.0512993 5.3875 0.15845 5.55375C0.983763 6.84531 2.04595 7.93313 3.2297 8.69906C4.56251 9.5625 5.99689 10 7.48939 10C8.94908 10 10.4209 9.54906 11.7456 8.69625C12.9475 7.92219 14.0181 6.83187 14.8419 5.5425C14.9453 5.38012 15 5.19146 14.9995 4.99892C14.9989 4.80638 14.9432 4.61804 14.8388 4.45625ZM7.50001 8C6.90667 8 6.32665 7.82405 5.8333 7.49441C5.33995 7.16476 4.95544 6.69623 4.72837 6.14805C4.50131 5.59987 4.4419 4.99667 4.55766 4.41473C4.67341 3.83279 4.95914 3.29824 5.37869 2.87868C5.79825 2.45912 6.3328 2.1734 6.91474 2.05764C7.49669 1.94189 8.09988 2.0013 8.64806 2.22836C9.19624 2.45542 9.66478 2.83994 9.99442 3.33329C10.3241 3.82664 10.5 4.40666 10.5 5C10.4991 5.79537 10.1827 6.5579 9.62033 7.12032C9.05792 7.68273 8.29538 7.99909 7.50001 8Z" fill="white"/>
            </svg>
          </template>
          <template #label><span class="label mobileHidden">{{ emptyBalancesLabel }}</span></template>
        </base-button>
        <base-button
            :label="$t('addresses.vacuum')"
            class="balances-top-bar__vacuum"
            @click="$router.push({ name: 'payments' })"
        >
        </base-button>
      </div>
    </div>
      <div class="balances-top-bar__row">
          <div class="balances-top-bar__bottom-container mobileHidden">
              <div class="balances-top-bar__select-container">
                  <business-wallet-network-select
                      class="networkSelect"
                      v-model="networksValue"
                      clearable
                      :placeholder="$t('orders.select_network')"
                      :list="networks"
                      uniq-key="id"
                      @input="handleSelectNetwork"
                  />
              </div>
              <div class="balances-top-bar__select-container" ref="file">
                  <business-wallet-network-select
                      v-model="currenciesValue"
                      :list="currencies"
                      clearable
                      :placeholder="$t('orders.select_currencies')"
                      @input="handleSelectCurrency"
                      uniq-key="id"
                  />
              </div>
            <div class="balances-top-bar__select-container">
              <address-risks-select v-model="risk" @input="handleSelectRisk" />
            </div>
          </div>
          <div class="balances-top-bar__right-side">
              <div class="balances-top-bar__right-buttons desktopHidden">
                  <base-button
                          class="balances-top-bar__empty-balances"
                          :type="emptyBalancesColor"
                          @click="toggleEmptyBalances"
                  >
                      <template #prepend>
                          <svg v-if="hideEmptyBalances" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.2904 12.9117L9.67617 11.2975C9.30858 11.4286 8.91263 11.5 8.5 11.5C6.567 11.5 5 9.93303 5 8.00004C5 7.58741 5.0714 7.19146 5.20253 6.82387L3.14112 4.76246C1.43872 6.27906 0.5 8.00004 0.5 8.00004C0.5 8.00004 3.5 13.5 8.5 13.5C9.51539 13.5 10.4483 13.2732 11.2904 12.9117Z" fill="#744DB2"/>
                              <path d="M5.70967 3.08837C6.55172 2.72686 7.48462 2.50004 8.5 2.50004C13.5 2.50004 16.5 8.00004 16.5 8.00004C16.5 8.00004 15.5613 9.72101 13.8589 11.2376L11.7975 9.17618C11.9286 8.8086 12 8.41266 12 8.00004C12 6.06704 10.433 4.50004 8.5 4.50004C8.08738 4.50004 7.69144 4.57144 7.32386 4.70256L5.70967 3.08837Z" fill="#744DB2"/>
                              <path d="M6.02485 7.64619C6.00847 7.76178 6 7.87992 6 8.00004C6 9.38075 7.11929 10.5 8.5 10.5C8.62012 10.5 8.73825 10.4916 8.85385 10.4752L6.02485 7.64619Z" fill="#744DB2"/>
                              <path d="M10.9752 8.35386L8.14618 5.52488C8.26176 5.50851 8.37989 5.50004 8.5 5.50004C9.88071 5.50004 11 6.61933 11 8.00004C11 8.12014 10.9915 8.23827 10.9752 8.35386Z" fill="#744DB2"/>
                              <path d="M14.1464 14.3536L2.14645 2.35359L2.85355 1.64648L14.8536 13.6465L14.1464 14.3536Z" fill="#744DB2"/>
                          </svg>
                          <svg v-else width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.8388 4.45625C14.0119 3.1775 12.9391 2.09125 11.7366 1.31469C10.4063 0.454688 8.93751 0 7.48939 0C6.16064 0 4.85408 0.379688 3.60595 1.12844C2.33314 1.89188 1.18001 3.00719 0.17845 4.44312C0.0653832 4.60541 0.00311256 4.79764 -0.000448591 4.9954C-0.00400975 5.19315 0.0512993 5.3875 0.15845 5.55375C0.983763 6.84531 2.04595 7.93313 3.2297 8.69906C4.56251 9.5625 5.99689 10 7.48939 10C8.94908 10 10.4209 9.54906 11.7456 8.69625C12.9475 7.92219 14.0181 6.83187 14.8419 5.5425C14.9453 5.38012 15 5.19146 14.9995 4.99892C14.9989 4.80638 14.9432 4.61804 14.8388 4.45625ZM7.50001 8C6.90667 8 6.32665 7.82405 5.8333 7.49441C5.33995 7.16476 4.95544 6.69623 4.72837 6.14805C4.50131 5.59987 4.4419 4.99667 4.55766 4.41473C4.67341 3.83279 4.95914 3.29824 5.37869 2.87868C5.79825 2.45912 6.3328 2.1734 6.91474 2.05764C7.49669 1.94189 8.09988 2.0013 8.64806 2.22836C9.19624 2.45542 9.66478 2.83994 9.99442 3.33329C10.3241 3.82664 10.5 4.40666 10.5 5C10.4991 5.79537 10.1827 6.5579 9.62033 7.12032C9.05792 7.68273 8.29538 7.99909 7.50001 8Z" fill="white"/>
                          </svg>
                      </template>
                      <template #label><span class="label mobileHidden">{{ emptyBalancesLabel }}</span></template>
                  </base-button>
                  <base-button
                          :label="$t('addresses.vacuum')"
                          class="balances-top-bar__vacuum"
                          @click="$router.push({ name: 'payments' })"
                  >
                  </base-button>
              </div>

              <refresh-button
                class="balances-top-bar__refresh desktopHidden"
                :isLoading="isLoading"
                @click="updateBalances"
              />
              <filter-mobile
                  :is-filtered="isFiltered"
                  class="desktopHidden"
                  @reset="resetFilters"
              >
                  <orphan-search :placeholder="$t('addresses.search_address')" v-model="search" />
                  <div class="balances-top-bar__select-container">
                      <business-wallet-network-select
                          :value="networksValue"
                          :list="networks"
                          clearable
                          :placeholder="$t('orders.select_network')"
                          uniq-key="id"
                          @input="handleSelectNetwork"
                      />
                  </div>
                  <div class="balances-top-bar__select-container" ref="file">
                      <business-wallet-network-select
                          v-model="currenciesValue"
                          :list="currencies"
                          clearable
                          :placeholder="$t('orders.select_currencies')"
                          uniq-key="id"
                          @input="handleSelectCurrency"
                      />
                  </div>
                <div class="balances-top-bar__select-container">
                  <address-risks-select v-model="risk" @input="handleSelectRisk" />
                </div>
              </filter-mobile>
          </div>
      </div>

  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';

import AtButton from 'at-ui/src/components/button';
import BalancesSearch from "./BalancesSearch";
import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';
import BtnRefresh from "../../common/btn-refresh";
import btn from "../../common/btn";
import CurrencyImg from "../CurrencyImg";
import RefreshButton from "@/public/components/common/uiKit/button/refresh/index.vue";
import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
import BusinessWalletNetworkSelect
    from "@/public/components/elements/business-walet/Select/businessWalletNetworkSelect.vue";
import FilterMobile from "@/public/components/elements/orphan-transactions/filter/index.vue";
import OrphanSearch from "@/public/components/elements/orphan-transactions/OrphanSearch.vue";

import _ from "lodash";
import AddressRisksSelect from "@/public/components/elements/address-risks/Select/AddressRisksSelect.vue";

export default {
    name: 'BalancesTopBar',
    components: {
      AddressRisksSelect,
        OrphanSearch,
        FilterMobile,
        BusinessWalletNetworkSelect,
        BaseButton,
        RefreshButton,
        CurrencyImg,
        BtnRefresh,
        BalancesSearch,
        AtButton,
        AtSelect,
        AtOption,
        btn,
    },
    props: {
        accountId: {
            type: [String, undefined],
        },
        currencies: {
            type: Array,
        },
        networks: {
            type: Array,
        },
    },
    data() {
        return {
            networksValue: null,
            currenciesValue: null,
            isLoading: false,
            hideEmptyBalances: false,
            risk: '',
            search: '',
        };
    },
    watch: {
      search (value) {
          this.$emit('search', value)
      }
    },
    computed: {
        ...mapGetters('app', [
            'getCurrencyNameById',
        ]),
        isFiltered({ networksValue, currenciesValue, search }) {
          return !( _.isEmpty(networksValue) && _.isEmpty(currenciesValue) && _.isEmpty(search) )
        },
        emptyBalancesIcon() {
            return this.hideEmptyBalances ? 'icon-eye-off' : 'icon-eye';
        },
        emptyBalancesColor() {
            return this.hideEmptyBalances ? 'secondary' : 'primary'
        },
        emptyBalancesLabel() {
            return this.hideEmptyBalances ? this.$t('addresses.zero_balances_show') :this.$t('addresses.zero_balances')
        },
        refreshIcon() {
            return this.isLoading ? '' : 'icon-rotate-cw';
        }
    },
    methods: {
        ...mapActions('balances', [
            'getAccountBalancesGrouped',
        ]),

        handleSelectCurrency(value) {
            this.currenciesValue = value
            this.$emit('selectCurrency', value);
        },
        handleSelectNetwork(value) {
            this.networksValue = value
            this.$emit('selectNetwork', value);
        },
      handleSelectRisk(value) {
            this.risk = value
            this.$emit('selectRisk', value);
        },
        handleSearch(value) {
            this.search = value
        },
        updateBalances() {
            this.isLoading = true;

            this.getAccountBalancesGrouped({accountId: this.accountId})
                .finally(() => {
                    this.isLoading = false;
                });
        },
        toggleEmptyBalances() {
            this.hideEmptyBalances = !this.hideEmptyBalances;
            this.$emit('toggleEmptyBalances', this.hideEmptyBalances);
        },
        resetFilters() {
          const filtersList = ['handleSelectCurrency', 'handleSelectNetwork', 'handleSearch']

          filtersList.forEach(filter => {
            this[filter]('')
          })
        }
    },
}

</script>
<style scoped>


</style>
<style lang="scss" scoped>
.desktopHidden {
  display: none;
}
.balances-top-bar {
  width: 100%;
    &__vacuum {
        min-width: 180px;
    }
  &__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
  }
  &__search {
    display: grid;
    grid-template-columns: 1fr 48px 1fr;
    grid-gap: 10px;
  }

  &__label {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__search {
    width: 100%;
  }

  &__select-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__right-side {
    margin-left: 10px;
    display: flex;
    flex-grow: 0;
    gap: 5px;
  }
  &__right-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  &__empty-balances {
    min-height: 46px;
    min-width: 210px;
    &-label {
      font-weight: 500;
      margin-left: 8px;
      color: #744DB2;
    }
    @media (max-width: 950px) {
      min-width: 234px;
    }
  }

  &__bottom-container {
    display: flex;
    gap: 15px;
    width: 100%;
  }

}
.desktopHidden {
  display: none;
}
@media screen and (max-width: 850px) {

  .balances-top-bar {
    display: flex;
    justify-content: flex-end;
      &__vacuum {
          min-width: 150px;
      }
    &__empty-balances {
      min-width: 48px !important;
    }
    &__bottom-container {
      display: flex;
      flex-direction: column;
    }

    &__select-container {
      max-width: none;
    }

    &__right-side {
      margin-left: 0;
      margin-top: 0;
      justify-content: flex-end;
      gap: 10px;
    }
    &__right-buttons {
      display: flex;
      flex-direction: row-reverse;
      gap: 10px;
    }
  }
  ::v-deep .filter__content {
    min-width: 280px !important;
    padding: 10px !important;
  }
  ::v-deep .advanced_balances__network-select {
    margin: 10px 0;
  }
    ::v-deep .currency-search-wrapper {
        width: 100%;
    }
    ::v-deep .currency-search-wrapper .search-input {
        width: 100%;
        &::placeholder {
            font-size: 13px;
        }
    }
  .mobileHidden {
    display: none;
  }
  .desktopHidden {
    display: inherit;
  }
}

@media screen and (max-width: 567px) {
  .balances-top-bar {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.currencyImg {
  margin-right: 15px;
  width: 30px;
  height: 30px;
}
::v-deep .at-select__dropdown .at-select__option {
  font-family: var(--font-family);
  font-size: var(--font-size-text-main);
  line-height: var(--line-height);
  color: var(--primary-font-color);

  padding: 10px;
  display: flex;
  align-items: center;

  &:hover {
    background: var(--hover-color-select);
  }
}
::v-deep .advanced-balances-select-wrapper {
  .sublabel {
    display: none;
  }
}
::v-deep .currency-network-icon-wrapper {
    position: relative;

    display: flex;

    .currency-network-icon__currency {
        width: 22px;
        height: 22px;

        border: none;

        //position: absolute;
        //
        //top: 0;
        //left: 0;
        //
        //transform: translate(-45%, -45%);
        @media screen and (max-width: 850px) {
          left: -8px;
        }
    }
    .currency-network-icon__network {
        width: 18px;
        height: 18px;

        position: absolute;

        top: 0;
        left: 0;

        transform: translate(-45%, -45%);
        @media screen and (max-width: 850px) {
            left: -8px;
        }
    }
}
.networkSelect {
    //::v-deep .advanced_balances__network-currency-list-item .left-block .currency-and-network-info-block {
    //    margin-left: 25px !important;
    //}
    ::v-deep .currency-network-icon-wrapper .currency-network-icon__network {
        transform: translate(0%,-45%) !important;
    }
}
</style>
