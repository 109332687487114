<template>
  <BaseModal
      class="remove-api-keys-modal"
      width="100%"
      :value="isOpen"
      :title="title"
      :show-close="true"
      :show-head="!isLoading"
      :show-footer="!isLoading"
      @on-confirm="handleRemoveRequest"
      @on-cancel="handleCancelCreate"
  >
    <template v-if="!isLoading">
      <template v-if="!isSuccess">
        <p class="remove-api-keys-modal__message">
          {{ $t('payout_modal_execute.subtitle') }}
        </p>
        <p class="remove-api-keys-modal__alias" v-if="alias">{{ alias }}</p>
      </template>
      <template v-else>
        <p class="remove-api-keys-modal__message">
          {{ $t('payout_modal_execute.success') }}
        </p>
      </template>
    </template>
    <LoadingSpinner
        v-else
        :title="deleting_an_api_key"
    />

    <template #footer>
      <template v-if="!isSuccess">
        <AtButton @click="handleCancelCreate">
          {{ $t('api_keys.close') }}
        </AtButton>
        <AtButton
            type="primary"
            @click="handleRemoveRequest"
        >
          {{ $t('payout_modal_execute.button.execute') }}
        </AtButton>
      </template>
      <AtButton
          v-else
          class="remove-api-keys-modal__full-width-btn"
          type="primary"
          @click="handleCancelCreate"
      >
        {{ $t('api_keys.close') }}
      </AtButton>
    </template>

  </BaseModal>
</template>

<script>

import {mapState, mapGetters, mapActions} from 'vuex';

import AtModal from 'at-ui/src/components/modal';
import LoadingSpinner from "/public/components/elements/LoadingSpinner";
import AtButton from 'at-ui/src/components/button';
import BaseModal from "../BaseModal";

export default {
    name: 'PayoutModalRegistryExecute',
    components: {
        BaseModal,
        AtModal,
        LoadingSpinner,
        AtButton,
    },
    data() {
        return {
            isLoading: false,
            isSuccess: false,
        };
    },
    computed: {
        ...mapState('app', {
            modalPayload: state => state.modal.payload,
        }),
        ...mapGetters('app', [
            'isOpenModal',
        ]),
        isOpen() {
            const {
                $options: {
                    name,
                },
                isOpenModal,
            } = this;

            return isOpenModal(name);
        },
        title() {
            return this.$t('payout_modal_execute.title');
        },
        alias() {
            return this.modalPayload?.registryTitle ?? '';
        },
        hideFooter() {
            const {
                isLoading,
                isSuccess,
            } = this;

            return isLoading || isSuccess;
        },
        deleting_an_api_key() {
            return this.$t('payout_modal_execute.pending');
        },
    },
    methods: {
        ...mapActions('app', [
            'closeModal',
        ]),
        ...mapActions('payoutBalances', [
            'executeRegistryMutation',
            'fetchRegistries',
        ]),
        clearAllData() {
            this.isLoading = false;
            this.isSuccess = false;
        },
        handleRemoveRequest() {
            const {
                modalPayload: {
                    registryId,
                },
                executeRegistryMutation,
            } = this;
            this.isLoading = true;
            executeRegistryMutation({registryId})
                .then(status => {
                    this.isSuccess = status;
                })
                .finally(() => {
                    this.isLoading = false;

                });
        },
        handleCancelCreate() {
            const {
                closeModal,
                clearAllData,
            } = this;

            closeModal();
            const {
                $route,

            } = this;
            this.fetchRegistries({
                route: $route,
                showUrlFilters: false,
            });

            setTimeout(() => {
                clearAllData();
            }, 500);
        },
    },
};

</script>

<style lang="scss" scoped>

.remove-api-keys-modal {
  &__full-width-btn {
    width: 100%;
  }

  &__alias {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #2C405A;
    margin-top: 25px;
  }

  //::v-deep .at-modal__footer {
  //  padding-left: 16px;
  //  padding-right: 16px;
  //}
}

</style>
