<template>
    <div class="dashboard-withdrawal-row table-row row">
        <div class="dashboard-withdrawal-row__id col col-5">
            <CopyString
                :string="id"
                canCopy
            />
        </div>
        <div class="dashboard-withdrawal-row__currency col">
            <CurrencyImg
                class="dashboard-withdrawal-row__currency-img"
                :currency="currency"
            />
            {{ currency | getCurrencyTicker }}
        </div>
        <div class="col">
            {{ network | getNetworkName }}
        </div>
        <div class="col">
            {{ amount }}
        </div>
        <div class="col col-4">
            {{ createdAt | getFormatDate('DD.MM.YYYY, HH:mm') }}
        </div>
        <div class="col col-1">
            <RouterLink :to="{ name: 'request', params: { id } }">
                <Icon icon="eye" />
            </RouterLink>
        </div>
    </div>
</template>

<script>

import Moment from "moment";
import { mapGetters } from 'vuex';

import copyToClipboard from "/public/mixins/copyToClipboard";

import CopyString from "/public/components/elements/CopyString";
import CurrencyImg from "/public/components/elements/CurrencyImg";
import AtTag from 'at-ui/src/components/tag';
import Icon from "/public/components/elements/Icon";

export default {
    name: 'DashboardWithdrawalRow',
    components: {
        CopyString,
        CurrencyImg,
        AtTag,
        Icon,
    },
    mixins: [copyToClipboard],
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters('app', [
            'getCurrencyNameById',
        ]),
        id () {
            return this.data.id;
        },
        currency () {
            return this.data.currencyId;
        },
        amount () {
            return this.data.amount;
        },
        network () {
            return this.data.networkId;
        },
        createdAt () {
            return this.data.createdAt;
        },
    },
};

</script>

<style lang="scss" scoped>

.dashboard-withdrawal-row {
    position: relative;
    align-items: center;
    color: #3F536E;

    &__link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    &__id {
        position: relative;
        z-index: 2;
    }

    &__currency {
        display: flex;
        align-items: center;
    }

    &__currency-img {
        margin-right: 12px;
        width: 24px;
        height: 24px;
    }
}

</style>
