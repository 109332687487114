<template>
    <div class="withdrawal-address row">
        <div class="withdrawal-address__amount col">
            <AtInput
                size="small"
                type="number"
                :status="amountStatus"
                v-model="data.amount"
            />
            <AtButton
                class="withdrawal-address__amount-max"
                size="small"
                type="primary"
                hollow
                @click="setMax"
            >
                max
            </AtButton>
        </div>
        <div class="col">
            <AtInput
                v-if="currency"
                size="small"
                :status="addressStatus"
                v-model="data.address"
            />
        </div>
        <div class="col">
            <AtInput
                size="small"
                v-model="data.tag"
            />
        </div>
        <div class="col col-2">
            <AtButton
                size="small"
                icon="icon-trash-2"
                @click="$emit('removeAddress')"
            />
        </div>
    </div>
</template>

<script>

import { checkAddress } from "../../../../../common/validators/address";

import AtInput from 'at-ui/src/components/input';
import AtButton from 'at-ui/src/components/button';

export default {
    name: 'WithdrawalAddress',
    components: {
        AtInput,
        AtButton,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        currency: {
            type: String,
            default: null,
        },
        balance: {
            type: Number,
            default: null,
        },
    },
    computed: {
        amountStatus () {
            return !this.data.amount ? 'error' : '';
        },
        addressStatus () {
            const {
                currency,
                data: { address },
            } = this;

            if (!address) {
                return 'error';
            } else {
                const check = checkAddress(address, currency);
                return !check ? 'error' : '';
            }
        },
    },
    methods: {
        setMax () {
            this.data.amount = this.balance;
        },
    },
};

</script>

<style lang="scss" scoped>

    .withdrawal-address {
        margin-left: 0;
        margin-right: 0;
        padding: 8px 12px;
        border: 1px solid $gray2;
        border-top: none;
        
        &__amount {
            display: flex;
        }

        &__amount-max {
            margin-left: 4px;
        }
    }

</style>