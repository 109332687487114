import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!) {
        getPayoutBalances(organizationId: $organizationId) {
            id
            currencyId
            networkId
            address
            tag
            balance
            nativeBalance
            useForAutoSwap
            riskLevel
            alias
            comment
            useForDirectWithdrawals
            isCollect
        }
    }
`;
// nativeBalance
