import {fetchInvoices} from './actions/fetchInvoices';
import {createInvoice} from './actions/createInvoice';
import {fetchInvoice} from './actions/fetchInvoice';
import {updateInvoice} from "./actions/updateInvoice";
import {downloadCSV} from "./actions/downloadCSV";
import {fetchBlockChainTransaction} from "./actions/fetchBlockchainTransaction";
import {downloadXLSX} from "./actions/downloadXLSX";


export const actions = {
    fetchInvoices,
    downloadCSV,
    downloadXLSX,
    createInvoice,
    fetchInvoice,
    updateInvoice,
    fetchBlockChainTransaction,
};
