<template>
  <btn-toggler v-model="themeModel">
    <template #left-btn-icon="{ active }">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15Z" :fill="active ? 'white' : iconColor"/>
        <path d="M10 0C10.3452 0 10.625 0.279822 10.625 0.625V3.125C10.625 3.47018 10.3452 3.75 10 3.75C9.65482 3.75 9.375 3.47018 9.375 3.125V0.625C9.375 0.279822 9.65482 0 10 0Z" :fill="active ? 'white' : iconColor"/>
        <path d="M10 16.25C10.3452 16.25 10.625 16.5298 10.625 16.875V19.375C10.625 19.7202 10.3452 20 10 20C9.65482 20 9.375 19.7202 9.375 19.375V16.875C9.375 16.5298 9.65482 16.25 10 16.25Z" :fill="active ? 'white' : iconColor"/>
        <path d="M20 10C20 10.3452 19.7202 10.625 19.375 10.625H16.875C16.5298 10.625 16.25 10.3452 16.25 10C16.25 9.65482 16.5298 9.375 16.875 9.375H19.375C19.7202 9.375 20 9.65482 20 10Z" :fill="active ? 'white' : iconColor"/>
        <path d="M3.75 10C3.75 10.3452 3.47018 10.625 3.125 10.625H0.625C0.279822 10.625 -1.50882e-08 10.3452 0 10C1.50882e-08 9.65482 0.279822 9.375 0.625 9.375H3.125C3.47018 9.375 3.75 9.65482 3.75 10Z" :fill="active ? 'white' : iconColor"/>
        <path d="M17.0711 2.92897C17.3151 3.17305 17.3151 3.56878 17.0711 3.81286L15.3033 5.58062C15.0592 5.8247 14.6635 5.8247 14.4194 5.58062C14.1753 5.33654 14.1753 4.94082 14.4194 4.69674L16.1872 2.92897C16.4313 2.68489 16.827 2.68489 17.0711 2.92897Z" :fill="active ? 'white' : iconColor"/>
        <path d="M5.58058 14.4195C5.82466 14.6635 5.82466 15.0593 5.58058 15.3033L3.81281 17.0711C3.56874 17.3152 3.17301 17.3152 2.92893 17.0711C2.68485 16.827 2.68485 16.4313 2.92893 16.1872L4.6967 14.4195C4.94077 14.1754 5.3365 14.1754 5.58058 14.4195Z" :fill="active ? 'white' : iconColor"/>
        <path d="M17.0711 17.0711C16.827 17.3152 16.4313 17.3152 16.1872 17.0711L14.4194 15.3034C14.1753 15.0593 14.1753 14.6636 14.4194 14.4195C14.6635 14.1754 15.0592 14.1754 15.3033 14.4195L17.0711 16.1872C17.3151 16.4313 17.3151 16.827 17.0711 17.0711Z" :fill="active ? 'white' : iconColor"/>
        <path d="M5.58058 5.58064C5.3365 5.82471 4.94078 5.82471 4.6967 5.58064L2.92893 3.81287C2.68485 3.56879 2.68485 3.17306 2.92893 2.92899C3.17301 2.68491 3.56874 2.68491 3.81282 2.92899L5.58058 4.69675C5.82466 4.94083 5.82466 5.33656 5.58058 5.58064Z" :fill="active ? 'white' : iconColor"/>
      </svg>
    </template>
    <template #right-btn-icon="{ active }">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M7.50037 0.34788C7.72813 0.625544 7.80587 1.04401 7.60087 1.42016C6.90013 2.70593 6.50188 4.17877 6.50188 5.74607C6.50188 10.7716 10.6004 14.8409 15.6502 14.8409C16.3082 14.8409 16.9493 14.7719 17.5668 14.6411C17.989 14.5516 18.373 14.7425 18.5789 15.0358C18.7913 15.3383 18.8358 15.7897 18.5398 16.1531C16.6292 18.4981 13.706 20 10.4295 20C4.66678 20 0 15.3579 0 9.63753C0 5.33225 2.64268 1.63996 6.40442 0.0751098C6.83905 -0.105689 7.26536 0.0613665 7.50037 0.34788Z" :fill="active ? 'white' : iconColor"/>
        <path d="M13.4929 3.9353C13.5753 3.68823 13.9248 3.68823 14.0071 3.9353L14.4912 5.38763C14.707 6.035 15.215 6.543 15.8624 6.75879L17.3147 7.2429C17.5618 7.32526 17.5618 7.67473 17.3147 7.75709L15.8624 8.2412C15.215 8.45699 14.707 8.96499 14.4912 9.61236L14.0071 11.0647C13.9248 11.3118 13.5753 11.3118 13.4929 11.0647L13.0088 9.61235C12.793 8.96498 12.285 8.45699 11.6376 8.2412L10.1853 7.75709C9.93823 7.67473 9.93823 7.32526 10.1853 7.2429L11.6377 6.75879C12.285 6.543 12.793 6.03501 13.0088 5.38764L13.4929 3.9353Z" :fill="active ? 'white' : iconColor"/>
        <path d="M17.3286 0.123533C17.3835 -0.0411787 17.6165 -0.0411773 17.6714 0.123535L17.9941 1.09175C18.138 1.52333 18.4767 1.862 18.9083 2.00586L19.8765 2.3286C20.0412 2.38351 20.0412 2.61649 19.8765 2.67139L18.9083 2.99413C18.4767 3.13799 18.138 3.47666 17.9941 3.90824L17.6714 4.87647C17.6165 5.04118 17.3835 5.04118 17.3286 4.87647L17.0059 3.90823C16.862 3.47665 16.5234 3.13799 16.0918 2.99413L15.1235 2.67139C14.9588 2.61649 14.9588 2.38351 15.1235 2.3286L16.0918 2.00586C16.5234 1.862 16.862 1.52334 17.0059 1.09176L17.3286 0.123533Z" :fill="active ? 'white' : iconColor"/>
      </svg>
    </template>
  </btn-toggler>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import BtnToggler from "@/public/components/common/uiKit/togglers/BtnToggler";

export default {
    name: "HeaderTheme",
    components: {
        BtnToggler
    },
    computed: {
        ...mapState('app', [
            'theme',
        ]),
        storageTheme() {
          return localStorage.getItem('data-theme-private')
        },
        iconColor({ theme }) {
          return theme === 'dark' ? '#7E7E7E' : '#8B81A0'
        },
        themeModel: {
          get({ theme }) {
            return theme === 'dark'
          },
          set() {
            this.toggleTheme()
          }
        }
    },
    created() {
      document.documentElement.setAttribute("data-theme-private", this.storageTheme);
      this.setTheme( this.storageTheme ) //пишем тему в стор
    },
    methods: {
        ...mapMutations('app', ['setTheme']),
        toggleTheme() {
          if (this.theme === 'dark') {
            document.documentElement.setAttribute("data-theme-private", 'light');
            localStorage.setItem("data-theme-private", 'light');
          } else {
            document.documentElement.setAttribute("data-theme-private", 'dark');
            localStorage.setItem("data-theme-private", 'dark');
          }

          this.setTheme( localStorage.getItem('data-theme-private') ) //пишем тему в стор
        }
    }
}
</script>
