export const PRE_CHECKOUT_THEME_ENUM = {
    DARK_BLUE_GRADIENT: 'dark_blue_gradient',
    PINK_GRADIENT: 'pink_gradient',
    PURPLE_GRADIENT: 'purple_gradient',
    BLUE_GRADIENT: 'blue_gradient',
    GREEN_GRADIENT: 'green_gradient',
    YELLOW_GRADIENT: 'yellow_gradient',
    ORANGE_GRADIENT: 'orange_gradient',
    BLACK: 'black',
    DARK_BLUE: 'dark_blue',
    PINK: 'pink',
    PURPLE: 'purple',
    BLUE: 'blue',
    GREEN: 'green',
    YELLOW: 'yellow',
    ORANGE: 'orange',
};
