import GetAccountBalancesQuery from "/public/graphQl/queries/getAccountBalancesPayin";

export const getAccountBalancesPayin = async ({commit, dispatch, rootGetters}, payload) => {
    let balancesData;

    const variables = {
        ...payload
    }

    if (!variables.organizationId) {
        variables.organizationId = rootGetters['app/getSelectedOrganization'].id
    }

    try {
        balancesData = await dispatch('app/request', {
            type: 'query',
            gql: GetAccountBalancesQuery,
            variables
        }, {root: true});
    } catch (error) {
        return error;
    }

    const { data: { getAccountBalances } = {} } = balancesData || {}

    return getAccountBalances || [];
};
