<template>
  <div>
    <preloader v-if="isLoading || !isInit"/>
    <div v-else class="requests">
      <h1 class="requests__title">
        {{ $t('withdrawal.title') }}
      </h1>
      <p class="requests__paragraph">
        {{ $t('withdrawal.description') }}
      </p>

      <div class="requests__container">
        <RequestsTopBar class="requests__top-bar"/>
        <div class="requests__list">
          <RequestsListHeader class="requests__list-header"/>
          <template v-if="hasRequests">
            <PreviewRequest
                v-for="(request, key) in requests"
                :key="key"
                class="requests__request"
                :data="request"
            />
            <div class="requests__pagination">
              <pagination
                  size="small"
                  :total="totalRequestsCount"
                  :page-size="requestsCountOnPage"
                  :current="currentPage"
                  @page-change="changePage"
              />
            </div>
          </template>
          <EmptyTable
              v-else
              class="requests__empty"
          >
            {{ $t('withdrawal.no_data_available') }}
          </EmptyTable>
        </div>
      </div>
      <CreateWithdrawalRequestModal/>
    </div>

  </div>
</template>

<script>

import {mapState, mapActions} from 'vuex';
import pagination from '../components/common/pagination';
import RequestsTopBar from "/public/components/elements/requests/RequestsTopBar";
import RequestsListHeader from "/public/components/elements/requests/RequestsListHeader";
import PreviewRequest from "/public/components/elements/requests/PreviewRequest";
import AtPagination from "at-ui/src/components/pagination";
import EmptyTable from "/public/components/elements/EmptyTable";
import CreateWithdrawalRequestModal from "/public/components/modals/requests/CreateWithdrawalRequestModal";
import Preloader from "/public/components/common/uiKit/spinner/index.vue";

export default {
    name: 'RequestsPage',
    title: 'withdrawal.title',
    components: {
        Preloader,
        RequestsTopBar,
        RequestsListHeader,
        PreviewRequest,
        AtPagination,
        EmptyTable,
        CreateWithdrawalRequestModal,
        pagination,
    },
    computed: {
        ...mapState('app', [
            'isInit',
            'isLoading',
        ]),
        ...mapState('withdrawalRequests', [
            'requestsCountOnPage',
            'requests',
            'totalRequestsCount',
            'withdrawalData',
        ]),
        currentPage() {
            const pageFromUrl = this.$route.query.page;

            if (!pageFromUrl) {
                return 1;
            }

            return Number(pageFromUrl);
        },
        hasRequests() {
            return Boolean(this.requests?.length);
        },
    },
    mounted() {
        const {
            $route,
            isInit,
            fetchWithdrawalRequests,
        } = this;

        if (isInit) {
            fetchWithdrawalRequests({
                route: $route,
                showUrlFilters: false,
            });
        }
    },
    methods: {
        ...mapActions('withdrawalRequests', [
            'fetchWithdrawalRequests',
            'requestWithdrawal',
        ]),

        download() {
            const {
                $route,
            } = this;

            this.downloadCSV({
                route: $route,
            });
        },
        downloadXLSXQuery() {
            const {
                $route,
            } = this;

            this.downloadXLSX({
                route: $route,
            });
        },
        changePage(pageNumber) {
            const {
                $route,
                $router,
                fetchWithdrawalRequests,
            } = this;

            fetchWithdrawalRequests({
                route: $route,
                router: $router,
                page: pageNumber,
            });
        },
    },
    watch: {
        isInit() {
            const {
                $route,
                fetchWithdrawalRequests,
            } = this;

            fetchWithdrawalRequests({
                route: $route,
                showUrlFilters: false,
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.requests {
  max-width: 1420px;
  padding-left: var(--padding-left-container);
  padding-right: var(--padding-right-container);
  padding-top: var(--padding-top-container);

  &__title {
    margin-bottom: var(--margin-h1-bottom);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-h1);
    line-height: var(--line-height);
    color: var(--black-200);
  }

  &__paragraph {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-h3);
    line-height: var(--line-height);
    color: var(--black-200);
  }


  &__pagination {
    background: #FFFFFF;
    min-height: 84px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 0 0 20px 20px;
  }

  &__container {
    border: 1px solid #F2F2F2;
    box-shadow: 0px 8px 50px rgba(10, 17, 105, 0.08);
    border-radius: 20px;
    background: #FFFFFF;
    overflow: hidden;

    margin-top: var(--margin-container-top);
    margin-bottom: var(--margin-container-bottom);
  }

  &__top-bar {
    border-radius: 20px 20px 0 0;
  }


}

@media screen and (max-width: 1330px) {

}

@media screen and (max-width: 1180px) {

}

@media screen and (max-width: 375px) {

  .requests {


    &__title {
      padding-left: 10px;
    }

    &__paragraph {
      padding-left: 10px;
    }
  }
}
</style>
