<script>
    export default{
        name: "TableColumns"
    }
</script>

<template>
<div class="header">
    <div class="header__item"><span class="header__item-title">{{ $t('address_book.note_line') }}</span></div>
    <div class="header__item"><span class="header__item-title">{{ $t('address_book.network_line') }}</span></div>
    <div class="header__item"><span class="header__item-title">{{ $t('address_book.address_line') }}</span></div>
</div>
</template>

<style scoped lang="scss">
.header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr .5fr;
  grid-auto-flow: column;
  grid-gap: 5px;
  align-items: center;
  padding: 0 $p30;
  min-height: 56px;
  background: var(--new-front-select-item-bg-active);
  border-radius: 10px;
  &__item {
    display: flex;
    align-items: center;
    gap: 5px;
    &-title {
      font-size: $h4;
      color: var(--new-front-secondary-font-color);;
      font-weight: 500;
    }
    &-sort {
      cursor: pointer;
      //transition: transform .2s ease;
      &--active {
        transform: rotate(180deg);
      }
    }
  }
}
@media (max-width: 1410px) {
  .header__item-title {
    font-size: $h5;
  }
}
</style>
