<template>
  <div class="new-payment-modal-wrapper">
    <store-modal
      :name="$options.name"
      :loading="isLoading"
      @closeModal="closeBundleModal"
    >
      <template #header>
        {{ $t('create-bundle.title') }}
      </template>
      <template #body>
        <template v-if="!billingLink">
          <base-input
            :label="$t('create-bundle.externalId')"
            :placeholder="$t('create-bundle.enter_external')"
            :error="validationUsed ? isClientIdValid : ''"
            v-model="clientIdModel"
          />
          <base-input
            :label="$t('create-bundle.email')"
            :placeholder="$t('create-bundle.enter_email')"
            :error="validationUsed ? isEmailValid : ''"
            v-model="emailModel"
          />
        </template>
        <template v-else>
          <fake-input>
            <template #label>
              <span>
                {{ $t('create-bundle.externalId') }}
              </span>
            </template>
            <template #value>
              <span>
                {{ clientId }}
              </span>
            </template>
            <template #suffix>
              <copy :value="clientId" />
            </template>
          </fake-input>
          <fake-input>
            <template #label>
              <span>
                {{ $t('create-bundle.email') }}
              </span>
            </template>
            <template #value>
              <span>
                {{ email }}
              </span>
            </template>
          </fake-input>
          <fake-input>
            <template #label>
            <span >
              {{ $t('create-bundle.bundle_link') }}
            </span>
            </template>
            <template #value>
              <a :href="billingLink" target="_blank">
                {{ getTruncatedString(billingLink, 12, 12) }}
              </a>
            </template>
            <template #suffix>
              <copy :value="billingLink" />
            </template>
          </fake-input>
        </template>
      </template>
      <template #footer>
        <div class="actions">
          <base-button
            :label="$t('create-order-modal.close')"
            type="secondary"
            class="action-item"
            @click="closeBundleModal"
          />
          <base-button
            v-if="!billingLink"
            :label="$t('create-order-modal.create')"
            type="primary"
            :disabled="buttonValidation"
            class="action-item"
            @click="createPaymentBundle"
          />
        </div>
      </template>
    </store-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import BaseModal from "../BaseModal";
import createBillingLink from "../../../graphQl/mutations/createBillingLink";
import CopyString from "../../elements/CopyString";

import { getTruncatedString } from '/public/filters'

export default {
    name: "NewPaymentBundle",
    components: {
      CopyString,
      BaseModal,
      BaseButton: () => import(/* webpackChunkName: "public/components/common/uiKit/button/base/index.vue" */ '/public/components/common/uiKit/button/base/index.vue'),
      BaseInput: () => import(/* webpackChunkName: "public/components/common/uiKit/input/base/index.vue" */ '/public/components/common/uiKit/input/base/index.vue'),
      Copy: () => import(/* webpackChunkName: "/public/components/common/copy" */ '/public/components/common/copy'),
      StoreModal: () => import(/* webpackChunkName: "public/components/modals/StoreModal" */ '/public/components/modals/StoreModal'),
      FakeInput: () => import(/* webpackChunkName: "/public/components/common/uiKit/input/base" */ '/public/components/common/uiKit/input/FakeInput'),
    },
    props: {
        merchantId: {
            type: String,
            default: '',
        }
    },
    data () {
        return {
            email: undefined,
            clientId: undefined,
            billingLink: undefined,
            isLoading: false,
            validationUsed: false
        };
    },
    computed: {
        ...mapGetters('app', [
            'isOpenModal',
            'getSelectedOrganization',
        ]),
        ...mapState('app', ['currencies']),
        isOpen() {
            const {
                $options: {name},
                isOpenModal,
            } = this;

            return isOpenModal(name);
        },
        buttonValidation () {
            const { validationUsed, isClientIdValid, isEmailValid } = this
            return !validationUsed || !(isClientIdValid === '' && isEmailValid === '');
        },
        isClientIdValid ({ validationUsed }) {
          if (!validationUsed) {
            return ''
          }

          if (!this.clientId) {
            return this.$t('common-components.inputs.errors.empty');
          } else {
            return ''
          }
        },
        isEmailValid ({ validationUsed }) {
            if (!validationUsed) {
              return ''
            }

            if (!this.email) {
                return this.$t('common-components.inputs.errors.empty');
            }
            const regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            return regExp.test(this.email) ? '' : this.$t('common-components.inputs.errors.e-mail');
        },
        emailModel: {
          get({ email }) {
            return email
          },
          set(v) {
            this.validationUsed = true
            this.email = v
          }
        },
        clientIdModel: {
          get({ clientId }) {
            return clientId
          },
          set(v) {
            this.validationUsed = true
            this.clientId = v
          }
        }
    },
    methods: {
        ...mapActions('app', [
            'openModal',
            'closeModal',
            'request',
        ]),
        getTruncatedString,
        async createPaymentBundle () {
            if (!!this.isEmailValid || !!this.isClientIdValid) {
              return
            }

            const {
              email,
              clientId,
              getSelectedOrganization,
              merchantId,
            } = this

            this.isLoading = true

            const variables = {
                email,
                clientId,
                organizationId: getSelectedOrganization.id,
                merchantId,
            }
            const { data: {createBillingLink: req }} = await this.request({
                type: 'mutation',
                gql: createBillingLink,
                variables,
            })
            this.billingLink = req
            this.isLoading = false
            this.validationUsed = false
        },
        closeBundleModal () {
            this.clientId = ''
            this.email = ''
            this.billingLink = ''

            this.validationUsed = false

            this.closeModal()
        },
    },
};
</script>

<style lang="scss">
.new-payment-modal-wrapper {
  position: relative;

  font-family: var(--new-front-font-family);

  &.loading {
    .main-modal-header {
      opacity: 0.5
    }
    .main-modal-body {
      opacity: 0.5
    }
    .main-modal-footer {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .main-modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    font-size: $h1;
    font-weight: 500;
    color: var(--new-front-text-primary-bg-modal-primary);
  }

  .main-modal-body {
    display: flex;
    flex-direction: column;
    gap: 20px;

    padding: 0 30px 20px;

    .billing-link {
      &.web {
        display: block;
      }
      &.mobile {
        display: none;
      }
    }

    a {
      color: var(--new-front-text-link-bg-modal-secondary);
    }
  }

  .main-modal-footer {
    .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;

      gap: 20px;

      padding: 0 10px 20px;

      .action-item {
        flex-grow: 1;
      }
    }
  }

  @include below_tablet() {
    .main-modal-body {
      .billing-link {
        &.web {
          display: none;
        }
        &.mobile {
          display: block;
        }
      }
    }
  }

  @include below_phone() {
    .main-modal-header {
      font-size: $h3;
    }
    .main-modal-body {
      .billing-link {

      }
    }
  }
}
</style>
