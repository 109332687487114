import AccountDepositAddressMutation from "/public/graphQl/mutations/accountDepositAddress";

export const accountDepositAddress = async ({ state, commit, dispatch, rootGetters }, payload) => {
    if(state.deposit.loading) {
        return;
    }

    commit('setDepositLoading', true);

    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    const variables = {
        organizationId,
        ...payload,
    };

    try {
        const {data: {accountDepositAddress}} = await dispatch('app/request', {
            type: 'mutation',
            gql: AccountDepositAddressMutation,
            variables,
        }, { root: true });

        commit("setDeposit", accountDepositAddress);
        commit('setDepositCreated', true);
    } catch (error) {
        console.error('Fail create deposit', error);
    } finally {
        commit('setDepositLoading', false);
    }
};
