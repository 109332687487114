<template>
  <div class="api-keys__list-header">
    <p class="api-keys__list-header-item ">
      {{ $t('api_keys.name') }}
    </p>
    <p class="api-keys__list-header-item ">
      {{ $t('api_keys.public_key') }}
    </p>
    <p class="api-keys__list-header-item ">
      {{ $t('api_keys.private_key') }}
    </p>
    <div class="list-header__item">

    </div>
  </div>
</template>

<script>

export default {
    name: 'ApiKeysListHeader',
};

</script>

<style lang="scss" scoped>
.api-keys {
  &__list-header {

  }
  &__list-header-item {
    font-family: $Gilroy ;
    display: flex;
    align-items: center;
    gap: 5px;
    min-height: 56px;
    background: var(--new-front-select-item-bg-active);
    font-size: $h4;
    color: var(--new-front-secondary-font-color);;
    font-weight: 500;
  }
}


</style>
