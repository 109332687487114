<template>
    <div class="roles">
        <div class="roles__wrapper">
            <span class="roles__title">
                 {{ $t('organization.organization_roles') }}
            </span>
            <base-button
                style="width: 180px;"
                icon="icon-plus"
                title="edit"
                :label="$t('organization.organization_btn_add_role')"
                @click="addRole"
            >
                <template #prepend>
                    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 0C4.27614 0 4.5 0.223858 4.5 0.5V3.5H7.5C7.77614 3.5 8 3.72386 8 4C8 4.27614 7.77614 4.5 7.5 4.5H4.5V7.5C4.5 7.77614 4.27614 8 4 8C3.72386 8 3.5 7.77614 3.5 7.5V4.5H0.5C0.223858 4.5 0 4.27614 0 4C0 3.72386 0.223858 3.5 0.5 3.5H3.5V0.5C3.5 0.223858 3.72386 0 4 0Z" fill="white"/>
                    </svg>
                </template>
            </base-button>
        </div>
        <OrganizationListRoles/>
    </div>
</template>

<script>
import OrganizationListRoles from "./OrganizationListRoles";
import pagination from "../../common/pagination";
import {mapActions, mapMutations, mapState} from "vuex";
import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";

export default {
    name: 'OrganizationRoles',

    components: {
        BaseButton,
        OrganizationListRoles,
        pagination,
    },

    computed: {
        ...mapState('organizations', [
            'detailRole',
        ]),
    },

    methods: {
        ...mapActions('organizations', [
            'getOrganizationRole',
        ]),
        ...mapMutations('organizations', ['changeDetailRole', 'setCreateRoleStatus']),

        addRole() {
            this.changeDetailRole(true);
            this.setCreateRoleStatus(true);
        },
    },
};
</script>

<style scoped lang="scss">
.roles {
    width: 100%;
    display: flex;
    flex-direction: column;
    //border: 1px solid var(--border-color);
    //background: var(--secondary-color);
    //box-shadow: 0 8px 50px rgba(10, 17, 105, .08);
    //border-radius: 20px;
    align-items: flex-start;
    overflow: hidden;
    //margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    border: none;
    box-shadow: none;
    border-radius: 0;
  }

  &__title {
      font-family: $Gilroy;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 23px;
      color: var(--new-front-primary-font-color);

      @media screen and (max-width: 768px) {
          font-size: 16px;
          line-height: 16px;
      }
  }

  &__wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin-bottom: 20px;
      padding: 30px 0 0 0;

      @media screen and (max-width: 768px) {
          padding: 0 20px 6px 20px;
          border-bottom: 1px solid var(--new-front-divider-color);
      }
  }

    &__btn {
        height: 46px;
        max-width: 168px;
        width: 100%;
        background: #0059F7;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        transition: all 0.3s ease-in;
        border: none;
        color: #fff;

        &:hover {
            background: var(--blue-400);
        }
    }

  &__pagination {
    width: 100%;
  }
}
</style>
