import getPayoutBalances from "../../../../graphQl/queries/getPayoutBalances";

export const fetchPayoutBalances = async ({commit, dispatch, rootGetters}) => {
    let currenciesData;

    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    try {
        currenciesData = await dispatch('app/request', {
            type: 'query',
            gql: getPayoutBalances,
            variables: { organizationId },
        }, {root: true});
    } catch (error) {
        return error;
    }

    if (currenciesData.message) {
        throw currenciesData.message;
    }

    if (currenciesData.errors) {
        throw currenciesData.errors;
    }

    const {
        data: {getPayoutBalances: payoutBalances},
    } = currenciesData;

    commit('payoutBalances', payoutBalances);

    return payoutBalances;
};