import {getBalanceFee} from "./actions/getBalanceFee";
import {fetchAccounts} from "./actions/fetchAccounts";
import {createAccount} from "./actions/createAccount";
import {accountDepositAddress} from "./actions/accountDepositAddress";
import {getAllNetworks} from "./actions/getAllNetworks";
import {getAllDeposits} from "./actions/getAllDeposits";
import {getCurrencyById} from "./actions/getCurrencyById";
import {clearDeposit} from "./actions/clearDeposit";
import {getAccountData, getAccountHistory} from "./actions/getAccountData";
import {changeAccountBalance} from "./actions/changeAccountBalance";
import {downloadCSV} from "./actions/downloadCSV";
import {downloadXLSX} from "./actions/downloadXLSX";
import {accountsSubscriptions} from "./actions/accountsSubscriptions";


export const actions = {
    getBalanceFee: getBalanceFee,
    changeAccountBalance,
    fetchAccounts: fetchAccounts,
    createAccount: createAccount,
    downloadCSV,
    downloadXLSX,
    clearDeposit: clearDeposit,
    accountDepositAddress,
    getAccountHistory,
    getAllNetworks: getAllNetworks,
    getAllDeposits: getAllDeposits,
    getCurrencyById: getCurrencyById,
    getAccountData: getAccountData,
    accountsSubscriptions,
};
