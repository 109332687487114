import getNetworkFee from "/public/graphQl/queries/getNetworkFee";

export const fetchNetworkFee = async ({ dispatch }, coin) => {
    try {
        const {data: {getNetworkFee: networkFee}} = await dispatch('app/request', {
            type: 'query',
            gql: getNetworkFee,
            variables: {coin},
        }, { root: true });

        return networkFee;
    } catch (error) {
        console.error('fetch network fee', error);
        return Infinity;
    }
};