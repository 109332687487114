<template>
<div>
  <div class="addresses">
    <zero-state
      v-if="showZeroState"
      :meta="zeroStateMeta"
      :background-transparent="false"
    />
    <Container
      v-else
      class="addresses__container"
      overflow
    >
      <div class="addresses__top-bar">
        <top-bar
          :filters.sync="filters"
          :refresh="loadAddresses"
        />
      </div>

      <div class="addresses__list">
        <div
          v-if="isLoading"
          class="loader-block"
          style="min-height: 400px"
        >
          <new-spinner />
        </div>
        <div v-else-if="paginationList.length">
          <addresses-table
            :addresses="paginationList"
            @openModal="openCreateWithdrawalRequestModal"
          />
        </div>
        <div
          v-else
          class="empty-list-label"
        >
          {{ $t('common.empty')}}
        </div>
        <div
            v-if="pagination.total > pagination.limit"
            class="subscriptions-pagination"
        >
          <pagination
            size="small"
            :total="pagination.total"
            :page-size="pagination.limit"
            :current="currentPage"
            @page-change="changePage"
          />
        </div>

        <SubscriptionsAddressesWithdrawal :addresses="addresses"/>
      </div>
    </Container>

    <ChangeAddressBook />
    <NewAddressModal />
    <TwoFaRestriction />
    <TwoFaConfirmation
      modal-name="SubscriptionsAddressesWithdrawal"
    />
    <NotEnoughAdvanceBalance />
  </div>
</div>
</template>

<script>
import comingSoon from "@components/elements/coming-soon";
import Container from "../components/common/Container";
import OrdersSortByCurrency from "../components/elements/orders/OrdersSortByCurrency";
import OrdersSortByNetwork from "../components/elements/orders/OrdersSortByNetwork";
import BtnRefresh from "../components/common/btn-refresh";
import SubscriptionsAddressesItem from "../components/elements/subscriptions-addresses/SubscriptionsAddressesItem";
import Preloader from "/public/components/common/uiKit/spinner/index.vue";
import {mapActions, mapGetters, mapState} from "vuex";
import getRecurrentAddresses from "../graphQl/queries/getRecurrentAddresses";
import SubscriptionsSortByNetwork from "../components/elements/subscriptions-addresses/SubscriptionsSortByNetwork";
import SubscriptionsSortByCurrency from "../components/elements/subscriptions-addresses/SubscriptionsSortByCurrency";
import CreateWithdrawalRequestModal from "../components/modals/requests/CreateWithdrawalRequestModal";
import ChangeAddressBook from "../components/modals/addressBook/ChangeAddressBook";
import NewAddressModal from "../components/modals/addressBook/NewAddressModal";
import TwoFaRestriction from "@/public/components/modals/twoFactorAuthorization/TwoFaRestriction";
import TwoFaConfirmation from "@/public/components/modals/twoFactorAuthorization/TwoFaConfirmation";
import NotEnoughAdvanceBalance from "@/public/components/modals/business-wallet/NotEnoughAdvanceBalance.vue";

import SubscriptionsAddressesWithdrawal
  from "../components/modals/subscriptions-addresses/SubcriptionsAddressesWithdrawal";

import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";

export default {
    name: "subscriptionsAddress",
    title: 'navigation.payment-address',

    components: {
        NotEnoughAdvanceBalance,
        TwoFaConfirmation,
        TwoFaRestriction,
        NewAddressModal,
        ChangeAddressBook,
        SubscriptionsAddressesWithdrawal,
        CreateWithdrawalRequestModal,
        SubscriptionsSortByCurrency,
        SubscriptionsSortByNetwork,
        Preloader,
        SubscriptionsAddressesItem,
        BtnRefresh,
        OrdersSortByNetwork,
        OrdersSortByCurrency,
        Container,
        comingSoon,

        AddressesTable: () => import(/* webpackChunkName: "public/components/elements/subscriptions-addresses/Table" */ '/public/components/elements/subscriptions-addresses/Table'),
        BaseButton: () => import(/* webpackChunkName: "public/components/common/uiKit/button/base/bg-secondary" */ '/public/components/common/uiKit/button/base/bg-secondary'),
        TopBar: () => import(/* webpackChunkName: "public/components/elements/subscriptions-addresses/TopBar" */ '/public/components/elements/subscriptions-addresses/TopBar'),
        NewSpinner: () => import (/* webpackChunkName: "public/components/common/uiKit/spinner" */ "/public/components/common/uiKit/spinner/index.vue"),
        Pagination: () => import(/* webpackChunkName: "public/components/common/uiKit/Pagination.vue" */ '/public/components/common/uiKit/Pagination.vue'),
        ZeroState: () => import(/* webpackChunkName: "public/pages/zeroState" */ '/public/pages/zeroState')
    },
    rulesAlias: 'recurrent',
    data () {
        return {
            isLoading: true,
            network: '',
            currency: '',
            filters: {
              currency: undefined,
              network: undefined,
              query: undefined,
              risk: '',
            },
            pagination: {
              offset: 0,
              total: undefined,
              limit: 10,
            },
            currentPage: 1
        };
    },
    computed: {
      ...mapGetters('app', [
        'sortedCurrencies',
        'isOpenModal',
        'getSelectedOrganization',
      ]),
      ...mapState('app', ['networks', 'currencies']),
      ...mapState('subscriptions', ['addresses']),
      ...mapGetters('organizations', ['userRoleRules']),
      ...mapState('accounts', ['accounts']),
      ...mapState('user', {
        userOtpEnabled: state => state.user.otpToken,
      }),
      isAddressBookAllowed() {
        return this.userRoleRules['address_book'].isAllowed;
      },
      filteredAddressesOld () {
        if (this.network) {
          const networkId = this.networks.find(({alias}) => alias === this.network).id;
          return this.addresses.filter(item => item.networkId === networkId);
        }
        if (this.currency) {
          const currencyId = this.currencies.find(({currency}) => currency === this.currency).id;
          return this.addresses.filter(item => item.currencyId === currencyId);
        }
        return this.addresses;
      },
      filteredAddresses () {
        const { network, currency, query, risk } = this.filters

        let preparedAddresses = cloneDeep(this.addresses)

        if (network) {
          preparedAddresses = preparedAddresses.filter(item => item.networkId === network?.id);
        }
        if (currency) {
          preparedAddresses = preparedAddresses.filter(item => item.currencyId === currency?.id);
        }
        if (query) {
          preparedAddresses = preparedAddresses.filter(item => item.address.toLowerCase().includes(query.toLowerCase()));
        }

        if(risk) {
          preparedAddresses = preparedAddresses.filter(({riskLevel}) => riskLevel === risk.value)
        }

        this.pagination.total = preparedAddresses.length
        this.changePage()

        return preparedAddresses;
      },
      paginationList({ filteredAddresses, pagination }) {
        return filteredAddresses.slice(pagination.offset, (pagination.offset + pagination.limit))
      },
      isEmptyFilters({ filters }) {
        return isEmpty(filters.currency) && isEmpty(filters.network) && isEmpty(filters.query) && isEmpty((filters.risk))
      },
      showZeroState({ isEmptyFilters, paginationList }) {
        return isEmptyFilters && !paginationList.length
      },
      zeroStateMeta() {
        return {
          title: this.$t('zero-states.subscriptions-addresses.title'),
          subtitle: this.$t('zero-states.subscriptions-addresses.subtitle'),
          actions: [
            {
              title: this.$t('zero-states.subscriptions-addresses.actions.create-subscription.title'),
              info: this.$t('zero-states.subscriptions-addresses.actions.create-subscription.info'),
              btnName: this.$t('zero-states.subscriptions-addresses.actions.create-subscription.btn-name'),
              route: { name: 'subscriptions' },
              withoutIcon: true
            },
            {
              title: this.$t('zero-states.subscriptions.actions.api-integration.title'),
              info: this.$t('zero-states.subscriptions.actions.api-integration.info'),
              btnName: this.$t('zero-states.subscriptions.actions.api-integration.btn-name'),
              btnSecondary: true,
              withoutIcon: true,
              apiMethod: '/api-reference/10recurring_payments/post_create-subscriber-billing-link'
            }
          ],
          type: 'default'
        }
      }
   },
    async created() {
      const isAllowed = this.userRoleRules[this.$options.rulesAlias].isAllowed;
      const isViewAllowed = this.userRoleRules[this.$options.rulesAlias].entities.view_addresses;

      if (!isAllowed || !isViewAllowed) {
          this.$Message.error(this.$t('rules.not_allowed'));
          this.$router.push('/merchant/dashboard');
      } else {
        await this.loadAddresses();
      }
    },
    methods: {
      ...mapActions('app', [
        'openModal',
        'request',
      ]),
      ...mapActions('subscriptions', [
        'getAddresses',
      ]),
      openCreateWithdrawalRequestModal (id) {
        if (Number(this.accounts[0].balance) < 0) {
          this.openModal({
            name: 'NotEnoughAdvanceBalance',
            payload: {
              advanceBalanceAmount: this.accounts[0].balance,
            },
          });
          return;
        }
        if (!this.userOtpEnabled) {
          this.openModal({
            name: 'TwoFaRestriction',
          });
          return;
        }

        this.openModal({
          name: 'SubscriptionsAddressesWithdrawal',
          payload: {
            addressId: id,
          },
        });
      },
      // changeNetwork (value) {
      //     this.currency = '';
      //     this.network = value;
      // },
      // changeCurrency (value) {
      //     this.network = '';
      //     this.currency = value;
      // },
      async loadAddresses () {
          this.isLoading = true
          await this.getAddresses().then( () => this.isLoading = false )
      },
      changePage(page = 1) {
        this.currentPage = page
        this.pagination.offset = (page - 1) * this.pagination.limit
      }
    }
};
</script>

<style lang="scss" scoped>
  .addresses {
    width: 80vw;

    @include below_1280() {
      width: 77vw;
    }
    @include below_1180() {
      width: 99vw;
    }

    font-family: var(--new-front-font-family);

    padding: 0 30px;

    &__title {
      display: flex;
      align-items: end;

      margin: 10px 0;

      font-style: normal;
      font-weight: 400;
      font-size: $h1;
      line-height: $h1;
      color: var(--new-front-text-primary-bg-primary);

      @media (max-width: 950px) {
        text-align: center;
      }
    }
    &__paragraph-block {
      display: flex;
      align-items: start;

      span {
        font-style: normal;
        font-weight: 400;
        font-size: $h3;
        line-height: $h3;
        color: var(--new-front-text-secondary-bg-primary);
      }
    }
    &__container {
      display: flex;
      flex-direction: column;
      gap: 30px;

      padding: 30px;

      background: var(--new-front-bg-secondary)!important;

      @include below_phone () {
        padding: 10px;
      }
    }
    &__top-bar {
      width: 100%;

      //display: grid;
      //align-items: flex-end;
      //grid-template-columns: 1fr 1fr fit-content(50px);
      //grid-gap: 30px;
      //@media (max-width: 950px) {
      //  grid-template-columns: 1fr;
      //  grid-gap: 10px;
      //}
    }
    &__list {
      .loader-block {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      .empty-list-label {
        width: 100%;
        text-align: center;

        color: var(--new-front-text-secondary-bg-secondary);
      }
    }
    &__container-address {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    //&__refresh-mobile {
    //  display: none;
    //  @media (max-width: 950px) {
    //    display: unset;
    //  }
    //}
    //&__refresh-button {
    //  @media (max-width: 950px) {
    //    display: none;
    //  }
    //}
    //&__address-btn {
    //  width: 176px;
    //  height: 46px;
    //  background: #0059F7;
    //  border-radius: 12px;
    //  transition: all .3s ease;
    //  cursor: pointer;
    //  display: flex;
    //  align-items: center;
    //  justify-content: center;
    //  font-family: 'Roboto';
    //  font-style: normal;
    //  font-weight: 400;
    //  font-size: 16px;
    //  line-height: 19px;
    //  color: #fff;
    //  margin-bottom: 10px;
    //
    //  svg {
    //    margin-right: 10px;
    //  }
    //
    //  &:hover {
    //    background: #0028F7;
    //  }
    //}

    @include below_phone () {
      &__title {
        font-size: $h3;
        line-height: $h3;
      }
      &__paragraph-block {
        span {
          font-size: $h6;
          line-height: $h6;
        }
      }
    }
  }
</style>
