<template>
    <div class="organization-list">
        <OrganisationListHeaderRoles/>
        <OrganisationListBodyRoles/>
    </div>
</template>

<script>
import OrganisationListHeaderRoles from "./OrganisationListHeaderRoles";
import OrganisationListBodyRoles from "./OrganisationListBodyRoles";

export default {
    name: 'OrganizationList',

    components: {
        OrganisationListHeaderRoles,
        OrganisationListBodyRoles,
    },
};
</script>

<style scoped lang="scss">
.organization-list {
  overflow: auto;
  width: 100%;
}
</style>