<script>
    import Container from "@/public/components/common/uiKit/Container.vue";
    import pagination from "@/public/components/common/uiKit/Pagination.vue";
    import Table from "@/public/components/common/uiKit/table/Table.vue";
    import AuthorizationHistoryItem from "@/public/components/elements/authorization-history/TableItem.vue";
    import MobileAuthorizationHistoryTableItem
        from "@/public/components/elements/authorization-history/MobileTableItem.vue";
    import {mapActions, mapGetters, mapState} from "vuex";
    export default {
        name: "AuthorizationHistory",
        components: {MobileAuthorizationHistoryTableItem, AuthorizationHistoryItem, Container, pagination, Table, NewSpinner: () => import (/* webpackChunkName: "Spinner" */ "/public/components/common/uiKit/spinner/index.vue"),},
        data () {
            return {
                page: 1,
                pageSize: 10,
            }
        },
        methods: {
            ...mapActions('authorizationHistory', ['getAuthorizationHistory']),
            paginate(page) {
                this.page = page;
                this.loadHistory()
            },
            loadHistory () {
                const userId = this.$route.params.id
                const payload = {
                    limit: this.pageSize,
                    offset: (this.page - 1) * this.pageSize,
                    userId
                }
                this.getAuthorizationHistory(payload)
            }
        },
        created() {
            this.loadHistory()
        },
        computed: {
            ...mapState('app', ['isLoading']),
            ...mapState('authorizationHistory', ['history']),
            totalCount () {
                return this.history.count
            },
            email () {
              return this.$route.params.email
            },
            list () {
              return this.history.rows
            },
            tableHeader () {
                return [
                    {
                        title: this.$t('auth-history.date'),
                        slug: 'date',
                        isSort: false
                    },
                    {
                        title: 'IP',
                        slug: 'ip',
                        isSort: false
                    },
                    {
                        title: this.$t('auth-history.client'),
                        slug: 'client',
                        isSort: false
                    },
                ]
            },
        }
    }
</script>

<template>
<div class="history">
    <h1 class="history__title">
        {{ $t('auth-history.title') }}
    </h1>
    <router-link class="history__return" to="/organizations">
        <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.07812 0.583127C6.81906 0.454574 6.50723 0.476409 6.27015 0.639703L0.825704 4.3897C0.621307 4.53048 0.5 4.75779 0.5 5C0.5 5.24221 0.621307 5.46952 0.825704 5.6103L6.27015 9.3603C6.50723 9.52359 6.81906 9.54543 7.07812 9.41687C7.33717 9.28832 7.5 9.03094 7.5 8.75V1.25C7.5 0.969062 7.33717 0.71168 7.07812 0.583127Z" fill="#6750A4" stroke="#6750A4" stroke-linejoin="round"/>
        </svg>
        {{ $t('orphan.return') }}
    </router-link>
    <Container>
        <div class="history__container-title">
            {{ $t('auth-history.container-title') }}: {{ email }}
        </div>
        <Table v-if="!isLoading && list?.length"  class="history__table" :header-list="tableHeader">
            <authorization-history-item v-for="item in list" :item="item" />
        </Table>
        <div v-if="!isLoading && list?.length" class="history__table-mobile">
            <mobile-authorization-history-table-item v-for="item in list" :item="item" />
        </div>
        <div class="history__empty" v-if="!list?.length && !isLoading">
            <div class="history__empty-title">{{ $t('profits.empty-history-title') }}</div>
        </div>
        <new-spinner v-if="isLoading" />
        <pagination v-if="list?.length" :page-size="pageSize" :current="page" @page-change="paginate"
                    :total="totalCount"/>
    </Container>
</div>
</template>

<style scoped lang="scss">
.history {
  padding: 30px;
  font-family: $Gilroy;
    &__container-title {
        font-size: $h2;
        color: var(--new-front-text-primary-bg-modal-primary);
        font-weight: 500;
        margin-bottom: $p30;
    }
    &__empty {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 30px 0 0;
        color: var(--new-front-secondary-font-color);
        &-title {
            font-size: $h2;
        }
        &-subtitle {
            font-size: $h4;
        }
    }
  &__title {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: $h1;
    color: var(--new-front-text-primary-bg-modal-primary);
    margin-left: $p5;
    margin-bottom: $p5;
    line-height: 31px;
    > svg {
      margin-left: 5px;
    }
  }
  &__subtitle {
    font-size: $h2;
    font-weight: 400;
    color: var(--new-front-secondary-font-color);
    margin-bottom: $p30;
    margin-left: $p5;
  }
    &__return {
        margin-top: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--new-front-bg-btn-primary-bg-modal-primary);
        font-size: $h4;
        font-weight: 500;
        margin-bottom: $p20;
    }
    &__table {
        &-mobile {
            display: none;
        }
    }
}
@media (max-width: 556px) {
    .history {
        padding: 10px;
        &__container-title {
            font-size: $h4;
            padding: 20px;
            margin-bottom: 0;
            border-bottom: 1px solid var(--new-front-divider-color);
        }
        &__table {
            display: none;
            &-mobile {
                display: unset;
            }
        }
    }
}
</style>
