import getCurrencyByIdQuery from "/public/graphQl/queries/getCurrencyById";

export const getCurrencyById = async ({ commit, dispatch }, args) => {

    let data;
    try {
        data = await dispatch('app/request', {
            type: 'query',
            gql: getCurrencyByIdQuery,
            variables: args,
        }, { root: true });
    } catch (error) {
        return error;
    }

    commit('currencies', data);
    return data;
};