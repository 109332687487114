export const mutations = {
    setAddresses: (state, payload) => {
        // state.addresses = [...payload, ...payload.slice(0, 3), ...payload, ...payload.slice(2), ...payload]
        state.addresses = payload
    },
    updateAddresses: (state, { balance, addressId }) => {
        if (!state.addresses.length) return

        const address = state.addresses.find(({ id }) => id === addressId )

        if (!address) return

        address.balance = balance
    }
}
