<script>
    import vClickOutside from "v-click-outside";
    import {mapState} from "vuex";
    import Popper from 'vue-popperjs';
    import 'vue-popperjs/dist/vue-popper.css';

    export default{
        name: "CollectingSettingsActions",
        directives: {
            clickOutside: vClickOutside.directive
        },
        components: {
            'popper': Popper
        },
        props: {
            closeOutside: {
                type: Boolean,
                default: true
            },
            id: {
                type: String,
                default: ''
            },
            historyAllowed: {
                type: Boolean,
                default: true
            },
            actionsAllowed: {
                type: Boolean,
                default: true
            }
        },
        data () {
            return {
                isModalOpen: false
            }
        },
        computed: {
            ...mapState(['themeStatus']),
        },
        methods: {
            emitClick (type) {
                this.$emit('click', type)
                this.closeModal()
            },
            toggleFilter (event) {
                this.isModalOpen = !this.isModalOpen
            },
            confirm () {
                this.$emit('confirm')
                this.isModalOpen = false
            },
            closeModal (event) {
                // if (event?._prevClass === 'settings__trigger' || event?.parentElement._prevClass === 'settings__trigger') return;
                this.isModalOpen = false
            },
        }
    }
</script>

<template>
<div class="actions">
    <transition name="slide-fade">
        <popper
            trigger="clickToOpen"
            :options="{
      placement: 'left-start',
    }"
        >
            <div class="actions__trigger" slot="reference" ref="actionsTrigger">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.5 3C6.5 2.17157 7.17157 1.5 8 1.5C8.82843 1.5 9.5 2.17157 9.5 3C9.5 3.82843 8.82843 4.5 8 4.5C7.17157 4.5 6.5 3.82843 6.5 3ZM6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8ZM6.5 13C6.5 12.1716 7.17157 11.5 8 11.5C8.82843 11.5 9.5 12.1716 9.5 13C9.5 13.8284 8.82843 14.5 8 14.5C7.17157 14.5 6.5 13.8284 6.5 13Z" fill="var(--new-front-primary-font-color)"/>
                </svg>
            </div>
          <div class="actions__modal">
                    <div :class="['actions__modal-item', !historyAllowed ? 'disabled' : '' ]" @click="emitClick('history')">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 1C5.46183 1.00439 3.98409 1.59935 2.872 2.662V1.6C2.872 1.44087 2.80879 1.28826 2.69626 1.17574C2.58374 1.06321 2.43113 1 2.272 1C2.11287 1 1.96026 1.06321 1.84774 1.17574C1.73521 1.28826 1.672 1.44087 1.672 1.6V4.3C1.672 4.45913 1.73521 4.61174 1.84774 4.72426C1.96026 4.83678 2.11287 4.9 2.272 4.9H4.972C5.13113 4.9 5.28374 4.83678 5.39626 4.72426C5.50878 4.61174 5.572 4.45913 5.572 4.3C5.572 4.14087 5.50878 3.98826 5.39626 3.87574C5.28374 3.76321 5.13113 3.7 4.972 3.7H3.532C4.30347 2.89381 5.3317 2.38159 6.43992 2.2514C7.54814 2.12121 8.66709 2.38118 9.60439 2.98663C10.5417 3.59207 11.2388 4.50515 11.5758 5.56887C11.9128 6.6326 11.8687 7.78051 11.451 8.81524C11.0334 9.84996 10.2683 10.7069 9.28729 11.2386C8.30629 11.7703 7.17069 11.9436 6.07573 11.7288C4.98077 11.5139 3.99488 10.9243 3.28755 10.0613C2.58023 9.19831 2.19567 8.11583 2.2 7C2.2 6.84087 2.13679 6.68826 2.02426 6.57573C1.91174 6.46321 1.75913 6.4 1.6 6.4C1.44087 6.4 1.28826 6.46321 1.17574 6.57573C1.06321 6.68826 1 6.84087 1 7C1 8.18669 1.35189 9.34672 2.01118 10.3334C2.67047 11.3201 3.60754 12.0891 4.7039 12.5433C5.80025 12.9974 7.00665 13.1162 8.17054 12.8847C9.33443 12.6532 10.4035 12.0818 11.2426 11.2426C12.0818 10.4035 12.6532 9.33443 12.8847 8.17054C13.1162 7.00665 12.9974 5.80025 12.5433 4.7039C12.0891 3.60754 11.3201 2.67047 10.3334 2.01118C9.34672 1.35189 8.18669 1 7 1ZM7 4.6C6.84087 4.6 6.68826 4.66321 6.57573 4.77574C6.46321 4.88826 6.4 5.04087 6.4 5.2V7C6.4 7.15913 6.46321 7.31174 6.57573 7.42426C6.68826 7.53678 6.84087 7.6 7 7.6H8.2C8.35913 7.6 8.51174 7.53678 8.62426 7.42426C8.73678 7.31174 8.8 7.15913 8.8 7C8.8 6.84087 8.73678 6.68826 8.62426 6.57573C8.51174 6.46321 8.35913 6.4 8.2 6.4H7.6V5.2C7.6 5.04087 7.53678 4.88826 7.42426 4.77574C7.31174 4.66321 7.15913 4.6 7 4.6Z" fill="var(--new-front-secondary-font-color)"/>
                        </svg>
                        <span>{{ $t('profits.history') }}</span>
                    </div>
                    <div :class="['actions__modal-item', !actionsAllowed ? 'disabled' : '']" @click="emitClick('edit')">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.811 0.695271L11.9406 0.565638C12.3531 0.153159 13.0219 0.153159 13.4344 0.565638C13.8468 0.978117 13.8468 1.64688 13.4344 2.05936L13.3047 2.18899C13.7651 2.70436 13.7479 3.49583 13.2531 3.99058L4.24687 12.9969C4.1908 13.0529 4.12055 13.0927 4.04362 13.1119L0.543619 13.9869C0.39453 14.0242 0.236816 13.9805 0.12815 13.8719C0.0194837 13.7632 -0.0242003 13.6055 0.0130719 13.4564L0.888072 9.95639C0.907304 9.87946 0.947081 9.80921 1.00315 9.75314L9.43925 1.31703C9.26852 1.19664 9.03091 1.21282 8.87815 1.36558L5.99687 4.24686C5.82601 4.41771 5.549 4.41771 5.37815 4.24686C5.2073 4.076 5.2073 3.79899 5.37815 3.62814L8.25943 0.746857C8.75466 0.25163 9.54717 0.23488 10.0625 0.696606C10.5593 0.251489 11.3137 0.251044 11.811 0.695271ZM11.2469 1.36558C11.076 1.19472 10.799 1.19472 10.6281 1.36558L1.70756 10.2862L1.0388 12.9612L3.71384 12.2925L12.6344 3.37186C12.8053 3.201 12.8053 2.92399 12.6344 2.75314L11.2469 1.36558Z" fill="var(--new-front-secondary-font-color)"/>
                        </svg>
                        <span>{{ $t('profits.edit') }}</span>
                    </div>
                    <div class="actions__modal-item" :class="['actions__modal-item', !actionsAllowed ? 'disabled' : '']" @click="emitClick('delete')">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.1875 0.875C1.70425 0.875 1.3125 1.26675 1.3125 1.75V2.625C1.3125 3.10825 1.70425 3.5 2.1875 3.5H2.625V11.375C2.625 12.3415 3.4085 13.125 4.375 13.125H9.625C10.5915 13.125 11.375 12.3415 11.375 11.375V3.5H11.8125C12.2957 3.5 12.6875 3.10825 12.6875 2.625V1.75C12.6875 1.26675 12.2957 0.875 11.8125 0.875H8.75C8.75 0.391751 8.35825 0 7.875 0H6.125C5.64175 0 5.25 0.391751 5.25 0.875H2.1875ZM4.8125 4.375C5.05412 4.375 5.25 4.57088 5.25 4.8125V10.9375C5.25 11.1791 5.05412 11.375 4.8125 11.375C4.57088 11.375 4.375 11.1791 4.375 10.9375L4.375 4.8125C4.375 4.57088 4.57088 4.375 4.8125 4.375ZM7 4.375C7.24162 4.375 7.4375 4.57088 7.4375 4.8125V10.9375C7.4375 11.1791 7.24162 11.375 7 11.375C6.75838 11.375 6.5625 11.1791 6.5625 10.9375V4.8125C6.5625 4.57088 6.75838 4.375 7 4.375ZM9.625 4.8125V10.9375C9.625 11.1791 9.42912 11.375 9.1875 11.375C8.94588 11.375 8.75 11.1791 8.75 10.9375V4.8125C8.75 4.57088 8.94588 4.375 9.1875 4.375C9.42912 4.375 9.625 4.57088 9.625 4.8125Z" fill="var(--new-front-secondary-font-color)"/>
                        </svg>
                        <span>{{ $t('profits.delete') }}</span>
                    </div>
                </div>
        </popper>
    </transition>
</div>
</template>

<style scoped lang="scss">
.actions {
    position: relative;
    z-index: 3;
  &__trigger {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      cursor: pointer;
      z-index: 1;
  }
  &__modal {
      position: absolute;
      right: -5px;
      top: 5px;
      background: var(--new-front-main-bg);
      border: 1px solid var(--new-front-input-border);
      min-width: 100px;
      width: fit-content;
      max-width: 160px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 10px;
      z-index: 5;
      overflow: hidden;
    &-item {
      display: flex;
      align-items: center;
      gap: 10px;
      min-width: 100px;
      width: 100%;
      border-bottom: 1px solid var(--new-front-input-border);
      color: var(--new-front-secondary-font-color);
      font-weight: 500;
      padding: $p10;
      transition: all .2s ease;
      cursor: pointer;
      &:hover {
        color:var(--new-front-primary-font-color) ;
        background: var(--new-font-nav-hover-bg);
        &::v-deep svg {
          path {
            fill: #744DB2 !important;
          }
        }
      }
      &:last-of-type {
        border-bottom: unset;
      }
    }
  }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
    transition: all .3s ease;
}
.slide-fade-leave-active {
    transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(10px);
    opacity: 0;
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
</style>
