export const state = {
    balances: [],
    updatesSub: undefined,
    rollingReserve: [],
    accountBalances: [],
    price: {},
    balancesGrouped: {
        currencyGroups: [{
            currencyId: '',
            networkGroups: [{
                networkId: '',
                addresses: [{
                    accountId: '',
                    address: '',
                    balance: '',
                    currencyId: '',
                    id: '',
                    networkId: '',
                    tag: '',
                }],
            }],
        }],
    },
    balancesGroupedPayin: {
        currencyGroups: [{
            currencyId: '',
            networkGroups: [{
                networkId: '',
                addresses: [{
                    accountId: '',
                    address: '',
                    balance: '',
                    currencyId: '',
                    id: '',
                    networkId: '',
                    tag: '',
                }],
            }],
        }],
    },
    balancesGroupedBusiness: {
        currencyGroups: [{
            currencyId: '',
            networkGroups: [{
                networkId: '',
                addresses: [{
                    accountId: '',
                    address: '',
                    balance: '',
                    currencyId: '',
                    id: '',
                    networkId: '',
                    tag: '',
                    isArchived: false
                }],
            }],
        }],
    },
    balancesBusiness: [{
        id: '',
        accountId: '',
        currencyId: '',
        networkId: '',
        address: '',
        tag: '',
        balance: '0',
        alias: '',
        comment: '',
        isBanned: false,
        isActive: false,
        isArchived: false
    }],
    lastUpdateAddress: null
};
