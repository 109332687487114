<template>
  <div class="requests-sort-by-network">
    <p class="requests-sort-by-network__title">
      {{ $t('withdrawal.network') }}
    </p>
    <AtSelect
        :placeholder="select_network"

        clearable
        :value="selectedNetworks[0]"
        @on-change="changeSelect"
    >
      <AtOption
          v-for="(network, key) in networks"
          :key="key"
          :value="network.alias"
      />
    </AtSelect>
  </div>
</template>

<script>

import {mapState, mapActions} from 'vuex';

import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';

export default {
    name: 'RequestsSortByNetwork',
    components: {
        AtSelect,
        AtOption,
    },
    data() {
        return {
            selectedNetworks: [],
        };
    },
    computed: {
        ...mapState('app', {
            networks: state => state.networks,
        }),
        select_network() {
            return this.$t('withdrawal.select_network');
        },
    },
    beforeMount() {
        this.setNetworksFromUrl();
    },
    methods: {
        ...mapActions('withdrawalRequests', [
            'fetchWithdrawalRequests',
        ]),
        setNetworksFromUrl() {
            const networksFromUrl = this.$route.query.networks;

            if (!networksFromUrl) {
                return false;
            }

            this.selectedNetworks = networksFromUrl.split(',');
        },
        changeSelect(values) {
            const {
                $route,
                $router,
                fetchWithdrawalRequests,
            } = this;
            const val = values ? [values] : [];
            fetchWithdrawalRequests({
                route: $route,
                router: $router,
                networks: val,
                excludeParams: ['page'],
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.requests-sort-by-network {
  &__title {
    margin-bottom: 8px;
    font-size: var(--font-size-text-main);
    font-family: var(--font-family);
    line-height: var(--line-height);
    color: var(--black-200);
  }

  &::v-deep .at-select {
    background: #FFFFFF;
    box-sizing: border-box;
  }

  ::v-deep .at-select__arrow {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  ::v-deep .at-select__clear {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }


  &::v-deep .at-select__selection {
    border: 1px solid #F2F2F2;
    border-radius: 12px;
    min-height: 46px;
    display: flex;
    padding-left: 30px;
    align-items: center;
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    flex-wrap: wrap;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  ::v-deep .at-select__dropdown .at-select__option {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

  ::v-deep .at-select__placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }

  ::v-deep .at-select__selection {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

}

</style>