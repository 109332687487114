import gql from 'graphql-tag';

export default gql`
mutation(
    $organizationId: String!,
    $otpToken: String!
    $alias: String
    $currencyId: String
    $networkId: String!
    $type: String
    $amount: String!
    $addressTo: String!
    $id: String!
  )  {
    updateCollectingSetting(
    organizationId: $organizationId,
    otpToken: $otpToken,
    alias: $alias,
    currencyId: $currencyId,
    networkId: $networkId,
    type: $type,
    amount: $amount,
    addressTo: $addressTo,
    id: $id
  ) {
    code
    result {
      id
      alias
      currencyId
      networkId
      type
      amount
      addressTo
    }
    traceId
  }
}
`
