import removeOrganizationUserMutation from "/public/graphQl/mutations/removeOrganizationUser.js";

export const deleteOrganizationUser = async ({commit, dispatch,rootState, rootGetters}, payload) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    let currenciesData;

    let variables = {
        organizationId,
        userId: payload.userId,
    };

    try {
        if (!rootState.app.isLoading) {
            commit('app/setLoading', true, {root: true});
        }
        currenciesData = await dispatch('app/request', {
            type: 'query',
            gql: removeOrganizationUserMutation,
            variables,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/setLoading', false, {root: true});
    }

    if (currenciesData.message) {
        throw currenciesData.message;
    }

    if (currenciesData.errors) {
        throw currenciesData.errors;
    }

    return true;
};
