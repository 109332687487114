<template>
    <div class="preview-request table-row row">
        <RouterLink
            class="preview-request__link"
            :to="{ name: 'exchange', params: { id } }"
        />
        <div class="preview-request__id col col-5">
            <CopyString
                :string="id"
                canCopy
            />
        </div>
        <div class="preview-request__currency col">
            {{ pair }}
        </div>
        <div class="col">
            {{ amountFrom }}
        </div>
        <div class="col">
            {{ amountTo }}
        </div>
        <div class="col col-4">
            {{ createdAt }}
        </div>
        <div class="col col-3">
            <AtTag :color="status.colorTag">
                {{ status.title }}
            </AtTag>
        </div>
    </div>
</template>

<script>

import Moment from "moment";
import { mapGetters } from 'vuex';

import copyToClipboard from "/public/mixins/copyToClipboard";

import CopyString from "/public/components/elements/CopyString";
import CurrencyImg from "/public/components/elements/CurrencyImg";
import AtTag from 'at-ui/src/components/tag';
import {TRANSACTION_REQUEST_STATE_ENUM} from "../../../../common/constants/transaction-request-state-enum";

export default {
    name: 'PreviewExchange',
    statuses: [
        {
            value: TRANSACTION_REQUEST_STATE_ENUM.INIT,
            title: 'В обработке',
            colorTag: 'primary',
        },
        {
            value: TRANSACTION_REQUEST_STATE_ENUM.PROCESSED,
            title: 'Выполнена',
            colorTag: 'success',
        },
        {
            value: TRANSACTION_REQUEST_STATE_ENUM.REJECTED,
            title: 'Отклонена',
            colorTag: 'error',
        },
        {
            value: TRANSACTION_REQUEST_STATE_ENUM.PENDING,
            title: 'Ожидание',
            colorTag: 'info',
        },
        {
            value: TRANSACTION_REQUEST_STATE_ENUM.READY,
            title: 'Обмен',
            colorTag: 'warning',
        },
    ],
    components: {
        CopyString,
        CurrencyImg,
        AtTag,
    },
    mixins: [copyToClipboard],
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters('app', [
            'getCurrencyNameById',
        ]),
        id () {
            return this.data?.id;
        },
        pair () {
            const {
                data: { currencyFrom, currencyTo },
                getCurrencyNameById,
            } = this;

            return `${getCurrencyNameById(currencyFrom)} / ${getCurrencyNameById(currencyTo)}`;
        },
        amountFrom () {
            return this.data?.amountFrom || 0;
        },
        amountTo () {
            return this.data?.amountTo || 0;
        },
        createdAt () {
            return Moment(Number(this.data?.createdAt)).format('DD.MM.YYYY, HH:mm');
        },
        status () {
            const {
                $options: { statuses },
                data: { state },
            } = this;

            return statuses.find(statusItem => statusItem.value === state);
        },
    },
};

</script>

<style lang="scss" scoped>

    .preview-request {
        position: relative;
        align-items: center;
        color: #3F536E;

        &__link {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
        }

        &__id {
            position: relative;
            z-index: 2;
        }

        &__currency {
            display: flex;
            align-items: center;
        }

        &__currency-img {
            margin-right: 12px;
            width: 24px;
            height: 24px;
        }
    }

</style>
