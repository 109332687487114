<template>
  <div class="transactions-sort-by-network">
    <business-wallet-network-select
      class="transactions-sort-by-network__select"
      :placeholder="select_network"
      clearable
      :list="networks"
      :value="selectedNetwork"
      uniq-key="id"
      @input="selectNetworks"
    />
  </div>
</template>

<script>

import {mapActions, mapGetters, mapState} from 'vuex';

import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';
import BusinessWalletNetworkSelect
    from "@/public/components/elements/business-walet/Select/businessWalletNetworkSelect.vue";

export default {
    name: 'SortByNetwork',
    components: {
        BusinessWalletNetworkSelect,
        AtSelect,
        AtOption,
    },
    data() {
        return {
            selectedNetwork: null,
        };
    },
    watch: {
      selectedNetwork(v) {
        this.$emit('has-selected-network', !!v)
      }
    },
    computed: {
        ...mapState('app', {
            networks: state => state.networks,
        }),
        ...mapGetters('app', [
            'hasNetworks'
        ]),
        select_network() {
            return this.$t('transactions.select_network');
        },
    },
    created() {
      this.$emit('select-network-method', this.selectNetworks)
      this.setNetworksFromUrl()
    },
    methods: {
        ...mapActions('addressBook', [
            'getAddressBook',
        ]),
        setNetworksFromUrl() {
            const networksFromUrl = this.$route.query.network;

            if (!networksFromUrl) return

            this.selectedNetwork = this.networks.find(({id}) => id === networksFromUrl)
        },
        selectNetworks(nw) {
            this.selectedNetwork = nw

            const {
                $route,
                $router,
            } = this

            $router.replace({
              query: {
                search: $route.query.search,
                network: nw.id,
                page: 1
              }
            }).catch(() => {})
        },
    },
};

</script>

<style lang="scss" scoped>

.transactions-sort-by-network {

}
::v-deep {
  .advanced_balances__network-select {
    margin: 0 !important;
    width: 100%;
    .sublabel {
      display: none;
    }
  }
}

</style>
