import gql from 'graphql-tag';

export default gql`
    query($accountId: String) {
         getAllDeposits(accountId: $accountId) {
            type,
            amount,
            balance,
            date,
            orderId
        }
    }
`;