import {createApiKeys} from "./actions/createApiKeys";
import {removeApiKeys} from "./actions/removeApiKeys";
import {updateApiKeys} from "./actions/updateApiKeys";
import {fetchApiKeys} from "./actions/fetchApiKeys";
import {changeLangQuery} from "./actions/changeLangQuery";
import {fetchUser} from "./actions/fetchUser";
import {fetchToken} from "./actions/fetchToken";
import {createTokenAuth} from "./actions/createTokenAuth";
import {fetchApiRulesMap} from "@/public/store/modules/user/actions/fetchApiRulesMap";

export const actions = {
    createApiKeys,
    changeLangQuery,
    removeApiKeys,
    updateApiKeys,
    fetchApiKeys,
    fetchApiRulesMap,
    fetchUser,
    fetchToken,
    createTokenAuth,
};
