import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!) {
        getApiKeys(organizationId: $organizationId) {
            id
            alias
            public
            secret
            ipv4
            ipv6
            rules
            readOnly
        }
    }
`;
