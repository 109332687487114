import Store from "../../index";

export class invoiceController {
    static createInvoice({
        accountId,
        itemId,
        itemDescription,
        currencyId,
        amount,
        includeFee,
        insurancePercent,
        currencies,
        slippagePercent,
        additionalFees
    }) {
        return Store.dispatch('invoices/createInvoice', {
            accountId,
            itemId,
            itemDescription,
            currencyId,
            amount,
            includeFee,
            insurancePercent,
            currencies,
            slippagePercent,
            additionalFees
        });
    }
}
