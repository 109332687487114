import ioClient from "../constants/io-client";
import {EVENTS_TYPES} from "../../common/constants/events-types";
import {mapActions, mapState} from "vuex";
import {getCurrencyTicker} from "../filters";

export const ioMixin = {
    computed: {
        ...mapState('accounts', {
            accounts: state => state.accounts,
        }),
    },
    methods: {
        ...mapActions('accounts', [
            'changeAccountBalance',
        ]),
        ...mapActions('orders', [
            'updateOrder',
        ]),
        ...mapActions('balances', [
            'updateAccountBalances',
        ]),

        onAccountsCreate([accountId]) {
        },
        onAccountsChangeBalance([accountId, balance]) {
            this.changeAccountBalance({accountId, balance});
        },
        onAccountDeposit([accountId, amount]) {
            const account = this.accounts.find(a => a.accountId === accountId);

            if (!account) {
                return;
            }

            const ticker = getCurrencyTicker(account.currencyId);

            if (!ticker) {
                return;
            }

            const start = accountId.substr(0, 4);
            const end = accountId.substr(-6);

            this.$Notify.success({
                title: this.$t('advance_balances.Replenishment'),
                message: this.$t('advance_balances.account_replenished_successfully', {
                    start: start,
                    end: end,
                    amount: amount,
                    ticker: ticker,
                }),

            });
        },
        onAccountsBlock([accountId, reason]) {
            const start = accountId.substr(0, 4);
            const end = accountId.substr(-6);

            this.$Notify({
                title: `Аккаунт заблокирован`,
                message: `Аккаунт ${start}****${end} был заблокирован` + (reason ? ': ' + reason : ''),
                type: 'warning',
            });

        },
        onAccountsUnblock([accountId]) {
            const start = accountId.substr(0, 4);
            const end = accountId.substr(-6);

            this.$Notify({
                title: `Аккаунт разблокирован`,
                message: `Аккаунт ${start}****${end} был разблокирован`,
                type: 'success',
            });
        },

        onAddressesCreate([accountId, addressId]) {
        },
        onAddressesChangeBalance([accountId, addressId, balance]) {
            this.updateAccountBalances({accountId, addressId, balance});
        },

        onOrdersCreate([orderId]) {
        },
        onOrdersUpdate([orderId, status, amount, received, expiresAt]) {
            this.updateOrder({orderId, status, amount, received, expiresAt});
        },

        onWithdrawalUpdate([withdrawalId, status, tx]) {
        },

        subscribeOnEvents() {
            ioClient.on(EVENTS_TYPES.ACCOUNTS.CREATE, this.onAccountsCreate);
            ioClient.on(EVENTS_TYPES.ACCOUNTS.CHANGE_BALANCE, this.onAccountsChangeBalance);
            ioClient.on(EVENTS_TYPES.ACCOUNTS.DEPOSIT, this.onAccountDeposit);
            ioClient.on(EVENTS_TYPES.ACCOUNTS.BLOCK, this.onAccountsBlock);
            ioClient.on(EVENTS_TYPES.ACCOUNTS.UNBLOCK, this.onAccountsUnblock);

            ioClient.on(EVENTS_TYPES.ADDRESSES.CREATE, this.onAddressesCreate);
            ioClient.on(EVENTS_TYPES.ADDRESSES.CHANGE_BALANCE, this.onAddressesChangeBalance);

            ioClient.on(EVENTS_TYPES.ORDERS.CREATE, this.onOrdersCreate);
            ioClient.on(EVENTS_TYPES.ORDERS.UPDATE, this.onOrdersUpdate);

            ioClient.on(EVENTS_TYPES.WITHDRAWALS, this.onWithdrawalUpdate);
        },
    },
    mounted() {
        this.subscribeOnEvents();
    },
};
