<template>
<bh-select-new
    v-model="innerModel"
    :placeholder="$t('invoice.select-currencies')"
    class="grouping-select item"
    :list="this.filteredList"
    :label="''"
    with-search
    multiple
    clearable
    gap
>
  <template #search>
    <search
        id="search-input"
        class="search"
        v-model="search"
    />
  </template>
  <template #info="item">
    <div class="currency-network-option-item">
      <div class="currency-and-network-info column">
        <currency-network-icon :currency="item.currency.currency || item.currency" :network="item?.network?.name && withNetworks ? item?.network?.name.toUpperCase() : ''" />
        <div class="item__title">
          <span class="currency">
           {{ withNetworks ? item.currency.alias || item.alias : item.currency }}
          </span>
          <span v-if="withNetworks" class="network">
           {{ item.network?.alias }}
          </span>
        </div>
      </div>
    </div>
  </template>
  <template
      #selected
  >
    <div :class="['currency-network-option-item', { 'noNetworks': !withNetworks }]">
      <div class="currency-and-network-info">
        <currency-network-icon v-for="item in selectedList" :key="item.id" :currency="item.currency.currency || item.currency" :network="item?.network?.name && withNetworks ? item?.network?.name.toUpperCase() : ''" />
<!--        <div>-->
<!--                  <span class="currency">-->
<!--                {{ item.currency.alias }}-->
<!--              </span>-->
<!--          <span class="network">-->
<!--                {{ item.network.alias }}-->
<!--        </span>-->
<!--        </div>-->
        <span
            v-if="innerModel.length > 5"
            class="selected-quantity"
        >
                    +{{innerModel.length - 5}}
                </span>
      </div>
    </div>
  </template>
</bh-select-new>
</template>

<script>
  import BhSelectNew from '/public/components/elements/advance-balances/selects/Base'
  import {mapState} from "vuex";
  import NetworkAndCurrencyItem from "@/public/components/elements/orders/Select/Item.vue";
  import CurrencyImg from "@/public/components/elements/CurrencyImg.vue";
  import CurrencyNetworkIcon from "@/public/components/elements/business-walet/Select/CurrencyNetworkIcon.vue";

  export default {
    name: "WidgetsMultiCurrencySelect",
    components: {
      CurrencyNetworkIcon,
      CurrencyImg,
      NetworkAndCurrencyItem,
      BhSelectNew,
      Search: () => import(/* webpackChunkName: "public/components/common/uiKit/search" */ '/public/components/common/uiKit/search'),
    },
    props: {
      value:  {
        type: Array,
        default: () => ([])
      },
      list:  {
        type: Array,
        default: () => ([])
      },
      isFiatEnabled: {
        type: Boolean,
        default: false
      },
      withNetworks: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        search: ''
      }
    },
    mounted() {
      this.$emit('input', this.value.map(({ id, networkId, currencyId, currency, network }) => ({
        currency: currency.currency || currency,
        network: network?.name ? network.name : network,
        currencyId,
        networkId
      })))
    },
    computed: {
      ...mapState('app', ['currencies']),
      innerModel: {
        get({ value, parsedList }) {
          if (!this.withNetworks) {
            return value.map( arr => parsedList.find(item => item.currencyId === arr.currencyId))
          }
          return value.map( arr => parsedList.find(item => item.networkId === arr.networkId &&  item.currencyId === arr.currencyId))
        },
        set(v) {
          this.$emit('input', v.map(({ id, networkId, currencyId, currency, network }) => ({
            currency: currency.currency || currency,
            network: network?.name ? network.name : network,
            currencyId,
            networkId
          })))
        }
      },
      selectedList () {
        return this.innerModel.slice(0, 5)
      },
      filteredList({ parsedList, search }) {
        //метод фильтрации общего списка валют
        if (search) {
          if (!this.withNetworks) {
            return parsedList.filter((netCurr = {}) => {
              const currencyName = netCurr['currency'] ? netCurr['currency'].toLowerCase() : '';
              const currencyAlias = netCurr['label'] ? netCurr['label'].toLowerCase() : '';

              return currencyName.includes(search.toLowerCase()) || currencyAlias.includes(search.toLowerCase())
            })
          }
          return parsedList.filter((netCurr = {}) => {
            const currencyName = netCurr['currency'] ? netCurr['currency']['currency'].toLowerCase() : ''
            const currencyAlias = netCurr['currency'] ? netCurr['currency']['alias'].toLowerCase() : ''
            const networkName = ( netCurr['network'] && netCurr['network']?.name ) ? netCurr['network']['name'].toLowerCase() : ''
            const networkAlias = ( netCurr['network'] && netCurr['network']?.name ) ? netCurr['network']['alias'].toLowerCase() : ''

            return networkName.includes(search.toLowerCase()) || networkAlias.includes(search.toLowerCase()) || currencyName.includes(search.toLowerCase()) || currencyAlias.includes(search.toLowerCase())
          })
        } else {
          return parsedList
        }
      },
      parsedList ({ currencies, list }) {
        const allowedCurrencies = currencies.filter(({ allowInvoice }) => allowInvoice).sort((a, b) => a.orderIndex - b.orderIndex)
        const currenciesList = list.length ? allowedCurrencies.filter(item => list.find(listItem => listItem.currencyId === item.id)).map(item => {
          const networks =  item.networks.filter(networkItem => list.find(listItem => item.id === listItem.currencyId && listItem.networkId === networkItem.id) )
          return {
            ...item,
            networks
          }
        }) : allowedCurrencies
        if (!this.withNetworks) {
          return currenciesList.map(item => ({
            id: item.id || item.currencyId,
            currencyId: item.id || item.currencyId,
            currency: item.currency,
            network: {},
            networkId: '',
            label: `${item.currency}`,
          }) )
        }

        return currenciesList.reduce(( acc, curr ) => {
          const { allowInvoice, isFiat } = curr

          if (allowInvoice && this.isFiatEnabled && isFiat) {
            const currency = {
              id: `${curr.id}_fiat`,
              networkId: '',
              currencyId: curr.id,
              currency: curr,
              network: {},
              label: `${curr.currency}`,
            }

            acc.push(currency)
            return acc
          }

          if (curr.networks && allowInvoice) {
            curr.networks.forEach(network => {
              if (!network.allowDeposit) {
                return
              }
              const currencyWithNetwork = {
                id: `${curr.id}_${network.id}`,
                networkId: network.id,
                currencyId: curr.id,
                currency: curr,
                network: network,
                label: `${curr.currency} (${network.name.toUpperCase()})`,
              }

              acc.push(currencyWithNetwork)
            })
          }

          return acc
        }, [])
      },
    },

  }
</script>

<style lang="scss" scoped>
.selected-quantity {
  color: var(--new-front-primary-font-color);
  padding-left: 5px
}
.search {
  margin: 5px 0 15px 0;
}
.item__title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .network {
    color: var(--text-color-text-secondary);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }
  .currency {
    color: var(--text-text-primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    margin-bottom: 5px;
  }
}
::v-deep {
  .new-order-modal__row {
    width: 100%;
  }
  .placeholder {
    font-weight: 400;
  }
  .label {
    font-size: 13px;
  }
  .currency-network-icon-wrapper .currency-network-icon__currency {
    width: 32px;
    height: 32px;
  }
  .new-bh-select-wrapper .input-wrapper .options-list {
    @media screen and (max-width: 850px) {
      max-height: 300px;
    }
  }
  @media screen and (max-width: 576px) {
    .placeholder {
      font-size: 13px;
    }
    .label {
      font-size: 10px;
    }
    .currency {
      font-size: 13px;
    }
    .network {
      font-size: 13px;
    }
    .currency-network-icon-wrapper .currency-network-icon__network {
      width: 14px;
      height: 14px;
    }
    .currency-network-icon-wrapper .currency-network-icon__currency {
      width: 18px;
      height: 18px;
    }
  }
}
.grouping-select {
  width: 100%;
}
.currency-network-option-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 500;
  font-style: normal;

  min-height: 32px;
  margin-left: 15px;
  padding-top: 7px;

  &.noNetworks {
    padding-top: 0;
  }

  .currency-and-network-info {
    display: flex;
    align-items: center;

    color: #8F949A;

    gap: 15px;

    .currency {
      color: var(--new-front-text-primary-bg-secondary);
    }
    .network {
      color: var(--new-front-text-secondary-bg-secondary);
    }
  }
  .amount {
    display: flex;
    align-items: end;
    gap: 8px;

    .amount-label {
      font-weight: 400;
      color: var(--new-front-text-secondary-bg-secondary);
    }
    .amount-value {
      color: var(--new-front-text-primary-bg-secondary);
    }
  }

  @include below_880() {
    .column {
      width: 100%;
      &.amount {
        display: none;
      }
    }
  }

  @include below_phone(){
    .currency-left-block {
      .currency-info {
        width: 100%;
        padding: 0 15px;

        .name {}
      }

      .network-info {
        display: none;
        width: 60%;
      }
    }
    ::v-deep {
      .grouping-select {
        .label {
          font-size: 13px;
        }
      }
      .new-bh-select-wrapper .input-wrapper .input .placeholder {
        font-size: 13px;
      }
    }
  }
}
@include below_phone(){
  ::v-deep {
    .grouping-select {
      .label {
        font-size: 13px;
      }
    }
    .new-bh-select-wrapper .input-wrapper .input .placeholder {
      font-size: 13px;
    }
  }
}
.options-list-item {
  &:hover {
    .currency-network-option-item {
      .currency-info-left-block {
        .currency-info {
          .alias {
            color: var(--primary-font-color);
          }
        }
        .network-info {
          color: var(--primary-font-color);
        }
      }
    }
  }
}
</style>
