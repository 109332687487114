<template>
<div class="progress">
  <div class="progress-item progress-item ">
    <div :class="['progress-round', 'progress-round--permanent', step === 1 ? 'progress-round--active' : '']"></div>
    <div class="progress-label progress-label--active">{{ $t('authorization.registration-title') }}</div>
  </div>
  <div :class="['progress-line', step === 2 ? 'progress-line--active' : '']"></div>
  <div class="progress-item">
    <div :class="['progress-round', step === 2 ? 'progress-round--active' : '']"></div>
    <div :class="['progress-label', step === 2 ? 'progress-label--active' : '']">{{ $t('authorization.verification-email') }}</div>
  </div>
</div>
</template>

<script>
  export default {
    name: "ProgressRegistration",
    props: {
      step: {
        type: Number,
        default: 1
      }
    }
  }
</script>

<style lang="scss" scoped>
.progress {
  display: flex;
  align-items: center;
  margin-bottom: 65px;
  z-index: 2;
  &-round {
    position: relative;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: #5D566B;
    &--permanent {
      background: #744DB2;
    }
    &--active {
      background: #744DB2 !important;
      &:before {
        content: '';
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid #744DB2;
        position: absolute;
        left: -4px;
        top: -4px;
      }
    }
  }
  &-line {
    background: #49454F;
    border-radius: 92px;
    width: 135px;
    height: 2px;
    margin: 0 10px;
    z-index: 1;
    &--active {
      background: #744DB2;
    }
  }
  &-label {
    position: absolute;
    transform: translate(-35%, 10px);
    font-weight: 500;
    font-size: 14px;
    color: #5D566B;
    text-align: center;
    &--active {
      color: var(--new-front-primary-font-color);
    }
  }
  &-item {
    position: relative;
  }
}
@media (max-width: 556px) {
  .progress {
    margin-bottom: 30px;
    &-round {
      width: 12px;
      height: 12px;
      &--active {
        &::before {
          width: 16px;
          height: 16px;
          top: -2px;
          left: -2px;
        }
      }
    }
  }
}
</style>
