export const mutations = {
    requests: (state, requests) => {
        state.requests = requests;
    },
    totalRequestsCount: (state, count) => {
        state.totalRequestsCount = count;
    },
    filtersStatus: (state, status) => {
        state.filters.status = status;
    },
    setNetworkFees: (state, fees) => {
        state.fees = fees;
    },
    withdrawal: (state, data) => {
        state.withdrawalData = data;
    },
    request: (state, data) => {
        state.request = data;
    },

    clearFilters: (state) => {
        state.filters = {
            status: [],
        };
    },
};
