<template>
    <Example
        class="example"
        :hasResponse="hasResponse"
        :requestInfo="requestInfo"
    >
        <template #title>
            Получение ссылки на оплату ордера (create-new-order)
        </template>
        <template #settings>
            <div class="example__row">
                <div class="example__col">
                    <ExampleSetting title="Валюта (currency)">
                        <select
                            class="example__select"
                            :class="currencyStatus"
                            v-model="currency"
                        >
                            <option value="">
                                Выберите
                            </option>
                            <option
                                v-for="(currency, key) in currencies"
                                :key="key"
                                :value="currency.currency"
                            >
                                {{ currency.currency }}
                            </option>
                        </select>
                    </ExampleSetting>
                </div>
                <div class="example__col">
                    <ExampleSetting title="Сумма (amount)">
                        <AtInput
                            size="small"
                            type="number"
                            :status="amountStatus"
                            v-model="amount"
                        />
                    </ExampleSetting>
                </div>
                <div class="example__col">
                    <ExampleSetting title="Действителен до (availableTill)">
                        <DatePicker
                            class="example__date"
                            v-model="availableTill"
                            type="datetime"
                            valueType="timestamp"
                            :showSecond="false"
                            placeholder="Укажите время"
                        />
                    </ExampleSetting>
                </div>
            </div>
            <div class="example__row">
                <div class="example__col">
                    <ExampleSetting title="Error webhook (errorWebhook)">
                        <AtInput
                            size="small"
                            :status="errorWebhookStatus"
                            v-model="errorWebhook"
                        />
                    </ExampleSetting>
                </div>
                <div class="example__col">
                    <ExampleSetting title="Success webhook (successWebhook)">
                        <AtInput
                            size="small"
                            :status="successWebhookStatus"
                            v-model="successWebhook"
                        />
                    </ExampleSetting>
                </div>
                <div class="example__col">
                    <ExampleSetting title="Return webhook (returnWebhook)">
                        <AtInput
                            size="small"
                            v-model="returnWebhook"
                        />
                    </ExampleSetting>
                </div>
            </div>
            <div class="example__actions">
                <AtButton
                    type="primary"
                    size="large"
                    @click="makeRequest"
                >
                    Сделать запрос
                </AtButton>
                <AtButton
                    v-if="hasResponse"
                     size="large"
                     @click="clear"
                >
                    Очистить
                </AtButton>
            </div>
        </template>
        <template #response>{{ response }}</template>
    </Example>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex';
import Moment from "moment";

import { exampleMixin } from "/public/mixins/example";

import Example from './Example';
import ExampleSetting from "./ExampleSetting";
import AtInput from 'at-ui/src/components/input';
import DatePicker from 'vue2-datepicker';
import AtButton from 'at-ui/src/components/button';

export default {
    name: 'CreateNewOrder',
    components: {
        Example,
        ExampleSetting,
        DatePicker,
        AtInput,
        AtButton,
    },
    mixins: [exampleMixin],
    data () {
        return {
            currency: null,
            amount: null,
            availableTill: null,
            errorWebhook: null,
            successWebhook: null,
            returnWebhook: null,
            response: null,
        };
    },
    computed: {
        ...mapState('app', [
            'currencies',
        ]),
        ...mapGetters('user', [
            'hasTestApiKeys',
        ]),
        hasResponse () {
            return Boolean(this.response);
        },
        currencyStatus () {
            return !this.currency ? 'error' : '';
        },
        amountStatus () {
            return !this.amount ? 'error' : '';
        },
        errorWebhookStatus () {
            return /*!this.errorWebhook ? 'error' : */'';
        },
        successWebhookStatus () {
            return /*!this.successWebhook ? 'error' : */'';
        },
    },
    methods: {
        ...mapActions('app', [
            'apiRequest',
        ]),
        makeRequest () {
            const {
                hasTestApiKeys,
                currency,
                amount,
                availableTill,
                errorWebhook,
                successWebhook,
                returnWebhook,
                apiRequest,
                setRequestInfo,
            } = this;

            if (!hasTestApiKeys) {
                return false;
            }

            apiRequest({
                method: 'make-order',
                data: {
                    currency,
                    amount,
                    availableTill: availableTill ? Moment(availableTill).format() : null,
                    errorWebhook,
                    successWebhook,
                    returnWebhook,
                },
            })
                .then(resp => {
                    const {
                        data: {
                            response,
                        },
                    } = resp;

                    this.response = response;
                    setRequestInfo(resp);
                })
                .catch(error => {
                    this.response = error.response.data;
                    setRequestInfo(error.response);
                });
        },
        clear () {
            this.currency = null;
            this.amount = null;
            this.availableTill = null;
            this.errorWebhook = null;
            this.successWebhook = null;
            this.returnWebhook = null;
            this.response = null;
        },
    },
};

</script>
