<template>
    <div :class="['history-address__list', listAddress ? '' : 'history-address__list__list_empty']">
        <TransactionsListHeader @setSort="emitSort" :sortValue="sortValue" class="transactions__list-header"/>
        <template v-if="listAddress.length">
            <PreviewTransaction
                v-for="(transaction, key) in mappedList"
                :key="key"
                routeName="historyAddress"
                :data="transaction"
            />
        </template>
        <div v-else class="empty">
            {{ $t('orphan.empty') }}
        </div>
    </div>
</template>

<script>
import TransactionsListHeader from "./TransactionsListHeader";
import PreviewTransaction from "./PreviewTransaction";
import {mapActions, mapState} from "vuex";

export default {
    name: "HistoryAddress",
    props: {
        sortValue: {
            type: Boolean,
            default: false
        }
    },
    components: {
        TransactionsListHeader,
        PreviewTransaction,
    },
    computed: {
        ...mapState('historyAddresses', ['listAddress']),
        ...mapState('addressBook', {
          addressBook: state => state.listCurrenciesAddress,
        }),
      mappedList () {
          return this.listAddress.map((item) => ({
            ...item,
            addressBookAlias: this.addressBook.find(el => item.addressFrom === el.address)?.alias || '',
            addressToBookAlias: this.addressBook.find(el => item.addressTo === el.address)?.alias || ''
          }))
      }
    },
    methods: {
      emitSort (val) {
          this.$emit('setSort', val)
      }
    }
};
</script>

<style scoped lang="scss">
.history-address {

  &__list {
    overflow-x: scroll;
    &_empty {
      padding-bottom: 0;
    }
  }
}
.empty {
    font-weight: 500;
    padding: 30px;
    font-size: 24px;
    text-align: center;
    margin-top: 20px;
    color: var(--new-front-text-primary-bg-modal-primary);
}
</style>
