<script >
    import {defineComponent} from 'vue'
    import Copy from "@/public/components/common/copy.vue";
    import moment from "moment";
    import {mapState} from "vuex";
    import {getPrettyAddress} from "@/public/filters";

    export default {
        name: "MobileAuthorizationHistoryTableItem",
        components: {Copy},
        props: {
            item: {
                type: Object,
                default: () => ({})
            }
        },
        computed: {
            ...mapState('app', ['networks', 'currencies']),
            id () {
                if (!this.item?.id) return ''
                return getPrettyAddress(this.item?.id)
            },
            moment () {
                return moment
            }
        },
        methods: {
            getPrettyUserAgent (txt) {
                if (txt.length <= 15) {
                    return txt
                }
                return `${txt.substr(0, 30)}...`
            }
        }
    }
</script>

<template>
<div class="item">
<div class="item__left">
    <div class="item__title">{{ item.connectingIp }} <copy  :value="item.connectingIp"/> </div>
    <div class="item__sub">{{ moment(item.createdAt).format('DD/MM/YY HH:mm') }}</div>
</div>
    <div class="item__right">
        <div class="item__title">{{ getPrettyUserAgent(item.userAgent) }} <copy v-if="item.userAgent"  :value="item.userAgent" /> </div>
    </div>
</div>
</template>

<style scoped lang="scss">
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid var(--new-front-divider-color);
    width: 100%;
  &__left {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  &__title {
    font-size: $h6;
    color: var(--new-front-primary-font-color);
    font-weight: 500;
      display: flex;
      align-items: center;
  }
  &__sub {
    font-size: $h6;
    color: var(--new-front-secondary-font-color);
    font-weight: 500;
  }
}
</style>
