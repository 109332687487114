import gql from 'graphql-tag';

export default gql`
    mutation($file: Upload!, $title: String!) {
        uploadRegistry(file: $file, title: $title) {
            code
            result {
                registry {
                    id
                    title
                    status
                    createdAt
                    updatedAt
                }
                items {
                    id
                    currencyId
                    networkId
                    address
                    tag
                    amount
                }
                errors {
                    line
                    code
                    msg
                }
            }
        }
    }
`;