<template>
  <div class="base-search-wrapper">
    <input
        id="search-input"
        class="search-input"
        :placeholder="placeholder || $t('advance_balances.currency-and-network-search')"
        v-model="search"
    />
    <svg
        class="search-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
    >
      <path
          d="M11.7422 10.3439C12.5329 9.2673 13 7.9382 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13C7.93858 13 9.26801 12.5327 10.3448 11.7415L10.3439 11.7422C10.3734 11.7822 10.4062 11.8204 10.4424 11.8566L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L11.8566 10.4424C11.8204 10.4062 11.7822 10.3734 11.7422 10.3439ZM12 6.5C12 9.53757 9.53757 12 6.5 12C3.46243 12 1 9.53757 1 6.5C1 3.46243 3.46243 1 6.5 1C9.53757 1 12 3.46243 12 6.5Z"
          :fill="themeStatus === 'dark' ? '#7E7E7E' : '#4D4D4D'"
      />
    </svg>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { debounce, cloneDeep } from "lodash"

export default {
  name: "NetworkAndCurrencySearch",
  data() {
    return {
      search: null
    }
  },
  props: {
    value: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    },
    clearWhenDestroyed: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value(v) {
      this.search = v
    },
    search() {
      this.debounceOnSearch()
    }
  },
  computed: {
    ...mapState('app', {
      themeStatus: state => state.theme
    }),
    debounceOnSearch: ({ emitSearch }) => debounce(emitSearch, 200),
  },
  mounted() {
    this.search = cloneDeep(this.value)
  },
  methods: {
    emitSearch() {
      this.$emit('input', this.search)
    }
  },
  destroyed() {
    // TODO этот код надо убирать так как он по ссылке может перетирать серч который нужно хранить например в фидьрах
    // найти где это используется в мостах и переделать там а потом убрать этот код
    if (this.clearWhenDestroyed) {
      this.$emit('input', undefined)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-search-wrapper {
  position: relative;

  .search-input {
    width: 100%;
    background-color: var(--dashboard-secondary-bg-color);
    border: 1px solid var(--new-front-input-border);
    border-radius: 10px;
    font-weight: 500;
    font-size: $h4;

    color: var(--new-front-text-primary-bg-primary);

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    height: 48px;
    padding: 0 40px 0 20px;

    &:focus, textarea:focus, select:focus {
      outline: none !important;
    }

    &::placeholder {
      pointer-events: none;
      font-weight: 500;
      color: var(--new-front-secondary-font-color);
      opacity: 1;
    }
  }
  .search-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  @include below_phone() {
    .search-input {
      font-size: $h6;
    }
  }
}
</style>
