<script>

  export default {
    name: "WidgetsManagePreviewBg"
  }
</script>

<template>
<svg width="699" height="630" viewBox="0 0 699 721" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_f_27957_70406)">
    <circle cx="349" cy="347" r="65" fill="url(#paint0_linear_27957_70406)"/>
  </g>
  <circle opacity="0.14" cx="349" cy="371" r="199.5" stroke="#7E7E7E"/>
  <circle opacity="0.12" cx="349" cy="371" r="274.5" stroke="#7E7E7E"/>
  <circle opacity="0.1" cx="349" cy="371" r="349.5" stroke="#7E7E7E"/>
  <g opacity="0.3">
    <g clip-path="url(#clip0_27957_70406)">
      <rect x="608" y="149.271" width="34" height="34" rx="17" transform="rotate(-17.583 608 149.271)" fill="#8247E5"/>
      <g clip-path="url(#clip1_27957_70406)">
        <rect x="609.777" y="150.193" width="31.1667" height="31.1667" rx="8.5" transform="rotate(-17.583 609.777 150.193)" fill="#8247E5"/>
        <g clip-path="url(#clip2_27957_70406)">
          <path d="M633.978 155.837C633.518 155.752 633.011 155.912 632.628 156.265L630.025 158.993L628.235 160.771L625.688 163.481C625.361 163.815 624.854 163.976 624.338 163.909L621.486 163.313C621.026 163.228 620.612 162.898 620.462 162.426L619.632 159.806C619.499 159.387 619.591 158.896 619.958 158.492L621.963 156.415C622.29 156.08 622.797 155.92 623.313 155.987L626.165 156.583C626.625 156.667 627.039 156.997 627.189 157.469L627.737 159.198L629.51 157.368L628.945 155.586C628.812 155.167 628.454 154.819 627.921 154.7L622.639 153.663C622.179 153.578 621.672 153.739 621.288 154.091L617.471 158.069C617.088 158.421 616.995 158.911 617.128 159.331L618.689 164.257C618.822 164.676 619.18 165.024 619.713 165.144L625.052 166.162C625.512 166.247 626.019 166.087 626.402 165.734L628.966 163.077L630.739 161.246L633.302 158.588C633.63 158.254 634.136 158.093 634.653 158.16L637.487 158.704C637.948 158.789 638.362 159.119 638.511 159.59L639.342 162.211C639.475 162.63 639.382 163.12 639.015 163.525L637.067 165.584C636.74 165.919 636.233 166.079 635.717 166.012L632.882 165.469C632.421 165.384 632.007 165.054 631.858 164.582L631.326 162.905L629.553 164.736L630.101 166.465C630.234 166.884 630.592 167.232 631.125 167.352L636.464 168.371C636.924 168.455 637.431 168.295 637.814 167.943L641.592 164.035C641.919 163.701 642.068 163.192 641.918 162.721L640.34 157.742C640.208 157.323 639.85 156.975 639.316 156.855L633.978 155.837Z" fill="white"/>
        </g>
      </g>
    </g>
    <rect x="607.111" y="148.81" width="35.4167" height="35.4167" rx="17.7083" transform="rotate(-17.583 607.111 148.81)" stroke="#8247E5" stroke-opacity="0.5" stroke-width="1.41667"/>
  </g>
  <g opacity="0.3">
    <rect x="502.602" y="241" width="42" height="42" rx="21" transform="rotate(13.2118 502.602 241)" fill="#F3BA2B"/>
    <rect x="501.95" y="239.948" width="43.75" height="43.75" rx="21.875" transform="rotate(13.2118 501.95 239.948)" stroke="#F3BA2B" stroke-opacity="0.5" stroke-width="1.75"/>
    <path d="M514.062 263.253L519.781 259.709L523.327 265.431L526.655 263.368L521.047 254.318L511.999 259.925L514.062 263.253ZM506.321 263.443L509.649 261.381L511.712 264.709L508.384 266.771L506.321 263.443ZM513.168 267.059L516.713 272.779L522.435 269.232L524.499 272.559L524.497 272.56L515.447 278.169L509.84 269.122L509.837 269.117L513.168 267.059ZM524.782 267.779L528.11 265.716L530.173 269.044L526.845 271.107L524.782 267.779Z" fill="white"/>
    <path d="M520.984 266.884L520.985 266.884L518.892 263.507L516.397 265.054L516.397 265.054L516.11 265.232L515.519 265.598L515.514 265.601L515.517 265.606L517.608 268.98L520.985 266.887L520.986 266.886L520.984 266.884Z" fill="white"/>
  </g>
  <g opacity="0.3">
    <rect x="453.957" y="521" width="26" height="26" rx="13" transform="rotate(24.9243 453.957 521)" fill="#0AC18E"/>
    <rect x="453.695" y="520.282" width="27.0806" height="27.0806" rx="13.5403" transform="rotate(24.9243 453.695 520.282)" stroke="#0AC18E" stroke-opacity="0.5" stroke-width="1.08056"/>
    <path d="M464.853 536.398C464.88 535.052 463.859 534.288 462.366 533.875L462.723 531.942L461.547 531.726L461.185 533.65C460.877 533.592 460.566 533.519 460.248 533.468L460.605 531.554L459.43 531.337L459.073 533.27C458.816 533.23 456.698 532.835 456.698 532.835L456.464 534.093C456.464 534.093 457.336 534.235 457.32 534.249C457.8 534.336 457.902 534.644 457.903 534.889L456.928 540.188C456.88 540.332 456.746 540.554 456.416 540.497C456.429 540.515 455.561 540.339 455.561 540.339L455.066 541.703C455.066 541.703 457.165 542.094 457.44 542.147L457.079 544.102L458.254 544.318L458.621 542.351C458.941 542.418 459.254 542.479 459.561 542.534L459.196 544.49L460.372 544.707L460.732 542.754C462.531 543.112 464.147 542.623 464.638 540.955C464.925 539.946 464.256 538.697 463.444 538.214C464.185 537.993 464.766 537.437 464.853 536.398ZM462.435 540.279C461.927 541.921 459.692 541.113 458.897 540.97L459.354 538.373C460.152 538.517 462.714 538.755 462.435 540.279ZM462.604 536.532C462.188 538.008 460.278 537.314 459.616 537.191L460.028 534.833C460.69 534.963 462.745 535.052 462.604 536.532Z" fill="white"/>
  </g>
  <g opacity="0.3">
    <rect x="591" y="430.342" width="42" height="42" rx="21" transform="rotate(-8.68544 591 430.342)" fill="#345D9D"/>
    <rect x="590.019" y="429.622" width="43.7209" height="43.7209" rx="21.8605" transform="rotate(-8.68544 590.019 429.622)" stroke="#345D9D" stroke-opacity="0.35" stroke-width="1.72091"/>
    <path d="M617.247 463.1C625.625 461.82 631.38 453.991 630.1 445.612C628.82 437.234 620.99 431.479 612.612 432.759C604.233 434.039 598.479 441.869 599.759 450.247C601.039 458.626 608.868 464.38 617.247 463.1Z" fill="white"/>
    <path d="M612.328 430.918C608.964 431.432 605.827 432.932 603.315 435.229C600.803 437.526 599.028 440.516 598.216 443.821C597.403 447.126 597.589 450.598 598.749 453.798C599.91 456.997 601.993 459.781 604.735 461.797C607.478 463.813 610.757 464.97 614.157 465.122C617.557 465.274 620.926 464.415 623.838 462.652C626.75 460.89 629.074 458.304 630.515 455.221C631.957 452.137 632.453 448.696 631.939 445.331C631.605 443.103 630.835 440.962 629.674 439.031C628.513 437.1 626.983 435.416 625.171 434.076C623.359 432.737 621.301 431.767 619.115 431.222C616.928 430.678 614.656 430.569 612.427 430.903L612.328 430.918ZM615.303 448.463L614.445 454.706L623.918 453.259C623.981 453.247 624.045 453.248 624.107 453.261C624.169 453.274 624.228 453.299 624.281 453.335C624.333 453.37 624.378 453.416 624.413 453.469C624.448 453.523 624.471 453.582 624.483 453.645L624.507 453.801L624.117 456.769C624.102 456.909 624.039 457.039 623.939 457.139C623.839 457.238 623.709 457.301 623.569 457.317L609.07 459.531L610.235 450.881L607.642 452.12L607.971 450.131L610.564 448.892L612.208 436.754C612.225 436.614 612.289 436.485 612.388 436.385C612.487 436.286 612.617 436.223 612.757 436.206L616.423 435.646C616.485 435.634 616.549 435.635 616.611 435.648C616.673 435.661 616.732 435.686 616.785 435.722C616.837 435.758 616.882 435.804 616.917 435.857C616.952 435.91 616.976 435.97 616.987 436.032L617.011 436.189L615.625 446.432L618.218 445.193L617.944 447.259L615.303 448.463Z" fill="#345D9D"/>
  </g>
  <g opacity="0.3">
    <rect x="149" y="279.585" width="32" height="32" rx="16" transform="rotate(-1.04705 149 279.585)" fill="#F7931A"/>
    <rect x="148.321" y="278.931" width="33.3333" height="33.3333" rx="16.6667" transform="rotate(-1.04705 148.321 278.931)" stroke="#F7931A" stroke-opacity="0.5" stroke-width="1.33333"/>
    <path d="M171.878 293.08C172.17 290.832 170.454 289.658 168.122 288.897L168.818 285.847L166.975 285.42L166.298 288.39C165.813 288.277 165.316 288.172 164.821 288.068L165.504 285.079L163.662 284.652L162.965 287.701C162.565 287.617 162.171 287.533 161.789 287.445L161.791 287.435L159.249 286.845L158.797 288.828C158.797 288.828 160.164 289.117 160.136 289.137C160.882 289.31 161.025 289.803 161.01 290.196L160.217 293.671C160.269 293.683 160.336 293.701 160.409 293.729C160.348 293.715 160.282 293.699 160.215 293.684L159.103 298.551C159.017 298.78 158.792 299.126 158.271 299.006C158.289 299.032 156.931 298.695 156.931 298.695L156.059 300.827L158.457 301.383C158.903 301.487 159.341 301.596 159.772 301.699L159.068 304.784L160.909 305.211L161.606 302.159C162.109 302.286 162.597 302.404 163.075 302.514L162.381 305.552L164.224 305.979L164.927 302.9C168.067 303.439 170.415 303.156 171.353 300.287C172.109 297.977 171.247 296.669 169.586 295.836C170.779 295.536 171.668 294.725 171.879 293.08L171.878 293.079L171.878 293.08ZM167.794 299.077C167.269 301.382 163.41 300.212 162.161 299.924L163.094 295.837C164.343 296.127 168.343 296.673 167.794 299.077L167.794 299.077ZM168.253 293.113C167.774 295.209 164.561 294.207 163.521 293.966L164.367 290.26C165.407 290.501 168.752 290.925 168.253 293.113L168.253 293.113Z" fill="white"/>
  </g>
  <g opacity="0.3">
    <rect x="65" y="112.083" width="44" height="44" rx="22" transform="rotate(-18.6671 65 112.083)" fill="#53AE94"/>
    <rect x="63.8397" y="111.509" width="45.8308" height="45.8308" rx="22.9154" transform="rotate(-18.6671 63.8397 111.509)" stroke="#53AE94" stroke-opacity="0.5" stroke-width="1.83085"/>
    <path d="M94.4145 122.96L93.5428 120.38L99.4433 118.386L98.1151 114.455L82.0477 119.883L83.376 123.815L89.2772 121.821L90.1482 124.399C85.4267 126.24 82.1414 128.408 82.5259 129.546C82.9103 130.684 86.839 130.415 91.7082 129.016L94.5005 137.282L98.7674 135.84L95.9749 127.574C100.688 125.736 103.966 123.571 103.582 122.434C103.198 121.297 99.2781 121.564 94.4161 122.96M95.7384 126.874L95.7377 126.872C95.6199 126.92 95.0139 127.166 93.6367 127.632C92.5356 128.004 91.7504 128.234 91.4726 128.312L91.4737 128.316C87.1732 129.56 83.7612 129.89 83.4629 129.007C83.1646 128.124 86.0788 126.319 90.252 124.7L91.2254 127.581C91.5094 127.506 92.3189 127.285 93.4144 126.915C94.7303 126.47 95.3733 126.192 95.491 126.14L94.5176 123.258C98.8101 122.018 102.215 121.691 102.512 122.57C102.809 123.45 99.9023 125.256 95.7385 126.872" fill="white"/>
  </g>
  <g opacity="0.3">
    <rect x="356" y="2" width="37" height="37" rx="18.5" fill="#0098EA"/>
    <rect x="355.262" y="1.26246" width="38.4751" height="38.4751" rx="19.2375" stroke="#0098EA" stroke-opacity="0.35" stroke-width="1.47508"/>
    <path d="M381.376 10.9058H367.972C365.507 10.9058 363.945 13.5643 365.185 15.7134L373.457 30.0521C373.997 30.9883 375.35 30.9883 375.89 30.0521L384.164 15.7134C385.402 13.5677 383.84 10.9058 381.377 10.9058H381.376ZM373.451 25.7521L371.649 22.2653L367.302 14.4904C367.015 13.9928 367.369 13.3551 367.97 13.3551H373.449V25.7538L373.451 25.7521ZM382.042 14.4887L377.697 22.267L375.895 25.7521V13.3534H381.374C381.975 13.3534 382.329 13.9911 382.042 14.4887Z" fill="white"/>
  </g>
  <g opacity="0.3">
    <rect x="47" y="427.754" width="54" height="54" rx="27" transform="rotate(-15.8557 47 427.754)" fill="#627EEA"/>
    <rect x="45.6104" y="426.979" width="56.25" height="56.25" rx="28.125" transform="rotate(-15.8557 45.6104 426.979)" stroke="#627EEA" stroke-opacity="0.5" stroke-width="2.25"/>
    <path d="M76.4141 429.925L79.822 441.924L91.2507 443.575L76.4141 429.925Z" fill="white" fill-opacity="0.602"/>
    <path d="M76.4163 429.925L70.9685 449.336L79.8243 441.924L76.4163 429.925Z" fill="white"/>
    <path d="M83.3166 454.23L85.6323 462.383L91.7928 445.461L83.3166 454.23Z" fill="white" fill-opacity="0.602"/>
    <path d="M85.6345 462.383L83.3185 454.229L71.5039 451.224L85.6345 462.383Z" fill="white"/>
    <path d="M82.783 452.344L91.2521 443.575L79.8242 441.926L82.783 452.344Z" fill="white" fill-opacity="0.2"/>
    <path d="M70.9699 449.336L82.7853 452.343L79.8265 441.926L70.9699 449.336Z" fill="white" fill-opacity="0.602"/>
  </g>
  <defs>
    <filter id="filter0_f_27957_70406" x="84" y="82" width="530" height="530" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_27957_70406"/>
    </filter>
    <linearGradient id="paint0_linear_27957_70406" x1="284" y1="216.011" x2="385.578" y2="418.92" gradientUnits="userSpaceOnUse">
      <stop stop-color="#744DB2"/>
      <stop offset="0.515" stop-color="#8D4CF5"/>
      <stop offset="1" stop-color="#744DB2"/>
    </linearGradient>
    <clipPath id="clip0_27957_70406">
      <rect x="608" y="149.271" width="34" height="34" rx="17" transform="rotate(-17.583 608 149.271)" fill="white"/>
    </clipPath>
    <clipPath id="clip1_27957_70406">
      <rect x="609.777" y="150.193" width="31.1667" height="31.1667" rx="8.5" transform="rotate(-17.583 609.777 150.193)" fill="white"/>
    </clipPath>
    <clipPath id="clip2_27957_70406">
      <rect width="22.6667" height="18.4167" fill="white" transform="translate(615.969 155.661) rotate(-17.583)"/>
    </clipPath>
  </defs>
</svg>

</template>

<style scoped lang="scss">

</style>
