<template>
  <StoreModal
      class="create-withdrawal-request-modal"
      name="PayoutModalDeposit"
      @closeModal="handleCancelCreate"

  >
      <template #header>
          <div class="create-withdrawal-request-modal__header">
              <div class="title">
                  {{ title }}
              </div>
              <p v-if="notCreated" class="create-withdrawal-request-modal__subtitle">{{ $t('payout_modal_deposit.subtitle') }}</p>
          </div>
      </template>
      <template #body >
        <div v-if="isOpen && !depositLoading">
          <template v-if="notCreated">
            <template v-if="alertMessage.message">
              <AtAlert :type="alertMessage.type" :message="alertMessage.message"/>
              <br/>
            </template>
            <div class="create-withdrawal-request-modal__row">
              <div class="create-withdrawal-request-modal__currency-input-container-wrap">
                <p class="create-withdrawal-request-modal__input-label">{{  }}</p>
                <div class="create-withdrawal-request-modal__currency-input-container">
                    <base-select v-model="currencySelectedModel" :list="flatCurrencies" :placeholder="$t('payout_modal_deposit.placeholder')" :label="$t('payout_modal_deposit.label')" gap uniq-key="networkId">
                        <template #selected="{id, currency, alias, network}">
                            <div class="create-withdrawal-request-modal__currency-item" v-if="id">
                                <div class="create-withdrawal-request-modal__currency-info">
                                    <CurrencyImg class="create-withdrawal-request-modal__currency-input-selected-image"
                                                 :currency="id"/>
                                    <p class="create-withdrawal-request-modal__currency-text">{{ id | getCurrencyAliasById() }}</p>
                                    <p class="create-withdrawal-request-modal__currency-text secondary-text">{{ network.id | getNetworkSystemName | getNetworkShortNameByNetworkName }}</p>
                                    <!--                                    <div class="create-withdrawal-request-modal__currency-text-container">-->
<!--                                        <p class="create-withdrawal-request-modal__currency-text-title">{{-->
<!--                                            currency-->
<!--                                            }}</p>-->
<!--                                        <p class="create-withdrawal-request-modal__currency-text-subtitle">{{-->
<!--                                            alias-->
<!--                                            }}</p>-->
<!--                                    </div>-->
                                </div>
                            </div>
                        </template>
                        <template #info="{id, currency, alias, network}">
                            <div class="create-withdrawal-request-modal__currency-item" v-if="id">
                                <div class="create-withdrawal-request-modal__currency-info">
                                    <CurrencyImg class="create-withdrawal-request-modal__currency-input-selected-image"
                                                 :currency="id"/>
<!--                                    <div class="create-withdrawal-request-modal__currency-text-container">-->
<!--                                        <p class="create-withdrawal-request-modal__currency-text-title">{{-->
<!--                                            currency-->
<!--                                            }}</p>-->
<!--                                        <p class="create-withdrawal-request-modal__currency-text-subtitle">{{-->
<!--                                            alias-->
<!--                                            }}</p>-->
<!--                                    </div>-->
                                    <p class="create-withdrawal-request-modal__currency-text">{{ id | getCurrencyAliasById() }}</p>
                                    <p class="create-withdrawal-request-modal__currency-text secondary-text">{{ network.id | getNetworkSystemName | getNetworkShortNameByNetworkName }}</p>
                                </div>
                            </div>
                        </template>
                    </base-select>
                    <p class="create-withdrawal-request-modal__details">{{ $t('payout_modal_deposit.details') }}</p>
<!--                  <AtSelect v-model="currencySelectedModel" :placeholder="$t('payout_modal_deposit.placeholder')"-->
<!--                            class="create-withdrawal-request-modal__input-select" v-if="currencies.length > 0">-->
<!--                    <AtOption :value="`${currenciesItem.id}_${currenciesItem.network.id}`"-->
<!--                              v-for="currenciesItem in flatCurrencies"-->
<!--                              :key="currenciesItem.id + currenciesItem.network.id">-->


<!--                    </AtOption>-->
<!--                  </AtSelect>-->
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="create-withdrawal-request-modal__row">
              <div class="qr-code__container">
                <QrCode class="create-withdrawal-request-modal__qr" :string="depositResultAddress" size="medium" :bordered="false" />
                  <div class="create-withdrawal-request-modal__currency-container">
                      <CurrencyImg
                        class="create-withdrawal-request-modal__currency-img"
                        :currency="depositResultCurrencyId"
                      />
                      <div class="create-withdrawal-request-modal__currency-text-container">
                          <p class="create-withdrawal-request-modal__title">
                              {{
                              depositResultCurrencyId | getCurrencyTicker
                              }}
                          </p>
                          <p class="create-withdrawal-request-modal__text-secondary">
                              &ensp;- {{ depositResultNetworkId | getNetworkSystemName | getNetworkShortNameByNetworkName(false) }}
                          </p>
                      </div>
                  </div>
              </div>
              <div class="create-withdrawal-request-modal__title-content">
                  <p class="create-withdrawal-request-modal__text-main ">
                      {{ $t('payout_modal_deposit.address_for_replenishment') }}
                  </p>
                  <div class="addressCopy">
                      <CopyString
                              class="create-withdrawal-request-modal__copy-desktop"
                              :string="depositResultAddress"
                              :all=true
                              :successMessage="addresses_copied"
                              :failMessage="addresses_not_copied"
                              :title="notification"
                              canCopy
                      />
                      <CopyString
                              class="create-withdrawal-request-modal__copy-mobile"
                              :string="depositResultAddress"
                              :successMessage="addresses_copied"
                              :failMessage="addresses_not_copied"
                              :title="notification"
                              canCopy
                      />
                  </div>
              </div>
              <p class="create-withdrawal-request-modal__details">{{ $t('payout_modal_deposit.details') }}</p>
            </div>
            <div class="create-withdrawal-request-modal__row">
              <div class="row-label">{{ $t('payout_modal_deposit.alias') }}:</div>
              <div class="row-value">
                <base-input v-model="alias" :placeholder="$t('payout_modal_deposit.enter_an_alias')" />
              </div>
            </div>
            <div class="create-withdrawal-request-modal__row">
              <div class="row-label">{{ $t('payout_modal_deposit.comment') }}:</div>
              <div class="row-value">
                <base-input v-model="comment" type="textarea" :placeholder="$t('payout_modal_deposit.enter_a_comment')" />
              </div>
            </div>
          </template>
        </div>
        <new-spinner v-if="depositLoading" />
      </template>
    <template #footer>
      <div v-if="!depositLoading" class="create-withdrawal-request-modal__footer-container">
        <div class="create-withdrawal-request-modal__button-container">
          <base-button
            type="secondary"
            :label="$t('advance_balances.cancel')"
            @click="handleCancelCreate"
          />
          <base-button
            v-if="notCreated"
            type="primary"
            :label="$t('advance_balances.get_an_address')"
            @click="handleCreateRequest"
            :disabled="actionDisabled"
          />
          <base-button
            v-else
            type="primary"
            :label="$t('payout_modal_deposit.save')"
            @click="handleUpdateAddress"
            :disabled="actionDisabled"
          />
        </div>
      </div>
    </template>
  </StoreModal>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';

import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';
import AtButton from 'at-ui/src/components/button';
import BaseModal from "/public/components/modals/BaseModal";
import timer from '/public/components/elements/Timer';
import CopyString from '/public/components/elements/CopyString';
import {getCurrencyAliasById, getCurrencyTicker, getErrorMessage} from "../../../filters";
import CurrencyImg from "../../elements/CurrencyImg";
import BaseButton from "@/public/components/common/uiKit/button/base/bg-secondary/index.vue";
import BaseSelect from "@/public/components/common/uiKit/selects/base/index.vue";
import BaseInput from "@/public/components/common/uiKit/input/base/index.vue";
import setUseForAutoSwap from "@/public/graphQl/mutations/setUseForAutoSwap";

export default  {
    name: 'PayoutModalDeposit',
    components: {
      BaseInput,
      BaseSelect,
      BaseButton,
      CurrencyImg,
      timer,
      BaseModal,
      AtSelect,
      AtOption,
      AtButton,
      CopyString,
      QrCode: () => import(/* webpackChunkName: "/public/components/common/uiKit/qr-code" */ '/public/components/common/uiKit/qr-code'),
      StoreModal: () => import(/* webpackChunkName: "/public/components/modals/StoreModal" */ '/public/components/modals/StoreModal'),
      NewSpinner: () => import (/* webpackChunkName: "Spinner" */ "/public/components/common/uiKit/spinner/index.vue"),
    },
    props: {
        currencyBalanceId: {
            type: String,
            default: '7ae2af85-bf07-4b36-a59f-2a07bf9ef152',
        },
    },

    data() {
        return {
            isLoading: false,
            isSuccess: false,
            network: '',
            currencyId: '',
            currencySelectedModel: undefined,
            loadingMessage: null,
            alias: null,
            comment: null
        };
    },
    computed: {
        selectedFlatCurrency() {
            if (this.currencySelectedModel) {
                const currencyModel = this.currencySelectedModel?.id.split('_');
                const currencyId = this.currencySelectedModel.id
                const networkId  = this.currencySelectedModel.network.id
                return this.flatCurrencies.find((flatCurrency) => currencyId === flatCurrency.id && networkId === flatCurrency.network.id);
            }
            return null;
        },
        ...mapGetters('app', [
            'sortedCurrencies',
            'isOpenModal',
        ]),
        ...mapState('app', {
            currencies: state => state.currencies,
            payload: state => state.modal.payload,
        }),
        ...mapState('accounts', {
            accounts: state => state.accounts,

            // depositLoading: state => state.deposit.loading,
            // depositCreated: state => state.deposit.created,
            // depositCode: state => state.deposit.code,
            // depositResultAddress: state => state.deposit.result.address,
            // depositResultTag: state => state.deposit.result.tag,
            // depositResultUntil: state => state.deposit.result.until,
        }),
        ...mapState('payoutBalances', {
            depositLoading: state => state.deposit.pending,
            depositCreated: state => state.deposit.complete,
            depositCode: state => state.deposit.code,
            depositResultAddressId: state => state.deposit.result.id,
            depositResultAddress: state => state.deposit.result.address,
            depositResultTag: state => state.deposit.result.tag,
            depositResultNetworkId: state => state.deposit.result.networkId,
            depositResultCurrencyId: state => state.deposit.result.currencyId,

        }),

        notCreated() {
            return !this.successCreated;
        },
        successCreated() {
            return !!(this.depositCreated && this.depositCode === 0 && this.depositResultAddress);
        },
        failCreated() {
            return !!(this.depositCreated && this.depositCode !== 0);
        },

        depositPayload() {
            return {
                address: this.depositResultAddress,
                tag: this.depositResultTag,
                until: this.depositResultUntil,
            };
        },
        alertMessage() {
            if (!this.isOpen) {
                return {
                    type: '',
                    message: '',
                };
            }

            // if (!this.currency) {
            //     return {
            //         type: 'error',
            //         message: this.$t('advance_balances.unknown'),
            //     };
            // }
            //
            // if (!this.networks.length) {
            //     return {
            //         type: 'warning',
            //         message: this.$t('advance_balances.payments'),
            //     };
            // }
            //
            // if (!this.network) {
            //     return {
            //         type: 'info',
            //         message: this.$t('advance_balances.need_to_select'),
            //     };
            // }

            return {
                type: '',
                message: '',
            };
        },
        accountId() {
            return this.payload?.accountId;
        },
        account() {
            return this.accounts
                .find(account => account.accountId === this.accountId);
        },
        currency() {
            return this.currencies
                .find(currency => currency.id === this.account?.currencyId);
        },
        // networks() {
        //     if (!this.currenciesStablecoins) {
        //         return [];
        //     }
        //
        //     const networks = this.currenciesStablecoins()
        //         .filter(network => network.allowDeposit);
        //
        //     if (!networks) {
        //         return [];
        //     }
        //
        //     networks.sort((a, b) => a.alias > b.alias ? 1 : -1);
        //
        //     return networks;
        // },
        networks() {
            if (!this.currenciesStablecoins) {
                return [];
            }

            const currency = this.currencies
                .find(currency => currency.id === this.currencyId);

            if (!currency) {
                return [];
            }

            return currency.networks
                .filter(n => n.allowDeposit);
        },
        selectDisabled() {
            return !!this.depositLoading;
        },
        actionDisabled({ notCreated, alias, comment }) {
            return notCreated ? !this.currencySelectedModel : ( !alias && !comment );
        },

        isOpen() {
            const {
                $options: {name},
                isOpenModal,
            } = this;

            return isOpenModal(name);
        },
        currenciesStablecoins() {
            return this.account?.availableCurrenciesForDeposit || [];
        },
        currencyBalanceName() {
            return getCurrencyTicker(this.currencyBalanceId);
        },
        title() {
            return this.$t('payout_modal_deposit.title');
        },
        select_network() {
            return this.$t('advance_balances.select_network');
        },
        select_currency() {
            return this.$t('advance_balances.select_currency');
        },
        addresses_copied() {
            return this.$t('copy.addresses_copied');
        },
        addresses_not_copied() {
            return this.$t('copy.addresses_not_copied');
        },
        notification() {
            return this.$t('copy.notification');
        },
        flatCurrencies() {
            let currenciesFlat = [];
            for (const currency of this.currencies) {
                for (const currencyNetwork of currency.networks) {

                    currenciesFlat.push({
                        ...currency,
                        network: currencyNetwork,
                        networkId: currencyNetwork.id,
                    });
                }
            }
            currenciesFlat = currenciesFlat.filter((c) => c.allowPayout && c.network.allowDeposit);
            return currenciesFlat;
        },
    },
    watch: {
        isOpen(n, o) {
            if (n === o) {
                return;
            }

            if (!this.depositLoading) {
                this.clearAllData();
            }

            this.onModalOpen();
        },

        depositLoading(n, o) {
            if (n === o) {
                return;
            }

            n ? this.showLoader() : this.closeLoader();
        },
        depositCreated(n) {
            if (!n) {
                return;
            }

            this.closeLoader();
        },

        depositCode(n, o) {
            if (n === o) {
                return;
            }

            if (n === 0) {
                return;
            }

            this.$Message.error({
                message: this.$t(getErrorMessage(n)),
                duration: 10000,
            });

            this.$nextTick(() => {
                this.clearDeposit();
            });
        },

        currencyId() {
            this.$nextTick(() => {
                if (this.networks.length) {
                    this.network = this.networks[0].name;
                }
            });
        },
    },
    methods: {
        getCurrencyAliasById,
        ...mapActions('accounts', [
            'accountDepositAddress',
            "clearDeposit",
        ]),
        ...mapActions('payoutBalances', ['getPayoutAddress', 'fetchPayoutBalances', 'clearDeposit', 'updatePayoutAddressInfo']),
        ...mapActions('app', [
            'closeModal',
        ]),
        showLoader() {
            if (!this.isOpen) {
                return;
            }
            if (this.loadingMessage) {
                return;
            }

            this.loadingMessage = this.$Message.loading({
                message: this.$t('advance_balances.creating_an_address'),
                duration: 0,
            });
        },
        closeLoader() {
            if (!this.loadingMessage) {
                return;
            }

            this.loadingMessage();
        },

        clearAllData() {
            this.currencyId = '';
            this.network = '';
            this.clearDeposit();
        },
        handleCreateRequest() {
            const currencyModel = this.currencySelectedModel?.id.split('_');
            const currencyId = this.currencySelectedModel.id
            const networkId  = this.currencySelectedModel.network.id
            if (currencyId && networkId) {
                this.getPayoutAddress({
                    networkId: networkId,
                    currencyId: currencyId,
                });
            } else {
                return
            }

        },
        async handleCancelCreate() {
            this.currencySelectedModel = undefined
            this.alias = null
            this.comment = null

            await this.fetchPayoutBalances()
            this.closeModal()
        },
        async handleUpdateAddress() {
          const { alias, comment, depositResultAddressId: addressId } = this

          const variables = {
            addressId,
            alias,
            comment
          }

          const updatePayoutAddressInfoResponse = await this.updatePayoutAddressInfo({
            ...variables
          })

          if (updatePayoutAddressInfoResponse.result) {
            await this.handleCancelCreate()
          }
        },

        onModalOpen() {
            if (this.payload?.currencyId && this.payload?.networkId) {
                const currency = this.flatCurrencies.find((i) => i.id === this.payload.currencyId && i.network.id === this.payload.networkId);
                this.currencySelectedModel = currency.id + '_' + currency.network.id;
            }
            if (this.currenciesStablecoins.length) {
                this.$nextTick(() => {
                    this.currencyId = this.currenciesStablecoins[0]
                });
            }
            if (this.networks.length) {
                this.$nextTick(() => {
                    this.net = this.networks[0].name
                });
            }
        },
    },
};

</script>

<style lang="scss" scoped>
.addressCopy {
  width: 100%;
  padding: 15px 15px 15px 20px;
  border-radius: 10px;
  border: 1px solid var(--new-front-input-border);
  &::v-deep {
    .copy-string {
      .copy-string__string {
        color: #7E7E7E;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
      }
      justify-content: space-between;
    }
  }
}
.currency-img {
  width: 20px;
  height: 20px;

  &_preview {
    position: absolute;
    z-index: 2;
    top: 11px;
    width: 24px;
    height: 24px;
    left: 30px;
  }
}

.create-withdrawal-request-modal {
  &__header {
    text-align: center;

    .title {
      font-size: $h1;
      font-weight: 500;
      color: var(--new-front-primary-font-color);
    }
    .subtitle {
      font-size: $h4;
      color: var(--secondary-font-color);
    }
    @media screen and (max-width: 575px) {
      .title {
        font-size: $h3;
      }
      .subtitle {
        font-size: $h6;
      }
    }
  }
  &__subtitle {
    font-family: $Gilroy;
    line-height: var(--line-height);
    font-size: var(--font-size-text-main);
    color: var(--new-front-secondary-font-color);
    font-weight: 500;
    margin-top: 20px;
    @media screen and (max-width: 575px) {
      margin-top: 10px;
      font-size: $h6;
    }
  }

  &__input-label {
    font-family: $Gilroy;
    line-height: var(--line-height);
    font-size: var(--font-size-text-main);
    color: var(--new-front-primary-font-color);
    margin-bottom: 8px;
  }

  &__footer-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    margin-bottom: 20px;
  }

  &__details {
    font-family: $Gilroy;
    line-height: var(--line-height);
    font-size: $h6;
    font-weight: 400;
    color: var(--new-front-secondary-font-color);
    text-align: left;
    //margin-top: 5px;
  }

  &__button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
  }

  &__currency {
    &-img {
      width: 34px;
      height: 34px;
      z-index: 2;
    }

    &-input-email {
      ::v-deep .at-input__original {
        padding-left: 32px;
        border-radius: 12px;
        font-family: $Gilroy;
        font-size: var(--font-size-h3);
        line-height: var(--line-height);
        color: var(--new-front-primary-font-color);
        height: 45px;
        border: 1px solid #F2F2F2;
        box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
      }
    }

    &-input-comment {
      ::v-deep .at-textarea__original {
        padding-left: 32px;
        border-radius: 12px;
        padding-top: 10px;
        font-family: $Gilroy;
        font-size: var(--font-size-h3);
        line-height: var(--line-height);
        color: var(--new-front-primary-font-color);
        border: 1px solid #F2F2F2;
        box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
        min-height: 115px !important;
      }
    }

    &-amount {
      &-container {
        width: 100%;
        max-width: 672px;
        display: flex;
        flex-direction: column;

        ::v-deep .at-input__original {
          padding-left: 32px;
          border-radius: 12px;
          font-family: $Gilroy;
          font-size: var(--font-size-h3);
          line-height: var(--line-height);
          color: var(--new-front-primary-font-color);
          height: 64px;
        }
      }

      &-usd {
        padding-left: 32px;
        font-family: $Gilroy;
        font-size: var(--font-size-text-main);
        line-height: var(--line-height);
        color: var(--white-100);
      }
    }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 3px;
      &:first-of-type .create-withdrawal-request-modal__check-input {
        border-top: var(--border-table);
      }
    }

    &-input-selected {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      height: 100%;
      width: 100%;
      //background: var(--secondary-color);
      border-radius: 12px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      padding-left: 32px;

      ::v-deep .at-select__selected {
        color: transparent;
        display: none;
      }

      &-image {
        height: 22px;
        width: 22px;
      }

      &-currency {
        font-family: $Gilroy;
        font-size: var(--font-size-h3);
        font-weight: 500;
        line-height: var(--line-height);
        color: var(--new-front-primary-font-color);

        &_network {
          font-weight: 400;
          font-size: 20px;
          line-height: 23px;
          text-align: center;
          max-width: 190px;
          color: var(--new-front-primary-font-color);
        }
      }

      &-container {
        display: flex;
        align-items: center;
        gap: 15px;
      }
    }

    &-input-container {
      width: 100%;
      max-width: 672px;
      position: relative;
      //background: var(--secondary-color);
      border-radius: 12px;
      margin-bottom: 20px;

      &-wrap {
        width: 100%;
        max-width: 672px;
        display: flex;
        flex-direction: column;
        //margin-top: 45px;
      }
    }


    &-item {
    }

    &-info {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &-title {
      font-family: $Gilroy;
      font-size: var(--font-size-h3);
      line-height: var(--line-height);
      color: var(--new-front-primary-font-color);
    }

    &-select-all {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    &-text-container {
      display: flex;
      //flex-direction: column;
    }

    &-text {
      z-index: 2;
      font-family: $Gilroy;
      font-size: $h4;
      line-height: var(--line-height);
      color: var(--new-front-primary-font-color);
    }

    &-text-title {
      font-family: $Gilroy;
      font-size: $h4;
      line-height: var(--line-height);
      font-weight: 500;
      z-index: 2;
      color: var(--new-front-primary-font-color);
    }

    &-text-subtitle {
      font-family: $Gilroy;
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: var(--new-front-primary-font-color);
    }

  }


  &__text-main {
    font-family: $Gilroy;
    font-size: $h6;
    font-weight: 500;
    color: var(--new-front-secondary-font-color);
    margin-bottom: 5px;
  }

  &__text-secondary {
    font-family: $Gilroy;
    line-height: var(--line-height);
    color: var(--new-front-secondary-font-color);
    font-size: $h4;
    font-style: normal;
    font-weight: 500;
  }

  .qr-code__container {
    position: relative;
    width: fit-content;
    align-self: center;
    flex-direction: column;
    border-radius: 30px;
    border: 1px solid var(--new-front-input-border);
    margin-top: -10px;
    margin-bottom: 20px;
    overflow: hidden;
  }

  &__qr {
    width: 160px;
    height: 160px;
  }

  .border-square {
    position: absolute;
    background-image: linear-gradient(#2C405A, #2C405A);
  }

  .border-square_left-top {
    top: -20px;
    left: -20px;
    height: 2px;
    width: 23px;
  }

  .border-square_left-top-horizontal {
    top: -20px;
    left: -20px;
    height: 23px;
    width: 2px;
  }

  .border-square_right-top {
    top: -20px;
    right: -20px;
    height: 2px;
    width: 23px;
  }

  .border-square_right-top-horizontal {
    top: -20px;
    right: -20px;
    height: 23px;
    width: 2px;
  }

  .border-square_left-bottom {
    bottom: -20px;
    left: -20px;
    height: 2px;
    width: 23px;
  }

  .border-square_left-bottom-horizontal {
    bottom: -20px;
    left: -20px;
    height: 23px;
    width: 2px;
  }

  .border-square_right-bottom {
    bottom: -20px;
    right: -20px;
    height: 2px;
    width: 23px;
  }

  .border-square_right-bottom-horizontal {
    bottom: -20px;
    right: -20px;
    height: 23px;
    width: 2px;
  }

  .qr-code {
    width: 190px;
    height: 190px;
  }


  &__input-select {
    background: transparent;
    z-index: 2;

    ::v-deep .at-select__selected {
      display: none;
    }

    ::v-deep .icon {
      font-size: 24px;
    }

    ::v-deep .at-select__arrow {
      font-size: 24px;
    }

    ::v-deep .at-select__selection {
      min-height: 47px;
      border-radius: 12px;
      padding-left: 32px;
      background: transparent;
    }

    ::v-deep .at-select__placeholder {
      font-family: $Gilroy;
      line-height: var(--line-height);
      font-size: var(--font-size-h3);
      color: #8F949A;
    }
  }


  &__select-container {
    position: relative;
    width: 100%;
  }

  &__select-container_img ::v-deep .at-select__selection {
    padding-left: 68px;
  }

  &__row {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 10px 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .row-label {
      width: 100%;
      font-weight: 400;
      font-size: $h6;
      color: var(--new-front-secondary-font-color);
      margin-bottom: 5px;
    }
  }

  &__qr-row {
    display: flex;

    align-items: center;
    justify-content: center;
  }

  &__title {
    font-family: $Gilroy;
    font-size: $h4;
    line-height: var(--line-height);
    font-weight: 500;
    color: var(--new-front-primary-font-color);
  }

  &__title-content {
    text-align: right;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__currency {
    display: flex;
    align-items: center;
  }


  &-input-container {
    width: 100%;
    max-width: 672px;
    position: relative;
    //background: var(--secondary-color);
    border-radius: 12px;
    margin-bottom: 20px;

    &-wrap {
      width: 100%;
      max-width: 672px;
      display: flex;
      flex-direction: column;
      margin-top: 45px;
    }
  }


  &-container {
    position: absolute;
    bottom: 110px;
    //background: var(--secondary-color);
    padding: 20px 40px;
    height: 100%;
    max-height: 290px;
    width: 100%;
    max-width: 778px;
    border-radius: 12px;
    overflow: auto;
    box-shadow: 0px 8px 50px rgba(10, 17, 105, 0.08);
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 65px;
    }


    &-title {
      display: flex;
      align-items: center;
      gap: 15px;
      z-index: 2;
    }
  }


  &__withdrawal-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__full-width-btn {
    width: 100%;
  }

  &__table-header {
    margin-left: 0;
    margin-right: 0;
    padding: 4px 12px;
    background-color: $gray;
    border: 1px solid $gray2;
  }

  &__add-address {
    margin-top: 12px;
  }

  &__currency-img {
    margin-right: 10px;
    width: 22px;
    height: 22px;
  }

  &__currency-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--new-front-input-border);
    padding: 10px 20px;
  }

  &__timer-container {
    display: flex;
    margin-top: 50px;
    align-items: center;
    flex-direction: column;
  }

  &__copy {
    &-desktop {
      display: block;
    }

    &-mobile {
      display: none;
    }
  }
}

@media screen and (max-width: 600px) {
  .create-withdrawal-request-modal {
    &__copy {
      &-desktop {
        display: none;
      }

      &-mobile {
        display: block;
      }
    }


    &__title {
      margin-bottom: 10px;
    }
  }
}
.secondary-text {
  color: var(--new-front-secondary-font-color)
}
::v-deep {
  .options-list {
    max-height: 330px !important;
    .list {
      max-height: 300px !important;
    }
  }
  .base-select-wrapper {
    .label {
      @media screen and (max-width: 600px) {
        font-size: $h6;
      }
    }
  }
}
</style>
