import gql from 'graphql-tag';

export default gql`
    mutation updateOrganizationRole($organizationId: String!, $organizationRoleId: String!  $name: String, $comment: String, $rules: String) {
        updateOrganizationRole(organizationId: $organizationId, organizationRoleId: $organizationRoleId,  name: $name, comment: $comment, rules: $rules) {
            code
            result
        }
    }
`;
