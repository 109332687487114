<template>
  <AtButton
      class="refresh-button"
      size="small"
      :icon="refreshIcon"
      :loading="isLoading"
      @click="$emit('update')"
  />
</template>

<script>
import AtButton from "at-ui/src/components/button";

export default {
    name: "btn-refresh",
    components: {
        AtButton,
    },
    props: {
        icon: {
            type: String,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },

    },
    computed: {
        refreshIcon() {
            return this.isLoading ? '' : this.icon;
        },
    },
};
</script>

<style lang="scss" scoped>
.refresh-button {

  height: 46px;
  width: 60px;
  background: var(--refresh-btn-bg);
  border-radius: 12px;
  border: 1px solid var(--refresh-btn-border);
  transition: all 0.3s ease-in;

  &:hover {
    border: 1px solid var(--blue-400);
  }

  ::v-deep .at-btn__icon {
    color: #0059F7;
    font-size: 18px;
    line-height: 18px;
  }
}
</style>