<template>
  <StoreModal
          name="NewOrderModal"
      class="new-order-modal"
      :title="title"
      @closeModal="handleCancelCreate"
  >
      <template #header>
          <div class="new-order-modal__header">
              <div class="title">
                  {{ title }}
              </div>
          </div>
      </template>
      <template #body>
          <div class="new-order-modal__content">
              <template v-if="!isLoading">
                  <template v-if="!hasOrder">
                      <div class="new-order-modal__row ">
                          <div>
                              <base-input
                                      v-model="merchantOrder"
                                      :maxlength="255"
                                      :label="$t('create-order-modal.order')"
                                      :placeholder="order_placeholder"
                                      size="normal"
                              />
                          </div>
                      </div>
                      <div class="new-order-modal__row">
                          <div>
                              <base-textarea
                                      v-model="merchantDescription"
                                      :placeholder="description_of_order"
                                      :label="$t('create-order-modal.description')"
                                      :maxlength="255"
                                      size="large"
                                      resize="none"
                              />
                          </div>
                      </div>
                      <div class="new-order-modal__currency-container">
                          <div class="new-order-modal__row ">
                              <div class="new-order-modal__select-container new-order-modal__select-container_img">
                                  <business-wallet-network-select
                                          v-model="currencyFrom"
                                          :label="$t('create-order-modal.currency')"
                                          :list="currenciesForFrom"
                                          uniq-key="id"
                                  />
                              </div>
                          </div>

                          <div class="new-order-modal__row" v-if="networks.length" key="network-selector">
<!--                              <div class="new-order-modal__title">-->
<!--                                  {{  }}-->
<!--                              </div>-->
                              <div class="new-order-modal__select-container">
<!--                                  <AtSelect-->
<!--                                          size="large"-->
<!--                                          v-model="networkFrom"-->
<!--                                  >-->
<!--                                      <AtOption-->
<!--                                              v-for="(network) in networks"-->
<!--                                              :key="network.name"-->
<!--                                              :value="network.name"-->
<!--                                      >-->
<!--                                          {{ network.alias }}-->
<!--                                      </AtOption>-->
<!--                                  </AtSelect>-->
                                  <business-wallet-network-select v-model="networkFrom" :list="networks" uniq-key="id" :label="$t('create-order-modal.network')" />
                              </div>
                          </div>
                      </div>
                      <!--               адрес контракта-->
<!--                      <div v-if="contract" class="new-order-modal__row">-->
<!--                          <div class="new-order-modal__title">-->
<!--                              {{ $t('checkout.contract_address') }}-->
<!--                          </div>-->
<!--                          <span class="new-order-modal link" @click="viewContract">-->
<!--                        {{ contractSubstr }}-->
<!--                    </span>-->
<!--                      </div>-->

                      <div class="new-order-modal__row new-order-modal__row_amount">
                              <base-max-input
                                      v-model="amount"
                                      :min="0"
                                      size="normal"
                                      type="number"
                              >
                                  <template #label-left>{{$t('create-order-modal.amount')}}</template>
                              </base-max-input>
                      </div>

                      <div class="new-order-modal__row">
                          <div class="new-order-modal__title-container">
                              <p class="new-order-modal__title new-order-modal__title_slider">
                                  {{
                                  $t('orders.lifetime')
                                  }}
                                  <span class="new-order-modal__title">
            ({{
                                      +minValue.toFixed(2) === 0.5 ? $t('orders.lifetimeMinValueMinute') : $tc('orders.lifetimeMinValueHour', minValue, {n: minValue})
                                      }}

          </span>
                                  <span class="new-order-modal__title">
            {{
                                      $tc('orders.lifetimeMaxValueHour', maxValue, {n: maxValue})
                                      }})

          </span>
                              </p>
                          </div>
                          <date-picker
                                  popup-class="new-order-modal__time-picker-popup"
                                  class="new-order-modal__time-picker"
                                  v-model="lifetime"
                                  :append-to-body="false"
                                  :minute-step="30"
                                  :hour-options="lifetimeRange"
                                  type="time"
                                  value-type="HH:mm"
                                  format="HH:mm"
                                  placeholder="HH:mm"
                                  :showSecond="false"
                                  :disabled-time="minMaxValue"
                          >
                              <template v-slot:header>
                                  <div class="new-order-modal__label-container">
                                      <span class="new-order-modal__time-label">{{ $t('create-order-modal.hours') }}</span>
                                      <span class="new-order-modal__time-label new-order-modal__time-label_divider"></span>
                                      <span class="new-order-modal__time-label">{{ $t('create-order-modal.minutes') }}</span>
                                  </div>
                              </template>
                          </date-picker>
                      </div>
                      <div class="new-order-modal__divider">
                      </div>
                      <div :class="['new-order-modal__row', 'new-order-modal__advanced', isAdvancedOpened ? 'new-order-modal__advanced--opened' : '']">
                          <div :class="['new-order-modal__row', 'new-order-modal__advanced-title', isAdvancedOpened ? 'new-order-modal__advanced-title--opened' : '' ]" @click="toggleAdvancedOptions">
                              <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.228 1.635C0.085 1.462 0 1.241 0 1C0 0.448 0.448 0 1 0H7C7.552 0 8 0.448 8 1C8 1.241 7.915 1.462 7.772 1.635L4.808 5.589C4.626 5.838 4.332 6 4 6C3.668 6 3.374 5.838 3.192 5.589L0.228 1.635Z" fill="var(--new-front-primary-font-color)"/>
                              </svg>
                              <span>{{ $t('create-order-modal.advanced-options') }}</span>
                          </div>
                          <div class="new-order-modal__row">
                              <div>
                                  <base-input
                                          :status="errorWebhookStatus"
                                          v-model="errorWebhook"
                                          :placeholder="$t('create-order-modal.enter-webhook')"
                                          :label="$t('create-order-modal.error-webhook')"
                                  />
                              </div>
                              <span v-if="errorWebhookStatus" class="new-order-modal__error-text">{{
                                  $t('create-order-modal.invalid-url')
                                  }}</span>
                          </div>
                          <div class="new-order-modal__row">
                              <div>
                                  <base-input
                                          :status="successWebhookStatus"
                                          v-model="successWebhook"
                                          :placeholder="$t('create-order-modal.enter-webhook')"
                                          :label="$t('create-order-modal.success-webhook')"
                                  />
                              </div>
                              <span v-if="successWebhookStatus"
                                    class="new-order-modal__error-text">{{ $t('create-order-modal.invalid-url') }}</span>
                          </div>
                          <div class="new-order-modal__row">
                              <div>
                                  <base-input
                                          :status="returnUrlStatus"
                                          :placeholder="$t('create-order-modal.enter-url')"
                                          v-model="returnUrl"
                                          label="Return URL"
                                          :label="$t('create-order-modal.error-url')"
                                  />
                              </div>
                              <span v-if="returnUrlStatus" class="new-order-modal__error-text">{{
                                  $t('create-order-modal.invalid-url')
                                  }}</span>
                          </div>
                      </div>
                      <div
                              v-if="hasErrors"
                              class="new-order-modal__row"
                      >
                          <AtAlert
                                  v-for="(error, key) in errors"
                                  :key="key"
                                  type="error"
                                  :message="error"
                          />
                      </div>
                  </template>
                  <PreviewOrderInModal
                          v-else
                          class="new-order-modal__preview"
                          :data="order"
                          :order="merchantOrder"
                  />
              </template>
              <LoadingSpinner
                      v-else
                      class="new-order-modal__spinner"
                      :title="creating_order"
              />
          </div>
      </template>

    <template #footer v-if="!isLoading">
      <div v-if="!hasOrder" class="new-order-modal__footer">
        <base-button type="secondary" :label="$t('create-order-modal.close')" @click="handleCancelCreate" />
        <base-button
            type="primary"
            :disabled="actionButtonDisabled || !lifetime"
            :label="$t('create-order-modal.create')"
            @click="handleCreateOrder"
        />
      </div>
      <base-button
          v-else
          class="new-order-modal__full-width-btn"
          type="primary"
          :label="$t('create-order-modal.close')"
          @click="handleCancelCreate"
      />
    </template>


  </StoreModal>
</template>

<script>

    import 'vue2-datepicker/index.css';

    import Moment from "moment";
    import moment from "moment";

    // Moment.locale('ru');
    import {mapActions, mapGetters, mapState} from 'vuex';

    import AtModal from 'at-ui/src/components/modal';
    import AtSelect from 'at-ui/src/components/select';
    import AtOption from 'at-ui/src/components/option';
    import AtInput from 'at-ui/src/components/input';
    import Icon from "/public/components/elements/Icon";
    import AtButton from 'at-ui/src/components/button';
    import DatePicker from 'vue2-datepicker';
    import LoadingSpinner from "/public/components/common/uiKit/spinner/index.vue";
    import PreviewOrderInModal from "/public/components/elements/orders/PreviewOrderInModal";
    import AtAlert from 'at-ui/src/components/alert';
    import isURL from 'validator/lib/isURL';
    import BaseModal from "../BaseModal";
    import {OrderController} from "../../../store/modules/orders/controller";
    import {getErrorMessage} from "../../../filters";
    import {BlockchainLinks} from "../../../mixins/blockchain-links";
    import CurrencyImg from "../../elements/CurrencyImg";
    import BigNumber from "bignumber.js";
    import _ from "lodash";
    import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
    import BaseInput from "@/public/components/common/uiKit/input/base/index.vue";
    import BaseTextarea from "@/public/components/common/uiKit/textarea/index.vue";
    import BusinessWalletNetworkSelect
        from "@/public/components/elements/business-walet/Select/businessWalletNetworkSelect.vue";

    export default {
    name: 'NewOrderModal',
    mixins: [
        BlockchainLinks,
    ],
    components: {
        BusinessWalletNetworkSelect,
        BaseTextarea,
        BaseInput,
        BaseButton,
        CurrencyImg,
        BaseModal,
        AtModal,
        AtSelect,
        AtOption,
        AtInput,
        Icon,
        DatePicker,
        AtButton,
        LoadingSpinner,
        PreviewOrderInModal,
        AtAlert,
        BaseMaxInput: () => import(/* webpackChunkName: "/public/components/elements/advance-balances/input" */ '/public/components/elements/advance-balances/input'),
        StoreModal: () => import(/* webpackChunkName: "/public/components/modals/StoreModal" */ '/public/components/modals/StoreModal'),
    },
    data() {
        return {
            isLoading: false,
            order: null,
            currencyFrom: null,
            networkFrom: null,
            amount: null,
            expiresAt: Moment().add(1, 'days').valueOf(),
            link: '',
            errorWebhook: null,
            successWebhook: null,
            returnUrl: null,
            merchantOrder: null,
            merchantDescription: null,
            errors: [],
            lifetime: null,
            isAdvancedOpened: false,
        };
    },
    computed: {
        ...mapState('app', [
            'currencies',
        ]),
        ...mapState('orders', [
            'ordersCountOnPage',
        ]),
        ...mapState('accounts', {
            accounts: state => state.accounts,
        }),
        ...mapGetters('app', [
            'sortedCurrencies',
            'isOpenModal',
        ]),
        selectedNetworkObject() {
            const {networks, networkFrom} = this;
            if (!networkFrom) return
            return networks.find((network) => network.name === networkFrom.name);
        },
        lifetimeRange() {
            const {step, minValue, maxValue} = this;
            const upperLimit = maxValue.plus(step);
          // minValue.integerValue(BigNumber.ROUND_DOWN).toNumber()
            const lowerlimit = 0;
            return _.range(lowerlimit, +upperLimit, 1);
        },
        minValue() {
            const {selectedNetworkObject} = this;
            // const minValue = new BigNumber(selectedNetworkObject?.minFreezeTime ?? 0).div(3600);
            return 0;
        },
        maxValue() {
            const {selectedNetworkObject} = this;
            const maxValue = new BigNumber(selectedNetworkObject?.maxFreezeTime ?? 0).div(3600);
            return maxValue ?? 0;
        },
        step() {
            const {minValue} = this;
            // const step = minValue.isGreaterThan(0.5) ? 1 : minValue.toFixed(2);
            return 1;
        },
        percentage() {
            const {minValue, maxValue, lifetime} = this;
            const percentage = (lifetime - minValue) / (maxValue - minValue) * 100;
            return percentage;
        },
        isOpen() {

            const {
                $options: {
                    name,
                },
                isOpenModal,
            } = this;

            return isOpenModal(name);
        },
        hideFooter() {
            const {
                isLoading,
                order,
            } = this;

            return isLoading || order;
        },
        hasOrder() {
            return Boolean(this.order);
        },

        accountId() {
            return this.accounts?.[0]?.accountId;
        },
        title() {
            const {hasOrder, order} = this;
            return hasOrder ? `${this.$t(`create-order-modal.order`)} ${order.id}` : this.$t('create-order-modal.new_order');
        },
        currenciesForFrom() {
            return this.sortedCurrencies.filter(currency => currency.allowDeposit);
        },
        networks() {
            if (!this.currenciesForFrom.length || !this.currencyFrom) {
                return [];
            }

            const currency = this.currenciesForFrom
                .find(currency => currency.currency === this.currencyFrom.currency);

            if (!currency) {
                return [];
            }

            return currency.networks
                .filter(n => n.allowDeposit);
        },
        amountStatus() {
            const {amount} = this;
            if (amount === null) {
                return '';
            }
            return (amount <= 0) ? 'error' : '';
        },
        errorWebhookStatus() {
            return !!this.errorWebhook && !this.checkUrl(this.errorWebhook) ? 'error' : '';
        },
        successWebhookStatus() {
            return !!this.successWebhook && !this.checkUrl(this.successWebhook) ? 'error' : '';
        },
        returnUrlStatus() {
            return !!this.returnUrl && !this.checkUrl(this.returnUrl) ? 'error' : '';
        },
        hasErrors() {
            return Boolean(this.errors.length);
        },
        actionButtonDisabled() {
            return !this.currencyFrom || !this.networkFrom;
        },
        order_placeholder() {
            return this.$t('create-order-modal.order_placeholder');
        },
        description_of_order() {
            return this.$t('create-order-modal.description_of_order');
        },
        creating_order() {
            return this.$t('create-order-modal.creating_order');
        },
        contract() {
            if (!this.currencies) return '';
            if (!this.currencyFrom) return '';
            if (!this.networkFrom) return '';

            let contract = this.currencies.find(e => e.currency === this.currencyFrom.currency).networks?.find(e => e.name === this.networkFrom.name)?.contract;

            if (!contract) {
                return '';
            }
            return contract;

            // return this.currencies.find(e => e.currency === this.currencyFrom).networks?.find(e => e.name === this.networkFrom)?.contract?
            //     this.currencies.find(e => e.currency === this.currencyFrom).networks?.find(e => e.name === this.networkFrom)?.contract:
            //     '';
        },
        contractSubstr() {
            const {
                contract,
            } = this;
            // const startPart = contract.substr(0, 6);
            // const endPart = contract.substr(-6);
            //
            // let source;
            //
            // if (this.networkFrom === 'bsc') {
            //     source = 'BSCScan';
            // } else if (this.networkFrom === 'fantom') {
            //     source = 'FTMScan';
            // } else if (this.networkFrom === 'ethereum') {
            //     source = 'EtherScan';
            // } else if (this.networkFrom === 'tron') {
            //     source = 'TronScan';
            // } else if (this.networkFrom === 'solana') {
            //     source = 'SolScan';
            // }
            //
            // return `${startPart}***${endPart} (${source || this.currencyFrom})`;

            return this.$linkName(contract, this.networkFrom);
        },
        contractLink() {
            return this.$contractLink(this.currencyFrom.currency, this.networkFrom);
        },

        defaultDate() {
            return moment.utc(this.selectedNetworkObject.minFreezeTime * 1000).format('HH:mm:ss');

        },

    },
    methods: {
        toggleAdvancedOptions () {
            this.isAdvancedOpened = !this.isAdvancedOpened
        },
        minMaxValue(date) {
            const dateFormated = moment(date).format('HH:mm');
            let seconds = moment.duration(dateFormated).asSeconds();
            return (seconds > this.selectedNetworkObject.maxFreezeTime || seconds < this.selectedNetworkObject.minFreezeTime);
        },
        ...mapActions('app', [
            'closeModal',
        ]),
        ...mapActions('orders', [
            'createOrder',
            'fetchOrders',
        ]),
        checkUrl(url) {
            return isURL(`${url}`, {
                protocols: ['http', 'https'],
                require_protocol: true,
                host_blacklist: ['localhost', '127.0.0.1'],
            });
        },
        disabledDateForExpiresAt(date) {
            const today = Moment().add(-1, 'days').valueOf();
            return date <= today;
        },
        clearAllData() {
            this.isLoading = false;
            this.order = null;
            this.currencyFrom = null;
            this.networkFrom = null;
            this.amount = 0;
            this.errorWebhook = null;
            this.successWebhook = null;
            this.returnUrl = null;
            this.merchantOrder = null;
            this.merchantDescription = null;
            this.expiresAt = Moment().add(1, 'days').valueOf();
            this.link = '';
            this.errors = [];
            this.isAdvancedOpened = false
        },
        handleCreateOrder() {
            const {
                currencyFrom,
                accountId,
                networkFrom,
                amount,
                expiresAt,
                errorWebhook,
                successWebhook,
                returnUrl,
                ordersCountOnPage,
                fetchOrders,
                setErrors,
                merchantOrder,
                merchantDescription,

                errorWebhookStatus,
                successWebhookStatus,
                returnUrlStatus,
                lifetime,
            } = this;
            const urlPage = this.$route.query.page;
            const currentPage = urlPage ? Number(urlPage) : 1;

            if (!currencyFrom || !networkFrom || errorWebhookStatus || successWebhookStatus || returnUrlStatus) {
                return false;
            }
            const formattedLifetime = moment.duration(lifetime).asSeconds();
            this.isLoading = true;

            OrderController.createOrder({
                accountId,
                network: networkFrom.name,
                currency: currencyFrom.currency,
                amount,
                availableTill: expiresAt ? Moment(expiresAt).format() : null,
                errorWebhook, successWebhook,
                returnUrl,
                lifetime: formattedLifetime,
                order: merchantOrder,
                description: merchantDescription,
            })
                .then(order => {
                    if (order?.code === 0) {
                        this.order = order.result;

                    } else {
                        this.$Message.error({
                            message: this.$t(getErrorMessage(order.code)),
                            duration: 10000,
                        });
                    }
                })
                .catch(error => {
                    setErrors(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        handleCancelCreate() {
            const {
                clearAllData,
                closeModal,
                ordersCountOnPage,
                currentPage,
                fetchOrders,
            } = this;
            if (this.hasOrder) {
               this.$emit('updateOrders')
            }
            closeModal();

            setTimeout(() => {
                clearAllData();
            }, 500);
        },
        setErrors(error) {
            this.errors = [];

            if (error.includes('not valid')) {
                if (error.includes('errorWebhook')) {
                    this.errors.push('Передан некорректный error webhook');
                }

                if (error.includes('successWebhook')) {
                    this.errors.push('Передан некорректный success webhook');
                }
            }
        },
        viewContract() {
            window.open(this.contractLink);
        },
    },
    watch: {
        isOpen(status) {

            if (!status) {
                this.clearAllData();
                return;
            }
            if (this.currenciesForFrom.length) {
                this.currencyFrom = this.currenciesForFrom[0];

            }

            this.$nextTick(() => {

                if (this.networks.length) {
                    this.networkFrom = this.networks[0];

                }
            });
        },
        currencyFrom() {
            this.$nextTick(() => {
                if (this.networks.length) {
                    this.networkFrom = this.networks[0];
                }
            });
        },
        networkFrom() {
            this.$nextTick(() => {
                if (this.networks.length) {
                    this.lifetime = this.defaultDate;
                }
            });
        },
    },
};

</script>

<style lang="scss">
.new-order-modal {
  //max-width: 764px;
  &__advanced {
    max-height: 30px;
    overflow: hidden;
    margin-top: 35px;
    transition: max-height .3s ease;

    &--opened {
      max-height: 370px;
    }
    &-title {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-bottom: 20px;
      color: var(--new-front-primary-font-color);
      cursor: pointer;
      > span {
        font-size: $h4;
        font-weight: 500;
      }
      svg {
        transition: transform .3s ease;
      }
      &--opened {
        svg {
          transform: rotate(180deg);
        }
      }
    }

  }
  &__header {
    text-align: center;

    .title {
      font-size: $h1;
      font-weight: 500;
      color: var(--new-front-primary-font-color);
      @media (max-width: 450px) {
        font-size: $h2;
      }
    }
  }
  &__content {
    padding: 0 $p30;
    @media (max-width: 450px) {
      padding: 0 $p10;
    }
  }
  &__footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    @media (max-width: 450px) {
      display: flex;
      flex-direction: column-reverse;
      grid-template-columns: unset;
    }
  }
  &__error-text {
    font-size: var(--font-size-text-secondary);
    font-family: var(--font-family);
    line-height: var(--line-height);
    color: #EB5757;
  }

  &__time-picker-popup {
    background: black;
  }

  &__time-picker-popup::v-deep .mx-datepicker-header {
    padding: 0;

  }

  &__time-picker {
    width: 100%;
  }

  &__time-picker::v-deep.mx-datepicker-range {
    width: 100%;
  }

  //&__time-picker


  &__input {
    width: 100%;
  }

  &__divider {
    max-width: 100%;
    position: relative;
    &::before {
      content: '';
      width: 110%;
      border-bottom: 1px solid var(--new-front-input-border);
      background: black;
      position: absolute;
      left: -29px;
      bottom: 0;
    }
  }

  &__currency-container {
    display: flex;
    gap: 25px;
    justify-content: space-between;
  }

  &__select-container {
    position: relative;
  }

  &__option-container {
    display: flex;
    gap: 15px;
  }



  &__select-container_img ::v-deep .at-select__selection {
    padding-left: 68px;
  }

  &__label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 52px;
    //border-bottom: 1px solid var(--border-color);
  }

  &__time-label {

    font-family: $Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: var(--new-front-primary-font-color);
    width: 100%;

    &_divider {
      width: auto;
    }
  }

  &__row {
    margin-bottom: 20px;
    align-items: center;
    min-height: 30px;
    width: 100%;
    position: relative;

    ::v-deep .at-input--error .at-input__original {
      border-color: #EB5757;
    }

    &_amount {
      //padding-bottom: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--new-front-primary-label-color);
    margin-bottom: 10px;

    &_slider {
      //margin-bottom: 35px;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 13px;
    }
  }

  &__select {
    width: 100%;
    height: 32px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    outline: none;
    transition: .2s ease border-color;

    &:hover {
      border-color: #79A1EB;
    }
  }

  &__date.mx-datepicker {
    width: 100%;
  }

  &__spinner {
    margin: 16px 0 18px;
  }

  &__full-width-btn {
    width: 100%;
  }

  &.link {
    color: #5ECAE5;
    min-width: 220px;
    cursor: pointer;
  }

  .currency-img {
    width: 20px;
    height: 20px;

    &_preview {
      position: absolute;
      z-index: 2;
      top: 11px;
      width: 24px;
      height: 24px;
      left: 30px;
    }
  }


}
.new-order-modal__time-picker-popup {
  width: 100%;
  max-width: 312px;
  top: 60px !important;
}

.currency__option-text {
    color: var(--primary-font-color);
}

.new-order-modal__time-picker-popup > * > .mx-datepicker-header {
  padding: 0;
  border: none;
}

.new-order-modal__time-picker-popup > .mx-datepicker-content {
  border-radius: 8px;
  overflow: hidden;
  background: var(--new-front-main-bg) !important;
}

.new-order-modal__time-picker-popup > * > * > .mx-time {
  width: 100%;
}

.new-order-modal__time-picker-popup .mx-time-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.new-order-modal__time-picker-popup .mx-time-column .mx-time-item.active {
  width: 128px;
  height: 40px;
  color: #ffffff !important;
  background: var(--new-front-btn-bg-primary) !important;
  border-radius: 8px;
}

.new-order-modal__time-picker-popup .mx-time-item {
  width: 128px;
  height: 40px;
  background: var(--new-front-bg-modal-primary) !important;
  border-radius: 8px;
  margin-bottom: 4px;
  font-family: $Gilroy;
  font-style: normal;
  font-weight: 500;
  font-size: $h4;
  line-height: 16px;
  text-align: center;
  color: var(--new-front-primary-font-color) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: var(--new-front-select-item-bg-active) !important;
  }
}

.new-order-modal__time-picker-popup .mx-time-column {
  border: none;
}


</style>
<style lang="scss" scoped>


//Tick width & range thumb width need to be in sync, that's why I'm using the $unit variable.
$unit: 10px;


//Some range styling.
//Using mixins because each major browser needs a separate selector to style parts of the range input, combining selectors would invalidate the whole selector on each browser
@mixin range-track {
  -webkit-appearance: none;
  width: 100%;
  height: 7px;
  color: transparent;
  background: #F2F2F2;
  background-image: linear-gradient(90deg, var(--blue-300) var(--percentage), transparent var(--percentage));
  border-radius: 999px;
  border: none;
}

@mixin range-thumb {
  -webkit-appearance: none;
  height: 35px;
  width: 38px;
  border-radius: 12px;
  background: var(--blue-300);
  margin-top: -14px;
}

input[type=range] {
  -webkit-appearance: none;
  display: block;
  margin: 0;
  width: 100%;
  background: transparent;
}


input[type=range]::-webkit-slider-runnable-track {
  @include range-track();
}

input[type=range]::-moz-range-track {
  @include range-track();
}

input[type=range]::-ms-track {
  @include range-track();
}

input[type=range]::-ms-fill-lower {
  display: none;
}

input[type=range]::-ms-fill-upper {
  display: none;
}

input[type=range]::-webkit-slider-thumb {
  @include range-thumb();
}

input[type=range]::-moz-range-thumb {
  @include range-thumb();
}

input[type=range]::-ms-thumb {
  @include range-thumb();
}


//And now the ticks
.ticks {
  display: flex;
  justify-content: space-between;

  padding: $unit*2 $unit*2;
}

.tick {
  position: relative;
  display: flex;
  justify-content: center;
  background: gray;
  height: $unit;
  width: 0;
  font-family: $Gilroy;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: var(--primary-font-color);

  &_active {
    color: var(--blue-300);
  }
}


::v-deep .at-input__original {
  font-family: var(--font-family);
  font-size: var(--font-size-text-main);
  line-height: var(--line-height);

  &::placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }
}


@media screen and (max-width: 450px) {
  .new-order-modal {
    ::v-deep .currency-img_preview {
      left: 17px;
    }

    &__select-container_img {
      ::v-deep .at-select__selection {
        padding-left: 53px;
      }
    }

    ::v-deep .at-modal__title {
      max-width: 150px;
    }

    ::v-deep .at-modal__footer {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
    }

    &__amount {
      max-width: 100%;
    }

    &__row {
      margin-bottom: 10px;

      &_amount {
        margin-bottom: 20px;
      }
    }

    &__currency-container {
      flex-direction: column;
      gap: 10px;
      margin-bottom: 10px;
    }
  }

}

</style>
<!-- Стили выбора времени -->
<style lang="scss" scoped>
::v-deep {
  .mx-time-item  {
    background: var(--new-front-main-bg);
    color: var(--new-front-primary-font-color) !important;
    .active {
      background: var(--new-front-btn-bg-primary) !important;
      color: #ffffff !important;

    }
    &:hover {
      background: var(--new-front-select-item-bg-active) !important;
    }
  }
  .mx-input {
    width: 100%;
    min-height: 49px;
    padding: 0 20px 0 30px;
    background: transparent;
    border: 1px solid var(--new-front-input-border);
    box-shadow: inset 0 4px 20px var(--new-front-input-bg);
    border-radius: 12px;
    font-family: var(--new-front-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: var(--new-front-text-primary-bg-modal-primary);
  }
  .mx-time-columns {
    background: var(--new-front-main-bg);
  }
  .new-order-modal__time-picker-popup .mx-time-column .mx-time-list::after {
    height: 0 !important;
  }
  .mx-datepicker-main {
    border-color: var(--new-front-input-border) !important;
  }
  .mx-datepicker-popup {
    margin-top: 10px;
  }
}
::v-deep .icon  {
  .currency-network-icon__network {
    width: 22px !important;
    height: 22px !important;

    border: none !important;
    position: unset !important;

    top: unset !important;
    left: unset !important;

    transform: unset !important;
  }
}

::v-deep {
  .main-modal-wrapper {
    max-height: 100vh;
    overflow: auto;
  }
  .mx-scrollbar-track .mx-scrollbar-thumb {
    background: #744DB2;
  }
}
.new-order-modal__time-picker .mx-input-wrapper .mx-icon-calendar {
  color: var(--new-front-primary-font-color);
}

.new-order-modal__time-picker .mx-input-wrapper .mx-icon-clear {
  color: var(--new-front-primary-font-color);
}
</style>
