import sendWebhookQuery from "@/public/graphQl/mutations/sendWebhook";
import createCollectingSettings from "@/public/graphQl/mutations/createCollectingSettings";
import {getErrorMessage} from "@/public/filters";
import updateCollectingSettings from "@/public/graphQl/mutations/updateCollectingSettings";

export const updateSetting = async ({dispatch, commit, rootState, rootGetters}, payload) => {
    let settingResponse;

    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    try {
        commit('app/toggleLoading', null, {root: true});
        settingResponse = await dispatch('app/request', {
            type: 'mutation',
            gql: updateCollectingSettings,
            variables: {organizationId, ...payload},
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }
    if (settingResponse.graphQLErrors) {
        if (settingResponse.graphQLErrors[0].extensions.code === "INCORRECT_2FA") {
            // commit('setSetting', { errorMessage: getErrorMessage(settingResponse.graphQLErrors[0].extensions.code)})
            commit('setSetting', {code: 'error', errorMessage: settingResponse.graphQLErrors[0].message})
            return
        }
        commit('setSetting', {code: 'error', errorMessage: settingResponse.graphQLErrors[0].message})
        return
    }
    const { data: { updateCollectingSetting: {code, result, traceId } } } = settingResponse

    commit('setSetting', {...result, errorMessage: '', code},)
    return settingResponse;
};
