<script>

  import BaseInput from "@/public/pages/integration-settings/components/Input/index.vue";
  import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
  import {mapActions, mapMutations, mapState} from "vuex";
  import InfoButton from "@/public/components/common/uiKit/info-button/index.vue";
  import DeleteButton from "@/public/components/common/uiKit/delete-button/index.vue";

  export default {
    name: "OrganizationNewUsers",
    components: {DeleteButton, InfoButton, BaseButton, BaseInput},
    methods: {
      ...mapMutations('organizations', ['switchTab', 'changeDetailRole']),
      ...mapActions('organizations', [
        'getOrganizationUsers',
        'getOrganizationRoles',
      ]),
      ...mapActions('app', ['openModal']),
      handleAddUser() {
        this.switchTab(1)
        this.changeDetailRole(false)
        this.getOrganizationUsers()
      },
      deleteUser(user) {
        this.openModal({
          name: 'RemoveUserModal',
          payload: {
            user,
          },
        });
      },

      changeUser(user) {
        this.openModal({
          name: 'UpdateUserModal',
          payload: {
            user,
          },
        });
      },
    },
    computed: {
      ...mapState('organizations', [
        'organizationData',
      ]),
      list () {
        if (!this.organizationData.organizationUsers.length) return []
        return this.organizationData.organizationUsers.slice(0, 5)
      }
    }
  }
</script>

<template>
<div class="organization-information">
  <div class="header">
    <div class="header__left">
      <div class="header__title">
        {{ $t('organization.organization-new-users') }}
      </div>
    </div>
  </div>
  <div class="list">
    <div class="header">
      <div class="col">{{ $t('organization.list.user') }}</div>
      <div class="col">{{ $t('organization.list.role') }}</div>
<!--      <div class="col">-->
<!--        {{ $t('organization.list.last-visit') }}-->
<!--        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path d="M3.5 12.5C3.5 12.7761 3.27614 13 3 13C2.72386 13 2.5 12.7761 2.5 12.5L2.5 3.70711L1.35355 4.85355C1.15829 5.04882 0.841709 5.04882 0.646447 4.85355C0.451184 4.65829 0.451184 4.34171 0.646447 4.14645L2.64562 2.14728L2.65303 2.13998C2.69942 2.09524 2.75232 2.06123 2.80861 2.03794C2.86756 2.01349 2.9322 2 3 2C3.06779 2 3.13244 2.01349 3.19139 2.03794C3.25036 2.06234 3.30561 2.09851 3.35355 2.14645L5.35355 4.14645C5.54882 4.34171 5.54882 4.65829 5.35355 4.85355C5.15829 5.04882 4.84171 5.04882 4.64645 4.85355L3.5 3.70711L3.5 12.5Z" fill="var(&#45;&#45;text-color-text-secondary)"/>-->
<!--          <path d="M7 3.5C7 3.22386 7.22386 3 7.5 3H14.5C14.7761 3 15 3.22386 15 3.5C15 3.77614 14.7761 4 14.5 4H7.5C7.22386 4 7 3.77614 7 3.5Z" fill="var(&#45;&#45;text-color-text-secondary)"/>-->
<!--          <path d="M7.5 6C7.22386 6 7 6.22386 7 6.5C7 6.77614 7.22386 7 7.5 7H12.5C12.7761 7 13 6.77614 13 6.5C13 6.22386 12.7761 6 12.5 6H7.5Z" fill="var(&#45;&#45;text-color-text-secondary)"/>-->
<!--          <path d="M7.5 9C7.22386 9 7 9.22386 7 9.5C7 9.77614 7.22386 10 7.5 10H10.5C10.7761 10 11 9.77614 11 9.5C11 9.22386 10.7761 9 10.5 9H7.5Z" fill="var(&#45;&#45;text-color-text-secondary)"/>-->
<!--          <path d="M7.5 12C7.22386 12 7 12.2239 7 12.5C7 12.7761 7.22386 13 7.5 13H8.5C8.77614 13 9 12.7761 9 12.5C9 12.2239 8.77614 12 8.5 12H7.5Z" fill="var(&#45;&#45;text-color-text-secondary)"/>-->
<!--        </svg>-->
<!--      </div>-->
    </div>
    <template v-if="list.length">
      <div v-for="item in list" :key="item.id" class="item">
        <div class="item__values">
          <div class="item__value">{{ item.name}}</div>
          <div class="item__value">{{ item.role.name }}</div>
          <!--        <div class="item__value">17/02/23 12:22</div>-->
        </div>
        <div class="item__actions">
          <info-button @click="changeUser(item)" />
          <delete-button @click="deleteUser(item)"/>
        </div>
      </div>
    </template>
    <p class="empty" v-else> {{ $t('organization.no-users') }} </p>

  </div>
  <div class="more-btn">
    <base-button @click="handleAddUser" :label="list.length ? $t('organization.more') : $t('organization.organization_btn_add')" />
  </div>
</div>
</template>

<style scoped lang="scss">
.organization-information {
  border-radius: 20px;
  background: var(--bg-background-secondary, #202020);
  padding: 30px;
  font-family: $Gilroy;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    &__title {
      color: var(--text-text-primary, #FFF);
      font-family: Gilroy;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    &__left {
      display: flex;
      align-items: center;
      gap: 5px;
    }
    &__right {
      display: grid;
      grid-template-columns: 48px 1fr;
      gap: 10px;
    }
    &__clear {
      border-radius: 10px;
      border: 1px solid var(--button-border-button-primary-default, #6750A4);
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__button {
      width: 180px;
    }
  }
  .list {
    margin-bottom: 30px;
    .empty {
      color: var(--text-color-text-secondary, #7E7E7E);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 30px;
    }
    .header {
      border-radius: 10px;
      background: var(--bg-background-table, #333);
      padding: 0 20px;
      height: 56px;
      display: grid;
      grid-template-columns: 1fr 1fr 168px;
      margin-bottom: 0;
      .col {
        color: var(--text-color-text-secondary, #7E7E7E);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        align-items: center;
        gap: 5px;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
    .item {
      display: grid;
      grid-template-columns: 1fr 168px;
      align-items: center;
      border-bottom:   1px solid var(--border-devider-primary, #353537);
      padding: 10px 20px 10px 20px;
      &__values {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      &:last-child {
        border-bottom: none;
      }
      &__value {
        color: var(--text-text-primary, #FFF);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }
      &__actions {
        display: grid;
        grid-template-columns: 48px 48px;
        gap: 10px;
        justify-content: end;
        &-btn {
          cursor: pointer;
          height: 48px;
          width: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          border: 1px solid var(--button-border-button-primary-default, #6750A4);
          &.history {
            span {
              display: none;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .organization-information {
    padding: 0;
    //.header {
    //  display: none;
    //}
    .header {
      padding: 20px 0 15px 20px;
      margin-bottom: 0;
      border-bottom: 1px solid var(--border-devider-primary, #353537);;
    }
    .header__left {
      .header__title {
        font-size: 16px;
      }
    }
    .list {
      .header {
        display: none;
      }
      .item {
        padding: 16px 20px 20px 20px;
        height: unset;
        grid-template-columns: 1fr;
        border-bottom: 1px solid var(--border-devider-primary, #353537);
        &:last-child {
          border-bottom: 0;
        }
        &__value {
          color: var(--text-color-text-secondary, #7E7E7E);
          font-size: 13px;
          &:first-child {
            color: var(--text-text-primary, #FFF);
          }
          &:nth-child(even) {
            display: flex;
            justify-content: flex-end;
          }
        }
        &__values {
          grid-template-columns: 1fr 1fr;
        }
        &__actions {
          margin-top: 20px;
          grid-template-columns: 48px 48px;
          justify-items: right;
          &-btn {
            gap: 8px;
            &.history {
              width: unset;
              color: var(--bg-background-primary-purple, #6750A4);
              text-align: right;
              font-size: 13px;
              font-weight: 500;
              line-height: 28px;
              span {
                display: inline-block;
              }
            }
          }
        }
      }
    }
    .more-btn {
      margin-top: 30px;
      padding: 0 20px 20px 20px;
    }

  }
}
</style>
