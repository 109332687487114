<template>
  <div>
    <div class="cross-chain-history">
      <div class="cross-chain-history__title-wrapper">
        <return-button @click="goBack()"/>
      </div>
      <div class="cross-chain-history__container">
        <div class="header">
          <history-top-bar
            :pagination="pagination"
            @transfers-list="transfersList = $event"
            @loading="loading = $event"
            @go-to-first-page="pageChange(1)"
          />
        </div>

        <div class="cross-chain-history-dashboard">
          <div
            v-if="loading"
            class="loader-block"
            style="min-height: 400px"
          >
            <new-spinner />
          </div>
          <history-list
            v-else-if="transfersList.length"
            :transfers-list="transfersList"
            :pagination="pagination"
          />
          <div v-else class="empty-list-label">
            {{ $t('common.empty')}}
          </div>
        </div>
        <div class="cross-chain-history-pagination">
          <pagination
            v-if="pagination.total > pagination.limit"
            size="small"
            :total="pagination.total"
            :page-size="pagination.limit"
            :current="currentPage"
            @page-change="pageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HistoryIcons from "/public/components/elements/cross-chain/History/icons/";
import HistoryTopBar from "/public/components/elements/cross-chain/History/HistoryTopBar";
import HistoryList from "/public/components/elements/cross-chain/History/List";
import { mapGetters } from "vuex";

export default {
    name: "crossChainHistory",
    title: 'navigation.bridge',
    components: {
      HistoryIcons,
      HistoryTopBar,
      HistoryList,
      NewSpinner: () => import (/* webpackChunkName: "/public/components/common/uiKit/spinner" */ "/public/components/common/uiKit/spinner/index.vue"),
      Pagination: () => import(/* webpackChunkName: "public/components/common/uiKit/Pagination.vue" */ '/public/components/common/uiKit/Pagination.vue'),
      ReturnButton: () => import(/* webpackChunkName: "public/components/common/uiKit/button/return" */ '/public/components/common/uiKit/button/return')
    },
    created() {
        const isAllowed = this.userRoleRules['wallets_crosschain_transfer'].entities.view_crosschain_transfers;
        if (!isAllowed) {
            this.$Message.error(this.$t('rules.not_allowed'));
            this.$router.push('/merchant/dashboard');
        }
    },
    data() {
      return {
        transfersList: [],
        loading: false,
        currentPage: 1,
        pagination: {
          offset: 0,
          total: 0,
          limit: 10,
          sort: 'DESC'
        }
      }
    },
    computed: {
        ...mapGetters('organizations', ['userRoleRules'])
    },
    methods: {
      goBack() {
        this.$router.push({
          name: 'bridge'
        })
      },
      pageChange(v) {
        this.currentPage = v
        this.pagination.offset = (v - 1) * this.pagination.limit
      }
    },
};
</script>

<style lang="scss">
.cross-chain-history {
  padding: 0 30px 30px 30px;

  font-family: var(--new-front-font-family);

  width: 81vw;
  @include below_576() {
    padding: 0 10px 10px 10px;
    margin-top: -10px;
  }
  &__title-wrapper {
    position: relative;

    //display: flex;
    //align-items: center;
    //justify-content: space-between;
    //margin-bottom: var(--margin-h1-bottom);

    &__title {
      font-style: normal;
      font-weight: 400;
      font-size: $h1;
      color: var(--new-front-text-primary-bg-secondary);
    }

    &__paragraph {
      font-style: normal;
      font-weight: 400;
      font-size: $h3;
      line-height: $h3;
      color: var(--new-front-text-secondary-bg-secondary);

      padding-top: 10px;
    }

    @include below_375() {
      padding: 0 20px;
    }
  }

  &__container {
    background: var(--new-front-bg-secondary);
    border-radius: 20px;
    box-shadow: 0 8px 50px rgba(10, 17, 105, 0.08);
    margin-top: var(--margin-container-top);
    margin-bottom: var(--margin-container-bottom);

    padding: 30px;

    @include below_phone () {
      padding: 30px 0;

      .header {
        padding: 0 20px;
      }
    }

    .header {
      display: flex;
      flex-direction: column;
      justify-content: end;
    }

    .cross-chain-history-dashboard {
      display: flex;
      align-items: center;

      .loader-block {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      .empty-list-label {
        width: 100%;
        text-align: center;

        padding-top: 20px;

        color: var(--new-front-text-secondary-bg-secondary);
      }
    }

    .cross-chain-history-pagination {
      min-height: 50px;
      align-items: center;
      display: flex;
      justify-content: center;
      border-radius: 0 0 20px 20px;
    }

    .line {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: space-between;

      flex-wrap: wrap;

      .item {
        width: 45%;
        margin-top: 30px;

        &.amount-input {
          .label-right {
            .available-balance {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 12px;

              text-align: center;

              color: var(--secondary-font-color);
            }
          }
          .bottom-label {
            display: flex;
            justify-content: right;

            font-family: var(--new-front-font-family);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 32px;

            .minimum-amount {
              display: block;

              @include below_phone() {
                display: none;
              }
            }
            .available-balance {
              display: none;

              @include below_phone() {
                display: block;
              }

              .info {
                color: var(--secondary-font-color);
                margin-right: 16px;
              }
            }

            .info {
              color: var(--secondary-font-color);
              margin-right: 16px;
            }
            .value {
              color: var(--additional-font-color);
            }
          }
        }

        @include below_880() {
          width: 100%;
          margin-top: 20px;
        }
      }
    }

    .footer {
      border-top: 1px solid #F2F2F2;
      padding: 30px;
      margin-top: 30px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      @include below_880() {
        flex-direction: column;
      }

      .action {
        max-width: 260px;
        margin: 10px 0;

        @include below_880() {
          width: 100%;
          max-width: 100%;
          order: 1;
        }

      }
      .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        font-family: var(--new-front-font-family);
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height:26px;

        .item {
          margin-right: 50px;

          &.minimum-amount {
            display: none;

            @include below_phone () {
              display: flex;
            }
          }

          .label {
            color: var(--primary-font-color);
            margin-right: 37px;
          }
          .value {
            color: var(--secondary-font-color);
          }
        }
      }

      @include below_360() {
        padding: 10px;
        .info {
          font-size: 14px;
          line-height: 24px;

          .item {
            margin: 10px 10px 10px 0;
          }
        }
      }
    }
  }

  @include below_1180() {
    width: 100vw;
  }

  @include below_phone () {
    &__title-wrapper {
      &__title {
        font-size: $h3;
      }
    }
  }
}
</style>
