import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!) {
        getRecurrentAddresses(organizationId: $organizationId) {
            id
            currencyId
            networkId
            address
            tag
            balance
            alias
            comment
            isBanned
            riskLevel
        }
    }
`;
