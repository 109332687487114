<template>
    <Example
        :hasResponse="hasResponse"
        :requestInfo="requestInfo"
    >
        <template #title>
            Получение баланса (get-balance)
        </template>
        <template #settings>
            <div class="example__actions">
                <AtButton
                    type="primary"
                    size="large"
                    @click="makeRequest"
                >
                    Сделать запрос
                </AtButton>
                <AtButton
                    v-if="hasResponse"
                    size="large"
                    @click="clear"
                >
                    Очистить
                </AtButton>
            </div>
        </template>
        <template #response>{{ response }}</template>
    </Example>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

import { exampleMixin } from "/public/mixins/example";

import Example from './Example';
import AtButton from 'at-ui/src/components/button';

export default {
    name: 'GetBalance',
    components: {
        Example,
        AtButton,
    },
    mixins: [ exampleMixin ],
    data () {
        return {
            response: null,
        };
    },
    computed: {
        ...mapGetters('user', [
            'hasTestApiKeys',
        ]),
        hasResponse () {
            return Boolean(this.response);
        },
    },
    methods: {
        ...mapActions('app', [
            'apiRequest',
        ]),
        makeRequest () {
            const {
                hasTestApiKeys,
                apiRequest,
                setRequestInfo,
            } = this;

            if (!hasTestApiKeys) {
                return false;
            }

            apiRequest({
                method: 'get-balance',
            })
                .then(resp => {
                    const {
                        data: {
                            response,
                        },
                    } = resp;

                    this.response = response;
                    setRequestInfo(resp);
                })
                .catch(error => {
                    this.response = error.response.data;
                    setRequestInfo(error.response);
                });
        },
        clear () {
            this.response = null;
        },
    },
};

</script>