import createBankAccountMutation from "@/public/graphQl/mutations/createBankAccount";

export const createBankAccount = async ({dispatch, commit, rootGetters}, {
    alias,
    type,
    iban,
    bic,
    account,
    companyName,
    bankAddress,
    bankName,
    bankCountryCode,
    postalCode,
    currencyId
}) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    commit('setFetching', true)
    let accountData;
    try {
        accountData = await dispatch('app/request', {
            type: 'mutation',
            gql: createBankAccountMutation,
            variables: {
                organizationId,
                alias,
                type,
                iban,
                bic,
                account,
                companyName,
                bankAddress,
                bankName,
                bankCountryCode,
                postalCode,
                currencyId
            },
        }, {root: true});
    } catch (error) {
        return error;
    }

    if (accountData.message) {
        throw accountData.message;
    }

    if (accountData.errors) {
        throw accountData.errors;
    }

    const {
        data: {
            createBankAccount,
        },
    } = accountData;
    commit('setBankAccountInfoState', true)
    commit('setBankAccount', createBankAccount)
    commit('setFetching', false)
    return createBankAccount;
};
