<template>
<base-select
    v-model="innerModel"
    :placeholder="$t('business-wallet.header.selects.currency.placeholder')"
    class="settings-currency-select"
    :list="filteredList"
    multiple
    :canBeClosed="false"
    counterTextTranslate="integration-settings.elements.currency-select.selected-label"
    clearable
    gap
>
  <template #selected="data">
    <div class="selected-block">
      <network-and-currency-item v-bind="data" style="height: 46px; max-height: 46px"/>
      <div class="counter">
        {{ counter }}
      </div>
    </div>
  </template>
  <template #search>
    <search
        id="search-input"
        class="search"
        v-model="search"
        :clearWhenDestroyed="false"
    />
  </template>
  <template #info="data">
    <network-and-currency-item v-bind="data" style="height: 46px; max-height: 46px"/>
  </template>
</base-select>
</template>

<script>
  import BaseSelect from '/public/components/common/uiKit/selects/base'
  import { mapState } from "vuex";
  import NetworkAndCurrencyItem from "@/public/components/elements/business-walet/Select/Item.vue"

  export default {
    name: "DashboardCurrencyMultiSelect",
    components: {
      NetworkAndCurrencyItem,
      BaseSelect,
      Search: () => import(/* webpackChunkName: "public/components/common/uiKit/search" */ '/public/components/common/uiKit/search'),
      // NetCurrItem: () => import(/* webpackChunkName: "public/components/elements/integration-settings/selects/currency/Option" */ './Option'),
    },
    props: {
      value:  {
        type: Array,
        default: () => ([])
      },
    },
    data() {
      return {
        search: ''
      }
    },
    computed: {
      ...mapState('app', ['currencies']),
      filteredCurrencies({currencies}) {
        return currencies.filter(item => !item.isFiat)
      },
      filteredList({filteredCurrencies, search}) {
        //метод фильтрации общего списка валют
        if (search) {
          return filteredCurrencies.filter((curr = {}) => {
            const currencyName = curr['currency'].toLowerCase()
            const currencyAlias = curr['alias'].toLowerCase()

            return currencyName.includes(search.toLowerCase()) || currencyAlias.includes(search.toLowerCase())
          })
        } else {
          return filteredCurrencies
        }
      },
      innerModel: {
        get({ value }) {
          return value
        },
        set(v) {
          if (!v) {
            return this.$emit('input', v)
          }

          this.$emit('input', v.map((item) => item))
        }
      },
      counter({ innerModel }) {
        return !!innerModel && innerModel.length > 1 ? `+${innerModel.length - 1}` : undefined
      }
    }
  }
</script>

<style scoped lang="scss">
.settings-currency-select {
  .selected-block {
    display: flex;
    align-items: center;
    gap: 5px;

    .counter {
      font-size: $h6;
      color: var(--text-color-text-secondary, #818CA0);
    }
  }
  .search {
    margin-bottom: 15px;
  }
}
</style>
