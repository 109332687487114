<template>
  <div class="accounts-filter-by-date">
    <DatePicker
      class="accounts-filter-by-date__date"
      type="date"
      :show-second="false"
      range
      confirm
      popup-class="uikit-datepicker-popup"
      :editable="false"
      :placeholder="placeholder"
      :confirm-text="$t('analytics.filters.date-picker.btn-label')"
      :lang="selectLang"
      :disabled-date="disabledRange"
      value-type="date"
      v-model="dates"
      v-bind="$attrs"
      @confirm="selectDates"
      @clear="clearDates"
      :open="isOpened"
      @open="isOpened = true"
      @close="isOpened = false"
    >
      <template #icon-calendar>
        <svg
            class="chevron"
            :class="{ 'active': isOpened }"
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="6"
            viewBox="0 0 8 6"
            fill="none"
            style="width: 8px; height: 6px"
        >
          <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.228 1.635C0.085 1.462 0 1.241 0 1C0 0.448 0.448 0 1 0H7C7.552 0 8 0.448 8 1C8 1.241 7.915 1.462 7.772 1.635L4.808 5.589C4.626 5.838 4.332 6 4 6C3.668 6 3.374 5.838 3.192 5.589L0.228 1.635Z"
              :fill="themeStatus === 'dark' ? 'white' : '#333333'"
          />
        </svg>
      </template>
      <template #footer>
        <base-button
          type="secondary"
          class="button"
          :label="$t('analytics.filters.date-picker.btn-cancel')"
          size="small"
          style="width: 134px"
          @click="handleClose"
        />
      </template>
    </DatePicker>
  </div>
</template>

<script>
    import Moment from "moment";
    import {mapActions, mapState} from 'vuex';

    import DatePicker from "vue2-datepicker";
    import BaseButton from "@/entries/invoice/components/Elements/Button.vue";

    export default {
        name: 'NewDatepicker',
        components: {
          BaseButton,
            DatePicker,
        },
        props: {
          placeholder: {
              type: String,

          }
        },
        computed: {
            ...mapState('user', [
                'lang',
            ]),
            selectLang() {
                return this.lang;
            },
            select_dates() {
                return this.$t('advance_balances.select_dates');
            },
          ...mapState('app', {
            themeStatus: state => state.theme
          }),
        },
        data() {
            return {
                isOpened: false,
                dates: [],
            };
        },
        beforeMount() {
            this.setDateFromUrl();

            this.$emit('clear-dates', this.clearDates)
        },
        methods: {
            ...mapActions('accounts', [
                'getAccountHistory',
            ]),
          handleClose () {
            this.isOpened = false
          },
            disabledRange(date) {
                return date < new Date(2022, 0, 1) || date > new Date();
            },
            setDateFromUrl() {
                const dateFromUrl = this.$route.query.date;

                if (!dateFromUrl) {
                    return false;
                }

                this.dates = dateFromUrl.split('to').map(date => new Date(date));
            },
            selectDates() {
                const {
                    $route,
                    $router,
                    dates,
                } = this;

                const dateFrom = Moment(dates[0]).format();
                const dateTo = Moment(dates[1])
                    .add(23, 'hours')
                    .add(59, 'minutes')
                    .add(59, 'seconds')
                    .format()
                ;
                this.$emit('input', [ dateFrom, dateTo ])
            },
            clearDates() {
                const {
                    $route,
                    $router,
                } = this;

                this.$emit('input', null)
                this.dates = []
            },
        },
    };
</script>

<style lang="scss" scoped>
  .accounts-filter-by-date {
    &__title {
      margin-bottom: 8px;
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: var(--new-front-primary-font-color);
    }

    &__date::v-deep.mx-datepicker-range {
      width: 100%;
    }
    &__date ::v-deep .mx-datepicker-main {
        background: var(--new-front-bg-btn-secondary-bg-modal-primary) !important;
    }
    &__date ::v-deep .mx-input {
        font-family: $Gilroy;
            display: flex;
            align-items: center;

            width: 100%;

            min-height: 47px;
            line-height: 47px;
            background: var(--new-front-main-bg);
            border: 1px solid var(--new-front-input-border);
            box-shadow: inset 0 4px 20px var(--new-front-gradient-bg);
           color: var(--new-front-primary-font-color);
            border-radius: 12px;
            padding: 0 20px;

            cursor: pointer;

            font-style: normal;
            font-weight: 400;
            font-size: $h4; //16px
            line-height: 16px;

            .selected {
                width: 100%;
                font-weight: 500;
                pointer-events: none;
                color: var(--new-front-primary-font-color);
            }

            &::placeholder {
                pointer-events: none;
                font-weight: 500;
                color: var(--new-front-secondary-font-color);
                opacity: 1;
            }

            &.filled {
                &:hover {
                    svg.chevron {
                        display: none;
                    }
                    svg.clear {
                        display: flex;
                    }
                }
            }


            svg.chevron {
                position: absolute;
                top: 50%;
                right: 20px;

                display: block;

                transition: all .3s;
                transform: translateY(-50%) rotate(0);

                width: 10px;

                pointer-events: none;
            }

            svg.clear {
                display: none;

                position: absolute;
                top: 50%;
                right: 20px;

                transform: translateY(-50%);

                width: 10px;
            }
    }
    &__date ::v-deep .mx-datepicker-popup {
      min-width: 290px!important;
      font-family: $Gilroy;
      .mx-calendar-header {
        .mx-calendar-header-label {
          .mx-btn {
            font-size: $h4;
          }
        }
      }
      .mx-datepicker-content {
        .mx-datepicker-body {
          .mx-calendar {
            width: 285px!important;
          }
        }
        .mx-datepicker-footer {
          display: flex!important;
          justify-content: space-between;
          gap: 10px;
        }
      }
    }
  }
  .accounts-filter-by-date__date::v-deep {
    .mx-icon-calendar {
      display: flex;
      right: 22px;

      .chevron {
        transition: all .3s;
        transform: rotate(0);

        &.active {
          transition: all .3s;
          transform: rotate(180deg);
        }
      }
    }
    .mx-icon-clear {
      right: 18px;
      color: var(--new-front-text-primary-bg-primary);
    }
    ::v-deep .mx-datepicker-footer {
      display: flex!important;
      justify-content: space-between;
      gap: 10px;

      .cancel-btn {

      }
      ::v-deep.mx-datepicker-btn-confirm {
        font-family: $Gilroy!important;
        font-size: $h4!important;
        font-weight: 500;
        width: 120px!important;
        height: 34px!important;
        border-radius: 10px!important;
        padding: 8px!important;

        color: #FFFFFF!important;
        background-color: #744DB2!important;
      }
    }
  }
</style>

<style lang="scss">
  //очень долго пытался достучаться до этих классов через v-deep но не смог (возможно потому что попап пикера находится не в скоупе приложения)
  //поэтому вынес их в not scoped блок стилей
  .mx-datepicker-content {
    .mx-datepicker-footer {
      display: flex!important;
      justify-content: space-between;
      gap: 10px;
      .mx-datepicker-btn-confirm {
        font-family: $Gilroy;
        font-size: $h4;
        font-weight: 500;
        width: 120px;
        height: 34px;
        border-radius: 10px;
        padding: 8px;

        color: var(--ocp-white)!important;
        background: var(--button-border-button-primary-default);
      }
    }
  }
</style>
