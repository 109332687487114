import gql from "graphql-tag";

export default gql`
    query($organizationId: String!, $limit: Int!, $offset: Int!) {
        getPreCheckoutPages(organizationId: $organizationId, limit:$limit, offset: $offset) {
            count
            pages {
                id
                accountId
                url
                alias
                dailyTurnover
                info {
                 title
                 description
                 avatarLink
                 theme
                }
                createdAt
            }
        }
    }
`;
