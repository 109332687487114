<template>
    <Example
        class="example"
        :hasResponse="hasResponse"
        :requestInfo="requestInfo"
    >
        <template #title>
            Получение ориентировочной комиссии для вывода (estimated-fee)
        </template>
        <template #settings>
            <div class="example__row">
                <div class="example__col">
                    <ExampleSetting title="Валюта (currency)">
                        <select
                            class="example__select"
                            :class="currencyStatus"
                            v-model="currency"
                        >
                            <option value="">
                                Выберите
                            </option>
                            <option
                                v-for="(currency, key) in currencies"
                                :key="key"
                                :value="currency.currency"
                            >
                                {{ currency.currency }}
                            </option>
                        </select>
                    </ExampleSetting>
                </div>
                <div class="example__col">
                    <ExampleSetting title="Сумма вывода (amount)">
                        <AtInput
                            size="small"
                            :status="amountStatus"
                            v-model="amount"
                        />
                    </ExampleSetting>
                </div>
            </div>
            <div class="example__actions">
                <AtButton
                    type="primary"
                    size="large"
                    @click="makeRequest"
                >
                    Сделать запрос
                </AtButton>
                <AtButton
                    v-if="hasResponse"
                    size="large"
                    @click="clear"
                >
                    Очистить
                </AtButton>
            </div>
        </template>
        <template #response>{{ response }}</template>
    </Example>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex';

import { exampleMixin } from "/public/mixins/example";

import Example from './Example';
import ExampleSetting from "./ExampleSetting";
import AtInput from 'at-ui/src/components/input';
import AtButton from "at-ui/src/components/button";

export default {
    name: 'EstimatedFee',
    components: {
        Example,
        ExampleSetting,
        AtInput,
        AtButton,
    },
    mixins: [exampleMixin],
    data () {
        return {
            currency: null,
            amount: null,
            response: null,
        };
    },
    computed: {
        ...mapState('app', [
            'currencies',
        ]),
        ...mapGetters('user', [
            'hasTestApiKeys',
        ]),
        hasResponse () {
            return Boolean(this.response);
        },
        currencyStatus () {
            return !this.currency ? 'error' : '';
        },
        amountStatus () {
            return !this.amount ? 'error' : '';
        },
    },
    methods: {
        ...mapActions('app', [
            'apiRequest',
        ]),
        makeRequest () {
            const {
                hasTestApiKeys,
                currency,
                amount,
                apiRequest,
                setRequestInfo,
            } = this;

            if (!hasTestApiKeys) {
                return false;
            }

            apiRequest({
                method: 'get-estimated-fee',
                data: {
                    currency,
                    amount,
                },
            })
                .then(resp => {
                    const {
                        data: {
                            response,
                        },
                    } = resp;

                    this.response = response;
                    setRequestInfo(resp);
                })
                .catch(error => {
                    this.response = error.response.data;
                    setRequestInfo(error.response);
                });
        },
        clear () {
            this.currency = null;
            this.amount = null;
            this.response = null;
        },
    },
};

</script>