import gql from 'graphql-tag';

export default gql`
    mutation ($organizationId: String!, $clientId: String!, $clientEmail: String!, $createAddresses: Boolean) {
        createPersonalUser(organizationId:$organizationId, clientId:$clientId, clientEmail:$clientEmail, createAddresses:$createAddresses) {
            traceId
            code
            result {
                id
                clientId
                clientName
                clientEmail
                depositWebhookUrl
                createdAt
                updatedAt
            }
        }
    }
`;