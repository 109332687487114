
import getWidgetQuery from "/public/graphQl/queries/getIframeWidget";
// import {getAllBalances} from "../../balances/actions/getAllBalances";
export const fetchWidget = async ({state, commit, rootGetters, dispatch}, widgetId) => {
    if (state.loading) {
        return;
    }

    commit('setLoading', true);

    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    let data;
    try {
        data = await dispatch('app/request', {
            type: 'query',
            gql: getWidgetQuery,
            variables: { organizationId, widgetId },
        }, {root: true});
    } catch (error) {
        console.error('Fail fetch accounts', error);
    } finally {
        commit('setLoading', false);
    }

    const {
        data: {
            IFrameWidget: widget,
        },
    } = data;


    commit('setWidget', widget);

    return widget;
};
