<template>
  <div>
    <div class="transactions-list-header">
      <div
          class="transactions-list-header__item list-header__item--sorting  "
          @click="sortByDate"
      >
        {{ $t('transactions.date_of_creation') }}
        <Icon
            class="list-header__icon list-header__chart-icon"
            :class="{ 'list-header__chart-icon--asc': sortByDateStatus === 'ASC' }"
            icon="bar-chart"
        />
      </div>
      <div class="transactions-list-header__item   ">
        {{ $t('transactions.method') }}
      </div>
      <div class="transactions-list-header__item  ">
        {{ $t('transactions.status') }}
      </div>
      <!--      <div class="transactions-list-header__item  ">-->
      <!--        {{ $t('transactions.role') }}-->
      <!--      </div>-->

      <div class="transactions-list-header__item  ">
        {{ $t('transactions.basis') }}
      </div>

      <div class="transactions-list-header__item  ">
        {{ $t('transactions.source') }}
      </div>
      <div class="transactions-list-header__item   ">
        {{ $t('transactions.address_from') }}
      </div>
      <div class="transactions-list-header__item   ">
        {{ $t('transactions.address_to') }}
      </div>
      <div class="transactions-list-header__item   ">
        tx_id
      </div>


      <div class="transactions-list-header__item">
        {{ $t('transactions.coin') }}
      </div>
      <div class="transactions-list-header__item">
        {{ $t('transactions.network') }}
      </div>
      <div class="transactions-list-header__item   ">
        {{ $t('transactions.type') }}
      </div>
      <div class="transactions-list-header__item">
        <span class="transactions-list-header__item-text transactions-list-header__item-text_deposit">{{
            $t('transactions.sum.debit')
          }}</span>
        <span class="transactions-list-header__item-text">/</span>
        <span class="transactions-list-header__item-text transactions-list-header__item-text_credit">{{
            $t('transactions.sum.credit')
          }}</span>
      </div>
      <!--      <div class="transactions-list-header__item">-->
      <!--        {{ $t('transactions.write-off_currency') }}-->
      <!--      </div>-->

      <!--      <div class="transactions-list-header__item">-->
      <!--        {{ $t('transactions.write-off_amount') }}-->
      <!--      </div>-->

      <!--      <div class="transactions-list-header__item">-->
      <!--        {{ $t('transactions.order_id') }}-->
      <!--      </div>-->
      <!--      <div class="transactions-list-header__item">-->
      <!--        {{ $t('transactions.date_of_creation_order') }}-->
      <!--      </div>-->
      <!--      <div-->
      <!--          class="transactions-list-header__item list-header__item&#45;&#45;sorting  "-->
      <!--          @click="sortByDate"-->
      <!--      >-->
      <!--        {{ $t('transactions.date_of_creation') }}-->
      <!--        <Icon-->
      <!--            class="list-header__icon list-header__chart-icon"-->
      <!--            :class="{ 'list-header__chart-icon&#45;&#45;asc': sortByDateStatus === 'ASC' }"-->
      <!--            icon="bar-chart"-->
      <!--        />-->
      <!--      </div>-->

    </div>
    <div class="transaction-list-header_mobile">

    </div>
  </div>
</template>

<script>

import {mapActions} from 'vuex';

import Icon from "/public/components/elements/Icon";

export default {
    name: 'TransactionsListHeader',
    components: {
        Icon,
    },
    data() {
        return {
            sortByDateStatus: 'DESC',
        };
    },
    beforeMount() {
        this.setSortByDateFromUrl();
    },
    methods: {
        ...mapActions('transactions', [
            'fetchTransactions',
        ]),
        setSortByDateFromUrl() {
            const sortDateFromUrl = this.$route.query.sortDate;

            if (!sortDateFromUrl) {
                return false;
            }

            this.sortByDateStatus = sortDateFromUrl;
        },
        sortByDate() {
            const {
                $route,
                $router,
                sortByDateStatus,
                fetchTransactions,
            } = this;

            if (sortByDateStatus === 'DESC') {
                this.sortByDateStatus = 'ASC';
            } else {
                this.sortByDateStatus = 'DESC';
            }

            fetchTransactions({
                route: $route,
                router: $router,
                sortDate: this.sortByDateStatus,
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.transactions-list-header {
  padding: 0;

  display: grid;
  grid-template-columns: repeat(12, 20%);

  align-items: center;


  &_mobile {
    display: none;
  }

  &__item {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    min-height: 46px;
    background: var(--main-page-line);
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    text-align: start;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--primary-font-color);

    &:first-of-type {
      padding-left: 45px;
    }

    &:last-of-type {
      padding-right: 45px;
      justify-content: flex-end;
    }

    &-text {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;

      &_deposit {
        color: var(--green-200);
      }

      &_credit {
        color: var(--red-200);
      }
    }
  }


}

@media screen and (max-width: 1330px) {
  .transactions-list-header {
    &__item {
      &:first-of-type {
        padding-left: 15px;
      }

      &:last-of-type {
        padding-right: 15px;
      }
    }
  }

}

@media screen and (max-width: 950px) {
  .transactions-list-header {
    display: none;

    &_mobile {
      display: block;
    }

  }

}
</style>
