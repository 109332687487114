<template>
  <BaseModal
      class="remove-api-keys-modal"
      width="100%"
      :value="isOpen"
      :title="title"
      :show-close="true"
      :show-head="!isLoading"
      :show-footer="!isLoading"
      @on-confirm="handleRemoveRequest"
      @on-cancel="handleCancelCreate"
  >
    <template v-if="!isLoading">
      <template v-if="!isSuccess">
        <p class="remove-api-keys-modal__message">
          {{ $t('donations.modal.subtitle') }}
          <span class="remove-api-keys-modal__alias" v-if="alias">{{ alias }}</span>?
        </p>
      </template>
      <template v-else>
        <p class="remove-api-keys-modal__message">
          {{ $t('donations.modal.success') }}
        </p>
      </template>
    </template>
    <LoadingSpinner
        v-else
    />

    <template #footer>
      <template v-if="!isSuccess">
        <AtButton @click="handleCancelCreate">
          {{ $t('api_keys.close') }}
        </AtButton>
        <AtButton
            type="primary"
            @click="handleRemoveRequest"
        >
          {{ $t('api_keys.delete') }}
        </AtButton>
      </template>
      <AtButton
          v-else
          class="remove-api-keys-modal__full-width-btn"
          type="primary"
          @click="handleCancelCreate"
      >
        {{ $t('api_keys.close') }}
      </AtButton>
    </template>

  </BaseModal>
</template>

<script>

import {mapState, mapGetters, mapActions} from 'vuex';

import AtModal from 'at-ui/src/components/modal';
import LoadingSpinner from "/public/components/elements/LoadingSpinner";
import AtButton from 'at-ui/src/components/button';
import BaseModal from "../BaseModal";

export default {
    name: 'RemoveDonationModal',
    components: {
        BaseModal,
        AtModal,
        LoadingSpinner,
        AtButton,
    },
    data() {
        return {
            isLoading: false,
            isSuccess: false,
        };
    },
    computed: {
        ...mapState('app', {
            modalPayload: state => state.modal.payload,
        }),
        ...mapGetters('app', [
            'isOpenModal',
        ]),
        isOpen() {
            const {
                $options: {
                    name,
                },
                isOpenModal,
            } = this;

            return isOpenModal(name);
        },
        title() {
            return this.$t(this.isSuccess ? 'donations.modal.title' : 'donations.modal.title');
        },
        alias() {
            return this.modalPayload?.alias ?? '';
        },
        hideFooter() {
            const {
                isLoading,
                isSuccess,
            } = this;

            return isLoading || isSuccess;
        },
        deleting_an_api_key() {
            return this.$t('api_keys .deleting_an_api_key');
        },
    },
    methods: {
        ...mapActions('app', [
            'closeModal',
        ]),
        ...mapActions('donations', [
            'removePreCheckoutPage',
            'fetchPreCheckoutPages',
        ]),
        clearAllData() {
            this.isLoading = false;
            this.isSuccess = false;
        },
        handleRemoveRequest() {
            const {
                $route,
                $router,
                pageNumber,
                modalPayload: {
                    id,
                },
                removePreCheckoutPage,
                fetchPreCheckoutPages,
            } = this;

            this.isLoading = true;
            removePreCheckoutPage({pageId: id})
                .then(status => {
                    this.isSuccess = status.code === 0;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        handleCancelCreate() {
            const {
                closeModal,
                clearAllData,
                fetchPreCheckoutPages,
                $route,
                $router,
                pageNumber,
            } = this;
            if (this.isSuccess) {
                fetchPreCheckoutPages({
                    route: $route,
                    router: $router,
                    page: pageNumber,
                });
            }
            closeModal();

            setTimeout(() => {
                clearAllData();
            }, 500);

        },
    },
};

</script>

<style lang="scss" scoped>

.remove-api-keys-modal {
  &__full-width-btn {
    width: 100%;
  }

  &__message {
    font-family: var(--font-family);
    font-style: normal;
    font-size: var(--font-size-h3);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__alias {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-size-h3);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  //::v-deep .at-modal__footer {
  //  padding-left: 16px;
  //  padding-right: 16px;
  //}
}

</style>
