<template>
<div class="order-list-header">
  <div class=" order-list-header__item ">
    id
  </div>
  <div class=" order-list-header__item ">
    {{ $t('orders.order') }}
  </div>
  <!--        <div class=" list-header__item ">-->
  <!--          Аккаунт-->
  <!--        </div>-->
  <div class=" order-list-header__item ">
    {{ $t('orders.network') }}
  </div>
  <div class=" order-list-header__item ">
    {{ $t('orders.currency') }}
  </div>
  <div class=" order-list-header__item ">
    {{ $t('orders.received_amount') }}
  </div>
  <div class=" order-list-header__item ">
    {{ $t('orders.amount') }}
  </div>
  <div class=" order-list-header__item ">
    {{ $t('orders.status') }}
  </div>
  <div
      class=" order-list-header__item order-list-header__item--sorting list-header__item--sorting"
      @click="sortByDate"
  >
    {{ $t('orders.date_of_creation') }}
    <Icon
        class="list-header__icon list-header__chart-icon"
        :class="{ 'list-header__chart-icon--asc': sortByDateStatus === 'ASC' }"
        icon="bar-chart"
    />
  </div>
</div>
</template>

<script>

import {mapActions} from 'vuex';

import Icon from "/public/components/elements/Icon";

export default {
    name: 'OrdersListHeader',
    components: {
        Icon,
    },
    data() {
        return {
            sortByDateStatus: 'DESC',
        };
    },
    beforeMount() {
        this.setSortByDateFromUrl();
    },
    methods: {
        ...mapActions('orders', [
            'fetchOrders',
        ]),
        setSortByDateFromUrl() {
            const sortDateFromUrl = this.$route.query.sortDate;

            if (!sortDateFromUrl) {
                return false;
            }

            this.sortByDateStatus = sortDateFromUrl;
        },
        sortByDate() {
            const {
                $route,
                $router,
                sortByDateStatus,
                fetchOrders,
            } = this;

            if (sortByDateStatus === 'DESC') {
                this.sortByDateStatus = 'ASC';
            } else {
                this.sortByDateStatus = 'DESC';
            }

            fetchOrders({
                route: $route,
                router: $router,
                sortDate: this.sortByDateStatus,
            });
        },
    },
};

</script>
<style lang="scss" scoped>
.order-list-header {
  background: var(--main-page-line);
  border-bottom: 1px solid var(--border-color);
  border-top: 1px solid var(--border-color);
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 10px;
  min-height: 46px;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;

  &__item {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: var(--font-size-text-secondary);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }
}

@media screen and (max-width: 1300px) {
  .order-list-header {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 950px) {
  .order-list-header {
    display: none;
  }

}
</style>
