<template>
  <div>
    <preloader v-if="isLoading"/>
    <div v-else class="invoice">
      <AtButton
          class="invoice__all"
          type="primary"
          icon="icon-arrow-left"
          @click="goBack"
      >
        {{ $t('invoice.return') }}
      </AtButton>
      <h1 class="invoice__title">
        {{ $t(`invoice.info_main_title`) }}
      </h1>
      <div
          v-if="hasInvoice"
          class="invoice__info"
      >
        <div class="invoice__info-date">
          <span>{{ createdAt | getFormatDate('DD.MM.YYYY, HH:mm') }}</span>
        </div>
        <div class="invoice__info-description__container">
          <p class="invoice__info-description__info-title">
            {{ $t('invoice.info_title') }}
          </p>
          <p class="invoice__info-description__info-value">
            {{ itemId }}
          </p>
          <p class="invoice__info-description__title">
            {{ $t('invoice.info_description') }}
          </p>
          <p class="invoice__info-description__value">
            {{ merchantDescription }}
          </p>
        </div>
        <div class="invoice__info-container">
          <div class="invoice-hooks__info-container">
                <span class="invoice-hooks__info-item-title">
                    ID:
                </span>
            <span class="invoice-hooks__info-item-value">
                            <CopyString
                                :string="id"
                                :successMessage="id_copied"
                                :failMessage="id_not_copied"
                                :title="notification"
                                canCopy
                            />
                </span>
          </div>
          <div class="invoice-hooks__info-container">
                <span class="invoice-hooks__info-item-title">
                    {{ $t('invoice.order') }}
                </span>
            <span class="invoice-hooks__info-item-value">
              <CopyString
                  :string="orderId"
                  :successMessage="id_copied"
                  :failMessage="id_not_copied"
                  :title="notification"
                  canCopy
              />
            </span>
          </div>
        </div>


        <div class="invoice-detailed-info__container">
          <div class="invoice-detailed-info__header">
            <div class="invoice-detailed-info__header-container">
              <span class="invoice-detailed-info__title">
                {{ $t('invoice.invoice_currency') }}
              </span>
              <div class="invoice-detailed-info__value invoice-detailed-info__value_mobile" v-if="currency">
                <CurrencyImg
                    class="invoice__currency-img"
                    :currency="currency"
                />
                <div class="invoice__currency-description">
                  <span class="invoice__currency-ticker">{{ currencyName }}</span>
                  <!--                <span class="invoice__currency-network">{{ networkId | getNetworkName }}</span>-->
                </div>
              </div>
              <div class="invoice-detailed-info__value invoice-detailed-info__value_mobile" v-else>
                -
              </div>
            </div>
            <div class="invoice-detailed-info__header-container">
              <span class="invoice-detailed-info__title">
                {{ $t('invoice.info_sum') }}
              </span>
              <div class="invoice-detailed-info__value invoice-detailed-info__value_mobile">
                {{ amount }}
              </div>
            </div>
            <div class="invoice-detailed-info__header-container">
              <span class="invoice-detailed-info__title">
                {{ $t('invoice.exchange_rate') }}
              </span>
              <div class="invoice-detailed-info__value invoice-detailed-info__rate invoice-detailed-info__value_mobile" v-if="receivedCurrencyId">
                1 {{ currencyName }}
                <svg class="invoice-detailed-info__rate-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.71 9.29L17.71 5.29C17.6168 5.19676 17.5061 5.1228 17.3843 5.07234C17.2624 5.02188 17.1319 4.99591 17 4.99591C16.7337 4.99591 16.4783 5.1017 16.29 5.29C16.1017 5.47831 15.9959 5.7337 15.9959 6C15.9959 6.2663 16.1017 6.5217 16.29 6.71L18.59 9H7.00002C6.7348 9 6.48045 9.10536 6.29291 9.2929C6.10537 9.48043 6.00002 9.73479 6.00002 10C6.00002 10.2652 6.10537 10.5196 6.29291 10.7071C6.48045 10.8946 6.7348 11 7.00002 11H21C21.1974 10.999 21.3902 10.9396 21.5539 10.8293C21.7176 10.7189 21.845 10.5626 21.92 10.38C21.9966 10.1979 22.0175 9.99718 21.9801 9.80319C21.9428 9.6092 21.8488 9.43063 21.71 9.29ZM17 13H3.00002C2.80259 13.001 2.60988 13.0604 2.44616 13.1707C2.28244 13.2811 2.15504 13.4374 2.08002 13.62C2.00344 13.8021 1.98252 14.0028 2.01989 14.1968C2.05726 14.3908 2.15126 14.5694 2.29002 14.71L6.29002 18.71C6.38298 18.8037 6.49358 18.8781 6.61544 18.9289C6.7373 18.9797 6.86801 19.0058 7.00002 19.0058C7.13203 19.0058 7.26274 18.9797 7.38459 18.9289C7.50645 18.8781 7.61705 18.8037 7.71002 18.71C7.80375 18.617 7.87814 18.5064 7.92891 18.3846C7.97968 18.2627 8.00582 18.132 8.00582 18C8.00582 17.868 7.97968 17.7373 7.92891 17.6154C7.87814 17.4936 7.80375 17.383 7.71002 17.29L5.41002 15H17C17.2652 15 17.5196 14.8946 17.7071 14.7071C17.8947 14.5196 18 14.2652 18 14C18 13.7348 17.8947 13.4804 17.7071 13.2929C17.5196 13.1054 17.2652 13 17 13Z"
                        fill="var(--additional-font-color)"/>
                </svg>
                {{ rate }} {{ receivedCurrencyName }}
              </div>
              <div class="invoice-detailed-info__value invoice-detailed-info__value_mobile" v-else>
                -
              </div>
            </div>
            <div class="invoice-detailed-info__header-container">
              <span class="invoice-detailed-info__title">
                {{ $t('invoice.payment_currency') }}
              </span>
              <div class="invoice-detailed-info__value invoice-detailed-info__value_mobile" v-if="receivedCurrencyId">
                <CurrencyImg
                    class="invoice__currency-img"
                    :currency="receivedCurrency"
                />
                <div class="invoice__currency-description">
                  <span class="invoice__currency-ticker">{{ receivedCurrencyName }}</span>
                  <!--                <span class="invoice__currency-network">{{ networkId | getNetworkName }}</span>-->
                </div>
              </div>
              <div class="invoice-detailed-info__value invoice-detailed-info__value_mobile" v-else>
                -
              </div>
            </div>
            <div class="invoice-detailed-info__header-container">
              <span class="invoice-detailed-info__title">
                {{ $t('invoice.info_pay_sum') }}
              </span>
              <div class="invoice-detailed-info__value invoice-detailed-info__value_mobile" v-if="receivedCurrencyAmount">
                {{ receivedCurrencyAmount }}
              </div>
              <div class="invoice-detailed-info__value invoice-detailed-info__value_mobile" v-else>
                -
              </div>
            </div>
            <div class="invoice-detailed-info__header-container">
              <span class="invoice-detailed-info__title">
                {{ $t('invoice.info_order_amount') }}
              </span>
              <div class="invoice-detailed-info__value invoice-detailed-info__value_mobile" v-if="order">
                {{ order.amount }}
              </div>
              <div class="invoice-detailed-info__value invoice-detailed-info__value_mobile" v-else>
                -
              </div>
            </div>
          </div>
          <div class="invoice-detailed-info__values">
            <div class="invoice-detailed-info__value" v-if="currency">
              <CurrencyImg
                  class="invoice__currency-img"
                  :currency="currency"
              />
              <div class="invoice__currency-description">
                <span class="invoice__currency-ticker">{{ currencyName }}</span>
<!--                <span class="invoice__currency-network">{{ networkId | getNetworkName }}</span>-->
              </div>
            </div>
            <div class="invoice-detailed-info__value" v-else>
              -
            </div>
            <div class="invoice-detailed-info__value">
              {{ amount }}
            </div>
            <div class="invoice-detailed-info__value invoice-detailed-info__rate" v-if="receivedCurrencyId">
              1 {{ receivedCurrencyName }}
                <svg class="invoice-detailed-info__rate-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.71 9.29L17.71 5.29C17.6168 5.19676 17.5061 5.1228 17.3843 5.07234C17.2624 5.02188 17.1319 4.99591 17 4.99591C16.7337 4.99591 16.4783 5.1017 16.29 5.29C16.1017 5.47831 15.9959 5.7337 15.9959 6C15.9959 6.2663 16.1017 6.5217 16.29 6.71L18.59 9H7.00002C6.7348 9 6.48045 9.10536 6.29291 9.2929C6.10537 9.48043 6.00002 9.73479 6.00002 10C6.00002 10.2652 6.10537 10.5196 6.29291 10.7071C6.48045 10.8946 6.7348 11 7.00002 11H21C21.1974 10.999 21.3902 10.9396 21.5539 10.8293C21.7176 10.7189 21.845 10.5626 21.92 10.38C21.9966 10.1979 22.0175 9.99718 21.9801 9.80319C21.9428 9.6092 21.8488 9.43063 21.71 9.29ZM17 13H3.00002C2.80259 13.001 2.60988 13.0604 2.44616 13.1707C2.28244 13.2811 2.15504 13.4374 2.08002 13.62C2.00344 13.8021 1.98252 14.0028 2.01989 14.1968C2.05726 14.3908 2.15126 14.5694 2.29002 14.71L6.29002 18.71C6.38298 18.8037 6.49358 18.8781 6.61544 18.9289C6.7373 18.9797 6.86801 19.0058 7.00002 19.0058C7.13203 19.0058 7.26274 18.9797 7.38459 18.9289C7.50645 18.8781 7.61705 18.8037 7.71002 18.71C7.80375 18.617 7.87814 18.5064 7.92891 18.3846C7.97968 18.2627 8.00582 18.132 8.00582 18C8.00582 17.868 7.97968 17.7373 7.92891 17.6154C7.87814 17.4936 7.80375 17.383 7.71002 17.29L5.41002 15H17C17.2652 15 17.5196 14.8946 17.7071 14.7071C17.8947 14.5196 18 14.2652 18 14C18 13.7348 17.8947 13.4804 17.7071 13.2929C17.5196 13.1054 17.2652 13 17 13Z"
                        fill="var(--additional-font-color)"/>
                </svg>
              {{ rate }} {{ currencyName }}
            </div>
            <div class="invoice-detailed-info__value" v-else>
              -
            </div>
            <div class="invoice-detailed-info__value" v-if="receivedCurrencyId">
              <CurrencyImg
                  class="invoice__currency-img"
                  :currency="receivedCurrency"
              />
              <div class="invoice__currency-description">
                <span class="invoice__currency-ticker">{{ receivedCurrencyName }}</span>
<!--                <span class="invoice__currency-network">{{ networkId | getNetworkName }}</span>-->
              </div>
            </div>
            <div class="invoice-detailed-info__value" v-else>
              -
            </div>
            <div class="invoice-detailed-info__value" v-if="receivedCurrencyAmount">
              {{ receivedCurrencyAmount }}
            </div>
            <div class="invoice-detailed-info__value" v-else>
              -
            </div>
            <div class="invoice-detailed-info__value" v-if="order">
              {{ order.amount }}
            </div>
            <div class="invoice-detailed-info__value" v-else>
              -
            </div>
          </div>
        </div>
        <div class="invoice__payBtn">
          <btn
              @click="goToLink"
          >
            {{ $t('invoice.go_to_invoice') }}
          </btn>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

    import {mapActions, mapGetters, mapState} from 'vuex';

    import AtButton from 'at-ui/src/components/button';
    import CurrencyImg from "/public/components/elements/CurrencyImg";
    import TransactionsListHeader from "/public/components/elements/transactions/TransactionsListHeader";
    import PreviewTransaction from "/public/components/elements/transactions/PreviewTransaction";
    import EmptyTable from "/public/components/elements/EmptyTable";
    import CopyString from "../components/elements/CopyString";
    import {BlockchainLinks} from "../mixins/blockchain-links";
    import BigNumber from "bignumber.js";
    import Preloader from "/public/components/common/uiKit/spinner/index.vue";
    import OrderTransactionHeader from "../components/elements/orders/OrderTransactionHeader";
    import PreviewOrderTransaction from "../components/elements/orders/PreviewOrderTransaction";
    import Btn from "../components/common/btn";

    export default {
    name: 'InvoicePage',
    title: 'invoice.title',
    mixins: [
        BlockchainLinks,
    ],
    components: {
        Btn,
        PreviewOrderTransaction,
        OrderTransactionHeader,
        Preloader,
        AtButton,
        CurrencyImg,
        TransactionsListHeader,
        PreviewTransaction,
        EmptyTable,
        CopyString,
    },
    data() {
        return {
            invoice: null,
            order: null,
        };
    },
    computed: {
        ...mapGetters('app', [
            'getCurrencyNameById',
            'sortedCurrencies',
        ]),
        ...mapState('app', ['isLoading']),
        invoiceId() {
            return this.$route.params.id;
        },
        id () {
            return this.invoice.id;
        },
        orderId () {
            return this.invoice?.orderId ? this.invoice?.orderId : '';
        },
        itemId () {
            return this.invoice.itemId;
        },
        receivedAmountUSD() {
            return this.invoice.receivedAmountUSD;
        },
        currencyName() {
            return this.invoice.currencyName;
        },
        currencyId() {
            return this.invoice.currencyId;
        },
        currency() {
            const {
                currencyId,
                getCurrencyNameById,
            } = this;

            return getCurrencyNameById(currencyId);
        },
        receivedCurrency() {
            const {
                receivedCurrencyId,
                getCurrencyNameById,
            } = this;

            return getCurrencyNameById(receivedCurrencyId);
        },
        merchantOrder() {
            return this.invoice.order || '-';
        },
        merchantDescription() {
            return this.invoice.itemDescription || '-';
        },
        hasInvoice() {
            return Boolean(this.invoice);
        },
        received() {
            return new BigNumber(this.invoice.received).decimalPlaces(8).toFixed();
        },
        receivedCurrencyNetwork() {
            return this.invoice.receivedCurrency;
        },
        receivedCurrencyId() {
            return this.invoice.receivedCurrency;
        },
        receivedCurrencyName() {
            return this.invoice.receivedCurrencyName;
        },
        receivedCurrencyAmount() {
            return new BigNumber(this.invoice.receivedAmount).decimalPlaces(8).toFixed();
        },
        amount() {
            return new BigNumber(this.invoice.amount).decimalPlaces(8).toFixed();
        },
        amountUSD () {
            return new BigNumber(this.invoice.amountUSD).decimalPlaces(8).toFixed();
        },
        address() {
            return this.invoice.address;
        },
        tag() {
            return this.invoice.tag || '-';
        },
        errorWebhook() {
            return this.invoice.errorWebhook || '-';
        },
        successWebhook() {
            return this.invoice.successWebhook || '-';
        },
        returnUrl() {
            return this.invoice.returnUrl || '-';
        },
        createdAt() {
            return this.invoice.createdAt;
        },
        expiresAt() {
            return this.invoice.expiresAt;
        },
        link() {
            return this.invoice.invoiceLink;
        },
        id_copied() {
            return this.$t('copy.id_copied');
        },
        id_not_copied() {
            return this.$t('copy.id_not_copied');
        },
        notification() {
            return this.$t('copy.notification');
        },
        rate() {
            const {
                amount,
                order,
                receivedCurrencyId,
            } = this;

            if(!receivedCurrencyId || !order) return;

            const rate = amount / order.amount;

            return new BigNumber(rate).decimalPlaces(8).toFixed(2);
        },
    },
    async beforeMount() {
        await this.setInvoice();
        await this.setOrder();
    },
    methods: {
        ...mapActions('invoices', [
            'fetchInvoice',
        ]),
        ...mapActions('orders', [
            'fetchOrder',
        ]),
        async setOrder() {
            const {
                orderId,
                fetchOrder,
            } = this;

            if(!orderId) return;

            await fetchOrder({
                id: orderId,
            })
                .then(order => {
                    this.order = order;
                });
        },
        async setInvoice() {
            const {
                invoiceId,
                fetchInvoice,
            } = this;

            await fetchInvoice({
                invoiceId,
            })
                .then(order => {
                    this.invoice = order;
                });
        },
        goToLink() {
            if (this.link) {
                window.open(this.link);
            }
        },
        goBack() {
            if (window.history.length > 2)
                this.$router.go(-1);
            else this.$router.push({name: 'invoices'});
        },
        viewContract() {
            // let link, source;
            //
            // if (getNetworkName(this.order.networkId) === 'Fantom') {
            //     link = `https://ftmscan.com/token/${this.order.contract}`;
            //     source = 'FTMScan';
            // } else if (getNetworkName(this.order.networkId) === 'Ethereum') {
            //     link = `https://etherscan.io/token/${this.order.contract}`;
            //     source = 'EtherScan';
            // } else if (getNetworkName(this.order.networkId) === 'Tron') {
            //     link = `https://tronscan.org/#/token20/${this.order.contract}`;
            //     source = 'TronScan';
            // } else if (getNetworkName(this.order.networkId) === 'Solana') {
            //     link = `https://solscan.io/token/${this.order.contract}`;
            //     source = 'SolScan';
            // } else if (getNetworkName(this.order.networkId) === 'Binance Smart Chain'){
            //     link = `https://www.bscscan.com/address/${this.order.contract}`;
            //     source = 'BSCScan';
            // }
            //
            // if (!link) {
            //     return;
            // }

            window.open(this.contractLink);
        },
    },
};

</script>

<style lang="scss" scoped>

.invoice {
  padding-left: var(--padding-left-container);
  padding-right: var(--padding-right-container);
  padding-top: var(--padding-top-container);
  max-width: 1425px;
  &__info-date {
    text-align: right;
    font-size: 16px;
    padding-top: 46px;
    color: var(--primary-font-color);
    > span {
      margin-right: 79px;
    }
  }
  &__link-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: var(--primary-font-color);
  }

  &__link {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;

    &::v-deep .copy-string__copy {
      color: #8F949A;
    }

    &::v-deep .copy-string__string {
      color: var(--blue-300);
      word-break: break-all;
    }
  }

  &__transactions-list-header {
    min-width: 0;
  }

  &-hooks {

    &__info-container {
      display: grid;
      grid-template-columns: minmax(min-content, 216px) 1fr;
    }

    &__info-item-title {
      background: var(--primary-color);
      border: 1px solid var(--border-color);
      min-height: 72px;
      padding-left: 45px;
      display: flex;
      align-items: center;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      color: var(--primary-font-color);

      &_date {
        font-weight: 500;
      }
    }

    &__info-item-value {
      border: 1px solid var(--border-color);
      min-height: 40px;
      padding-left: 45px;
      display: flex;
      align-items: center;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;

      color: var(--primary-font-color);
    }
  }

  &__link-container {
    min-height: 105px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 45px;
    border-bottom: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
  }

  &-contract {
    &__header {
      display: grid;
      grid-template-columns: 2fr repeat(2, 1fr);
    }

    &__title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: var(--primary-font-color);
    }

    &__value {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: var(--primary-font-color);
      word-break: break-all;

      &_link {
        cursor: pointer;
        color: #0059F7;
      }
    }

    &__title-container {
      min-height: 135px;
      padding-left: 45px;
      border-bottom: 1px solid var(--border-color);
      border-right: 1px solid var(--border-color);
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-right: 10px;

      &:last-of-type {
        border-right: none;
      }
    }
  }

  &-detailed-info {

    &__container {
      padding: 0 79px;
    }

    &__header {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      background: var(--primary-color);
    }

    &__title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: var(--primary-font-color);
      min-height: 75px;
      padding-left: 29px;
      border-bottom: 1px solid var(--border-color);
      border-left: 1px solid var(--border-color);
      border-top: 1px solid var(--border-color);
      display: flex;
      align-items: center;

      &:last-of-type {
        border-right: 1px solid var(--border-color);
      }
    }

    &__values {
      display: grid;
      grid-template-columns: repeat(6 , 1fr);

    }

    &__value {
      min-height: 75px;
      padding-left: 45px;
      border-bottom: 1px solid var(--border-color);
      border-right: 1px solid var(--border-color);
      display: flex;
      align-items: center;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: var(--primary-font-color);

      &_mobile {
        display: none;
      }
      &:first-of-type {
        border-left: 1px solid var(--border-color);
      }
    }
    &__rate {
      padding-left: 0;
      justify-content: center;
      &-icon {
        margin: 0 5px;
      }
    }
  }

  &__info-description {
    &__container {
      min-height: 175px;
      padding: 10px 79px 50px 79px;
      @media (max-width: 575px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
      }
    }

    &__title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 23px;
      line-height: 16px;
      color: var(--primary-font-color);
    }

    &__value {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      color: var(--primary-font-color);
    }
    &__info {
      &-title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 13px;
        line-height: 28px;
        color: var(--primary-font-color);
      }
      &-value {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        color: var(--primary-font-color);
        margin-bottom: 50px;
      }
    }
  }

  &-basic-info {


    &__title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: var(--primary-font-color);
    }

    &__values {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }

    &__value {
      min-height: 75px;
      border-bottom: 1px solid var(--border-color);
      border-right: 1px solid var(--border-color);
      display: flex;
      align-items: center;
      padding-left: 45px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: var(--primary-font-color);

      &-mobile {
        display: none;
      }

      &:last-of-type {
        border-right: none;
      }


    }


    &__header {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      background: var(--primary-color);

      &-item {
        display: flex;
        gap: 18px;
        align-items: center;
        padding-left: 45px;
        min-height: 75px;
        border-bottom: 1px solid var(--border-color);
        border-right: 1px solid var(--border-color);

        &:last-of-type {
          border-right: none;
        }
      }
    }

    &__icon {
      width: 40px;
      height: 40px;
      background: #E7F0FF;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__transactions-list {
    z-index: 5;
    overflow-x: scroll;
    padding-bottom: 25px;
  }

  &__all {
    background: var(--blue-state-color);
    margin-bottom: 16px;
    border-radius: 6px;
    border: none;
    width: 100%;
    max-width: 125px;
    min-height: 40px;
    transition: all 0.3s ease-in;

    &:hover {
      background: var(--blue-state-color);
    }

    ::v-deep .at-btn__icon {
      font-size: var(--font-size-text-main);
    }

    ::v-deep .at-btn__text {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: var(--white-100);
    }

    ::v-deep .at-btn__icon {
      font-size: var(--font-size-text-main);
    }

  }


  &__title {
    font-family: var(--font-family);
    font-size: var(--font-size-h1);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    font-weight: normal;
    @media (max-width: 575px) {
      text-align: center;
    }
  }

  &__sub-title {

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    padding-left: 45px;
    color: var(--primary-font-color);
    margin-bottom: 30px;
  }

  &__info {
    background: var(--secondary-color);
    border-radius: 20px;
    overflow: hidden;
    box-shadow: var(--shadow-container);
    margin-bottom: var(--margin-container-bottom);
    margin-top: var(--margin-container-top);
  }

  &__info-item {
    margin-bottom: 12px;
    display: flex;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__info-item-title {
    margin-right: 8px;
  }

  &__info-item-value {
    font-weight: 700;

    &.link {
      color: #5ECAE5;

      cursor: pointer;
    }

    &--currency {
      display: flex;
      align-items: center;
    }
  }

  &__info-container {
    min-height: 72px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 72px;
    grid-gap: 22px;
    padding: 0 79px;
    margin-bottom: 23px;
  }

  &__currency {
    &-img {
      margin-right: 8px;
      width: 24px;
      height: 24px;
    }
    &-description {
      display: flex;
      flex-direction: column;
    }
    &-ticker {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
    &-network {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: var(--secondary-font-color);
    }
  }

  &__transactions {
    padding-top: 44px;
    padding-bottom: 43px;
  }

  &__no-data {
    text-align: center;
    font-size: 18px;
    font-family: 'Roboto';
  }

  &__payBtn {
    padding-left: 79px;
    margin-top: 88px;
    margin-bottom: 47px;
  }
}

@media screen and (max-width: 1180px) {
  .invoice {
    padding-top: 36px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 950px) {
  .invoice {
    padding-right: 10px;
    padding-left: 10px;
    &__info-date {
      text-align: center;
      margin-bottom: 50px;
      padding-top: 20px;
      > span {
        margin-right: 0;
      }
    }
    &__sub-title {
      padding-left: 10px;
    }

    &-basic-info {
      &__header {
        display: flex;
        flex-direction: column;
        background:var(--secondary-color);

        &-item {
          padding-left: 10px;
        }
      }

      &__values {
        display: none;
      }

      &__value-mobile {
        display: block;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: var(--primary-font-color);
      }

      &__mobile-container {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    &-detailed-info {

      &__container {
        padding: 0 10px;
      }

      &__title {
        min-height: 50px;
        min-width: 150px;
        padding-left: 10px;
        font-size: 14px;
        line-height: 16px;
        background: var(--primary-color);
      }

      &__header {
        background: var(--secondary-color);
        display: flex;
        flex-direction: column;

        &-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-height: 50px;
          border: 1px solid var(--border-color);
        }
      }

      &__values {
        display: none;
      }

      &__value {
        min-height: 0;
        padding-right: 10px;
        padding-left: 0;
        border: none;
        &_mobile {
          display: flex;
          border: none;
          font-size: 14px;
          line-height: 16px;
        }
      }
      &__rate {
        padding-left: 0;
        justify-content: center;
        font-size: 12px;
        padding-right: 0 !important;
        &-icon {
          margin: 0 5px;
          width: 16px;
        }
      }
    }


    &__info-description__container {
      padding-left: 10px;
      min-height: 100px;
      text-align: center;
      &__info {
        &-value {
          margin-bottom: 17px;
        }
      }
    }

    &-contract {

      &__header {
        display: flex;
        flex-direction: column;
      }

      &__title-container {
        min-height: 76px;
        padding-left: 10px;
        padding-right: 10px;
        gap: 10px;
      }

      &__value {
        font-size: 14px;
        line-height: 16px;

      }
    }

    &__link {
      font-size: 14px;
      line-height: 21px;
    }

    &__link-container {
      padding-left: 10px;
      padding-right: 10px;
      min-height: 76px;
      gap: 10px;
    }

    &__info-container {
      //min-height: 280px;
      padding: 0 10px;
      grid-template-columns: 1fr;
      grid-template-rows: 40px 40px;
      margin-top: 30px;
    }

    &-hooks {
      &__info-container {
        min-height: 40px;
        padding-left: 0;
        padding-right: 0;
      }

      &__info-item-title {
        padding-left: 10px;
        font-size: 12px;
        min-height: 40px;
      }

      &__info-item-value {
        padding-left: 10px;
        font-size: 12px;
      }
    }
    &__payBtn {
      padding: 0 10px;
      margin-top: 30px;
      > button {
        width: 100%;
      }
    }
  }

}
@media screen and (max-width: 345px) {
  .invoice {
    &-detailed-info {
      &__rate {
        padding-left: 0;
        justify-content: center;
        font-size: 12px;
        padding-right: 0 !important;

        &-icon {
          margin: 0 5px;
          width: 16px;
        }
      }
    }
  }
}
</style>
