<template>
  <div class="transaction-info">

    <h1 class="transaction-info__title">
      {{ $t('transaction.title') }}
    </h1>
    <div class="transaction-info__info-container">
      <div class="transaction-info__item" v-if="type">

            <span class="transaction-info__item-title">
            {{ $t('transaction.type') }}
            </span>
        <span class="transaction-info__item-value">
                {{ $t(type) }}
            </span>
      </div>
      <div class="transaction-info__item" v-if="status">
            <span class="transaction-info__item-title">
            {{ $t('transaction.status') }}
            </span>
        <span class="transaction-info__item-value">
                {{ $t(status) }}
            </span>
      </div>
      <div class="transaction-info__item">
            <span class="transaction-info__item-title">
            {{ $t('transaction.id_in_the_system') }}
            </span>


        <CopyString
            class="transaction-info__item-value"
            :string="id"
            :successMessage="$t('copy.id_copied')"
            :failMessage="$t('copy.id_not_copied')"
            :title="$t('copy.notification')"
            canCopy
        />
      </div>

      <div class="transaction-info__item" v-if="orderId">
            <span class="transaction-info__item-title">
            {{ $t('transaction.order_id') }}
            </span>
        <CopyString
            class="transaction-info__item-value"
            :string="orderId"
            :successMessage="$t('copy.id_copied')"
            :failMessage="$t('copy.id_not_copied')"
            :title="$t('copy.notification')"
            canCopy
        />

      </div>
      <div class="transaction-info__item">
            <span class="transaction-info__item-title">
            {{ $t('transaction.network') }}
            </span>
        <span class="transaction-info__item-value">
                {{ networkId | getNetworkName }}
            </span>
      </div>
      <div class="transaction-info__item" v-if="data.currencyId">
            <span class="transaction-info__item-title">
            {{ $t(typeCurrenncy) }}
            </span>
        <span class="transaction-info__item-value transaction-info__item-value--currency">
                <CurrencyImg
                    class="transaction-info__currency-img"
                    :currency="data.currencyId"
                />
                {{ data.currencyId | getCurrencyTicker }}
            </span>
      </div>
      <div class="transaction-info__item" v-if="tagFrom">
            <span class="transaction-info__item-title">
            {{ $t('transaction.the_tag_of_the_write-off_address') }}
            </span>
        <span class="transaction-info__item-value">
                {{ tagFrom }}
            </span>
      </div>
      <div class="transaction-info__item" v-if="addressFrom">
            <span class="transaction-info__item-title">
            {{ $t('transaction.write-off_address') }}
            </span>
        <span class="transaction-info__item-value">
                {{ addressFrom }}
            </span>
      </div>
      <div class="transaction-info__item" v-if="credit">
            <span class="transaction-info__item-title">
            {{ $t('transaction.write-off_amount') }}
            </span>
        <span class="transaction-info__item-value">
                {{ credit }}
            </span>
      </div>
      <!--      -->
      <!--      <div class="transaction-info__item" v-if="deposit">-->
      <!--            <span class="transaction-info__item-title">-->
      <!--            {{ $t('transaction.replenishment_currency') }}-->
      <!--            </span>-->
      <!--        <span class="transaction-info__item-value transaction-info__item-value&#45;&#45;currency">-->
      <!--                <CurrencyImg-->
      <!--                    class="transaction-info__currency-img"-->
      <!--                    :currency="deposit"-->
      <!--                />-->
      <!--                {{ deposit | getCurrencyTicker }}-->
      <!--            </span>-->
      <!--      </div>-->
      <!--      -->
      <div class="transaction-info__item" v-if="tagTo">
            <span class="transaction-info__item-title">
            {{ $t('transaction.the_tag_of_the_enrollment_address') }}
            </span>
        <span class="transaction-info__item-value">
                {{ tagTo }}
            </span>
      </div>
      <div class="transaction-info__item" v-if="addressTo">
            <span class="transaction-info__item-title">
            {{ $t('transaction.transfer_address') }}
            </span>
        <CopyString
            class="transaction-info__item-value"
            :string="addressTo"
            :successMessage="$t('copy.addresses_copied')"
            :failMessage="$t('copy.addresses_not_copied')"
            :title="$t('copy.notification')"
            canCopy
        />
      </div>
      <div class="transaction-info__item" v-if="data.amount">
            <span class="transaction-info__item-title">
            {{ $t('transaction.transfer_amount') }}
            </span>
        <span class="transaction-info__item-value">
                {{ data.amount }}
            </span>
      </div>
      <div class="transaction-info__item" v-if="data.amountUSD">
            <span class="transaction-info__item-title">
            {{ $t('transaction.transfer_amount_USD') }}
            </span>
        <span class="transaction-info__item-value">
                {{ data.amountUSD }}
            </span>
      </div>
      <div class="transaction-info__item" v-if="data.priceUSD">
            <span class="transaction-info__item-title">
            {{ $t('transaction.transfer_amount_rate') }}
            </span>
        <span class="transaction-info__item-value">
                {{ data.priceUSD }}
            </span>
      </div>
      <div class="transaction-info__item" v-if="data.recipients.length > 0">
            <span class="transaction-info__item-title transaction-info__item-title_start">
            {{ $t('transaction.transfer_recipients') }}
            </span>
        <div class="transaction-info__item-container" v-for="recipient in data.recipients" :key="recipient">
          <CopyString
              class="transaction-info__item-value"
              :string="recipient"
              :successMessage="$t('copy.addresses_copied')"
              :failMessage="$t('copy.addresses_not_copied')"
              :title="$t('copy.notification')"
              canCopy
          />
        </div>
      </div>
      <div class="transaction-info__item" v-if="data.senders.length > 0">
            <span class="transaction-info__item-title transaction-info__item-title_start">
            {{ $t('transaction.transfer_senders') }}
            </span>
        <div class="transaction-info__item-container" v-for="recipient in data.senders" :key="recipient">
          <CopyString
              class="transaction-info__item-value"
              :string="recipient"
              :successMessage="$t('copy.addresses_copied')"
              :failMessage="$t('copy.addresses_not_copied')"
              :title="$t('copy.notification')"
              canCopy
          />
        </div>
      </div>

      <div class="transaction-info__item transaction-info__item_transaction" v-if="data.transactionHash">
            <span class="transaction-info__item-title">
            {{ $t('transaction.transaction_in_the_blockchain') }}
            </span>
        <CopyString
            class="transaction-info__item-value"
            :string="data.transactionHash"
            :successMessage="$t('copy.transaction_hash')"
            :failMessage="$t('copy.transaction_hash_failed')"
            :title="$t('copy.notification')"
            :hash="false"
            canCopy
        />
      </div>
    
      <div class="transaction-info__item " v-if="createdAt">
            <span class="transaction-info__item-title">
            {{ $t('transaction.date_of_creation') }}
            </span>
        <span class="transaction-info__item-value">
                {{ createdAt | getFormatDate }}
            </span>
      </div>

    </div>
  </div>
</template>

<script>

import {mapState} from 'vuex';

import CurrencyImg from "/public/components/elements/CurrencyImg";
import CopyString from "../../../components/elements/CopyString";
import {
    orderTransactionType,
    orderTransactionTypeCurrency,
    transactionHistoryStatus,
    transactionHistoryType,
} from "../../../filters";
import BigNumber from "bignumber.js";

export default {
    name: 'OrderTransactionInfo',
    components: {
        CurrencyImg,
        CopyString,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState('app', {
            currencies: state => state.currencies,
        }),
        id() {
            return this.data.id;
        },
        networkId() {
            return this.data.networkId;
        },
        status() {
            return transactionHistoryStatus(this.data.status);
        },
        type() {
            return orderTransactionType(this.data.type);
        },
        typeCurrenncy() {
            return orderTransactionTypeCurrency(this.data.type);
        },
        credit() {
            return this.data.credit;
        },
        deposit() {
            return this.data.deposit;
        },
        amountFrom() {
            return this.data.credit ? BigNumber(this.data.credit).decimalPlaces(8) : '0.00';
        },
        amountTo() {
            return this.data.deposit ? BigNumber(this.data.deposit).decimalPlaces(8) : '0.00';
        },
        addressFrom() {
            return this.data.addressFrom;
        },
        tagFrom() {
            return this.data.tagFrom;
        },
        addressTo() {
            return this.data.addressTo;
        },
        tagTo() {
            return this.data.tagTo;
        },
        txId() {
            return this.data.txId;
        },
        createdAt() {
            return this.data.createdAt;
        },
        orderId() {
            return this.data.orderId;
        },
    },
};

</script>

<style scoped lang="scss">
.transaction-info {
  max-width: 764px;
  border-radius: 20px;
  box-shadow: 0px 8px 50px rgba(10, 17, 105, 0.08);


  &__title {
    min-height: 107px;
    background: #F9F9F9;
    border: 1px solid #F2F2F2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 60px;
    border-radius: 20px 20px 0 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
  }

  &__info-container {
    background: #FFFFFF;
    padding-left: 60px;
    padding-right: 60px;
    border-radius: 0 0 20px 20px;
  }

  &__item {
    min-height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F2F2F2;
    padding-bottom: 16px;
    padding-top: 16px;

    &:last-of-type {
      border: none;
      padding-bottom: 40px;
    }

    &_transaction {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &__item-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    &_start {
      align-self: flex-start;
    }
  }

  &__item-container {
    display: grid;
    gap: 10px;
  }

  &__item-value {
    white-space: normal;
    word-break: break-all;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #2C405A;
  }

}

@media screen and (max-width: 950px) {
  .transaction-info {
    &__title {
      padding-left: 10px;
      min-height: 47px;
      font-size: 16px;
      line-height: 19px;
    }

    &__info-container {
      padding-left: 10px;
      padding-right: 10px;
    }

    &__item {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
    }

    &__item-value {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }

    &__item-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }

}


</style>

