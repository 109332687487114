export const state = {
    payoutBalances: [],
    fee: {
        pending: false,
        complete: false,

        error: null,
        code: null,
        source: null,
        currencyId: null,
        currencyTicker: null,
        amount: null,
        usdAmount: null,
        until: null,
        token: null,
        networkFee: null,
        networkFeeUSD: null,
        tariffFee: null,
        tariffFeeUSD: null,
    },
    registryFile: {
        code: null,
        result: {
            errors: null,
            items: [],
            registryId: null,
            title: null,
        },
    },
    registriesData: {
        registriesCountOnPage: 7,
        totalRegistriesCount: 0,
        registries: [],
        registriesItems: [],
    },
    registriesDetailed: {
        registryArray: [],
    },
    deposit: {
        pending: false,
        complete: false,
        error: null,
        code: 0,
        result: {
            id: '',
            networkId: '',
            currencyId: '',
            balance: '',
            address: '',
            tag: '',
        },
    },
};
