import createTokenAuthMutation from "../../../../graphQl/mutations/createTokenAuth";

export const createTokenAuth = async ({commit, dispatch}, payload) => {
    let createTokenAuthData;

    try {
        createTokenAuthData = await dispatch('app/request', {
            type: 'mutation',
            gql: createTokenAuthMutation,
            variables: payload,
        }, {root: true});
    } catch (error) {
        return error;
    }

    if (createTokenAuthData.message) {
        throw createTokenAuthData.message;
    }

    if (createTokenAuthData.errors) {
        throw createTokenAuthData.errors;
    }

    const {
        data: {
            createTokenAuth: {
                code,
                result,
            },
        },
    } = createTokenAuthData;

    commit('changeReserveCodes', {code, result});

    return code;
};