<script>
  import {defineComponent} from 'vue'
  import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
  import WidgetsDemoLinkBlock from "@/public/components/elements/widgets-manage/settings/WidgetsDemoLinkBlock.vue";
  import {mapState} from "vuex";
  import {
    COLORS,
    PAYIN_CRYPTO_TYPE_INVOICE,
    PAYIN_CRYPTO_TYPE_PERSONAL,
    STEP_PAY_SELECT,
    STEP_START_PAGE, STEP_WIDGET
  } from "@/public/pages/widgets/constants";
  import BaseInput from "@/public/pages/integration-settings/components/Input/index.vue";

  export default {
    name: "WidgetsDemoScriptBlock",
    components: {BaseInput, WidgetsDemoLinkBlock, BaseButton},
    data () {
      return {
        isApollo: import.meta.env.VITE_PRODUCT_NAME === 'Apollopayment',
        copied: null,
        buttonText: "Deposit",
      }
    },
    COLORS,
    methods: {
      async copyClipboard(value, type) {
        await navigator.clipboard.writeText(value)
        this.copied = type
        setTimeout(() => this.copied = null, 2000)
      },
    },
    computed: {
      ...mapState('widgetsManage', ['widget', 'currentStep', "demo"]),
      buttonTagName () {
        return this.isApollo ? 'apollo-payment-button' : 'ocp-payment-button'
      },
      scriptLink () {
        return this.isApollo ? 'https://cdn.apollopayment.io/images/ApolloPaymentButton.js' : 'https://cdn.onchainpay.io/images/OCPPaymentButton.js'
      },
      generateApolloPaymentButton(data) {
        const {
          payinCryptoInvoiceCurrencies,
          payinCryptoInvoiceId,
          payinCryptoInvoiceDesc,
          payinCryptoInvoiceAmount,
          payinCryptoInvoicePayerEmail,
          payoutCryptoReceiverEmail,
          payoutCryptoAmountFrom,
          payoutCryptoCurrenciesFrom,
          payinCryptoCurrenciesTo,
          accentColor,
        } = this.demo;

        const {
          payinCryptoInvoicePayerEmailRequired,
          payinCryptoInvoicePayerEmailAllow,
          payinCryptoWebhook,
          payinCrypto,
          payoutCrypto,
          showStartPage,
        } = this.widget

        const payinParams = {
          payincryptoinvoiceamount: payinCryptoInvoiceCurrencies.length === 1 ? payinCryptoInvoiceAmount : '',
          payincryptoinvoicecurrencies: payinCryptoInvoiceCurrencies,
          payincryptocurrenciesto: payinCryptoCurrenciesTo,
          payincryptoinvoiceid: payinCryptoInvoiceId,
          payincryptoinvoicedesc: payinCryptoInvoiceDesc,
          payincryptoinvoicepayeremail: payinCryptoInvoicePayerEmail,
          payincryptowebhook: payinCryptoWebhook,
          label: this.buttonText,
          accentcolor: accentColor
        }

        const payoutParams = {
          payoutcryptoreceiveremail: payoutCryptoReceiverEmail,
          payoutcryptoamountfrom: payoutCryptoAmountFrom,
          payoutcryptocurrenciesfrom: payoutCryptoCurrenciesFrom,
          accentcolor: accentColor,
          label: this.buttonText,
        }

        // Функция для преобразования объектов в строку URL параметров
        const objectToUrlParams = (obj) => {
          const params = new URLSearchParams();

          const appendCurrencyParams = (key, array) => {
            if (array) {
              const formatted = array.map(({ currency, network, networkId }) =>
                  network && networkId ? `${currency}_${network}` : currency
              ).join(',');
              if (formatted) params.append(key, formatted);
            }
          };

          // Добавляем валюты с помощью вспомогательной функции
          appendCurrencyParams('payincryptocurrenciesto', obj.payincryptocurrenciesto);
          appendCurrencyParams('payincryptoinvoicecurrencies', obj.payincryptoinvoicecurrencies);
          appendCurrencyParams('payoutcryptocurrenciesfrom', obj.payoutCryptoCurrenciesFrom);

          // Добавляем остальные параметры
          Object.entries(obj).forEach(([key, value]) => {
            if (value && !['payincryptocurrenciesto', 'payincryptoinvoicecurrencies', 'payoutcryptocurrenciesfrom'].includes(key)) {
              params.append(key, value);
            }
          });

          return params.toString();
        };

        // Подготовка атрибутов
        const getAttributes = (data) => Object.fromEntries(
            objectToUrlParams({widgetid: this.widget.id,...data}).split('&').map(param => {
              const [key, value] = param.split('=');
              return [`data-${key}`, decodeURIComponent(value)];
            })
        );

        // Фильтрация и создание строки атрибутов
        const getFilteredAttributes = (data) => Object.entries(getAttributes(data))
            .filter(([_, value]) => value)
            .map(([key, value]) => `${key}="${value}"`)  // добавляем отступ для новой строки
            .join('\n');  // каждый атрибут на новой строке

        if (payoutCrypto && !payinCrypto) {
          const params = getFilteredAttributes(payoutParams)
          return `&lt;${this.buttonTagName}\n${params}\n&gt;&lt;/${this.buttonTagName}&gt;`
        }

        if (payoutCrypto && payinCrypto && showStartPage) {
          const params = getFilteredAttributes({ ...payinParams, ...payoutParams })
          return `&lt;${this.buttonTagName}\n${params}\n&gt;&lt;/${this.buttonTagName}&gt;`
        }

        if (payinCrypto && payoutCrypto && !showStartPage) {
          return {
            payin: `&lt;${this.buttonTagName}\n${getFilteredAttributes(payinParams)}\n&gt;&lt;/${this.buttonTagName}&gt;`,
            payout: `&lt;${this.buttonTagName}\n${getFilteredAttributes(payoutParams)}\n&gt;&lt;/${this.buttonTagName}&gt;`
          }
        }

        return `&lt;${this.buttonTagName}\n${getFilteredAttributes(payinParams)}\n&gt;&lt;/${this.buttonTagName}&gt;`
      },
      paymentButtonWithArrows() {
        const {
          payinCrypto,
          payoutCrypto,
          showStartPage,
        } = this.widget

        const encodedHtml = this.generateApolloPaymentButton

        if (payinCrypto && payoutCrypto && !showStartPage) {
          return {
            payin: encodedHtml.payin
                .replace(/&lt;/g, '<')  // Замена &lt; на стрелочку влево
                .replace(/&gt;/g, '>'),
            payout: encodedHtml.payout
                .replace(/&lt;/g, '<')  // Замена &lt; на стрелочку влево
                .replace(/&gt;/g, '>'),
          }
        }
        return encodedHtml
            .replace(/&lt;/g, '<')  // Замена &lt; на стрелочку влево
            .replace(/&gt;/g, '>'); // Замена &gt; на стрелочку вправо
      }
    }
  }
</script>

<template>
<div class="script">
  <div class="link-block">
    <div class="script__head">
      <div class="script__title">{{ $t('widgets.iframeIntegrationLink') }}</div>
      <div class="script__desc">{{ $t('widgets.iframeIntegrationLinkDesc') }}</div>
    </div>
    <widgets-demo-link-block />
    <div class="separator">
      <div class="line"></div>
      <div class="text">{{ $t('widgets.or') }}</div>
      <div class="line"></div>
    </div>
  </div>
  <div class="script-block">
    <div class="script__head">
      <div class="script__title">{{ $t('widgets.paymentButtonScript') }}</div>
      <div class="script__desc">{{ $t('widgets.paymentButtonScriptDesc') }}</div>
    </div>
    <div class="script__script">
      <div class="content">
        <div class="content__text">
          &lt;script type="module" src="{{ scriptLink }}"&gt;&lt;/script&gt;
        </div>
      </div>
      <base-button class="smallBtn" @click="copyClipboard(`&lt;script type=&quot;module&quot; src=&quot;${scriptLink}&quot;&gt;&lt;/script&gt;`, 'script')">
        <template #append>
          <svg
              v-if="copied === 'script'"
              class="clipboard-icon"
              width="16"
              height="16"
              viewBox="8 8 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M23.5361 13.2812L15.74 20.7188L11.8486 17" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2C0 0.89543 0.895431 0 2 0H10C11.1046 0 12 0.895431 12 2V4H14C15.1046 4 16 4.89543 16 6V14C16 15.1046 15.1046 16 14 16H6C4.89543 16 4 15.1046 4 14V12H2C0.895431 12 0 11.1046 0 10V2ZM2 1C1.44772 1 1 1.44772 1 2V10C1 10.5523 1.44772 11 2 11H10C10.5523 11 11 10.5523 11 10V2C11 1.44772 10.5523 1 10 1H2Z" fill="white"/>
          </svg>
        </template>
        <template #label>
          <span class="label">
            {{ copied === 'script' ? $t('widgets.copied') : $t('widgets.copyLink') }}
          </span>
        </template>
      </base-button>
    </div>
  </div>
  <div class="script-block">
    <div class="script__head">
      <div class="script__title">{{ $t('widgets.buttonPreview') }}</div>
      <div class="script__desc">{{ $t('widgets.buttonPreviewDesc') }}</div>
    </div>
    <div class="script__buttonPreview">
      <base-input type="text" max-length="28" v-model="buttonText" :placeholder="$t('widgets.enterButtonLabel')" />
      <div class="script__buttonPreview-btn" :style="{ background: $options.COLORS[demo.accentColor] }">{{ buttonText }}</div>
    </div>
  </div>
  <div v-if="!widget.showStartPage && widget.payinCrypto && widget.payoutCrypto" class="script-block">
    <div class="script__head">
      <div class="script__title">{{ $t('widgets.paymentButtonTag') }}</div>
      <div class="script__desc">{{ $t('widgets.paymentButtonDesc') }}</div>
    </div>
    <div class="script__script-subtitle">
      {{ $t('widgets.receiveCodeDesc') }}
    </div>
    <div class="script__script first">
      <div class="content">
        <div class="content__text" >
          <pre v-html="generateApolloPaymentButton.payin"></pre>
        </div>
      </div>
      <base-button class="smallBtn" @click="copyClipboard(paymentButtonWithArrows.payin, 'payin')">
        <template #append>
          <svg
              v-if="copied === 'payin'"
              class="clipboard-icon"
              width="16"
              height="16"
              viewBox="8 8 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M23.5361 13.2812L15.74 20.7188L11.8486 17" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2C0 0.89543 0.895431 0 2 0H10C11.1046 0 12 0.895431 12 2V4H14C15.1046 4 16 4.89543 16 6V14C16 15.1046 15.1046 16 14 16H6C4.89543 16 4 15.1046 4 14V12H2C0.895431 12 0 11.1046 0 10V2ZM2 1C1.44772 1 1 1.44772 1 2V10C1 10.5523 1.44772 11 2 11H10C10.5523 11 11 10.5523 11 10V2C11 1.44772 10.5523 1 10 1H2Z" fill="white"/>
          </svg>
        </template>
        <template #label>
          <span class="label">
            {{ copied === 'payin' ? $t('widgets.copied') : $t('widgets.copyLink') }}
          </span>
        </template>
      </base-button>
    </div>
    <div class="script__script-subtitle">
      {{ $t('widgets.payoutCodeDesc') }}
    </div>
    <div class="script__script">
      <div class="content">
        <div class="content__text" >
          <pre v-html="generateApolloPaymentButton.payout"></pre>
        </div>
      </div>
      <base-button class="smallBtn" @click="copyClipboard(paymentButtonWithArrows.payout, 'payout')">
        <template #append>
          <svg
              v-if="copied === 'payout'"
              class="clipboard-icon"
              width="16"
              height="16"
              viewBox="8 8 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M23.5361 13.2812L15.74 20.7188L11.8486 17" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2C0 0.89543 0.895431 0 2 0H10C11.1046 0 12 0.895431 12 2V4H14C15.1046 4 16 4.89543 16 6V14C16 15.1046 15.1046 16 14 16H6C4.89543 16 4 15.1046 4 14V12H2C0.895431 12 0 11.1046 0 10V2ZM2 1C1.44772 1 1 1.44772 1 2V10C1 10.5523 1.44772 11 2 11H10C10.5523 11 11 10.5523 11 10V2C11 1.44772 10.5523 1 10 1H2Z" fill="white"/>
          </svg>
        </template>
        <template #label>
          <span class="label">
            {{ copied === 'payout' ? $t('widgets.copied') : $t('widgets.copyLink') }}
          </span>
        </template>
      </base-button>
    </div>
  </div>
  <div v-else class="script-block">
    <div class="script__head">
      <div class="script__title">{{ $t('widgets.paymentButtonTag') }}</div>
      <div class="script__desc">{{ $t('widgets.paymentButtonDesc') }}</div>
    </div>
    <div class="script__script">
      <div class="content">
        <div class="content__text" >
          <pre v-html="generateApolloPaymentButton"></pre>
        </div>
      </div>
      <base-button class="smallBtn" @click="copyClipboard(paymentButtonWithArrows, 'single')">
        <template #append>
          <svg
              v-if="copied === 'single'"
              class="clipboard-icon"
              width="16"
              height="16"
              viewBox="8 8 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M23.5361 13.2812L15.74 20.7188L11.8486 17" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2C0 0.89543 0.895431 0 2 0H10C11.1046 0 12 0.895431 12 2V4H14C15.1046 4 16 4.89543 16 6V14C16 15.1046 15.1046 16 14 16H6C4.89543 16 4 15.1046 4 14V12H2C0.895431 12 0 11.1046 0 10V2ZM2 1C1.44772 1 1 1.44772 1 2V10C1 10.5523 1.44772 11 2 11H10C10.5523 11 11 10.5523 11 10V2C11 1.44772 10.5523 1 10 1H2Z" fill="white"/>
          </svg>
        </template>
        <template #label>
          <span class="label">
            {{ copied === 'single' ? $t('widgets.copied') : $t('widgets.copyLink') }}
          </span>
        </template>
      </base-button>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.separator {
  position: absolute;
  bottom: 30px;
  left: -30px;
  width: calc(100% + 60px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
  @media (max-width: 850px) {
    left: -15px;
    width: calc(100% + 30px);
  }
  .line {
    width: 100%;
    height: 2px;
    background: var(--border-devider-primary, #353537);
  }
  .text {
    color: var(--text-color-text-secondary, #7E7E7E);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }
}
.script {
  position: relative;
  z-index: 4;
  padding: 20px 30px;
  border-radius: 15px;
  border: 1px solid var(--border-border-neutral-variant);
  background: var(--bg-background-secondary);
  @media (max-width: 850px) {
    padding: 10px 15px;
  }
  &__buttonPreview {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 10px;
    @media (max-width: 850px) {
      grid-template-columns: 1fr;
    }
    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      width: 100%;
      border-radius: 10px;
      background: linear-gradient(153deg, #744DB2 -33.83%, #8D4CF5 33.16%, #744DB2 96.25%);
      color: #FFF;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
  &__script {
    display: grid;
    grid-template-columns: 1fr 48px;
    gap: 10px;
    align-items: start;
    @media (max-width: 850px) {
      grid-template-columns: 1fr;
    }
    &.first {
      margin-bottom: 20px;
      @media (max-width: 850px) {
        margin-bottom: 15px;
      }
    }
    &-subtitle {
      color: var(--text-text-primary);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      margin-bottom: 5px;
      @media (max-width: 850px) {
        font-size: 13px;
      }
    }
    .smallBtn {
      width: 48px;
      @media (max-width: 850px) {
        width: 100%;
      }
    }
    .label {
      @media (min-width: 850px) {
        display: none;
      }
    }
    .content {
      border-radius: 10px;
      background: var(--bg-background-input-secondary, #333);
      padding: 13px 30px;
      max-width: 100%;
      overflow: auto;
      min-height: 48px;
      display: flex;
      align-items: center;
      @media (max-width: 850px) {
        padding: 4px 18px;
      }
      &__text {
        color: var(--text-text-primary);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        @media (max-width: 850px) {
          font-size: 13px;
        }
      }
      //<script type="module" src="/index.js"></script>
    }
  }
  .script-block {
    padding-bottom: 40px;
    @media (max-width: 850px) {
      padding-bottom: 30px;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
  .link-block {
    position: relative;
    padding-bottom: 80px;
  }
  &__head {
    margin-bottom: 20px;
    @media (max-width: 850px) {
      margin-bottom: 15px;
    }
  }
  &__title {
    color: var(--text-text-primary, #FFF);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    margin-bottom: 5px;
    @media (max-width: 850px) {
      font-size: 16px;
    }
  }
  &__desc {
    color: var(--text-color-text-secondary, #7E7E7E);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    @media (max-width: 850px) {
      font-size: 13px;
    }
  }
}
</style>
