<template>
    <div class="exchanges-top-bar row">
        <div class="left-side">
            <ExchangesSortByStatus class="filter-item" />
            <ExchangesSortByCurrency class="filter-item" />
            <ExchangesFilterByDate class="filter-item" />
        </div>
        <div class="right-side">
            <AtButton
                size="small"
                type="primary"
                icon="icon-plus"
                @click="openCreateExchangeModal"
            >
                Создать обмен
            </AtButton>
            <AtButton
                size="small"
                :icon="refreshIcon"
                :loading="isLoading"
                @click="updateRequests"
            />
        </div>
    </div>
</template>

<script>

import { mapActions } from 'vuex';

import AtButton from 'at-ui/src/components/button';
import ExchangesSortByStatus from "./ExchangesSortByStatus";
import ExchangesSortByCurrency from "./ExchangesSortByCurrency";
import ExchangesFilterByDate from "./ExchangesFilterByDate";

export default {
    name: 'ExchangesTopBar',
    components: {
        ExchangesFilterByDate,
        ExchangesSortByCurrency,
        ExchangesSortByStatus,
        AtButton,
    },
    data () {
        return {
            isLoading: false,
        };
    },
    computed: {
        refreshIcon () {
            return this.isLoading ? '' : 'icon-refresh-cw';
        },
    },
    methods: {
        ...mapActions('app', [
            'openModal',
        ]),
        // ...mapActions('balances', [
        //     'fetchBalances',
        // ]),
        ...mapActions('exchanges', [
            'fetchExchanges',
        ]),
        updateRequests () {
            const {
                $route,
                $router,
                fetchExchanges,
            } = this;

            this.isLoading = true;

            fetchExchanges({
                route: $route,
                router: $router,
            })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        openCreateExchangeModal () {
            const {
                // fetchBalances,
                openModal,
            } = this;

            // fetchBalances();
            openModal({
                name: 'CreateExchangeModal',
                payload: { withoutCurrency: true },
            });
        },
    },
};

</script>

<style lang="scss" scoped>

    .exchanges-top-bar {
        justify-content: space-between;
        align-items: flex-end;

        & .left-side {
            width: 50%;

            & .filter-item {
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

</style>
