<template>
<div class="advanced-balances-select-wrapper">
    <div
            v-if="label"
            class="label"
    >
        <slot name="label">
            {{ label }}
        </slot>
    </div>
    <div
            class="input-wrapper"
            :class="{ expanded }"
    >
        <div
                :id="`select-${_uid}`"
                class="input"
                :class="{ 'filled': valueModel && clearable }"
                v-if="!isCustomTrigger"
        >
            <div
                    v-if="!valueModel"
                    class="placeholder"
            >
                <slot name="placeholder">
                    {{ placeholder }}
                </slot>
            </div>
            <div
                    v-else
                    class="selected"
            >
                <slot
                        name="selected"
                        v-bind="valueModel"
                >
                    <div v-if="!multiple">
                        {{ valueModel['label'] || valueModel[uniqKey] }}
                    </div>
                    <div v-else>
              <span>
                {{ valueModel['label'] || valueModel[uniqKey] }}
              </span>
                        <span
                                v-if="value.length > 1"
                                style="padding-left: 5px"
                        >
                +{{value.length - 1}}
              </span>
            </div>
          </slot>
        </div>
        <svg class="chevron" xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
          <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.228 1.635C0.085 1.462 0 1.241 0 1C0 0.448 0.448 0 1 0H7C7.552 0 8 0.448 8 1C8 1.241 7.915 1.462 7.772 1.635L4.808 5.589C4.626 5.838 4.332 6 4 6C3.668 6 3.374 5.838 3.192 5.589L0.228 1.635Z"
              :fill="themeStatus === 'dark' ? 'white' : 'black'"
          />
        </svg>
            <div v-if="clearable" class="clear-wrapper" @click="clear">
                <svg
                        class="clear"
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                >
                    <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.96967 13.0303C0.676777 12.7374 0.676777 12.2626 0.96967 11.9697L5.93934 7L0.96967 2.03033C0.676777 1.73744 0.676777 1.26256 0.96967 0.96967C1.26256 0.676777 1.73744 0.676777 2.03033 0.96967L7 5.93934L11.9697 0.969671C12.2626 0.676777 12.7374 0.676777 13.0303 0.969671C13.3232 1.26256 13.3232 1.73744 13.0303 2.03033L8.06066 7L13.0303 11.9697C13.3232 12.2626 13.3232 12.7374 13.0303 13.0303C12.7374 13.3232 12.2626 13.3232 11.9697 13.0303L7 8.06066L2.03033 13.0303C1.73744 13.3232 1.26256 13.3232 0.96967 13.0303Z"
                            :fill="themeStatus === 'dark' ? 'white' : 'black'"
                    />
                </svg>
            </div>
      </div>
      <slot name="trigger"  v-bind="{ id: `select-${_uid}` }" />
      <slot name="options-list">
        <div
          v-if="expanded"
          class="options-list"
          :class="{ flat, 'with-search': hasSearchSlot }"
        >
          <div>
            <slot name="search" />
          </div>
          <template v-if="list.length">
            <div class="list">
              <div
                  v-for="item in list"
                  class="list__item"
                  :class="{ 'active': valueModel && item[uniqKey] === valueModel[uniqKey] }"
                  @click="valueModel = item"
              >
                <slot name="option">
                  <div class="info">
                    <slot
                        name="info"
                        v-bind="{...item, active: valueModel && item[uniqKey] === valueModel[uniqKey]}"
                    >
                      {{ item[uniqKey] }}
                    </slot>
                  </div>
                  <div class="action">
                    <slot
                        name="action"
                        v-bind="item"
                    >
                      <svg
                          v-if="valueModel && item[uniqKey] === valueModel[uniqKey]"
                          class="check"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="11"
                          viewBox="0 0 15 11"
                          fill="none"
                      >
                        <path
                            d="M13.7499 1.5L5.43737 9.75L1.65894 6"
                            :stroke="themeStatus === 'dark' ? 'white' : '#744DB2'"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                      </svg>
                    </slot>
                  </div>
                </slot>
              </div>
            </div>
          </template>
          <div
            v-else
            class="empty"
          >
            {{ $t('common.empty') }}
          </div>
            <slot name="options-footer" />
        </div>

      </slot>
    </div>
    <slot name="sublabel">
        <div
          v-if="sublabel"
          class="sublabel"
        >
          {{ sublabel }}
        </div>
    </slot>
</div>
</template>

<script>
import AtSelect from 'at-ui/src/components/select';
import {mapState} from "vuex";

export default {
    name: "advanced-balances-select",
    components: {
        AtSelect
    },
    data() {
        return {
            expanded: false
        }
    },
    props: {
        label: {
            type: String,
            default: undefined
        },
        sublabel: {
            type: String,
            default: undefined
        },
        placeholder: {
            type: String,
            default: undefined
        },
        list: {
            type: Array,
            default: () => ([])
        },
        value: [String, Array, Object],
        clearable: {
            type: Boolean,
            default: false
        },
        //пропс отвечает за представление листа без паддингов - обычное представление списка
        flat: {
            type: Boolean,
            default: false
        },
        //пока заготовка под мультиселект - множетсвенный выбор не реализован до конца
        multiple: {
            type: Boolean,
            default: false
        },
        //пропс отвечает за представление листа c полем поиска
        withSearch: {
            type: Boolean,
            default: false
        },
        //пропс отвечает за уникальный ключ для списка
        uniqKey: {
            type: String,
            default: 'id'
        },
        // Пропс отвечает за применение слота вместо инпута, например: для создания кастомного триггера для адресной книги
        isCustomTrigger: {
            type: Boolean,
            default: false
        }
    },
    created() {
        this.addOuterClickListener()
    },
    destroyed() {
        document.removeEventListener("click", this.expandToggler, false);
    },
    computed: {
        ...mapState('app', {
          themeStatus: state => state.theme
        }),
        hasSearchSlot({$slots}) {
            //проверяем что существует слот search
            return $slots['search']
        },
            valueModel: {
                get({value, multiple}) {
                    if (multiple) {
                        return value[0]
                    } else {
                        return value
                    }
                },
                set(v) {
                    if (this.multiple) {
                        this.$emit('input', [...this.value, v])
                    } else {
                        this.$emit('input', v)
                    }
                }
            }
        },
        methods: {
            addOuterClickListener() {
                document.addEventListener('click', this.expandToggler, false)
            },
            clear () {
                this.$emit('input', '')
                this.valueModel = ''
            },
            expandToggler(el) {
                if (el.target.id === `select-${this._uid}`) {
                    this.$set(this, 'expanded', !this.expanded)
                } else if (el.target.id !== 'search-input') {
                    this.expanded = false
                }

                if (!this.expanded) {
                  this.$emit('closed')
                }
            }
        }
    }
</script>

<style lang="scss">
.advanced-balances-select-wrapper {
  font-family: var(--new-front-font-family);

  .label, .sublabel {
      font-style: normal;
      font-weight: 400;
      font-size: $h6; //13px
      line-height: $h6;

    padding-bottom: 5px;

    color: var(--new-front-input-label-font-color)!important;
  }

  .label {
    padding-bottom: 5px;
  }
  .sublabel {
    padding-top: 5px;
  }

  .input-wrapper {
    position: relative;

    .input {
      display: flex;
      align-items: center;

      width: 100%;

      min-height: 47px;
      line-height: 47px;
      background: transparent;
      border: 1px solid var(--new-front-input-border);
      box-shadow: inset 0 4px 20px var(--new-front-gradient-bg);
      border-radius: 12px;
      padding: 0 20px;

      cursor: pointer;

        font-style: normal;
        font-weight: 300;
        font-size: $h4; //16px
        line-height: 16px;

      .selected {
        width: 100%;
        font-weight: 500;
        pointer-events: none;
        color: var(--new-front-primary-font-color);
      }

      .placeholder {
        pointer-events: none;
        font-weight: 500;
        color: var(--new-front-secondary-font-color);
      }

      &.filled {
        &:hover {
          svg.chevron {
            display: none;
          }
          .clear-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
          }
          svg.clear {
            display: flex;
          }
        }
      }


      svg.chevron {
        position: absolute;
        top: 50%;
        right: 20px;

        display: block;

        transition: all .3s;
        transform: translateY(-50%) rotate(0);

        width: 10px;

        pointer-events: none;
      }

      svg.clear {
        display: none;

        position: absolute;
        top: 50%;
        right: 20px;

        transform: translateY(-50%);

        width: 10px;
      }
      .clear-wrapper {
        display: none;
        position: absolute;
        right: 0;
      }
    }

    .options-list {
      transition: all .3s;

      position: absolute;
      top: 57px;
      left: 0;

      opacity: 0;
      pointer-events: none;

      width: 100%;

      color: var(--new-front-primary-font-color);
      background: var(--new-front-main-bg);

      border: 1px solid var(--new-front-input-border);
      border-radius: 12px;

      z-index: 5;

      padding: 20px;

      .list {
        max-height: 300px;

        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 0; /* width of the entire scrollbar */
        }

        &::-webkit-scrollbar-track {
          background: transparent; /* color of the tracking area */
        }

        &::-webkit-scrollbar-thumb {
          background: transparent; /* color of the tracking area */
          width: 0;
        }

        &__item {
          width: 100%;
          margin: 5px 0;

          font-style: normal;
          font-weight: 500;

          padding: 15px;

          display: flex;
          align-items: center;
          justify-content: space-between;

          cursor: pointer;

          border: 1px solid var(--new-front-input-border);
          border-radius: 12px;

          color: var(--new-front-primary-font-color);

          &:hover {
            transition: all .3s;
            background: var(--new-front-select-item-bg-active);
            color: var(--new-front-primary-font-color);
          }

          &.active {
            background: var(--new-front-select-item-bg-active);
          }

          .info {
            width: -webkit-fill-available;
            width: 100%;
          }

          .action {
            width: fit-content;
            white-space: nowrap;

            svg.check {
              width: 13px;
            }
          }
        }
      }

      &.with-search {
        .list {
          max-height: 190px;
        }
      }

      &.flat {
        padding: 0;
        .options-list {
          .list {
            &__item {
              box-shadow: none;
              border-radius: 0;
              margin: 0;
            }
          }
        }
      }

      .empty {
        color: #8F949A;
      }
    }

    &.expanded {
      svg.chevron {
        transition: all .3s;

        transform: translateY(-50%) rotate(180deg);
      }

      .options-list {
        opacity: 1;
        pointer-events: visible;
      }
    }
  }

    @include below_phone() {
      .label, .sublabel {
        font-size: $h8; //10px
        line-height: $h8;
      }

      .input-wrapper {
        .input {
          padding: 0 10px;
          font-size: $h6; //13px
        }
        .options-list {
          padding: 10px;

        .list {
          max-height: 270px;
        }

        &.with-search {
          .list {
            max-height: 250px;
          }
        }
      }
    }
  }
}

</style>
