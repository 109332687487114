const TRANSACTION_HISTORY_BASIS_ENUM = {
    ORDER: 'order',
    WITHDRAWAL: 'withdrawal',
    DEPOSIT: 'deposit',
    TRANSFER: 'transfer',
    BRIDGE_INTERNAL: 'bridge_internal',
    BRIDGE_EXTERNAL: 'bridge_external',
    EXCHANGE_INTERNAL: 'exchange_internal',
    EXCHANGE_INTERNAL_FEE: 'exchange_internal_fee',
    KYT: 'kyt',
    EXCHANGE_AUTO: 'exchange_AUTO',
    COLLECTING: 'collecting',
    COMMISSION_WITHDRAWAL: 'commission_withdrawal',
    PAYOUT_AUTO_SWAP: 'payout_auto_swap'
    // PAYMENT: 'payment',
    // REFUND: 'refund',
};

export default TRANSACTION_HISTORY_BASIS_ENUM;
