import gql from 'graphql-tag';

export default gql`
    query ($organizationId: String!, $offset: Int!, $limit: Int!) {
         getMerchants(
            organizationId: $organizationId,
            offset: $offset,
            limit: $limit
          ) {
            id
            organizationId
            name
            logoUrl
            minSpendInterval
            maxSpendAmountUSD
            maxMonthSpendAmountUSD
            approveAmountUSD
            allowFreeSpend
            createdAt
          }
       }
`;
