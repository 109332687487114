<script>

  export default {
    name: "WidgetsThemeSelect",
    props: {
      value:  {
        type: String,
        default: () => ({})
      },
      isFiat: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      innerModel: {
        get({ value, list }) {
          return value
        },
        set(v) {
          this.$emit('input', v)
        }
      },
    }
  }
</script>

<template>
<div class="themes-select">
  <div :class="['theme', { active: value === 'dark' }]" @click="$emit('input', 'dark')">
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.5009 0.834912C19.0475 1.50131 19.2341 2.50563 18.7421 3.40838C17.0603 6.49422 16.1045 10.0291 16.1045 13.7906C16.1045 25.8519 25.9409 35.6181 38.0605 35.6181C39.6397 35.6181 41.1782 35.4526 42.6603 35.1386C43.6736 34.924 44.5952 35.3821 45.0893 36.0858C45.5991 36.812 45.7059 37.8954 44.9955 38.7673C40.4101 44.3955 33.3943 48 25.5307 48C11.7003 48 0.5 36.859 0.5 23.1301C0.5 12.7974 6.84244 3.9359 15.8706 0.180263C16.9137 -0.253653 17.9369 0.14728 18.5009 0.834912Z" fill="#6750A4"/>
      <path d="M32.883 9.44472C33.0807 8.85176 33.9194 8.85176 34.1171 9.44473L35.2789 12.9303C35.7968 14.484 37.016 15.7032 38.5697 16.2211L42.0553 17.383C42.6483 17.5806 42.6483 18.4194 42.0553 18.617L38.5697 19.7789C37.016 20.2968 35.7968 21.516 35.2789 23.0697L34.1171 26.5553C33.9194 27.1482 33.0807 27.1482 32.883 26.5553L31.7211 23.0696C31.2032 21.516 29.984 20.2968 28.4304 19.7789L24.9447 18.617C24.3518 18.4194 24.3518 17.5806 24.9447 17.383L28.4304 16.2211C29.984 15.7032 31.2032 14.484 31.7211 12.9303L32.883 9.44472Z" fill="#6750A4"/>
      <path d="M42.0887 0.296479C42.2205 -0.0988289 42.7796 -0.0988256 42.9114 0.296484L43.686 2.6202C44.0312 3.656 44.844 4.4688 45.8798 4.81406L48.2035 5.58864C48.5988 5.72041 48.5988 6.27957 48.2035 6.41134L45.8798 7.18592C44.844 7.53118 44.0312 8.34398 43.686 9.37978L42.9114 11.7035C42.7796 12.0988 42.2205 12.0988 42.0887 11.7035L41.3141 9.37976C40.9688 8.34397 40.156 7.53119 39.1203 7.18592L36.7965 6.41134C36.4012 6.27957 36.4012 5.72041 36.7965 5.58864L39.1203 4.81406C40.156 4.46879 40.9688 3.65601 41.3141 2.62022L42.0887 0.296479Z" fill="#6750A4"/>
    </svg>
    {{ $t('widgets.darkTheme') }}
  </div>
  <div :class="['theme', { active: value === 'light' }]" @click="$emit('input', 'light')">
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.5 36C31.1274 36 36.5 30.6274 36.5 24C36.5 17.3726 31.1274 12 24.5 12C17.8726 12 12.5 17.3726 12.5 24C12.5 30.6274 17.8726 36 24.5 36Z" fill="white"/>
      <path d="M24.5 0C25.3284 0 26 0.671573 26 1.5V7.5C26 8.32843 25.3284 9 24.5 9C23.6716 9 23 8.32843 23 7.5V1.5C23 0.671573 23.6716 0 24.5 0Z" fill="white"/>
      <path d="M24.5 39C25.3284 39 26 39.6716 26 40.5V46.5C26 47.3284 25.3284 48 24.5 48C23.6716 48 23 47.3284 23 46.5V40.5C23 39.6716 23.6716 39 24.5 39Z" fill="white"/>
      <path d="M48.5 24C48.5 24.8284 47.8284 25.5 47 25.5H41C40.1716 25.5 39.5 24.8284 39.5 24C39.5 23.1716 40.1716 22.5 41 22.5H47C47.8284 22.5 48.5 23.1716 48.5 24Z" fill="white"/>
      <path d="M9.5 24C9.5 24.8284 8.82843 25.5 8 25.5H2C1.17157 25.5 0.5 24.8284 0.5 24C0.5 23.1716 1.17157 22.5 2 22.5H8C8.82843 22.5 9.5 23.1716 9.5 24Z" fill="white"/>
      <path d="M41.4706 7.02953C42.0563 7.61532 42.0563 8.56507 41.4706 9.15085L37.2279 13.3935C36.6421 13.9793 35.6924 13.9793 35.1066 13.3935C34.5208 12.8077 34.5208 11.858 35.1066 11.2722L39.3492 7.02953C39.935 6.44375 40.8848 6.44375 41.4706 7.02953Z" fill="white"/>
      <path d="M13.8934 34.6067C14.4792 35.1925 14.4792 36.1422 13.8934 36.728L9.65075 40.9707C9.06497 41.5564 8.11522 41.5564 7.52943 40.9707C6.94365 40.3849 6.94365 39.4351 7.52943 38.8493L11.7721 34.6067C12.3579 34.0209 13.3076 34.0209 13.8934 34.6067Z" fill="white"/>
      <path d="M41.4706 40.9707C40.8848 41.5565 39.935 41.5565 39.3492 40.9707L35.1066 36.7281C34.5208 36.1423 34.5208 35.1925 35.1066 34.6067C35.6924 34.0209 36.6421 34.0209 37.2279 34.6067L41.4706 38.8494C42.0563 39.4352 42.0563 40.3849 41.4706 40.9707Z" fill="white"/>
      <path d="M13.8934 13.3935C13.3076 13.9793 12.3579 13.9793 11.7721 13.3935L7.52944 9.15089C6.94365 8.5651 6.94365 7.61535 7.52944 7.02957C8.11522 6.44378 9.06497 6.44378 9.65076 7.02957L13.8934 11.2722C14.4792 11.858 14.4792 12.8077 13.8934 13.3935Z" fill="white"/>
    </svg>
    {{ $t('widgets.lightTheme') }}
  </div>
</div>
</template>

<style scoped lang="scss">
.themes-select {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 13px;
  grid-auto-rows: 124px;
  @media (max-width: 850px) {
    grid-template-columns: 1fr;
  }
}
.theme {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  color: var(--text-color-text-secondary, #7E7E7E);
  font-family: $Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 10px;
  border: 1px solid var(--border-border-input-primary-default);
  background: var(--bg-background-input-primary, #2A2A2A);
  cursor: pointer;
  @media (max-width: 850px) {
    height: 124px;
    font-size: 16px;
  }
  svg {
    opacity: 0.07;
    path {
      fill: var(--text-text-primary);
    }
  }
  &.active {
    border: 1.5px solid var(--border-border-input-primary-active, #6750A4);
    color: var(--text-text-primary-accent, #6750A4);

    svg {
      opacity: 1;
      path {
        fill: #6750A4;
      }
    }
  }
}
</style>
