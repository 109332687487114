<template>
<bh-select-new
    v-model="innerModel"
    :placeholder="$t('invoice.select-currencies')"
    class="grouping-select item"
    :list="this.filteredList"
    :label="''"
    with-search
    multiple
    clearable
    gap
>
  <template #search>
    <search
        id="search-input"
        class="search"
        v-model="search"
    />
  </template>
  <template #info="item">
    <div class="currency-network-option-item">
      <div class="currency-and-network-info column">
        <currency-network-icon :currency="item.currency.currency || item.currency" :network="item.network?.name.toUpperCase()" />
        <div class="item__title">
          <span class="currency">
           {{ item.alias  }}
          </span>
          <span class="ticker">({{ item.currency }})</span>
        </div>
      </div>
    </div>
  </template>
  <template
      #selected
  >
    <div class="currency-network-option-item">
      <div class="currency-and-network-info">
        <currency-network-icon v-for="item in selectedList" :key="item.id" :currency="item.currency" />
        <!--        <div>-->
        <!--                  <span class="currency">-->
        <!--                {{ item.currency.alias }}-->
        <!--              </span>-->
        <!--          <span class="network">-->
        <!--                {{ item.network.alias }}-->
        <!--        </span>-->
        <!--        </div>-->
        <span
            v-if="innerModel.length > 5"
            class="selected-quantity"
        >
                    +{{innerModel.length - 5}}
                </span>
      </div>
    </div>
  </template>
</bh-select-new>
</template>

<script>
  import BhSelectNew from '/public/components/elements/advance-balances/selects/Base'
  import {mapState} from "vuex";
  import NetworkAndCurrencyItem from "@/public/components/elements/orders/Select/Item.vue";
  import CurrencyImg from "@/public/components/elements/CurrencyImg.vue";
  import CurrencyNetworkIcon from "@/public/components/elements/business-walet/Select/CurrencyNetworkIcon.vue";

  export default {
    name: "WidgetsMultiWalletFiat",
    components: {
      CurrencyNetworkIcon,
      CurrencyImg,
      NetworkAndCurrencyItem,
      BhSelectNew,
      Search: () => import(/* webpackChunkName: "public/components/common/uiKit/search" */ '/public/components/common/uiKit/search'),
    },
    props: {
      value:  {
        type: Array,
        default: () => ([])
      },
      isFiat: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        search: ''
      }
    },
    computed: {
      ...mapState('app', ['currencies']),
      innerModel: {
        get({ value, list }) {
          return value
        },
        set(v) {
          this.$emit('input', v.map(({ id, currency }) => ({
            currency: currency,
            network: '',
            currencyId: id,
            networkId: ''
          })))
        }
      },
      selectedList () {
        return this.innerModel.slice(0, 5)
      },
      filteredList({ list, search }) {
        //метод фильтрации общего списка валют
        if (search) {
          return list.filter((netCurr = {}) => {
            const currencyName = netCurr['currency'] ? netCurr['currency']['currency'].toLowerCase() : '';
            const currencyAlias = netCurr['currency'] ? netCurr['currency']['alias'].toLowerCase() : '';

            return currencyName.includes(search.toLowerCase()) || currencyAlias.includes(search.toLowerCase())
          })
        } else {
          return list
        }
      },
      list ({ currencies }) {
        return  currencies.filter((curr) => curr.isFiat)
      },
    },

  }
</script>

<style lang="scss" scoped>
.selected-quantity {
  color: var(--new-front-primary-font-color);
  padding-left: 5px
}
.search {
  margin: 5px 0 15px 0;
}
.item__title {
  .ticker {
    color: var(--text-color-text-secondary);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }
  .currency {
    color: var(--text-text-primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }
}
::v-deep {
  .new-order-modal__row {
    width: 100%;
  }
  .placeholder {
    font-weight: 400;
  }
  .label {
    font-size: 13px;
  }
  .currency-network-icon-wrapper .currency-network-icon__currency {
    width: 32px;
    height: 32px;
  }
  @media screen and (max-width: 576px) {
    .placeholder {
      font-size: 13px;
    }
    .label {
      font-size: 10px;
    }
    .currency {
      font-size: 13px;
    }
    .network {
      font-size: 13px;
    }
    .currency-network-icon-wrapper .currency-network-icon__network {
      width: 14px;
      height: 14px;
    }
    .currency-network-icon-wrapper .currency-network-icon__currency {
      width: 18px;
      height: 18px;
    }
  }
}
.grouping-select {
  width: 100%;
}
.currency-network-option-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 500;
  font-style: normal;

  min-height: 32px;
  margin-left: 6px;

  .currency-and-network-info {
    display: flex;
    align-items: center;

    color: #8F949A;

    gap: 10px;

    .currency {
      color: var(--new-front-text-primary-bg-secondary);
    }
    .network {
      color: var(--new-front-text-secondary-bg-secondary);
    }
  }
  .amount {
    display: flex;
    align-items: end;
    gap: 8px;

    .amount-label {
      font-weight: 400;
      color: var(--new-front-text-secondary-bg-secondary);
    }
    .amount-value {
      color: var(--new-front-text-primary-bg-secondary);
    }
  }

  @include below_880() {
    .column {
      width: 100%;
      &.amount {
        display: none;
      }
    }
  }

  @include below_phone(){
    .currency-left-block {
      .currency-info {
        width: 100%;
        padding: 0 15px;

        .name {}
      }

      .network-info {
        display: none;
        width: 60%;
      }
    }
    ::v-deep {
      .grouping-select {
        .label {
          font-size: 13px;
        }
      }
      .new-bh-select-wrapper .input-wrapper .input .placeholder {
        font-size: 13px;
      }
    }
  }
}
@include below_phone(){
  ::v-deep {
    .grouping-select {
      .label {
        font-size: 13px;
      }
    }
    .new-bh-select-wrapper .input-wrapper .input .placeholder {
      font-size: 13px;
    }
  }
}
.options-list-item {
  &:hover {
    .currency-network-option-item {
      .currency-info-left-block {
        .currency-info {
          .alias {
            color: var(--primary-font-color);
          }
        }
        .network-info {
          color: var(--primary-font-color);
        }
      }
    }
  }
}
</style>
