<template>
<div class="table">
    <table-header @sort="sort" :list="headerList"/>
    <slot />
</div>
</template>
<script>
    import TableHeader from "@/public/components/elements/orphan-transactions/table/TableHeader.vue";
    // HeaderList Example
    // tableHeader: [
    //     {
    //         title: 'Date',
    //         slug: 'date',
    //         isSort: true
    //     }
    // ]
    export default {
        name: "Table",
        components: {TableHeader},
        props: {
            headerList: {
                type: Array,
                default: () => ([])
            }
        },
        methods: {
            sort (slug, sortDirection) {
                this.$emit('sort', slug, sortDirection)
            }
        }
    }
</script>
<style scoped lang="scss">

</style>
