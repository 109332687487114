<template>
  <div class="accounts-sort-by-type">
    <p class="accounts-sort-by-type__title">
      <b>Тип</b>
    </p>
    <AtSelect
        class="accounts-sort-by-type__select"
        placeholder="Выберите тип"

        clearable
        :value="isActiveType"
        @on-change="selectType"
    >
      <AtOption
          v-for="(status, key) in $options.statuses"
          :key="key"
          :value="status.value"
          :label="status.title"
      />
    </AtSelect>
  </div>
</template>

<script>

import {mapState} from 'vuex';

import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';
import {AccountsController} from "../../../store/modules/accounts/controller";

let names = {
    DEPOSIT: "Пополнение",
    WITHDRAWAL: "Вывод",
    WITHDRAWAL_INIT: "Комиссия вывода",
    TRANSACTION_INIT: "Создание ордера",
    TRANSACTION_PROCESSED: "Депозит по ордеру",
    SYSTEM_INCR: "Пополнение",
    SYSTEM_DECR: "Списание",
};

export default {
    name: 'AccountDataFilterByType',

    statuses: [
        {
            value: names.DEPOSIT,
            title: names.DEPOSIT,
        },
        {
            value: names.WITHDRAWAL,
            title: names.WITHDRAWAL,
        },
        {
            value: names.WITHDRAWAL_INIT,
            title: names.WITHDRAWAL_INIT,
        },
        {
            value: names.TRANSACTION_INIT,
            title: names.TRANSACTION_INIT,
        },
        {
            value: names.TRANSACTION_PROCESSED,
            title: names.TRANSACTION_PROCESSED,
        },
        {
            value: names.SYSTEM_INCR,
            title: names.SYSTEM_INCR,
        },
        {
            value: names.SYSTEM_DECR,
            title: names.SYSTEM_DECR,
        },
    ],
    components: {
        AtSelect,
        AtOption,
    },
    computed: {
        ...mapState('accounts', {
            isActiveType: state => state.filters.type,
        }),
    },
    beforeMount() {

    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    methods: {
        selectType(values) {
            AccountsController.getAccountData(this.data.accountId, 0, 1, values);
        },
    },
};

</script>

<style lang="scss" scoped>

.accounts-sort-by-status {
  &__title {
    margin-bottom: 8px;
    font-size: var(--font-size-text-main);
    font-family: var(--new-front-font-family);
    line-height: var(--line-height);
    color: var(--black-200);
  }

  &__select ::v-deep .at-select__selection {
    min-height: 28px;
  }

  ::v-deep .at-select__dropdown .at-select__option {
    font-family: var(--new-front-font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

  ::v-deep .at-select__placeholder {
    font-family: var(--new-front-font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }

  ::v-deep .at-select__selection {
    font-family: var(--new-front-font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

}

</style>
