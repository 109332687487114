import gql from 'graphql-tag';

// источник обмена Business wallet

export default gql`
    query($organizationId: String!, $accountId: String!, $includeArchived: Boolean) {
        getBusinessAddressesGrouped(organizationId: $organizationId, accountId: $accountId, includeArchived: $includeArchived) {
            currencyGroups {
                currencyId
                balance
                networkGroups {
                    networkId
                    balance
                    addresses {
                        id
                        accountId
                        currencyId
                        networkId
                        address
                        tag
                        balance
                        alias
                        comment
                        isBanned
                        isArchived
                    }
                }
            }
        }
    }
`;
