<template>
    <div class="dashboard-deposit-row table-row row">
        <div class="dashboard-deposit-row__id col">
            <CopyString
                :string="id"
                canCopy
            />
        </div>
        <div class="col">
            {{ amount }}
        </div>
        <div class="dashboard-deposit-row__currency col">
            <CurrencyImg
                class="dashboard-deposit-row__currency-img"
                :currency="currency"
            />
            {{ currency }}
        </div>
        <div class="col">
            {{ createdAt | getFormatDate('DD.MM.YYYY, HH:mm') }}
        </div>
        <div class="col col-1">
            <RouterLink :to="{ name: 'order', params: { id } }">
                <Icon icon="eye" />
            </RouterLink>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import CopyString from "/public/components/elements/CopyString";
import CurrencyImg from "/public/components/elements/CurrencyImg";
import Icon from "/public/components/elements/Icon";

export default {
    name: 'DashboardDepositRow',
    components: {
        CopyString,
        CurrencyImg,
        Icon,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters('app', [
            'getCurrencyNameById',
        ]),
        id () {
            return this.data.id;
        },
        currency () {
            const {
                data: { currency },
                getCurrencyNameById,
            } = this;

            return getCurrencyNameById(currency);
        },
        amount () {
            return this.data.amount;
        },
        createdAt () {
            return this.data.createdAt;
        },
    },
};

</script>

<style lang="scss" scoped>

.dashboard-deposit-row {
    position: relative;
    align-items: center;
    color: #3F536E;

    &__link {
    }

    &__id {
        position: relative;
        z-index: 2;
    }

    &__currency {
        display: flex;
        align-items: center;
    }

    &__currency-img {
        margin-right: 12px;
        width: 24px;
        height: 24px;
    }
}

</style>
