<template>
  <div class="requests-sort-by-platform">
    <p class="requests-sort-by-platform__title">
      {{ $t('withdrawal.platform') }}
    </p>
    <AtSelect
        class="requests-sort-by-platform__select"
        :placeholder="select_platform"

        clearable
        v-model="isActivePlatform"
        @on-change="selectPlatform"
    >
      <AtOption
          v-for="(platform, key) in platforms"
          :key="key"
          :value="platform"
      />
    </AtSelect>
  </div>
</template>

<script>

import {mapActions} from 'vuex';
import {WITHDRAWAL_REQUEST_PLATFORM_ENUM} from '../../../../common/constants/withdrawal-request-platform-enum';

import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';

export default {
    name: 'RequestsSortByStatus',
    components: {
        AtSelect,
        AtOption,
    },
    data() {
        return {
            isActivePlatform: [],
        };
    },
    computed: {
        platforms() {
            return Object.values(WITHDRAWAL_REQUEST_PLATFORM_ENUM);
        },
        select_platform() {
            return this.$t('withdrawal.select_platform');
        },
    },
    beforeMount() {
        this.setPlatformFromUrl();
    },
    methods: {
        ...mapActions('withdrawalRequests', [
            'fetchWithdrawalRequests',
        ]),
        setPlatformFromUrl() {
            const platformFromUrl = this.$route.query.platform;

            if (!platformFromUrl) {
                return false;
            }

            return this.isActivePlatform = platformFromUrl.split(',');
        },
        selectPlatform() {
            const {
                $route,
                $router,
                fetchWithdrawalRequests,
            } = this;

            fetchWithdrawalRequests({
                route: $route,
                router: $router,
                platform: this.isActivePlatform,
                excludeParams: ['page'],
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.requests-sort-by-platform {
  &__title {
    margin-bottom: 8px;
    font-size: var(--font-size-text-main);
    font-family: var(--font-family);
    line-height: var(--line-height);
    color: var(--black-200);
  }

  &__select ::v-deep .at-select__selection {
    min-height: 28px;
  }

  ::v-deep .at-select__dropdown .at-select__option {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

  ::v-deep .at-select__selection {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
  }

  ::v-deep .at-select__placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
  }
}

</style>