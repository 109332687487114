import enableCollectingMutation from "../../../../graphQl/mutations/enableCollecting";

export const enableCollecting = async ({dispatch, commit, rootGetters}, { aml }) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;

    let enableCollecting;

    commit('app/toggleLoading', null, {root: true});
    try {
        enableCollecting = await dispatch('app/request', {
            type: 'mutation',
            gql: enableCollectingMutation,
            variables: {
                organizationId,
                aml
            },
        }, {root: true});
    } catch (error) {
        commit('app/toggleLoading', null, {root: true});
        return error;

    }

    if (enableCollecting.message) {
        throw enableCollecting.message;
    }

    if (enableCollecting.errors) {
        throw enableCollecting.errors;
    }
    commit('app/toggleLoading', null, {root: true});
    const {
        data: {
            enableCollecting: enableCollectingResponse,
        },
    } = enableCollecting;


    return enableCollectingResponse;

};
