<template>
    <div class="example">
        <h2 class="example__title">
            <slot name="title" />
        </h2>
        <div class="example__settings">
            <slot name="settings" />
        </div>
        <div
            v-if="hasResponse"
            class="example__response"
        >
            <h3 class="example__sub-title">
                Данные запроса
            </h3>
            <div class="example__request-info">
                <template v-if="hasRequestData">
                    <h4 class="example__secondary-sub-title">
                        Передаваемые параметры:
                    </h4>
                    <div class="example__request-info-params">
                        <pre>{{ requestData }}</pre>
                    </div>
                </template>
                <template v-if="hasPublicKey">
                    Публичный ключ: {{ publicKey }}
                </template>
                <template v-if="hasSignature">
                    <br>
                    Подпись: {{ signature }}
                </template>
            </div>
            <h3 class="example__sub-title">
                Ответ api
            </h3>
            <div
                v-if="hasStatus"
                class="example__status"
            >
                Код ответа сервера: {{ status }} <template v-if="hasStatusText">({{ statusText }})</template>
            </div>
            <pre><slot name="response" /></pre>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Example',
    props: {
        hasResponse: Boolean,
        requestInfo: {
            type: Object,
            required: true,
        },
    },
    computed: {
        status () {
            return this.requestInfo.status;
        },
        statusText () {
            return this.requestInfo.statusText;
        },
        requestData () {
            return this.requestInfo.requestData;
        },
        publicKey () {
            return this.requestInfo.publicKey;
        },
        signature () {
            return this.requestInfo.signature;
        },
        hasStatus () {
            return Boolean(this.status);
        },
        hasStatusText () {
            return Boolean(this.statusText);
        },
        hasRequestData () {
            return Boolean(this.requestData);
        },
        hasPublicKey () {
            return Boolean(this.publicKey);
        },
        hasSignature () {
            return Boolean(this.signature);
        },
    },
};

</script>

<style lang="scss" scoped>

    .example {

        &__title {
            margin-bottom: 24px;
            @include h2;
        }

        &__sub-title {
            margin-bottom: 6px;
            @include h3;
        }

        &__secondary-sub-title {
            margin-bottom: 6px;
        }

        &__settings {
            margin-bottom: 16px;
        }

        &__request-info {
            margin-bottom: 24px;
        }

        &__request-info-params {
            margin-bottom: 12px;
        }

        &__status {
            margin-bottom: 16px;
        }

        &__response {
            padding: 24px;
            background-color: $gray2;
            border-radius: 4px;
        }
    }

</style>